import styled, { css } from 'styled-components';

export const BalancesItem = styled.div<{
  $inp?: boolean;
}>`
  display: flex;

  grid-column: ${({ $inp }) => (!$inp ? 1 : 2)};
  ${({ $inp }) =>
    $inp &&
    css`
      grid-row: 2;
    `};
`;
