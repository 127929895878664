import { useTranslation } from 'react-i18next';
import { useInnerModals } from '../../providers';
import { useUserAgentDataQuery } from '../../apollo/operations';
import { Button, ButtonProps } from './Button';

const AgentUsersButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { dispatch } = useInnerModals();
  const { data: { me: user } = {} } = useUserAgentDataQuery();
  const userIsAgent = !!user?.agent?.isAgent;

  if (!userIsAgent) return null;

  return (
    <Button
      onClick={() => {
        dispatch(['setModalContent', 'agentUsers']);
      }}
      {...props}
    >
      {t('CASHIER_PAGE_AGENT__usersButton')}
    </Button>
  );
};

export { AgentUsersButton };
