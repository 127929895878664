import { useMemo } from 'react';
import { Breakpoint } from 'styled-components';
import { isString } from 'lodash-es';
import { useDownMedia } from './useDownMedia';

export const useShortenedAddress = (
  token: Maybe<string>,
  config?: { breakPoint?: Breakpoint | number } & {
    [key in 'mobile' | 'desktop']?: {
      start?: number;
      end?: number;
    };
  }
) => {
  const breakPoint = config?.breakPoint || 'sm';
  const mobileStartLength = config?.mobile?.start || 4;
  const mobileEndLength = config?.mobile?.end || 6;
  const desktopStartLength = config?.desktop?.start || 6;
  const desktopEndLength = config?.desktop?.end || 8;
  const mobile = useDownMedia(breakPoint);
  const startLength = mobile ? mobileStartLength : desktopStartLength;
  const endLength = mobile ? mobileEndLength : desktopEndLength;

  return useMemo(() => {
    if (!isString(token)) return '';

    return `${token.substring(0, startLength)}..${token.substring(
      token.length - endLength
    )}`;
  }, [token, startLength, endLength]);
};
