import { Breakpoint } from 'styled-components';
import { isNumber } from 'lodash-es';
import { useMedia } from 'react-use';
import { breakpoints } from '../styles/theme';

export const useDownMedia = (breakPoint: Breakpoint | number) => {
  const value = isNumber(breakPoint)
    ? breakPoint
    : parseInt(breakpoints[breakPoint]);
  return useMedia(`(max-width: ${value - 1}px)`, true);
};
