import { useLayoutEffect } from 'react';
import { css } from 'styled-components';
import { useCustomLayoutContentStyles as useCustomStyles } from '../layouts';

export const useCustomLayoutContentStyles = (
  styles: Maybe<ReturnType<typeof css>> | false
) => {
  const { setStyles } = useCustomStyles();
  useLayoutEffect(() => {
    setStyles(styles || undefined);
  }, [styles, setStyles]);
};
