import TagManager from 'react-gtm-module';
import { Buffer } from 'buffer';
import { GlobalHooks } from './hooks';
import { Providers } from './providers';
import { Routes } from './components';

const tagManagerArgs = {
  gtmId: 'GTM-5NNSP2K',
};

TagManager.initialize(tagManagerArgs);

// Required for legacy wallet connect, should be removed on update
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}

const App = () => (
  <Providers>
    <GlobalHooks />
    <Routes />
  </Providers>
);

export { App };
