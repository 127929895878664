import { isString, isNumber, toString, toNumber } from 'lodash-es';
import { BigNumber } from 'ethers';
import { ethers } from 'ethers/lib.esm';
import { erc20ABI } from 'wagmi';
import { BigNumberish } from '@ethersproject/bignumber';
import { BlockchainsQueryResult } from '../../apollo/operations';
import { marketplaceListingAbi } from '../abi/marketplace-listing';
import { nftCardAbi } from '../abi/nft-card';
import { nftSwapAbi } from '../abi/nft-swap';
import { getEnvVariable } from './env-helpers';

export type CryptoAddress = `0x${string}`;

export const isCryptoAddress = (value: unknown): value is CryptoAddress =>
  isString(value) && value.startsWith('0x');

export const getBSCChainId = () => toNumber(getEnvVariable('BSC_CHAIN_ID'));

export const getChainIdWithMyNFTs = () =>
  toNumber(getEnvVariable('CHAIN_ID_WITH_MY_NFTS'));

type CustomAbiContractName =
  | keyof Pick<
      NonNullable<BlockchainsQueryResult['data']>['blockchains'][number],
      'marketplaceAddress' | 'nftCardAddress'
    >
  | 'nftSwapAddress';

const ABIs = {
  marketplaceAddress: marketplaceListingAbi,
  nftCardAddress: nftCardAbi,
  nftSwapAddress: nftSwapAbi,
} as const;

type ABIsType = typeof ABIs;

export const getAbi = <T extends string>(
  name?: T
): T extends CustomAbiContractName ? ABIsType[T] : typeof erc20ABI =>
  // @ts-ignore
  isString(name) && name in ABIs ? ABIs[name] : erc20ABI;

type GetBNValueHelper = {
  string: string;
  number: number;
};

export const getBNValue = <T extends keyof GetBNValueHelper>(
  value: unknown,
  type: T
) => {
  if (!BigNumber.isBigNumber(value)) {
    throw new Error(`Value is not big number`);
  }

  return value[
    type === 'string' ? 'toString' : 'toNumber'
  ]() as GetBNValueHelper[T];
};

const isStringOrNumber = (value: unknown): value is StringOrNumber =>
  [isString, isNumber].some((fn) => fn(value));

export const getToBlockChainAmount = (
  rawAmount: StringOrNumber,
  units?: BigNumberish
) => {
  if (!isStringOrNumber(rawAmount)) {
    return BigNumber.from(0);
  }
  const amount = isString(rawAmount) ? rawAmount : toString(rawAmount);
  return ethers.utils.parseUnits(amount, units);
};

export const getFromBlockChainAmount = (
  amount: BigNumberish,
  units?: BigNumberish
) => ethers.utils.formatUnits(amount, units);
