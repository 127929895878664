import styled, { css } from 'styled-components';
import { BalancesItem } from './BalancesItem';
import { BalancesSectionLabel } from './BalancesSectionLabel';

export const BalancesSection = styled.section<{
  $grid?: boolean;
}>`
  padding: 0.6rem 5%;

  ${({ $grid = false }) =>
    $grid
      ? css`
          display: grid;
          grid-template-columns: 0.8fr 1fr;
          grid-gap: 6px 33px;
          align-content: space-between;

          ${BalancesSectionLabel} {
            grid-column: 1 / -1;
          }
        `
      : css`
          ${BalancesItem} {
            margin-top: 6px;
          }
        `}};
`;
