import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Text';
import { Button as DefButton } from 'components/buttons';
import { TranslationKeys } from '../../utils/i18n';
import { useOuterModals } from '../../providers';

type ConfirmationModalProps = {
  title?: TranslationKeys;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const ConfirmationModal = ({
  title,
  onConfirm,
  onCancel,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const { closeModal, dispatch } = useOuterModals();

  useLayoutEffect(() => {
    dispatch([
      'setWrapperProps',
      {
        size: 'narrow',
      },
    ]);
  }, [dispatch]);

  const onModalClose = () => {
    onCancel?.();
    closeModal();
  };

  return (
    <>
      <Title>{title ? t(title) : t('CONFIRMATION_MODAL__defaultTitle')}</Title>
      <Buttons>
        <Button
          onClick={() => {
            onConfirm?.();
            closeModal();
          }}
        >
          {t('CONFIRMATION_MODAL__confirmButtonText')}
        </Button>
        <Button onClick={onModalClose}>
          {t('CONFIRMATION_MODAL__cancelButtonText')}
        </Button>
      </Buttons>
    </>
  );
};

const Title = styled((props) => <Text component={'h3'} {...props} />)`
  margin-bottom: 16px;
  text-align: center;
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  justify-content: space-between;

  ${({ theme }) => theme.getDownMedia('xs')} {
    grid-template-columns: 1fr;
  }
`;

const Button = styled(DefButton)`
  min-width: 140px;

  ${({ theme }) => theme.getDownMedia('xs')} {
    min-width: 100px;
  }
`;

export { ConfirmationModal };
