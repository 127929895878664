import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isEmpty, isString, toNumber, pick } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikFormProps } from 'formik';
import { useSnackbar } from 'notistack';
import { useAccount } from 'wagmi';
import * as Yup from 'yup';
import {
  Currencies,
  currenciesData,
  isInpTokenSymbol,
} from '../../utils/consts/currencies';
import { LocalStorageKeys } from '../../utils/helpers';
import { getFormattedNumber } from '../../utils/helpers/strings';
import { useLocalStorageValue, useGpanelBalances } from '../../hooks';
import {
  useBlockchainBalances,
  useBlockchain,
  useServerAuth,
} from '../../providers';
import {
  LastWithdrawTransactionDocument,
  GpanelPendingTransactionsDocument,
  useUserWithdrawFormDataQuery,
  usePaymentSubmitWithdrawMutation,
  useAgentPaymentSubmitWithdrawMutation,
} from '../../apollo/operations';
import { useGpanel } from '../../providers/GpanelProvider';
import { Text as DefText } from '../Text';
import { Button as DefButton } from '../buttons';
import { BalanceInfoBlock } from '../cashier/BalanceInfoBlock';
import { AgentBlock as DefAgentBlock } from '../form-blocks';
import { Select as DefSelect } from '../form-elements/Select';
// Required to fix building issue
// noinspection ES6PreferShortImport
import { IconPosition } from '../form-elements/SelectOptionWithIcon';
import { FormikInput } from '../formik-elements';
import { BalancesInfoList } from '../styled/cashier';

const FullAccessWithdrawForm = (props: FormikFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useAccount();
  const { fullAccess } = useServerAuth();
  const blockchain = useBlockchain();
  const { value: rawBlockchainBalances } = useBlockchainBalances();
  const { user: gpanelUser } = useGpanel();
  const { balances: gpanelBalances } = useGpanelBalances();
  const [currency, setCurrency] = useState(Currencies.INP);
  const gpanelAuth = useLocalStorageValue(LocalStorageKeys.GPANEL_AUTH);

  const blockchainBalances = useMemo(
    () =>
      fullAccess && rawBlockchainBalances
        ? (rawBlockchainBalances.map((balance) =>
            pick(balance, ['id', 'symbol', 'withdrawActive', 'iconUrl'])
          ) as Array<Balance>)
        : predefinedCurrencies,
    [fullAccess, rawBlockchainBalances]
  );

  const chosenCurrency = useMemo(
    () =>
      blockchainBalances?.find(
        ({ symbol }) => symbol.toUpperCase() === currency
      ),
    [blockchainBalances, currency]
  );

  const { data: { me: user, agentGetUsers } = {} } =
    useUserWithdrawFormDataQuery({
      fetchPolicy: 'cache-and-network',
    });

  const kycData = useMemo(() => {
    const kyc = user?.kyc;

    if (!(kyc && chosenCurrency)) return null;

    return isInpTokenSymbol(chosenCurrency.symbol)
      ? {
          minWithdraw: kyc.minWithdrawInp,
          maxWithdraw: kyc.maxWithdrawInp,
          feeWithdraw: kyc.feeWithdrawInp,
        }
      : {
          minWithdraw: kyc.minWithdraw,
          maxWithdraw: kyc.maxWithdraw,
          feeWithdraw: kyc.feeWithdraw,
        };
  }, [user, chosenCurrency]);

  const hasAgent = !!user?.agent?.hasAgent;

  const agentOptions = useMemo(() => {
    const users = agentGetUsers?.agentFrom;
    if (!(hasAgent && !isEmpty(users))) return [];
    return users!.map((user) => ({
      label: user!.username,
      value: user!.gPanelUserId,
    }));
  }, [hasAgent, agentGetUsers]);

  const schema = useMemo(() => {
    if (!kycData) return undefined;
    const min = kycData.minWithdraw;
    const maxWithdraw = kycData.maxWithdraw;
    const availableBalance = toNumber(
      gpanelBalances?.[isInpCurrency(chosenCurrency) ? 'inp' : 'usd'] ?? '0'
    );
    const max = Math.min(maxWithdraw, availableBalance);
    return getSchema({
      min,
      max,
      fullAccess,
    });
  }, [kycData, gpanelBalances, chosenCurrency, fullAccess]);

  const selectOptions = useMemo(() => {
    if (!blockchainBalances) return [];

    return blockchainBalances
      .filter(({ withdrawActive }) => withdrawActive)
      .map(({ symbol, iconUrl }) => ({
        label: symbol.toUpperCase(),
        value: symbol.toUpperCase(),
        icon: iconUrl,
        iconPosition: IconPosition.START,
      }));
  }, [blockchainBalances]);

  const [userPaymentSubmitWithdraw] = usePaymentSubmitWithdrawMutation({
    fetchPolicy: 'no-cache',
  });

  const [agentPaymentSubmitWithdraw] = useAgentPaymentSubmitWithdrawMutation({
    fetchPolicy: 'no-cache',
  });

  const withdraw = useCallback(
    async (amount: number, userToWithdraw: string | null) => {
      try {
        if (
          fullAccess &&
          !(address && gpanelUser && gpanelAuth && blockchain && chosenCurrency)
        ) {
          throw new Error(`User's wallet should be connected`);
        } else if (!(gpanelUser && gpanelAuth && chosenCurrency)) {
          throw new Error(`No data to proceed with gpanel only`);
        }

        const toAgent = hasAgent && !!userToWithdraw;

        const blockchainId = blockchain?.id;
        const stablecoinId =
          !isInpTokenSymbol(chosenCurrency.symbol) && chosenCurrency.id
            ? toNumber(chosenCurrency.id)
            : undefined;
        const currency = chosenCurrency.symbol.toLowerCase();

        const commonVariables = {
          gpanelUserId: gpanelUser.id,
          gpanelAccessToken: gpanelAuth.accessToken,
          blockchainId,
          stablecoinId,
          currency,
          amount,
        };

        const commonConfig = {
          awaitRefetchQueries: true,
          refetchQueries: [
            { query: GpanelPendingTransactionsDocument },
            { query: LastWithdrawTransactionDocument },
          ],
        };

        const { data: withdrawResult } = await (toAgent
          ? agentPaymentSubmitWithdraw({
              variables: {
                ...commonVariables,
                agentGpanelUserId: userToWithdraw,
              },
              ...commonConfig,
            })
          : userPaymentSubmitWithdraw({
              variables: {
                ...commonVariables,
                account: address!,
              },
              ...commonConfig,
            }));

        const { status } = (() => {
          let status: string | undefined = undefined;

          if (!withdrawResult) return { status };

          if (
            'paymentSubmitWithdraw' in withdrawResult &&
            withdrawResult.paymentSubmitWithdraw
          ) {
            status = withdrawResult.paymentSubmitWithdraw.status;
          }

          if (
            'agentPaymentSubmitWithdraw' in withdrawResult &&
            withdrawResult.agentPaymentSubmitWithdraw
          ) {
            status = withdrawResult.agentPaymentSubmitWithdraw.status;
          }

          return { status };
        })();

        if (!status || status !== 'Created') {
          throw new Error(`Wrong status in payment withdraw mutation`);
        }
      } catch (e) {
        console.log(`Error in deposit function:`, e);
        throw e;
      }
    },
    [
      fullAccess,
      address,
      gpanelUser,
      gpanelAuth,
      hasAgent,
      blockchain,
      chosenCurrency,
      agentPaymentSubmitWithdraw,
      userPaymentSubmitWithdraw,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        amount: 0,
        user: null,
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async ({ amount, user }, { setFieldValue }) => {
        try {
          await withdraw(amount, user);
          setFieldValue('amount', 0, false);
          enqueueSnackbar(t('WITHDRAW_FORM__submitSuccess'), {
            variant: 'success',
          });
        } catch (e) {
          console.log(e);
          enqueueSnackbar(t('WITHDRAW_FORM__submitError'), {
            variant: 'error',
          });
        }
      }}
    >
      {({ values: { amount }, isSubmitting }) => {
        const currentValue = selectOptions.find(
          ({ value }) => value === currency
        );
        const minWithdraw = getFormattedNumber(kycData?.minWithdraw);
        const maxWithdraw = getFormattedNumber(kycData?.maxWithdraw);
        const feeWithdrawNum = isInpTokenSymbol(currentValue?.value)
          ? amount * 0.03
          : kycData?.feeWithdraw ?? 0;
        const feeWithdraw = getFormattedNumber(feeWithdrawNum);
        const resultAmount = getFormattedNumber(
          amount - feeWithdrawNum > 0 ? amount - feeWithdrawNum : 0
        );

        const label = currentValue?.label ?? '';

        return (
          <Form {...props}>
            <InputLabel>{t('WITHDRAW_FORM__label')}:</InputLabel>
            <InputWithButton>
              <Input
                name={'amount'}
                type={'number'}
                disabled={!kycData}
                InputProps={
                  !isEmpty(selectOptions)
                    ? {
                        endAdornment: (
                          <Select
                            defaultValue={currentValue}
                            options={selectOptions}
                            withBorder={false}
                            bgColor={'shark2'}
                            isSearchable={false}
                            onChange={(newValue) => {
                              const newCurrencyValue =
                                newValue &&
                                'value' in newValue &&
                                newValue.value;

                              const newCurrency =
                                newCurrencyValue &&
                                (Object.keys(Currencies).find(
                                  (currency) => currency === newCurrencyValue
                                ) as Currencies | undefined);

                              if (newCurrency && newCurrency !== currency) {
                                setCurrency(newCurrency);
                              }
                            }}
                          />
                        ),
                      }
                    : undefined
                }
              />
              <SubmitButton
                type={'submit'}
                loading={isSubmitting}
                disabled={!kycData}
              >
                {t('WITHDRAW_FORM__button')}
              </SubmitButton>
            </InputWithButton>
            {!!kycData && (
              <TransactionDataList
                $switchBreakpoint={switchBreakpoint}
                $withBottomLine
              >
                <BalanceInfoBlock
                  title={t('WITHDRAW_FORM__resultAmount')}
                  currency={currency}
                  amount={resultAmount}
                />
                <BalanceInfoBlock
                  title={t('WITHDRAW_FORM__feeAmount')}
                  currency={currency}
                  amount={feeWithdraw}
                />
              </TransactionDataList>
            )}
            {!!kycData && (
              <>
                <KYCDataList $switchBreakpoint={switchBreakpoint}>
                  <BalanceInfoBlock
                    title={t('WITHDRAW_FORM__minDepositInfo')}
                    currency={currency}
                    amount={minWithdraw}
                  />
                  <BalanceInfoBlock
                    title={t('WITHDRAW_FORM__maxDepositInfo')}
                    currency={currency}
                    amount={maxWithdraw}
                  />
                  <BalanceInfoBlock
                    title={t('WITHDRAW_FORM__feeDepositInfo')}
                    currency={currency}
                    amount={feeWithdraw}
                  />
                </KYCDataList>
                {label === 'INP' && (
                  <ResultFeeInfo>
                    {t('WITHDRAW_FORM__INPfeeRemark')}
                  </ResultFeeInfo>
                )}
              </>
            )}
            {hasAgent && (
              <AgentBlock
                type={'user'}
                name={'user'}
                selectProps={{
                  options: agentOptions,
                  isClearable: true,
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

// TODO: extract to be common with deposit form
type FormValues = {
  amount: number;
  user: string | null;
};

const isInpCurrency = (currency: Maybe<Record<string, unknown>>) =>
  currency &&
  'symbol' in currency &&
  isString(currency.symbol) &&
  isInpTokenSymbol(currency.symbol);

type Balance = {
  id: string | null;
  symbol: string;
  iconUrl: string;
  withdrawActive: boolean;
};

const predefinedCurrencies: ReadonlyArray<Balance> = [
  {
    id: null,
    symbol: Currencies.INP,
    iconUrl: currenciesData[Currencies.INP].icon,
    withdrawActive: true,
  },
  {
    id: null,
    symbol: Currencies.USD,
    iconUrl: currenciesData[Currencies.USD].icon,
    withdrawActive: true,
  },
];

const getSchema = ({
  min,
  max,
  fullAccess,
}: {
  min: number;
  max: number;
  fullAccess: boolean;
}): Yup.SchemaOf<FormValues> =>
  Yup.object({
    amount: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : toNumber(value)))
      .positive('Should be more than zero')
      .min(min, 'Should be more than min amount')
      .max(max, 'Should be less than available amount')
      .required('Field is required'),
    user: (() => {
      const userSchema = Yup.string();
      if (fullAccess) {
        return userSchema.defined().nullable();
      } else {
        return userSchema.required(
          `Agent should be selected, since you didn't connect wallet`
        );
      }
    })(),
  });

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${({ theme }) => theme.getDownMedia('sm')} {
    gap: 10px;
  }
`;

const Text = styled(DefText)`
  font-size: 14px;
  line-height: 1;
`;

const SmallGrayText = styled(DefText)`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: ${14 / 12};
  color: ${({ theme }) => theme.getColor('osloGray')};
`;

const InputLabel = styled(Text)``;

const switchBreakpoint = 1140;

const SubmitButton = styled(DefButton)`
  min-width: 142px;
  margin-left: 14px;

  ${({ theme }) => theme.getDownMedia(switchBreakpoint)} {
    margin-top: 8px;
    margin-left: 0;
  }
`;

const InputWithButton = styled.div`
  display: flex;
  ${({ theme }) => theme.getDownMedia(switchBreakpoint)} {
    flex-direction: column;
  }
`;

const Input = styled(FormikInput)`
  flex: 1 1 auto;
`;

const Select = styled(DefSelect)`
  min-width: 104px;
  align-self: stretch;
  .react-select__control {
    border-radius: 4px;
    height: 100%;
  }

  .react-select__dropdown-indicator {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0;
  }
`;

const TransactionDataList = styled(BalancesInfoList)``;

const KYCDataList = styled(BalancesInfoList)``;

const AgentBlock = styled(DefAgentBlock)`
  margin-bottom: 2px;
`;

const ResultFeeInfo = styled(SmallGrayText)``;

export { FullAccessWithdrawForm };
