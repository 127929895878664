import {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { usePrevious, useUpdateEffect } from 'react-use';
import { useSnackbar } from 'notistack';
import { useAccount } from 'wagmi';
import { useApolloClient } from '@apollo/client';
import { UserRoles } from '../utils/auth-consts';
import {
  LocalStorageKeys,
  setLocalStorageValue,
  removeLocalStorageValue,
} from '../utils/helpers';
import { useLocalStorageValue, useGetServerAuthData } from '../hooks';
import { useGpanel } from './GpanelProvider';

const ServerAuthProvider = (props: PropsWithChildren) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useAccount();
  // const prevAddress = usePrevious(address);
  const authData = useLocalStorageValue(localStorageKey);
  const userId = authData?.userId;
  const prevUserId = usePrevious(userId);
  const { loading, user: gpanelUser } = useGpanel();
  const getServerAuthData = useGetServerAuthData();
  const [clearingStore, setClearingStore] = useState(!authData?.accessToken);

  const role = useMemo(() => {
    switch (authData?.role) {
      case 'user':
        return UserRoles.user;
      case 'gpanel':
        return UserRoles.gpanel;
      default:
        return null;
    }
  }, [authData]);

  const authOnServer = useCallback(async () => {
    try {
      const serverAuth = await getServerAuthData();
      setLocalStorageValue(localStorageKey, serverAuth);
      return true;
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Couldn't auth on server`, { variant: 'error' });
      removeLocalStorageValue(localStorageKey);
      return false;
    }
  }, [enqueueSnackbar, getServerAuthData]);

  useUpdateEffect(() => {
    if (loading) return;
    if (!address && !gpanelUser) {
      removeLocalStorageValue(localStorageKey);
    }
  }, [loading, address, gpanelUser]);

  useEffect(() => {
    if (!authData?.accessToken || (prevUserId && prevUserId !== userId)) {
      setClearingStore(true);
      client.clearStore().finally(() => setClearingStore(false));
    }
  }, [authData, prevUserId, userId, client]);

  const context = useMemo<Context>(
    () => ({
      authorized: !!authData,
      role,
      fullAccess: role === UserRoles.user,
      authOnServer,
    }),
    [authData, role, authOnServer]
  );

  if (clearingStore) return null;

  return <ServerAuthContext.Provider value={context} {...props} />;
};

const localStorageKey = LocalStorageKeys.GRAPHQL_AUTH;

type Context = {
  authorized: boolean;
  role: UserRoles | null;
  fullAccess: boolean;
  authOnServer: () => Promise<boolean>;
};

const ServerAuthContext = createContext<Context>({
  authorized: false,
  role: null,
  fullAccess: false,
  authOnServer: () => Promise.resolve(false),
});

const useServerAuth = () => useContext(ServerAuthContext);

export { ServerAuthProvider, useServerAuth };
