import { useMemo, useContext, createContext, PropsWithChildren } from 'react';
import { useChainId } from '../hooks';
import {
  useBlockchainsQuery,
  BlockchainsQueryResult,
} from '../apollo/operations';

const BlockchainProvider = (props: PropsWithChildren) => {
  const chainId = useChainId();
  const { data: { blockchains = [] } = {} } = useBlockchainsQuery();

  const blockchain = useMemo(
    () =>
      blockchains.find(
        ({ chainId: blockchainId }) => blockchainId === chainId
      ) ?? null,
    [chainId, blockchains]
  );

  return <BlockchainContext.Provider value={blockchain} {...props} />;
};

type Blockchain = NonNullable<
  BlockchainsQueryResult['data']
>['blockchains'][number];

const BlockchainContext = createContext<Blockchain | null>(null);

export const useBlockchain = () => useContext(BlockchainContext);

export { BlockchainProvider };
