import { useCallback } from 'react';
import { ethers } from 'ethers';
import { useProvider, useAccount } from 'wagmi';
import { getAbi } from '../utils/helpers/contract-helpers';
import { getEnvVariable } from '../utils/helpers/env-helpers';
import { useBlockchain } from '../providers';

export const useCheckAllowanceNFT = () => {
  const { address } = useAccount();
  const provider = useProvider();
  const blockchain = useBlockchain();

  return useCallback(
    async (cardId: string) => {
      if (!(address && blockchain?.nftCardAddress)) {
        throw new Error(
          'No user address or nftCardAddress address in' +
            ' GetCheckAllowanceLP'
        );
      }

      const nftCardContract = new ethers.Contract(
        blockchain.nftCardAddress,
        getAbi('nftCardAddress'),
        provider
      );

      const allowedResult = (
        await nftCardContract.getApproved(cardId)
      ).toLowerCase();

      if (allowedResult !== NFTSwapAddress) {
        return nftCardContract.isApprovedForAll(address, NFTSwapAddress);
      }

      return allowedResult === NFTSwapAddress;
    },
    [address, blockchain, provider]
  );
};

const NFTSwapAddress = getEnvVariable('NFT_SWAP_ADDRESS');
