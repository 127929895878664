import { isString, isNumber } from 'lodash-es';
import { ConnectorNames } from '../consts/connectors';
import { isArrayOrObject } from './common';

export enum LocalStorageKeys {
  GRAPHQL_AUTH = 'inpoker/auth/graphql',
  GPANEL_AUTH = 'inpoker/gpanel/auth',
  GPANEL_TEMP_UUID = 'inpoker/gpanel/tempUUID',
  GPANEL_PREV_PAGE = 'inpoker/gpanel/prev-page',
  CONNECTOR = 'inpoker/wallet/connector',
  LANGUAGE = 'inpoker/language',
  WITHDRAW_2_FA_DATA = 'inpoker/withdraw-2fa-data',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LocalStorageObserverFn = (value: any) => void;

const changeObservers = Object.values(LocalStorageKeys).reduce(
  (acc, key) => ({
    ...acc,
    [key]: [],
  }),
  {} as Record<LocalStorageKeys, LocalStorageObserverFn[]>
);

type ManagedData<R, P = undefined> = {
  params?: P extends undefined ? R : P;
  response: Nullish<R>;
};

type GraphqlAuthShape = {
  userId: string;
  accessToken: string;
  role: string;
};

type GpanelAuthShape = {
  accessToken: string;
  refreshToken: string;
};

type KeysManageData = {
  [LocalStorageKeys.GRAPHQL_AUTH]: ManagedData<GraphqlAuthShape>;
  [LocalStorageKeys.GPANEL_AUTH]: ManagedData<GpanelAuthShape>;
  [LocalStorageKeys.GPANEL_TEMP_UUID]: ManagedData<string>;
  [LocalStorageKeys.GPANEL_PREV_PAGE]: ManagedData<string>;
  [LocalStorageKeys.CONNECTOR]: ManagedData<ConnectorNames>;
  [LocalStorageKeys.LANGUAGE]: ManagedData<string>;
  [LocalStorageKeys.WITHDRAW_2_FA_DATA]: ManagedData<{
    id: string;
    time: string;
  }>;
};

export const setLocalStorageValue = <T extends keyof KeysManageData>(
  key: T,
  value: NonNullable<KeysManageData[T]['params']>
) => {
  let dataToSave;

  switch (true) {
    case isArrayOrObject(value):
      dataToSave = JSON.stringify(value);
      break;
    case isNumber(value):
      // @ts-ignore
      dataToSave = parseFloat(value);
      break;
    case isString(value):
      dataToSave = value;
      break;
    default:
      dataToSave = null;
  }

  if (!dataToSave) {
    removeLocalStorageValue(key);
    return;
  }

  localStorage.setItem(key, dataToSave as string);
  changeObservers[key].forEach((fn) => fn(value));
};

export const removeLocalStorageValue = (key: LocalStorageKeys) => {
  localStorage.removeItem(key);
  changeObservers[key].forEach((fn) => fn(null));
};

export const getLocalStorageValue = <T extends keyof KeysManageData>(
  key: T
): KeysManageData[T]['response'] => {
  const savedData = localStorage.getItem(key);

  if (!savedData) return null;

  try {
    return JSON.parse(savedData);
  } catch (e) {
    return savedData;
  }
};

export const addLocalStorageObserver = (
  key: LocalStorageKeys,
  fn: LocalStorageObserverFn
) => {
  if (changeObservers[key].includes(fn)) return false;
  changeObservers[key].push(fn);
  return true;
};

export const removeLocalStorageObserver = (
  key: LocalStorageKeys,
  fn: LocalStorageObserverFn
) => {
  if (!changeObservers[key].includes(fn)) return false;
  changeObservers[key] = changeObservers[key].filter(
    (storedFn) => storedFn !== fn
  );
  return true;
};
