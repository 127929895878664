/* eslint-disable prefer-spread */
import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Paginator as DefPaginator } from 'components/Paginator';
import { useSnackbar } from 'notistack';
import { useAccount } from 'wagmi';
import {
  useCustomLayoutContentStyles,
  useUserAvatar,
  useUpdateAvatars,
} from '../hooks';
import { useServerAuth } from '../providers';
import { BackgroundedWrapper } from '../components/styled';
import {
  useUserNftAvatarsQuery,
  useSetUserAvatarMutation,
  UserAvatarDocument,
} from '../apollo/operations';
import { Avatar as DefAvatar } from '../components/Avatar';
import { PermissionsBlock } from '../components/PermissionsBlock';
import { Text as DefText, Text } from '../components/Text';
import { Button } from '../components/buttons';
import { AvatarCard } from '../components/cards';
import { Spinner } from '../components/spinners/Spinner';
import { useGpanel } from '../providers/GpanelProvider';
import { cashierAndVaultPagesStyles } from '../styles/cashier-and-vault-pages';
import { withDividerStyles } from '../styles/divider';

const NFTAvatarPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useAccount();
  const { fullAccess } = useServerAuth();
  const { avatar } = useUserAvatar();
  const { loading: loadingInpokerUser, user: inpokerUser } = useGpanel();
  const [page, setPage] = useState(1);

  useCustomLayoutContentStyles(cashierAndVaultPagesStyles);

  const signedIn = address && fullAccess && inpokerUser;

  const { loading: loadingAvatars, data: { userErc721s: rawAvatars } = {} } =
    useUserNftAvatarsQuery({
      skip: !signedIn,
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
    });

  const avatars = useMemo(() => {
    if (!rawAvatars) return [];
    return rawAvatars.filter((avatar) => !!avatar.imgUrl);
  }, [rawAvatars]);

  const paginatedAvatars = useMemo(
    () => avatars.slice((page - 1) * avatarsOnPage, page * avatarsOnPage),
    [avatars, page]
  );

  const [updateAvatars, { updatingAvatars }] = useUpdateAvatars();

  const [updateUserAvatar, { loading: updatingUserAvatar }] =
    useSetUserAvatarMutation({
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: UserAvatarDocument,
        },
      ],
      awaitRefetchQueries: true,
    });

  if (loadingInpokerUser || !signedIn) {
    return <PermissionsBlock />;
  }

  if (!rawAvatars && loadingAvatars) {
    return <Spinner />;
  }

  return (
    <>
      <CurrentAvatarSection>
        <AvatarImageInfo>
          <AvatarImage size={'big'} />
          <AvatarCaption>
            {t('NFT_AVATAR_PAGE__currentAvatarText')}
          </AvatarCaption>
        </AvatarImageInfo>
        <AvatarTextInfo>
          {!!avatar?.name && (
            <AvatarTextInfoBlock>
              <AvatarTextInfoTitle>
                {t('NFT_AVATAR_PAGE__currentAvatarNFTNameTitle')}
              </AvatarTextInfoTitle>
              <AvatarTextInfoText>{avatar.name}</AvatarTextInfoText>
            </AvatarTextInfoBlock>
          )}
          {!!avatar?.collection && (
            <AvatarTextInfoBlock>
              <AvatarTextInfoTitle>
                {t('NFT_AVATAR_PAGE__currentAvatarNFTCollectionTitle')}
              </AvatarTextInfoTitle>
              <AvatarTextInfoText>{avatar.collection}</AvatarTextInfoText>
            </AvatarTextInfoBlock>
          )}
        </AvatarTextInfo>
      </CurrentAvatarSection>
      <AvatarRefreshSection>
        <AvatarRefreshSectionText>
          {t('NFT_AVATAR_PAGE__updateSectionText')}
        </AvatarRefreshSectionText>
        <AvatarRefreshSectionButton
          loading={updatingAvatars}
          size={'small'}
          onClick={() => {
            if (signedIn) {
              updateAvatars();
            } else {
              enqueueSnackbar(
                t('NFT_AVATAR_PAGE__updateSectionSignInRequire'),
                {
                  variant: 'warning',
                }
              );
            }
          }}
        >
          {t('NFT_AVATAR_PAGE__updateSectionButton')}
        </AvatarRefreshSectionButton>
      </AvatarRefreshSection>
      <NFTsSection>
        <NFTsSectionTitle>
          {t('NFT_AVATAR_PAGE__nftCollectionTitle')}
        </NFTsSectionTitle>
        <List>
          {paginatedAvatars.map((avatar) => (
            <AvatarCard
              key={avatar.id}
              card={avatar}
              settingAvatar={updatingUserAvatar}
              setUserAvatar={() =>
                updateUserAvatar({
                  variables: {
                    id: avatar.id,
                  },
                })
              }
            />
          ))}
        </List>
        {avatars.length > avatarsOnPage && (
          <Paginator
            count={Math.ceil(avatars.length / avatarsOnPage)}
            page={page}
            onChange={(_, page) => setPage(page)}
          />
        )}
      </NFTsSection>
      {updatingAvatars && (
        <LoaderBlock>
          <Spinner size={80} />
          <LoaderText as={'h3'}>
            {t('MY_NFT_CARDS_PAGE__loadingText')}
          </LoaderText>
        </LoaderBlock>
      )}
    </>
  );
};

const avatarsOnPage = 10;

const CurrentAvatarSection = styled.section`
  display: flex;
`;

const AvatarImageInfo = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AvatarImage = styled(DefAvatar)``;

const AvatarCaption = styled(Text).attrs({
  variant: 'small',
  color: 'osloGray',
})`
  margin-top: 10px;
`;

const AvatarRefreshSection = styled(BackgroundedWrapper).attrs({
  as: 'section',
})`
  padding: 16px 20px;
`;

const AvatarRefreshSectionText = styled(DefText)``;
const AvatarRefreshSectionButton = styled(Button)`
  margin-top: 24px;
`;

const AvatarTextInfo = styled.section`
  margin-left: 14%;
  flex: 1 1 auto;
`;

const AvatarTextInfoBlock = styled.section`
  & + & {
    margin-top: 16px;
  }
`;

const AvatarTextInfoTitle = styled(DefText).attrs({
  component: 'h3',
  variant: 'small',
  color: 'osloGray',
})`
  margin-bottom: 2px;
  text-transform: uppercase;
`;

const AvatarTextInfoText = styled(DefText)``;

const desktopOffset = 2;
const mobileOffset = 15;

const NFTsSection = styled.section`
  grid-column: 1 / -1;
  margin-top: ${desktopOffset + desktopOffset}px;
  position: relative;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    margin-top: ${mobileOffset + mobileOffset}px;
  }

  ${withDividerStyles({
    desktopOffset,
    mobileOffset,
  })};
`;

const NFTsSectionTitle = styled(DefText).attrs({
  component: 'h2',
  variant: 'normal',
  weight: 'medium',
})`
  margin: 24px 0 22px;
  text-transform: uppercase;
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, 175px);
  grid-gap: 30px 10px;
  justify-content: space-between;

  ${({ theme }) => theme.getDownMedia(400)} {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

const Paginator = styled(DefPaginator)`
  display: flex;
  justify-content: center;
  padding: 20px 0 30px;
`;

const LoaderBlock = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
`;

const LoaderText = styled(Text)`
  margin-top: 40px;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
`;

export { NFTAvatarPage };
