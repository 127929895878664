import { useRef, useState, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikFormProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Currencies } from '../../utils/consts/currencies';
import { VaultDataFields } from '../../utils/helpers/vault-staking';
import { useVault } from '../../providers';
import { useInnerModals } from '../../providers';
import { resetButtonStyles, resetListStyles } from '../../styles/helpers';
import { Text } from '../Text';
import { Button as DefButton } from '../buttons';
import { BalanceInfoBlock } from '../cashier/BalanceInfoBlock';
import { FormikInput } from '../formik-elements';

export type StackFormProps = FormikFormProps;

const StakeForm = (props: StackFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { value: vaultValue, retry: reloadVault } = useVault();
  const [submitting, setSubmitting] = useState(false);
  const typeValueRef = useRef<SubmitType | null>(null);
  const { dispatch } = useInnerModals();
  const { vault } = vaultValue ?? {};

  type Handlers = {
    [key in Exclude<SubmitType, 'getReward' | 'unstakeAll'>]: (
      data: string
    ) => Promise<void>;
  } & {
    [key in Exclude<SubmitType, 'stake' | 'unstake'>]: () => Promise<
      void | 'canceled'
    >;
  };

  const hanlders = useMemo<Handlers>(
    () => ({
      stake: (data) => vault?.stake(data) ?? Promise.reject(),
      unstake: (data) =>
        new Promise((resolve, reject) => {
          dispatch([
            'setModalContent',
            'unstake',
            {
              onConfirmClick: () =>
                vault?.unstake(data).then(resolve).catch(reject),
              // @ts-ignore
              onClose: () => resolve('canceled'),
            },
          ]);
        }),
      getReward: () => vault?.getReward() ?? Promise.reject(),
      unstakeAll: () =>
        new Promise((resolve, reject) => {
          dispatch([
            'setModalContent',
            'unstake',
            {
              onConfirmClick: () => {
                if (!vault) return reject();
                vault
                  .unstake(vault.data[VaultDataFields.STACKED_AMOUNT])
                  .then(resolve)
                  .catch(reject);
              },
              onClose: () => resolve('canceled'),
            },
          ]);
        }),
    }),
    [vault, dispatch]
  );

  const showError = useCallback(() => {
    enqueueSnackbar('Issue on submit', { variant: 'error' });
  }, [enqueueSnackbar]);

  const handleChange = useCallback(
    async <T extends SubmitType>(
      type: T,
      value?: Parameters<Handlers[T]>[0]
    ) => {
      try {
        setSubmitting(true);
        const result = await hanlders[type](value as string);

        if (result === 'canceled') return;

        reloadVault();
        enqueueSnackbar('Success', { variant: 'success' });
      } catch (e) {
        showError();
      } finally {
        setSubmitting(false);
      }
    },
    [hanlders, reloadVault, enqueueSnackbar, showError]
  );

  if (!vault) return null;

  const disabled = !vault.data[VaultDataFields.ALLOWED_STATE];

  return (
    <Formik<FormValues>
      initialValues={{
        stackValue: '',
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async ({ stackValue }, { resetForm }) => {
        if (!typeValueRef.current) {
          showError();
          return;
        }
        await handleChange(typeValueRef.current, stackValue);
        resetForm();
      }}
    >
      {({ setFieldValue, submitForm }) => (
        <Form aria-disabled={disabled} {...props}>
          <InputLabel as={'label'} htmlFor="staking-input">
            {t('STAKE_FORM__inputLabel')}:
          </InputLabel>
          <Input
            id={'staking-input'}
            type={'number'}
            // @ts-ignore
            max={vault.data[VaultDataFields.BALANCE]}
            name={'stackValue'}
            placeholder={t('STAKE_FORM__inputPlaceholder')}
            disabled={disabled || submitting}
            helperText={
              !disabled &&
              `${t('STAKE_FORM__inputAvailableBalanceHelpText')}: ${
                vault.data[VaultDataFields.BALANCE]
              } ${t('STAKE_FORM__inputStakedHelpText')}: ${
                vault.data[VaultDataFields.STACKED_AMOUNT]
              }`
            }
            InputProps={{
              endAdornment: (
                <MaxButton
                  type={'button'}
                  disabled={disabled || submitting}
                  onClick={() => {
                    if (disabled || submitting) return;
                    setFieldValue(
                      'stackValue',
                      vault.data[VaultDataFields.BALANCE]
                    );
                  }}
                >
                  {t('STAKE_FORM__inputMaxButton')}
                </MaxButton>
              ),
            }}
          />
          <Buttons>
            <Button
              type={'button'}
              loading={submitting}
              disabled={disabled}
              onClick={() => {
                typeValueRef.current = 'stake';
                submitForm();
              }}
            >
              {t('STAKE_FORM__stakeButton')}
            </Button>
            <Button
              color={'secondary'}
              type={'button'}
              loading={submitting}
              disabled={disabled}
              onClick={() => {
                typeValueRef.current = 'unstake';
                submitForm();
              }}
            >
              {t('STAKE_FORM__unStakeButton')}
            </Button>
            <Button
              color={'secondary'}
              type={'button'}
              loading={submitting}
              disabled={disabled}
              onClick={() => {
                handleChange('getReward');
              }}
            >
              {t('STAKE_FORM__rewardButton')}
            </Button>
            <Button
              color={'secondary'}
              type={'button'}
              loading={submitting}
              disabled={disabled}
              onClick={() => {
                handleChange('unstakeAll');
              }}
            >
              {t('STAKE_FORM__unStakeAllButton')}
            </Button>
          </Buttons>
          <InfoBlock>
            <BalanceInfoBlock
              title={t('STAKE_FORM__availableBalanceLabel')}
              currency={Currencies.INP}
              amount={vault.data[VaultDataFields.BALANCE]}
            />
            <BalanceInfoBlock
              title={t('STAKE_FORM__stakedAmountLabel')}
              currency={Currencies.INP}
              amount={vault.data[VaultDataFields.STACKED_AMOUNT]}
            />
          </InfoBlock>
        </Form>
      )}
    </Formik>
  );
};

/*const Form = styled(DefForm)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    flex-direction: column;
  }
`;*/

const Form = styled(DefForm)`
  padding-bottom: 18px;
`;

const InputLabel = styled(Text)`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    margin-bottom: 10px;
  }
`;

const Input = styled(FormikInput)`
  width: 100%;
`;

const MaxButton = styled.button`
  ${resetButtonStyles};
  padding: 7px 8px;
  background: ${({ theme }) => theme.getColor('gradientButtonBackground')};
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  line-height: ${14 / 12};
  position: relative;
  right: 4px;
  ${({ disabled }) =>
    !!disabled &&
    css`
      cursor: auto;
    `}
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4%;
  margin-top: 20px;

  ${({ theme }) => theme.getDownMedia('md')} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
`;

const Button = styled(DefButton)`
  padding-left: 4px;
  padding-right: 4px;
`;

const InfoBlock = styled.ul`
  ${resetListStyles};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-top: 18px;
  border-top: 1px solid ${({ theme }) => theme.getColor('charade')};
  padding-top: 14px;
  ${({ theme }) => theme.getDownMedia(1050)} {
    grid-template-columns: 1fr;
  }
`;

type FormValues = {
  stackValue: string;
};

type SubmitType = 'stake' | 'unstake' | 'getReward' | 'unstakeAll';

const schema = Yup.object().shape({
  stackValue: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : toNumber(value)))
    .positive('Should be more than zero')
    .required('Field is required'),
});

export { StakeForm };
