import { isNumber } from 'lodash-es';
import { TranslationKeys } from './i18n';

export enum BlockchainErrors {
  GENERAL = 'general',
  NOT_ENOUGH_FUNDS = 'notEnoughFunds',
}

const blockchainErrorsTranslations: {
  [key in BlockchainErrors]: TranslationKeys;
} = {
  [BlockchainErrors.GENERAL]: 'BLOCKCHAIN_ERRORS__general',
  [BlockchainErrors.NOT_ENOUGH_FUNDS]: 'BLOCKCHAIN_ERRORS__notEnoughFunds',
};

const blockchainErrorByCode = new Map<number, BlockchainErrors>([
  [-32000, BlockchainErrors.NOT_ENOUGH_FUNDS],
]);
const getBlockchainErrorByCode = (code: unknown) => {
  if (isNumber(code) && blockchainErrorByCode.has(code)) {
    return blockchainErrorByCode.get(code) as BlockchainErrors;
  }
  return BlockchainErrors.GENERAL;
};

export const getBlockchainErrorTranslationByCode = (code: unknown) =>
  blockchainErrorsTranslations[getBlockchainErrorByCode(code)];
