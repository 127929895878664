import styled from 'styled-components';
import { Text } from '../../Text';

export const BalancesSectionLabel = styled(Text).attrs({
  component: 'h3',
  variant: 'small',
  color: 'osloGray',
})`
  text-transform: uppercase;
`;
