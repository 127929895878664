import { useLanguage } from './useLanguage';
import { useWalletEagerConnect } from './useWalletEagerConnect';
import { useWalletEventListeners } from './useWalletEventListeners';

export const GlobalHooks = () => {
  useWalletEagerConnect();
  useWalletEventListeners();
  useLanguage();
  return null;
};
