import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useGpanel } from '../providers/GpanelProvider';
import { GpanelInfo } from './GpanelInfo';
import { GpanelLoginButton as DefGpanelLoginButton } from './buttons';
import { Spinner } from './spinners/Spinner';

const AppbarGpanel = (props: HTMLAttributes<HTMLDivElement>) => {
  const { loading, user } = useGpanel();

  return (
    <Wrapper {...props}>
      {(() => {
        if (loading) return <Spinner />;
        if (user) return <GpanelInfo />;
        return <GpanelLoginButton />;
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const GpanelLoginButton = styled(DefGpanelLoginButton)`
  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    width: auto;
    margin-left: auto;
    padding: 6px 10px;
    text-align: center;
  }
`;

export { AppbarGpanel };
