import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { useVault } from '../../providers';
import { BackgroundedWrapper } from '../styled';
import { StakeForm } from '../forms';
import { Tab, TabList, TabPanel, Tabs } from '../styled/tabs';
import { RegistrationTabContent } from './RegistrationTabContent';

const VaultTabs = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { isConnected } = useAccount();
  const { value: vaultData } = useVault();
  const vault = vaultData?.vault;

  if (!isConnected || !vault) return null;

  return (
    <Wrapper as={'section'} {...props}>
      <Tabs>
        <TabList>
          <Tab>{t('VAULT_TABS__stakingTab')}</Tab>
          <Tab>{t('VAULT_TABS__registrationTab')}</Tab>
        </TabList>
        <TabPanel>
          <StakeForm />
        </TabPanel>
        <TabPanel>
          <RegistrationTabContent />
        </TabPanel>
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled(BackgroundedWrapper)``;

export { VaultTabs };
