import { useState, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty, compact, toString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Paginator as DefPaginator } from 'components/Paginator';
import { useAccount } from 'wagmi';
import {
  getBSCChainId,
  getChainIdWithMyNFTs,
} from '../utils/helpers/contract-helpers';
import {
  useMyNftCards,
  useIsMobile,
  useIsPortraitTablet,
  useIsLandscapeTablet,
} from 'hooks';
import { useBlockchain, useServerAuth, useInnerModals } from '../providers';
import { useDelegatedNfTsQuery } from '../apollo/operations';
import { Text, TextProps } from '../components/Text';
import { Button } from '../components/buttons';
import {
  MyCardItem as DefCardItem,
  ProcessingCardItem as DefProcessingCardItem,
  DelegatedCardItem as DefDelegatedCardItem,
  MyCardItemProps,
} from '../components/cards';
import { DelegateNFTCardsForm as DefDelegateNFTCardsForm } from '../components/forms';
import { Spinner } from '../components/spinners/Spinner';

const MyNftCardsPage = () => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { fullAccess, authOnServer } = useServerAuth();
  const { dispatch } = useInnerModals();
  const blockchain = useBlockchain();
  const [page, setPage] = useState(1);
  const [checkedCards, setCheckedCards] = useState<number[]>([]);
  const mobile = useIsMobile();
  const portraitTablet = useIsPortraitTablet();
  const landscapeTablet = useIsLandscapeTablet();

  const cardsOnPage = useMemo(() => {
    switch (true) {
      case mobile:
        return 2;
      case portraitTablet:
        return 6;
      case landscapeTablet:
      default:
        return 8;
    }
  }, [mobile, portraitTablet, landscapeTablet]);

  const active =
    !!blockchain &&
    (getBSCChainId() === blockchain.chainId ||
      getChainIdWithMyNFTs() === blockchain.chainId);

  const delegateActive =
    !!blockchain && getChainIdWithMyNFTs() === blockchain.chainId;

  const { loading, cards, processingCards, reloadCards } = useMyNftCards(
    !active
  );

  const paginatedCards = useMemo(
    () => cards.slice((page - 1) * cardsOnPage, page * cardsOnPage),
    [cards, cardsOnPage, page]
  );

  const onCardCheckChange = useCallback<
    NonNullable<MyCardItemProps['onCardCheckChange']>
  >((cardId, checked) => {
    setCheckedCards((prevCards) => {
      if (checked && !prevCards.includes(cardId)) {
        return [...prevCards, cardId];
      } else if (!checked && prevCards.includes(cardId)) {
        return prevCards.filter((id) => id !== cardId);
      } else {
        return prevCards;
      }
    });
  }, []);

  const { data: { NFTUserConnected } = {} } = useDelegatedNfTsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !fullAccess,
  });

  const { ownDelegatedCards, rentedDelegatedCards } = useMemo(
    () => ({
      ownDelegatedCards: compact(NFTUserConnected?.own ?? []),
      rentedDelegatedCards: compact(NFTUserConnected?.rented ?? []),
    }),
    [NFTUserConnected]
  );

  return (
    <>
      {(() => {
        if (loading) {
          return <Spinner />;
        }

        if (address && !active) {
          return (
            <UnavailableTitle>
              {t('MY_NFT_CARDS_PAGE__unsupportedBlockchainTitle')}
            </UnavailableTitle>
          );
        }

        return (
          <>
            <List>
              {0 < processingCards &&
                new Array(processingCards)
                  .fill('', 0, processingCards)
                  .map((_, index) => (
                    <ProcessingCardItem
                      tokenId={index.toString()}
                      key={`empty-card-${index}`}
                    />
                  ))}
              {paginatedCards.map((card) => {
                const delegatedCard = ownDelegatedCards.find(
                  ({ tokenId }) => toString(tokenId) === card.tokenId
                );

                const delegated = (() => {
                  if (
                    delegatedCard &&
                    delegatedCard.owner &&
                    delegatedCard.username
                  ) {
                    return {
                      status: delegatedCard.status,
                      owner: delegatedCard.owner,
                      username: delegatedCard.username,
                    };
                  }
                })();

                return (
                  <CardItem
                    key={card.id}
                    card={card}
                    delegated={delegated}
                    cardIsChecked={checkedCards.includes(card.id)}
                    onCardCheckChange={onCardCheckChange}
                    onClick={() => {
                      dispatch([
                        'setModalContent',
                        'cardOverview',
                        {
                          card,
                          reloadCards,
                        },
                      ]);
                    }}
                  />
                );
              })}
            </List>
            {cards.length > cardsOnPage && (
              <Paginator
                count={Math.ceil(cards.length / cardsOnPage)}
                page={page}
                onChange={(_, page) => setPage(page)}
              />
            )}
            {fullAccess ? (
              <>
                {delegateActive && !isEmpty(cards) && (
                  <DelegateNFTCardsForm cardIds={checkedCards} />
                )}
              </>
            ) : address ? (
              <Button onClick={authOnServer}>
                {t('PERMISSIONS_BLOCK__signWalletButton')}
              </Button>
            ) : null}
            {!isEmpty(rentedDelegatedCards) && (
              <DelegatedList>
                {rentedDelegatedCards.map((card) => (
                  <DelegatedCardItem key={card.id} card={card} />
                ))}
              </DelegatedList>
            )}
          </>
        );
      })()}
    </>
  );
};

const UnavailableTitle = styled((props: TextProps) => (
  <Text component={'h1'} {...props} />
))`
  text-align: center;
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, 175px);
  grid-gap: 30px 10px;
  justify-content: space-between;

  ${({ theme }) => theme.getDownMedia(400)} {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

const commonCardItemStyles = css``;

const ProcessingCardItem = styled(DefProcessingCardItem)`
  ${commonCardItemStyles};
`;

const CardItem = styled(DefCardItem)`
  ${commonCardItemStyles};
`;

const Paginator = styled(DefPaginator)`
  display: flex;
  justify-content: center;
  padding: 20px 0 30px;
`;

const DelegateNFTCardsForm = styled(DefDelegateNFTCardsForm)`
  max-width: 470px;
  margin: 10px auto 20px;
`;

const DelegatedList = styled(List)`
  margin-top: 20px;
`;

const DelegatedCardItem = styled(DefDelegatedCardItem)`
  ${commonCardItemStyles};
`;

export { MyNftCardsPage };
