import {
  useState,
  useMemo,
  HTMLAttributes,
  Dispatch,
  SetStateAction,
} from 'react';
import styled, { css } from 'styled-components';
import {
  isString,
  isEmpty,
  isObject,
  isFunction,
  toNumber,
  toString,
  uniqBy,
  compact,
} from 'lodash-es';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useAsyncFn, useUpdateEffect } from 'react-use';
import { useSnackbar } from 'notistack';
import { Currencies, currenciesData } from '../utils/consts/currencies';
import { getEnvVariable } from '../utils/helpers/env-helpers';
import {
  useDirectDepositDataQuery,
  DepositAddressDocument,
  UserDepositAddressesDocument,
  DepositAddressQuery,
  DepositAddressQueryVariables,
  UserDepositAddressesQuery,
  UserDepositAddressesQueryVariables,
} from '../apollo/operations';
import { resetListStyles } from '../styles/helpers';
import { Text } from './Text';
import { Button, CopyButton as DefCopyButton } from './buttons';
import { IconPosition, Select as DefSelect } from './form-elements';
import { Spinner } from './spinners/Spinner';
import { Label as DefLabel } from './styled/cashier';

export type DirectDepositProps = {
  extendedVersion?: boolean;
  onTransactionHistoryClick?: () => void;
  onWalletDepositClick?: () => void;
} & HTMLAttributes<HTMLDivElement>;

const DirectDeposit = ({
  extendedVersion = false,
  onTransactionHistoryClick,
  onWalletDepositClick,
  ...props
}: DirectDepositProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [currency, setCurrency] = useState<Maybe<string>>(null);
  const [blockchain, setBlockchain] = useState<Maybe<string>>(null);

  const { loading, data, client } = useDirectDepositDataQuery({
    fetchPolicy: 'cache-and-network',
  });

  const addresses = data?.me?.depositAddress;
  const stablecoins = data?.stablecoins;
  const blockchains = data?.blockchains;

  const address = useMemo(
    () =>
      blockchain
        ? compact(addresses).find(
            ({ blockchainId }) => blockchainId === toNumber(blockchain)
          )?.address ?? null
        : null,
    [addresses, blockchain]
  );

  const stablecoinsOptions = useMemo(() => {
    if (!stablecoins) return [];

    return [
      {
        label: Currencies.INP,
        value: Currencies.INP,
        icon: currenciesData[Currencies.INP].icon,
        iconPosition: IconPosition.START,
      },
      ...uniqBy(stablecoins, 'symbol').map(({ symbol, iconUrl }) => ({
        label: symbol.toUpperCase(),
        value: symbol.toUpperCase(),
        icon: iconUrl ?? '',
        iconPosition: IconPosition.START,
      })),
    ];
  }, [stablecoins]);

  const blockchainsOptions = useMemo(() => {
    if (!(currency && !isEmpty(blockchains))) {
      return [];
    }

    // If Inp we're limiting to bsc blockchain
    if (currency === Currencies.INP) {
      const bscBlockchain = blockchains!.find(
        ({ chainId }) => chainId === toNumber(getEnvVariable('BSC_CHAIN_ID'))
      );

      if (!bscBlockchain) return [];

      return [
        {
          label: bscBlockchain.name,
          value: toString(bscBlockchain.id),
          icon: bscBlockchain.iconUrl ?? '',
          iconPosition: IconPosition.START,
        },
      ];
    }

    return blockchains!
      .filter(({ stablecoins }) =>
        stablecoins?.some(({ symbol }) => symbol.toUpperCase() === currency)
      )
      .map(({ id, name, iconUrl }) => ({
        label: name,
        value: toString(id),
        icon: iconUrl ?? '',
        iconPosition: IconPosition.START,
      }));
  }, [currency, blockchains]);

  useUpdateEffect(() => {
    if (currency === Currencies.INP) {
      const bscBlockchainId = blockchains?.find(
        ({ chainId }) => chainId === toNumber(getEnvVariable('BSC_CHAIN_ID'))
      )?.id;
      bscBlockchainId && setBlockchain(toString(bscBlockchainId));
    }
  }, [currency, blockchains]);

  const [{ loading: loadingDepositAddress }, getDepositAddress] =
    useAsyncFn(async () => {
      try {
        await client.query<DepositAddressQuery, DepositAddressQueryVariables>({
          query: DepositAddressDocument,
          fetchPolicy: 'network-only',
          variables: {
            blockchainId: toNumber(blockchain),
          },
        });
        await client.query<
          UserDepositAddressesQuery,
          UserDepositAddressesQueryVariables
        >({
          query: UserDepositAddressesDocument,
          fetchPolicy: 'network-only',
        });
      } catch (e) {
        console.error(`Error on getting deposit address`, e);
        enqueueSnackbar(t('DIRECT_DEPOSIT__gettingDepositAddressError'), {
          variant: 'error',
        });
      }
    }, [client, blockchain]);

  const setSelectValue =
    (setValue: Dispatch<SetStateAction<Maybe<string>>>) =>
    (selectValue: unknown) => {
      // @ts-ignore
      const newValue = isObject(selectValue) ? selectValue.value : null;
      isString(newValue) && setValue(newValue);
    };

  if (loading && !data) {
    return (
      <Spinner
        style={{
          margin: 'auto',
        }}
      />
    );
  }

  return (
    <Wrapper
      $extendedVersion={extendedVersion}
      $addressExist={!!address}
      {...props}
    >
      <ControlsAndAddress>
        <Label>{t('DIRECT_DEPOSIT__currencyLabel')}:</Label>
        <Select
          defaultValue={{
            label: t('DIRECT_DEPOSIT__currencySelectPlaceholder'),
            value: undefined,
          }}
          options={stablecoinsOptions}
          isSearchable={false}
          whiteBackground
          onChange={setSelectValue(setCurrency)}
        />
        <Select
          defaultValue={{
            label: t('DIRECT_DEPOSIT__blockchainSelectPlaceholder'),
            value: undefined,
          }}
          value={
            currency === Currencies.INP && blockchain
              ? blockchainsOptions.find(({ value }) => value === blockchain)
              : undefined
          }
          options={blockchainsOptions}
          noOptionsMessage={() => t('DIRECT_DEPOSIT__noBlockchainsOptions')}
          isSearchable={false}
          whiteBackground
          onChange={setSelectValue(setBlockchain)}
        />
        {!!blockchain && (
          <>
            <Label>{t('DIRECT_DEPOSIT__depositAddressLabel')}:</Label>
            {address ? (
              <>
                <AddressWrapper>
                  <Address weight={'bold'}>{address}</Address>
                  <CopyButton valueToCopy={address} />
                </AddressWrapper>
                <NoticeText
                  style={{
                    gridColumn: '1 / -1',
                  }}
                >
                  {t('DIRECT_DEPOSIT__notification')}
                </NoticeText>
              </>
            ) : (
              <GetAddressButton
                loading={loadingDepositAddress}
                onClick={getDepositAddress}
              >
                {t('DIRECT_DEPOSIT__getDepositAddressButton')}
              </GetAddressButton>
            )}
          </>
        )}
      </ControlsAndAddress>
      {!!address && (
        <>
          <QRCodeWrapper>
            <QRCode
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={address}
            />
          </QRCodeWrapper>
          <ButtonsList>
            {isFunction(onTransactionHistoryClick) && (
              <li>
                <Button color={'secondary'} onClick={onTransactionHistoryClick}>
                  {t('DIRECT_DEPOSIT__transactionHistoryButton')}
                </Button>
              </li>
            )}
            {isFunction(onWalletDepositClick) && (
              <li>
                <Button onClick={onWalletDepositClick}>
                  {t('DIRECT_DEPOSIT__walletDepositButton')}
                </Button>
              </li>
            )}
          </ButtonsList>
        </>
      )}
    </Wrapper>
  );
};

const normalBreakPoint = 1192;
const extendedBreakPoint = 780;

const ControlsAndAddress = styled.div`
  grid-area: form;
  align-self: start;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 15px;

  ${({ theme }) => theme.getDownMedia('sm')} {
    grid-row-gap: 10px;
  }
`;

const QRCodeWrapper = styled.div`
  grid-area: qr;
  align-self: start;
  padding: 6px 6px 3px;
  background-color: #fff;
`;

const Wrapper = styled.div<{
  $extendedVersion: boolean;
  $addressExist: boolean;
}>`
  min-height: 220px;
  display: grid;
  grid-template-columns: minmax(300px, 3.3fr) minmax(98px, 1fr);
  grid-template-areas:
    'form qr'
    'buttons buttons';
  grid-column-gap: 22px;

  ${({ $extendedVersion, $addressExist }) =>
    $extendedVersion &&
    css`
      grid-column-gap: 15%;
      max-width: 685px;
      margin: 0 auto;
      ${$addressExist &&
      css`
        ${ControlsAndAddress} {
          ${({ theme }) => theme.getUpMedia(extendedBreakPoint)} {
            position: relative;
            &:after {
              content: '';
              display: block;
              width: 1px;
              height: 80%;
              background-color: ${({ theme }) =>
                theme.getColor('rgbaDividerColor')};
              position: absolute;
              top: 0;
              right: -4%;
            }
          }
        }
      `};
    `}

  ${({ theme, $extendedVersion }) =>
    theme.getDownMedia(
      $extendedVersion ? extendedBreakPoint : normalBreakPoint
    )} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'form'
      'buttons';
  }

  ${QRCodeWrapper} {
    ${({ theme, $extendedVersion }) =>
      theme.getDownMedia(
        $extendedVersion ? extendedBreakPoint : normalBreakPoint
      )} {
      display: none;
    }
  }
`;

const Label = styled(DefLabel).attrs({
  component: 'h4',
  variant: 'normal',
  weight: 'normal',
})`
  grid-column: 1 / -1;
`;

const Select = styled(DefSelect)`
  min-width: 104px;
  grid-column: span 2;
`;

const AddressWrapper = styled.div`
  display: flex;
  grid-column: 1 / -1;
`;

const Address = styled(Text)`
  word-break: break-all;
`;

const CopyButton = styled(DefCopyButton)`
  flex-shrink: 0;
  margin-left: 10px;
`;

const GetAddressButton = styled(Button)``;

const NoticeText = styled(Text)`
  font-size: ${({ theme }) => theme.pxToRem(10)};
  line-height: ${12 / 10};
`;

const ButtonsList = styled.ul`
  ${resetListStyles};
  grid-area: buttons;
  margin-top: 15px;
`;

export { DirectDeposit };
