import {
  useCallback,
  useLayoutEffect,
  useMemo,
  useContext,
  createContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { isObject, isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMethods } from 'react-use';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { devMode } from '../utils/consts/consts';
import { LocalStorageKeys, setLocalStorageValue } from '../utils/helpers';
import { getUniqueId, prettifyResponse } from '../utils/helpers/common';
import { getEnvVariable } from '../utils/helpers/env-helpers';
import { gpanelApi, GpanelAPICallTypes } from '../utils/helpers/gpanel-helpers';
import { useLocalStorageValue } from '../hooks';
import {
  SetGpanelUuidDocument,
  SetGpanelUuidMutation,
  SetGpanelUuidMutationVariables,
} from '../apollo/operations';

const GpanelProvider = (props: PropsWithChildren) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const gpanelAuthData = useLocalStorageValue(LocalStorageKeys.GPANEL_AUTH);
  const [state, methods] = useMethods(createMethods, initialState);

  const authInGpanel = useCallback(async () => {
    try {
      methods.toggleLoading(true);

      const getGpanelUrl = (id: string) => {
        const gpanelDomain = getEnvVariable('INPOKER_OAUTH2_URL');
        let param: string;
        const requestDomain = window.location.hostname;

        if (requestDomain === 'localhost') {
          param = `localhost:${window.location.port}`;
        } else {
          param = requestDomain.replaceAll('.', '-');
        }

        return `${gpanelDomain}/oauth2/authorize?response_type=code&client_id=site&grant_type=password&state=${id}|old|${param}`;
      };

      const id = getUniqueId();
      const gpanelUrl = getGpanelUrl(id);

      const response = await client.mutate<
        SetGpanelUuidMutation,
        SetGpanelUuidMutationVariables
      >({
        mutation: SetGpanelUuidDocument,
        fetchPolicy: 'no-cache',
        variables: {
          uuid: id,
        },
      });

      if (
        response.errors ||
        response.data?.setGpanelUUID?.message !== 'saved'
      ) {
        throw new Error(
          `Couldn't save uuid to hasura: ${
            response.errors ? `: ${prettifyResponse(response.errors)}` : ''
          }`
        );
      }

      setLocalStorageValue(LocalStorageKeys.GPANEL_TEMP_UUID, id);

      window.location.replace(gpanelUrl);
    } catch (e) {
      if (isObject(e) && 'message' in e) {
        // @ts-ignore
        console.log(`Error on gpanel login: ${e.message}`);
      } else {
        console.log(`Error on gpanel login`);
      }
      enqueueSnackbar(t('DASHBOARD__gpanelLoginError'), {
        variant: 'error',
      });
      methods.toggleLoading(false);
    }
  }, [methods, client, enqueueSnackbar, t]);

  const loadUserData = useCallback(async () => {
    const response = await gpanelApi(GpanelAPICallTypes.USER_INFO);
    return response.data;
  }, []);

  const loadUserBalance = useCallback(async () => {
    const response = await gpanelApi(GpanelAPICallTypes.USER_WALLET);
    return Object.entries(response.data.balances).reduce<
      Record<string, string>
    >(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value.formatted,
      }),
      {}
    );
  }, []);

  const loadData = useCallback(async () => {
    try {
      methods.toggleLoading(true);
      const [userInfo, balance] = await Promise.all([
        loadUserData(),
        loadUserBalance(),
      ]);

      methods.setUser({
        id: userInfo.uuid,
        name: userInfo.username,
        email: userInfo.email,
        balance,
      });
    } catch (e) {
      methods.resetUser();
    } finally {
      methods.toggleLoading(false);
    }
  }, [methods, loadUserData, loadUserBalance]);

  // Load data to provider or reset
  useLayoutEffect(() => {
    if (!gpanelAuthData) {
      methods.resetUser();
      methods.toggleLoading(false);
    } else {
      loadData();
    }
  }, [gpanelAuthData, methods, loadData]);

  // Updating balance each 5 seconds
  useEffect(() => {
    if (!gpanelAuthData) return;

    let mounted = true;

    const interval = setInterval(
      () =>
        loadUserBalance()
          .then((balance) => {
            mounted && methods.setBalance(balance);
          })
          .catch((e) => {
            console.log(`Couldn't update inpoker user balance:`, e);
          }),
      devMode ? 5 * 60 * 1000 : 20 * 1000
    );

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [gpanelAuthData, loadUserBalance, methods]);

  const contextValue = useMemo(
    () => ({ ...state, authInGpanel }),
    [state, authInGpanel]
  );

  return <GpanelContext.Provider value={contextValue} {...props} />;
};

type State = {
  loading: boolean;
  user: {
    id: string;
    name: string;
    email: string;
    balance: Record<string, string>;
  } | null;
};

const initialState: State = {
  loading: true,
  user: null,
};

// noinspection JSUnusedGlobalSymbols
const createMethods = (state: State) => ({
  toggleLoading: (val: boolean) => ({
    ...state,
    loading: val ?? !state.loading,
  }),
  setUser: (user: State['user']) => ({
    ...state,
    user,
  }),
  setBalance: (balance: NonNullable<State['user']>['balance']) => {
    if (!state.user) return state;
    if (isEqual(state.user.balance, balance)) {
      return state;
    }
    return {
      ...state,
      user: {
        ...state.user,
        balance,
      },
    };
  },
  resetUser: () => ({
    ...state,
    user: null,
  }),
});

const GpanelContext = createContext<
  State & {
    authInGpanel: () => Promise<void>;
  }
>({ ...initialState, authInGpanel: () => Promise.resolve() });

export const useGpanel = () => useContext(GpanelContext);

export { GpanelProvider };
