import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache';

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: number;
  json: any;
  jsonb: any;
  numeric: number;
  smallint: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type DepositOutput = {
  __typename?: 'DepositOutput';
  blockchainId?: Maybe<Scalars['Int']>;
  depositAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type GeneralMessageOutput = {
  __typename?: 'GeneralMessageOutput';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type KycSubmitOutput = {
  __typename?: 'KYCSubmitOutput';
  status: Scalars['String'];
};

export type NftConnectedDetail = {
  __typename?: 'NFTConnectedDetail';
  blockchainId?: Maybe<Scalars['Int']>;
  collection?: Maybe<Scalars['String']>;
  gpanelId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  nftId: Scalars['Int'];
  owner?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tokenId: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

export type NftConnectedOutput = {
  __typename?: 'NFTConnectedOutput';
  own?: Maybe<Array<Maybe<NftConnectedDetail>>>;
  rented?: Maybe<Array<Maybe<NftConnectedDetail>>>;
};

export type NftTicketsClaimOptions = {
  __typename?: 'NFTTicketsClaimOptions';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
};

export type NftTicketsClaimOutput = {
  __typename?: 'NFTTicketsClaimOutput';
  claimOptions?: Maybe<Array<Maybe<NftTicketsClaimOptions>>>;
  message?: Maybe<Scalars['String']>;
  nextReleaseTime?: Maybe<Scalars['String']>;
  totalNFTs?: Maybe<Scalars['Int']>;
};

export type RateOutput = {
  __typename?: 'RateOutput';
  fee: Scalars['Float'];
  fromAmount: Scalars['Float'];
  fromCurrency: Scalars['String'];
  message: Scalars['String'];
  toAmount: Scalars['Float'];
  toCurrency: Scalars['String'];
  toMinimumAmount: Scalars['Float'];
};

export type StatusOutput = {
  __typename?: 'StatusOutput';
  blockchainId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type SwapOrderOutput = {
  __typename?: 'SwapOrderOutput';
  amountFrom: Scalars['Float'];
  amountTo?: Maybe<Scalars['Float']>;
  amountToMinimum: Scalars['Float'];
  blockchainId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  currencyFrom: Scalars['String'];
  currencyTo: Scalars['String'];
  fee: Scalars['Float'];
  gpanelUserId: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['String'];
  txid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

export type SwapOutput = {
  __typename?: 'SwapOutput';
  message: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** Test table */
export type Test = {
  __typename?: 'Test';
  id: Scalars['Int'];
  text: Scalars['String'];
};

/** aggregated selection of "Test" */
export type Test_Aggregate = {
  __typename?: 'Test_aggregate';
  aggregate?: Maybe<Test_Aggregate_Fields>;
  nodes: Array<Test>;
};

/** aggregate fields of "Test" */
export type Test_Aggregate_Fields = {
  __typename?: 'Test_aggregate_fields';
  avg?: Maybe<Test_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Test_Max_Fields>;
  min?: Maybe<Test_Min_Fields>;
  stddev?: Maybe<Test_Stddev_Fields>;
  stddev_pop?: Maybe<Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Test_Stddev_Samp_Fields>;
  sum?: Maybe<Test_Sum_Fields>;
  var_pop?: Maybe<Test_Var_Pop_Fields>;
  var_samp?: Maybe<Test_Var_Samp_Fields>;
  variance?: Maybe<Test_Variance_Fields>;
};

/** aggregate fields of "Test" */
export type Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Test_Avg_Fields = {
  __typename?: 'Test_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Test". All fields are combined with a logical 'AND'. */
export type Test_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Bool_Exp>>;
  _not?: InputMaybe<Test_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Test" */
export enum Test_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestPkey = 'Test_pkey',
}

/** input type for incrementing numeric columns in table "Test" */
export type Test_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Test" */
export type Test_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Test_Max_Fields = {
  __typename?: 'Test_max_fields';
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Test_Min_Fields = {
  __typename?: 'Test_min_fields';
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Test" */
export type Test_Mutation_Response = {
  __typename?: 'Test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test>;
};

/** on_conflict condition type for table "Test" */
export type Test_On_Conflict = {
  constraint: Test_Constraint;
  update_columns?: Array<Test_Update_Column>;
  where?: InputMaybe<Test_Bool_Exp>;
};

/** Ordering options when selecting data from "Test". */
export type Test_Order_By = {
  id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Test */
export type Test_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Test" */
export enum Test_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
}

/** input type for updating data in table "Test" */
export type Test_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Test_Stddev_Fields = {
  __typename?: 'Test_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Test_Stddev_Pop_Fields = {
  __typename?: 'Test_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Test_Stddev_Samp_Fields = {
  __typename?: 'Test_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Test" */
export type Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Test_Sum_Fields = {
  __typename?: 'Test_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Test" */
export enum Test_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
}

export type Test_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Test_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Test_Set_Input>;
  where: Test_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Test_Var_Pop_Fields = {
  __typename?: 'Test_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Test_Var_Samp_Fields = {
  __typename?: 'Test_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Test_Variance_Fields = {
  __typename?: 'Test_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type UserOutput = {
  __typename?: 'UserOutput';
  agent?: Maybe<AgentUserOutput>;
  avatar?: Maybe<AvatarOutput>;
  createdAt: Scalars['String'];
  depositAddress?: Maybe<Array<Maybe<DepositAddressOutput>>>;
  ethAddress: Scalars['String'];
  gpanelId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  kyc?: Maybe<KycOutput>;
  kycAllowed?: Maybe<Scalars['String']>;
  kycPending?: Maybe<Scalars['String']>;
  kycStatus?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  pokerBusd?: Maybe<Scalars['String']>;
  pokerInp?: Maybe<Scalars['String']>;
  pokerName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  staking?: Maybe<StakingOutput>;
  telegramName?: Maybe<Scalars['String']>;
};

export type AgentFindUserOutput = {
  __typename?: 'agentFindUserOutput';
  avatar?: Maybe<Scalars['String']>;
  gPanelUserId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type AgentGetUsersOutput = {
  __typename?: 'agentGetUsersOutput';
  agentFrom?: Maybe<Array<Maybe<AgentUserItemOutput>>>;
  agentOf?: Maybe<Array<Maybe<AgentUserItemOutput>>>;
};

export type AgentUserItemOutput = {
  __typename?: 'agentUserItemOutput';
  avatar?: Maybe<Scalars['String']>;
  gPanelUserId: Scalars['String'];
  id: Scalars['Int'];
  username: Scalars['String'];
};

export type AgentUserOutput = {
  __typename?: 'agentUserOutput';
  hasAgent?: Maybe<Scalars['Boolean']>;
  isAgent?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "anno_gpanel" */
export type Anno_Gpanel = {
  __typename?: 'anno_gpanel';
  access_token?: Maybe<Scalars['String']>;
  code_token?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  refresh_token?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['String'];
};

/** aggregated selection of "anno_gpanel" */
export type Anno_Gpanel_Aggregate = {
  __typename?: 'anno_gpanel_aggregate';
  aggregate?: Maybe<Anno_Gpanel_Aggregate_Fields>;
  nodes: Array<Anno_Gpanel>;
};

/** aggregate fields of "anno_gpanel" */
export type Anno_Gpanel_Aggregate_Fields = {
  __typename?: 'anno_gpanel_aggregate_fields';
  avg?: Maybe<Anno_Gpanel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Anno_Gpanel_Max_Fields>;
  min?: Maybe<Anno_Gpanel_Min_Fields>;
  stddev?: Maybe<Anno_Gpanel_Stddev_Fields>;
  stddev_pop?: Maybe<Anno_Gpanel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Anno_Gpanel_Stddev_Samp_Fields>;
  sum?: Maybe<Anno_Gpanel_Sum_Fields>;
  var_pop?: Maybe<Anno_Gpanel_Var_Pop_Fields>;
  var_samp?: Maybe<Anno_Gpanel_Var_Samp_Fields>;
  variance?: Maybe<Anno_Gpanel_Variance_Fields>;
};

/** aggregate fields of "anno_gpanel" */
export type Anno_Gpanel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Anno_Gpanel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Anno_Gpanel_Avg_Fields = {
  __typename?: 'anno_gpanel_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "anno_gpanel". All fields are combined with a logical 'AND'. */
export type Anno_Gpanel_Bool_Exp = {
  _and?: InputMaybe<Array<Anno_Gpanel_Bool_Exp>>;
  _not?: InputMaybe<Anno_Gpanel_Bool_Exp>;
  _or?: InputMaybe<Array<Anno_Gpanel_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  code_token?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uuid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "anno_gpanel" */
export enum Anno_Gpanel_Constraint {
  /** unique or primary key constraint on columns "id" */
  AnnoGpanelPkey = 'anno_gpanel_pkey',
}

/** input type for incrementing numeric columns in table "anno_gpanel" */
export type Anno_Gpanel_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "anno_gpanel" */
export type Anno_Gpanel_Insert_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  code_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Anno_Gpanel_Max_Fields = {
  __typename?: 'anno_gpanel_max_fields';
  access_token?: Maybe<Scalars['String']>;
  code_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Anno_Gpanel_Min_Fields = {
  __typename?: 'anno_gpanel_min_fields';
  access_token?: Maybe<Scalars['String']>;
  code_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "anno_gpanel" */
export type Anno_Gpanel_Mutation_Response = {
  __typename?: 'anno_gpanel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Anno_Gpanel>;
};

/** on_conflict condition type for table "anno_gpanel" */
export type Anno_Gpanel_On_Conflict = {
  constraint: Anno_Gpanel_Constraint;
  update_columns?: Array<Anno_Gpanel_Update_Column>;
  where?: InputMaybe<Anno_Gpanel_Bool_Exp>;
};

/** Ordering options when selecting data from "anno_gpanel". */
export type Anno_Gpanel_Order_By = {
  access_token?: InputMaybe<Order_By>;
  code_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: anno_gpanel */
export type Anno_Gpanel_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "anno_gpanel" */
export enum Anno_Gpanel_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  CodeToken = 'code_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "anno_gpanel" */
export type Anno_Gpanel_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  code_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Anno_Gpanel_Stddev_Fields = {
  __typename?: 'anno_gpanel_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Anno_Gpanel_Stddev_Pop_Fields = {
  __typename?: 'anno_gpanel_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Anno_Gpanel_Stddev_Samp_Fields = {
  __typename?: 'anno_gpanel_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "anno_gpanel" */
export type Anno_Gpanel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Anno_Gpanel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Anno_Gpanel_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  code_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Anno_Gpanel_Sum_Fields = {
  __typename?: 'anno_gpanel_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "anno_gpanel" */
export enum Anno_Gpanel_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  CodeToken = 'code_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

export type Anno_Gpanel_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Anno_Gpanel_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Anno_Gpanel_Set_Input>;
  where: Anno_Gpanel_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Anno_Gpanel_Var_Pop_Fields = {
  __typename?: 'anno_gpanel_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Anno_Gpanel_Var_Samp_Fields = {
  __typename?: 'anno_gpanel_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Anno_Gpanel_Variance_Fields = {
  __typename?: 'anno_gpanel_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type AuthLoginOutput = {
  __typename?: 'authLoginOutput';
  access_token?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  eth_address?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_id?: Maybe<Scalars['String']>;
};

export type AuthSignatureOutput = {
  __typename?: 'authSignatureOutput';
  message: Scalars['String'];
  nonce: Scalars['String'];
};

export type AvatarOutput = {
  __typename?: 'avatarOutput';
  collection?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "blockchain" */
export type Blockchain = {
  __typename?: 'blockchain';
  apiKey?: Maybe<Scalars['String']>;
  apiUrl?: Maybe<Scalars['String']>;
  chainId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  erc721Active: Scalars['Boolean'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inpActive: Scalars['Boolean'];
  inpAddress?: Maybe<Scalars['String']>;
  inpFeeAddress?: Maybe<Scalars['String']>;
  inpVaultAddress?: Maybe<Scalars['String']>;
  lp_address?: Maybe<Scalars['String']>;
  lp_stablecoin_id?: Maybe<Scalars['Int']>;
  marketplaceAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nativeCurrency: Scalars['jsonb'];
  nftCardAddress?: Maybe<Scalars['String']>;
  /** An array relationship */
  pending_txes: Array<Pending_Tx>;
  /** An aggregate relationship */
  pending_txes_aggregate: Pending_Tx_Aggregate;
  rpc: Scalars['jsonb'];
  rpcPublic: Scalars['jsonb'];
  scanUrl: Scalars['String'];
  /** An array relationship */
  stablecoins: Array<Stablecoins>;
  /** An aggregate relationship */
  stablecoins_aggregate: Stablecoins_Aggregate;
  swap_pair_address?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  webActive: Scalars['Boolean'];
};

/** columns and relationships of "blockchain" */
export type BlockchainNativeCurrencyArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "blockchain" */
export type BlockchainPending_TxesArgs = {
  distinct_on?: InputMaybe<Array<Pending_Tx_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pending_Tx_Order_By>>;
  where?: InputMaybe<Pending_Tx_Bool_Exp>;
};

/** columns and relationships of "blockchain" */
export type BlockchainPending_Txes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pending_Tx_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pending_Tx_Order_By>>;
  where?: InputMaybe<Pending_Tx_Bool_Exp>;
};

/** columns and relationships of "blockchain" */
export type BlockchainRpcArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "blockchain" */
export type BlockchainRpcPublicArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "blockchain" */
export type BlockchainStablecoinsArgs = {
  distinct_on?: InputMaybe<Array<Stablecoins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stablecoins_Order_By>>;
  where?: InputMaybe<Stablecoins_Bool_Exp>;
};

/** columns and relationships of "blockchain" */
export type BlockchainStablecoins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stablecoins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stablecoins_Order_By>>;
  where?: InputMaybe<Stablecoins_Bool_Exp>;
};

/** aggregated selection of "blockchain" */
export type Blockchain_Aggregate = {
  __typename?: 'blockchain_aggregate';
  aggregate?: Maybe<Blockchain_Aggregate_Fields>;
  nodes: Array<Blockchain>;
};

/** aggregate fields of "blockchain" */
export type Blockchain_Aggregate_Fields = {
  __typename?: 'blockchain_aggregate_fields';
  avg?: Maybe<Blockchain_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Blockchain_Max_Fields>;
  min?: Maybe<Blockchain_Min_Fields>;
  stddev?: Maybe<Blockchain_Stddev_Fields>;
  stddev_pop?: Maybe<Blockchain_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blockchain_Stddev_Samp_Fields>;
  sum?: Maybe<Blockchain_Sum_Fields>;
  var_pop?: Maybe<Blockchain_Var_Pop_Fields>;
  var_samp?: Maybe<Blockchain_Var_Samp_Fields>;
  variance?: Maybe<Blockchain_Variance_Fields>;
};

/** aggregate fields of "blockchain" */
export type Blockchain_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blockchain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Blockchain_Append_Input = {
  nativeCurrency?: InputMaybe<Scalars['jsonb']>;
  rpc?: InputMaybe<Scalars['jsonb']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Blockchain_Avg_Fields = {
  __typename?: 'blockchain_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lp_stablecoin_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "blockchain". All fields are combined with a logical 'AND'. */
export type Blockchain_Bool_Exp = {
  _and?: InputMaybe<Array<Blockchain_Bool_Exp>>;
  _not?: InputMaybe<Blockchain_Bool_Exp>;
  _or?: InputMaybe<Array<Blockchain_Bool_Exp>>;
  apiKey?: InputMaybe<String_Comparison_Exp>;
  apiUrl?: InputMaybe<String_Comparison_Exp>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  erc721Active?: InputMaybe<Boolean_Comparison_Exp>;
  iconUrl?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inpActive?: InputMaybe<Boolean_Comparison_Exp>;
  inpAddress?: InputMaybe<String_Comparison_Exp>;
  inpFeeAddress?: InputMaybe<String_Comparison_Exp>;
  inpVaultAddress?: InputMaybe<String_Comparison_Exp>;
  lp_address?: InputMaybe<String_Comparison_Exp>;
  lp_stablecoin_id?: InputMaybe<Int_Comparison_Exp>;
  marketplaceAddress?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nativeCurrency?: InputMaybe<Jsonb_Comparison_Exp>;
  nftCardAddress?: InputMaybe<String_Comparison_Exp>;
  pending_txes?: InputMaybe<Pending_Tx_Bool_Exp>;
  pending_txes_aggregate?: InputMaybe<Pending_Tx_Aggregate_Bool_Exp>;
  rpc?: InputMaybe<Jsonb_Comparison_Exp>;
  rpcPublic?: InputMaybe<Jsonb_Comparison_Exp>;
  scanUrl?: InputMaybe<String_Comparison_Exp>;
  stablecoins?: InputMaybe<Stablecoins_Bool_Exp>;
  stablecoins_aggregate?: InputMaybe<Stablecoins_Aggregate_Bool_Exp>;
  swap_pair_address?: InputMaybe<String_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  webActive?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "blockchain" */
export enum Blockchain_Constraint {
  /** unique or primary key constraint on columns "id" */
  BlockchainsPkey = 'blockchains_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Blockchain_Delete_At_Path_Input = {
  nativeCurrency?: InputMaybe<Array<Scalars['String']>>;
  rpc?: InputMaybe<Array<Scalars['String']>>;
  rpcPublic?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Blockchain_Delete_Elem_Input = {
  nativeCurrency?: InputMaybe<Scalars['Int']>;
  rpc?: InputMaybe<Scalars['Int']>;
  rpcPublic?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Blockchain_Delete_Key_Input = {
  nativeCurrency?: InputMaybe<Scalars['String']>;
  rpc?: InputMaybe<Scalars['String']>;
  rpcPublic?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "blockchain" */
export type Blockchain_Inc_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lp_stablecoin_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "blockchain" */
export type Blockchain_Insert_Input = {
  apiKey?: InputMaybe<Scalars['String']>;
  apiUrl?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  erc721Active?: InputMaybe<Scalars['Boolean']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inpActive?: InputMaybe<Scalars['Boolean']>;
  inpAddress?: InputMaybe<Scalars['String']>;
  inpFeeAddress?: InputMaybe<Scalars['String']>;
  inpVaultAddress?: InputMaybe<Scalars['String']>;
  lp_address?: InputMaybe<Scalars['String']>;
  lp_stablecoin_id?: InputMaybe<Scalars['Int']>;
  marketplaceAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nativeCurrency?: InputMaybe<Scalars['jsonb']>;
  nftCardAddress?: InputMaybe<Scalars['String']>;
  pending_txes?: InputMaybe<Pending_Tx_Arr_Rel_Insert_Input>;
  rpc?: InputMaybe<Scalars['jsonb']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']>;
  scanUrl?: InputMaybe<Scalars['String']>;
  stablecoins?: InputMaybe<Stablecoins_Arr_Rel_Insert_Input>;
  swap_pair_address?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  webActive?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "blockchain_main_deposit" */
export type Blockchain_Main_Deposit = {
  __typename?: 'blockchain_main_deposit';
  address: Scalars['String'];
  blockchain_id: Scalars['Int'];
  forwarderfactory?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  salt?: Maybe<Scalars['String']>;
};

/** aggregated selection of "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_Aggregate = {
  __typename?: 'blockchain_main_deposit_aggregate';
  aggregate?: Maybe<Blockchain_Main_Deposit_Aggregate_Fields>;
  nodes: Array<Blockchain_Main_Deposit>;
};

/** aggregate fields of "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_Aggregate_Fields = {
  __typename?: 'blockchain_main_deposit_aggregate_fields';
  avg?: Maybe<Blockchain_Main_Deposit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Blockchain_Main_Deposit_Max_Fields>;
  min?: Maybe<Blockchain_Main_Deposit_Min_Fields>;
  stddev?: Maybe<Blockchain_Main_Deposit_Stddev_Fields>;
  stddev_pop?: Maybe<Blockchain_Main_Deposit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blockchain_Main_Deposit_Stddev_Samp_Fields>;
  sum?: Maybe<Blockchain_Main_Deposit_Sum_Fields>;
  var_pop?: Maybe<Blockchain_Main_Deposit_Var_Pop_Fields>;
  var_samp?: Maybe<Blockchain_Main_Deposit_Var_Samp_Fields>;
  variance?: Maybe<Blockchain_Main_Deposit_Variance_Fields>;
};

/** aggregate fields of "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blockchain_Main_Deposit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Blockchain_Main_Deposit_Avg_Fields = {
  __typename?: 'blockchain_main_deposit_avg_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "blockchain_main_deposit". All fields are combined with a logical 'AND'. */
export type Blockchain_Main_Deposit_Bool_Exp = {
  _and?: InputMaybe<Array<Blockchain_Main_Deposit_Bool_Exp>>;
  _not?: InputMaybe<Blockchain_Main_Deposit_Bool_Exp>;
  _or?: InputMaybe<Array<Blockchain_Main_Deposit_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  forwarderfactory?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "blockchain_main_deposit" */
export enum Blockchain_Main_Deposit_Constraint {
  /** unique or primary key constraint on columns "id" */
  BlockchainMainDepositPkey = 'blockchain_main_deposit_pkey',
}

/** input type for incrementing numeric columns in table "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_Inc_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  forwarderfactory?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  salt?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Blockchain_Main_Deposit_Max_Fields = {
  __typename?: 'blockchain_main_deposit_max_fields';
  address?: Maybe<Scalars['String']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  forwarderfactory?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  salt?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Blockchain_Main_Deposit_Min_Fields = {
  __typename?: 'blockchain_main_deposit_min_fields';
  address?: Maybe<Scalars['String']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  forwarderfactory?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  salt?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_Mutation_Response = {
  __typename?: 'blockchain_main_deposit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blockchain_Main_Deposit>;
};

/** on_conflict condition type for table "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_On_Conflict = {
  constraint: Blockchain_Main_Deposit_Constraint;
  update_columns?: Array<Blockchain_Main_Deposit_Update_Column>;
  where?: InputMaybe<Blockchain_Main_Deposit_Bool_Exp>;
};

/** Ordering options when selecting data from "blockchain_main_deposit". */
export type Blockchain_Main_Deposit_Order_By = {
  address?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  forwarderfactory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blockchain_main_deposit */
export type Blockchain_Main_Deposit_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "blockchain_main_deposit" */
export enum Blockchain_Main_Deposit_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  Forwarderfactory = 'forwarderfactory',
  /** column name */
  Id = 'id',
  /** column name */
  Salt = 'salt',
}

/** input type for updating data in table "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  forwarderfactory?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  salt?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Blockchain_Main_Deposit_Stddev_Fields = {
  __typename?: 'blockchain_main_deposit_stddev_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Blockchain_Main_Deposit_Stddev_Pop_Fields = {
  __typename?: 'blockchain_main_deposit_stddev_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Blockchain_Main_Deposit_Stddev_Samp_Fields = {
  __typename?: 'blockchain_main_deposit_stddev_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "blockchain_main_deposit" */
export type Blockchain_Main_Deposit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blockchain_Main_Deposit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Blockchain_Main_Deposit_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  forwarderfactory?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  salt?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Blockchain_Main_Deposit_Sum_Fields = {
  __typename?: 'blockchain_main_deposit_sum_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "blockchain_main_deposit" */
export enum Blockchain_Main_Deposit_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  Forwarderfactory = 'forwarderfactory',
  /** column name */
  Id = 'id',
  /** column name */
  Salt = 'salt',
}

export type Blockchain_Main_Deposit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Blockchain_Main_Deposit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Blockchain_Main_Deposit_Set_Input>;
  where: Blockchain_Main_Deposit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Blockchain_Main_Deposit_Var_Pop_Fields = {
  __typename?: 'blockchain_main_deposit_var_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Blockchain_Main_Deposit_Var_Samp_Fields = {
  __typename?: 'blockchain_main_deposit_var_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Blockchain_Main_Deposit_Variance_Fields = {
  __typename?: 'blockchain_main_deposit_variance_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Blockchain_Max_Fields = {
  __typename?: 'blockchain_max_fields';
  apiKey?: Maybe<Scalars['String']>;
  apiUrl?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inpAddress?: Maybe<Scalars['String']>;
  inpFeeAddress?: Maybe<Scalars['String']>;
  inpVaultAddress?: Maybe<Scalars['String']>;
  lp_address?: Maybe<Scalars['String']>;
  lp_stablecoin_id?: Maybe<Scalars['Int']>;
  marketplaceAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nftCardAddress?: Maybe<Scalars['String']>;
  scanUrl?: Maybe<Scalars['String']>;
  swap_pair_address?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Blockchain_Min_Fields = {
  __typename?: 'blockchain_min_fields';
  apiKey?: Maybe<Scalars['String']>;
  apiUrl?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inpAddress?: Maybe<Scalars['String']>;
  inpFeeAddress?: Maybe<Scalars['String']>;
  inpVaultAddress?: Maybe<Scalars['String']>;
  lp_address?: Maybe<Scalars['String']>;
  lp_stablecoin_id?: Maybe<Scalars['Int']>;
  marketplaceAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nftCardAddress?: Maybe<Scalars['String']>;
  scanUrl?: Maybe<Scalars['String']>;
  swap_pair_address?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "blockchain" */
export type Blockchain_Mutation_Response = {
  __typename?: 'blockchain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blockchain>;
};

/** input type for inserting object relation for remote table "blockchain" */
export type Blockchain_Obj_Rel_Insert_Input = {
  data: Blockchain_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Blockchain_On_Conflict>;
};

/** on_conflict condition type for table "blockchain" */
export type Blockchain_On_Conflict = {
  constraint: Blockchain_Constraint;
  update_columns?: Array<Blockchain_Update_Column>;
  where?: InputMaybe<Blockchain_Bool_Exp>;
};

/** Ordering options when selecting data from "blockchain". */
export type Blockchain_Order_By = {
  apiKey?: InputMaybe<Order_By>;
  apiUrl?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  erc721Active?: InputMaybe<Order_By>;
  iconUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inpActive?: InputMaybe<Order_By>;
  inpAddress?: InputMaybe<Order_By>;
  inpFeeAddress?: InputMaybe<Order_By>;
  inpVaultAddress?: InputMaybe<Order_By>;
  lp_address?: InputMaybe<Order_By>;
  lp_stablecoin_id?: InputMaybe<Order_By>;
  marketplaceAddress?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nativeCurrency?: InputMaybe<Order_By>;
  nftCardAddress?: InputMaybe<Order_By>;
  pending_txes_aggregate?: InputMaybe<Pending_Tx_Aggregate_Order_By>;
  rpc?: InputMaybe<Order_By>;
  rpcPublic?: InputMaybe<Order_By>;
  scanUrl?: InputMaybe<Order_By>;
  stablecoins_aggregate?: InputMaybe<Stablecoins_Aggregate_Order_By>;
  swap_pair_address?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  webActive?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blockchain */
export type Blockchain_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Blockchain_Prepend_Input = {
  nativeCurrency?: InputMaybe<Scalars['jsonb']>;
  rpc?: InputMaybe<Scalars['jsonb']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "blockchain" */
export enum Blockchain_Select_Column {
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  ApiUrl = 'apiUrl',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Erc721Active = 'erc721Active',
  /** column name */
  IconUrl = 'iconUrl',
  /** column name */
  Id = 'id',
  /** column name */
  InpActive = 'inpActive',
  /** column name */
  InpAddress = 'inpAddress',
  /** column name */
  InpFeeAddress = 'inpFeeAddress',
  /** column name */
  InpVaultAddress = 'inpVaultAddress',
  /** column name */
  LpAddress = 'lp_address',
  /** column name */
  LpStablecoinId = 'lp_stablecoin_id',
  /** column name */
  MarketplaceAddress = 'marketplaceAddress',
  /** column name */
  Name = 'name',
  /** column name */
  NativeCurrency = 'nativeCurrency',
  /** column name */
  NftCardAddress = 'nftCardAddress',
  /** column name */
  Rpc = 'rpc',
  /** column name */
  RpcPublic = 'rpcPublic',
  /** column name */
  ScanUrl = 'scanUrl',
  /** column name */
  SwapPairAddress = 'swap_pair_address',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebActive = 'webActive',
}

/** input type for updating data in table "blockchain" */
export type Blockchain_Set_Input = {
  apiKey?: InputMaybe<Scalars['String']>;
  apiUrl?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  erc721Active?: InputMaybe<Scalars['Boolean']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inpActive?: InputMaybe<Scalars['Boolean']>;
  inpAddress?: InputMaybe<Scalars['String']>;
  inpFeeAddress?: InputMaybe<Scalars['String']>;
  inpVaultAddress?: InputMaybe<Scalars['String']>;
  lp_address?: InputMaybe<Scalars['String']>;
  lp_stablecoin_id?: InputMaybe<Scalars['Int']>;
  marketplaceAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nativeCurrency?: InputMaybe<Scalars['jsonb']>;
  nftCardAddress?: InputMaybe<Scalars['String']>;
  rpc?: InputMaybe<Scalars['jsonb']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']>;
  scanUrl?: InputMaybe<Scalars['String']>;
  swap_pair_address?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  webActive?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Blockchain_Stddev_Fields = {
  __typename?: 'blockchain_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lp_stablecoin_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Blockchain_Stddev_Pop_Fields = {
  __typename?: 'blockchain_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lp_stablecoin_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Blockchain_Stddev_Samp_Fields = {
  __typename?: 'blockchain_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lp_stablecoin_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "blockchain" */
export type Blockchain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blockchain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Blockchain_Stream_Cursor_Value_Input = {
  apiKey?: InputMaybe<Scalars['String']>;
  apiUrl?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  erc721Active?: InputMaybe<Scalars['Boolean']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inpActive?: InputMaybe<Scalars['Boolean']>;
  inpAddress?: InputMaybe<Scalars['String']>;
  inpFeeAddress?: InputMaybe<Scalars['String']>;
  inpVaultAddress?: InputMaybe<Scalars['String']>;
  lp_address?: InputMaybe<Scalars['String']>;
  lp_stablecoin_id?: InputMaybe<Scalars['Int']>;
  marketplaceAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nativeCurrency?: InputMaybe<Scalars['jsonb']>;
  nftCardAddress?: InputMaybe<Scalars['String']>;
  rpc?: InputMaybe<Scalars['jsonb']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']>;
  scanUrl?: InputMaybe<Scalars['String']>;
  swap_pair_address?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  webActive?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Blockchain_Sum_Fields = {
  __typename?: 'blockchain_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lp_stablecoin_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "blockchain" */
export enum Blockchain_Update_Column {
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  ApiUrl = 'apiUrl',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Erc721Active = 'erc721Active',
  /** column name */
  IconUrl = 'iconUrl',
  /** column name */
  Id = 'id',
  /** column name */
  InpActive = 'inpActive',
  /** column name */
  InpAddress = 'inpAddress',
  /** column name */
  InpFeeAddress = 'inpFeeAddress',
  /** column name */
  InpVaultAddress = 'inpVaultAddress',
  /** column name */
  LpAddress = 'lp_address',
  /** column name */
  LpStablecoinId = 'lp_stablecoin_id',
  /** column name */
  MarketplaceAddress = 'marketplaceAddress',
  /** column name */
  Name = 'name',
  /** column name */
  NativeCurrency = 'nativeCurrency',
  /** column name */
  NftCardAddress = 'nftCardAddress',
  /** column name */
  Rpc = 'rpc',
  /** column name */
  RpcPublic = 'rpcPublic',
  /** column name */
  ScanUrl = 'scanUrl',
  /** column name */
  SwapPairAddress = 'swap_pair_address',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebActive = 'webActive',
}

export type Blockchain_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Blockchain_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Blockchain_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Blockchain_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Blockchain_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Blockchain_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Blockchain_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Blockchain_Set_Input>;
  where: Blockchain_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Blockchain_Var_Pop_Fields = {
  __typename?: 'blockchain_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lp_stablecoin_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Blockchain_Var_Samp_Fields = {
  __typename?: 'blockchain_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lp_stablecoin_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Blockchain_Variance_Fields = {
  __typename?: 'blockchain_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lp_stablecoin_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "burn_profit" */
export type Burn_Profit = {
  __typename?: 'burn_profit';
  amount_rake: Scalars['numeric'];
  amount_tournament: Scalars['numeric'];
  burn_date?: Maybe<Scalars['timestamptz']>;
  burn_tx_log?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ended: Scalars['timestamptz'];
  id: Scalars['Int'];
  money_type: Scalars['String'];
  started: Scalars['timestamptz'];
  status: Scalars['String'];
  total_burn: Scalars['numeric'];
  total_burn_inp: Scalars['numeric'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "burn_profit" */
export type Burn_Profit_Aggregate = {
  __typename?: 'burn_profit_aggregate';
  aggregate?: Maybe<Burn_Profit_Aggregate_Fields>;
  nodes: Array<Burn_Profit>;
};

/** aggregate fields of "burn_profit" */
export type Burn_Profit_Aggregate_Fields = {
  __typename?: 'burn_profit_aggregate_fields';
  avg?: Maybe<Burn_Profit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Burn_Profit_Max_Fields>;
  min?: Maybe<Burn_Profit_Min_Fields>;
  stddev?: Maybe<Burn_Profit_Stddev_Fields>;
  stddev_pop?: Maybe<Burn_Profit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Burn_Profit_Stddev_Samp_Fields>;
  sum?: Maybe<Burn_Profit_Sum_Fields>;
  var_pop?: Maybe<Burn_Profit_Var_Pop_Fields>;
  var_samp?: Maybe<Burn_Profit_Var_Samp_Fields>;
  variance?: Maybe<Burn_Profit_Variance_Fields>;
};

/** aggregate fields of "burn_profit" */
export type Burn_Profit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Burn_Profit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Burn_Profit_Avg_Fields = {
  __typename?: 'burn_profit_avg_fields';
  amount_rake?: Maybe<Scalars['Float']>;
  amount_tournament?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_burn?: Maybe<Scalars['Float']>;
  total_burn_inp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "burn_profit". All fields are combined with a logical 'AND'. */
export type Burn_Profit_Bool_Exp = {
  _and?: InputMaybe<Array<Burn_Profit_Bool_Exp>>;
  _not?: InputMaybe<Burn_Profit_Bool_Exp>;
  _or?: InputMaybe<Array<Burn_Profit_Bool_Exp>>;
  amount_rake?: InputMaybe<Numeric_Comparison_Exp>;
  amount_tournament?: InputMaybe<Numeric_Comparison_Exp>;
  burn_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  burn_tx_log?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ended?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  money_type?: InputMaybe<String_Comparison_Exp>;
  started?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  total_burn?: InputMaybe<Numeric_Comparison_Exp>;
  total_burn_inp?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "burn_profit" */
export enum Burn_Profit_Constraint {
  /** unique or primary key constraint on columns "id" */
  BurnProfitPkey = 'burn_profit_pkey',
}

/** input type for incrementing numeric columns in table "burn_profit" */
export type Burn_Profit_Inc_Input = {
  amount_rake?: InputMaybe<Scalars['numeric']>;
  amount_tournament?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  total_burn?: InputMaybe<Scalars['numeric']>;
  total_burn_inp?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "burn_profit" */
export type Burn_Profit_Insert_Input = {
  amount_rake?: InputMaybe<Scalars['numeric']>;
  amount_tournament?: InputMaybe<Scalars['numeric']>;
  burn_date?: InputMaybe<Scalars['timestamptz']>;
  burn_tx_log?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  money_type?: InputMaybe<Scalars['String']>;
  started?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  total_burn?: InputMaybe<Scalars['numeric']>;
  total_burn_inp?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Burn_Profit_Max_Fields = {
  __typename?: 'burn_profit_max_fields';
  amount_rake?: Maybe<Scalars['numeric']>;
  amount_tournament?: Maybe<Scalars['numeric']>;
  burn_date?: Maybe<Scalars['timestamptz']>;
  burn_tx_log?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ended?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  money_type?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  total_burn?: Maybe<Scalars['numeric']>;
  total_burn_inp?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Burn_Profit_Min_Fields = {
  __typename?: 'burn_profit_min_fields';
  amount_rake?: Maybe<Scalars['numeric']>;
  amount_tournament?: Maybe<Scalars['numeric']>;
  burn_date?: Maybe<Scalars['timestamptz']>;
  burn_tx_log?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ended?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  money_type?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  total_burn?: Maybe<Scalars['numeric']>;
  total_burn_inp?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "burn_profit" */
export type Burn_Profit_Mutation_Response = {
  __typename?: 'burn_profit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Burn_Profit>;
};

/** on_conflict condition type for table "burn_profit" */
export type Burn_Profit_On_Conflict = {
  constraint: Burn_Profit_Constraint;
  update_columns?: Array<Burn_Profit_Update_Column>;
  where?: InputMaybe<Burn_Profit_Bool_Exp>;
};

/** Ordering options when selecting data from "burn_profit". */
export type Burn_Profit_Order_By = {
  amount_rake?: InputMaybe<Order_By>;
  amount_tournament?: InputMaybe<Order_By>;
  burn_date?: InputMaybe<Order_By>;
  burn_tx_log?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ended?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  money_type?: InputMaybe<Order_By>;
  started?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total_burn?: InputMaybe<Order_By>;
  total_burn_inp?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: burn_profit */
export type Burn_Profit_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "burn_profit" */
export enum Burn_Profit_Select_Column {
  /** column name */
  AmountRake = 'amount_rake',
  /** column name */
  AmountTournament = 'amount_tournament',
  /** column name */
  BurnDate = 'burn_date',
  /** column name */
  BurnTxLog = 'burn_tx_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Ended = 'ended',
  /** column name */
  Id = 'id',
  /** column name */
  MoneyType = 'money_type',
  /** column name */
  Started = 'started',
  /** column name */
  Status = 'status',
  /** column name */
  TotalBurn = 'total_burn',
  /** column name */
  TotalBurnInp = 'total_burn_inp',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "burn_profit" */
export type Burn_Profit_Set_Input = {
  amount_rake?: InputMaybe<Scalars['numeric']>;
  amount_tournament?: InputMaybe<Scalars['numeric']>;
  burn_date?: InputMaybe<Scalars['timestamptz']>;
  burn_tx_log?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  money_type?: InputMaybe<Scalars['String']>;
  started?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  total_burn?: InputMaybe<Scalars['numeric']>;
  total_burn_inp?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Burn_Profit_Stddev_Fields = {
  __typename?: 'burn_profit_stddev_fields';
  amount_rake?: Maybe<Scalars['Float']>;
  amount_tournament?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_burn?: Maybe<Scalars['Float']>;
  total_burn_inp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Burn_Profit_Stddev_Pop_Fields = {
  __typename?: 'burn_profit_stddev_pop_fields';
  amount_rake?: Maybe<Scalars['Float']>;
  amount_tournament?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_burn?: Maybe<Scalars['Float']>;
  total_burn_inp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Burn_Profit_Stddev_Samp_Fields = {
  __typename?: 'burn_profit_stddev_samp_fields';
  amount_rake?: Maybe<Scalars['Float']>;
  amount_tournament?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_burn?: Maybe<Scalars['Float']>;
  total_burn_inp?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "burn_profit" */
export type Burn_Profit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Burn_Profit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Burn_Profit_Stream_Cursor_Value_Input = {
  amount_rake?: InputMaybe<Scalars['numeric']>;
  amount_tournament?: InputMaybe<Scalars['numeric']>;
  burn_date?: InputMaybe<Scalars['timestamptz']>;
  burn_tx_log?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  money_type?: InputMaybe<Scalars['String']>;
  started?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  total_burn?: InputMaybe<Scalars['numeric']>;
  total_burn_inp?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Burn_Profit_Sum_Fields = {
  __typename?: 'burn_profit_sum_fields';
  amount_rake?: Maybe<Scalars['numeric']>;
  amount_tournament?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  total_burn?: Maybe<Scalars['numeric']>;
  total_burn_inp?: Maybe<Scalars['numeric']>;
};

/** update columns of table "burn_profit" */
export enum Burn_Profit_Update_Column {
  /** column name */
  AmountRake = 'amount_rake',
  /** column name */
  AmountTournament = 'amount_tournament',
  /** column name */
  BurnDate = 'burn_date',
  /** column name */
  BurnTxLog = 'burn_tx_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Ended = 'ended',
  /** column name */
  Id = 'id',
  /** column name */
  MoneyType = 'money_type',
  /** column name */
  Started = 'started',
  /** column name */
  Status = 'status',
  /** column name */
  TotalBurn = 'total_burn',
  /** column name */
  TotalBurnInp = 'total_burn_inp',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Burn_Profit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Burn_Profit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Burn_Profit_Set_Input>;
  where: Burn_Profit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Burn_Profit_Var_Pop_Fields = {
  __typename?: 'burn_profit_var_pop_fields';
  amount_rake?: Maybe<Scalars['Float']>;
  amount_tournament?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_burn?: Maybe<Scalars['Float']>;
  total_burn_inp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Burn_Profit_Var_Samp_Fields = {
  __typename?: 'burn_profit_var_samp_fields';
  amount_rake?: Maybe<Scalars['Float']>;
  amount_tournament?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_burn?: Maybe<Scalars['Float']>;
  total_burn_inp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Burn_Profit_Variance_Fields = {
  __typename?: 'burn_profit_variance_fields';
  amount_rake?: Maybe<Scalars['Float']>;
  amount_tournament?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_burn?: Maybe<Scalars['Float']>;
  total_burn_inp?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "currencies" */
export type Currencies = {
  __typename?: 'currencies';
  apy_distribution?: Maybe<Scalars['numeric']>;
  apy_supply?: Maybe<Scalars['numeric']>;
  coingecko_id?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rate_btc?: Maybe<Scalars['numeric']>;
  rate_last_update?: Maybe<Scalars['Int']>;
  rate_usd?: Maybe<Scalars['numeric']>;
  status: Scalars['String'];
  symbol: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  venus_address?: Maybe<Scalars['String']>;
  venus_borrowApy?: Maybe<Scalars['numeric']>;
  venus_json?: Maybe<Scalars['jsonb']>;
  venus_name?: Maybe<Scalars['String']>;
  venus_supplyApy?: Maybe<Scalars['numeric']>;
  venus_symbol?: Maybe<Scalars['String']>;
};

/** columns and relationships of "currencies" */
export type CurrenciesVenus_JsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "currencies" */
export type Currencies_Aggregate = {
  __typename?: 'currencies_aggregate';
  aggregate?: Maybe<Currencies_Aggregate_Fields>;
  nodes: Array<Currencies>;
};

/** aggregate fields of "currencies" */
export type Currencies_Aggregate_Fields = {
  __typename?: 'currencies_aggregate_fields';
  avg?: Maybe<Currencies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Currencies_Max_Fields>;
  min?: Maybe<Currencies_Min_Fields>;
  stddev?: Maybe<Currencies_Stddev_Fields>;
  stddev_pop?: Maybe<Currencies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Currencies_Stddev_Samp_Fields>;
  sum?: Maybe<Currencies_Sum_Fields>;
  var_pop?: Maybe<Currencies_Var_Pop_Fields>;
  var_samp?: Maybe<Currencies_Var_Samp_Fields>;
  variance?: Maybe<Currencies_Variance_Fields>;
};

/** aggregate fields of "currencies" */
export type Currencies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currencies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Currencies_Append_Input = {
  venus_json?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Currencies_Avg_Fields = {
  __typename?: 'currencies_avg_fields';
  apy_distribution?: Maybe<Scalars['Float']>;
  apy_supply?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_btc?: Maybe<Scalars['Float']>;
  rate_last_update?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
  venus_borrowApy?: Maybe<Scalars['Float']>;
  venus_supplyApy?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "currencies". All fields are combined with a logical 'AND'. */
export type Currencies_Bool_Exp = {
  _and?: InputMaybe<Array<Currencies_Bool_Exp>>;
  _not?: InputMaybe<Currencies_Bool_Exp>;
  _or?: InputMaybe<Array<Currencies_Bool_Exp>>;
  apy_distribution?: InputMaybe<Numeric_Comparison_Exp>;
  apy_supply?: InputMaybe<Numeric_Comparison_Exp>;
  coingecko_id?: InputMaybe<String_Comparison_Exp>;
  contract?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rate_btc?: InputMaybe<Numeric_Comparison_Exp>;
  rate_last_update?: InputMaybe<Int_Comparison_Exp>;
  rate_usd?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  venus_address?: InputMaybe<String_Comparison_Exp>;
  venus_borrowApy?: InputMaybe<Numeric_Comparison_Exp>;
  venus_json?: InputMaybe<Jsonb_Comparison_Exp>;
  venus_name?: InputMaybe<String_Comparison_Exp>;
  venus_supplyApy?: InputMaybe<Numeric_Comparison_Exp>;
  venus_symbol?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "currencies" */
export enum Currencies_Constraint {
  /** unique or primary key constraint on columns "id" */
  CurrenciesPkey = 'currencies_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Currencies_Delete_At_Path_Input = {
  venus_json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Currencies_Delete_Elem_Input = {
  venus_json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Currencies_Delete_Key_Input = {
  venus_json?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "currencies" */
export type Currencies_Inc_Input = {
  apy_distribution?: InputMaybe<Scalars['numeric']>;
  apy_supply?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  rate_btc?: InputMaybe<Scalars['numeric']>;
  rate_last_update?: InputMaybe<Scalars['Int']>;
  rate_usd?: InputMaybe<Scalars['numeric']>;
  venus_borrowApy?: InputMaybe<Scalars['numeric']>;
  venus_supplyApy?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "currencies" */
export type Currencies_Insert_Input = {
  apy_distribution?: InputMaybe<Scalars['numeric']>;
  apy_supply?: InputMaybe<Scalars['numeric']>;
  coingecko_id?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rate_btc?: InputMaybe<Scalars['numeric']>;
  rate_last_update?: InputMaybe<Scalars['Int']>;
  rate_usd?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  venus_address?: InputMaybe<Scalars['String']>;
  venus_borrowApy?: InputMaybe<Scalars['numeric']>;
  venus_json?: InputMaybe<Scalars['jsonb']>;
  venus_name?: InputMaybe<Scalars['String']>;
  venus_supplyApy?: InputMaybe<Scalars['numeric']>;
  venus_symbol?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Currencies_Max_Fields = {
  __typename?: 'currencies_max_fields';
  apy_distribution?: Maybe<Scalars['numeric']>;
  apy_supply?: Maybe<Scalars['numeric']>;
  coingecko_id?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rate_btc?: Maybe<Scalars['numeric']>;
  rate_last_update?: Maybe<Scalars['Int']>;
  rate_usd?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  venus_address?: Maybe<Scalars['String']>;
  venus_borrowApy?: Maybe<Scalars['numeric']>;
  venus_name?: Maybe<Scalars['String']>;
  venus_supplyApy?: Maybe<Scalars['numeric']>;
  venus_symbol?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Currencies_Min_Fields = {
  __typename?: 'currencies_min_fields';
  apy_distribution?: Maybe<Scalars['numeric']>;
  apy_supply?: Maybe<Scalars['numeric']>;
  coingecko_id?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rate_btc?: Maybe<Scalars['numeric']>;
  rate_last_update?: Maybe<Scalars['Int']>;
  rate_usd?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  venus_address?: Maybe<Scalars['String']>;
  venus_borrowApy?: Maybe<Scalars['numeric']>;
  venus_name?: Maybe<Scalars['String']>;
  venus_supplyApy?: Maybe<Scalars['numeric']>;
  venus_symbol?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "currencies" */
export type Currencies_Mutation_Response = {
  __typename?: 'currencies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currencies>;
};

/** on_conflict condition type for table "currencies" */
export type Currencies_On_Conflict = {
  constraint: Currencies_Constraint;
  update_columns?: Array<Currencies_Update_Column>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};

/** Ordering options when selecting data from "currencies". */
export type Currencies_Order_By = {
  apy_distribution?: InputMaybe<Order_By>;
  apy_supply?: InputMaybe<Order_By>;
  coingecko_id?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rate_btc?: InputMaybe<Order_By>;
  rate_last_update?: InputMaybe<Order_By>;
  rate_usd?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  venus_address?: InputMaybe<Order_By>;
  venus_borrowApy?: InputMaybe<Order_By>;
  venus_json?: InputMaybe<Order_By>;
  venus_name?: InputMaybe<Order_By>;
  venus_supplyApy?: InputMaybe<Order_By>;
  venus_symbol?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currencies */
export type Currencies_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Currencies_Prepend_Input = {
  venus_json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "currencies" */
export enum Currencies_Select_Column {
  /** column name */
  ApyDistribution = 'apy_distribution',
  /** column name */
  ApySupply = 'apy_supply',
  /** column name */
  CoingeckoId = 'coingecko_id',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  RateBtc = 'rate_btc',
  /** column name */
  RateLastUpdate = 'rate_last_update',
  /** column name */
  RateUsd = 'rate_usd',
  /** column name */
  Status = 'status',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VenusAddress = 'venus_address',
  /** column name */
  VenusBorrowApy = 'venus_borrowApy',
  /** column name */
  VenusJson = 'venus_json',
  /** column name */
  VenusName = 'venus_name',
  /** column name */
  VenusSupplyApy = 'venus_supplyApy',
  /** column name */
  VenusSymbol = 'venus_symbol',
}

/** input type for updating data in table "currencies" */
export type Currencies_Set_Input = {
  apy_distribution?: InputMaybe<Scalars['numeric']>;
  apy_supply?: InputMaybe<Scalars['numeric']>;
  coingecko_id?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rate_btc?: InputMaybe<Scalars['numeric']>;
  rate_last_update?: InputMaybe<Scalars['Int']>;
  rate_usd?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  venus_address?: InputMaybe<Scalars['String']>;
  venus_borrowApy?: InputMaybe<Scalars['numeric']>;
  venus_json?: InputMaybe<Scalars['jsonb']>;
  venus_name?: InputMaybe<Scalars['String']>;
  venus_supplyApy?: InputMaybe<Scalars['numeric']>;
  venus_symbol?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Currencies_Stddev_Fields = {
  __typename?: 'currencies_stddev_fields';
  apy_distribution?: Maybe<Scalars['Float']>;
  apy_supply?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_btc?: Maybe<Scalars['Float']>;
  rate_last_update?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
  venus_borrowApy?: Maybe<Scalars['Float']>;
  venus_supplyApy?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Currencies_Stddev_Pop_Fields = {
  __typename?: 'currencies_stddev_pop_fields';
  apy_distribution?: Maybe<Scalars['Float']>;
  apy_supply?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_btc?: Maybe<Scalars['Float']>;
  rate_last_update?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
  venus_borrowApy?: Maybe<Scalars['Float']>;
  venus_supplyApy?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Currencies_Stddev_Samp_Fields = {
  __typename?: 'currencies_stddev_samp_fields';
  apy_distribution?: Maybe<Scalars['Float']>;
  apy_supply?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_btc?: Maybe<Scalars['Float']>;
  rate_last_update?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
  venus_borrowApy?: Maybe<Scalars['Float']>;
  venus_supplyApy?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "currencies" */
export type Currencies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currencies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currencies_Stream_Cursor_Value_Input = {
  apy_distribution?: InputMaybe<Scalars['numeric']>;
  apy_supply?: InputMaybe<Scalars['numeric']>;
  coingecko_id?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rate_btc?: InputMaybe<Scalars['numeric']>;
  rate_last_update?: InputMaybe<Scalars['Int']>;
  rate_usd?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  venus_address?: InputMaybe<Scalars['String']>;
  venus_borrowApy?: InputMaybe<Scalars['numeric']>;
  venus_json?: InputMaybe<Scalars['jsonb']>;
  venus_name?: InputMaybe<Scalars['String']>;
  venus_supplyApy?: InputMaybe<Scalars['numeric']>;
  venus_symbol?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Currencies_Sum_Fields = {
  __typename?: 'currencies_sum_fields';
  apy_distribution?: Maybe<Scalars['numeric']>;
  apy_supply?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  rate_btc?: Maybe<Scalars['numeric']>;
  rate_last_update?: Maybe<Scalars['Int']>;
  rate_usd?: Maybe<Scalars['numeric']>;
  venus_borrowApy?: Maybe<Scalars['numeric']>;
  venus_supplyApy?: Maybe<Scalars['numeric']>;
};

/** update columns of table "currencies" */
export enum Currencies_Update_Column {
  /** column name */
  ApyDistribution = 'apy_distribution',
  /** column name */
  ApySupply = 'apy_supply',
  /** column name */
  CoingeckoId = 'coingecko_id',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  RateBtc = 'rate_btc',
  /** column name */
  RateLastUpdate = 'rate_last_update',
  /** column name */
  RateUsd = 'rate_usd',
  /** column name */
  Status = 'status',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VenusAddress = 'venus_address',
  /** column name */
  VenusBorrowApy = 'venus_borrowApy',
  /** column name */
  VenusJson = 'venus_json',
  /** column name */
  VenusName = 'venus_name',
  /** column name */
  VenusSupplyApy = 'venus_supplyApy',
  /** column name */
  VenusSymbol = 'venus_symbol',
}

export type Currencies_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Currencies_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Currencies_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Currencies_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Currencies_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Currencies_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Currencies_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currencies_Set_Input>;
  where: Currencies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Currencies_Var_Pop_Fields = {
  __typename?: 'currencies_var_pop_fields';
  apy_distribution?: Maybe<Scalars['Float']>;
  apy_supply?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_btc?: Maybe<Scalars['Float']>;
  rate_last_update?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
  venus_borrowApy?: Maybe<Scalars['Float']>;
  venus_supplyApy?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Currencies_Var_Samp_Fields = {
  __typename?: 'currencies_var_samp_fields';
  apy_distribution?: Maybe<Scalars['Float']>;
  apy_supply?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_btc?: Maybe<Scalars['Float']>;
  rate_last_update?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
  venus_borrowApy?: Maybe<Scalars['Float']>;
  venus_supplyApy?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Currencies_Variance_Fields = {
  __typename?: 'currencies_variance_fields';
  apy_distribution?: Maybe<Scalars['Float']>;
  apy_supply?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_btc?: Maybe<Scalars['Float']>;
  rate_last_update?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
  venus_borrowApy?: Maybe<Scalars['Float']>;
  venus_supplyApy?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "currency_history" */
export type Currency_History = {
  __typename?: 'currency_history';
  created_at: Scalars['timestamptz'];
  currency_id: Scalars['Int'];
  id: Scalars['bigint'];
  rate_usd: Scalars['numeric'];
  source?: Maybe<Scalars['String']>;
};

/** aggregated selection of "currency_history" */
export type Currency_History_Aggregate = {
  __typename?: 'currency_history_aggregate';
  aggregate?: Maybe<Currency_History_Aggregate_Fields>;
  nodes: Array<Currency_History>;
};

/** aggregate fields of "currency_history" */
export type Currency_History_Aggregate_Fields = {
  __typename?: 'currency_history_aggregate_fields';
  avg?: Maybe<Currency_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Currency_History_Max_Fields>;
  min?: Maybe<Currency_History_Min_Fields>;
  stddev?: Maybe<Currency_History_Stddev_Fields>;
  stddev_pop?: Maybe<Currency_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Currency_History_Stddev_Samp_Fields>;
  sum?: Maybe<Currency_History_Sum_Fields>;
  var_pop?: Maybe<Currency_History_Var_Pop_Fields>;
  var_samp?: Maybe<Currency_History_Var_Samp_Fields>;
  variance?: Maybe<Currency_History_Variance_Fields>;
};

/** aggregate fields of "currency_history" */
export type Currency_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Currency_History_Avg_Fields = {
  __typename?: 'currency_history_avg_fields';
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "currency_history". All fields are combined with a logical 'AND'. */
export type Currency_History_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_History_Bool_Exp>>;
  _not?: InputMaybe<Currency_History_Bool_Exp>;
  _or?: InputMaybe<Array<Currency_History_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  rate_usd?: InputMaybe<Numeric_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency_history" */
export enum Currency_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  CurrencyHistoryPkey = 'currency_history_pkey',
}

/** input type for incrementing numeric columns in table "currency_history" */
export type Currency_History_Inc_Input = {
  currency_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  rate_usd?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "currency_history" */
export type Currency_History_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  rate_usd?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Currency_History_Max_Fields = {
  __typename?: 'currency_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  rate_usd?: Maybe<Scalars['numeric']>;
  source?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Currency_History_Min_Fields = {
  __typename?: 'currency_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  rate_usd?: Maybe<Scalars['numeric']>;
  source?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "currency_history" */
export type Currency_History_Mutation_Response = {
  __typename?: 'currency_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency_History>;
};

/** on_conflict condition type for table "currency_history" */
export type Currency_History_On_Conflict = {
  constraint: Currency_History_Constraint;
  update_columns?: Array<Currency_History_Update_Column>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};

/** Ordering options when selecting data from "currency_history". */
export type Currency_History_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_usd?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currency_history */
export type Currency_History_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "currency_history" */
export enum Currency_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  Id = 'id',
  /** column name */
  RateUsd = 'rate_usd',
  /** column name */
  Source = 'source',
}

/** input type for updating data in table "currency_history" */
export type Currency_History_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  rate_usd?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Currency_History_Stddev_Fields = {
  __typename?: 'currency_history_stddev_fields';
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Currency_History_Stddev_Pop_Fields = {
  __typename?: 'currency_history_stddev_pop_fields';
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Currency_History_Stddev_Samp_Fields = {
  __typename?: 'currency_history_stddev_samp_fields';
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "currency_history" */
export type Currency_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_History_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  rate_usd?: InputMaybe<Scalars['numeric']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Currency_History_Sum_Fields = {
  __typename?: 'currency_history_sum_fields';
  currency_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  rate_usd?: Maybe<Scalars['numeric']>;
};

/** update columns of table "currency_history" */
export enum Currency_History_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  Id = 'id',
  /** column name */
  RateUsd = 'rate_usd',
  /** column name */
  Source = 'source',
}

export type Currency_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Currency_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_History_Set_Input>;
  where: Currency_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Currency_History_Var_Pop_Fields = {
  __typename?: 'currency_history_var_pop_fields';
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Currency_History_Var_Samp_Fields = {
  __typename?: 'currency_history_var_samp_fields';
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Currency_History_Variance_Fields = {
  __typename?: 'currency_history_variance_fields';
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_usd?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

export type DepositAddressOutput = {
  __typename?: 'depositAddressOutput';
  address: Scalars['String'];
  blockchainId?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

/** columns and relationships of "erc721_addresses" */
export type Erc721_Addresses = {
  __typename?: 'erc721_addresses';
  blockchain_id: Scalars['Int'];
  contract_address: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  read: Scalars['Boolean'];
  token_name: Scalars['String'];
  token_symbol: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "erc721_addresses" */
export type Erc721_Addresses_Aggregate = {
  __typename?: 'erc721_addresses_aggregate';
  aggregate?: Maybe<Erc721_Addresses_Aggregate_Fields>;
  nodes: Array<Erc721_Addresses>;
};

/** aggregate fields of "erc721_addresses" */
export type Erc721_Addresses_Aggregate_Fields = {
  __typename?: 'erc721_addresses_aggregate_fields';
  avg?: Maybe<Erc721_Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Erc721_Addresses_Max_Fields>;
  min?: Maybe<Erc721_Addresses_Min_Fields>;
  stddev?: Maybe<Erc721_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Erc721_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Erc721_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Erc721_Addresses_Sum_Fields>;
  var_pop?: Maybe<Erc721_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Erc721_Addresses_Var_Samp_Fields>;
  variance?: Maybe<Erc721_Addresses_Variance_Fields>;
};

/** aggregate fields of "erc721_addresses" */
export type Erc721_Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Erc721_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Erc721_Addresses_Avg_Fields = {
  __typename?: 'erc721_addresses_avg_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "erc721_addresses". All fields are combined with a logical 'AND'. */
export type Erc721_Addresses_Bool_Exp = {
  _and?: InputMaybe<Array<Erc721_Addresses_Bool_Exp>>;
  _not?: InputMaybe<Erc721_Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Erc721_Addresses_Bool_Exp>>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  contract_address?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  read?: InputMaybe<Boolean_Comparison_Exp>;
  token_name?: InputMaybe<String_Comparison_Exp>;
  token_symbol?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "erc721_addresses" */
export enum Erc721_Addresses_Constraint {
  /** unique or primary key constraint on columns "id" */
  Erc721AddressesPkey = 'erc721_addresses_pkey',
}

/** input type for incrementing numeric columns in table "erc721_addresses" */
export type Erc721_Addresses_Inc_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "erc721_addresses" */
export type Erc721_Addresses_Insert_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  contract_address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  read?: InputMaybe<Scalars['Boolean']>;
  token_name?: InputMaybe<Scalars['String']>;
  token_symbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Erc721_Addresses_Max_Fields = {
  __typename?: 'erc721_addresses_max_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  contract_address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  token_name?: Maybe<Scalars['String']>;
  token_symbol?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Erc721_Addresses_Min_Fields = {
  __typename?: 'erc721_addresses_min_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  contract_address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  token_name?: Maybe<Scalars['String']>;
  token_symbol?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "erc721_addresses" */
export type Erc721_Addresses_Mutation_Response = {
  __typename?: 'erc721_addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Erc721_Addresses>;
};

/** on_conflict condition type for table "erc721_addresses" */
export type Erc721_Addresses_On_Conflict = {
  constraint: Erc721_Addresses_Constraint;
  update_columns?: Array<Erc721_Addresses_Update_Column>;
  where?: InputMaybe<Erc721_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "erc721_addresses". */
export type Erc721_Addresses_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  contract_address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  read?: InputMaybe<Order_By>;
  token_name?: InputMaybe<Order_By>;
  token_symbol?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: erc721_addresses */
export type Erc721_Addresses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "erc721_addresses" */
export enum Erc721_Addresses_Select_Column {
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  ContractAddress = 'contract_address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  TokenName = 'token_name',
  /** column name */
  TokenSymbol = 'token_symbol',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "erc721_addresses" */
export type Erc721_Addresses_Set_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  contract_address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  read?: InputMaybe<Scalars['Boolean']>;
  token_name?: InputMaybe<Scalars['String']>;
  token_symbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Erc721_Addresses_Stddev_Fields = {
  __typename?: 'erc721_addresses_stddev_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Erc721_Addresses_Stddev_Pop_Fields = {
  __typename?: 'erc721_addresses_stddev_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Erc721_Addresses_Stddev_Samp_Fields = {
  __typename?: 'erc721_addresses_stddev_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "erc721_addresses" */
export type Erc721_Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Erc721_Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Erc721_Addresses_Stream_Cursor_Value_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  contract_address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  read?: InputMaybe<Scalars['Boolean']>;
  token_name?: InputMaybe<Scalars['String']>;
  token_symbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Erc721_Addresses_Sum_Fields = {
  __typename?: 'erc721_addresses_sum_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "erc721_addresses" */
export enum Erc721_Addresses_Update_Column {
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  ContractAddress = 'contract_address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  TokenName = 'token_name',
  /** column name */
  TokenSymbol = 'token_symbol',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Erc721_Addresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Erc721_Addresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Erc721_Addresses_Set_Input>;
  where: Erc721_Addresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Erc721_Addresses_Var_Pop_Fields = {
  __typename?: 'erc721_addresses_var_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Erc721_Addresses_Var_Samp_Fields = {
  __typename?: 'erc721_addresses_var_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Erc721_Addresses_Variance_Fields = {
  __typename?: 'erc721_addresses_variance_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "evenbet_affiliate_player_balance" */
export type EvenbetAffiliatePlayerBalances = {
  __typename?: 'evenbetAffiliatePlayerBalances';
  affiliateGpanelId?: Maybe<Scalars['String']>;
  affiliateUserId?: Maybe<Scalars['uuid']>;
  affiliateUsername?: Maybe<Scalars['String']>;
  cashBalance?: Maybe<Scalars['numeric']>;
  cashBalanceInp?: Maybe<Scalars['numeric']>;
  cashBalanceUsd?: Maybe<Scalars['numeric']>;
  playerGpanelId?: Maybe<Scalars['String']>;
  playerUserId?: Maybe<Scalars['uuid']>;
  playerUsername?: Maybe<Scalars['String']>;
};

/** aggregated selection of "evenbet_affiliate_player_balance" */
export type EvenbetAffiliatePlayerBalances_Aggregate = {
  __typename?: 'evenbetAffiliatePlayerBalances_aggregate';
  aggregate?: Maybe<EvenbetAffiliatePlayerBalances_Aggregate_Fields>;
  nodes: Array<EvenbetAffiliatePlayerBalances>;
};

/** aggregate fields of "evenbet_affiliate_player_balance" */
export type EvenbetAffiliatePlayerBalances_Aggregate_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_aggregate_fields';
  avg?: Maybe<EvenbetAffiliatePlayerBalances_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EvenbetAffiliatePlayerBalances_Max_Fields>;
  min?: Maybe<EvenbetAffiliatePlayerBalances_Min_Fields>;
  stddev?: Maybe<EvenbetAffiliatePlayerBalances_Stddev_Fields>;
  stddev_pop?: Maybe<EvenbetAffiliatePlayerBalances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EvenbetAffiliatePlayerBalances_Stddev_Samp_Fields>;
  sum?: Maybe<EvenbetAffiliatePlayerBalances_Sum_Fields>;
  var_pop?: Maybe<EvenbetAffiliatePlayerBalances_Var_Pop_Fields>;
  var_samp?: Maybe<EvenbetAffiliatePlayerBalances_Var_Samp_Fields>;
  variance?: Maybe<EvenbetAffiliatePlayerBalances_Variance_Fields>;
};

/** aggregate fields of "evenbet_affiliate_player_balance" */
export type EvenbetAffiliatePlayerBalances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type EvenbetAffiliatePlayerBalances_Avg_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_avg_fields';
  cashBalance?: Maybe<Scalars['Float']>;
  cashBalanceInp?: Maybe<Scalars['Float']>;
  cashBalanceUsd?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "evenbet_affiliate_player_balance". All fields are combined with a logical 'AND'. */
export type EvenbetAffiliatePlayerBalances_Bool_Exp = {
  _and?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Bool_Exp>>;
  _not?: InputMaybe<EvenbetAffiliatePlayerBalances_Bool_Exp>;
  _or?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Bool_Exp>>;
  affiliateGpanelId?: InputMaybe<String_Comparison_Exp>;
  affiliateUserId?: InputMaybe<Uuid_Comparison_Exp>;
  affiliateUsername?: InputMaybe<String_Comparison_Exp>;
  cashBalance?: InputMaybe<Numeric_Comparison_Exp>;
  cashBalanceInp?: InputMaybe<Numeric_Comparison_Exp>;
  cashBalanceUsd?: InputMaybe<Numeric_Comparison_Exp>;
  playerGpanelId?: InputMaybe<String_Comparison_Exp>;
  playerUserId?: InputMaybe<Uuid_Comparison_Exp>;
  playerUsername?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type EvenbetAffiliatePlayerBalances_Max_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_max_fields';
  affiliateGpanelId?: Maybe<Scalars['String']>;
  affiliateUserId?: Maybe<Scalars['uuid']>;
  affiliateUsername?: Maybe<Scalars['String']>;
  cashBalance?: Maybe<Scalars['numeric']>;
  cashBalanceInp?: Maybe<Scalars['numeric']>;
  cashBalanceUsd?: Maybe<Scalars['numeric']>;
  playerGpanelId?: Maybe<Scalars['String']>;
  playerUserId?: Maybe<Scalars['uuid']>;
  playerUsername?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EvenbetAffiliatePlayerBalances_Min_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_min_fields';
  affiliateGpanelId?: Maybe<Scalars['String']>;
  affiliateUserId?: Maybe<Scalars['uuid']>;
  affiliateUsername?: Maybe<Scalars['String']>;
  cashBalance?: Maybe<Scalars['numeric']>;
  cashBalanceInp?: Maybe<Scalars['numeric']>;
  cashBalanceUsd?: Maybe<Scalars['numeric']>;
  playerGpanelId?: Maybe<Scalars['String']>;
  playerUserId?: Maybe<Scalars['uuid']>;
  playerUsername?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "evenbet_affiliate_player_balance". */
export type EvenbetAffiliatePlayerBalances_Order_By = {
  affiliateGpanelId?: InputMaybe<Order_By>;
  affiliateUserId?: InputMaybe<Order_By>;
  affiliateUsername?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cashBalanceInp?: InputMaybe<Order_By>;
  cashBalanceUsd?: InputMaybe<Order_By>;
  playerGpanelId?: InputMaybe<Order_By>;
  playerUserId?: InputMaybe<Order_By>;
  playerUsername?: InputMaybe<Order_By>;
};

/** select columns of table "evenbet_affiliate_player_balance" */
export enum EvenbetAffiliatePlayerBalances_Select_Column {
  /** column name */
  AffiliateGpanelId = 'affiliateGpanelId',
  /** column name */
  AffiliateUserId = 'affiliateUserId',
  /** column name */
  AffiliateUsername = 'affiliateUsername',
  /** column name */
  CashBalance = 'cashBalance',
  /** column name */
  CashBalanceInp = 'cashBalanceInp',
  /** column name */
  CashBalanceUsd = 'cashBalanceUsd',
  /** column name */
  PlayerGpanelId = 'playerGpanelId',
  /** column name */
  PlayerUserId = 'playerUserId',
  /** column name */
  PlayerUsername = 'playerUsername',
}

/** aggregate stddev on columns */
export type EvenbetAffiliatePlayerBalances_Stddev_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_stddev_fields';
  cashBalance?: Maybe<Scalars['Float']>;
  cashBalanceInp?: Maybe<Scalars['Float']>;
  cashBalanceUsd?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type EvenbetAffiliatePlayerBalances_Stddev_Pop_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_stddev_pop_fields';
  cashBalance?: Maybe<Scalars['Float']>;
  cashBalanceInp?: Maybe<Scalars['Float']>;
  cashBalanceUsd?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type EvenbetAffiliatePlayerBalances_Stddev_Samp_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_stddev_samp_fields';
  cashBalance?: Maybe<Scalars['Float']>;
  cashBalanceInp?: Maybe<Scalars['Float']>;
  cashBalanceUsd?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "evenbetAffiliatePlayerBalances" */
export type EvenbetAffiliatePlayerBalances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EvenbetAffiliatePlayerBalances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EvenbetAffiliatePlayerBalances_Stream_Cursor_Value_Input = {
  affiliateGpanelId?: InputMaybe<Scalars['String']>;
  affiliateUserId?: InputMaybe<Scalars['uuid']>;
  affiliateUsername?: InputMaybe<Scalars['String']>;
  cashBalance?: InputMaybe<Scalars['numeric']>;
  cashBalanceInp?: InputMaybe<Scalars['numeric']>;
  cashBalanceUsd?: InputMaybe<Scalars['numeric']>;
  playerGpanelId?: InputMaybe<Scalars['String']>;
  playerUserId?: InputMaybe<Scalars['uuid']>;
  playerUsername?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type EvenbetAffiliatePlayerBalances_Sum_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_sum_fields';
  cashBalance?: Maybe<Scalars['numeric']>;
  cashBalanceInp?: Maybe<Scalars['numeric']>;
  cashBalanceUsd?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type EvenbetAffiliatePlayerBalances_Var_Pop_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_var_pop_fields';
  cashBalance?: Maybe<Scalars['Float']>;
  cashBalanceInp?: Maybe<Scalars['Float']>;
  cashBalanceUsd?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type EvenbetAffiliatePlayerBalances_Var_Samp_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_var_samp_fields';
  cashBalance?: Maybe<Scalars['Float']>;
  cashBalanceInp?: Maybe<Scalars['Float']>;
  cashBalanceUsd?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type EvenbetAffiliatePlayerBalances_Variance_Fields = {
  __typename?: 'evenbetAffiliatePlayerBalances_variance_fields';
  cashBalance?: Maybe<Scalars['Float']>;
  cashBalanceInp?: Maybe<Scalars['Float']>;
  cashBalanceUsd?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests = {
  __typename?: 'evenbet_ticket_requests';
  amount: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  evenbet_id: Scalars['Int'];
  grouped_request_id?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  nft_id: Scalars['Int'];
  status: Scalars['String'];
  ticket_template: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['numeric'];
};

/** aggregated selection of "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_Aggregate = {
  __typename?: 'evenbet_ticket_requests_aggregate';
  aggregate?: Maybe<Evenbet_Ticket_Requests_Aggregate_Fields>;
  nodes: Array<Evenbet_Ticket_Requests>;
};

/** aggregate fields of "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_Aggregate_Fields = {
  __typename?: 'evenbet_ticket_requests_aggregate_fields';
  avg?: Maybe<Evenbet_Ticket_Requests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Evenbet_Ticket_Requests_Max_Fields>;
  min?: Maybe<Evenbet_Ticket_Requests_Min_Fields>;
  stddev?: Maybe<Evenbet_Ticket_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Evenbet_Ticket_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Evenbet_Ticket_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Evenbet_Ticket_Requests_Sum_Fields>;
  var_pop?: Maybe<Evenbet_Ticket_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Evenbet_Ticket_Requests_Var_Samp_Fields>;
  variance?: Maybe<Evenbet_Ticket_Requests_Variance_Fields>;
};

/** aggregate fields of "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evenbet_Ticket_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Evenbet_Ticket_Requests_Avg_Fields = {
  __typename?: 'evenbet_ticket_requests_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  ticket_template?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "evenbet_ticket_requests". All fields are combined with a logical 'AND'. */
export type Evenbet_Ticket_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Evenbet_Ticket_Requests_Bool_Exp>>;
  _not?: InputMaybe<Evenbet_Ticket_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Evenbet_Ticket_Requests_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evenbet_id?: InputMaybe<Int_Comparison_Exp>;
  grouped_request_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  nft_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  ticket_template?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "evenbet_ticket_requests" */
export enum Evenbet_Ticket_Requests_Constraint {
  /** unique or primary key constraint on columns "id" */
  EvenbetTicketRequestsPkey = 'evenbet_ticket_requests_pkey',
}

/** input type for incrementing numeric columns in table "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  ticket_template?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  grouped_request_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  ticket_template?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Evenbet_Ticket_Requests_Max_Fields = {
  __typename?: 'evenbet_ticket_requests_max_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  grouped_request_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  nft_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  ticket_template?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Evenbet_Ticket_Requests_Min_Fields = {
  __typename?: 'evenbet_ticket_requests_min_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  grouped_request_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  nft_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  ticket_template?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_Mutation_Response = {
  __typename?: 'evenbet_ticket_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Evenbet_Ticket_Requests>;
};

/** on_conflict condition type for table "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_On_Conflict = {
  constraint: Evenbet_Ticket_Requests_Constraint;
  update_columns?: Array<Evenbet_Ticket_Requests_Update_Column>;
  where?: InputMaybe<Evenbet_Ticket_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "evenbet_ticket_requests". */
export type Evenbet_Ticket_Requests_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  grouped_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nft_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  ticket_template?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evenbet_ticket_requests */
export type Evenbet_Ticket_Requests_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "evenbet_ticket_requests" */
export enum Evenbet_Ticket_Requests_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvenbetId = 'evenbet_id',
  /** column name */
  GroupedRequestId = 'grouped_request_id',
  /** column name */
  Id = 'id',
  /** column name */
  NftId = 'nft_id',
  /** column name */
  Status = 'status',
  /** column name */
  TicketTemplate = 'ticket_template',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  grouped_request_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  ticket_template?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Evenbet_Ticket_Requests_Stddev_Fields = {
  __typename?: 'evenbet_ticket_requests_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  ticket_template?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Evenbet_Ticket_Requests_Stddev_Pop_Fields = {
  __typename?: 'evenbet_ticket_requests_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  ticket_template?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Evenbet_Ticket_Requests_Stddev_Samp_Fields = {
  __typename?: 'evenbet_ticket_requests_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  ticket_template?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "evenbet_ticket_requests" */
export type Evenbet_Ticket_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evenbet_Ticket_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evenbet_Ticket_Requests_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  grouped_request_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  ticket_template?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Evenbet_Ticket_Requests_Sum_Fields = {
  __typename?: 'evenbet_ticket_requests_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  nft_id?: Maybe<Scalars['Int']>;
  ticket_template?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "evenbet_ticket_requests" */
export enum Evenbet_Ticket_Requests_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvenbetId = 'evenbet_id',
  /** column name */
  GroupedRequestId = 'grouped_request_id',
  /** column name */
  Id = 'id',
  /** column name */
  NftId = 'nft_id',
  /** column name */
  Status = 'status',
  /** column name */
  TicketTemplate = 'ticket_template',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

export type Evenbet_Ticket_Requests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Evenbet_Ticket_Requests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evenbet_Ticket_Requests_Set_Input>;
  where: Evenbet_Ticket_Requests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Evenbet_Ticket_Requests_Var_Pop_Fields = {
  __typename?: 'evenbet_ticket_requests_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  ticket_template?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Evenbet_Ticket_Requests_Var_Samp_Fields = {
  __typename?: 'evenbet_ticket_requests_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  ticket_template?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Evenbet_Ticket_Requests_Variance_Fields = {
  __typename?: 'evenbet_ticket_requests_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  ticket_template?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "evenbet_tickets" */
export type Evenbet_Tickets = {
  __typename?: 'evenbet_tickets';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  evenbet_id: Scalars['Int'];
  expiration_date: Scalars['timestamptz'];
  id: Scalars['Int'];
  issued_at: Scalars['timestamptz'];
  money_type: Scalars['String'];
  name: Scalars['String'];
  spend_for?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "evenbet_tickets" */
export type Evenbet_Tickets_Aggregate = {
  __typename?: 'evenbet_tickets_aggregate';
  aggregate?: Maybe<Evenbet_Tickets_Aggregate_Fields>;
  nodes: Array<Evenbet_Tickets>;
};

/** aggregate fields of "evenbet_tickets" */
export type Evenbet_Tickets_Aggregate_Fields = {
  __typename?: 'evenbet_tickets_aggregate_fields';
  avg?: Maybe<Evenbet_Tickets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Evenbet_Tickets_Max_Fields>;
  min?: Maybe<Evenbet_Tickets_Min_Fields>;
  stddev?: Maybe<Evenbet_Tickets_Stddev_Fields>;
  stddev_pop?: Maybe<Evenbet_Tickets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Evenbet_Tickets_Stddev_Samp_Fields>;
  sum?: Maybe<Evenbet_Tickets_Sum_Fields>;
  var_pop?: Maybe<Evenbet_Tickets_Var_Pop_Fields>;
  var_samp?: Maybe<Evenbet_Tickets_Var_Samp_Fields>;
  variance?: Maybe<Evenbet_Tickets_Variance_Fields>;
};

/** aggregate fields of "evenbet_tickets" */
export type Evenbet_Tickets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evenbet_Tickets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Evenbet_Tickets_Avg_Fields = {
  __typename?: 'evenbet_tickets_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  spend_for?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "evenbet_tickets". All fields are combined with a logical 'AND'. */
export type Evenbet_Tickets_Bool_Exp = {
  _and?: InputMaybe<Array<Evenbet_Tickets_Bool_Exp>>;
  _not?: InputMaybe<Evenbet_Tickets_Bool_Exp>;
  _or?: InputMaybe<Array<Evenbet_Tickets_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evenbet_id?: InputMaybe<Int_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  issued_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  money_type?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  spend_for?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  used_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "evenbet_tickets" */
export enum Evenbet_Tickets_Constraint {
  /** unique or primary key constraint on columns "id" */
  EvenbetTicketsPkey = 'evenbet_tickets_pkey',
}

/** input type for incrementing numeric columns in table "evenbet_tickets" */
export type Evenbet_Tickets_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  spend_for?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "evenbet_tickets" */
export type Evenbet_Tickets_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  issued_at?: InputMaybe<Scalars['timestamptz']>;
  money_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  spend_for?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Evenbet_Tickets_Max_Fields = {
  __typename?: 'evenbet_tickets_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  issued_at?: Maybe<Scalars['timestamptz']>;
  money_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  spend_for?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Evenbet_Tickets_Min_Fields = {
  __typename?: 'evenbet_tickets_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  issued_at?: Maybe<Scalars['timestamptz']>;
  money_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  spend_for?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "evenbet_tickets" */
export type Evenbet_Tickets_Mutation_Response = {
  __typename?: 'evenbet_tickets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Evenbet_Tickets>;
};

/** on_conflict condition type for table "evenbet_tickets" */
export type Evenbet_Tickets_On_Conflict = {
  constraint: Evenbet_Tickets_Constraint;
  update_columns?: Array<Evenbet_Tickets_Update_Column>;
  where?: InputMaybe<Evenbet_Tickets_Bool_Exp>;
};

/** Ordering options when selecting data from "evenbet_tickets". */
export type Evenbet_Tickets_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  money_type?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  spend_for?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  used_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evenbet_tickets */
export type Evenbet_Tickets_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "evenbet_tickets" */
export enum Evenbet_Tickets_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvenbetId = 'evenbet_id',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  IssuedAt = 'issued_at',
  /** column name */
  MoneyType = 'money_type',
  /** column name */
  Name = 'name',
  /** column name */
  SpendFor = 'spend_for',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedAt = 'used_at',
}

/** input type for updating data in table "evenbet_tickets" */
export type Evenbet_Tickets_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  issued_at?: InputMaybe<Scalars['timestamptz']>;
  money_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  spend_for?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Evenbet_Tickets_Stddev_Fields = {
  __typename?: 'evenbet_tickets_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  spend_for?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Evenbet_Tickets_Stddev_Pop_Fields = {
  __typename?: 'evenbet_tickets_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  spend_for?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Evenbet_Tickets_Stddev_Samp_Fields = {
  __typename?: 'evenbet_tickets_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  spend_for?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "evenbet_tickets" */
export type Evenbet_Tickets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evenbet_Tickets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evenbet_Tickets_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  issued_at?: InputMaybe<Scalars['timestamptz']>;
  money_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  spend_for?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Evenbet_Tickets_Sum_Fields = {
  __typename?: 'evenbet_tickets_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  spend_for?: Maybe<Scalars['Int']>;
};

/** update columns of table "evenbet_tickets" */
export enum Evenbet_Tickets_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvenbetId = 'evenbet_id',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  IssuedAt = 'issued_at',
  /** column name */
  MoneyType = 'money_type',
  /** column name */
  Name = 'name',
  /** column name */
  SpendFor = 'spend_for',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedAt = 'used_at',
}

export type Evenbet_Tickets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Evenbet_Tickets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evenbet_Tickets_Set_Input>;
  where: Evenbet_Tickets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Evenbet_Tickets_Var_Pop_Fields = {
  __typename?: 'evenbet_tickets_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  spend_for?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Evenbet_Tickets_Var_Samp_Fields = {
  __typename?: 'evenbet_tickets_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  spend_for?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Evenbet_Tickets_Variance_Fields = {
  __typename?: 'evenbet_tickets_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  spend_for?: Maybe<Scalars['Float']>;
};

export type GPanelOutput = {
  __typename?: 'gPanelOutput';
  accessToken?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** columns and relationships of "gpanel_payments" */
export type GpanelPayments = {
  __typename?: 'gpanelPayments';
  amount: Scalars['numeric'];
  createdAt: Scalars['timestamptz'];
  currency: Scalars['String'];
  date: Scalars['timestamptz'];
  gpanelId: Scalars['String'];
  gpanelUserId: Scalars['String'];
  /** An object relationship */
  gpanel_user: GpanelUsers;
  id: Scalars['Int'];
  identity: Scalars['String'];
  method: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "gpanel_payments" */
export type GpanelPayments_Aggregate = {
  __typename?: 'gpanelPayments_aggregate';
  aggregate?: Maybe<GpanelPayments_Aggregate_Fields>;
  nodes: Array<GpanelPayments>;
};

export type GpanelPayments_Aggregate_Bool_Exp = {
  count?: InputMaybe<GpanelPayments_Aggregate_Bool_Exp_Count>;
};

export type GpanelPayments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GpanelPayments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GpanelPayments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "gpanel_payments" */
export type GpanelPayments_Aggregate_Fields = {
  __typename?: 'gpanelPayments_aggregate_fields';
  avg?: Maybe<GpanelPayments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GpanelPayments_Max_Fields>;
  min?: Maybe<GpanelPayments_Min_Fields>;
  stddev?: Maybe<GpanelPayments_Stddev_Fields>;
  stddev_pop?: Maybe<GpanelPayments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GpanelPayments_Stddev_Samp_Fields>;
  sum?: Maybe<GpanelPayments_Sum_Fields>;
  var_pop?: Maybe<GpanelPayments_Var_Pop_Fields>;
  var_samp?: Maybe<GpanelPayments_Var_Samp_Fields>;
  variance?: Maybe<GpanelPayments_Variance_Fields>;
};

/** aggregate fields of "gpanel_payments" */
export type GpanelPayments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GpanelPayments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gpanel_payments" */
export type GpanelPayments_Aggregate_Order_By = {
  avg?: InputMaybe<GpanelPayments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GpanelPayments_Max_Order_By>;
  min?: InputMaybe<GpanelPayments_Min_Order_By>;
  stddev?: InputMaybe<GpanelPayments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GpanelPayments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GpanelPayments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GpanelPayments_Sum_Order_By>;
  var_pop?: InputMaybe<GpanelPayments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GpanelPayments_Var_Samp_Order_By>;
  variance?: InputMaybe<GpanelPayments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "gpanel_payments" */
export type GpanelPayments_Arr_Rel_Insert_Input = {
  data: Array<GpanelPayments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GpanelPayments_On_Conflict>;
};

/** aggregate avg on columns */
export type GpanelPayments_Avg_Fields = {
  __typename?: 'gpanelPayments_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gpanel_payments" */
export type GpanelPayments_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "gpanel_payments". All fields are combined with a logical 'AND'. */
export type GpanelPayments_Bool_Exp = {
  _and?: InputMaybe<Array<GpanelPayments_Bool_Exp>>;
  _not?: InputMaybe<GpanelPayments_Bool_Exp>;
  _or?: InputMaybe<Array<GpanelPayments_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  gpanelId?: InputMaybe<String_Comparison_Exp>;
  gpanelUserId?: InputMaybe<String_Comparison_Exp>;
  gpanel_user?: InputMaybe<GpanelUsers_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity?: InputMaybe<String_Comparison_Exp>;
  method?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gpanel_payments" */
export enum GpanelPayments_Constraint {
  /** unique or primary key constraint on columns "id" */
  GpanelTransactionsPkey = 'gpanel_transactions_pkey',
}

/** input type for incrementing numeric columns in table "gpanel_payments" */
export type GpanelPayments_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "gpanel_payments" */
export type GpanelPayments_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  gpanelId?: InputMaybe<Scalars['String']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  gpanel_user?: InputMaybe<GpanelUsers_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  identity?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type GpanelPayments_Max_Fields = {
  __typename?: 'gpanelPayments_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  gpanelId?: Maybe<Scalars['String']>;
  gpanelUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "gpanel_payments" */
export type GpanelPayments_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  gpanelId?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GpanelPayments_Min_Fields = {
  __typename?: 'gpanelPayments_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  gpanelId?: Maybe<Scalars['String']>;
  gpanelUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "gpanel_payments" */
export type GpanelPayments_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  gpanelId?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "gpanel_payments" */
export type GpanelPayments_Mutation_Response = {
  __typename?: 'gpanelPayments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GpanelPayments>;
};

/** on_conflict condition type for table "gpanel_payments" */
export type GpanelPayments_On_Conflict = {
  constraint: GpanelPayments_Constraint;
  update_columns?: Array<GpanelPayments_Update_Column>;
  where?: InputMaybe<GpanelPayments_Bool_Exp>;
};

/** Ordering options when selecting data from "gpanel_payments". */
export type GpanelPayments_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  gpanelId?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  gpanel_user?: InputMaybe<GpanelUsers_Order_By>;
  id?: InputMaybe<Order_By>;
  identity?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gpanel_payments */
export type GpanelPayments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "gpanel_payments" */
export enum GpanelPayments_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Date = 'date',
  /** column name */
  GpanelId = 'gpanelId',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  Method = 'method',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "gpanel_payments" */
export type GpanelPayments_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  gpanelId?: InputMaybe<Scalars['String']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type GpanelPayments_Stddev_Fields = {
  __typename?: 'gpanelPayments_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gpanel_payments" */
export type GpanelPayments_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GpanelPayments_Stddev_Pop_Fields = {
  __typename?: 'gpanelPayments_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gpanel_payments" */
export type GpanelPayments_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GpanelPayments_Stddev_Samp_Fields = {
  __typename?: 'gpanelPayments_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gpanel_payments" */
export type GpanelPayments_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "gpanelPayments" */
export type GpanelPayments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GpanelPayments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GpanelPayments_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  gpanelId?: InputMaybe<Scalars['String']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type GpanelPayments_Sum_Fields = {
  __typename?: 'gpanelPayments_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "gpanel_payments" */
export type GpanelPayments_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "gpanel_payments" */
export enum GpanelPayments_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Date = 'date',
  /** column name */
  GpanelId = 'gpanelId',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  Method = 'method',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type GpanelPayments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GpanelPayments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GpanelPayments_Set_Input>;
  where: GpanelPayments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GpanelPayments_Var_Pop_Fields = {
  __typename?: 'gpanelPayments_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gpanel_payments" */
export type GpanelPayments_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GpanelPayments_Var_Samp_Fields = {
  __typename?: 'gpanelPayments_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gpanel_payments" */
export type GpanelPayments_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GpanelPayments_Variance_Fields = {
  __typename?: 'gpanelPayments_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gpanel_payments" */
export type GpanelPayments_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "gpanel_users" */
export type GpanelUsers = {
  __typename?: 'gpanelUsers';
  avatar_id?: Maybe<Scalars['Int']>;
  bonusBalance: Scalars['numeric'];
  cashBalance: Scalars['numeric'];
  cash_balance_inp: Scalars['numeric'];
  cash_balance_usd: Scalars['numeric'];
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  evenbet_id?: Maybe<Scalars['Int']>;
  gpanelId: Scalars['String'];
  /** An array relationship */
  gpanel_payments: Array<GpanelPayments>;
  /** An aggregate relationship */
  gpanel_payments_aggregate: GpanelPayments_Aggregate;
  id: Scalars['Int'];
  /** An object relationship */
  kyc: Kycs;
  kycPending: Scalars['Boolean'];
  kycStatus: Scalars['Int'];
  registered: Scalars['timestamptz'];
  state: Scalars['String'];
  state_internal: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
  username: Scalars['String'];
};

/** columns and relationships of "gpanel_users" */
export type GpanelUsersGpanel_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<GpanelPayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelPayments_Order_By>>;
  where?: InputMaybe<GpanelPayments_Bool_Exp>;
};

/** columns and relationships of "gpanel_users" */
export type GpanelUsersGpanel_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GpanelPayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelPayments_Order_By>>;
  where?: InputMaybe<GpanelPayments_Bool_Exp>;
};

/** aggregated selection of "gpanel_users" */
export type GpanelUsers_Aggregate = {
  __typename?: 'gpanelUsers_aggregate';
  aggregate?: Maybe<GpanelUsers_Aggregate_Fields>;
  nodes: Array<GpanelUsers>;
};

export type GpanelUsers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<GpanelUsers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<GpanelUsers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<GpanelUsers_Aggregate_Bool_Exp_Count>;
};

export type GpanelUsers_Aggregate_Bool_Exp_Bool_And = {
  arguments: GpanelUsers_Select_Column_GpanelUsers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GpanelUsers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type GpanelUsers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: GpanelUsers_Select_Column_GpanelUsers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GpanelUsers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type GpanelUsers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GpanelUsers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GpanelUsers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "gpanel_users" */
export type GpanelUsers_Aggregate_Fields = {
  __typename?: 'gpanelUsers_aggregate_fields';
  avg?: Maybe<GpanelUsers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GpanelUsers_Max_Fields>;
  min?: Maybe<GpanelUsers_Min_Fields>;
  stddev?: Maybe<GpanelUsers_Stddev_Fields>;
  stddev_pop?: Maybe<GpanelUsers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GpanelUsers_Stddev_Samp_Fields>;
  sum?: Maybe<GpanelUsers_Sum_Fields>;
  var_pop?: Maybe<GpanelUsers_Var_Pop_Fields>;
  var_samp?: Maybe<GpanelUsers_Var_Samp_Fields>;
  variance?: Maybe<GpanelUsers_Variance_Fields>;
};

/** aggregate fields of "gpanel_users" */
export type GpanelUsers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GpanelUsers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gpanel_users" */
export type GpanelUsers_Aggregate_Order_By = {
  avg?: InputMaybe<GpanelUsers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GpanelUsers_Max_Order_By>;
  min?: InputMaybe<GpanelUsers_Min_Order_By>;
  stddev?: InputMaybe<GpanelUsers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GpanelUsers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GpanelUsers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GpanelUsers_Sum_Order_By>;
  var_pop?: InputMaybe<GpanelUsers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GpanelUsers_Var_Samp_Order_By>;
  variance?: InputMaybe<GpanelUsers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "gpanel_users" */
export type GpanelUsers_Arr_Rel_Insert_Input = {
  data: Array<GpanelUsers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GpanelUsers_On_Conflict>;
};

/** aggregate avg on columns */
export type GpanelUsers_Avg_Fields = {
  __typename?: 'gpanelUsers_avg_fields';
  avatar_id?: Maybe<Scalars['Float']>;
  bonusBalance?: Maybe<Scalars['Float']>;
  cashBalance?: Maybe<Scalars['Float']>;
  cash_balance_inp?: Maybe<Scalars['Float']>;
  cash_balance_usd?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gpanel_users" */
export type GpanelUsers_Avg_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "gpanel_users". All fields are combined with a logical 'AND'. */
export type GpanelUsers_Bool_Exp = {
  _and?: InputMaybe<Array<GpanelUsers_Bool_Exp>>;
  _not?: InputMaybe<GpanelUsers_Bool_Exp>;
  _or?: InputMaybe<Array<GpanelUsers_Bool_Exp>>;
  avatar_id?: InputMaybe<Int_Comparison_Exp>;
  bonusBalance?: InputMaybe<Numeric_Comparison_Exp>;
  cashBalance?: InputMaybe<Numeric_Comparison_Exp>;
  cash_balance_inp?: InputMaybe<Numeric_Comparison_Exp>;
  cash_balance_usd?: InputMaybe<Numeric_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  evenbet_id?: InputMaybe<Int_Comparison_Exp>;
  gpanelId?: InputMaybe<String_Comparison_Exp>;
  gpanel_payments?: InputMaybe<GpanelPayments_Bool_Exp>;
  gpanel_payments_aggregate?: InputMaybe<GpanelPayments_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  kyc?: InputMaybe<Kycs_Bool_Exp>;
  kycPending?: InputMaybe<Boolean_Comparison_Exp>;
  kycStatus?: InputMaybe<Int_Comparison_Exp>;
  registered?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  state_internal?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gpanel_users" */
export enum GpanelUsers_Constraint {
  /** unique or primary key constraint on columns "gpanel_id" */
  GpanelUsersGpanelIdKey = 'gpanel_users_gpanel_id_key',
  /** unique or primary key constraint on columns "username" */
  GpanelUsersUsernameKey = 'gpanel_users_username_key',
  /** unique or primary key constraint on columns "id" */
  UserGpanelDataPkey = 'user_gpanel_data_pkey',
}

/** input type for incrementing numeric columns in table "gpanel_users" */
export type GpanelUsers_Inc_Input = {
  avatar_id?: InputMaybe<Scalars['Int']>;
  bonusBalance?: InputMaybe<Scalars['numeric']>;
  cashBalance?: InputMaybe<Scalars['numeric']>;
  cash_balance_inp?: InputMaybe<Scalars['numeric']>;
  cash_balance_usd?: InputMaybe<Scalars['numeric']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  kycStatus?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "gpanel_users" */
export type GpanelUsers_Insert_Input = {
  avatar_id?: InputMaybe<Scalars['Int']>;
  bonusBalance?: InputMaybe<Scalars['numeric']>;
  cashBalance?: InputMaybe<Scalars['numeric']>;
  cash_balance_inp?: InputMaybe<Scalars['numeric']>;
  cash_balance_usd?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  gpanelId?: InputMaybe<Scalars['String']>;
  gpanel_payments?: InputMaybe<GpanelPayments_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  kyc?: InputMaybe<Kycs_Obj_Rel_Insert_Input>;
  kycPending?: InputMaybe<Scalars['Boolean']>;
  kycStatus?: InputMaybe<Scalars['Int']>;
  registered?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  state_internal?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GpanelUsers_Max_Fields = {
  __typename?: 'gpanelUsers_max_fields';
  avatar_id?: Maybe<Scalars['Int']>;
  bonusBalance?: Maybe<Scalars['numeric']>;
  cashBalance?: Maybe<Scalars['numeric']>;
  cash_balance_inp?: Maybe<Scalars['numeric']>;
  cash_balance_usd?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  gpanelId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  kycStatus?: Maybe<Scalars['Int']>;
  registered?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  state_internal?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "gpanel_users" */
export type GpanelUsers_Max_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  gpanelId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
  registered?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_internal?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GpanelUsers_Min_Fields = {
  __typename?: 'gpanelUsers_min_fields';
  avatar_id?: Maybe<Scalars['Int']>;
  bonusBalance?: Maybe<Scalars['numeric']>;
  cashBalance?: Maybe<Scalars['numeric']>;
  cash_balance_inp?: Maybe<Scalars['numeric']>;
  cash_balance_usd?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  gpanelId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  kycStatus?: Maybe<Scalars['Int']>;
  registered?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  state_internal?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "gpanel_users" */
export type GpanelUsers_Min_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  gpanelId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
  registered?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_internal?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "gpanel_users" */
export type GpanelUsers_Mutation_Response = {
  __typename?: 'gpanelUsers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GpanelUsers>;
};

/** input type for inserting object relation for remote table "gpanel_users" */
export type GpanelUsers_Obj_Rel_Insert_Input = {
  data: GpanelUsers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<GpanelUsers_On_Conflict>;
};

/** on_conflict condition type for table "gpanel_users" */
export type GpanelUsers_On_Conflict = {
  constraint: GpanelUsers_Constraint;
  update_columns?: Array<GpanelUsers_Update_Column>;
  where?: InputMaybe<GpanelUsers_Bool_Exp>;
};

/** Ordering options when selecting data from "gpanel_users". */
export type GpanelUsers_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  gpanelId?: InputMaybe<Order_By>;
  gpanel_payments_aggregate?: InputMaybe<GpanelPayments_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  kyc?: InputMaybe<Kycs_Order_By>;
  kycPending?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
  registered?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_internal?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gpanel_users */
export type GpanelUsers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "gpanel_users" */
export enum GpanelUsers_Select_Column {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  BonusBalance = 'bonusBalance',
  /** column name */
  CashBalance = 'cashBalance',
  /** column name */
  CashBalanceInp = 'cash_balance_inp',
  /** column name */
  CashBalanceUsd = 'cash_balance_usd',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EvenbetId = 'evenbet_id',
  /** column name */
  GpanelId = 'gpanelId',
  /** column name */
  Id = 'id',
  /** column name */
  KycPending = 'kycPending',
  /** column name */
  KycStatus = 'kycStatus',
  /** column name */
  Registered = 'registered',
  /** column name */
  State = 'state',
  /** column name */
  StateInternal = 'state_internal',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Username = 'username',
}

/** select "gpanelUsers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "gpanel_users" */
export enum GpanelUsers_Select_Column_GpanelUsers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  KycPending = 'kycPending',
}

/** select "gpanelUsers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "gpanel_users" */
export enum GpanelUsers_Select_Column_GpanelUsers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  KycPending = 'kycPending',
}

/** input type for updating data in table "gpanel_users" */
export type GpanelUsers_Set_Input = {
  avatar_id?: InputMaybe<Scalars['Int']>;
  bonusBalance?: InputMaybe<Scalars['numeric']>;
  cashBalance?: InputMaybe<Scalars['numeric']>;
  cash_balance_inp?: InputMaybe<Scalars['numeric']>;
  cash_balance_usd?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  gpanelId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  kycPending?: InputMaybe<Scalars['Boolean']>;
  kycStatus?: InputMaybe<Scalars['Int']>;
  registered?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  state_internal?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type GpanelUsers_Stddev_Fields = {
  __typename?: 'gpanelUsers_stddev_fields';
  avatar_id?: Maybe<Scalars['Float']>;
  bonusBalance?: Maybe<Scalars['Float']>;
  cashBalance?: Maybe<Scalars['Float']>;
  cash_balance_inp?: Maybe<Scalars['Float']>;
  cash_balance_usd?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gpanel_users" */
export type GpanelUsers_Stddev_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GpanelUsers_Stddev_Pop_Fields = {
  __typename?: 'gpanelUsers_stddev_pop_fields';
  avatar_id?: Maybe<Scalars['Float']>;
  bonusBalance?: Maybe<Scalars['Float']>;
  cashBalance?: Maybe<Scalars['Float']>;
  cash_balance_inp?: Maybe<Scalars['Float']>;
  cash_balance_usd?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gpanel_users" */
export type GpanelUsers_Stddev_Pop_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GpanelUsers_Stddev_Samp_Fields = {
  __typename?: 'gpanelUsers_stddev_samp_fields';
  avatar_id?: Maybe<Scalars['Float']>;
  bonusBalance?: Maybe<Scalars['Float']>;
  cashBalance?: Maybe<Scalars['Float']>;
  cash_balance_inp?: Maybe<Scalars['Float']>;
  cash_balance_usd?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gpanel_users" */
export type GpanelUsers_Stddev_Samp_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "gpanelUsers" */
export type GpanelUsers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GpanelUsers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GpanelUsers_Stream_Cursor_Value_Input = {
  avatar_id?: InputMaybe<Scalars['Int']>;
  bonusBalance?: InputMaybe<Scalars['numeric']>;
  cashBalance?: InputMaybe<Scalars['numeric']>;
  cash_balance_inp?: InputMaybe<Scalars['numeric']>;
  cash_balance_usd?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  evenbet_id?: InputMaybe<Scalars['Int']>;
  gpanelId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  kycPending?: InputMaybe<Scalars['Boolean']>;
  kycStatus?: InputMaybe<Scalars['Int']>;
  registered?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  state_internal?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type GpanelUsers_Sum_Fields = {
  __typename?: 'gpanelUsers_sum_fields';
  avatar_id?: Maybe<Scalars['Int']>;
  bonusBalance?: Maybe<Scalars['numeric']>;
  cashBalance?: Maybe<Scalars['numeric']>;
  cash_balance_inp?: Maybe<Scalars['numeric']>;
  cash_balance_usd?: Maybe<Scalars['numeric']>;
  evenbet_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kycStatus?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "gpanel_users" */
export type GpanelUsers_Sum_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
};

/** update columns of table "gpanel_users" */
export enum GpanelUsers_Update_Column {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  BonusBalance = 'bonusBalance',
  /** column name */
  CashBalance = 'cashBalance',
  /** column name */
  CashBalanceInp = 'cash_balance_inp',
  /** column name */
  CashBalanceUsd = 'cash_balance_usd',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EvenbetId = 'evenbet_id',
  /** column name */
  GpanelId = 'gpanelId',
  /** column name */
  Id = 'id',
  /** column name */
  KycPending = 'kycPending',
  /** column name */
  KycStatus = 'kycStatus',
  /** column name */
  Registered = 'registered',
  /** column name */
  State = 'state',
  /** column name */
  StateInternal = 'state_internal',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Username = 'username',
}

export type GpanelUsers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GpanelUsers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GpanelUsers_Set_Input>;
  where: GpanelUsers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GpanelUsers_Var_Pop_Fields = {
  __typename?: 'gpanelUsers_var_pop_fields';
  avatar_id?: Maybe<Scalars['Float']>;
  bonusBalance?: Maybe<Scalars['Float']>;
  cashBalance?: Maybe<Scalars['Float']>;
  cash_balance_inp?: Maybe<Scalars['Float']>;
  cash_balance_usd?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gpanel_users" */
export type GpanelUsers_Var_Pop_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GpanelUsers_Var_Samp_Fields = {
  __typename?: 'gpanelUsers_var_samp_fields';
  avatar_id?: Maybe<Scalars['Float']>;
  bonusBalance?: Maybe<Scalars['Float']>;
  cashBalance?: Maybe<Scalars['Float']>;
  cash_balance_inp?: Maybe<Scalars['Float']>;
  cash_balance_usd?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gpanel_users" */
export type GpanelUsers_Var_Samp_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GpanelUsers_Variance_Fields = {
  __typename?: 'gpanelUsers_variance_fields';
  avatar_id?: Maybe<Scalars['Float']>;
  bonusBalance?: Maybe<Scalars['Float']>;
  cashBalance?: Maybe<Scalars['Float']>;
  cash_balance_inp?: Maybe<Scalars['Float']>;
  cash_balance_usd?: Maybe<Scalars['Float']>;
  evenbet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gpanel_users" */
export type GpanelUsers_Variance_Order_By = {
  avatar_id?: InputMaybe<Order_By>;
  bonusBalance?: InputMaybe<Order_By>;
  cashBalance?: InputMaybe<Order_By>;
  cash_balance_inp?: InputMaybe<Order_By>;
  cash_balance_usd?: InputMaybe<Order_By>;
  evenbet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kycStatus?: InputMaybe<Order_By>;
};

/** columns and relationships of "gpanel_users_agent" */
export type Gpanel_Users_Agent = {
  __typename?: 'gpanel_users_agent';
  agent_of_gpanel_user_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  gpanel_user_id: Scalars['String'];
  id: Scalars['Int'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "gpanel_users_agent" */
export type Gpanel_Users_Agent_Aggregate = {
  __typename?: 'gpanel_users_agent_aggregate';
  aggregate?: Maybe<Gpanel_Users_Agent_Aggregate_Fields>;
  nodes: Array<Gpanel_Users_Agent>;
};

/** aggregate fields of "gpanel_users_agent" */
export type Gpanel_Users_Agent_Aggregate_Fields = {
  __typename?: 'gpanel_users_agent_aggregate_fields';
  avg?: Maybe<Gpanel_Users_Agent_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gpanel_Users_Agent_Max_Fields>;
  min?: Maybe<Gpanel_Users_Agent_Min_Fields>;
  stddev?: Maybe<Gpanel_Users_Agent_Stddev_Fields>;
  stddev_pop?: Maybe<Gpanel_Users_Agent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gpanel_Users_Agent_Stddev_Samp_Fields>;
  sum?: Maybe<Gpanel_Users_Agent_Sum_Fields>;
  var_pop?: Maybe<Gpanel_Users_Agent_Var_Pop_Fields>;
  var_samp?: Maybe<Gpanel_Users_Agent_Var_Samp_Fields>;
  variance?: Maybe<Gpanel_Users_Agent_Variance_Fields>;
};

/** aggregate fields of "gpanel_users_agent" */
export type Gpanel_Users_Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gpanel_Users_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Gpanel_Users_Agent_Avg_Fields = {
  __typename?: 'gpanel_users_agent_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gpanel_users_agent". All fields are combined with a logical 'AND'. */
export type Gpanel_Users_Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Gpanel_Users_Agent_Bool_Exp>>;
  _not?: InputMaybe<Gpanel_Users_Agent_Bool_Exp>;
  _or?: InputMaybe<Array<Gpanel_Users_Agent_Bool_Exp>>;
  agent_of_gpanel_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gpanel_user_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gpanel_users_agent" */
export enum Gpanel_Users_Agent_Constraint {
  /** unique or primary key constraint on columns "id" */
  GpanelUsersAgentPkey = 'gpanel_users_agent_pkey',
}

/** input type for incrementing numeric columns in table "gpanel_users_agent" */
export type Gpanel_Users_Agent_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "gpanel_users_agent" */
export type Gpanel_Users_Agent_Insert_Input = {
  agent_of_gpanel_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gpanel_Users_Agent_Max_Fields = {
  __typename?: 'gpanel_users_agent_max_fields';
  agent_of_gpanel_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Gpanel_Users_Agent_Min_Fields = {
  __typename?: 'gpanel_users_agent_min_fields';
  agent_of_gpanel_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "gpanel_users_agent" */
export type Gpanel_Users_Agent_Mutation_Response = {
  __typename?: 'gpanel_users_agent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gpanel_Users_Agent>;
};

/** on_conflict condition type for table "gpanel_users_agent" */
export type Gpanel_Users_Agent_On_Conflict = {
  constraint: Gpanel_Users_Agent_Constraint;
  update_columns?: Array<Gpanel_Users_Agent_Update_Column>;
  where?: InputMaybe<Gpanel_Users_Agent_Bool_Exp>;
};

/** Ordering options when selecting data from "gpanel_users_agent". */
export type Gpanel_Users_Agent_Order_By = {
  agent_of_gpanel_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gpanel_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gpanel_users_agent */
export type Gpanel_Users_Agent_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "gpanel_users_agent" */
export enum Gpanel_Users_Agent_Select_Column {
  /** column name */
  AgentOfGpanelUserId = 'agent_of_gpanel_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "gpanel_users_agent" */
export type Gpanel_Users_Agent_Set_Input = {
  agent_of_gpanel_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gpanel_Users_Agent_Stddev_Fields = {
  __typename?: 'gpanel_users_agent_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gpanel_Users_Agent_Stddev_Pop_Fields = {
  __typename?: 'gpanel_users_agent_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gpanel_Users_Agent_Stddev_Samp_Fields = {
  __typename?: 'gpanel_users_agent_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "gpanel_users_agent" */
export type Gpanel_Users_Agent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gpanel_Users_Agent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gpanel_Users_Agent_Stream_Cursor_Value_Input = {
  agent_of_gpanel_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Gpanel_Users_Agent_Sum_Fields = {
  __typename?: 'gpanel_users_agent_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "gpanel_users_agent" */
export enum Gpanel_Users_Agent_Update_Column {
  /** column name */
  AgentOfGpanelUserId = 'agent_of_gpanel_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Gpanel_Users_Agent_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gpanel_Users_Agent_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gpanel_Users_Agent_Set_Input>;
  where: Gpanel_Users_Agent_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gpanel_Users_Agent_Var_Pop_Fields = {
  __typename?: 'gpanel_users_agent_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gpanel_Users_Agent_Var_Samp_Fields = {
  __typename?: 'gpanel_users_agent_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gpanel_Users_Agent_Variance_Fields = {
  __typename?: 'gpanel_users_agent_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address = {
  __typename?: 'gpanel_users_deposit_address';
  address: Scalars['String'];
  blockchain_id: Scalars['Int'];
  blockchain_main_deposit_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id: Scalars['String'];
  id: Scalars['bigint'];
  tx?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_Aggregate = {
  __typename?: 'gpanel_users_deposit_address_aggregate';
  aggregate?: Maybe<Gpanel_Users_Deposit_Address_Aggregate_Fields>;
  nodes: Array<Gpanel_Users_Deposit_Address>;
};

/** aggregate fields of "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_Aggregate_Fields = {
  __typename?: 'gpanel_users_deposit_address_aggregate_fields';
  avg?: Maybe<Gpanel_Users_Deposit_Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gpanel_Users_Deposit_Address_Max_Fields>;
  min?: Maybe<Gpanel_Users_Deposit_Address_Min_Fields>;
  stddev?: Maybe<Gpanel_Users_Deposit_Address_Stddev_Fields>;
  stddev_pop?: Maybe<Gpanel_Users_Deposit_Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gpanel_Users_Deposit_Address_Stddev_Samp_Fields>;
  sum?: Maybe<Gpanel_Users_Deposit_Address_Sum_Fields>;
  var_pop?: Maybe<Gpanel_Users_Deposit_Address_Var_Pop_Fields>;
  var_samp?: Maybe<Gpanel_Users_Deposit_Address_Var_Samp_Fields>;
  variance?: Maybe<Gpanel_Users_Deposit_Address_Variance_Fields>;
};

/** aggregate fields of "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Gpanel_Users_Deposit_Address_Avg_Fields = {
  __typename?: 'gpanel_users_deposit_address_avg_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gpanel_users_deposit_address". All fields are combined with a logical 'AND'. */
export type Gpanel_Users_Deposit_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Bool_Exp>>;
  _not?: InputMaybe<Gpanel_Users_Deposit_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  blockchain_main_deposit_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gpanel_user_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tx?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gpanel_users_deposit_address" */
export enum Gpanel_Users_Deposit_Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  GpanelUsersDepositAddressPkey = 'gpanel_users_deposit_address_pkey',
}

/** input type for incrementing numeric columns in table "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_Inc_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  blockchain_main_deposit_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  blockchain_main_deposit_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  tx?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gpanel_Users_Deposit_Address_Max_Fields = {
  __typename?: 'gpanel_users_deposit_address_max_fields';
  address?: Maybe<Scalars['String']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  tx?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Gpanel_Users_Deposit_Address_Min_Fields = {
  __typename?: 'gpanel_users_deposit_address_min_fields';
  address?: Maybe<Scalars['String']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  tx?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_Mutation_Response = {
  __typename?: 'gpanel_users_deposit_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gpanel_Users_Deposit_Address>;
};

/** on_conflict condition type for table "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_On_Conflict = {
  constraint: Gpanel_Users_Deposit_Address_Constraint;
  update_columns?: Array<Gpanel_Users_Deposit_Address_Update_Column>;
  where?: InputMaybe<Gpanel_Users_Deposit_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "gpanel_users_deposit_address". */
export type Gpanel_Users_Deposit_Address_Order_By = {
  address?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  blockchain_main_deposit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gpanel_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tx?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gpanel_users_deposit_address */
export type Gpanel_Users_Deposit_Address_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "gpanel_users_deposit_address" */
export enum Gpanel_Users_Deposit_Address_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  BlockchainMainDepositId = 'blockchain_main_deposit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Tx = 'tx',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  blockchain_main_deposit_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  tx?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gpanel_Users_Deposit_Address_Stddev_Fields = {
  __typename?: 'gpanel_users_deposit_address_stddev_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gpanel_Users_Deposit_Address_Stddev_Pop_Fields = {
  __typename?: 'gpanel_users_deposit_address_stddev_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gpanel_Users_Deposit_Address_Stddev_Samp_Fields = {
  __typename?: 'gpanel_users_deposit_address_stddev_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "gpanel_users_deposit_address" */
export type Gpanel_Users_Deposit_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gpanel_Users_Deposit_Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gpanel_Users_Deposit_Address_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  blockchain_main_deposit_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  tx?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Gpanel_Users_Deposit_Address_Sum_Fields = {
  __typename?: 'gpanel_users_deposit_address_sum_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "gpanel_users_deposit_address" */
export enum Gpanel_Users_Deposit_Address_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  BlockchainMainDepositId = 'blockchain_main_deposit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Tx = 'tx',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Gpanel_Users_Deposit_Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gpanel_Users_Deposit_Address_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gpanel_Users_Deposit_Address_Set_Input>;
  where: Gpanel_Users_Deposit_Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gpanel_Users_Deposit_Address_Var_Pop_Fields = {
  __typename?: 'gpanel_users_deposit_address_var_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gpanel_Users_Deposit_Address_Var_Samp_Fields = {
  __typename?: 'gpanel_users_deposit_address_var_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gpanel_Users_Deposit_Address_Variance_Fields = {
  __typename?: 'gpanel_users_deposit_address_variance_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blockchain_main_deposit_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "gpanel_users_kyc" */
export type Gpanel_Users_Kyc = {
  __typename?: 'gpanel_users_kyc';
  approvedDate?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  gpanel_user_id: Scalars['String'];
  id: Scalars['Int'];
  inreviewDate?: Maybe<Scalars['timestamptz']>;
  levelName?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  recordId: Scalars['String'];
  reviewResult?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  waitingDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_Aggregate = {
  __typename?: 'gpanel_users_kyc_aggregate';
  aggregate?: Maybe<Gpanel_Users_Kyc_Aggregate_Fields>;
  nodes: Array<Gpanel_Users_Kyc>;
};

/** aggregate fields of "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_Aggregate_Fields = {
  __typename?: 'gpanel_users_kyc_aggregate_fields';
  avg?: Maybe<Gpanel_Users_Kyc_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gpanel_Users_Kyc_Max_Fields>;
  min?: Maybe<Gpanel_Users_Kyc_Min_Fields>;
  stddev?: Maybe<Gpanel_Users_Kyc_Stddev_Fields>;
  stddev_pop?: Maybe<Gpanel_Users_Kyc_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gpanel_Users_Kyc_Stddev_Samp_Fields>;
  sum?: Maybe<Gpanel_Users_Kyc_Sum_Fields>;
  var_pop?: Maybe<Gpanel_Users_Kyc_Var_Pop_Fields>;
  var_samp?: Maybe<Gpanel_Users_Kyc_Var_Samp_Fields>;
  variance?: Maybe<Gpanel_Users_Kyc_Variance_Fields>;
};

/** aggregate fields of "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gpanel_Users_Kyc_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Gpanel_Users_Kyc_Avg_Fields = {
  __typename?: 'gpanel_users_kyc_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gpanel_users_kyc". All fields are combined with a logical 'AND'. */
export type Gpanel_Users_Kyc_Bool_Exp = {
  _and?: InputMaybe<Array<Gpanel_Users_Kyc_Bool_Exp>>;
  _not?: InputMaybe<Gpanel_Users_Kyc_Bool_Exp>;
  _or?: InputMaybe<Array<Gpanel_Users_Kyc_Bool_Exp>>;
  approvedDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gpanel_user_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inreviewDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  levelName?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  recordId?: InputMaybe<String_Comparison_Exp>;
  reviewResult?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  waitingDate?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gpanel_users_kyc" */
export enum Gpanel_Users_Kyc_Constraint {
  /** unique or primary key constraint on columns "id" */
  GpanelUsersKycPkey = 'gpanel_users_kyc_pkey',
}

/** input type for incrementing numeric columns in table "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_Insert_Input = {
  approvedDate?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inreviewDate?: InputMaybe<Scalars['timestamptz']>;
  levelName?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  recordId?: InputMaybe<Scalars['String']>;
  reviewResult?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waitingDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gpanel_Users_Kyc_Max_Fields = {
  __typename?: 'gpanel_users_kyc_max_fields';
  approvedDate?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inreviewDate?: Maybe<Scalars['timestamptz']>;
  levelName?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  reviewResult?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  waitingDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Gpanel_Users_Kyc_Min_Fields = {
  __typename?: 'gpanel_users_kyc_min_fields';
  approvedDate?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inreviewDate?: Maybe<Scalars['timestamptz']>;
  levelName?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  reviewResult?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  waitingDate?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_Mutation_Response = {
  __typename?: 'gpanel_users_kyc_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gpanel_Users_Kyc>;
};

/** on_conflict condition type for table "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_On_Conflict = {
  constraint: Gpanel_Users_Kyc_Constraint;
  update_columns?: Array<Gpanel_Users_Kyc_Update_Column>;
  where?: InputMaybe<Gpanel_Users_Kyc_Bool_Exp>;
};

/** Ordering options when selecting data from "gpanel_users_kyc". */
export type Gpanel_Users_Kyc_Order_By = {
  approvedDate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gpanel_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inreviewDate?: InputMaybe<Order_By>;
  levelName?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  recordId?: InputMaybe<Order_By>;
  reviewResult?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  waitingDate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gpanel_users_kyc */
export type Gpanel_Users_Kyc_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "gpanel_users_kyc" */
export enum Gpanel_Users_Kyc_Select_Column {
  /** column name */
  ApprovedDate = 'approvedDate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  InreviewDate = 'inreviewDate',
  /** column name */
  LevelName = 'levelName',
  /** column name */
  Provider = 'provider',
  /** column name */
  RecordId = 'recordId',
  /** column name */
  ReviewResult = 'reviewResult',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WaitingDate = 'waitingDate',
}

/** input type for updating data in table "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_Set_Input = {
  approvedDate?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inreviewDate?: InputMaybe<Scalars['timestamptz']>;
  levelName?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  recordId?: InputMaybe<Scalars['String']>;
  reviewResult?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waitingDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gpanel_Users_Kyc_Stddev_Fields = {
  __typename?: 'gpanel_users_kyc_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gpanel_Users_Kyc_Stddev_Pop_Fields = {
  __typename?: 'gpanel_users_kyc_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gpanel_Users_Kyc_Stddev_Samp_Fields = {
  __typename?: 'gpanel_users_kyc_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "gpanel_users_kyc" */
export type Gpanel_Users_Kyc_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gpanel_Users_Kyc_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gpanel_Users_Kyc_Stream_Cursor_Value_Input = {
  approvedDate?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inreviewDate?: InputMaybe<Scalars['timestamptz']>;
  levelName?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  recordId?: InputMaybe<Scalars['String']>;
  reviewResult?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waitingDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Gpanel_Users_Kyc_Sum_Fields = {
  __typename?: 'gpanel_users_kyc_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "gpanel_users_kyc" */
export enum Gpanel_Users_Kyc_Update_Column {
  /** column name */
  ApprovedDate = 'approvedDate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  InreviewDate = 'inreviewDate',
  /** column name */
  LevelName = 'levelName',
  /** column name */
  Provider = 'provider',
  /** column name */
  RecordId = 'recordId',
  /** column name */
  ReviewResult = 'reviewResult',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WaitingDate = 'waitingDate',
}

export type Gpanel_Users_Kyc_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gpanel_Users_Kyc_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gpanel_Users_Kyc_Set_Input>;
  where: Gpanel_Users_Kyc_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gpanel_Users_Kyc_Var_Pop_Fields = {
  __typename?: 'gpanel_users_kyc_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gpanel_Users_Kyc_Var_Samp_Fields = {
  __typename?: 'gpanel_users_kyc_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gpanel_Users_Kyc_Variance_Fields = {
  __typename?: 'gpanel_users_kyc_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type KycOutput = {
  __typename?: 'kycOutput';
  feeDeposit: Scalars['Int'];
  feeDepositInp: Scalars['Int'];
  feeWithdraw: Scalars['Int'];
  feeWithdrawInp: Scalars['Int'];
  id: Scalars['Int'];
  info?: Maybe<Scalars['String']>;
  level: Scalars['String'];
  maxDeposit: Scalars['Int'];
  maxDepositInp: Scalars['Int'];
  maxWithdraw: Scalars['Int'];
  maxWithdrawInp: Scalars['Int'];
  minDeposit: Scalars['Int'];
  minDepositInp: Scalars['Int'];
  minWithdraw: Scalars['Int'];
  minWithdrawInp: Scalars['Int'];
};

/** columns and relationships of "kyc" */
export type Kycs = {
  __typename?: 'kycs';
  feeDeposit: Scalars['Int'];
  feeDepositInp: Scalars['Int'];
  feeWithdraw: Scalars['Int'];
  feeWithdrawInp: Scalars['Int'];
  id: Scalars['Int'];
  info?: Maybe<Scalars['String']>;
  level: Scalars['String'];
  maxDeposit: Scalars['Int'];
  maxDepositInp: Scalars['Int'];
  maxWithdraw: Scalars['Int'];
  maxWithdrawInp: Scalars['Int'];
  minDeposit: Scalars['Int'];
  minDepositInp: Scalars['Int'];
  minWithdraw: Scalars['Int'];
  minWithdrawInp: Scalars['Int'];
};

/** aggregated selection of "kyc" */
export type Kycs_Aggregate = {
  __typename?: 'kycs_aggregate';
  aggregate?: Maybe<Kycs_Aggregate_Fields>;
  nodes: Array<Kycs>;
};

/** aggregate fields of "kyc" */
export type Kycs_Aggregate_Fields = {
  __typename?: 'kycs_aggregate_fields';
  avg?: Maybe<Kycs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Kycs_Max_Fields>;
  min?: Maybe<Kycs_Min_Fields>;
  stddev?: Maybe<Kycs_Stddev_Fields>;
  stddev_pop?: Maybe<Kycs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kycs_Stddev_Samp_Fields>;
  sum?: Maybe<Kycs_Sum_Fields>;
  var_pop?: Maybe<Kycs_Var_Pop_Fields>;
  var_samp?: Maybe<Kycs_Var_Samp_Fields>;
  variance?: Maybe<Kycs_Variance_Fields>;
};

/** aggregate fields of "kyc" */
export type Kycs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kycs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Kycs_Avg_Fields = {
  __typename?: 'kycs_avg_fields';
  feeDeposit?: Maybe<Scalars['Float']>;
  feeDepositInp?: Maybe<Scalars['Float']>;
  feeWithdraw?: Maybe<Scalars['Float']>;
  feeWithdrawInp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxDeposit?: Maybe<Scalars['Float']>;
  maxDepositInp?: Maybe<Scalars['Float']>;
  maxWithdraw?: Maybe<Scalars['Float']>;
  maxWithdrawInp?: Maybe<Scalars['Float']>;
  minDeposit?: Maybe<Scalars['Float']>;
  minDepositInp?: Maybe<Scalars['Float']>;
  minWithdraw?: Maybe<Scalars['Float']>;
  minWithdrawInp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "kyc". All fields are combined with a logical 'AND'. */
export type Kycs_Bool_Exp = {
  _and?: InputMaybe<Array<Kycs_Bool_Exp>>;
  _not?: InputMaybe<Kycs_Bool_Exp>;
  _or?: InputMaybe<Array<Kycs_Bool_Exp>>;
  feeDeposit?: InputMaybe<Int_Comparison_Exp>;
  feeDepositInp?: InputMaybe<Int_Comparison_Exp>;
  feeWithdraw?: InputMaybe<Int_Comparison_Exp>;
  feeWithdrawInp?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  info?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<String_Comparison_Exp>;
  maxDeposit?: InputMaybe<Int_Comparison_Exp>;
  maxDepositInp?: InputMaybe<Int_Comparison_Exp>;
  maxWithdraw?: InputMaybe<Int_Comparison_Exp>;
  maxWithdrawInp?: InputMaybe<Int_Comparison_Exp>;
  minDeposit?: InputMaybe<Int_Comparison_Exp>;
  minDepositInp?: InputMaybe<Int_Comparison_Exp>;
  minWithdraw?: InputMaybe<Int_Comparison_Exp>;
  minWithdrawInp?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "kyc" */
export enum Kycs_Constraint {
  /** unique or primary key constraint on columns "id" */
  KycPkey = 'kyc_pkey',
}

/** input type for incrementing numeric columns in table "kyc" */
export type Kycs_Inc_Input = {
  feeDeposit?: InputMaybe<Scalars['Int']>;
  feeDepositInp?: InputMaybe<Scalars['Int']>;
  feeWithdraw?: InputMaybe<Scalars['Int']>;
  feeWithdrawInp?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  maxDeposit?: InputMaybe<Scalars['Int']>;
  maxDepositInp?: InputMaybe<Scalars['Int']>;
  maxWithdraw?: InputMaybe<Scalars['Int']>;
  maxWithdrawInp?: InputMaybe<Scalars['Int']>;
  minDeposit?: InputMaybe<Scalars['Int']>;
  minDepositInp?: InputMaybe<Scalars['Int']>;
  minWithdraw?: InputMaybe<Scalars['Int']>;
  minWithdrawInp?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "kyc" */
export type Kycs_Insert_Input = {
  feeDeposit?: InputMaybe<Scalars['Int']>;
  feeDepositInp?: InputMaybe<Scalars['Int']>;
  feeWithdraw?: InputMaybe<Scalars['Int']>;
  feeWithdrawInp?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  maxDeposit?: InputMaybe<Scalars['Int']>;
  maxDepositInp?: InputMaybe<Scalars['Int']>;
  maxWithdraw?: InputMaybe<Scalars['Int']>;
  maxWithdrawInp?: InputMaybe<Scalars['Int']>;
  minDeposit?: InputMaybe<Scalars['Int']>;
  minDepositInp?: InputMaybe<Scalars['Int']>;
  minWithdraw?: InputMaybe<Scalars['Int']>;
  minWithdrawInp?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Kycs_Max_Fields = {
  __typename?: 'kycs_max_fields';
  feeDeposit?: Maybe<Scalars['Int']>;
  feeDepositInp?: Maybe<Scalars['Int']>;
  feeWithdraw?: Maybe<Scalars['Int']>;
  feeWithdrawInp?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  maxDeposit?: Maybe<Scalars['Int']>;
  maxDepositInp?: Maybe<Scalars['Int']>;
  maxWithdraw?: Maybe<Scalars['Int']>;
  maxWithdrawInp?: Maybe<Scalars['Int']>;
  minDeposit?: Maybe<Scalars['Int']>;
  minDepositInp?: Maybe<Scalars['Int']>;
  minWithdraw?: Maybe<Scalars['Int']>;
  minWithdrawInp?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Kycs_Min_Fields = {
  __typename?: 'kycs_min_fields';
  feeDeposit?: Maybe<Scalars['Int']>;
  feeDepositInp?: Maybe<Scalars['Int']>;
  feeWithdraw?: Maybe<Scalars['Int']>;
  feeWithdrawInp?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  maxDeposit?: Maybe<Scalars['Int']>;
  maxDepositInp?: Maybe<Scalars['Int']>;
  maxWithdraw?: Maybe<Scalars['Int']>;
  maxWithdrawInp?: Maybe<Scalars['Int']>;
  minDeposit?: Maybe<Scalars['Int']>;
  minDepositInp?: Maybe<Scalars['Int']>;
  minWithdraw?: Maybe<Scalars['Int']>;
  minWithdrawInp?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "kyc" */
export type Kycs_Mutation_Response = {
  __typename?: 'kycs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kycs>;
};

/** input type for inserting object relation for remote table "kyc" */
export type Kycs_Obj_Rel_Insert_Input = {
  data: Kycs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Kycs_On_Conflict>;
};

/** on_conflict condition type for table "kyc" */
export type Kycs_On_Conflict = {
  constraint: Kycs_Constraint;
  update_columns?: Array<Kycs_Update_Column>;
  where?: InputMaybe<Kycs_Bool_Exp>;
};

/** Ordering options when selecting data from "kyc". */
export type Kycs_Order_By = {
  feeDeposit?: InputMaybe<Order_By>;
  feeDepositInp?: InputMaybe<Order_By>;
  feeWithdraw?: InputMaybe<Order_By>;
  feeWithdrawInp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  info?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  maxDeposit?: InputMaybe<Order_By>;
  maxDepositInp?: InputMaybe<Order_By>;
  maxWithdraw?: InputMaybe<Order_By>;
  maxWithdrawInp?: InputMaybe<Order_By>;
  minDeposit?: InputMaybe<Order_By>;
  minDepositInp?: InputMaybe<Order_By>;
  minWithdraw?: InputMaybe<Order_By>;
  minWithdrawInp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kyc */
export type Kycs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "kyc" */
export enum Kycs_Select_Column {
  /** column name */
  FeeDeposit = 'feeDeposit',
  /** column name */
  FeeDepositInp = 'feeDepositInp',
  /** column name */
  FeeWithdraw = 'feeWithdraw',
  /** column name */
  FeeWithdrawInp = 'feeWithdrawInp',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Level = 'level',
  /** column name */
  MaxDeposit = 'maxDeposit',
  /** column name */
  MaxDepositInp = 'maxDepositInp',
  /** column name */
  MaxWithdraw = 'maxWithdraw',
  /** column name */
  MaxWithdrawInp = 'maxWithdrawInp',
  /** column name */
  MinDeposit = 'minDeposit',
  /** column name */
  MinDepositInp = 'minDepositInp',
  /** column name */
  MinWithdraw = 'minWithdraw',
  /** column name */
  MinWithdrawInp = 'minWithdrawInp',
}

/** input type for updating data in table "kyc" */
export type Kycs_Set_Input = {
  feeDeposit?: InputMaybe<Scalars['Int']>;
  feeDepositInp?: InputMaybe<Scalars['Int']>;
  feeWithdraw?: InputMaybe<Scalars['Int']>;
  feeWithdrawInp?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  maxDeposit?: InputMaybe<Scalars['Int']>;
  maxDepositInp?: InputMaybe<Scalars['Int']>;
  maxWithdraw?: InputMaybe<Scalars['Int']>;
  maxWithdrawInp?: InputMaybe<Scalars['Int']>;
  minDeposit?: InputMaybe<Scalars['Int']>;
  minDepositInp?: InputMaybe<Scalars['Int']>;
  minWithdraw?: InputMaybe<Scalars['Int']>;
  minWithdrawInp?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Kycs_Stddev_Fields = {
  __typename?: 'kycs_stddev_fields';
  feeDeposit?: Maybe<Scalars['Float']>;
  feeDepositInp?: Maybe<Scalars['Float']>;
  feeWithdraw?: Maybe<Scalars['Float']>;
  feeWithdrawInp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxDeposit?: Maybe<Scalars['Float']>;
  maxDepositInp?: Maybe<Scalars['Float']>;
  maxWithdraw?: Maybe<Scalars['Float']>;
  maxWithdrawInp?: Maybe<Scalars['Float']>;
  minDeposit?: Maybe<Scalars['Float']>;
  minDepositInp?: Maybe<Scalars['Float']>;
  minWithdraw?: Maybe<Scalars['Float']>;
  minWithdrawInp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Kycs_Stddev_Pop_Fields = {
  __typename?: 'kycs_stddev_pop_fields';
  feeDeposit?: Maybe<Scalars['Float']>;
  feeDepositInp?: Maybe<Scalars['Float']>;
  feeWithdraw?: Maybe<Scalars['Float']>;
  feeWithdrawInp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxDeposit?: Maybe<Scalars['Float']>;
  maxDepositInp?: Maybe<Scalars['Float']>;
  maxWithdraw?: Maybe<Scalars['Float']>;
  maxWithdrawInp?: Maybe<Scalars['Float']>;
  minDeposit?: Maybe<Scalars['Float']>;
  minDepositInp?: Maybe<Scalars['Float']>;
  minWithdraw?: Maybe<Scalars['Float']>;
  minWithdrawInp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Kycs_Stddev_Samp_Fields = {
  __typename?: 'kycs_stddev_samp_fields';
  feeDeposit?: Maybe<Scalars['Float']>;
  feeDepositInp?: Maybe<Scalars['Float']>;
  feeWithdraw?: Maybe<Scalars['Float']>;
  feeWithdrawInp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxDeposit?: Maybe<Scalars['Float']>;
  maxDepositInp?: Maybe<Scalars['Float']>;
  maxWithdraw?: Maybe<Scalars['Float']>;
  maxWithdrawInp?: Maybe<Scalars['Float']>;
  minDeposit?: Maybe<Scalars['Float']>;
  minDepositInp?: Maybe<Scalars['Float']>;
  minWithdraw?: Maybe<Scalars['Float']>;
  minWithdrawInp?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "kycs" */
export type Kycs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kycs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kycs_Stream_Cursor_Value_Input = {
  feeDeposit?: InputMaybe<Scalars['Int']>;
  feeDepositInp?: InputMaybe<Scalars['Int']>;
  feeWithdraw?: InputMaybe<Scalars['Int']>;
  feeWithdrawInp?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  maxDeposit?: InputMaybe<Scalars['Int']>;
  maxDepositInp?: InputMaybe<Scalars['Int']>;
  maxWithdraw?: InputMaybe<Scalars['Int']>;
  maxWithdrawInp?: InputMaybe<Scalars['Int']>;
  minDeposit?: InputMaybe<Scalars['Int']>;
  minDepositInp?: InputMaybe<Scalars['Int']>;
  minWithdraw?: InputMaybe<Scalars['Int']>;
  minWithdrawInp?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Kycs_Sum_Fields = {
  __typename?: 'kycs_sum_fields';
  feeDeposit?: Maybe<Scalars['Int']>;
  feeDepositInp?: Maybe<Scalars['Int']>;
  feeWithdraw?: Maybe<Scalars['Int']>;
  feeWithdrawInp?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  maxDeposit?: Maybe<Scalars['Int']>;
  maxDepositInp?: Maybe<Scalars['Int']>;
  maxWithdraw?: Maybe<Scalars['Int']>;
  maxWithdrawInp?: Maybe<Scalars['Int']>;
  minDeposit?: Maybe<Scalars['Int']>;
  minDepositInp?: Maybe<Scalars['Int']>;
  minWithdraw?: Maybe<Scalars['Int']>;
  minWithdrawInp?: Maybe<Scalars['Int']>;
};

/** update columns of table "kyc" */
export enum Kycs_Update_Column {
  /** column name */
  FeeDeposit = 'feeDeposit',
  /** column name */
  FeeDepositInp = 'feeDepositInp',
  /** column name */
  FeeWithdraw = 'feeWithdraw',
  /** column name */
  FeeWithdrawInp = 'feeWithdrawInp',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Level = 'level',
  /** column name */
  MaxDeposit = 'maxDeposit',
  /** column name */
  MaxDepositInp = 'maxDepositInp',
  /** column name */
  MaxWithdraw = 'maxWithdraw',
  /** column name */
  MaxWithdrawInp = 'maxWithdrawInp',
  /** column name */
  MinDeposit = 'minDeposit',
  /** column name */
  MinDepositInp = 'minDepositInp',
  /** column name */
  MinWithdraw = 'minWithdraw',
  /** column name */
  MinWithdrawInp = 'minWithdrawInp',
}

export type Kycs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Kycs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kycs_Set_Input>;
  where: Kycs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Kycs_Var_Pop_Fields = {
  __typename?: 'kycs_var_pop_fields';
  feeDeposit?: Maybe<Scalars['Float']>;
  feeDepositInp?: Maybe<Scalars['Float']>;
  feeWithdraw?: Maybe<Scalars['Float']>;
  feeWithdrawInp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxDeposit?: Maybe<Scalars['Float']>;
  maxDepositInp?: Maybe<Scalars['Float']>;
  maxWithdraw?: Maybe<Scalars['Float']>;
  maxWithdrawInp?: Maybe<Scalars['Float']>;
  minDeposit?: Maybe<Scalars['Float']>;
  minDepositInp?: Maybe<Scalars['Float']>;
  minWithdraw?: Maybe<Scalars['Float']>;
  minWithdrawInp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Kycs_Var_Samp_Fields = {
  __typename?: 'kycs_var_samp_fields';
  feeDeposit?: Maybe<Scalars['Float']>;
  feeDepositInp?: Maybe<Scalars['Float']>;
  feeWithdraw?: Maybe<Scalars['Float']>;
  feeWithdrawInp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxDeposit?: Maybe<Scalars['Float']>;
  maxDepositInp?: Maybe<Scalars['Float']>;
  maxWithdraw?: Maybe<Scalars['Float']>;
  maxWithdrawInp?: Maybe<Scalars['Float']>;
  minDeposit?: Maybe<Scalars['Float']>;
  minDepositInp?: Maybe<Scalars['Float']>;
  minWithdraw?: Maybe<Scalars['Float']>;
  minWithdrawInp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Kycs_Variance_Fields = {
  __typename?: 'kycs_variance_fields';
  feeDeposit?: Maybe<Scalars['Float']>;
  feeDepositInp?: Maybe<Scalars['Float']>;
  feeWithdraw?: Maybe<Scalars['Float']>;
  feeWithdrawInp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxDeposit?: Maybe<Scalars['Float']>;
  maxDepositInp?: Maybe<Scalars['Float']>;
  maxWithdraw?: Maybe<Scalars['Float']>;
  maxWithdrawInp?: Maybe<Scalars['Float']>;
  minDeposit?: Maybe<Scalars['Float']>;
  minDepositInp?: Maybe<Scalars['Float']>;
  minWithdraw?: Maybe<Scalars['Float']>;
  minWithdrawInp?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "leaderboard" */
export type Leaderboard = {
  __typename?: 'leaderboard';
  created_at?: Maybe<Scalars['timestamptz']>;
  end: Scalars['timestamptz'];
  id: Scalars['bigint'];
  money_type: Scalars['String'];
  name: Scalars['String'];
  reward?: Maybe<Scalars['String']>;
  start: Scalars['timestamptz'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  website_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "leaderboard" */
export type Leaderboard_Aggregate = {
  __typename?: 'leaderboard_aggregate';
  aggregate?: Maybe<Leaderboard_Aggregate_Fields>;
  nodes: Array<Leaderboard>;
};

/** aggregate fields of "leaderboard" */
export type Leaderboard_Aggregate_Fields = {
  __typename?: 'leaderboard_aggregate_fields';
  avg?: Maybe<Leaderboard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leaderboard_Max_Fields>;
  min?: Maybe<Leaderboard_Min_Fields>;
  stddev?: Maybe<Leaderboard_Stddev_Fields>;
  stddev_pop?: Maybe<Leaderboard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leaderboard_Stddev_Samp_Fields>;
  sum?: Maybe<Leaderboard_Sum_Fields>;
  var_pop?: Maybe<Leaderboard_Var_Pop_Fields>;
  var_samp?: Maybe<Leaderboard_Var_Samp_Fields>;
  variance?: Maybe<Leaderboard_Variance_Fields>;
};

/** aggregate fields of "leaderboard" */
export type Leaderboard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leaderboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Leaderboard_Avg_Fields = {
  __typename?: 'leaderboard_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "leaderboard". All fields are combined with a logical 'AND'. */
export type Leaderboard_Bool_Exp = {
  _and?: InputMaybe<Array<Leaderboard_Bool_Exp>>;
  _not?: InputMaybe<Leaderboard_Bool_Exp>;
  _or?: InputMaybe<Array<Leaderboard_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  money_type?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  reward?: InputMaybe<String_Comparison_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  website_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "leaderboard" */
export enum Leaderboard_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeaderboardPkey = 'leaderboard_pkey',
}

/** input type for incrementing numeric columns in table "leaderboard" */
export type Leaderboard_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "leaderboard" */
export type Leaderboard_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  money_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reward?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Leaderboard_Max_Fields = {
  __typename?: 'leaderboard_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  money_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reward?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Leaderboard_Min_Fields = {
  __typename?: 'leaderboard_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  money_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reward?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "leaderboard" */
export type Leaderboard_Mutation_Response = {
  __typename?: 'leaderboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Leaderboard>;
};

/** on_conflict condition type for table "leaderboard" */
export type Leaderboard_On_Conflict = {
  constraint: Leaderboard_Constraint;
  update_columns?: Array<Leaderboard_Update_Column>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};

/** Ordering options when selecting data from "leaderboard". */
export type Leaderboard_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  money_type?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reward?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  website_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leaderboard */
export type Leaderboard_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "leaderboard_ranking" */
export type Leaderboard_Ranking = {
  __typename?: 'leaderboard_ranking';
  created_at: Scalars['timestamptz'];
  gpanel_user_id: Scalars['String'];
  id: Scalars['bigint'];
  leaderboard_id: Scalars['Int'];
  score: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  username: Scalars['String'];
};

/** aggregated selection of "leaderboard_ranking" */
export type Leaderboard_Ranking_Aggregate = {
  __typename?: 'leaderboard_ranking_aggregate';
  aggregate?: Maybe<Leaderboard_Ranking_Aggregate_Fields>;
  nodes: Array<Leaderboard_Ranking>;
};

/** aggregate fields of "leaderboard_ranking" */
export type Leaderboard_Ranking_Aggregate_Fields = {
  __typename?: 'leaderboard_ranking_aggregate_fields';
  avg?: Maybe<Leaderboard_Ranking_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leaderboard_Ranking_Max_Fields>;
  min?: Maybe<Leaderboard_Ranking_Min_Fields>;
  stddev?: Maybe<Leaderboard_Ranking_Stddev_Fields>;
  stddev_pop?: Maybe<Leaderboard_Ranking_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leaderboard_Ranking_Stddev_Samp_Fields>;
  sum?: Maybe<Leaderboard_Ranking_Sum_Fields>;
  var_pop?: Maybe<Leaderboard_Ranking_Var_Pop_Fields>;
  var_samp?: Maybe<Leaderboard_Ranking_Var_Samp_Fields>;
  variance?: Maybe<Leaderboard_Ranking_Variance_Fields>;
};

/** aggregate fields of "leaderboard_ranking" */
export type Leaderboard_Ranking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leaderboard_Ranking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Leaderboard_Ranking_Avg_Fields = {
  __typename?: 'leaderboard_ranking_avg_fields';
  id?: Maybe<Scalars['Float']>;
  leaderboard_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "leaderboard_ranking". All fields are combined with a logical 'AND'. */
export type Leaderboard_Ranking_Bool_Exp = {
  _and?: InputMaybe<Array<Leaderboard_Ranking_Bool_Exp>>;
  _not?: InputMaybe<Leaderboard_Ranking_Bool_Exp>;
  _or?: InputMaybe<Array<Leaderboard_Ranking_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gpanel_user_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  leaderboard_id?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "leaderboard_ranking" */
export enum Leaderboard_Ranking_Constraint {
  /** unique or primary key constraint on columns "leaderboard_id", "gpanel_user_id" */
  LeaderboardRankingLeaderboardIdGpanelUserIdKey = 'leaderboard_ranking_leaderboard_id_gpanel_user_id_key',
  /** unique or primary key constraint on columns "id" */
  LeaderboardRankingPkey = 'leaderboard_ranking_pkey',
}

/** input type for incrementing numeric columns in table "leaderboard_ranking" */
export type Leaderboard_Ranking_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  leaderboard_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "leaderboard_ranking" */
export type Leaderboard_Ranking_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  leaderboard_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Leaderboard_Ranking_Max_Fields = {
  __typename?: 'leaderboard_ranking_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  leaderboard_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Leaderboard_Ranking_Min_Fields = {
  __typename?: 'leaderboard_ranking_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  leaderboard_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "leaderboard_ranking" */
export type Leaderboard_Ranking_Mutation_Response = {
  __typename?: 'leaderboard_ranking_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Leaderboard_Ranking>;
};

/** on_conflict condition type for table "leaderboard_ranking" */
export type Leaderboard_Ranking_On_Conflict = {
  constraint: Leaderboard_Ranking_Constraint;
  update_columns?: Array<Leaderboard_Ranking_Update_Column>;
  where?: InputMaybe<Leaderboard_Ranking_Bool_Exp>;
};

/** Ordering options when selecting data from "leaderboard_ranking". */
export type Leaderboard_Ranking_Order_By = {
  created_at?: InputMaybe<Order_By>;
  gpanel_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leaderboard_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leaderboard_ranking */
export type Leaderboard_Ranking_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "leaderboard_ranking" */
export enum Leaderboard_Ranking_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  LeaderboardId = 'leaderboard_id',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
}

/** input type for updating data in table "leaderboard_ranking" */
export type Leaderboard_Ranking_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  leaderboard_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Leaderboard_Ranking_Stddev_Fields = {
  __typename?: 'leaderboard_ranking_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  leaderboard_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Leaderboard_Ranking_Stddev_Pop_Fields = {
  __typename?: 'leaderboard_ranking_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  leaderboard_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Leaderboard_Ranking_Stddev_Samp_Fields = {
  __typename?: 'leaderboard_ranking_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  leaderboard_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "leaderboard_ranking" */
export type Leaderboard_Ranking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leaderboard_Ranking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leaderboard_Ranking_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  leaderboard_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Leaderboard_Ranking_Sum_Fields = {
  __typename?: 'leaderboard_ranking_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  leaderboard_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
};

/** update columns of table "leaderboard_ranking" */
export enum Leaderboard_Ranking_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  LeaderboardId = 'leaderboard_id',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
}

export type Leaderboard_Ranking_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Leaderboard_Ranking_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Leaderboard_Ranking_Set_Input>;
  where: Leaderboard_Ranking_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Leaderboard_Ranking_Var_Pop_Fields = {
  __typename?: 'leaderboard_ranking_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  leaderboard_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Leaderboard_Ranking_Var_Samp_Fields = {
  __typename?: 'leaderboard_ranking_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  leaderboard_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Leaderboard_Ranking_Variance_Fields = {
  __typename?: 'leaderboard_ranking_variance_fields';
  id?: Maybe<Scalars['Float']>;
  leaderboard_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** select columns of table "leaderboard" */
export enum Leaderboard_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  MoneyType = 'money_type',
  /** column name */
  Name = 'name',
  /** column name */
  Reward = 'reward',
  /** column name */
  Start = 'start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebsiteId = 'website_id',
}

/** input type for updating data in table "leaderboard" */
export type Leaderboard_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  money_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reward?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Leaderboard_Stddev_Fields = {
  __typename?: 'leaderboard_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Leaderboard_Stddev_Pop_Fields = {
  __typename?: 'leaderboard_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Leaderboard_Stddev_Samp_Fields = {
  __typename?: 'leaderboard_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "leaderboard" */
export type Leaderboard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leaderboard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leaderboard_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  money_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reward?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Leaderboard_Sum_Fields = {
  __typename?: 'leaderboard_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "leaderboard" */
export enum Leaderboard_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  MoneyType = 'money_type',
  /** column name */
  Name = 'name',
  /** column name */
  Reward = 'reward',
  /** column name */
  Start = 'start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebsiteId = 'website_id',
}

export type Leaderboard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Leaderboard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Leaderboard_Set_Input>;
  where: Leaderboard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Leaderboard_Var_Pop_Fields = {
  __typename?: 'leaderboard_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Leaderboard_Var_Samp_Fields = {
  __typename?: 'leaderboard_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Leaderboard_Variance_Fields = {
  __typename?: 'leaderboard_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "liquidity_pools" */
export type Liquidity_Pools = {
  __typename?: 'liquidity_pools';
  contract: Scalars['String'];
  currency_id1: Scalars['Int'];
  currency_id2: Scalars['Int'];
  id: Scalars['bigint'];
  status: Scalars['String'];
};

/** aggregated selection of "liquidity_pools" */
export type Liquidity_Pools_Aggregate = {
  __typename?: 'liquidity_pools_aggregate';
  aggregate?: Maybe<Liquidity_Pools_Aggregate_Fields>;
  nodes: Array<Liquidity_Pools>;
};

/** aggregate fields of "liquidity_pools" */
export type Liquidity_Pools_Aggregate_Fields = {
  __typename?: 'liquidity_pools_aggregate_fields';
  avg?: Maybe<Liquidity_Pools_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Liquidity_Pools_Max_Fields>;
  min?: Maybe<Liquidity_Pools_Min_Fields>;
  stddev?: Maybe<Liquidity_Pools_Stddev_Fields>;
  stddev_pop?: Maybe<Liquidity_Pools_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Liquidity_Pools_Stddev_Samp_Fields>;
  sum?: Maybe<Liquidity_Pools_Sum_Fields>;
  var_pop?: Maybe<Liquidity_Pools_Var_Pop_Fields>;
  var_samp?: Maybe<Liquidity_Pools_Var_Samp_Fields>;
  variance?: Maybe<Liquidity_Pools_Variance_Fields>;
};

/** aggregate fields of "liquidity_pools" */
export type Liquidity_Pools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Liquidity_Pools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Liquidity_Pools_Avg_Fields = {
  __typename?: 'liquidity_pools_avg_fields';
  currency_id1?: Maybe<Scalars['Float']>;
  currency_id2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "liquidity_pools". All fields are combined with a logical 'AND'. */
export type Liquidity_Pools_Bool_Exp = {
  _and?: InputMaybe<Array<Liquidity_Pools_Bool_Exp>>;
  _not?: InputMaybe<Liquidity_Pools_Bool_Exp>;
  _or?: InputMaybe<Array<Liquidity_Pools_Bool_Exp>>;
  contract?: InputMaybe<String_Comparison_Exp>;
  currency_id1?: InputMaybe<Int_Comparison_Exp>;
  currency_id2?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "liquidity_pools" */
export enum Liquidity_Pools_Constraint {
  /** unique or primary key constraint on columns "id" */
  LiquidityPoolsPkey = 'LiquidityPools_pkey',
}

/** input type for incrementing numeric columns in table "liquidity_pools" */
export type Liquidity_Pools_Inc_Input = {
  currency_id1?: InputMaybe<Scalars['Int']>;
  currency_id2?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "liquidity_pools" */
export type Liquidity_Pools_Insert_Input = {
  contract?: InputMaybe<Scalars['String']>;
  currency_id1?: InputMaybe<Scalars['Int']>;
  currency_id2?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Liquidity_Pools_Max_Fields = {
  __typename?: 'liquidity_pools_max_fields';
  contract?: Maybe<Scalars['String']>;
  currency_id1?: Maybe<Scalars['Int']>;
  currency_id2?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Liquidity_Pools_Min_Fields = {
  __typename?: 'liquidity_pools_min_fields';
  contract?: Maybe<Scalars['String']>;
  currency_id1?: Maybe<Scalars['Int']>;
  currency_id2?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "liquidity_pools" */
export type Liquidity_Pools_Mutation_Response = {
  __typename?: 'liquidity_pools_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Liquidity_Pools>;
};

/** on_conflict condition type for table "liquidity_pools" */
export type Liquidity_Pools_On_Conflict = {
  constraint: Liquidity_Pools_Constraint;
  update_columns?: Array<Liquidity_Pools_Update_Column>;
  where?: InputMaybe<Liquidity_Pools_Bool_Exp>;
};

/** Ordering options when selecting data from "liquidity_pools". */
export type Liquidity_Pools_Order_By = {
  contract?: InputMaybe<Order_By>;
  currency_id1?: InputMaybe<Order_By>;
  currency_id2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: liquidity_pools */
export type Liquidity_Pools_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "liquidity_pools" */
export enum Liquidity_Pools_Select_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  CurrencyId1 = 'currency_id1',
  /** column name */
  CurrencyId2 = 'currency_id2',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "liquidity_pools" */
export type Liquidity_Pools_Set_Input = {
  contract?: InputMaybe<Scalars['String']>;
  currency_id1?: InputMaybe<Scalars['Int']>;
  currency_id2?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Liquidity_Pools_Stddev_Fields = {
  __typename?: 'liquidity_pools_stddev_fields';
  currency_id1?: Maybe<Scalars['Float']>;
  currency_id2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Liquidity_Pools_Stddev_Pop_Fields = {
  __typename?: 'liquidity_pools_stddev_pop_fields';
  currency_id1?: Maybe<Scalars['Float']>;
  currency_id2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Liquidity_Pools_Stddev_Samp_Fields = {
  __typename?: 'liquidity_pools_stddev_samp_fields';
  currency_id1?: Maybe<Scalars['Float']>;
  currency_id2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "liquidity_pools" */
export type Liquidity_Pools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Liquidity_Pools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Liquidity_Pools_Stream_Cursor_Value_Input = {
  contract?: InputMaybe<Scalars['String']>;
  currency_id1?: InputMaybe<Scalars['Int']>;
  currency_id2?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Liquidity_Pools_Sum_Fields = {
  __typename?: 'liquidity_pools_sum_fields';
  currency_id1?: Maybe<Scalars['Int']>;
  currency_id2?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "liquidity_pools" */
export enum Liquidity_Pools_Update_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  CurrencyId1 = 'currency_id1',
  /** column name */
  CurrencyId2 = 'currency_id2',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
}

export type Liquidity_Pools_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Liquidity_Pools_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Liquidity_Pools_Set_Input>;
  where: Liquidity_Pools_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Liquidity_Pools_Var_Pop_Fields = {
  __typename?: 'liquidity_pools_var_pop_fields';
  currency_id1?: Maybe<Scalars['Float']>;
  currency_id2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Liquidity_Pools_Var_Samp_Fields = {
  __typename?: 'liquidity_pools_var_samp_fields';
  currency_id1?: Maybe<Scalars['Float']>;
  currency_id2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Liquidity_Pools_Variance_Fields = {
  __typename?: 'liquidity_pools_variance_fields';
  currency_id1?: Maybe<Scalars['Float']>;
  currency_id2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "listings" */
export type MarketListings = {
  __typename?: 'marketListings';
  amount: Scalars['Int'];
  blockchainId?: Maybe<Scalars['smallint']>;
  buyer: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  endedAt?: Maybe<Scalars['Int']>;
  erc20Address: Scalars['String'];
  id: Scalars['Int'];
  isErc721: Scalars['Boolean'];
  lastBidder?: Maybe<Scalars['String']>;
  /** An object relationship */
  marketplaceContract: MarketplaceContracts;
  marketplaceContractId: Scalars['Int'];
  /** An object relationship */
  nft: Nfts;
  nftId: Scalars['Int'];
  originalListingId: Scalars['bigint'];
  price: Scalars['numeric'];
  seller: Scalars['String'];
  stakeCount: Scalars['Int'];
  state: Scalars['smallint'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "listings" */
export type MarketListings_Aggregate = {
  __typename?: 'marketListings_aggregate';
  aggregate?: Maybe<MarketListings_Aggregate_Fields>;
  nodes: Array<MarketListings>;
};

/** aggregate fields of "listings" */
export type MarketListings_Aggregate_Fields = {
  __typename?: 'marketListings_aggregate_fields';
  avg?: Maybe<MarketListings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MarketListings_Max_Fields>;
  min?: Maybe<MarketListings_Min_Fields>;
  stddev?: Maybe<MarketListings_Stddev_Fields>;
  stddev_pop?: Maybe<MarketListings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MarketListings_Stddev_Samp_Fields>;
  sum?: Maybe<MarketListings_Sum_Fields>;
  var_pop?: Maybe<MarketListings_Var_Pop_Fields>;
  var_samp?: Maybe<MarketListings_Var_Samp_Fields>;
  variance?: Maybe<MarketListings_Variance_Fields>;
};

/** aggregate fields of "listings" */
export type MarketListings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MarketListings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MarketListings_Avg_Fields = {
  __typename?: 'marketListings_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marketplaceContractId?: Maybe<Scalars['Float']>;
  nftId?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "listings". All fields are combined with a logical 'AND'. */
export type MarketListings_Bool_Exp = {
  _and?: InputMaybe<Array<MarketListings_Bool_Exp>>;
  _not?: InputMaybe<MarketListings_Bool_Exp>;
  _or?: InputMaybe<Array<MarketListings_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  blockchainId?: InputMaybe<Smallint_Comparison_Exp>;
  buyer?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  endedAt?: InputMaybe<Int_Comparison_Exp>;
  erc20Address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isErc721?: InputMaybe<Boolean_Comparison_Exp>;
  lastBidder?: InputMaybe<String_Comparison_Exp>;
  marketplaceContract?: InputMaybe<MarketplaceContracts_Bool_Exp>;
  marketplaceContractId?: InputMaybe<Int_Comparison_Exp>;
  nft?: InputMaybe<Nfts_Bool_Exp>;
  nftId?: InputMaybe<Int_Comparison_Exp>;
  originalListingId?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  seller?: InputMaybe<String_Comparison_Exp>;
  stakeCount?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<Smallint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "listings" */
export enum MarketListings_Constraint {
  /** unique or primary key constraint on columns "originalListingId" */
  ListingsOriginalListingIdKey = 'listings_originalListingId_key',
  /** unique or primary key constraint on columns "id" */
  ListingsPkey = 'listings_pkey',
}

/** input type for incrementing numeric columns in table "listings" */
export type MarketListings_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['smallint']>;
  endedAt?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  marketplaceContractId?: InputMaybe<Scalars['Int']>;
  nftId?: InputMaybe<Scalars['Int']>;
  originalListingId?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  stakeCount?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "listings" */
export type MarketListings_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['smallint']>;
  buyer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['Int']>;
  erc20Address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isErc721?: InputMaybe<Scalars['Boolean']>;
  lastBidder?: InputMaybe<Scalars['String']>;
  marketplaceContract?: InputMaybe<MarketplaceContracts_Obj_Rel_Insert_Input>;
  marketplaceContractId?: InputMaybe<Scalars['Int']>;
  nft?: InputMaybe<Nfts_Obj_Rel_Insert_Input>;
  nftId?: InputMaybe<Scalars['Int']>;
  originalListingId?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller?: InputMaybe<Scalars['String']>;
  stakeCount?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['smallint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MarketListings_Max_Fields = {
  __typename?: 'marketListings_max_fields';
  amount?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['smallint']>;
  buyer?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  endedAt?: Maybe<Scalars['Int']>;
  erc20Address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastBidder?: Maybe<Scalars['String']>;
  marketplaceContractId?: Maybe<Scalars['Int']>;
  nftId?: Maybe<Scalars['Int']>;
  originalListingId?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller?: Maybe<Scalars['String']>;
  stakeCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['smallint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type MarketListings_Min_Fields = {
  __typename?: 'marketListings_min_fields';
  amount?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['smallint']>;
  buyer?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  endedAt?: Maybe<Scalars['Int']>;
  erc20Address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastBidder?: Maybe<Scalars['String']>;
  marketplaceContractId?: Maybe<Scalars['Int']>;
  nftId?: Maybe<Scalars['Int']>;
  originalListingId?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller?: Maybe<Scalars['String']>;
  stakeCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['smallint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "listings" */
export type MarketListings_Mutation_Response = {
  __typename?: 'marketListings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MarketListings>;
};

/** input type for inserting object relation for remote table "listings" */
export type MarketListings_Obj_Rel_Insert_Input = {
  data: MarketListings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<MarketListings_On_Conflict>;
};

/** on_conflict condition type for table "listings" */
export type MarketListings_On_Conflict = {
  constraint: MarketListings_Constraint;
  update_columns?: Array<MarketListings_Update_Column>;
  where?: InputMaybe<MarketListings_Bool_Exp>;
};

/** Ordering options when selecting data from "listings". */
export type MarketListings_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchainId?: InputMaybe<Order_By>;
  buyer?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  erc20Address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isErc721?: InputMaybe<Order_By>;
  lastBidder?: InputMaybe<Order_By>;
  marketplaceContract?: InputMaybe<MarketplaceContracts_Order_By>;
  marketplaceContractId?: InputMaybe<Order_By>;
  nft?: InputMaybe<Nfts_Order_By>;
  nftId?: InputMaybe<Order_By>;
  originalListingId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller?: InputMaybe<Order_By>;
  stakeCount?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: listings */
export type MarketListings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "listings" */
export enum MarketListings_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  Buyer = 'buyer',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Erc20Address = 'erc20Address',
  /** column name */
  Id = 'id',
  /** column name */
  IsErc721 = 'isErc721',
  /** column name */
  LastBidder = 'lastBidder',
  /** column name */
  MarketplaceContractId = 'marketplaceContractId',
  /** column name */
  NftId = 'nftId',
  /** column name */
  OriginalListingId = 'originalListingId',
  /** column name */
  Price = 'price',
  /** column name */
  Seller = 'seller',
  /** column name */
  StakeCount = 'stakeCount',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "listings" */
export type MarketListings_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['smallint']>;
  buyer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['Int']>;
  erc20Address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isErc721?: InputMaybe<Scalars['Boolean']>;
  lastBidder?: InputMaybe<Scalars['String']>;
  marketplaceContractId?: InputMaybe<Scalars['Int']>;
  nftId?: InputMaybe<Scalars['Int']>;
  originalListingId?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller?: InputMaybe<Scalars['String']>;
  stakeCount?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['smallint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MarketListings_Stddev_Fields = {
  __typename?: 'marketListings_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marketplaceContractId?: Maybe<Scalars['Float']>;
  nftId?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MarketListings_Stddev_Pop_Fields = {
  __typename?: 'marketListings_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marketplaceContractId?: Maybe<Scalars['Float']>;
  nftId?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MarketListings_Stddev_Samp_Fields = {
  __typename?: 'marketListings_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marketplaceContractId?: Maybe<Scalars['Float']>;
  nftId?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "marketListings" */
export type MarketListings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MarketListings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketListings_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['smallint']>;
  buyer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['Int']>;
  erc20Address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isErc721?: InputMaybe<Scalars['Boolean']>;
  lastBidder?: InputMaybe<Scalars['String']>;
  marketplaceContractId?: InputMaybe<Scalars['Int']>;
  nftId?: InputMaybe<Scalars['Int']>;
  originalListingId?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller?: InputMaybe<Scalars['String']>;
  stakeCount?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['smallint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type MarketListings_Sum_Fields = {
  __typename?: 'marketListings_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['smallint']>;
  endedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  marketplaceContractId?: Maybe<Scalars['Int']>;
  nftId?: Maybe<Scalars['Int']>;
  originalListingId?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  stakeCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['smallint']>;
};

/** update columns of table "listings" */
export enum MarketListings_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  Buyer = 'buyer',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Erc20Address = 'erc20Address',
  /** column name */
  Id = 'id',
  /** column name */
  IsErc721 = 'isErc721',
  /** column name */
  LastBidder = 'lastBidder',
  /** column name */
  MarketplaceContractId = 'marketplaceContractId',
  /** column name */
  NftId = 'nftId',
  /** column name */
  OriginalListingId = 'originalListingId',
  /** column name */
  Price = 'price',
  /** column name */
  Seller = 'seller',
  /** column name */
  StakeCount = 'stakeCount',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MarketListings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MarketListings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketListings_Set_Input>;
  where: MarketListings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MarketListings_Var_Pop_Fields = {
  __typename?: 'marketListings_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marketplaceContractId?: Maybe<Scalars['Float']>;
  nftId?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MarketListings_Var_Samp_Fields = {
  __typename?: 'marketListings_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marketplaceContractId?: Maybe<Scalars['Float']>;
  nftId?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MarketListings_Variance_Fields = {
  __typename?: 'marketListings_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marketplaceContractId?: Maybe<Scalars['Float']>;
  nftId?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "marketplaceContracts" */
export type MarketplaceContracts = {
  __typename?: 'marketplaceContracts';
  blockNumber: Scalars['bigint'];
  chain: Scalars['String'];
  contractAddress: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  fee: Scalars['smallint'];
  feeRecipient: Scalars['String'];
  id: Scalars['Int'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "marketplaceContracts" */
export type MarketplaceContracts_Aggregate = {
  __typename?: 'marketplaceContracts_aggregate';
  aggregate?: Maybe<MarketplaceContracts_Aggregate_Fields>;
  nodes: Array<MarketplaceContracts>;
};

/** aggregate fields of "marketplaceContracts" */
export type MarketplaceContracts_Aggregate_Fields = {
  __typename?: 'marketplaceContracts_aggregate_fields';
  avg?: Maybe<MarketplaceContracts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MarketplaceContracts_Max_Fields>;
  min?: Maybe<MarketplaceContracts_Min_Fields>;
  stddev?: Maybe<MarketplaceContracts_Stddev_Fields>;
  stddev_pop?: Maybe<MarketplaceContracts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MarketplaceContracts_Stddev_Samp_Fields>;
  sum?: Maybe<MarketplaceContracts_Sum_Fields>;
  var_pop?: Maybe<MarketplaceContracts_Var_Pop_Fields>;
  var_samp?: Maybe<MarketplaceContracts_Var_Samp_Fields>;
  variance?: Maybe<MarketplaceContracts_Variance_Fields>;
};

/** aggregate fields of "marketplaceContracts" */
export type MarketplaceContracts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MarketplaceContracts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MarketplaceContracts_Avg_Fields = {
  __typename?: 'marketplaceContracts_avg_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "marketplaceContracts". All fields are combined with a logical 'AND'. */
export type MarketplaceContracts_Bool_Exp = {
  _and?: InputMaybe<Array<MarketplaceContracts_Bool_Exp>>;
  _not?: InputMaybe<MarketplaceContracts_Bool_Exp>;
  _or?: InputMaybe<Array<MarketplaceContracts_Bool_Exp>>;
  blockNumber?: InputMaybe<Bigint_Comparison_Exp>;
  chain?: InputMaybe<String_Comparison_Exp>;
  contractAddress?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fee?: InputMaybe<Smallint_Comparison_Exp>;
  feeRecipient?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplaceContracts" */
export enum MarketplaceContracts_Constraint {
  /** unique or primary key constraint on columns "id" */
  MarketplaceContractsPkey = 'marketplaceContracts_pkey',
}

/** input type for incrementing numeric columns in table "marketplaceContracts" */
export type MarketplaceContracts_Inc_Input = {
  blockNumber?: InputMaybe<Scalars['bigint']>;
  fee?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "marketplaceContracts" */
export type MarketplaceContracts_Insert_Input = {
  blockNumber?: InputMaybe<Scalars['bigint']>;
  chain?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fee?: InputMaybe<Scalars['smallint']>;
  feeRecipient?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MarketplaceContracts_Max_Fields = {
  __typename?: 'marketplaceContracts_max_fields';
  blockNumber?: Maybe<Scalars['bigint']>;
  chain?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fee?: Maybe<Scalars['smallint']>;
  feeRecipient?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type MarketplaceContracts_Min_Fields = {
  __typename?: 'marketplaceContracts_min_fields';
  blockNumber?: Maybe<Scalars['bigint']>;
  chain?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fee?: Maybe<Scalars['smallint']>;
  feeRecipient?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "marketplaceContracts" */
export type MarketplaceContracts_Mutation_Response = {
  __typename?: 'marketplaceContracts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MarketplaceContracts>;
};

/** input type for inserting object relation for remote table "marketplaceContracts" */
export type MarketplaceContracts_Obj_Rel_Insert_Input = {
  data: MarketplaceContracts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<MarketplaceContracts_On_Conflict>;
};

/** on_conflict condition type for table "marketplaceContracts" */
export type MarketplaceContracts_On_Conflict = {
  constraint: MarketplaceContracts_Constraint;
  update_columns?: Array<MarketplaceContracts_Update_Column>;
  where?: InputMaybe<MarketplaceContracts_Bool_Exp>;
};

/** Ordering options when selecting data from "marketplaceContracts". */
export type MarketplaceContracts_Order_By = {
  blockNumber?: InputMaybe<Order_By>;
  chain?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  feeRecipient?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: marketplaceContracts */
export type MarketplaceContracts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "marketplaceContracts" */
export enum MarketplaceContracts_Select_Column {
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  Chain = 'chain',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fee = 'fee',
  /** column name */
  FeeRecipient = 'feeRecipient',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "marketplaceContracts" */
export type MarketplaceContracts_Set_Input = {
  blockNumber?: InputMaybe<Scalars['bigint']>;
  chain?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fee?: InputMaybe<Scalars['smallint']>;
  feeRecipient?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MarketplaceContracts_Stddev_Fields = {
  __typename?: 'marketplaceContracts_stddev_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MarketplaceContracts_Stddev_Pop_Fields = {
  __typename?: 'marketplaceContracts_stddev_pop_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MarketplaceContracts_Stddev_Samp_Fields = {
  __typename?: 'marketplaceContracts_stddev_samp_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "marketplaceContracts" */
export type MarketplaceContracts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MarketplaceContracts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketplaceContracts_Stream_Cursor_Value_Input = {
  blockNumber?: InputMaybe<Scalars['bigint']>;
  chain?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fee?: InputMaybe<Scalars['smallint']>;
  feeRecipient?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type MarketplaceContracts_Sum_Fields = {
  __typename?: 'marketplaceContracts_sum_fields';
  blockNumber?: Maybe<Scalars['bigint']>;
  fee?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "marketplaceContracts" */
export enum MarketplaceContracts_Update_Column {
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  Chain = 'chain',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fee = 'fee',
  /** column name */
  FeeRecipient = 'feeRecipient',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MarketplaceContracts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MarketplaceContracts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketplaceContracts_Set_Input>;
  where: MarketplaceContracts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MarketplaceContracts_Var_Pop_Fields = {
  __typename?: 'marketplaceContracts_var_pop_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MarketplaceContracts_Var_Samp_Fields = {
  __typename?: 'marketplaceContracts_var_samp_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MarketplaceContracts_Variance_Fields = {
  __typename?: 'marketplaceContracts_variance_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  NFTDelegationAccept?: Maybe<GeneralMessageOutput>;
  NFTReleaseTicket?: Maybe<GeneralMessageOutput>;
  NFTRentOut?: Maybe<GeneralMessageOutput>;
  agentPaymentSubmitDeposit?: Maybe<DepositOutput>;
  agentPaymentSubmitWithdraw?: Maybe<StatusOutput>;
  agentPaymentSubmittedDeposit?: Maybe<StatusOutput>;
  /** delete single row from the table: "blockchain" */
  deleteBlockchain?: Maybe<Blockchain>;
  /** delete data from the table: "blockchain" */
  deleteBlockchains?: Maybe<Blockchain_Mutation_Response>;
  /** delete single row from the table: "gpanel_payments" */
  deleteGpanelPayment?: Maybe<GpanelPayments>;
  /** delete data from the table: "gpanel_payments" */
  deleteGpanelPayments?: Maybe<GpanelPayments_Mutation_Response>;
  /** delete single row from the table: "gpanel_users" */
  deleteGpanelUser?: Maybe<GpanelUsers>;
  /** delete data from the table: "gpanel_users" */
  deleteGpanelUsers?: Maybe<GpanelUsers_Mutation_Response>;
  /** delete single row from the table: "listings" */
  deleteMarketListing?: Maybe<MarketListings>;
  /** delete data from the table: "listings" */
  deleteMarketListings?: Maybe<MarketListings_Mutation_Response>;
  /** delete single row from the table: "marketplaceContracts" */
  deleteMarketplaceContract?: Maybe<MarketplaceContracts>;
  /** delete data from the table: "marketplaceContracts" */
  deleteMarketplaceContracts?: Maybe<MarketplaceContracts_Mutation_Response>;
  /** delete single row from the table: "nfts" */
  deleteNft?: Maybe<Nfts>;
  /** delete single row from the table: "nftContracts" */
  deleteNftContract?: Maybe<NftContracts>;
  /** delete data from the table: "nftContracts" */
  deleteNftContracts?: Maybe<NftContracts_Mutation_Response>;
  /** delete single row from the table: "nftMetadata" */
  deleteNftMetadata?: Maybe<NftMetadatas>;
  /** delete data from the table: "nftMetadata" */
  deleteNftMetadatas?: Maybe<NftMetadatas_Mutation_Response>;
  /** delete single row from the table: "nft_owners" */
  deleteNftOwner?: Maybe<NftOwners>;
  /** delete data from the table: "nft_owners" */
  deleteNftOwners?: Maybe<NftOwners_Mutation_Response>;
  /** delete data from the table: "nfts" */
  deleteNfts?: Maybe<Nfts_Mutation_Response>;
  /** delete single row from the table: "pending_transactions" */
  deletePendingTransaction?: Maybe<PendingTransactions>;
  /** delete data from the table: "pending_transactions" */
  deletePendingTransactions?: Maybe<PendingTransactions_Mutation_Response>;
  /** delete single row from the table: "stablecoins" */
  deleteStablecoin?: Maybe<Stablecoins>;
  /** delete data from the table: "stablecoins" */
  deleteStablecoins?: Maybe<Stablecoins_Mutation_Response>;
  /** delete data from the table: "swap_transactions" */
  deleteSwapTransactions?: Maybe<SwapTransactions_Mutation_Response>;
  /** delete single row from the table: "transactionStatistics" */
  deleteTransactionStatistic?: Maybe<TransactionStatistics>;
  /** delete data from the table: "transactionStatistics" */
  deleteTransactionStatistics?: Maybe<TransactionStatistics_Mutation_Response>;
  /** delete single row from the table: "users" */
  deleteUser?: Maybe<Users>;
  /** delete single row from the table: "user_erc721" */
  deleteUserErc721?: Maybe<UserErc721>;
  /** delete data from the table: "user_erc721" */
  deleteUserErc721s?: Maybe<UserErc721_Mutation_Response>;
  /** delete single row from the table: "user_jobs" */
  deleteUserJob?: Maybe<UserJobs>;
  /** delete data from the table: "user_jobs" */
  deleteUserJobs?: Maybe<UserJobs_Mutation_Response>;
  /** delete data from the table: "users" */
  deleteUsers?: Maybe<Users_Mutation_Response>;
  /** delete data from the table: "Test" */
  delete_Test?: Maybe<Test_Mutation_Response>;
  /** delete single row from the table: "Test" */
  delete_Test_by_pk?: Maybe<Test>;
  /** delete data from the table: "anno_gpanel" */
  delete_anno_gpanel?: Maybe<Anno_Gpanel_Mutation_Response>;
  /** delete single row from the table: "anno_gpanel" */
  delete_anno_gpanel_by_pk?: Maybe<Anno_Gpanel>;
  /** delete data from the table: "blockchain_main_deposit" */
  delete_blockchain_main_deposit?: Maybe<Blockchain_Main_Deposit_Mutation_Response>;
  /** delete single row from the table: "blockchain_main_deposit" */
  delete_blockchain_main_deposit_by_pk?: Maybe<Blockchain_Main_Deposit>;
  /** delete data from the table: "burn_profit" */
  delete_burn_profit?: Maybe<Burn_Profit_Mutation_Response>;
  /** delete single row from the table: "burn_profit" */
  delete_burn_profit_by_pk?: Maybe<Burn_Profit>;
  /** delete data from the table: "currencies" */
  delete_currencies?: Maybe<Currencies_Mutation_Response>;
  /** delete single row from the table: "currencies" */
  delete_currencies_by_pk?: Maybe<Currencies>;
  /** delete data from the table: "currency_history" */
  delete_currency_history?: Maybe<Currency_History_Mutation_Response>;
  /** delete single row from the table: "currency_history" */
  delete_currency_history_by_pk?: Maybe<Currency_History>;
  /** delete data from the table: "erc721_addresses" */
  delete_erc721_addresses?: Maybe<Erc721_Addresses_Mutation_Response>;
  /** delete single row from the table: "erc721_addresses" */
  delete_erc721_addresses_by_pk?: Maybe<Erc721_Addresses>;
  /** delete data from the table: "evenbet_ticket_requests" */
  delete_evenbet_ticket_requests?: Maybe<Evenbet_Ticket_Requests_Mutation_Response>;
  /** delete single row from the table: "evenbet_ticket_requests" */
  delete_evenbet_ticket_requests_by_pk?: Maybe<Evenbet_Ticket_Requests>;
  /** delete data from the table: "evenbet_tickets" */
  delete_evenbet_tickets?: Maybe<Evenbet_Tickets_Mutation_Response>;
  /** delete single row from the table: "evenbet_tickets" */
  delete_evenbet_tickets_by_pk?: Maybe<Evenbet_Tickets>;
  /** delete data from the table: "gpanel_users_agent" */
  delete_gpanel_users_agent?: Maybe<Gpanel_Users_Agent_Mutation_Response>;
  /** delete single row from the table: "gpanel_users_agent" */
  delete_gpanel_users_agent_by_pk?: Maybe<Gpanel_Users_Agent>;
  /** delete data from the table: "gpanel_users_deposit_address" */
  delete_gpanel_users_deposit_address?: Maybe<Gpanel_Users_Deposit_Address_Mutation_Response>;
  /** delete single row from the table: "gpanel_users_deposit_address" */
  delete_gpanel_users_deposit_address_by_pk?: Maybe<Gpanel_Users_Deposit_Address>;
  /** delete data from the table: "gpanel_users_kyc" */
  delete_gpanel_users_kyc?: Maybe<Gpanel_Users_Kyc_Mutation_Response>;
  /** delete single row from the table: "gpanel_users_kyc" */
  delete_gpanel_users_kyc_by_pk?: Maybe<Gpanel_Users_Kyc>;
  /** delete data from the table: "kyc" */
  delete_kycs?: Maybe<Kycs_Mutation_Response>;
  /** delete single row from the table: "kyc" */
  delete_kycs_by_pk?: Maybe<Kycs>;
  /** delete data from the table: "leaderboard" */
  delete_leaderboard?: Maybe<Leaderboard_Mutation_Response>;
  /** delete single row from the table: "leaderboard" */
  delete_leaderboard_by_pk?: Maybe<Leaderboard>;
  /** delete data from the table: "leaderboard_ranking" */
  delete_leaderboard_ranking?: Maybe<Leaderboard_Ranking_Mutation_Response>;
  /** delete single row from the table: "leaderboard_ranking" */
  delete_leaderboard_ranking_by_pk?: Maybe<Leaderboard_Ranking>;
  /** delete data from the table: "liquidity_pools" */
  delete_liquidity_pools?: Maybe<Liquidity_Pools_Mutation_Response>;
  /** delete single row from the table: "liquidity_pools" */
  delete_liquidity_pools_by_pk?: Maybe<Liquidity_Pools>;
  /** delete data from the table: "nft_gpanel_users" */
  delete_nft_gpanel_users?: Maybe<Nft_Gpanel_Users_Mutation_Response>;
  /** delete single row from the table: "nft_gpanel_users" */
  delete_nft_gpanel_users_by_pk?: Maybe<Nft_Gpanel_Users>;
  /** delete data from the table: "nft_listings" */
  delete_nft_listings?: Maybe<Nft_Listings_Mutation_Response>;
  /** delete single row from the table: "nft_listings" */
  delete_nft_listings_by_pk?: Maybe<Nft_Listings>;
  /** delete data from the table: "nft_transactions" */
  delete_nft_transactions?: Maybe<Nft_Transactions_Mutation_Response>;
  /** delete single row from the table: "nft_transactions" */
  delete_nft_transactions_by_pk?: Maybe<Nft_Transactions>;
  /** delete data from the table: "pending_tx" */
  delete_pending_tx?: Maybe<Pending_Tx_Mutation_Response>;
  /** delete single row from the table: "pending_tx" */
  delete_pending_tx_by_pk?: Maybe<Pending_Tx>;
  /** delete data from the table: "rakeback_lock" */
  delete_rakebackLocks?: Maybe<RakebackLocks_Mutation_Response>;
  /** delete single row from the table: "rakeback_lock" */
  delete_rakebackLocks_by_pk?: Maybe<RakebackLocks>;
  /** delete data from the table: "staking_refill" */
  delete_staking_refill?: Maybe<Staking_Refill_Mutation_Response>;
  /** delete single row from the table: "staking_refill" */
  delete_staking_refill_by_pk?: Maybe<Staking_Refill>;
  /** delete data from the table: "tournaments" */
  delete_tournaments?: Maybe<Tournaments_Mutation_Response>;
  /** delete single row from the table: "tournaments" */
  delete_tournaments_by_pk?: Maybe<Tournaments>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<Transactions_Mutation_Response>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete data from the table: "user_erc721_block" */
  delete_user_erc721_block?: Maybe<User_Erc721_Block_Mutation_Response>;
  /** delete single row from the table: "user_erc721_block" */
  delete_user_erc721_block_by_pk?: Maybe<User_Erc721_Block>;
  /** delete data from the table: "user_gpanel" */
  delete_user_gpanel?: Maybe<User_Gpanel_Mutation_Response>;
  /** delete single row from the table: "user_gpanel" */
  delete_user_gpanel_by_pk?: Maybe<User_Gpanel>;
  /** delete data from the table: "user_lock" */
  delete_user_lock?: Maybe<User_Lock_Mutation_Response>;
  /** delete single row from the table: "user_lock" */
  delete_user_lock_by_pk?: Maybe<User_Lock>;
  /** delete data from the table: "user_staking" */
  delete_user_staking?: Maybe<User_Staking_Mutation_Response>;
  /** delete single row from the table: "user_staking" */
  delete_user_staking_by_pk?: Maybe<User_Staking>;
  /** delete data from the table: "user_staking_transactions" */
  delete_user_staking_transactions?: Maybe<User_Staking_Transactions_Mutation_Response>;
  /** delete single row from the table: "user_staking_transactions" */
  delete_user_staking_transactions_by_pk?: Maybe<User_Staking_Transactions>;
  /** delete data from the table: "users_old" */
  delete_users_old?: Maybe<Users_Old_Mutation_Response>;
  /** delete single row from the table: "users_old" */
  delete_users_old_by_pk?: Maybe<Users_Old>;
  /** insert a single row into the table: "blockchain" */
  insertBlockchain?: Maybe<Blockchain>;
  /** insert data into the table: "blockchain" */
  insertBlockchains?: Maybe<Blockchain_Mutation_Response>;
  /** insert a single row into the table: "gpanel_payments" */
  insertGpanelPayment?: Maybe<GpanelPayments>;
  /** insert data into the table: "gpanel_payments" */
  insertGpanelPayments?: Maybe<GpanelPayments_Mutation_Response>;
  /** insert a single row into the table: "gpanel_users" */
  insertGpanelUser?: Maybe<GpanelUsers>;
  /** insert data into the table: "gpanel_users" */
  insertGpanelUsers?: Maybe<GpanelUsers_Mutation_Response>;
  /** insert a single row into the table: "listings" */
  insertMarketListing?: Maybe<MarketListings>;
  /** insert data into the table: "listings" */
  insertMarketListings?: Maybe<MarketListings_Mutation_Response>;
  /** insert a single row into the table: "marketplaceContracts" */
  insertMarketplaceContract?: Maybe<MarketplaceContracts>;
  /** insert data into the table: "marketplaceContracts" */
  insertMarketplaceContracts?: Maybe<MarketplaceContracts_Mutation_Response>;
  /** insert a single row into the table: "nfts" */
  insertNft?: Maybe<Nfts>;
  /** insert a single row into the table: "nftContracts" */
  insertNftContract?: Maybe<NftContracts>;
  /** insert data into the table: "nftContracts" */
  insertNftContracts?: Maybe<NftContracts_Mutation_Response>;
  /** insert a single row into the table: "nftMetadata" */
  insertNftMetadata?: Maybe<NftMetadatas>;
  /** insert data into the table: "nftMetadata" */
  insertNftMetadatas?: Maybe<NftMetadatas_Mutation_Response>;
  /** insert a single row into the table: "nft_owners" */
  insertNftOwner?: Maybe<NftOwners>;
  /** insert data into the table: "nft_owners" */
  insertNftOwners?: Maybe<NftOwners_Mutation_Response>;
  /** insert data into the table: "nfts" */
  insertNfts?: Maybe<Nfts_Mutation_Response>;
  /** insert a single row into the table: "pending_transactions" */
  insertPendingTransaction?: Maybe<PendingTransactions>;
  /** insert data into the table: "pending_transactions" */
  insertPendingTransactions?: Maybe<PendingTransactions_Mutation_Response>;
  /** insert a single row into the table: "stablecoins" */
  insertStablecoin?: Maybe<Stablecoins>;
  /** insert data into the table: "stablecoins" */
  insertStablecoins?: Maybe<Stablecoins_Mutation_Response>;
  /** insert a single row into the table: "swap_transactions" */
  insertSwapTransaction?: Maybe<SwapTransactions>;
  /** insert data into the table: "swap_transactions" */
  insertSwapTransactions?: Maybe<SwapTransactions_Mutation_Response>;
  /** insert a single row into the table: "transactionStatistics" */
  insertTransactionStatistic?: Maybe<TransactionStatistics>;
  /** insert data into the table: "transactionStatistics" */
  insertTransactionStatistics?: Maybe<TransactionStatistics_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insertUser?: Maybe<Users>;
  /** insert a single row into the table: "user_erc721" */
  insertUserErc721?: Maybe<UserErc721>;
  /** insert data into the table: "user_erc721" */
  insertUserErc721s?: Maybe<UserErc721_Mutation_Response>;
  /** insert a single row into the table: "user_jobs" */
  insertUserJob?: Maybe<UserJobs>;
  /** insert data into the table: "user_jobs" */
  insertUserJobs?: Maybe<UserJobs_Mutation_Response>;
  /** insert data into the table: "users" */
  insertUsers?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "Test" */
  insert_Test?: Maybe<Test_Mutation_Response>;
  /** insert a single row into the table: "Test" */
  insert_Test_one?: Maybe<Test>;
  /** insert data into the table: "anno_gpanel" */
  insert_anno_gpanel?: Maybe<Anno_Gpanel_Mutation_Response>;
  /** insert a single row into the table: "anno_gpanel" */
  insert_anno_gpanel_one?: Maybe<Anno_Gpanel>;
  /** insert data into the table: "blockchain_main_deposit" */
  insert_blockchain_main_deposit?: Maybe<Blockchain_Main_Deposit_Mutation_Response>;
  /** insert a single row into the table: "blockchain_main_deposit" */
  insert_blockchain_main_deposit_one?: Maybe<Blockchain_Main_Deposit>;
  /** insert data into the table: "burn_profit" */
  insert_burn_profit?: Maybe<Burn_Profit_Mutation_Response>;
  /** insert a single row into the table: "burn_profit" */
  insert_burn_profit_one?: Maybe<Burn_Profit>;
  /** insert data into the table: "currencies" */
  insert_currencies?: Maybe<Currencies_Mutation_Response>;
  /** insert a single row into the table: "currencies" */
  insert_currencies_one?: Maybe<Currencies>;
  /** insert data into the table: "currency_history" */
  insert_currency_history?: Maybe<Currency_History_Mutation_Response>;
  /** insert a single row into the table: "currency_history" */
  insert_currency_history_one?: Maybe<Currency_History>;
  /** insert data into the table: "erc721_addresses" */
  insert_erc721_addresses?: Maybe<Erc721_Addresses_Mutation_Response>;
  /** insert a single row into the table: "erc721_addresses" */
  insert_erc721_addresses_one?: Maybe<Erc721_Addresses>;
  /** insert data into the table: "evenbet_ticket_requests" */
  insert_evenbet_ticket_requests?: Maybe<Evenbet_Ticket_Requests_Mutation_Response>;
  /** insert a single row into the table: "evenbet_ticket_requests" */
  insert_evenbet_ticket_requests_one?: Maybe<Evenbet_Ticket_Requests>;
  /** insert data into the table: "evenbet_tickets" */
  insert_evenbet_tickets?: Maybe<Evenbet_Tickets_Mutation_Response>;
  /** insert a single row into the table: "evenbet_tickets" */
  insert_evenbet_tickets_one?: Maybe<Evenbet_Tickets>;
  /** insert data into the table: "gpanel_users_agent" */
  insert_gpanel_users_agent?: Maybe<Gpanel_Users_Agent_Mutation_Response>;
  /** insert a single row into the table: "gpanel_users_agent" */
  insert_gpanel_users_agent_one?: Maybe<Gpanel_Users_Agent>;
  /** insert data into the table: "gpanel_users_deposit_address" */
  insert_gpanel_users_deposit_address?: Maybe<Gpanel_Users_Deposit_Address_Mutation_Response>;
  /** insert a single row into the table: "gpanel_users_deposit_address" */
  insert_gpanel_users_deposit_address_one?: Maybe<Gpanel_Users_Deposit_Address>;
  /** insert data into the table: "gpanel_users_kyc" */
  insert_gpanel_users_kyc?: Maybe<Gpanel_Users_Kyc_Mutation_Response>;
  /** insert a single row into the table: "gpanel_users_kyc" */
  insert_gpanel_users_kyc_one?: Maybe<Gpanel_Users_Kyc>;
  /** insert data into the table: "kyc" */
  insert_kycs?: Maybe<Kycs_Mutation_Response>;
  /** insert a single row into the table: "kyc" */
  insert_kycs_one?: Maybe<Kycs>;
  /** insert data into the table: "leaderboard" */
  insert_leaderboard?: Maybe<Leaderboard_Mutation_Response>;
  /** insert a single row into the table: "leaderboard" */
  insert_leaderboard_one?: Maybe<Leaderboard>;
  /** insert data into the table: "leaderboard_ranking" */
  insert_leaderboard_ranking?: Maybe<Leaderboard_Ranking_Mutation_Response>;
  /** insert a single row into the table: "leaderboard_ranking" */
  insert_leaderboard_ranking_one?: Maybe<Leaderboard_Ranking>;
  /** insert data into the table: "liquidity_pools" */
  insert_liquidity_pools?: Maybe<Liquidity_Pools_Mutation_Response>;
  /** insert a single row into the table: "liquidity_pools" */
  insert_liquidity_pools_one?: Maybe<Liquidity_Pools>;
  /** insert data into the table: "nft_gpanel_users" */
  insert_nft_gpanel_users?: Maybe<Nft_Gpanel_Users_Mutation_Response>;
  /** insert a single row into the table: "nft_gpanel_users" */
  insert_nft_gpanel_users_one?: Maybe<Nft_Gpanel_Users>;
  /** insert data into the table: "nft_listings" */
  insert_nft_listings?: Maybe<Nft_Listings_Mutation_Response>;
  /** insert a single row into the table: "nft_listings" */
  insert_nft_listings_one?: Maybe<Nft_Listings>;
  /** insert data into the table: "nft_transactions" */
  insert_nft_transactions?: Maybe<Nft_Transactions_Mutation_Response>;
  /** insert a single row into the table: "nft_transactions" */
  insert_nft_transactions_one?: Maybe<Nft_Transactions>;
  /** insert data into the table: "pending_tx" */
  insert_pending_tx?: Maybe<Pending_Tx_Mutation_Response>;
  /** insert a single row into the table: "pending_tx" */
  insert_pending_tx_one?: Maybe<Pending_Tx>;
  /** insert data into the table: "rakeback_lock" */
  insert_rakebackLocks?: Maybe<RakebackLocks_Mutation_Response>;
  /** insert a single row into the table: "rakeback_lock" */
  insert_rakebackLocks_one?: Maybe<RakebackLocks>;
  /** insert data into the table: "staking_refill" */
  insert_staking_refill?: Maybe<Staking_Refill_Mutation_Response>;
  /** insert a single row into the table: "staking_refill" */
  insert_staking_refill_one?: Maybe<Staking_Refill>;
  /** insert data into the table: "tournaments" */
  insert_tournaments?: Maybe<Tournaments_Mutation_Response>;
  /** insert a single row into the table: "tournaments" */
  insert_tournaments_one?: Maybe<Tournaments>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<Transactions_Mutation_Response>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** insert data into the table: "user_erc721_block" */
  insert_user_erc721_block?: Maybe<User_Erc721_Block_Mutation_Response>;
  /** insert a single row into the table: "user_erc721_block" */
  insert_user_erc721_block_one?: Maybe<User_Erc721_Block>;
  /** insert data into the table: "user_gpanel" */
  insert_user_gpanel?: Maybe<User_Gpanel_Mutation_Response>;
  /** insert a single row into the table: "user_gpanel" */
  insert_user_gpanel_one?: Maybe<User_Gpanel>;
  /** insert data into the table: "user_lock" */
  insert_user_lock?: Maybe<User_Lock_Mutation_Response>;
  /** insert a single row into the table: "user_lock" */
  insert_user_lock_one?: Maybe<User_Lock>;
  /** insert data into the table: "user_staking" */
  insert_user_staking?: Maybe<User_Staking_Mutation_Response>;
  /** insert a single row into the table: "user_staking" */
  insert_user_staking_one?: Maybe<User_Staking>;
  /** insert data into the table: "user_staking_transactions" */
  insert_user_staking_transactions?: Maybe<User_Staking_Transactions_Mutation_Response>;
  /** insert a single row into the table: "user_staking_transactions" */
  insert_user_staking_transactions_one?: Maybe<User_Staking_Transactions>;
  /** insert data into the table: "users_old" */
  insert_users_old?: Maybe<Users_Old_Mutation_Response>;
  /** insert a single row into the table: "users_old" */
  insert_users_old_one?: Maybe<Users_Old>;
  paymentSubmitDeposit?: Maybe<DepositOutput>;
  paymentSubmitKYC?: Maybe<KycSubmitOutput>;
  paymentSubmitWithdraw?: Maybe<StatusOutput>;
  paymentSubmitWithdraw2FA1?: Maybe<StatusOutput>;
  paymentSubmitWithdraw2FA2?: Maybe<StatusOutput>;
  paymentSubmitWithdraw2FAresend?: Maybe<StatusOutput>;
  paymentSubmittedDeposit?: Maybe<StatusOutput>;
  refreshGpanel?: Maybe<GPanelOutput>;
  /** setGpanelUUID */
  setGpanelUUID?: Maybe<GeneralMessageOutput>;
  setNFTusername?: Maybe<GeneralMessageOutput>;
  swapTokens?: Maybe<SwapOutput>;
  tournamentCreate?: Maybe<GeneralMessageOutput>;
  updateAvailableAvatarNFTs: Scalars['uuid'];
  updateAvatar?: Maybe<GeneralMessageOutput>;
  /** update single row of the table: "blockchain" */
  updateBlockchain?: Maybe<Blockchain>;
  /** update data of the table: "blockchain" */
  updateBlockchains?: Maybe<Blockchain_Mutation_Response>;
  /** update single row of the table: "gpanel_payments" */
  updateGpanelPayment?: Maybe<GpanelPayments>;
  /** update data of the table: "gpanel_payments" */
  updateGpanelPayments?: Maybe<GpanelPayments_Mutation_Response>;
  /** update single row of the table: "gpanel_users" */
  updateGpanelUser?: Maybe<GpanelUsers>;
  /** update data of the table: "gpanel_users" */
  updateGpanelUsers?: Maybe<GpanelUsers_Mutation_Response>;
  /** update multiples rows of table: "swap_transactions" */
  updateManySwapTransactions?: Maybe<
    Array<Maybe<SwapTransactions_Mutation_Response>>
  >;
  /** update single row of the table: "listings" */
  updateMarketListing?: Maybe<MarketListings>;
  /** update data of the table: "listings" */
  updateMarketListings?: Maybe<MarketListings_Mutation_Response>;
  /** update single row of the table: "marketplaceContracts" */
  updateMarketplaceContract?: Maybe<MarketplaceContracts>;
  /** update data of the table: "marketplaceContracts" */
  updateMarketplaceContracts?: Maybe<MarketplaceContracts_Mutation_Response>;
  updateNFTListings?: Maybe<GeneralMessageOutput>;
  /** update single row of the table: "nfts" */
  updateNft?: Maybe<Nfts>;
  /** update single row of the table: "nftContracts" */
  updateNftContract?: Maybe<NftContracts>;
  /** update data of the table: "nftContracts" */
  updateNftContracts?: Maybe<NftContracts_Mutation_Response>;
  /** update single row of the table: "nftMetadata" */
  updateNftMetadata?: Maybe<NftMetadatas>;
  /** update data of the table: "nftMetadata" */
  updateNftMetadatas?: Maybe<NftMetadatas_Mutation_Response>;
  /** update single row of the table: "nft_owners" */
  updateNftOwner?: Maybe<NftOwners>;
  /** update data of the table: "nft_owners" */
  updateNftOwners?: Maybe<NftOwners_Mutation_Response>;
  /** update data of the table: "nfts" */
  updateNfts?: Maybe<Nfts_Mutation_Response>;
  /** update single row of the table: "pending_transactions" */
  updatePendingTransaction?: Maybe<PendingTransactions>;
  /** update data of the table: "pending_transactions" */
  updatePendingTransactions?: Maybe<PendingTransactions_Mutation_Response>;
  /** update single row of the table: "stablecoins" */
  updateStablecoin?: Maybe<Stablecoins>;
  /** update data of the table: "stablecoins" */
  updateStablecoins?: Maybe<Stablecoins_Mutation_Response>;
  /** update data of the table: "swap_transactions" */
  updateSwapTransactions?: Maybe<SwapTransactions_Mutation_Response>;
  /** update single row of the table: "transactionStatistics" */
  updateTransactionStatistic?: Maybe<TransactionStatistics>;
  /** update data of the table: "transactionStatistics" */
  updateTransactionStatistics?: Maybe<TransactionStatistics_Mutation_Response>;
  /** update single row of the table: "users" */
  updateUser?: Maybe<Users>;
  /** update single row of the table: "user_erc721" */
  updateUserErc721?: Maybe<UserErc721>;
  /** update data of the table: "user_erc721" */
  updateUserErc721s?: Maybe<UserErc721_Mutation_Response>;
  /** update single row of the table: "user_jobs" */
  updateUserJob?: Maybe<UserJobs>;
  /** update data of the table: "user_jobs" */
  updateUserJobs?: Maybe<UserJobs_Mutation_Response>;
  /** update data of the table: "users" */
  updateUsers?: Maybe<Users_Mutation_Response>;
  /** update data of the table: "Test" */
  update_Test?: Maybe<Test_Mutation_Response>;
  /** update single row of the table: "Test" */
  update_Test_by_pk?: Maybe<Test>;
  /** update multiples rows of table: "Test" */
  update_Test_many?: Maybe<Array<Maybe<Test_Mutation_Response>>>;
  /** update data of the table: "anno_gpanel" */
  update_anno_gpanel?: Maybe<Anno_Gpanel_Mutation_Response>;
  /** update single row of the table: "anno_gpanel" */
  update_anno_gpanel_by_pk?: Maybe<Anno_Gpanel>;
  /** update multiples rows of table: "anno_gpanel" */
  update_anno_gpanel_many?: Maybe<Array<Maybe<Anno_Gpanel_Mutation_Response>>>;
  /** update data of the table: "blockchain_main_deposit" */
  update_blockchain_main_deposit?: Maybe<Blockchain_Main_Deposit_Mutation_Response>;
  /** update single row of the table: "blockchain_main_deposit" */
  update_blockchain_main_deposit_by_pk?: Maybe<Blockchain_Main_Deposit>;
  /** update multiples rows of table: "blockchain_main_deposit" */
  update_blockchain_main_deposit_many?: Maybe<
    Array<Maybe<Blockchain_Main_Deposit_Mutation_Response>>
  >;
  /** update multiples rows of table: "blockchain" */
  update_blockchain_many?: Maybe<Array<Maybe<Blockchain_Mutation_Response>>>;
  /** update data of the table: "burn_profit" */
  update_burn_profit?: Maybe<Burn_Profit_Mutation_Response>;
  /** update single row of the table: "burn_profit" */
  update_burn_profit_by_pk?: Maybe<Burn_Profit>;
  /** update multiples rows of table: "burn_profit" */
  update_burn_profit_many?: Maybe<Array<Maybe<Burn_Profit_Mutation_Response>>>;
  /** update data of the table: "currencies" */
  update_currencies?: Maybe<Currencies_Mutation_Response>;
  /** update single row of the table: "currencies" */
  update_currencies_by_pk?: Maybe<Currencies>;
  /** update multiples rows of table: "currencies" */
  update_currencies_many?: Maybe<Array<Maybe<Currencies_Mutation_Response>>>;
  /** update data of the table: "currency_history" */
  update_currency_history?: Maybe<Currency_History_Mutation_Response>;
  /** update single row of the table: "currency_history" */
  update_currency_history_by_pk?: Maybe<Currency_History>;
  /** update multiples rows of table: "currency_history" */
  update_currency_history_many?: Maybe<
    Array<Maybe<Currency_History_Mutation_Response>>
  >;
  /** update data of the table: "erc721_addresses" */
  update_erc721_addresses?: Maybe<Erc721_Addresses_Mutation_Response>;
  /** update single row of the table: "erc721_addresses" */
  update_erc721_addresses_by_pk?: Maybe<Erc721_Addresses>;
  /** update multiples rows of table: "erc721_addresses" */
  update_erc721_addresses_many?: Maybe<
    Array<Maybe<Erc721_Addresses_Mutation_Response>>
  >;
  /** update data of the table: "evenbet_ticket_requests" */
  update_evenbet_ticket_requests?: Maybe<Evenbet_Ticket_Requests_Mutation_Response>;
  /** update single row of the table: "evenbet_ticket_requests" */
  update_evenbet_ticket_requests_by_pk?: Maybe<Evenbet_Ticket_Requests>;
  /** update multiples rows of table: "evenbet_ticket_requests" */
  update_evenbet_ticket_requests_many?: Maybe<
    Array<Maybe<Evenbet_Ticket_Requests_Mutation_Response>>
  >;
  /** update data of the table: "evenbet_tickets" */
  update_evenbet_tickets?: Maybe<Evenbet_Tickets_Mutation_Response>;
  /** update single row of the table: "evenbet_tickets" */
  update_evenbet_tickets_by_pk?: Maybe<Evenbet_Tickets>;
  /** update multiples rows of table: "evenbet_tickets" */
  update_evenbet_tickets_many?: Maybe<
    Array<Maybe<Evenbet_Tickets_Mutation_Response>>
  >;
  /** update multiples rows of table: "gpanel_payments" */
  update_gpanelPayments_many?: Maybe<
    Array<Maybe<GpanelPayments_Mutation_Response>>
  >;
  /** update multiples rows of table: "gpanel_users" */
  update_gpanelUsers_many?: Maybe<Array<Maybe<GpanelUsers_Mutation_Response>>>;
  /** update data of the table: "gpanel_users_agent" */
  update_gpanel_users_agent?: Maybe<Gpanel_Users_Agent_Mutation_Response>;
  /** update single row of the table: "gpanel_users_agent" */
  update_gpanel_users_agent_by_pk?: Maybe<Gpanel_Users_Agent>;
  /** update multiples rows of table: "gpanel_users_agent" */
  update_gpanel_users_agent_many?: Maybe<
    Array<Maybe<Gpanel_Users_Agent_Mutation_Response>>
  >;
  /** update data of the table: "gpanel_users_deposit_address" */
  update_gpanel_users_deposit_address?: Maybe<Gpanel_Users_Deposit_Address_Mutation_Response>;
  /** update single row of the table: "gpanel_users_deposit_address" */
  update_gpanel_users_deposit_address_by_pk?: Maybe<Gpanel_Users_Deposit_Address>;
  /** update multiples rows of table: "gpanel_users_deposit_address" */
  update_gpanel_users_deposit_address_many?: Maybe<
    Array<Maybe<Gpanel_Users_Deposit_Address_Mutation_Response>>
  >;
  /** update data of the table: "gpanel_users_kyc" */
  update_gpanel_users_kyc?: Maybe<Gpanel_Users_Kyc_Mutation_Response>;
  /** update single row of the table: "gpanel_users_kyc" */
  update_gpanel_users_kyc_by_pk?: Maybe<Gpanel_Users_Kyc>;
  /** update multiples rows of table: "gpanel_users_kyc" */
  update_gpanel_users_kyc_many?: Maybe<
    Array<Maybe<Gpanel_Users_Kyc_Mutation_Response>>
  >;
  /** update data of the table: "kyc" */
  update_kycs?: Maybe<Kycs_Mutation_Response>;
  /** update single row of the table: "kyc" */
  update_kycs_by_pk?: Maybe<Kycs>;
  /** update multiples rows of table: "kyc" */
  update_kycs_many?: Maybe<Array<Maybe<Kycs_Mutation_Response>>>;
  /** update data of the table: "leaderboard" */
  update_leaderboard?: Maybe<Leaderboard_Mutation_Response>;
  /** update single row of the table: "leaderboard" */
  update_leaderboard_by_pk?: Maybe<Leaderboard>;
  /** update multiples rows of table: "leaderboard" */
  update_leaderboard_many?: Maybe<Array<Maybe<Leaderboard_Mutation_Response>>>;
  /** update data of the table: "leaderboard_ranking" */
  update_leaderboard_ranking?: Maybe<Leaderboard_Ranking_Mutation_Response>;
  /** update single row of the table: "leaderboard_ranking" */
  update_leaderboard_ranking_by_pk?: Maybe<Leaderboard_Ranking>;
  /** update multiples rows of table: "leaderboard_ranking" */
  update_leaderboard_ranking_many?: Maybe<
    Array<Maybe<Leaderboard_Ranking_Mutation_Response>>
  >;
  /** update data of the table: "liquidity_pools" */
  update_liquidity_pools?: Maybe<Liquidity_Pools_Mutation_Response>;
  /** update single row of the table: "liquidity_pools" */
  update_liquidity_pools_by_pk?: Maybe<Liquidity_Pools>;
  /** update multiples rows of table: "liquidity_pools" */
  update_liquidity_pools_many?: Maybe<
    Array<Maybe<Liquidity_Pools_Mutation_Response>>
  >;
  /** update multiples rows of table: "listings" */
  update_marketListings_many?: Maybe<
    Array<Maybe<MarketListings_Mutation_Response>>
  >;
  /** update multiples rows of table: "marketplaceContracts" */
  update_marketplaceContracts_many?: Maybe<
    Array<Maybe<MarketplaceContracts_Mutation_Response>>
  >;
  /** update multiples rows of table: "nftContracts" */
  update_nftContracts_many?: Maybe<
    Array<Maybe<NftContracts_Mutation_Response>>
  >;
  /** update multiples rows of table: "nftMetadata" */
  update_nftMetadatas_many?: Maybe<
    Array<Maybe<NftMetadatas_Mutation_Response>>
  >;
  /** update multiples rows of table: "nft_owners" */
  update_nftOwners_many?: Maybe<Array<Maybe<NftOwners_Mutation_Response>>>;
  /** update data of the table: "nft_gpanel_users" */
  update_nft_gpanel_users?: Maybe<Nft_Gpanel_Users_Mutation_Response>;
  /** update single row of the table: "nft_gpanel_users" */
  update_nft_gpanel_users_by_pk?: Maybe<Nft_Gpanel_Users>;
  /** update multiples rows of table: "nft_gpanel_users" */
  update_nft_gpanel_users_many?: Maybe<
    Array<Maybe<Nft_Gpanel_Users_Mutation_Response>>
  >;
  /** update data of the table: "nft_listings" */
  update_nft_listings?: Maybe<Nft_Listings_Mutation_Response>;
  /** update single row of the table: "nft_listings" */
  update_nft_listings_by_pk?: Maybe<Nft_Listings>;
  /** update multiples rows of table: "nft_listings" */
  update_nft_listings_many?: Maybe<
    Array<Maybe<Nft_Listings_Mutation_Response>>
  >;
  /** update data of the table: "nft_transactions" */
  update_nft_transactions?: Maybe<Nft_Transactions_Mutation_Response>;
  /** update single row of the table: "nft_transactions" */
  update_nft_transactions_by_pk?: Maybe<Nft_Transactions>;
  /** update multiples rows of table: "nft_transactions" */
  update_nft_transactions_many?: Maybe<
    Array<Maybe<Nft_Transactions_Mutation_Response>>
  >;
  /** update multiples rows of table: "nfts" */
  update_nfts_many?: Maybe<Array<Maybe<Nfts_Mutation_Response>>>;
  /** update multiples rows of table: "pending_transactions" */
  update_pendingTransactions_many?: Maybe<
    Array<Maybe<PendingTransactions_Mutation_Response>>
  >;
  /** update data of the table: "pending_tx" */
  update_pending_tx?: Maybe<Pending_Tx_Mutation_Response>;
  /** update single row of the table: "pending_tx" */
  update_pending_tx_by_pk?: Maybe<Pending_Tx>;
  /** update multiples rows of table: "pending_tx" */
  update_pending_tx_many?: Maybe<Array<Maybe<Pending_Tx_Mutation_Response>>>;
  /** update data of the table: "rakeback_lock" */
  update_rakebackLocks?: Maybe<RakebackLocks_Mutation_Response>;
  /** update single row of the table: "rakeback_lock" */
  update_rakebackLocks_by_pk?: Maybe<RakebackLocks>;
  /** update multiples rows of table: "rakeback_lock" */
  update_rakebackLocks_many?: Maybe<
    Array<Maybe<RakebackLocks_Mutation_Response>>
  >;
  /** update multiples rows of table: "stablecoins" */
  update_stablecoins_many?: Maybe<Array<Maybe<Stablecoins_Mutation_Response>>>;
  /** update data of the table: "staking_refill" */
  update_staking_refill?: Maybe<Staking_Refill_Mutation_Response>;
  /** update single row of the table: "staking_refill" */
  update_staking_refill_by_pk?: Maybe<Staking_Refill>;
  /** update multiples rows of table: "staking_refill" */
  update_staking_refill_many?: Maybe<
    Array<Maybe<Staking_Refill_Mutation_Response>>
  >;
  /** update data of the table: "tournaments" */
  update_tournaments?: Maybe<Tournaments_Mutation_Response>;
  /** update single row of the table: "tournaments" */
  update_tournaments_by_pk?: Maybe<Tournaments>;
  /** update multiples rows of table: "tournaments" */
  update_tournaments_many?: Maybe<Array<Maybe<Tournaments_Mutation_Response>>>;
  /** update multiples rows of table: "transactionStatistics" */
  update_transactionStatistics_many?: Maybe<
    Array<Maybe<TransactionStatistics_Mutation_Response>>
  >;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<Transactions_Mutation_Response>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update multiples rows of table: "transactions" */
  update_transactions_many?: Maybe<
    Array<Maybe<Transactions_Mutation_Response>>
  >;
  /** update multiples rows of table: "user_erc721" */
  update_userErc721_many?: Maybe<Array<Maybe<UserErc721_Mutation_Response>>>;
  /** update multiples rows of table: "user_jobs" */
  update_userJobs_many?: Maybe<Array<Maybe<UserJobs_Mutation_Response>>>;
  /** update data of the table: "user_erc721_block" */
  update_user_erc721_block?: Maybe<User_Erc721_Block_Mutation_Response>;
  /** update single row of the table: "user_erc721_block" */
  update_user_erc721_block_by_pk?: Maybe<User_Erc721_Block>;
  /** update multiples rows of table: "user_erc721_block" */
  update_user_erc721_block_many?: Maybe<
    Array<Maybe<User_Erc721_Block_Mutation_Response>>
  >;
  /** update data of the table: "user_gpanel" */
  update_user_gpanel?: Maybe<User_Gpanel_Mutation_Response>;
  /** update single row of the table: "user_gpanel" */
  update_user_gpanel_by_pk?: Maybe<User_Gpanel>;
  /** update multiples rows of table: "user_gpanel" */
  update_user_gpanel_many?: Maybe<Array<Maybe<User_Gpanel_Mutation_Response>>>;
  /** update data of the table: "user_lock" */
  update_user_lock?: Maybe<User_Lock_Mutation_Response>;
  /** update single row of the table: "user_lock" */
  update_user_lock_by_pk?: Maybe<User_Lock>;
  /** update multiples rows of table: "user_lock" */
  update_user_lock_many?: Maybe<Array<Maybe<User_Lock_Mutation_Response>>>;
  /** update data of the table: "user_staking" */
  update_user_staking?: Maybe<User_Staking_Mutation_Response>;
  /** update single row of the table: "user_staking" */
  update_user_staking_by_pk?: Maybe<User_Staking>;
  /** update multiples rows of table: "user_staking" */
  update_user_staking_many?: Maybe<
    Array<Maybe<User_Staking_Mutation_Response>>
  >;
  /** update data of the table: "user_staking_transactions" */
  update_user_staking_transactions?: Maybe<User_Staking_Transactions_Mutation_Response>;
  /** update single row of the table: "user_staking_transactions" */
  update_user_staking_transactions_by_pk?: Maybe<User_Staking_Transactions>;
  /** update multiples rows of table: "user_staking_transactions" */
  update_user_staking_transactions_many?: Maybe<
    Array<Maybe<User_Staking_Transactions_Mutation_Response>>
  >;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "users_old" */
  update_users_old?: Maybe<Users_Old_Mutation_Response>;
  /** update single row of the table: "users_old" */
  update_users_old_by_pk?: Maybe<Users_Old>;
  /** update multiples rows of table: "users_old" */
  update_users_old_many?: Maybe<Array<Maybe<Users_Old_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootNftDelegationAcceptArgs = {
  accept: Scalars['Boolean'];
  nftIds: Array<InputMaybe<Scalars['Int']>>;
};

/** mutation root */
export type Mutation_RootNftReleaseTicketArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  ticketId: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootNftRentOutArgs = {
  blockchainId: Scalars['Int'];
  delegate: Scalars['Boolean'];
  gPanelUserId?: InputMaybe<Scalars['String']>;
  nftIds: Array<Scalars['Int']>;
};

/** mutation root */
export type Mutation_RootAgentPaymentSubmitDepositArgs = {
  address: Scalars['String'];
  amount: Scalars['Float'];
  blockchainId?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  gpanelUserId: Scalars['String'];
  stablecoinId?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type Mutation_RootAgentPaymentSubmitWithdrawArgs = {
  agentGpanelUserId: Scalars['String'];
  amount: Scalars['Float'];
  blockchainId?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  gpanelAccessToken: Scalars['String'];
  gpanelUserId: Scalars['String'];
  stablecoinId?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type Mutation_RootAgentPaymentSubmittedDepositArgs = {
  id: Scalars['String'];
  tx: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteBlockchainArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteBlockchainsArgs = {
  where: Blockchain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteGpanelPaymentArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteGpanelPaymentsArgs = {
  where: GpanelPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteGpanelUserArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteGpanelUsersArgs = {
  where: GpanelUsers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteMarketListingArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteMarketListingsArgs = {
  where: MarketListings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteMarketplaceContractArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteMarketplaceContractsArgs = {
  where: MarketplaceContracts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteNftArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteNftContractArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteNftContractsArgs = {
  where: NftContracts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteNftMetadataArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteNftMetadatasArgs = {
  where: NftMetadatas_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteNftOwnerArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteNftOwnersArgs = {
  where: NftOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteNftsArgs = {
  where: Nfts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeletePendingTransactionArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePendingTransactionsArgs = {
  where: PendingTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteStablecoinArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteStablecoinsArgs = {
  where: Stablecoins_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteSwapTransactionsArgs = {
  where: SwapTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteTransactionStatisticArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteTransactionStatisticsArgs = {
  where: TransactionStatistics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteUserErc721Args = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDeleteUserErc721sArgs = {
  where: UserErc721_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteUserJobArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteUserJobsArgs = {
  where: UserJobs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TestArgs = {
  where: Test_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Test_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Anno_GpanelArgs = {
  where: Anno_Gpanel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Anno_Gpanel_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Blockchain_Main_DepositArgs = {
  where: Blockchain_Main_Deposit_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Blockchain_Main_Deposit_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Burn_ProfitArgs = {
  where: Burn_Profit_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Burn_Profit_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_CurrenciesArgs = {
  where: Currencies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Currencies_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Currency_HistoryArgs = {
  where: Currency_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Currency_History_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Erc721_AddressesArgs = {
  where: Erc721_Addresses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Erc721_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Evenbet_Ticket_RequestsArgs = {
  where: Evenbet_Ticket_Requests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evenbet_Ticket_Requests_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Evenbet_TicketsArgs = {
  where: Evenbet_Tickets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evenbet_Tickets_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Gpanel_Users_AgentArgs = {
  where: Gpanel_Users_Agent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Gpanel_Users_Agent_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Gpanel_Users_Deposit_AddressArgs = {
  where: Gpanel_Users_Deposit_Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Gpanel_Users_Deposit_Address_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Gpanel_Users_KycArgs = {
  where: Gpanel_Users_Kyc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Gpanel_Users_Kyc_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_KycsArgs = {
  where: Kycs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kycs_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_LeaderboardArgs = {
  where: Leaderboard_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Leaderboard_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Leaderboard_RankingArgs = {
  where: Leaderboard_Ranking_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Leaderboard_Ranking_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Liquidity_PoolsArgs = {
  where: Liquidity_Pools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Liquidity_Pools_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Nft_Gpanel_UsersArgs = {
  where: Nft_Gpanel_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Nft_Gpanel_Users_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Nft_ListingsArgs = {
  where: Nft_Listings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Nft_Listings_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Nft_TransactionsArgs = {
  where: Nft_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Nft_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Pending_TxArgs = {
  where: Pending_Tx_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Pending_Tx_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_RakebackLocksArgs = {
  where: RakebackLocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_RakebackLocks_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Staking_RefillArgs = {
  where: Staking_Refill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Staking_Refill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_TournamentsArgs = {
  where: Tournaments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_TransactionsArgs = {
  where: Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_Erc721_BlockArgs = {
  where: User_Erc721_Block_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Erc721_Block_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_GpanelArgs = {
  where: User_Gpanel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Gpanel_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_LockArgs = {
  where: User_Lock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Lock_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_User_StakingArgs = {
  where: User_Staking_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Staking_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_Staking_TransactionsArgs = {
  where: User_Staking_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Staking_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Users_OldArgs = {
  where: Users_Old_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_Old_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootInsertBlockchainArgs = {
  object: Blockchain_Insert_Input;
  on_conflict?: InputMaybe<Blockchain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertBlockchainsArgs = {
  objects: Array<Blockchain_Insert_Input>;
  on_conflict?: InputMaybe<Blockchain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertGpanelPaymentArgs = {
  object: GpanelPayments_Insert_Input;
  on_conflict?: InputMaybe<GpanelPayments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertGpanelPaymentsArgs = {
  objects: Array<GpanelPayments_Insert_Input>;
  on_conflict?: InputMaybe<GpanelPayments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertGpanelUserArgs = {
  object: GpanelUsers_Insert_Input;
  on_conflict?: InputMaybe<GpanelUsers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertGpanelUsersArgs = {
  objects: Array<GpanelUsers_Insert_Input>;
  on_conflict?: InputMaybe<GpanelUsers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertMarketListingArgs = {
  object: MarketListings_Insert_Input;
  on_conflict?: InputMaybe<MarketListings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertMarketListingsArgs = {
  objects: Array<MarketListings_Insert_Input>;
  on_conflict?: InputMaybe<MarketListings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertMarketplaceContractArgs = {
  object: MarketplaceContracts_Insert_Input;
  on_conflict?: InputMaybe<MarketplaceContracts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertMarketplaceContractsArgs = {
  objects: Array<MarketplaceContracts_Insert_Input>;
  on_conflict?: InputMaybe<MarketplaceContracts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNftArgs = {
  object: Nfts_Insert_Input;
  on_conflict?: InputMaybe<Nfts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNftContractArgs = {
  object: NftContracts_Insert_Input;
  on_conflict?: InputMaybe<NftContracts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNftContractsArgs = {
  objects: Array<NftContracts_Insert_Input>;
  on_conflict?: InputMaybe<NftContracts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNftMetadataArgs = {
  object: NftMetadatas_Insert_Input;
  on_conflict?: InputMaybe<NftMetadatas_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNftMetadatasArgs = {
  objects: Array<NftMetadatas_Insert_Input>;
  on_conflict?: InputMaybe<NftMetadatas_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNftOwnerArgs = {
  object: NftOwners_Insert_Input;
  on_conflict?: InputMaybe<NftOwners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNftOwnersArgs = {
  objects: Array<NftOwners_Insert_Input>;
  on_conflict?: InputMaybe<NftOwners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNftsArgs = {
  objects: Array<Nfts_Insert_Input>;
  on_conflict?: InputMaybe<Nfts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPendingTransactionArgs = {
  object: PendingTransactions_Insert_Input;
  on_conflict?: InputMaybe<PendingTransactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPendingTransactionsArgs = {
  objects: Array<PendingTransactions_Insert_Input>;
  on_conflict?: InputMaybe<PendingTransactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertStablecoinArgs = {
  object: Stablecoins_Insert_Input;
  on_conflict?: InputMaybe<Stablecoins_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertStablecoinsArgs = {
  objects: Array<Stablecoins_Insert_Input>;
  on_conflict?: InputMaybe<Stablecoins_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertSwapTransactionArgs = {
  object: SwapTransactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsertSwapTransactionsArgs = {
  objects: Array<SwapTransactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsertTransactionStatisticArgs = {
  object: TransactionStatistics_Insert_Input;
  on_conflict?: InputMaybe<TransactionStatistics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionStatisticsArgs = {
  objects: Array<TransactionStatistics_Insert_Input>;
  on_conflict?: InputMaybe<TransactionStatistics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUserArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUserErc721Args = {
  object: UserErc721_Insert_Input;
  on_conflict?: InputMaybe<UserErc721_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUserErc721sArgs = {
  objects: Array<UserErc721_Insert_Input>;
  on_conflict?: InputMaybe<UserErc721_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUserJobArgs = {
  object: UserJobs_Insert_Input;
  on_conflict?: InputMaybe<UserJobs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUserJobsArgs = {
  objects: Array<UserJobs_Insert_Input>;
  on_conflict?: InputMaybe<UserJobs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TestArgs = {
  objects: Array<Test_Insert_Input>;
  on_conflict?: InputMaybe<Test_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Test_OneArgs = {
  object: Test_Insert_Input;
  on_conflict?: InputMaybe<Test_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Anno_GpanelArgs = {
  objects: Array<Anno_Gpanel_Insert_Input>;
  on_conflict?: InputMaybe<Anno_Gpanel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Anno_Gpanel_OneArgs = {
  object: Anno_Gpanel_Insert_Input;
  on_conflict?: InputMaybe<Anno_Gpanel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Blockchain_Main_DepositArgs = {
  objects: Array<Blockchain_Main_Deposit_Insert_Input>;
  on_conflict?: InputMaybe<Blockchain_Main_Deposit_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Blockchain_Main_Deposit_OneArgs = {
  object: Blockchain_Main_Deposit_Insert_Input;
  on_conflict?: InputMaybe<Blockchain_Main_Deposit_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Burn_ProfitArgs = {
  objects: Array<Burn_Profit_Insert_Input>;
  on_conflict?: InputMaybe<Burn_Profit_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Burn_Profit_OneArgs = {
  object: Burn_Profit_Insert_Input;
  on_conflict?: InputMaybe<Burn_Profit_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CurrenciesArgs = {
  objects: Array<Currencies_Insert_Input>;
  on_conflict?: InputMaybe<Currencies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Currencies_OneArgs = {
  object: Currencies_Insert_Input;
  on_conflict?: InputMaybe<Currencies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Currency_HistoryArgs = {
  objects: Array<Currency_History_Insert_Input>;
  on_conflict?: InputMaybe<Currency_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Currency_History_OneArgs = {
  object: Currency_History_Insert_Input;
  on_conflict?: InputMaybe<Currency_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Erc721_AddressesArgs = {
  objects: Array<Erc721_Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Erc721_Addresses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Erc721_Addresses_OneArgs = {
  object: Erc721_Addresses_Insert_Input;
  on_conflict?: InputMaybe<Erc721_Addresses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evenbet_Ticket_RequestsArgs = {
  objects: Array<Evenbet_Ticket_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Evenbet_Ticket_Requests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evenbet_Ticket_Requests_OneArgs = {
  object: Evenbet_Ticket_Requests_Insert_Input;
  on_conflict?: InputMaybe<Evenbet_Ticket_Requests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evenbet_TicketsArgs = {
  objects: Array<Evenbet_Tickets_Insert_Input>;
  on_conflict?: InputMaybe<Evenbet_Tickets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evenbet_Tickets_OneArgs = {
  object: Evenbet_Tickets_Insert_Input;
  on_conflict?: InputMaybe<Evenbet_Tickets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gpanel_Users_AgentArgs = {
  objects: Array<Gpanel_Users_Agent_Insert_Input>;
  on_conflict?: InputMaybe<Gpanel_Users_Agent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gpanel_Users_Agent_OneArgs = {
  object: Gpanel_Users_Agent_Insert_Input;
  on_conflict?: InputMaybe<Gpanel_Users_Agent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gpanel_Users_Deposit_AddressArgs = {
  objects: Array<Gpanel_Users_Deposit_Address_Insert_Input>;
  on_conflict?: InputMaybe<Gpanel_Users_Deposit_Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gpanel_Users_Deposit_Address_OneArgs = {
  object: Gpanel_Users_Deposit_Address_Insert_Input;
  on_conflict?: InputMaybe<Gpanel_Users_Deposit_Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gpanel_Users_KycArgs = {
  objects: Array<Gpanel_Users_Kyc_Insert_Input>;
  on_conflict?: InputMaybe<Gpanel_Users_Kyc_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gpanel_Users_Kyc_OneArgs = {
  object: Gpanel_Users_Kyc_Insert_Input;
  on_conflict?: InputMaybe<Gpanel_Users_Kyc_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_KycsArgs = {
  objects: Array<Kycs_Insert_Input>;
  on_conflict?: InputMaybe<Kycs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Kycs_OneArgs = {
  object: Kycs_Insert_Input;
  on_conflict?: InputMaybe<Kycs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LeaderboardArgs = {
  objects: Array<Leaderboard_Insert_Input>;
  on_conflict?: InputMaybe<Leaderboard_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leaderboard_OneArgs = {
  object: Leaderboard_Insert_Input;
  on_conflict?: InputMaybe<Leaderboard_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leaderboard_RankingArgs = {
  objects: Array<Leaderboard_Ranking_Insert_Input>;
  on_conflict?: InputMaybe<Leaderboard_Ranking_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leaderboard_Ranking_OneArgs = {
  object: Leaderboard_Ranking_Insert_Input;
  on_conflict?: InputMaybe<Leaderboard_Ranking_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Liquidity_PoolsArgs = {
  objects: Array<Liquidity_Pools_Insert_Input>;
  on_conflict?: InputMaybe<Liquidity_Pools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Liquidity_Pools_OneArgs = {
  object: Liquidity_Pools_Insert_Input;
  on_conflict?: InputMaybe<Liquidity_Pools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_Gpanel_UsersArgs = {
  objects: Array<Nft_Gpanel_Users_Insert_Input>;
  on_conflict?: InputMaybe<Nft_Gpanel_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_Gpanel_Users_OneArgs = {
  object: Nft_Gpanel_Users_Insert_Input;
  on_conflict?: InputMaybe<Nft_Gpanel_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_ListingsArgs = {
  objects: Array<Nft_Listings_Insert_Input>;
  on_conflict?: InputMaybe<Nft_Listings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_Listings_OneArgs = {
  object: Nft_Listings_Insert_Input;
  on_conflict?: InputMaybe<Nft_Listings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_TransactionsArgs = {
  objects: Array<Nft_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Nft_Transactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_Transactions_OneArgs = {
  object: Nft_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Nft_Transactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Pending_TxArgs = {
  objects: Array<Pending_Tx_Insert_Input>;
  on_conflict?: InputMaybe<Pending_Tx_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Pending_Tx_OneArgs = {
  object: Pending_Tx_Insert_Input;
  on_conflict?: InputMaybe<Pending_Tx_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RakebackLocksArgs = {
  objects: Array<RakebackLocks_Insert_Input>;
  on_conflict?: InputMaybe<RakebackLocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RakebackLocks_OneArgs = {
  object: RakebackLocks_Insert_Input;
  on_conflict?: InputMaybe<RakebackLocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staking_RefillArgs = {
  objects: Array<Staking_Refill_Insert_Input>;
  on_conflict?: InputMaybe<Staking_Refill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staking_Refill_OneArgs = {
  object: Staking_Refill_Insert_Input;
  on_conflict?: InputMaybe<Staking_Refill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TournamentsArgs = {
  objects: Array<Tournaments_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_OneArgs = {
  object: Tournaments_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TransactionsArgs = {
  objects: Array<Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Transactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Transactions_OneArgs = {
  object: Transactions_Insert_Input;
  on_conflict?: InputMaybe<Transactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Erc721_BlockArgs = {
  objects: Array<User_Erc721_Block_Insert_Input>;
  on_conflict?: InputMaybe<User_Erc721_Block_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Erc721_Block_OneArgs = {
  object: User_Erc721_Block_Insert_Input;
  on_conflict?: InputMaybe<User_Erc721_Block_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_GpanelArgs = {
  objects: Array<User_Gpanel_Insert_Input>;
  on_conflict?: InputMaybe<User_Gpanel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Gpanel_OneArgs = {
  object: User_Gpanel_Insert_Input;
  on_conflict?: InputMaybe<User_Gpanel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_LockArgs = {
  objects: Array<User_Lock_Insert_Input>;
  on_conflict?: InputMaybe<User_Lock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Lock_OneArgs = {
  object: User_Lock_Insert_Input;
  on_conflict?: InputMaybe<User_Lock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_StakingArgs = {
  objects: Array<User_Staking_Insert_Input>;
  on_conflict?: InputMaybe<User_Staking_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Staking_OneArgs = {
  object: User_Staking_Insert_Input;
  on_conflict?: InputMaybe<User_Staking_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Staking_TransactionsArgs = {
  objects: Array<User_Staking_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<User_Staking_Transactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Staking_Transactions_OneArgs = {
  object: User_Staking_Transactions_Insert_Input;
  on_conflict?: InputMaybe<User_Staking_Transactions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OldArgs = {
  objects: Array<Users_Old_Insert_Input>;
  on_conflict?: InputMaybe<Users_Old_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_Old_OneArgs = {
  object: Users_Old_Insert_Input;
  on_conflict?: InputMaybe<Users_Old_On_Conflict>;
};

/** mutation root */
export type Mutation_RootPaymentSubmitDepositArgs = {
  address: Scalars['String'];
  amount: Scalars['Float'];
  blockchainId?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  gpanelUserId: Scalars['String'];
  stablecoinId?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type Mutation_RootPaymentSubmitWithdrawArgs = {
  address: Scalars['String'];
  amount: Scalars['Float'];
  blockchainId?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  gpanelAccessToken: Scalars['String'];
  gpanelUserId: Scalars['String'];
  stablecoinId?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type Mutation_RootPaymentSubmitWithdraw2Fa1Args = {
  address: Scalars['String'];
  amount: Scalars['Float'];
  blockchainId?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  gpanelAccessToken: Scalars['String'];
  gpanelUserId: Scalars['String'];
  stablecoinId?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type Mutation_RootPaymentSubmitWithdraw2Fa2Args = {
  code: Scalars['String'];
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootPaymentSubmitWithdraw2FAresendArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootPaymentSubmittedDepositArgs = {
  id: Scalars['String'];
  tx: Scalars['String'];
};

/** mutation root */
export type Mutation_RootRefreshGpanelArgs = {
  refreshToken: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSetGpanelUuidArgs = {
  uuid: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSetNfTusernameArgs = {
  email?: InputMaybe<Scalars['String']>;
  nftId: Scalars['Int'];
  username?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootSwapTokensArgs = {
  fromAmount: Scalars['Float'];
  fromCurrency: Scalars['String'];
  gpanelAccessToken: Scalars['String'];
  gpanelUserId: Scalars['String'];
  toCurrency: Scalars['String'];
  toMinimumAmount: Scalars['Float'];
};

/** mutation root */
export type Mutation_RootTournamentCreateArgs = {
  tournament: TournamentInput;
};

/** mutation root */
export type Mutation_RootUpdateAvatarArgs = {
  avatarId: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootUpdateBlockchainArgs = {
  _append?: InputMaybe<Blockchain_Append_Input>;
  _delete_at_path?: InputMaybe<Blockchain_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Blockchain_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Blockchain_Delete_Key_Input>;
  _inc?: InputMaybe<Blockchain_Inc_Input>;
  _prepend?: InputMaybe<Blockchain_Prepend_Input>;
  _set?: InputMaybe<Blockchain_Set_Input>;
  pk_columns: Blockchain_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateBlockchainsArgs = {
  _append?: InputMaybe<Blockchain_Append_Input>;
  _delete_at_path?: InputMaybe<Blockchain_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Blockchain_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Blockchain_Delete_Key_Input>;
  _inc?: InputMaybe<Blockchain_Inc_Input>;
  _prepend?: InputMaybe<Blockchain_Prepend_Input>;
  _set?: InputMaybe<Blockchain_Set_Input>;
  where: Blockchain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateGpanelPaymentArgs = {
  _inc?: InputMaybe<GpanelPayments_Inc_Input>;
  _set?: InputMaybe<GpanelPayments_Set_Input>;
  pk_columns: GpanelPayments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateGpanelPaymentsArgs = {
  _inc?: InputMaybe<GpanelPayments_Inc_Input>;
  _set?: InputMaybe<GpanelPayments_Set_Input>;
  where: GpanelPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateGpanelUserArgs = {
  _inc?: InputMaybe<GpanelUsers_Inc_Input>;
  _set?: InputMaybe<GpanelUsers_Set_Input>;
  pk_columns: GpanelUsers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateGpanelUsersArgs = {
  _inc?: InputMaybe<GpanelUsers_Inc_Input>;
  _set?: InputMaybe<GpanelUsers_Set_Input>;
  where: GpanelUsers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateManySwapTransactionsArgs = {
  updates: Array<SwapTransactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdateMarketListingArgs = {
  _inc?: InputMaybe<MarketListings_Inc_Input>;
  _set?: InputMaybe<MarketListings_Set_Input>;
  pk_columns: MarketListings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateMarketListingsArgs = {
  _inc?: InputMaybe<MarketListings_Inc_Input>;
  _set?: InputMaybe<MarketListings_Set_Input>;
  where: MarketListings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateMarketplaceContractArgs = {
  _inc?: InputMaybe<MarketplaceContracts_Inc_Input>;
  _set?: InputMaybe<MarketplaceContracts_Set_Input>;
  pk_columns: MarketplaceContracts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateMarketplaceContractsArgs = {
  _inc?: InputMaybe<MarketplaceContracts_Inc_Input>;
  _set?: InputMaybe<MarketplaceContracts_Set_Input>;
  where: MarketplaceContracts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateNftListingsArgs = {
  listingId?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type Mutation_RootUpdateNftArgs = {
  _inc?: InputMaybe<Nfts_Inc_Input>;
  _set?: InputMaybe<Nfts_Set_Input>;
  pk_columns: Nfts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateNftContractArgs = {
  _inc?: InputMaybe<NftContracts_Inc_Input>;
  _set?: InputMaybe<NftContracts_Set_Input>;
  pk_columns: NftContracts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateNftContractsArgs = {
  _inc?: InputMaybe<NftContracts_Inc_Input>;
  _set?: InputMaybe<NftContracts_Set_Input>;
  where: NftContracts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateNftMetadataArgs = {
  _inc?: InputMaybe<NftMetadatas_Inc_Input>;
  _set?: InputMaybe<NftMetadatas_Set_Input>;
  pk_columns: NftMetadatas_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateNftMetadatasArgs = {
  _inc?: InputMaybe<NftMetadatas_Inc_Input>;
  _set?: InputMaybe<NftMetadatas_Set_Input>;
  where: NftMetadatas_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateNftOwnerArgs = {
  _inc?: InputMaybe<NftOwners_Inc_Input>;
  _set?: InputMaybe<NftOwners_Set_Input>;
  pk_columns: NftOwners_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateNftOwnersArgs = {
  _inc?: InputMaybe<NftOwners_Inc_Input>;
  _set?: InputMaybe<NftOwners_Set_Input>;
  where: NftOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateNftsArgs = {
  _inc?: InputMaybe<Nfts_Inc_Input>;
  _set?: InputMaybe<Nfts_Set_Input>;
  where: Nfts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdatePendingTransactionArgs = {
  _inc?: InputMaybe<PendingTransactions_Inc_Input>;
  _set?: InputMaybe<PendingTransactions_Set_Input>;
  pk_columns: PendingTransactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdatePendingTransactionsArgs = {
  _inc?: InputMaybe<PendingTransactions_Inc_Input>;
  _set?: InputMaybe<PendingTransactions_Set_Input>;
  where: PendingTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateStablecoinArgs = {
  _inc?: InputMaybe<Stablecoins_Inc_Input>;
  _set?: InputMaybe<Stablecoins_Set_Input>;
  pk_columns: Stablecoins_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateStablecoinsArgs = {
  _inc?: InputMaybe<Stablecoins_Inc_Input>;
  _set?: InputMaybe<Stablecoins_Set_Input>;
  where: Stablecoins_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateSwapTransactionsArgs = {
  _inc?: InputMaybe<SwapTransactions_Inc_Input>;
  _set?: InputMaybe<SwapTransactions_Set_Input>;
  where: SwapTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateTransactionStatisticArgs = {
  _inc?: InputMaybe<TransactionStatistics_Inc_Input>;
  _set?: InputMaybe<TransactionStatistics_Set_Input>;
  pk_columns: TransactionStatistics_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateTransactionStatisticsArgs = {
  _inc?: InputMaybe<TransactionStatistics_Inc_Input>;
  _set?: InputMaybe<TransactionStatistics_Set_Input>;
  where: TransactionStatistics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateUserErc721Args = {
  _inc?: InputMaybe<UserErc721_Inc_Input>;
  _set?: InputMaybe<UserErc721_Set_Input>;
  pk_columns: UserErc721_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateUserErc721sArgs = {
  _inc?: InputMaybe<UserErc721_Inc_Input>;
  _set?: InputMaybe<UserErc721_Set_Input>;
  where: UserErc721_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateUserJobArgs = {
  _inc?: InputMaybe<UserJobs_Inc_Input>;
  _set?: InputMaybe<UserJobs_Set_Input>;
  pk_columns: UserJobs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateUserJobsArgs = {
  _inc?: InputMaybe<UserJobs_Inc_Input>;
  _set?: InputMaybe<UserJobs_Set_Input>;
  where: UserJobs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TestArgs = {
  _inc?: InputMaybe<Test_Inc_Input>;
  _set?: InputMaybe<Test_Set_Input>;
  where: Test_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Test_By_PkArgs = {
  _inc?: InputMaybe<Test_Inc_Input>;
  _set?: InputMaybe<Test_Set_Input>;
  pk_columns: Test_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Test_ManyArgs = {
  updates: Array<Test_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Anno_GpanelArgs = {
  _inc?: InputMaybe<Anno_Gpanel_Inc_Input>;
  _set?: InputMaybe<Anno_Gpanel_Set_Input>;
  where: Anno_Gpanel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Anno_Gpanel_By_PkArgs = {
  _inc?: InputMaybe<Anno_Gpanel_Inc_Input>;
  _set?: InputMaybe<Anno_Gpanel_Set_Input>;
  pk_columns: Anno_Gpanel_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Anno_Gpanel_ManyArgs = {
  updates: Array<Anno_Gpanel_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Blockchain_Main_DepositArgs = {
  _inc?: InputMaybe<Blockchain_Main_Deposit_Inc_Input>;
  _set?: InputMaybe<Blockchain_Main_Deposit_Set_Input>;
  where: Blockchain_Main_Deposit_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Blockchain_Main_Deposit_By_PkArgs = {
  _inc?: InputMaybe<Blockchain_Main_Deposit_Inc_Input>;
  _set?: InputMaybe<Blockchain_Main_Deposit_Set_Input>;
  pk_columns: Blockchain_Main_Deposit_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Blockchain_Main_Deposit_ManyArgs = {
  updates: Array<Blockchain_Main_Deposit_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Blockchain_ManyArgs = {
  updates: Array<Blockchain_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Burn_ProfitArgs = {
  _inc?: InputMaybe<Burn_Profit_Inc_Input>;
  _set?: InputMaybe<Burn_Profit_Set_Input>;
  where: Burn_Profit_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Burn_Profit_By_PkArgs = {
  _inc?: InputMaybe<Burn_Profit_Inc_Input>;
  _set?: InputMaybe<Burn_Profit_Set_Input>;
  pk_columns: Burn_Profit_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Burn_Profit_ManyArgs = {
  updates: Array<Burn_Profit_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CurrenciesArgs = {
  _append?: InputMaybe<Currencies_Append_Input>;
  _delete_at_path?: InputMaybe<Currencies_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Currencies_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Currencies_Delete_Key_Input>;
  _inc?: InputMaybe<Currencies_Inc_Input>;
  _prepend?: InputMaybe<Currencies_Prepend_Input>;
  _set?: InputMaybe<Currencies_Set_Input>;
  where: Currencies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Currencies_By_PkArgs = {
  _append?: InputMaybe<Currencies_Append_Input>;
  _delete_at_path?: InputMaybe<Currencies_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Currencies_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Currencies_Delete_Key_Input>;
  _inc?: InputMaybe<Currencies_Inc_Input>;
  _prepend?: InputMaybe<Currencies_Prepend_Input>;
  _set?: InputMaybe<Currencies_Set_Input>;
  pk_columns: Currencies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Currencies_ManyArgs = {
  updates: Array<Currencies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Currency_HistoryArgs = {
  _inc?: InputMaybe<Currency_History_Inc_Input>;
  _set?: InputMaybe<Currency_History_Set_Input>;
  where: Currency_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Currency_History_By_PkArgs = {
  _inc?: InputMaybe<Currency_History_Inc_Input>;
  _set?: InputMaybe<Currency_History_Set_Input>;
  pk_columns: Currency_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Currency_History_ManyArgs = {
  updates: Array<Currency_History_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Erc721_AddressesArgs = {
  _inc?: InputMaybe<Erc721_Addresses_Inc_Input>;
  _set?: InputMaybe<Erc721_Addresses_Set_Input>;
  where: Erc721_Addresses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Erc721_Addresses_By_PkArgs = {
  _inc?: InputMaybe<Erc721_Addresses_Inc_Input>;
  _set?: InputMaybe<Erc721_Addresses_Set_Input>;
  pk_columns: Erc721_Addresses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Erc721_Addresses_ManyArgs = {
  updates: Array<Erc721_Addresses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evenbet_Ticket_RequestsArgs = {
  _inc?: InputMaybe<Evenbet_Ticket_Requests_Inc_Input>;
  _set?: InputMaybe<Evenbet_Ticket_Requests_Set_Input>;
  where: Evenbet_Ticket_Requests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evenbet_Ticket_Requests_By_PkArgs = {
  _inc?: InputMaybe<Evenbet_Ticket_Requests_Inc_Input>;
  _set?: InputMaybe<Evenbet_Ticket_Requests_Set_Input>;
  pk_columns: Evenbet_Ticket_Requests_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evenbet_Ticket_Requests_ManyArgs = {
  updates: Array<Evenbet_Ticket_Requests_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evenbet_TicketsArgs = {
  _inc?: InputMaybe<Evenbet_Tickets_Inc_Input>;
  _set?: InputMaybe<Evenbet_Tickets_Set_Input>;
  where: Evenbet_Tickets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evenbet_Tickets_By_PkArgs = {
  _inc?: InputMaybe<Evenbet_Tickets_Inc_Input>;
  _set?: InputMaybe<Evenbet_Tickets_Set_Input>;
  pk_columns: Evenbet_Tickets_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evenbet_Tickets_ManyArgs = {
  updates: Array<Evenbet_Tickets_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GpanelPayments_ManyArgs = {
  updates: Array<GpanelPayments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GpanelUsers_ManyArgs = {
  updates: Array<GpanelUsers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_AgentArgs = {
  _inc?: InputMaybe<Gpanel_Users_Agent_Inc_Input>;
  _set?: InputMaybe<Gpanel_Users_Agent_Set_Input>;
  where: Gpanel_Users_Agent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_Agent_By_PkArgs = {
  _inc?: InputMaybe<Gpanel_Users_Agent_Inc_Input>;
  _set?: InputMaybe<Gpanel_Users_Agent_Set_Input>;
  pk_columns: Gpanel_Users_Agent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_Agent_ManyArgs = {
  updates: Array<Gpanel_Users_Agent_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_Deposit_AddressArgs = {
  _inc?: InputMaybe<Gpanel_Users_Deposit_Address_Inc_Input>;
  _set?: InputMaybe<Gpanel_Users_Deposit_Address_Set_Input>;
  where: Gpanel_Users_Deposit_Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_Deposit_Address_By_PkArgs = {
  _inc?: InputMaybe<Gpanel_Users_Deposit_Address_Inc_Input>;
  _set?: InputMaybe<Gpanel_Users_Deposit_Address_Set_Input>;
  pk_columns: Gpanel_Users_Deposit_Address_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_Deposit_Address_ManyArgs = {
  updates: Array<Gpanel_Users_Deposit_Address_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_KycArgs = {
  _inc?: InputMaybe<Gpanel_Users_Kyc_Inc_Input>;
  _set?: InputMaybe<Gpanel_Users_Kyc_Set_Input>;
  where: Gpanel_Users_Kyc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_Kyc_By_PkArgs = {
  _inc?: InputMaybe<Gpanel_Users_Kyc_Inc_Input>;
  _set?: InputMaybe<Gpanel_Users_Kyc_Set_Input>;
  pk_columns: Gpanel_Users_Kyc_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Gpanel_Users_Kyc_ManyArgs = {
  updates: Array<Gpanel_Users_Kyc_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_KycsArgs = {
  _inc?: InputMaybe<Kycs_Inc_Input>;
  _set?: InputMaybe<Kycs_Set_Input>;
  where: Kycs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kycs_By_PkArgs = {
  _inc?: InputMaybe<Kycs_Inc_Input>;
  _set?: InputMaybe<Kycs_Set_Input>;
  pk_columns: Kycs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Kycs_ManyArgs = {
  updates: Array<Kycs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LeaderboardArgs = {
  _inc?: InputMaybe<Leaderboard_Inc_Input>;
  _set?: InputMaybe<Leaderboard_Set_Input>;
  where: Leaderboard_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_By_PkArgs = {
  _inc?: InputMaybe<Leaderboard_Inc_Input>;
  _set?: InputMaybe<Leaderboard_Set_Input>;
  pk_columns: Leaderboard_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_ManyArgs = {
  updates: Array<Leaderboard_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_RankingArgs = {
  _inc?: InputMaybe<Leaderboard_Ranking_Inc_Input>;
  _set?: InputMaybe<Leaderboard_Ranking_Set_Input>;
  where: Leaderboard_Ranking_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Ranking_By_PkArgs = {
  _inc?: InputMaybe<Leaderboard_Ranking_Inc_Input>;
  _set?: InputMaybe<Leaderboard_Ranking_Set_Input>;
  pk_columns: Leaderboard_Ranking_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Ranking_ManyArgs = {
  updates: Array<Leaderboard_Ranking_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Liquidity_PoolsArgs = {
  _inc?: InputMaybe<Liquidity_Pools_Inc_Input>;
  _set?: InputMaybe<Liquidity_Pools_Set_Input>;
  where: Liquidity_Pools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Liquidity_Pools_By_PkArgs = {
  _inc?: InputMaybe<Liquidity_Pools_Inc_Input>;
  _set?: InputMaybe<Liquidity_Pools_Set_Input>;
  pk_columns: Liquidity_Pools_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Liquidity_Pools_ManyArgs = {
  updates: Array<Liquidity_Pools_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_MarketListings_ManyArgs = {
  updates: Array<MarketListings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_MarketplaceContracts_ManyArgs = {
  updates: Array<MarketplaceContracts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_NftContracts_ManyArgs = {
  updates: Array<NftContracts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_NftMetadatas_ManyArgs = {
  updates: Array<NftMetadatas_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_NftOwners_ManyArgs = {
  updates: Array<NftOwners_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Gpanel_UsersArgs = {
  _inc?: InputMaybe<Nft_Gpanel_Users_Inc_Input>;
  _set?: InputMaybe<Nft_Gpanel_Users_Set_Input>;
  where: Nft_Gpanel_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Gpanel_Users_By_PkArgs = {
  _inc?: InputMaybe<Nft_Gpanel_Users_Inc_Input>;
  _set?: InputMaybe<Nft_Gpanel_Users_Set_Input>;
  pk_columns: Nft_Gpanel_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Gpanel_Users_ManyArgs = {
  updates: Array<Nft_Gpanel_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_ListingsArgs = {
  _inc?: InputMaybe<Nft_Listings_Inc_Input>;
  _set?: InputMaybe<Nft_Listings_Set_Input>;
  where: Nft_Listings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Listings_By_PkArgs = {
  _inc?: InputMaybe<Nft_Listings_Inc_Input>;
  _set?: InputMaybe<Nft_Listings_Set_Input>;
  pk_columns: Nft_Listings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Listings_ManyArgs = {
  updates: Array<Nft_Listings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_TransactionsArgs = {
  _inc?: InputMaybe<Nft_Transactions_Inc_Input>;
  _set?: InputMaybe<Nft_Transactions_Set_Input>;
  where: Nft_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Nft_Transactions_Inc_Input>;
  _set?: InputMaybe<Nft_Transactions_Set_Input>;
  pk_columns: Nft_Transactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Transactions_ManyArgs = {
  updates: Array<Nft_Transactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Nfts_ManyArgs = {
  updates: Array<Nfts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PendingTransactions_ManyArgs = {
  updates: Array<PendingTransactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Pending_TxArgs = {
  _inc?: InputMaybe<Pending_Tx_Inc_Input>;
  _set?: InputMaybe<Pending_Tx_Set_Input>;
  where: Pending_Tx_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Pending_Tx_By_PkArgs = {
  _inc?: InputMaybe<Pending_Tx_Inc_Input>;
  _set?: InputMaybe<Pending_Tx_Set_Input>;
  pk_columns: Pending_Tx_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Pending_Tx_ManyArgs = {
  updates: Array<Pending_Tx_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RakebackLocksArgs = {
  _inc?: InputMaybe<RakebackLocks_Inc_Input>;
  _set?: InputMaybe<RakebackLocks_Set_Input>;
  where: RakebackLocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_RakebackLocks_By_PkArgs = {
  _inc?: InputMaybe<RakebackLocks_Inc_Input>;
  _set?: InputMaybe<RakebackLocks_Set_Input>;
  pk_columns: RakebackLocks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_RakebackLocks_ManyArgs = {
  updates: Array<RakebackLocks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stablecoins_ManyArgs = {
  updates: Array<Stablecoins_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Staking_RefillArgs = {
  _inc?: InputMaybe<Staking_Refill_Inc_Input>;
  _set?: InputMaybe<Staking_Refill_Set_Input>;
  where: Staking_Refill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Staking_Refill_By_PkArgs = {
  _inc?: InputMaybe<Staking_Refill_Inc_Input>;
  _set?: InputMaybe<Staking_Refill_Set_Input>;
  pk_columns: Staking_Refill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Staking_Refill_ManyArgs = {
  updates: Array<Staking_Refill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TournamentsArgs = {
  _inc?: InputMaybe<Tournaments_Inc_Input>;
  _set?: InputMaybe<Tournaments_Set_Input>;
  where: Tournaments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Inc_Input>;
  _set?: InputMaybe<Tournaments_Set_Input>;
  pk_columns: Tournaments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_ManyArgs = {
  updates: Array<Tournaments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TransactionStatistics_ManyArgs = {
  updates: Array<TransactionStatistics_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TransactionsArgs = {
  _append?: InputMaybe<Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Transactions_Inc_Input>;
  _prepend?: InputMaybe<Transactions_Prepend_Input>;
  _set?: InputMaybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Transactions_By_PkArgs = {
  _append?: InputMaybe<Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Transactions_Inc_Input>;
  _prepend?: InputMaybe<Transactions_Prepend_Input>;
  _set?: InputMaybe<Transactions_Set_Input>;
  pk_columns: Transactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Transactions_ManyArgs = {
  updates: Array<Transactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UserErc721_ManyArgs = {
  updates: Array<UserErc721_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UserJobs_ManyArgs = {
  updates: Array<UserJobs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Erc721_BlockArgs = {
  _inc?: InputMaybe<User_Erc721_Block_Inc_Input>;
  _set?: InputMaybe<User_Erc721_Block_Set_Input>;
  where: User_Erc721_Block_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Erc721_Block_By_PkArgs = {
  _inc?: InputMaybe<User_Erc721_Block_Inc_Input>;
  _set?: InputMaybe<User_Erc721_Block_Set_Input>;
  pk_columns: User_Erc721_Block_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Erc721_Block_ManyArgs = {
  updates: Array<User_Erc721_Block_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_GpanelArgs = {
  _inc?: InputMaybe<User_Gpanel_Inc_Input>;
  _set?: InputMaybe<User_Gpanel_Set_Input>;
  where: User_Gpanel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Gpanel_By_PkArgs = {
  _inc?: InputMaybe<User_Gpanel_Inc_Input>;
  _set?: InputMaybe<User_Gpanel_Set_Input>;
  pk_columns: User_Gpanel_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Gpanel_ManyArgs = {
  updates: Array<User_Gpanel_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_LockArgs = {
  _inc?: InputMaybe<User_Lock_Inc_Input>;
  _set?: InputMaybe<User_Lock_Set_Input>;
  where: User_Lock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Lock_By_PkArgs = {
  _inc?: InputMaybe<User_Lock_Inc_Input>;
  _set?: InputMaybe<User_Lock_Set_Input>;
  pk_columns: User_Lock_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Lock_ManyArgs = {
  updates: Array<User_Lock_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_StakingArgs = {
  _inc?: InputMaybe<User_Staking_Inc_Input>;
  _set?: InputMaybe<User_Staking_Set_Input>;
  where: User_Staking_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Staking_By_PkArgs = {
  _inc?: InputMaybe<User_Staking_Inc_Input>;
  _set?: InputMaybe<User_Staking_Set_Input>;
  pk_columns: User_Staking_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Staking_ManyArgs = {
  updates: Array<User_Staking_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Staking_TransactionsArgs = {
  _inc?: InputMaybe<User_Staking_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Staking_Transactions_Set_Input>;
  where: User_Staking_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Staking_Transactions_By_PkArgs = {
  _inc?: InputMaybe<User_Staking_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Staking_Transactions_Set_Input>;
  pk_columns: User_Staking_Transactions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Staking_Transactions_ManyArgs = {
  updates: Array<User_Staking_Transactions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_OldArgs = {
  _inc?: InputMaybe<Users_Old_Inc_Input>;
  _set?: InputMaybe<Users_Old_Set_Input>;
  where: Users_Old_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Old_By_PkArgs = {
  _inc?: InputMaybe<Users_Old_Inc_Input>;
  _set?: InputMaybe<Users_Old_Set_Input>;
  pk_columns: Users_Old_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Old_ManyArgs = {
  updates: Array<Users_Old_Updates>;
};

/** columns and relationships of "nftContracts" */
export type NftContracts = {
  __typename?: 'nftContracts';
  blockNumber?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['Int']>;
  chain: Scalars['String'];
  collectionName?: Maybe<Scalars['String']>;
  contractAddress: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  generation: Scalars['smallint'];
  id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "nftContracts" */
export type NftContracts_Aggregate = {
  __typename?: 'nftContracts_aggregate';
  aggregate?: Maybe<NftContracts_Aggregate_Fields>;
  nodes: Array<NftContracts>;
};

/** aggregate fields of "nftContracts" */
export type NftContracts_Aggregate_Fields = {
  __typename?: 'nftContracts_aggregate_fields';
  avg?: Maybe<NftContracts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NftContracts_Max_Fields>;
  min?: Maybe<NftContracts_Min_Fields>;
  stddev?: Maybe<NftContracts_Stddev_Fields>;
  stddev_pop?: Maybe<NftContracts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NftContracts_Stddev_Samp_Fields>;
  sum?: Maybe<NftContracts_Sum_Fields>;
  var_pop?: Maybe<NftContracts_Var_Pop_Fields>;
  var_samp?: Maybe<NftContracts_Var_Samp_Fields>;
  variance?: Maybe<NftContracts_Variance_Fields>;
};

/** aggregate fields of "nftContracts" */
export type NftContracts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<NftContracts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NftContracts_Avg_Fields = {
  __typename?: 'nftContracts_avg_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nftContracts". All fields are combined with a logical 'AND'. */
export type NftContracts_Bool_Exp = {
  _and?: InputMaybe<Array<NftContracts_Bool_Exp>>;
  _not?: InputMaybe<NftContracts_Bool_Exp>;
  _or?: InputMaybe<Array<NftContracts_Bool_Exp>>;
  blockNumber?: InputMaybe<Int_Comparison_Exp>;
  blockchainId?: InputMaybe<Int_Comparison_Exp>;
  chain?: InputMaybe<String_Comparison_Exp>;
  collectionName?: InputMaybe<String_Comparison_Exp>;
  contractAddress?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  generation?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nftContracts" */
export enum NftContracts_Constraint {
  /** unique or primary key constraint on columns "id" */
  NftContractsPkey = 'nftContracts_pkey',
}

/** input type for incrementing numeric columns in table "nftContracts" */
export type NftContracts_Inc_Input = {
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  generation?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nftContracts" */
export type NftContracts_Insert_Input = {
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  chain?: InputMaybe<Scalars['String']>;
  collectionName?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generation?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type NftContracts_Max_Fields = {
  __typename?: 'nftContracts_max_fields';
  blockNumber?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['Int']>;
  chain?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type NftContracts_Min_Fields = {
  __typename?: 'nftContracts_min_fields';
  blockNumber?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['Int']>;
  chain?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nftContracts" */
export type NftContracts_Mutation_Response = {
  __typename?: 'nftContracts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NftContracts>;
};

/** input type for inserting object relation for remote table "nftContracts" */
export type NftContracts_Obj_Rel_Insert_Input = {
  data: NftContracts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<NftContracts_On_Conflict>;
};

/** on_conflict condition type for table "nftContracts" */
export type NftContracts_On_Conflict = {
  constraint: NftContracts_Constraint;
  update_columns?: Array<NftContracts_Update_Column>;
  where?: InputMaybe<NftContracts_Bool_Exp>;
};

/** Ordering options when selecting data from "nftContracts". */
export type NftContracts_Order_By = {
  blockNumber?: InputMaybe<Order_By>;
  blockchainId?: InputMaybe<Order_By>;
  chain?: InputMaybe<Order_By>;
  collectionName?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nftContracts */
export type NftContracts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "nftContracts" */
export enum NftContracts_Select_Column {
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  Chain = 'chain',
  /** column name */
  CollectionName = 'collectionName',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Generation = 'generation',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "nftContracts" */
export type NftContracts_Set_Input = {
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  chain?: InputMaybe<Scalars['String']>;
  collectionName?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generation?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type NftContracts_Stddev_Fields = {
  __typename?: 'nftContracts_stddev_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type NftContracts_Stddev_Pop_Fields = {
  __typename?: 'nftContracts_stddev_pop_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type NftContracts_Stddev_Samp_Fields = {
  __typename?: 'nftContracts_stddev_samp_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nftContracts" */
export type NftContracts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: NftContracts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type NftContracts_Stream_Cursor_Value_Input = {
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  chain?: InputMaybe<Scalars['String']>;
  collectionName?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generation?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type NftContracts_Sum_Fields = {
  __typename?: 'nftContracts_sum_fields';
  blockNumber?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['Int']>;
  generation?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "nftContracts" */
export enum NftContracts_Update_Column {
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  Chain = 'chain',
  /** column name */
  CollectionName = 'collectionName',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Generation = 'generation',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type NftContracts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NftContracts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NftContracts_Set_Input>;
  where: NftContracts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type NftContracts_Var_Pop_Fields = {
  __typename?: 'nftContracts_var_pop_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type NftContracts_Var_Samp_Fields = {
  __typename?: 'nftContracts_var_samp_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type NftContracts_Variance_Fields = {
  __typename?: 'nftContracts_variance_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nftMetadata" */
export type NftMetadatas = {
  __typename?: 'nftMetadatas';
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "nftMetadata" */
export type NftMetadatas_Aggregate = {
  __typename?: 'nftMetadatas_aggregate';
  aggregate?: Maybe<NftMetadatas_Aggregate_Fields>;
  nodes: Array<NftMetadatas>;
};

/** aggregate fields of "nftMetadata" */
export type NftMetadatas_Aggregate_Fields = {
  __typename?: 'nftMetadatas_aggregate_fields';
  avg?: Maybe<NftMetadatas_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NftMetadatas_Max_Fields>;
  min?: Maybe<NftMetadatas_Min_Fields>;
  stddev?: Maybe<NftMetadatas_Stddev_Fields>;
  stddev_pop?: Maybe<NftMetadatas_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NftMetadatas_Stddev_Samp_Fields>;
  sum?: Maybe<NftMetadatas_Sum_Fields>;
  var_pop?: Maybe<NftMetadatas_Var_Pop_Fields>;
  var_samp?: Maybe<NftMetadatas_Var_Samp_Fields>;
  variance?: Maybe<NftMetadatas_Variance_Fields>;
};

/** aggregate fields of "nftMetadata" */
export type NftMetadatas_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<NftMetadatas_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NftMetadatas_Avg_Fields = {
  __typename?: 'nftMetadatas_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nftMetadata". All fields are combined with a logical 'AND'. */
export type NftMetadatas_Bool_Exp = {
  _and?: InputMaybe<Array<NftMetadatas_Bool_Exp>>;
  _not?: InputMaybe<NftMetadatas_Bool_Exp>;
  _or?: InputMaybe<Array<NftMetadatas_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  imageUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nftMetadata" */
export enum NftMetadatas_Constraint {
  /** unique or primary key constraint on columns "id" */
  MetadataPkey = 'metadata_pkey',
}

/** input type for incrementing numeric columns in table "nftMetadata" */
export type NftMetadatas_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nftMetadata" */
export type NftMetadatas_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type NftMetadatas_Max_Fields = {
  __typename?: 'nftMetadatas_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type NftMetadatas_Min_Fields = {
  __typename?: 'nftMetadatas_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nftMetadata" */
export type NftMetadatas_Mutation_Response = {
  __typename?: 'nftMetadatas_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NftMetadatas>;
};

/** input type for inserting object relation for remote table "nftMetadata" */
export type NftMetadatas_Obj_Rel_Insert_Input = {
  data: NftMetadatas_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<NftMetadatas_On_Conflict>;
};

/** on_conflict condition type for table "nftMetadata" */
export type NftMetadatas_On_Conflict = {
  constraint: NftMetadatas_Constraint;
  update_columns?: Array<NftMetadatas_Update_Column>;
  where?: InputMaybe<NftMetadatas_Bool_Exp>;
};

/** Ordering options when selecting data from "nftMetadata". */
export type NftMetadatas_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nftMetadata */
export type NftMetadatas_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "nftMetadata" */
export enum NftMetadatas_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "nftMetadata" */
export type NftMetadatas_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type NftMetadatas_Stddev_Fields = {
  __typename?: 'nftMetadatas_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type NftMetadatas_Stddev_Pop_Fields = {
  __typename?: 'nftMetadatas_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type NftMetadatas_Stddev_Samp_Fields = {
  __typename?: 'nftMetadatas_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nftMetadatas" */
export type NftMetadatas_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: NftMetadatas_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type NftMetadatas_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type NftMetadatas_Sum_Fields = {
  __typename?: 'nftMetadatas_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "nftMetadata" */
export enum NftMetadatas_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type NftMetadatas_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NftMetadatas_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NftMetadatas_Set_Input>;
  where: NftMetadatas_Bool_Exp;
};

/** aggregate var_pop on columns */
export type NftMetadatas_Var_Pop_Fields = {
  __typename?: 'nftMetadatas_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type NftMetadatas_Var_Samp_Fields = {
  __typename?: 'nftMetadatas_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type NftMetadatas_Variance_Fields = {
  __typename?: 'nftMetadatas_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nft_owners" */
export type NftOwners = {
  __typename?: 'nftOwners';
  blockchain_id?: Maybe<Scalars['Int']>;
  borrowed: Scalars['Boolean'];
  contract: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  delegated?: Maybe<Nft_Gpanel_Users>;
  generation: Scalars['Int'];
  gpanelUserId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  ownerAddress: Scalars['String'];
  pokerName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tokenid: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nft_owners" */
export type NftOwners_Aggregate = {
  __typename?: 'nftOwners_aggregate';
  aggregate?: Maybe<NftOwners_Aggregate_Fields>;
  nodes: Array<NftOwners>;
};

export type NftOwners_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<NftOwners_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<NftOwners_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<NftOwners_Aggregate_Bool_Exp_Count>;
};

export type NftOwners_Aggregate_Bool_Exp_Bool_And = {
  arguments: NftOwners_Select_Column_NftOwners_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<NftOwners_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type NftOwners_Aggregate_Bool_Exp_Bool_Or = {
  arguments: NftOwners_Select_Column_NftOwners_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<NftOwners_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type NftOwners_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<NftOwners_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<NftOwners_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nft_owners" */
export type NftOwners_Aggregate_Fields = {
  __typename?: 'nftOwners_aggregate_fields';
  avg?: Maybe<NftOwners_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NftOwners_Max_Fields>;
  min?: Maybe<NftOwners_Min_Fields>;
  stddev?: Maybe<NftOwners_Stddev_Fields>;
  stddev_pop?: Maybe<NftOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NftOwners_Stddev_Samp_Fields>;
  sum?: Maybe<NftOwners_Sum_Fields>;
  var_pop?: Maybe<NftOwners_Var_Pop_Fields>;
  var_samp?: Maybe<NftOwners_Var_Samp_Fields>;
  variance?: Maybe<NftOwners_Variance_Fields>;
};

/** aggregate fields of "nft_owners" */
export type NftOwners_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<NftOwners_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nft_owners" */
export type NftOwners_Aggregate_Order_By = {
  avg?: InputMaybe<NftOwners_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<NftOwners_Max_Order_By>;
  min?: InputMaybe<NftOwners_Min_Order_By>;
  stddev?: InputMaybe<NftOwners_Stddev_Order_By>;
  stddev_pop?: InputMaybe<NftOwners_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<NftOwners_Stddev_Samp_Order_By>;
  sum?: InputMaybe<NftOwners_Sum_Order_By>;
  var_pop?: InputMaybe<NftOwners_Var_Pop_Order_By>;
  var_samp?: InputMaybe<NftOwners_Var_Samp_Order_By>;
  variance?: InputMaybe<NftOwners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nft_owners" */
export type NftOwners_Arr_Rel_Insert_Input = {
  data: Array<NftOwners_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<NftOwners_On_Conflict>;
};

/** aggregate avg on columns */
export type NftOwners_Avg_Fields = {
  __typename?: 'nftOwners_avg_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nft_owners" */
export type NftOwners_Avg_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nft_owners". All fields are combined with a logical 'AND'. */
export type NftOwners_Bool_Exp = {
  _and?: InputMaybe<Array<NftOwners_Bool_Exp>>;
  _not?: InputMaybe<NftOwners_Bool_Exp>;
  _or?: InputMaybe<Array<NftOwners_Bool_Exp>>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  borrowed?: InputMaybe<Boolean_Comparison_Exp>;
  contract?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  delegated?: InputMaybe<Nft_Gpanel_Users_Bool_Exp>;
  generation?: InputMaybe<Int_Comparison_Exp>;
  gpanelUserId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  ownerAddress?: InputMaybe<String_Comparison_Exp>;
  pokerName?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tokenid?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nft_owners" */
export enum NftOwners_Constraint {
  /** unique or primary key constraint on columns "id" */
  NftOwnersPkey = 'nft_owners_pkey',
  /** unique or primary key constraint on columns "contract", "blockchain_id", "tokenid" */
  NftOwnersTokenidContractBlockchainIdKey = 'nft_owners_tokenid_contract_blockchain_id_key',
}

/** input type for incrementing numeric columns in table "nft_owners" */
export type NftOwners_Inc_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  generation?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tokenid?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nft_owners" */
export type NftOwners_Insert_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  borrowed?: InputMaybe<Scalars['Boolean']>;
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  delegated?: InputMaybe<Nft_Gpanel_Users_Obj_Rel_Insert_Input>;
  generation?: InputMaybe<Scalars['Int']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  pokerName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tokenid?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type NftOwners_Max_Fields = {
  __typename?: 'nftOwners_max_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  gpanelUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  pokerName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tokenid?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nft_owners" */
export type NftOwners_Max_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  pokerName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type NftOwners_Min_Fields = {
  __typename?: 'nftOwners_min_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  gpanelUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  pokerName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tokenid?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nft_owners" */
export type NftOwners_Min_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  pokerName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nft_owners" */
export type NftOwners_Mutation_Response = {
  __typename?: 'nftOwners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NftOwners>;
};

/** input type for inserting object relation for remote table "nft_owners" */
export type NftOwners_Obj_Rel_Insert_Input = {
  data: NftOwners_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<NftOwners_On_Conflict>;
};

/** on_conflict condition type for table "nft_owners" */
export type NftOwners_On_Conflict = {
  constraint: NftOwners_Constraint;
  update_columns?: Array<NftOwners_Update_Column>;
  where?: InputMaybe<NftOwners_Bool_Exp>;
};

/** Ordering options when selecting data from "nft_owners". */
export type NftOwners_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  borrowed?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  delegated?: InputMaybe<Nft_Gpanel_Users_Order_By>;
  generation?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  pokerName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nft_owners */
export type NftOwners_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "nft_owners" */
export enum NftOwners_Select_Column {
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  Borrowed = 'borrowed',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Generation = 'generation',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  PokerName = 'pokerName',
  /** column name */
  Status = 'status',
  /** column name */
  Tokenid = 'tokenid',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** select "nftOwners_aggregate_bool_exp_bool_and_arguments_columns" columns of table "nft_owners" */
export enum NftOwners_Select_Column_NftOwners_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Borrowed = 'borrowed',
}

/** select "nftOwners_aggregate_bool_exp_bool_or_arguments_columns" columns of table "nft_owners" */
export enum NftOwners_Select_Column_NftOwners_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Borrowed = 'borrowed',
}

/** input type for updating data in table "nft_owners" */
export type NftOwners_Set_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  borrowed?: InputMaybe<Scalars['Boolean']>;
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generation?: InputMaybe<Scalars['Int']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  pokerName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tokenid?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type NftOwners_Stddev_Fields = {
  __typename?: 'nftOwners_stddev_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nft_owners" */
export type NftOwners_Stddev_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type NftOwners_Stddev_Pop_Fields = {
  __typename?: 'nftOwners_stddev_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nft_owners" */
export type NftOwners_Stddev_Pop_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type NftOwners_Stddev_Samp_Fields = {
  __typename?: 'nftOwners_stddev_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nft_owners" */
export type NftOwners_Stddev_Samp_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nftOwners" */
export type NftOwners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: NftOwners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type NftOwners_Stream_Cursor_Value_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  borrowed?: InputMaybe<Scalars['Boolean']>;
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generation?: InputMaybe<Scalars['Int']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  ownerAddress?: InputMaybe<Scalars['String']>;
  pokerName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tokenid?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type NftOwners_Sum_Fields = {
  __typename?: 'nftOwners_sum_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  generation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tokenid?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nft_owners" */
export type NftOwners_Sum_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
};

/** update columns of table "nft_owners" */
export enum NftOwners_Update_Column {
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  Borrowed = 'borrowed',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Generation = 'generation',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  PokerName = 'pokerName',
  /** column name */
  Status = 'status',
  /** column name */
  Tokenid = 'tokenid',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type NftOwners_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NftOwners_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NftOwners_Set_Input>;
  where: NftOwners_Bool_Exp;
};

/** aggregate var_pop on columns */
export type NftOwners_Var_Pop_Fields = {
  __typename?: 'nftOwners_var_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nft_owners" */
export type NftOwners_Var_Pop_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type NftOwners_Var_Samp_Fields = {
  __typename?: 'nftOwners_var_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nft_owners" */
export type NftOwners_Var_Samp_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type NftOwners_Variance_Fields = {
  __typename?: 'nftOwners_variance_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  generation?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nft_owners" */
export type NftOwners_Variance_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  generation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
};

/** columns and relationships of "nft_gpanel_users" */
export type Nft_Gpanel_Users = {
  __typename?: 'nft_gpanel_users';
  created_at: Scalars['timestamptz'];
  delegate_accepted?: Maybe<Scalars['timestamptz']>;
  delegate_stopped?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  gpanel_user?: Maybe<GpanelUsers>;
  gpanel_user_id: Scalars['String'];
  gpanel_username?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  nft_id: Scalars['Int'];
  /** An object relationship */
  nft_owner?: Maybe<NftOwners>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "nft_gpanel_users" */
export type Nft_Gpanel_Users_Aggregate = {
  __typename?: 'nft_gpanel_users_aggregate';
  aggregate?: Maybe<Nft_Gpanel_Users_Aggregate_Fields>;
  nodes: Array<Nft_Gpanel_Users>;
};

/** aggregate fields of "nft_gpanel_users" */
export type Nft_Gpanel_Users_Aggregate_Fields = {
  __typename?: 'nft_gpanel_users_aggregate_fields';
  avg?: Maybe<Nft_Gpanel_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nft_Gpanel_Users_Max_Fields>;
  min?: Maybe<Nft_Gpanel_Users_Min_Fields>;
  stddev?: Maybe<Nft_Gpanel_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Nft_Gpanel_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nft_Gpanel_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Nft_Gpanel_Users_Sum_Fields>;
  var_pop?: Maybe<Nft_Gpanel_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Nft_Gpanel_Users_Var_Samp_Fields>;
  variance?: Maybe<Nft_Gpanel_Users_Variance_Fields>;
};

/** aggregate fields of "nft_gpanel_users" */
export type Nft_Gpanel_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nft_Gpanel_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nft_Gpanel_Users_Avg_Fields = {
  __typename?: 'nft_gpanel_users_avg_fields';
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nft_gpanel_users". All fields are combined with a logical 'AND'. */
export type Nft_Gpanel_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Nft_Gpanel_Users_Bool_Exp>>;
  _not?: InputMaybe<Nft_Gpanel_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Nft_Gpanel_Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delegate_accepted?: InputMaybe<Timestamptz_Comparison_Exp>;
  delegate_stopped?: InputMaybe<Timestamptz_Comparison_Exp>;
  gpanel_user?: InputMaybe<GpanelUsers_Bool_Exp>;
  gpanel_user_id?: InputMaybe<String_Comparison_Exp>;
  gpanel_username?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  nft_id?: InputMaybe<Int_Comparison_Exp>;
  nft_owner?: InputMaybe<NftOwners_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nft_gpanel_users" */
export enum Nft_Gpanel_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  NftGpanelUsersPkey = 'nft_gpanel_users_pkey',
}

/** input type for incrementing numeric columns in table "nft_gpanel_users" */
export type Nft_Gpanel_Users_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nft_gpanel_users" */
export type Nft_Gpanel_Users_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  delegate_accepted?: InputMaybe<Scalars['timestamptz']>;
  delegate_stopped?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user?: InputMaybe<GpanelUsers_Obj_Rel_Insert_Input>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  gpanel_username?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  nft_owner?: InputMaybe<NftOwners_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nft_Gpanel_Users_Max_Fields = {
  __typename?: 'nft_gpanel_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  delegate_accepted?: Maybe<Scalars['timestamptz']>;
  delegate_stopped?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  gpanel_username?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  nft_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nft_Gpanel_Users_Min_Fields = {
  __typename?: 'nft_gpanel_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  delegate_accepted?: Maybe<Scalars['timestamptz']>;
  delegate_stopped?: Maybe<Scalars['timestamptz']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  gpanel_username?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  nft_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nft_gpanel_users" */
export type Nft_Gpanel_Users_Mutation_Response = {
  __typename?: 'nft_gpanel_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nft_Gpanel_Users>;
};

/** input type for inserting object relation for remote table "nft_gpanel_users" */
export type Nft_Gpanel_Users_Obj_Rel_Insert_Input = {
  data: Nft_Gpanel_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nft_Gpanel_Users_On_Conflict>;
};

/** on_conflict condition type for table "nft_gpanel_users" */
export type Nft_Gpanel_Users_On_Conflict = {
  constraint: Nft_Gpanel_Users_Constraint;
  update_columns?: Array<Nft_Gpanel_Users_Update_Column>;
  where?: InputMaybe<Nft_Gpanel_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "nft_gpanel_users". */
export type Nft_Gpanel_Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delegate_accepted?: InputMaybe<Order_By>;
  delegate_stopped?: InputMaybe<Order_By>;
  gpanel_user?: InputMaybe<GpanelUsers_Order_By>;
  gpanel_user_id?: InputMaybe<Order_By>;
  gpanel_username?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nft_id?: InputMaybe<Order_By>;
  nft_owner?: InputMaybe<NftOwners_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nft_gpanel_users */
export type Nft_Gpanel_Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "nft_gpanel_users" */
export enum Nft_Gpanel_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DelegateAccepted = 'delegate_accepted',
  /** column name */
  DelegateStopped = 'delegate_stopped',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  GpanelUsername = 'gpanel_username',
  /** column name */
  Id = 'id',
  /** column name */
  NftId = 'nft_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "nft_gpanel_users" */
export type Nft_Gpanel_Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  delegate_accepted?: InputMaybe<Scalars['timestamptz']>;
  delegate_stopped?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  gpanel_username?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nft_Gpanel_Users_Stddev_Fields = {
  __typename?: 'nft_gpanel_users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nft_Gpanel_Users_Stddev_Pop_Fields = {
  __typename?: 'nft_gpanel_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nft_Gpanel_Users_Stddev_Samp_Fields = {
  __typename?: 'nft_gpanel_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nft_gpanel_users" */
export type Nft_Gpanel_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nft_Gpanel_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nft_Gpanel_Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  delegate_accepted?: InputMaybe<Scalars['timestamptz']>;
  delegate_stopped?: InputMaybe<Scalars['timestamptz']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  gpanel_username?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nft_Gpanel_Users_Sum_Fields = {
  __typename?: 'nft_gpanel_users_sum_fields';
  id?: Maybe<Scalars['Int']>;
  nft_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "nft_gpanel_users" */
export enum Nft_Gpanel_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DelegateAccepted = 'delegate_accepted',
  /** column name */
  DelegateStopped = 'delegate_stopped',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  GpanelUsername = 'gpanel_username',
  /** column name */
  Id = 'id',
  /** column name */
  NftId = 'nft_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Nft_Gpanel_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nft_Gpanel_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nft_Gpanel_Users_Set_Input>;
  where: Nft_Gpanel_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nft_Gpanel_Users_Var_Pop_Fields = {
  __typename?: 'nft_gpanel_users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nft_Gpanel_Users_Var_Samp_Fields = {
  __typename?: 'nft_gpanel_users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nft_Gpanel_Users_Variance_Fields = {
  __typename?: 'nft_gpanel_users_variance_fields';
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nft_listings" */
export type Nft_Listings = {
  __typename?: 'nft_listings';
  amount: Scalars['Int'];
  blockchain_id?: Maybe<Scalars['Int']>;
  buyer: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  endedAt?: Maybe<Scalars['Int']>;
  erc20Address: Scalars['String'];
  id: Scalars['Int'];
  isErc721: Scalars['Boolean'];
  lastBidder?: Maybe<Scalars['String']>;
  nft_id: Scalars['Int'];
  nftaddress: Scalars['String'];
  originalListingId: Scalars['numeric'];
  price: Scalars['numeric'];
  seller: Scalars['String'];
  stakeCount: Scalars['Int'];
  state: Scalars['smallint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "nft_listings" */
export type Nft_Listings_Aggregate = {
  __typename?: 'nft_listings_aggregate';
  aggregate?: Maybe<Nft_Listings_Aggregate_Fields>;
  nodes: Array<Nft_Listings>;
};

/** aggregate fields of "nft_listings" */
export type Nft_Listings_Aggregate_Fields = {
  __typename?: 'nft_listings_aggregate_fields';
  avg?: Maybe<Nft_Listings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nft_Listings_Max_Fields>;
  min?: Maybe<Nft_Listings_Min_Fields>;
  stddev?: Maybe<Nft_Listings_Stddev_Fields>;
  stddev_pop?: Maybe<Nft_Listings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nft_Listings_Stddev_Samp_Fields>;
  sum?: Maybe<Nft_Listings_Sum_Fields>;
  var_pop?: Maybe<Nft_Listings_Var_Pop_Fields>;
  var_samp?: Maybe<Nft_Listings_Var_Samp_Fields>;
  variance?: Maybe<Nft_Listings_Variance_Fields>;
};

/** aggregate fields of "nft_listings" */
export type Nft_Listings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nft_Listings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nft_Listings_Avg_Fields = {
  __typename?: 'nft_listings_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nft_listings". All fields are combined with a logical 'AND'. */
export type Nft_Listings_Bool_Exp = {
  _and?: InputMaybe<Array<Nft_Listings_Bool_Exp>>;
  _not?: InputMaybe<Nft_Listings_Bool_Exp>;
  _or?: InputMaybe<Array<Nft_Listings_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  buyer?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  endedAt?: InputMaybe<Int_Comparison_Exp>;
  erc20Address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isErc721?: InputMaybe<Boolean_Comparison_Exp>;
  lastBidder?: InputMaybe<String_Comparison_Exp>;
  nft_id?: InputMaybe<Int_Comparison_Exp>;
  nftaddress?: InputMaybe<String_Comparison_Exp>;
  originalListingId?: InputMaybe<Numeric_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  seller?: InputMaybe<String_Comparison_Exp>;
  stakeCount?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<Smallint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nft_listings" */
export enum Nft_Listings_Constraint {
  /** unique or primary key constraint on columns "id" */
  NftListingsPkey = 'nft_listings_pkey',
}

/** input type for incrementing numeric columns in table "nft_listings" */
export type Nft_Listings_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  endedAt?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  originalListingId?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  stakeCount?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "nft_listings" */
export type Nft_Listings_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  buyer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['Int']>;
  erc20Address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isErc721?: InputMaybe<Scalars['Boolean']>;
  lastBidder?: InputMaybe<Scalars['String']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  nftaddress?: InputMaybe<Scalars['String']>;
  originalListingId?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller?: InputMaybe<Scalars['String']>;
  stakeCount?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nft_Listings_Max_Fields = {
  __typename?: 'nft_listings_max_fields';
  amount?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  buyer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  endedAt?: Maybe<Scalars['Int']>;
  erc20Address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastBidder?: Maybe<Scalars['String']>;
  nft_id?: Maybe<Scalars['Int']>;
  nftaddress?: Maybe<Scalars['String']>;
  originalListingId?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
  seller?: Maybe<Scalars['String']>;
  stakeCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nft_Listings_Min_Fields = {
  __typename?: 'nft_listings_min_fields';
  amount?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  buyer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  endedAt?: Maybe<Scalars['Int']>;
  erc20Address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastBidder?: Maybe<Scalars['String']>;
  nft_id?: Maybe<Scalars['Int']>;
  nftaddress?: Maybe<Scalars['String']>;
  originalListingId?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
  seller?: Maybe<Scalars['String']>;
  stakeCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nft_listings" */
export type Nft_Listings_Mutation_Response = {
  __typename?: 'nft_listings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nft_Listings>;
};

/** on_conflict condition type for table "nft_listings" */
export type Nft_Listings_On_Conflict = {
  constraint: Nft_Listings_Constraint;
  update_columns?: Array<Nft_Listings_Update_Column>;
  where?: InputMaybe<Nft_Listings_Bool_Exp>;
};

/** Ordering options when selecting data from "nft_listings". */
export type Nft_Listings_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  buyer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  erc20Address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isErc721?: InputMaybe<Order_By>;
  lastBidder?: InputMaybe<Order_By>;
  nft_id?: InputMaybe<Order_By>;
  nftaddress?: InputMaybe<Order_By>;
  originalListingId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  seller?: InputMaybe<Order_By>;
  stakeCount?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nft_listings */
export type Nft_Listings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "nft_listings" */
export enum Nft_Listings_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  Buyer = 'buyer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Erc20Address = 'erc20Address',
  /** column name */
  Id = 'id',
  /** column name */
  IsErc721 = 'isErc721',
  /** column name */
  LastBidder = 'lastBidder',
  /** column name */
  NftId = 'nft_id',
  /** column name */
  Nftaddress = 'nftaddress',
  /** column name */
  OriginalListingId = 'originalListingId',
  /** column name */
  Price = 'price',
  /** column name */
  Seller = 'seller',
  /** column name */
  StakeCount = 'stakeCount',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "nft_listings" */
export type Nft_Listings_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  buyer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['Int']>;
  erc20Address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isErc721?: InputMaybe<Scalars['Boolean']>;
  lastBidder?: InputMaybe<Scalars['String']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  nftaddress?: InputMaybe<Scalars['String']>;
  originalListingId?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller?: InputMaybe<Scalars['String']>;
  stakeCount?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nft_Listings_Stddev_Fields = {
  __typename?: 'nft_listings_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nft_Listings_Stddev_Pop_Fields = {
  __typename?: 'nft_listings_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nft_Listings_Stddev_Samp_Fields = {
  __typename?: 'nft_listings_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nft_listings" */
export type Nft_Listings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nft_Listings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nft_Listings_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  buyer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['Int']>;
  erc20Address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isErc721?: InputMaybe<Scalars['Boolean']>;
  lastBidder?: InputMaybe<Scalars['String']>;
  nft_id?: InputMaybe<Scalars['Int']>;
  nftaddress?: InputMaybe<Scalars['String']>;
  originalListingId?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  seller?: InputMaybe<Scalars['String']>;
  stakeCount?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nft_Listings_Sum_Fields = {
  __typename?: 'nft_listings_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  endedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  nft_id?: Maybe<Scalars['Int']>;
  originalListingId?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
  stakeCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['smallint']>;
};

/** update columns of table "nft_listings" */
export enum Nft_Listings_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  Buyer = 'buyer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Erc20Address = 'erc20Address',
  /** column name */
  Id = 'id',
  /** column name */
  IsErc721 = 'isErc721',
  /** column name */
  LastBidder = 'lastBidder',
  /** column name */
  NftId = 'nft_id',
  /** column name */
  Nftaddress = 'nftaddress',
  /** column name */
  OriginalListingId = 'originalListingId',
  /** column name */
  Price = 'price',
  /** column name */
  Seller = 'seller',
  /** column name */
  StakeCount = 'stakeCount',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Nft_Listings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nft_Listings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nft_Listings_Set_Input>;
  where: Nft_Listings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nft_Listings_Var_Pop_Fields = {
  __typename?: 'nft_listings_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nft_Listings_Var_Samp_Fields = {
  __typename?: 'nft_listings_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nft_Listings_Variance_Fields = {
  __typename?: 'nft_listings_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nft_id?: Maybe<Scalars['Float']>;
  originalListingId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stakeCount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nft_transactions" */
export type Nft_Transactions = {
  __typename?: 'nft_transactions';
  action: Scalars['String'];
  blockNumber: Scalars['Int'];
  blockchain_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  swap_log?: Maybe<Scalars['String']>;
  timeStamp: Scalars['Int'];
  to: Scalars['String'];
  tokenid: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "nft_transactions" */
export type Nft_Transactions_Aggregate = {
  __typename?: 'nft_transactions_aggregate';
  aggregate?: Maybe<Nft_Transactions_Aggregate_Fields>;
  nodes: Array<Nft_Transactions>;
};

/** aggregate fields of "nft_transactions" */
export type Nft_Transactions_Aggregate_Fields = {
  __typename?: 'nft_transactions_aggregate_fields';
  avg?: Maybe<Nft_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nft_Transactions_Max_Fields>;
  min?: Maybe<Nft_Transactions_Min_Fields>;
  stddev?: Maybe<Nft_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Nft_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nft_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Nft_Transactions_Sum_Fields>;
  var_pop?: Maybe<Nft_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Nft_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Nft_Transactions_Variance_Fields>;
};

/** aggregate fields of "nft_transactions" */
export type Nft_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nft_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nft_Transactions_Avg_Fields = {
  __typename?: 'nft_transactions_avg_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nft_transactions". All fields are combined with a logical 'AND'. */
export type Nft_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Nft_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Nft_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Nft_Transactions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  blockNumber?: InputMaybe<Int_Comparison_Exp>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  swap_log?: InputMaybe<String_Comparison_Exp>;
  timeStamp?: InputMaybe<Int_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
  tokenid?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nft_transactions" */
export enum Nft_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  NftTransactionsPkey = 'nft_transactions_pkey',
}

/** input type for incrementing numeric columns in table "nft_transactions" */
export type Nft_Transactions_Inc_Input = {
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  tokenid?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nft_transactions" */
export type Nft_Transactions_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  swap_log?: InputMaybe<Scalars['String']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  tokenid?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nft_Transactions_Max_Fields = {
  __typename?: 'nft_transactions_max_fields';
  action?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  swap_log?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['String']>;
  tokenid?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nft_Transactions_Min_Fields = {
  __typename?: 'nft_transactions_min_fields';
  action?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  swap_log?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['String']>;
  tokenid?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nft_transactions" */
export type Nft_Transactions_Mutation_Response = {
  __typename?: 'nft_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nft_Transactions>;
};

/** on_conflict condition type for table "nft_transactions" */
export type Nft_Transactions_On_Conflict = {
  constraint: Nft_Transactions_Constraint;
  update_columns?: Array<Nft_Transactions_Update_Column>;
  where?: InputMaybe<Nft_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "nft_transactions". */
export type Nft_Transactions_Order_By = {
  action?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  swap_log?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  tokenid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nft_transactions */
export type Nft_Transactions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "nft_transactions" */
export enum Nft_Transactions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  SwapLog = 'swap_log',
  /** column name */
  TimeStamp = 'timeStamp',
  /** column name */
  To = 'to',
  /** column name */
  Tokenid = 'tokenid',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "nft_transactions" */
export type Nft_Transactions_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  swap_log?: InputMaybe<Scalars['String']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  tokenid?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nft_Transactions_Stddev_Fields = {
  __typename?: 'nft_transactions_stddev_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nft_Transactions_Stddev_Pop_Fields = {
  __typename?: 'nft_transactions_stddev_pop_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nft_Transactions_Stddev_Samp_Fields = {
  __typename?: 'nft_transactions_stddev_samp_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nft_transactions" */
export type Nft_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nft_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nft_Transactions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  swap_log?: InputMaybe<Scalars['String']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  tokenid?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nft_Transactions_Sum_Fields = {
  __typename?: 'nft_transactions_sum_fields';
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  timeStamp?: Maybe<Scalars['Int']>;
  tokenid?: Maybe<Scalars['Int']>;
};

/** update columns of table "nft_transactions" */
export enum Nft_Transactions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  SwapLog = 'swap_log',
  /** column name */
  TimeStamp = 'timeStamp',
  /** column name */
  To = 'to',
  /** column name */
  Tokenid = 'tokenid',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Nft_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nft_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nft_Transactions_Set_Input>;
  where: Nft_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nft_Transactions_Var_Pop_Fields = {
  __typename?: 'nft_transactions_var_pop_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nft_Transactions_Var_Samp_Fields = {
  __typename?: 'nft_transactions_var_samp_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nft_Transactions_Variance_Fields = {
  __typename?: 'nft_transactions_variance_fields';
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  tokenid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nfts" */
export type Nfts = {
  __typename?: 'nfts';
  activeListingId?: Maybe<Scalars['Int']>;
  activeUsernameId?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['smallint']>;
  contractId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  lastTransferredAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  listing?: Maybe<MarketListings>;
  metadataId: Scalars['Int'];
  /** An object relationship */
  nftContract: NftContracts;
  /** An object relationship */
  nftMetadatum: NftMetadatas;
  owner: Scalars['String'];
  tokenId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "nfts" */
export type Nfts_Aggregate = {
  __typename?: 'nfts_aggregate';
  aggregate?: Maybe<Nfts_Aggregate_Fields>;
  nodes: Array<Nfts>;
};

/** aggregate fields of "nfts" */
export type Nfts_Aggregate_Fields = {
  __typename?: 'nfts_aggregate_fields';
  avg?: Maybe<Nfts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nfts_Max_Fields>;
  min?: Maybe<Nfts_Min_Fields>;
  stddev?: Maybe<Nfts_Stddev_Fields>;
  stddev_pop?: Maybe<Nfts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nfts_Stddev_Samp_Fields>;
  sum?: Maybe<Nfts_Sum_Fields>;
  var_pop?: Maybe<Nfts_Var_Pop_Fields>;
  var_samp?: Maybe<Nfts_Var_Samp_Fields>;
  variance?: Maybe<Nfts_Variance_Fields>;
};

/** aggregate fields of "nfts" */
export type Nfts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nfts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nfts_Avg_Fields = {
  __typename?: 'nfts_avg_fields';
  activeListingId?: Maybe<Scalars['Float']>;
  activeUsernameId?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  metadataId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nfts". All fields are combined with a logical 'AND'. */
export type Nfts_Bool_Exp = {
  _and?: InputMaybe<Array<Nfts_Bool_Exp>>;
  _not?: InputMaybe<Nfts_Bool_Exp>;
  _or?: InputMaybe<Array<Nfts_Bool_Exp>>;
  activeListingId?: InputMaybe<Int_Comparison_Exp>;
  activeUsernameId?: InputMaybe<Int_Comparison_Exp>;
  blockchainId?: InputMaybe<Smallint_Comparison_Exp>;
  contractId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  imageUrl?: InputMaybe<String_Comparison_Exp>;
  lastTransferredAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  listing?: InputMaybe<MarketListings_Bool_Exp>;
  metadataId?: InputMaybe<Int_Comparison_Exp>;
  nftContract?: InputMaybe<NftContracts_Bool_Exp>;
  nftMetadatum?: InputMaybe<NftMetadatas_Bool_Exp>;
  owner?: InputMaybe<String_Comparison_Exp>;
  tokenId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nfts" */
export enum Nfts_Constraint {
  /** unique or primary key constraint on columns "id" */
  NftsPkey = 'nfts_pkey',
}

/** input type for incrementing numeric columns in table "nfts" */
export type Nfts_Inc_Input = {
  activeListingId?: InputMaybe<Scalars['Int']>;
  activeUsernameId?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['smallint']>;
  contractId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadataId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nfts" */
export type Nfts_Insert_Input = {
  activeListingId?: InputMaybe<Scalars['Int']>;
  activeUsernameId?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['smallint']>;
  contractId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  lastTransferredAt?: InputMaybe<Scalars['timestamptz']>;
  listing?: InputMaybe<MarketListings_Obj_Rel_Insert_Input>;
  metadataId?: InputMaybe<Scalars['Int']>;
  nftContract?: InputMaybe<NftContracts_Obj_Rel_Insert_Input>;
  nftMetadatum?: InputMaybe<NftMetadatas_Obj_Rel_Insert_Input>;
  owner?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nfts_Max_Fields = {
  __typename?: 'nfts_max_fields';
  activeListingId?: Maybe<Scalars['Int']>;
  activeUsernameId?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['smallint']>;
  contractId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  lastTransferredAt?: Maybe<Scalars['timestamptz']>;
  metadataId?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nfts_Min_Fields = {
  __typename?: 'nfts_min_fields';
  activeListingId?: Maybe<Scalars['Int']>;
  activeUsernameId?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['smallint']>;
  contractId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  lastTransferredAt?: Maybe<Scalars['timestamptz']>;
  metadataId?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nfts" */
export type Nfts_Mutation_Response = {
  __typename?: 'nfts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nfts>;
};

/** input type for inserting object relation for remote table "nfts" */
export type Nfts_Obj_Rel_Insert_Input = {
  data: Nfts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nfts_On_Conflict>;
};

/** on_conflict condition type for table "nfts" */
export type Nfts_On_Conflict = {
  constraint: Nfts_Constraint;
  update_columns?: Array<Nfts_Update_Column>;
  where?: InputMaybe<Nfts_Bool_Exp>;
};

/** Ordering options when selecting data from "nfts". */
export type Nfts_Order_By = {
  activeListingId?: InputMaybe<Order_By>;
  activeUsernameId?: InputMaybe<Order_By>;
  blockchainId?: InputMaybe<Order_By>;
  contractId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  lastTransferredAt?: InputMaybe<Order_By>;
  listing?: InputMaybe<MarketListings_Order_By>;
  metadataId?: InputMaybe<Order_By>;
  nftContract?: InputMaybe<NftContracts_Order_By>;
  nftMetadatum?: InputMaybe<NftMetadatas_Order_By>;
  owner?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nfts */
export type Nfts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "nfts" */
export enum Nfts_Select_Column {
  /** column name */
  ActiveListingId = 'activeListingId',
  /** column name */
  ActiveUsernameId = 'activeUsernameId',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  ContractId = 'contractId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  LastTransferredAt = 'lastTransferredAt',
  /** column name */
  MetadataId = 'metadataId',
  /** column name */
  Owner = 'owner',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "nfts" */
export type Nfts_Set_Input = {
  activeListingId?: InputMaybe<Scalars['Int']>;
  activeUsernameId?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['smallint']>;
  contractId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  lastTransferredAt?: InputMaybe<Scalars['timestamptz']>;
  metadataId?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nfts_Stddev_Fields = {
  __typename?: 'nfts_stddev_fields';
  activeListingId?: Maybe<Scalars['Float']>;
  activeUsernameId?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  metadataId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nfts_Stddev_Pop_Fields = {
  __typename?: 'nfts_stddev_pop_fields';
  activeListingId?: Maybe<Scalars['Float']>;
  activeUsernameId?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  metadataId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nfts_Stddev_Samp_Fields = {
  __typename?: 'nfts_stddev_samp_fields';
  activeListingId?: Maybe<Scalars['Float']>;
  activeUsernameId?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  metadataId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nfts" */
export type Nfts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nfts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nfts_Stream_Cursor_Value_Input = {
  activeListingId?: InputMaybe<Scalars['Int']>;
  activeUsernameId?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['smallint']>;
  contractId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  lastTransferredAt?: InputMaybe<Scalars['timestamptz']>;
  metadataId?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nfts_Sum_Fields = {
  __typename?: 'nfts_sum_fields';
  activeListingId?: Maybe<Scalars['Int']>;
  activeUsernameId?: Maybe<Scalars['Int']>;
  blockchainId?: Maybe<Scalars['smallint']>;
  contractId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  metadataId?: Maybe<Scalars['Int']>;
};

/** update columns of table "nfts" */
export enum Nfts_Update_Column {
  /** column name */
  ActiveListingId = 'activeListingId',
  /** column name */
  ActiveUsernameId = 'activeUsernameId',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  ContractId = 'contractId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  LastTransferredAt = 'lastTransferredAt',
  /** column name */
  MetadataId = 'metadataId',
  /** column name */
  Owner = 'owner',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Nfts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nfts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nfts_Set_Input>;
  where: Nfts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nfts_Var_Pop_Fields = {
  __typename?: 'nfts_var_pop_fields';
  activeListingId?: Maybe<Scalars['Float']>;
  activeUsernameId?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  metadataId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nfts_Var_Samp_Fields = {
  __typename?: 'nfts_var_samp_fields';
  activeListingId?: Maybe<Scalars['Float']>;
  activeUsernameId?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  metadataId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nfts_Variance_Fields = {
  __typename?: 'nfts_variance_fields';
  activeListingId?: Maybe<Scalars['Float']>;
  activeUsernameId?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  metadataId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "pending_transactions" */
export type PendingTransactions = {
  __typename?: 'pendingTransactions';
  agent: Scalars['Boolean'];
  amount: Scalars['numeric'];
  /** An object relationship */
  blockchain: Blockchain;
  blockchain_id: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  currency: Scalars['String'];
  depositAddress?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['numeric']>;
  gpanelUserId: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  stablecoin?: Maybe<Stablecoins>;
  stablecoin_id?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  statusInternal: Scalars['String'];
  twofa?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
  txlog?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid'];
  username?: Maybe<Scalars['String']>;
  withdraw_address?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pending_transactions" */
export type PendingTransactions_Aggregate = {
  __typename?: 'pendingTransactions_aggregate';
  aggregate?: Maybe<PendingTransactions_Aggregate_Fields>;
  nodes: Array<PendingTransactions>;
};

export type PendingTransactions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PendingTransactions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PendingTransactions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PendingTransactions_Aggregate_Bool_Exp_Count>;
};

export type PendingTransactions_Aggregate_Bool_Exp_Bool_And = {
  arguments: PendingTransactions_Select_Column_PendingTransactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PendingTransactions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PendingTransactions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PendingTransactions_Select_Column_PendingTransactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PendingTransactions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PendingTransactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PendingTransactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PendingTransactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pending_transactions" */
export type PendingTransactions_Aggregate_Fields = {
  __typename?: 'pendingTransactions_aggregate_fields';
  avg?: Maybe<PendingTransactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PendingTransactions_Max_Fields>;
  min?: Maybe<PendingTransactions_Min_Fields>;
  stddev?: Maybe<PendingTransactions_Stddev_Fields>;
  stddev_pop?: Maybe<PendingTransactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PendingTransactions_Stddev_Samp_Fields>;
  sum?: Maybe<PendingTransactions_Sum_Fields>;
  var_pop?: Maybe<PendingTransactions_Var_Pop_Fields>;
  var_samp?: Maybe<PendingTransactions_Var_Samp_Fields>;
  variance?: Maybe<PendingTransactions_Variance_Fields>;
};

/** aggregate fields of "pending_transactions" */
export type PendingTransactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PendingTransactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pending_transactions" */
export type PendingTransactions_Aggregate_Order_By = {
  avg?: InputMaybe<PendingTransactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PendingTransactions_Max_Order_By>;
  min?: InputMaybe<PendingTransactions_Min_Order_By>;
  stddev?: InputMaybe<PendingTransactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PendingTransactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PendingTransactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PendingTransactions_Sum_Order_By>;
  var_pop?: InputMaybe<PendingTransactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PendingTransactions_Var_Samp_Order_By>;
  variance?: InputMaybe<PendingTransactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "pending_transactions" */
export type PendingTransactions_Arr_Rel_Insert_Input = {
  data: Array<PendingTransactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PendingTransactions_On_Conflict>;
};

/** aggregate avg on columns */
export type PendingTransactions_Avg_Fields = {
  __typename?: 'pendingTransactions_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  stablecoin_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pending_transactions" */
export type PendingTransactions_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pending_transactions". All fields are combined with a logical 'AND'. */
export type PendingTransactions_Bool_Exp = {
  _and?: InputMaybe<Array<PendingTransactions_Bool_Exp>>;
  _not?: InputMaybe<PendingTransactions_Bool_Exp>;
  _or?: InputMaybe<Array<PendingTransactions_Bool_Exp>>;
  agent?: InputMaybe<Boolean_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  blockchain?: InputMaybe<Blockchain_Bool_Exp>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  depositAddress?: InputMaybe<String_Comparison_Exp>;
  fee?: InputMaybe<Numeric_Comparison_Exp>;
  gpanelUserId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  stablecoin?: InputMaybe<Stablecoins_Bool_Exp>;
  stablecoin_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  statusInternal?: InputMaybe<String_Comparison_Exp>;
  twofa?: InputMaybe<String_Comparison_Exp>;
  txid?: InputMaybe<String_Comparison_Exp>;
  txlog?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  withdraw_address?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pending_transactions" */
export enum PendingTransactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsPkey = 'transactions_pkey',
}

/** input type for incrementing numeric columns in table "pending_transactions" */
export type PendingTransactions_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['numeric']>;
  stablecoin_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pending_transactions" */
export type PendingTransactions_Insert_Input = {
  agent?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['numeric']>;
  blockchain?: InputMaybe<Blockchain_Obj_Rel_Insert_Input>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  depositAddress?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['numeric']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  stablecoin?: InputMaybe<Stablecoins_Obj_Rel_Insert_Input>;
  stablecoin_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  statusInternal?: InputMaybe<Scalars['String']>;
  twofa?: InputMaybe<Scalars['String']>;
  txid?: InputMaybe<Scalars['String']>;
  txlog?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
  withdraw_address?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PendingTransactions_Max_Fields = {
  __typename?: 'pendingTransactions_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  depositAddress?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['numeric']>;
  gpanelUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  stablecoin_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
  twofa?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
  txlog?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
  withdraw_address?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pending_transactions" */
export type PendingTransactions_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  depositAddress?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  statusInternal?: InputMaybe<Order_By>;
  twofa?: InputMaybe<Order_By>;
  txid?: InputMaybe<Order_By>;
  txlog?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  withdraw_address?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PendingTransactions_Min_Fields = {
  __typename?: 'pendingTransactions_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  depositAddress?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['numeric']>;
  gpanelUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  stablecoin_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
  twofa?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
  txlog?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
  withdraw_address?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pending_transactions" */
export type PendingTransactions_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  depositAddress?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  statusInternal?: InputMaybe<Order_By>;
  twofa?: InputMaybe<Order_By>;
  txid?: InputMaybe<Order_By>;
  txlog?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  withdraw_address?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pending_transactions" */
export type PendingTransactions_Mutation_Response = {
  __typename?: 'pendingTransactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PendingTransactions>;
};

/** on_conflict condition type for table "pending_transactions" */
export type PendingTransactions_On_Conflict = {
  constraint: PendingTransactions_Constraint;
  update_columns?: Array<PendingTransactions_Update_Column>;
  where?: InputMaybe<PendingTransactions_Bool_Exp>;
};

/** Ordering options when selecting data from "pending_transactions". */
export type PendingTransactions_Order_By = {
  agent?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  blockchain?: InputMaybe<Blockchain_Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  depositAddress?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stablecoin?: InputMaybe<Stablecoins_Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  statusInternal?: InputMaybe<Order_By>;
  twofa?: InputMaybe<Order_By>;
  txid?: InputMaybe<Order_By>;
  txlog?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  withdraw_address?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pending_transactions */
export type PendingTransactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "pending_transactions" */
export enum PendingTransactions_Select_Column {
  /** column name */
  Agent = 'agent',
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DepositAddress = 'depositAddress',
  /** column name */
  Fee = 'fee',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  StablecoinId = 'stablecoin_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusInternal = 'statusInternal',
  /** column name */
  Twofa = 'twofa',
  /** column name */
  Txid = 'txid',
  /** column name */
  Txlog = 'txlog',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Username = 'username',
  /** column name */
  WithdrawAddress = 'withdraw_address',
}

/** select "pendingTransactions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pending_transactions" */
export enum PendingTransactions_Select_Column_PendingTransactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Agent = 'agent',
}

/** select "pendingTransactions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pending_transactions" */
export enum PendingTransactions_Select_Column_PendingTransactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Agent = 'agent',
}

/** input type for updating data in table "pending_transactions" */
export type PendingTransactions_Set_Input = {
  agent?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['numeric']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  depositAddress?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['numeric']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  stablecoin_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  statusInternal?: InputMaybe<Scalars['String']>;
  twofa?: InputMaybe<Scalars['String']>;
  txid?: InputMaybe<Scalars['String']>;
  txlog?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
  withdraw_address?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type PendingTransactions_Stddev_Fields = {
  __typename?: 'pendingTransactions_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  stablecoin_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pending_transactions" */
export type PendingTransactions_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PendingTransactions_Stddev_Pop_Fields = {
  __typename?: 'pendingTransactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  stablecoin_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pending_transactions" */
export type PendingTransactions_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PendingTransactions_Stddev_Samp_Fields = {
  __typename?: 'pendingTransactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  stablecoin_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pending_transactions" */
export type PendingTransactions_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "pendingTransactions" */
export type PendingTransactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PendingTransactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PendingTransactions_Stream_Cursor_Value_Input = {
  agent?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['numeric']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  depositAddress?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['numeric']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  stablecoin_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  statusInternal?: InputMaybe<Scalars['String']>;
  twofa?: InputMaybe<Scalars['String']>;
  txid?: InputMaybe<Scalars['String']>;
  txlog?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
  withdraw_address?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type PendingTransactions_Sum_Fields = {
  __typename?: 'pendingTransactions_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['numeric']>;
  stablecoin_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pending_transactions" */
export type PendingTransactions_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
};

/** update columns of table "pending_transactions" */
export enum PendingTransactions_Update_Column {
  /** column name */
  Agent = 'agent',
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DepositAddress = 'depositAddress',
  /** column name */
  Fee = 'fee',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  StablecoinId = 'stablecoin_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusInternal = 'statusInternal',
  /** column name */
  Twofa = 'twofa',
  /** column name */
  Txid = 'txid',
  /** column name */
  Txlog = 'txlog',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Username = 'username',
  /** column name */
  WithdrawAddress = 'withdraw_address',
}

export type PendingTransactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PendingTransactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PendingTransactions_Set_Input>;
  where: PendingTransactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PendingTransactions_Var_Pop_Fields = {
  __typename?: 'pendingTransactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  stablecoin_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pending_transactions" */
export type PendingTransactions_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PendingTransactions_Var_Samp_Fields = {
  __typename?: 'pendingTransactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  stablecoin_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pending_transactions" */
export type PendingTransactions_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PendingTransactions_Variance_Fields = {
  __typename?: 'pendingTransactions_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  stablecoin_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pending_transactions" */
export type PendingTransactions_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  stablecoin_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "pending_tx" */
export type Pending_Tx = {
  __typename?: 'pending_tx';
  amount: Scalars['numeric'];
  blockNumber: Scalars['Int'];
  blockchain_id?: Maybe<Scalars['Int']>;
  contractAddress: Scalars['String'];
  created_at: Scalars['timestamptz'];
  from: Scalars['String'];
  function: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['bigint'];
  timeStamp: Scalars['Int'];
  to: Scalars['String'];
  tokenSymbol: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  wallet: Scalars['String'];
};

/** aggregated selection of "pending_tx" */
export type Pending_Tx_Aggregate = {
  __typename?: 'pending_tx_aggregate';
  aggregate?: Maybe<Pending_Tx_Aggregate_Fields>;
  nodes: Array<Pending_Tx>;
};

export type Pending_Tx_Aggregate_Bool_Exp = {
  count?: InputMaybe<Pending_Tx_Aggregate_Bool_Exp_Count>;
};

export type Pending_Tx_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Pending_Tx_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Pending_Tx_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pending_tx" */
export type Pending_Tx_Aggregate_Fields = {
  __typename?: 'pending_tx_aggregate_fields';
  avg?: Maybe<Pending_Tx_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pending_Tx_Max_Fields>;
  min?: Maybe<Pending_Tx_Min_Fields>;
  stddev?: Maybe<Pending_Tx_Stddev_Fields>;
  stddev_pop?: Maybe<Pending_Tx_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pending_Tx_Stddev_Samp_Fields>;
  sum?: Maybe<Pending_Tx_Sum_Fields>;
  var_pop?: Maybe<Pending_Tx_Var_Pop_Fields>;
  var_samp?: Maybe<Pending_Tx_Var_Samp_Fields>;
  variance?: Maybe<Pending_Tx_Variance_Fields>;
};

/** aggregate fields of "pending_tx" */
export type Pending_Tx_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pending_Tx_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pending_tx" */
export type Pending_Tx_Aggregate_Order_By = {
  avg?: InputMaybe<Pending_Tx_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pending_Tx_Max_Order_By>;
  min?: InputMaybe<Pending_Tx_Min_Order_By>;
  stddev?: InputMaybe<Pending_Tx_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Pending_Tx_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Pending_Tx_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Pending_Tx_Sum_Order_By>;
  var_pop?: InputMaybe<Pending_Tx_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Pending_Tx_Var_Samp_Order_By>;
  variance?: InputMaybe<Pending_Tx_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "pending_tx" */
export type Pending_Tx_Arr_Rel_Insert_Input = {
  data: Array<Pending_Tx_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pending_Tx_On_Conflict>;
};

/** aggregate avg on columns */
export type Pending_Tx_Avg_Fields = {
  __typename?: 'pending_tx_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pending_tx" */
export type Pending_Tx_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pending_tx". All fields are combined with a logical 'AND'. */
export type Pending_Tx_Bool_Exp = {
  _and?: InputMaybe<Array<Pending_Tx_Bool_Exp>>;
  _not?: InputMaybe<Pending_Tx_Bool_Exp>;
  _or?: InputMaybe<Array<Pending_Tx_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  blockNumber?: InputMaybe<Int_Comparison_Exp>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  contractAddress?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  function?: InputMaybe<String_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  timeStamp?: InputMaybe<Int_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
  tokenSymbol?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  wallet?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pending_tx" */
export enum Pending_Tx_Constraint {
  /** unique or primary key constraint on columns "id" */
  PendingTxPkey = 'pending_tx_pkey',
}

/** input type for incrementing numeric columns in table "pending_tx" */
export type Pending_Tx_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pending_tx" */
export type Pending_Tx_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  tokenSymbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pending_Tx_Max_Fields = {
  __typename?: 'pending_tx_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  contractAddress?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  timeStamp?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['String']>;
  tokenSymbol?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pending_tx" */
export type Pending_Tx_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  function?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  tokenSymbol?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Pending_Tx_Min_Fields = {
  __typename?: 'pending_tx_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  contractAddress?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  timeStamp?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['String']>;
  tokenSymbol?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pending_tx" */
export type Pending_Tx_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  function?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  tokenSymbol?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pending_tx" */
export type Pending_Tx_Mutation_Response = {
  __typename?: 'pending_tx_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pending_Tx>;
};

/** on_conflict condition type for table "pending_tx" */
export type Pending_Tx_On_Conflict = {
  constraint: Pending_Tx_Constraint;
  update_columns?: Array<Pending_Tx_Update_Column>;
  where?: InputMaybe<Pending_Tx_Bool_Exp>;
};

/** Ordering options when selecting data from "pending_tx". */
export type Pending_Tx_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  function?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  tokenSymbol?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pending_tx */
export type Pending_Tx_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "pending_tx" */
export enum Pending_Tx_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Function = 'function',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  TimeStamp = 'timeStamp',
  /** column name */
  To = 'to',
  /** column name */
  TokenSymbol = 'tokenSymbol',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Wallet = 'wallet',
}

/** input type for updating data in table "pending_tx" */
export type Pending_Tx_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  tokenSymbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Pending_Tx_Stddev_Fields = {
  __typename?: 'pending_tx_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pending_tx" */
export type Pending_Tx_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pending_Tx_Stddev_Pop_Fields = {
  __typename?: 'pending_tx_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pending_tx" */
export type Pending_Tx_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pending_Tx_Stddev_Samp_Fields = {
  __typename?: 'pending_tx_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pending_tx" */
export type Pending_Tx_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "pending_tx" */
export type Pending_Tx_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pending_Tx_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pending_Tx_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  tokenSymbol?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Pending_Tx_Sum_Fields = {
  __typename?: 'pending_tx_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  timeStamp?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pending_tx" */
export type Pending_Tx_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
};

/** update columns of table "pending_tx" */
export enum Pending_Tx_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Function = 'function',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  TimeStamp = 'timeStamp',
  /** column name */
  To = 'to',
  /** column name */
  TokenSymbol = 'tokenSymbol',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Wallet = 'wallet',
}

export type Pending_Tx_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Pending_Tx_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pending_Tx_Set_Input>;
  where: Pending_Tx_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Pending_Tx_Var_Pop_Fields = {
  __typename?: 'pending_tx_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pending_tx" */
export type Pending_Tx_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pending_Tx_Var_Samp_Fields = {
  __typename?: 'pending_tx_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pending_tx" */
export type Pending_Tx_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Pending_Tx_Variance_Fields = {
  __typename?: 'pending_tx_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pending_tx" */
export type Pending_Tx_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  NFTAvailableTickets?: Maybe<NftTicketsClaimOutput>;
  NFTFindUser?: Maybe<AgentFindUserOutput>;
  NFTUserConnected?: Maybe<NftConnectedOutput>;
  /** fetch data from the table: "Test" */
  Test: Array<Test>;
  /** fetch aggregated fields from the table: "Test" */
  Test_aggregate: Test_Aggregate;
  /** fetch data from the table: "Test" using primary key columns */
  Test_by_pk?: Maybe<Test>;
  agentFindUser?: Maybe<AgentFindUserOutput>;
  agentGetUsers?: Maybe<AgentGetUsersOutput>;
  /** fetch data from the table: "anno_gpanel" */
  anno_gpanel: Array<Anno_Gpanel>;
  /** fetch aggregated fields from the table: "anno_gpanel" */
  anno_gpanel_aggregate: Anno_Gpanel_Aggregate;
  /** fetch data from the table: "anno_gpanel" using primary key columns */
  anno_gpanel_by_pk?: Maybe<Anno_Gpanel>;
  authLogin?: Maybe<AuthLoginOutput>;
  authRefresh?: Maybe<AuthLoginOutput>;
  authSignature?: Maybe<AuthSignatureOutput>;
  /** fetch data from the table: "blockchain" using primary key columns */
  blockchain?: Maybe<Blockchain>;
  /** fetch data from the table: "blockchain_main_deposit" */
  blockchain_main_deposit: Array<Blockchain_Main_Deposit>;
  /** fetch aggregated fields from the table: "blockchain_main_deposit" */
  blockchain_main_deposit_aggregate: Blockchain_Main_Deposit_Aggregate;
  /** fetch data from the table: "blockchain_main_deposit" using primary key columns */
  blockchain_main_deposit_by_pk?: Maybe<Blockchain_Main_Deposit>;
  /** fetch data from the table: "blockchain" */
  blockchains: Array<Blockchain>;
  /** fetch aggregated fields from the table: "blockchain" */
  blockchainsAggregate: Blockchain_Aggregate;
  /** fetch data from the table: "burn_profit" */
  burn_profit: Array<Burn_Profit>;
  /** fetch aggregated fields from the table: "burn_profit" */
  burn_profit_aggregate: Burn_Profit_Aggregate;
  /** fetch data from the table: "burn_profit" using primary key columns */
  burn_profit_by_pk?: Maybe<Burn_Profit>;
  /** fetch data from the table: "currencies" */
  currencies: Array<Currencies>;
  /** fetch aggregated fields from the table: "currencies" */
  currencies_aggregate: Currencies_Aggregate;
  /** fetch data from the table: "currencies" using primary key columns */
  currencies_by_pk?: Maybe<Currencies>;
  /** fetch data from the table: "currency_history" */
  currency_history: Array<Currency_History>;
  /** fetch aggregated fields from the table: "currency_history" */
  currency_history_aggregate: Currency_History_Aggregate;
  /** fetch data from the table: "currency_history" using primary key columns */
  currency_history_by_pk?: Maybe<Currency_History>;
  /** fetch data from the table: "erc721_addresses" */
  erc721_addresses: Array<Erc721_Addresses>;
  /** fetch aggregated fields from the table: "erc721_addresses" */
  erc721_addresses_aggregate: Erc721_Addresses_Aggregate;
  /** fetch data from the table: "erc721_addresses" using primary key columns */
  erc721_addresses_by_pk?: Maybe<Erc721_Addresses>;
  /** fetch data from the table: "evenbet_affiliate_player_balance" */
  evenbetAffiliatePlayerBalances: Array<EvenbetAffiliatePlayerBalances>;
  /** fetch aggregated fields from the table: "evenbet_affiliate_player_balance" */
  evenbetAffiliatePlayerBalancesAggregate: EvenbetAffiliatePlayerBalances_Aggregate;
  /** fetch data from the table: "evenbet_ticket_requests" */
  evenbet_ticket_requests: Array<Evenbet_Ticket_Requests>;
  /** fetch aggregated fields from the table: "evenbet_ticket_requests" */
  evenbet_ticket_requests_aggregate: Evenbet_Ticket_Requests_Aggregate;
  /** fetch data from the table: "evenbet_ticket_requests" using primary key columns */
  evenbet_ticket_requests_by_pk?: Maybe<Evenbet_Ticket_Requests>;
  /** fetch data from the table: "evenbet_tickets" */
  evenbet_tickets: Array<Evenbet_Tickets>;
  /** fetch aggregated fields from the table: "evenbet_tickets" */
  evenbet_tickets_aggregate: Evenbet_Tickets_Aggregate;
  /** fetch data from the table: "evenbet_tickets" using primary key columns */
  evenbet_tickets_by_pk?: Maybe<Evenbet_Tickets>;
  /** getGpanelUUID */
  getGpanelUUID?: Maybe<GPanelOutput>;
  /** fetch data from the table: "gpanel_payments" using primary key columns */
  gpanelPayment?: Maybe<GpanelPayments>;
  /** fetch data from the table: "gpanel_payments" */
  gpanelPayments: Array<GpanelPayments>;
  /** fetch aggregated fields from the table: "gpanel_payments" */
  gpanelPaymentsAggregate: GpanelPayments_Aggregate;
  /** fetch data from the table: "gpanel_users" using primary key columns */
  gpanelUser?: Maybe<GpanelUsers>;
  /** fetch data from the table: "gpanel_users" */
  gpanelUsers: Array<GpanelUsers>;
  /** fetch aggregated fields from the table: "gpanel_users" */
  gpanelUsersAggregate: GpanelUsers_Aggregate;
  /** fetch data from the table: "gpanel_users_agent" */
  gpanel_users_agent: Array<Gpanel_Users_Agent>;
  /** fetch aggregated fields from the table: "gpanel_users_agent" */
  gpanel_users_agent_aggregate: Gpanel_Users_Agent_Aggregate;
  /** fetch data from the table: "gpanel_users_agent" using primary key columns */
  gpanel_users_agent_by_pk?: Maybe<Gpanel_Users_Agent>;
  /** fetch data from the table: "gpanel_users_deposit_address" */
  gpanel_users_deposit_address: Array<Gpanel_Users_Deposit_Address>;
  /** fetch aggregated fields from the table: "gpanel_users_deposit_address" */
  gpanel_users_deposit_address_aggregate: Gpanel_Users_Deposit_Address_Aggregate;
  /** fetch data from the table: "gpanel_users_deposit_address" using primary key columns */
  gpanel_users_deposit_address_by_pk?: Maybe<Gpanel_Users_Deposit_Address>;
  /** fetch data from the table: "gpanel_users_kyc" */
  gpanel_users_kyc: Array<Gpanel_Users_Kyc>;
  /** fetch aggregated fields from the table: "gpanel_users_kyc" */
  gpanel_users_kyc_aggregate: Gpanel_Users_Kyc_Aggregate;
  /** fetch data from the table: "gpanel_users_kyc" using primary key columns */
  gpanel_users_kyc_by_pk?: Maybe<Gpanel_Users_Kyc>;
  /** fetch data from the table: "kyc" using primary key columns */
  kyc?: Maybe<Kycs>;
  /** fetch data from the table: "kyc" */
  kycs: Array<Kycs>;
  /** fetch aggregated fields from the table: "kyc" */
  kycs_aggregate: Kycs_Aggregate;
  /** fetch data from the table: "leaderboard" */
  leaderboard: Array<Leaderboard>;
  /** fetch aggregated fields from the table: "leaderboard" */
  leaderboard_aggregate: Leaderboard_Aggregate;
  /** fetch data from the table: "leaderboard" using primary key columns */
  leaderboard_by_pk?: Maybe<Leaderboard>;
  /** fetch data from the table: "leaderboard_ranking" */
  leaderboard_ranking: Array<Leaderboard_Ranking>;
  /** fetch aggregated fields from the table: "leaderboard_ranking" */
  leaderboard_ranking_aggregate: Leaderboard_Ranking_Aggregate;
  /** fetch data from the table: "leaderboard_ranking" using primary key columns */
  leaderboard_ranking_by_pk?: Maybe<Leaderboard_Ranking>;
  /** fetch data from the table: "liquidity_pools" */
  liquidity_pools: Array<Liquidity_Pools>;
  /** fetch aggregated fields from the table: "liquidity_pools" */
  liquidity_pools_aggregate: Liquidity_Pools_Aggregate;
  /** fetch data from the table: "liquidity_pools" using primary key columns */
  liquidity_pools_by_pk?: Maybe<Liquidity_Pools>;
  /** fetch data from the table: "listings" using primary key columns */
  marketListing?: Maybe<MarketListings>;
  /** fetch data from the table: "listings" */
  marketListings: Array<MarketListings>;
  /** fetch aggregated fields from the table: "listings" */
  marketListingsAggregate: MarketListings_Aggregate;
  /** fetch data from the table: "marketplaceContracts" using primary key columns */
  marketplaceContract?: Maybe<MarketplaceContracts>;
  /** fetch data from the table: "marketplaceContracts" */
  marketplaceContracts: Array<MarketplaceContracts>;
  /** fetch aggregated fields from the table: "marketplaceContracts" */
  marketplaceContractsAggregate: MarketplaceContracts_Aggregate;
  me?: Maybe<UserOutput>;
  /** fetch data from the table: "nfts" using primary key columns */
  nft?: Maybe<Nfts>;
  /** fetch data from the table: "nftContracts" using primary key columns */
  nftContract?: Maybe<NftContracts>;
  /** fetch data from the table: "nftContracts" */
  nftContracts: Array<NftContracts>;
  /** fetch aggregated fields from the table: "nftContracts" */
  nftContractsAggregate: NftContracts_Aggregate;
  /** fetch data from the table: "nftMetadata" using primary key columns */
  nftMetadata?: Maybe<NftMetadatas>;
  /** fetch data from the table: "nftMetadata" */
  nftMetadatas: Array<NftMetadatas>;
  /** fetch aggregated fields from the table: "nftMetadata" */
  nftMetadatasAggregate: NftMetadatas_Aggregate;
  /** fetch data from the table: "nft_owners" using primary key columns */
  nftOwner?: Maybe<NftOwners>;
  /** fetch data from the table: "nft_owners" */
  nftOwners: Array<NftOwners>;
  /** fetch aggregated fields from the table: "nft_owners" */
  nftOwnersAggregate: NftOwners_Aggregate;
  /** fetch data from the table: "nft_gpanel_users" */
  nft_gpanel_users: Array<Nft_Gpanel_Users>;
  /** fetch aggregated fields from the table: "nft_gpanel_users" */
  nft_gpanel_users_aggregate: Nft_Gpanel_Users_Aggregate;
  /** fetch data from the table: "nft_gpanel_users" using primary key columns */
  nft_gpanel_users_by_pk?: Maybe<Nft_Gpanel_Users>;
  /** fetch data from the table: "nft_listings" */
  nft_listings: Array<Nft_Listings>;
  /** fetch aggregated fields from the table: "nft_listings" */
  nft_listings_aggregate: Nft_Listings_Aggregate;
  /** fetch data from the table: "nft_listings" using primary key columns */
  nft_listings_by_pk?: Maybe<Nft_Listings>;
  /** fetch data from the table: "nft_transactions" */
  nft_transactions: Array<Nft_Transactions>;
  /** fetch aggregated fields from the table: "nft_transactions" */
  nft_transactions_aggregate: Nft_Transactions_Aggregate;
  /** fetch data from the table: "nft_transactions" using primary key columns */
  nft_transactions_by_pk?: Maybe<Nft_Transactions>;
  /** fetch data from the table: "nfts" */
  nfts: Array<Nfts>;
  /** fetch aggregated fields from the table: "nfts" */
  nftsAggregate: Nfts_Aggregate;
  paymentGetDepositAddress?: Maybe<DepositAddressOutput>;
  paymentSumsubToken?: Maybe<SumsubOutput>;
  /** fetch data from the table: "pending_transactions" using primary key columns */
  pendingTransaction?: Maybe<PendingTransactions>;
  /** fetch data from the table: "pending_transactions" */
  pendingTransactions: Array<PendingTransactions>;
  /** fetch aggregated fields from the table: "pending_transactions" */
  pendingTransactionsAggregate: PendingTransactions_Aggregate;
  /** fetch data from the table: "pending_tx" */
  pending_tx: Array<Pending_Tx>;
  /** fetch aggregated fields from the table: "pending_tx" */
  pending_tx_aggregate: Pending_Tx_Aggregate;
  /** fetch data from the table: "pending_tx" using primary key columns */
  pending_tx_by_pk?: Maybe<Pending_Tx>;
  /** fetch data from the table: "rakeback_lock" */
  rakebackLocks: Array<RakebackLocks>;
  /** fetch aggregated fields from the table: "rakeback_lock" */
  rakebackLocks_aggregate: RakebackLocks_Aggregate;
  /** fetch data from the table: "rakeback_lock" using primary key columns */
  rakebackLocks_by_pk?: Maybe<RakebackLocks>;
  /** fetch data from the table: "stablecoins" using primary key columns */
  stablecoin?: Maybe<Stablecoins>;
  /** An array relationship */
  stablecoins: Array<Stablecoins>;
  /** fetch aggregated fields from the table: "stablecoins" */
  stablecoinsAggregate: Stablecoins_Aggregate;
  /** fetch data from the table: "staking_refill" */
  staking_refill: Array<Staking_Refill>;
  /** fetch aggregated fields from the table: "staking_refill" */
  staking_refill_aggregate: Staking_Refill_Aggregate;
  /** fetch data from the table: "staking_refill" using primary key columns */
  staking_refill_by_pk?: Maybe<Staking_Refill>;
  swapGetOrder?: Maybe<SwapOrderOutput>;
  swapRates?: Maybe<RateOutput>;
  /** fetch data from the table: "swap_transactions" */
  swapTransactions: Array<SwapTransactions>;
  /** fetch aggregated fields from the table: "swap_transactions" */
  swapTransactionsAggregate: SwapTransactions_Aggregate;
  /** fetch data from the table: "tournaments" */
  tournaments: Array<Tournaments>;
  /** fetch aggregated fields from the table: "tournaments" */
  tournaments_aggregate: Tournaments_Aggregate;
  /** fetch data from the table: "tournaments" using primary key columns */
  tournaments_by_pk?: Maybe<Tournaments>;
  /** fetch data from the table: "transactionStatistics" using primary key columns */
  transactionStatistic?: Maybe<TransactionStatistics>;
  /** fetch data from the table: "transactionStatistics" */
  transactionStatistics: Array<TransactionStatistics>;
  /** fetch aggregated fields from the table: "transactionStatistics" */
  transactionStatisticsAggregate: TransactionStatistics_Aggregate;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactionsAggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  updateAffiliatePlayerBalance?: Maybe<GeneralMessageOutput>;
  updateAvailableAvatarNFTs?: Maybe<UpdateAvailableAvatarNfTs>;
  /** fetch data from the table: "users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_erc721" using primary key columns */
  userErc721?: Maybe<UserErc721>;
  /** fetch data from the table: "user_erc721" */
  userErc721s: Array<UserErc721>;
  /** fetch aggregated fields from the table: "user_erc721" */
  userErc721sAggregate: UserErc721_Aggregate;
  /** fetch data from the table: "user_jobs" using primary key columns */
  userJob?: Maybe<UserJobs>;
  /** fetch data from the table: "user_jobs" */
  userJobs: Array<UserJobs>;
  /** fetch aggregated fields from the table: "user_jobs" */
  userJobsAggregage: UserJobs_Aggregate;
  /** fetch data from the table: "user_erc721_block" */
  user_erc721_block: Array<User_Erc721_Block>;
  /** fetch aggregated fields from the table: "user_erc721_block" */
  user_erc721_block_aggregate: User_Erc721_Block_Aggregate;
  /** fetch data from the table: "user_erc721_block" using primary key columns */
  user_erc721_block_by_pk?: Maybe<User_Erc721_Block>;
  /** fetch data from the table: "user_gpanel" */
  user_gpanel: Array<User_Gpanel>;
  /** fetch aggregated fields from the table: "user_gpanel" */
  user_gpanel_aggregate: User_Gpanel_Aggregate;
  /** fetch data from the table: "user_gpanel" using primary key columns */
  user_gpanel_by_pk?: Maybe<User_Gpanel>;
  /** fetch data from the table: "user_lock" */
  user_lock: Array<User_Lock>;
  /** fetch aggregated fields from the table: "user_lock" */
  user_lock_aggregate: User_Lock_Aggregate;
  /** fetch data from the table: "user_lock" using primary key columns */
  user_lock_by_pk?: Maybe<User_Lock>;
  /** fetch data from the table: "user_staking" */
  user_staking: Array<User_Staking>;
  /** fetch aggregated fields from the table: "user_staking" */
  user_staking_aggregate: User_Staking_Aggregate;
  /** fetch data from the table: "user_staking" using primary key columns */
  user_staking_by_pk?: Maybe<User_Staking>;
  /** fetch data from the table: "user_staking_transactions" */
  user_staking_transactions: Array<User_Staking_Transactions>;
  /** fetch aggregated fields from the table: "user_staking_transactions" */
  user_staking_transactions_aggregate: User_Staking_Transactions_Aggregate;
  /** fetch data from the table: "user_staking_transactions" using primary key columns */
  user_staking_transactions_by_pk?: Maybe<User_Staking_Transactions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table: "users_login" */
  users_login: Array<Users_Login>;
  /** fetch aggregated fields from the table: "users_login" */
  users_login_aggregate: Users_Login_Aggregate;
  /** fetch data from the table: "users_old" */
  users_old: Array<Users_Old>;
  /** fetch aggregated fields from the table: "users_old" */
  users_old_aggregate: Users_Old_Aggregate;
  /** fetch data from the table: "users_old" using primary key columns */
  users_old_by_pk?: Maybe<Users_Old>;
};

export type Query_RootNftFindUserArgs = {
  name: Scalars['String'];
};

export type Query_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};

export type Query_RootTest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};

export type Query_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootAgentFindUserArgs = {
  name: Scalars['String'];
};

export type Query_RootAnno_GpanelArgs = {
  distinct_on?: InputMaybe<Array<Anno_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anno_Gpanel_Order_By>>;
  where?: InputMaybe<Anno_Gpanel_Bool_Exp>;
};

export type Query_RootAnno_Gpanel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Anno_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anno_Gpanel_Order_By>>;
  where?: InputMaybe<Anno_Gpanel_Bool_Exp>;
};

export type Query_RootAnno_Gpanel_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootAuthLoginArgs = {
  address?: InputMaybe<Scalars['String']>;
  gpanelAccessToken?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
};

export type Query_RootAuthRefreshArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  gpanelAccessToken?: InputMaybe<Scalars['String']>;
};

export type Query_RootAuthSignatureArgs = {
  address: Scalars['String'];
};

export type Query_RootBlockchainArgs = {
  id: Scalars['Int'];
};

export type Query_RootBlockchain_Main_DepositArgs = {
  distinct_on?: InputMaybe<Array<Blockchain_Main_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blockchain_Main_Deposit_Order_By>>;
  where?: InputMaybe<Blockchain_Main_Deposit_Bool_Exp>;
};

export type Query_RootBlockchain_Main_Deposit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blockchain_Main_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blockchain_Main_Deposit_Order_By>>;
  where?: InputMaybe<Blockchain_Main_Deposit_Bool_Exp>;
};

export type Query_RootBlockchain_Main_Deposit_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootBlockchainsArgs = {
  distinct_on?: InputMaybe<Array<Blockchain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blockchain_Order_By>>;
  where?: InputMaybe<Blockchain_Bool_Exp>;
};

export type Query_RootBlockchainsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Blockchain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blockchain_Order_By>>;
  where?: InputMaybe<Blockchain_Bool_Exp>;
};

export type Query_RootBurn_ProfitArgs = {
  distinct_on?: InputMaybe<Array<Burn_Profit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Burn_Profit_Order_By>>;
  where?: InputMaybe<Burn_Profit_Bool_Exp>;
};

export type Query_RootBurn_Profit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Burn_Profit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Burn_Profit_Order_By>>;
  where?: InputMaybe<Burn_Profit_Bool_Exp>;
};

export type Query_RootBurn_Profit_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCurrenciesArgs = {
  distinct_on?: InputMaybe<Array<Currencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencies_Order_By>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};

export type Query_RootCurrencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencies_Order_By>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};

export type Query_RootCurrencies_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootCurrency_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Currency_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_History_Order_By>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};

export type Query_RootCurrency_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_History_Order_By>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};

export type Query_RootCurrency_History_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootErc721_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Erc721_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Erc721_Addresses_Order_By>>;
  where?: InputMaybe<Erc721_Addresses_Bool_Exp>;
};

export type Query_RootErc721_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Erc721_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Erc721_Addresses_Order_By>>;
  where?: InputMaybe<Erc721_Addresses_Bool_Exp>;
};

export type Query_RootErc721_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootEvenbetAffiliatePlayerBalancesArgs = {
  distinct_on?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Order_By>>;
  where?: InputMaybe<EvenbetAffiliatePlayerBalances_Bool_Exp>;
};

export type Query_RootEvenbetAffiliatePlayerBalancesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Order_By>>;
  where?: InputMaybe<EvenbetAffiliatePlayerBalances_Bool_Exp>;
};

export type Query_RootEvenbet_Ticket_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Evenbet_Ticket_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Evenbet_Ticket_Requests_Order_By>>;
  where?: InputMaybe<Evenbet_Ticket_Requests_Bool_Exp>;
};

export type Query_RootEvenbet_Ticket_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evenbet_Ticket_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Evenbet_Ticket_Requests_Order_By>>;
  where?: InputMaybe<Evenbet_Ticket_Requests_Bool_Exp>;
};

export type Query_RootEvenbet_Ticket_Requests_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEvenbet_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Evenbet_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Evenbet_Tickets_Order_By>>;
  where?: InputMaybe<Evenbet_Tickets_Bool_Exp>;
};

export type Query_RootEvenbet_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evenbet_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Evenbet_Tickets_Order_By>>;
  where?: InputMaybe<Evenbet_Tickets_Bool_Exp>;
};

export type Query_RootEvenbet_Tickets_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGetGpanelUuidArgs = {
  uuid: Scalars['String'];
};

export type Query_RootGpanelPaymentArgs = {
  id: Scalars['Int'];
};

export type Query_RootGpanelPaymentsArgs = {
  distinct_on?: InputMaybe<Array<GpanelPayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelPayments_Order_By>>;
  where?: InputMaybe<GpanelPayments_Bool_Exp>;
};

export type Query_RootGpanelPaymentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GpanelPayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelPayments_Order_By>>;
  where?: InputMaybe<GpanelPayments_Bool_Exp>;
};

export type Query_RootGpanelUserArgs = {
  id: Scalars['Int'];
};

export type Query_RootGpanelUsersArgs = {
  distinct_on?: InputMaybe<Array<GpanelUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelUsers_Order_By>>;
  where?: InputMaybe<GpanelUsers_Bool_Exp>;
};

export type Query_RootGpanelUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<GpanelUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelUsers_Order_By>>;
  where?: InputMaybe<GpanelUsers_Bool_Exp>;
};

export type Query_RootGpanel_Users_AgentArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Agent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Agent_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Agent_Bool_Exp>;
};

export type Query_RootGpanel_Users_Agent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Agent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Agent_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Agent_Bool_Exp>;
};

export type Query_RootGpanel_Users_Agent_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGpanel_Users_Deposit_AddressArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Deposit_Address_Bool_Exp>;
};

export type Query_RootGpanel_Users_Deposit_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Deposit_Address_Bool_Exp>;
};

export type Query_RootGpanel_Users_Deposit_Address_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootGpanel_Users_KycArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Kyc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Kyc_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Kyc_Bool_Exp>;
};

export type Query_RootGpanel_Users_Kyc_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Kyc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Kyc_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Kyc_Bool_Exp>;
};

export type Query_RootGpanel_Users_Kyc_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootKycArgs = {
  id: Scalars['Int'];
};

export type Query_RootKycsArgs = {
  distinct_on?: InputMaybe<Array<Kycs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kycs_Order_By>>;
  where?: InputMaybe<Kycs_Bool_Exp>;
};

export type Query_RootKycs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kycs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kycs_Order_By>>;
  where?: InputMaybe<Kycs_Bool_Exp>;
};

export type Query_RootLeaderboardArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leaderboard_Order_By>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};

export type Query_RootLeaderboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leaderboard_Order_By>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};

export type Query_RootLeaderboard_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootLeaderboard_RankingArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leaderboard_Ranking_Order_By>>;
  where?: InputMaybe<Leaderboard_Ranking_Bool_Exp>;
};

export type Query_RootLeaderboard_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leaderboard_Ranking_Order_By>>;
  where?: InputMaybe<Leaderboard_Ranking_Bool_Exp>;
};

export type Query_RootLeaderboard_Ranking_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootLiquidity_PoolsArgs = {
  distinct_on?: InputMaybe<Array<Liquidity_Pools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Liquidity_Pools_Order_By>>;
  where?: InputMaybe<Liquidity_Pools_Bool_Exp>;
};

export type Query_RootLiquidity_Pools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Liquidity_Pools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Liquidity_Pools_Order_By>>;
  where?: InputMaybe<Liquidity_Pools_Bool_Exp>;
};

export type Query_RootLiquidity_Pools_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootMarketListingArgs = {
  id: Scalars['Int'];
};

export type Query_RootMarketListingsArgs = {
  distinct_on?: InputMaybe<Array<MarketListings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketListings_Order_By>>;
  where?: InputMaybe<MarketListings_Bool_Exp>;
};

export type Query_RootMarketListingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketListings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketListings_Order_By>>;
  where?: InputMaybe<MarketListings_Bool_Exp>;
};

export type Query_RootMarketplaceContractArgs = {
  id: Scalars['Int'];
};

export type Query_RootMarketplaceContractsArgs = {
  distinct_on?: InputMaybe<Array<MarketplaceContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketplaceContracts_Order_By>>;
  where?: InputMaybe<MarketplaceContracts_Bool_Exp>;
};

export type Query_RootMarketplaceContractsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketplaceContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketplaceContracts_Order_By>>;
  where?: InputMaybe<MarketplaceContracts_Bool_Exp>;
};

export type Query_RootNftArgs = {
  id: Scalars['Int'];
};

export type Query_RootNftContractArgs = {
  id: Scalars['Int'];
};

export type Query_RootNftContractsArgs = {
  distinct_on?: InputMaybe<Array<NftContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftContracts_Order_By>>;
  where?: InputMaybe<NftContracts_Bool_Exp>;
};

export type Query_RootNftContractsAggregateArgs = {
  distinct_on?: InputMaybe<Array<NftContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftContracts_Order_By>>;
  where?: InputMaybe<NftContracts_Bool_Exp>;
};

export type Query_RootNftMetadataArgs = {
  id: Scalars['Int'];
};

export type Query_RootNftMetadatasArgs = {
  distinct_on?: InputMaybe<Array<NftMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftMetadatas_Order_By>>;
  where?: InputMaybe<NftMetadatas_Bool_Exp>;
};

export type Query_RootNftMetadatasAggregateArgs = {
  distinct_on?: InputMaybe<Array<NftMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftMetadatas_Order_By>>;
  where?: InputMaybe<NftMetadatas_Bool_Exp>;
};

export type Query_RootNftOwnerArgs = {
  id: Scalars['Int'];
};

export type Query_RootNftOwnersArgs = {
  distinct_on?: InputMaybe<Array<NftOwners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftOwners_Order_By>>;
  where?: InputMaybe<NftOwners_Bool_Exp>;
};

export type Query_RootNftOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<NftOwners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftOwners_Order_By>>;
  where?: InputMaybe<NftOwners_Bool_Exp>;
};

export type Query_RootNft_Gpanel_UsersArgs = {
  distinct_on?: InputMaybe<Array<Nft_Gpanel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Gpanel_Users_Order_By>>;
  where?: InputMaybe<Nft_Gpanel_Users_Bool_Exp>;
};

export type Query_RootNft_Gpanel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Gpanel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Gpanel_Users_Order_By>>;
  where?: InputMaybe<Nft_Gpanel_Users_Bool_Exp>;
};

export type Query_RootNft_Gpanel_Users_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootNft_ListingsArgs = {
  distinct_on?: InputMaybe<Array<Nft_Listings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Listings_Order_By>>;
  where?: InputMaybe<Nft_Listings_Bool_Exp>;
};

export type Query_RootNft_Listings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Listings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Listings_Order_By>>;
  where?: InputMaybe<Nft_Listings_Bool_Exp>;
};

export type Query_RootNft_Listings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootNft_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Nft_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Transactions_Order_By>>;
  where?: InputMaybe<Nft_Transactions_Bool_Exp>;
};

export type Query_RootNft_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Transactions_Order_By>>;
  where?: InputMaybe<Nft_Transactions_Bool_Exp>;
};

export type Query_RootNft_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootNftsArgs = {
  distinct_on?: InputMaybe<Array<Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nfts_Order_By>>;
  where?: InputMaybe<Nfts_Bool_Exp>;
};

export type Query_RootNftsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nfts_Order_By>>;
  where?: InputMaybe<Nfts_Bool_Exp>;
};

export type Query_RootPaymentGetDepositAddressArgs = {
  blockchainId: Scalars['Int'];
};

export type Query_RootPaymentSumsubTokenArgs = {
  gpanelUserId: Scalars['String'];
};

export type Query_RootPendingTransactionArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPendingTransactionsArgs = {
  distinct_on?: InputMaybe<Array<PendingTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PendingTransactions_Order_By>>;
  where?: InputMaybe<PendingTransactions_Bool_Exp>;
};

export type Query_RootPendingTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PendingTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PendingTransactions_Order_By>>;
  where?: InputMaybe<PendingTransactions_Bool_Exp>;
};

export type Query_RootPending_TxArgs = {
  distinct_on?: InputMaybe<Array<Pending_Tx_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pending_Tx_Order_By>>;
  where?: InputMaybe<Pending_Tx_Bool_Exp>;
};

export type Query_RootPending_Tx_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pending_Tx_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pending_Tx_Order_By>>;
  where?: InputMaybe<Pending_Tx_Bool_Exp>;
};

export type Query_RootPending_Tx_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootRakebackLocksArgs = {
  distinct_on?: InputMaybe<Array<RakebackLocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RakebackLocks_Order_By>>;
  where?: InputMaybe<RakebackLocks_Bool_Exp>;
};

export type Query_RootRakebackLocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RakebackLocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RakebackLocks_Order_By>>;
  where?: InputMaybe<RakebackLocks_Bool_Exp>;
};

export type Query_RootRakebackLocks_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootStablecoinArgs = {
  id: Scalars['Int'];
};

export type Query_RootStablecoinsArgs = {
  distinct_on?: InputMaybe<Array<Stablecoins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stablecoins_Order_By>>;
  where?: InputMaybe<Stablecoins_Bool_Exp>;
};

export type Query_RootStablecoinsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stablecoins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stablecoins_Order_By>>;
  where?: InputMaybe<Stablecoins_Bool_Exp>;
};

export type Query_RootStaking_RefillArgs = {
  distinct_on?: InputMaybe<Array<Staking_Refill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Refill_Order_By>>;
  where?: InputMaybe<Staking_Refill_Bool_Exp>;
};

export type Query_RootStaking_Refill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staking_Refill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Refill_Order_By>>;
  where?: InputMaybe<Staking_Refill_Bool_Exp>;
};

export type Query_RootStaking_Refill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSwapGetOrderArgs = {
  gpanelUserId: Scalars['String'];
  id: Scalars['String'];
};

export type Query_RootSwapRatesArgs = {
  fromAmount?: InputMaybe<Scalars['Float']>;
  fromCurrency: Scalars['String'];
  toAmount?: InputMaybe<Scalars['Float']>;
  toCurrency: Scalars['String'];
};

export type Query_RootSwapTransactionsArgs = {
  distinct_on?: InputMaybe<Array<SwapTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SwapTransactions_Order_By>>;
  where?: InputMaybe<SwapTransactions_Bool_Exp>;
};

export type Query_RootSwapTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SwapTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SwapTransactions_Order_By>>;
  where?: InputMaybe<SwapTransactions_Bool_Exp>;
};

export type Query_RootTournamentsArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournaments_Order_By>>;
  where?: InputMaybe<Tournaments_Bool_Exp>;
};

export type Query_RootTournaments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournaments_Order_By>>;
  where?: InputMaybe<Tournaments_Bool_Exp>;
};

export type Query_RootTournaments_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootTransactionStatisticArgs = {
  id: Scalars['Int'];
};

export type Query_RootTransactionStatisticsArgs = {
  distinct_on?: InputMaybe<Array<TransactionStatistics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransactionStatistics_Order_By>>;
  where?: InputMaybe<TransactionStatistics_Bool_Exp>;
};

export type Query_RootTransactionStatisticsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TransactionStatistics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransactionStatistics_Order_By>>;
  where?: InputMaybe<TransactionStatistics_Bool_Exp>;
};

export type Query_RootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

export type Query_RootTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

export type Query_RootTransactions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUpdateAvailableAvatarNfTsArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUserArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUserErc721Args = {
  id: Scalars['bigint'];
};

export type Query_RootUserErc721sArgs = {
  distinct_on?: InputMaybe<Array<UserErc721_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserErc721_Order_By>>;
  where?: InputMaybe<UserErc721_Bool_Exp>;
};

export type Query_RootUserErc721sAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserErc721_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserErc721_Order_By>>;
  where?: InputMaybe<UserErc721_Bool_Exp>;
};

export type Query_RootUserJobArgs = {
  id: Scalars['Int'];
};

export type Query_RootUserJobsArgs = {
  distinct_on?: InputMaybe<Array<UserJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobs_Order_By>>;
  where?: InputMaybe<UserJobs_Bool_Exp>;
};

export type Query_RootUserJobsAggregageArgs = {
  distinct_on?: InputMaybe<Array<UserJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobs_Order_By>>;
  where?: InputMaybe<UserJobs_Bool_Exp>;
};

export type Query_RootUser_Erc721_BlockArgs = {
  distinct_on?: InputMaybe<Array<User_Erc721_Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Erc721_Block_Order_By>>;
  where?: InputMaybe<User_Erc721_Block_Bool_Exp>;
};

export type Query_RootUser_Erc721_Block_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Erc721_Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Erc721_Block_Order_By>>;
  where?: InputMaybe<User_Erc721_Block_Bool_Exp>;
};

export type Query_RootUser_Erc721_Block_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_GpanelArgs = {
  distinct_on?: InputMaybe<Array<User_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Gpanel_Order_By>>;
  where?: InputMaybe<User_Gpanel_Bool_Exp>;
};

export type Query_RootUser_Gpanel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Gpanel_Order_By>>;
  where?: InputMaybe<User_Gpanel_Bool_Exp>;
};

export type Query_RootUser_Gpanel_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_LockArgs = {
  distinct_on?: InputMaybe<Array<User_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lock_Order_By>>;
  where?: InputMaybe<User_Lock_Bool_Exp>;
};

export type Query_RootUser_Lock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lock_Order_By>>;
  where?: InputMaybe<User_Lock_Bool_Exp>;
};

export type Query_RootUser_Lock_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootUser_StakingArgs = {
  distinct_on?: InputMaybe<Array<User_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Staking_Order_By>>;
  where?: InputMaybe<User_Staking_Bool_Exp>;
};

export type Query_RootUser_Staking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Staking_Order_By>>;
  where?: InputMaybe<User_Staking_Bool_Exp>;
};

export type Query_RootUser_Staking_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_Staking_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Staking_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Staking_Transactions_Order_By>>;
  where?: InputMaybe<User_Staking_Transactions_Bool_Exp>;
};

export type Query_RootUser_Staking_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Staking_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Staking_Transactions_Order_By>>;
  where?: InputMaybe<User_Staking_Transactions_Bool_Exp>;
};

export type Query_RootUser_Staking_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_LoginArgs = {
  distinct_on?: InputMaybe<Array<Users_Login_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Login_Order_By>>;
  where?: InputMaybe<Users_Login_Bool_Exp>;
};

export type Query_RootUsers_Login_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Login_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Login_Order_By>>;
  where?: InputMaybe<Users_Login_Bool_Exp>;
};

export type Query_RootUsers_OldArgs = {
  distinct_on?: InputMaybe<Array<Users_Old_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Old_Order_By>>;
  where?: InputMaybe<Users_Old_Bool_Exp>;
};

export type Query_RootUsers_Old_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Old_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Old_Order_By>>;
  where?: InputMaybe<Users_Old_Bool_Exp>;
};

export type Query_RootUsers_Old_By_PkArgs = {
  id: Scalars['bigint'];
};

/** columns and relationships of "rakeback_lock" */
export type RakebackLocks = {
  __typename?: 'rakebackLocks';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  level: Scalars['Int'];
  percentage: Scalars['numeric'];
  percentageNft?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  usd: Scalars['Int'];
};

/** aggregated selection of "rakeback_lock" */
export type RakebackLocks_Aggregate = {
  __typename?: 'rakebackLocks_aggregate';
  aggregate?: Maybe<RakebackLocks_Aggregate_Fields>;
  nodes: Array<RakebackLocks>;
};

/** aggregate fields of "rakeback_lock" */
export type RakebackLocks_Aggregate_Fields = {
  __typename?: 'rakebackLocks_aggregate_fields';
  avg?: Maybe<RakebackLocks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RakebackLocks_Max_Fields>;
  min?: Maybe<RakebackLocks_Min_Fields>;
  stddev?: Maybe<RakebackLocks_Stddev_Fields>;
  stddev_pop?: Maybe<RakebackLocks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RakebackLocks_Stddev_Samp_Fields>;
  sum?: Maybe<RakebackLocks_Sum_Fields>;
  var_pop?: Maybe<RakebackLocks_Var_Pop_Fields>;
  var_samp?: Maybe<RakebackLocks_Var_Samp_Fields>;
  variance?: Maybe<RakebackLocks_Variance_Fields>;
};

/** aggregate fields of "rakeback_lock" */
export type RakebackLocks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RakebackLocks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RakebackLocks_Avg_Fields = {
  __typename?: 'rakebackLocks_avg_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  percentageNft?: Maybe<Scalars['Float']>;
  usd?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rakeback_lock". All fields are combined with a logical 'AND'. */
export type RakebackLocks_Bool_Exp = {
  _and?: InputMaybe<Array<RakebackLocks_Bool_Exp>>;
  _not?: InputMaybe<RakebackLocks_Bool_Exp>;
  _or?: InputMaybe<Array<RakebackLocks_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  percentage?: InputMaybe<Numeric_Comparison_Exp>;
  percentageNft?: InputMaybe<Numeric_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  usd?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "rakeback_lock" */
export enum RakebackLocks_Constraint {
  /** unique or primary key constraint on columns "id" */
  RakebackLockPkey = 'rakeback_lock_pkey',
}

/** input type for incrementing numeric columns in table "rakeback_lock" */
export type RakebackLocks_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['numeric']>;
  percentageNft?: InputMaybe<Scalars['numeric']>;
  usd?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rakeback_lock" */
export type RakebackLocks_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['numeric']>;
  percentageNft?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  usd?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type RakebackLocks_Max_Fields = {
  __typename?: 'rakebackLocks_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['numeric']>;
  percentageNft?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  usd?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type RakebackLocks_Min_Fields = {
  __typename?: 'rakebackLocks_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['numeric']>;
  percentageNft?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  usd?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "rakeback_lock" */
export type RakebackLocks_Mutation_Response = {
  __typename?: 'rakebackLocks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RakebackLocks>;
};

/** on_conflict condition type for table "rakeback_lock" */
export type RakebackLocks_On_Conflict = {
  constraint: RakebackLocks_Constraint;
  update_columns?: Array<RakebackLocks_Update_Column>;
  where?: InputMaybe<RakebackLocks_Bool_Exp>;
};

/** Ordering options when selecting data from "rakeback_lock". */
export type RakebackLocks_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  percentageNft?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  usd?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rakeback_lock */
export type RakebackLocks_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "rakeback_lock" */
export enum RakebackLocks_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Level = 'level',
  /** column name */
  Percentage = 'percentage',
  /** column name */
  PercentageNft = 'percentageNft',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Usd = 'usd',
}

/** input type for updating data in table "rakeback_lock" */
export type RakebackLocks_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['numeric']>;
  percentageNft?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  usd?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type RakebackLocks_Stddev_Fields = {
  __typename?: 'rakebackLocks_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  percentageNft?: Maybe<Scalars['Float']>;
  usd?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RakebackLocks_Stddev_Pop_Fields = {
  __typename?: 'rakebackLocks_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  percentageNft?: Maybe<Scalars['Float']>;
  usd?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RakebackLocks_Stddev_Samp_Fields = {
  __typename?: 'rakebackLocks_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  percentageNft?: Maybe<Scalars['Float']>;
  usd?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rakebackLocks" */
export type RakebackLocks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RakebackLocks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RakebackLocks_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['numeric']>;
  percentageNft?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  usd?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type RakebackLocks_Sum_Fields = {
  __typename?: 'rakebackLocks_sum_fields';
  id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['numeric']>;
  percentageNft?: Maybe<Scalars['numeric']>;
  usd?: Maybe<Scalars['Int']>;
};

/** update columns of table "rakeback_lock" */
export enum RakebackLocks_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Level = 'level',
  /** column name */
  Percentage = 'percentage',
  /** column name */
  PercentageNft = 'percentageNft',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Usd = 'usd',
}

export type RakebackLocks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RakebackLocks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RakebackLocks_Set_Input>;
  where: RakebackLocks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RakebackLocks_Var_Pop_Fields = {
  __typename?: 'rakebackLocks_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  percentageNft?: Maybe<Scalars['Float']>;
  usd?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RakebackLocks_Var_Samp_Fields = {
  __typename?: 'rakebackLocks_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  percentageNft?: Maybe<Scalars['Float']>;
  usd?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RakebackLocks_Variance_Fields = {
  __typename?: 'rakebackLocks_variance_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  percentageNft?: Maybe<Scalars['Float']>;
  usd?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "stablecoins" */
export type Stablecoins = {
  __typename?: 'stablecoins';
  /** An object relationship */
  blockchain: Blockchain;
  blockchainId: Scalars['Int'];
  contract: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  decimals: Scalars['Int'];
  depositActive: Scalars['Boolean'];
  depositAddressActive: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  symbol: Scalars['String'];
  units: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['numeric'];
  withdrawActive: Scalars['Boolean'];
};

/** aggregated selection of "stablecoins" */
export type Stablecoins_Aggregate = {
  __typename?: 'stablecoins_aggregate';
  aggregate?: Maybe<Stablecoins_Aggregate_Fields>;
  nodes: Array<Stablecoins>;
};

export type Stablecoins_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Stablecoins_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Stablecoins_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Stablecoins_Aggregate_Bool_Exp_Count>;
};

export type Stablecoins_Aggregate_Bool_Exp_Bool_And = {
  arguments: Stablecoins_Select_Column_Stablecoins_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stablecoins_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stablecoins_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Stablecoins_Select_Column_Stablecoins_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stablecoins_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stablecoins_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stablecoins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stablecoins_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stablecoins" */
export type Stablecoins_Aggregate_Fields = {
  __typename?: 'stablecoins_aggregate_fields';
  avg?: Maybe<Stablecoins_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stablecoins_Max_Fields>;
  min?: Maybe<Stablecoins_Min_Fields>;
  stddev?: Maybe<Stablecoins_Stddev_Fields>;
  stddev_pop?: Maybe<Stablecoins_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stablecoins_Stddev_Samp_Fields>;
  sum?: Maybe<Stablecoins_Sum_Fields>;
  var_pop?: Maybe<Stablecoins_Var_Pop_Fields>;
  var_samp?: Maybe<Stablecoins_Var_Samp_Fields>;
  variance?: Maybe<Stablecoins_Variance_Fields>;
};

/** aggregate fields of "stablecoins" */
export type Stablecoins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stablecoins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stablecoins" */
export type Stablecoins_Aggregate_Order_By = {
  avg?: InputMaybe<Stablecoins_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stablecoins_Max_Order_By>;
  min?: InputMaybe<Stablecoins_Min_Order_By>;
  stddev?: InputMaybe<Stablecoins_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stablecoins_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stablecoins_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stablecoins_Sum_Order_By>;
  var_pop?: InputMaybe<Stablecoins_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stablecoins_Var_Samp_Order_By>;
  variance?: InputMaybe<Stablecoins_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stablecoins" */
export type Stablecoins_Arr_Rel_Insert_Input = {
  data: Array<Stablecoins_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stablecoins_On_Conflict>;
};

/** aggregate avg on columns */
export type Stablecoins_Avg_Fields = {
  __typename?: 'stablecoins_avg_fields';
  blockchainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stablecoins" */
export type Stablecoins_Avg_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stablecoins". All fields are combined with a logical 'AND'. */
export type Stablecoins_Bool_Exp = {
  _and?: InputMaybe<Array<Stablecoins_Bool_Exp>>;
  _not?: InputMaybe<Stablecoins_Bool_Exp>;
  _or?: InputMaybe<Array<Stablecoins_Bool_Exp>>;
  blockchain?: InputMaybe<Blockchain_Bool_Exp>;
  blockchainId?: InputMaybe<Int_Comparison_Exp>;
  contract?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  decimals?: InputMaybe<Int_Comparison_Exp>;
  depositActive?: InputMaybe<Boolean_Comparison_Exp>;
  depositAddressActive?: InputMaybe<Boolean_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  iconUrl?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  units?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
  withdrawActive?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "stablecoins" */
export enum Stablecoins_Constraint {
  /** unique or primary key constraint on columns "id" */
  StablecoinsPkey = 'stablecoins_pkey',
}

/** input type for incrementing numeric columns in table "stablecoins" */
export type Stablecoins_Inc_Input = {
  blockchainId?: InputMaybe<Scalars['Int']>;
  decimals?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "stablecoins" */
export type Stablecoins_Insert_Input = {
  blockchain?: InputMaybe<Blockchain_Obj_Rel_Insert_Input>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  decimals?: InputMaybe<Scalars['Int']>;
  depositActive?: InputMaybe<Scalars['Boolean']>;
  depositAddressActive?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  symbol?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
  withdrawActive?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Stablecoins_Max_Fields = {
  __typename?: 'stablecoins_max_fields';
  blockchainId?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  decimals?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "stablecoins" */
export type Stablecoins_Max_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  iconUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  units?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stablecoins_Min_Fields = {
  __typename?: 'stablecoins_min_fields';
  blockchainId?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  decimals?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "stablecoins" */
export type Stablecoins_Min_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  iconUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  units?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stablecoins" */
export type Stablecoins_Mutation_Response = {
  __typename?: 'stablecoins_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stablecoins>;
};

/** input type for inserting object relation for remote table "stablecoins" */
export type Stablecoins_Obj_Rel_Insert_Input = {
  data: Stablecoins_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stablecoins_On_Conflict>;
};

/** on_conflict condition type for table "stablecoins" */
export type Stablecoins_On_Conflict = {
  constraint: Stablecoins_Constraint;
  update_columns?: Array<Stablecoins_Update_Column>;
  where?: InputMaybe<Stablecoins_Bool_Exp>;
};

/** Ordering options when selecting data from "stablecoins". */
export type Stablecoins_Order_By = {
  blockchain?: InputMaybe<Blockchain_Order_By>;
  blockchainId?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  depositActive?: InputMaybe<Order_By>;
  depositAddressActive?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  iconUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  units?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  withdrawActive?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stablecoins */
export type Stablecoins_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stablecoins" */
export enum Stablecoins_Select_Column {
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  DepositActive = 'depositActive',
  /** column name */
  DepositAddressActive = 'depositAddressActive',
  /** column name */
  Icon = 'icon',
  /** column name */
  IconUrl = 'iconUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  Units = 'units',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  WithdrawActive = 'withdrawActive',
}

/** select "stablecoins_aggregate_bool_exp_bool_and_arguments_columns" columns of table "stablecoins" */
export enum Stablecoins_Select_Column_Stablecoins_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DepositActive = 'depositActive',
  /** column name */
  DepositAddressActive = 'depositAddressActive',
  /** column name */
  WithdrawActive = 'withdrawActive',
}

/** select "stablecoins_aggregate_bool_exp_bool_or_arguments_columns" columns of table "stablecoins" */
export enum Stablecoins_Select_Column_Stablecoins_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DepositActive = 'depositActive',
  /** column name */
  DepositAddressActive = 'depositAddressActive',
  /** column name */
  WithdrawActive = 'withdrawActive',
}

/** input type for updating data in table "stablecoins" */
export type Stablecoins_Set_Input = {
  blockchainId?: InputMaybe<Scalars['Int']>;
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  decimals?: InputMaybe<Scalars['Int']>;
  depositActive?: InputMaybe<Scalars['Boolean']>;
  depositAddressActive?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  symbol?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
  withdrawActive?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Stablecoins_Stddev_Fields = {
  __typename?: 'stablecoins_stddev_fields';
  blockchainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stablecoins" */
export type Stablecoins_Stddev_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stablecoins_Stddev_Pop_Fields = {
  __typename?: 'stablecoins_stddev_pop_fields';
  blockchainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stablecoins" */
export type Stablecoins_Stddev_Pop_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stablecoins_Stddev_Samp_Fields = {
  __typename?: 'stablecoins_stddev_samp_fields';
  blockchainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stablecoins" */
export type Stablecoins_Stddev_Samp_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stablecoins" */
export type Stablecoins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stablecoins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stablecoins_Stream_Cursor_Value_Input = {
  blockchainId?: InputMaybe<Scalars['Int']>;
  contract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  decimals?: InputMaybe<Scalars['Int']>;
  depositActive?: InputMaybe<Scalars['Boolean']>;
  depositAddressActive?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  symbol?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
  withdrawActive?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Stablecoins_Sum_Fields = {
  __typename?: 'stablecoins_sum_fields';
  blockchainId?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "stablecoins" */
export type Stablecoins_Sum_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "stablecoins" */
export enum Stablecoins_Update_Column {
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  DepositActive = 'depositActive',
  /** column name */
  DepositAddressActive = 'depositAddressActive',
  /** column name */
  Icon = 'icon',
  /** column name */
  IconUrl = 'iconUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  Units = 'units',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  WithdrawActive = 'withdrawActive',
}

export type Stablecoins_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stablecoins_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stablecoins_Set_Input>;
  where: Stablecoins_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stablecoins_Var_Pop_Fields = {
  __typename?: 'stablecoins_var_pop_fields';
  blockchainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stablecoins" */
export type Stablecoins_Var_Pop_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stablecoins_Var_Samp_Fields = {
  __typename?: 'stablecoins_var_samp_fields';
  blockchainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stablecoins" */
export type Stablecoins_Var_Samp_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stablecoins_Variance_Fields = {
  __typename?: 'stablecoins_variance_fields';
  blockchainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stablecoins" */
export type Stablecoins_Variance_Order_By = {
  blockchainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type StakingOutput = {
  __typename?: 'stakingOutput';
  busd: Scalars['Float'];
  inp: Scalars['Float'];
  nft: Scalars['String'];
  rackbackId: Scalars['Int'];
  status: Scalars['String'];
};

/** The staking contract is refilled automatically. This table contains the schedule for it. */
export type Staking_Refill = {
  __typename?: 'staking_refill';
  amount: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  status: Scalars['String'];
  tx?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  week: Scalars['Int'];
  year: Scalars['Int'];
};

/** aggregated selection of "staking_refill" */
export type Staking_Refill_Aggregate = {
  __typename?: 'staking_refill_aggregate';
  aggregate?: Maybe<Staking_Refill_Aggregate_Fields>;
  nodes: Array<Staking_Refill>;
};

/** aggregate fields of "staking_refill" */
export type Staking_Refill_Aggregate_Fields = {
  __typename?: 'staking_refill_aggregate_fields';
  avg?: Maybe<Staking_Refill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Staking_Refill_Max_Fields>;
  min?: Maybe<Staking_Refill_Min_Fields>;
  stddev?: Maybe<Staking_Refill_Stddev_Fields>;
  stddev_pop?: Maybe<Staking_Refill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Staking_Refill_Stddev_Samp_Fields>;
  sum?: Maybe<Staking_Refill_Sum_Fields>;
  var_pop?: Maybe<Staking_Refill_Var_Pop_Fields>;
  var_samp?: Maybe<Staking_Refill_Var_Samp_Fields>;
  variance?: Maybe<Staking_Refill_Variance_Fields>;
};

/** aggregate fields of "staking_refill" */
export type Staking_Refill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staking_Refill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Staking_Refill_Avg_Fields = {
  __typename?: 'staking_refill_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "staking_refill". All fields are combined with a logical 'AND'. */
export type Staking_Refill_Bool_Exp = {
  _and?: InputMaybe<Array<Staking_Refill_Bool_Exp>>;
  _not?: InputMaybe<Staking_Refill_Bool_Exp>;
  _or?: InputMaybe<Array<Staking_Refill_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tx?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  week?: InputMaybe<Int_Comparison_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "staking_refill" */
export enum Staking_Refill_Constraint {
  /** unique or primary key constraint on columns "id" */
  StakingRefillPkey = 'staking_refill_pkey',
}

/** input type for incrementing numeric columns in table "staking_refill" */
export type Staking_Refill_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  week?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staking_refill" */
export type Staking_Refill_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tx?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  week?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Staking_Refill_Max_Fields = {
  __typename?: 'staking_refill_max_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tx?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Staking_Refill_Min_Fields = {
  __typename?: 'staking_refill_min_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tx?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "staking_refill" */
export type Staking_Refill_Mutation_Response = {
  __typename?: 'staking_refill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staking_Refill>;
};

/** on_conflict condition type for table "staking_refill" */
export type Staking_Refill_On_Conflict = {
  constraint: Staking_Refill_Constraint;
  update_columns?: Array<Staking_Refill_Update_Column>;
  where?: InputMaybe<Staking_Refill_Bool_Exp>;
};

/** Ordering options when selecting data from "staking_refill". */
export type Staking_Refill_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tx?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staking_refill */
export type Staking_Refill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "staking_refill" */
export enum Staking_Refill_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Tx = 'tx',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Week = 'week',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "staking_refill" */
export type Staking_Refill_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tx?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  week?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Staking_Refill_Stddev_Fields = {
  __typename?: 'staking_refill_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Staking_Refill_Stddev_Pop_Fields = {
  __typename?: 'staking_refill_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Staking_Refill_Stddev_Samp_Fields = {
  __typename?: 'staking_refill_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "staking_refill" */
export type Staking_Refill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staking_Refill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staking_Refill_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tx?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  week?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Staking_Refill_Sum_Fields = {
  __typename?: 'staking_refill_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** update columns of table "staking_refill" */
export enum Staking_Refill_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Tx = 'tx',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Week = 'week',
  /** column name */
  Year = 'year',
}

export type Staking_Refill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Staking_Refill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staking_Refill_Set_Input>;
  where: Staking_Refill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staking_Refill_Var_Pop_Fields = {
  __typename?: 'staking_refill_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Staking_Refill_Var_Samp_Fields = {
  __typename?: 'staking_refill_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Staking_Refill_Variance_Fields = {
  __typename?: 'staking_refill_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Test" */
  Test: Array<Test>;
  /** fetch aggregated fields from the table: "Test" */
  Test_aggregate: Test_Aggregate;
  /** fetch data from the table: "Test" using primary key columns */
  Test_by_pk?: Maybe<Test>;
  /** fetch data from the table in a streaming manner: "Test" */
  Test_stream: Array<Test>;
  /** fetch data from the table: "anno_gpanel" */
  anno_gpanel: Array<Anno_Gpanel>;
  /** fetch aggregated fields from the table: "anno_gpanel" */
  anno_gpanel_aggregate: Anno_Gpanel_Aggregate;
  /** fetch data from the table: "anno_gpanel" using primary key columns */
  anno_gpanel_by_pk?: Maybe<Anno_Gpanel>;
  /** fetch data from the table in a streaming manner: "anno_gpanel" */
  anno_gpanel_stream: Array<Anno_Gpanel>;
  /** fetch data from the table: "blockchain" using primary key columns */
  blockchain?: Maybe<Blockchain>;
  /** fetch data from the table: "blockchain_main_deposit" */
  blockchain_main_deposit: Array<Blockchain_Main_Deposit>;
  /** fetch aggregated fields from the table: "blockchain_main_deposit" */
  blockchain_main_deposit_aggregate: Blockchain_Main_Deposit_Aggregate;
  /** fetch data from the table: "blockchain_main_deposit" using primary key columns */
  blockchain_main_deposit_by_pk?: Maybe<Blockchain_Main_Deposit>;
  /** fetch data from the table in a streaming manner: "blockchain_main_deposit" */
  blockchain_main_deposit_stream: Array<Blockchain_Main_Deposit>;
  /** fetch data from the table in a streaming manner: "blockchain" */
  blockchain_stream: Array<Blockchain>;
  /** fetch data from the table: "blockchain" */
  blockchains: Array<Blockchain>;
  /** fetch aggregated fields from the table: "blockchain" */
  blockchainsAggregate: Blockchain_Aggregate;
  /** fetch data from the table: "burn_profit" */
  burn_profit: Array<Burn_Profit>;
  /** fetch aggregated fields from the table: "burn_profit" */
  burn_profit_aggregate: Burn_Profit_Aggregate;
  /** fetch data from the table: "burn_profit" using primary key columns */
  burn_profit_by_pk?: Maybe<Burn_Profit>;
  /** fetch data from the table in a streaming manner: "burn_profit" */
  burn_profit_stream: Array<Burn_Profit>;
  /** fetch data from the table: "currencies" */
  currencies: Array<Currencies>;
  /** fetch aggregated fields from the table: "currencies" */
  currencies_aggregate: Currencies_Aggregate;
  /** fetch data from the table: "currencies" using primary key columns */
  currencies_by_pk?: Maybe<Currencies>;
  /** fetch data from the table in a streaming manner: "currencies" */
  currencies_stream: Array<Currencies>;
  /** fetch data from the table: "currency_history" */
  currency_history: Array<Currency_History>;
  /** fetch aggregated fields from the table: "currency_history" */
  currency_history_aggregate: Currency_History_Aggregate;
  /** fetch data from the table: "currency_history" using primary key columns */
  currency_history_by_pk?: Maybe<Currency_History>;
  /** fetch data from the table in a streaming manner: "currency_history" */
  currency_history_stream: Array<Currency_History>;
  /** fetch data from the table: "erc721_addresses" */
  erc721_addresses: Array<Erc721_Addresses>;
  /** fetch aggregated fields from the table: "erc721_addresses" */
  erc721_addresses_aggregate: Erc721_Addresses_Aggregate;
  /** fetch data from the table: "erc721_addresses" using primary key columns */
  erc721_addresses_by_pk?: Maybe<Erc721_Addresses>;
  /** fetch data from the table in a streaming manner: "erc721_addresses" */
  erc721_addresses_stream: Array<Erc721_Addresses>;
  /** fetch data from the table: "evenbet_affiliate_player_balance" */
  evenbetAffiliatePlayerBalances: Array<EvenbetAffiliatePlayerBalances>;
  /** fetch aggregated fields from the table: "evenbet_affiliate_player_balance" */
  evenbetAffiliatePlayerBalancesAggregate: EvenbetAffiliatePlayerBalances_Aggregate;
  /** fetch data from the table in a streaming manner: "evenbet_affiliate_player_balance" */
  evenbetAffiliatePlayerBalancesStream: Array<EvenbetAffiliatePlayerBalances>;
  /** fetch data from the table: "evenbet_ticket_requests" */
  evenbet_ticket_requests: Array<Evenbet_Ticket_Requests>;
  /** fetch aggregated fields from the table: "evenbet_ticket_requests" */
  evenbet_ticket_requests_aggregate: Evenbet_Ticket_Requests_Aggregate;
  /** fetch data from the table: "evenbet_ticket_requests" using primary key columns */
  evenbet_ticket_requests_by_pk?: Maybe<Evenbet_Ticket_Requests>;
  /** fetch data from the table in a streaming manner: "evenbet_ticket_requests" */
  evenbet_ticket_requests_stream: Array<Evenbet_Ticket_Requests>;
  /** fetch data from the table: "evenbet_tickets" */
  evenbet_tickets: Array<Evenbet_Tickets>;
  /** fetch aggregated fields from the table: "evenbet_tickets" */
  evenbet_tickets_aggregate: Evenbet_Tickets_Aggregate;
  /** fetch data from the table: "evenbet_tickets" using primary key columns */
  evenbet_tickets_by_pk?: Maybe<Evenbet_Tickets>;
  /** fetch data from the table in a streaming manner: "evenbet_tickets" */
  evenbet_tickets_stream: Array<Evenbet_Tickets>;
  /** fetch data from the table: "gpanel_payments" using primary key columns */
  gpanelPayment?: Maybe<GpanelPayments>;
  /** fetch data from the table: "gpanel_payments" */
  gpanelPayments: Array<GpanelPayments>;
  /** fetch aggregated fields from the table: "gpanel_payments" */
  gpanelPaymentsAggregate: GpanelPayments_Aggregate;
  /** fetch data from the table in a streaming manner: "gpanel_payments" */
  gpanelPayments_stream: Array<GpanelPayments>;
  /** fetch data from the table: "gpanel_users" using primary key columns */
  gpanelUser?: Maybe<GpanelUsers>;
  /** fetch data from the table: "gpanel_users" */
  gpanelUsers: Array<GpanelUsers>;
  /** fetch aggregated fields from the table: "gpanel_users" */
  gpanelUsersAggregate: GpanelUsers_Aggregate;
  /** fetch data from the table in a streaming manner: "gpanel_users" */
  gpanelUsers_stream: Array<GpanelUsers>;
  /** fetch data from the table: "gpanel_users_agent" */
  gpanel_users_agent: Array<Gpanel_Users_Agent>;
  /** fetch aggregated fields from the table: "gpanel_users_agent" */
  gpanel_users_agent_aggregate: Gpanel_Users_Agent_Aggregate;
  /** fetch data from the table: "gpanel_users_agent" using primary key columns */
  gpanel_users_agent_by_pk?: Maybe<Gpanel_Users_Agent>;
  /** fetch data from the table in a streaming manner: "gpanel_users_agent" */
  gpanel_users_agent_stream: Array<Gpanel_Users_Agent>;
  /** fetch data from the table: "gpanel_users_deposit_address" */
  gpanel_users_deposit_address: Array<Gpanel_Users_Deposit_Address>;
  /** fetch aggregated fields from the table: "gpanel_users_deposit_address" */
  gpanel_users_deposit_address_aggregate: Gpanel_Users_Deposit_Address_Aggregate;
  /** fetch data from the table: "gpanel_users_deposit_address" using primary key columns */
  gpanel_users_deposit_address_by_pk?: Maybe<Gpanel_Users_Deposit_Address>;
  /** fetch data from the table in a streaming manner: "gpanel_users_deposit_address" */
  gpanel_users_deposit_address_stream: Array<Gpanel_Users_Deposit_Address>;
  /** fetch data from the table: "gpanel_users_kyc" */
  gpanel_users_kyc: Array<Gpanel_Users_Kyc>;
  /** fetch aggregated fields from the table: "gpanel_users_kyc" */
  gpanel_users_kyc_aggregate: Gpanel_Users_Kyc_Aggregate;
  /** fetch data from the table: "gpanel_users_kyc" using primary key columns */
  gpanel_users_kyc_by_pk?: Maybe<Gpanel_Users_Kyc>;
  /** fetch data from the table in a streaming manner: "gpanel_users_kyc" */
  gpanel_users_kyc_stream: Array<Gpanel_Users_Kyc>;
  /** fetch data from the table: "kyc" using primary key columns */
  kyc?: Maybe<Kycs>;
  /** fetch data from the table: "kyc" */
  kycs: Array<Kycs>;
  /** fetch aggregated fields from the table: "kyc" */
  kycs_aggregate: Kycs_Aggregate;
  /** fetch data from the table in a streaming manner: "kyc" */
  kycs_stream: Array<Kycs>;
  /** fetch data from the table: "leaderboard" */
  leaderboard: Array<Leaderboard>;
  /** fetch aggregated fields from the table: "leaderboard" */
  leaderboard_aggregate: Leaderboard_Aggregate;
  /** fetch data from the table: "leaderboard" using primary key columns */
  leaderboard_by_pk?: Maybe<Leaderboard>;
  /** fetch data from the table: "leaderboard_ranking" */
  leaderboard_ranking: Array<Leaderboard_Ranking>;
  /** fetch aggregated fields from the table: "leaderboard_ranking" */
  leaderboard_ranking_aggregate: Leaderboard_Ranking_Aggregate;
  /** fetch data from the table: "leaderboard_ranking" using primary key columns */
  leaderboard_ranking_by_pk?: Maybe<Leaderboard_Ranking>;
  /** fetch data from the table in a streaming manner: "leaderboard_ranking" */
  leaderboard_ranking_stream: Array<Leaderboard_Ranking>;
  /** fetch data from the table in a streaming manner: "leaderboard" */
  leaderboard_stream: Array<Leaderboard>;
  /** fetch data from the table: "liquidity_pools" */
  liquidity_pools: Array<Liquidity_Pools>;
  /** fetch aggregated fields from the table: "liquidity_pools" */
  liquidity_pools_aggregate: Liquidity_Pools_Aggregate;
  /** fetch data from the table: "liquidity_pools" using primary key columns */
  liquidity_pools_by_pk?: Maybe<Liquidity_Pools>;
  /** fetch data from the table in a streaming manner: "liquidity_pools" */
  liquidity_pools_stream: Array<Liquidity_Pools>;
  /** fetch data from the table: "listings" using primary key columns */
  marketListing?: Maybe<MarketListings>;
  /** fetch data from the table: "listings" */
  marketListings: Array<MarketListings>;
  /** fetch aggregated fields from the table: "listings" */
  marketListingsAggregate: MarketListings_Aggregate;
  /** fetch data from the table in a streaming manner: "listings" */
  marketListings_stream: Array<MarketListings>;
  /** fetch data from the table: "marketplaceContracts" using primary key columns */
  marketplaceContract?: Maybe<MarketplaceContracts>;
  /** fetch data from the table: "marketplaceContracts" */
  marketplaceContracts: Array<MarketplaceContracts>;
  /** fetch aggregated fields from the table: "marketplaceContracts" */
  marketplaceContractsAggregate: MarketplaceContracts_Aggregate;
  /** fetch data from the table in a streaming manner: "marketplaceContracts" */
  marketplaceContracts_stream: Array<MarketplaceContracts>;
  /** fetch data from the table: "nfts" using primary key columns */
  nft?: Maybe<Nfts>;
  /** fetch data from the table: "nftContracts" using primary key columns */
  nftContract?: Maybe<NftContracts>;
  /** fetch data from the table: "nftContracts" */
  nftContracts: Array<NftContracts>;
  /** fetch aggregated fields from the table: "nftContracts" */
  nftContractsAggregate: NftContracts_Aggregate;
  /** fetch data from the table in a streaming manner: "nftContracts" */
  nftContracts_stream: Array<NftContracts>;
  /** fetch data from the table: "nftMetadata" using primary key columns */
  nftMetadata?: Maybe<NftMetadatas>;
  /** fetch data from the table: "nftMetadata" */
  nftMetadatas: Array<NftMetadatas>;
  /** fetch aggregated fields from the table: "nftMetadata" */
  nftMetadatasAggregate: NftMetadatas_Aggregate;
  /** fetch data from the table in a streaming manner: "nftMetadata" */
  nftMetadatas_stream: Array<NftMetadatas>;
  /** fetch data from the table: "nft_owners" using primary key columns */
  nftOwner?: Maybe<NftOwners>;
  /** fetch data from the table: "nft_owners" */
  nftOwners: Array<NftOwners>;
  /** fetch aggregated fields from the table: "nft_owners" */
  nftOwnersAggregate: NftOwners_Aggregate;
  /** fetch data from the table in a streaming manner: "nft_owners" */
  nftOwners_stream: Array<NftOwners>;
  /** fetch data from the table: "nft_gpanel_users" */
  nft_gpanel_users: Array<Nft_Gpanel_Users>;
  /** fetch aggregated fields from the table: "nft_gpanel_users" */
  nft_gpanel_users_aggregate: Nft_Gpanel_Users_Aggregate;
  /** fetch data from the table: "nft_gpanel_users" using primary key columns */
  nft_gpanel_users_by_pk?: Maybe<Nft_Gpanel_Users>;
  /** fetch data from the table in a streaming manner: "nft_gpanel_users" */
  nft_gpanel_users_stream: Array<Nft_Gpanel_Users>;
  /** fetch data from the table: "nft_listings" */
  nft_listings: Array<Nft_Listings>;
  /** fetch aggregated fields from the table: "nft_listings" */
  nft_listings_aggregate: Nft_Listings_Aggregate;
  /** fetch data from the table: "nft_listings" using primary key columns */
  nft_listings_by_pk?: Maybe<Nft_Listings>;
  /** fetch data from the table in a streaming manner: "nft_listings" */
  nft_listings_stream: Array<Nft_Listings>;
  /** fetch data from the table: "nft_transactions" */
  nft_transactions: Array<Nft_Transactions>;
  /** fetch aggregated fields from the table: "nft_transactions" */
  nft_transactions_aggregate: Nft_Transactions_Aggregate;
  /** fetch data from the table: "nft_transactions" using primary key columns */
  nft_transactions_by_pk?: Maybe<Nft_Transactions>;
  /** fetch data from the table in a streaming manner: "nft_transactions" */
  nft_transactions_stream: Array<Nft_Transactions>;
  /** fetch data from the table: "nfts" */
  nfts: Array<Nfts>;
  /** fetch aggregated fields from the table: "nfts" */
  nftsAggregate: Nfts_Aggregate;
  /** fetch data from the table in a streaming manner: "nfts" */
  nfts_stream: Array<Nfts>;
  /** fetch data from the table: "pending_transactions" using primary key columns */
  pendingTransaction?: Maybe<PendingTransactions>;
  /** fetch data from the table: "pending_transactions" */
  pendingTransactions: Array<PendingTransactions>;
  /** fetch aggregated fields from the table: "pending_transactions" */
  pendingTransactionsAggregate: PendingTransactions_Aggregate;
  /** fetch data from the table in a streaming manner: "pending_transactions" */
  pendingTransactions_stream: Array<PendingTransactions>;
  /** fetch data from the table: "pending_tx" */
  pending_tx: Array<Pending_Tx>;
  /** fetch aggregated fields from the table: "pending_tx" */
  pending_tx_aggregate: Pending_Tx_Aggregate;
  /** fetch data from the table: "pending_tx" using primary key columns */
  pending_tx_by_pk?: Maybe<Pending_Tx>;
  /** fetch data from the table in a streaming manner: "pending_tx" */
  pending_tx_stream: Array<Pending_Tx>;
  /** fetch data from the table: "rakeback_lock" */
  rakebackLocks: Array<RakebackLocks>;
  /** fetch aggregated fields from the table: "rakeback_lock" */
  rakebackLocks_aggregate: RakebackLocks_Aggregate;
  /** fetch data from the table: "rakeback_lock" using primary key columns */
  rakebackLocks_by_pk?: Maybe<RakebackLocks>;
  /** fetch data from the table in a streaming manner: "rakeback_lock" */
  rakebackLocks_stream: Array<RakebackLocks>;
  /** fetch data from the table: "stablecoins" using primary key columns */
  stablecoin?: Maybe<Stablecoins>;
  /** An array relationship */
  stablecoins: Array<Stablecoins>;
  /** fetch aggregated fields from the table: "stablecoins" */
  stablecoinsAggregate: Stablecoins_Aggregate;
  /** fetch data from the table in a streaming manner: "stablecoins" */
  stablecoins_stream: Array<Stablecoins>;
  /** fetch data from the table: "staking_refill" */
  staking_refill: Array<Staking_Refill>;
  /** fetch aggregated fields from the table: "staking_refill" */
  staking_refill_aggregate: Staking_Refill_Aggregate;
  /** fetch data from the table: "staking_refill" using primary key columns */
  staking_refill_by_pk?: Maybe<Staking_Refill>;
  /** fetch data from the table in a streaming manner: "staking_refill" */
  staking_refill_stream: Array<Staking_Refill>;
  /** fetch data from the table: "swap_transactions" */
  swapTransactions: Array<SwapTransactions>;
  /** fetch aggregated fields from the table: "swap_transactions" */
  swapTransactionsAggregate: SwapTransactions_Aggregate;
  /** fetch data from the table in a streaming manner: "swap_transactions" */
  swapTransactionsStream: Array<SwapTransactions>;
  /** fetch data from the table: "tournaments" */
  tournaments: Array<Tournaments>;
  /** fetch aggregated fields from the table: "tournaments" */
  tournaments_aggregate: Tournaments_Aggregate;
  /** fetch data from the table: "tournaments" using primary key columns */
  tournaments_by_pk?: Maybe<Tournaments>;
  /** fetch data from the table in a streaming manner: "tournaments" */
  tournaments_stream: Array<Tournaments>;
  /** fetch data from the table: "transactionStatistics" using primary key columns */
  transactionStatistic?: Maybe<TransactionStatistics>;
  /** fetch data from the table: "transactionStatistics" */
  transactionStatistics: Array<TransactionStatistics>;
  /** fetch aggregated fields from the table: "transactionStatistics" */
  transactionStatisticsAggregate: TransactionStatistics_Aggregate;
  /** fetch data from the table in a streaming manner: "transactionStatistics" */
  transactionStatistics_stream: Array<TransactionStatistics>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactionsAggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table in a streaming manner: "transactions" */
  transactions_stream: Array<Transactions>;
  updateAvailableAvatarNFTs?: Maybe<UpdateAvailableAvatarNfTs>;
  /** fetch data from the table: "users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_erc721" using primary key columns */
  userErc721?: Maybe<UserErc721>;
  /** fetch data from the table in a streaming manner: "user_erc721" */
  userErc721_stream: Array<UserErc721>;
  /** fetch data from the table: "user_erc721" */
  userErc721s: Array<UserErc721>;
  /** fetch aggregated fields from the table: "user_erc721" */
  userErc721sAggregate: UserErc721_Aggregate;
  /** fetch data from the table: "user_jobs" using primary key columns */
  userJob?: Maybe<UserJobs>;
  /** fetch data from the table: "user_jobs" */
  userJobs: Array<UserJobs>;
  /** fetch aggregated fields from the table: "user_jobs" */
  userJobsAggregage: UserJobs_Aggregate;
  /** fetch data from the table in a streaming manner: "user_jobs" */
  userJobsStream: Array<UserJobs>;
  /** fetch data from the table: "user_erc721_block" */
  user_erc721_block: Array<User_Erc721_Block>;
  /** fetch aggregated fields from the table: "user_erc721_block" */
  user_erc721_block_aggregate: User_Erc721_Block_Aggregate;
  /** fetch data from the table: "user_erc721_block" using primary key columns */
  user_erc721_block_by_pk?: Maybe<User_Erc721_Block>;
  /** fetch data from the table in a streaming manner: "user_erc721_block" */
  user_erc721_block_stream: Array<User_Erc721_Block>;
  /** fetch data from the table: "user_gpanel" */
  user_gpanel: Array<User_Gpanel>;
  /** fetch aggregated fields from the table: "user_gpanel" */
  user_gpanel_aggregate: User_Gpanel_Aggregate;
  /** fetch data from the table: "user_gpanel" using primary key columns */
  user_gpanel_by_pk?: Maybe<User_Gpanel>;
  /** fetch data from the table in a streaming manner: "user_gpanel" */
  user_gpanel_stream: Array<User_Gpanel>;
  /** fetch data from the table: "user_lock" */
  user_lock: Array<User_Lock>;
  /** fetch aggregated fields from the table: "user_lock" */
  user_lock_aggregate: User_Lock_Aggregate;
  /** fetch data from the table: "user_lock" using primary key columns */
  user_lock_by_pk?: Maybe<User_Lock>;
  /** fetch data from the table in a streaming manner: "user_lock" */
  user_lock_stream: Array<User_Lock>;
  /** fetch data from the table: "user_staking" */
  user_staking: Array<User_Staking>;
  /** fetch aggregated fields from the table: "user_staking" */
  user_staking_aggregate: User_Staking_Aggregate;
  /** fetch data from the table: "user_staking" using primary key columns */
  user_staking_by_pk?: Maybe<User_Staking>;
  /** fetch data from the table in a streaming manner: "user_staking" */
  user_staking_stream: Array<User_Staking>;
  /** fetch data from the table: "user_staking_transactions" */
  user_staking_transactions: Array<User_Staking_Transactions>;
  /** fetch aggregated fields from the table: "user_staking_transactions" */
  user_staking_transactions_aggregate: User_Staking_Transactions_Aggregate;
  /** fetch data from the table: "user_staking_transactions" using primary key columns */
  user_staking_transactions_by_pk?: Maybe<User_Staking_Transactions>;
  /** fetch data from the table in a streaming manner: "user_staking_transactions" */
  user_staking_transactions_stream: Array<User_Staking_Transactions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table: "users_login" */
  users_login: Array<Users_Login>;
  /** fetch aggregated fields from the table: "users_login" */
  users_login_aggregate: Users_Login_Aggregate;
  /** fetch data from the table in a streaming manner: "users_login" */
  users_login_stream: Array<Users_Login>;
  /** fetch data from the table: "users_old" */
  users_old: Array<Users_Old>;
  /** fetch aggregated fields from the table: "users_old" */
  users_old_aggregate: Users_Old_Aggregate;
  /** fetch data from the table: "users_old" using primary key columns */
  users_old_by_pk?: Maybe<Users_Old>;
  /** fetch data from the table in a streaming manner: "users_old" */
  users_old_stream: Array<Users_Old>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};

export type Subscription_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};

export type Subscription_RootTest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};

export type Subscription_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Bool_Exp>;
};

export type Subscription_RootAnno_GpanelArgs = {
  distinct_on?: InputMaybe<Array<Anno_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anno_Gpanel_Order_By>>;
  where?: InputMaybe<Anno_Gpanel_Bool_Exp>;
};

export type Subscription_RootAnno_Gpanel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Anno_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anno_Gpanel_Order_By>>;
  where?: InputMaybe<Anno_Gpanel_Bool_Exp>;
};

export type Subscription_RootAnno_Gpanel_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootAnno_Gpanel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Anno_Gpanel_Stream_Cursor_Input>>;
  where?: InputMaybe<Anno_Gpanel_Bool_Exp>;
};

export type Subscription_RootBlockchainArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootBlockchain_Main_DepositArgs = {
  distinct_on?: InputMaybe<Array<Blockchain_Main_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blockchain_Main_Deposit_Order_By>>;
  where?: InputMaybe<Blockchain_Main_Deposit_Bool_Exp>;
};

export type Subscription_RootBlockchain_Main_Deposit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blockchain_Main_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blockchain_Main_Deposit_Order_By>>;
  where?: InputMaybe<Blockchain_Main_Deposit_Bool_Exp>;
};

export type Subscription_RootBlockchain_Main_Deposit_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootBlockchain_Main_Deposit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Blockchain_Main_Deposit_Stream_Cursor_Input>>;
  where?: InputMaybe<Blockchain_Main_Deposit_Bool_Exp>;
};

export type Subscription_RootBlockchain_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Blockchain_Stream_Cursor_Input>>;
  where?: InputMaybe<Blockchain_Bool_Exp>;
};

export type Subscription_RootBlockchainsArgs = {
  distinct_on?: InputMaybe<Array<Blockchain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blockchain_Order_By>>;
  where?: InputMaybe<Blockchain_Bool_Exp>;
};

export type Subscription_RootBlockchainsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Blockchain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blockchain_Order_By>>;
  where?: InputMaybe<Blockchain_Bool_Exp>;
};

export type Subscription_RootBurn_ProfitArgs = {
  distinct_on?: InputMaybe<Array<Burn_Profit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Burn_Profit_Order_By>>;
  where?: InputMaybe<Burn_Profit_Bool_Exp>;
};

export type Subscription_RootBurn_Profit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Burn_Profit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Burn_Profit_Order_By>>;
  where?: InputMaybe<Burn_Profit_Bool_Exp>;
};

export type Subscription_RootBurn_Profit_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootBurn_Profit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Burn_Profit_Stream_Cursor_Input>>;
  where?: InputMaybe<Burn_Profit_Bool_Exp>;
};

export type Subscription_RootCurrenciesArgs = {
  distinct_on?: InputMaybe<Array<Currencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencies_Order_By>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};

export type Subscription_RootCurrencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencies_Order_By>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};

export type Subscription_RootCurrencies_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootCurrencies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Currencies_Stream_Cursor_Input>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};

export type Subscription_RootCurrency_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Currency_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_History_Order_By>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};

export type Subscription_RootCurrency_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_History_Order_By>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};

export type Subscription_RootCurrency_History_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootCurrency_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Currency_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};

export type Subscription_RootErc721_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Erc721_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Erc721_Addresses_Order_By>>;
  where?: InputMaybe<Erc721_Addresses_Bool_Exp>;
};

export type Subscription_RootErc721_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Erc721_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Erc721_Addresses_Order_By>>;
  where?: InputMaybe<Erc721_Addresses_Bool_Exp>;
};

export type Subscription_RootErc721_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootErc721_Addresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Erc721_Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Erc721_Addresses_Bool_Exp>;
};

export type Subscription_RootEvenbetAffiliatePlayerBalancesArgs = {
  distinct_on?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Order_By>>;
  where?: InputMaybe<EvenbetAffiliatePlayerBalances_Bool_Exp>;
};

export type Subscription_RootEvenbetAffiliatePlayerBalancesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EvenbetAffiliatePlayerBalances_Order_By>>;
  where?: InputMaybe<EvenbetAffiliatePlayerBalances_Bool_Exp>;
};

export type Subscription_RootEvenbetAffiliatePlayerBalancesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EvenbetAffiliatePlayerBalances_Stream_Cursor_Input>>;
  where?: InputMaybe<EvenbetAffiliatePlayerBalances_Bool_Exp>;
};

export type Subscription_RootEvenbet_Ticket_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Evenbet_Ticket_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Evenbet_Ticket_Requests_Order_By>>;
  where?: InputMaybe<Evenbet_Ticket_Requests_Bool_Exp>;
};

export type Subscription_RootEvenbet_Ticket_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evenbet_Ticket_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Evenbet_Ticket_Requests_Order_By>>;
  where?: InputMaybe<Evenbet_Ticket_Requests_Bool_Exp>;
};

export type Subscription_RootEvenbet_Ticket_Requests_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEvenbet_Ticket_Requests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Evenbet_Ticket_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Evenbet_Ticket_Requests_Bool_Exp>;
};

export type Subscription_RootEvenbet_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Evenbet_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Evenbet_Tickets_Order_By>>;
  where?: InputMaybe<Evenbet_Tickets_Bool_Exp>;
};

export type Subscription_RootEvenbet_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evenbet_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Evenbet_Tickets_Order_By>>;
  where?: InputMaybe<Evenbet_Tickets_Bool_Exp>;
};

export type Subscription_RootEvenbet_Tickets_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEvenbet_Tickets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Evenbet_Tickets_Stream_Cursor_Input>>;
  where?: InputMaybe<Evenbet_Tickets_Bool_Exp>;
};

export type Subscription_RootGpanelPaymentArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGpanelPaymentsArgs = {
  distinct_on?: InputMaybe<Array<GpanelPayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelPayments_Order_By>>;
  where?: InputMaybe<GpanelPayments_Bool_Exp>;
};

export type Subscription_RootGpanelPaymentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GpanelPayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelPayments_Order_By>>;
  where?: InputMaybe<GpanelPayments_Bool_Exp>;
};

export type Subscription_RootGpanelPayments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GpanelPayments_Stream_Cursor_Input>>;
  where?: InputMaybe<GpanelPayments_Bool_Exp>;
};

export type Subscription_RootGpanelUserArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGpanelUsersArgs = {
  distinct_on?: InputMaybe<Array<GpanelUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelUsers_Order_By>>;
  where?: InputMaybe<GpanelUsers_Bool_Exp>;
};

export type Subscription_RootGpanelUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<GpanelUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelUsers_Order_By>>;
  where?: InputMaybe<GpanelUsers_Bool_Exp>;
};

export type Subscription_RootGpanelUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GpanelUsers_Stream_Cursor_Input>>;
  where?: InputMaybe<GpanelUsers_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_AgentArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Agent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Agent_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Agent_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_Agent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Agent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Agent_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Agent_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_Agent_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGpanel_Users_Agent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gpanel_Users_Agent_Stream_Cursor_Input>>;
  where?: InputMaybe<Gpanel_Users_Agent_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_Deposit_AddressArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Deposit_Address_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_Deposit_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Deposit_Address_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Deposit_Address_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_Deposit_Address_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootGpanel_Users_Deposit_Address_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gpanel_Users_Deposit_Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Gpanel_Users_Deposit_Address_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_KycArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Kyc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Kyc_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Kyc_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_Kyc_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gpanel_Users_Kyc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gpanel_Users_Kyc_Order_By>>;
  where?: InputMaybe<Gpanel_Users_Kyc_Bool_Exp>;
};

export type Subscription_RootGpanel_Users_Kyc_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGpanel_Users_Kyc_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gpanel_Users_Kyc_Stream_Cursor_Input>>;
  where?: InputMaybe<Gpanel_Users_Kyc_Bool_Exp>;
};

export type Subscription_RootKycArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootKycsArgs = {
  distinct_on?: InputMaybe<Array<Kycs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kycs_Order_By>>;
  where?: InputMaybe<Kycs_Bool_Exp>;
};

export type Subscription_RootKycs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kycs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kycs_Order_By>>;
  where?: InputMaybe<Kycs_Bool_Exp>;
};

export type Subscription_RootKycs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kycs_Stream_Cursor_Input>>;
  where?: InputMaybe<Kycs_Bool_Exp>;
};

export type Subscription_RootLeaderboardArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leaderboard_Order_By>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};

export type Subscription_RootLeaderboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leaderboard_Order_By>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};

export type Subscription_RootLeaderboard_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootLeaderboard_RankingArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leaderboard_Ranking_Order_By>>;
  where?: InputMaybe<Leaderboard_Ranking_Bool_Exp>;
};

export type Subscription_RootLeaderboard_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leaderboard_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leaderboard_Ranking_Order_By>>;
  where?: InputMaybe<Leaderboard_Ranking_Bool_Exp>;
};

export type Subscription_RootLeaderboard_Ranking_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootLeaderboard_Ranking_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leaderboard_Ranking_Stream_Cursor_Input>>;
  where?: InputMaybe<Leaderboard_Ranking_Bool_Exp>;
};

export type Subscription_RootLeaderboard_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leaderboard_Stream_Cursor_Input>>;
  where?: InputMaybe<Leaderboard_Bool_Exp>;
};

export type Subscription_RootLiquidity_PoolsArgs = {
  distinct_on?: InputMaybe<Array<Liquidity_Pools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Liquidity_Pools_Order_By>>;
  where?: InputMaybe<Liquidity_Pools_Bool_Exp>;
};

export type Subscription_RootLiquidity_Pools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Liquidity_Pools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Liquidity_Pools_Order_By>>;
  where?: InputMaybe<Liquidity_Pools_Bool_Exp>;
};

export type Subscription_RootLiquidity_Pools_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootLiquidity_Pools_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Liquidity_Pools_Stream_Cursor_Input>>;
  where?: InputMaybe<Liquidity_Pools_Bool_Exp>;
};

export type Subscription_RootMarketListingArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootMarketListingsArgs = {
  distinct_on?: InputMaybe<Array<MarketListings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketListings_Order_By>>;
  where?: InputMaybe<MarketListings_Bool_Exp>;
};

export type Subscription_RootMarketListingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketListings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketListings_Order_By>>;
  where?: InputMaybe<MarketListings_Bool_Exp>;
};

export type Subscription_RootMarketListings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MarketListings_Stream_Cursor_Input>>;
  where?: InputMaybe<MarketListings_Bool_Exp>;
};

export type Subscription_RootMarketplaceContractArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootMarketplaceContractsArgs = {
  distinct_on?: InputMaybe<Array<MarketplaceContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketplaceContracts_Order_By>>;
  where?: InputMaybe<MarketplaceContracts_Bool_Exp>;
};

export type Subscription_RootMarketplaceContractsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketplaceContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketplaceContracts_Order_By>>;
  where?: InputMaybe<MarketplaceContracts_Bool_Exp>;
};

export type Subscription_RootMarketplaceContracts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MarketplaceContracts_Stream_Cursor_Input>>;
  where?: InputMaybe<MarketplaceContracts_Bool_Exp>;
};

export type Subscription_RootNftArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootNftContractArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootNftContractsArgs = {
  distinct_on?: InputMaybe<Array<NftContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftContracts_Order_By>>;
  where?: InputMaybe<NftContracts_Bool_Exp>;
};

export type Subscription_RootNftContractsAggregateArgs = {
  distinct_on?: InputMaybe<Array<NftContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftContracts_Order_By>>;
  where?: InputMaybe<NftContracts_Bool_Exp>;
};

export type Subscription_RootNftContracts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<NftContracts_Stream_Cursor_Input>>;
  where?: InputMaybe<NftContracts_Bool_Exp>;
};

export type Subscription_RootNftMetadataArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootNftMetadatasArgs = {
  distinct_on?: InputMaybe<Array<NftMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftMetadatas_Order_By>>;
  where?: InputMaybe<NftMetadatas_Bool_Exp>;
};

export type Subscription_RootNftMetadatasAggregateArgs = {
  distinct_on?: InputMaybe<Array<NftMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftMetadatas_Order_By>>;
  where?: InputMaybe<NftMetadatas_Bool_Exp>;
};

export type Subscription_RootNftMetadatas_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<NftMetadatas_Stream_Cursor_Input>>;
  where?: InputMaybe<NftMetadatas_Bool_Exp>;
};

export type Subscription_RootNftOwnerArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootNftOwnersArgs = {
  distinct_on?: InputMaybe<Array<NftOwners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftOwners_Order_By>>;
  where?: InputMaybe<NftOwners_Bool_Exp>;
};

export type Subscription_RootNftOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<NftOwners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftOwners_Order_By>>;
  where?: InputMaybe<NftOwners_Bool_Exp>;
};

export type Subscription_RootNftOwners_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<NftOwners_Stream_Cursor_Input>>;
  where?: InputMaybe<NftOwners_Bool_Exp>;
};

export type Subscription_RootNft_Gpanel_UsersArgs = {
  distinct_on?: InputMaybe<Array<Nft_Gpanel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Gpanel_Users_Order_By>>;
  where?: InputMaybe<Nft_Gpanel_Users_Bool_Exp>;
};

export type Subscription_RootNft_Gpanel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Gpanel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Gpanel_Users_Order_By>>;
  where?: InputMaybe<Nft_Gpanel_Users_Bool_Exp>;
};

export type Subscription_RootNft_Gpanel_Users_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootNft_Gpanel_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nft_Gpanel_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Nft_Gpanel_Users_Bool_Exp>;
};

export type Subscription_RootNft_ListingsArgs = {
  distinct_on?: InputMaybe<Array<Nft_Listings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Listings_Order_By>>;
  where?: InputMaybe<Nft_Listings_Bool_Exp>;
};

export type Subscription_RootNft_Listings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Listings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Listings_Order_By>>;
  where?: InputMaybe<Nft_Listings_Bool_Exp>;
};

export type Subscription_RootNft_Listings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootNft_Listings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nft_Listings_Stream_Cursor_Input>>;
  where?: InputMaybe<Nft_Listings_Bool_Exp>;
};

export type Subscription_RootNft_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Nft_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Transactions_Order_By>>;
  where?: InputMaybe<Nft_Transactions_Bool_Exp>;
};

export type Subscription_RootNft_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Transactions_Order_By>>;
  where?: InputMaybe<Nft_Transactions_Bool_Exp>;
};

export type Subscription_RootNft_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootNft_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nft_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Nft_Transactions_Bool_Exp>;
};

export type Subscription_RootNftsArgs = {
  distinct_on?: InputMaybe<Array<Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nfts_Order_By>>;
  where?: InputMaybe<Nfts_Bool_Exp>;
};

export type Subscription_RootNftsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Nfts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nfts_Order_By>>;
  where?: InputMaybe<Nfts_Bool_Exp>;
};

export type Subscription_RootNfts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nfts_Stream_Cursor_Input>>;
  where?: InputMaybe<Nfts_Bool_Exp>;
};

export type Subscription_RootPendingTransactionArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPendingTransactionsArgs = {
  distinct_on?: InputMaybe<Array<PendingTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PendingTransactions_Order_By>>;
  where?: InputMaybe<PendingTransactions_Bool_Exp>;
};

export type Subscription_RootPendingTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PendingTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PendingTransactions_Order_By>>;
  where?: InputMaybe<PendingTransactions_Bool_Exp>;
};

export type Subscription_RootPendingTransactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PendingTransactions_Stream_Cursor_Input>>;
  where?: InputMaybe<PendingTransactions_Bool_Exp>;
};

export type Subscription_RootPending_TxArgs = {
  distinct_on?: InputMaybe<Array<Pending_Tx_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pending_Tx_Order_By>>;
  where?: InputMaybe<Pending_Tx_Bool_Exp>;
};

export type Subscription_RootPending_Tx_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pending_Tx_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pending_Tx_Order_By>>;
  where?: InputMaybe<Pending_Tx_Bool_Exp>;
};

export type Subscription_RootPending_Tx_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootPending_Tx_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pending_Tx_Stream_Cursor_Input>>;
  where?: InputMaybe<Pending_Tx_Bool_Exp>;
};

export type Subscription_RootRakebackLocksArgs = {
  distinct_on?: InputMaybe<Array<RakebackLocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RakebackLocks_Order_By>>;
  where?: InputMaybe<RakebackLocks_Bool_Exp>;
};

export type Subscription_RootRakebackLocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RakebackLocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RakebackLocks_Order_By>>;
  where?: InputMaybe<RakebackLocks_Bool_Exp>;
};

export type Subscription_RootRakebackLocks_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootRakebackLocks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RakebackLocks_Stream_Cursor_Input>>;
  where?: InputMaybe<RakebackLocks_Bool_Exp>;
};

export type Subscription_RootStablecoinArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootStablecoinsArgs = {
  distinct_on?: InputMaybe<Array<Stablecoins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stablecoins_Order_By>>;
  where?: InputMaybe<Stablecoins_Bool_Exp>;
};

export type Subscription_RootStablecoinsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stablecoins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stablecoins_Order_By>>;
  where?: InputMaybe<Stablecoins_Bool_Exp>;
};

export type Subscription_RootStablecoins_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stablecoins_Stream_Cursor_Input>>;
  where?: InputMaybe<Stablecoins_Bool_Exp>;
};

export type Subscription_RootStaking_RefillArgs = {
  distinct_on?: InputMaybe<Array<Staking_Refill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Refill_Order_By>>;
  where?: InputMaybe<Staking_Refill_Bool_Exp>;
};

export type Subscription_RootStaking_Refill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staking_Refill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staking_Refill_Order_By>>;
  where?: InputMaybe<Staking_Refill_Bool_Exp>;
};

export type Subscription_RootStaking_Refill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootStaking_Refill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staking_Refill_Stream_Cursor_Input>>;
  where?: InputMaybe<Staking_Refill_Bool_Exp>;
};

export type Subscription_RootSwapTransactionsArgs = {
  distinct_on?: InputMaybe<Array<SwapTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SwapTransactions_Order_By>>;
  where?: InputMaybe<SwapTransactions_Bool_Exp>;
};

export type Subscription_RootSwapTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SwapTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SwapTransactions_Order_By>>;
  where?: InputMaybe<SwapTransactions_Bool_Exp>;
};

export type Subscription_RootSwapTransactionsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SwapTransactions_Stream_Cursor_Input>>;
  where?: InputMaybe<SwapTransactions_Bool_Exp>;
};

export type Subscription_RootTournamentsArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournaments_Order_By>>;
  where?: InputMaybe<Tournaments_Bool_Exp>;
};

export type Subscription_RootTournaments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournaments_Order_By>>;
  where?: InputMaybe<Tournaments_Bool_Exp>;
};

export type Subscription_RootTournaments_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootTournaments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tournaments_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Bool_Exp>;
};

export type Subscription_RootTransactionStatisticArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTransactionStatisticsArgs = {
  distinct_on?: InputMaybe<Array<TransactionStatistics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransactionStatistics_Order_By>>;
  where?: InputMaybe<TransactionStatistics_Bool_Exp>;
};

export type Subscription_RootTransactionStatisticsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TransactionStatistics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransactionStatistics_Order_By>>;
  where?: InputMaybe<TransactionStatistics_Bool_Exp>;
};

export type Subscription_RootTransactionStatistics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TransactionStatistics_Stream_Cursor_Input>>;
  where?: InputMaybe<TransactionStatistics_Bool_Exp>;
};

export type Subscription_RootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

export type Subscription_RootTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

export type Subscription_RootTransactions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTransactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

export type Subscription_RootUpdateAvailableAvatarNfTsArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUserArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUserErc721Args = {
  id: Scalars['bigint'];
};

export type Subscription_RootUserErc721_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserErc721_Stream_Cursor_Input>>;
  where?: InputMaybe<UserErc721_Bool_Exp>;
};

export type Subscription_RootUserErc721sArgs = {
  distinct_on?: InputMaybe<Array<UserErc721_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserErc721_Order_By>>;
  where?: InputMaybe<UserErc721_Bool_Exp>;
};

export type Subscription_RootUserErc721sAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserErc721_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserErc721_Order_By>>;
  where?: InputMaybe<UserErc721_Bool_Exp>;
};

export type Subscription_RootUserJobArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUserJobsArgs = {
  distinct_on?: InputMaybe<Array<UserJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobs_Order_By>>;
  where?: InputMaybe<UserJobs_Bool_Exp>;
};

export type Subscription_RootUserJobsAggregageArgs = {
  distinct_on?: InputMaybe<Array<UserJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserJobs_Order_By>>;
  where?: InputMaybe<UserJobs_Bool_Exp>;
};

export type Subscription_RootUserJobsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserJobs_Stream_Cursor_Input>>;
  where?: InputMaybe<UserJobs_Bool_Exp>;
};

export type Subscription_RootUser_Erc721_BlockArgs = {
  distinct_on?: InputMaybe<Array<User_Erc721_Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Erc721_Block_Order_By>>;
  where?: InputMaybe<User_Erc721_Block_Bool_Exp>;
};

export type Subscription_RootUser_Erc721_Block_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Erc721_Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Erc721_Block_Order_By>>;
  where?: InputMaybe<User_Erc721_Block_Bool_Exp>;
};

export type Subscription_RootUser_Erc721_Block_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Erc721_Block_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Erc721_Block_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Erc721_Block_Bool_Exp>;
};

export type Subscription_RootUser_GpanelArgs = {
  distinct_on?: InputMaybe<Array<User_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Gpanel_Order_By>>;
  where?: InputMaybe<User_Gpanel_Bool_Exp>;
};

export type Subscription_RootUser_Gpanel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Gpanel_Order_By>>;
  where?: InputMaybe<User_Gpanel_Bool_Exp>;
};

export type Subscription_RootUser_Gpanel_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Gpanel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Gpanel_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Gpanel_Bool_Exp>;
};

export type Subscription_RootUser_LockArgs = {
  distinct_on?: InputMaybe<Array<User_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lock_Order_By>>;
  where?: InputMaybe<User_Lock_Bool_Exp>;
};

export type Subscription_RootUser_Lock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lock_Order_By>>;
  where?: InputMaybe<User_Lock_Bool_Exp>;
};

export type Subscription_RootUser_Lock_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootUser_Lock_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Lock_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Lock_Bool_Exp>;
};

export type Subscription_RootUser_StakingArgs = {
  distinct_on?: InputMaybe<Array<User_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Staking_Order_By>>;
  where?: InputMaybe<User_Staking_Bool_Exp>;
};

export type Subscription_RootUser_Staking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Staking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Staking_Order_By>>;
  where?: InputMaybe<User_Staking_Bool_Exp>;
};

export type Subscription_RootUser_Staking_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Staking_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Staking_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Staking_Bool_Exp>;
};

export type Subscription_RootUser_Staking_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Staking_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Staking_Transactions_Order_By>>;
  where?: InputMaybe<User_Staking_Transactions_Bool_Exp>;
};

export type Subscription_RootUser_Staking_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Staking_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Staking_Transactions_Order_By>>;
  where?: InputMaybe<User_Staking_Transactions_Bool_Exp>;
};

export type Subscription_RootUser_Staking_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Staking_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Staking_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Staking_Transactions_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_LoginArgs = {
  distinct_on?: InputMaybe<Array<Users_Login_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Login_Order_By>>;
  where?: InputMaybe<Users_Login_Bool_Exp>;
};

export type Subscription_RootUsers_Login_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Login_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Login_Order_By>>;
  where?: InputMaybe<Users_Login_Bool_Exp>;
};

export type Subscription_RootUsers_Login_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Login_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Login_Bool_Exp>;
};

export type Subscription_RootUsers_OldArgs = {
  distinct_on?: InputMaybe<Array<Users_Old_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Old_Order_By>>;
  where?: InputMaybe<Users_Old_Bool_Exp>;
};

export type Subscription_RootUsers_Old_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Old_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Old_Order_By>>;
  where?: InputMaybe<Users_Old_Bool_Exp>;
};

export type Subscription_RootUsers_Old_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootUsers_Old_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Old_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Old_Bool_Exp>;
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type SumsubOutput = {
  __typename?: 'sumsubOutput';
  accessToken?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** columns and relationships of "swap_transactions" */
export type SwapTransactions = {
  __typename?: 'swapTransactions';
  amountFrom: Scalars['numeric'];
  amountTo?: Maybe<Scalars['numeric']>;
  amountToMinimum: Scalars['numeric'];
  blockchainId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  currencyFrom: Scalars['String'];
  currencyTo: Scalars['String'];
  depositTransaction?: Maybe<Scalars['uuid']>;
  fee?: Maybe<Scalars['numeric']>;
  gpanelUserId: Scalars['String'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  statusInternal: Scalars['String'];
  txid?: Maybe<Scalars['String']>;
  txlog?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  withdrawTransaction?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "swap_transactions" */
export type SwapTransactions_Aggregate = {
  __typename?: 'swapTransactions_aggregate';
  aggregate?: Maybe<SwapTransactions_Aggregate_Fields>;
  nodes: Array<SwapTransactions>;
};

/** aggregate fields of "swap_transactions" */
export type SwapTransactions_Aggregate_Fields = {
  __typename?: 'swapTransactions_aggregate_fields';
  avg?: Maybe<SwapTransactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SwapTransactions_Max_Fields>;
  min?: Maybe<SwapTransactions_Min_Fields>;
  stddev?: Maybe<SwapTransactions_Stddev_Fields>;
  stddev_pop?: Maybe<SwapTransactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SwapTransactions_Stddev_Samp_Fields>;
  sum?: Maybe<SwapTransactions_Sum_Fields>;
  var_pop?: Maybe<SwapTransactions_Var_Pop_Fields>;
  var_samp?: Maybe<SwapTransactions_Var_Samp_Fields>;
  variance?: Maybe<SwapTransactions_Variance_Fields>;
};

/** aggregate fields of "swap_transactions" */
export type SwapTransactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SwapTransactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SwapTransactions_Avg_Fields = {
  __typename?: 'swapTransactions_avg_fields';
  amountFrom?: Maybe<Scalars['Float']>;
  amountTo?: Maybe<Scalars['Float']>;
  amountToMinimum?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "swap_transactions". All fields are combined with a logical 'AND'. */
export type SwapTransactions_Bool_Exp = {
  _and?: InputMaybe<Array<SwapTransactions_Bool_Exp>>;
  _not?: InputMaybe<SwapTransactions_Bool_Exp>;
  _or?: InputMaybe<Array<SwapTransactions_Bool_Exp>>;
  amountFrom?: InputMaybe<Numeric_Comparison_Exp>;
  amountTo?: InputMaybe<Numeric_Comparison_Exp>;
  amountToMinimum?: InputMaybe<Numeric_Comparison_Exp>;
  blockchainId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currencyFrom?: InputMaybe<String_Comparison_Exp>;
  currencyTo?: InputMaybe<String_Comparison_Exp>;
  depositTransaction?: InputMaybe<Uuid_Comparison_Exp>;
  fee?: InputMaybe<Numeric_Comparison_Exp>;
  gpanelUserId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  statusInternal?: InputMaybe<String_Comparison_Exp>;
  txid?: InputMaybe<String_Comparison_Exp>;
  txlog?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  withdrawTransaction?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "swap_transactions" */
export type SwapTransactions_Inc_Input = {
  amountFrom?: InputMaybe<Scalars['numeric']>;
  amountTo?: InputMaybe<Scalars['numeric']>;
  amountToMinimum?: InputMaybe<Scalars['numeric']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "swap_transactions" */
export type SwapTransactions_Insert_Input = {
  amountFrom?: InputMaybe<Scalars['numeric']>;
  amountTo?: InputMaybe<Scalars['numeric']>;
  amountToMinimum?: InputMaybe<Scalars['numeric']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyFrom?: InputMaybe<Scalars['String']>;
  currencyTo?: InputMaybe<Scalars['String']>;
  depositTransaction?: InputMaybe<Scalars['uuid']>;
  fee?: InputMaybe<Scalars['numeric']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  statusInternal?: InputMaybe<Scalars['String']>;
  txid?: InputMaybe<Scalars['String']>;
  txlog?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  withdrawTransaction?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SwapTransactions_Max_Fields = {
  __typename?: 'swapTransactions_max_fields';
  amountFrom?: Maybe<Scalars['numeric']>;
  amountTo?: Maybe<Scalars['numeric']>;
  amountToMinimum?: Maybe<Scalars['numeric']>;
  blockchainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyFrom?: Maybe<Scalars['String']>;
  currencyTo?: Maybe<Scalars['String']>;
  depositTransaction?: Maybe<Scalars['uuid']>;
  fee?: Maybe<Scalars['numeric']>;
  gpanelUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
  txlog?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  withdrawTransaction?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SwapTransactions_Min_Fields = {
  __typename?: 'swapTransactions_min_fields';
  amountFrom?: Maybe<Scalars['numeric']>;
  amountTo?: Maybe<Scalars['numeric']>;
  amountToMinimum?: Maybe<Scalars['numeric']>;
  blockchainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyFrom?: Maybe<Scalars['String']>;
  currencyTo?: Maybe<Scalars['String']>;
  depositTransaction?: Maybe<Scalars['uuid']>;
  fee?: Maybe<Scalars['numeric']>;
  gpanelUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
  txlog?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  withdrawTransaction?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "swap_transactions" */
export type SwapTransactions_Mutation_Response = {
  __typename?: 'swapTransactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SwapTransactions>;
};

/** Ordering options when selecting data from "swap_transactions". */
export type SwapTransactions_Order_By = {
  amountFrom?: InputMaybe<Order_By>;
  amountTo?: InputMaybe<Order_By>;
  amountToMinimum?: InputMaybe<Order_By>;
  blockchainId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currencyFrom?: InputMaybe<Order_By>;
  currencyTo?: InputMaybe<Order_By>;
  depositTransaction?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  statusInternal?: InputMaybe<Order_By>;
  txid?: InputMaybe<Order_By>;
  txlog?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  withdrawTransaction?: InputMaybe<Order_By>;
};

/** select columns of table "swap_transactions" */
export enum SwapTransactions_Select_Column {
  /** column name */
  AmountFrom = 'amountFrom',
  /** column name */
  AmountTo = 'amountTo',
  /** column name */
  AmountToMinimum = 'amountToMinimum',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyFrom = 'currencyFrom',
  /** column name */
  CurrencyTo = 'currencyTo',
  /** column name */
  DepositTransaction = 'depositTransaction',
  /** column name */
  Fee = 'fee',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusInternal = 'statusInternal',
  /** column name */
  Txid = 'txid',
  /** column name */
  Txlog = 'txlog',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WithdrawTransaction = 'withdrawTransaction',
}

/** input type for updating data in table "swap_transactions" */
export type SwapTransactions_Set_Input = {
  amountFrom?: InputMaybe<Scalars['numeric']>;
  amountTo?: InputMaybe<Scalars['numeric']>;
  amountToMinimum?: InputMaybe<Scalars['numeric']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyFrom?: InputMaybe<Scalars['String']>;
  currencyTo?: InputMaybe<Scalars['String']>;
  depositTransaction?: InputMaybe<Scalars['uuid']>;
  fee?: InputMaybe<Scalars['numeric']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  statusInternal?: InputMaybe<Scalars['String']>;
  txid?: InputMaybe<Scalars['String']>;
  txlog?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  withdrawTransaction?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type SwapTransactions_Stddev_Fields = {
  __typename?: 'swapTransactions_stddev_fields';
  amountFrom?: Maybe<Scalars['Float']>;
  amountTo?: Maybe<Scalars['Float']>;
  amountToMinimum?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SwapTransactions_Stddev_Pop_Fields = {
  __typename?: 'swapTransactions_stddev_pop_fields';
  amountFrom?: Maybe<Scalars['Float']>;
  amountTo?: Maybe<Scalars['Float']>;
  amountToMinimum?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SwapTransactions_Stddev_Samp_Fields = {
  __typename?: 'swapTransactions_stddev_samp_fields';
  amountFrom?: Maybe<Scalars['Float']>;
  amountTo?: Maybe<Scalars['Float']>;
  amountToMinimum?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "swapTransactions" */
export type SwapTransactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SwapTransactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SwapTransactions_Stream_Cursor_Value_Input = {
  amountFrom?: InputMaybe<Scalars['numeric']>;
  amountTo?: InputMaybe<Scalars['numeric']>;
  amountToMinimum?: InputMaybe<Scalars['numeric']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyFrom?: InputMaybe<Scalars['String']>;
  currencyTo?: InputMaybe<Scalars['String']>;
  depositTransaction?: InputMaybe<Scalars['uuid']>;
  fee?: InputMaybe<Scalars['numeric']>;
  gpanelUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  statusInternal?: InputMaybe<Scalars['String']>;
  txid?: InputMaybe<Scalars['String']>;
  txlog?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  withdrawTransaction?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type SwapTransactions_Sum_Fields = {
  __typename?: 'swapTransactions_sum_fields';
  amountFrom?: Maybe<Scalars['numeric']>;
  amountTo?: Maybe<Scalars['numeric']>;
  amountToMinimum?: Maybe<Scalars['numeric']>;
  blockchainId?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['numeric']>;
};

export type SwapTransactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SwapTransactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SwapTransactions_Set_Input>;
  where: SwapTransactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SwapTransactions_Var_Pop_Fields = {
  __typename?: 'swapTransactions_var_pop_fields';
  amountFrom?: Maybe<Scalars['Float']>;
  amountTo?: Maybe<Scalars['Float']>;
  amountToMinimum?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SwapTransactions_Var_Samp_Fields = {
  __typename?: 'swapTransactions_var_samp_fields';
  amountFrom?: Maybe<Scalars['Float']>;
  amountTo?: Maybe<Scalars['Float']>;
  amountToMinimum?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SwapTransactions_Variance_Fields = {
  __typename?: 'swapTransactions_variance_fields';
  amountFrom?: Maybe<Scalars['Float']>;
  amountTo?: Maybe<Scalars['Float']>;
  amountToMinimum?: Maybe<Scalars['Float']>;
  blockchainId?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type TournamentInput = {
  additionalPassword?: InputMaybe<Scalars['String']>;
  additionalReEntry: Scalars['Boolean'];
  buyInAmount: Scalars['Float'];
  buyInCurrency: Scalars['String'];
  dateAnnouncement: Scalars['timestamptz'];
  dateStart: Scalars['timestamptz'];
  name: Scalars['String'];
};

/** columns and relationships of "tournaments" */
export type Tournaments = {
  __typename?: 'tournaments';
  amount: Scalars['numeric'];
  announcement: Scalars['timestamptz'];
  created_at: Scalars['timestamptz'];
  currency: Scalars['String'];
  eventbet_tournament_id?: Maybe<Scalars['Int']>;
  gpanel_user_id: Scalars['String'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  password: Scalars['String'];
  reentry: Scalars['Boolean'];
  start: Scalars['timestamptz'];
  status: Scalars['String'];
  total_profit?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "tournaments" */
export type Tournaments_Aggregate = {
  __typename?: 'tournaments_aggregate';
  aggregate?: Maybe<Tournaments_Aggregate_Fields>;
  nodes: Array<Tournaments>;
};

/** aggregate fields of "tournaments" */
export type Tournaments_Aggregate_Fields = {
  __typename?: 'tournaments_aggregate_fields';
  avg?: Maybe<Tournaments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tournaments_Max_Fields>;
  min?: Maybe<Tournaments_Min_Fields>;
  stddev?: Maybe<Tournaments_Stddev_Fields>;
  stddev_pop?: Maybe<Tournaments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tournaments_Stddev_Samp_Fields>;
  sum?: Maybe<Tournaments_Sum_Fields>;
  var_pop?: Maybe<Tournaments_Var_Pop_Fields>;
  var_samp?: Maybe<Tournaments_Var_Samp_Fields>;
  variance?: Maybe<Tournaments_Variance_Fields>;
};

/** aggregate fields of "tournaments" */
export type Tournaments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tournaments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tournaments_Avg_Fields = {
  __typename?: 'tournaments_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  eventbet_tournament_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_profit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tournaments". All fields are combined with a logical 'AND'. */
export type Tournaments_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Bool_Exp>>;
  _not?: InputMaybe<Tournaments_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  announcement?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  eventbet_tournament_id?: InputMaybe<Int_Comparison_Exp>;
  gpanel_user_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  reentry?: InputMaybe<Boolean_Comparison_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  total_profit?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments" */
export enum Tournaments_Constraint {
  /** unique or primary key constraint on columns "id" */
  TournamentsPkey = 'tournaments_pkey',
}

/** input type for incrementing numeric columns in table "tournaments" */
export type Tournaments_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  eventbet_tournament_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  total_profit?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "tournaments" */
export type Tournaments_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  announcement?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  eventbet_tournament_id?: InputMaybe<Scalars['Int']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  reentry?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  total_profit?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tournaments_Max_Fields = {
  __typename?: 'tournaments_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  announcement?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  eventbet_tournament_id?: Maybe<Scalars['Int']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  total_profit?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tournaments_Min_Fields = {
  __typename?: 'tournaments_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  announcement?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  eventbet_tournament_id?: Maybe<Scalars['Int']>;
  gpanel_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  total_profit?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tournaments" */
export type Tournaments_Mutation_Response = {
  __typename?: 'tournaments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tournaments>;
};

/** on_conflict condition type for table "tournaments" */
export type Tournaments_On_Conflict = {
  constraint: Tournaments_Constraint;
  update_columns?: Array<Tournaments_Update_Column>;
  where?: InputMaybe<Tournaments_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments". */
export type Tournaments_Order_By = {
  amount?: InputMaybe<Order_By>;
  announcement?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  eventbet_tournament_id?: InputMaybe<Order_By>;
  gpanel_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  reentry?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total_profit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments */
export type Tournaments_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "tournaments" */
export enum Tournaments_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Announcement = 'announcement',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  EventbetTournamentId = 'eventbet_tournament_id',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  Reentry = 'reentry',
  /** column name */
  Start = 'start',
  /** column name */
  Status = 'status',
  /** column name */
  TotalProfit = 'total_profit',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "tournaments" */
export type Tournaments_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  announcement?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  eventbet_tournament_id?: InputMaybe<Scalars['Int']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  reentry?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  total_profit?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tournaments_Stddev_Fields = {
  __typename?: 'tournaments_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  eventbet_tournament_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_profit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tournaments_Stddev_Pop_Fields = {
  __typename?: 'tournaments_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  eventbet_tournament_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_profit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tournaments_Stddev_Samp_Fields = {
  __typename?: 'tournaments_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  eventbet_tournament_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_profit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tournaments" */
export type Tournaments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  announcement?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  eventbet_tournament_id?: InputMaybe<Scalars['Int']>;
  gpanel_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  reentry?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  total_profit?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tournaments_Sum_Fields = {
  __typename?: 'tournaments_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  eventbet_tournament_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  total_profit?: Maybe<Scalars['numeric']>;
};

/** update columns of table "tournaments" */
export enum Tournaments_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Announcement = 'announcement',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  EventbetTournamentId = 'eventbet_tournament_id',
  /** column name */
  GpanelUserId = 'gpanel_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  Reentry = 'reentry',
  /** column name */
  Start = 'start',
  /** column name */
  Status = 'status',
  /** column name */
  TotalProfit = 'total_profit',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tournaments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Set_Input>;
  where: Tournaments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tournaments_Var_Pop_Fields = {
  __typename?: 'tournaments_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  eventbet_tournament_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_profit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tournaments_Var_Samp_Fields = {
  __typename?: 'tournaments_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  eventbet_tournament_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_profit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tournaments_Variance_Fields = {
  __typename?: 'tournaments_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  eventbet_tournament_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_profit?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "transactionStatistics" */
export type TransactionStatistics = {
  __typename?: 'transactionStatistics';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  totalCoins?: Maybe<Scalars['String']>;
  totalTransactions?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "transactionStatistics" */
export type TransactionStatistics_Aggregate = {
  __typename?: 'transactionStatistics_aggregate';
  aggregate?: Maybe<TransactionStatistics_Aggregate_Fields>;
  nodes: Array<TransactionStatistics>;
};

/** aggregate fields of "transactionStatistics" */
export type TransactionStatistics_Aggregate_Fields = {
  __typename?: 'transactionStatistics_aggregate_fields';
  avg?: Maybe<TransactionStatistics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TransactionStatistics_Max_Fields>;
  min?: Maybe<TransactionStatistics_Min_Fields>;
  stddev?: Maybe<TransactionStatistics_Stddev_Fields>;
  stddev_pop?: Maybe<TransactionStatistics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TransactionStatistics_Stddev_Samp_Fields>;
  sum?: Maybe<TransactionStatistics_Sum_Fields>;
  var_pop?: Maybe<TransactionStatistics_Var_Pop_Fields>;
  var_samp?: Maybe<TransactionStatistics_Var_Samp_Fields>;
  variance?: Maybe<TransactionStatistics_Variance_Fields>;
};

/** aggregate fields of "transactionStatistics" */
export type TransactionStatistics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TransactionStatistics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TransactionStatistics_Avg_Fields = {
  __typename?: 'transactionStatistics_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "transactionStatistics". All fields are combined with a logical 'AND'. */
export type TransactionStatistics_Bool_Exp = {
  _and?: InputMaybe<Array<TransactionStatistics_Bool_Exp>>;
  _not?: InputMaybe<TransactionStatistics_Bool_Exp>;
  _or?: InputMaybe<Array<TransactionStatistics_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  totalCoins?: InputMaybe<String_Comparison_Exp>;
  totalTransactions?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transactionStatistics" */
export enum TransactionStatistics_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionStatisticsPkey = 'transactionStatistics_pkey',
}

/** input type for incrementing numeric columns in table "transactionStatistics" */
export type TransactionStatistics_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transactionStatistics" */
export type TransactionStatistics_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  totalCoins?: InputMaybe<Scalars['String']>;
  totalTransactions?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TransactionStatistics_Max_Fields = {
  __typename?: 'transactionStatistics_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  totalCoins?: Maybe<Scalars['String']>;
  totalTransactions?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type TransactionStatistics_Min_Fields = {
  __typename?: 'transactionStatistics_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  totalCoins?: Maybe<Scalars['String']>;
  totalTransactions?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "transactionStatistics" */
export type TransactionStatistics_Mutation_Response = {
  __typename?: 'transactionStatistics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TransactionStatistics>;
};

/** on_conflict condition type for table "transactionStatistics" */
export type TransactionStatistics_On_Conflict = {
  constraint: TransactionStatistics_Constraint;
  update_columns?: Array<TransactionStatistics_Update_Column>;
  where?: InputMaybe<TransactionStatistics_Bool_Exp>;
};

/** Ordering options when selecting data from "transactionStatistics". */
export type TransactionStatistics_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalCoins?: InputMaybe<Order_By>;
  totalTransactions?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transactionStatistics */
export type TransactionStatistics_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transactionStatistics" */
export enum TransactionStatistics_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TotalCoins = 'totalCoins',
  /** column name */
  TotalTransactions = 'totalTransactions',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "transactionStatistics" */
export type TransactionStatistics_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  totalCoins?: InputMaybe<Scalars['String']>;
  totalTransactions?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TransactionStatistics_Stddev_Fields = {
  __typename?: 'transactionStatistics_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TransactionStatistics_Stddev_Pop_Fields = {
  __typename?: 'transactionStatistics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TransactionStatistics_Stddev_Samp_Fields = {
  __typename?: 'transactionStatistics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "transactionStatistics" */
export type TransactionStatistics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TransactionStatistics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TransactionStatistics_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  totalCoins?: InputMaybe<Scalars['String']>;
  totalTransactions?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TransactionStatistics_Sum_Fields = {
  __typename?: 'transactionStatistics_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "transactionStatistics" */
export enum TransactionStatistics_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TotalCoins = 'totalCoins',
  /** column name */
  TotalTransactions = 'totalTransactions',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type TransactionStatistics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TransactionStatistics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransactionStatistics_Set_Input>;
  where: TransactionStatistics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TransactionStatistics_Var_Pop_Fields = {
  __typename?: 'transactionStatistics_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TransactionStatistics_Var_Samp_Fields = {
  __typename?: 'transactionStatistics_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TransactionStatistics_Variance_Fields = {
  __typename?: 'transactionStatistics_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "transactions" */
export type Transactions = {
  __typename?: 'transactions';
  blockchain_id?: Maybe<Scalars['smallint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  payload?: Maybe<Scalars['json']>;
  payloadBinary?: Maybe<Scalars['jsonb']>;
  price?: Maybe<Scalars['bigint']>;
  txId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "transactions" */
export type TransactionsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "transactions" */
export type TransactionsPayloadBinaryArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  __typename?: 'transactions_aggregate';
  aggregate?: Maybe<Transactions_Aggregate_Fields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  __typename?: 'transactions_aggregate_fields';
  avg?: Maybe<Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transactions_Max_Fields>;
  min?: Maybe<Transactions_Min_Fields>;
  stddev?: Maybe<Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Transactions_Sum_Fields>;
  var_pop?: Maybe<Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Transactions_Var_Samp_Fields>;
  variance?: Maybe<Transactions_Variance_Fields>;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Append_Input = {
  payloadBinary?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Transactions_Avg_Fields = {
  __typename?: 'transactions_avg_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Transactions_Bool_Exp>>;
  _not?: InputMaybe<Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Transactions_Bool_Exp>>;
  blockchain_id?: InputMaybe<Smallint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  payload?: InputMaybe<Json_Comparison_Exp>;
  payloadBinary?: InputMaybe<Jsonb_Comparison_Exp>;
  price?: InputMaybe<Bigint_Comparison_Exp>;
  txId?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsPkey = 'transactions_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transactions_Delete_At_Path_Input = {
  payloadBinary?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transactions_Delete_Elem_Input = {
  payloadBinary?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transactions_Delete_Key_Input = {
  payloadBinary?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "transactions" */
export type Transactions_Inc_Input = {
  blockchain_id?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "transactions" */
export type Transactions_Insert_Input = {
  blockchain_id?: InputMaybe<Scalars['smallint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['json']>;
  payloadBinary?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['bigint']>;
  txId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  __typename?: 'transactions_max_fields';
  blockchain_id?: Maybe<Scalars['smallint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  txId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  __typename?: 'transactions_min_fields';
  blockchain_id?: Maybe<Scalars['smallint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  txId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "transactions" */
export type Transactions_Mutation_Response = {
  __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
};

/** on_conflict condition type for table "transactions" */
export type Transactions_On_Conflict = {
  constraint: Transactions_Constraint;
  update_columns?: Array<Transactions_Update_Column>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "transactions". */
export type Transactions_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  payloadBinary?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  txId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transactions */
export type Transactions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Prepend_Input = {
  payloadBinary?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  PayloadBinary = 'payloadBinary',
  /** column name */
  Price = 'price',
  /** column name */
  TxId = 'txId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "transactions" */
export type Transactions_Set_Input = {
  blockchain_id?: InputMaybe<Scalars['smallint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['json']>;
  payloadBinary?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['bigint']>;
  txId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Transactions_Stddev_Fields = {
  __typename?: 'transactions_stddev_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Transactions_Stddev_Pop_Fields = {
  __typename?: 'transactions_stddev_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Transactions_Stddev_Samp_Fields = {
  __typename?: 'transactions_stddev_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "transactions" */
export type Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transactions_Stream_Cursor_Value_Input = {
  blockchain_id?: InputMaybe<Scalars['smallint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['json']>;
  payloadBinary?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['bigint']>;
  txId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Transactions_Sum_Fields = {
  __typename?: 'transactions_sum_fields';
  blockchain_id?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
};

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  PayloadBinary = 'payloadBinary',
  /** column name */
  Price = 'price',
  /** column name */
  TxId = 'txId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transactions_Var_Pop_Fields = {
  __typename?: 'transactions_var_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Transactions_Var_Samp_Fields = {
  __typename?: 'transactions_var_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Transactions_Variance_Fields = {
  __typename?: 'transactions_variance_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** fields of action: "updateAvailableAvatarNFTs" */
export type UpdateAvailableAvatarNfTs = {
  __typename?: 'updateAvailableAvatarNFTs';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']>;
  /** the unique id of an action */
  id: Scalars['uuid'];
  /** the output fields of this action */
  output?: Maybe<GeneralMessageOutput>;
};

/** columns and relationships of "user_erc721" */
export type UserErc721 = {
  __typename?: 'userErc721';
  animationUrl?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  erc721AddressId: Scalars['Int'];
  id: Scalars['bigint'];
  imgUrl?: Maybe<Scalars['String']>;
  metaUrl: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
  tokenId: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_erc721" */
export type UserErc721_Aggregate = {
  __typename?: 'userErc721_aggregate';
  aggregate?: Maybe<UserErc721_Aggregate_Fields>;
  nodes: Array<UserErc721>;
};

/** aggregate fields of "user_erc721" */
export type UserErc721_Aggregate_Fields = {
  __typename?: 'userErc721_aggregate_fields';
  avg?: Maybe<UserErc721_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserErc721_Max_Fields>;
  min?: Maybe<UserErc721_Min_Fields>;
  stddev?: Maybe<UserErc721_Stddev_Fields>;
  stddev_pop?: Maybe<UserErc721_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserErc721_Stddev_Samp_Fields>;
  sum?: Maybe<UserErc721_Sum_Fields>;
  var_pop?: Maybe<UserErc721_Var_Pop_Fields>;
  var_samp?: Maybe<UserErc721_Var_Samp_Fields>;
  variance?: Maybe<UserErc721_Variance_Fields>;
};

/** aggregate fields of "user_erc721" */
export type UserErc721_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserErc721_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserErc721_Avg_Fields = {
  __typename?: 'userErc721_avg_fields';
  erc721AddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_erc721". All fields are combined with a logical 'AND'. */
export type UserErc721_Bool_Exp = {
  _and?: InputMaybe<Array<UserErc721_Bool_Exp>>;
  _not?: InputMaybe<UserErc721_Bool_Exp>;
  _or?: InputMaybe<Array<UserErc721_Bool_Exp>>;
  animationUrl?: InputMaybe<String_Comparison_Exp>;
  collection?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  erc721AddressId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  imgUrl?: InputMaybe<String_Comparison_Exp>;
  metaUrl?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tokenId?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_erc721" */
export enum UserErc721_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserErc721Pkey = 'user_erc721_pkey',
}

/** input type for incrementing numeric columns in table "user_erc721" */
export type UserErc721_Inc_Input = {
  erc721AddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  tokenId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_erc721" */
export type UserErc721_Insert_Input = {
  animationUrl?: InputMaybe<Scalars['String']>;
  collection?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  erc721AddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  metaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserErc721_Max_Fields = {
  __typename?: 'userErc721_max_fields';
  animationUrl?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  erc721AddressId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  imgUrl?: Maybe<Scalars['String']>;
  metaUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserErc721_Min_Fields = {
  __typename?: 'userErc721_min_fields';
  animationUrl?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  erc721AddressId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  imgUrl?: Maybe<Scalars['String']>;
  metaUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_erc721" */
export type UserErc721_Mutation_Response = {
  __typename?: 'userErc721_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserErc721>;
};

/** on_conflict condition type for table "user_erc721" */
export type UserErc721_On_Conflict = {
  constraint: UserErc721_Constraint;
  update_columns?: Array<UserErc721_Update_Column>;
  where?: InputMaybe<UserErc721_Bool_Exp>;
};

/** Ordering options when selecting data from "user_erc721". */
export type UserErc721_Order_By = {
  animationUrl?: InputMaybe<Order_By>;
  collection?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  erc721AddressId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imgUrl?: InputMaybe<Order_By>;
  metaUrl?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_erc721 */
export type UserErc721_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_erc721" */
export enum UserErc721_Select_Column {
  /** column name */
  AnimationUrl = 'animationUrl',
  /** column name */
  Collection = 'collection',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Erc721AddressId = 'erc721AddressId',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'imgUrl',
  /** column name */
  MetaUrl = 'metaUrl',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "user_erc721" */
export type UserErc721_Set_Input = {
  animationUrl?: InputMaybe<Scalars['String']>;
  collection?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  erc721AddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  metaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type UserErc721_Stddev_Fields = {
  __typename?: 'userErc721_stddev_fields';
  erc721AddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserErc721_Stddev_Pop_Fields = {
  __typename?: 'userErc721_stddev_pop_fields';
  erc721AddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserErc721_Stddev_Samp_Fields = {
  __typename?: 'userErc721_stddev_samp_fields';
  erc721AddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "userErc721" */
export type UserErc721_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserErc721_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserErc721_Stream_Cursor_Value_Input = {
  animationUrl?: InputMaybe<Scalars['String']>;
  collection?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  erc721AddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  metaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type UserErc721_Sum_Fields = {
  __typename?: 'userErc721_sum_fields';
  erc721AddressId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  tokenId?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_erc721" */
export enum UserErc721_Update_Column {
  /** column name */
  AnimationUrl = 'animationUrl',
  /** column name */
  Collection = 'collection',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Erc721AddressId = 'erc721AddressId',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'imgUrl',
  /** column name */
  MetaUrl = 'metaUrl',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type UserErc721_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserErc721_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserErc721_Set_Input>;
  where: UserErc721_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserErc721_Var_Pop_Fields = {
  __typename?: 'userErc721_var_pop_fields';
  erc721AddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserErc721_Var_Samp_Fields = {
  __typename?: 'userErc721_var_samp_fields';
  erc721AddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserErc721_Variance_Fields = {
  __typename?: 'userErc721_variance_fields';
  erc721AddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_jobs" */
export type UserJobs = {
  __typename?: 'userJobs';
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  percentage: Scalars['Int'];
  status: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_jobs" */
export type UserJobs_Aggregate = {
  __typename?: 'userJobs_aggregate';
  aggregate?: Maybe<UserJobs_Aggregate_Fields>;
  nodes: Array<UserJobs>;
};

/** aggregate fields of "user_jobs" */
export type UserJobs_Aggregate_Fields = {
  __typename?: 'userJobs_aggregate_fields';
  avg?: Maybe<UserJobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserJobs_Max_Fields>;
  min?: Maybe<UserJobs_Min_Fields>;
  stddev?: Maybe<UserJobs_Stddev_Fields>;
  stddev_pop?: Maybe<UserJobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserJobs_Stddev_Samp_Fields>;
  sum?: Maybe<UserJobs_Sum_Fields>;
  var_pop?: Maybe<UserJobs_Var_Pop_Fields>;
  var_samp?: Maybe<UserJobs_Var_Samp_Fields>;
  variance?: Maybe<UserJobs_Variance_Fields>;
};

/** aggregate fields of "user_jobs" */
export type UserJobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserJobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserJobs_Avg_Fields = {
  __typename?: 'userJobs_avg_fields';
  id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_jobs". All fields are combined with a logical 'AND'. */
export type UserJobs_Bool_Exp = {
  _and?: InputMaybe<Array<UserJobs_Bool_Exp>>;
  _not?: InputMaybe<UserJobs_Bool_Exp>;
  _or?: InputMaybe<Array<UserJobs_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  percentage?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_jobs" */
export enum UserJobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserErc721JobPkey = 'user_erc721_job_pkey',
}

/** input type for incrementing numeric columns in table "user_jobs" */
export type UserJobs_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_jobs" */
export type UserJobs_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserJobs_Max_Fields = {
  __typename?: 'userJobs_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserJobs_Min_Fields = {
  __typename?: 'userJobs_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_jobs" */
export type UserJobs_Mutation_Response = {
  __typename?: 'userJobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserJobs>;
};

/** on_conflict condition type for table "user_jobs" */
export type UserJobs_On_Conflict = {
  constraint: UserJobs_Constraint;
  update_columns?: Array<UserJobs_Update_Column>;
  where?: InputMaybe<UserJobs_Bool_Exp>;
};

/** Ordering options when selecting data from "user_jobs". */
export type UserJobs_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percentage?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_jobs */
export type UserJobs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_jobs" */
export enum UserJobs_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Percentage = 'percentage',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "user_jobs" */
export type UserJobs_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type UserJobs_Stddev_Fields = {
  __typename?: 'userJobs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserJobs_Stddev_Pop_Fields = {
  __typename?: 'userJobs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserJobs_Stddev_Samp_Fields = {
  __typename?: 'userJobs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "userJobs" */
export type UserJobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserJobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserJobs_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type UserJobs_Sum_Fields = {
  __typename?: 'userJobs_sum_fields';
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_jobs" */
export enum UserJobs_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Percentage = 'percentage',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type UserJobs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserJobs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserJobs_Set_Input>;
  where: UserJobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserJobs_Var_Pop_Fields = {
  __typename?: 'userJobs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserJobs_Var_Samp_Fields = {
  __typename?: 'userJobs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserJobs_Variance_Fields = {
  __typename?: 'userJobs_variance_fields';
  id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_erc721_block" */
export type User_Erc721_Block = {
  __typename?: 'user_erc721_block';
  blockchain_id: Scalars['Int'];
  blocknr: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_erc721_block" */
export type User_Erc721_Block_Aggregate = {
  __typename?: 'user_erc721_block_aggregate';
  aggregate?: Maybe<User_Erc721_Block_Aggregate_Fields>;
  nodes: Array<User_Erc721_Block>;
};

/** aggregate fields of "user_erc721_block" */
export type User_Erc721_Block_Aggregate_Fields = {
  __typename?: 'user_erc721_block_aggregate_fields';
  avg?: Maybe<User_Erc721_Block_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Erc721_Block_Max_Fields>;
  min?: Maybe<User_Erc721_Block_Min_Fields>;
  stddev?: Maybe<User_Erc721_Block_Stddev_Fields>;
  stddev_pop?: Maybe<User_Erc721_Block_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Erc721_Block_Stddev_Samp_Fields>;
  sum?: Maybe<User_Erc721_Block_Sum_Fields>;
  var_pop?: Maybe<User_Erc721_Block_Var_Pop_Fields>;
  var_samp?: Maybe<User_Erc721_Block_Var_Samp_Fields>;
  variance?: Maybe<User_Erc721_Block_Variance_Fields>;
};

/** aggregate fields of "user_erc721_block" */
export type User_Erc721_Block_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Erc721_Block_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Erc721_Block_Avg_Fields = {
  __typename?: 'user_erc721_block_avg_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blocknr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_erc721_block". All fields are combined with a logical 'AND'. */
export type User_Erc721_Block_Bool_Exp = {
  _and?: InputMaybe<Array<User_Erc721_Block_Bool_Exp>>;
  _not?: InputMaybe<User_Erc721_Block_Bool_Exp>;
  _or?: InputMaybe<Array<User_Erc721_Block_Bool_Exp>>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  blocknr?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_erc721_block" */
export enum User_Erc721_Block_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserErc721BlockPkey = 'user_erc721_block_pkey',
}

/** input type for incrementing numeric columns in table "user_erc721_block" */
export type User_Erc721_Block_Inc_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  blocknr?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_erc721_block" */
export type User_Erc721_Block_Insert_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  blocknr?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Erc721_Block_Max_Fields = {
  __typename?: 'user_erc721_block_max_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  blocknr?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Erc721_Block_Min_Fields = {
  __typename?: 'user_erc721_block_min_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  blocknr?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_erc721_block" */
export type User_Erc721_Block_Mutation_Response = {
  __typename?: 'user_erc721_block_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Erc721_Block>;
};

/** on_conflict condition type for table "user_erc721_block" */
export type User_Erc721_Block_On_Conflict = {
  constraint: User_Erc721_Block_Constraint;
  update_columns?: Array<User_Erc721_Block_Update_Column>;
  where?: InputMaybe<User_Erc721_Block_Bool_Exp>;
};

/** Ordering options when selecting data from "user_erc721_block". */
export type User_Erc721_Block_Order_By = {
  blockchain_id?: InputMaybe<Order_By>;
  blocknr?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_erc721_block */
export type User_Erc721_Block_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_erc721_block" */
export enum User_Erc721_Block_Select_Column {
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  Blocknr = 'blocknr',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_erc721_block" */
export type User_Erc721_Block_Set_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  blocknr?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Erc721_Block_Stddev_Fields = {
  __typename?: 'user_erc721_block_stddev_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blocknr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Erc721_Block_Stddev_Pop_Fields = {
  __typename?: 'user_erc721_block_stddev_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blocknr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Erc721_Block_Stddev_Samp_Fields = {
  __typename?: 'user_erc721_block_stddev_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blocknr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_erc721_block" */
export type User_Erc721_Block_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Erc721_Block_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Erc721_Block_Stream_Cursor_Value_Input = {
  blockchain_id?: InputMaybe<Scalars['Int']>;
  blocknr?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Erc721_Block_Sum_Fields = {
  __typename?: 'user_erc721_block_sum_fields';
  blockchain_id?: Maybe<Scalars['Int']>;
  blocknr?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_erc721_block" */
export enum User_Erc721_Block_Update_Column {
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  Blocknr = 'blocknr',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Erc721_Block_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Erc721_Block_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Erc721_Block_Set_Input>;
  where: User_Erc721_Block_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Erc721_Block_Var_Pop_Fields = {
  __typename?: 'user_erc721_block_var_pop_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blocknr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Erc721_Block_Var_Samp_Fields = {
  __typename?: 'user_erc721_block_var_samp_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blocknr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Erc721_Block_Variance_Fields = {
  __typename?: 'user_erc721_block_variance_fields';
  blockchain_id?: Maybe<Scalars['Float']>;
  blocknr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_gpanel" */
export type User_Gpanel = {
  __typename?: 'user_gpanel';
  access_token?: Maybe<Scalars['String']>;
  code_token?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  refresh_token?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  uuid?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_gpanel" */
export type User_Gpanel_Aggregate = {
  __typename?: 'user_gpanel_aggregate';
  aggregate?: Maybe<User_Gpanel_Aggregate_Fields>;
  nodes: Array<User_Gpanel>;
};

export type User_Gpanel_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Gpanel_Aggregate_Bool_Exp_Count>;
};

export type User_Gpanel_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Gpanel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Gpanel_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_gpanel" */
export type User_Gpanel_Aggregate_Fields = {
  __typename?: 'user_gpanel_aggregate_fields';
  avg?: Maybe<User_Gpanel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Gpanel_Max_Fields>;
  min?: Maybe<User_Gpanel_Min_Fields>;
  stddev?: Maybe<User_Gpanel_Stddev_Fields>;
  stddev_pop?: Maybe<User_Gpanel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Gpanel_Stddev_Samp_Fields>;
  sum?: Maybe<User_Gpanel_Sum_Fields>;
  var_pop?: Maybe<User_Gpanel_Var_Pop_Fields>;
  var_samp?: Maybe<User_Gpanel_Var_Samp_Fields>;
  variance?: Maybe<User_Gpanel_Variance_Fields>;
};

/** aggregate fields of "user_gpanel" */
export type User_Gpanel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Gpanel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_gpanel" */
export type User_Gpanel_Aggregate_Order_By = {
  avg?: InputMaybe<User_Gpanel_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Gpanel_Max_Order_By>;
  min?: InputMaybe<User_Gpanel_Min_Order_By>;
  stddev?: InputMaybe<User_Gpanel_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Gpanel_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Gpanel_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Gpanel_Sum_Order_By>;
  var_pop?: InputMaybe<User_Gpanel_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Gpanel_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Gpanel_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_gpanel" */
export type User_Gpanel_Arr_Rel_Insert_Input = {
  data: Array<User_Gpanel_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Gpanel_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Gpanel_Avg_Fields = {
  __typename?: 'user_gpanel_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_gpanel" */
export type User_Gpanel_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_gpanel". All fields are combined with a logical 'AND'. */
export type User_Gpanel_Bool_Exp = {
  _and?: InputMaybe<Array<User_Gpanel_Bool_Exp>>;
  _not?: InputMaybe<User_Gpanel_Bool_Exp>;
  _or?: InputMaybe<Array<User_Gpanel_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  code_token?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  uuid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_gpanel" */
export enum User_Gpanel_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserGpanelPkey = 'user_gpanel_pkey',
}

/** input type for incrementing numeric columns in table "user_gpanel" */
export type User_Gpanel_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_gpanel" */
export type User_Gpanel_Insert_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  code_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Gpanel_Max_Fields = {
  __typename?: 'user_gpanel_max_fields';
  access_token?: Maybe<Scalars['String']>;
  code_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_gpanel" */
export type User_Gpanel_Max_Order_By = {
  access_token?: InputMaybe<Order_By>;
  code_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Gpanel_Min_Fields = {
  __typename?: 'user_gpanel_min_fields';
  access_token?: Maybe<Scalars['String']>;
  code_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_gpanel" */
export type User_Gpanel_Min_Order_By = {
  access_token?: InputMaybe<Order_By>;
  code_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_gpanel" */
export type User_Gpanel_Mutation_Response = {
  __typename?: 'user_gpanel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Gpanel>;
};

/** on_conflict condition type for table "user_gpanel" */
export type User_Gpanel_On_Conflict = {
  constraint: User_Gpanel_Constraint;
  update_columns?: Array<User_Gpanel_Update_Column>;
  where?: InputMaybe<User_Gpanel_Bool_Exp>;
};

/** Ordering options when selecting data from "user_gpanel". */
export type User_Gpanel_Order_By = {
  access_token?: InputMaybe<Order_By>;
  code_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_gpanel */
export type User_Gpanel_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_gpanel" */
export enum User_Gpanel_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  CodeToken = 'code_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "user_gpanel" */
export type User_Gpanel_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  code_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Gpanel_Stddev_Fields = {
  __typename?: 'user_gpanel_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_gpanel" */
export type User_Gpanel_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Gpanel_Stddev_Pop_Fields = {
  __typename?: 'user_gpanel_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_gpanel" */
export type User_Gpanel_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Gpanel_Stddev_Samp_Fields = {
  __typename?: 'user_gpanel_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_gpanel" */
export type User_Gpanel_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_gpanel" */
export type User_Gpanel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Gpanel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Gpanel_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  code_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Gpanel_Sum_Fields = {
  __typename?: 'user_gpanel_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_gpanel" */
export type User_Gpanel_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "user_gpanel" */
export enum User_Gpanel_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  CodeToken = 'code_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Uuid = 'uuid',
}

export type User_Gpanel_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Gpanel_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Gpanel_Set_Input>;
  where: User_Gpanel_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Gpanel_Var_Pop_Fields = {
  __typename?: 'user_gpanel_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_gpanel" */
export type User_Gpanel_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Gpanel_Var_Samp_Fields = {
  __typename?: 'user_gpanel_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_gpanel" */
export type User_Gpanel_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Gpanel_Variance_Fields = {
  __typename?: 'user_gpanel_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_gpanel" */
export type User_Gpanel_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_lock" */
export type User_Lock = {
  __typename?: 'user_lock';
  amount: Scalars['numeric'];
  confirmed: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  lock_id: Scalars['Int'];
  removed: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_lock" */
export type User_Lock_Aggregate = {
  __typename?: 'user_lock_aggregate';
  aggregate?: Maybe<User_Lock_Aggregate_Fields>;
  nodes: Array<User_Lock>;
};

export type User_Lock_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Lock_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Lock_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Lock_Aggregate_Bool_Exp_Count>;
};

export type User_Lock_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Lock_Select_Column_User_Lock_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Lock_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Lock_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Lock_Select_Column_User_Lock_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Lock_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Lock_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Lock_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Lock_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_lock" */
export type User_Lock_Aggregate_Fields = {
  __typename?: 'user_lock_aggregate_fields';
  avg?: Maybe<User_Lock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Lock_Max_Fields>;
  min?: Maybe<User_Lock_Min_Fields>;
  stddev?: Maybe<User_Lock_Stddev_Fields>;
  stddev_pop?: Maybe<User_Lock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Lock_Stddev_Samp_Fields>;
  sum?: Maybe<User_Lock_Sum_Fields>;
  var_pop?: Maybe<User_Lock_Var_Pop_Fields>;
  var_samp?: Maybe<User_Lock_Var_Samp_Fields>;
  variance?: Maybe<User_Lock_Variance_Fields>;
};

/** aggregate fields of "user_lock" */
export type User_Lock_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Lock_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_lock" */
export type User_Lock_Aggregate_Order_By = {
  avg?: InputMaybe<User_Lock_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Lock_Max_Order_By>;
  min?: InputMaybe<User_Lock_Min_Order_By>;
  stddev?: InputMaybe<User_Lock_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Lock_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Lock_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Lock_Sum_Order_By>;
  var_pop?: InputMaybe<User_Lock_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Lock_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Lock_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_lock" */
export type User_Lock_Arr_Rel_Insert_Input = {
  data: Array<User_Lock_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Lock_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Lock_Avg_Fields = {
  __typename?: 'user_lock_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_lock" */
export type User_Lock_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_lock". All fields are combined with a logical 'AND'. */
export type User_Lock_Bool_Exp = {
  _and?: InputMaybe<Array<User_Lock_Bool_Exp>>;
  _not?: InputMaybe<User_Lock_Bool_Exp>;
  _or?: InputMaybe<Array<User_Lock_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  confirmed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lock_id?: InputMaybe<Int_Comparison_Exp>;
  removed?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_lock" */
export enum User_Lock_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserLockPkey = 'user_lock_pkey',
}

/** input type for incrementing numeric columns in table "user_lock" */
export type User_Lock_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  lock_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_lock" */
export type User_Lock_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  lock_id?: InputMaybe<Scalars['Int']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Lock_Max_Fields = {
  __typename?: 'user_lock_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  lock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_lock" */
export type User_Lock_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Lock_Min_Fields = {
  __typename?: 'user_lock_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  lock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_lock" */
export type User_Lock_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_lock" */
export type User_Lock_Mutation_Response = {
  __typename?: 'user_lock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Lock>;
};

/** on_conflict condition type for table "user_lock" */
export type User_Lock_On_Conflict = {
  constraint: User_Lock_Constraint;
  update_columns?: Array<User_Lock_Update_Column>;
  where?: InputMaybe<User_Lock_Bool_Exp>;
};

/** Ordering options when selecting data from "user_lock". */
export type User_Lock_Order_By = {
  amount?: InputMaybe<Order_By>;
  confirmed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
  removed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_lock */
export type User_Lock_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_lock" */
export enum User_Lock_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockId = 'lock_id',
  /** column name */
  Removed = 'removed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_lock_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_lock" */
export enum User_Lock_Select_Column_User_Lock_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  Removed = 'removed',
}

/** select "user_lock_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_lock" */
export enum User_Lock_Select_Column_User_Lock_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  Removed = 'removed',
}

/** input type for updating data in table "user_lock" */
export type User_Lock_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  lock_id?: InputMaybe<Scalars['Int']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Lock_Stddev_Fields = {
  __typename?: 'user_lock_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_lock" */
export type User_Lock_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Lock_Stddev_Pop_Fields = {
  __typename?: 'user_lock_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_lock" */
export type User_Lock_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Lock_Stddev_Samp_Fields = {
  __typename?: 'user_lock_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_lock" */
export type User_Lock_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_lock" */
export type User_Lock_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Lock_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Lock_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  lock_id?: InputMaybe<Scalars['Int']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Lock_Sum_Fields = {
  __typename?: 'user_lock_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  lock_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_lock" */
export type User_Lock_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_lock" */
export enum User_Lock_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockId = 'lock_id',
  /** column name */
  Removed = 'removed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Lock_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Lock_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Lock_Set_Input>;
  where: User_Lock_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Lock_Var_Pop_Fields = {
  __typename?: 'user_lock_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_lock" */
export type User_Lock_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Lock_Var_Samp_Fields = {
  __typename?: 'user_lock_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_lock" */
export type User_Lock_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Lock_Variance_Fields = {
  __typename?: 'user_lock_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_lock" */
export type User_Lock_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lock_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_staking" */
export type User_Staking = {
  __typename?: 'user_staking';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  nft: Scalars['Boolean'];
  rackback_id?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  unstaked_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  usd_value?: Maybe<Scalars['numeric']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_staking" */
export type User_Staking_Aggregate = {
  __typename?: 'user_staking_aggregate';
  aggregate?: Maybe<User_Staking_Aggregate_Fields>;
  nodes: Array<User_Staking>;
};

/** aggregate fields of "user_staking" */
export type User_Staking_Aggregate_Fields = {
  __typename?: 'user_staking_aggregate_fields';
  avg?: Maybe<User_Staking_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Staking_Max_Fields>;
  min?: Maybe<User_Staking_Min_Fields>;
  stddev?: Maybe<User_Staking_Stddev_Fields>;
  stddev_pop?: Maybe<User_Staking_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Staking_Stddev_Samp_Fields>;
  sum?: Maybe<User_Staking_Sum_Fields>;
  var_pop?: Maybe<User_Staking_Var_Pop_Fields>;
  var_samp?: Maybe<User_Staking_Var_Samp_Fields>;
  variance?: Maybe<User_Staking_Variance_Fields>;
};

/** aggregate fields of "user_staking" */
export type User_Staking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Staking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Staking_Avg_Fields = {
  __typename?: 'user_staking_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rackback_id?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_staking". All fields are combined with a logical 'AND'. */
export type User_Staking_Bool_Exp = {
  _and?: InputMaybe<Array<User_Staking_Bool_Exp>>;
  _not?: InputMaybe<User_Staking_Bool_Exp>;
  _or?: InputMaybe<Array<User_Staking_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  nft?: InputMaybe<Boolean_Comparison_Exp>;
  rackback_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  unstaked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  usd_value?: InputMaybe<Numeric_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_staking" */
export enum User_Staking_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserStakingPkey = 'user_staking_pkey',
}

/** input type for incrementing numeric columns in table "user_staking" */
export type User_Staking_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  rackback_id?: InputMaybe<Scalars['Int']>;
  usd_value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "user_staking" */
export type User_Staking_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  nft?: InputMaybe<Scalars['Boolean']>;
  rackback_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  unstaked_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  usd_value?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Staking_Max_Fields = {
  __typename?: 'user_staking_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  rackback_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  unstaked_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usd_value?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Staking_Min_Fields = {
  __typename?: 'user_staking_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  rackback_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  unstaked_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usd_value?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_staking" */
export type User_Staking_Mutation_Response = {
  __typename?: 'user_staking_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Staking>;
};

/** on_conflict condition type for table "user_staking" */
export type User_Staking_On_Conflict = {
  constraint: User_Staking_Constraint;
  update_columns?: Array<User_Staking_Update_Column>;
  where?: InputMaybe<User_Staking_Bool_Exp>;
};

/** Ordering options when selecting data from "user_staking". */
export type User_Staking_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nft?: InputMaybe<Order_By>;
  rackback_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  unstaked_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  usd_value?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_staking */
export type User_Staking_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_staking" */
export enum User_Staking_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Nft = 'nft',
  /** column name */
  RackbackId = 'rackback_id',
  /** column name */
  Status = 'status',
  /** column name */
  UnstakedAt = 'unstaked_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsdValue = 'usd_value',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_staking" */
export type User_Staking_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  nft?: InputMaybe<Scalars['Boolean']>;
  rackback_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  unstaked_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  usd_value?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Staking_Stddev_Fields = {
  __typename?: 'user_staking_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rackback_id?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Staking_Stddev_Pop_Fields = {
  __typename?: 'user_staking_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rackback_id?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Staking_Stddev_Samp_Fields = {
  __typename?: 'user_staking_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rackback_id?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_staking" */
export type User_Staking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Staking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Staking_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  nft?: InputMaybe<Scalars['Boolean']>;
  rackback_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  unstaked_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  usd_value?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Staking_Sum_Fields = {
  __typename?: 'user_staking_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  rackback_id?: Maybe<Scalars['Int']>;
  usd_value?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "user_staking_transactions" */
export type User_Staking_Transactions = {
  __typename?: 'user_staking_transactions';
  amount: Scalars['numeric'];
  blockNumber: Scalars['Int'];
  blockchain_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from: Scalars['String'];
  function?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  id: Scalars['Int'];
  timeStamp: Scalars['Int'];
  to: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  usd_value?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "user_staking_transactions" */
export type User_Staking_Transactions_Aggregate = {
  __typename?: 'user_staking_transactions_aggregate';
  aggregate?: Maybe<User_Staking_Transactions_Aggregate_Fields>;
  nodes: Array<User_Staking_Transactions>;
};

/** aggregate fields of "user_staking_transactions" */
export type User_Staking_Transactions_Aggregate_Fields = {
  __typename?: 'user_staking_transactions_aggregate_fields';
  avg?: Maybe<User_Staking_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Staking_Transactions_Max_Fields>;
  min?: Maybe<User_Staking_Transactions_Min_Fields>;
  stddev?: Maybe<User_Staking_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<User_Staking_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Staking_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<User_Staking_Transactions_Sum_Fields>;
  var_pop?: Maybe<User_Staking_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<User_Staking_Transactions_Var_Samp_Fields>;
  variance?: Maybe<User_Staking_Transactions_Variance_Fields>;
};

/** aggregate fields of "user_staking_transactions" */
export type User_Staking_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Staking_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Staking_Transactions_Avg_Fields = {
  __typename?: 'user_staking_transactions_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_staking_transactions". All fields are combined with a logical 'AND'. */
export type User_Staking_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Staking_Transactions_Bool_Exp>>;
  _not?: InputMaybe<User_Staking_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Staking_Transactions_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  blockNumber?: InputMaybe<Int_Comparison_Exp>;
  blockchain_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  function?: InputMaybe<String_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  timeStamp?: InputMaybe<Int_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  usd_value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_staking_transactions" */
export enum User_Staking_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserStakingTransactionsPkey = 'user_staking_transactions_pkey',
}

/** input type for incrementing numeric columns in table "user_staking_transactions" */
export type User_Staking_Transactions_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  usd_value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "user_staking_transactions" */
export type User_Staking_Transactions_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  usd_value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type User_Staking_Transactions_Max_Fields = {
  __typename?: 'user_staking_transactions_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  timeStamp?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usd_value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type User_Staking_Transactions_Min_Fields = {
  __typename?: 'user_staking_transactions_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  timeStamp?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usd_value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "user_staking_transactions" */
export type User_Staking_Transactions_Mutation_Response = {
  __typename?: 'user_staking_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Staking_Transactions>;
};

/** on_conflict condition type for table "user_staking_transactions" */
export type User_Staking_Transactions_On_Conflict = {
  constraint: User_Staking_Transactions_Constraint;
  update_columns?: Array<User_Staking_Transactions_Update_Column>;
  where?: InputMaybe<User_Staking_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_staking_transactions". */
export type User_Staking_Transactions_Order_By = {
  amount?: InputMaybe<Order_By>;
  blockNumber?: InputMaybe<Order_By>;
  blockchain_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  function?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeStamp?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  usd_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_staking_transactions */
export type User_Staking_Transactions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_staking_transactions" */
export enum User_Staking_Transactions_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Function = 'function',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  TimeStamp = 'timeStamp',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsdValue = 'usd_value',
}

/** input type for updating data in table "user_staking_transactions" */
export type User_Staking_Transactions_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  usd_value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type User_Staking_Transactions_Stddev_Fields = {
  __typename?: 'user_staking_transactions_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Staking_Transactions_Stddev_Pop_Fields = {
  __typename?: 'user_staking_transactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Staking_Transactions_Stddev_Samp_Fields = {
  __typename?: 'user_staking_transactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_staking_transactions" */
export type User_Staking_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Staking_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Staking_Transactions_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockchain_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  timeStamp?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  usd_value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type User_Staking_Transactions_Sum_Fields = {
  __typename?: 'user_staking_transactions_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockchain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  timeStamp?: Maybe<Scalars['Int']>;
  usd_value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "user_staking_transactions" */
export enum User_Staking_Transactions_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainId = 'blockchain_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Function = 'function',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  TimeStamp = 'timeStamp',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsdValue = 'usd_value',
}

export type User_Staking_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Staking_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Staking_Transactions_Set_Input>;
  where: User_Staking_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Staking_Transactions_Var_Pop_Fields = {
  __typename?: 'user_staking_transactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Staking_Transactions_Var_Samp_Fields = {
  __typename?: 'user_staking_transactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Staking_Transactions_Variance_Fields = {
  __typename?: 'user_staking_transactions_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  blockNumber?: Maybe<Scalars['Float']>;
  blockchain_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** update columns of table "user_staking" */
export enum User_Staking_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Nft = 'nft',
  /** column name */
  RackbackId = 'rackback_id',
  /** column name */
  Status = 'status',
  /** column name */
  UnstakedAt = 'unstaked_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsdValue = 'usd_value',
  /** column name */
  UserId = 'user_id',
}

export type User_Staking_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Staking_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Staking_Set_Input>;
  where: User_Staking_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Staking_Var_Pop_Fields = {
  __typename?: 'user_staking_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rackback_id?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Staking_Var_Samp_Fields = {
  __typename?: 'user_staking_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rackback_id?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Staking_Variance_Fields = {
  __typename?: 'user_staking_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rackback_id?: Maybe<Scalars['Float']>;
  usd_value?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  chainBusd?: Maybe<Scalars['numeric']>;
  chainInp?: Maybe<Scalars['numeric']>;
  createdAt: Scalars['timestamptz'];
  ethAddress: Scalars['String'];
  gpanelUserId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  gpanel_users: Array<GpanelUsers>;
  /** An aggregate relationship */
  gpanel_users_aggregate: GpanelUsers_Aggregate;
  id: Scalars['uuid'];
  idnr: Scalars['bigint'];
  language: Scalars['String'];
  lastSeen: Scalars['timestamptz'];
  lastSignin?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  nft_owners: Array<NftOwners>;
  /** An aggregate relationship */
  nft_owners_aggregate: NftOwners_Aggregate;
  /** An array relationship */
  pending_transactions: Array<PendingTransactions>;
  /** An aggregate relationship */
  pending_transactions_aggregate: PendingTransactions_Aggregate;
  /** Remove */
  pokerBusd?: Maybe<Scalars['numeric']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['numeric']>;
  pokerName?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  telegramName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  user_gpanels: Array<User_Gpanel>;
  /** An aggregate relationship */
  user_gpanels_aggregate: User_Gpanel_Aggregate;
  /** An array relationship */
  user_locks: Array<User_Lock>;
  /** An aggregate relationship */
  user_locks_aggregate: User_Lock_Aggregate;
};

/** columns and relationships of "users" */
export type UsersGpanel_UsersArgs = {
  distinct_on?: InputMaybe<Array<GpanelUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelUsers_Order_By>>;
  where?: InputMaybe<GpanelUsers_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersGpanel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GpanelUsers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GpanelUsers_Order_By>>;
  where?: InputMaybe<GpanelUsers_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersNft_OwnersArgs = {
  distinct_on?: InputMaybe<Array<NftOwners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftOwners_Order_By>>;
  where?: InputMaybe<NftOwners_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersNft_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NftOwners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NftOwners_Order_By>>;
  where?: InputMaybe<NftOwners_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPending_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<PendingTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PendingTransactions_Order_By>>;
  where?: InputMaybe<PendingTransactions_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPending_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PendingTransactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PendingTransactions_Order_By>>;
  where?: InputMaybe<PendingTransactions_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_GpanelsArgs = {
  distinct_on?: InputMaybe<Array<User_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Gpanel_Order_By>>;
  where?: InputMaybe<User_Gpanel_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Gpanels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Gpanel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Gpanel_Order_By>>;
  where?: InputMaybe<User_Gpanel_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_LocksArgs = {
  distinct_on?: InputMaybe<Array<User_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lock_Order_By>>;
  where?: InputMaybe<User_Lock_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Locks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lock_Order_By>>;
  where?: InputMaybe<User_Lock_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  chainBusd?: Maybe<Scalars['Float']>;
  chainInp?: Maybe<Scalars['Float']>;
  gpanelUserId?: Maybe<Scalars['Float']>;
  idnr?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  chainBusd?: InputMaybe<Numeric_Comparison_Exp>;
  chainInp?: InputMaybe<Numeric_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  ethAddress?: InputMaybe<String_Comparison_Exp>;
  gpanelUserId?: InputMaybe<Int_Comparison_Exp>;
  gpanel_users?: InputMaybe<GpanelUsers_Bool_Exp>;
  gpanel_users_aggregate?: InputMaybe<GpanelUsers_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  idnr?: InputMaybe<Bigint_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  lastSeen?: InputMaybe<Timestamptz_Comparison_Exp>;
  lastSignin?: InputMaybe<Timestamptz_Comparison_Exp>;
  nft_owners?: InputMaybe<NftOwners_Bool_Exp>;
  nft_owners_aggregate?: InputMaybe<NftOwners_Aggregate_Bool_Exp>;
  pending_transactions?: InputMaybe<PendingTransactions_Bool_Exp>;
  pending_transactions_aggregate?: InputMaybe<PendingTransactions_Aggregate_Bool_Exp>;
  pokerBusd?: InputMaybe<Numeric_Comparison_Exp>;
  pokerInp?: InputMaybe<Numeric_Comparison_Exp>;
  pokerName?: InputMaybe<String_Comparison_Exp>;
  signature?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  telegramName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_gpanels?: InputMaybe<User_Gpanel_Bool_Exp>;
  user_gpanels_aggregate?: InputMaybe<User_Gpanel_Aggregate_Bool_Exp>;
  user_locks?: InputMaybe<User_Lock_Bool_Exp>;
  user_locks_aggregate?: InputMaybe<User_Lock_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "idnr" */
  UsersNewIdnrKey = 'users_new_idnr_key',
  /** unique or primary key constraint on columns "id" */
  UsersNewPkey = 'users_new_pkey',
  /** unique or primary key constraint on columns "eth_address" */
  UsersUnEthAddress = 'users_un_eth_address',
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  chainBusd?: InputMaybe<Scalars['numeric']>;
  chainInp?: InputMaybe<Scalars['numeric']>;
  gpanelUserId?: InputMaybe<Scalars['Int']>;
  idnr?: InputMaybe<Scalars['bigint']>;
  /** Remove */
  pokerBusd?: InputMaybe<Scalars['numeric']>;
  /** Remove */
  pokerInp?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  chainBusd?: InputMaybe<Scalars['numeric']>;
  chainInp?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  ethAddress?: InputMaybe<Scalars['String']>;
  gpanelUserId?: InputMaybe<Scalars['Int']>;
  gpanel_users?: InputMaybe<GpanelUsers_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  idnr?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']>;
  lastSignin?: InputMaybe<Scalars['timestamptz']>;
  nft_owners?: InputMaybe<NftOwners_Arr_Rel_Insert_Input>;
  pending_transactions?: InputMaybe<PendingTransactions_Arr_Rel_Insert_Input>;
  /** Remove */
  pokerBusd?: InputMaybe<Scalars['numeric']>;
  /** Remove */
  pokerInp?: InputMaybe<Scalars['numeric']>;
  pokerName?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  telegramName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user_gpanels?: InputMaybe<User_Gpanel_Arr_Rel_Insert_Input>;
  user_locks?: InputMaybe<User_Lock_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "users_login" */
export type Users_Login = {
  __typename?: 'users_login';
  eth_address?: Maybe<Scalars['String']>;
  gpanel_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users_login" */
export type Users_Login_Aggregate = {
  __typename?: 'users_login_aggregate';
  aggregate?: Maybe<Users_Login_Aggregate_Fields>;
  nodes: Array<Users_Login>;
};

/** aggregate fields of "users_login" */
export type Users_Login_Aggregate_Fields = {
  __typename?: 'users_login_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Login_Max_Fields>;
  min?: Maybe<Users_Login_Min_Fields>;
};

/** aggregate fields of "users_login" */
export type Users_Login_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Login_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_login". All fields are combined with a logical 'AND'. */
export type Users_Login_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Login_Bool_Exp>>;
  _not?: InputMaybe<Users_Login_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Login_Bool_Exp>>;
  eth_address?: InputMaybe<String_Comparison_Exp>;
  gpanel_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Users_Login_Max_Fields = {
  __typename?: 'users_login_max_fields';
  eth_address?: Maybe<Scalars['String']>;
  gpanel_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Login_Min_Fields = {
  __typename?: 'users_login_min_fields';
  eth_address?: Maybe<Scalars['String']>;
  gpanel_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "users_login". */
export type Users_Login_Order_By = {
  eth_address?: InputMaybe<Order_By>;
  gpanel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** select columns of table "users_login" */
export enum Users_Login_Select_Column {
  /** column name */
  EthAddress = 'eth_address',
  /** column name */
  GpanelId = 'gpanel_id',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  Username = 'username',
}

/** Streaming cursor of the table "users_login" */
export type Users_Login_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Login_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Login_Stream_Cursor_Value_Input = {
  eth_address?: InputMaybe<Scalars['String']>;
  gpanel_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  chainBusd?: Maybe<Scalars['numeric']>;
  chainInp?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  ethAddress?: Maybe<Scalars['String']>;
  gpanelUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  idnr?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  lastSeen?: Maybe<Scalars['timestamptz']>;
  lastSignin?: Maybe<Scalars['timestamptz']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['numeric']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['numeric']>;
  pokerName?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telegramName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  chainBusd?: Maybe<Scalars['numeric']>;
  chainInp?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  ethAddress?: Maybe<Scalars['String']>;
  gpanelUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  idnr?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  lastSeen?: Maybe<Scalars['timestamptz']>;
  lastSignin?: Maybe<Scalars['timestamptz']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['numeric']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['numeric']>;
  pokerName?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telegramName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** columns and relationships of "users_old" */
export type Users_Old = {
  __typename?: 'users_old';
  chain_busd?: Maybe<Scalars['numeric']>;
  chain_inp?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  eth_address: Scalars['String'];
  id: Scalars['bigint'];
  poker_busd?: Maybe<Scalars['numeric']>;
  poker_inp?: Maybe<Scalars['numeric']>;
  poker_name?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  telegram_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "users_old" */
export type Users_Old_Aggregate = {
  __typename?: 'users_old_aggregate';
  aggregate?: Maybe<Users_Old_Aggregate_Fields>;
  nodes: Array<Users_Old>;
};

/** aggregate fields of "users_old" */
export type Users_Old_Aggregate_Fields = {
  __typename?: 'users_old_aggregate_fields';
  avg?: Maybe<Users_Old_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Old_Max_Fields>;
  min?: Maybe<Users_Old_Min_Fields>;
  stddev?: Maybe<Users_Old_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Old_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Old_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Old_Sum_Fields>;
  var_pop?: Maybe<Users_Old_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Old_Var_Samp_Fields>;
  variance?: Maybe<Users_Old_Variance_Fields>;
};

/** aggregate fields of "users_old" */
export type Users_Old_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Old_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_Old_Avg_Fields = {
  __typename?: 'users_old_avg_fields';
  chain_busd?: Maybe<Scalars['Float']>;
  chain_inp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poker_busd?: Maybe<Scalars['Float']>;
  poker_inp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users_old". All fields are combined with a logical 'AND'. */
export type Users_Old_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Old_Bool_Exp>>;
  _not?: InputMaybe<Users_Old_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Old_Bool_Exp>>;
  chain_busd?: InputMaybe<Numeric_Comparison_Exp>;
  chain_inp?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  eth_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  poker_busd?: InputMaybe<Numeric_Comparison_Exp>;
  poker_inp?: InputMaybe<Numeric_Comparison_Exp>;
  poker_name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  telegram_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_old" */
export enum Users_Old_Constraint {
  /** unique or primary key constraint on columns "eth_address" */
  UsersEthAddressKey = 'users_eth_address_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
}

/** input type for incrementing numeric columns in table "users_old" */
export type Users_Old_Inc_Input = {
  chain_busd?: InputMaybe<Scalars['numeric']>;
  chain_inp?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  poker_busd?: InputMaybe<Scalars['numeric']>;
  poker_inp?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "users_old" */
export type Users_Old_Insert_Input = {
  chain_busd?: InputMaybe<Scalars['numeric']>;
  chain_inp?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  eth_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  poker_busd?: InputMaybe<Scalars['numeric']>;
  poker_inp?: InputMaybe<Scalars['numeric']>;
  poker_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  telegram_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Old_Max_Fields = {
  __typename?: 'users_old_max_fields';
  chain_busd?: Maybe<Scalars['numeric']>;
  chain_inp?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eth_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  poker_busd?: Maybe<Scalars['numeric']>;
  poker_inp?: Maybe<Scalars['numeric']>;
  poker_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telegram_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Old_Min_Fields = {
  __typename?: 'users_old_min_fields';
  chain_busd?: Maybe<Scalars['numeric']>;
  chain_inp?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eth_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  poker_busd?: Maybe<Scalars['numeric']>;
  poker_inp?: Maybe<Scalars['numeric']>;
  poker_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telegram_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users_old" */
export type Users_Old_Mutation_Response = {
  __typename?: 'users_old_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Old>;
};

/** on_conflict condition type for table "users_old" */
export type Users_Old_On_Conflict = {
  constraint: Users_Old_Constraint;
  update_columns?: Array<Users_Old_Update_Column>;
  where?: InputMaybe<Users_Old_Bool_Exp>;
};

/** Ordering options when selecting data from "users_old". */
export type Users_Old_Order_By = {
  chain_busd?: InputMaybe<Order_By>;
  chain_inp?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  eth_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  poker_busd?: InputMaybe<Order_By>;
  poker_inp?: InputMaybe<Order_By>;
  poker_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  telegram_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_old */
export type Users_Old_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "users_old" */
export enum Users_Old_Select_Column {
  /** column name */
  ChainBusd = 'chain_busd',
  /** column name */
  ChainInp = 'chain_inp',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EthAddress = 'eth_address',
  /** column name */
  Id = 'id',
  /** column name */
  PokerBusd = 'poker_busd',
  /** column name */
  PokerInp = 'poker_inp',
  /** column name */
  PokerName = 'poker_name',
  /** column name */
  Status = 'status',
  /** column name */
  TelegramName = 'telegram_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "users_old" */
export type Users_Old_Set_Input = {
  chain_busd?: InputMaybe<Scalars['numeric']>;
  chain_inp?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  eth_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  poker_busd?: InputMaybe<Scalars['numeric']>;
  poker_inp?: InputMaybe<Scalars['numeric']>;
  poker_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  telegram_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Users_Old_Stddev_Fields = {
  __typename?: 'users_old_stddev_fields';
  chain_busd?: Maybe<Scalars['Float']>;
  chain_inp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poker_busd?: Maybe<Scalars['Float']>;
  poker_inp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Old_Stddev_Pop_Fields = {
  __typename?: 'users_old_stddev_pop_fields';
  chain_busd?: Maybe<Scalars['Float']>;
  chain_inp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poker_busd?: Maybe<Scalars['Float']>;
  poker_inp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Old_Stddev_Samp_Fields = {
  __typename?: 'users_old_stddev_samp_fields';
  chain_busd?: Maybe<Scalars['Float']>;
  chain_inp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poker_busd?: Maybe<Scalars['Float']>;
  poker_inp?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "users_old" */
export type Users_Old_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Old_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Old_Stream_Cursor_Value_Input = {
  chain_busd?: InputMaybe<Scalars['numeric']>;
  chain_inp?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  eth_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  poker_busd?: InputMaybe<Scalars['numeric']>;
  poker_inp?: InputMaybe<Scalars['numeric']>;
  poker_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  telegram_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Users_Old_Sum_Fields = {
  __typename?: 'users_old_sum_fields';
  chain_busd?: Maybe<Scalars['numeric']>;
  chain_inp?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  poker_busd?: Maybe<Scalars['numeric']>;
  poker_inp?: Maybe<Scalars['numeric']>;
};

/** update columns of table "users_old" */
export enum Users_Old_Update_Column {
  /** column name */
  ChainBusd = 'chain_busd',
  /** column name */
  ChainInp = 'chain_inp',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EthAddress = 'eth_address',
  /** column name */
  Id = 'id',
  /** column name */
  PokerBusd = 'poker_busd',
  /** column name */
  PokerInp = 'poker_inp',
  /** column name */
  PokerName = 'poker_name',
  /** column name */
  Status = 'status',
  /** column name */
  TelegramName = 'telegram_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Users_Old_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Old_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Old_Set_Input>;
  where: Users_Old_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Old_Var_Pop_Fields = {
  __typename?: 'users_old_var_pop_fields';
  chain_busd?: Maybe<Scalars['Float']>;
  chain_inp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poker_busd?: Maybe<Scalars['Float']>;
  poker_inp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Old_Var_Samp_Fields = {
  __typename?: 'users_old_var_samp_fields';
  chain_busd?: Maybe<Scalars['Float']>;
  chain_inp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poker_busd?: Maybe<Scalars['Float']>;
  poker_inp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Old_Variance_Fields = {
  __typename?: 'users_old_variance_fields';
  chain_busd?: Maybe<Scalars['Float']>;
  chain_inp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poker_busd?: Maybe<Scalars['Float']>;
  poker_inp?: Maybe<Scalars['Float']>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  chainBusd?: InputMaybe<Order_By>;
  chainInp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  ethAddress?: InputMaybe<Order_By>;
  gpanelUserId?: InputMaybe<Order_By>;
  gpanel_users_aggregate?: InputMaybe<GpanelUsers_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  idnr?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  lastSignin?: InputMaybe<Order_By>;
  nft_owners_aggregate?: InputMaybe<NftOwners_Aggregate_Order_By>;
  pending_transactions_aggregate?: InputMaybe<PendingTransactions_Aggregate_Order_By>;
  pokerBusd?: InputMaybe<Order_By>;
  pokerInp?: InputMaybe<Order_By>;
  pokerName?: InputMaybe<Order_By>;
  signature?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  telegramName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_gpanels_aggregate?: InputMaybe<User_Gpanel_Aggregate_Order_By>;
  user_locks_aggregate?: InputMaybe<User_Lock_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  ChainBusd = 'chainBusd',
  /** column name */
  ChainInp = 'chainInp',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EthAddress = 'ethAddress',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Idnr = 'idnr',
  /** column name */
  Language = 'language',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  LastSignin = 'lastSignin',
  /** column name */
  PokerBusd = 'pokerBusd',
  /** column name */
  PokerInp = 'pokerInp',
  /** column name */
  PokerName = 'pokerName',
  /** column name */
  Signature = 'signature',
  /** column name */
  Status = 'status',
  /** column name */
  TelegramName = 'telegramName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  chainBusd?: InputMaybe<Scalars['numeric']>;
  chainInp?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  ethAddress?: InputMaybe<Scalars['String']>;
  gpanelUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  idnr?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']>;
  lastSignin?: InputMaybe<Scalars['timestamptz']>;
  /** Remove */
  pokerBusd?: InputMaybe<Scalars['numeric']>;
  /** Remove */
  pokerInp?: InputMaybe<Scalars['numeric']>;
  pokerName?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  telegramName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  chainBusd?: Maybe<Scalars['Float']>;
  chainInp?: Maybe<Scalars['Float']>;
  gpanelUserId?: Maybe<Scalars['Float']>;
  idnr?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  chainBusd?: Maybe<Scalars['Float']>;
  chainInp?: Maybe<Scalars['Float']>;
  gpanelUserId?: Maybe<Scalars['Float']>;
  idnr?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  chainBusd?: Maybe<Scalars['Float']>;
  chainInp?: Maybe<Scalars['Float']>;
  gpanelUserId?: Maybe<Scalars['Float']>;
  idnr?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  chainBusd?: InputMaybe<Scalars['numeric']>;
  chainInp?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  ethAddress?: InputMaybe<Scalars['String']>;
  gpanelUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  idnr?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']>;
  lastSignin?: InputMaybe<Scalars['timestamptz']>;
  /** Remove */
  pokerBusd?: InputMaybe<Scalars['numeric']>;
  /** Remove */
  pokerInp?: InputMaybe<Scalars['numeric']>;
  pokerName?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  telegramName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  chainBusd?: Maybe<Scalars['numeric']>;
  chainInp?: Maybe<Scalars['numeric']>;
  gpanelUserId?: Maybe<Scalars['Int']>;
  idnr?: Maybe<Scalars['bigint']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['numeric']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['numeric']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  ChainBusd = 'chainBusd',
  /** column name */
  ChainInp = 'chainInp',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EthAddress = 'ethAddress',
  /** column name */
  GpanelUserId = 'gpanelUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Idnr = 'idnr',
  /** column name */
  Language = 'language',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  LastSignin = 'lastSignin',
  /** column name */
  PokerBusd = 'pokerBusd',
  /** column name */
  PokerInp = 'pokerInp',
  /** column name */
  PokerName = 'pokerName',
  /** column name */
  Signature = 'signature',
  /** column name */
  Status = 'status',
  /** column name */
  TelegramName = 'telegramName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  chainBusd?: Maybe<Scalars['Float']>;
  chainInp?: Maybe<Scalars['Float']>;
  gpanelUserId?: Maybe<Scalars['Float']>;
  idnr?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  chainBusd?: Maybe<Scalars['Float']>;
  chainInp?: Maybe<Scalars['Float']>;
  gpanelUserId?: Maybe<Scalars['Float']>;
  idnr?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  chainBusd?: Maybe<Scalars['Float']>;
  chainInp?: Maybe<Scalars['Float']>;
  gpanelUserId?: Maybe<Scalars['Float']>;
  idnr?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerBusd?: Maybe<Scalars['Float']>;
  /** Remove */
  pokerInp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AgentUsersBalancesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type AgentUsersBalancesQuery = {
  __typename?: 'query_root';
  updateAffiliatePlayerBalance?:
    | { __typename?: 'GeneralMessageOutput'; message: string }
    | null
    | undefined;
  evenbetAffiliatePlayerBalances: Array<{
    __typename?: 'evenbetAffiliatePlayerBalances';
    playerUsername?: string | null | undefined;
    cashBalanceUsd?: number | null | undefined;
    cashBalanceInp?: number | null | undefined;
  }>;
  evenbetAffiliatePlayerBalancesAggregate: {
    __typename?: 'evenbetAffiliatePlayerBalances_aggregate';
    aggregate?:
      | {
          __typename?: 'evenbetAffiliatePlayerBalances_aggregate_fields';
          totalCount: number;
        }
      | null
      | undefined;
  };
};

export type NonceQueryVariables = Exact<{
  address: Scalars['String'];
}>;

export type NonceQuery = {
  __typename?: 'query_root';
  authSignature?:
    | { __typename?: 'authSignatureOutput'; nonce: string }
    | null
    | undefined;
};

export type AccessTokenQueryVariables = Exact<{
  address?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  gpanelAccessToken?: InputMaybe<Scalars['String']>;
}>;

export type AccessTokenQuery = {
  __typename?: 'query_root';
  authLogin?:
    | {
        __typename?: 'authLoginOutput';
        user_id?: string | null | undefined;
        access_token?: string | null | undefined;
        role?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RefreshAccessTokenQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']>;
  gpanelAccessToken?: InputMaybe<Scalars['String']>;
}>;

export type RefreshAccessTokenQuery = {
  __typename?: 'query_root';
  authRefresh?:
    | {
        __typename?: 'authLoginOutput';
        user_id?: string | null | undefined;
        access_token?: string | null | undefined;
        eth_address?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetGpanelTokensQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetGpanelTokensQuery = {
  __typename?: 'query_root';
  getGpanelUUID?:
    | {
        __typename?: 'gPanelOutput';
        accessToken?: string | null | undefined;
        refreshToken?: string | null | undefined;
      }
    | null
    | undefined;
};

export type SumsubAccessTokenQueryVariables = Exact<{
  gpanelUserId: Scalars['String'];
}>;

export type SumsubAccessTokenQuery = {
  __typename?: 'query_root';
  paymentSumsubToken?:
    | { __typename?: 'sumsubOutput'; accessToken?: string | null | undefined }
    | null
    | undefined;
};

export type SetGpanelUuidMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SetGpanelUuidMutation = {
  __typename?: 'mutation_root';
  setGpanelUUID?:
    | { __typename?: 'GeneralMessageOutput'; message: string }
    | null
    | undefined;
};

export type RefreshGpanelAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshGpanelAccessTokenMutation = {
  __typename?: 'mutation_root';
  refreshGpanel?:
    | {
        __typename?: 'gPanelOutput';
        accessToken?: string | null | undefined;
        refreshToken?: string | null | undefined;
      }
    | null
    | undefined;
};

export type BlockChainDataFragment = {
  __typename?: 'blockchain';
  id: number;
  name: string;
  chainId: number;
  symbol: string;
  rpcPublic: any;
  scanUrl: string;
  iconUrl?: string | null | undefined;
  nativeCurrency: any;
  inpAddress?: string | null | undefined;
  marketplaceAddress?: string | null | undefined;
  inpVaultAddress?: string | null | undefined;
  nftCardAddress?: string | null | undefined;
  stablecoins: Array<{
    __typename?: 'stablecoins';
    id: number;
    contract: string;
    symbol: string;
    iconUrl?: string | null | undefined;
    depositActive: boolean;
    withdrawActive: boolean;
    units: string;
  }>;
};

export type BlockchainsQueryVariables = Exact<{ [key: string]: never }>;

export type BlockchainsQuery = {
  __typename?: 'query_root';
  blockchains: Array<{
    __typename?: 'blockchain';
    id: number;
    name: string;
    chainId: number;
    symbol: string;
    rpcPublic: any;
    scanUrl: string;
    iconUrl?: string | null | undefined;
    nativeCurrency: any;
    inpAddress?: string | null | undefined;
    marketplaceAddress?: string | null | undefined;
    inpVaultAddress?: string | null | undefined;
    nftCardAddress?: string | null | undefined;
    stablecoins: Array<{
      __typename?: 'stablecoins';
      id: number;
      contract: string;
      symbol: string;
      iconUrl?: string | null | undefined;
      depositActive: boolean;
      withdrawActive: boolean;
      units: string;
    }>;
  }>;
};

export type DirectDepositStablecoinDataFragment = {
  __typename?: 'stablecoins';
  id: number;
  blockchainId: number;
  iconUrl?: string | null | undefined;
  symbol: string;
};

export type DirectDepositDataQueryVariables = Exact<{ [key: string]: never }>;

export type DirectDepositDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        depositAddress?:
          | Array<
              | {
                  __typename?: 'depositAddressOutput';
                  blockchainId?: number | null | undefined;
                  address: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  stablecoins: Array<{
    __typename?: 'stablecoins';
    id: number;
    blockchainId: number;
    iconUrl?: string | null | undefined;
    symbol: string;
  }>;
  blockchains: Array<{
    __typename?: 'blockchain';
    id: number;
    chainId: number;
    name: string;
    symbol: string;
    iconUrl?: string | null | undefined;
    stablecoins: Array<{
      __typename?: 'stablecoins';
      id: number;
      blockchainId: number;
      iconUrl?: string | null | undefined;
      symbol: string;
    }>;
  }>;
};

export type DepositAddressQueryVariables = Exact<{
  blockchainId: Scalars['Int'];
}>;

export type DepositAddressQuery = {
  __typename?: 'query_root';
  paymentGetDepositAddress?:
    | { __typename?: 'depositAddressOutput'; address: string }
    | null
    | undefined;
};

export type BuyCryptoDataQueryVariables = Exact<{ [key: string]: never }>;

export type BuyCryptoDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        depositAddress?:
          | Array<
              | {
                  __typename?: 'depositAddressOutput';
                  blockchainId?: number | null | undefined;
                  address: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  stablecoins: Array<{
    __typename?: 'stablecoins';
    id: number;
    symbol: string;
  }>;
};

export type LastDepositTransactionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LastDepositTransactionQuery = {
  __typename?: 'query_root';
  gpanelPayments: Array<{
    __typename?: 'gpanelPayments';
    id: number;
    date: any;
    amount: number;
    currency: string;
  }>;
  pendingTransactions: Array<{
    __typename?: 'pendingTransactions';
    id: any;
    createdAt: any;
    amount: number;
    currency: string;
  }>;
};

export type LastWithdrawTransactionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LastWithdrawTransactionQuery = {
  __typename?: 'query_root';
  gpanelPayments: Array<{
    __typename?: 'gpanelPayments';
    date: any;
    amount: number;
    currency: string;
  }>;
  pendingTransactions: Array<{
    __typename?: 'pendingTransactions';
    createdAt: any;
    amount: number;
    currency: string;
  }>;
};

export type GpanelPendingTransactionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GpanelPendingTransactionsQuery = {
  __typename?: 'query_root';
  pendingTransactions: Array<{
    __typename?: 'pendingTransactions';
    id: any;
    createdAt: any;
    type: string;
    currency: string;
    amount: number;
    status: string;
    txid?: string | null | undefined;
    username?: string | null | undefined;
    blockchain: { __typename?: 'blockchain'; id: number; scanUrl: string };
  }>;
};

export type GpanelPaymentsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type GpanelPaymentsQuery = {
  __typename?: 'query_root';
  gpanelPaymentsAggregate: {
    __typename?: 'gpanelPayments_aggregate';
    aggregate?:
      | { __typename?: 'gpanelPayments_aggregate_fields'; totalCount: number }
      | null
      | undefined;
  };
  gpanelPayments: Array<{
    __typename?: 'gpanelPayments';
    id: number;
    gpanelId: string;
    date: any;
    method: string;
    status: string;
    identity: string;
    currency: string;
    amount: number;
  }>;
};

export type AgentFindUserQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type AgentFindUserQuery = {
  __typename?: 'query_root';
  agentFindUser?:
    | {
        __typename?: 'agentFindUserOutput';
        gPanelUserId?: string | null | undefined;
        username?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PaymentSubmitDepositMutationVariables = Exact<{
  blockchainId: Scalars['Int'];
  account: Scalars['String'];
  gpanelUserId: Scalars['String'];
  stablecoinId?: InputMaybe<Scalars['Int']>;
  currency: Scalars['String'];
  amount: Scalars['Float'];
}>;

export type PaymentSubmitDepositMutation = {
  __typename?: 'mutation_root';
  paymentSubmitDeposit?:
    | {
        __typename?: 'DepositOutput';
        id?: string | null | undefined;
        status: string;
        depositAddress?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PaymentSubmittedDepositMutationVariables = Exact<{
  id: Scalars['String'];
  tx: Scalars['String'];
}>;

export type PaymentSubmittedDepositMutation = {
  __typename?: 'mutation_root';
  paymentSubmittedDeposit?:
    | {
        __typename?: 'StatusOutput';
        id?: string | null | undefined;
        status: string;
      }
    | null
    | undefined;
};

export type AgentPaymentSubmitDepositMutationVariables = Exact<{
  blockchainId: Scalars['Int'];
  account: Scalars['String'];
  gpanelUserId: Scalars['String'];
  stablecoinId?: InputMaybe<Scalars['Int']>;
  currency: Scalars['String'];
  amount: Scalars['Float'];
}>;

export type AgentPaymentSubmitDepositMutation = {
  __typename?: 'mutation_root';
  agentPaymentSubmitDeposit?:
    | {
        __typename?: 'DepositOutput';
        id?: string | null | undefined;
        status: string;
        depositAddress?: string | null | undefined;
      }
    | null
    | undefined;
};

export type AgentPaymentSubmittedDepositMutationVariables = Exact<{
  id: Scalars['String'];
  tx: Scalars['String'];
}>;

export type AgentPaymentSubmittedDepositMutation = {
  __typename?: 'mutation_root';
  agentPaymentSubmittedDeposit?:
    | {
        __typename?: 'StatusOutput';
        id?: string | null | undefined;
        status: string;
      }
    | null
    | undefined;
};

export type PaymentSubmitWithdrawMutationVariables = Exact<{
  account: Scalars['String'];
  gpanelUserId: Scalars['String'];
  gpanelAccessToken: Scalars['String'];
  blockchainId?: InputMaybe<Scalars['Int']>;
  stablecoinId?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
}>;

export type PaymentSubmitWithdrawMutation = {
  __typename?: 'mutation_root';
  paymentSubmitWithdraw?:
    | { __typename?: 'StatusOutput'; status: string }
    | null
    | undefined;
};

export type AgentPaymentSubmitWithdrawMutationVariables = Exact<{
  gpanelUserId: Scalars['String'];
  gpanelAccessToken: Scalars['String'];
  agentGpanelUserId: Scalars['String'];
  blockchainId?: InputMaybe<Scalars['Int']>;
  stablecoinId?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
}>;

export type AgentPaymentSubmitWithdrawMutation = {
  __typename?: 'mutation_root';
  agentPaymentSubmitWithdraw?:
    | { __typename?: 'StatusOutput'; status: string }
    | null
    | undefined;
};

export type ClaimDataQueryVariables = Exact<{ [key: string]: never }>;

export type ClaimDataQuery = {
  __typename?: 'query_root';
  NFTAvailableTickets?:
    | {
        __typename?: 'NFTTicketsClaimOutput';
        totalNFTs?: number | null | undefined;
        nextReleaseTime?: string | null | undefined;
        claimOptions?:
          | Array<
              | {
                  __typename?: 'NFTTicketsClaimOptions';
                  id?: number | null | undefined;
                  amount?: number | null | undefined;
                  label?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ClaimMutationVariables = Exact<{
  ticketId: Scalars['Int'];
  amount?: InputMaybe<Scalars['Int']>;
}>;

export type ClaimMutation = {
  __typename?: 'mutation_root';
  NFTReleaseTicket?:
    | { __typename?: 'GeneralMessageOutput'; message: string }
    | null
    | undefined;
};

export type WithdrawPageFormStablecoinDataFragment = {
  __typename?: 'stablecoins';
  id: number;
  blockchainId: number;
  iconUrl?: string | null | undefined;
  symbol: string;
};

export type WithdrawPageFormDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WithdrawPageFormDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        kycStatus?: string | null | undefined;
        kyc?:
          | {
              __typename?: 'kycOutput';
              id: number;
              level: string;
              minWithdrawInp: number;
              maxWithdrawInp: number;
              feeWithdrawInp: number;
              minWithdraw: number;
              maxWithdraw: number;
              feeWithdraw: number;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  stablecoins: Array<{
    __typename?: 'stablecoins';
    id: number;
    blockchainId: number;
    iconUrl?: string | null | undefined;
    symbol: string;
  }>;
  blockchains: Array<{
    __typename?: 'blockchain';
    id: number;
    chainId: number;
    iconUrl?: string | null | undefined;
    symbol: string;
    name: string;
    stablecoins: Array<{
      __typename?: 'stablecoins';
      id: number;
      blockchainId: number;
      iconUrl?: string | null | undefined;
      symbol: string;
    }>;
  }>;
};

export type WithdrawPageWithdrawRequestMutationVariables = Exact<{
  gpanelUserId: Scalars['String'];
  gpanelAccessToken: Scalars['String'];
  currency?: InputMaybe<Scalars['String']>;
  stablecoinId?: InputMaybe<Scalars['Int']>;
  blockchainId?: InputMaybe<Scalars['Int']>;
  address: Scalars['String'];
  amount: Scalars['Float'];
}>;

export type WithdrawPageWithdrawRequestMutation = {
  __typename?: 'mutation_root';
  paymentSubmitWithdraw2FA1?:
    | {
        __typename?: 'StatusOutput';
        id?: string | null | undefined;
        status: string;
      }
    | null
    | undefined;
};

export type WithdrawPageWithdrawCodeConfirmMutationVariables = Exact<{
  id: Scalars['String'];
  code: Scalars['String'];
}>;

export type WithdrawPageWithdrawCodeConfirmMutation = {
  __typename?: 'mutation_root';
  paymentSubmitWithdraw2FA2?:
    | { __typename?: 'StatusOutput'; status: string }
    | null
    | undefined;
};

export type CommonInitialDataQueryVariables = Exact<{ [key: string]: never }>;

export type CommonInitialDataQuery = {
  __typename?: 'query_root';
  rakebackLocks: Array<{
    __typename?: 'rakebackLocks';
    id: number;
    label: string;
    level: number;
    percentage: number;
    percentageNft?: number | null | undefined;
    usd: number;
  }>;
  stablecoins: Array<{
    __typename?: 'stablecoins';
    id: number;
    blockchainId: number;
    iconUrl?: string | null | undefined;
    symbol: string;
  }>;
  blockchains: Array<{
    __typename?: 'blockchain';
    id: number;
    name: string;
    chainId: number;
    symbol: string;
    rpcPublic: any;
    scanUrl: string;
    iconUrl?: string | null | undefined;
    nativeCurrency: any;
    inpAddress?: string | null | undefined;
    marketplaceAddress?: string | null | undefined;
    inpVaultAddress?: string | null | undefined;
    nftCardAddress?: string | null | undefined;
    stablecoins: Array<{
      __typename?: 'stablecoins';
      id: number;
      contract: string;
      symbol: string;
      iconUrl?: string | null | undefined;
      depositActive: boolean;
      withdrawActive: boolean;
      units: string;
    }>;
  }>;
};

export type UpdateNftListingsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateNftListingsMutation = {
  __typename?: 'mutation_root';
  updateNFTListings?:
    | { __typename?: 'GeneralMessageOutput'; message: string }
    | null
    | undefined;
};

export type UserNftAvatarsQueryVariables = Exact<{ [key: string]: never }>;

export type UserNftAvatarsQuery = {
  __typename?: 'query_root';
  userErc721s: Array<{
    __typename?: 'userErc721';
    id: number;
    tokenId: number;
    name: string;
    collection?: string | null | undefined;
    imgUrl?: string | null | undefined;
  }>;
};

export type LoadingAvatarProcessesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LoadingAvatarProcessesQuery = {
  __typename?: 'query_root';
  userJobs: Array<{ __typename?: 'userJobs'; id: number }>;
};

export type UpdateAvatarNftsMutationVariables = Exact<{ [key: string]: never }>;

export type UpdateAvatarNftsMutation = {
  __typename?: 'mutation_root';
  updateAvailableAvatarNFTs: any;
};

export type SetUserAvatarMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type SetUserAvatarMutation = {
  __typename?: 'mutation_root';
  updateAvatar?:
    | { __typename?: 'GeneralMessageOutput'; message: string }
    | null
    | undefined;
};

export type UserNftsQueryVariables = Exact<{
  account: Scalars['String'];
  blockchainId?: InputMaybe<Scalars['smallint']>;
}>;

export type UserNftsQuery = {
  __typename?: 'query_root';
  nfts: Array<{
    __typename?: 'nfts';
    id: number;
    tokenId: string;
    owner: string;
    imageUrl?: string | null | undefined;
    nftContract: {
      __typename?: 'nftContracts';
      id: number;
      blockchainId?: number | null | undefined;
      contractAddress: string;
      collectionName?: string | null | undefined;
    };
    nftMetadatum: { __typename?: 'nftMetadatas'; id: number; imageUrl: string };
    listing?:
      | {
          __typename?: 'marketListings';
          originalListingId: number;
          price: number;
          endedAt?: number | null | undefined;
          marketplaceContract: {
            __typename?: 'marketplaceContracts';
            id: number;
            contractAddress: string;
            type: string;
            fee: any;
          };
        }
      | null
      | undefined;
  }>;
};

export type NftCardNameQueryVariables = Exact<{
  tokenId: Scalars['Int'];
}>;

export type NftCardNameQuery = {
  __typename?: 'query_root';
  nftOwners: Array<{
    __typename?: 'nftOwners';
    id: number;
    tokenid: number;
    pokerName?: string | null | undefined;
  }>;
};

export type UserDataToDelegateNftQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type UserDataToDelegateNftQuery = {
  __typename?: 'query_root';
  NFTFindUser?:
    | {
        __typename?: 'agentFindUserOutput';
        gPanelUserId?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DelegatedNftDataFragment = {
  __typename?: 'NFTConnectedDetail';
  id: number;
  status: string;
  tokenId: number;
  nftId: number;
  collection?: string | null | undefined;
  owner?: string | null | undefined;
  username?: string | null | undefined;
  imageUrl?: string | null | undefined;
};

export type DelegatedNfTsQueryVariables = Exact<{ [key: string]: never }>;

export type DelegatedNfTsQuery = {
  __typename?: 'query_root';
  NFTUserConnected?:
    | {
        __typename?: 'NFTConnectedOutput';
        own?:
          | Array<
              | {
                  __typename?: 'NFTConnectedDetail';
                  id: number;
                  tokenId: number;
                  status: string;
                  owner?: string | null | undefined;
                  username?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        rented?:
          | Array<
              | {
                  __typename?: 'NFTConnectedDetail';
                  id: number;
                  status: string;
                  tokenId: number;
                  nftId: number;
                  collection?: string | null | undefined;
                  owner?: string | null | undefined;
                  username?: string | null | undefined;
                  imageUrl?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SaveNftCardNameMutationVariables = Exact<{
  tokenId: Scalars['Int'];
  name: Scalars['String'];
}>;

export type SaveNftCardNameMutation = {
  __typename?: 'mutation_root';
  setNFTusername?:
    | { __typename?: 'GeneralMessageOutput'; message: string }
    | null
    | undefined;
};

export type DelegateOrRevokeNftMutationVariables = Exact<{
  blockchainId: Scalars['Int'];
  nftIds: Array<Scalars['Int']> | Scalars['Int'];
  gPanelUserId?: InputMaybe<Scalars['String']>;
  delegate: Scalars['Boolean'];
}>;

export type DelegateOrRevokeNftMutation = {
  __typename?: 'mutation_root';
  NFTRentOut?:
    | { __typename?: 'GeneralMessageOutput'; message: string }
    | null
    | undefined;
};

export type AcceptOrCancelDelegatedNftMutationVariables = Exact<{
  nftId: Scalars['Int'];
  accept: Scalars['Boolean'];
}>;

export type AcceptOrCancelDelegatedNftMutation = {
  __typename?: 'mutation_root';
  NFTDelegationAccept?:
    | { __typename?: 'GeneralMessageOutput'; message: string }
    | null
    | undefined;
};

export type InitialUserDataQueryVariables = Exact<{
  limited?: InputMaybe<Scalars['Boolean']>;
}>;

export type InitialUserDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        role?: string | null | undefined;
        language?: string | null | undefined;
        pokerName?: string | null | undefined;
        kycStatus?: string | null | undefined;
        avatar?:
          | {
              __typename?: 'avatarOutput';
              id?: number | null | undefined;
              name?: string | null | undefined;
              collection?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
        kyc?:
          | {
              __typename?: 'kycOutput';
              id: number;
              level: string;
              minDeposit: number;
              maxDeposit: number;
              feeDeposit: number;
              minWithdraw: number;
              maxWithdraw: number;
              feeWithdraw: number;
            }
          | null
          | undefined;
        agent?:
          | {
              __typename?: 'agentUserOutput';
              isAgent?: boolean | null | undefined;
              hasAgent?: boolean | null | undefined;
            }
          | null
          | undefined;
        staking?:
          | {
              __typename?: 'stakingOutput';
              rackbackId: number;
              status: string;
              inp: number;
              busd: number;
              nft: string;
            }
          | null
          | undefined;
        depositAddress?:
          | Array<
              | {
                  __typename?: 'depositAddressOutput';
                  blockchainId?: number | null | undefined;
                  address: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserLanguageQueryVariables = Exact<{ [key: string]: never }>;

export type UserLanguageQuery = {
  __typename?: 'query_root';
  me?:
    | { __typename?: 'UserOutput'; language?: string | null | undefined }
    | null
    | undefined;
};

export type UserAvatarQueryVariables = Exact<{ [key: string]: never }>;

export type UserAvatarQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        avatar?:
          | {
              __typename?: 'avatarOutput';
              id?: number | null | undefined;
              name?: string | null | undefined;
              collection?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserKycDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserKycDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        kycStatus?: string | null | undefined;
        kyc?:
          | { __typename?: 'kycOutput'; id: number; level: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserWithdrawPageFormKycDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserWithdrawPageFormKycDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        kycStatus?: string | null | undefined;
        kyc?:
          | {
              __typename?: 'kycOutput';
              id: number;
              level: string;
              minWithdrawInp: number;
              maxWithdrawInp: number;
              feeWithdrawInp: number;
              minWithdraw: number;
              maxWithdraw: number;
              feeWithdraw: number;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserAgentDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserAgentDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        agent?:
          | {
              __typename?: 'agentUserOutput';
              isAgent?: boolean | null | undefined;
              hasAgent?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserDepositFormDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserDepositFormDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        kyc?:
          | {
              __typename?: 'kycOutput';
              id: number;
              minDeposit: number;
              maxDeposit: number;
              feeDeposit: number;
              minDepositInp: number;
              maxDepositInp: number;
              feeDepositInp: number;
            }
          | null
          | undefined;
        agent?:
          | {
              __typename?: 'agentUserOutput';
              isAgent?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  agentGetUsers?:
    | {
        __typename?: 'agentGetUsersOutput';
        agentOf?:
          | Array<
              | {
                  __typename?: 'agentUserItemOutput';
                  id: number;
                  gPanelUserId: string;
                  username: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserDepositAddressesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserDepositAddressesQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        depositAddress?:
          | Array<
              | {
                  __typename?: 'depositAddressOutput';
                  blockchainId?: number | null | undefined;
                  address: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserWithdrawFormDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserWithdrawFormDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        kyc?:
          | {
              __typename?: 'kycOutput';
              id: number;
              minWithdraw: number;
              maxWithdraw: number;
              feeWithdraw: number;
              minWithdrawInp: number;
              maxWithdrawInp: number;
              feeWithdrawInp: number;
            }
          | null
          | undefined;
        agent?:
          | {
              __typename?: 'agentUserOutput';
              hasAgent?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  agentGetUsers?:
    | {
        __typename?: 'agentGetUsersOutput';
        agentFrom?:
          | Array<
              | {
                  __typename?: 'agentUserItemOutput';
                  id: number;
                  gPanelUserId: string;
                  username: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserStakingDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserStakingDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        staking?:
          | {
              __typename?: 'stakingOutput';
              rackbackId: number;
              status: string;
              inp: number;
              busd: number;
              nft: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateUserLanguageMutationVariables = Exact<{
  lang: Scalars['String'];
}>;

export type UpdateUserLanguageMutation = {
  __typename?: 'mutation_root';
  updateUsers?:
    | { __typename?: 'users_mutation_response'; affected_rows: number }
    | null
    | undefined;
};

export type RakebackDataFragment = {
  __typename?: 'rakebackLocks';
  id: number;
  label: string;
  level: number;
  percentage: number;
  percentageNft?: number | null | undefined;
  usd: number;
};

export type VaultTableDataQueryVariables = Exact<{ [key: string]: never }>;

export type VaultTableDataQuery = {
  __typename?: 'query_root';
  rakebackLocks: Array<{
    __typename?: 'rakebackLocks';
    id: number;
    label: string;
    level: number;
    percentage: number;
    percentageNft?: number | null | undefined;
    usd: number;
  }>;
};

export const BlockChainDataFragmentDoc = gql`
  fragment blockChainData on blockchain {
    id
    name
    chainId
    symbol
    rpcPublic
    scanUrl
    iconUrl
    nativeCurrency
    inpAddress
    marketplaceAddress
    inpVaultAddress
    nftCardAddress
    stablecoins {
      id
      contract
      symbol
      iconUrl
      depositActive
      withdrawActive
      units
    }
  }
`;
export const DirectDepositStablecoinDataFragmentDoc = gql`
  fragment directDepositStablecoinData on stablecoins {
    id
    blockchainId
    iconUrl
    symbol
  }
`;
export const WithdrawPageFormStablecoinDataFragmentDoc = gql`
  fragment withdrawPageFormStablecoinData on stablecoins {
    id
    blockchainId
    iconUrl
    symbol
  }
`;
export const DelegatedNftDataFragmentDoc = gql`
  fragment delegatedNFTData on NFTConnectedDetail {
    id
    status
    tokenId
    nftId
    collection
    owner
    username
    imageUrl
  }
`;
export const RakebackDataFragmentDoc = gql`
  fragment rakebackData on rakebackLocks {
    id
    label
    level
    percentage
    percentageNft
    usd
  }
`;
export const AgentUsersBalancesDocument = gql`
  query agentUsersBalances($limit: Int!, $offset: Int!) {
    updateAffiliatePlayerBalance {
      message
    }
    evenbetAffiliatePlayerBalances(limit: $limit, offset: $offset) {
      playerUsername
      cashBalanceUsd
      cashBalanceInp
    }
    evenbetAffiliatePlayerBalancesAggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

/**
 * __useAgentUsersBalancesQuery__
 *
 * To run a query within a React component, call `useAgentUsersBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentUsersBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentUsersBalancesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAgentUsersBalancesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AgentUsersBalancesQuery,
    AgentUsersBalancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AgentUsersBalancesQuery,
    AgentUsersBalancesQueryVariables
  >(AgentUsersBalancesDocument, options);
}
export function useAgentUsersBalancesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AgentUsersBalancesQuery,
    AgentUsersBalancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AgentUsersBalancesQuery,
    AgentUsersBalancesQueryVariables
  >(AgentUsersBalancesDocument, options);
}
export type AgentUsersBalancesQueryHookResult = ReturnType<
  typeof useAgentUsersBalancesQuery
>;
export type AgentUsersBalancesLazyQueryHookResult = ReturnType<
  typeof useAgentUsersBalancesLazyQuery
>;
export type AgentUsersBalancesQueryResult = ApolloReactCommon.QueryResult<
  AgentUsersBalancesQuery,
  AgentUsersBalancesQueryVariables
>;
export function refetchAgentUsersBalancesQuery(
  variables: AgentUsersBalancesQueryVariables
) {
  return { query: AgentUsersBalancesDocument, variables: variables };
}
export const NonceDocument = gql`
  query nonce($address: String!) {
    authSignature(address: $address) {
      nonce
    }
  }
`;

/**
 * __useNonceQuery__
 *
 * To run a query within a React component, call `useNonceQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonceQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useNonceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    NonceQuery,
    NonceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NonceQuery, NonceQueryVariables>(
    NonceDocument,
    options
  );
}
export function useNonceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NonceQuery,
    NonceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<NonceQuery, NonceQueryVariables>(
    NonceDocument,
    options
  );
}
export type NonceQueryHookResult = ReturnType<typeof useNonceQuery>;
export type NonceLazyQueryHookResult = ReturnType<typeof useNonceLazyQuery>;
export type NonceQueryResult = ApolloReactCommon.QueryResult<
  NonceQuery,
  NonceQueryVariables
>;
export function refetchNonceQuery(variables: NonceQueryVariables) {
  return { query: NonceDocument, variables: variables };
}
export const AccessTokenDocument = gql`
  query accessToken(
    $address: String
    $signature: String
    $gpanelAccessToken: String
  ) {
    authLogin(
      address: $address
      signature: $signature
      gpanelAccessToken: $gpanelAccessToken
    ) {
      user_id
      access_token
      role
    }
  }
`;

/**
 * __useAccessTokenQuery__
 *
 * To run a query within a React component, call `useAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessTokenQuery({
 *   variables: {
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *      gpanelAccessToken: // value for 'gpanelAccessToken'
 *   },
 * });
 */
export function useAccessTokenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AccessTokenQuery,
    AccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AccessTokenQuery, AccessTokenQueryVariables>(
    AccessTokenDocument,
    options
  );
}
export function useAccessTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AccessTokenQuery,
    AccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AccessTokenQuery,
    AccessTokenQueryVariables
  >(AccessTokenDocument, options);
}
export type AccessTokenQueryHookResult = ReturnType<typeof useAccessTokenQuery>;
export type AccessTokenLazyQueryHookResult = ReturnType<
  typeof useAccessTokenLazyQuery
>;
export type AccessTokenQueryResult = ApolloReactCommon.QueryResult<
  AccessTokenQuery,
  AccessTokenQueryVariables
>;
export function refetchAccessTokenQuery(variables?: AccessTokenQueryVariables) {
  return { query: AccessTokenDocument, variables: variables };
}
export const RefreshAccessTokenDocument = gql`
  query refreshAccessToken($accessToken: String, $gpanelAccessToken: String) {
    authRefresh(
      accessToken: $accessToken
      gpanelAccessToken: $gpanelAccessToken
    ) {
      user_id
      access_token
      eth_address
    }
  }
`;

/**
 * __useRefreshAccessTokenQuery__
 *
 * To run a query within a React component, call `useRefreshAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshAccessTokenQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      gpanelAccessToken: // value for 'gpanelAccessToken'
 *   },
 * });
 */
export function useRefreshAccessTokenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RefreshAccessTokenQuery,
    RefreshAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RefreshAccessTokenQuery,
    RefreshAccessTokenQueryVariables
  >(RefreshAccessTokenDocument, options);
}
export function useRefreshAccessTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RefreshAccessTokenQuery,
    RefreshAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RefreshAccessTokenQuery,
    RefreshAccessTokenQueryVariables
  >(RefreshAccessTokenDocument, options);
}
export type RefreshAccessTokenQueryHookResult = ReturnType<
  typeof useRefreshAccessTokenQuery
>;
export type RefreshAccessTokenLazyQueryHookResult = ReturnType<
  typeof useRefreshAccessTokenLazyQuery
>;
export type RefreshAccessTokenQueryResult = ApolloReactCommon.QueryResult<
  RefreshAccessTokenQuery,
  RefreshAccessTokenQueryVariables
>;
export function refetchRefreshAccessTokenQuery(
  variables?: RefreshAccessTokenQueryVariables
) {
  return { query: RefreshAccessTokenDocument, variables: variables };
}
export const GetGpanelTokensDocument = gql`
  query getGpanelTokens($uuid: String!) {
    getGpanelUUID(uuid: $uuid) {
      accessToken
      refreshToken
    }
  }
`;

/**
 * __useGetGpanelTokensQuery__
 *
 * To run a query within a React component, call `useGetGpanelTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGpanelTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGpanelTokensQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetGpanelTokensQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGpanelTokensQuery,
    GetGpanelTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGpanelTokensQuery,
    GetGpanelTokensQueryVariables
  >(GetGpanelTokensDocument, options);
}
export function useGetGpanelTokensLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGpanelTokensQuery,
    GetGpanelTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGpanelTokensQuery,
    GetGpanelTokensQueryVariables
  >(GetGpanelTokensDocument, options);
}
export type GetGpanelTokensQueryHookResult = ReturnType<
  typeof useGetGpanelTokensQuery
>;
export type GetGpanelTokensLazyQueryHookResult = ReturnType<
  typeof useGetGpanelTokensLazyQuery
>;
export type GetGpanelTokensQueryResult = ApolloReactCommon.QueryResult<
  GetGpanelTokensQuery,
  GetGpanelTokensQueryVariables
>;
export function refetchGetGpanelTokensQuery(
  variables: GetGpanelTokensQueryVariables
) {
  return { query: GetGpanelTokensDocument, variables: variables };
}
export const SumsubAccessTokenDocument = gql`
  query sumsubAccessToken($gpanelUserId: String!) {
    paymentSumsubToken(gpanelUserId: $gpanelUserId) {
      accessToken
    }
  }
`;

/**
 * __useSumsubAccessTokenQuery__
 *
 * To run a query within a React component, call `useSumsubAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSumsubAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSumsubAccessTokenQuery({
 *   variables: {
 *      gpanelUserId: // value for 'gpanelUserId'
 *   },
 * });
 */
export function useSumsubAccessTokenQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SumsubAccessTokenQuery,
    SumsubAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SumsubAccessTokenQuery,
    SumsubAccessTokenQueryVariables
  >(SumsubAccessTokenDocument, options);
}
export function useSumsubAccessTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SumsubAccessTokenQuery,
    SumsubAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SumsubAccessTokenQuery,
    SumsubAccessTokenQueryVariables
  >(SumsubAccessTokenDocument, options);
}
export type SumsubAccessTokenQueryHookResult = ReturnType<
  typeof useSumsubAccessTokenQuery
>;
export type SumsubAccessTokenLazyQueryHookResult = ReturnType<
  typeof useSumsubAccessTokenLazyQuery
>;
export type SumsubAccessTokenQueryResult = ApolloReactCommon.QueryResult<
  SumsubAccessTokenQuery,
  SumsubAccessTokenQueryVariables
>;
export function refetchSumsubAccessTokenQuery(
  variables: SumsubAccessTokenQueryVariables
) {
  return { query: SumsubAccessTokenDocument, variables: variables };
}
export const SetGpanelUuidDocument = gql`
  mutation setGpanelUUID($uuid: String!) {
    setGpanelUUID(uuid: $uuid) {
      message
    }
  }
`;
export type SetGpanelUuidMutationFn = ApolloReactCommon.MutationFunction<
  SetGpanelUuidMutation,
  SetGpanelUuidMutationVariables
>;

/**
 * __useSetGpanelUuidMutation__
 *
 * To run a mutation, you first call `useSetGpanelUuidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGpanelUuidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGpanelUuidMutation, { data, loading, error }] = useSetGpanelUuidMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSetGpanelUuidMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetGpanelUuidMutation,
    SetGpanelUuidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetGpanelUuidMutation,
    SetGpanelUuidMutationVariables
  >(SetGpanelUuidDocument, options);
}
export type SetGpanelUuidMutationHookResult = ReturnType<
  typeof useSetGpanelUuidMutation
>;
export type SetGpanelUuidMutationResult =
  ApolloReactCommon.MutationResult<SetGpanelUuidMutation>;
export type SetGpanelUuidMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SetGpanelUuidMutation,
    SetGpanelUuidMutationVariables
  >;
export const RefreshGpanelAccessTokenDocument = gql`
  mutation refreshGpanelAccessToken($refreshToken: String!) {
    refreshGpanel(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
export type RefreshGpanelAccessTokenMutationFn =
  ApolloReactCommon.MutationFunction<
    RefreshGpanelAccessTokenMutation,
    RefreshGpanelAccessTokenMutationVariables
  >;

/**
 * __useRefreshGpanelAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshGpanelAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshGpanelAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshGpanelAccessTokenMutation, { data, loading, error }] = useRefreshGpanelAccessTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshGpanelAccessTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RefreshGpanelAccessTokenMutation,
    RefreshGpanelAccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RefreshGpanelAccessTokenMutation,
    RefreshGpanelAccessTokenMutationVariables
  >(RefreshGpanelAccessTokenDocument, options);
}
export type RefreshGpanelAccessTokenMutationHookResult = ReturnType<
  typeof useRefreshGpanelAccessTokenMutation
>;
export type RefreshGpanelAccessTokenMutationResult =
  ApolloReactCommon.MutationResult<RefreshGpanelAccessTokenMutation>;
export type RefreshGpanelAccessTokenMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RefreshGpanelAccessTokenMutation,
    RefreshGpanelAccessTokenMutationVariables
  >;
export const BlockchainsDocument = gql`
  query blockchains {
    blockchains(where: { webActive: { _eq: true } }) {
      ...blockChainData
    }
  }
  ${BlockChainDataFragmentDoc}
`;

/**
 * __useBlockchainsQuery__
 *
 * To run a query within a React component, call `useBlockchainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockchainsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BlockchainsQuery,
    BlockchainsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BlockchainsQuery, BlockchainsQueryVariables>(
    BlockchainsDocument,
    options
  );
}
export function useBlockchainsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BlockchainsQuery,
    BlockchainsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BlockchainsQuery,
    BlockchainsQueryVariables
  >(BlockchainsDocument, options);
}
export type BlockchainsQueryHookResult = ReturnType<typeof useBlockchainsQuery>;
export type BlockchainsLazyQueryHookResult = ReturnType<
  typeof useBlockchainsLazyQuery
>;
export type BlockchainsQueryResult = ApolloReactCommon.QueryResult<
  BlockchainsQuery,
  BlockchainsQueryVariables
>;
export function refetchBlockchainsQuery(variables?: BlockchainsQueryVariables) {
  return { query: BlockchainsDocument, variables: variables };
}
export const DirectDepositDataDocument = gql`
  query directDepositData {
    me {
      id
      depositAddress {
        blockchainId
        address
      }
    }
    stablecoins(where: { depositActive: { _eq: true } }) {
      ...directDepositStablecoinData
    }
    blockchains {
      id
      chainId
      name
      symbol
      iconUrl
      stablecoins {
        ...directDepositStablecoinData
      }
    }
  }
  ${DirectDepositStablecoinDataFragmentDoc}
`;

/**
 * __useDirectDepositDataQuery__
 *
 * To run a query within a React component, call `useDirectDepositDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectDepositDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectDepositDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDirectDepositDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DirectDepositDataQuery,
    DirectDepositDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DirectDepositDataQuery,
    DirectDepositDataQueryVariables
  >(DirectDepositDataDocument, options);
}
export function useDirectDepositDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DirectDepositDataQuery,
    DirectDepositDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DirectDepositDataQuery,
    DirectDepositDataQueryVariables
  >(DirectDepositDataDocument, options);
}
export type DirectDepositDataQueryHookResult = ReturnType<
  typeof useDirectDepositDataQuery
>;
export type DirectDepositDataLazyQueryHookResult = ReturnType<
  typeof useDirectDepositDataLazyQuery
>;
export type DirectDepositDataQueryResult = ApolloReactCommon.QueryResult<
  DirectDepositDataQuery,
  DirectDepositDataQueryVariables
>;
export function refetchDirectDepositDataQuery(
  variables?: DirectDepositDataQueryVariables
) {
  return { query: DirectDepositDataDocument, variables: variables };
}
export const DepositAddressDocument = gql`
  query depositAddress($blockchainId: Int!) {
    paymentGetDepositAddress(blockchainId: $blockchainId) {
      address
    }
  }
`;

/**
 * __useDepositAddressQuery__
 *
 * To run a query within a React component, call `useDepositAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositAddressQuery({
 *   variables: {
 *      blockchainId: // value for 'blockchainId'
 *   },
 * });
 */
export function useDepositAddressQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DepositAddressQuery,
    DepositAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DepositAddressQuery,
    DepositAddressQueryVariables
  >(DepositAddressDocument, options);
}
export function useDepositAddressLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DepositAddressQuery,
    DepositAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DepositAddressQuery,
    DepositAddressQueryVariables
  >(DepositAddressDocument, options);
}
export type DepositAddressQueryHookResult = ReturnType<
  typeof useDepositAddressQuery
>;
export type DepositAddressLazyQueryHookResult = ReturnType<
  typeof useDepositAddressLazyQuery
>;
export type DepositAddressQueryResult = ApolloReactCommon.QueryResult<
  DepositAddressQuery,
  DepositAddressQueryVariables
>;
export function refetchDepositAddressQuery(
  variables: DepositAddressQueryVariables
) {
  return { query: DepositAddressDocument, variables: variables };
}
export const BuyCryptoDataDocument = gql`
  query buyCryptoData {
    me {
      id
      depositAddress {
        blockchainId
        address
      }
    }
    stablecoins {
      id
      symbol
    }
  }
`;

/**
 * __useBuyCryptoDataQuery__
 *
 * To run a query within a React component, call `useBuyCryptoDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyCryptoDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyCryptoDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useBuyCryptoDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BuyCryptoDataQuery,
    BuyCryptoDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BuyCryptoDataQuery,
    BuyCryptoDataQueryVariables
  >(BuyCryptoDataDocument, options);
}
export function useBuyCryptoDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BuyCryptoDataQuery,
    BuyCryptoDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BuyCryptoDataQuery,
    BuyCryptoDataQueryVariables
  >(BuyCryptoDataDocument, options);
}
export type BuyCryptoDataQueryHookResult = ReturnType<
  typeof useBuyCryptoDataQuery
>;
export type BuyCryptoDataLazyQueryHookResult = ReturnType<
  typeof useBuyCryptoDataLazyQuery
>;
export type BuyCryptoDataQueryResult = ApolloReactCommon.QueryResult<
  BuyCryptoDataQuery,
  BuyCryptoDataQueryVariables
>;
export function refetchBuyCryptoDataQuery(
  variables?: BuyCryptoDataQueryVariables
) {
  return { query: BuyCryptoDataDocument, variables: variables };
}
export const LastDepositTransactionDocument = gql`
  query lastDepositTransaction {
    gpanelPayments(
      where: { identity: { _eq: "DEPOSIT" } }
      order_by: { date: desc }
      limit: 1
    ) {
      id
      date
      amount
      currency
    }
    pendingTransactions(
      where: { type: { _eq: "deposit" } }
      order_by: { createdAt: desc }
      limit: 1
    ) {
      id
      createdAt
      amount
      currency
    }
  }
`;

/**
 * __useLastDepositTransactionQuery__
 *
 * To run a query within a React component, call `useLastDepositTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastDepositTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastDepositTransactionQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastDepositTransactionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LastDepositTransactionQuery,
    LastDepositTransactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LastDepositTransactionQuery,
    LastDepositTransactionQueryVariables
  >(LastDepositTransactionDocument, options);
}
export function useLastDepositTransactionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LastDepositTransactionQuery,
    LastDepositTransactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LastDepositTransactionQuery,
    LastDepositTransactionQueryVariables
  >(LastDepositTransactionDocument, options);
}
export type LastDepositTransactionQueryHookResult = ReturnType<
  typeof useLastDepositTransactionQuery
>;
export type LastDepositTransactionLazyQueryHookResult = ReturnType<
  typeof useLastDepositTransactionLazyQuery
>;
export type LastDepositTransactionQueryResult = ApolloReactCommon.QueryResult<
  LastDepositTransactionQuery,
  LastDepositTransactionQueryVariables
>;
export function refetchLastDepositTransactionQuery(
  variables?: LastDepositTransactionQueryVariables
) {
  return { query: LastDepositTransactionDocument, variables: variables };
}
export const LastWithdrawTransactionDocument = gql`
  query lastWithdrawTransaction {
    gpanelPayments(
      where: { identity: { _eq: "WITHDRAWAL" } }
      order_by: { date: desc }
      limit: 1
    ) {
      date
      amount
      currency
    }
    pendingTransactions(
      where: { type: { _eq: "withdraw" } }
      order_by: { createdAt: desc }
      limit: 1
    ) {
      createdAt
      amount
      currency
    }
  }
`;

/**
 * __useLastWithdrawTransactionQuery__
 *
 * To run a query within a React component, call `useLastWithdrawTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastWithdrawTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastWithdrawTransactionQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastWithdrawTransactionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LastWithdrawTransactionQuery,
    LastWithdrawTransactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LastWithdrawTransactionQuery,
    LastWithdrawTransactionQueryVariables
  >(LastWithdrawTransactionDocument, options);
}
export function useLastWithdrawTransactionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LastWithdrawTransactionQuery,
    LastWithdrawTransactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LastWithdrawTransactionQuery,
    LastWithdrawTransactionQueryVariables
  >(LastWithdrawTransactionDocument, options);
}
export type LastWithdrawTransactionQueryHookResult = ReturnType<
  typeof useLastWithdrawTransactionQuery
>;
export type LastWithdrawTransactionLazyQueryHookResult = ReturnType<
  typeof useLastWithdrawTransactionLazyQuery
>;
export type LastWithdrawTransactionQueryResult = ApolloReactCommon.QueryResult<
  LastWithdrawTransactionQuery,
  LastWithdrawTransactionQueryVariables
>;
export function refetchLastWithdrawTransactionQuery(
  variables?: LastWithdrawTransactionQueryVariables
) {
  return { query: LastWithdrawTransactionDocument, variables: variables };
}
export const GpanelPendingTransactionsDocument = gql`
  query gpanelPendingTransactions {
    pendingTransactions(order_by: { createdAt: desc }) {
      id
      createdAt
      type
      currency
      amount
      status
      txid
      blockchain {
        id
        scanUrl
      }
      username
    }
  }
`;

/**
 * __useGpanelPendingTransactionsQuery__
 *
 * To run a query within a React component, call `useGpanelPendingTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGpanelPendingTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGpanelPendingTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGpanelPendingTransactionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GpanelPendingTransactionsQuery,
    GpanelPendingTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GpanelPendingTransactionsQuery,
    GpanelPendingTransactionsQueryVariables
  >(GpanelPendingTransactionsDocument, options);
}
export function useGpanelPendingTransactionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GpanelPendingTransactionsQuery,
    GpanelPendingTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GpanelPendingTransactionsQuery,
    GpanelPendingTransactionsQueryVariables
  >(GpanelPendingTransactionsDocument, options);
}
export type GpanelPendingTransactionsQueryHookResult = ReturnType<
  typeof useGpanelPendingTransactionsQuery
>;
export type GpanelPendingTransactionsLazyQueryHookResult = ReturnType<
  typeof useGpanelPendingTransactionsLazyQuery
>;
export type GpanelPendingTransactionsQueryResult =
  ApolloReactCommon.QueryResult<
    GpanelPendingTransactionsQuery,
    GpanelPendingTransactionsQueryVariables
  >;
export function refetchGpanelPendingTransactionsQuery(
  variables?: GpanelPendingTransactionsQueryVariables
) {
  return { query: GpanelPendingTransactionsDocument, variables: variables };
}
export const GpanelPaymentsDocument = gql`
  query gpanelPayments($limit: Int!, $offset: Int!) {
    gpanelPaymentsAggregate {
      aggregate {
        totalCount: count
      }
    }
    gpanelPayments(order_by: { date: desc }, limit: $limit, offset: $offset) {
      id
      gpanelId
      date
      method
      status
      identity
      currency
      amount
    }
  }
`;

/**
 * __useGpanelPaymentsQuery__
 *
 * To run a query within a React component, call `useGpanelPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGpanelPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGpanelPaymentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGpanelPaymentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GpanelPaymentsQuery,
    GpanelPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GpanelPaymentsQuery,
    GpanelPaymentsQueryVariables
  >(GpanelPaymentsDocument, options);
}
export function useGpanelPaymentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GpanelPaymentsQuery,
    GpanelPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GpanelPaymentsQuery,
    GpanelPaymentsQueryVariables
  >(GpanelPaymentsDocument, options);
}
export type GpanelPaymentsQueryHookResult = ReturnType<
  typeof useGpanelPaymentsQuery
>;
export type GpanelPaymentsLazyQueryHookResult = ReturnType<
  typeof useGpanelPaymentsLazyQuery
>;
export type GpanelPaymentsQueryResult = ApolloReactCommon.QueryResult<
  GpanelPaymentsQuery,
  GpanelPaymentsQueryVariables
>;
export function refetchGpanelPaymentsQuery(
  variables: GpanelPaymentsQueryVariables
) {
  return { query: GpanelPaymentsDocument, variables: variables };
}
export const AgentFindUserDocument = gql`
  query agentFindUser($name: String!) {
    agentFindUser(name: $name) {
      gPanelUserId
      username
    }
  }
`;

/**
 * __useAgentFindUserQuery__
 *
 * To run a query within a React component, call `useAgentFindUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentFindUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentFindUserQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAgentFindUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AgentFindUserQuery,
    AgentFindUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AgentFindUserQuery,
    AgentFindUserQueryVariables
  >(AgentFindUserDocument, options);
}
export function useAgentFindUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AgentFindUserQuery,
    AgentFindUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AgentFindUserQuery,
    AgentFindUserQueryVariables
  >(AgentFindUserDocument, options);
}
export type AgentFindUserQueryHookResult = ReturnType<
  typeof useAgentFindUserQuery
>;
export type AgentFindUserLazyQueryHookResult = ReturnType<
  typeof useAgentFindUserLazyQuery
>;
export type AgentFindUserQueryResult = ApolloReactCommon.QueryResult<
  AgentFindUserQuery,
  AgentFindUserQueryVariables
>;
export function refetchAgentFindUserQuery(
  variables: AgentFindUserQueryVariables
) {
  return { query: AgentFindUserDocument, variables: variables };
}
export const PaymentSubmitDepositDocument = gql`
  mutation paymentSubmitDeposit(
    $blockchainId: Int!
    $account: String!
    $gpanelUserId: String!
    $stablecoinId: Int
    $currency: String!
    $amount: Float!
  ) {
    paymentSubmitDeposit(
      blockchainId: $blockchainId
      address: $account
      gpanelUserId: $gpanelUserId
      stablecoinId: $stablecoinId
      currency: $currency
      amount: $amount
    ) {
      id
      status
      depositAddress
    }
  }
`;
export type PaymentSubmitDepositMutationFn = ApolloReactCommon.MutationFunction<
  PaymentSubmitDepositMutation,
  PaymentSubmitDepositMutationVariables
>;

/**
 * __usePaymentSubmitDepositMutation__
 *
 * To run a mutation, you first call `usePaymentSubmitDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentSubmitDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentSubmitDepositMutation, { data, loading, error }] = usePaymentSubmitDepositMutation({
 *   variables: {
 *      blockchainId: // value for 'blockchainId'
 *      account: // value for 'account'
 *      gpanelUserId: // value for 'gpanelUserId'
 *      stablecoinId: // value for 'stablecoinId'
 *      currency: // value for 'currency'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function usePaymentSubmitDepositMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PaymentSubmitDepositMutation,
    PaymentSubmitDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PaymentSubmitDepositMutation,
    PaymentSubmitDepositMutationVariables
  >(PaymentSubmitDepositDocument, options);
}
export type PaymentSubmitDepositMutationHookResult = ReturnType<
  typeof usePaymentSubmitDepositMutation
>;
export type PaymentSubmitDepositMutationResult =
  ApolloReactCommon.MutationResult<PaymentSubmitDepositMutation>;
export type PaymentSubmitDepositMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PaymentSubmitDepositMutation,
    PaymentSubmitDepositMutationVariables
  >;
export const PaymentSubmittedDepositDocument = gql`
  mutation paymentSubmittedDeposit($id: String!, $tx: String!) {
    paymentSubmittedDeposit(id: $id, tx: $tx) {
      id
      status
    }
  }
`;
export type PaymentSubmittedDepositMutationFn =
  ApolloReactCommon.MutationFunction<
    PaymentSubmittedDepositMutation,
    PaymentSubmittedDepositMutationVariables
  >;

/**
 * __usePaymentSubmittedDepositMutation__
 *
 * To run a mutation, you first call `usePaymentSubmittedDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentSubmittedDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentSubmittedDepositMutation, { data, loading, error }] = usePaymentSubmittedDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tx: // value for 'tx'
 *   },
 * });
 */
export function usePaymentSubmittedDepositMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PaymentSubmittedDepositMutation,
    PaymentSubmittedDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PaymentSubmittedDepositMutation,
    PaymentSubmittedDepositMutationVariables
  >(PaymentSubmittedDepositDocument, options);
}
export type PaymentSubmittedDepositMutationHookResult = ReturnType<
  typeof usePaymentSubmittedDepositMutation
>;
export type PaymentSubmittedDepositMutationResult =
  ApolloReactCommon.MutationResult<PaymentSubmittedDepositMutation>;
export type PaymentSubmittedDepositMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PaymentSubmittedDepositMutation,
    PaymentSubmittedDepositMutationVariables
  >;
export const AgentPaymentSubmitDepositDocument = gql`
  mutation agentPaymentSubmitDeposit(
    $blockchainId: Int!
    $account: String!
    $gpanelUserId: String!
    $stablecoinId: Int
    $currency: String!
    $amount: Float!
  ) {
    agentPaymentSubmitDeposit(
      blockchainId: $blockchainId
      address: $account
      gpanelUserId: $gpanelUserId
      stablecoinId: $stablecoinId
      currency: $currency
      amount: $amount
    ) {
      id
      status
      depositAddress
    }
  }
`;
export type AgentPaymentSubmitDepositMutationFn =
  ApolloReactCommon.MutationFunction<
    AgentPaymentSubmitDepositMutation,
    AgentPaymentSubmitDepositMutationVariables
  >;

/**
 * __useAgentPaymentSubmitDepositMutation__
 *
 * To run a mutation, you first call `useAgentPaymentSubmitDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgentPaymentSubmitDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agentPaymentSubmitDepositMutation, { data, loading, error }] = useAgentPaymentSubmitDepositMutation({
 *   variables: {
 *      blockchainId: // value for 'blockchainId'
 *      account: // value for 'account'
 *      gpanelUserId: // value for 'gpanelUserId'
 *      stablecoinId: // value for 'stablecoinId'
 *      currency: // value for 'currency'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAgentPaymentSubmitDepositMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AgentPaymentSubmitDepositMutation,
    AgentPaymentSubmitDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AgentPaymentSubmitDepositMutation,
    AgentPaymentSubmitDepositMutationVariables
  >(AgentPaymentSubmitDepositDocument, options);
}
export type AgentPaymentSubmitDepositMutationHookResult = ReturnType<
  typeof useAgentPaymentSubmitDepositMutation
>;
export type AgentPaymentSubmitDepositMutationResult =
  ApolloReactCommon.MutationResult<AgentPaymentSubmitDepositMutation>;
export type AgentPaymentSubmitDepositMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AgentPaymentSubmitDepositMutation,
    AgentPaymentSubmitDepositMutationVariables
  >;
export const AgentPaymentSubmittedDepositDocument = gql`
  mutation agentPaymentSubmittedDeposit($id: String!, $tx: String!) {
    agentPaymentSubmittedDeposit(id: $id, tx: $tx) {
      id
      status
    }
  }
`;
export type AgentPaymentSubmittedDepositMutationFn =
  ApolloReactCommon.MutationFunction<
    AgentPaymentSubmittedDepositMutation,
    AgentPaymentSubmittedDepositMutationVariables
  >;

/**
 * __useAgentPaymentSubmittedDepositMutation__
 *
 * To run a mutation, you first call `useAgentPaymentSubmittedDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgentPaymentSubmittedDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agentPaymentSubmittedDepositMutation, { data, loading, error }] = useAgentPaymentSubmittedDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tx: // value for 'tx'
 *   },
 * });
 */
export function useAgentPaymentSubmittedDepositMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AgentPaymentSubmittedDepositMutation,
    AgentPaymentSubmittedDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AgentPaymentSubmittedDepositMutation,
    AgentPaymentSubmittedDepositMutationVariables
  >(AgentPaymentSubmittedDepositDocument, options);
}
export type AgentPaymentSubmittedDepositMutationHookResult = ReturnType<
  typeof useAgentPaymentSubmittedDepositMutation
>;
export type AgentPaymentSubmittedDepositMutationResult =
  ApolloReactCommon.MutationResult<AgentPaymentSubmittedDepositMutation>;
export type AgentPaymentSubmittedDepositMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AgentPaymentSubmittedDepositMutation,
    AgentPaymentSubmittedDepositMutationVariables
  >;
export const PaymentSubmitWithdrawDocument = gql`
  mutation paymentSubmitWithdraw(
    $account: String!
    $gpanelUserId: String!
    $gpanelAccessToken: String!
    $blockchainId: Int
    $stablecoinId: Int
    $currency: String
    $amount: Float!
  ) {
    paymentSubmitWithdraw(
      address: $account
      gpanelUserId: $gpanelUserId
      gpanelAccessToken: $gpanelAccessToken
      blockchainId: $blockchainId
      stablecoinId: $stablecoinId
      currency: $currency
      amount: $amount
    ) {
      status
    }
  }
`;
export type PaymentSubmitWithdrawMutationFn =
  ApolloReactCommon.MutationFunction<
    PaymentSubmitWithdrawMutation,
    PaymentSubmitWithdrawMutationVariables
  >;

/**
 * __usePaymentSubmitWithdrawMutation__
 *
 * To run a mutation, you first call `usePaymentSubmitWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentSubmitWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentSubmitWithdrawMutation, { data, loading, error }] = usePaymentSubmitWithdrawMutation({
 *   variables: {
 *      account: // value for 'account'
 *      gpanelUserId: // value for 'gpanelUserId'
 *      gpanelAccessToken: // value for 'gpanelAccessToken'
 *      blockchainId: // value for 'blockchainId'
 *      stablecoinId: // value for 'stablecoinId'
 *      currency: // value for 'currency'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function usePaymentSubmitWithdrawMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PaymentSubmitWithdrawMutation,
    PaymentSubmitWithdrawMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PaymentSubmitWithdrawMutation,
    PaymentSubmitWithdrawMutationVariables
  >(PaymentSubmitWithdrawDocument, options);
}
export type PaymentSubmitWithdrawMutationHookResult = ReturnType<
  typeof usePaymentSubmitWithdrawMutation
>;
export type PaymentSubmitWithdrawMutationResult =
  ApolloReactCommon.MutationResult<PaymentSubmitWithdrawMutation>;
export type PaymentSubmitWithdrawMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PaymentSubmitWithdrawMutation,
    PaymentSubmitWithdrawMutationVariables
  >;
export const AgentPaymentSubmitWithdrawDocument = gql`
  mutation agentPaymentSubmitWithdraw(
    $gpanelUserId: String!
    $gpanelAccessToken: String!
    $agentGpanelUserId: String!
    $blockchainId: Int
    $stablecoinId: Int
    $currency: String
    $amount: Float!
  ) {
    agentPaymentSubmitWithdraw(
      gpanelUserId: $gpanelUserId
      gpanelAccessToken: $gpanelAccessToken
      agentGpanelUserId: $agentGpanelUserId
      blockchainId: $blockchainId
      stablecoinId: $stablecoinId
      currency: $currency
      amount: $amount
    ) {
      status
    }
  }
`;
export type AgentPaymentSubmitWithdrawMutationFn =
  ApolloReactCommon.MutationFunction<
    AgentPaymentSubmitWithdrawMutation,
    AgentPaymentSubmitWithdrawMutationVariables
  >;

/**
 * __useAgentPaymentSubmitWithdrawMutation__
 *
 * To run a mutation, you first call `useAgentPaymentSubmitWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgentPaymentSubmitWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agentPaymentSubmitWithdrawMutation, { data, loading, error }] = useAgentPaymentSubmitWithdrawMutation({
 *   variables: {
 *      gpanelUserId: // value for 'gpanelUserId'
 *      gpanelAccessToken: // value for 'gpanelAccessToken'
 *      agentGpanelUserId: // value for 'agentGpanelUserId'
 *      blockchainId: // value for 'blockchainId'
 *      stablecoinId: // value for 'stablecoinId'
 *      currency: // value for 'currency'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAgentPaymentSubmitWithdrawMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AgentPaymentSubmitWithdrawMutation,
    AgentPaymentSubmitWithdrawMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AgentPaymentSubmitWithdrawMutation,
    AgentPaymentSubmitWithdrawMutationVariables
  >(AgentPaymentSubmitWithdrawDocument, options);
}
export type AgentPaymentSubmitWithdrawMutationHookResult = ReturnType<
  typeof useAgentPaymentSubmitWithdrawMutation
>;
export type AgentPaymentSubmitWithdrawMutationResult =
  ApolloReactCommon.MutationResult<AgentPaymentSubmitWithdrawMutation>;
export type AgentPaymentSubmitWithdrawMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AgentPaymentSubmitWithdrawMutation,
    AgentPaymentSubmitWithdrawMutationVariables
  >;
export const ClaimDataDocument = gql`
  query claimData {
    NFTAvailableTickets {
      totalNFTs
      nextReleaseTime
      claimOptions {
        id
        amount
        label
      }
    }
  }
`;

/**
 * __useClaimDataQuery__
 *
 * To run a query within a React component, call `useClaimDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useClaimDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClaimDataQuery,
    ClaimDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClaimDataQuery, ClaimDataQueryVariables>(
    ClaimDataDocument,
    options
  );
}
export function useClaimDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClaimDataQuery,
    ClaimDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClaimDataQuery, ClaimDataQueryVariables>(
    ClaimDataDocument,
    options
  );
}
export type ClaimDataQueryHookResult = ReturnType<typeof useClaimDataQuery>;
export type ClaimDataLazyQueryHookResult = ReturnType<
  typeof useClaimDataLazyQuery
>;
export type ClaimDataQueryResult = ApolloReactCommon.QueryResult<
  ClaimDataQuery,
  ClaimDataQueryVariables
>;
export function refetchClaimDataQuery(variables?: ClaimDataQueryVariables) {
  return { query: ClaimDataDocument, variables: variables };
}
export const ClaimDocument = gql`
  mutation claim($ticketId: Int!, $amount: Int) {
    NFTReleaseTicket(ticketId: $ticketId, amount: $amount) {
      message
    }
  }
`;
export type ClaimMutationFn = ApolloReactCommon.MutationFunction<
  ClaimMutation,
  ClaimMutationVariables
>;

/**
 * __useClaimMutation__
 *
 * To run a mutation, you first call `useClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimMutation, { data, loading, error }] = useClaimMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useClaimMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClaimMutation,
    ClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ClaimMutation, ClaimMutationVariables>(
    ClaimDocument,
    options
  );
}
export type ClaimMutationHookResult = ReturnType<typeof useClaimMutation>;
export type ClaimMutationResult =
  ApolloReactCommon.MutationResult<ClaimMutation>;
export type ClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClaimMutation,
  ClaimMutationVariables
>;
export const WithdrawPageFormDataDocument = gql`
  query withdrawPageFormData {
    me {
      id
      kycStatus
      kyc {
        id
        level
        minWithdrawInp
        maxWithdrawInp
        feeWithdrawInp
        minWithdraw
        maxWithdraw
        feeWithdraw
      }
    }
    stablecoins(where: { withdrawActive: { _eq: true } }) {
      ...withdrawPageFormStablecoinData
    }
    blockchains {
      id
      chainId
      iconUrl
      symbol
      name
      stablecoins(where: { withdrawActive: { _eq: true } }) {
        ...withdrawPageFormStablecoinData
      }
    }
  }
  ${WithdrawPageFormStablecoinDataFragmentDoc}
`;

/**
 * __useWithdrawPageFormDataQuery__
 *
 * To run a query within a React component, call `useWithdrawPageFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawPageFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawPageFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useWithdrawPageFormDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WithdrawPageFormDataQuery,
    WithdrawPageFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    WithdrawPageFormDataQuery,
    WithdrawPageFormDataQueryVariables
  >(WithdrawPageFormDataDocument, options);
}
export function useWithdrawPageFormDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WithdrawPageFormDataQuery,
    WithdrawPageFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    WithdrawPageFormDataQuery,
    WithdrawPageFormDataQueryVariables
  >(WithdrawPageFormDataDocument, options);
}
export type WithdrawPageFormDataQueryHookResult = ReturnType<
  typeof useWithdrawPageFormDataQuery
>;
export type WithdrawPageFormDataLazyQueryHookResult = ReturnType<
  typeof useWithdrawPageFormDataLazyQuery
>;
export type WithdrawPageFormDataQueryResult = ApolloReactCommon.QueryResult<
  WithdrawPageFormDataQuery,
  WithdrawPageFormDataQueryVariables
>;
export function refetchWithdrawPageFormDataQuery(
  variables?: WithdrawPageFormDataQueryVariables
) {
  return { query: WithdrawPageFormDataDocument, variables: variables };
}
export const WithdrawPageWithdrawRequestDocument = gql`
  mutation withdrawPageWithdrawRequest(
    $gpanelUserId: String!
    $gpanelAccessToken: String!
    $currency: String
    $stablecoinId: Int
    $blockchainId: Int
    $address: String!
    $amount: Float!
  ) {
    paymentSubmitWithdraw2FA1(
      gpanelUserId: $gpanelUserId
      gpanelAccessToken: $gpanelAccessToken
      currency: $currency
      stablecoinId: $stablecoinId
      blockchainId: $blockchainId
      address: $address
      amount: $amount
    ) {
      id
      status
    }
  }
`;
export type WithdrawPageWithdrawRequestMutationFn =
  ApolloReactCommon.MutationFunction<
    WithdrawPageWithdrawRequestMutation,
    WithdrawPageWithdrawRequestMutationVariables
  >;

/**
 * __useWithdrawPageWithdrawRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawPageWithdrawRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawPageWithdrawRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawPageWithdrawRequestMutation, { data, loading, error }] = useWithdrawPageWithdrawRequestMutation({
 *   variables: {
 *      gpanelUserId: // value for 'gpanelUserId'
 *      gpanelAccessToken: // value for 'gpanelAccessToken'
 *      currency: // value for 'currency'
 *      stablecoinId: // value for 'stablecoinId'
 *      blockchainId: // value for 'blockchainId'
 *      address: // value for 'address'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useWithdrawPageWithdrawRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WithdrawPageWithdrawRequestMutation,
    WithdrawPageWithdrawRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    WithdrawPageWithdrawRequestMutation,
    WithdrawPageWithdrawRequestMutationVariables
  >(WithdrawPageWithdrawRequestDocument, options);
}
export type WithdrawPageWithdrawRequestMutationHookResult = ReturnType<
  typeof useWithdrawPageWithdrawRequestMutation
>;
export type WithdrawPageWithdrawRequestMutationResult =
  ApolloReactCommon.MutationResult<WithdrawPageWithdrawRequestMutation>;
export type WithdrawPageWithdrawRequestMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    WithdrawPageWithdrawRequestMutation,
    WithdrawPageWithdrawRequestMutationVariables
  >;
export const WithdrawPageWithdrawCodeConfirmDocument = gql`
  mutation withdrawPageWithdrawCodeConfirm($id: String!, $code: String!) {
    paymentSubmitWithdraw2FA2(id: $id, code: $code) {
      status
    }
  }
`;
export type WithdrawPageWithdrawCodeConfirmMutationFn =
  ApolloReactCommon.MutationFunction<
    WithdrawPageWithdrawCodeConfirmMutation,
    WithdrawPageWithdrawCodeConfirmMutationVariables
  >;

/**
 * __useWithdrawPageWithdrawCodeConfirmMutation__
 *
 * To run a mutation, you first call `useWithdrawPageWithdrawCodeConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawPageWithdrawCodeConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawPageWithdrawCodeConfirmMutation, { data, loading, error }] = useWithdrawPageWithdrawCodeConfirmMutation({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useWithdrawPageWithdrawCodeConfirmMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WithdrawPageWithdrawCodeConfirmMutation,
    WithdrawPageWithdrawCodeConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    WithdrawPageWithdrawCodeConfirmMutation,
    WithdrawPageWithdrawCodeConfirmMutationVariables
  >(WithdrawPageWithdrawCodeConfirmDocument, options);
}
export type WithdrawPageWithdrawCodeConfirmMutationHookResult = ReturnType<
  typeof useWithdrawPageWithdrawCodeConfirmMutation
>;
export type WithdrawPageWithdrawCodeConfirmMutationResult =
  ApolloReactCommon.MutationResult<WithdrawPageWithdrawCodeConfirmMutation>;
export type WithdrawPageWithdrawCodeConfirmMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    WithdrawPageWithdrawCodeConfirmMutation,
    WithdrawPageWithdrawCodeConfirmMutationVariables
  >;
export const CommonInitialDataDocument = gql`
  query commonInitialData {
    rakebackLocks {
      ...rakebackData
    }
    stablecoins {
      id
      blockchainId
      iconUrl
      symbol
    }
    blockchains(where: { webActive: { _eq: true } }) {
      ...blockChainData
    }
  }
  ${RakebackDataFragmentDoc}
  ${BlockChainDataFragmentDoc}
`;

/**
 * __useCommonInitialDataQuery__
 *
 * To run a query within a React component, call `useCommonInitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonInitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonInitialDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommonInitialDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CommonInitialDataQuery,
    CommonInitialDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CommonInitialDataQuery,
    CommonInitialDataQueryVariables
  >(CommonInitialDataDocument, options);
}
export function useCommonInitialDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CommonInitialDataQuery,
    CommonInitialDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CommonInitialDataQuery,
    CommonInitialDataQueryVariables
  >(CommonInitialDataDocument, options);
}
export type CommonInitialDataQueryHookResult = ReturnType<
  typeof useCommonInitialDataQuery
>;
export type CommonInitialDataLazyQueryHookResult = ReturnType<
  typeof useCommonInitialDataLazyQuery
>;
export type CommonInitialDataQueryResult = ApolloReactCommon.QueryResult<
  CommonInitialDataQuery,
  CommonInitialDataQueryVariables
>;
export function refetchCommonInitialDataQuery(
  variables?: CommonInitialDataQueryVariables
) {
  return { query: CommonInitialDataDocument, variables: variables };
}
export const UpdateNftListingsDocument = gql`
  mutation updateNFTListings {
    updateNFTListings {
      message
    }
  }
`;
export type UpdateNftListingsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateNftListingsMutation,
  UpdateNftListingsMutationVariables
>;

/**
 * __useUpdateNftListingsMutation__
 *
 * To run a mutation, you first call `useUpdateNftListingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNftListingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNftListingsMutation, { data, loading, error }] = useUpdateNftListingsMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateNftListingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateNftListingsMutation,
    UpdateNftListingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateNftListingsMutation,
    UpdateNftListingsMutationVariables
  >(UpdateNftListingsDocument, options);
}
export type UpdateNftListingsMutationHookResult = ReturnType<
  typeof useUpdateNftListingsMutation
>;
export type UpdateNftListingsMutationResult =
  ApolloReactCommon.MutationResult<UpdateNftListingsMutation>;
export type UpdateNftListingsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateNftListingsMutation,
    UpdateNftListingsMutationVariables
  >;
export const UserNftAvatarsDocument = gql`
  query userNftAvatars {
    userErc721s(distinct_on: imgUrl) {
      id
      tokenId
      name
      collection
      imgUrl
    }
  }
`;

/**
 * __useUserNftAvatarsQuery__
 *
 * To run a query within a React component, call `useUserNftAvatarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNftAvatarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNftAvatarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNftAvatarsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserNftAvatarsQuery,
    UserNftAvatarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserNftAvatarsQuery,
    UserNftAvatarsQueryVariables
  >(UserNftAvatarsDocument, options);
}
export function useUserNftAvatarsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserNftAvatarsQuery,
    UserNftAvatarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserNftAvatarsQuery,
    UserNftAvatarsQueryVariables
  >(UserNftAvatarsDocument, options);
}
export type UserNftAvatarsQueryHookResult = ReturnType<
  typeof useUserNftAvatarsQuery
>;
export type UserNftAvatarsLazyQueryHookResult = ReturnType<
  typeof useUserNftAvatarsLazyQuery
>;
export type UserNftAvatarsQueryResult = ApolloReactCommon.QueryResult<
  UserNftAvatarsQuery,
  UserNftAvatarsQueryVariables
>;
export function refetchUserNftAvatarsQuery(
  variables?: UserNftAvatarsQueryVariables
) {
  return { query: UserNftAvatarsDocument, variables: variables };
}
export const LoadingAvatarProcessesDocument = gql`
  query loadingAvatarProcesses {
    userJobs(where: { type: { _eq: "erc721" } }) {
      id
    }
  }
`;

/**
 * __useLoadingAvatarProcessesQuery__
 *
 * To run a query within a React component, call `useLoadingAvatarProcessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadingAvatarProcessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadingAvatarProcessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadingAvatarProcessesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LoadingAvatarProcessesQuery,
    LoadingAvatarProcessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LoadingAvatarProcessesQuery,
    LoadingAvatarProcessesQueryVariables
  >(LoadingAvatarProcessesDocument, options);
}
export function useLoadingAvatarProcessesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LoadingAvatarProcessesQuery,
    LoadingAvatarProcessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LoadingAvatarProcessesQuery,
    LoadingAvatarProcessesQueryVariables
  >(LoadingAvatarProcessesDocument, options);
}
export type LoadingAvatarProcessesQueryHookResult = ReturnType<
  typeof useLoadingAvatarProcessesQuery
>;
export type LoadingAvatarProcessesLazyQueryHookResult = ReturnType<
  typeof useLoadingAvatarProcessesLazyQuery
>;
export type LoadingAvatarProcessesQueryResult = ApolloReactCommon.QueryResult<
  LoadingAvatarProcessesQuery,
  LoadingAvatarProcessesQueryVariables
>;
export function refetchLoadingAvatarProcessesQuery(
  variables?: LoadingAvatarProcessesQueryVariables
) {
  return { query: LoadingAvatarProcessesDocument, variables: variables };
}
export const UpdateAvatarNftsDocument = gql`
  mutation updateAvatarNfts {
    updateAvailableAvatarNFTs
  }
`;
export type UpdateAvatarNftsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAvatarNftsMutation,
  UpdateAvatarNftsMutationVariables
>;

/**
 * __useUpdateAvatarNftsMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarNftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarNftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarNftsMutation, { data, loading, error }] = useUpdateAvatarNftsMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateAvatarNftsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAvatarNftsMutation,
    UpdateAvatarNftsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateAvatarNftsMutation,
    UpdateAvatarNftsMutationVariables
  >(UpdateAvatarNftsDocument, options);
}
export type UpdateAvatarNftsMutationHookResult = ReturnType<
  typeof useUpdateAvatarNftsMutation
>;
export type UpdateAvatarNftsMutationResult =
  ApolloReactCommon.MutationResult<UpdateAvatarNftsMutation>;
export type UpdateAvatarNftsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateAvatarNftsMutation,
    UpdateAvatarNftsMutationVariables
  >;
export const SetUserAvatarDocument = gql`
  mutation setUserAvatar($id: Int!) {
    updateAvatar(avatarId: $id) {
      message
    }
  }
`;
export type SetUserAvatarMutationFn = ApolloReactCommon.MutationFunction<
  SetUserAvatarMutation,
  SetUserAvatarMutationVariables
>;

/**
 * __useSetUserAvatarMutation__
 *
 * To run a mutation, you first call `useSetUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAvatarMutation, { data, loading, error }] = useSetUserAvatarMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetUserAvatarMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetUserAvatarMutation,
    SetUserAvatarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetUserAvatarMutation,
    SetUserAvatarMutationVariables
  >(SetUserAvatarDocument, options);
}
export type SetUserAvatarMutationHookResult = ReturnType<
  typeof useSetUserAvatarMutation
>;
export type SetUserAvatarMutationResult =
  ApolloReactCommon.MutationResult<SetUserAvatarMutation>;
export type SetUserAvatarMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SetUserAvatarMutation,
    SetUserAvatarMutationVariables
  >;
export const UserNftsDocument = gql`
  query userNfts($account: String!, $blockchainId: smallint) {
    nfts(
      where: { owner: { _eq: $account }, blockchainId: { _eq: $blockchainId } }
      order_by: { id: asc }
    ) {
      id
      tokenId
      owner
      imageUrl
      nftContract {
        id
        blockchainId
        contractAddress
        collectionName
      }
      nftMetadatum {
        id
        imageUrl
      }
      listing {
        originalListingId
        price
        endedAt
        marketplaceContract {
          id
          contractAddress
          type
          fee
        }
      }
    }
  }
`;

/**
 * __useUserNftsQuery__
 *
 * To run a query within a React component, call `useUserNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNftsQuery({
 *   variables: {
 *      account: // value for 'account'
 *      blockchainId: // value for 'blockchainId'
 *   },
 * });
 */
export function useUserNftsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserNftsQuery,
    UserNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserNftsQuery, UserNftsQueryVariables>(
    UserNftsDocument,
    options
  );
}
export function useUserNftsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserNftsQuery,
    UserNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserNftsQuery, UserNftsQueryVariables>(
    UserNftsDocument,
    options
  );
}
export type UserNftsQueryHookResult = ReturnType<typeof useUserNftsQuery>;
export type UserNftsLazyQueryHookResult = ReturnType<
  typeof useUserNftsLazyQuery
>;
export type UserNftsQueryResult = ApolloReactCommon.QueryResult<
  UserNftsQuery,
  UserNftsQueryVariables
>;
export function refetchUserNftsQuery(variables: UserNftsQueryVariables) {
  return { query: UserNftsDocument, variables: variables };
}
export const NftCardNameDocument = gql`
  query nftCardName($tokenId: Int!) {
    nftOwners(where: { tokenid: { _eq: $tokenId } }) {
      id
      tokenid
      pokerName
    }
  }
`;

/**
 * __useNftCardNameQuery__
 *
 * To run a query within a React component, call `useNftCardNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useNftCardNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNftCardNameQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useNftCardNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    NftCardNameQuery,
    NftCardNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NftCardNameQuery, NftCardNameQueryVariables>(
    NftCardNameDocument,
    options
  );
}
export function useNftCardNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NftCardNameQuery,
    NftCardNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    NftCardNameQuery,
    NftCardNameQueryVariables
  >(NftCardNameDocument, options);
}
export type NftCardNameQueryHookResult = ReturnType<typeof useNftCardNameQuery>;
export type NftCardNameLazyQueryHookResult = ReturnType<
  typeof useNftCardNameLazyQuery
>;
export type NftCardNameQueryResult = ApolloReactCommon.QueryResult<
  NftCardNameQuery,
  NftCardNameQueryVariables
>;
export function refetchNftCardNameQuery(variables: NftCardNameQueryVariables) {
  return { query: NftCardNameDocument, variables: variables };
}
export const UserDataToDelegateNftDocument = gql`
  query userDataToDelegateNft($name: String!) {
    NFTFindUser(name: $name) {
      gPanelUserId
    }
  }
`;

/**
 * __useUserDataToDelegateNftQuery__
 *
 * To run a query within a React component, call `useUserDataToDelegateNftQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataToDelegateNftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataToDelegateNftQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUserDataToDelegateNftQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserDataToDelegateNftQuery,
    UserDataToDelegateNftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserDataToDelegateNftQuery,
    UserDataToDelegateNftQueryVariables
  >(UserDataToDelegateNftDocument, options);
}
export function useUserDataToDelegateNftLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserDataToDelegateNftQuery,
    UserDataToDelegateNftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserDataToDelegateNftQuery,
    UserDataToDelegateNftQueryVariables
  >(UserDataToDelegateNftDocument, options);
}
export type UserDataToDelegateNftQueryHookResult = ReturnType<
  typeof useUserDataToDelegateNftQuery
>;
export type UserDataToDelegateNftLazyQueryHookResult = ReturnType<
  typeof useUserDataToDelegateNftLazyQuery
>;
export type UserDataToDelegateNftQueryResult = ApolloReactCommon.QueryResult<
  UserDataToDelegateNftQuery,
  UserDataToDelegateNftQueryVariables
>;
export function refetchUserDataToDelegateNftQuery(
  variables: UserDataToDelegateNftQueryVariables
) {
  return { query: UserDataToDelegateNftDocument, variables: variables };
}
export const DelegatedNfTsDocument = gql`
  query delegatedNFTs {
    NFTUserConnected {
      own {
        id
        tokenId
        status
        owner
        username
      }
      rented {
        ...delegatedNFTData
      }
    }
  }
  ${DelegatedNftDataFragmentDoc}
`;

/**
 * __useDelegatedNfTsQuery__
 *
 * To run a query within a React component, call `useDelegatedNfTsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDelegatedNfTsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDelegatedNfTsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDelegatedNfTsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DelegatedNfTsQuery,
    DelegatedNfTsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DelegatedNfTsQuery,
    DelegatedNfTsQueryVariables
  >(DelegatedNfTsDocument, options);
}
export function useDelegatedNfTsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DelegatedNfTsQuery,
    DelegatedNfTsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DelegatedNfTsQuery,
    DelegatedNfTsQueryVariables
  >(DelegatedNfTsDocument, options);
}
export type DelegatedNfTsQueryHookResult = ReturnType<
  typeof useDelegatedNfTsQuery
>;
export type DelegatedNfTsLazyQueryHookResult = ReturnType<
  typeof useDelegatedNfTsLazyQuery
>;
export type DelegatedNfTsQueryResult = ApolloReactCommon.QueryResult<
  DelegatedNfTsQuery,
  DelegatedNfTsQueryVariables
>;
export function refetchDelegatedNfTsQuery(
  variables?: DelegatedNfTsQueryVariables
) {
  return { query: DelegatedNfTsDocument, variables: variables };
}
export const SaveNftCardNameDocument = gql`
  mutation saveNftCardName($tokenId: Int!, $name: String!) {
    setNFTusername(nftId: $tokenId, username: $name) {
      message
    }
  }
`;
export type SaveNftCardNameMutationFn = ApolloReactCommon.MutationFunction<
  SaveNftCardNameMutation,
  SaveNftCardNameMutationVariables
>;

/**
 * __useSaveNftCardNameMutation__
 *
 * To run a mutation, you first call `useSaveNftCardNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNftCardNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNftCardNameMutation, { data, loading, error }] = useSaveNftCardNameMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSaveNftCardNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveNftCardNameMutation,
    SaveNftCardNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveNftCardNameMutation,
    SaveNftCardNameMutationVariables
  >(SaveNftCardNameDocument, options);
}
export type SaveNftCardNameMutationHookResult = ReturnType<
  typeof useSaveNftCardNameMutation
>;
export type SaveNftCardNameMutationResult =
  ApolloReactCommon.MutationResult<SaveNftCardNameMutation>;
export type SaveNftCardNameMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SaveNftCardNameMutation,
    SaveNftCardNameMutationVariables
  >;
export const DelegateOrRevokeNftDocument = gql`
  mutation delegateOrRevokeNFT(
    $blockchainId: Int!
    $nftIds: [Int!]!
    $gPanelUserId: String
    $delegate: Boolean!
  ) {
    NFTRentOut(
      blockchainId: $blockchainId
      nftIds: $nftIds
      gPanelUserId: $gPanelUserId
      delegate: $delegate
    ) {
      message
    }
  }
`;
export type DelegateOrRevokeNftMutationFn = ApolloReactCommon.MutationFunction<
  DelegateOrRevokeNftMutation,
  DelegateOrRevokeNftMutationVariables
>;

/**
 * __useDelegateOrRevokeNftMutation__
 *
 * To run a mutation, you first call `useDelegateOrRevokeNftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelegateOrRevokeNftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delegateOrRevokeNftMutation, { data, loading, error }] = useDelegateOrRevokeNftMutation({
 *   variables: {
 *      blockchainId: // value for 'blockchainId'
 *      nftIds: // value for 'nftIds'
 *      gPanelUserId: // value for 'gPanelUserId'
 *      delegate: // value for 'delegate'
 *   },
 * });
 */
export function useDelegateOrRevokeNftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DelegateOrRevokeNftMutation,
    DelegateOrRevokeNftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DelegateOrRevokeNftMutation,
    DelegateOrRevokeNftMutationVariables
  >(DelegateOrRevokeNftDocument, options);
}
export type DelegateOrRevokeNftMutationHookResult = ReturnType<
  typeof useDelegateOrRevokeNftMutation
>;
export type DelegateOrRevokeNftMutationResult =
  ApolloReactCommon.MutationResult<DelegateOrRevokeNftMutation>;
export type DelegateOrRevokeNftMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DelegateOrRevokeNftMutation,
    DelegateOrRevokeNftMutationVariables
  >;
export const AcceptOrCancelDelegatedNftDocument = gql`
  mutation acceptOrCancelDelegatedNft($nftId: Int!, $accept: Boolean!) {
    NFTDelegationAccept(nftIds: [$nftId], accept: $accept) {
      message
    }
  }
`;
export type AcceptOrCancelDelegatedNftMutationFn =
  ApolloReactCommon.MutationFunction<
    AcceptOrCancelDelegatedNftMutation,
    AcceptOrCancelDelegatedNftMutationVariables
  >;

/**
 * __useAcceptOrCancelDelegatedNftMutation__
 *
 * To run a mutation, you first call `useAcceptOrCancelDelegatedNftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOrCancelDelegatedNftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOrCancelDelegatedNftMutation, { data, loading, error }] = useAcceptOrCancelDelegatedNftMutation({
 *   variables: {
 *      nftId: // value for 'nftId'
 *      accept: // value for 'accept'
 *   },
 * });
 */
export function useAcceptOrCancelDelegatedNftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AcceptOrCancelDelegatedNftMutation,
    AcceptOrCancelDelegatedNftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AcceptOrCancelDelegatedNftMutation,
    AcceptOrCancelDelegatedNftMutationVariables
  >(AcceptOrCancelDelegatedNftDocument, options);
}
export type AcceptOrCancelDelegatedNftMutationHookResult = ReturnType<
  typeof useAcceptOrCancelDelegatedNftMutation
>;
export type AcceptOrCancelDelegatedNftMutationResult =
  ApolloReactCommon.MutationResult<AcceptOrCancelDelegatedNftMutation>;
export type AcceptOrCancelDelegatedNftMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AcceptOrCancelDelegatedNftMutation,
    AcceptOrCancelDelegatedNftMutationVariables
  >;
export const InitialUserDataDocument = gql`
  query initialUserData($limited: Boolean = false) {
    me {
      id
      role
      language @skip(if: $limited)
      pokerName
      avatar {
        id
        name
        collection
        url
      }
      kycStatus
      kyc {
        id
        level
        minDeposit
        maxDeposit
        feeDeposit
        minWithdraw
        maxWithdraw
        feeWithdraw
      }
      agent {
        isAgent
        hasAgent
      }
      staking @skip(if: $limited) {
        rackbackId
        status
        inp
        busd
        nft
      }
      depositAddress {
        blockchainId
        address
      }
    }
  }
`;

/**
 * __useInitialUserDataQuery__
 *
 * To run a query within a React component, call `useInitialUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialUserDataQuery({
 *   variables: {
 *      limited: // value for 'limited'
 *   },
 * });
 */
export function useInitialUserDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InitialUserDataQuery,
    InitialUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InitialUserDataQuery,
    InitialUserDataQueryVariables
  >(InitialUserDataDocument, options);
}
export function useInitialUserDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InitialUserDataQuery,
    InitialUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InitialUserDataQuery,
    InitialUserDataQueryVariables
  >(InitialUserDataDocument, options);
}
export type InitialUserDataQueryHookResult = ReturnType<
  typeof useInitialUserDataQuery
>;
export type InitialUserDataLazyQueryHookResult = ReturnType<
  typeof useInitialUserDataLazyQuery
>;
export type InitialUserDataQueryResult = ApolloReactCommon.QueryResult<
  InitialUserDataQuery,
  InitialUserDataQueryVariables
>;
export function refetchInitialUserDataQuery(
  variables?: InitialUserDataQueryVariables
) {
  return { query: InitialUserDataDocument, variables: variables };
}
export const UserLanguageDocument = gql`
  query userLanguage {
    me {
      language
    }
  }
`;

/**
 * __useUserLanguageQuery__
 *
 * To run a query within a React component, call `useUserLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLanguageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserLanguageQuery,
    UserLanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserLanguageQuery,
    UserLanguageQueryVariables
  >(UserLanguageDocument, options);
}
export function useUserLanguageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserLanguageQuery,
    UserLanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserLanguageQuery,
    UserLanguageQueryVariables
  >(UserLanguageDocument, options);
}
export type UserLanguageQueryHookResult = ReturnType<
  typeof useUserLanguageQuery
>;
export type UserLanguageLazyQueryHookResult = ReturnType<
  typeof useUserLanguageLazyQuery
>;
export type UserLanguageQueryResult = ApolloReactCommon.QueryResult<
  UserLanguageQuery,
  UserLanguageQueryVariables
>;
export function refetchUserLanguageQuery(
  variables?: UserLanguageQueryVariables
) {
  return { query: UserLanguageDocument, variables: variables };
}
export const UserAvatarDocument = gql`
  query userAvatar {
    me {
      id
      avatar {
        id
        name
        collection
        url
      }
    }
  }
`;

/**
 * __useUserAvatarQuery__
 *
 * To run a query within a React component, call `useUserAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAvatarQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAvatarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserAvatarQuery,
    UserAvatarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserAvatarQuery, UserAvatarQueryVariables>(
    UserAvatarDocument,
    options
  );
}
export function useUserAvatarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserAvatarQuery,
    UserAvatarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserAvatarQuery,
    UserAvatarQueryVariables
  >(UserAvatarDocument, options);
}
export type UserAvatarQueryHookResult = ReturnType<typeof useUserAvatarQuery>;
export type UserAvatarLazyQueryHookResult = ReturnType<
  typeof useUserAvatarLazyQuery
>;
export type UserAvatarQueryResult = ApolloReactCommon.QueryResult<
  UserAvatarQuery,
  UserAvatarQueryVariables
>;
export function refetchUserAvatarQuery(variables?: UserAvatarQueryVariables) {
  return { query: UserAvatarDocument, variables: variables };
}
export const UserKycDataDocument = gql`
  query userKycData {
    me {
      id
      kycStatus
      kyc {
        id
        level
      }
    }
  }
`;

/**
 * __useUserKycDataQuery__
 *
 * To run a query within a React component, call `useUserKycDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserKycDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserKycDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserKycDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserKycDataQuery,
    UserKycDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserKycDataQuery, UserKycDataQueryVariables>(
    UserKycDataDocument,
    options
  );
}
export function useUserKycDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserKycDataQuery,
    UserKycDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserKycDataQuery,
    UserKycDataQueryVariables
  >(UserKycDataDocument, options);
}
export type UserKycDataQueryHookResult = ReturnType<typeof useUserKycDataQuery>;
export type UserKycDataLazyQueryHookResult = ReturnType<
  typeof useUserKycDataLazyQuery
>;
export type UserKycDataQueryResult = ApolloReactCommon.QueryResult<
  UserKycDataQuery,
  UserKycDataQueryVariables
>;
export function refetchUserKycDataQuery(variables?: UserKycDataQueryVariables) {
  return { query: UserKycDataDocument, variables: variables };
}
export const UserWithdrawPageFormKycDataDocument = gql`
  query userWithdrawPageFormKycData {
    me {
      id
      kycStatus
      kyc {
        id
        level
        minWithdrawInp
        maxWithdrawInp
        feeWithdrawInp
        minWithdraw
        maxWithdraw
        feeWithdraw
      }
    }
  }
`;

/**
 * __useUserWithdrawPageFormKycDataQuery__
 *
 * To run a query within a React component, call `useUserWithdrawPageFormKycDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithdrawPageFormKycDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithdrawPageFormKycDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithdrawPageFormKycDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserWithdrawPageFormKycDataQuery,
    UserWithdrawPageFormKycDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserWithdrawPageFormKycDataQuery,
    UserWithdrawPageFormKycDataQueryVariables
  >(UserWithdrawPageFormKycDataDocument, options);
}
export function useUserWithdrawPageFormKycDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserWithdrawPageFormKycDataQuery,
    UserWithdrawPageFormKycDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserWithdrawPageFormKycDataQuery,
    UserWithdrawPageFormKycDataQueryVariables
  >(UserWithdrawPageFormKycDataDocument, options);
}
export type UserWithdrawPageFormKycDataQueryHookResult = ReturnType<
  typeof useUserWithdrawPageFormKycDataQuery
>;
export type UserWithdrawPageFormKycDataLazyQueryHookResult = ReturnType<
  typeof useUserWithdrawPageFormKycDataLazyQuery
>;
export type UserWithdrawPageFormKycDataQueryResult =
  ApolloReactCommon.QueryResult<
    UserWithdrawPageFormKycDataQuery,
    UserWithdrawPageFormKycDataQueryVariables
  >;
export function refetchUserWithdrawPageFormKycDataQuery(
  variables?: UserWithdrawPageFormKycDataQueryVariables
) {
  return { query: UserWithdrawPageFormKycDataDocument, variables: variables };
}
export const UserAgentDataDocument = gql`
  query userAgentData {
    me {
      id
      agent {
        isAgent
        hasAgent
      }
    }
  }
`;

/**
 * __useUserAgentDataQuery__
 *
 * To run a query within a React component, call `useUserAgentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAgentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAgentDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAgentDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserAgentDataQuery,
    UserAgentDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserAgentDataQuery,
    UserAgentDataQueryVariables
  >(UserAgentDataDocument, options);
}
export function useUserAgentDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserAgentDataQuery,
    UserAgentDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserAgentDataQuery,
    UserAgentDataQueryVariables
  >(UserAgentDataDocument, options);
}
export type UserAgentDataQueryHookResult = ReturnType<
  typeof useUserAgentDataQuery
>;
export type UserAgentDataLazyQueryHookResult = ReturnType<
  typeof useUserAgentDataLazyQuery
>;
export type UserAgentDataQueryResult = ApolloReactCommon.QueryResult<
  UserAgentDataQuery,
  UserAgentDataQueryVariables
>;
export function refetchUserAgentDataQuery(
  variables?: UserAgentDataQueryVariables
) {
  return { query: UserAgentDataDocument, variables: variables };
}
export const UserDepositFormDataDocument = gql`
  query userDepositFormData {
    me {
      id
      kyc {
        id
        minDeposit
        maxDeposit
        feeDeposit
        minDepositInp
        maxDepositInp
        feeDepositInp
      }
      agent {
        isAgent
      }
    }
    agentGetUsers {
      agentOf {
        id
        gPanelUserId
        username
      }
    }
  }
`;

/**
 * __useUserDepositFormDataQuery__
 *
 * To run a query within a React component, call `useUserDepositFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDepositFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDepositFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDepositFormDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserDepositFormDataQuery,
    UserDepositFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserDepositFormDataQuery,
    UserDepositFormDataQueryVariables
  >(UserDepositFormDataDocument, options);
}
export function useUserDepositFormDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserDepositFormDataQuery,
    UserDepositFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserDepositFormDataQuery,
    UserDepositFormDataQueryVariables
  >(UserDepositFormDataDocument, options);
}
export type UserDepositFormDataQueryHookResult = ReturnType<
  typeof useUserDepositFormDataQuery
>;
export type UserDepositFormDataLazyQueryHookResult = ReturnType<
  typeof useUserDepositFormDataLazyQuery
>;
export type UserDepositFormDataQueryResult = ApolloReactCommon.QueryResult<
  UserDepositFormDataQuery,
  UserDepositFormDataQueryVariables
>;
export function refetchUserDepositFormDataQuery(
  variables?: UserDepositFormDataQueryVariables
) {
  return { query: UserDepositFormDataDocument, variables: variables };
}
export const UserDepositAddressesDocument = gql`
  query userDepositAddresses {
    me {
      id
      depositAddress {
        blockchainId
        address
      }
    }
  }
`;

/**
 * __useUserDepositAddressesQuery__
 *
 * To run a query within a React component, call `useUserDepositAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDepositAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDepositAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDepositAddressesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserDepositAddressesQuery,
    UserDepositAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserDepositAddressesQuery,
    UserDepositAddressesQueryVariables
  >(UserDepositAddressesDocument, options);
}
export function useUserDepositAddressesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserDepositAddressesQuery,
    UserDepositAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserDepositAddressesQuery,
    UserDepositAddressesQueryVariables
  >(UserDepositAddressesDocument, options);
}
export type UserDepositAddressesQueryHookResult = ReturnType<
  typeof useUserDepositAddressesQuery
>;
export type UserDepositAddressesLazyQueryHookResult = ReturnType<
  typeof useUserDepositAddressesLazyQuery
>;
export type UserDepositAddressesQueryResult = ApolloReactCommon.QueryResult<
  UserDepositAddressesQuery,
  UserDepositAddressesQueryVariables
>;
export function refetchUserDepositAddressesQuery(
  variables?: UserDepositAddressesQueryVariables
) {
  return { query: UserDepositAddressesDocument, variables: variables };
}
export const UserWithdrawFormDataDocument = gql`
  query userWithdrawFormData {
    me {
      id
      kyc {
        id
        minWithdraw
        maxWithdraw
        feeWithdraw
        minWithdrawInp
        maxWithdrawInp
        feeWithdrawInp
      }
      agent {
        hasAgent
      }
    }
    agentGetUsers {
      agentFrom {
        id
        gPanelUserId
        username
      }
    }
  }
`;

/**
 * __useUserWithdrawFormDataQuery__
 *
 * To run a query within a React component, call `useUserWithdrawFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithdrawFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithdrawFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithdrawFormDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserWithdrawFormDataQuery,
    UserWithdrawFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserWithdrawFormDataQuery,
    UserWithdrawFormDataQueryVariables
  >(UserWithdrawFormDataDocument, options);
}
export function useUserWithdrawFormDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserWithdrawFormDataQuery,
    UserWithdrawFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserWithdrawFormDataQuery,
    UserWithdrawFormDataQueryVariables
  >(UserWithdrawFormDataDocument, options);
}
export type UserWithdrawFormDataQueryHookResult = ReturnType<
  typeof useUserWithdrawFormDataQuery
>;
export type UserWithdrawFormDataLazyQueryHookResult = ReturnType<
  typeof useUserWithdrawFormDataLazyQuery
>;
export type UserWithdrawFormDataQueryResult = ApolloReactCommon.QueryResult<
  UserWithdrawFormDataQuery,
  UserWithdrawFormDataQueryVariables
>;
export function refetchUserWithdrawFormDataQuery(
  variables?: UserWithdrawFormDataQueryVariables
) {
  return { query: UserWithdrawFormDataDocument, variables: variables };
}
export const UserStakingDataDocument = gql`
  query userStakingData {
    me {
      id
      staking {
        rackbackId
        status
        inp
        busd
        nft
      }
    }
  }
`;

/**
 * __useUserStakingDataQuery__
 *
 * To run a query within a React component, call `useUserStakingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStakingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStakingDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserStakingDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserStakingDataQuery,
    UserStakingDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserStakingDataQuery,
    UserStakingDataQueryVariables
  >(UserStakingDataDocument, options);
}
export function useUserStakingDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserStakingDataQuery,
    UserStakingDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserStakingDataQuery,
    UserStakingDataQueryVariables
  >(UserStakingDataDocument, options);
}
export type UserStakingDataQueryHookResult = ReturnType<
  typeof useUserStakingDataQuery
>;
export type UserStakingDataLazyQueryHookResult = ReturnType<
  typeof useUserStakingDataLazyQuery
>;
export type UserStakingDataQueryResult = ApolloReactCommon.QueryResult<
  UserStakingDataQuery,
  UserStakingDataQueryVariables
>;
export function refetchUserStakingDataQuery(
  variables?: UserStakingDataQueryVariables
) {
  return { query: UserStakingDataDocument, variables: variables };
}
export const UpdateUserLanguageDocument = gql`
  mutation updateUserLanguage($lang: String!) {
    updateUsers(_set: { language: $lang }, where: {}) {
      affected_rows
    }
  }
`;
export type UpdateUserLanguageMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserLanguageMutation,
  UpdateUserLanguageMutationVariables
>;

/**
 * __useUpdateUserLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguageMutation, { data, loading, error }] = useUpdateUserLanguageMutation({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateUserLanguageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserLanguageMutation,
    UpdateUserLanguageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateUserLanguageMutation,
    UpdateUserLanguageMutationVariables
  >(UpdateUserLanguageDocument, options);
}
export type UpdateUserLanguageMutationHookResult = ReturnType<
  typeof useUpdateUserLanguageMutation
>;
export type UpdateUserLanguageMutationResult =
  ApolloReactCommon.MutationResult<UpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateUserLanguageMutation,
    UpdateUserLanguageMutationVariables
  >;
export const VaultTableDataDocument = gql`
  query vaultTableData {
    rakebackLocks {
      ...rakebackData
    }
  }
  ${RakebackDataFragmentDoc}
`;

/**
 * __useVaultTableDataQuery__
 *
 * To run a query within a React component, call `useVaultTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultTableDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useVaultTableDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    VaultTableDataQuery,
    VaultTableDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    VaultTableDataQuery,
    VaultTableDataQueryVariables
  >(VaultTableDataDocument, options);
}
export function useVaultTableDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VaultTableDataQuery,
    VaultTableDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    VaultTableDataQuery,
    VaultTableDataQueryVariables
  >(VaultTableDataDocument, options);
}
export type VaultTableDataQueryHookResult = ReturnType<
  typeof useVaultTableDataQuery
>;
export type VaultTableDataLazyQueryHookResult = ReturnType<
  typeof useVaultTableDataLazyQuery
>;
export type VaultTableDataQueryResult = ApolloReactCommon.QueryResult<
  VaultTableDataQuery,
  VaultTableDataQueryVariables
>;
export function refetchVaultTableDataQuery(
  variables?: VaultTableDataQueryVariables
) {
  return { query: VaultTableDataDocument, variables: variables };
}
export type DepositOutputKeySpecifier = (
  | 'blockchainId'
  | 'depositAddress'
  | 'id'
  | 'status'
  | DepositOutputKeySpecifier
)[];
export type DepositOutputFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GeneralMessageOutputKeySpecifier = (
  | 'message'
  | GeneralMessageOutputKeySpecifier
)[];
export type GeneralMessageOutputFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type KYCSubmitOutputKeySpecifier = (
  | 'status'
  | KYCSubmitOutputKeySpecifier
)[];
export type KYCSubmitOutputFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NFTConnectedDetailKeySpecifier = (
  | 'blockchainId'
  | 'collection'
  | 'gpanelId'
  | 'id'
  | 'imageUrl'
  | 'nftId'
  | 'owner'
  | 'status'
  | 'tokenId'
  | 'username'
  | NFTConnectedDetailKeySpecifier
)[];
export type NFTConnectedDetailFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  collection?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NFTConnectedOutputKeySpecifier = (
  | 'own'
  | 'rented'
  | NFTConnectedOutputKeySpecifier
)[];
export type NFTConnectedOutputFieldPolicy = {
  own?: FieldPolicy<any> | FieldReadFunction<any>;
  rented?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NFTTicketsClaimOptionsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'label'
  | NFTTicketsClaimOptionsKeySpecifier
)[];
export type NFTTicketsClaimOptionsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NFTTicketsClaimOutputKeySpecifier = (
  | 'claimOptions'
  | 'message'
  | 'nextReleaseTime'
  | 'totalNFTs'
  | NFTTicketsClaimOutputKeySpecifier
)[];
export type NFTTicketsClaimOutputFieldPolicy = {
  claimOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  nextReleaseTime?: FieldPolicy<any> | FieldReadFunction<any>;
  totalNFTs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateOutputKeySpecifier = (
  | 'fee'
  | 'fromAmount'
  | 'fromCurrency'
  | 'message'
  | 'toAmount'
  | 'toCurrency'
  | 'toMinimumAmount'
  | RateOutputKeySpecifier
)[];
export type RateOutputFieldPolicy = {
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  fromAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  fromCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  toAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  toCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
  toMinimumAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusOutputKeySpecifier = (
  | 'blockchainId'
  | 'id'
  | 'status'
  | StatusOutputKeySpecifier
)[];
export type StatusOutputFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SwapOrderOutputKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'createdAt'
  | 'currencyFrom'
  | 'currencyTo'
  | 'fee'
  | 'gpanelUserId'
  | 'id'
  | 'status'
  | 'txid'
  | 'updatedAt'
  | SwapOrderOutputKeySpecifier
)[];
export type SwapOrderOutputFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyTo?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SwapOutputKeySpecifier = (
  | 'message'
  | 'orderId'
  | 'status'
  | SwapOutputKeySpecifier
)[];
export type SwapOutputFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  orderId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TestKeySpecifier = ('id' | 'text' | TestKeySpecifier)[];
export type TestFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  text?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | Test_aggregateKeySpecifier
)[];
export type Test_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | Test_aggregate_fieldsKeySpecifier
)[];
export type Test_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_avg_fieldsKeySpecifier = (
  | 'id'
  | Test_avg_fieldsKeySpecifier
)[];
export type Test_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_max_fieldsKeySpecifier = (
  | 'id'
  | 'text'
  | Test_max_fieldsKeySpecifier
)[];
export type Test_max_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  text?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_min_fieldsKeySpecifier = (
  | 'id'
  | 'text'
  | Test_min_fieldsKeySpecifier
)[];
export type Test_min_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  text?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | Test_mutation_responseKeySpecifier
)[];
export type Test_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_stddev_fieldsKeySpecifier = (
  | 'id'
  | Test_stddev_fieldsKeySpecifier
)[];
export type Test_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | Test_stddev_pop_fieldsKeySpecifier
)[];
export type Test_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | Test_stddev_samp_fieldsKeySpecifier
)[];
export type Test_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_sum_fieldsKeySpecifier = (
  | 'id'
  | Test_sum_fieldsKeySpecifier
)[];
export type Test_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_var_pop_fieldsKeySpecifier = (
  | 'id'
  | Test_var_pop_fieldsKeySpecifier
)[];
export type Test_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_var_samp_fieldsKeySpecifier = (
  | 'id'
  | Test_var_samp_fieldsKeySpecifier
)[];
export type Test_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Test_variance_fieldsKeySpecifier = (
  | 'id'
  | Test_variance_fieldsKeySpecifier
)[];
export type Test_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserOutputKeySpecifier = (
  | 'agent'
  | 'avatar'
  | 'createdAt'
  | 'depositAddress'
  | 'ethAddress'
  | 'gpanelId'
  | 'id'
  | 'kyc'
  | 'kycAllowed'
  | 'kycPending'
  | 'kycStatus'
  | 'language'
  | 'pokerBusd'
  | 'pokerInp'
  | 'pokerName'
  | 'role'
  | 'staking'
  | 'telegramName'
  | UserOutputKeySpecifier
)[];
export type UserOutputFieldPolicy = {
  agent?: FieldPolicy<any> | FieldReadFunction<any>;
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  ethAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  kycAllowed?: FieldPolicy<any> | FieldReadFunction<any>;
  kycPending?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerName?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  staking?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type agentFindUserOutputKeySpecifier = (
  | 'avatar'
  | 'gPanelUserId'
  | 'message'
  | 'username'
  | agentFindUserOutputKeySpecifier
)[];
export type agentFindUserOutputFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  gPanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type agentGetUsersOutputKeySpecifier = (
  | 'agentFrom'
  | 'agentOf'
  | agentGetUsersOutputKeySpecifier
)[];
export type agentGetUsersOutputFieldPolicy = {
  agentFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  agentOf?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type agentUserItemOutputKeySpecifier = (
  | 'avatar'
  | 'gPanelUserId'
  | 'id'
  | 'username'
  | agentUserItemOutputKeySpecifier
)[];
export type agentUserItemOutputFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  gPanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type agentUserOutputKeySpecifier = (
  | 'hasAgent'
  | 'isAgent'
  | agentUserOutputKeySpecifier
)[];
export type agentUserOutputFieldPolicy = {
  hasAgent?: FieldPolicy<any> | FieldReadFunction<any>;
  isAgent?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanelKeySpecifier = (
  | 'access_token'
  | 'code_token'
  | 'created_at'
  | 'id'
  | 'refresh_token'
  | 'updated_at'
  | 'uuid'
  | anno_gpanelKeySpecifier
)[];
export type anno_gpanelFieldPolicy = {
  access_token?: FieldPolicy<any> | FieldReadFunction<any>;
  code_token?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  refresh_token?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | anno_gpanel_aggregateKeySpecifier
)[];
export type anno_gpanel_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | anno_gpanel_aggregate_fieldsKeySpecifier
)[];
export type anno_gpanel_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_avg_fieldsKeySpecifier = (
  | 'id'
  | anno_gpanel_avg_fieldsKeySpecifier
)[];
export type anno_gpanel_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_max_fieldsKeySpecifier = (
  | 'access_token'
  | 'code_token'
  | 'created_at'
  | 'id'
  | 'refresh_token'
  | 'updated_at'
  | 'uuid'
  | anno_gpanel_max_fieldsKeySpecifier
)[];
export type anno_gpanel_max_fieldsFieldPolicy = {
  access_token?: FieldPolicy<any> | FieldReadFunction<any>;
  code_token?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  refresh_token?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_min_fieldsKeySpecifier = (
  | 'access_token'
  | 'code_token'
  | 'created_at'
  | 'id'
  | 'refresh_token'
  | 'updated_at'
  | 'uuid'
  | anno_gpanel_min_fieldsKeySpecifier
)[];
export type anno_gpanel_min_fieldsFieldPolicy = {
  access_token?: FieldPolicy<any> | FieldReadFunction<any>;
  code_token?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  refresh_token?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | anno_gpanel_mutation_responseKeySpecifier
)[];
export type anno_gpanel_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_stddev_fieldsKeySpecifier = (
  | 'id'
  | anno_gpanel_stddev_fieldsKeySpecifier
)[];
export type anno_gpanel_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | anno_gpanel_stddev_pop_fieldsKeySpecifier
)[];
export type anno_gpanel_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | anno_gpanel_stddev_samp_fieldsKeySpecifier
)[];
export type anno_gpanel_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_sum_fieldsKeySpecifier = (
  | 'id'
  | anno_gpanel_sum_fieldsKeySpecifier
)[];
export type anno_gpanel_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_var_pop_fieldsKeySpecifier = (
  | 'id'
  | anno_gpanel_var_pop_fieldsKeySpecifier
)[];
export type anno_gpanel_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_var_samp_fieldsKeySpecifier = (
  | 'id'
  | anno_gpanel_var_samp_fieldsKeySpecifier
)[];
export type anno_gpanel_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type anno_gpanel_variance_fieldsKeySpecifier = (
  | 'id'
  | anno_gpanel_variance_fieldsKeySpecifier
)[];
export type anno_gpanel_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type authLoginOutputKeySpecifier = (
  | 'access_token'
  | 'error'
  | 'eth_address'
  | 'expires_in'
  | 'role'
  | 'roles'
  | 'user_id'
  | authLoginOutputKeySpecifier
)[];
export type authLoginOutputFieldPolicy = {
  access_token?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  eth_address?: FieldPolicy<any> | FieldReadFunction<any>;
  expires_in?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  roles?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type authSignatureOutputKeySpecifier = (
  | 'message'
  | 'nonce'
  | authSignatureOutputKeySpecifier
)[];
export type authSignatureOutputFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  nonce?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type avatarOutputKeySpecifier = (
  | 'collection'
  | 'id'
  | 'name'
  | 'url'
  | avatarOutputKeySpecifier
)[];
export type avatarOutputFieldPolicy = {
  collection?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchainKeySpecifier = (
  | 'apiKey'
  | 'apiUrl'
  | 'chainId'
  | 'createdAt'
  | 'erc721Active'
  | 'iconUrl'
  | 'id'
  | 'inpActive'
  | 'inpAddress'
  | 'inpFeeAddress'
  | 'inpVaultAddress'
  | 'lp_address'
  | 'lp_stablecoin_id'
  | 'marketplaceAddress'
  | 'name'
  | 'nativeCurrency'
  | 'nftCardAddress'
  | 'pending_txes'
  | 'pending_txes_aggregate'
  | 'rpc'
  | 'rpcPublic'
  | 'scanUrl'
  | 'stablecoins'
  | 'stablecoins_aggregate'
  | 'swap_pair_address'
  | 'symbol'
  | 'updatedAt'
  | 'webActive'
  | blockchainKeySpecifier
)[];
export type blockchainFieldPolicy = {
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721Active?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inpActive?: FieldPolicy<any> | FieldReadFunction<any>;
  inpAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  inpFeeAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  inpVaultAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_address?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nativeCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
  nftCardAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_txes?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_txes_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  rpc?: FieldPolicy<any> | FieldReadFunction<any>;
  rpcPublic?: FieldPolicy<any> | FieldReadFunction<any>;
  scanUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoins?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoins_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  swap_pair_address?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  webActive?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | blockchain_aggregateKeySpecifier
)[];
export type blockchain_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | blockchain_aggregate_fieldsKeySpecifier
)[];
export type blockchain_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_avg_fieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | 'lp_stablecoin_id'
  | blockchain_avg_fieldsKeySpecifier
)[];
export type blockchain_avg_fieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_depositKeySpecifier = (
  | 'address'
  | 'blockchain_id'
  | 'forwarderfactory'
  | 'id'
  | 'salt'
  | blockchain_main_depositKeySpecifier
)[];
export type blockchain_main_depositFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  forwarderfactory?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  salt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | blockchain_main_deposit_aggregateKeySpecifier
)[];
export type blockchain_main_deposit_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | blockchain_main_deposit_aggregate_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_avg_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | blockchain_main_deposit_avg_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_avg_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_max_fieldsKeySpecifier = (
  | 'address'
  | 'blockchain_id'
  | 'forwarderfactory'
  | 'id'
  | 'salt'
  | blockchain_main_deposit_max_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_max_fieldsFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  forwarderfactory?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  salt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_min_fieldsKeySpecifier = (
  | 'address'
  | 'blockchain_id'
  | 'forwarderfactory'
  | 'id'
  | 'salt'
  | blockchain_main_deposit_min_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_min_fieldsFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  forwarderfactory?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  salt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | blockchain_main_deposit_mutation_responseKeySpecifier
)[];
export type blockchain_main_deposit_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_stddev_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | blockchain_main_deposit_stddev_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_stddev_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_stddev_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | blockchain_main_deposit_stddev_pop_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_stddev_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_stddev_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | blockchain_main_deposit_stddev_samp_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_stddev_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_sum_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | blockchain_main_deposit_sum_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_sum_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_var_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | blockchain_main_deposit_var_pop_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_var_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_var_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | blockchain_main_deposit_var_samp_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_var_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_main_deposit_variance_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | blockchain_main_deposit_variance_fieldsKeySpecifier
)[];
export type blockchain_main_deposit_variance_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_max_fieldsKeySpecifier = (
  | 'apiKey'
  | 'apiUrl'
  | 'chainId'
  | 'createdAt'
  | 'iconUrl'
  | 'id'
  | 'inpAddress'
  | 'inpFeeAddress'
  | 'inpVaultAddress'
  | 'lp_address'
  | 'lp_stablecoin_id'
  | 'marketplaceAddress'
  | 'name'
  | 'nftCardAddress'
  | 'scanUrl'
  | 'swap_pair_address'
  | 'symbol'
  | 'updatedAt'
  | blockchain_max_fieldsKeySpecifier
)[];
export type blockchain_max_fieldsFieldPolicy = {
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inpAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  inpFeeAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  inpVaultAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_address?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nftCardAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  scanUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  swap_pair_address?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_min_fieldsKeySpecifier = (
  | 'apiKey'
  | 'apiUrl'
  | 'chainId'
  | 'createdAt'
  | 'iconUrl'
  | 'id'
  | 'inpAddress'
  | 'inpFeeAddress'
  | 'inpVaultAddress'
  | 'lp_address'
  | 'lp_stablecoin_id'
  | 'marketplaceAddress'
  | 'name'
  | 'nftCardAddress'
  | 'scanUrl'
  | 'swap_pair_address'
  | 'symbol'
  | 'updatedAt'
  | blockchain_min_fieldsKeySpecifier
)[];
export type blockchain_min_fieldsFieldPolicy = {
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inpAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  inpFeeAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  inpVaultAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_address?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nftCardAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  scanUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  swap_pair_address?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | blockchain_mutation_responseKeySpecifier
)[];
export type blockchain_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_stddev_fieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | 'lp_stablecoin_id'
  | blockchain_stddev_fieldsKeySpecifier
)[];
export type blockchain_stddev_fieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_stddev_pop_fieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | 'lp_stablecoin_id'
  | blockchain_stddev_pop_fieldsKeySpecifier
)[];
export type blockchain_stddev_pop_fieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_stddev_samp_fieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | 'lp_stablecoin_id'
  | blockchain_stddev_samp_fieldsKeySpecifier
)[];
export type blockchain_stddev_samp_fieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_sum_fieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | 'lp_stablecoin_id'
  | blockchain_sum_fieldsKeySpecifier
)[];
export type blockchain_sum_fieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_var_pop_fieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | 'lp_stablecoin_id'
  | blockchain_var_pop_fieldsKeySpecifier
)[];
export type blockchain_var_pop_fieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_var_samp_fieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | 'lp_stablecoin_id'
  | blockchain_var_samp_fieldsKeySpecifier
)[];
export type blockchain_var_samp_fieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type blockchain_variance_fieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | 'lp_stablecoin_id'
  | blockchain_variance_fieldsKeySpecifier
)[];
export type blockchain_variance_fieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lp_stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profitKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'burn_date'
  | 'burn_tx_log'
  | 'created_at'
  | 'ended'
  | 'id'
  | 'money_type'
  | 'started'
  | 'status'
  | 'total_burn'
  | 'total_burn_inp'
  | 'updated_at'
  | burn_profitKeySpecifier
)[];
export type burn_profitFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_date?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_tx_log?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  ended?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  started?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | burn_profit_aggregateKeySpecifier
)[];
export type burn_profit_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | burn_profit_aggregate_fieldsKeySpecifier
)[];
export type burn_profit_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_avg_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'id'
  | 'total_burn'
  | 'total_burn_inp'
  | burn_profit_avg_fieldsKeySpecifier
)[];
export type burn_profit_avg_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_max_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'burn_date'
  | 'burn_tx_log'
  | 'created_at'
  | 'ended'
  | 'id'
  | 'money_type'
  | 'started'
  | 'status'
  | 'total_burn'
  | 'total_burn_inp'
  | 'updated_at'
  | burn_profit_max_fieldsKeySpecifier
)[];
export type burn_profit_max_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_date?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_tx_log?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  ended?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  started?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_min_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'burn_date'
  | 'burn_tx_log'
  | 'created_at'
  | 'ended'
  | 'id'
  | 'money_type'
  | 'started'
  | 'status'
  | 'total_burn'
  | 'total_burn_inp'
  | 'updated_at'
  | burn_profit_min_fieldsKeySpecifier
)[];
export type burn_profit_min_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_date?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_tx_log?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  ended?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  started?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | burn_profit_mutation_responseKeySpecifier
)[];
export type burn_profit_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_stddev_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'id'
  | 'total_burn'
  | 'total_burn_inp'
  | burn_profit_stddev_fieldsKeySpecifier
)[];
export type burn_profit_stddev_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_stddev_pop_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'id'
  | 'total_burn'
  | 'total_burn_inp'
  | burn_profit_stddev_pop_fieldsKeySpecifier
)[];
export type burn_profit_stddev_pop_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_stddev_samp_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'id'
  | 'total_burn'
  | 'total_burn_inp'
  | burn_profit_stddev_samp_fieldsKeySpecifier
)[];
export type burn_profit_stddev_samp_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_sum_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'id'
  | 'total_burn'
  | 'total_burn_inp'
  | burn_profit_sum_fieldsKeySpecifier
)[];
export type burn_profit_sum_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_var_pop_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'id'
  | 'total_burn'
  | 'total_burn_inp'
  | burn_profit_var_pop_fieldsKeySpecifier
)[];
export type burn_profit_var_pop_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_var_samp_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'id'
  | 'total_burn'
  | 'total_burn_inp'
  | burn_profit_var_samp_fieldsKeySpecifier
)[];
export type burn_profit_var_samp_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type burn_profit_variance_fieldsKeySpecifier = (
  | 'amount_rake'
  | 'amount_tournament'
  | 'id'
  | 'total_burn'
  | 'total_burn_inp'
  | burn_profit_variance_fieldsKeySpecifier
)[];
export type burn_profit_variance_fieldsFieldPolicy = {
  amount_rake?: FieldPolicy<any> | FieldReadFunction<any>;
  amount_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn?: FieldPolicy<any> | FieldReadFunction<any>;
  total_burn_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currenciesKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'coingecko_id'
  | 'contract'
  | 'created_at'
  | 'id'
  | 'image'
  | 'name'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'status'
  | 'symbol'
  | 'updated_at'
  | 'venus_address'
  | 'venus_borrowApy'
  | 'venus_json'
  | 'venus_name'
  | 'venus_supplyApy'
  | 'venus_symbol'
  | currenciesKeySpecifier
)[];
export type currenciesFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  coingecko_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_address?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_json?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_name?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_symbol?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | currencies_aggregateKeySpecifier
)[];
export type currencies_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | currencies_aggregate_fieldsKeySpecifier
)[];
export type currencies_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_avg_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'id'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'venus_borrowApy'
  | 'venus_supplyApy'
  | currencies_avg_fieldsKeySpecifier
)[];
export type currencies_avg_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_max_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'coingecko_id'
  | 'contract'
  | 'created_at'
  | 'id'
  | 'image'
  | 'name'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'status'
  | 'symbol'
  | 'updated_at'
  | 'venus_address'
  | 'venus_borrowApy'
  | 'venus_name'
  | 'venus_supplyApy'
  | 'venus_symbol'
  | currencies_max_fieldsKeySpecifier
)[];
export type currencies_max_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  coingecko_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_address?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_name?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_symbol?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_min_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'coingecko_id'
  | 'contract'
  | 'created_at'
  | 'id'
  | 'image'
  | 'name'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'status'
  | 'symbol'
  | 'updated_at'
  | 'venus_address'
  | 'venus_borrowApy'
  | 'venus_name'
  | 'venus_supplyApy'
  | 'venus_symbol'
  | currencies_min_fieldsKeySpecifier
)[];
export type currencies_min_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  coingecko_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_address?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_name?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_symbol?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | currencies_mutation_responseKeySpecifier
)[];
export type currencies_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_stddev_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'id'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'venus_borrowApy'
  | 'venus_supplyApy'
  | currencies_stddev_fieldsKeySpecifier
)[];
export type currencies_stddev_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_stddev_pop_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'id'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'venus_borrowApy'
  | 'venus_supplyApy'
  | currencies_stddev_pop_fieldsKeySpecifier
)[];
export type currencies_stddev_pop_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_stddev_samp_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'id'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'venus_borrowApy'
  | 'venus_supplyApy'
  | currencies_stddev_samp_fieldsKeySpecifier
)[];
export type currencies_stddev_samp_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_sum_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'id'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'venus_borrowApy'
  | 'venus_supplyApy'
  | currencies_sum_fieldsKeySpecifier
)[];
export type currencies_sum_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_var_pop_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'id'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'venus_borrowApy'
  | 'venus_supplyApy'
  | currencies_var_pop_fieldsKeySpecifier
)[];
export type currencies_var_pop_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_var_samp_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'id'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'venus_borrowApy'
  | 'venus_supplyApy'
  | currencies_var_samp_fieldsKeySpecifier
)[];
export type currencies_var_samp_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currencies_variance_fieldsKeySpecifier = (
  | 'apy_distribution'
  | 'apy_supply'
  | 'id'
  | 'rate_btc'
  | 'rate_last_update'
  | 'rate_usd'
  | 'venus_borrowApy'
  | 'venus_supplyApy'
  | currencies_variance_fieldsKeySpecifier
)[];
export type currencies_variance_fieldsFieldPolicy = {
  apy_distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  apy_supply?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_btc?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_last_update?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_borrowApy?: FieldPolicy<any> | FieldReadFunction<any>;
  venus_supplyApy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_historyKeySpecifier = (
  | 'created_at'
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | 'source'
  | currency_historyKeySpecifier
)[];
export type currency_historyFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | currency_history_aggregateKeySpecifier
)[];
export type currency_history_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | currency_history_aggregate_fieldsKeySpecifier
)[];
export type currency_history_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_avg_fieldsKeySpecifier = (
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | currency_history_avg_fieldsKeySpecifier
)[];
export type currency_history_avg_fieldsFieldPolicy = {
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_max_fieldsKeySpecifier = (
  | 'created_at'
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | 'source'
  | currency_history_max_fieldsKeySpecifier
)[];
export type currency_history_max_fieldsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_min_fieldsKeySpecifier = (
  | 'created_at'
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | 'source'
  | currency_history_min_fieldsKeySpecifier
)[];
export type currency_history_min_fieldsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | currency_history_mutation_responseKeySpecifier
)[];
export type currency_history_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_stddev_fieldsKeySpecifier = (
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | currency_history_stddev_fieldsKeySpecifier
)[];
export type currency_history_stddev_fieldsFieldPolicy = {
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_stddev_pop_fieldsKeySpecifier = (
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | currency_history_stddev_pop_fieldsKeySpecifier
)[];
export type currency_history_stddev_pop_fieldsFieldPolicy = {
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_stddev_samp_fieldsKeySpecifier = (
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | currency_history_stddev_samp_fieldsKeySpecifier
)[];
export type currency_history_stddev_samp_fieldsFieldPolicy = {
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_sum_fieldsKeySpecifier = (
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | currency_history_sum_fieldsKeySpecifier
)[];
export type currency_history_sum_fieldsFieldPolicy = {
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_var_pop_fieldsKeySpecifier = (
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | currency_history_var_pop_fieldsKeySpecifier
)[];
export type currency_history_var_pop_fieldsFieldPolicy = {
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_var_samp_fieldsKeySpecifier = (
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | currency_history_var_samp_fieldsKeySpecifier
)[];
export type currency_history_var_samp_fieldsFieldPolicy = {
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type currency_history_variance_fieldsKeySpecifier = (
  | 'currency_id'
  | 'id'
  | 'rate_usd'
  | currency_history_variance_fieldsKeySpecifier
)[];
export type currency_history_variance_fieldsFieldPolicy = {
  currency_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate_usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type depositAddressOutputKeySpecifier = (
  | 'address'
  | 'blockchainId'
  | 'message'
  | depositAddressOutputKeySpecifier
)[];
export type depositAddressOutputFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addressesKeySpecifier = (
  | 'blockchain_id'
  | 'contract_address'
  | 'created_at'
  | 'id'
  | 'read'
  | 'token_name'
  | 'token_symbol'
  | 'updated_at'
  | erc721_addressesKeySpecifier
)[];
export type erc721_addressesFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contract_address?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  read?: FieldPolicy<any> | FieldReadFunction<any>;
  token_name?: FieldPolicy<any> | FieldReadFunction<any>;
  token_symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | erc721_addresses_aggregateKeySpecifier
)[];
export type erc721_addresses_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | erc721_addresses_aggregate_fieldsKeySpecifier
)[];
export type erc721_addresses_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_avg_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | erc721_addresses_avg_fieldsKeySpecifier
)[];
export type erc721_addresses_avg_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_max_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'contract_address'
  | 'created_at'
  | 'id'
  | 'token_name'
  | 'token_symbol'
  | 'updated_at'
  | erc721_addresses_max_fieldsKeySpecifier
)[];
export type erc721_addresses_max_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contract_address?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  token_name?: FieldPolicy<any> | FieldReadFunction<any>;
  token_symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_min_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'contract_address'
  | 'created_at'
  | 'id'
  | 'token_name'
  | 'token_symbol'
  | 'updated_at'
  | erc721_addresses_min_fieldsKeySpecifier
)[];
export type erc721_addresses_min_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contract_address?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  token_name?: FieldPolicy<any> | FieldReadFunction<any>;
  token_symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | erc721_addresses_mutation_responseKeySpecifier
)[];
export type erc721_addresses_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_stddev_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | erc721_addresses_stddev_fieldsKeySpecifier
)[];
export type erc721_addresses_stddev_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_stddev_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | erc721_addresses_stddev_pop_fieldsKeySpecifier
)[];
export type erc721_addresses_stddev_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_stddev_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | erc721_addresses_stddev_samp_fieldsKeySpecifier
)[];
export type erc721_addresses_stddev_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_sum_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | erc721_addresses_sum_fieldsKeySpecifier
)[];
export type erc721_addresses_sum_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_var_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | erc721_addresses_var_pop_fieldsKeySpecifier
)[];
export type erc721_addresses_var_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_var_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | erc721_addresses_var_samp_fieldsKeySpecifier
)[];
export type erc721_addresses_var_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type erc721_addresses_variance_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | erc721_addresses_variance_fieldsKeySpecifier
)[];
export type erc721_addresses_variance_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalancesKeySpecifier = (
  | 'affiliateGpanelId'
  | 'affiliateUserId'
  | 'affiliateUsername'
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | 'playerGpanelId'
  | 'playerUserId'
  | 'playerUsername'
  | evenbetAffiliatePlayerBalancesKeySpecifier
)[];
export type evenbetAffiliatePlayerBalancesFieldPolicy = {
  affiliateGpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  affiliateUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  affiliateUsername?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerUsername?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | evenbetAffiliatePlayerBalances_aggregateKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | evenbetAffiliatePlayerBalances_aggregate_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_avg_fieldsKeySpecifier = (
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | evenbetAffiliatePlayerBalances_avg_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_avg_fieldsFieldPolicy = {
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_max_fieldsKeySpecifier = (
  | 'affiliateGpanelId'
  | 'affiliateUserId'
  | 'affiliateUsername'
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | 'playerGpanelId'
  | 'playerUserId'
  | 'playerUsername'
  | evenbetAffiliatePlayerBalances_max_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_max_fieldsFieldPolicy = {
  affiliateGpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  affiliateUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  affiliateUsername?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerUsername?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_min_fieldsKeySpecifier = (
  | 'affiliateGpanelId'
  | 'affiliateUserId'
  | 'affiliateUsername'
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | 'playerGpanelId'
  | 'playerUserId'
  | 'playerUsername'
  | evenbetAffiliatePlayerBalances_min_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_min_fieldsFieldPolicy = {
  affiliateGpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  affiliateUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  affiliateUsername?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerUsername?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_stddev_fieldsKeySpecifier = (
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | evenbetAffiliatePlayerBalances_stddev_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_stddev_fieldsFieldPolicy = {
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_stddev_pop_fieldsKeySpecifier = (
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | evenbetAffiliatePlayerBalances_stddev_pop_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_stddev_pop_fieldsFieldPolicy = {
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_stddev_samp_fieldsKeySpecifier = (
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | evenbetAffiliatePlayerBalances_stddev_samp_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_stddev_samp_fieldsFieldPolicy = {
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_sum_fieldsKeySpecifier = (
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | evenbetAffiliatePlayerBalances_sum_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_sum_fieldsFieldPolicy = {
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_var_pop_fieldsKeySpecifier = (
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | evenbetAffiliatePlayerBalances_var_pop_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_var_pop_fieldsFieldPolicy = {
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_var_samp_fieldsKeySpecifier = (
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | evenbetAffiliatePlayerBalances_var_samp_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_var_samp_fieldsFieldPolicy = {
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbetAffiliatePlayerBalances_variance_fieldsKeySpecifier = (
  | 'cashBalance'
  | 'cashBalanceInp'
  | 'cashBalanceUsd'
  | evenbetAffiliatePlayerBalances_variance_fieldsKeySpecifier
)[];
export type evenbetAffiliatePlayerBalances_variance_fieldsFieldPolicy = {
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceInp?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalanceUsd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requestsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'evenbet_id'
  | 'grouped_request_id'
  | 'id'
  | 'nft_id'
  | 'status'
  | 'ticket_template'
  | 'updated_at'
  | 'value'
  | evenbet_ticket_requestsKeySpecifier
)[];
export type evenbet_ticket_requestsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  grouped_request_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | evenbet_ticket_requests_aggregateKeySpecifier
)[];
export type evenbet_ticket_requests_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | evenbet_ticket_requests_aggregate_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'nft_id'
  | 'ticket_template'
  | 'value'
  | evenbet_ticket_requests_avg_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_max_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'evenbet_id'
  | 'grouped_request_id'
  | 'id'
  | 'nft_id'
  | 'status'
  | 'ticket_template'
  | 'updated_at'
  | 'value'
  | evenbet_ticket_requests_max_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  grouped_request_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_min_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'evenbet_id'
  | 'grouped_request_id'
  | 'id'
  | 'nft_id'
  | 'status'
  | 'ticket_template'
  | 'updated_at'
  | 'value'
  | evenbet_ticket_requests_min_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  grouped_request_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | evenbet_ticket_requests_mutation_responseKeySpecifier
)[];
export type evenbet_ticket_requests_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'nft_id'
  | 'ticket_template'
  | 'value'
  | evenbet_ticket_requests_stddev_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'nft_id'
  | 'ticket_template'
  | 'value'
  | evenbet_ticket_requests_stddev_pop_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'nft_id'
  | 'ticket_template'
  | 'value'
  | evenbet_ticket_requests_stddev_samp_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'nft_id'
  | 'ticket_template'
  | 'value'
  | evenbet_ticket_requests_sum_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'nft_id'
  | 'ticket_template'
  | 'value'
  | evenbet_ticket_requests_var_pop_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'nft_id'
  | 'ticket_template'
  | 'value'
  | evenbet_ticket_requests_var_samp_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticket_requests_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'nft_id'
  | 'ticket_template'
  | 'value'
  | evenbet_ticket_requests_variance_fieldsKeySpecifier
)[];
export type evenbet_ticket_requests_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket_template?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_ticketsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'evenbet_id'
  | 'expiration_date'
  | 'id'
  | 'issued_at'
  | 'money_type'
  | 'name'
  | 'spend_for'
  | 'status'
  | 'updated_at'
  | 'used_at'
  | evenbet_ticketsKeySpecifier
)[];
export type evenbet_ticketsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  expiration_date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  issued_at?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  used_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | evenbet_tickets_aggregateKeySpecifier
)[];
export type evenbet_tickets_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | evenbet_tickets_aggregate_fieldsKeySpecifier
)[];
export type evenbet_tickets_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'spend_for'
  | evenbet_tickets_avg_fieldsKeySpecifier
)[];
export type evenbet_tickets_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_max_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'evenbet_id'
  | 'expiration_date'
  | 'id'
  | 'issued_at'
  | 'money_type'
  | 'name'
  | 'spend_for'
  | 'status'
  | 'updated_at'
  | 'used_at'
  | evenbet_tickets_max_fieldsKeySpecifier
)[];
export type evenbet_tickets_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  expiration_date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  issued_at?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  used_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_min_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'evenbet_id'
  | 'expiration_date'
  | 'id'
  | 'issued_at'
  | 'money_type'
  | 'name'
  | 'spend_for'
  | 'status'
  | 'updated_at'
  | 'used_at'
  | evenbet_tickets_min_fieldsKeySpecifier
)[];
export type evenbet_tickets_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  expiration_date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  issued_at?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  used_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | evenbet_tickets_mutation_responseKeySpecifier
)[];
export type evenbet_tickets_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'spend_for'
  | evenbet_tickets_stddev_fieldsKeySpecifier
)[];
export type evenbet_tickets_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'spend_for'
  | evenbet_tickets_stddev_pop_fieldsKeySpecifier
)[];
export type evenbet_tickets_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'spend_for'
  | evenbet_tickets_stddev_samp_fieldsKeySpecifier
)[];
export type evenbet_tickets_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'spend_for'
  | evenbet_tickets_sum_fieldsKeySpecifier
)[];
export type evenbet_tickets_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'spend_for'
  | evenbet_tickets_var_pop_fieldsKeySpecifier
)[];
export type evenbet_tickets_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'spend_for'
  | evenbet_tickets_var_samp_fieldsKeySpecifier
)[];
export type evenbet_tickets_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type evenbet_tickets_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'evenbet_id'
  | 'id'
  | 'spend_for'
  | evenbet_tickets_variance_fieldsKeySpecifier
)[];
export type evenbet_tickets_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  spend_for?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gPanelOutputKeySpecifier = (
  | 'accessToken'
  | 'message'
  | 'refreshToken'
  | gPanelOutputKeySpecifier
)[];
export type gPanelOutputFieldPolicy = {
  accessToken?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPaymentsKeySpecifier = (
  | 'amount'
  | 'createdAt'
  | 'currency'
  | 'date'
  | 'gpanelId'
  | 'gpanelUserId'
  | 'gpanel_user'
  | 'id'
  | 'identity'
  | 'method'
  | 'status'
  | 'type'
  | 'updatedAt'
  | gpanelPaymentsKeySpecifier
)[];
export type gpanelPaymentsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  identity?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gpanelPayments_aggregateKeySpecifier
)[];
export type gpanelPayments_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | gpanelPayments_aggregate_fieldsKeySpecifier
)[];
export type gpanelPayments_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | gpanelPayments_avg_fieldsKeySpecifier
)[];
export type gpanelPayments_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_max_fieldsKeySpecifier = (
  | 'amount'
  | 'createdAt'
  | 'currency'
  | 'date'
  | 'gpanelId'
  | 'gpanelUserId'
  | 'id'
  | 'identity'
  | 'method'
  | 'status'
  | 'type'
  | 'updatedAt'
  | gpanelPayments_max_fieldsKeySpecifier
)[];
export type gpanelPayments_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  identity?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_min_fieldsKeySpecifier = (
  | 'amount'
  | 'createdAt'
  | 'currency'
  | 'date'
  | 'gpanelId'
  | 'gpanelUserId'
  | 'id'
  | 'identity'
  | 'method'
  | 'status'
  | 'type'
  | 'updatedAt'
  | gpanelPayments_min_fieldsKeySpecifier
)[];
export type gpanelPayments_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  identity?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gpanelPayments_mutation_responseKeySpecifier
)[];
export type gpanelPayments_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | gpanelPayments_stddev_fieldsKeySpecifier
)[];
export type gpanelPayments_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | gpanelPayments_stddev_pop_fieldsKeySpecifier
)[];
export type gpanelPayments_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | gpanelPayments_stddev_samp_fieldsKeySpecifier
)[];
export type gpanelPayments_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | gpanelPayments_sum_fieldsKeySpecifier
)[];
export type gpanelPayments_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | gpanelPayments_var_pop_fieldsKeySpecifier
)[];
export type gpanelPayments_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | gpanelPayments_var_samp_fieldsKeySpecifier
)[];
export type gpanelPayments_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelPayments_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | gpanelPayments_variance_fieldsKeySpecifier
)[];
export type gpanelPayments_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsersKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'createdAt'
  | 'email'
  | 'evenbet_id'
  | 'gpanelId'
  | 'gpanel_payments'
  | 'gpanel_payments_aggregate'
  | 'id'
  | 'kyc'
  | 'kycPending'
  | 'kycStatus'
  | 'registered'
  | 'state'
  | 'state_internal'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | 'username'
  | gpanelUsersKeySpecifier
)[];
export type gpanelUsersFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_payments?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_payments_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  kycPending?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  registered?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  state_internal?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gpanelUsers_aggregateKeySpecifier
)[];
export type gpanelUsers_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | gpanelUsers_aggregate_fieldsKeySpecifier
)[];
export type gpanelUsers_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_avg_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'evenbet_id'
  | 'id'
  | 'kycStatus'
  | gpanelUsers_avg_fieldsKeySpecifier
)[];
export type gpanelUsers_avg_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_max_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'createdAt'
  | 'email'
  | 'evenbet_id'
  | 'gpanelId'
  | 'id'
  | 'kycStatus'
  | 'registered'
  | 'state'
  | 'state_internal'
  | 'updatedAt'
  | 'userId'
  | 'username'
  | gpanelUsers_max_fieldsKeySpecifier
)[];
export type gpanelUsers_max_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  registered?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  state_internal?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_min_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'createdAt'
  | 'email'
  | 'evenbet_id'
  | 'gpanelId'
  | 'id'
  | 'kycStatus'
  | 'registered'
  | 'state'
  | 'state_internal'
  | 'updatedAt'
  | 'userId'
  | 'username'
  | gpanelUsers_min_fieldsKeySpecifier
)[];
export type gpanelUsers_min_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  registered?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  state_internal?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gpanelUsers_mutation_responseKeySpecifier
)[];
export type gpanelUsers_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_stddev_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'evenbet_id'
  | 'id'
  | 'kycStatus'
  | gpanelUsers_stddev_fieldsKeySpecifier
)[];
export type gpanelUsers_stddev_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_stddev_pop_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'evenbet_id'
  | 'id'
  | 'kycStatus'
  | gpanelUsers_stddev_pop_fieldsKeySpecifier
)[];
export type gpanelUsers_stddev_pop_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_stddev_samp_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'evenbet_id'
  | 'id'
  | 'kycStatus'
  | gpanelUsers_stddev_samp_fieldsKeySpecifier
)[];
export type gpanelUsers_stddev_samp_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_sum_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'evenbet_id'
  | 'id'
  | 'kycStatus'
  | gpanelUsers_sum_fieldsKeySpecifier
)[];
export type gpanelUsers_sum_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_var_pop_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'evenbet_id'
  | 'id'
  | 'kycStatus'
  | gpanelUsers_var_pop_fieldsKeySpecifier
)[];
export type gpanelUsers_var_pop_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_var_samp_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'evenbet_id'
  | 'id'
  | 'kycStatus'
  | gpanelUsers_var_samp_fieldsKeySpecifier
)[];
export type gpanelUsers_var_samp_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanelUsers_variance_fieldsKeySpecifier = (
  | 'avatar_id'
  | 'bonusBalance'
  | 'cashBalance'
  | 'cash_balance_inp'
  | 'cash_balance_usd'
  | 'evenbet_id'
  | 'id'
  | 'kycStatus'
  | gpanelUsers_variance_fieldsKeySpecifier
)[];
export type gpanelUsers_variance_fieldsFieldPolicy = {
  avatar_id?: FieldPolicy<any> | FieldReadFunction<any>;
  bonusBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cashBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  cash_balance_usd?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kycStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agentKeySpecifier = (
  | 'agent_of_gpanel_user_id'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'status'
  | 'updated_at'
  | gpanel_users_agentKeySpecifier
)[];
export type gpanel_users_agentFieldPolicy = {
  agent_of_gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gpanel_users_agent_aggregateKeySpecifier
)[];
export type gpanel_users_agent_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | gpanel_users_agent_aggregate_fieldsKeySpecifier
)[];
export type gpanel_users_agent_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_avg_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_agent_avg_fieldsKeySpecifier
)[];
export type gpanel_users_agent_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_max_fieldsKeySpecifier = (
  | 'agent_of_gpanel_user_id'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'status'
  | 'updated_at'
  | gpanel_users_agent_max_fieldsKeySpecifier
)[];
export type gpanel_users_agent_max_fieldsFieldPolicy = {
  agent_of_gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_min_fieldsKeySpecifier = (
  | 'agent_of_gpanel_user_id'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'status'
  | 'updated_at'
  | gpanel_users_agent_min_fieldsKeySpecifier
)[];
export type gpanel_users_agent_min_fieldsFieldPolicy = {
  agent_of_gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gpanel_users_agent_mutation_responseKeySpecifier
)[];
export type gpanel_users_agent_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_stddev_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_agent_stddev_fieldsKeySpecifier
)[];
export type gpanel_users_agent_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_agent_stddev_pop_fieldsKeySpecifier
)[];
export type gpanel_users_agent_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_agent_stddev_samp_fieldsKeySpecifier
)[];
export type gpanel_users_agent_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_sum_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_agent_sum_fieldsKeySpecifier
)[];
export type gpanel_users_agent_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_var_pop_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_agent_var_pop_fieldsKeySpecifier
)[];
export type gpanel_users_agent_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_var_samp_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_agent_var_samp_fieldsKeySpecifier
)[];
export type gpanel_users_agent_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_agent_variance_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_agent_variance_fieldsKeySpecifier
)[];
export type gpanel_users_agent_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_addressKeySpecifier = (
  | 'address'
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'tx'
  | 'updated_at'
  | gpanel_users_deposit_addressKeySpecifier
)[];
export type gpanel_users_deposit_addressFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tx?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gpanel_users_deposit_address_aggregateKeySpecifier
)[];
export type gpanel_users_deposit_address_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | gpanel_users_deposit_address_aggregate_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_avg_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'id'
  | gpanel_users_deposit_address_avg_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_avg_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_max_fieldsKeySpecifier = (
  | 'address'
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'tx'
  | 'updated_at'
  | gpanel_users_deposit_address_max_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_max_fieldsFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tx?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_min_fieldsKeySpecifier = (
  | 'address'
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'tx'
  | 'updated_at'
  | gpanel_users_deposit_address_min_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_min_fieldsFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tx?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gpanel_users_deposit_address_mutation_responseKeySpecifier
)[];
export type gpanel_users_deposit_address_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_stddev_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'id'
  | gpanel_users_deposit_address_stddev_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_stddev_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_stddev_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'id'
  | gpanel_users_deposit_address_stddev_pop_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_stddev_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_stddev_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'id'
  | gpanel_users_deposit_address_stddev_samp_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_stddev_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_sum_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'id'
  | gpanel_users_deposit_address_sum_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_sum_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_var_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'id'
  | gpanel_users_deposit_address_var_pop_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_var_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_var_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'id'
  | gpanel_users_deposit_address_var_samp_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_var_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_deposit_address_variance_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blockchain_main_deposit_id'
  | 'id'
  | gpanel_users_deposit_address_variance_fieldsKeySpecifier
)[];
export type gpanel_users_deposit_address_variance_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kycKeySpecifier = (
  | 'approvedDate'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'inreviewDate'
  | 'levelName'
  | 'provider'
  | 'recordId'
  | 'reviewResult'
  | 'status'
  | 'type'
  | 'updated_at'
  | 'waitingDate'
  | gpanel_users_kycKeySpecifier
)[];
export type gpanel_users_kycFieldPolicy = {
  approvedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inreviewDate?: FieldPolicy<any> | FieldReadFunction<any>;
  levelName?: FieldPolicy<any> | FieldReadFunction<any>;
  provider?: FieldPolicy<any> | FieldReadFunction<any>;
  recordId?: FieldPolicy<any> | FieldReadFunction<any>;
  reviewResult?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  waitingDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gpanel_users_kyc_aggregateKeySpecifier
)[];
export type gpanel_users_kyc_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | gpanel_users_kyc_aggregate_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_avg_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_kyc_avg_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_max_fieldsKeySpecifier = (
  | 'approvedDate'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'inreviewDate'
  | 'levelName'
  | 'provider'
  | 'recordId'
  | 'reviewResult'
  | 'status'
  | 'type'
  | 'updated_at'
  | 'waitingDate'
  | gpanel_users_kyc_max_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_max_fieldsFieldPolicy = {
  approvedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inreviewDate?: FieldPolicy<any> | FieldReadFunction<any>;
  levelName?: FieldPolicy<any> | FieldReadFunction<any>;
  provider?: FieldPolicy<any> | FieldReadFunction<any>;
  recordId?: FieldPolicy<any> | FieldReadFunction<any>;
  reviewResult?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  waitingDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_min_fieldsKeySpecifier = (
  | 'approvedDate'
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'inreviewDate'
  | 'levelName'
  | 'provider'
  | 'recordId'
  | 'reviewResult'
  | 'status'
  | 'type'
  | 'updated_at'
  | 'waitingDate'
  | gpanel_users_kyc_min_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_min_fieldsFieldPolicy = {
  approvedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inreviewDate?: FieldPolicy<any> | FieldReadFunction<any>;
  levelName?: FieldPolicy<any> | FieldReadFunction<any>;
  provider?: FieldPolicy<any> | FieldReadFunction<any>;
  recordId?: FieldPolicy<any> | FieldReadFunction<any>;
  reviewResult?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  waitingDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gpanel_users_kyc_mutation_responseKeySpecifier
)[];
export type gpanel_users_kyc_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_stddev_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_kyc_stddev_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_kyc_stddev_pop_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_kyc_stddev_samp_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_sum_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_kyc_sum_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_var_pop_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_kyc_var_pop_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_var_samp_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_kyc_var_samp_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type gpanel_users_kyc_variance_fieldsKeySpecifier = (
  | 'id'
  | gpanel_users_kyc_variance_fieldsKeySpecifier
)[];
export type gpanel_users_kyc_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycOutputKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'info'
  | 'level'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycOutputKeySpecifier
)[];
export type kycOutputFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  info?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'info'
  | 'level'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycsKeySpecifier
)[];
export type kycsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  info?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | kycs_aggregateKeySpecifier
)[];
export type kycs_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | kycs_aggregate_fieldsKeySpecifier
)[];
export type kycs_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_avg_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_avg_fieldsKeySpecifier
)[];
export type kycs_avg_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_max_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'info'
  | 'level'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_max_fieldsKeySpecifier
)[];
export type kycs_max_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  info?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_min_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'info'
  | 'level'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_min_fieldsKeySpecifier
)[];
export type kycs_min_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  info?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | kycs_mutation_responseKeySpecifier
)[];
export type kycs_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_stddev_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_stddev_fieldsKeySpecifier
)[];
export type kycs_stddev_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_stddev_pop_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_stddev_pop_fieldsKeySpecifier
)[];
export type kycs_stddev_pop_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_stddev_samp_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_stddev_samp_fieldsKeySpecifier
)[];
export type kycs_stddev_samp_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_sum_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_sum_fieldsKeySpecifier
)[];
export type kycs_sum_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_var_pop_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_var_pop_fieldsKeySpecifier
)[];
export type kycs_var_pop_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_var_samp_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_var_samp_fieldsKeySpecifier
)[];
export type kycs_var_samp_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type kycs_variance_fieldsKeySpecifier = (
  | 'feeDeposit'
  | 'feeDepositInp'
  | 'feeWithdraw'
  | 'feeWithdrawInp'
  | 'id'
  | 'maxDeposit'
  | 'maxDepositInp'
  | 'maxWithdraw'
  | 'maxWithdrawInp'
  | 'minDeposit'
  | 'minDepositInp'
  | 'minWithdraw'
  | 'minWithdrawInp'
  | kycs_variance_fieldsKeySpecifier
)[];
export type kycs_variance_fieldsFieldPolicy = {
  feeDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  feeWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  minDepositInp?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  minWithdrawInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboardKeySpecifier = (
  | 'created_at'
  | 'end'
  | 'id'
  | 'money_type'
  | 'name'
  | 'reward'
  | 'start'
  | 'updated_at'
  | 'website_id'
  | leaderboardKeySpecifier
)[];
export type leaderboardFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  reward?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  website_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | leaderboard_aggregateKeySpecifier
)[];
export type leaderboard_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | leaderboard_aggregate_fieldsKeySpecifier
)[];
export type leaderboard_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_avg_fieldsKeySpecifier = (
  | 'id'
  | leaderboard_avg_fieldsKeySpecifier
)[];
export type leaderboard_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_max_fieldsKeySpecifier = (
  | 'created_at'
  | 'end'
  | 'id'
  | 'money_type'
  | 'name'
  | 'reward'
  | 'start'
  | 'updated_at'
  | 'website_id'
  | leaderboard_max_fieldsKeySpecifier
)[];
export type leaderboard_max_fieldsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  reward?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  website_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_min_fieldsKeySpecifier = (
  | 'created_at'
  | 'end'
  | 'id'
  | 'money_type'
  | 'name'
  | 'reward'
  | 'start'
  | 'updated_at'
  | 'website_id'
  | leaderboard_min_fieldsKeySpecifier
)[];
export type leaderboard_min_fieldsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money_type?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  reward?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  website_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | leaderboard_mutation_responseKeySpecifier
)[];
export type leaderboard_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_rankingKeySpecifier = (
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | 'updated_at'
  | 'username'
  | leaderboard_rankingKeySpecifier
)[];
export type leaderboard_rankingFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | leaderboard_ranking_aggregateKeySpecifier
)[];
export type leaderboard_ranking_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | leaderboard_ranking_aggregate_fieldsKeySpecifier
)[];
export type leaderboard_ranking_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_avg_fieldsKeySpecifier = (
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | leaderboard_ranking_avg_fieldsKeySpecifier
)[];
export type leaderboard_ranking_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_max_fieldsKeySpecifier = (
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | 'updated_at'
  | 'username'
  | leaderboard_ranking_max_fieldsKeySpecifier
)[];
export type leaderboard_ranking_max_fieldsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_min_fieldsKeySpecifier = (
  | 'created_at'
  | 'gpanel_user_id'
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | 'updated_at'
  | 'username'
  | leaderboard_ranking_min_fieldsKeySpecifier
)[];
export type leaderboard_ranking_min_fieldsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | leaderboard_ranking_mutation_responseKeySpecifier
)[];
export type leaderboard_ranking_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_stddev_fieldsKeySpecifier = (
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | leaderboard_ranking_stddev_fieldsKeySpecifier
)[];
export type leaderboard_ranking_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | leaderboard_ranking_stddev_pop_fieldsKeySpecifier
)[];
export type leaderboard_ranking_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | leaderboard_ranking_stddev_samp_fieldsKeySpecifier
)[];
export type leaderboard_ranking_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_sum_fieldsKeySpecifier = (
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | leaderboard_ranking_sum_fieldsKeySpecifier
)[];
export type leaderboard_ranking_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_var_pop_fieldsKeySpecifier = (
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | leaderboard_ranking_var_pop_fieldsKeySpecifier
)[];
export type leaderboard_ranking_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_var_samp_fieldsKeySpecifier = (
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | leaderboard_ranking_var_samp_fieldsKeySpecifier
)[];
export type leaderboard_ranking_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_ranking_variance_fieldsKeySpecifier = (
  | 'id'
  | 'leaderboard_id'
  | 'score'
  | leaderboard_ranking_variance_fieldsKeySpecifier
)[];
export type leaderboard_ranking_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_id?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_stddev_fieldsKeySpecifier = (
  | 'id'
  | leaderboard_stddev_fieldsKeySpecifier
)[];
export type leaderboard_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | leaderboard_stddev_pop_fieldsKeySpecifier
)[];
export type leaderboard_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | leaderboard_stddev_samp_fieldsKeySpecifier
)[];
export type leaderboard_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_sum_fieldsKeySpecifier = (
  | 'id'
  | leaderboard_sum_fieldsKeySpecifier
)[];
export type leaderboard_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_var_pop_fieldsKeySpecifier = (
  | 'id'
  | leaderboard_var_pop_fieldsKeySpecifier
)[];
export type leaderboard_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_var_samp_fieldsKeySpecifier = (
  | 'id'
  | leaderboard_var_samp_fieldsKeySpecifier
)[];
export type leaderboard_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type leaderboard_variance_fieldsKeySpecifier = (
  | 'id'
  | leaderboard_variance_fieldsKeySpecifier
)[];
export type leaderboard_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_poolsKeySpecifier = (
  | 'contract'
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | 'status'
  | liquidity_poolsKeySpecifier
)[];
export type liquidity_poolsFieldPolicy = {
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | liquidity_pools_aggregateKeySpecifier
)[];
export type liquidity_pools_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | liquidity_pools_aggregate_fieldsKeySpecifier
)[];
export type liquidity_pools_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_avg_fieldsKeySpecifier = (
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | liquidity_pools_avg_fieldsKeySpecifier
)[];
export type liquidity_pools_avg_fieldsFieldPolicy = {
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_max_fieldsKeySpecifier = (
  | 'contract'
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | 'status'
  | liquidity_pools_max_fieldsKeySpecifier
)[];
export type liquidity_pools_max_fieldsFieldPolicy = {
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_min_fieldsKeySpecifier = (
  | 'contract'
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | 'status'
  | liquidity_pools_min_fieldsKeySpecifier
)[];
export type liquidity_pools_min_fieldsFieldPolicy = {
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | liquidity_pools_mutation_responseKeySpecifier
)[];
export type liquidity_pools_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_stddev_fieldsKeySpecifier = (
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | liquidity_pools_stddev_fieldsKeySpecifier
)[];
export type liquidity_pools_stddev_fieldsFieldPolicy = {
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_stddev_pop_fieldsKeySpecifier = (
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | liquidity_pools_stddev_pop_fieldsKeySpecifier
)[];
export type liquidity_pools_stddev_pop_fieldsFieldPolicy = {
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_stddev_samp_fieldsKeySpecifier = (
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | liquidity_pools_stddev_samp_fieldsKeySpecifier
)[];
export type liquidity_pools_stddev_samp_fieldsFieldPolicy = {
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_sum_fieldsKeySpecifier = (
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | liquidity_pools_sum_fieldsKeySpecifier
)[];
export type liquidity_pools_sum_fieldsFieldPolicy = {
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_var_pop_fieldsKeySpecifier = (
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | liquidity_pools_var_pop_fieldsKeySpecifier
)[];
export type liquidity_pools_var_pop_fieldsFieldPolicy = {
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_var_samp_fieldsKeySpecifier = (
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | liquidity_pools_var_samp_fieldsKeySpecifier
)[];
export type liquidity_pools_var_samp_fieldsFieldPolicy = {
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type liquidity_pools_variance_fieldsKeySpecifier = (
  | 'currency_id1'
  | 'currency_id2'
  | 'id'
  | liquidity_pools_variance_fieldsKeySpecifier
)[];
export type liquidity_pools_variance_fieldsFieldPolicy = {
  currency_id1?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_id2?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListingsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'buyer'
  | 'createdAt'
  | 'endedAt'
  | 'erc20Address'
  | 'id'
  | 'isErc721'
  | 'lastBidder'
  | 'marketplaceContract'
  | 'marketplaceContractId'
  | 'nft'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'seller'
  | 'stakeCount'
  | 'state'
  | 'updatedAt'
  | marketListingsKeySpecifier
)[];
export type marketListingsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  buyer?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isErc721?: FieldPolicy<any> | FieldReadFunction<any>;
  lastBidder?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContract?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nft?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seller?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | marketListings_aggregateKeySpecifier
)[];
export type marketListings_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | marketListings_aggregate_fieldsKeySpecifier
)[];
export type marketListings_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'endedAt'
  | 'id'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | marketListings_avg_fieldsKeySpecifier
)[];
export type marketListings_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_max_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'buyer'
  | 'createdAt'
  | 'endedAt'
  | 'erc20Address'
  | 'id'
  | 'lastBidder'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'seller'
  | 'stakeCount'
  | 'state'
  | 'updatedAt'
  | marketListings_max_fieldsKeySpecifier
)[];
export type marketListings_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  buyer?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastBidder?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seller?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_min_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'buyer'
  | 'createdAt'
  | 'endedAt'
  | 'erc20Address'
  | 'id'
  | 'lastBidder'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'seller'
  | 'stakeCount'
  | 'state'
  | 'updatedAt'
  | marketListings_min_fieldsKeySpecifier
)[];
export type marketListings_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  buyer?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastBidder?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seller?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | marketListings_mutation_responseKeySpecifier
)[];
export type marketListings_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'endedAt'
  | 'id'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | marketListings_stddev_fieldsKeySpecifier
)[];
export type marketListings_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'endedAt'
  | 'id'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | marketListings_stddev_pop_fieldsKeySpecifier
)[];
export type marketListings_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'endedAt'
  | 'id'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | marketListings_stddev_samp_fieldsKeySpecifier
)[];
export type marketListings_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'endedAt'
  | 'id'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | marketListings_sum_fieldsKeySpecifier
)[];
export type marketListings_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'endedAt'
  | 'id'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | marketListings_var_pop_fieldsKeySpecifier
)[];
export type marketListings_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'endedAt'
  | 'id'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | marketListings_var_samp_fieldsKeySpecifier
)[];
export type marketListings_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketListings_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchainId'
  | 'endedAt'
  | 'id'
  | 'marketplaceContractId'
  | 'nftId'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | marketListings_variance_fieldsKeySpecifier
)[];
export type marketListings_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContractsKeySpecifier = (
  | 'blockNumber'
  | 'chain'
  | 'contractAddress'
  | 'createdAt'
  | 'fee'
  | 'feeRecipient'
  | 'id'
  | 'type'
  | 'updatedAt'
  | marketplaceContractsKeySpecifier
)[];
export type marketplaceContractsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  chain?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  feeRecipient?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | marketplaceContracts_aggregateKeySpecifier
)[];
export type marketplaceContracts_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | marketplaceContracts_aggregate_fieldsKeySpecifier
)[];
export type marketplaceContracts_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_avg_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'fee'
  | 'id'
  | marketplaceContracts_avg_fieldsKeySpecifier
)[];
export type marketplaceContracts_avg_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_max_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'chain'
  | 'contractAddress'
  | 'createdAt'
  | 'fee'
  | 'feeRecipient'
  | 'id'
  | 'type'
  | 'updatedAt'
  | marketplaceContracts_max_fieldsKeySpecifier
)[];
export type marketplaceContracts_max_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  chain?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  feeRecipient?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_min_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'chain'
  | 'contractAddress'
  | 'createdAt'
  | 'fee'
  | 'feeRecipient'
  | 'id'
  | 'type'
  | 'updatedAt'
  | marketplaceContracts_min_fieldsKeySpecifier
)[];
export type marketplaceContracts_min_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  chain?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  feeRecipient?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | marketplaceContracts_mutation_responseKeySpecifier
)[];
export type marketplaceContracts_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_stddev_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'fee'
  | 'id'
  | marketplaceContracts_stddev_fieldsKeySpecifier
)[];
export type marketplaceContracts_stddev_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_stddev_pop_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'fee'
  | 'id'
  | marketplaceContracts_stddev_pop_fieldsKeySpecifier
)[];
export type marketplaceContracts_stddev_pop_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_stddev_samp_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'fee'
  | 'id'
  | marketplaceContracts_stddev_samp_fieldsKeySpecifier
)[];
export type marketplaceContracts_stddev_samp_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_sum_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'fee'
  | 'id'
  | marketplaceContracts_sum_fieldsKeySpecifier
)[];
export type marketplaceContracts_sum_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_var_pop_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'fee'
  | 'id'
  | marketplaceContracts_var_pop_fieldsKeySpecifier
)[];
export type marketplaceContracts_var_pop_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_var_samp_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'fee'
  | 'id'
  | marketplaceContracts_var_samp_fieldsKeySpecifier
)[];
export type marketplaceContracts_var_samp_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type marketplaceContracts_variance_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'fee'
  | 'id'
  | marketplaceContracts_variance_fieldsKeySpecifier
)[];
export type marketplaceContracts_variance_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type mutation_rootKeySpecifier = (
  | 'NFTDelegationAccept'
  | 'NFTReleaseTicket'
  | 'NFTRentOut'
  | 'agentPaymentSubmitDeposit'
  | 'agentPaymentSubmitWithdraw'
  | 'agentPaymentSubmittedDeposit'
  | 'deleteBlockchain'
  | 'deleteBlockchains'
  | 'deleteGpanelPayment'
  | 'deleteGpanelPayments'
  | 'deleteGpanelUser'
  | 'deleteGpanelUsers'
  | 'deleteMarketListing'
  | 'deleteMarketListings'
  | 'deleteMarketplaceContract'
  | 'deleteMarketplaceContracts'
  | 'deleteNft'
  | 'deleteNftContract'
  | 'deleteNftContracts'
  | 'deleteNftMetadata'
  | 'deleteNftMetadatas'
  | 'deleteNftOwner'
  | 'deleteNftOwners'
  | 'deleteNfts'
  | 'deletePendingTransaction'
  | 'deletePendingTransactions'
  | 'deleteStablecoin'
  | 'deleteStablecoins'
  | 'deleteSwapTransactions'
  | 'deleteTransactionStatistic'
  | 'deleteTransactionStatistics'
  | 'deleteUser'
  | 'deleteUserErc721'
  | 'deleteUserErc721s'
  | 'deleteUserJob'
  | 'deleteUserJobs'
  | 'deleteUsers'
  | 'delete_Test'
  | 'delete_Test_by_pk'
  | 'delete_anno_gpanel'
  | 'delete_anno_gpanel_by_pk'
  | 'delete_blockchain_main_deposit'
  | 'delete_blockchain_main_deposit_by_pk'
  | 'delete_burn_profit'
  | 'delete_burn_profit_by_pk'
  | 'delete_currencies'
  | 'delete_currencies_by_pk'
  | 'delete_currency_history'
  | 'delete_currency_history_by_pk'
  | 'delete_erc721_addresses'
  | 'delete_erc721_addresses_by_pk'
  | 'delete_evenbet_ticket_requests'
  | 'delete_evenbet_ticket_requests_by_pk'
  | 'delete_evenbet_tickets'
  | 'delete_evenbet_tickets_by_pk'
  | 'delete_gpanel_users_agent'
  | 'delete_gpanel_users_agent_by_pk'
  | 'delete_gpanel_users_deposit_address'
  | 'delete_gpanel_users_deposit_address_by_pk'
  | 'delete_gpanel_users_kyc'
  | 'delete_gpanel_users_kyc_by_pk'
  | 'delete_kycs'
  | 'delete_kycs_by_pk'
  | 'delete_leaderboard'
  | 'delete_leaderboard_by_pk'
  | 'delete_leaderboard_ranking'
  | 'delete_leaderboard_ranking_by_pk'
  | 'delete_liquidity_pools'
  | 'delete_liquidity_pools_by_pk'
  | 'delete_nft_gpanel_users'
  | 'delete_nft_gpanel_users_by_pk'
  | 'delete_nft_listings'
  | 'delete_nft_listings_by_pk'
  | 'delete_nft_transactions'
  | 'delete_nft_transactions_by_pk'
  | 'delete_pending_tx'
  | 'delete_pending_tx_by_pk'
  | 'delete_rakebackLocks'
  | 'delete_rakebackLocks_by_pk'
  | 'delete_staking_refill'
  | 'delete_staking_refill_by_pk'
  | 'delete_tournaments'
  | 'delete_tournaments_by_pk'
  | 'delete_transactions'
  | 'delete_transactions_by_pk'
  | 'delete_user_erc721_block'
  | 'delete_user_erc721_block_by_pk'
  | 'delete_user_gpanel'
  | 'delete_user_gpanel_by_pk'
  | 'delete_user_lock'
  | 'delete_user_lock_by_pk'
  | 'delete_user_staking'
  | 'delete_user_staking_by_pk'
  | 'delete_user_staking_transactions'
  | 'delete_user_staking_transactions_by_pk'
  | 'delete_users_old'
  | 'delete_users_old_by_pk'
  | 'insertBlockchain'
  | 'insertBlockchains'
  | 'insertGpanelPayment'
  | 'insertGpanelPayments'
  | 'insertGpanelUser'
  | 'insertGpanelUsers'
  | 'insertMarketListing'
  | 'insertMarketListings'
  | 'insertMarketplaceContract'
  | 'insertMarketplaceContracts'
  | 'insertNft'
  | 'insertNftContract'
  | 'insertNftContracts'
  | 'insertNftMetadata'
  | 'insertNftMetadatas'
  | 'insertNftOwner'
  | 'insertNftOwners'
  | 'insertNfts'
  | 'insertPendingTransaction'
  | 'insertPendingTransactions'
  | 'insertStablecoin'
  | 'insertStablecoins'
  | 'insertSwapTransaction'
  | 'insertSwapTransactions'
  | 'insertTransactionStatistic'
  | 'insertTransactionStatistics'
  | 'insertUser'
  | 'insertUserErc721'
  | 'insertUserErc721s'
  | 'insertUserJob'
  | 'insertUserJobs'
  | 'insertUsers'
  | 'insert_Test'
  | 'insert_Test_one'
  | 'insert_anno_gpanel'
  | 'insert_anno_gpanel_one'
  | 'insert_blockchain_main_deposit'
  | 'insert_blockchain_main_deposit_one'
  | 'insert_burn_profit'
  | 'insert_burn_profit_one'
  | 'insert_currencies'
  | 'insert_currencies_one'
  | 'insert_currency_history'
  | 'insert_currency_history_one'
  | 'insert_erc721_addresses'
  | 'insert_erc721_addresses_one'
  | 'insert_evenbet_ticket_requests'
  | 'insert_evenbet_ticket_requests_one'
  | 'insert_evenbet_tickets'
  | 'insert_evenbet_tickets_one'
  | 'insert_gpanel_users_agent'
  | 'insert_gpanel_users_agent_one'
  | 'insert_gpanel_users_deposit_address'
  | 'insert_gpanel_users_deposit_address_one'
  | 'insert_gpanel_users_kyc'
  | 'insert_gpanel_users_kyc_one'
  | 'insert_kycs'
  | 'insert_kycs_one'
  | 'insert_leaderboard'
  | 'insert_leaderboard_one'
  | 'insert_leaderboard_ranking'
  | 'insert_leaderboard_ranking_one'
  | 'insert_liquidity_pools'
  | 'insert_liquidity_pools_one'
  | 'insert_nft_gpanel_users'
  | 'insert_nft_gpanel_users_one'
  | 'insert_nft_listings'
  | 'insert_nft_listings_one'
  | 'insert_nft_transactions'
  | 'insert_nft_transactions_one'
  | 'insert_pending_tx'
  | 'insert_pending_tx_one'
  | 'insert_rakebackLocks'
  | 'insert_rakebackLocks_one'
  | 'insert_staking_refill'
  | 'insert_staking_refill_one'
  | 'insert_tournaments'
  | 'insert_tournaments_one'
  | 'insert_transactions'
  | 'insert_transactions_one'
  | 'insert_user_erc721_block'
  | 'insert_user_erc721_block_one'
  | 'insert_user_gpanel'
  | 'insert_user_gpanel_one'
  | 'insert_user_lock'
  | 'insert_user_lock_one'
  | 'insert_user_staking'
  | 'insert_user_staking_one'
  | 'insert_user_staking_transactions'
  | 'insert_user_staking_transactions_one'
  | 'insert_users_old'
  | 'insert_users_old_one'
  | 'paymentSubmitDeposit'
  | 'paymentSubmitKYC'
  | 'paymentSubmitWithdraw'
  | 'paymentSubmitWithdraw2FA1'
  | 'paymentSubmitWithdraw2FA2'
  | 'paymentSubmitWithdraw2FAresend'
  | 'paymentSubmittedDeposit'
  | 'refreshGpanel'
  | 'setGpanelUUID'
  | 'setNFTusername'
  | 'swapTokens'
  | 'tournamentCreate'
  | 'updateAvailableAvatarNFTs'
  | 'updateAvatar'
  | 'updateBlockchain'
  | 'updateBlockchains'
  | 'updateGpanelPayment'
  | 'updateGpanelPayments'
  | 'updateGpanelUser'
  | 'updateGpanelUsers'
  | 'updateManySwapTransactions'
  | 'updateMarketListing'
  | 'updateMarketListings'
  | 'updateMarketplaceContract'
  | 'updateMarketplaceContracts'
  | 'updateNFTListings'
  | 'updateNft'
  | 'updateNftContract'
  | 'updateNftContracts'
  | 'updateNftMetadata'
  | 'updateNftMetadatas'
  | 'updateNftOwner'
  | 'updateNftOwners'
  | 'updateNfts'
  | 'updatePendingTransaction'
  | 'updatePendingTransactions'
  | 'updateStablecoin'
  | 'updateStablecoins'
  | 'updateSwapTransactions'
  | 'updateTransactionStatistic'
  | 'updateTransactionStatistics'
  | 'updateUser'
  | 'updateUserErc721'
  | 'updateUserErc721s'
  | 'updateUserJob'
  | 'updateUserJobs'
  | 'updateUsers'
  | 'update_Test'
  | 'update_Test_by_pk'
  | 'update_Test_many'
  | 'update_anno_gpanel'
  | 'update_anno_gpanel_by_pk'
  | 'update_anno_gpanel_many'
  | 'update_blockchain_main_deposit'
  | 'update_blockchain_main_deposit_by_pk'
  | 'update_blockchain_main_deposit_many'
  | 'update_blockchain_many'
  | 'update_burn_profit'
  | 'update_burn_profit_by_pk'
  | 'update_burn_profit_many'
  | 'update_currencies'
  | 'update_currencies_by_pk'
  | 'update_currencies_many'
  | 'update_currency_history'
  | 'update_currency_history_by_pk'
  | 'update_currency_history_many'
  | 'update_erc721_addresses'
  | 'update_erc721_addresses_by_pk'
  | 'update_erc721_addresses_many'
  | 'update_evenbet_ticket_requests'
  | 'update_evenbet_ticket_requests_by_pk'
  | 'update_evenbet_ticket_requests_many'
  | 'update_evenbet_tickets'
  | 'update_evenbet_tickets_by_pk'
  | 'update_evenbet_tickets_many'
  | 'update_gpanelPayments_many'
  | 'update_gpanelUsers_many'
  | 'update_gpanel_users_agent'
  | 'update_gpanel_users_agent_by_pk'
  | 'update_gpanel_users_agent_many'
  | 'update_gpanel_users_deposit_address'
  | 'update_gpanel_users_deposit_address_by_pk'
  | 'update_gpanel_users_deposit_address_many'
  | 'update_gpanel_users_kyc'
  | 'update_gpanel_users_kyc_by_pk'
  | 'update_gpanel_users_kyc_many'
  | 'update_kycs'
  | 'update_kycs_by_pk'
  | 'update_kycs_many'
  | 'update_leaderboard'
  | 'update_leaderboard_by_pk'
  | 'update_leaderboard_many'
  | 'update_leaderboard_ranking'
  | 'update_leaderboard_ranking_by_pk'
  | 'update_leaderboard_ranking_many'
  | 'update_liquidity_pools'
  | 'update_liquidity_pools_by_pk'
  | 'update_liquidity_pools_many'
  | 'update_marketListings_many'
  | 'update_marketplaceContracts_many'
  | 'update_nftContracts_many'
  | 'update_nftMetadatas_many'
  | 'update_nftOwners_many'
  | 'update_nft_gpanel_users'
  | 'update_nft_gpanel_users_by_pk'
  | 'update_nft_gpanel_users_many'
  | 'update_nft_listings'
  | 'update_nft_listings_by_pk'
  | 'update_nft_listings_many'
  | 'update_nft_transactions'
  | 'update_nft_transactions_by_pk'
  | 'update_nft_transactions_many'
  | 'update_nfts_many'
  | 'update_pendingTransactions_many'
  | 'update_pending_tx'
  | 'update_pending_tx_by_pk'
  | 'update_pending_tx_many'
  | 'update_rakebackLocks'
  | 'update_rakebackLocks_by_pk'
  | 'update_rakebackLocks_many'
  | 'update_stablecoins_many'
  | 'update_staking_refill'
  | 'update_staking_refill_by_pk'
  | 'update_staking_refill_many'
  | 'update_tournaments'
  | 'update_tournaments_by_pk'
  | 'update_tournaments_many'
  | 'update_transactionStatistics_many'
  | 'update_transactions'
  | 'update_transactions_by_pk'
  | 'update_transactions_many'
  | 'update_userErc721_many'
  | 'update_userJobs_many'
  | 'update_user_erc721_block'
  | 'update_user_erc721_block_by_pk'
  | 'update_user_erc721_block_many'
  | 'update_user_gpanel'
  | 'update_user_gpanel_by_pk'
  | 'update_user_gpanel_many'
  | 'update_user_lock'
  | 'update_user_lock_by_pk'
  | 'update_user_lock_many'
  | 'update_user_staking'
  | 'update_user_staking_by_pk'
  | 'update_user_staking_many'
  | 'update_user_staking_transactions'
  | 'update_user_staking_transactions_by_pk'
  | 'update_user_staking_transactions_many'
  | 'update_users_many'
  | 'update_users_old'
  | 'update_users_old_by_pk'
  | 'update_users_old_many'
  | mutation_rootKeySpecifier
)[];
export type mutation_rootFieldPolicy = {
  NFTDelegationAccept?: FieldPolicy<any> | FieldReadFunction<any>;
  NFTReleaseTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  NFTRentOut?: FieldPolicy<any> | FieldReadFunction<any>;
  agentPaymentSubmitDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  agentPaymentSubmitWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  agentPaymentSubmittedDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBlockchain?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBlockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteGpanelPayment?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteGpanelPayments?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteGpanelUser?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteGpanelUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMarketListing?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMarketListings?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMarketplaceContract?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMarketplaceContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteNft?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteNftContract?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteNftContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteNftMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteNftMetadatas?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteNftOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteNftOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteNfts?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePendingTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePendingTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStablecoin?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStablecoins?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteSwapTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTransactionStatistic?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTransactionStatistics?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUser?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUserErc721?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUserErc721s?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUserJob?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUserJobs?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_Test?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_Test_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_anno_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_anno_gpanel_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_blockchain_main_deposit?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_blockchain_main_deposit_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  delete_burn_profit?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_burn_profit_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_currencies_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_currency_history?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_currency_history_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_erc721_addresses?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_erc721_addresses_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_evenbet_ticket_requests?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_evenbet_ticket_requests_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  delete_evenbet_tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_evenbet_tickets_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_gpanel_users_agent?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_gpanel_users_agent_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_gpanel_users_deposit_address?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  delete_gpanel_users_deposit_address_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  delete_gpanel_users_kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_gpanel_users_kyc_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_kycs?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_kycs_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_leaderboard?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_leaderboard_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_leaderboard_ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_leaderboard_ranking_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_liquidity_pools?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_liquidity_pools_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_nft_gpanel_users?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_nft_gpanel_users_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_nft_listings?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_nft_listings_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_nft_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_nft_transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_pending_tx?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_pending_tx_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_rakebackLocks?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_rakebackLocks_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_staking_refill?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_staking_refill_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_tournaments_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_erc721_block?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_erc721_block_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_gpanel_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_lock?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_lock_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_staking?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_staking_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_staking_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_user_staking_transactions_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  delete_users_old?: FieldPolicy<any> | FieldReadFunction<any>;
  delete_users_old_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  insertBlockchain?: FieldPolicy<any> | FieldReadFunction<any>;
  insertBlockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  insertGpanelPayment?: FieldPolicy<any> | FieldReadFunction<any>;
  insertGpanelPayments?: FieldPolicy<any> | FieldReadFunction<any>;
  insertGpanelUser?: FieldPolicy<any> | FieldReadFunction<any>;
  insertGpanelUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertMarketListing?: FieldPolicy<any> | FieldReadFunction<any>;
  insertMarketListings?: FieldPolicy<any> | FieldReadFunction<any>;
  insertMarketplaceContract?: FieldPolicy<any> | FieldReadFunction<any>;
  insertMarketplaceContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  insertNft?: FieldPolicy<any> | FieldReadFunction<any>;
  insertNftContract?: FieldPolicy<any> | FieldReadFunction<any>;
  insertNftContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  insertNftMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  insertNftMetadatas?: FieldPolicy<any> | FieldReadFunction<any>;
  insertNftOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  insertNftOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  insertNfts?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPendingTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPendingTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStablecoin?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStablecoins?: FieldPolicy<any> | FieldReadFunction<any>;
  insertSwapTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  insertSwapTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTransactionStatistic?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTransactionStatistics?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUser?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUserErc721?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUserErc721s?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUserJob?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUserJobs?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_Test?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_Test_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_anno_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_anno_gpanel_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_blockchain_main_deposit?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_blockchain_main_deposit_one?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  insert_burn_profit?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_burn_profit_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_currencies_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_currency_history?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_currency_history_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_erc721_addresses?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_erc721_addresses_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_evenbet_ticket_requests?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_evenbet_ticket_requests_one?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  insert_evenbet_tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_evenbet_tickets_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_gpanel_users_agent?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_gpanel_users_agent_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_gpanel_users_deposit_address?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  insert_gpanel_users_deposit_address_one?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  insert_gpanel_users_kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_gpanel_users_kyc_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_kycs?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_kycs_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_leaderboard?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_leaderboard_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_leaderboard_ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_leaderboard_ranking_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_liquidity_pools?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_liquidity_pools_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_nft_gpanel_users?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_nft_gpanel_users_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_nft_listings?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_nft_listings_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_nft_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_nft_transactions_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_pending_tx?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_pending_tx_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_rakebackLocks?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_rakebackLocks_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_staking_refill?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_staking_refill_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_tournaments_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_transactions_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_erc721_block?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_erc721_block_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_gpanel_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_lock?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_lock_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_staking?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_staking_one?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_staking_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_user_staking_transactions_one?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  insert_users_old?: FieldPolicy<any> | FieldReadFunction<any>;
  insert_users_old_one?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSubmitDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSubmitKYC?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSubmitWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSubmitWithdraw2FA1?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSubmitWithdraw2FA2?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSubmitWithdraw2FAresend?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSubmittedDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshGpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  setGpanelUUID?: FieldPolicy<any> | FieldReadFunction<any>;
  setNFTusername?: FieldPolicy<any> | FieldReadFunction<any>;
  swapTokens?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAvailableAvatarNFTs?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAvatar?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockchain?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGpanelPayment?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGpanelPayments?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGpanelUser?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGpanelUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateManySwapTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMarketListing?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMarketListings?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMarketplaceContract?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMarketplaceContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNFTListings?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNft?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNftContract?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNftContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNftMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNftMetadatas?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNftOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNftOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNfts?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePendingTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePendingTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStablecoin?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStablecoins?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSwapTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTransactionStatistic?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTransactionStatistics?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUser?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUserErc721?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUserErc721s?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUserJob?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUserJobs?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  update_Test?: FieldPolicy<any> | FieldReadFunction<any>;
  update_Test_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_Test_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_anno_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  update_anno_gpanel_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_anno_gpanel_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_blockchain_main_deposit?: FieldPolicy<any> | FieldReadFunction<any>;
  update_blockchain_main_deposit_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_blockchain_main_deposit_many?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_blockchain_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_burn_profit?: FieldPolicy<any> | FieldReadFunction<any>;
  update_burn_profit_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_burn_profit_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  update_currencies_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_currencies_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_currency_history?: FieldPolicy<any> | FieldReadFunction<any>;
  update_currency_history_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_currency_history_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_erc721_addresses?: FieldPolicy<any> | FieldReadFunction<any>;
  update_erc721_addresses_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_erc721_addresses_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_evenbet_ticket_requests?: FieldPolicy<any> | FieldReadFunction<any>;
  update_evenbet_ticket_requests_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_evenbet_ticket_requests_many?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_evenbet_tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  update_evenbet_tickets_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_evenbet_tickets_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_gpanelPayments_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_gpanelUsers_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_gpanel_users_agent?: FieldPolicy<any> | FieldReadFunction<any>;
  update_gpanel_users_agent_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_gpanel_users_agent_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_gpanel_users_deposit_address?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_gpanel_users_deposit_address_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_gpanel_users_deposit_address_many?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_gpanel_users_kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  update_gpanel_users_kyc_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_gpanel_users_kyc_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_kycs?: FieldPolicy<any> | FieldReadFunction<any>;
  update_kycs_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_kycs_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_leaderboard?: FieldPolicy<any> | FieldReadFunction<any>;
  update_leaderboard_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_leaderboard_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_leaderboard_ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  update_leaderboard_ranking_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_leaderboard_ranking_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_liquidity_pools?: FieldPolicy<any> | FieldReadFunction<any>;
  update_liquidity_pools_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_liquidity_pools_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_marketListings_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_marketplaceContracts_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nftContracts_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nftMetadatas_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nftOwners_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_gpanel_users?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_gpanel_users_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_gpanel_users_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_listings?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_listings_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_listings_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nft_transactions_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_nfts_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_pendingTransactions_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_pending_tx?: FieldPolicy<any> | FieldReadFunction<any>;
  update_pending_tx_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_pending_tx_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_rakebackLocks?: FieldPolicy<any> | FieldReadFunction<any>;
  update_rakebackLocks_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_rakebackLocks_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_stablecoins_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_staking_refill?: FieldPolicy<any> | FieldReadFunction<any>;
  update_staking_refill_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_staking_refill_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  update_tournaments_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_tournaments_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_transactionStatistics_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  update_transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_transactions_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_userErc721_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_userJobs_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_erc721_block?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_erc721_block_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_erc721_block_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_gpanel_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_gpanel_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_lock?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_lock_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_lock_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_staking?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_staking_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_staking_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_staking_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  update_user_staking_transactions_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_user_staking_transactions_many?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  update_users_many?: FieldPolicy<any> | FieldReadFunction<any>;
  update_users_old?: FieldPolicy<any> | FieldReadFunction<any>;
  update_users_old_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  update_users_old_many?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContractsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'chain'
  | 'collectionName'
  | 'contractAddress'
  | 'createdAt'
  | 'generation'
  | 'id'
  | 'updatedAt'
  | nftContractsKeySpecifier
)[];
export type nftContractsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  chain?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionName?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | nftContracts_aggregateKeySpecifier
)[];
export type nftContracts_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | nftContracts_aggregate_fieldsKeySpecifier
)[];
export type nftContracts_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_avg_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'generation'
  | 'id'
  | nftContracts_avg_fieldsKeySpecifier
)[];
export type nftContracts_avg_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_max_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'chain'
  | 'collectionName'
  | 'contractAddress'
  | 'createdAt'
  | 'generation'
  | 'id'
  | 'updatedAt'
  | nftContracts_max_fieldsKeySpecifier
)[];
export type nftContracts_max_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  chain?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionName?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_min_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'chain'
  | 'collectionName'
  | 'contractAddress'
  | 'createdAt'
  | 'generation'
  | 'id'
  | 'updatedAt'
  | nftContracts_min_fieldsKeySpecifier
)[];
export type nftContracts_min_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  chain?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionName?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | nftContracts_mutation_responseKeySpecifier
)[];
export type nftContracts_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_stddev_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'generation'
  | 'id'
  | nftContracts_stddev_fieldsKeySpecifier
)[];
export type nftContracts_stddev_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_stddev_pop_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'generation'
  | 'id'
  | nftContracts_stddev_pop_fieldsKeySpecifier
)[];
export type nftContracts_stddev_pop_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_stddev_samp_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'generation'
  | 'id'
  | nftContracts_stddev_samp_fieldsKeySpecifier
)[];
export type nftContracts_stddev_samp_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_sum_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'generation'
  | 'id'
  | nftContracts_sum_fieldsKeySpecifier
)[];
export type nftContracts_sum_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_var_pop_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'generation'
  | 'id'
  | nftContracts_var_pop_fieldsKeySpecifier
)[];
export type nftContracts_var_pop_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_var_samp_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'generation'
  | 'id'
  | nftContracts_var_samp_fieldsKeySpecifier
)[];
export type nftContracts_var_samp_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftContracts_variance_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchainId'
  | 'generation'
  | 'id'
  | nftContracts_variance_fieldsKeySpecifier
)[];
export type nftContracts_variance_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatasKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'imageUrl'
  | 'updatedAt'
  | nftMetadatasKeySpecifier
)[];
export type nftMetadatasFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | nftMetadatas_aggregateKeySpecifier
)[];
export type nftMetadatas_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | nftMetadatas_aggregate_fieldsKeySpecifier
)[];
export type nftMetadatas_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_avg_fieldsKeySpecifier = (
  | 'id'
  | nftMetadatas_avg_fieldsKeySpecifier
)[];
export type nftMetadatas_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'imageUrl'
  | 'updatedAt'
  | nftMetadatas_max_fieldsKeySpecifier
)[];
export type nftMetadatas_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'imageUrl'
  | 'updatedAt'
  | nftMetadatas_min_fieldsKeySpecifier
)[];
export type nftMetadatas_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | nftMetadatas_mutation_responseKeySpecifier
)[];
export type nftMetadatas_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_stddev_fieldsKeySpecifier = (
  | 'id'
  | nftMetadatas_stddev_fieldsKeySpecifier
)[];
export type nftMetadatas_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | nftMetadatas_stddev_pop_fieldsKeySpecifier
)[];
export type nftMetadatas_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | nftMetadatas_stddev_samp_fieldsKeySpecifier
)[];
export type nftMetadatas_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_sum_fieldsKeySpecifier = (
  | 'id'
  | nftMetadatas_sum_fieldsKeySpecifier
)[];
export type nftMetadatas_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_var_pop_fieldsKeySpecifier = (
  | 'id'
  | nftMetadatas_var_pop_fieldsKeySpecifier
)[];
export type nftMetadatas_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_var_samp_fieldsKeySpecifier = (
  | 'id'
  | nftMetadatas_var_samp_fieldsKeySpecifier
)[];
export type nftMetadatas_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftMetadatas_variance_fieldsKeySpecifier = (
  | 'id'
  | nftMetadatas_variance_fieldsKeySpecifier
)[];
export type nftMetadatas_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwnersKeySpecifier = (
  | 'blockchain_id'
  | 'borrowed'
  | 'contract'
  | 'createdAt'
  | 'delegated'
  | 'generation'
  | 'gpanelUserId'
  | 'id'
  | 'image_url'
  | 'ownerAddress'
  | 'pokerName'
  | 'status'
  | 'tokenid'
  | 'updatedAt'
  | 'userId'
  | nftOwnersKeySpecifier
)[];
export type nftOwnersFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  borrowed?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  delegated?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image_url?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerName?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | nftOwners_aggregateKeySpecifier
)[];
export type nftOwners_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | nftOwners_aggregate_fieldsKeySpecifier
)[];
export type nftOwners_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_avg_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'generation'
  | 'id'
  | 'tokenid'
  | nftOwners_avg_fieldsKeySpecifier
)[];
export type nftOwners_avg_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_max_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'contract'
  | 'createdAt'
  | 'generation'
  | 'gpanelUserId'
  | 'id'
  | 'image_url'
  | 'ownerAddress'
  | 'pokerName'
  | 'status'
  | 'tokenid'
  | 'updatedAt'
  | 'userId'
  | nftOwners_max_fieldsKeySpecifier
)[];
export type nftOwners_max_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image_url?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerName?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_min_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'contract'
  | 'createdAt'
  | 'generation'
  | 'gpanelUserId'
  | 'id'
  | 'image_url'
  | 'ownerAddress'
  | 'pokerName'
  | 'status'
  | 'tokenid'
  | 'updatedAt'
  | 'userId'
  | nftOwners_min_fieldsKeySpecifier
)[];
export type nftOwners_min_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image_url?: FieldPolicy<any> | FieldReadFunction<any>;
  ownerAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerName?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | nftOwners_mutation_responseKeySpecifier
)[];
export type nftOwners_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_stddev_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'generation'
  | 'id'
  | 'tokenid'
  | nftOwners_stddev_fieldsKeySpecifier
)[];
export type nftOwners_stddev_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_stddev_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'generation'
  | 'id'
  | 'tokenid'
  | nftOwners_stddev_pop_fieldsKeySpecifier
)[];
export type nftOwners_stddev_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_stddev_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'generation'
  | 'id'
  | 'tokenid'
  | nftOwners_stddev_samp_fieldsKeySpecifier
)[];
export type nftOwners_stddev_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_sum_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'generation'
  | 'id'
  | 'tokenid'
  | nftOwners_sum_fieldsKeySpecifier
)[];
export type nftOwners_sum_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_var_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'generation'
  | 'id'
  | 'tokenid'
  | nftOwners_var_pop_fieldsKeySpecifier
)[];
export type nftOwners_var_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_var_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'generation'
  | 'id'
  | 'tokenid'
  | nftOwners_var_samp_fieldsKeySpecifier
)[];
export type nftOwners_var_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftOwners_variance_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'generation'
  | 'id'
  | 'tokenid'
  | nftOwners_variance_fieldsKeySpecifier
)[];
export type nftOwners_variance_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  generation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_usersKeySpecifier = (
  | 'created_at'
  | 'delegate_accepted'
  | 'delegate_stopped'
  | 'gpanel_user'
  | 'gpanel_user_id'
  | 'gpanel_username'
  | 'id'
  | 'nft_id'
  | 'nft_owner'
  | 'status'
  | 'updated_at'
  | nft_gpanel_usersKeySpecifier
)[];
export type nft_gpanel_usersFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  delegate_accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  delegate_stopped?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_username?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_owner?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | nft_gpanel_users_aggregateKeySpecifier
)[];
export type nft_gpanel_users_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | nft_gpanel_users_aggregate_fieldsKeySpecifier
)[];
export type nft_gpanel_users_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_avg_fieldsKeySpecifier = (
  | 'id'
  | 'nft_id'
  | nft_gpanel_users_avg_fieldsKeySpecifier
)[];
export type nft_gpanel_users_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_max_fieldsKeySpecifier = (
  | 'created_at'
  | 'delegate_accepted'
  | 'delegate_stopped'
  | 'gpanel_user_id'
  | 'gpanel_username'
  | 'id'
  | 'nft_id'
  | 'status'
  | 'updated_at'
  | nft_gpanel_users_max_fieldsKeySpecifier
)[];
export type nft_gpanel_users_max_fieldsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  delegate_accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  delegate_stopped?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_username?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_min_fieldsKeySpecifier = (
  | 'created_at'
  | 'delegate_accepted'
  | 'delegate_stopped'
  | 'gpanel_user_id'
  | 'gpanel_username'
  | 'id'
  | 'nft_id'
  | 'status'
  | 'updated_at'
  | nft_gpanel_users_min_fieldsKeySpecifier
)[];
export type nft_gpanel_users_min_fieldsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  delegate_accepted?: FieldPolicy<any> | FieldReadFunction<any>;
  delegate_stopped?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_username?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | nft_gpanel_users_mutation_responseKeySpecifier
)[];
export type nft_gpanel_users_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_stddev_fieldsKeySpecifier = (
  | 'id'
  | 'nft_id'
  | nft_gpanel_users_stddev_fieldsKeySpecifier
)[];
export type nft_gpanel_users_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | 'nft_id'
  | nft_gpanel_users_stddev_pop_fieldsKeySpecifier
)[];
export type nft_gpanel_users_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | 'nft_id'
  | nft_gpanel_users_stddev_samp_fieldsKeySpecifier
)[];
export type nft_gpanel_users_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_sum_fieldsKeySpecifier = (
  | 'id'
  | 'nft_id'
  | nft_gpanel_users_sum_fieldsKeySpecifier
)[];
export type nft_gpanel_users_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_var_pop_fieldsKeySpecifier = (
  | 'id'
  | 'nft_id'
  | nft_gpanel_users_var_pop_fieldsKeySpecifier
)[];
export type nft_gpanel_users_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_var_samp_fieldsKeySpecifier = (
  | 'id'
  | 'nft_id'
  | nft_gpanel_users_var_samp_fieldsKeySpecifier
)[];
export type nft_gpanel_users_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_gpanel_users_variance_fieldsKeySpecifier = (
  | 'id'
  | 'nft_id'
  | nft_gpanel_users_variance_fieldsKeySpecifier
)[];
export type nft_gpanel_users_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listingsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'buyer'
  | 'created_at'
  | 'endedAt'
  | 'erc20Address'
  | 'id'
  | 'isErc721'
  | 'lastBidder'
  | 'nft_id'
  | 'nftaddress'
  | 'originalListingId'
  | 'price'
  | 'seller'
  | 'stakeCount'
  | 'state'
  | 'updated_at'
  | nft_listingsKeySpecifier
)[];
export type nft_listingsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  buyer?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isErc721?: FieldPolicy<any> | FieldReadFunction<any>;
  lastBidder?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  nftaddress?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seller?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | nft_listings_aggregateKeySpecifier
)[];
export type nft_listings_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | nft_listings_aggregate_fieldsKeySpecifier
)[];
export type nft_listings_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'endedAt'
  | 'id'
  | 'nft_id'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | nft_listings_avg_fieldsKeySpecifier
)[];
export type nft_listings_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_max_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'buyer'
  | 'created_at'
  | 'endedAt'
  | 'erc20Address'
  | 'id'
  | 'lastBidder'
  | 'nft_id'
  | 'nftaddress'
  | 'originalListingId'
  | 'price'
  | 'seller'
  | 'stakeCount'
  | 'state'
  | 'updated_at'
  | nft_listings_max_fieldsKeySpecifier
)[];
export type nft_listings_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  buyer?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastBidder?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  nftaddress?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seller?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_min_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'buyer'
  | 'created_at'
  | 'endedAt'
  | 'erc20Address'
  | 'id'
  | 'lastBidder'
  | 'nft_id'
  | 'nftaddress'
  | 'originalListingId'
  | 'price'
  | 'seller'
  | 'stakeCount'
  | 'state'
  | 'updated_at'
  | nft_listings_min_fieldsKeySpecifier
)[];
export type nft_listings_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  buyer?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastBidder?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  nftaddress?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seller?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | nft_listings_mutation_responseKeySpecifier
)[];
export type nft_listings_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'endedAt'
  | 'id'
  | 'nft_id'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | nft_listings_stddev_fieldsKeySpecifier
)[];
export type nft_listings_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'endedAt'
  | 'id'
  | 'nft_id'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | nft_listings_stddev_pop_fieldsKeySpecifier
)[];
export type nft_listings_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'endedAt'
  | 'id'
  | 'nft_id'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | nft_listings_stddev_samp_fieldsKeySpecifier
)[];
export type nft_listings_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'endedAt'
  | 'id'
  | 'nft_id'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | nft_listings_sum_fieldsKeySpecifier
)[];
export type nft_listings_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'endedAt'
  | 'id'
  | 'nft_id'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | nft_listings_var_pop_fieldsKeySpecifier
)[];
export type nft_listings_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'endedAt'
  | 'id'
  | 'nft_id'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | nft_listings_var_samp_fieldsKeySpecifier
)[];
export type nft_listings_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_listings_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'endedAt'
  | 'id'
  | 'nft_id'
  | 'originalListingId'
  | 'price'
  | 'stakeCount'
  | 'state'
  | nft_listings_variance_fieldsKeySpecifier
)[];
export type nft_listings_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  endedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stakeCount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactionsKeySpecifier = (
  | 'action'
  | 'blockNumber'
  | 'blockchain_id'
  | 'created_at'
  | 'from'
  | 'hash'
  | 'id'
  | 'swap_log'
  | 'timeStamp'
  | 'to'
  | 'tokenid'
  | 'updated_at'
  | nft_transactionsKeySpecifier
)[];
export type nft_transactionsFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  swap_log?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | nft_transactions_aggregateKeySpecifier
)[];
export type nft_transactions_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | nft_transactions_aggregate_fieldsKeySpecifier
)[];
export type nft_transactions_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_avg_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'tokenid'
  | nft_transactions_avg_fieldsKeySpecifier
)[];
export type nft_transactions_avg_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_max_fieldsKeySpecifier = (
  | 'action'
  | 'blockNumber'
  | 'blockchain_id'
  | 'created_at'
  | 'from'
  | 'hash'
  | 'id'
  | 'swap_log'
  | 'timeStamp'
  | 'to'
  | 'tokenid'
  | 'updated_at'
  | nft_transactions_max_fieldsKeySpecifier
)[];
export type nft_transactions_max_fieldsFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  swap_log?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_min_fieldsKeySpecifier = (
  | 'action'
  | 'blockNumber'
  | 'blockchain_id'
  | 'created_at'
  | 'from'
  | 'hash'
  | 'id'
  | 'swap_log'
  | 'timeStamp'
  | 'to'
  | 'tokenid'
  | 'updated_at'
  | nft_transactions_min_fieldsKeySpecifier
)[];
export type nft_transactions_min_fieldsFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  swap_log?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | nft_transactions_mutation_responseKeySpecifier
)[];
export type nft_transactions_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_stddev_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'tokenid'
  | nft_transactions_stddev_fieldsKeySpecifier
)[];
export type nft_transactions_stddev_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_stddev_pop_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'tokenid'
  | nft_transactions_stddev_pop_fieldsKeySpecifier
)[];
export type nft_transactions_stddev_pop_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_stddev_samp_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'tokenid'
  | nft_transactions_stddev_samp_fieldsKeySpecifier
)[];
export type nft_transactions_stddev_samp_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_sum_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'tokenid'
  | nft_transactions_sum_fieldsKeySpecifier
)[];
export type nft_transactions_sum_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_var_pop_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'tokenid'
  | nft_transactions_var_pop_fieldsKeySpecifier
)[];
export type nft_transactions_var_pop_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_var_samp_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'tokenid'
  | nft_transactions_var_samp_fieldsKeySpecifier
)[];
export type nft_transactions_var_samp_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nft_transactions_variance_fieldsKeySpecifier = (
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'tokenid'
  | nft_transactions_variance_fieldsKeySpecifier
)[];
export type nft_transactions_variance_fieldsFieldPolicy = {
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nftsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'createdAt'
  | 'id'
  | 'imageUrl'
  | 'lastTransferredAt'
  | 'listing'
  | 'metadataId'
  | 'nftContract'
  | 'nftMetadatum'
  | 'owner'
  | 'tokenId'
  | 'updatedAt'
  | nftsKeySpecifier
)[];
export type nftsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  lastTransferredAt?: FieldPolicy<any> | FieldReadFunction<any>;
  listing?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
  nftContract?: FieldPolicy<any> | FieldReadFunction<any>;
  nftMetadatum?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | nfts_aggregateKeySpecifier
)[];
export type nfts_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | nfts_aggregate_fieldsKeySpecifier
)[];
export type nfts_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_avg_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'id'
  | 'metadataId'
  | nfts_avg_fieldsKeySpecifier
)[];
export type nfts_avg_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_max_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'createdAt'
  | 'id'
  | 'imageUrl'
  | 'lastTransferredAt'
  | 'metadataId'
  | 'owner'
  | 'tokenId'
  | 'updatedAt'
  | nfts_max_fieldsKeySpecifier
)[];
export type nfts_max_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  lastTransferredAt?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_min_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'createdAt'
  | 'id'
  | 'imageUrl'
  | 'lastTransferredAt'
  | 'metadataId'
  | 'owner'
  | 'tokenId'
  | 'updatedAt'
  | nfts_min_fieldsKeySpecifier
)[];
export type nfts_min_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  lastTransferredAt?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | nfts_mutation_responseKeySpecifier
)[];
export type nfts_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_stddev_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'id'
  | 'metadataId'
  | nfts_stddev_fieldsKeySpecifier
)[];
export type nfts_stddev_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_stddev_pop_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'id'
  | 'metadataId'
  | nfts_stddev_pop_fieldsKeySpecifier
)[];
export type nfts_stddev_pop_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_stddev_samp_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'id'
  | 'metadataId'
  | nfts_stddev_samp_fieldsKeySpecifier
)[];
export type nfts_stddev_samp_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_sum_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'id'
  | 'metadataId'
  | nfts_sum_fieldsKeySpecifier
)[];
export type nfts_sum_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_var_pop_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'id'
  | 'metadataId'
  | nfts_var_pop_fieldsKeySpecifier
)[];
export type nfts_var_pop_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_var_samp_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'id'
  | 'metadataId'
  | nfts_var_samp_fieldsKeySpecifier
)[];
export type nfts_var_samp_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type nfts_variance_fieldsKeySpecifier = (
  | 'activeListingId'
  | 'activeUsernameId'
  | 'blockchainId'
  | 'contractId'
  | 'id'
  | 'metadataId'
  | nfts_variance_fieldsKeySpecifier
)[];
export type nfts_variance_fieldsFieldPolicy = {
  activeListingId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeUsernameId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadataId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactionsKeySpecifier = (
  | 'agent'
  | 'amount'
  | 'blockchain'
  | 'blockchain_id'
  | 'createdAt'
  | 'currency'
  | 'depositAddress'
  | 'fee'
  | 'gpanelUserId'
  | 'id'
  | 'stablecoin'
  | 'stablecoin_id'
  | 'status'
  | 'statusInternal'
  | 'twofa'
  | 'txid'
  | 'txlog'
  | 'type'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | 'username'
  | 'withdraw_address'
  | pendingTransactionsKeySpecifier
)[];
export type pendingTransactionsFieldPolicy = {
  agent?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  twofa?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  withdraw_address?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | pendingTransactions_aggregateKeySpecifier
)[];
export type pendingTransactions_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | pendingTransactions_aggregate_fieldsKeySpecifier
)[];
export type pendingTransactions_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'fee'
  | 'stablecoin_id'
  | pendingTransactions_avg_fieldsKeySpecifier
)[];
export type pendingTransactions_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_max_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'createdAt'
  | 'currency'
  | 'depositAddress'
  | 'fee'
  | 'gpanelUserId'
  | 'id'
  | 'stablecoin_id'
  | 'status'
  | 'statusInternal'
  | 'twofa'
  | 'txid'
  | 'txlog'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | 'username'
  | 'withdraw_address'
  | pendingTransactions_max_fieldsKeySpecifier
)[];
export type pendingTransactions_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  twofa?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  withdraw_address?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_min_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'createdAt'
  | 'currency'
  | 'depositAddress'
  | 'fee'
  | 'gpanelUserId'
  | 'id'
  | 'stablecoin_id'
  | 'status'
  | 'statusInternal'
  | 'twofa'
  | 'txid'
  | 'txlog'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | 'username'
  | 'withdraw_address'
  | pendingTransactions_min_fieldsKeySpecifier
)[];
export type pendingTransactions_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  twofa?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  withdraw_address?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | pendingTransactions_mutation_responseKeySpecifier
)[];
export type pendingTransactions_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'fee'
  | 'stablecoin_id'
  | pendingTransactions_stddev_fieldsKeySpecifier
)[];
export type pendingTransactions_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'fee'
  | 'stablecoin_id'
  | pendingTransactions_stddev_pop_fieldsKeySpecifier
)[];
export type pendingTransactions_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'fee'
  | 'stablecoin_id'
  | pendingTransactions_stddev_samp_fieldsKeySpecifier
)[];
export type pendingTransactions_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'fee'
  | 'stablecoin_id'
  | pendingTransactions_sum_fieldsKeySpecifier
)[];
export type pendingTransactions_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'fee'
  | 'stablecoin_id'
  | pendingTransactions_var_pop_fieldsKeySpecifier
)[];
export type pendingTransactions_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'fee'
  | 'stablecoin_id'
  | pendingTransactions_var_samp_fieldsKeySpecifier
)[];
export type pendingTransactions_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pendingTransactions_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'blockchain_id'
  | 'fee'
  | 'stablecoin_id'
  | pendingTransactions_variance_fieldsKeySpecifier
)[];
export type pendingTransactions_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_txKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'contractAddress'
  | 'created_at'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'tokenSymbol'
  | 'updated_at'
  | 'wallet'
  | pending_txKeySpecifier
)[];
export type pending_txFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenSymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  wallet?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | pending_tx_aggregateKeySpecifier
)[];
export type pending_tx_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | pending_tx_aggregate_fieldsKeySpecifier
)[];
export type pending_tx_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | pending_tx_avg_fieldsKeySpecifier
)[];
export type pending_tx_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_max_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'contractAddress'
  | 'created_at'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'tokenSymbol'
  | 'updated_at'
  | 'wallet'
  | pending_tx_max_fieldsKeySpecifier
)[];
export type pending_tx_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenSymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  wallet?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_min_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'contractAddress'
  | 'created_at'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'tokenSymbol'
  | 'updated_at'
  | 'wallet'
  | pending_tx_min_fieldsKeySpecifier
)[];
export type pending_tx_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenSymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  wallet?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | pending_tx_mutation_responseKeySpecifier
)[];
export type pending_tx_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | pending_tx_stddev_fieldsKeySpecifier
)[];
export type pending_tx_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | pending_tx_stddev_pop_fieldsKeySpecifier
)[];
export type pending_tx_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | pending_tx_stddev_samp_fieldsKeySpecifier
)[];
export type pending_tx_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | pending_tx_sum_fieldsKeySpecifier
)[];
export type pending_tx_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | pending_tx_var_pop_fieldsKeySpecifier
)[];
export type pending_tx_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | pending_tx_var_samp_fieldsKeySpecifier
)[];
export type pending_tx_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type pending_tx_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | pending_tx_variance_fieldsKeySpecifier
)[];
export type pending_tx_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type query_rootKeySpecifier = (
  | 'NFTAvailableTickets'
  | 'NFTFindUser'
  | 'NFTUserConnected'
  | 'Test'
  | 'Test_aggregate'
  | 'Test_by_pk'
  | 'agentFindUser'
  | 'agentGetUsers'
  | 'anno_gpanel'
  | 'anno_gpanel_aggregate'
  | 'anno_gpanel_by_pk'
  | 'authLogin'
  | 'authRefresh'
  | 'authSignature'
  | 'blockchain'
  | 'blockchain_main_deposit'
  | 'blockchain_main_deposit_aggregate'
  | 'blockchain_main_deposit_by_pk'
  | 'blockchains'
  | 'blockchainsAggregate'
  | 'burn_profit'
  | 'burn_profit_aggregate'
  | 'burn_profit_by_pk'
  | 'currencies'
  | 'currencies_aggregate'
  | 'currencies_by_pk'
  | 'currency_history'
  | 'currency_history_aggregate'
  | 'currency_history_by_pk'
  | 'erc721_addresses'
  | 'erc721_addresses_aggregate'
  | 'erc721_addresses_by_pk'
  | 'evenbetAffiliatePlayerBalances'
  | 'evenbetAffiliatePlayerBalancesAggregate'
  | 'evenbet_ticket_requests'
  | 'evenbet_ticket_requests_aggregate'
  | 'evenbet_ticket_requests_by_pk'
  | 'evenbet_tickets'
  | 'evenbet_tickets_aggregate'
  | 'evenbet_tickets_by_pk'
  | 'getGpanelUUID'
  | 'gpanelPayment'
  | 'gpanelPayments'
  | 'gpanelPaymentsAggregate'
  | 'gpanelUser'
  | 'gpanelUsers'
  | 'gpanelUsersAggregate'
  | 'gpanel_users_agent'
  | 'gpanel_users_agent_aggregate'
  | 'gpanel_users_agent_by_pk'
  | 'gpanel_users_deposit_address'
  | 'gpanel_users_deposit_address_aggregate'
  | 'gpanel_users_deposit_address_by_pk'
  | 'gpanel_users_kyc'
  | 'gpanel_users_kyc_aggregate'
  | 'gpanel_users_kyc_by_pk'
  | 'kyc'
  | 'kycs'
  | 'kycs_aggregate'
  | 'leaderboard'
  | 'leaderboard_aggregate'
  | 'leaderboard_by_pk'
  | 'leaderboard_ranking'
  | 'leaderboard_ranking_aggregate'
  | 'leaderboard_ranking_by_pk'
  | 'liquidity_pools'
  | 'liquidity_pools_aggregate'
  | 'liquidity_pools_by_pk'
  | 'marketListing'
  | 'marketListings'
  | 'marketListingsAggregate'
  | 'marketplaceContract'
  | 'marketplaceContracts'
  | 'marketplaceContractsAggregate'
  | 'me'
  | 'nft'
  | 'nftContract'
  | 'nftContracts'
  | 'nftContractsAggregate'
  | 'nftMetadata'
  | 'nftMetadatas'
  | 'nftMetadatasAggregate'
  | 'nftOwner'
  | 'nftOwners'
  | 'nftOwnersAggregate'
  | 'nft_gpanel_users'
  | 'nft_gpanel_users_aggregate'
  | 'nft_gpanel_users_by_pk'
  | 'nft_listings'
  | 'nft_listings_aggregate'
  | 'nft_listings_by_pk'
  | 'nft_transactions'
  | 'nft_transactions_aggregate'
  | 'nft_transactions_by_pk'
  | 'nfts'
  | 'nftsAggregate'
  | 'paymentGetDepositAddress'
  | 'paymentSumsubToken'
  | 'pendingTransaction'
  | 'pendingTransactions'
  | 'pendingTransactionsAggregate'
  | 'pending_tx'
  | 'pending_tx_aggregate'
  | 'pending_tx_by_pk'
  | 'rakebackLocks'
  | 'rakebackLocks_aggregate'
  | 'rakebackLocks_by_pk'
  | 'stablecoin'
  | 'stablecoins'
  | 'stablecoinsAggregate'
  | 'staking_refill'
  | 'staking_refill_aggregate'
  | 'staking_refill_by_pk'
  | 'swapGetOrder'
  | 'swapRates'
  | 'swapTransactions'
  | 'swapTransactionsAggregate'
  | 'tournaments'
  | 'tournaments_aggregate'
  | 'tournaments_by_pk'
  | 'transactionStatistic'
  | 'transactionStatistics'
  | 'transactionStatisticsAggregate'
  | 'transactions'
  | 'transactionsAggregate'
  | 'transactions_by_pk'
  | 'updateAffiliatePlayerBalance'
  | 'updateAvailableAvatarNFTs'
  | 'user'
  | 'userErc721'
  | 'userErc721s'
  | 'userErc721sAggregate'
  | 'userJob'
  | 'userJobs'
  | 'userJobsAggregage'
  | 'user_erc721_block'
  | 'user_erc721_block_aggregate'
  | 'user_erc721_block_by_pk'
  | 'user_gpanel'
  | 'user_gpanel_aggregate'
  | 'user_gpanel_by_pk'
  | 'user_lock'
  | 'user_lock_aggregate'
  | 'user_lock_by_pk'
  | 'user_staking'
  | 'user_staking_aggregate'
  | 'user_staking_by_pk'
  | 'user_staking_transactions'
  | 'user_staking_transactions_aggregate'
  | 'user_staking_transactions_by_pk'
  | 'users'
  | 'usersAggregate'
  | 'users_login'
  | 'users_login_aggregate'
  | 'users_old'
  | 'users_old_aggregate'
  | 'users_old_by_pk'
  | query_rootKeySpecifier
)[];
export type query_rootFieldPolicy = {
  NFTAvailableTickets?: FieldPolicy<any> | FieldReadFunction<any>;
  NFTFindUser?: FieldPolicy<any> | FieldReadFunction<any>;
  NFTUserConnected?: FieldPolicy<any> | FieldReadFunction<any>;
  Test?: FieldPolicy<any> | FieldReadFunction<any>;
  Test_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  Test_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  agentFindUser?: FieldPolicy<any> | FieldReadFunction<any>;
  agentGetUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  anno_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  anno_gpanel_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  anno_gpanel_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  authLogin?: FieldPolicy<any> | FieldReadFunction<any>;
  authRefresh?: FieldPolicy<any> | FieldReadFunction<any>;
  authSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_profit?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_profit_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_profit_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_history?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_history_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_history_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721_addresses?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721_addresses_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721_addresses_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbetAffiliatePlayerBalances?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbetAffiliatePlayerBalancesAggregate?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  evenbet_ticket_requests?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_ticket_requests_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_ticket_requests_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_tickets_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_tickets_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  getGpanelUUID?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelPayment?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelPayments?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelPaymentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUser?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUsersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_agent?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_agent_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_agent_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_deposit_address?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_deposit_address_aggregate?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  gpanel_users_deposit_address_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  gpanel_users_kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_kyc_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_kyc_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  kycs?: FieldPolicy<any> | FieldReadFunction<any>;
  kycs_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_ranking_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_ranking_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  liquidity_pools?: FieldPolicy<any> | FieldReadFunction<any>;
  liquidity_pools_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  liquidity_pools_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  marketListing?: FieldPolicy<any> | FieldReadFunction<any>;
  marketListings?: FieldPolicy<any> | FieldReadFunction<any>;
  marketListingsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContract?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  me?: FieldPolicy<any> | FieldReadFunction<any>;
  nft?: FieldPolicy<any> | FieldReadFunction<any>;
  nftContract?: FieldPolicy<any> | FieldReadFunction<any>;
  nftContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  nftContractsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nftMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  nftMetadatas?: FieldPolicy<any> | FieldReadFunction<any>;
  nftMetadatasAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nftOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  nftOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  nftOwnersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_gpanel_users?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_gpanel_users_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_gpanel_users_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_listings?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_listings_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_listings_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_transactions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  nfts?: FieldPolicy<any> | FieldReadFunction<any>;
  nftsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentGetDepositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSumsubToken?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTransactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_tx?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_tx_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_tx_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  rakebackLocks?: FieldPolicy<any> | FieldReadFunction<any>;
  rakebackLocks_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  rakebackLocks_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoins?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoinsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  staking_refill?: FieldPolicy<any> | FieldReadFunction<any>;
  staking_refill_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  staking_refill_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  swapGetOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  swapRates?: FieldPolicy<any> | FieldReadFunction<any>;
  swapTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  swapTransactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionStatistic?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionStatistics?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionStatisticsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAffiliatePlayerBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAvailableAvatarNFTs?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userErc721?: FieldPolicy<any> | FieldReadFunction<any>;
  userErc721s?: FieldPolicy<any> | FieldReadFunction<any>;
  userErc721sAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  userJob?: FieldPolicy<any> | FieldReadFunction<any>;
  userJobs?: FieldPolicy<any> | FieldReadFunction<any>;
  userJobsAggregage?: FieldPolicy<any> | FieldReadFunction<any>;
  user_erc721_block?: FieldPolicy<any> | FieldReadFunction<any>;
  user_erc721_block_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_erc721_block_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanel_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanel_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_lock?: FieldPolicy<any> | FieldReadFunction<any>;
  user_lock_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_lock_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_transactions_aggregate?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  user_staking_transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  usersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  users_login?: FieldPolicy<any> | FieldReadFunction<any>;
  users_login_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  users_old?: FieldPolicy<any> | FieldReadFunction<any>;
  users_old_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  users_old_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocksKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'label'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'updatedAt'
  | 'usd'
  | rakebackLocksKeySpecifier
)[];
export type rakebackLocksFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | rakebackLocks_aggregateKeySpecifier
)[];
export type rakebackLocks_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | rakebackLocks_aggregate_fieldsKeySpecifier
)[];
export type rakebackLocks_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_avg_fieldsKeySpecifier = (
  | 'id'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'usd'
  | rakebackLocks_avg_fieldsKeySpecifier
)[];
export type rakebackLocks_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'label'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'updatedAt'
  | 'usd'
  | rakebackLocks_max_fieldsKeySpecifier
)[];
export type rakebackLocks_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'label'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'updatedAt'
  | 'usd'
  | rakebackLocks_min_fieldsKeySpecifier
)[];
export type rakebackLocks_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | rakebackLocks_mutation_responseKeySpecifier
)[];
export type rakebackLocks_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_stddev_fieldsKeySpecifier = (
  | 'id'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'usd'
  | rakebackLocks_stddev_fieldsKeySpecifier
)[];
export type rakebackLocks_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'usd'
  | rakebackLocks_stddev_pop_fieldsKeySpecifier
)[];
export type rakebackLocks_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'usd'
  | rakebackLocks_stddev_samp_fieldsKeySpecifier
)[];
export type rakebackLocks_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_sum_fieldsKeySpecifier = (
  | 'id'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'usd'
  | rakebackLocks_sum_fieldsKeySpecifier
)[];
export type rakebackLocks_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_var_pop_fieldsKeySpecifier = (
  | 'id'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'usd'
  | rakebackLocks_var_pop_fieldsKeySpecifier
)[];
export type rakebackLocks_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_var_samp_fieldsKeySpecifier = (
  | 'id'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'usd'
  | rakebackLocks_var_samp_fieldsKeySpecifier
)[];
export type rakebackLocks_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type rakebackLocks_variance_fieldsKeySpecifier = (
  | 'id'
  | 'level'
  | 'percentage'
  | 'percentageNft'
  | 'usd'
  | rakebackLocks_variance_fieldsKeySpecifier
)[];
export type rakebackLocks_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageNft?: FieldPolicy<any> | FieldReadFunction<any>;
  usd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoinsKeySpecifier = (
  | 'blockchain'
  | 'blockchainId'
  | 'contract'
  | 'createdAt'
  | 'decimals'
  | 'depositActive'
  | 'depositAddressActive'
  | 'icon'
  | 'iconUrl'
  | 'id'
  | 'symbol'
  | 'units'
  | 'updatedAt'
  | 'value'
  | 'withdrawActive'
  | stablecoinsKeySpecifier
)[];
export type stablecoinsFieldPolicy = {
  blockchain?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  depositActive?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddressActive?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawActive?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | stablecoins_aggregateKeySpecifier
)[];
export type stablecoins_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | stablecoins_aggregate_fieldsKeySpecifier
)[];
export type stablecoins_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_avg_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'decimals'
  | 'id'
  | 'value'
  | stablecoins_avg_fieldsKeySpecifier
)[];
export type stablecoins_avg_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_max_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'contract'
  | 'createdAt'
  | 'decimals'
  | 'icon'
  | 'iconUrl'
  | 'id'
  | 'symbol'
  | 'units'
  | 'updatedAt'
  | 'value'
  | stablecoins_max_fieldsKeySpecifier
)[];
export type stablecoins_max_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_min_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'contract'
  | 'createdAt'
  | 'decimals'
  | 'icon'
  | 'iconUrl'
  | 'id'
  | 'symbol'
  | 'units'
  | 'updatedAt'
  | 'value'
  | stablecoins_min_fieldsKeySpecifier
)[];
export type stablecoins_min_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | stablecoins_mutation_responseKeySpecifier
)[];
export type stablecoins_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_stddev_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'decimals'
  | 'id'
  | 'value'
  | stablecoins_stddev_fieldsKeySpecifier
)[];
export type stablecoins_stddev_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_stddev_pop_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'decimals'
  | 'id'
  | 'value'
  | stablecoins_stddev_pop_fieldsKeySpecifier
)[];
export type stablecoins_stddev_pop_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_stddev_samp_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'decimals'
  | 'id'
  | 'value'
  | stablecoins_stddev_samp_fieldsKeySpecifier
)[];
export type stablecoins_stddev_samp_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_sum_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'decimals'
  | 'id'
  | 'value'
  | stablecoins_sum_fieldsKeySpecifier
)[];
export type stablecoins_sum_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_var_pop_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'decimals'
  | 'id'
  | 'value'
  | stablecoins_var_pop_fieldsKeySpecifier
)[];
export type stablecoins_var_pop_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_var_samp_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'decimals'
  | 'id'
  | 'value'
  | stablecoins_var_samp_fieldsKeySpecifier
)[];
export type stablecoins_var_samp_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stablecoins_variance_fieldsKeySpecifier = (
  | 'blockchainId'
  | 'decimals'
  | 'id'
  | 'value'
  | stablecoins_variance_fieldsKeySpecifier
)[];
export type stablecoins_variance_fieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type stakingOutputKeySpecifier = (
  | 'busd'
  | 'inp'
  | 'nft'
  | 'rackbackId'
  | 'status'
  | stakingOutputKeySpecifier
)[];
export type stakingOutputFieldPolicy = {
  busd?: FieldPolicy<any> | FieldReadFunction<any>;
  inp?: FieldPolicy<any> | FieldReadFunction<any>;
  nft?: FieldPolicy<any> | FieldReadFunction<any>;
  rackbackId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refillKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'id'
  | 'status'
  | 'tx'
  | 'updated_at'
  | 'week'
  | 'year'
  | staking_refillKeySpecifier
)[];
export type staking_refillFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tx?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | staking_refill_aggregateKeySpecifier
)[];
export type staking_refill_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | staking_refill_aggregate_fieldsKeySpecifier
)[];
export type staking_refill_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'week'
  | 'year'
  | staking_refill_avg_fieldsKeySpecifier
)[];
export type staking_refill_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_max_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'id'
  | 'status'
  | 'tx'
  | 'updated_at'
  | 'week'
  | 'year'
  | staking_refill_max_fieldsKeySpecifier
)[];
export type staking_refill_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tx?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_min_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'id'
  | 'status'
  | 'tx'
  | 'updated_at'
  | 'week'
  | 'year'
  | staking_refill_min_fieldsKeySpecifier
)[];
export type staking_refill_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tx?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | staking_refill_mutation_responseKeySpecifier
)[];
export type staking_refill_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'week'
  | 'year'
  | staking_refill_stddev_fieldsKeySpecifier
)[];
export type staking_refill_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'week'
  | 'year'
  | staking_refill_stddev_pop_fieldsKeySpecifier
)[];
export type staking_refill_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'week'
  | 'year'
  | staking_refill_stddev_samp_fieldsKeySpecifier
)[];
export type staking_refill_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'week'
  | 'year'
  | staking_refill_sum_fieldsKeySpecifier
)[];
export type staking_refill_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'week'
  | 'year'
  | staking_refill_var_pop_fieldsKeySpecifier
)[];
export type staking_refill_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'week'
  | 'year'
  | staking_refill_var_samp_fieldsKeySpecifier
)[];
export type staking_refill_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type staking_refill_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'week'
  | 'year'
  | staking_refill_variance_fieldsKeySpecifier
)[];
export type staking_refill_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  week?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type subscription_rootKeySpecifier = (
  | 'Test'
  | 'Test_aggregate'
  | 'Test_by_pk'
  | 'Test_stream'
  | 'anno_gpanel'
  | 'anno_gpanel_aggregate'
  | 'anno_gpanel_by_pk'
  | 'anno_gpanel_stream'
  | 'blockchain'
  | 'blockchain_main_deposit'
  | 'blockchain_main_deposit_aggregate'
  | 'blockchain_main_deposit_by_pk'
  | 'blockchain_main_deposit_stream'
  | 'blockchain_stream'
  | 'blockchains'
  | 'blockchainsAggregate'
  | 'burn_profit'
  | 'burn_profit_aggregate'
  | 'burn_profit_by_pk'
  | 'burn_profit_stream'
  | 'currencies'
  | 'currencies_aggregate'
  | 'currencies_by_pk'
  | 'currencies_stream'
  | 'currency_history'
  | 'currency_history_aggregate'
  | 'currency_history_by_pk'
  | 'currency_history_stream'
  | 'erc721_addresses'
  | 'erc721_addresses_aggregate'
  | 'erc721_addresses_by_pk'
  | 'erc721_addresses_stream'
  | 'evenbetAffiliatePlayerBalances'
  | 'evenbetAffiliatePlayerBalancesAggregate'
  | 'evenbetAffiliatePlayerBalancesStream'
  | 'evenbet_ticket_requests'
  | 'evenbet_ticket_requests_aggregate'
  | 'evenbet_ticket_requests_by_pk'
  | 'evenbet_ticket_requests_stream'
  | 'evenbet_tickets'
  | 'evenbet_tickets_aggregate'
  | 'evenbet_tickets_by_pk'
  | 'evenbet_tickets_stream'
  | 'gpanelPayment'
  | 'gpanelPayments'
  | 'gpanelPaymentsAggregate'
  | 'gpanelPayments_stream'
  | 'gpanelUser'
  | 'gpanelUsers'
  | 'gpanelUsersAggregate'
  | 'gpanelUsers_stream'
  | 'gpanel_users_agent'
  | 'gpanel_users_agent_aggregate'
  | 'gpanel_users_agent_by_pk'
  | 'gpanel_users_agent_stream'
  | 'gpanel_users_deposit_address'
  | 'gpanel_users_deposit_address_aggregate'
  | 'gpanel_users_deposit_address_by_pk'
  | 'gpanel_users_deposit_address_stream'
  | 'gpanel_users_kyc'
  | 'gpanel_users_kyc_aggregate'
  | 'gpanel_users_kyc_by_pk'
  | 'gpanel_users_kyc_stream'
  | 'kyc'
  | 'kycs'
  | 'kycs_aggregate'
  | 'kycs_stream'
  | 'leaderboard'
  | 'leaderboard_aggregate'
  | 'leaderboard_by_pk'
  | 'leaderboard_ranking'
  | 'leaderboard_ranking_aggregate'
  | 'leaderboard_ranking_by_pk'
  | 'leaderboard_ranking_stream'
  | 'leaderboard_stream'
  | 'liquidity_pools'
  | 'liquidity_pools_aggregate'
  | 'liquidity_pools_by_pk'
  | 'liquidity_pools_stream'
  | 'marketListing'
  | 'marketListings'
  | 'marketListingsAggregate'
  | 'marketListings_stream'
  | 'marketplaceContract'
  | 'marketplaceContracts'
  | 'marketplaceContractsAggregate'
  | 'marketplaceContracts_stream'
  | 'nft'
  | 'nftContract'
  | 'nftContracts'
  | 'nftContractsAggregate'
  | 'nftContracts_stream'
  | 'nftMetadata'
  | 'nftMetadatas'
  | 'nftMetadatasAggregate'
  | 'nftMetadatas_stream'
  | 'nftOwner'
  | 'nftOwners'
  | 'nftOwnersAggregate'
  | 'nftOwners_stream'
  | 'nft_gpanel_users'
  | 'nft_gpanel_users_aggregate'
  | 'nft_gpanel_users_by_pk'
  | 'nft_gpanel_users_stream'
  | 'nft_listings'
  | 'nft_listings_aggregate'
  | 'nft_listings_by_pk'
  | 'nft_listings_stream'
  | 'nft_transactions'
  | 'nft_transactions_aggregate'
  | 'nft_transactions_by_pk'
  | 'nft_transactions_stream'
  | 'nfts'
  | 'nftsAggregate'
  | 'nfts_stream'
  | 'pendingTransaction'
  | 'pendingTransactions'
  | 'pendingTransactionsAggregate'
  | 'pendingTransactions_stream'
  | 'pending_tx'
  | 'pending_tx_aggregate'
  | 'pending_tx_by_pk'
  | 'pending_tx_stream'
  | 'rakebackLocks'
  | 'rakebackLocks_aggregate'
  | 'rakebackLocks_by_pk'
  | 'rakebackLocks_stream'
  | 'stablecoin'
  | 'stablecoins'
  | 'stablecoinsAggregate'
  | 'stablecoins_stream'
  | 'staking_refill'
  | 'staking_refill_aggregate'
  | 'staking_refill_by_pk'
  | 'staking_refill_stream'
  | 'swapTransactions'
  | 'swapTransactionsAggregate'
  | 'swapTransactionsStream'
  | 'tournaments'
  | 'tournaments_aggregate'
  | 'tournaments_by_pk'
  | 'tournaments_stream'
  | 'transactionStatistic'
  | 'transactionStatistics'
  | 'transactionStatisticsAggregate'
  | 'transactionStatistics_stream'
  | 'transactions'
  | 'transactionsAggregate'
  | 'transactions_by_pk'
  | 'transactions_stream'
  | 'updateAvailableAvatarNFTs'
  | 'user'
  | 'userErc721'
  | 'userErc721_stream'
  | 'userErc721s'
  | 'userErc721sAggregate'
  | 'userJob'
  | 'userJobs'
  | 'userJobsAggregage'
  | 'userJobsStream'
  | 'user_erc721_block'
  | 'user_erc721_block_aggregate'
  | 'user_erc721_block_by_pk'
  | 'user_erc721_block_stream'
  | 'user_gpanel'
  | 'user_gpanel_aggregate'
  | 'user_gpanel_by_pk'
  | 'user_gpanel_stream'
  | 'user_lock'
  | 'user_lock_aggregate'
  | 'user_lock_by_pk'
  | 'user_lock_stream'
  | 'user_staking'
  | 'user_staking_aggregate'
  | 'user_staking_by_pk'
  | 'user_staking_stream'
  | 'user_staking_transactions'
  | 'user_staking_transactions_aggregate'
  | 'user_staking_transactions_by_pk'
  | 'user_staking_transactions_stream'
  | 'users'
  | 'usersAggregate'
  | 'users_login'
  | 'users_login_aggregate'
  | 'users_login_stream'
  | 'users_old'
  | 'users_old_aggregate'
  | 'users_old_by_pk'
  | 'users_old_stream'
  | 'users_stream'
  | subscription_rootKeySpecifier
)[];
export type subscription_rootFieldPolicy = {
  Test?: FieldPolicy<any> | FieldReadFunction<any>;
  Test_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  Test_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  Test_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  anno_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  anno_gpanel_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  anno_gpanel_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  anno_gpanel_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_main_deposit_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_profit?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_profit_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_profit_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  burn_profit_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_history?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_history_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_history_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  currency_history_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721_addresses?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721_addresses_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721_addresses_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721_addresses_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbetAffiliatePlayerBalances?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbetAffiliatePlayerBalancesAggregate?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  evenbetAffiliatePlayerBalancesStream?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  evenbet_ticket_requests?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_ticket_requests_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_ticket_requests_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_ticket_requests_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_tickets_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_tickets_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  evenbet_tickets_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelPayment?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelPayments?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelPaymentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelPayments_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUser?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUsersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUsers_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_agent?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_agent_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_agent_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_agent_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_deposit_address?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_deposit_address_aggregate?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  gpanel_users_deposit_address_by_pk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  gpanel_users_deposit_address_stream?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  gpanel_users_kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_kyc_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_kyc_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_kyc_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  kyc?: FieldPolicy<any> | FieldReadFunction<any>;
  kycs?: FieldPolicy<any> | FieldReadFunction<any>;
  kycs_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  kycs_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_ranking_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_ranking_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_ranking_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  leaderboard_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  liquidity_pools?: FieldPolicy<any> | FieldReadFunction<any>;
  liquidity_pools_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  liquidity_pools_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  liquidity_pools_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  marketListing?: FieldPolicy<any> | FieldReadFunction<any>;
  marketListings?: FieldPolicy<any> | FieldReadFunction<any>;
  marketListingsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  marketListings_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContract?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContractsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  marketplaceContracts_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  nft?: FieldPolicy<any> | FieldReadFunction<any>;
  nftContract?: FieldPolicy<any> | FieldReadFunction<any>;
  nftContracts?: FieldPolicy<any> | FieldReadFunction<any>;
  nftContractsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nftContracts_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  nftMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  nftMetadatas?: FieldPolicy<any> | FieldReadFunction<any>;
  nftMetadatasAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nftMetadatas_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  nftOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  nftOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  nftOwnersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nftOwners_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_gpanel_users?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_gpanel_users_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_gpanel_users_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_gpanel_users_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_listings?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_listings_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_listings_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_listings_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_transactions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_transactions_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  nfts?: FieldPolicy<any> | FieldReadFunction<any>;
  nftsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nfts_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTransactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTransactions_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_tx?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_tx_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_tx_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_tx_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  rakebackLocks?: FieldPolicy<any> | FieldReadFunction<any>;
  rakebackLocks_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  rakebackLocks_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  rakebackLocks_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoin?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoins?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoinsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  stablecoins_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  staking_refill?: FieldPolicy<any> | FieldReadFunction<any>;
  staking_refill_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  staking_refill_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  staking_refill_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  swapTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  swapTransactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  swapTransactionsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionStatistic?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionStatistics?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionStatisticsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionStatistics_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAvailableAvatarNFTs?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userErc721?: FieldPolicy<any> | FieldReadFunction<any>;
  userErc721_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  userErc721s?: FieldPolicy<any> | FieldReadFunction<any>;
  userErc721sAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  userJob?: FieldPolicy<any> | FieldReadFunction<any>;
  userJobs?: FieldPolicy<any> | FieldReadFunction<any>;
  userJobsAggregage?: FieldPolicy<any> | FieldReadFunction<any>;
  userJobsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  user_erc721_block?: FieldPolicy<any> | FieldReadFunction<any>;
  user_erc721_block_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_erc721_block_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_erc721_block_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanel?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanel_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanel_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanel_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  user_lock?: FieldPolicy<any> | FieldReadFunction<any>;
  user_lock_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_lock_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_lock_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_transactions_aggregate?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  user_staking_transactions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  user_staking_transactions_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  usersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  users_login?: FieldPolicy<any> | FieldReadFunction<any>;
  users_login_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  users_login_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  users_old?: FieldPolicy<any> | FieldReadFunction<any>;
  users_old_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  users_old_by_pk?: FieldPolicy<any> | FieldReadFunction<any>;
  users_old_stream?: FieldPolicy<any> | FieldReadFunction<any>;
  users_stream?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type sumsubOutputKeySpecifier = (
  | 'accessToken'
  | 'message'
  | sumsubOutputKeySpecifier
)[];
export type sumsubOutputFieldPolicy = {
  accessToken?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactionsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'createdAt'
  | 'currencyFrom'
  | 'currencyTo'
  | 'depositTransaction'
  | 'fee'
  | 'gpanelUserId'
  | 'id'
  | 'status'
  | 'statusInternal'
  | 'txid'
  | 'txlog'
  | 'updatedAt'
  | 'withdrawTransaction'
  | swapTransactionsKeySpecifier
)[];
export type swapTransactionsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyTo?: FieldPolicy<any> | FieldReadFunction<any>;
  depositTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | swapTransactions_aggregateKeySpecifier
)[];
export type swapTransactions_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | swapTransactions_aggregate_fieldsKeySpecifier
)[];
export type swapTransactions_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_avg_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'fee'
  | swapTransactions_avg_fieldsKeySpecifier
)[];
export type swapTransactions_avg_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_max_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'createdAt'
  | 'currencyFrom'
  | 'currencyTo'
  | 'depositTransaction'
  | 'fee'
  | 'gpanelUserId'
  | 'id'
  | 'status'
  | 'statusInternal'
  | 'txid'
  | 'txlog'
  | 'updatedAt'
  | 'withdrawTransaction'
  | swapTransactions_max_fieldsKeySpecifier
)[];
export type swapTransactions_max_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyTo?: FieldPolicy<any> | FieldReadFunction<any>;
  depositTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_min_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'createdAt'
  | 'currencyFrom'
  | 'currencyTo'
  | 'depositTransaction'
  | 'fee'
  | 'gpanelUserId'
  | 'id'
  | 'status'
  | 'statusInternal'
  | 'txid'
  | 'txlog'
  | 'updatedAt'
  | 'withdrawTransaction'
  | swapTransactions_min_fieldsKeySpecifier
)[];
export type swapTransactions_min_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyTo?: FieldPolicy<any> | FieldReadFunction<any>;
  depositTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | swapTransactions_mutation_responseKeySpecifier
)[];
export type swapTransactions_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_stddev_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'fee'
  | swapTransactions_stddev_fieldsKeySpecifier
)[];
export type swapTransactions_stddev_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_stddev_pop_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'fee'
  | swapTransactions_stddev_pop_fieldsKeySpecifier
)[];
export type swapTransactions_stddev_pop_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_stddev_samp_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'fee'
  | swapTransactions_stddev_samp_fieldsKeySpecifier
)[];
export type swapTransactions_stddev_samp_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_sum_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'fee'
  | swapTransactions_sum_fieldsKeySpecifier
)[];
export type swapTransactions_sum_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_var_pop_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'fee'
  | swapTransactions_var_pop_fieldsKeySpecifier
)[];
export type swapTransactions_var_pop_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_var_samp_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'fee'
  | swapTransactions_var_samp_fieldsKeySpecifier
)[];
export type swapTransactions_var_samp_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type swapTransactions_variance_fieldsKeySpecifier = (
  | 'amountFrom'
  | 'amountTo'
  | 'amountToMinimum'
  | 'blockchainId'
  | 'fee'
  | swapTransactions_variance_fieldsKeySpecifier
)[];
export type swapTransactions_variance_fieldsFieldPolicy = {
  amountFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  amountTo?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournamentsKeySpecifier = (
  | 'amount'
  | 'announcement'
  | 'created_at'
  | 'currency'
  | 'eventbet_tournament_id'
  | 'gpanel_user_id'
  | 'id'
  | 'name'
  | 'password'
  | 'reentry'
  | 'start'
  | 'status'
  | 'total_profit'
  | 'updated_at'
  | tournamentsKeySpecifier
)[];
export type tournamentsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  announcement?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  password?: FieldPolicy<any> | FieldReadFunction<any>;
  reentry?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | tournaments_aggregateKeySpecifier
)[];
export type tournaments_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | tournaments_aggregate_fieldsKeySpecifier
)[];
export type tournaments_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'eventbet_tournament_id'
  | 'id'
  | 'total_profit'
  | tournaments_avg_fieldsKeySpecifier
)[];
export type tournaments_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_max_fieldsKeySpecifier = (
  | 'amount'
  | 'announcement'
  | 'created_at'
  | 'currency'
  | 'eventbet_tournament_id'
  | 'gpanel_user_id'
  | 'id'
  | 'name'
  | 'password'
  | 'start'
  | 'status'
  | 'total_profit'
  | 'updated_at'
  | tournaments_max_fieldsKeySpecifier
)[];
export type tournaments_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  announcement?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  password?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_min_fieldsKeySpecifier = (
  | 'amount'
  | 'announcement'
  | 'created_at'
  | 'currency'
  | 'eventbet_tournament_id'
  | 'gpanel_user_id'
  | 'id'
  | 'name'
  | 'password'
  | 'start'
  | 'status'
  | 'total_profit'
  | 'updated_at'
  | tournaments_min_fieldsKeySpecifier
)[];
export type tournaments_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  announcement?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  password?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | tournaments_mutation_responseKeySpecifier
)[];
export type tournaments_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'eventbet_tournament_id'
  | 'id'
  | 'total_profit'
  | tournaments_stddev_fieldsKeySpecifier
)[];
export type tournaments_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'eventbet_tournament_id'
  | 'id'
  | 'total_profit'
  | tournaments_stddev_pop_fieldsKeySpecifier
)[];
export type tournaments_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'eventbet_tournament_id'
  | 'id'
  | 'total_profit'
  | tournaments_stddev_samp_fieldsKeySpecifier
)[];
export type tournaments_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'eventbet_tournament_id'
  | 'id'
  | 'total_profit'
  | tournaments_sum_fieldsKeySpecifier
)[];
export type tournaments_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'eventbet_tournament_id'
  | 'id'
  | 'total_profit'
  | tournaments_var_pop_fieldsKeySpecifier
)[];
export type tournaments_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'eventbet_tournament_id'
  | 'id'
  | 'total_profit'
  | tournaments_var_samp_fieldsKeySpecifier
)[];
export type tournaments_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type tournaments_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'eventbet_tournament_id'
  | 'id'
  | 'total_profit'
  | tournaments_variance_fieldsKeySpecifier
)[];
export type tournaments_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  eventbet_tournament_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  total_profit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatisticsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'totalCoins'
  | 'totalTransactions'
  | 'type'
  | 'updatedAt'
  | transactionStatisticsKeySpecifier
)[];
export type transactionStatisticsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCoins?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | transactionStatistics_aggregateKeySpecifier
)[];
export type transactionStatistics_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | transactionStatistics_aggregate_fieldsKeySpecifier
)[];
export type transactionStatistics_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_avg_fieldsKeySpecifier = (
  | 'id'
  | transactionStatistics_avg_fieldsKeySpecifier
)[];
export type transactionStatistics_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'totalCoins'
  | 'totalTransactions'
  | 'type'
  | 'updatedAt'
  | transactionStatistics_max_fieldsKeySpecifier
)[];
export type transactionStatistics_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCoins?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'totalCoins'
  | 'totalTransactions'
  | 'type'
  | 'updatedAt'
  | transactionStatistics_min_fieldsKeySpecifier
)[];
export type transactionStatistics_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCoins?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | transactionStatistics_mutation_responseKeySpecifier
)[];
export type transactionStatistics_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_stddev_fieldsKeySpecifier = (
  | 'id'
  | transactionStatistics_stddev_fieldsKeySpecifier
)[];
export type transactionStatistics_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | transactionStatistics_stddev_pop_fieldsKeySpecifier
)[];
export type transactionStatistics_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | transactionStatistics_stddev_samp_fieldsKeySpecifier
)[];
export type transactionStatistics_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_sum_fieldsKeySpecifier = (
  | 'id'
  | transactionStatistics_sum_fieldsKeySpecifier
)[];
export type transactionStatistics_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_var_pop_fieldsKeySpecifier = (
  | 'id'
  | transactionStatistics_var_pop_fieldsKeySpecifier
)[];
export type transactionStatistics_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_var_samp_fieldsKeySpecifier = (
  | 'id'
  | transactionStatistics_var_samp_fieldsKeySpecifier
)[];
export type transactionStatistics_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionStatistics_variance_fieldsKeySpecifier = (
  | 'id'
  | transactionStatistics_variance_fieldsKeySpecifier
)[];
export type transactionStatistics_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactionsKeySpecifier = (
  | 'blockchain_id'
  | 'createdAt'
  | 'id'
  | 'payload'
  | 'payloadBinary'
  | 'price'
  | 'txId'
  | 'type'
  | 'updatedAt'
  | transactionsKeySpecifier
)[];
export type transactionsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  payload?: FieldPolicy<any> | FieldReadFunction<any>;
  payloadBinary?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  txId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | transactions_aggregateKeySpecifier
)[];
export type transactions_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | transactions_aggregate_fieldsKeySpecifier
)[];
export type transactions_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_avg_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | 'price'
  | transactions_avg_fieldsKeySpecifier
)[];
export type transactions_avg_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_max_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'createdAt'
  | 'id'
  | 'price'
  | 'txId'
  | 'type'
  | 'updatedAt'
  | transactions_max_fieldsKeySpecifier
)[];
export type transactions_max_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  txId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_min_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'createdAt'
  | 'id'
  | 'price'
  | 'txId'
  | 'type'
  | 'updatedAt'
  | transactions_min_fieldsKeySpecifier
)[];
export type transactions_min_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  txId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | transactions_mutation_responseKeySpecifier
)[];
export type transactions_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_stddev_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | 'price'
  | transactions_stddev_fieldsKeySpecifier
)[];
export type transactions_stddev_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_stddev_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | 'price'
  | transactions_stddev_pop_fieldsKeySpecifier
)[];
export type transactions_stddev_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_stddev_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | 'price'
  | transactions_stddev_samp_fieldsKeySpecifier
)[];
export type transactions_stddev_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_sum_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | 'price'
  | transactions_sum_fieldsKeySpecifier
)[];
export type transactions_sum_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_var_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | 'price'
  | transactions_var_pop_fieldsKeySpecifier
)[];
export type transactions_var_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_var_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | 'price'
  | transactions_var_samp_fieldsKeySpecifier
)[];
export type transactions_var_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type transactions_variance_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'id'
  | 'price'
  | transactions_variance_fieldsKeySpecifier
)[];
export type transactions_variance_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type updateAvailableAvatarNFTsKeySpecifier = (
  | 'created_at'
  | 'errors'
  | 'id'
  | 'output'
  | updateAvailableAvatarNFTsKeySpecifier
)[];
export type updateAvailableAvatarNFTsFieldPolicy = {
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  errors?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  output?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721KeySpecifier = (
  | 'animationUrl'
  | 'collection'
  | 'createdAt'
  | 'erc721AddressId'
  | 'id'
  | 'imgUrl'
  | 'metaUrl'
  | 'name'
  | 'status'
  | 'tokenId'
  | 'updatedAt'
  | 'userId'
  | userErc721KeySpecifier
)[];
export type userErc721FieldPolicy = {
  animationUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  collection?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imgUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  metaUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | userErc721_aggregateKeySpecifier
)[];
export type userErc721_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | userErc721_aggregate_fieldsKeySpecifier
)[];
export type userErc721_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_avg_fieldsKeySpecifier = (
  | 'erc721AddressId'
  | 'id'
  | 'tokenId'
  | userErc721_avg_fieldsKeySpecifier
)[];
export type userErc721_avg_fieldsFieldPolicy = {
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_max_fieldsKeySpecifier = (
  | 'animationUrl'
  | 'collection'
  | 'createdAt'
  | 'erc721AddressId'
  | 'id'
  | 'imgUrl'
  | 'metaUrl'
  | 'name'
  | 'status'
  | 'tokenId'
  | 'updatedAt'
  | 'userId'
  | userErc721_max_fieldsKeySpecifier
)[];
export type userErc721_max_fieldsFieldPolicy = {
  animationUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  collection?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imgUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  metaUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_min_fieldsKeySpecifier = (
  | 'animationUrl'
  | 'collection'
  | 'createdAt'
  | 'erc721AddressId'
  | 'id'
  | 'imgUrl'
  | 'metaUrl'
  | 'name'
  | 'status'
  | 'tokenId'
  | 'updatedAt'
  | 'userId'
  | userErc721_min_fieldsKeySpecifier
)[];
export type userErc721_min_fieldsFieldPolicy = {
  animationUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  collection?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imgUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  metaUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | userErc721_mutation_responseKeySpecifier
)[];
export type userErc721_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_stddev_fieldsKeySpecifier = (
  | 'erc721AddressId'
  | 'id'
  | 'tokenId'
  | userErc721_stddev_fieldsKeySpecifier
)[];
export type userErc721_stddev_fieldsFieldPolicy = {
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_stddev_pop_fieldsKeySpecifier = (
  | 'erc721AddressId'
  | 'id'
  | 'tokenId'
  | userErc721_stddev_pop_fieldsKeySpecifier
)[];
export type userErc721_stddev_pop_fieldsFieldPolicy = {
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_stddev_samp_fieldsKeySpecifier = (
  | 'erc721AddressId'
  | 'id'
  | 'tokenId'
  | userErc721_stddev_samp_fieldsKeySpecifier
)[];
export type userErc721_stddev_samp_fieldsFieldPolicy = {
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_sum_fieldsKeySpecifier = (
  | 'erc721AddressId'
  | 'id'
  | 'tokenId'
  | userErc721_sum_fieldsKeySpecifier
)[];
export type userErc721_sum_fieldsFieldPolicy = {
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_var_pop_fieldsKeySpecifier = (
  | 'erc721AddressId'
  | 'id'
  | 'tokenId'
  | userErc721_var_pop_fieldsKeySpecifier
)[];
export type userErc721_var_pop_fieldsFieldPolicy = {
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_var_samp_fieldsKeySpecifier = (
  | 'erc721AddressId'
  | 'id'
  | 'tokenId'
  | userErc721_var_samp_fieldsKeySpecifier
)[];
export type userErc721_var_samp_fieldsFieldPolicy = {
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userErc721_variance_fieldsKeySpecifier = (
  | 'erc721AddressId'
  | 'id'
  | 'tokenId'
  | userErc721_variance_fieldsKeySpecifier
)[];
export type userErc721_variance_fieldsFieldPolicy = {
  erc721AddressId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'percentage'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | userJobsKeySpecifier
)[];
export type userJobsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | userJobs_aggregateKeySpecifier
)[];
export type userJobs_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | userJobs_aggregate_fieldsKeySpecifier
)[];
export type userJobs_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_avg_fieldsKeySpecifier = (
  | 'id'
  | 'percentage'
  | userJobs_avg_fieldsKeySpecifier
)[];
export type userJobs_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'percentage'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | userJobs_max_fieldsKeySpecifier
)[];
export type userJobs_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'percentage'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | userJobs_min_fieldsKeySpecifier
)[];
export type userJobs_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | userJobs_mutation_responseKeySpecifier
)[];
export type userJobs_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_stddev_fieldsKeySpecifier = (
  | 'id'
  | 'percentage'
  | userJobs_stddev_fieldsKeySpecifier
)[];
export type userJobs_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | 'percentage'
  | userJobs_stddev_pop_fieldsKeySpecifier
)[];
export type userJobs_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | 'percentage'
  | userJobs_stddev_samp_fieldsKeySpecifier
)[];
export type userJobs_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_sum_fieldsKeySpecifier = (
  | 'id'
  | 'percentage'
  | userJobs_sum_fieldsKeySpecifier
)[];
export type userJobs_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_var_pop_fieldsKeySpecifier = (
  | 'id'
  | 'percentage'
  | userJobs_var_pop_fieldsKeySpecifier
)[];
export type userJobs_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_var_samp_fieldsKeySpecifier = (
  | 'id'
  | 'percentage'
  | userJobs_var_samp_fieldsKeySpecifier
)[];
export type userJobs_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type userJobs_variance_fieldsKeySpecifier = (
  | 'id'
  | 'percentage'
  | userJobs_variance_fieldsKeySpecifier
)[];
export type userJobs_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_blockKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'created_at'
  | 'id'
  | 'updated_at'
  | 'user_id'
  | user_erc721_blockKeySpecifier
)[];
export type user_erc721_blockFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | user_erc721_block_aggregateKeySpecifier
)[];
export type user_erc721_block_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | user_erc721_block_aggregate_fieldsKeySpecifier
)[];
export type user_erc721_block_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_avg_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'id'
  | user_erc721_block_avg_fieldsKeySpecifier
)[];
export type user_erc721_block_avg_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_max_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'created_at'
  | 'id'
  | 'updated_at'
  | 'user_id'
  | user_erc721_block_max_fieldsKeySpecifier
)[];
export type user_erc721_block_max_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_min_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'created_at'
  | 'id'
  | 'updated_at'
  | 'user_id'
  | user_erc721_block_min_fieldsKeySpecifier
)[];
export type user_erc721_block_min_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | user_erc721_block_mutation_responseKeySpecifier
)[];
export type user_erc721_block_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_stddev_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'id'
  | user_erc721_block_stddev_fieldsKeySpecifier
)[];
export type user_erc721_block_stddev_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_stddev_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'id'
  | user_erc721_block_stddev_pop_fieldsKeySpecifier
)[];
export type user_erc721_block_stddev_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_stddev_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'id'
  | user_erc721_block_stddev_samp_fieldsKeySpecifier
)[];
export type user_erc721_block_stddev_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_sum_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'id'
  | user_erc721_block_sum_fieldsKeySpecifier
)[];
export type user_erc721_block_sum_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_var_pop_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'id'
  | user_erc721_block_var_pop_fieldsKeySpecifier
)[];
export type user_erc721_block_var_pop_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_var_samp_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'id'
  | user_erc721_block_var_samp_fieldsKeySpecifier
)[];
export type user_erc721_block_var_samp_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_erc721_block_variance_fieldsKeySpecifier = (
  | 'blockchain_id'
  | 'blocknr'
  | 'id'
  | user_erc721_block_variance_fieldsKeySpecifier
)[];
export type user_erc721_block_variance_fieldsFieldPolicy = {
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  blocknr?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanelKeySpecifier = (
  | 'access_token'
  | 'code_token'
  | 'created_at'
  | 'id'
  | 'refresh_token'
  | 'updated_at'
  | 'user_id'
  | 'uuid'
  | user_gpanelKeySpecifier
)[];
export type user_gpanelFieldPolicy = {
  access_token?: FieldPolicy<any> | FieldReadFunction<any>;
  code_token?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  refresh_token?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | user_gpanel_aggregateKeySpecifier
)[];
export type user_gpanel_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | user_gpanel_aggregate_fieldsKeySpecifier
)[];
export type user_gpanel_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_avg_fieldsKeySpecifier = (
  | 'id'
  | user_gpanel_avg_fieldsKeySpecifier
)[];
export type user_gpanel_avg_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_max_fieldsKeySpecifier = (
  | 'access_token'
  | 'code_token'
  | 'created_at'
  | 'id'
  | 'refresh_token'
  | 'updated_at'
  | 'user_id'
  | 'uuid'
  | user_gpanel_max_fieldsKeySpecifier
)[];
export type user_gpanel_max_fieldsFieldPolicy = {
  access_token?: FieldPolicy<any> | FieldReadFunction<any>;
  code_token?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  refresh_token?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_min_fieldsKeySpecifier = (
  | 'access_token'
  | 'code_token'
  | 'created_at'
  | 'id'
  | 'refresh_token'
  | 'updated_at'
  | 'user_id'
  | 'uuid'
  | user_gpanel_min_fieldsKeySpecifier
)[];
export type user_gpanel_min_fieldsFieldPolicy = {
  access_token?: FieldPolicy<any> | FieldReadFunction<any>;
  code_token?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  refresh_token?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | user_gpanel_mutation_responseKeySpecifier
)[];
export type user_gpanel_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_stddev_fieldsKeySpecifier = (
  | 'id'
  | user_gpanel_stddev_fieldsKeySpecifier
)[];
export type user_gpanel_stddev_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_stddev_pop_fieldsKeySpecifier = (
  | 'id'
  | user_gpanel_stddev_pop_fieldsKeySpecifier
)[];
export type user_gpanel_stddev_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_stddev_samp_fieldsKeySpecifier = (
  | 'id'
  | user_gpanel_stddev_samp_fieldsKeySpecifier
)[];
export type user_gpanel_stddev_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_sum_fieldsKeySpecifier = (
  | 'id'
  | user_gpanel_sum_fieldsKeySpecifier
)[];
export type user_gpanel_sum_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_var_pop_fieldsKeySpecifier = (
  | 'id'
  | user_gpanel_var_pop_fieldsKeySpecifier
)[];
export type user_gpanel_var_pop_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_var_samp_fieldsKeySpecifier = (
  | 'id'
  | user_gpanel_var_samp_fieldsKeySpecifier
)[];
export type user_gpanel_var_samp_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_gpanel_variance_fieldsKeySpecifier = (
  | 'id'
  | user_gpanel_variance_fieldsKeySpecifier
)[];
export type user_gpanel_variance_fieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lockKeySpecifier = (
  | 'amount'
  | 'confirmed'
  | 'created_at'
  | 'id'
  | 'lock_id'
  | 'removed'
  | 'updated_at'
  | 'user'
  | 'user_id'
  | user_lockKeySpecifier
)[];
export type user_lockFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  confirmed?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
  removed?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | user_lock_aggregateKeySpecifier
)[];
export type user_lock_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | user_lock_aggregate_fieldsKeySpecifier
)[];
export type user_lock_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'lock_id'
  | user_lock_avg_fieldsKeySpecifier
)[];
export type user_lock_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_max_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'id'
  | 'lock_id'
  | 'updated_at'
  | 'user_id'
  | user_lock_max_fieldsKeySpecifier
)[];
export type user_lock_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_min_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'id'
  | 'lock_id'
  | 'updated_at'
  | 'user_id'
  | user_lock_min_fieldsKeySpecifier
)[];
export type user_lock_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | user_lock_mutation_responseKeySpecifier
)[];
export type user_lock_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'lock_id'
  | user_lock_stddev_fieldsKeySpecifier
)[];
export type user_lock_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'lock_id'
  | user_lock_stddev_pop_fieldsKeySpecifier
)[];
export type user_lock_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'lock_id'
  | user_lock_stddev_samp_fieldsKeySpecifier
)[];
export type user_lock_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'lock_id'
  | user_lock_sum_fieldsKeySpecifier
)[];
export type user_lock_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'lock_id'
  | user_lock_var_pop_fieldsKeySpecifier
)[];
export type user_lock_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'lock_id'
  | user_lock_var_samp_fieldsKeySpecifier
)[];
export type user_lock_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_lock_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'lock_id'
  | user_lock_variance_fieldsKeySpecifier
)[];
export type user_lock_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lock_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_stakingKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'id'
  | 'nft'
  | 'rackback_id'
  | 'status'
  | 'unstaked_at'
  | 'updated_at'
  | 'usd_value'
  | 'user_id'
  | user_stakingKeySpecifier
)[];
export type user_stakingFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nft?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  unstaked_at?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | user_staking_aggregateKeySpecifier
)[];
export type user_staking_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | user_staking_aggregate_fieldsKeySpecifier
)[];
export type user_staking_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'rackback_id'
  | 'usd_value'
  | user_staking_avg_fieldsKeySpecifier
)[];
export type user_staking_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_max_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'id'
  | 'rackback_id'
  | 'status'
  | 'unstaked_at'
  | 'updated_at'
  | 'usd_value'
  | 'user_id'
  | user_staking_max_fieldsKeySpecifier
)[];
export type user_staking_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  unstaked_at?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_min_fieldsKeySpecifier = (
  | 'amount'
  | 'created_at'
  | 'id'
  | 'rackback_id'
  | 'status'
  | 'unstaked_at'
  | 'updated_at'
  | 'usd_value'
  | 'user_id'
  | user_staking_min_fieldsKeySpecifier
)[];
export type user_staking_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  unstaked_at?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
  user_id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | user_staking_mutation_responseKeySpecifier
)[];
export type user_staking_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'rackback_id'
  | 'usd_value'
  | user_staking_stddev_fieldsKeySpecifier
)[];
export type user_staking_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'rackback_id'
  | 'usd_value'
  | user_staking_stddev_pop_fieldsKeySpecifier
)[];
export type user_staking_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'rackback_id'
  | 'usd_value'
  | user_staking_stddev_samp_fieldsKeySpecifier
)[];
export type user_staking_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'rackback_id'
  | 'usd_value'
  | user_staking_sum_fieldsKeySpecifier
)[];
export type user_staking_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactionsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'created_at'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'updated_at'
  | 'usd_value'
  | user_staking_transactionsKeySpecifier
)[];
export type user_staking_transactionsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | user_staking_transactions_aggregateKeySpecifier
)[];
export type user_staking_transactions_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | user_staking_transactions_aggregate_fieldsKeySpecifier
)[];
export type user_staking_transactions_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_avg_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'usd_value'
  | user_staking_transactions_avg_fieldsKeySpecifier
)[];
export type user_staking_transactions_avg_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_max_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'created_at'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'updated_at'
  | 'usd_value'
  | user_staking_transactions_max_fieldsKeySpecifier
)[];
export type user_staking_transactions_max_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_min_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'created_at'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'updated_at'
  | 'usd_value'
  | user_staking_transactions_min_fieldsKeySpecifier
)[];
export type user_staking_transactions_min_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | user_staking_transactions_mutation_responseKeySpecifier
)[];
export type user_staking_transactions_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_stddev_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'usd_value'
  | user_staking_transactions_stddev_fieldsKeySpecifier
)[];
export type user_staking_transactions_stddev_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_stddev_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'usd_value'
  | user_staking_transactions_stddev_pop_fieldsKeySpecifier
)[];
export type user_staking_transactions_stddev_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_stddev_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'usd_value'
  | user_staking_transactions_stddev_samp_fieldsKeySpecifier
)[];
export type user_staking_transactions_stddev_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_sum_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'usd_value'
  | user_staking_transactions_sum_fieldsKeySpecifier
)[];
export type user_staking_transactions_sum_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'usd_value'
  | user_staking_transactions_var_pop_fieldsKeySpecifier
)[];
export type user_staking_transactions_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'usd_value'
  | user_staking_transactions_var_samp_fieldsKeySpecifier
)[];
export type user_staking_transactions_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_transactions_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchain_id'
  | 'id'
  | 'timeStamp'
  | 'usd_value'
  | user_staking_transactions_variance_fieldsKeySpecifier
)[];
export type user_staking_transactions_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_var_pop_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'rackback_id'
  | 'usd_value'
  | user_staking_var_pop_fieldsKeySpecifier
)[];
export type user_staking_var_pop_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_var_samp_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'rackback_id'
  | 'usd_value'
  | user_staking_var_samp_fieldsKeySpecifier
)[];
export type user_staking_var_samp_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type user_staking_variance_fieldsKeySpecifier = (
  | 'amount'
  | 'id'
  | 'rackback_id'
  | 'usd_value'
  | user_staking_variance_fieldsKeySpecifier
)[];
export type user_staking_variance_fieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rackback_id?: FieldPolicy<any> | FieldReadFunction<any>;
  usd_value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type usersKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'createdAt'
  | 'ethAddress'
  | 'gpanelUserId'
  | 'gpanel_users'
  | 'gpanel_users_aggregate'
  | 'id'
  | 'idnr'
  | 'language'
  | 'lastSeen'
  | 'lastSignin'
  | 'nft_owners'
  | 'nft_owners_aggregate'
  | 'pending_transactions'
  | 'pending_transactions_aggregate'
  | 'pokerBusd'
  | 'pokerInp'
  | 'pokerName'
  | 'signature'
  | 'status'
  | 'telegramName'
  | 'updatedAt'
  | 'user_gpanels'
  | 'user_gpanels_aggregate'
  | 'user_locks'
  | 'user_locks_aggregate'
  | usersKeySpecifier
)[];
export type usersFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  ethAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_users_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSeen?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSignin?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_owners?: FieldPolicy<any> | FieldReadFunction<any>;
  nft_owners_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  pending_transactions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerName?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramName?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanels?: FieldPolicy<any> | FieldReadFunction<any>;
  user_gpanels_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_locks?: FieldPolicy<any> | FieldReadFunction<any>;
  user_locks_aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | users_aggregateKeySpecifier
)[];
export type users_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | users_aggregate_fieldsKeySpecifier
)[];
export type users_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_avg_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'gpanelUserId'
  | 'idnr'
  | 'pokerBusd'
  | 'pokerInp'
  | users_avg_fieldsKeySpecifier
)[];
export type users_avg_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_loginKeySpecifier = (
  | 'eth_address'
  | 'gpanel_id'
  | 'id'
  | 'source'
  | 'username'
  | users_loginKeySpecifier
)[];
export type users_loginFieldPolicy = {
  eth_address?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_login_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | users_login_aggregateKeySpecifier
)[];
export type users_login_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_login_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | users_login_aggregate_fieldsKeySpecifier
)[];
export type users_login_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_login_max_fieldsKeySpecifier = (
  | 'eth_address'
  | 'gpanel_id'
  | 'id'
  | 'source'
  | 'username'
  | users_login_max_fieldsKeySpecifier
)[];
export type users_login_max_fieldsFieldPolicy = {
  eth_address?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_login_min_fieldsKeySpecifier = (
  | 'eth_address'
  | 'gpanel_id'
  | 'id'
  | 'source'
  | 'username'
  | users_login_min_fieldsKeySpecifier
)[];
export type users_login_min_fieldsFieldPolicy = {
  eth_address?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanel_id?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_max_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'createdAt'
  | 'ethAddress'
  | 'gpanelUserId'
  | 'id'
  | 'idnr'
  | 'language'
  | 'lastSeen'
  | 'lastSignin'
  | 'pokerBusd'
  | 'pokerInp'
  | 'pokerName'
  | 'signature'
  | 'status'
  | 'telegramName'
  | 'updatedAt'
  | users_max_fieldsKeySpecifier
)[];
export type users_max_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  ethAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSeen?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSignin?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerName?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramName?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_min_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'createdAt'
  | 'ethAddress'
  | 'gpanelUserId'
  | 'id'
  | 'idnr'
  | 'language'
  | 'lastSeen'
  | 'lastSignin'
  | 'pokerBusd'
  | 'pokerInp'
  | 'pokerName'
  | 'signature'
  | 'status'
  | 'telegramName'
  | 'updatedAt'
  | users_min_fieldsKeySpecifier
)[];
export type users_min_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  ethAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSeen?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSignin?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerName?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramName?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | users_mutation_responseKeySpecifier
)[];
export type users_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_oldKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'created_at'
  | 'eth_address'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | 'poker_name'
  | 'status'
  | 'telegram_name'
  | 'updated_at'
  | users_oldKeySpecifier
)[];
export type users_oldFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  eth_address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  telegram_name?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | users_old_aggregateKeySpecifier
)[];
export type users_old_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | users_old_aggregate_fieldsKeySpecifier
)[];
export type users_old_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>;
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_avg_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | users_old_avg_fieldsKeySpecifier
)[];
export type users_old_avg_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_max_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'created_at'
  | 'eth_address'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | 'poker_name'
  | 'status'
  | 'telegram_name'
  | 'updated_at'
  | users_old_max_fieldsKeySpecifier
)[];
export type users_old_max_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  eth_address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  telegram_name?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_min_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'created_at'
  | 'eth_address'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | 'poker_name'
  | 'status'
  | 'telegram_name'
  | 'updated_at'
  | users_old_min_fieldsKeySpecifier
)[];
export type users_old_min_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  created_at?: FieldPolicy<any> | FieldReadFunction<any>;
  eth_address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  telegram_name?: FieldPolicy<any> | FieldReadFunction<any>;
  updated_at?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | users_old_mutation_responseKeySpecifier
)[];
export type users_old_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_stddev_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | users_old_stddev_fieldsKeySpecifier
)[];
export type users_old_stddev_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_stddev_pop_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | users_old_stddev_pop_fieldsKeySpecifier
)[];
export type users_old_stddev_pop_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_stddev_samp_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | users_old_stddev_samp_fieldsKeySpecifier
)[];
export type users_old_stddev_samp_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_sum_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | users_old_sum_fieldsKeySpecifier
)[];
export type users_old_sum_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_var_pop_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | users_old_var_pop_fieldsKeySpecifier
)[];
export type users_old_var_pop_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_var_samp_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | users_old_var_samp_fieldsKeySpecifier
)[];
export type users_old_var_samp_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_old_variance_fieldsKeySpecifier = (
  | 'chain_busd'
  | 'chain_inp'
  | 'id'
  | 'poker_busd'
  | 'poker_inp'
  | users_old_variance_fieldsKeySpecifier
)[];
export type users_old_variance_fieldsFieldPolicy = {
  chain_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  chain_inp?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_busd?: FieldPolicy<any> | FieldReadFunction<any>;
  poker_inp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_stddev_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'gpanelUserId'
  | 'idnr'
  | 'pokerBusd'
  | 'pokerInp'
  | users_stddev_fieldsKeySpecifier
)[];
export type users_stddev_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_stddev_pop_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'gpanelUserId'
  | 'idnr'
  | 'pokerBusd'
  | 'pokerInp'
  | users_stddev_pop_fieldsKeySpecifier
)[];
export type users_stddev_pop_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_stddev_samp_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'gpanelUserId'
  | 'idnr'
  | 'pokerBusd'
  | 'pokerInp'
  | users_stddev_samp_fieldsKeySpecifier
)[];
export type users_stddev_samp_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_sum_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'gpanelUserId'
  | 'idnr'
  | 'pokerBusd'
  | 'pokerInp'
  | users_sum_fieldsKeySpecifier
)[];
export type users_sum_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_var_pop_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'gpanelUserId'
  | 'idnr'
  | 'pokerBusd'
  | 'pokerInp'
  | users_var_pop_fieldsKeySpecifier
)[];
export type users_var_pop_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_var_samp_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'gpanelUserId'
  | 'idnr'
  | 'pokerBusd'
  | 'pokerInp'
  | users_var_samp_fieldsKeySpecifier
)[];
export type users_var_samp_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type users_variance_fieldsKeySpecifier = (
  | 'chainBusd'
  | 'chainInp'
  | 'gpanelUserId'
  | 'idnr'
  | 'pokerBusd'
  | 'pokerInp'
  | users_variance_fieldsKeySpecifier
)[];
export type users_variance_fieldsFieldPolicy = {
  chainBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  chainInp?: FieldPolicy<any> | FieldReadFunction<any>;
  gpanelUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  idnr?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerBusd?: FieldPolicy<any> | FieldReadFunction<any>;
  pokerInp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  DepositOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DepositOutputKeySpecifier
      | (() => undefined | DepositOutputKeySpecifier);
    fields?: DepositOutputFieldPolicy;
  };
  GeneralMessageOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GeneralMessageOutputKeySpecifier
      | (() => undefined | GeneralMessageOutputKeySpecifier);
    fields?: GeneralMessageOutputFieldPolicy;
  };
  KYCSubmitOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KYCSubmitOutputKeySpecifier
      | (() => undefined | KYCSubmitOutputKeySpecifier);
    fields?: KYCSubmitOutputFieldPolicy;
  };
  NFTConnectedDetail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NFTConnectedDetailKeySpecifier
      | (() => undefined | NFTConnectedDetailKeySpecifier);
    fields?: NFTConnectedDetailFieldPolicy;
  };
  NFTConnectedOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NFTConnectedOutputKeySpecifier
      | (() => undefined | NFTConnectedOutputKeySpecifier);
    fields?: NFTConnectedOutputFieldPolicy;
  };
  NFTTicketsClaimOptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NFTTicketsClaimOptionsKeySpecifier
      | (() => undefined | NFTTicketsClaimOptionsKeySpecifier);
    fields?: NFTTicketsClaimOptionsFieldPolicy;
  };
  NFTTicketsClaimOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NFTTicketsClaimOutputKeySpecifier
      | (() => undefined | NFTTicketsClaimOutputKeySpecifier);
    fields?: NFTTicketsClaimOutputFieldPolicy;
  };
  RateOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RateOutputKeySpecifier
      | (() => undefined | RateOutputKeySpecifier);
    fields?: RateOutputFieldPolicy;
  };
  StatusOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusOutputKeySpecifier
      | (() => undefined | StatusOutputKeySpecifier);
    fields?: StatusOutputFieldPolicy;
  };
  SwapOrderOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SwapOrderOutputKeySpecifier
      | (() => undefined | SwapOrderOutputKeySpecifier);
    fields?: SwapOrderOutputFieldPolicy;
  };
  SwapOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SwapOutputKeySpecifier
      | (() => undefined | SwapOutputKeySpecifier);
    fields?: SwapOutputFieldPolicy;
  };
  Test?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TestKeySpecifier | (() => undefined | TestKeySpecifier);
    fields?: TestFieldPolicy;
  };
  Test_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_aggregateKeySpecifier
      | (() => undefined | Test_aggregateKeySpecifier);
    fields?: Test_aggregateFieldPolicy;
  };
  Test_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_aggregate_fieldsKeySpecifier
      | (() => undefined | Test_aggregate_fieldsKeySpecifier);
    fields?: Test_aggregate_fieldsFieldPolicy;
  };
  Test_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_avg_fieldsKeySpecifier
      | (() => undefined | Test_avg_fieldsKeySpecifier);
    fields?: Test_avg_fieldsFieldPolicy;
  };
  Test_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_max_fieldsKeySpecifier
      | (() => undefined | Test_max_fieldsKeySpecifier);
    fields?: Test_max_fieldsFieldPolicy;
  };
  Test_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_min_fieldsKeySpecifier
      | (() => undefined | Test_min_fieldsKeySpecifier);
    fields?: Test_min_fieldsFieldPolicy;
  };
  Test_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_mutation_responseKeySpecifier
      | (() => undefined | Test_mutation_responseKeySpecifier);
    fields?: Test_mutation_responseFieldPolicy;
  };
  Test_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_stddev_fieldsKeySpecifier
      | (() => undefined | Test_stddev_fieldsKeySpecifier);
    fields?: Test_stddev_fieldsFieldPolicy;
  };
  Test_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_stddev_pop_fieldsKeySpecifier
      | (() => undefined | Test_stddev_pop_fieldsKeySpecifier);
    fields?: Test_stddev_pop_fieldsFieldPolicy;
  };
  Test_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_stddev_samp_fieldsKeySpecifier
      | (() => undefined | Test_stddev_samp_fieldsKeySpecifier);
    fields?: Test_stddev_samp_fieldsFieldPolicy;
  };
  Test_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_sum_fieldsKeySpecifier
      | (() => undefined | Test_sum_fieldsKeySpecifier);
    fields?: Test_sum_fieldsFieldPolicy;
  };
  Test_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_var_pop_fieldsKeySpecifier
      | (() => undefined | Test_var_pop_fieldsKeySpecifier);
    fields?: Test_var_pop_fieldsFieldPolicy;
  };
  Test_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_var_samp_fieldsKeySpecifier
      | (() => undefined | Test_var_samp_fieldsKeySpecifier);
    fields?: Test_var_samp_fieldsFieldPolicy;
  };
  Test_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Test_variance_fieldsKeySpecifier
      | (() => undefined | Test_variance_fieldsKeySpecifier);
    fields?: Test_variance_fieldsFieldPolicy;
  };
  UserOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserOutputKeySpecifier
      | (() => undefined | UserOutputKeySpecifier);
    fields?: UserOutputFieldPolicy;
  };
  agentFindUserOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | agentFindUserOutputKeySpecifier
      | (() => undefined | agentFindUserOutputKeySpecifier);
    fields?: agentFindUserOutputFieldPolicy;
  };
  agentGetUsersOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | agentGetUsersOutputKeySpecifier
      | (() => undefined | agentGetUsersOutputKeySpecifier);
    fields?: agentGetUsersOutputFieldPolicy;
  };
  agentUserItemOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | agentUserItemOutputKeySpecifier
      | (() => undefined | agentUserItemOutputKeySpecifier);
    fields?: agentUserItemOutputFieldPolicy;
  };
  agentUserOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | agentUserOutputKeySpecifier
      | (() => undefined | agentUserOutputKeySpecifier);
    fields?: agentUserOutputFieldPolicy;
  };
  anno_gpanel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanelKeySpecifier
      | (() => undefined | anno_gpanelKeySpecifier);
    fields?: anno_gpanelFieldPolicy;
  };
  anno_gpanel_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_aggregateKeySpecifier
      | (() => undefined | anno_gpanel_aggregateKeySpecifier);
    fields?: anno_gpanel_aggregateFieldPolicy;
  };
  anno_gpanel_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_aggregate_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_aggregate_fieldsKeySpecifier);
    fields?: anno_gpanel_aggregate_fieldsFieldPolicy;
  };
  anno_gpanel_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_avg_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_avg_fieldsKeySpecifier);
    fields?: anno_gpanel_avg_fieldsFieldPolicy;
  };
  anno_gpanel_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_max_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_max_fieldsKeySpecifier);
    fields?: anno_gpanel_max_fieldsFieldPolicy;
  };
  anno_gpanel_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_min_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_min_fieldsKeySpecifier);
    fields?: anno_gpanel_min_fieldsFieldPolicy;
  };
  anno_gpanel_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_mutation_responseKeySpecifier
      | (() => undefined | anno_gpanel_mutation_responseKeySpecifier);
    fields?: anno_gpanel_mutation_responseFieldPolicy;
  };
  anno_gpanel_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_stddev_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_stddev_fieldsKeySpecifier);
    fields?: anno_gpanel_stddev_fieldsFieldPolicy;
  };
  anno_gpanel_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_stddev_pop_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_stddev_pop_fieldsKeySpecifier);
    fields?: anno_gpanel_stddev_pop_fieldsFieldPolicy;
  };
  anno_gpanel_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_stddev_samp_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_stddev_samp_fieldsKeySpecifier);
    fields?: anno_gpanel_stddev_samp_fieldsFieldPolicy;
  };
  anno_gpanel_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_sum_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_sum_fieldsKeySpecifier);
    fields?: anno_gpanel_sum_fieldsFieldPolicy;
  };
  anno_gpanel_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_var_pop_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_var_pop_fieldsKeySpecifier);
    fields?: anno_gpanel_var_pop_fieldsFieldPolicy;
  };
  anno_gpanel_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_var_samp_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_var_samp_fieldsKeySpecifier);
    fields?: anno_gpanel_var_samp_fieldsFieldPolicy;
  };
  anno_gpanel_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | anno_gpanel_variance_fieldsKeySpecifier
      | (() => undefined | anno_gpanel_variance_fieldsKeySpecifier);
    fields?: anno_gpanel_variance_fieldsFieldPolicy;
  };
  authLoginOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | authLoginOutputKeySpecifier
      | (() => undefined | authLoginOutputKeySpecifier);
    fields?: authLoginOutputFieldPolicy;
  };
  authSignatureOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | authSignatureOutputKeySpecifier
      | (() => undefined | authSignatureOutputKeySpecifier);
    fields?: authSignatureOutputFieldPolicy;
  };
  avatarOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | avatarOutputKeySpecifier
      | (() => undefined | avatarOutputKeySpecifier);
    fields?: avatarOutputFieldPolicy;
  };
  blockchain?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchainKeySpecifier
      | (() => undefined | blockchainKeySpecifier);
    fields?: blockchainFieldPolicy;
  };
  blockchain_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_aggregateKeySpecifier
      | (() => undefined | blockchain_aggregateKeySpecifier);
    fields?: blockchain_aggregateFieldPolicy;
  };
  blockchain_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_aggregate_fieldsKeySpecifier
      | (() => undefined | blockchain_aggregate_fieldsKeySpecifier);
    fields?: blockchain_aggregate_fieldsFieldPolicy;
  };
  blockchain_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_avg_fieldsKeySpecifier
      | (() => undefined | blockchain_avg_fieldsKeySpecifier);
    fields?: blockchain_avg_fieldsFieldPolicy;
  };
  blockchain_main_deposit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_main_depositKeySpecifier
      | (() => undefined | blockchain_main_depositKeySpecifier);
    fields?: blockchain_main_depositFieldPolicy;
  };
  blockchain_main_deposit_aggregate?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_aggregateKeySpecifier
      | (() => undefined | blockchain_main_deposit_aggregateKeySpecifier);
    fields?: blockchain_main_deposit_aggregateFieldPolicy;
  };
  blockchain_main_deposit_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_aggregate_fieldsKeySpecifier
      | (() =>
          | undefined
          | blockchain_main_deposit_aggregate_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_aggregate_fieldsFieldPolicy;
  };
  blockchain_main_deposit_avg_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_avg_fieldsKeySpecifier
      | (() => undefined | blockchain_main_deposit_avg_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_avg_fieldsFieldPolicy;
  };
  blockchain_main_deposit_max_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_max_fieldsKeySpecifier
      | (() => undefined | blockchain_main_deposit_max_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_max_fieldsFieldPolicy;
  };
  blockchain_main_deposit_min_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_min_fieldsKeySpecifier
      | (() => undefined | blockchain_main_deposit_min_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_min_fieldsFieldPolicy;
  };
  blockchain_main_deposit_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_mutation_responseKeySpecifier
      | (() =>
          | undefined
          | blockchain_main_deposit_mutation_responseKeySpecifier);
    fields?: blockchain_main_deposit_mutation_responseFieldPolicy;
  };
  blockchain_main_deposit_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_stddev_fieldsKeySpecifier
      | (() => undefined | blockchain_main_deposit_stddev_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_stddev_fieldsFieldPolicy;
  };
  blockchain_main_deposit_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_stddev_pop_fieldsKeySpecifier
      | (() =>
          | undefined
          | blockchain_main_deposit_stddev_pop_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_stddev_pop_fieldsFieldPolicy;
  };
  blockchain_main_deposit_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_stddev_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | blockchain_main_deposit_stddev_samp_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_stddev_samp_fieldsFieldPolicy;
  };
  blockchain_main_deposit_sum_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_sum_fieldsKeySpecifier
      | (() => undefined | blockchain_main_deposit_sum_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_sum_fieldsFieldPolicy;
  };
  blockchain_main_deposit_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_var_pop_fieldsKeySpecifier
      | (() => undefined | blockchain_main_deposit_var_pop_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_var_pop_fieldsFieldPolicy;
  };
  blockchain_main_deposit_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_var_samp_fieldsKeySpecifier
      | (() => undefined | blockchain_main_deposit_var_samp_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_var_samp_fieldsFieldPolicy;
  };
  blockchain_main_deposit_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | blockchain_main_deposit_variance_fieldsKeySpecifier
      | (() => undefined | blockchain_main_deposit_variance_fieldsKeySpecifier);
    fields?: blockchain_main_deposit_variance_fieldsFieldPolicy;
  };
  blockchain_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_max_fieldsKeySpecifier
      | (() => undefined | blockchain_max_fieldsKeySpecifier);
    fields?: blockchain_max_fieldsFieldPolicy;
  };
  blockchain_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_min_fieldsKeySpecifier
      | (() => undefined | blockchain_min_fieldsKeySpecifier);
    fields?: blockchain_min_fieldsFieldPolicy;
  };
  blockchain_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_mutation_responseKeySpecifier
      | (() => undefined | blockchain_mutation_responseKeySpecifier);
    fields?: blockchain_mutation_responseFieldPolicy;
  };
  blockchain_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_stddev_fieldsKeySpecifier
      | (() => undefined | blockchain_stddev_fieldsKeySpecifier);
    fields?: blockchain_stddev_fieldsFieldPolicy;
  };
  blockchain_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_stddev_pop_fieldsKeySpecifier
      | (() => undefined | blockchain_stddev_pop_fieldsKeySpecifier);
    fields?: blockchain_stddev_pop_fieldsFieldPolicy;
  };
  blockchain_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_stddev_samp_fieldsKeySpecifier
      | (() => undefined | blockchain_stddev_samp_fieldsKeySpecifier);
    fields?: blockchain_stddev_samp_fieldsFieldPolicy;
  };
  blockchain_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_sum_fieldsKeySpecifier
      | (() => undefined | blockchain_sum_fieldsKeySpecifier);
    fields?: blockchain_sum_fieldsFieldPolicy;
  };
  blockchain_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_var_pop_fieldsKeySpecifier
      | (() => undefined | blockchain_var_pop_fieldsKeySpecifier);
    fields?: blockchain_var_pop_fieldsFieldPolicy;
  };
  blockchain_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_var_samp_fieldsKeySpecifier
      | (() => undefined | blockchain_var_samp_fieldsKeySpecifier);
    fields?: blockchain_var_samp_fieldsFieldPolicy;
  };
  blockchain_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | blockchain_variance_fieldsKeySpecifier
      | (() => undefined | blockchain_variance_fieldsKeySpecifier);
    fields?: blockchain_variance_fieldsFieldPolicy;
  };
  burn_profit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profitKeySpecifier
      | (() => undefined | burn_profitKeySpecifier);
    fields?: burn_profitFieldPolicy;
  };
  burn_profit_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_aggregateKeySpecifier
      | (() => undefined | burn_profit_aggregateKeySpecifier);
    fields?: burn_profit_aggregateFieldPolicy;
  };
  burn_profit_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_aggregate_fieldsKeySpecifier
      | (() => undefined | burn_profit_aggregate_fieldsKeySpecifier);
    fields?: burn_profit_aggregate_fieldsFieldPolicy;
  };
  burn_profit_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_avg_fieldsKeySpecifier
      | (() => undefined | burn_profit_avg_fieldsKeySpecifier);
    fields?: burn_profit_avg_fieldsFieldPolicy;
  };
  burn_profit_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_max_fieldsKeySpecifier
      | (() => undefined | burn_profit_max_fieldsKeySpecifier);
    fields?: burn_profit_max_fieldsFieldPolicy;
  };
  burn_profit_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_min_fieldsKeySpecifier
      | (() => undefined | burn_profit_min_fieldsKeySpecifier);
    fields?: burn_profit_min_fieldsFieldPolicy;
  };
  burn_profit_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_mutation_responseKeySpecifier
      | (() => undefined | burn_profit_mutation_responseKeySpecifier);
    fields?: burn_profit_mutation_responseFieldPolicy;
  };
  burn_profit_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_stddev_fieldsKeySpecifier
      | (() => undefined | burn_profit_stddev_fieldsKeySpecifier);
    fields?: burn_profit_stddev_fieldsFieldPolicy;
  };
  burn_profit_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_stddev_pop_fieldsKeySpecifier
      | (() => undefined | burn_profit_stddev_pop_fieldsKeySpecifier);
    fields?: burn_profit_stddev_pop_fieldsFieldPolicy;
  };
  burn_profit_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_stddev_samp_fieldsKeySpecifier
      | (() => undefined | burn_profit_stddev_samp_fieldsKeySpecifier);
    fields?: burn_profit_stddev_samp_fieldsFieldPolicy;
  };
  burn_profit_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_sum_fieldsKeySpecifier
      | (() => undefined | burn_profit_sum_fieldsKeySpecifier);
    fields?: burn_profit_sum_fieldsFieldPolicy;
  };
  burn_profit_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_var_pop_fieldsKeySpecifier
      | (() => undefined | burn_profit_var_pop_fieldsKeySpecifier);
    fields?: burn_profit_var_pop_fieldsFieldPolicy;
  };
  burn_profit_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_var_samp_fieldsKeySpecifier
      | (() => undefined | burn_profit_var_samp_fieldsKeySpecifier);
    fields?: burn_profit_var_samp_fieldsFieldPolicy;
  };
  burn_profit_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | burn_profit_variance_fieldsKeySpecifier
      | (() => undefined | burn_profit_variance_fieldsKeySpecifier);
    fields?: burn_profit_variance_fieldsFieldPolicy;
  };
  currencies?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currenciesKeySpecifier
      | (() => undefined | currenciesKeySpecifier);
    fields?: currenciesFieldPolicy;
  };
  currencies_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_aggregateKeySpecifier
      | (() => undefined | currencies_aggregateKeySpecifier);
    fields?: currencies_aggregateFieldPolicy;
  };
  currencies_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_aggregate_fieldsKeySpecifier
      | (() => undefined | currencies_aggregate_fieldsKeySpecifier);
    fields?: currencies_aggregate_fieldsFieldPolicy;
  };
  currencies_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_avg_fieldsKeySpecifier
      | (() => undefined | currencies_avg_fieldsKeySpecifier);
    fields?: currencies_avg_fieldsFieldPolicy;
  };
  currencies_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_max_fieldsKeySpecifier
      | (() => undefined | currencies_max_fieldsKeySpecifier);
    fields?: currencies_max_fieldsFieldPolicy;
  };
  currencies_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_min_fieldsKeySpecifier
      | (() => undefined | currencies_min_fieldsKeySpecifier);
    fields?: currencies_min_fieldsFieldPolicy;
  };
  currencies_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_mutation_responseKeySpecifier
      | (() => undefined | currencies_mutation_responseKeySpecifier);
    fields?: currencies_mutation_responseFieldPolicy;
  };
  currencies_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_stddev_fieldsKeySpecifier
      | (() => undefined | currencies_stddev_fieldsKeySpecifier);
    fields?: currencies_stddev_fieldsFieldPolicy;
  };
  currencies_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_stddev_pop_fieldsKeySpecifier
      | (() => undefined | currencies_stddev_pop_fieldsKeySpecifier);
    fields?: currencies_stddev_pop_fieldsFieldPolicy;
  };
  currencies_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_stddev_samp_fieldsKeySpecifier
      | (() => undefined | currencies_stddev_samp_fieldsKeySpecifier);
    fields?: currencies_stddev_samp_fieldsFieldPolicy;
  };
  currencies_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_sum_fieldsKeySpecifier
      | (() => undefined | currencies_sum_fieldsKeySpecifier);
    fields?: currencies_sum_fieldsFieldPolicy;
  };
  currencies_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_var_pop_fieldsKeySpecifier
      | (() => undefined | currencies_var_pop_fieldsKeySpecifier);
    fields?: currencies_var_pop_fieldsFieldPolicy;
  };
  currencies_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_var_samp_fieldsKeySpecifier
      | (() => undefined | currencies_var_samp_fieldsKeySpecifier);
    fields?: currencies_var_samp_fieldsFieldPolicy;
  };
  currencies_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currencies_variance_fieldsKeySpecifier
      | (() => undefined | currencies_variance_fieldsKeySpecifier);
    fields?: currencies_variance_fieldsFieldPolicy;
  };
  currency_history?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currency_historyKeySpecifier
      | (() => undefined | currency_historyKeySpecifier);
    fields?: currency_historyFieldPolicy;
  };
  currency_history_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currency_history_aggregateKeySpecifier
      | (() => undefined | currency_history_aggregateKeySpecifier);
    fields?: currency_history_aggregateFieldPolicy;
  };
  currency_history_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | currency_history_aggregate_fieldsKeySpecifier
      | (() => undefined | currency_history_aggregate_fieldsKeySpecifier);
    fields?: currency_history_aggregate_fieldsFieldPolicy;
  };
  currency_history_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currency_history_avg_fieldsKeySpecifier
      | (() => undefined | currency_history_avg_fieldsKeySpecifier);
    fields?: currency_history_avg_fieldsFieldPolicy;
  };
  currency_history_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currency_history_max_fieldsKeySpecifier
      | (() => undefined | currency_history_max_fieldsKeySpecifier);
    fields?: currency_history_max_fieldsFieldPolicy;
  };
  currency_history_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currency_history_min_fieldsKeySpecifier
      | (() => undefined | currency_history_min_fieldsKeySpecifier);
    fields?: currency_history_min_fieldsFieldPolicy;
  };
  currency_history_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | currency_history_mutation_responseKeySpecifier
      | (() => undefined | currency_history_mutation_responseKeySpecifier);
    fields?: currency_history_mutation_responseFieldPolicy;
  };
  currency_history_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currency_history_stddev_fieldsKeySpecifier
      | (() => undefined | currency_history_stddev_fieldsKeySpecifier);
    fields?: currency_history_stddev_fieldsFieldPolicy;
  };
  currency_history_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | currency_history_stddev_pop_fieldsKeySpecifier
      | (() => undefined | currency_history_stddev_pop_fieldsKeySpecifier);
    fields?: currency_history_stddev_pop_fieldsFieldPolicy;
  };
  currency_history_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | currency_history_stddev_samp_fieldsKeySpecifier
      | (() => undefined | currency_history_stddev_samp_fieldsKeySpecifier);
    fields?: currency_history_stddev_samp_fieldsFieldPolicy;
  };
  currency_history_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currency_history_sum_fieldsKeySpecifier
      | (() => undefined | currency_history_sum_fieldsKeySpecifier);
    fields?: currency_history_sum_fieldsFieldPolicy;
  };
  currency_history_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | currency_history_var_pop_fieldsKeySpecifier
      | (() => undefined | currency_history_var_pop_fieldsKeySpecifier);
    fields?: currency_history_var_pop_fieldsFieldPolicy;
  };
  currency_history_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | currency_history_var_samp_fieldsKeySpecifier
      | (() => undefined | currency_history_var_samp_fieldsKeySpecifier);
    fields?: currency_history_var_samp_fieldsFieldPolicy;
  };
  currency_history_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | currency_history_variance_fieldsKeySpecifier
      | (() => undefined | currency_history_variance_fieldsKeySpecifier);
    fields?: currency_history_variance_fieldsFieldPolicy;
  };
  depositAddressOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | depositAddressOutputKeySpecifier
      | (() => undefined | depositAddressOutputKeySpecifier);
    fields?: depositAddressOutputFieldPolicy;
  };
  erc721_addresses?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | erc721_addressesKeySpecifier
      | (() => undefined | erc721_addressesKeySpecifier);
    fields?: erc721_addressesFieldPolicy;
  };
  erc721_addresses_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | erc721_addresses_aggregateKeySpecifier
      | (() => undefined | erc721_addresses_aggregateKeySpecifier);
    fields?: erc721_addresses_aggregateFieldPolicy;
  };
  erc721_addresses_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | erc721_addresses_aggregate_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_aggregate_fieldsKeySpecifier);
    fields?: erc721_addresses_aggregate_fieldsFieldPolicy;
  };
  erc721_addresses_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | erc721_addresses_avg_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_avg_fieldsKeySpecifier);
    fields?: erc721_addresses_avg_fieldsFieldPolicy;
  };
  erc721_addresses_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | erc721_addresses_max_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_max_fieldsKeySpecifier);
    fields?: erc721_addresses_max_fieldsFieldPolicy;
  };
  erc721_addresses_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | erc721_addresses_min_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_min_fieldsKeySpecifier);
    fields?: erc721_addresses_min_fieldsFieldPolicy;
  };
  erc721_addresses_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | erc721_addresses_mutation_responseKeySpecifier
      | (() => undefined | erc721_addresses_mutation_responseKeySpecifier);
    fields?: erc721_addresses_mutation_responseFieldPolicy;
  };
  erc721_addresses_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | erc721_addresses_stddev_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_stddev_fieldsKeySpecifier);
    fields?: erc721_addresses_stddev_fieldsFieldPolicy;
  };
  erc721_addresses_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | erc721_addresses_stddev_pop_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_stddev_pop_fieldsKeySpecifier);
    fields?: erc721_addresses_stddev_pop_fieldsFieldPolicy;
  };
  erc721_addresses_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | erc721_addresses_stddev_samp_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_stddev_samp_fieldsKeySpecifier);
    fields?: erc721_addresses_stddev_samp_fieldsFieldPolicy;
  };
  erc721_addresses_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | erc721_addresses_sum_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_sum_fieldsKeySpecifier);
    fields?: erc721_addresses_sum_fieldsFieldPolicy;
  };
  erc721_addresses_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | erc721_addresses_var_pop_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_var_pop_fieldsKeySpecifier);
    fields?: erc721_addresses_var_pop_fieldsFieldPolicy;
  };
  erc721_addresses_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | erc721_addresses_var_samp_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_var_samp_fieldsKeySpecifier);
    fields?: erc721_addresses_var_samp_fieldsFieldPolicy;
  };
  erc721_addresses_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | erc721_addresses_variance_fieldsKeySpecifier
      | (() => undefined | erc721_addresses_variance_fieldsKeySpecifier);
    fields?: erc721_addresses_variance_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalancesKeySpecifier
      | (() => undefined | evenbetAffiliatePlayerBalancesKeySpecifier);
    fields?: evenbetAffiliatePlayerBalancesFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_aggregate?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_aggregateKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_aggregateKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_aggregateFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_aggregate_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_aggregate_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_aggregate_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_avg_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_avg_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_avg_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_avg_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_max_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_max_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_max_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_max_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_min_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_min_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_min_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_min_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_stddev_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_stddev_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_stddev_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_stddev_pop_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_stddev_pop_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_stddev_pop_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_stddev_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_stddev_samp_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_stddev_samp_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_sum_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_sum_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_sum_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_sum_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_var_pop_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_var_pop_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_var_pop_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_var_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_var_samp_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_var_samp_fieldsFieldPolicy;
  };
  evenbetAffiliatePlayerBalances_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbetAffiliatePlayerBalances_variance_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbetAffiliatePlayerBalances_variance_fieldsKeySpecifier);
    fields?: evenbetAffiliatePlayerBalances_variance_fieldsFieldPolicy;
  };
  evenbet_ticket_requests?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_ticket_requestsKeySpecifier
      | (() => undefined | evenbet_ticket_requestsKeySpecifier);
    fields?: evenbet_ticket_requestsFieldPolicy;
  };
  evenbet_ticket_requests_aggregate?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_aggregateKeySpecifier
      | (() => undefined | evenbet_ticket_requests_aggregateKeySpecifier);
    fields?: evenbet_ticket_requests_aggregateFieldPolicy;
  };
  evenbet_ticket_requests_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_aggregate_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbet_ticket_requests_aggregate_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_aggregate_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_avg_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_avg_fieldsKeySpecifier
      | (() => undefined | evenbet_ticket_requests_avg_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_avg_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_max_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_max_fieldsKeySpecifier
      | (() => undefined | evenbet_ticket_requests_max_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_max_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_min_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_min_fieldsKeySpecifier
      | (() => undefined | evenbet_ticket_requests_min_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_min_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_mutation_responseKeySpecifier
      | (() =>
          | undefined
          | evenbet_ticket_requests_mutation_responseKeySpecifier);
    fields?: evenbet_ticket_requests_mutation_responseFieldPolicy;
  };
  evenbet_ticket_requests_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_stddev_fieldsKeySpecifier
      | (() => undefined | evenbet_ticket_requests_stddev_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_stddev_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_stddev_pop_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbet_ticket_requests_stddev_pop_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_stddev_pop_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_stddev_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | evenbet_ticket_requests_stddev_samp_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_stddev_samp_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_sum_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_sum_fieldsKeySpecifier
      | (() => undefined | evenbet_ticket_requests_sum_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_sum_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_var_pop_fieldsKeySpecifier
      | (() => undefined | evenbet_ticket_requests_var_pop_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_var_pop_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_var_samp_fieldsKeySpecifier
      | (() => undefined | evenbet_ticket_requests_var_samp_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_var_samp_fieldsFieldPolicy;
  };
  evenbet_ticket_requests_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_ticket_requests_variance_fieldsKeySpecifier
      | (() => undefined | evenbet_ticket_requests_variance_fieldsKeySpecifier);
    fields?: evenbet_ticket_requests_variance_fieldsFieldPolicy;
  };
  evenbet_tickets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_ticketsKeySpecifier
      | (() => undefined | evenbet_ticketsKeySpecifier);
    fields?: evenbet_ticketsFieldPolicy;
  };
  evenbet_tickets_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_aggregateKeySpecifier
      | (() => undefined | evenbet_tickets_aggregateKeySpecifier);
    fields?: evenbet_tickets_aggregateFieldPolicy;
  };
  evenbet_tickets_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_tickets_aggregate_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_aggregate_fieldsKeySpecifier);
    fields?: evenbet_tickets_aggregate_fieldsFieldPolicy;
  };
  evenbet_tickets_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_avg_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_avg_fieldsKeySpecifier);
    fields?: evenbet_tickets_avg_fieldsFieldPolicy;
  };
  evenbet_tickets_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_max_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_max_fieldsKeySpecifier);
    fields?: evenbet_tickets_max_fieldsFieldPolicy;
  };
  evenbet_tickets_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_min_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_min_fieldsKeySpecifier);
    fields?: evenbet_tickets_min_fieldsFieldPolicy;
  };
  evenbet_tickets_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_tickets_mutation_responseKeySpecifier
      | (() => undefined | evenbet_tickets_mutation_responseKeySpecifier);
    fields?: evenbet_tickets_mutation_responseFieldPolicy;
  };
  evenbet_tickets_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_stddev_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_stddev_fieldsKeySpecifier);
    fields?: evenbet_tickets_stddev_fieldsFieldPolicy;
  };
  evenbet_tickets_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_tickets_stddev_pop_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_stddev_pop_fieldsKeySpecifier);
    fields?: evenbet_tickets_stddev_pop_fieldsFieldPolicy;
  };
  evenbet_tickets_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | evenbet_tickets_stddev_samp_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_stddev_samp_fieldsKeySpecifier);
    fields?: evenbet_tickets_stddev_samp_fieldsFieldPolicy;
  };
  evenbet_tickets_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_sum_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_sum_fieldsKeySpecifier);
    fields?: evenbet_tickets_sum_fieldsFieldPolicy;
  };
  evenbet_tickets_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_var_pop_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_var_pop_fieldsKeySpecifier);
    fields?: evenbet_tickets_var_pop_fieldsFieldPolicy;
  };
  evenbet_tickets_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_var_samp_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_var_samp_fieldsKeySpecifier);
    fields?: evenbet_tickets_var_samp_fieldsFieldPolicy;
  };
  evenbet_tickets_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | evenbet_tickets_variance_fieldsKeySpecifier
      | (() => undefined | evenbet_tickets_variance_fieldsKeySpecifier);
    fields?: evenbet_tickets_variance_fieldsFieldPolicy;
  };
  gPanelOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gPanelOutputKeySpecifier
      | (() => undefined | gPanelOutputKeySpecifier);
    fields?: gPanelOutputFieldPolicy;
  };
  gpanelPayments?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPaymentsKeySpecifier
      | (() => undefined | gpanelPaymentsKeySpecifier);
    fields?: gpanelPaymentsFieldPolicy;
  };
  gpanelPayments_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_aggregateKeySpecifier
      | (() => undefined | gpanelPayments_aggregateKeySpecifier);
    fields?: gpanelPayments_aggregateFieldPolicy;
  };
  gpanelPayments_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_aggregate_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_aggregate_fieldsKeySpecifier);
    fields?: gpanelPayments_aggregate_fieldsFieldPolicy;
  };
  gpanelPayments_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_avg_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_avg_fieldsKeySpecifier);
    fields?: gpanelPayments_avg_fieldsFieldPolicy;
  };
  gpanelPayments_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_max_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_max_fieldsKeySpecifier);
    fields?: gpanelPayments_max_fieldsFieldPolicy;
  };
  gpanelPayments_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_min_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_min_fieldsKeySpecifier);
    fields?: gpanelPayments_min_fieldsFieldPolicy;
  };
  gpanelPayments_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanelPayments_mutation_responseKeySpecifier
      | (() => undefined | gpanelPayments_mutation_responseKeySpecifier);
    fields?: gpanelPayments_mutation_responseFieldPolicy;
  };
  gpanelPayments_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_stddev_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_stddev_fieldsKeySpecifier);
    fields?: gpanelPayments_stddev_fieldsFieldPolicy;
  };
  gpanelPayments_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanelPayments_stddev_pop_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_stddev_pop_fieldsKeySpecifier);
    fields?: gpanelPayments_stddev_pop_fieldsFieldPolicy;
  };
  gpanelPayments_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanelPayments_stddev_samp_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_stddev_samp_fieldsKeySpecifier);
    fields?: gpanelPayments_stddev_samp_fieldsFieldPolicy;
  };
  gpanelPayments_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_sum_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_sum_fieldsKeySpecifier);
    fields?: gpanelPayments_sum_fieldsFieldPolicy;
  };
  gpanelPayments_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_var_pop_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_var_pop_fieldsKeySpecifier);
    fields?: gpanelPayments_var_pop_fieldsFieldPolicy;
  };
  gpanelPayments_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_var_samp_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_var_samp_fieldsKeySpecifier);
    fields?: gpanelPayments_var_samp_fieldsFieldPolicy;
  };
  gpanelPayments_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelPayments_variance_fieldsKeySpecifier
      | (() => undefined | gpanelPayments_variance_fieldsKeySpecifier);
    fields?: gpanelPayments_variance_fieldsFieldPolicy;
  };
  gpanelUsers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsersKeySpecifier
      | (() => undefined | gpanelUsersKeySpecifier);
    fields?: gpanelUsersFieldPolicy;
  };
  gpanelUsers_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_aggregateKeySpecifier
      | (() => undefined | gpanelUsers_aggregateKeySpecifier);
    fields?: gpanelUsers_aggregateFieldPolicy;
  };
  gpanelUsers_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_aggregate_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_aggregate_fieldsKeySpecifier);
    fields?: gpanelUsers_aggregate_fieldsFieldPolicy;
  };
  gpanelUsers_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_avg_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_avg_fieldsKeySpecifier);
    fields?: gpanelUsers_avg_fieldsFieldPolicy;
  };
  gpanelUsers_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_max_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_max_fieldsKeySpecifier);
    fields?: gpanelUsers_max_fieldsFieldPolicy;
  };
  gpanelUsers_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_min_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_min_fieldsKeySpecifier);
    fields?: gpanelUsers_min_fieldsFieldPolicy;
  };
  gpanelUsers_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_mutation_responseKeySpecifier
      | (() => undefined | gpanelUsers_mutation_responseKeySpecifier);
    fields?: gpanelUsers_mutation_responseFieldPolicy;
  };
  gpanelUsers_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_stddev_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_stddev_fieldsKeySpecifier);
    fields?: gpanelUsers_stddev_fieldsFieldPolicy;
  };
  gpanelUsers_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_stddev_pop_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_stddev_pop_fieldsKeySpecifier);
    fields?: gpanelUsers_stddev_pop_fieldsFieldPolicy;
  };
  gpanelUsers_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_stddev_samp_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_stddev_samp_fieldsKeySpecifier);
    fields?: gpanelUsers_stddev_samp_fieldsFieldPolicy;
  };
  gpanelUsers_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_sum_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_sum_fieldsKeySpecifier);
    fields?: gpanelUsers_sum_fieldsFieldPolicy;
  };
  gpanelUsers_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_var_pop_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_var_pop_fieldsKeySpecifier);
    fields?: gpanelUsers_var_pop_fieldsFieldPolicy;
  };
  gpanelUsers_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_var_samp_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_var_samp_fieldsKeySpecifier);
    fields?: gpanelUsers_var_samp_fieldsFieldPolicy;
  };
  gpanelUsers_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanelUsers_variance_fieldsKeySpecifier
      | (() => undefined | gpanelUsers_variance_fieldsKeySpecifier);
    fields?: gpanelUsers_variance_fieldsFieldPolicy;
  };
  gpanel_users_agent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_agentKeySpecifier
      | (() => undefined | gpanel_users_agentKeySpecifier);
    fields?: gpanel_users_agentFieldPolicy;
  };
  gpanel_users_agent_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_agent_aggregateKeySpecifier
      | (() => undefined | gpanel_users_agent_aggregateKeySpecifier);
    fields?: gpanel_users_agent_aggregateFieldPolicy;
  };
  gpanel_users_agent_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_agent_aggregate_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_aggregate_fieldsKeySpecifier);
    fields?: gpanel_users_agent_aggregate_fieldsFieldPolicy;
  };
  gpanel_users_agent_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_agent_avg_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_avg_fieldsKeySpecifier);
    fields?: gpanel_users_agent_avg_fieldsFieldPolicy;
  };
  gpanel_users_agent_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_agent_max_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_max_fieldsKeySpecifier);
    fields?: gpanel_users_agent_max_fieldsFieldPolicy;
  };
  gpanel_users_agent_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_agent_min_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_min_fieldsKeySpecifier);
    fields?: gpanel_users_agent_min_fieldsFieldPolicy;
  };
  gpanel_users_agent_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_agent_mutation_responseKeySpecifier
      | (() => undefined | gpanel_users_agent_mutation_responseKeySpecifier);
    fields?: gpanel_users_agent_mutation_responseFieldPolicy;
  };
  gpanel_users_agent_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_agent_stddev_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_stddev_fieldsKeySpecifier);
    fields?: gpanel_users_agent_stddev_fieldsFieldPolicy;
  };
  gpanel_users_agent_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_agent_stddev_pop_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_stddev_pop_fieldsKeySpecifier);
    fields?: gpanel_users_agent_stddev_pop_fieldsFieldPolicy;
  };
  gpanel_users_agent_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_agent_stddev_samp_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_stddev_samp_fieldsKeySpecifier);
    fields?: gpanel_users_agent_stddev_samp_fieldsFieldPolicy;
  };
  gpanel_users_agent_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_agent_sum_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_sum_fieldsKeySpecifier);
    fields?: gpanel_users_agent_sum_fieldsFieldPolicy;
  };
  gpanel_users_agent_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_agent_var_pop_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_var_pop_fieldsKeySpecifier);
    fields?: gpanel_users_agent_var_pop_fieldsFieldPolicy;
  };
  gpanel_users_agent_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_agent_var_samp_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_var_samp_fieldsKeySpecifier);
    fields?: gpanel_users_agent_var_samp_fieldsFieldPolicy;
  };
  gpanel_users_agent_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_agent_variance_fieldsKeySpecifier
      | (() => undefined | gpanel_users_agent_variance_fieldsKeySpecifier);
    fields?: gpanel_users_agent_variance_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_deposit_addressKeySpecifier
      | (() => undefined | gpanel_users_deposit_addressKeySpecifier);
    fields?: gpanel_users_deposit_addressFieldPolicy;
  };
  gpanel_users_deposit_address_aggregate?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_aggregateKeySpecifier
      | (() => undefined | gpanel_users_deposit_address_aggregateKeySpecifier);
    fields?: gpanel_users_deposit_address_aggregateFieldPolicy;
  };
  gpanel_users_deposit_address_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_aggregate_fieldsKeySpecifier
      | (() =>
          | undefined
          | gpanel_users_deposit_address_aggregate_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_aggregate_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_avg_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_avg_fieldsKeySpecifier
      | (() => undefined | gpanel_users_deposit_address_avg_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_avg_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_max_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_max_fieldsKeySpecifier
      | (() => undefined | gpanel_users_deposit_address_max_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_max_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_min_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_min_fieldsKeySpecifier
      | (() => undefined | gpanel_users_deposit_address_min_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_min_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_mutation_responseKeySpecifier
      | (() =>
          | undefined
          | gpanel_users_deposit_address_mutation_responseKeySpecifier);
    fields?: gpanel_users_deposit_address_mutation_responseFieldPolicy;
  };
  gpanel_users_deposit_address_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_stddev_fieldsKeySpecifier
      | (() =>
          | undefined
          | gpanel_users_deposit_address_stddev_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_stddev_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_stddev_pop_fieldsKeySpecifier
      | (() =>
          | undefined
          | gpanel_users_deposit_address_stddev_pop_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_stddev_pop_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_stddev_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | gpanel_users_deposit_address_stddev_samp_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_stddev_samp_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_sum_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_sum_fieldsKeySpecifier
      | (() => undefined | gpanel_users_deposit_address_sum_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_sum_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_var_pop_fieldsKeySpecifier
      | (() =>
          | undefined
          | gpanel_users_deposit_address_var_pop_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_var_pop_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_var_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | gpanel_users_deposit_address_var_samp_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_var_samp_fieldsFieldPolicy;
  };
  gpanel_users_deposit_address_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_deposit_address_variance_fieldsKeySpecifier
      | (() =>
          | undefined
          | gpanel_users_deposit_address_variance_fieldsKeySpecifier);
    fields?: gpanel_users_deposit_address_variance_fieldsFieldPolicy;
  };
  gpanel_users_kyc?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_kycKeySpecifier
      | (() => undefined | gpanel_users_kycKeySpecifier);
    fields?: gpanel_users_kycFieldPolicy;
  };
  gpanel_users_kyc_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_kyc_aggregateKeySpecifier
      | (() => undefined | gpanel_users_kyc_aggregateKeySpecifier);
    fields?: gpanel_users_kyc_aggregateFieldPolicy;
  };
  gpanel_users_kyc_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_kyc_aggregate_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_aggregate_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_aggregate_fieldsFieldPolicy;
  };
  gpanel_users_kyc_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_kyc_avg_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_avg_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_avg_fieldsFieldPolicy;
  };
  gpanel_users_kyc_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_kyc_max_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_max_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_max_fieldsFieldPolicy;
  };
  gpanel_users_kyc_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_kyc_min_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_min_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_min_fieldsFieldPolicy;
  };
  gpanel_users_kyc_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_kyc_mutation_responseKeySpecifier
      | (() => undefined | gpanel_users_kyc_mutation_responseKeySpecifier);
    fields?: gpanel_users_kyc_mutation_responseFieldPolicy;
  };
  gpanel_users_kyc_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_kyc_stddev_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_stddev_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_stddev_fieldsFieldPolicy;
  };
  gpanel_users_kyc_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_kyc_stddev_pop_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_stddev_pop_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_stddev_pop_fieldsFieldPolicy;
  };
  gpanel_users_kyc_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_kyc_stddev_samp_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_stddev_samp_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_stddev_samp_fieldsFieldPolicy;
  };
  gpanel_users_kyc_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_kyc_sum_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_sum_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_sum_fieldsFieldPolicy;
  };
  gpanel_users_kyc_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gpanel_users_kyc_var_pop_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_var_pop_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_var_pop_fieldsFieldPolicy;
  };
  gpanel_users_kyc_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_kyc_var_samp_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_var_samp_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_var_samp_fieldsFieldPolicy;
  };
  gpanel_users_kyc_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | gpanel_users_kyc_variance_fieldsKeySpecifier
      | (() => undefined | gpanel_users_kyc_variance_fieldsKeySpecifier);
    fields?: gpanel_users_kyc_variance_fieldsFieldPolicy;
  };
  kycOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycOutputKeySpecifier
      | (() => undefined | kycOutputKeySpecifier);
    fields?: kycOutputFieldPolicy;
  };
  kycs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | kycsKeySpecifier | (() => undefined | kycsKeySpecifier);
    fields?: kycsFieldPolicy;
  };
  kycs_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_aggregateKeySpecifier
      | (() => undefined | kycs_aggregateKeySpecifier);
    fields?: kycs_aggregateFieldPolicy;
  };
  kycs_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_aggregate_fieldsKeySpecifier
      | (() => undefined | kycs_aggregate_fieldsKeySpecifier);
    fields?: kycs_aggregate_fieldsFieldPolicy;
  };
  kycs_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_avg_fieldsKeySpecifier
      | (() => undefined | kycs_avg_fieldsKeySpecifier);
    fields?: kycs_avg_fieldsFieldPolicy;
  };
  kycs_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_max_fieldsKeySpecifier
      | (() => undefined | kycs_max_fieldsKeySpecifier);
    fields?: kycs_max_fieldsFieldPolicy;
  };
  kycs_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_min_fieldsKeySpecifier
      | (() => undefined | kycs_min_fieldsKeySpecifier);
    fields?: kycs_min_fieldsFieldPolicy;
  };
  kycs_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_mutation_responseKeySpecifier
      | (() => undefined | kycs_mutation_responseKeySpecifier);
    fields?: kycs_mutation_responseFieldPolicy;
  };
  kycs_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_stddev_fieldsKeySpecifier
      | (() => undefined | kycs_stddev_fieldsKeySpecifier);
    fields?: kycs_stddev_fieldsFieldPolicy;
  };
  kycs_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_stddev_pop_fieldsKeySpecifier
      | (() => undefined | kycs_stddev_pop_fieldsKeySpecifier);
    fields?: kycs_stddev_pop_fieldsFieldPolicy;
  };
  kycs_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_stddev_samp_fieldsKeySpecifier
      | (() => undefined | kycs_stddev_samp_fieldsKeySpecifier);
    fields?: kycs_stddev_samp_fieldsFieldPolicy;
  };
  kycs_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_sum_fieldsKeySpecifier
      | (() => undefined | kycs_sum_fieldsKeySpecifier);
    fields?: kycs_sum_fieldsFieldPolicy;
  };
  kycs_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_var_pop_fieldsKeySpecifier
      | (() => undefined | kycs_var_pop_fieldsKeySpecifier);
    fields?: kycs_var_pop_fieldsFieldPolicy;
  };
  kycs_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_var_samp_fieldsKeySpecifier
      | (() => undefined | kycs_var_samp_fieldsKeySpecifier);
    fields?: kycs_var_samp_fieldsFieldPolicy;
  };
  kycs_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | kycs_variance_fieldsKeySpecifier
      | (() => undefined | kycs_variance_fieldsKeySpecifier);
    fields?: kycs_variance_fieldsFieldPolicy;
  };
  leaderboard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboardKeySpecifier
      | (() => undefined | leaderboardKeySpecifier);
    fields?: leaderboardFieldPolicy;
  };
  leaderboard_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_aggregateKeySpecifier
      | (() => undefined | leaderboard_aggregateKeySpecifier);
    fields?: leaderboard_aggregateFieldPolicy;
  };
  leaderboard_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_aggregate_fieldsKeySpecifier
      | (() => undefined | leaderboard_aggregate_fieldsKeySpecifier);
    fields?: leaderboard_aggregate_fieldsFieldPolicy;
  };
  leaderboard_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_avg_fieldsKeySpecifier
      | (() => undefined | leaderboard_avg_fieldsKeySpecifier);
    fields?: leaderboard_avg_fieldsFieldPolicy;
  };
  leaderboard_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_max_fieldsKeySpecifier
      | (() => undefined | leaderboard_max_fieldsKeySpecifier);
    fields?: leaderboard_max_fieldsFieldPolicy;
  };
  leaderboard_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_min_fieldsKeySpecifier
      | (() => undefined | leaderboard_min_fieldsKeySpecifier);
    fields?: leaderboard_min_fieldsFieldPolicy;
  };
  leaderboard_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_mutation_responseKeySpecifier
      | (() => undefined | leaderboard_mutation_responseKeySpecifier);
    fields?: leaderboard_mutation_responseFieldPolicy;
  };
  leaderboard_ranking?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_rankingKeySpecifier
      | (() => undefined | leaderboard_rankingKeySpecifier);
    fields?: leaderboard_rankingFieldPolicy;
  };
  leaderboard_ranking_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_ranking_aggregateKeySpecifier
      | (() => undefined | leaderboard_ranking_aggregateKeySpecifier);
    fields?: leaderboard_ranking_aggregateFieldPolicy;
  };
  leaderboard_ranking_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | leaderboard_ranking_aggregate_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_aggregate_fieldsKeySpecifier);
    fields?: leaderboard_ranking_aggregate_fieldsFieldPolicy;
  };
  leaderboard_ranking_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_ranking_avg_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_avg_fieldsKeySpecifier);
    fields?: leaderboard_ranking_avg_fieldsFieldPolicy;
  };
  leaderboard_ranking_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_ranking_max_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_max_fieldsKeySpecifier);
    fields?: leaderboard_ranking_max_fieldsFieldPolicy;
  };
  leaderboard_ranking_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_ranking_min_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_min_fieldsKeySpecifier);
    fields?: leaderboard_ranking_min_fieldsFieldPolicy;
  };
  leaderboard_ranking_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | leaderboard_ranking_mutation_responseKeySpecifier
      | (() => undefined | leaderboard_ranking_mutation_responseKeySpecifier);
    fields?: leaderboard_ranking_mutation_responseFieldPolicy;
  };
  leaderboard_ranking_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | leaderboard_ranking_stddev_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_stddev_fieldsKeySpecifier);
    fields?: leaderboard_ranking_stddev_fieldsFieldPolicy;
  };
  leaderboard_ranking_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | leaderboard_ranking_stddev_pop_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_stddev_pop_fieldsKeySpecifier);
    fields?: leaderboard_ranking_stddev_pop_fieldsFieldPolicy;
  };
  leaderboard_ranking_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | leaderboard_ranking_stddev_samp_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_stddev_samp_fieldsKeySpecifier);
    fields?: leaderboard_ranking_stddev_samp_fieldsFieldPolicy;
  };
  leaderboard_ranking_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_ranking_sum_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_sum_fieldsKeySpecifier);
    fields?: leaderboard_ranking_sum_fieldsFieldPolicy;
  };
  leaderboard_ranking_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | leaderboard_ranking_var_pop_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_var_pop_fieldsKeySpecifier);
    fields?: leaderboard_ranking_var_pop_fieldsFieldPolicy;
  };
  leaderboard_ranking_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | leaderboard_ranking_var_samp_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_var_samp_fieldsKeySpecifier);
    fields?: leaderboard_ranking_var_samp_fieldsFieldPolicy;
  };
  leaderboard_ranking_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | leaderboard_ranking_variance_fieldsKeySpecifier
      | (() => undefined | leaderboard_ranking_variance_fieldsKeySpecifier);
    fields?: leaderboard_ranking_variance_fieldsFieldPolicy;
  };
  leaderboard_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_stddev_fieldsKeySpecifier
      | (() => undefined | leaderboard_stddev_fieldsKeySpecifier);
    fields?: leaderboard_stddev_fieldsFieldPolicy;
  };
  leaderboard_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_stddev_pop_fieldsKeySpecifier
      | (() => undefined | leaderboard_stddev_pop_fieldsKeySpecifier);
    fields?: leaderboard_stddev_pop_fieldsFieldPolicy;
  };
  leaderboard_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_stddev_samp_fieldsKeySpecifier
      | (() => undefined | leaderboard_stddev_samp_fieldsKeySpecifier);
    fields?: leaderboard_stddev_samp_fieldsFieldPolicy;
  };
  leaderboard_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_sum_fieldsKeySpecifier
      | (() => undefined | leaderboard_sum_fieldsKeySpecifier);
    fields?: leaderboard_sum_fieldsFieldPolicy;
  };
  leaderboard_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_var_pop_fieldsKeySpecifier
      | (() => undefined | leaderboard_var_pop_fieldsKeySpecifier);
    fields?: leaderboard_var_pop_fieldsFieldPolicy;
  };
  leaderboard_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_var_samp_fieldsKeySpecifier
      | (() => undefined | leaderboard_var_samp_fieldsKeySpecifier);
    fields?: leaderboard_var_samp_fieldsFieldPolicy;
  };
  leaderboard_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | leaderboard_variance_fieldsKeySpecifier
      | (() => undefined | leaderboard_variance_fieldsKeySpecifier);
    fields?: leaderboard_variance_fieldsFieldPolicy;
  };
  liquidity_pools?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_poolsKeySpecifier
      | (() => undefined | liquidity_poolsKeySpecifier);
    fields?: liquidity_poolsFieldPolicy;
  };
  liquidity_pools_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_aggregateKeySpecifier
      | (() => undefined | liquidity_pools_aggregateKeySpecifier);
    fields?: liquidity_pools_aggregateFieldPolicy;
  };
  liquidity_pools_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | liquidity_pools_aggregate_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_aggregate_fieldsKeySpecifier);
    fields?: liquidity_pools_aggregate_fieldsFieldPolicy;
  };
  liquidity_pools_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_avg_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_avg_fieldsKeySpecifier);
    fields?: liquidity_pools_avg_fieldsFieldPolicy;
  };
  liquidity_pools_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_max_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_max_fieldsKeySpecifier);
    fields?: liquidity_pools_max_fieldsFieldPolicy;
  };
  liquidity_pools_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_min_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_min_fieldsKeySpecifier);
    fields?: liquidity_pools_min_fieldsFieldPolicy;
  };
  liquidity_pools_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | liquidity_pools_mutation_responseKeySpecifier
      | (() => undefined | liquidity_pools_mutation_responseKeySpecifier);
    fields?: liquidity_pools_mutation_responseFieldPolicy;
  };
  liquidity_pools_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_stddev_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_stddev_fieldsKeySpecifier);
    fields?: liquidity_pools_stddev_fieldsFieldPolicy;
  };
  liquidity_pools_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | liquidity_pools_stddev_pop_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_stddev_pop_fieldsKeySpecifier);
    fields?: liquidity_pools_stddev_pop_fieldsFieldPolicy;
  };
  liquidity_pools_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | liquidity_pools_stddev_samp_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_stddev_samp_fieldsKeySpecifier);
    fields?: liquidity_pools_stddev_samp_fieldsFieldPolicy;
  };
  liquidity_pools_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_sum_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_sum_fieldsKeySpecifier);
    fields?: liquidity_pools_sum_fieldsFieldPolicy;
  };
  liquidity_pools_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_var_pop_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_var_pop_fieldsKeySpecifier);
    fields?: liquidity_pools_var_pop_fieldsFieldPolicy;
  };
  liquidity_pools_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_var_samp_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_var_samp_fieldsKeySpecifier);
    fields?: liquidity_pools_var_samp_fieldsFieldPolicy;
  };
  liquidity_pools_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | liquidity_pools_variance_fieldsKeySpecifier
      | (() => undefined | liquidity_pools_variance_fieldsKeySpecifier);
    fields?: liquidity_pools_variance_fieldsFieldPolicy;
  };
  marketListings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListingsKeySpecifier
      | (() => undefined | marketListingsKeySpecifier);
    fields?: marketListingsFieldPolicy;
  };
  marketListings_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_aggregateKeySpecifier
      | (() => undefined | marketListings_aggregateKeySpecifier);
    fields?: marketListings_aggregateFieldPolicy;
  };
  marketListings_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_aggregate_fieldsKeySpecifier
      | (() => undefined | marketListings_aggregate_fieldsKeySpecifier);
    fields?: marketListings_aggregate_fieldsFieldPolicy;
  };
  marketListings_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_avg_fieldsKeySpecifier
      | (() => undefined | marketListings_avg_fieldsKeySpecifier);
    fields?: marketListings_avg_fieldsFieldPolicy;
  };
  marketListings_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_max_fieldsKeySpecifier
      | (() => undefined | marketListings_max_fieldsKeySpecifier);
    fields?: marketListings_max_fieldsFieldPolicy;
  };
  marketListings_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_min_fieldsKeySpecifier
      | (() => undefined | marketListings_min_fieldsKeySpecifier);
    fields?: marketListings_min_fieldsFieldPolicy;
  };
  marketListings_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketListings_mutation_responseKeySpecifier
      | (() => undefined | marketListings_mutation_responseKeySpecifier);
    fields?: marketListings_mutation_responseFieldPolicy;
  };
  marketListings_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_stddev_fieldsKeySpecifier
      | (() => undefined | marketListings_stddev_fieldsKeySpecifier);
    fields?: marketListings_stddev_fieldsFieldPolicy;
  };
  marketListings_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketListings_stddev_pop_fieldsKeySpecifier
      | (() => undefined | marketListings_stddev_pop_fieldsKeySpecifier);
    fields?: marketListings_stddev_pop_fieldsFieldPolicy;
  };
  marketListings_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketListings_stddev_samp_fieldsKeySpecifier
      | (() => undefined | marketListings_stddev_samp_fieldsKeySpecifier);
    fields?: marketListings_stddev_samp_fieldsFieldPolicy;
  };
  marketListings_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_sum_fieldsKeySpecifier
      | (() => undefined | marketListings_sum_fieldsKeySpecifier);
    fields?: marketListings_sum_fieldsFieldPolicy;
  };
  marketListings_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_var_pop_fieldsKeySpecifier
      | (() => undefined | marketListings_var_pop_fieldsKeySpecifier);
    fields?: marketListings_var_pop_fieldsFieldPolicy;
  };
  marketListings_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_var_samp_fieldsKeySpecifier
      | (() => undefined | marketListings_var_samp_fieldsKeySpecifier);
    fields?: marketListings_var_samp_fieldsFieldPolicy;
  };
  marketListings_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketListings_variance_fieldsKeySpecifier
      | (() => undefined | marketListings_variance_fieldsKeySpecifier);
    fields?: marketListings_variance_fieldsFieldPolicy;
  };
  marketplaceContracts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketplaceContractsKeySpecifier
      | (() => undefined | marketplaceContractsKeySpecifier);
    fields?: marketplaceContractsFieldPolicy;
  };
  marketplaceContracts_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketplaceContracts_aggregateKeySpecifier
      | (() => undefined | marketplaceContracts_aggregateKeySpecifier);
    fields?: marketplaceContracts_aggregateFieldPolicy;
  };
  marketplaceContracts_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketplaceContracts_aggregate_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_aggregate_fieldsKeySpecifier);
    fields?: marketplaceContracts_aggregate_fieldsFieldPolicy;
  };
  marketplaceContracts_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketplaceContracts_avg_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_avg_fieldsKeySpecifier);
    fields?: marketplaceContracts_avg_fieldsFieldPolicy;
  };
  marketplaceContracts_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketplaceContracts_max_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_max_fieldsKeySpecifier);
    fields?: marketplaceContracts_max_fieldsFieldPolicy;
  };
  marketplaceContracts_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketplaceContracts_min_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_min_fieldsKeySpecifier);
    fields?: marketplaceContracts_min_fieldsFieldPolicy;
  };
  marketplaceContracts_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketplaceContracts_mutation_responseKeySpecifier
      | (() => undefined | marketplaceContracts_mutation_responseKeySpecifier);
    fields?: marketplaceContracts_mutation_responseFieldPolicy;
  };
  marketplaceContracts_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketplaceContracts_stddev_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_stddev_fieldsKeySpecifier);
    fields?: marketplaceContracts_stddev_fieldsFieldPolicy;
  };
  marketplaceContracts_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketplaceContracts_stddev_pop_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_stddev_pop_fieldsKeySpecifier);
    fields?: marketplaceContracts_stddev_pop_fieldsFieldPolicy;
  };
  marketplaceContracts_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketplaceContracts_stddev_samp_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_stddev_samp_fieldsKeySpecifier);
    fields?: marketplaceContracts_stddev_samp_fieldsFieldPolicy;
  };
  marketplaceContracts_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | marketplaceContracts_sum_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_sum_fieldsKeySpecifier);
    fields?: marketplaceContracts_sum_fieldsFieldPolicy;
  };
  marketplaceContracts_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketplaceContracts_var_pop_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_var_pop_fieldsKeySpecifier);
    fields?: marketplaceContracts_var_pop_fieldsFieldPolicy;
  };
  marketplaceContracts_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketplaceContracts_var_samp_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_var_samp_fieldsKeySpecifier);
    fields?: marketplaceContracts_var_samp_fieldsFieldPolicy;
  };
  marketplaceContracts_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | marketplaceContracts_variance_fieldsKeySpecifier
      | (() => undefined | marketplaceContracts_variance_fieldsKeySpecifier);
    fields?: marketplaceContracts_variance_fieldsFieldPolicy;
  };
  mutation_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | mutation_rootKeySpecifier
      | (() => undefined | mutation_rootKeySpecifier);
    fields?: mutation_rootFieldPolicy;
  };
  nftContracts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContractsKeySpecifier
      | (() => undefined | nftContractsKeySpecifier);
    fields?: nftContractsFieldPolicy;
  };
  nftContracts_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_aggregateKeySpecifier
      | (() => undefined | nftContracts_aggregateKeySpecifier);
    fields?: nftContracts_aggregateFieldPolicy;
  };
  nftContracts_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_aggregate_fieldsKeySpecifier
      | (() => undefined | nftContracts_aggregate_fieldsKeySpecifier);
    fields?: nftContracts_aggregate_fieldsFieldPolicy;
  };
  nftContracts_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_avg_fieldsKeySpecifier
      | (() => undefined | nftContracts_avg_fieldsKeySpecifier);
    fields?: nftContracts_avg_fieldsFieldPolicy;
  };
  nftContracts_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_max_fieldsKeySpecifier
      | (() => undefined | nftContracts_max_fieldsKeySpecifier);
    fields?: nftContracts_max_fieldsFieldPolicy;
  };
  nftContracts_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_min_fieldsKeySpecifier
      | (() => undefined | nftContracts_min_fieldsKeySpecifier);
    fields?: nftContracts_min_fieldsFieldPolicy;
  };
  nftContracts_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_mutation_responseKeySpecifier
      | (() => undefined | nftContracts_mutation_responseKeySpecifier);
    fields?: nftContracts_mutation_responseFieldPolicy;
  };
  nftContracts_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_stddev_fieldsKeySpecifier
      | (() => undefined | nftContracts_stddev_fieldsKeySpecifier);
    fields?: nftContracts_stddev_fieldsFieldPolicy;
  };
  nftContracts_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_stddev_pop_fieldsKeySpecifier
      | (() => undefined | nftContracts_stddev_pop_fieldsKeySpecifier);
    fields?: nftContracts_stddev_pop_fieldsFieldPolicy;
  };
  nftContracts_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_stddev_samp_fieldsKeySpecifier
      | (() => undefined | nftContracts_stddev_samp_fieldsKeySpecifier);
    fields?: nftContracts_stddev_samp_fieldsFieldPolicy;
  };
  nftContracts_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_sum_fieldsKeySpecifier
      | (() => undefined | nftContracts_sum_fieldsKeySpecifier);
    fields?: nftContracts_sum_fieldsFieldPolicy;
  };
  nftContracts_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_var_pop_fieldsKeySpecifier
      | (() => undefined | nftContracts_var_pop_fieldsKeySpecifier);
    fields?: nftContracts_var_pop_fieldsFieldPolicy;
  };
  nftContracts_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_var_samp_fieldsKeySpecifier
      | (() => undefined | nftContracts_var_samp_fieldsKeySpecifier);
    fields?: nftContracts_var_samp_fieldsFieldPolicy;
  };
  nftContracts_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftContracts_variance_fieldsKeySpecifier
      | (() => undefined | nftContracts_variance_fieldsKeySpecifier);
    fields?: nftContracts_variance_fieldsFieldPolicy;
  };
  nftMetadatas?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatasKeySpecifier
      | (() => undefined | nftMetadatasKeySpecifier);
    fields?: nftMetadatasFieldPolicy;
  };
  nftMetadatas_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_aggregateKeySpecifier
      | (() => undefined | nftMetadatas_aggregateKeySpecifier);
    fields?: nftMetadatas_aggregateFieldPolicy;
  };
  nftMetadatas_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_aggregate_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_aggregate_fieldsKeySpecifier);
    fields?: nftMetadatas_aggregate_fieldsFieldPolicy;
  };
  nftMetadatas_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_avg_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_avg_fieldsKeySpecifier);
    fields?: nftMetadatas_avg_fieldsFieldPolicy;
  };
  nftMetadatas_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_max_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_max_fieldsKeySpecifier);
    fields?: nftMetadatas_max_fieldsFieldPolicy;
  };
  nftMetadatas_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_min_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_min_fieldsKeySpecifier);
    fields?: nftMetadatas_min_fieldsFieldPolicy;
  };
  nftMetadatas_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_mutation_responseKeySpecifier
      | (() => undefined | nftMetadatas_mutation_responseKeySpecifier);
    fields?: nftMetadatas_mutation_responseFieldPolicy;
  };
  nftMetadatas_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_stddev_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_stddev_fieldsKeySpecifier);
    fields?: nftMetadatas_stddev_fieldsFieldPolicy;
  };
  nftMetadatas_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_stddev_pop_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_stddev_pop_fieldsKeySpecifier);
    fields?: nftMetadatas_stddev_pop_fieldsFieldPolicy;
  };
  nftMetadatas_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_stddev_samp_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_stddev_samp_fieldsKeySpecifier);
    fields?: nftMetadatas_stddev_samp_fieldsFieldPolicy;
  };
  nftMetadatas_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_sum_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_sum_fieldsKeySpecifier);
    fields?: nftMetadatas_sum_fieldsFieldPolicy;
  };
  nftMetadatas_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_var_pop_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_var_pop_fieldsKeySpecifier);
    fields?: nftMetadatas_var_pop_fieldsFieldPolicy;
  };
  nftMetadatas_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_var_samp_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_var_samp_fieldsKeySpecifier);
    fields?: nftMetadatas_var_samp_fieldsFieldPolicy;
  };
  nftMetadatas_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftMetadatas_variance_fieldsKeySpecifier
      | (() => undefined | nftMetadatas_variance_fieldsKeySpecifier);
    fields?: nftMetadatas_variance_fieldsFieldPolicy;
  };
  nftOwners?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwnersKeySpecifier
      | (() => undefined | nftOwnersKeySpecifier);
    fields?: nftOwnersFieldPolicy;
  };
  nftOwners_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_aggregateKeySpecifier
      | (() => undefined | nftOwners_aggregateKeySpecifier);
    fields?: nftOwners_aggregateFieldPolicy;
  };
  nftOwners_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_aggregate_fieldsKeySpecifier
      | (() => undefined | nftOwners_aggregate_fieldsKeySpecifier);
    fields?: nftOwners_aggregate_fieldsFieldPolicy;
  };
  nftOwners_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_avg_fieldsKeySpecifier
      | (() => undefined | nftOwners_avg_fieldsKeySpecifier);
    fields?: nftOwners_avg_fieldsFieldPolicy;
  };
  nftOwners_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_max_fieldsKeySpecifier
      | (() => undefined | nftOwners_max_fieldsKeySpecifier);
    fields?: nftOwners_max_fieldsFieldPolicy;
  };
  nftOwners_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_min_fieldsKeySpecifier
      | (() => undefined | nftOwners_min_fieldsKeySpecifier);
    fields?: nftOwners_min_fieldsFieldPolicy;
  };
  nftOwners_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_mutation_responseKeySpecifier
      | (() => undefined | nftOwners_mutation_responseKeySpecifier);
    fields?: nftOwners_mutation_responseFieldPolicy;
  };
  nftOwners_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_stddev_fieldsKeySpecifier
      | (() => undefined | nftOwners_stddev_fieldsKeySpecifier);
    fields?: nftOwners_stddev_fieldsFieldPolicy;
  };
  nftOwners_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_stddev_pop_fieldsKeySpecifier
      | (() => undefined | nftOwners_stddev_pop_fieldsKeySpecifier);
    fields?: nftOwners_stddev_pop_fieldsFieldPolicy;
  };
  nftOwners_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_stddev_samp_fieldsKeySpecifier
      | (() => undefined | nftOwners_stddev_samp_fieldsKeySpecifier);
    fields?: nftOwners_stddev_samp_fieldsFieldPolicy;
  };
  nftOwners_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_sum_fieldsKeySpecifier
      | (() => undefined | nftOwners_sum_fieldsKeySpecifier);
    fields?: nftOwners_sum_fieldsFieldPolicy;
  };
  nftOwners_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_var_pop_fieldsKeySpecifier
      | (() => undefined | nftOwners_var_pop_fieldsKeySpecifier);
    fields?: nftOwners_var_pop_fieldsFieldPolicy;
  };
  nftOwners_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_var_samp_fieldsKeySpecifier
      | (() => undefined | nftOwners_var_samp_fieldsKeySpecifier);
    fields?: nftOwners_var_samp_fieldsFieldPolicy;
  };
  nftOwners_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nftOwners_variance_fieldsKeySpecifier
      | (() => undefined | nftOwners_variance_fieldsKeySpecifier);
    fields?: nftOwners_variance_fieldsFieldPolicy;
  };
  nft_gpanel_users?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_gpanel_usersKeySpecifier
      | (() => undefined | nft_gpanel_usersKeySpecifier);
    fields?: nft_gpanel_usersFieldPolicy;
  };
  nft_gpanel_users_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_gpanel_users_aggregateKeySpecifier
      | (() => undefined | nft_gpanel_users_aggregateKeySpecifier);
    fields?: nft_gpanel_users_aggregateFieldPolicy;
  };
  nft_gpanel_users_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_gpanel_users_aggregate_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_aggregate_fieldsKeySpecifier);
    fields?: nft_gpanel_users_aggregate_fieldsFieldPolicy;
  };
  nft_gpanel_users_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_gpanel_users_avg_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_avg_fieldsKeySpecifier);
    fields?: nft_gpanel_users_avg_fieldsFieldPolicy;
  };
  nft_gpanel_users_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_gpanel_users_max_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_max_fieldsKeySpecifier);
    fields?: nft_gpanel_users_max_fieldsFieldPolicy;
  };
  nft_gpanel_users_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_gpanel_users_min_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_min_fieldsKeySpecifier);
    fields?: nft_gpanel_users_min_fieldsFieldPolicy;
  };
  nft_gpanel_users_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_gpanel_users_mutation_responseKeySpecifier
      | (() => undefined | nft_gpanel_users_mutation_responseKeySpecifier);
    fields?: nft_gpanel_users_mutation_responseFieldPolicy;
  };
  nft_gpanel_users_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_gpanel_users_stddev_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_stddev_fieldsKeySpecifier);
    fields?: nft_gpanel_users_stddev_fieldsFieldPolicy;
  };
  nft_gpanel_users_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_gpanel_users_stddev_pop_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_stddev_pop_fieldsKeySpecifier);
    fields?: nft_gpanel_users_stddev_pop_fieldsFieldPolicy;
  };
  nft_gpanel_users_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_gpanel_users_stddev_samp_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_stddev_samp_fieldsKeySpecifier);
    fields?: nft_gpanel_users_stddev_samp_fieldsFieldPolicy;
  };
  nft_gpanel_users_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_gpanel_users_sum_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_sum_fieldsKeySpecifier);
    fields?: nft_gpanel_users_sum_fieldsFieldPolicy;
  };
  nft_gpanel_users_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_gpanel_users_var_pop_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_var_pop_fieldsKeySpecifier);
    fields?: nft_gpanel_users_var_pop_fieldsFieldPolicy;
  };
  nft_gpanel_users_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_gpanel_users_var_samp_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_var_samp_fieldsKeySpecifier);
    fields?: nft_gpanel_users_var_samp_fieldsFieldPolicy;
  };
  nft_gpanel_users_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_gpanel_users_variance_fieldsKeySpecifier
      | (() => undefined | nft_gpanel_users_variance_fieldsKeySpecifier);
    fields?: nft_gpanel_users_variance_fieldsFieldPolicy;
  };
  nft_listings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listingsKeySpecifier
      | (() => undefined | nft_listingsKeySpecifier);
    fields?: nft_listingsFieldPolicy;
  };
  nft_listings_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_aggregateKeySpecifier
      | (() => undefined | nft_listings_aggregateKeySpecifier);
    fields?: nft_listings_aggregateFieldPolicy;
  };
  nft_listings_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_aggregate_fieldsKeySpecifier
      | (() => undefined | nft_listings_aggregate_fieldsKeySpecifier);
    fields?: nft_listings_aggregate_fieldsFieldPolicy;
  };
  nft_listings_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_avg_fieldsKeySpecifier
      | (() => undefined | nft_listings_avg_fieldsKeySpecifier);
    fields?: nft_listings_avg_fieldsFieldPolicy;
  };
  nft_listings_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_max_fieldsKeySpecifier
      | (() => undefined | nft_listings_max_fieldsKeySpecifier);
    fields?: nft_listings_max_fieldsFieldPolicy;
  };
  nft_listings_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_min_fieldsKeySpecifier
      | (() => undefined | nft_listings_min_fieldsKeySpecifier);
    fields?: nft_listings_min_fieldsFieldPolicy;
  };
  nft_listings_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_mutation_responseKeySpecifier
      | (() => undefined | nft_listings_mutation_responseKeySpecifier);
    fields?: nft_listings_mutation_responseFieldPolicy;
  };
  nft_listings_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_stddev_fieldsKeySpecifier
      | (() => undefined | nft_listings_stddev_fieldsKeySpecifier);
    fields?: nft_listings_stddev_fieldsFieldPolicy;
  };
  nft_listings_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_stddev_pop_fieldsKeySpecifier
      | (() => undefined | nft_listings_stddev_pop_fieldsKeySpecifier);
    fields?: nft_listings_stddev_pop_fieldsFieldPolicy;
  };
  nft_listings_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_stddev_samp_fieldsKeySpecifier
      | (() => undefined | nft_listings_stddev_samp_fieldsKeySpecifier);
    fields?: nft_listings_stddev_samp_fieldsFieldPolicy;
  };
  nft_listings_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_sum_fieldsKeySpecifier
      | (() => undefined | nft_listings_sum_fieldsKeySpecifier);
    fields?: nft_listings_sum_fieldsFieldPolicy;
  };
  nft_listings_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_var_pop_fieldsKeySpecifier
      | (() => undefined | nft_listings_var_pop_fieldsKeySpecifier);
    fields?: nft_listings_var_pop_fieldsFieldPolicy;
  };
  nft_listings_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_var_samp_fieldsKeySpecifier
      | (() => undefined | nft_listings_var_samp_fieldsKeySpecifier);
    fields?: nft_listings_var_samp_fieldsFieldPolicy;
  };
  nft_listings_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_listings_variance_fieldsKeySpecifier
      | (() => undefined | nft_listings_variance_fieldsKeySpecifier);
    fields?: nft_listings_variance_fieldsFieldPolicy;
  };
  nft_transactions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_transactionsKeySpecifier
      | (() => undefined | nft_transactionsKeySpecifier);
    fields?: nft_transactionsFieldPolicy;
  };
  nft_transactions_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_transactions_aggregateKeySpecifier
      | (() => undefined | nft_transactions_aggregateKeySpecifier);
    fields?: nft_transactions_aggregateFieldPolicy;
  };
  nft_transactions_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_transactions_aggregate_fieldsKeySpecifier
      | (() => undefined | nft_transactions_aggregate_fieldsKeySpecifier);
    fields?: nft_transactions_aggregate_fieldsFieldPolicy;
  };
  nft_transactions_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_transactions_avg_fieldsKeySpecifier
      | (() => undefined | nft_transactions_avg_fieldsKeySpecifier);
    fields?: nft_transactions_avg_fieldsFieldPolicy;
  };
  nft_transactions_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_transactions_max_fieldsKeySpecifier
      | (() => undefined | nft_transactions_max_fieldsKeySpecifier);
    fields?: nft_transactions_max_fieldsFieldPolicy;
  };
  nft_transactions_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_transactions_min_fieldsKeySpecifier
      | (() => undefined | nft_transactions_min_fieldsKeySpecifier);
    fields?: nft_transactions_min_fieldsFieldPolicy;
  };
  nft_transactions_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_transactions_mutation_responseKeySpecifier
      | (() => undefined | nft_transactions_mutation_responseKeySpecifier);
    fields?: nft_transactions_mutation_responseFieldPolicy;
  };
  nft_transactions_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_transactions_stddev_fieldsKeySpecifier
      | (() => undefined | nft_transactions_stddev_fieldsKeySpecifier);
    fields?: nft_transactions_stddev_fieldsFieldPolicy;
  };
  nft_transactions_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_transactions_stddev_pop_fieldsKeySpecifier
      | (() => undefined | nft_transactions_stddev_pop_fieldsKeySpecifier);
    fields?: nft_transactions_stddev_pop_fieldsFieldPolicy;
  };
  nft_transactions_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_transactions_stddev_samp_fieldsKeySpecifier
      | (() => undefined | nft_transactions_stddev_samp_fieldsKeySpecifier);
    fields?: nft_transactions_stddev_samp_fieldsFieldPolicy;
  };
  nft_transactions_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_transactions_sum_fieldsKeySpecifier
      | (() => undefined | nft_transactions_sum_fieldsKeySpecifier);
    fields?: nft_transactions_sum_fieldsFieldPolicy;
  };
  nft_transactions_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nft_transactions_var_pop_fieldsKeySpecifier
      | (() => undefined | nft_transactions_var_pop_fieldsKeySpecifier);
    fields?: nft_transactions_var_pop_fieldsFieldPolicy;
  };
  nft_transactions_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_transactions_var_samp_fieldsKeySpecifier
      | (() => undefined | nft_transactions_var_samp_fieldsKeySpecifier);
    fields?: nft_transactions_var_samp_fieldsFieldPolicy;
  };
  nft_transactions_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | nft_transactions_variance_fieldsKeySpecifier
      | (() => undefined | nft_transactions_variance_fieldsKeySpecifier);
    fields?: nft_transactions_variance_fieldsFieldPolicy;
  };
  nfts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | nftsKeySpecifier | (() => undefined | nftsKeySpecifier);
    fields?: nftsFieldPolicy;
  };
  nfts_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_aggregateKeySpecifier
      | (() => undefined | nfts_aggregateKeySpecifier);
    fields?: nfts_aggregateFieldPolicy;
  };
  nfts_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_aggregate_fieldsKeySpecifier
      | (() => undefined | nfts_aggregate_fieldsKeySpecifier);
    fields?: nfts_aggregate_fieldsFieldPolicy;
  };
  nfts_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_avg_fieldsKeySpecifier
      | (() => undefined | nfts_avg_fieldsKeySpecifier);
    fields?: nfts_avg_fieldsFieldPolicy;
  };
  nfts_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_max_fieldsKeySpecifier
      | (() => undefined | nfts_max_fieldsKeySpecifier);
    fields?: nfts_max_fieldsFieldPolicy;
  };
  nfts_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_min_fieldsKeySpecifier
      | (() => undefined | nfts_min_fieldsKeySpecifier);
    fields?: nfts_min_fieldsFieldPolicy;
  };
  nfts_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_mutation_responseKeySpecifier
      | (() => undefined | nfts_mutation_responseKeySpecifier);
    fields?: nfts_mutation_responseFieldPolicy;
  };
  nfts_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_stddev_fieldsKeySpecifier
      | (() => undefined | nfts_stddev_fieldsKeySpecifier);
    fields?: nfts_stddev_fieldsFieldPolicy;
  };
  nfts_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_stddev_pop_fieldsKeySpecifier
      | (() => undefined | nfts_stddev_pop_fieldsKeySpecifier);
    fields?: nfts_stddev_pop_fieldsFieldPolicy;
  };
  nfts_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_stddev_samp_fieldsKeySpecifier
      | (() => undefined | nfts_stddev_samp_fieldsKeySpecifier);
    fields?: nfts_stddev_samp_fieldsFieldPolicy;
  };
  nfts_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_sum_fieldsKeySpecifier
      | (() => undefined | nfts_sum_fieldsKeySpecifier);
    fields?: nfts_sum_fieldsFieldPolicy;
  };
  nfts_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_var_pop_fieldsKeySpecifier
      | (() => undefined | nfts_var_pop_fieldsKeySpecifier);
    fields?: nfts_var_pop_fieldsFieldPolicy;
  };
  nfts_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_var_samp_fieldsKeySpecifier
      | (() => undefined | nfts_var_samp_fieldsKeySpecifier);
    fields?: nfts_var_samp_fieldsFieldPolicy;
  };
  nfts_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | nfts_variance_fieldsKeySpecifier
      | (() => undefined | nfts_variance_fieldsKeySpecifier);
    fields?: nfts_variance_fieldsFieldPolicy;
  };
  pendingTransactions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pendingTransactionsKeySpecifier
      | (() => undefined | pendingTransactionsKeySpecifier);
    fields?: pendingTransactionsFieldPolicy;
  };
  pendingTransactions_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pendingTransactions_aggregateKeySpecifier
      | (() => undefined | pendingTransactions_aggregateKeySpecifier);
    fields?: pendingTransactions_aggregateFieldPolicy;
  };
  pendingTransactions_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | pendingTransactions_aggregate_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_aggregate_fieldsKeySpecifier);
    fields?: pendingTransactions_aggregate_fieldsFieldPolicy;
  };
  pendingTransactions_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pendingTransactions_avg_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_avg_fieldsKeySpecifier);
    fields?: pendingTransactions_avg_fieldsFieldPolicy;
  };
  pendingTransactions_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pendingTransactions_max_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_max_fieldsKeySpecifier);
    fields?: pendingTransactions_max_fieldsFieldPolicy;
  };
  pendingTransactions_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pendingTransactions_min_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_min_fieldsKeySpecifier);
    fields?: pendingTransactions_min_fieldsFieldPolicy;
  };
  pendingTransactions_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | pendingTransactions_mutation_responseKeySpecifier
      | (() => undefined | pendingTransactions_mutation_responseKeySpecifier);
    fields?: pendingTransactions_mutation_responseFieldPolicy;
  };
  pendingTransactions_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | pendingTransactions_stddev_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_stddev_fieldsKeySpecifier);
    fields?: pendingTransactions_stddev_fieldsFieldPolicy;
  };
  pendingTransactions_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | pendingTransactions_stddev_pop_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_stddev_pop_fieldsKeySpecifier);
    fields?: pendingTransactions_stddev_pop_fieldsFieldPolicy;
  };
  pendingTransactions_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | pendingTransactions_stddev_samp_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_stddev_samp_fieldsKeySpecifier);
    fields?: pendingTransactions_stddev_samp_fieldsFieldPolicy;
  };
  pendingTransactions_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pendingTransactions_sum_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_sum_fieldsKeySpecifier);
    fields?: pendingTransactions_sum_fieldsFieldPolicy;
  };
  pendingTransactions_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | pendingTransactions_var_pop_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_var_pop_fieldsKeySpecifier);
    fields?: pendingTransactions_var_pop_fieldsFieldPolicy;
  };
  pendingTransactions_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | pendingTransactions_var_samp_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_var_samp_fieldsKeySpecifier);
    fields?: pendingTransactions_var_samp_fieldsFieldPolicy;
  };
  pendingTransactions_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | pendingTransactions_variance_fieldsKeySpecifier
      | (() => undefined | pendingTransactions_variance_fieldsKeySpecifier);
    fields?: pendingTransactions_variance_fieldsFieldPolicy;
  };
  pending_tx?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_txKeySpecifier
      | (() => undefined | pending_txKeySpecifier);
    fields?: pending_txFieldPolicy;
  };
  pending_tx_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_aggregateKeySpecifier
      | (() => undefined | pending_tx_aggregateKeySpecifier);
    fields?: pending_tx_aggregateFieldPolicy;
  };
  pending_tx_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_aggregate_fieldsKeySpecifier
      | (() => undefined | pending_tx_aggregate_fieldsKeySpecifier);
    fields?: pending_tx_aggregate_fieldsFieldPolicy;
  };
  pending_tx_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_avg_fieldsKeySpecifier
      | (() => undefined | pending_tx_avg_fieldsKeySpecifier);
    fields?: pending_tx_avg_fieldsFieldPolicy;
  };
  pending_tx_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_max_fieldsKeySpecifier
      | (() => undefined | pending_tx_max_fieldsKeySpecifier);
    fields?: pending_tx_max_fieldsFieldPolicy;
  };
  pending_tx_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_min_fieldsKeySpecifier
      | (() => undefined | pending_tx_min_fieldsKeySpecifier);
    fields?: pending_tx_min_fieldsFieldPolicy;
  };
  pending_tx_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_mutation_responseKeySpecifier
      | (() => undefined | pending_tx_mutation_responseKeySpecifier);
    fields?: pending_tx_mutation_responseFieldPolicy;
  };
  pending_tx_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_stddev_fieldsKeySpecifier
      | (() => undefined | pending_tx_stddev_fieldsKeySpecifier);
    fields?: pending_tx_stddev_fieldsFieldPolicy;
  };
  pending_tx_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_stddev_pop_fieldsKeySpecifier
      | (() => undefined | pending_tx_stddev_pop_fieldsKeySpecifier);
    fields?: pending_tx_stddev_pop_fieldsFieldPolicy;
  };
  pending_tx_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_stddev_samp_fieldsKeySpecifier
      | (() => undefined | pending_tx_stddev_samp_fieldsKeySpecifier);
    fields?: pending_tx_stddev_samp_fieldsFieldPolicy;
  };
  pending_tx_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_sum_fieldsKeySpecifier
      | (() => undefined | pending_tx_sum_fieldsKeySpecifier);
    fields?: pending_tx_sum_fieldsFieldPolicy;
  };
  pending_tx_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_var_pop_fieldsKeySpecifier
      | (() => undefined | pending_tx_var_pop_fieldsKeySpecifier);
    fields?: pending_tx_var_pop_fieldsFieldPolicy;
  };
  pending_tx_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_var_samp_fieldsKeySpecifier
      | (() => undefined | pending_tx_var_samp_fieldsKeySpecifier);
    fields?: pending_tx_var_samp_fieldsFieldPolicy;
  };
  pending_tx_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | pending_tx_variance_fieldsKeySpecifier
      | (() => undefined | pending_tx_variance_fieldsKeySpecifier);
    fields?: pending_tx_variance_fieldsFieldPolicy;
  };
  query_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | query_rootKeySpecifier
      | (() => undefined | query_rootKeySpecifier);
    fields?: query_rootFieldPolicy;
  };
  rakebackLocks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocksKeySpecifier
      | (() => undefined | rakebackLocksKeySpecifier);
    fields?: rakebackLocksFieldPolicy;
  };
  rakebackLocks_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_aggregateKeySpecifier
      | (() => undefined | rakebackLocks_aggregateKeySpecifier);
    fields?: rakebackLocks_aggregateFieldPolicy;
  };
  rakebackLocks_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_aggregate_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_aggregate_fieldsKeySpecifier);
    fields?: rakebackLocks_aggregate_fieldsFieldPolicy;
  };
  rakebackLocks_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_avg_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_avg_fieldsKeySpecifier);
    fields?: rakebackLocks_avg_fieldsFieldPolicy;
  };
  rakebackLocks_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_max_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_max_fieldsKeySpecifier);
    fields?: rakebackLocks_max_fieldsFieldPolicy;
  };
  rakebackLocks_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_min_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_min_fieldsKeySpecifier);
    fields?: rakebackLocks_min_fieldsFieldPolicy;
  };
  rakebackLocks_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_mutation_responseKeySpecifier
      | (() => undefined | rakebackLocks_mutation_responseKeySpecifier);
    fields?: rakebackLocks_mutation_responseFieldPolicy;
  };
  rakebackLocks_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_stddev_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_stddev_fieldsKeySpecifier);
    fields?: rakebackLocks_stddev_fieldsFieldPolicy;
  };
  rakebackLocks_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_stddev_pop_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_stddev_pop_fieldsKeySpecifier);
    fields?: rakebackLocks_stddev_pop_fieldsFieldPolicy;
  };
  rakebackLocks_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | rakebackLocks_stddev_samp_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_stddev_samp_fieldsKeySpecifier);
    fields?: rakebackLocks_stddev_samp_fieldsFieldPolicy;
  };
  rakebackLocks_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_sum_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_sum_fieldsKeySpecifier);
    fields?: rakebackLocks_sum_fieldsFieldPolicy;
  };
  rakebackLocks_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_var_pop_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_var_pop_fieldsKeySpecifier);
    fields?: rakebackLocks_var_pop_fieldsFieldPolicy;
  };
  rakebackLocks_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_var_samp_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_var_samp_fieldsKeySpecifier);
    fields?: rakebackLocks_var_samp_fieldsFieldPolicy;
  };
  rakebackLocks_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | rakebackLocks_variance_fieldsKeySpecifier
      | (() => undefined | rakebackLocks_variance_fieldsKeySpecifier);
    fields?: rakebackLocks_variance_fieldsFieldPolicy;
  };
  stablecoins?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoinsKeySpecifier
      | (() => undefined | stablecoinsKeySpecifier);
    fields?: stablecoinsFieldPolicy;
  };
  stablecoins_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_aggregateKeySpecifier
      | (() => undefined | stablecoins_aggregateKeySpecifier);
    fields?: stablecoins_aggregateFieldPolicy;
  };
  stablecoins_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_aggregate_fieldsKeySpecifier
      | (() => undefined | stablecoins_aggregate_fieldsKeySpecifier);
    fields?: stablecoins_aggregate_fieldsFieldPolicy;
  };
  stablecoins_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_avg_fieldsKeySpecifier
      | (() => undefined | stablecoins_avg_fieldsKeySpecifier);
    fields?: stablecoins_avg_fieldsFieldPolicy;
  };
  stablecoins_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_max_fieldsKeySpecifier
      | (() => undefined | stablecoins_max_fieldsKeySpecifier);
    fields?: stablecoins_max_fieldsFieldPolicy;
  };
  stablecoins_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_min_fieldsKeySpecifier
      | (() => undefined | stablecoins_min_fieldsKeySpecifier);
    fields?: stablecoins_min_fieldsFieldPolicy;
  };
  stablecoins_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_mutation_responseKeySpecifier
      | (() => undefined | stablecoins_mutation_responseKeySpecifier);
    fields?: stablecoins_mutation_responseFieldPolicy;
  };
  stablecoins_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_stddev_fieldsKeySpecifier
      | (() => undefined | stablecoins_stddev_fieldsKeySpecifier);
    fields?: stablecoins_stddev_fieldsFieldPolicy;
  };
  stablecoins_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_stddev_pop_fieldsKeySpecifier
      | (() => undefined | stablecoins_stddev_pop_fieldsKeySpecifier);
    fields?: stablecoins_stddev_pop_fieldsFieldPolicy;
  };
  stablecoins_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_stddev_samp_fieldsKeySpecifier
      | (() => undefined | stablecoins_stddev_samp_fieldsKeySpecifier);
    fields?: stablecoins_stddev_samp_fieldsFieldPolicy;
  };
  stablecoins_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_sum_fieldsKeySpecifier
      | (() => undefined | stablecoins_sum_fieldsKeySpecifier);
    fields?: stablecoins_sum_fieldsFieldPolicy;
  };
  stablecoins_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_var_pop_fieldsKeySpecifier
      | (() => undefined | stablecoins_var_pop_fieldsKeySpecifier);
    fields?: stablecoins_var_pop_fieldsFieldPolicy;
  };
  stablecoins_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_var_samp_fieldsKeySpecifier
      | (() => undefined | stablecoins_var_samp_fieldsKeySpecifier);
    fields?: stablecoins_var_samp_fieldsFieldPolicy;
  };
  stablecoins_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stablecoins_variance_fieldsKeySpecifier
      | (() => undefined | stablecoins_variance_fieldsKeySpecifier);
    fields?: stablecoins_variance_fieldsFieldPolicy;
  };
  stakingOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stakingOutputKeySpecifier
      | (() => undefined | stakingOutputKeySpecifier);
    fields?: stakingOutputFieldPolicy;
  };
  staking_refill?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refillKeySpecifier
      | (() => undefined | staking_refillKeySpecifier);
    fields?: staking_refillFieldPolicy;
  };
  staking_refill_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_aggregateKeySpecifier
      | (() => undefined | staking_refill_aggregateKeySpecifier);
    fields?: staking_refill_aggregateFieldPolicy;
  };
  staking_refill_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_aggregate_fieldsKeySpecifier
      | (() => undefined | staking_refill_aggregate_fieldsKeySpecifier);
    fields?: staking_refill_aggregate_fieldsFieldPolicy;
  };
  staking_refill_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_avg_fieldsKeySpecifier
      | (() => undefined | staking_refill_avg_fieldsKeySpecifier);
    fields?: staking_refill_avg_fieldsFieldPolicy;
  };
  staking_refill_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_max_fieldsKeySpecifier
      | (() => undefined | staking_refill_max_fieldsKeySpecifier);
    fields?: staking_refill_max_fieldsFieldPolicy;
  };
  staking_refill_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_min_fieldsKeySpecifier
      | (() => undefined | staking_refill_min_fieldsKeySpecifier);
    fields?: staking_refill_min_fieldsFieldPolicy;
  };
  staking_refill_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | staking_refill_mutation_responseKeySpecifier
      | (() => undefined | staking_refill_mutation_responseKeySpecifier);
    fields?: staking_refill_mutation_responseFieldPolicy;
  };
  staking_refill_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_stddev_fieldsKeySpecifier
      | (() => undefined | staking_refill_stddev_fieldsKeySpecifier);
    fields?: staking_refill_stddev_fieldsFieldPolicy;
  };
  staking_refill_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | staking_refill_stddev_pop_fieldsKeySpecifier
      | (() => undefined | staking_refill_stddev_pop_fieldsKeySpecifier);
    fields?: staking_refill_stddev_pop_fieldsFieldPolicy;
  };
  staking_refill_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | staking_refill_stddev_samp_fieldsKeySpecifier
      | (() => undefined | staking_refill_stddev_samp_fieldsKeySpecifier);
    fields?: staking_refill_stddev_samp_fieldsFieldPolicy;
  };
  staking_refill_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_sum_fieldsKeySpecifier
      | (() => undefined | staking_refill_sum_fieldsKeySpecifier);
    fields?: staking_refill_sum_fieldsFieldPolicy;
  };
  staking_refill_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_var_pop_fieldsKeySpecifier
      | (() => undefined | staking_refill_var_pop_fieldsKeySpecifier);
    fields?: staking_refill_var_pop_fieldsFieldPolicy;
  };
  staking_refill_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_var_samp_fieldsKeySpecifier
      | (() => undefined | staking_refill_var_samp_fieldsKeySpecifier);
    fields?: staking_refill_var_samp_fieldsFieldPolicy;
  };
  staking_refill_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | staking_refill_variance_fieldsKeySpecifier
      | (() => undefined | staking_refill_variance_fieldsKeySpecifier);
    fields?: staking_refill_variance_fieldsFieldPolicy;
  };
  subscription_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscription_rootKeySpecifier
      | (() => undefined | subscription_rootKeySpecifier);
    fields?: subscription_rootFieldPolicy;
  };
  sumsubOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | sumsubOutputKeySpecifier
      | (() => undefined | sumsubOutputKeySpecifier);
    fields?: sumsubOutputFieldPolicy;
  };
  swapTransactions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | swapTransactionsKeySpecifier
      | (() => undefined | swapTransactionsKeySpecifier);
    fields?: swapTransactionsFieldPolicy;
  };
  swapTransactions_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | swapTransactions_aggregateKeySpecifier
      | (() => undefined | swapTransactions_aggregateKeySpecifier);
    fields?: swapTransactions_aggregateFieldPolicy;
  };
  swapTransactions_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | swapTransactions_aggregate_fieldsKeySpecifier
      | (() => undefined | swapTransactions_aggregate_fieldsKeySpecifier);
    fields?: swapTransactions_aggregate_fieldsFieldPolicy;
  };
  swapTransactions_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | swapTransactions_avg_fieldsKeySpecifier
      | (() => undefined | swapTransactions_avg_fieldsKeySpecifier);
    fields?: swapTransactions_avg_fieldsFieldPolicy;
  };
  swapTransactions_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | swapTransactions_max_fieldsKeySpecifier
      | (() => undefined | swapTransactions_max_fieldsKeySpecifier);
    fields?: swapTransactions_max_fieldsFieldPolicy;
  };
  swapTransactions_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | swapTransactions_min_fieldsKeySpecifier
      | (() => undefined | swapTransactions_min_fieldsKeySpecifier);
    fields?: swapTransactions_min_fieldsFieldPolicy;
  };
  swapTransactions_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | swapTransactions_mutation_responseKeySpecifier
      | (() => undefined | swapTransactions_mutation_responseKeySpecifier);
    fields?: swapTransactions_mutation_responseFieldPolicy;
  };
  swapTransactions_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | swapTransactions_stddev_fieldsKeySpecifier
      | (() => undefined | swapTransactions_stddev_fieldsKeySpecifier);
    fields?: swapTransactions_stddev_fieldsFieldPolicy;
  };
  swapTransactions_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | swapTransactions_stddev_pop_fieldsKeySpecifier
      | (() => undefined | swapTransactions_stddev_pop_fieldsKeySpecifier);
    fields?: swapTransactions_stddev_pop_fieldsFieldPolicy;
  };
  swapTransactions_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | swapTransactions_stddev_samp_fieldsKeySpecifier
      | (() => undefined | swapTransactions_stddev_samp_fieldsKeySpecifier);
    fields?: swapTransactions_stddev_samp_fieldsFieldPolicy;
  };
  swapTransactions_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | swapTransactions_sum_fieldsKeySpecifier
      | (() => undefined | swapTransactions_sum_fieldsKeySpecifier);
    fields?: swapTransactions_sum_fieldsFieldPolicy;
  };
  swapTransactions_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | swapTransactions_var_pop_fieldsKeySpecifier
      | (() => undefined | swapTransactions_var_pop_fieldsKeySpecifier);
    fields?: swapTransactions_var_pop_fieldsFieldPolicy;
  };
  swapTransactions_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | swapTransactions_var_samp_fieldsKeySpecifier
      | (() => undefined | swapTransactions_var_samp_fieldsKeySpecifier);
    fields?: swapTransactions_var_samp_fieldsFieldPolicy;
  };
  swapTransactions_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | swapTransactions_variance_fieldsKeySpecifier
      | (() => undefined | swapTransactions_variance_fieldsKeySpecifier);
    fields?: swapTransactions_variance_fieldsFieldPolicy;
  };
  tournaments?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournamentsKeySpecifier
      | (() => undefined | tournamentsKeySpecifier);
    fields?: tournamentsFieldPolicy;
  };
  tournaments_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_aggregateKeySpecifier
      | (() => undefined | tournaments_aggregateKeySpecifier);
    fields?: tournaments_aggregateFieldPolicy;
  };
  tournaments_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_aggregate_fieldsKeySpecifier
      | (() => undefined | tournaments_aggregate_fieldsKeySpecifier);
    fields?: tournaments_aggregate_fieldsFieldPolicy;
  };
  tournaments_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_avg_fieldsKeySpecifier
      | (() => undefined | tournaments_avg_fieldsKeySpecifier);
    fields?: tournaments_avg_fieldsFieldPolicy;
  };
  tournaments_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_max_fieldsKeySpecifier
      | (() => undefined | tournaments_max_fieldsKeySpecifier);
    fields?: tournaments_max_fieldsFieldPolicy;
  };
  tournaments_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_min_fieldsKeySpecifier
      | (() => undefined | tournaments_min_fieldsKeySpecifier);
    fields?: tournaments_min_fieldsFieldPolicy;
  };
  tournaments_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_mutation_responseKeySpecifier
      | (() => undefined | tournaments_mutation_responseKeySpecifier);
    fields?: tournaments_mutation_responseFieldPolicy;
  };
  tournaments_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_stddev_fieldsKeySpecifier
      | (() => undefined | tournaments_stddev_fieldsKeySpecifier);
    fields?: tournaments_stddev_fieldsFieldPolicy;
  };
  tournaments_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_stddev_pop_fieldsKeySpecifier
      | (() => undefined | tournaments_stddev_pop_fieldsKeySpecifier);
    fields?: tournaments_stddev_pop_fieldsFieldPolicy;
  };
  tournaments_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_stddev_samp_fieldsKeySpecifier
      | (() => undefined | tournaments_stddev_samp_fieldsKeySpecifier);
    fields?: tournaments_stddev_samp_fieldsFieldPolicy;
  };
  tournaments_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_sum_fieldsKeySpecifier
      | (() => undefined | tournaments_sum_fieldsKeySpecifier);
    fields?: tournaments_sum_fieldsFieldPolicy;
  };
  tournaments_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_var_pop_fieldsKeySpecifier
      | (() => undefined | tournaments_var_pop_fieldsKeySpecifier);
    fields?: tournaments_var_pop_fieldsFieldPolicy;
  };
  tournaments_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_var_samp_fieldsKeySpecifier
      | (() => undefined | tournaments_var_samp_fieldsKeySpecifier);
    fields?: tournaments_var_samp_fieldsFieldPolicy;
  };
  tournaments_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | tournaments_variance_fieldsKeySpecifier
      | (() => undefined | tournaments_variance_fieldsKeySpecifier);
    fields?: tournaments_variance_fieldsFieldPolicy;
  };
  transactionStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactionStatisticsKeySpecifier
      | (() => undefined | transactionStatisticsKeySpecifier);
    fields?: transactionStatisticsFieldPolicy;
  };
  transactionStatistics_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactionStatistics_aggregateKeySpecifier
      | (() => undefined | transactionStatistics_aggregateKeySpecifier);
    fields?: transactionStatistics_aggregateFieldPolicy;
  };
  transactionStatistics_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_aggregate_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_aggregate_fieldsKeySpecifier);
    fields?: transactionStatistics_aggregate_fieldsFieldPolicy;
  };
  transactionStatistics_avg_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_avg_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_avg_fieldsKeySpecifier);
    fields?: transactionStatistics_avg_fieldsFieldPolicy;
  };
  transactionStatistics_max_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_max_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_max_fieldsKeySpecifier);
    fields?: transactionStatistics_max_fieldsFieldPolicy;
  };
  transactionStatistics_min_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_min_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_min_fieldsKeySpecifier);
    fields?: transactionStatistics_min_fieldsFieldPolicy;
  };
  transactionStatistics_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_mutation_responseKeySpecifier
      | (() => undefined | transactionStatistics_mutation_responseKeySpecifier);
    fields?: transactionStatistics_mutation_responseFieldPolicy;
  };
  transactionStatistics_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_stddev_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_stddev_fieldsKeySpecifier);
    fields?: transactionStatistics_stddev_fieldsFieldPolicy;
  };
  transactionStatistics_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_stddev_pop_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_stddev_pop_fieldsKeySpecifier);
    fields?: transactionStatistics_stddev_pop_fieldsFieldPolicy;
  };
  transactionStatistics_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_stddev_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | transactionStatistics_stddev_samp_fieldsKeySpecifier);
    fields?: transactionStatistics_stddev_samp_fieldsFieldPolicy;
  };
  transactionStatistics_sum_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_sum_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_sum_fieldsKeySpecifier);
    fields?: transactionStatistics_sum_fieldsFieldPolicy;
  };
  transactionStatistics_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_var_pop_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_var_pop_fieldsKeySpecifier);
    fields?: transactionStatistics_var_pop_fieldsFieldPolicy;
  };
  transactionStatistics_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_var_samp_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_var_samp_fieldsKeySpecifier);
    fields?: transactionStatistics_var_samp_fieldsFieldPolicy;
  };
  transactionStatistics_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | transactionStatistics_variance_fieldsKeySpecifier
      | (() => undefined | transactionStatistics_variance_fieldsKeySpecifier);
    fields?: transactionStatistics_variance_fieldsFieldPolicy;
  };
  transactions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactionsKeySpecifier
      | (() => undefined | transactionsKeySpecifier);
    fields?: transactionsFieldPolicy;
  };
  transactions_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_aggregateKeySpecifier
      | (() => undefined | transactions_aggregateKeySpecifier);
    fields?: transactions_aggregateFieldPolicy;
  };
  transactions_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_aggregate_fieldsKeySpecifier
      | (() => undefined | transactions_aggregate_fieldsKeySpecifier);
    fields?: transactions_aggregate_fieldsFieldPolicy;
  };
  transactions_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_avg_fieldsKeySpecifier
      | (() => undefined | transactions_avg_fieldsKeySpecifier);
    fields?: transactions_avg_fieldsFieldPolicy;
  };
  transactions_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_max_fieldsKeySpecifier
      | (() => undefined | transactions_max_fieldsKeySpecifier);
    fields?: transactions_max_fieldsFieldPolicy;
  };
  transactions_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_min_fieldsKeySpecifier
      | (() => undefined | transactions_min_fieldsKeySpecifier);
    fields?: transactions_min_fieldsFieldPolicy;
  };
  transactions_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_mutation_responseKeySpecifier
      | (() => undefined | transactions_mutation_responseKeySpecifier);
    fields?: transactions_mutation_responseFieldPolicy;
  };
  transactions_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_stddev_fieldsKeySpecifier
      | (() => undefined | transactions_stddev_fieldsKeySpecifier);
    fields?: transactions_stddev_fieldsFieldPolicy;
  };
  transactions_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_stddev_pop_fieldsKeySpecifier
      | (() => undefined | transactions_stddev_pop_fieldsKeySpecifier);
    fields?: transactions_stddev_pop_fieldsFieldPolicy;
  };
  transactions_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_stddev_samp_fieldsKeySpecifier
      | (() => undefined | transactions_stddev_samp_fieldsKeySpecifier);
    fields?: transactions_stddev_samp_fieldsFieldPolicy;
  };
  transactions_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_sum_fieldsKeySpecifier
      | (() => undefined | transactions_sum_fieldsKeySpecifier);
    fields?: transactions_sum_fieldsFieldPolicy;
  };
  transactions_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_var_pop_fieldsKeySpecifier
      | (() => undefined | transactions_var_pop_fieldsKeySpecifier);
    fields?: transactions_var_pop_fieldsFieldPolicy;
  };
  transactions_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_var_samp_fieldsKeySpecifier
      | (() => undefined | transactions_var_samp_fieldsKeySpecifier);
    fields?: transactions_var_samp_fieldsFieldPolicy;
  };
  transactions_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | transactions_variance_fieldsKeySpecifier
      | (() => undefined | transactions_variance_fieldsKeySpecifier);
    fields?: transactions_variance_fieldsFieldPolicy;
  };
  updateAvailableAvatarNFTs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | updateAvailableAvatarNFTsKeySpecifier
      | (() => undefined | updateAvailableAvatarNFTsKeySpecifier);
    fields?: updateAvailableAvatarNFTsFieldPolicy;
  };
  userErc721?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721KeySpecifier
      | (() => undefined | userErc721KeySpecifier);
    fields?: userErc721FieldPolicy;
  };
  userErc721_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_aggregateKeySpecifier
      | (() => undefined | userErc721_aggregateKeySpecifier);
    fields?: userErc721_aggregateFieldPolicy;
  };
  userErc721_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_aggregate_fieldsKeySpecifier
      | (() => undefined | userErc721_aggregate_fieldsKeySpecifier);
    fields?: userErc721_aggregate_fieldsFieldPolicy;
  };
  userErc721_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_avg_fieldsKeySpecifier
      | (() => undefined | userErc721_avg_fieldsKeySpecifier);
    fields?: userErc721_avg_fieldsFieldPolicy;
  };
  userErc721_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_max_fieldsKeySpecifier
      | (() => undefined | userErc721_max_fieldsKeySpecifier);
    fields?: userErc721_max_fieldsFieldPolicy;
  };
  userErc721_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_min_fieldsKeySpecifier
      | (() => undefined | userErc721_min_fieldsKeySpecifier);
    fields?: userErc721_min_fieldsFieldPolicy;
  };
  userErc721_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_mutation_responseKeySpecifier
      | (() => undefined | userErc721_mutation_responseKeySpecifier);
    fields?: userErc721_mutation_responseFieldPolicy;
  };
  userErc721_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_stddev_fieldsKeySpecifier
      | (() => undefined | userErc721_stddev_fieldsKeySpecifier);
    fields?: userErc721_stddev_fieldsFieldPolicy;
  };
  userErc721_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_stddev_pop_fieldsKeySpecifier
      | (() => undefined | userErc721_stddev_pop_fieldsKeySpecifier);
    fields?: userErc721_stddev_pop_fieldsFieldPolicy;
  };
  userErc721_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_stddev_samp_fieldsKeySpecifier
      | (() => undefined | userErc721_stddev_samp_fieldsKeySpecifier);
    fields?: userErc721_stddev_samp_fieldsFieldPolicy;
  };
  userErc721_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_sum_fieldsKeySpecifier
      | (() => undefined | userErc721_sum_fieldsKeySpecifier);
    fields?: userErc721_sum_fieldsFieldPolicy;
  };
  userErc721_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_var_pop_fieldsKeySpecifier
      | (() => undefined | userErc721_var_pop_fieldsKeySpecifier);
    fields?: userErc721_var_pop_fieldsFieldPolicy;
  };
  userErc721_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_var_samp_fieldsKeySpecifier
      | (() => undefined | userErc721_var_samp_fieldsKeySpecifier);
    fields?: userErc721_var_samp_fieldsFieldPolicy;
  };
  userErc721_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userErc721_variance_fieldsKeySpecifier
      | (() => undefined | userErc721_variance_fieldsKeySpecifier);
    fields?: userErc721_variance_fieldsFieldPolicy;
  };
  userJobs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobsKeySpecifier
      | (() => undefined | userJobsKeySpecifier);
    fields?: userJobsFieldPolicy;
  };
  userJobs_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_aggregateKeySpecifier
      | (() => undefined | userJobs_aggregateKeySpecifier);
    fields?: userJobs_aggregateFieldPolicy;
  };
  userJobs_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_aggregate_fieldsKeySpecifier
      | (() => undefined | userJobs_aggregate_fieldsKeySpecifier);
    fields?: userJobs_aggregate_fieldsFieldPolicy;
  };
  userJobs_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_avg_fieldsKeySpecifier
      | (() => undefined | userJobs_avg_fieldsKeySpecifier);
    fields?: userJobs_avg_fieldsFieldPolicy;
  };
  userJobs_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_max_fieldsKeySpecifier
      | (() => undefined | userJobs_max_fieldsKeySpecifier);
    fields?: userJobs_max_fieldsFieldPolicy;
  };
  userJobs_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_min_fieldsKeySpecifier
      | (() => undefined | userJobs_min_fieldsKeySpecifier);
    fields?: userJobs_min_fieldsFieldPolicy;
  };
  userJobs_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_mutation_responseKeySpecifier
      | (() => undefined | userJobs_mutation_responseKeySpecifier);
    fields?: userJobs_mutation_responseFieldPolicy;
  };
  userJobs_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_stddev_fieldsKeySpecifier
      | (() => undefined | userJobs_stddev_fieldsKeySpecifier);
    fields?: userJobs_stddev_fieldsFieldPolicy;
  };
  userJobs_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_stddev_pop_fieldsKeySpecifier
      | (() => undefined | userJobs_stddev_pop_fieldsKeySpecifier);
    fields?: userJobs_stddev_pop_fieldsFieldPolicy;
  };
  userJobs_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_stddev_samp_fieldsKeySpecifier
      | (() => undefined | userJobs_stddev_samp_fieldsKeySpecifier);
    fields?: userJobs_stddev_samp_fieldsFieldPolicy;
  };
  userJobs_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_sum_fieldsKeySpecifier
      | (() => undefined | userJobs_sum_fieldsKeySpecifier);
    fields?: userJobs_sum_fieldsFieldPolicy;
  };
  userJobs_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_var_pop_fieldsKeySpecifier
      | (() => undefined | userJobs_var_pop_fieldsKeySpecifier);
    fields?: userJobs_var_pop_fieldsFieldPolicy;
  };
  userJobs_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_var_samp_fieldsKeySpecifier
      | (() => undefined | userJobs_var_samp_fieldsKeySpecifier);
    fields?: userJobs_var_samp_fieldsFieldPolicy;
  };
  userJobs_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | userJobs_variance_fieldsKeySpecifier
      | (() => undefined | userJobs_variance_fieldsKeySpecifier);
    fields?: userJobs_variance_fieldsFieldPolicy;
  };
  user_erc721_block?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_erc721_blockKeySpecifier
      | (() => undefined | user_erc721_blockKeySpecifier);
    fields?: user_erc721_blockFieldPolicy;
  };
  user_erc721_block_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_erc721_block_aggregateKeySpecifier
      | (() => undefined | user_erc721_block_aggregateKeySpecifier);
    fields?: user_erc721_block_aggregateFieldPolicy;
  };
  user_erc721_block_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_erc721_block_aggregate_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_aggregate_fieldsKeySpecifier);
    fields?: user_erc721_block_aggregate_fieldsFieldPolicy;
  };
  user_erc721_block_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_erc721_block_avg_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_avg_fieldsKeySpecifier);
    fields?: user_erc721_block_avg_fieldsFieldPolicy;
  };
  user_erc721_block_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_erc721_block_max_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_max_fieldsKeySpecifier);
    fields?: user_erc721_block_max_fieldsFieldPolicy;
  };
  user_erc721_block_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_erc721_block_min_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_min_fieldsKeySpecifier);
    fields?: user_erc721_block_min_fieldsFieldPolicy;
  };
  user_erc721_block_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_erc721_block_mutation_responseKeySpecifier
      | (() => undefined | user_erc721_block_mutation_responseKeySpecifier);
    fields?: user_erc721_block_mutation_responseFieldPolicy;
  };
  user_erc721_block_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_erc721_block_stddev_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_stddev_fieldsKeySpecifier);
    fields?: user_erc721_block_stddev_fieldsFieldPolicy;
  };
  user_erc721_block_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_erc721_block_stddev_pop_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_stddev_pop_fieldsKeySpecifier);
    fields?: user_erc721_block_stddev_pop_fieldsFieldPolicy;
  };
  user_erc721_block_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_erc721_block_stddev_samp_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_stddev_samp_fieldsKeySpecifier);
    fields?: user_erc721_block_stddev_samp_fieldsFieldPolicy;
  };
  user_erc721_block_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_erc721_block_sum_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_sum_fieldsKeySpecifier);
    fields?: user_erc721_block_sum_fieldsFieldPolicy;
  };
  user_erc721_block_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_erc721_block_var_pop_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_var_pop_fieldsKeySpecifier);
    fields?: user_erc721_block_var_pop_fieldsFieldPolicy;
  };
  user_erc721_block_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_erc721_block_var_samp_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_var_samp_fieldsKeySpecifier);
    fields?: user_erc721_block_var_samp_fieldsFieldPolicy;
  };
  user_erc721_block_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_erc721_block_variance_fieldsKeySpecifier
      | (() => undefined | user_erc721_block_variance_fieldsKeySpecifier);
    fields?: user_erc721_block_variance_fieldsFieldPolicy;
  };
  user_gpanel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanelKeySpecifier
      | (() => undefined | user_gpanelKeySpecifier);
    fields?: user_gpanelFieldPolicy;
  };
  user_gpanel_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_aggregateKeySpecifier
      | (() => undefined | user_gpanel_aggregateKeySpecifier);
    fields?: user_gpanel_aggregateFieldPolicy;
  };
  user_gpanel_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_aggregate_fieldsKeySpecifier
      | (() => undefined | user_gpanel_aggregate_fieldsKeySpecifier);
    fields?: user_gpanel_aggregate_fieldsFieldPolicy;
  };
  user_gpanel_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_avg_fieldsKeySpecifier
      | (() => undefined | user_gpanel_avg_fieldsKeySpecifier);
    fields?: user_gpanel_avg_fieldsFieldPolicy;
  };
  user_gpanel_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_max_fieldsKeySpecifier
      | (() => undefined | user_gpanel_max_fieldsKeySpecifier);
    fields?: user_gpanel_max_fieldsFieldPolicy;
  };
  user_gpanel_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_min_fieldsKeySpecifier
      | (() => undefined | user_gpanel_min_fieldsKeySpecifier);
    fields?: user_gpanel_min_fieldsFieldPolicy;
  };
  user_gpanel_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_mutation_responseKeySpecifier
      | (() => undefined | user_gpanel_mutation_responseKeySpecifier);
    fields?: user_gpanel_mutation_responseFieldPolicy;
  };
  user_gpanel_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_stddev_fieldsKeySpecifier
      | (() => undefined | user_gpanel_stddev_fieldsKeySpecifier);
    fields?: user_gpanel_stddev_fieldsFieldPolicy;
  };
  user_gpanel_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_stddev_pop_fieldsKeySpecifier
      | (() => undefined | user_gpanel_stddev_pop_fieldsKeySpecifier);
    fields?: user_gpanel_stddev_pop_fieldsFieldPolicy;
  };
  user_gpanel_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_stddev_samp_fieldsKeySpecifier
      | (() => undefined | user_gpanel_stddev_samp_fieldsKeySpecifier);
    fields?: user_gpanel_stddev_samp_fieldsFieldPolicy;
  };
  user_gpanel_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_sum_fieldsKeySpecifier
      | (() => undefined | user_gpanel_sum_fieldsKeySpecifier);
    fields?: user_gpanel_sum_fieldsFieldPolicy;
  };
  user_gpanel_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_var_pop_fieldsKeySpecifier
      | (() => undefined | user_gpanel_var_pop_fieldsKeySpecifier);
    fields?: user_gpanel_var_pop_fieldsFieldPolicy;
  };
  user_gpanel_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_var_samp_fieldsKeySpecifier
      | (() => undefined | user_gpanel_var_samp_fieldsKeySpecifier);
    fields?: user_gpanel_var_samp_fieldsFieldPolicy;
  };
  user_gpanel_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_gpanel_variance_fieldsKeySpecifier
      | (() => undefined | user_gpanel_variance_fieldsKeySpecifier);
    fields?: user_gpanel_variance_fieldsFieldPolicy;
  };
  user_lock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lockKeySpecifier
      | (() => undefined | user_lockKeySpecifier);
    fields?: user_lockFieldPolicy;
  };
  user_lock_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_aggregateKeySpecifier
      | (() => undefined | user_lock_aggregateKeySpecifier);
    fields?: user_lock_aggregateFieldPolicy;
  };
  user_lock_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_aggregate_fieldsKeySpecifier
      | (() => undefined | user_lock_aggregate_fieldsKeySpecifier);
    fields?: user_lock_aggregate_fieldsFieldPolicy;
  };
  user_lock_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_avg_fieldsKeySpecifier
      | (() => undefined | user_lock_avg_fieldsKeySpecifier);
    fields?: user_lock_avg_fieldsFieldPolicy;
  };
  user_lock_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_max_fieldsKeySpecifier
      | (() => undefined | user_lock_max_fieldsKeySpecifier);
    fields?: user_lock_max_fieldsFieldPolicy;
  };
  user_lock_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_min_fieldsKeySpecifier
      | (() => undefined | user_lock_min_fieldsKeySpecifier);
    fields?: user_lock_min_fieldsFieldPolicy;
  };
  user_lock_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_mutation_responseKeySpecifier
      | (() => undefined | user_lock_mutation_responseKeySpecifier);
    fields?: user_lock_mutation_responseFieldPolicy;
  };
  user_lock_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_stddev_fieldsKeySpecifier
      | (() => undefined | user_lock_stddev_fieldsKeySpecifier);
    fields?: user_lock_stddev_fieldsFieldPolicy;
  };
  user_lock_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_stddev_pop_fieldsKeySpecifier
      | (() => undefined | user_lock_stddev_pop_fieldsKeySpecifier);
    fields?: user_lock_stddev_pop_fieldsFieldPolicy;
  };
  user_lock_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_stddev_samp_fieldsKeySpecifier
      | (() => undefined | user_lock_stddev_samp_fieldsKeySpecifier);
    fields?: user_lock_stddev_samp_fieldsFieldPolicy;
  };
  user_lock_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_sum_fieldsKeySpecifier
      | (() => undefined | user_lock_sum_fieldsKeySpecifier);
    fields?: user_lock_sum_fieldsFieldPolicy;
  };
  user_lock_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_var_pop_fieldsKeySpecifier
      | (() => undefined | user_lock_var_pop_fieldsKeySpecifier);
    fields?: user_lock_var_pop_fieldsFieldPolicy;
  };
  user_lock_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_var_samp_fieldsKeySpecifier
      | (() => undefined | user_lock_var_samp_fieldsKeySpecifier);
    fields?: user_lock_var_samp_fieldsFieldPolicy;
  };
  user_lock_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_lock_variance_fieldsKeySpecifier
      | (() => undefined | user_lock_variance_fieldsKeySpecifier);
    fields?: user_lock_variance_fieldsFieldPolicy;
  };
  user_staking?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_stakingKeySpecifier
      | (() => undefined | user_stakingKeySpecifier);
    fields?: user_stakingFieldPolicy;
  };
  user_staking_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_aggregateKeySpecifier
      | (() => undefined | user_staking_aggregateKeySpecifier);
    fields?: user_staking_aggregateFieldPolicy;
  };
  user_staking_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_aggregate_fieldsKeySpecifier
      | (() => undefined | user_staking_aggregate_fieldsKeySpecifier);
    fields?: user_staking_aggregate_fieldsFieldPolicy;
  };
  user_staking_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_avg_fieldsKeySpecifier
      | (() => undefined | user_staking_avg_fieldsKeySpecifier);
    fields?: user_staking_avg_fieldsFieldPolicy;
  };
  user_staking_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_max_fieldsKeySpecifier
      | (() => undefined | user_staking_max_fieldsKeySpecifier);
    fields?: user_staking_max_fieldsFieldPolicy;
  };
  user_staking_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_min_fieldsKeySpecifier
      | (() => undefined | user_staking_min_fieldsKeySpecifier);
    fields?: user_staking_min_fieldsFieldPolicy;
  };
  user_staking_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_mutation_responseKeySpecifier
      | (() => undefined | user_staking_mutation_responseKeySpecifier);
    fields?: user_staking_mutation_responseFieldPolicy;
  };
  user_staking_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_stddev_fieldsKeySpecifier
      | (() => undefined | user_staking_stddev_fieldsKeySpecifier);
    fields?: user_staking_stddev_fieldsFieldPolicy;
  };
  user_staking_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_stddev_pop_fieldsKeySpecifier
      | (() => undefined | user_staking_stddev_pop_fieldsKeySpecifier);
    fields?: user_staking_stddev_pop_fieldsFieldPolicy;
  };
  user_staking_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_stddev_samp_fieldsKeySpecifier
      | (() => undefined | user_staking_stddev_samp_fieldsKeySpecifier);
    fields?: user_staking_stddev_samp_fieldsFieldPolicy;
  };
  user_staking_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_sum_fieldsKeySpecifier
      | (() => undefined | user_staking_sum_fieldsKeySpecifier);
    fields?: user_staking_sum_fieldsFieldPolicy;
  };
  user_staking_transactions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_transactionsKeySpecifier
      | (() => undefined | user_staking_transactionsKeySpecifier);
    fields?: user_staking_transactionsFieldPolicy;
  };
  user_staking_transactions_aggregate?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_aggregateKeySpecifier
      | (() => undefined | user_staking_transactions_aggregateKeySpecifier);
    fields?: user_staking_transactions_aggregateFieldPolicy;
  };
  user_staking_transactions_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_aggregate_fieldsKeySpecifier
      | (() =>
          | undefined
          | user_staking_transactions_aggregate_fieldsKeySpecifier);
    fields?: user_staking_transactions_aggregate_fieldsFieldPolicy;
  };
  user_staking_transactions_avg_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_avg_fieldsKeySpecifier
      | (() => undefined | user_staking_transactions_avg_fieldsKeySpecifier);
    fields?: user_staking_transactions_avg_fieldsFieldPolicy;
  };
  user_staking_transactions_max_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_max_fieldsKeySpecifier
      | (() => undefined | user_staking_transactions_max_fieldsKeySpecifier);
    fields?: user_staking_transactions_max_fieldsFieldPolicy;
  };
  user_staking_transactions_min_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_min_fieldsKeySpecifier
      | (() => undefined | user_staking_transactions_min_fieldsKeySpecifier);
    fields?: user_staking_transactions_min_fieldsFieldPolicy;
  };
  user_staking_transactions_mutation_response?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_mutation_responseKeySpecifier
      | (() =>
          | undefined
          | user_staking_transactions_mutation_responseKeySpecifier);
    fields?: user_staking_transactions_mutation_responseFieldPolicy;
  };
  user_staking_transactions_stddev_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_stddev_fieldsKeySpecifier
      | (() => undefined | user_staking_transactions_stddev_fieldsKeySpecifier);
    fields?: user_staking_transactions_stddev_fieldsFieldPolicy;
  };
  user_staking_transactions_stddev_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_stddev_pop_fieldsKeySpecifier
      | (() =>
          | undefined
          | user_staking_transactions_stddev_pop_fieldsKeySpecifier);
    fields?: user_staking_transactions_stddev_pop_fieldsFieldPolicy;
  };
  user_staking_transactions_stddev_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_stddev_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | user_staking_transactions_stddev_samp_fieldsKeySpecifier);
    fields?: user_staking_transactions_stddev_samp_fieldsFieldPolicy;
  };
  user_staking_transactions_sum_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_sum_fieldsKeySpecifier
      | (() => undefined | user_staking_transactions_sum_fieldsKeySpecifier);
    fields?: user_staking_transactions_sum_fieldsFieldPolicy;
  };
  user_staking_transactions_var_pop_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_var_pop_fieldsKeySpecifier
      | (() =>
          | undefined
          | user_staking_transactions_var_pop_fieldsKeySpecifier);
    fields?: user_staking_transactions_var_pop_fieldsFieldPolicy;
  };
  user_staking_transactions_var_samp_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_var_samp_fieldsKeySpecifier
      | (() =>
          | undefined
          | user_staking_transactions_var_samp_fieldsKeySpecifier);
    fields?: user_staking_transactions_var_samp_fieldsFieldPolicy;
  };
  user_staking_transactions_variance_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | user_staking_transactions_variance_fieldsKeySpecifier
      | (() =>
          | undefined
          | user_staking_transactions_variance_fieldsKeySpecifier);
    fields?: user_staking_transactions_variance_fieldsFieldPolicy;
  };
  user_staking_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_var_pop_fieldsKeySpecifier
      | (() => undefined | user_staking_var_pop_fieldsKeySpecifier);
    fields?: user_staking_var_pop_fieldsFieldPolicy;
  };
  user_staking_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_var_samp_fieldsKeySpecifier
      | (() => undefined | user_staking_var_samp_fieldsKeySpecifier);
    fields?: user_staking_var_samp_fieldsFieldPolicy;
  };
  user_staking_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | user_staking_variance_fieldsKeySpecifier
      | (() => undefined | user_staking_variance_fieldsKeySpecifier);
    fields?: user_staking_variance_fieldsFieldPolicy;
  };
  users?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | usersKeySpecifier
      | (() => undefined | usersKeySpecifier);
    fields?: usersFieldPolicy;
  };
  users_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_aggregateKeySpecifier
      | (() => undefined | users_aggregateKeySpecifier);
    fields?: users_aggregateFieldPolicy;
  };
  users_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_aggregate_fieldsKeySpecifier
      | (() => undefined | users_aggregate_fieldsKeySpecifier);
    fields?: users_aggregate_fieldsFieldPolicy;
  };
  users_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_avg_fieldsKeySpecifier
      | (() => undefined | users_avg_fieldsKeySpecifier);
    fields?: users_avg_fieldsFieldPolicy;
  };
  users_login?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_loginKeySpecifier
      | (() => undefined | users_loginKeySpecifier);
    fields?: users_loginFieldPolicy;
  };
  users_login_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_login_aggregateKeySpecifier
      | (() => undefined | users_login_aggregateKeySpecifier);
    fields?: users_login_aggregateFieldPolicy;
  };
  users_login_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_login_aggregate_fieldsKeySpecifier
      | (() => undefined | users_login_aggregate_fieldsKeySpecifier);
    fields?: users_login_aggregate_fieldsFieldPolicy;
  };
  users_login_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_login_max_fieldsKeySpecifier
      | (() => undefined | users_login_max_fieldsKeySpecifier);
    fields?: users_login_max_fieldsFieldPolicy;
  };
  users_login_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_login_min_fieldsKeySpecifier
      | (() => undefined | users_login_min_fieldsKeySpecifier);
    fields?: users_login_min_fieldsFieldPolicy;
  };
  users_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_max_fieldsKeySpecifier
      | (() => undefined | users_max_fieldsKeySpecifier);
    fields?: users_max_fieldsFieldPolicy;
  };
  users_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_min_fieldsKeySpecifier
      | (() => undefined | users_min_fieldsKeySpecifier);
    fields?: users_min_fieldsFieldPolicy;
  };
  users_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_mutation_responseKeySpecifier
      | (() => undefined | users_mutation_responseKeySpecifier);
    fields?: users_mutation_responseFieldPolicy;
  };
  users_old?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_oldKeySpecifier
      | (() => undefined | users_oldKeySpecifier);
    fields?: users_oldFieldPolicy;
  };
  users_old_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_aggregateKeySpecifier
      | (() => undefined | users_old_aggregateKeySpecifier);
    fields?: users_old_aggregateFieldPolicy;
  };
  users_old_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_aggregate_fieldsKeySpecifier
      | (() => undefined | users_old_aggregate_fieldsKeySpecifier);
    fields?: users_old_aggregate_fieldsFieldPolicy;
  };
  users_old_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_avg_fieldsKeySpecifier
      | (() => undefined | users_old_avg_fieldsKeySpecifier);
    fields?: users_old_avg_fieldsFieldPolicy;
  };
  users_old_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_max_fieldsKeySpecifier
      | (() => undefined | users_old_max_fieldsKeySpecifier);
    fields?: users_old_max_fieldsFieldPolicy;
  };
  users_old_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_min_fieldsKeySpecifier
      | (() => undefined | users_old_min_fieldsKeySpecifier);
    fields?: users_old_min_fieldsFieldPolicy;
  };
  users_old_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_mutation_responseKeySpecifier
      | (() => undefined | users_old_mutation_responseKeySpecifier);
    fields?: users_old_mutation_responseFieldPolicy;
  };
  users_old_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_stddev_fieldsKeySpecifier
      | (() => undefined | users_old_stddev_fieldsKeySpecifier);
    fields?: users_old_stddev_fieldsFieldPolicy;
  };
  users_old_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_stddev_pop_fieldsKeySpecifier
      | (() => undefined | users_old_stddev_pop_fieldsKeySpecifier);
    fields?: users_old_stddev_pop_fieldsFieldPolicy;
  };
  users_old_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_stddev_samp_fieldsKeySpecifier
      | (() => undefined | users_old_stddev_samp_fieldsKeySpecifier);
    fields?: users_old_stddev_samp_fieldsFieldPolicy;
  };
  users_old_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_sum_fieldsKeySpecifier
      | (() => undefined | users_old_sum_fieldsKeySpecifier);
    fields?: users_old_sum_fieldsFieldPolicy;
  };
  users_old_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_var_pop_fieldsKeySpecifier
      | (() => undefined | users_old_var_pop_fieldsKeySpecifier);
    fields?: users_old_var_pop_fieldsFieldPolicy;
  };
  users_old_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_var_samp_fieldsKeySpecifier
      | (() => undefined | users_old_var_samp_fieldsKeySpecifier);
    fields?: users_old_var_samp_fieldsFieldPolicy;
  };
  users_old_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_old_variance_fieldsKeySpecifier
      | (() => undefined | users_old_variance_fieldsKeySpecifier);
    fields?: users_old_variance_fieldsFieldPolicy;
  };
  users_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_stddev_fieldsKeySpecifier
      | (() => undefined | users_stddev_fieldsKeySpecifier);
    fields?: users_stddev_fieldsFieldPolicy;
  };
  users_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_stddev_pop_fieldsKeySpecifier
      | (() => undefined | users_stddev_pop_fieldsKeySpecifier);
    fields?: users_stddev_pop_fieldsFieldPolicy;
  };
  users_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_stddev_samp_fieldsKeySpecifier
      | (() => undefined | users_stddev_samp_fieldsKeySpecifier);
    fields?: users_stddev_samp_fieldsFieldPolicy;
  };
  users_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_sum_fieldsKeySpecifier
      | (() => undefined | users_sum_fieldsKeySpecifier);
    fields?: users_sum_fieldsFieldPolicy;
  };
  users_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_var_pop_fieldsKeySpecifier
      | (() => undefined | users_var_pop_fieldsKeySpecifier);
    fields?: users_var_pop_fieldsFieldPolicy;
  };
  users_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_var_samp_fieldsKeySpecifier
      | (() => undefined | users_var_samp_fieldsKeySpecifier);
    fields?: users_var_samp_fieldsFieldPolicy;
  };
  users_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_variance_fieldsKeySpecifier
      | (() => undefined | users_variance_fieldsKeySpecifier);
    fields?: users_variance_fieldsFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  DepositOutput: ResolverTypeWrapper<DepositOutput>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GeneralMessageOutput: ResolverTypeWrapper<GeneralMessageOutput>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int_comparison_exp: Int_Comparison_Exp;
  KYCSubmitOutput: ResolverTypeWrapper<KycSubmitOutput>;
  NFTConnectedDetail: ResolverTypeWrapper<NftConnectedDetail>;
  NFTConnectedOutput: ResolverTypeWrapper<NftConnectedOutput>;
  NFTTicketsClaimOptions: ResolverTypeWrapper<NftTicketsClaimOptions>;
  NFTTicketsClaimOutput: ResolverTypeWrapper<NftTicketsClaimOutput>;
  RateOutput: ResolverTypeWrapper<RateOutput>;
  StatusOutput: ResolverTypeWrapper<StatusOutput>;
  String: ResolverTypeWrapper<Scalars['String']>;
  String_comparison_exp: String_Comparison_Exp;
  SwapOrderOutput: ResolverTypeWrapper<SwapOrderOutput>;
  SwapOutput: ResolverTypeWrapper<SwapOutput>;
  Test: ResolverTypeWrapper<Test>;
  Test_aggregate: ResolverTypeWrapper<Test_Aggregate>;
  Test_aggregate_fields: ResolverTypeWrapper<Test_Aggregate_Fields>;
  Test_avg_fields: ResolverTypeWrapper<Test_Avg_Fields>;
  Test_bool_exp: Test_Bool_Exp;
  Test_constraint: Test_Constraint;
  Test_inc_input: Test_Inc_Input;
  Test_insert_input: Test_Insert_Input;
  Test_max_fields: ResolverTypeWrapper<Test_Max_Fields>;
  Test_min_fields: ResolverTypeWrapper<Test_Min_Fields>;
  Test_mutation_response: ResolverTypeWrapper<Test_Mutation_Response>;
  Test_on_conflict: Test_On_Conflict;
  Test_order_by: Test_Order_By;
  Test_pk_columns_input: Test_Pk_Columns_Input;
  Test_select_column: Test_Select_Column;
  Test_set_input: Test_Set_Input;
  Test_stddev_fields: ResolverTypeWrapper<Test_Stddev_Fields>;
  Test_stddev_pop_fields: ResolverTypeWrapper<Test_Stddev_Pop_Fields>;
  Test_stddev_samp_fields: ResolverTypeWrapper<Test_Stddev_Samp_Fields>;
  Test_stream_cursor_input: Test_Stream_Cursor_Input;
  Test_stream_cursor_value_input: Test_Stream_Cursor_Value_Input;
  Test_sum_fields: ResolverTypeWrapper<Test_Sum_Fields>;
  Test_update_column: Test_Update_Column;
  Test_updates: Test_Updates;
  Test_var_pop_fields: ResolverTypeWrapper<Test_Var_Pop_Fields>;
  Test_var_samp_fields: ResolverTypeWrapper<Test_Var_Samp_Fields>;
  Test_variance_fields: ResolverTypeWrapper<Test_Variance_Fields>;
  UserOutput: ResolverTypeWrapper<UserOutput>;
  agentFindUserOutput: ResolverTypeWrapper<AgentFindUserOutput>;
  agentGetUsersOutput: ResolverTypeWrapper<AgentGetUsersOutput>;
  agentUserItemOutput: ResolverTypeWrapper<AgentUserItemOutput>;
  agentUserOutput: ResolverTypeWrapper<AgentUserOutput>;
  anno_gpanel: ResolverTypeWrapper<Anno_Gpanel>;
  anno_gpanel_aggregate: ResolverTypeWrapper<Anno_Gpanel_Aggregate>;
  anno_gpanel_aggregate_fields: ResolverTypeWrapper<Anno_Gpanel_Aggregate_Fields>;
  anno_gpanel_avg_fields: ResolverTypeWrapper<Anno_Gpanel_Avg_Fields>;
  anno_gpanel_bool_exp: Anno_Gpanel_Bool_Exp;
  anno_gpanel_constraint: Anno_Gpanel_Constraint;
  anno_gpanel_inc_input: Anno_Gpanel_Inc_Input;
  anno_gpanel_insert_input: Anno_Gpanel_Insert_Input;
  anno_gpanel_max_fields: ResolverTypeWrapper<Anno_Gpanel_Max_Fields>;
  anno_gpanel_min_fields: ResolverTypeWrapper<Anno_Gpanel_Min_Fields>;
  anno_gpanel_mutation_response: ResolverTypeWrapper<Anno_Gpanel_Mutation_Response>;
  anno_gpanel_on_conflict: Anno_Gpanel_On_Conflict;
  anno_gpanel_order_by: Anno_Gpanel_Order_By;
  anno_gpanel_pk_columns_input: Anno_Gpanel_Pk_Columns_Input;
  anno_gpanel_select_column: Anno_Gpanel_Select_Column;
  anno_gpanel_set_input: Anno_Gpanel_Set_Input;
  anno_gpanel_stddev_fields: ResolverTypeWrapper<Anno_Gpanel_Stddev_Fields>;
  anno_gpanel_stddev_pop_fields: ResolverTypeWrapper<Anno_Gpanel_Stddev_Pop_Fields>;
  anno_gpanel_stddev_samp_fields: ResolverTypeWrapper<Anno_Gpanel_Stddev_Samp_Fields>;
  anno_gpanel_stream_cursor_input: Anno_Gpanel_Stream_Cursor_Input;
  anno_gpanel_stream_cursor_value_input: Anno_Gpanel_Stream_Cursor_Value_Input;
  anno_gpanel_sum_fields: ResolverTypeWrapper<Anno_Gpanel_Sum_Fields>;
  anno_gpanel_update_column: Anno_Gpanel_Update_Column;
  anno_gpanel_updates: Anno_Gpanel_Updates;
  anno_gpanel_var_pop_fields: ResolverTypeWrapper<Anno_Gpanel_Var_Pop_Fields>;
  anno_gpanel_var_samp_fields: ResolverTypeWrapper<Anno_Gpanel_Var_Samp_Fields>;
  anno_gpanel_variance_fields: ResolverTypeWrapper<Anno_Gpanel_Variance_Fields>;
  authLoginOutput: ResolverTypeWrapper<AuthLoginOutput>;
  authSignatureOutput: ResolverTypeWrapper<AuthSignatureOutput>;
  avatarOutput: ResolverTypeWrapper<AvatarOutput>;
  bigint: ResolverTypeWrapper<Scalars['bigint']>;
  bigint_comparison_exp: Bigint_Comparison_Exp;
  blockchain: ResolverTypeWrapper<Blockchain>;
  blockchain_aggregate: ResolverTypeWrapper<Blockchain_Aggregate>;
  blockchain_aggregate_fields: ResolverTypeWrapper<Blockchain_Aggregate_Fields>;
  blockchain_append_input: Blockchain_Append_Input;
  blockchain_avg_fields: ResolverTypeWrapper<Blockchain_Avg_Fields>;
  blockchain_bool_exp: Blockchain_Bool_Exp;
  blockchain_constraint: Blockchain_Constraint;
  blockchain_delete_at_path_input: Blockchain_Delete_At_Path_Input;
  blockchain_delete_elem_input: Blockchain_Delete_Elem_Input;
  blockchain_delete_key_input: Blockchain_Delete_Key_Input;
  blockchain_inc_input: Blockchain_Inc_Input;
  blockchain_insert_input: Blockchain_Insert_Input;
  blockchain_main_deposit: ResolverTypeWrapper<Blockchain_Main_Deposit>;
  blockchain_main_deposit_aggregate: ResolverTypeWrapper<Blockchain_Main_Deposit_Aggregate>;
  blockchain_main_deposit_aggregate_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Aggregate_Fields>;
  blockchain_main_deposit_avg_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Avg_Fields>;
  blockchain_main_deposit_bool_exp: Blockchain_Main_Deposit_Bool_Exp;
  blockchain_main_deposit_constraint: Blockchain_Main_Deposit_Constraint;
  blockchain_main_deposit_inc_input: Blockchain_Main_Deposit_Inc_Input;
  blockchain_main_deposit_insert_input: Blockchain_Main_Deposit_Insert_Input;
  blockchain_main_deposit_max_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Max_Fields>;
  blockchain_main_deposit_min_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Min_Fields>;
  blockchain_main_deposit_mutation_response: ResolverTypeWrapper<Blockchain_Main_Deposit_Mutation_Response>;
  blockchain_main_deposit_on_conflict: Blockchain_Main_Deposit_On_Conflict;
  blockchain_main_deposit_order_by: Blockchain_Main_Deposit_Order_By;
  blockchain_main_deposit_pk_columns_input: Blockchain_Main_Deposit_Pk_Columns_Input;
  blockchain_main_deposit_select_column: Blockchain_Main_Deposit_Select_Column;
  blockchain_main_deposit_set_input: Blockchain_Main_Deposit_Set_Input;
  blockchain_main_deposit_stddev_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Stddev_Fields>;
  blockchain_main_deposit_stddev_pop_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Stddev_Pop_Fields>;
  blockchain_main_deposit_stddev_samp_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Stddev_Samp_Fields>;
  blockchain_main_deposit_stream_cursor_input: Blockchain_Main_Deposit_Stream_Cursor_Input;
  blockchain_main_deposit_stream_cursor_value_input: Blockchain_Main_Deposit_Stream_Cursor_Value_Input;
  blockchain_main_deposit_sum_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Sum_Fields>;
  blockchain_main_deposit_update_column: Blockchain_Main_Deposit_Update_Column;
  blockchain_main_deposit_updates: Blockchain_Main_Deposit_Updates;
  blockchain_main_deposit_var_pop_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Var_Pop_Fields>;
  blockchain_main_deposit_var_samp_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Var_Samp_Fields>;
  blockchain_main_deposit_variance_fields: ResolverTypeWrapper<Blockchain_Main_Deposit_Variance_Fields>;
  blockchain_max_fields: ResolverTypeWrapper<Blockchain_Max_Fields>;
  blockchain_min_fields: ResolverTypeWrapper<Blockchain_Min_Fields>;
  blockchain_mutation_response: ResolverTypeWrapper<Blockchain_Mutation_Response>;
  blockchain_obj_rel_insert_input: Blockchain_Obj_Rel_Insert_Input;
  blockchain_on_conflict: Blockchain_On_Conflict;
  blockchain_order_by: Blockchain_Order_By;
  blockchain_pk_columns_input: Blockchain_Pk_Columns_Input;
  blockchain_prepend_input: Blockchain_Prepend_Input;
  blockchain_select_column: Blockchain_Select_Column;
  blockchain_set_input: Blockchain_Set_Input;
  blockchain_stddev_fields: ResolverTypeWrapper<Blockchain_Stddev_Fields>;
  blockchain_stddev_pop_fields: ResolverTypeWrapper<Blockchain_Stddev_Pop_Fields>;
  blockchain_stddev_samp_fields: ResolverTypeWrapper<Blockchain_Stddev_Samp_Fields>;
  blockchain_stream_cursor_input: Blockchain_Stream_Cursor_Input;
  blockchain_stream_cursor_value_input: Blockchain_Stream_Cursor_Value_Input;
  blockchain_sum_fields: ResolverTypeWrapper<Blockchain_Sum_Fields>;
  blockchain_update_column: Blockchain_Update_Column;
  blockchain_updates: Blockchain_Updates;
  blockchain_var_pop_fields: ResolverTypeWrapper<Blockchain_Var_Pop_Fields>;
  blockchain_var_samp_fields: ResolverTypeWrapper<Blockchain_Var_Samp_Fields>;
  blockchain_variance_fields: ResolverTypeWrapper<Blockchain_Variance_Fields>;
  burn_profit: ResolverTypeWrapper<Burn_Profit>;
  burn_profit_aggregate: ResolverTypeWrapper<Burn_Profit_Aggregate>;
  burn_profit_aggregate_fields: ResolverTypeWrapper<Burn_Profit_Aggregate_Fields>;
  burn_profit_avg_fields: ResolverTypeWrapper<Burn_Profit_Avg_Fields>;
  burn_profit_bool_exp: Burn_Profit_Bool_Exp;
  burn_profit_constraint: Burn_Profit_Constraint;
  burn_profit_inc_input: Burn_Profit_Inc_Input;
  burn_profit_insert_input: Burn_Profit_Insert_Input;
  burn_profit_max_fields: ResolverTypeWrapper<Burn_Profit_Max_Fields>;
  burn_profit_min_fields: ResolverTypeWrapper<Burn_Profit_Min_Fields>;
  burn_profit_mutation_response: ResolverTypeWrapper<Burn_Profit_Mutation_Response>;
  burn_profit_on_conflict: Burn_Profit_On_Conflict;
  burn_profit_order_by: Burn_Profit_Order_By;
  burn_profit_pk_columns_input: Burn_Profit_Pk_Columns_Input;
  burn_profit_select_column: Burn_Profit_Select_Column;
  burn_profit_set_input: Burn_Profit_Set_Input;
  burn_profit_stddev_fields: ResolverTypeWrapper<Burn_Profit_Stddev_Fields>;
  burn_profit_stddev_pop_fields: ResolverTypeWrapper<Burn_Profit_Stddev_Pop_Fields>;
  burn_profit_stddev_samp_fields: ResolverTypeWrapper<Burn_Profit_Stddev_Samp_Fields>;
  burn_profit_stream_cursor_input: Burn_Profit_Stream_Cursor_Input;
  burn_profit_stream_cursor_value_input: Burn_Profit_Stream_Cursor_Value_Input;
  burn_profit_sum_fields: ResolverTypeWrapper<Burn_Profit_Sum_Fields>;
  burn_profit_update_column: Burn_Profit_Update_Column;
  burn_profit_updates: Burn_Profit_Updates;
  burn_profit_var_pop_fields: ResolverTypeWrapper<Burn_Profit_Var_Pop_Fields>;
  burn_profit_var_samp_fields: ResolverTypeWrapper<Burn_Profit_Var_Samp_Fields>;
  burn_profit_variance_fields: ResolverTypeWrapper<Burn_Profit_Variance_Fields>;
  currencies: ResolverTypeWrapper<Currencies>;
  currencies_aggregate: ResolverTypeWrapper<Currencies_Aggregate>;
  currencies_aggregate_fields: ResolverTypeWrapper<Currencies_Aggregate_Fields>;
  currencies_append_input: Currencies_Append_Input;
  currencies_avg_fields: ResolverTypeWrapper<Currencies_Avg_Fields>;
  currencies_bool_exp: Currencies_Bool_Exp;
  currencies_constraint: Currencies_Constraint;
  currencies_delete_at_path_input: Currencies_Delete_At_Path_Input;
  currencies_delete_elem_input: Currencies_Delete_Elem_Input;
  currencies_delete_key_input: Currencies_Delete_Key_Input;
  currencies_inc_input: Currencies_Inc_Input;
  currencies_insert_input: Currencies_Insert_Input;
  currencies_max_fields: ResolverTypeWrapper<Currencies_Max_Fields>;
  currencies_min_fields: ResolverTypeWrapper<Currencies_Min_Fields>;
  currencies_mutation_response: ResolverTypeWrapper<Currencies_Mutation_Response>;
  currencies_on_conflict: Currencies_On_Conflict;
  currencies_order_by: Currencies_Order_By;
  currencies_pk_columns_input: Currencies_Pk_Columns_Input;
  currencies_prepend_input: Currencies_Prepend_Input;
  currencies_select_column: Currencies_Select_Column;
  currencies_set_input: Currencies_Set_Input;
  currencies_stddev_fields: ResolverTypeWrapper<Currencies_Stddev_Fields>;
  currencies_stddev_pop_fields: ResolverTypeWrapper<Currencies_Stddev_Pop_Fields>;
  currencies_stddev_samp_fields: ResolverTypeWrapper<Currencies_Stddev_Samp_Fields>;
  currencies_stream_cursor_input: Currencies_Stream_Cursor_Input;
  currencies_stream_cursor_value_input: Currencies_Stream_Cursor_Value_Input;
  currencies_sum_fields: ResolverTypeWrapper<Currencies_Sum_Fields>;
  currencies_update_column: Currencies_Update_Column;
  currencies_updates: Currencies_Updates;
  currencies_var_pop_fields: ResolverTypeWrapper<Currencies_Var_Pop_Fields>;
  currencies_var_samp_fields: ResolverTypeWrapper<Currencies_Var_Samp_Fields>;
  currencies_variance_fields: ResolverTypeWrapper<Currencies_Variance_Fields>;
  currency_history: ResolverTypeWrapper<Currency_History>;
  currency_history_aggregate: ResolverTypeWrapper<Currency_History_Aggregate>;
  currency_history_aggregate_fields: ResolverTypeWrapper<Currency_History_Aggregate_Fields>;
  currency_history_avg_fields: ResolverTypeWrapper<Currency_History_Avg_Fields>;
  currency_history_bool_exp: Currency_History_Bool_Exp;
  currency_history_constraint: Currency_History_Constraint;
  currency_history_inc_input: Currency_History_Inc_Input;
  currency_history_insert_input: Currency_History_Insert_Input;
  currency_history_max_fields: ResolverTypeWrapper<Currency_History_Max_Fields>;
  currency_history_min_fields: ResolverTypeWrapper<Currency_History_Min_Fields>;
  currency_history_mutation_response: ResolverTypeWrapper<Currency_History_Mutation_Response>;
  currency_history_on_conflict: Currency_History_On_Conflict;
  currency_history_order_by: Currency_History_Order_By;
  currency_history_pk_columns_input: Currency_History_Pk_Columns_Input;
  currency_history_select_column: Currency_History_Select_Column;
  currency_history_set_input: Currency_History_Set_Input;
  currency_history_stddev_fields: ResolverTypeWrapper<Currency_History_Stddev_Fields>;
  currency_history_stddev_pop_fields: ResolverTypeWrapper<Currency_History_Stddev_Pop_Fields>;
  currency_history_stddev_samp_fields: ResolverTypeWrapper<Currency_History_Stddev_Samp_Fields>;
  currency_history_stream_cursor_input: Currency_History_Stream_Cursor_Input;
  currency_history_stream_cursor_value_input: Currency_History_Stream_Cursor_Value_Input;
  currency_history_sum_fields: ResolverTypeWrapper<Currency_History_Sum_Fields>;
  currency_history_update_column: Currency_History_Update_Column;
  currency_history_updates: Currency_History_Updates;
  currency_history_var_pop_fields: ResolverTypeWrapper<Currency_History_Var_Pop_Fields>;
  currency_history_var_samp_fields: ResolverTypeWrapper<Currency_History_Var_Samp_Fields>;
  currency_history_variance_fields: ResolverTypeWrapper<Currency_History_Variance_Fields>;
  cursor_ordering: Cursor_Ordering;
  depositAddressOutput: ResolverTypeWrapper<DepositAddressOutput>;
  erc721_addresses: ResolverTypeWrapper<Erc721_Addresses>;
  erc721_addresses_aggregate: ResolverTypeWrapper<Erc721_Addresses_Aggregate>;
  erc721_addresses_aggregate_fields: ResolverTypeWrapper<Erc721_Addresses_Aggregate_Fields>;
  erc721_addresses_avg_fields: ResolverTypeWrapper<Erc721_Addresses_Avg_Fields>;
  erc721_addresses_bool_exp: Erc721_Addresses_Bool_Exp;
  erc721_addresses_constraint: Erc721_Addresses_Constraint;
  erc721_addresses_inc_input: Erc721_Addresses_Inc_Input;
  erc721_addresses_insert_input: Erc721_Addresses_Insert_Input;
  erc721_addresses_max_fields: ResolverTypeWrapper<Erc721_Addresses_Max_Fields>;
  erc721_addresses_min_fields: ResolverTypeWrapper<Erc721_Addresses_Min_Fields>;
  erc721_addresses_mutation_response: ResolverTypeWrapper<Erc721_Addresses_Mutation_Response>;
  erc721_addresses_on_conflict: Erc721_Addresses_On_Conflict;
  erc721_addresses_order_by: Erc721_Addresses_Order_By;
  erc721_addresses_pk_columns_input: Erc721_Addresses_Pk_Columns_Input;
  erc721_addresses_select_column: Erc721_Addresses_Select_Column;
  erc721_addresses_set_input: Erc721_Addresses_Set_Input;
  erc721_addresses_stddev_fields: ResolverTypeWrapper<Erc721_Addresses_Stddev_Fields>;
  erc721_addresses_stddev_pop_fields: ResolverTypeWrapper<Erc721_Addresses_Stddev_Pop_Fields>;
  erc721_addresses_stddev_samp_fields: ResolverTypeWrapper<Erc721_Addresses_Stddev_Samp_Fields>;
  erc721_addresses_stream_cursor_input: Erc721_Addresses_Stream_Cursor_Input;
  erc721_addresses_stream_cursor_value_input: Erc721_Addresses_Stream_Cursor_Value_Input;
  erc721_addresses_sum_fields: ResolverTypeWrapper<Erc721_Addresses_Sum_Fields>;
  erc721_addresses_update_column: Erc721_Addresses_Update_Column;
  erc721_addresses_updates: Erc721_Addresses_Updates;
  erc721_addresses_var_pop_fields: ResolverTypeWrapper<Erc721_Addresses_Var_Pop_Fields>;
  erc721_addresses_var_samp_fields: ResolverTypeWrapper<Erc721_Addresses_Var_Samp_Fields>;
  erc721_addresses_variance_fields: ResolverTypeWrapper<Erc721_Addresses_Variance_Fields>;
  evenbetAffiliatePlayerBalances: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances>;
  evenbetAffiliatePlayerBalances_aggregate: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Aggregate>;
  evenbetAffiliatePlayerBalances_aggregate_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Aggregate_Fields>;
  evenbetAffiliatePlayerBalances_avg_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Avg_Fields>;
  evenbetAffiliatePlayerBalances_bool_exp: EvenbetAffiliatePlayerBalances_Bool_Exp;
  evenbetAffiliatePlayerBalances_max_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Max_Fields>;
  evenbetAffiliatePlayerBalances_min_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Min_Fields>;
  evenbetAffiliatePlayerBalances_order_by: EvenbetAffiliatePlayerBalances_Order_By;
  evenbetAffiliatePlayerBalances_select_column: EvenbetAffiliatePlayerBalances_Select_Column;
  evenbetAffiliatePlayerBalances_stddev_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Stddev_Fields>;
  evenbetAffiliatePlayerBalances_stddev_pop_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Stddev_Pop_Fields>;
  evenbetAffiliatePlayerBalances_stddev_samp_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Stddev_Samp_Fields>;
  evenbetAffiliatePlayerBalances_stream_cursor_input: EvenbetAffiliatePlayerBalances_Stream_Cursor_Input;
  evenbetAffiliatePlayerBalances_stream_cursor_value_input: EvenbetAffiliatePlayerBalances_Stream_Cursor_Value_Input;
  evenbetAffiliatePlayerBalances_sum_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Sum_Fields>;
  evenbetAffiliatePlayerBalances_var_pop_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Var_Pop_Fields>;
  evenbetAffiliatePlayerBalances_var_samp_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Var_Samp_Fields>;
  evenbetAffiliatePlayerBalances_variance_fields: ResolverTypeWrapper<EvenbetAffiliatePlayerBalances_Variance_Fields>;
  evenbet_ticket_requests: ResolverTypeWrapper<Evenbet_Ticket_Requests>;
  evenbet_ticket_requests_aggregate: ResolverTypeWrapper<Evenbet_Ticket_Requests_Aggregate>;
  evenbet_ticket_requests_aggregate_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Aggregate_Fields>;
  evenbet_ticket_requests_avg_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Avg_Fields>;
  evenbet_ticket_requests_bool_exp: Evenbet_Ticket_Requests_Bool_Exp;
  evenbet_ticket_requests_constraint: Evenbet_Ticket_Requests_Constraint;
  evenbet_ticket_requests_inc_input: Evenbet_Ticket_Requests_Inc_Input;
  evenbet_ticket_requests_insert_input: Evenbet_Ticket_Requests_Insert_Input;
  evenbet_ticket_requests_max_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Max_Fields>;
  evenbet_ticket_requests_min_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Min_Fields>;
  evenbet_ticket_requests_mutation_response: ResolverTypeWrapper<Evenbet_Ticket_Requests_Mutation_Response>;
  evenbet_ticket_requests_on_conflict: Evenbet_Ticket_Requests_On_Conflict;
  evenbet_ticket_requests_order_by: Evenbet_Ticket_Requests_Order_By;
  evenbet_ticket_requests_pk_columns_input: Evenbet_Ticket_Requests_Pk_Columns_Input;
  evenbet_ticket_requests_select_column: Evenbet_Ticket_Requests_Select_Column;
  evenbet_ticket_requests_set_input: Evenbet_Ticket_Requests_Set_Input;
  evenbet_ticket_requests_stddev_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Stddev_Fields>;
  evenbet_ticket_requests_stddev_pop_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Stddev_Pop_Fields>;
  evenbet_ticket_requests_stddev_samp_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Stddev_Samp_Fields>;
  evenbet_ticket_requests_stream_cursor_input: Evenbet_Ticket_Requests_Stream_Cursor_Input;
  evenbet_ticket_requests_stream_cursor_value_input: Evenbet_Ticket_Requests_Stream_Cursor_Value_Input;
  evenbet_ticket_requests_sum_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Sum_Fields>;
  evenbet_ticket_requests_update_column: Evenbet_Ticket_Requests_Update_Column;
  evenbet_ticket_requests_updates: Evenbet_Ticket_Requests_Updates;
  evenbet_ticket_requests_var_pop_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Var_Pop_Fields>;
  evenbet_ticket_requests_var_samp_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Var_Samp_Fields>;
  evenbet_ticket_requests_variance_fields: ResolverTypeWrapper<Evenbet_Ticket_Requests_Variance_Fields>;
  evenbet_tickets: ResolverTypeWrapper<Evenbet_Tickets>;
  evenbet_tickets_aggregate: ResolverTypeWrapper<Evenbet_Tickets_Aggregate>;
  evenbet_tickets_aggregate_fields: ResolverTypeWrapper<Evenbet_Tickets_Aggregate_Fields>;
  evenbet_tickets_avg_fields: ResolverTypeWrapper<Evenbet_Tickets_Avg_Fields>;
  evenbet_tickets_bool_exp: Evenbet_Tickets_Bool_Exp;
  evenbet_tickets_constraint: Evenbet_Tickets_Constraint;
  evenbet_tickets_inc_input: Evenbet_Tickets_Inc_Input;
  evenbet_tickets_insert_input: Evenbet_Tickets_Insert_Input;
  evenbet_tickets_max_fields: ResolverTypeWrapper<Evenbet_Tickets_Max_Fields>;
  evenbet_tickets_min_fields: ResolverTypeWrapper<Evenbet_Tickets_Min_Fields>;
  evenbet_tickets_mutation_response: ResolverTypeWrapper<Evenbet_Tickets_Mutation_Response>;
  evenbet_tickets_on_conflict: Evenbet_Tickets_On_Conflict;
  evenbet_tickets_order_by: Evenbet_Tickets_Order_By;
  evenbet_tickets_pk_columns_input: Evenbet_Tickets_Pk_Columns_Input;
  evenbet_tickets_select_column: Evenbet_Tickets_Select_Column;
  evenbet_tickets_set_input: Evenbet_Tickets_Set_Input;
  evenbet_tickets_stddev_fields: ResolverTypeWrapper<Evenbet_Tickets_Stddev_Fields>;
  evenbet_tickets_stddev_pop_fields: ResolverTypeWrapper<Evenbet_Tickets_Stddev_Pop_Fields>;
  evenbet_tickets_stddev_samp_fields: ResolverTypeWrapper<Evenbet_Tickets_Stddev_Samp_Fields>;
  evenbet_tickets_stream_cursor_input: Evenbet_Tickets_Stream_Cursor_Input;
  evenbet_tickets_stream_cursor_value_input: Evenbet_Tickets_Stream_Cursor_Value_Input;
  evenbet_tickets_sum_fields: ResolverTypeWrapper<Evenbet_Tickets_Sum_Fields>;
  evenbet_tickets_update_column: Evenbet_Tickets_Update_Column;
  evenbet_tickets_updates: Evenbet_Tickets_Updates;
  evenbet_tickets_var_pop_fields: ResolverTypeWrapper<Evenbet_Tickets_Var_Pop_Fields>;
  evenbet_tickets_var_samp_fields: ResolverTypeWrapper<Evenbet_Tickets_Var_Samp_Fields>;
  evenbet_tickets_variance_fields: ResolverTypeWrapper<Evenbet_Tickets_Variance_Fields>;
  gPanelOutput: ResolverTypeWrapper<GPanelOutput>;
  gpanelPayments: ResolverTypeWrapper<GpanelPayments>;
  gpanelPayments_aggregate: ResolverTypeWrapper<GpanelPayments_Aggregate>;
  gpanelPayments_aggregate_bool_exp: GpanelPayments_Aggregate_Bool_Exp;
  gpanelPayments_aggregate_bool_exp_count: GpanelPayments_Aggregate_Bool_Exp_Count;
  gpanelPayments_aggregate_fields: ResolverTypeWrapper<GpanelPayments_Aggregate_Fields>;
  gpanelPayments_aggregate_order_by: GpanelPayments_Aggregate_Order_By;
  gpanelPayments_arr_rel_insert_input: GpanelPayments_Arr_Rel_Insert_Input;
  gpanelPayments_avg_fields: ResolverTypeWrapper<GpanelPayments_Avg_Fields>;
  gpanelPayments_avg_order_by: GpanelPayments_Avg_Order_By;
  gpanelPayments_bool_exp: GpanelPayments_Bool_Exp;
  gpanelPayments_constraint: GpanelPayments_Constraint;
  gpanelPayments_inc_input: GpanelPayments_Inc_Input;
  gpanelPayments_insert_input: GpanelPayments_Insert_Input;
  gpanelPayments_max_fields: ResolverTypeWrapper<GpanelPayments_Max_Fields>;
  gpanelPayments_max_order_by: GpanelPayments_Max_Order_By;
  gpanelPayments_min_fields: ResolverTypeWrapper<GpanelPayments_Min_Fields>;
  gpanelPayments_min_order_by: GpanelPayments_Min_Order_By;
  gpanelPayments_mutation_response: ResolverTypeWrapper<GpanelPayments_Mutation_Response>;
  gpanelPayments_on_conflict: GpanelPayments_On_Conflict;
  gpanelPayments_order_by: GpanelPayments_Order_By;
  gpanelPayments_pk_columns_input: GpanelPayments_Pk_Columns_Input;
  gpanelPayments_select_column: GpanelPayments_Select_Column;
  gpanelPayments_set_input: GpanelPayments_Set_Input;
  gpanelPayments_stddev_fields: ResolverTypeWrapper<GpanelPayments_Stddev_Fields>;
  gpanelPayments_stddev_order_by: GpanelPayments_Stddev_Order_By;
  gpanelPayments_stddev_pop_fields: ResolverTypeWrapper<GpanelPayments_Stddev_Pop_Fields>;
  gpanelPayments_stddev_pop_order_by: GpanelPayments_Stddev_Pop_Order_By;
  gpanelPayments_stddev_samp_fields: ResolverTypeWrapper<GpanelPayments_Stddev_Samp_Fields>;
  gpanelPayments_stddev_samp_order_by: GpanelPayments_Stddev_Samp_Order_By;
  gpanelPayments_stream_cursor_input: GpanelPayments_Stream_Cursor_Input;
  gpanelPayments_stream_cursor_value_input: GpanelPayments_Stream_Cursor_Value_Input;
  gpanelPayments_sum_fields: ResolverTypeWrapper<GpanelPayments_Sum_Fields>;
  gpanelPayments_sum_order_by: GpanelPayments_Sum_Order_By;
  gpanelPayments_update_column: GpanelPayments_Update_Column;
  gpanelPayments_updates: GpanelPayments_Updates;
  gpanelPayments_var_pop_fields: ResolverTypeWrapper<GpanelPayments_Var_Pop_Fields>;
  gpanelPayments_var_pop_order_by: GpanelPayments_Var_Pop_Order_By;
  gpanelPayments_var_samp_fields: ResolverTypeWrapper<GpanelPayments_Var_Samp_Fields>;
  gpanelPayments_var_samp_order_by: GpanelPayments_Var_Samp_Order_By;
  gpanelPayments_variance_fields: ResolverTypeWrapper<GpanelPayments_Variance_Fields>;
  gpanelPayments_variance_order_by: GpanelPayments_Variance_Order_By;
  gpanelUsers: ResolverTypeWrapper<GpanelUsers>;
  gpanelUsers_aggregate: ResolverTypeWrapper<GpanelUsers_Aggregate>;
  gpanelUsers_aggregate_bool_exp: GpanelUsers_Aggregate_Bool_Exp;
  gpanelUsers_aggregate_bool_exp_bool_and: GpanelUsers_Aggregate_Bool_Exp_Bool_And;
  gpanelUsers_aggregate_bool_exp_bool_or: GpanelUsers_Aggregate_Bool_Exp_Bool_Or;
  gpanelUsers_aggregate_bool_exp_count: GpanelUsers_Aggregate_Bool_Exp_Count;
  gpanelUsers_aggregate_fields: ResolverTypeWrapper<GpanelUsers_Aggregate_Fields>;
  gpanelUsers_aggregate_order_by: GpanelUsers_Aggregate_Order_By;
  gpanelUsers_arr_rel_insert_input: GpanelUsers_Arr_Rel_Insert_Input;
  gpanelUsers_avg_fields: ResolverTypeWrapper<GpanelUsers_Avg_Fields>;
  gpanelUsers_avg_order_by: GpanelUsers_Avg_Order_By;
  gpanelUsers_bool_exp: GpanelUsers_Bool_Exp;
  gpanelUsers_constraint: GpanelUsers_Constraint;
  gpanelUsers_inc_input: GpanelUsers_Inc_Input;
  gpanelUsers_insert_input: GpanelUsers_Insert_Input;
  gpanelUsers_max_fields: ResolverTypeWrapper<GpanelUsers_Max_Fields>;
  gpanelUsers_max_order_by: GpanelUsers_Max_Order_By;
  gpanelUsers_min_fields: ResolverTypeWrapper<GpanelUsers_Min_Fields>;
  gpanelUsers_min_order_by: GpanelUsers_Min_Order_By;
  gpanelUsers_mutation_response: ResolverTypeWrapper<GpanelUsers_Mutation_Response>;
  gpanelUsers_obj_rel_insert_input: GpanelUsers_Obj_Rel_Insert_Input;
  gpanelUsers_on_conflict: GpanelUsers_On_Conflict;
  gpanelUsers_order_by: GpanelUsers_Order_By;
  gpanelUsers_pk_columns_input: GpanelUsers_Pk_Columns_Input;
  gpanelUsers_select_column: GpanelUsers_Select_Column;
  gpanelUsers_select_column_gpanelUsers_aggregate_bool_exp_bool_and_arguments_columns: GpanelUsers_Select_Column_GpanelUsers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  gpanelUsers_select_column_gpanelUsers_aggregate_bool_exp_bool_or_arguments_columns: GpanelUsers_Select_Column_GpanelUsers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  gpanelUsers_set_input: GpanelUsers_Set_Input;
  gpanelUsers_stddev_fields: ResolverTypeWrapper<GpanelUsers_Stddev_Fields>;
  gpanelUsers_stddev_order_by: GpanelUsers_Stddev_Order_By;
  gpanelUsers_stddev_pop_fields: ResolverTypeWrapper<GpanelUsers_Stddev_Pop_Fields>;
  gpanelUsers_stddev_pop_order_by: GpanelUsers_Stddev_Pop_Order_By;
  gpanelUsers_stddev_samp_fields: ResolverTypeWrapper<GpanelUsers_Stddev_Samp_Fields>;
  gpanelUsers_stddev_samp_order_by: GpanelUsers_Stddev_Samp_Order_By;
  gpanelUsers_stream_cursor_input: GpanelUsers_Stream_Cursor_Input;
  gpanelUsers_stream_cursor_value_input: GpanelUsers_Stream_Cursor_Value_Input;
  gpanelUsers_sum_fields: ResolverTypeWrapper<GpanelUsers_Sum_Fields>;
  gpanelUsers_sum_order_by: GpanelUsers_Sum_Order_By;
  gpanelUsers_update_column: GpanelUsers_Update_Column;
  gpanelUsers_updates: GpanelUsers_Updates;
  gpanelUsers_var_pop_fields: ResolverTypeWrapper<GpanelUsers_Var_Pop_Fields>;
  gpanelUsers_var_pop_order_by: GpanelUsers_Var_Pop_Order_By;
  gpanelUsers_var_samp_fields: ResolverTypeWrapper<GpanelUsers_Var_Samp_Fields>;
  gpanelUsers_var_samp_order_by: GpanelUsers_Var_Samp_Order_By;
  gpanelUsers_variance_fields: ResolverTypeWrapper<GpanelUsers_Variance_Fields>;
  gpanelUsers_variance_order_by: GpanelUsers_Variance_Order_By;
  gpanel_users_agent: ResolverTypeWrapper<Gpanel_Users_Agent>;
  gpanel_users_agent_aggregate: ResolverTypeWrapper<Gpanel_Users_Agent_Aggregate>;
  gpanel_users_agent_aggregate_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Aggregate_Fields>;
  gpanel_users_agent_avg_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Avg_Fields>;
  gpanel_users_agent_bool_exp: Gpanel_Users_Agent_Bool_Exp;
  gpanel_users_agent_constraint: Gpanel_Users_Agent_Constraint;
  gpanel_users_agent_inc_input: Gpanel_Users_Agent_Inc_Input;
  gpanel_users_agent_insert_input: Gpanel_Users_Agent_Insert_Input;
  gpanel_users_agent_max_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Max_Fields>;
  gpanel_users_agent_min_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Min_Fields>;
  gpanel_users_agent_mutation_response: ResolverTypeWrapper<Gpanel_Users_Agent_Mutation_Response>;
  gpanel_users_agent_on_conflict: Gpanel_Users_Agent_On_Conflict;
  gpanel_users_agent_order_by: Gpanel_Users_Agent_Order_By;
  gpanel_users_agent_pk_columns_input: Gpanel_Users_Agent_Pk_Columns_Input;
  gpanel_users_agent_select_column: Gpanel_Users_Agent_Select_Column;
  gpanel_users_agent_set_input: Gpanel_Users_Agent_Set_Input;
  gpanel_users_agent_stddev_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Stddev_Fields>;
  gpanel_users_agent_stddev_pop_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Stddev_Pop_Fields>;
  gpanel_users_agent_stddev_samp_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Stddev_Samp_Fields>;
  gpanel_users_agent_stream_cursor_input: Gpanel_Users_Agent_Stream_Cursor_Input;
  gpanel_users_agent_stream_cursor_value_input: Gpanel_Users_Agent_Stream_Cursor_Value_Input;
  gpanel_users_agent_sum_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Sum_Fields>;
  gpanel_users_agent_update_column: Gpanel_Users_Agent_Update_Column;
  gpanel_users_agent_updates: Gpanel_Users_Agent_Updates;
  gpanel_users_agent_var_pop_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Var_Pop_Fields>;
  gpanel_users_agent_var_samp_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Var_Samp_Fields>;
  gpanel_users_agent_variance_fields: ResolverTypeWrapper<Gpanel_Users_Agent_Variance_Fields>;
  gpanel_users_deposit_address: ResolverTypeWrapper<Gpanel_Users_Deposit_Address>;
  gpanel_users_deposit_address_aggregate: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Aggregate>;
  gpanel_users_deposit_address_aggregate_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Aggregate_Fields>;
  gpanel_users_deposit_address_avg_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Avg_Fields>;
  gpanel_users_deposit_address_bool_exp: Gpanel_Users_Deposit_Address_Bool_Exp;
  gpanel_users_deposit_address_constraint: Gpanel_Users_Deposit_Address_Constraint;
  gpanel_users_deposit_address_inc_input: Gpanel_Users_Deposit_Address_Inc_Input;
  gpanel_users_deposit_address_insert_input: Gpanel_Users_Deposit_Address_Insert_Input;
  gpanel_users_deposit_address_max_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Max_Fields>;
  gpanel_users_deposit_address_min_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Min_Fields>;
  gpanel_users_deposit_address_mutation_response: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Mutation_Response>;
  gpanel_users_deposit_address_on_conflict: Gpanel_Users_Deposit_Address_On_Conflict;
  gpanel_users_deposit_address_order_by: Gpanel_Users_Deposit_Address_Order_By;
  gpanel_users_deposit_address_pk_columns_input: Gpanel_Users_Deposit_Address_Pk_Columns_Input;
  gpanel_users_deposit_address_select_column: Gpanel_Users_Deposit_Address_Select_Column;
  gpanel_users_deposit_address_set_input: Gpanel_Users_Deposit_Address_Set_Input;
  gpanel_users_deposit_address_stddev_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Stddev_Fields>;
  gpanel_users_deposit_address_stddev_pop_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Stddev_Pop_Fields>;
  gpanel_users_deposit_address_stddev_samp_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Stddev_Samp_Fields>;
  gpanel_users_deposit_address_stream_cursor_input: Gpanel_Users_Deposit_Address_Stream_Cursor_Input;
  gpanel_users_deposit_address_stream_cursor_value_input: Gpanel_Users_Deposit_Address_Stream_Cursor_Value_Input;
  gpanel_users_deposit_address_sum_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Sum_Fields>;
  gpanel_users_deposit_address_update_column: Gpanel_Users_Deposit_Address_Update_Column;
  gpanel_users_deposit_address_updates: Gpanel_Users_Deposit_Address_Updates;
  gpanel_users_deposit_address_var_pop_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Var_Pop_Fields>;
  gpanel_users_deposit_address_var_samp_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Var_Samp_Fields>;
  gpanel_users_deposit_address_variance_fields: ResolverTypeWrapper<Gpanel_Users_Deposit_Address_Variance_Fields>;
  gpanel_users_kyc: ResolverTypeWrapper<Gpanel_Users_Kyc>;
  gpanel_users_kyc_aggregate: ResolverTypeWrapper<Gpanel_Users_Kyc_Aggregate>;
  gpanel_users_kyc_aggregate_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Aggregate_Fields>;
  gpanel_users_kyc_avg_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Avg_Fields>;
  gpanel_users_kyc_bool_exp: Gpanel_Users_Kyc_Bool_Exp;
  gpanel_users_kyc_constraint: Gpanel_Users_Kyc_Constraint;
  gpanel_users_kyc_inc_input: Gpanel_Users_Kyc_Inc_Input;
  gpanel_users_kyc_insert_input: Gpanel_Users_Kyc_Insert_Input;
  gpanel_users_kyc_max_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Max_Fields>;
  gpanel_users_kyc_min_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Min_Fields>;
  gpanel_users_kyc_mutation_response: ResolverTypeWrapper<Gpanel_Users_Kyc_Mutation_Response>;
  gpanel_users_kyc_on_conflict: Gpanel_Users_Kyc_On_Conflict;
  gpanel_users_kyc_order_by: Gpanel_Users_Kyc_Order_By;
  gpanel_users_kyc_pk_columns_input: Gpanel_Users_Kyc_Pk_Columns_Input;
  gpanel_users_kyc_select_column: Gpanel_Users_Kyc_Select_Column;
  gpanel_users_kyc_set_input: Gpanel_Users_Kyc_Set_Input;
  gpanel_users_kyc_stddev_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Stddev_Fields>;
  gpanel_users_kyc_stddev_pop_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Stddev_Pop_Fields>;
  gpanel_users_kyc_stddev_samp_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Stddev_Samp_Fields>;
  gpanel_users_kyc_stream_cursor_input: Gpanel_Users_Kyc_Stream_Cursor_Input;
  gpanel_users_kyc_stream_cursor_value_input: Gpanel_Users_Kyc_Stream_Cursor_Value_Input;
  gpanel_users_kyc_sum_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Sum_Fields>;
  gpanel_users_kyc_update_column: Gpanel_Users_Kyc_Update_Column;
  gpanel_users_kyc_updates: Gpanel_Users_Kyc_Updates;
  gpanel_users_kyc_var_pop_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Var_Pop_Fields>;
  gpanel_users_kyc_var_samp_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Var_Samp_Fields>;
  gpanel_users_kyc_variance_fields: ResolverTypeWrapper<Gpanel_Users_Kyc_Variance_Fields>;
  json: ResolverTypeWrapper<Scalars['json']>;
  json_comparison_exp: Json_Comparison_Exp;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>;
  jsonb_cast_exp: Jsonb_Cast_Exp;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  kycOutput: ResolverTypeWrapper<KycOutput>;
  kycs: ResolverTypeWrapper<Kycs>;
  kycs_aggregate: ResolverTypeWrapper<Kycs_Aggregate>;
  kycs_aggregate_fields: ResolverTypeWrapper<Kycs_Aggregate_Fields>;
  kycs_avg_fields: ResolverTypeWrapper<Kycs_Avg_Fields>;
  kycs_bool_exp: Kycs_Bool_Exp;
  kycs_constraint: Kycs_Constraint;
  kycs_inc_input: Kycs_Inc_Input;
  kycs_insert_input: Kycs_Insert_Input;
  kycs_max_fields: ResolverTypeWrapper<Kycs_Max_Fields>;
  kycs_min_fields: ResolverTypeWrapper<Kycs_Min_Fields>;
  kycs_mutation_response: ResolverTypeWrapper<Kycs_Mutation_Response>;
  kycs_obj_rel_insert_input: Kycs_Obj_Rel_Insert_Input;
  kycs_on_conflict: Kycs_On_Conflict;
  kycs_order_by: Kycs_Order_By;
  kycs_pk_columns_input: Kycs_Pk_Columns_Input;
  kycs_select_column: Kycs_Select_Column;
  kycs_set_input: Kycs_Set_Input;
  kycs_stddev_fields: ResolverTypeWrapper<Kycs_Stddev_Fields>;
  kycs_stddev_pop_fields: ResolverTypeWrapper<Kycs_Stddev_Pop_Fields>;
  kycs_stddev_samp_fields: ResolverTypeWrapper<Kycs_Stddev_Samp_Fields>;
  kycs_stream_cursor_input: Kycs_Stream_Cursor_Input;
  kycs_stream_cursor_value_input: Kycs_Stream_Cursor_Value_Input;
  kycs_sum_fields: ResolverTypeWrapper<Kycs_Sum_Fields>;
  kycs_update_column: Kycs_Update_Column;
  kycs_updates: Kycs_Updates;
  kycs_var_pop_fields: ResolverTypeWrapper<Kycs_Var_Pop_Fields>;
  kycs_var_samp_fields: ResolverTypeWrapper<Kycs_Var_Samp_Fields>;
  kycs_variance_fields: ResolverTypeWrapper<Kycs_Variance_Fields>;
  leaderboard: ResolverTypeWrapper<Leaderboard>;
  leaderboard_aggregate: ResolverTypeWrapper<Leaderboard_Aggregate>;
  leaderboard_aggregate_fields: ResolverTypeWrapper<Leaderboard_Aggregate_Fields>;
  leaderboard_avg_fields: ResolverTypeWrapper<Leaderboard_Avg_Fields>;
  leaderboard_bool_exp: Leaderboard_Bool_Exp;
  leaderboard_constraint: Leaderboard_Constraint;
  leaderboard_inc_input: Leaderboard_Inc_Input;
  leaderboard_insert_input: Leaderboard_Insert_Input;
  leaderboard_max_fields: ResolverTypeWrapper<Leaderboard_Max_Fields>;
  leaderboard_min_fields: ResolverTypeWrapper<Leaderboard_Min_Fields>;
  leaderboard_mutation_response: ResolverTypeWrapper<Leaderboard_Mutation_Response>;
  leaderboard_on_conflict: Leaderboard_On_Conflict;
  leaderboard_order_by: Leaderboard_Order_By;
  leaderboard_pk_columns_input: Leaderboard_Pk_Columns_Input;
  leaderboard_ranking: ResolverTypeWrapper<Leaderboard_Ranking>;
  leaderboard_ranking_aggregate: ResolverTypeWrapper<Leaderboard_Ranking_Aggregate>;
  leaderboard_ranking_aggregate_fields: ResolverTypeWrapper<Leaderboard_Ranking_Aggregate_Fields>;
  leaderboard_ranking_avg_fields: ResolverTypeWrapper<Leaderboard_Ranking_Avg_Fields>;
  leaderboard_ranking_bool_exp: Leaderboard_Ranking_Bool_Exp;
  leaderboard_ranking_constraint: Leaderboard_Ranking_Constraint;
  leaderboard_ranking_inc_input: Leaderboard_Ranking_Inc_Input;
  leaderboard_ranking_insert_input: Leaderboard_Ranking_Insert_Input;
  leaderboard_ranking_max_fields: ResolverTypeWrapper<Leaderboard_Ranking_Max_Fields>;
  leaderboard_ranking_min_fields: ResolverTypeWrapper<Leaderboard_Ranking_Min_Fields>;
  leaderboard_ranking_mutation_response: ResolverTypeWrapper<Leaderboard_Ranking_Mutation_Response>;
  leaderboard_ranking_on_conflict: Leaderboard_Ranking_On_Conflict;
  leaderboard_ranking_order_by: Leaderboard_Ranking_Order_By;
  leaderboard_ranking_pk_columns_input: Leaderboard_Ranking_Pk_Columns_Input;
  leaderboard_ranking_select_column: Leaderboard_Ranking_Select_Column;
  leaderboard_ranking_set_input: Leaderboard_Ranking_Set_Input;
  leaderboard_ranking_stddev_fields: ResolverTypeWrapper<Leaderboard_Ranking_Stddev_Fields>;
  leaderboard_ranking_stddev_pop_fields: ResolverTypeWrapper<Leaderboard_Ranking_Stddev_Pop_Fields>;
  leaderboard_ranking_stddev_samp_fields: ResolverTypeWrapper<Leaderboard_Ranking_Stddev_Samp_Fields>;
  leaderboard_ranking_stream_cursor_input: Leaderboard_Ranking_Stream_Cursor_Input;
  leaderboard_ranking_stream_cursor_value_input: Leaderboard_Ranking_Stream_Cursor_Value_Input;
  leaderboard_ranking_sum_fields: ResolverTypeWrapper<Leaderboard_Ranking_Sum_Fields>;
  leaderboard_ranking_update_column: Leaderboard_Ranking_Update_Column;
  leaderboard_ranking_updates: Leaderboard_Ranking_Updates;
  leaderboard_ranking_var_pop_fields: ResolverTypeWrapper<Leaderboard_Ranking_Var_Pop_Fields>;
  leaderboard_ranking_var_samp_fields: ResolverTypeWrapper<Leaderboard_Ranking_Var_Samp_Fields>;
  leaderboard_ranking_variance_fields: ResolverTypeWrapper<Leaderboard_Ranking_Variance_Fields>;
  leaderboard_select_column: Leaderboard_Select_Column;
  leaderboard_set_input: Leaderboard_Set_Input;
  leaderboard_stddev_fields: ResolverTypeWrapper<Leaderboard_Stddev_Fields>;
  leaderboard_stddev_pop_fields: ResolverTypeWrapper<Leaderboard_Stddev_Pop_Fields>;
  leaderboard_stddev_samp_fields: ResolverTypeWrapper<Leaderboard_Stddev_Samp_Fields>;
  leaderboard_stream_cursor_input: Leaderboard_Stream_Cursor_Input;
  leaderboard_stream_cursor_value_input: Leaderboard_Stream_Cursor_Value_Input;
  leaderboard_sum_fields: ResolverTypeWrapper<Leaderboard_Sum_Fields>;
  leaderboard_update_column: Leaderboard_Update_Column;
  leaderboard_updates: Leaderboard_Updates;
  leaderboard_var_pop_fields: ResolverTypeWrapper<Leaderboard_Var_Pop_Fields>;
  leaderboard_var_samp_fields: ResolverTypeWrapper<Leaderboard_Var_Samp_Fields>;
  leaderboard_variance_fields: ResolverTypeWrapper<Leaderboard_Variance_Fields>;
  liquidity_pools: ResolverTypeWrapper<Liquidity_Pools>;
  liquidity_pools_aggregate: ResolverTypeWrapper<Liquidity_Pools_Aggregate>;
  liquidity_pools_aggregate_fields: ResolverTypeWrapper<Liquidity_Pools_Aggregate_Fields>;
  liquidity_pools_avg_fields: ResolverTypeWrapper<Liquidity_Pools_Avg_Fields>;
  liquidity_pools_bool_exp: Liquidity_Pools_Bool_Exp;
  liquidity_pools_constraint: Liquidity_Pools_Constraint;
  liquidity_pools_inc_input: Liquidity_Pools_Inc_Input;
  liquidity_pools_insert_input: Liquidity_Pools_Insert_Input;
  liquidity_pools_max_fields: ResolverTypeWrapper<Liquidity_Pools_Max_Fields>;
  liquidity_pools_min_fields: ResolverTypeWrapper<Liquidity_Pools_Min_Fields>;
  liquidity_pools_mutation_response: ResolverTypeWrapper<Liquidity_Pools_Mutation_Response>;
  liquidity_pools_on_conflict: Liquidity_Pools_On_Conflict;
  liquidity_pools_order_by: Liquidity_Pools_Order_By;
  liquidity_pools_pk_columns_input: Liquidity_Pools_Pk_Columns_Input;
  liquidity_pools_select_column: Liquidity_Pools_Select_Column;
  liquidity_pools_set_input: Liquidity_Pools_Set_Input;
  liquidity_pools_stddev_fields: ResolverTypeWrapper<Liquidity_Pools_Stddev_Fields>;
  liquidity_pools_stddev_pop_fields: ResolverTypeWrapper<Liquidity_Pools_Stddev_Pop_Fields>;
  liquidity_pools_stddev_samp_fields: ResolverTypeWrapper<Liquidity_Pools_Stddev_Samp_Fields>;
  liquidity_pools_stream_cursor_input: Liquidity_Pools_Stream_Cursor_Input;
  liquidity_pools_stream_cursor_value_input: Liquidity_Pools_Stream_Cursor_Value_Input;
  liquidity_pools_sum_fields: ResolverTypeWrapper<Liquidity_Pools_Sum_Fields>;
  liquidity_pools_update_column: Liquidity_Pools_Update_Column;
  liquidity_pools_updates: Liquidity_Pools_Updates;
  liquidity_pools_var_pop_fields: ResolverTypeWrapper<Liquidity_Pools_Var_Pop_Fields>;
  liquidity_pools_var_samp_fields: ResolverTypeWrapper<Liquidity_Pools_Var_Samp_Fields>;
  liquidity_pools_variance_fields: ResolverTypeWrapper<Liquidity_Pools_Variance_Fields>;
  marketListings: ResolverTypeWrapper<MarketListings>;
  marketListings_aggregate: ResolverTypeWrapper<MarketListings_Aggregate>;
  marketListings_aggregate_fields: ResolverTypeWrapper<MarketListings_Aggregate_Fields>;
  marketListings_avg_fields: ResolverTypeWrapper<MarketListings_Avg_Fields>;
  marketListings_bool_exp: MarketListings_Bool_Exp;
  marketListings_constraint: MarketListings_Constraint;
  marketListings_inc_input: MarketListings_Inc_Input;
  marketListings_insert_input: MarketListings_Insert_Input;
  marketListings_max_fields: ResolverTypeWrapper<MarketListings_Max_Fields>;
  marketListings_min_fields: ResolverTypeWrapper<MarketListings_Min_Fields>;
  marketListings_mutation_response: ResolverTypeWrapper<MarketListings_Mutation_Response>;
  marketListings_obj_rel_insert_input: MarketListings_Obj_Rel_Insert_Input;
  marketListings_on_conflict: MarketListings_On_Conflict;
  marketListings_order_by: MarketListings_Order_By;
  marketListings_pk_columns_input: MarketListings_Pk_Columns_Input;
  marketListings_select_column: MarketListings_Select_Column;
  marketListings_set_input: MarketListings_Set_Input;
  marketListings_stddev_fields: ResolverTypeWrapper<MarketListings_Stddev_Fields>;
  marketListings_stddev_pop_fields: ResolverTypeWrapper<MarketListings_Stddev_Pop_Fields>;
  marketListings_stddev_samp_fields: ResolverTypeWrapper<MarketListings_Stddev_Samp_Fields>;
  marketListings_stream_cursor_input: MarketListings_Stream_Cursor_Input;
  marketListings_stream_cursor_value_input: MarketListings_Stream_Cursor_Value_Input;
  marketListings_sum_fields: ResolverTypeWrapper<MarketListings_Sum_Fields>;
  marketListings_update_column: MarketListings_Update_Column;
  marketListings_updates: MarketListings_Updates;
  marketListings_var_pop_fields: ResolverTypeWrapper<MarketListings_Var_Pop_Fields>;
  marketListings_var_samp_fields: ResolverTypeWrapper<MarketListings_Var_Samp_Fields>;
  marketListings_variance_fields: ResolverTypeWrapper<MarketListings_Variance_Fields>;
  marketplaceContracts: ResolverTypeWrapper<MarketplaceContracts>;
  marketplaceContracts_aggregate: ResolverTypeWrapper<MarketplaceContracts_Aggregate>;
  marketplaceContracts_aggregate_fields: ResolverTypeWrapper<MarketplaceContracts_Aggregate_Fields>;
  marketplaceContracts_avg_fields: ResolverTypeWrapper<MarketplaceContracts_Avg_Fields>;
  marketplaceContracts_bool_exp: MarketplaceContracts_Bool_Exp;
  marketplaceContracts_constraint: MarketplaceContracts_Constraint;
  marketplaceContracts_inc_input: MarketplaceContracts_Inc_Input;
  marketplaceContracts_insert_input: MarketplaceContracts_Insert_Input;
  marketplaceContracts_max_fields: ResolverTypeWrapper<MarketplaceContracts_Max_Fields>;
  marketplaceContracts_min_fields: ResolverTypeWrapper<MarketplaceContracts_Min_Fields>;
  marketplaceContracts_mutation_response: ResolverTypeWrapper<MarketplaceContracts_Mutation_Response>;
  marketplaceContracts_obj_rel_insert_input: MarketplaceContracts_Obj_Rel_Insert_Input;
  marketplaceContracts_on_conflict: MarketplaceContracts_On_Conflict;
  marketplaceContracts_order_by: MarketplaceContracts_Order_By;
  marketplaceContracts_pk_columns_input: MarketplaceContracts_Pk_Columns_Input;
  marketplaceContracts_select_column: MarketplaceContracts_Select_Column;
  marketplaceContracts_set_input: MarketplaceContracts_Set_Input;
  marketplaceContracts_stddev_fields: ResolverTypeWrapper<MarketplaceContracts_Stddev_Fields>;
  marketplaceContracts_stddev_pop_fields: ResolverTypeWrapper<MarketplaceContracts_Stddev_Pop_Fields>;
  marketplaceContracts_stddev_samp_fields: ResolverTypeWrapper<MarketplaceContracts_Stddev_Samp_Fields>;
  marketplaceContracts_stream_cursor_input: MarketplaceContracts_Stream_Cursor_Input;
  marketplaceContracts_stream_cursor_value_input: MarketplaceContracts_Stream_Cursor_Value_Input;
  marketplaceContracts_sum_fields: ResolverTypeWrapper<MarketplaceContracts_Sum_Fields>;
  marketplaceContracts_update_column: MarketplaceContracts_Update_Column;
  marketplaceContracts_updates: MarketplaceContracts_Updates;
  marketplaceContracts_var_pop_fields: ResolverTypeWrapper<MarketplaceContracts_Var_Pop_Fields>;
  marketplaceContracts_var_samp_fields: ResolverTypeWrapper<MarketplaceContracts_Var_Samp_Fields>;
  marketplaceContracts_variance_fields: ResolverTypeWrapper<MarketplaceContracts_Variance_Fields>;
  mutation_root: ResolverTypeWrapper<{}>;
  nftContracts: ResolverTypeWrapper<NftContracts>;
  nftContracts_aggregate: ResolverTypeWrapper<NftContracts_Aggregate>;
  nftContracts_aggregate_fields: ResolverTypeWrapper<NftContracts_Aggregate_Fields>;
  nftContracts_avg_fields: ResolverTypeWrapper<NftContracts_Avg_Fields>;
  nftContracts_bool_exp: NftContracts_Bool_Exp;
  nftContracts_constraint: NftContracts_Constraint;
  nftContracts_inc_input: NftContracts_Inc_Input;
  nftContracts_insert_input: NftContracts_Insert_Input;
  nftContracts_max_fields: ResolverTypeWrapper<NftContracts_Max_Fields>;
  nftContracts_min_fields: ResolverTypeWrapper<NftContracts_Min_Fields>;
  nftContracts_mutation_response: ResolverTypeWrapper<NftContracts_Mutation_Response>;
  nftContracts_obj_rel_insert_input: NftContracts_Obj_Rel_Insert_Input;
  nftContracts_on_conflict: NftContracts_On_Conflict;
  nftContracts_order_by: NftContracts_Order_By;
  nftContracts_pk_columns_input: NftContracts_Pk_Columns_Input;
  nftContracts_select_column: NftContracts_Select_Column;
  nftContracts_set_input: NftContracts_Set_Input;
  nftContracts_stddev_fields: ResolverTypeWrapper<NftContracts_Stddev_Fields>;
  nftContracts_stddev_pop_fields: ResolverTypeWrapper<NftContracts_Stddev_Pop_Fields>;
  nftContracts_stddev_samp_fields: ResolverTypeWrapper<NftContracts_Stddev_Samp_Fields>;
  nftContracts_stream_cursor_input: NftContracts_Stream_Cursor_Input;
  nftContracts_stream_cursor_value_input: NftContracts_Stream_Cursor_Value_Input;
  nftContracts_sum_fields: ResolverTypeWrapper<NftContracts_Sum_Fields>;
  nftContracts_update_column: NftContracts_Update_Column;
  nftContracts_updates: NftContracts_Updates;
  nftContracts_var_pop_fields: ResolverTypeWrapper<NftContracts_Var_Pop_Fields>;
  nftContracts_var_samp_fields: ResolverTypeWrapper<NftContracts_Var_Samp_Fields>;
  nftContracts_variance_fields: ResolverTypeWrapper<NftContracts_Variance_Fields>;
  nftMetadatas: ResolverTypeWrapper<NftMetadatas>;
  nftMetadatas_aggregate: ResolverTypeWrapper<NftMetadatas_Aggregate>;
  nftMetadatas_aggregate_fields: ResolverTypeWrapper<NftMetadatas_Aggregate_Fields>;
  nftMetadatas_avg_fields: ResolverTypeWrapper<NftMetadatas_Avg_Fields>;
  nftMetadatas_bool_exp: NftMetadatas_Bool_Exp;
  nftMetadatas_constraint: NftMetadatas_Constraint;
  nftMetadatas_inc_input: NftMetadatas_Inc_Input;
  nftMetadatas_insert_input: NftMetadatas_Insert_Input;
  nftMetadatas_max_fields: ResolverTypeWrapper<NftMetadatas_Max_Fields>;
  nftMetadatas_min_fields: ResolverTypeWrapper<NftMetadatas_Min_Fields>;
  nftMetadatas_mutation_response: ResolverTypeWrapper<NftMetadatas_Mutation_Response>;
  nftMetadatas_obj_rel_insert_input: NftMetadatas_Obj_Rel_Insert_Input;
  nftMetadatas_on_conflict: NftMetadatas_On_Conflict;
  nftMetadatas_order_by: NftMetadatas_Order_By;
  nftMetadatas_pk_columns_input: NftMetadatas_Pk_Columns_Input;
  nftMetadatas_select_column: NftMetadatas_Select_Column;
  nftMetadatas_set_input: NftMetadatas_Set_Input;
  nftMetadatas_stddev_fields: ResolverTypeWrapper<NftMetadatas_Stddev_Fields>;
  nftMetadatas_stddev_pop_fields: ResolverTypeWrapper<NftMetadatas_Stddev_Pop_Fields>;
  nftMetadatas_stddev_samp_fields: ResolverTypeWrapper<NftMetadatas_Stddev_Samp_Fields>;
  nftMetadatas_stream_cursor_input: NftMetadatas_Stream_Cursor_Input;
  nftMetadatas_stream_cursor_value_input: NftMetadatas_Stream_Cursor_Value_Input;
  nftMetadatas_sum_fields: ResolverTypeWrapper<NftMetadatas_Sum_Fields>;
  nftMetadatas_update_column: NftMetadatas_Update_Column;
  nftMetadatas_updates: NftMetadatas_Updates;
  nftMetadatas_var_pop_fields: ResolverTypeWrapper<NftMetadatas_Var_Pop_Fields>;
  nftMetadatas_var_samp_fields: ResolverTypeWrapper<NftMetadatas_Var_Samp_Fields>;
  nftMetadatas_variance_fields: ResolverTypeWrapper<NftMetadatas_Variance_Fields>;
  nftOwners: ResolverTypeWrapper<NftOwners>;
  nftOwners_aggregate: ResolverTypeWrapper<NftOwners_Aggregate>;
  nftOwners_aggregate_bool_exp: NftOwners_Aggregate_Bool_Exp;
  nftOwners_aggregate_bool_exp_bool_and: NftOwners_Aggregate_Bool_Exp_Bool_And;
  nftOwners_aggregate_bool_exp_bool_or: NftOwners_Aggregate_Bool_Exp_Bool_Or;
  nftOwners_aggregate_bool_exp_count: NftOwners_Aggregate_Bool_Exp_Count;
  nftOwners_aggregate_fields: ResolverTypeWrapper<NftOwners_Aggregate_Fields>;
  nftOwners_aggregate_order_by: NftOwners_Aggregate_Order_By;
  nftOwners_arr_rel_insert_input: NftOwners_Arr_Rel_Insert_Input;
  nftOwners_avg_fields: ResolverTypeWrapper<NftOwners_Avg_Fields>;
  nftOwners_avg_order_by: NftOwners_Avg_Order_By;
  nftOwners_bool_exp: NftOwners_Bool_Exp;
  nftOwners_constraint: NftOwners_Constraint;
  nftOwners_inc_input: NftOwners_Inc_Input;
  nftOwners_insert_input: NftOwners_Insert_Input;
  nftOwners_max_fields: ResolverTypeWrapper<NftOwners_Max_Fields>;
  nftOwners_max_order_by: NftOwners_Max_Order_By;
  nftOwners_min_fields: ResolverTypeWrapper<NftOwners_Min_Fields>;
  nftOwners_min_order_by: NftOwners_Min_Order_By;
  nftOwners_mutation_response: ResolverTypeWrapper<NftOwners_Mutation_Response>;
  nftOwners_obj_rel_insert_input: NftOwners_Obj_Rel_Insert_Input;
  nftOwners_on_conflict: NftOwners_On_Conflict;
  nftOwners_order_by: NftOwners_Order_By;
  nftOwners_pk_columns_input: NftOwners_Pk_Columns_Input;
  nftOwners_select_column: NftOwners_Select_Column;
  nftOwners_select_column_nftOwners_aggregate_bool_exp_bool_and_arguments_columns: NftOwners_Select_Column_NftOwners_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  nftOwners_select_column_nftOwners_aggregate_bool_exp_bool_or_arguments_columns: NftOwners_Select_Column_NftOwners_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  nftOwners_set_input: NftOwners_Set_Input;
  nftOwners_stddev_fields: ResolverTypeWrapper<NftOwners_Stddev_Fields>;
  nftOwners_stddev_order_by: NftOwners_Stddev_Order_By;
  nftOwners_stddev_pop_fields: ResolverTypeWrapper<NftOwners_Stddev_Pop_Fields>;
  nftOwners_stddev_pop_order_by: NftOwners_Stddev_Pop_Order_By;
  nftOwners_stddev_samp_fields: ResolverTypeWrapper<NftOwners_Stddev_Samp_Fields>;
  nftOwners_stddev_samp_order_by: NftOwners_Stddev_Samp_Order_By;
  nftOwners_stream_cursor_input: NftOwners_Stream_Cursor_Input;
  nftOwners_stream_cursor_value_input: NftOwners_Stream_Cursor_Value_Input;
  nftOwners_sum_fields: ResolverTypeWrapper<NftOwners_Sum_Fields>;
  nftOwners_sum_order_by: NftOwners_Sum_Order_By;
  nftOwners_update_column: NftOwners_Update_Column;
  nftOwners_updates: NftOwners_Updates;
  nftOwners_var_pop_fields: ResolverTypeWrapper<NftOwners_Var_Pop_Fields>;
  nftOwners_var_pop_order_by: NftOwners_Var_Pop_Order_By;
  nftOwners_var_samp_fields: ResolverTypeWrapper<NftOwners_Var_Samp_Fields>;
  nftOwners_var_samp_order_by: NftOwners_Var_Samp_Order_By;
  nftOwners_variance_fields: ResolverTypeWrapper<NftOwners_Variance_Fields>;
  nftOwners_variance_order_by: NftOwners_Variance_Order_By;
  nft_gpanel_users: ResolverTypeWrapper<Nft_Gpanel_Users>;
  nft_gpanel_users_aggregate: ResolverTypeWrapper<Nft_Gpanel_Users_Aggregate>;
  nft_gpanel_users_aggregate_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Aggregate_Fields>;
  nft_gpanel_users_avg_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Avg_Fields>;
  nft_gpanel_users_bool_exp: Nft_Gpanel_Users_Bool_Exp;
  nft_gpanel_users_constraint: Nft_Gpanel_Users_Constraint;
  nft_gpanel_users_inc_input: Nft_Gpanel_Users_Inc_Input;
  nft_gpanel_users_insert_input: Nft_Gpanel_Users_Insert_Input;
  nft_gpanel_users_max_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Max_Fields>;
  nft_gpanel_users_min_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Min_Fields>;
  nft_gpanel_users_mutation_response: ResolverTypeWrapper<Nft_Gpanel_Users_Mutation_Response>;
  nft_gpanel_users_obj_rel_insert_input: Nft_Gpanel_Users_Obj_Rel_Insert_Input;
  nft_gpanel_users_on_conflict: Nft_Gpanel_Users_On_Conflict;
  nft_gpanel_users_order_by: Nft_Gpanel_Users_Order_By;
  nft_gpanel_users_pk_columns_input: Nft_Gpanel_Users_Pk_Columns_Input;
  nft_gpanel_users_select_column: Nft_Gpanel_Users_Select_Column;
  nft_gpanel_users_set_input: Nft_Gpanel_Users_Set_Input;
  nft_gpanel_users_stddev_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Stddev_Fields>;
  nft_gpanel_users_stddev_pop_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Stddev_Pop_Fields>;
  nft_gpanel_users_stddev_samp_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Stddev_Samp_Fields>;
  nft_gpanel_users_stream_cursor_input: Nft_Gpanel_Users_Stream_Cursor_Input;
  nft_gpanel_users_stream_cursor_value_input: Nft_Gpanel_Users_Stream_Cursor_Value_Input;
  nft_gpanel_users_sum_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Sum_Fields>;
  nft_gpanel_users_update_column: Nft_Gpanel_Users_Update_Column;
  nft_gpanel_users_updates: Nft_Gpanel_Users_Updates;
  nft_gpanel_users_var_pop_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Var_Pop_Fields>;
  nft_gpanel_users_var_samp_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Var_Samp_Fields>;
  nft_gpanel_users_variance_fields: ResolverTypeWrapper<Nft_Gpanel_Users_Variance_Fields>;
  nft_listings: ResolverTypeWrapper<Nft_Listings>;
  nft_listings_aggregate: ResolverTypeWrapper<Nft_Listings_Aggregate>;
  nft_listings_aggregate_fields: ResolverTypeWrapper<Nft_Listings_Aggregate_Fields>;
  nft_listings_avg_fields: ResolverTypeWrapper<Nft_Listings_Avg_Fields>;
  nft_listings_bool_exp: Nft_Listings_Bool_Exp;
  nft_listings_constraint: Nft_Listings_Constraint;
  nft_listings_inc_input: Nft_Listings_Inc_Input;
  nft_listings_insert_input: Nft_Listings_Insert_Input;
  nft_listings_max_fields: ResolverTypeWrapper<Nft_Listings_Max_Fields>;
  nft_listings_min_fields: ResolverTypeWrapper<Nft_Listings_Min_Fields>;
  nft_listings_mutation_response: ResolverTypeWrapper<Nft_Listings_Mutation_Response>;
  nft_listings_on_conflict: Nft_Listings_On_Conflict;
  nft_listings_order_by: Nft_Listings_Order_By;
  nft_listings_pk_columns_input: Nft_Listings_Pk_Columns_Input;
  nft_listings_select_column: Nft_Listings_Select_Column;
  nft_listings_set_input: Nft_Listings_Set_Input;
  nft_listings_stddev_fields: ResolverTypeWrapper<Nft_Listings_Stddev_Fields>;
  nft_listings_stddev_pop_fields: ResolverTypeWrapper<Nft_Listings_Stddev_Pop_Fields>;
  nft_listings_stddev_samp_fields: ResolverTypeWrapper<Nft_Listings_Stddev_Samp_Fields>;
  nft_listings_stream_cursor_input: Nft_Listings_Stream_Cursor_Input;
  nft_listings_stream_cursor_value_input: Nft_Listings_Stream_Cursor_Value_Input;
  nft_listings_sum_fields: ResolverTypeWrapper<Nft_Listings_Sum_Fields>;
  nft_listings_update_column: Nft_Listings_Update_Column;
  nft_listings_updates: Nft_Listings_Updates;
  nft_listings_var_pop_fields: ResolverTypeWrapper<Nft_Listings_Var_Pop_Fields>;
  nft_listings_var_samp_fields: ResolverTypeWrapper<Nft_Listings_Var_Samp_Fields>;
  nft_listings_variance_fields: ResolverTypeWrapper<Nft_Listings_Variance_Fields>;
  nft_transactions: ResolverTypeWrapper<Nft_Transactions>;
  nft_transactions_aggregate: ResolverTypeWrapper<Nft_Transactions_Aggregate>;
  nft_transactions_aggregate_fields: ResolverTypeWrapper<Nft_Transactions_Aggregate_Fields>;
  nft_transactions_avg_fields: ResolverTypeWrapper<Nft_Transactions_Avg_Fields>;
  nft_transactions_bool_exp: Nft_Transactions_Bool_Exp;
  nft_transactions_constraint: Nft_Transactions_Constraint;
  nft_transactions_inc_input: Nft_Transactions_Inc_Input;
  nft_transactions_insert_input: Nft_Transactions_Insert_Input;
  nft_transactions_max_fields: ResolverTypeWrapper<Nft_Transactions_Max_Fields>;
  nft_transactions_min_fields: ResolverTypeWrapper<Nft_Transactions_Min_Fields>;
  nft_transactions_mutation_response: ResolverTypeWrapper<Nft_Transactions_Mutation_Response>;
  nft_transactions_on_conflict: Nft_Transactions_On_Conflict;
  nft_transactions_order_by: Nft_Transactions_Order_By;
  nft_transactions_pk_columns_input: Nft_Transactions_Pk_Columns_Input;
  nft_transactions_select_column: Nft_Transactions_Select_Column;
  nft_transactions_set_input: Nft_Transactions_Set_Input;
  nft_transactions_stddev_fields: ResolverTypeWrapper<Nft_Transactions_Stddev_Fields>;
  nft_transactions_stddev_pop_fields: ResolverTypeWrapper<Nft_Transactions_Stddev_Pop_Fields>;
  nft_transactions_stddev_samp_fields: ResolverTypeWrapper<Nft_Transactions_Stddev_Samp_Fields>;
  nft_transactions_stream_cursor_input: Nft_Transactions_Stream_Cursor_Input;
  nft_transactions_stream_cursor_value_input: Nft_Transactions_Stream_Cursor_Value_Input;
  nft_transactions_sum_fields: ResolverTypeWrapper<Nft_Transactions_Sum_Fields>;
  nft_transactions_update_column: Nft_Transactions_Update_Column;
  nft_transactions_updates: Nft_Transactions_Updates;
  nft_transactions_var_pop_fields: ResolverTypeWrapper<Nft_Transactions_Var_Pop_Fields>;
  nft_transactions_var_samp_fields: ResolverTypeWrapper<Nft_Transactions_Var_Samp_Fields>;
  nft_transactions_variance_fields: ResolverTypeWrapper<Nft_Transactions_Variance_Fields>;
  nfts: ResolverTypeWrapper<Nfts>;
  nfts_aggregate: ResolverTypeWrapper<Nfts_Aggregate>;
  nfts_aggregate_fields: ResolverTypeWrapper<Nfts_Aggregate_Fields>;
  nfts_avg_fields: ResolverTypeWrapper<Nfts_Avg_Fields>;
  nfts_bool_exp: Nfts_Bool_Exp;
  nfts_constraint: Nfts_Constraint;
  nfts_inc_input: Nfts_Inc_Input;
  nfts_insert_input: Nfts_Insert_Input;
  nfts_max_fields: ResolverTypeWrapper<Nfts_Max_Fields>;
  nfts_min_fields: ResolverTypeWrapper<Nfts_Min_Fields>;
  nfts_mutation_response: ResolverTypeWrapper<Nfts_Mutation_Response>;
  nfts_obj_rel_insert_input: Nfts_Obj_Rel_Insert_Input;
  nfts_on_conflict: Nfts_On_Conflict;
  nfts_order_by: Nfts_Order_By;
  nfts_pk_columns_input: Nfts_Pk_Columns_Input;
  nfts_select_column: Nfts_Select_Column;
  nfts_set_input: Nfts_Set_Input;
  nfts_stddev_fields: ResolverTypeWrapper<Nfts_Stddev_Fields>;
  nfts_stddev_pop_fields: ResolverTypeWrapper<Nfts_Stddev_Pop_Fields>;
  nfts_stddev_samp_fields: ResolverTypeWrapper<Nfts_Stddev_Samp_Fields>;
  nfts_stream_cursor_input: Nfts_Stream_Cursor_Input;
  nfts_stream_cursor_value_input: Nfts_Stream_Cursor_Value_Input;
  nfts_sum_fields: ResolverTypeWrapper<Nfts_Sum_Fields>;
  nfts_update_column: Nfts_Update_Column;
  nfts_updates: Nfts_Updates;
  nfts_var_pop_fields: ResolverTypeWrapper<Nfts_Var_Pop_Fields>;
  nfts_var_samp_fields: ResolverTypeWrapper<Nfts_Var_Samp_Fields>;
  nfts_variance_fields: ResolverTypeWrapper<Nfts_Variance_Fields>;
  numeric: ResolverTypeWrapper<Scalars['numeric']>;
  numeric_comparison_exp: Numeric_Comparison_Exp;
  order_by: Order_By;
  pendingTransactions: ResolverTypeWrapper<PendingTransactions>;
  pendingTransactions_aggregate: ResolverTypeWrapper<PendingTransactions_Aggregate>;
  pendingTransactions_aggregate_bool_exp: PendingTransactions_Aggregate_Bool_Exp;
  pendingTransactions_aggregate_bool_exp_bool_and: PendingTransactions_Aggregate_Bool_Exp_Bool_And;
  pendingTransactions_aggregate_bool_exp_bool_or: PendingTransactions_Aggregate_Bool_Exp_Bool_Or;
  pendingTransactions_aggregate_bool_exp_count: PendingTransactions_Aggregate_Bool_Exp_Count;
  pendingTransactions_aggregate_fields: ResolverTypeWrapper<PendingTransactions_Aggregate_Fields>;
  pendingTransactions_aggregate_order_by: PendingTransactions_Aggregate_Order_By;
  pendingTransactions_arr_rel_insert_input: PendingTransactions_Arr_Rel_Insert_Input;
  pendingTransactions_avg_fields: ResolverTypeWrapper<PendingTransactions_Avg_Fields>;
  pendingTransactions_avg_order_by: PendingTransactions_Avg_Order_By;
  pendingTransactions_bool_exp: PendingTransactions_Bool_Exp;
  pendingTransactions_constraint: PendingTransactions_Constraint;
  pendingTransactions_inc_input: PendingTransactions_Inc_Input;
  pendingTransactions_insert_input: PendingTransactions_Insert_Input;
  pendingTransactions_max_fields: ResolverTypeWrapper<PendingTransactions_Max_Fields>;
  pendingTransactions_max_order_by: PendingTransactions_Max_Order_By;
  pendingTransactions_min_fields: ResolverTypeWrapper<PendingTransactions_Min_Fields>;
  pendingTransactions_min_order_by: PendingTransactions_Min_Order_By;
  pendingTransactions_mutation_response: ResolverTypeWrapper<PendingTransactions_Mutation_Response>;
  pendingTransactions_on_conflict: PendingTransactions_On_Conflict;
  pendingTransactions_order_by: PendingTransactions_Order_By;
  pendingTransactions_pk_columns_input: PendingTransactions_Pk_Columns_Input;
  pendingTransactions_select_column: PendingTransactions_Select_Column;
  pendingTransactions_select_column_pendingTransactions_aggregate_bool_exp_bool_and_arguments_columns: PendingTransactions_Select_Column_PendingTransactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  pendingTransactions_select_column_pendingTransactions_aggregate_bool_exp_bool_or_arguments_columns: PendingTransactions_Select_Column_PendingTransactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  pendingTransactions_set_input: PendingTransactions_Set_Input;
  pendingTransactions_stddev_fields: ResolverTypeWrapper<PendingTransactions_Stddev_Fields>;
  pendingTransactions_stddev_order_by: PendingTransactions_Stddev_Order_By;
  pendingTransactions_stddev_pop_fields: ResolverTypeWrapper<PendingTransactions_Stddev_Pop_Fields>;
  pendingTransactions_stddev_pop_order_by: PendingTransactions_Stddev_Pop_Order_By;
  pendingTransactions_stddev_samp_fields: ResolverTypeWrapper<PendingTransactions_Stddev_Samp_Fields>;
  pendingTransactions_stddev_samp_order_by: PendingTransactions_Stddev_Samp_Order_By;
  pendingTransactions_stream_cursor_input: PendingTransactions_Stream_Cursor_Input;
  pendingTransactions_stream_cursor_value_input: PendingTransactions_Stream_Cursor_Value_Input;
  pendingTransactions_sum_fields: ResolverTypeWrapper<PendingTransactions_Sum_Fields>;
  pendingTransactions_sum_order_by: PendingTransactions_Sum_Order_By;
  pendingTransactions_update_column: PendingTransactions_Update_Column;
  pendingTransactions_updates: PendingTransactions_Updates;
  pendingTransactions_var_pop_fields: ResolverTypeWrapper<PendingTransactions_Var_Pop_Fields>;
  pendingTransactions_var_pop_order_by: PendingTransactions_Var_Pop_Order_By;
  pendingTransactions_var_samp_fields: ResolverTypeWrapper<PendingTransactions_Var_Samp_Fields>;
  pendingTransactions_var_samp_order_by: PendingTransactions_Var_Samp_Order_By;
  pendingTransactions_variance_fields: ResolverTypeWrapper<PendingTransactions_Variance_Fields>;
  pendingTransactions_variance_order_by: PendingTransactions_Variance_Order_By;
  pending_tx: ResolverTypeWrapper<Pending_Tx>;
  pending_tx_aggregate: ResolverTypeWrapper<Pending_Tx_Aggregate>;
  pending_tx_aggregate_bool_exp: Pending_Tx_Aggregate_Bool_Exp;
  pending_tx_aggregate_bool_exp_count: Pending_Tx_Aggregate_Bool_Exp_Count;
  pending_tx_aggregate_fields: ResolverTypeWrapper<Pending_Tx_Aggregate_Fields>;
  pending_tx_aggregate_order_by: Pending_Tx_Aggregate_Order_By;
  pending_tx_arr_rel_insert_input: Pending_Tx_Arr_Rel_Insert_Input;
  pending_tx_avg_fields: ResolverTypeWrapper<Pending_Tx_Avg_Fields>;
  pending_tx_avg_order_by: Pending_Tx_Avg_Order_By;
  pending_tx_bool_exp: Pending_Tx_Bool_Exp;
  pending_tx_constraint: Pending_Tx_Constraint;
  pending_tx_inc_input: Pending_Tx_Inc_Input;
  pending_tx_insert_input: Pending_Tx_Insert_Input;
  pending_tx_max_fields: ResolverTypeWrapper<Pending_Tx_Max_Fields>;
  pending_tx_max_order_by: Pending_Tx_Max_Order_By;
  pending_tx_min_fields: ResolverTypeWrapper<Pending_Tx_Min_Fields>;
  pending_tx_min_order_by: Pending_Tx_Min_Order_By;
  pending_tx_mutation_response: ResolverTypeWrapper<Pending_Tx_Mutation_Response>;
  pending_tx_on_conflict: Pending_Tx_On_Conflict;
  pending_tx_order_by: Pending_Tx_Order_By;
  pending_tx_pk_columns_input: Pending_Tx_Pk_Columns_Input;
  pending_tx_select_column: Pending_Tx_Select_Column;
  pending_tx_set_input: Pending_Tx_Set_Input;
  pending_tx_stddev_fields: ResolverTypeWrapper<Pending_Tx_Stddev_Fields>;
  pending_tx_stddev_order_by: Pending_Tx_Stddev_Order_By;
  pending_tx_stddev_pop_fields: ResolverTypeWrapper<Pending_Tx_Stddev_Pop_Fields>;
  pending_tx_stddev_pop_order_by: Pending_Tx_Stddev_Pop_Order_By;
  pending_tx_stddev_samp_fields: ResolverTypeWrapper<Pending_Tx_Stddev_Samp_Fields>;
  pending_tx_stddev_samp_order_by: Pending_Tx_Stddev_Samp_Order_By;
  pending_tx_stream_cursor_input: Pending_Tx_Stream_Cursor_Input;
  pending_tx_stream_cursor_value_input: Pending_Tx_Stream_Cursor_Value_Input;
  pending_tx_sum_fields: ResolverTypeWrapper<Pending_Tx_Sum_Fields>;
  pending_tx_sum_order_by: Pending_Tx_Sum_Order_By;
  pending_tx_update_column: Pending_Tx_Update_Column;
  pending_tx_updates: Pending_Tx_Updates;
  pending_tx_var_pop_fields: ResolverTypeWrapper<Pending_Tx_Var_Pop_Fields>;
  pending_tx_var_pop_order_by: Pending_Tx_Var_Pop_Order_By;
  pending_tx_var_samp_fields: ResolverTypeWrapper<Pending_Tx_Var_Samp_Fields>;
  pending_tx_var_samp_order_by: Pending_Tx_Var_Samp_Order_By;
  pending_tx_variance_fields: ResolverTypeWrapper<Pending_Tx_Variance_Fields>;
  pending_tx_variance_order_by: Pending_Tx_Variance_Order_By;
  query_root: ResolverTypeWrapper<{}>;
  rakebackLocks: ResolverTypeWrapper<RakebackLocks>;
  rakebackLocks_aggregate: ResolverTypeWrapper<RakebackLocks_Aggregate>;
  rakebackLocks_aggregate_fields: ResolverTypeWrapper<RakebackLocks_Aggregate_Fields>;
  rakebackLocks_avg_fields: ResolverTypeWrapper<RakebackLocks_Avg_Fields>;
  rakebackLocks_bool_exp: RakebackLocks_Bool_Exp;
  rakebackLocks_constraint: RakebackLocks_Constraint;
  rakebackLocks_inc_input: RakebackLocks_Inc_Input;
  rakebackLocks_insert_input: RakebackLocks_Insert_Input;
  rakebackLocks_max_fields: ResolverTypeWrapper<RakebackLocks_Max_Fields>;
  rakebackLocks_min_fields: ResolverTypeWrapper<RakebackLocks_Min_Fields>;
  rakebackLocks_mutation_response: ResolverTypeWrapper<RakebackLocks_Mutation_Response>;
  rakebackLocks_on_conflict: RakebackLocks_On_Conflict;
  rakebackLocks_order_by: RakebackLocks_Order_By;
  rakebackLocks_pk_columns_input: RakebackLocks_Pk_Columns_Input;
  rakebackLocks_select_column: RakebackLocks_Select_Column;
  rakebackLocks_set_input: RakebackLocks_Set_Input;
  rakebackLocks_stddev_fields: ResolverTypeWrapper<RakebackLocks_Stddev_Fields>;
  rakebackLocks_stddev_pop_fields: ResolverTypeWrapper<RakebackLocks_Stddev_Pop_Fields>;
  rakebackLocks_stddev_samp_fields: ResolverTypeWrapper<RakebackLocks_Stddev_Samp_Fields>;
  rakebackLocks_stream_cursor_input: RakebackLocks_Stream_Cursor_Input;
  rakebackLocks_stream_cursor_value_input: RakebackLocks_Stream_Cursor_Value_Input;
  rakebackLocks_sum_fields: ResolverTypeWrapper<RakebackLocks_Sum_Fields>;
  rakebackLocks_update_column: RakebackLocks_Update_Column;
  rakebackLocks_updates: RakebackLocks_Updates;
  rakebackLocks_var_pop_fields: ResolverTypeWrapper<RakebackLocks_Var_Pop_Fields>;
  rakebackLocks_var_samp_fields: ResolverTypeWrapper<RakebackLocks_Var_Samp_Fields>;
  rakebackLocks_variance_fields: ResolverTypeWrapper<RakebackLocks_Variance_Fields>;
  smallint: ResolverTypeWrapper<Scalars['smallint']>;
  smallint_comparison_exp: Smallint_Comparison_Exp;
  stablecoins: ResolverTypeWrapper<Stablecoins>;
  stablecoins_aggregate: ResolverTypeWrapper<Stablecoins_Aggregate>;
  stablecoins_aggregate_bool_exp: Stablecoins_Aggregate_Bool_Exp;
  stablecoins_aggregate_bool_exp_bool_and: Stablecoins_Aggregate_Bool_Exp_Bool_And;
  stablecoins_aggregate_bool_exp_bool_or: Stablecoins_Aggregate_Bool_Exp_Bool_Or;
  stablecoins_aggregate_bool_exp_count: Stablecoins_Aggregate_Bool_Exp_Count;
  stablecoins_aggregate_fields: ResolverTypeWrapper<Stablecoins_Aggregate_Fields>;
  stablecoins_aggregate_order_by: Stablecoins_Aggregate_Order_By;
  stablecoins_arr_rel_insert_input: Stablecoins_Arr_Rel_Insert_Input;
  stablecoins_avg_fields: ResolverTypeWrapper<Stablecoins_Avg_Fields>;
  stablecoins_avg_order_by: Stablecoins_Avg_Order_By;
  stablecoins_bool_exp: Stablecoins_Bool_Exp;
  stablecoins_constraint: Stablecoins_Constraint;
  stablecoins_inc_input: Stablecoins_Inc_Input;
  stablecoins_insert_input: Stablecoins_Insert_Input;
  stablecoins_max_fields: ResolverTypeWrapper<Stablecoins_Max_Fields>;
  stablecoins_max_order_by: Stablecoins_Max_Order_By;
  stablecoins_min_fields: ResolverTypeWrapper<Stablecoins_Min_Fields>;
  stablecoins_min_order_by: Stablecoins_Min_Order_By;
  stablecoins_mutation_response: ResolverTypeWrapper<Stablecoins_Mutation_Response>;
  stablecoins_obj_rel_insert_input: Stablecoins_Obj_Rel_Insert_Input;
  stablecoins_on_conflict: Stablecoins_On_Conflict;
  stablecoins_order_by: Stablecoins_Order_By;
  stablecoins_pk_columns_input: Stablecoins_Pk_Columns_Input;
  stablecoins_select_column: Stablecoins_Select_Column;
  stablecoins_select_column_stablecoins_aggregate_bool_exp_bool_and_arguments_columns: Stablecoins_Select_Column_Stablecoins_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  stablecoins_select_column_stablecoins_aggregate_bool_exp_bool_or_arguments_columns: Stablecoins_Select_Column_Stablecoins_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  stablecoins_set_input: Stablecoins_Set_Input;
  stablecoins_stddev_fields: ResolverTypeWrapper<Stablecoins_Stddev_Fields>;
  stablecoins_stddev_order_by: Stablecoins_Stddev_Order_By;
  stablecoins_stddev_pop_fields: ResolverTypeWrapper<Stablecoins_Stddev_Pop_Fields>;
  stablecoins_stddev_pop_order_by: Stablecoins_Stddev_Pop_Order_By;
  stablecoins_stddev_samp_fields: ResolverTypeWrapper<Stablecoins_Stddev_Samp_Fields>;
  stablecoins_stddev_samp_order_by: Stablecoins_Stddev_Samp_Order_By;
  stablecoins_stream_cursor_input: Stablecoins_Stream_Cursor_Input;
  stablecoins_stream_cursor_value_input: Stablecoins_Stream_Cursor_Value_Input;
  stablecoins_sum_fields: ResolverTypeWrapper<Stablecoins_Sum_Fields>;
  stablecoins_sum_order_by: Stablecoins_Sum_Order_By;
  stablecoins_update_column: Stablecoins_Update_Column;
  stablecoins_updates: Stablecoins_Updates;
  stablecoins_var_pop_fields: ResolverTypeWrapper<Stablecoins_Var_Pop_Fields>;
  stablecoins_var_pop_order_by: Stablecoins_Var_Pop_Order_By;
  stablecoins_var_samp_fields: ResolverTypeWrapper<Stablecoins_Var_Samp_Fields>;
  stablecoins_var_samp_order_by: Stablecoins_Var_Samp_Order_By;
  stablecoins_variance_fields: ResolverTypeWrapper<Stablecoins_Variance_Fields>;
  stablecoins_variance_order_by: Stablecoins_Variance_Order_By;
  stakingOutput: ResolverTypeWrapper<StakingOutput>;
  staking_refill: ResolverTypeWrapper<Staking_Refill>;
  staking_refill_aggregate: ResolverTypeWrapper<Staking_Refill_Aggregate>;
  staking_refill_aggregate_fields: ResolverTypeWrapper<Staking_Refill_Aggregate_Fields>;
  staking_refill_avg_fields: ResolverTypeWrapper<Staking_Refill_Avg_Fields>;
  staking_refill_bool_exp: Staking_Refill_Bool_Exp;
  staking_refill_constraint: Staking_Refill_Constraint;
  staking_refill_inc_input: Staking_Refill_Inc_Input;
  staking_refill_insert_input: Staking_Refill_Insert_Input;
  staking_refill_max_fields: ResolverTypeWrapper<Staking_Refill_Max_Fields>;
  staking_refill_min_fields: ResolverTypeWrapper<Staking_Refill_Min_Fields>;
  staking_refill_mutation_response: ResolverTypeWrapper<Staking_Refill_Mutation_Response>;
  staking_refill_on_conflict: Staking_Refill_On_Conflict;
  staking_refill_order_by: Staking_Refill_Order_By;
  staking_refill_pk_columns_input: Staking_Refill_Pk_Columns_Input;
  staking_refill_select_column: Staking_Refill_Select_Column;
  staking_refill_set_input: Staking_Refill_Set_Input;
  staking_refill_stddev_fields: ResolverTypeWrapper<Staking_Refill_Stddev_Fields>;
  staking_refill_stddev_pop_fields: ResolverTypeWrapper<Staking_Refill_Stddev_Pop_Fields>;
  staking_refill_stddev_samp_fields: ResolverTypeWrapper<Staking_Refill_Stddev_Samp_Fields>;
  staking_refill_stream_cursor_input: Staking_Refill_Stream_Cursor_Input;
  staking_refill_stream_cursor_value_input: Staking_Refill_Stream_Cursor_Value_Input;
  staking_refill_sum_fields: ResolverTypeWrapper<Staking_Refill_Sum_Fields>;
  staking_refill_update_column: Staking_Refill_Update_Column;
  staking_refill_updates: Staking_Refill_Updates;
  staking_refill_var_pop_fields: ResolverTypeWrapper<Staking_Refill_Var_Pop_Fields>;
  staking_refill_var_samp_fields: ResolverTypeWrapper<Staking_Refill_Var_Samp_Fields>;
  staking_refill_variance_fields: ResolverTypeWrapper<Staking_Refill_Variance_Fields>;
  subscription_root: ResolverTypeWrapper<{}>;
  sumsubOutput: ResolverTypeWrapper<SumsubOutput>;
  swapTransactions: ResolverTypeWrapper<SwapTransactions>;
  swapTransactions_aggregate: ResolverTypeWrapper<SwapTransactions_Aggregate>;
  swapTransactions_aggregate_fields: ResolverTypeWrapper<SwapTransactions_Aggregate_Fields>;
  swapTransactions_avg_fields: ResolverTypeWrapper<SwapTransactions_Avg_Fields>;
  swapTransactions_bool_exp: SwapTransactions_Bool_Exp;
  swapTransactions_inc_input: SwapTransactions_Inc_Input;
  swapTransactions_insert_input: SwapTransactions_Insert_Input;
  swapTransactions_max_fields: ResolverTypeWrapper<SwapTransactions_Max_Fields>;
  swapTransactions_min_fields: ResolverTypeWrapper<SwapTransactions_Min_Fields>;
  swapTransactions_mutation_response: ResolverTypeWrapper<SwapTransactions_Mutation_Response>;
  swapTransactions_order_by: SwapTransactions_Order_By;
  swapTransactions_select_column: SwapTransactions_Select_Column;
  swapTransactions_set_input: SwapTransactions_Set_Input;
  swapTransactions_stddev_fields: ResolverTypeWrapper<SwapTransactions_Stddev_Fields>;
  swapTransactions_stddev_pop_fields: ResolverTypeWrapper<SwapTransactions_Stddev_Pop_Fields>;
  swapTransactions_stddev_samp_fields: ResolverTypeWrapper<SwapTransactions_Stddev_Samp_Fields>;
  swapTransactions_stream_cursor_input: SwapTransactions_Stream_Cursor_Input;
  swapTransactions_stream_cursor_value_input: SwapTransactions_Stream_Cursor_Value_Input;
  swapTransactions_sum_fields: ResolverTypeWrapper<SwapTransactions_Sum_Fields>;
  swapTransactions_updates: SwapTransactions_Updates;
  swapTransactions_var_pop_fields: ResolverTypeWrapper<SwapTransactions_Var_Pop_Fields>;
  swapTransactions_var_samp_fields: ResolverTypeWrapper<SwapTransactions_Var_Samp_Fields>;
  swapTransactions_variance_fields: ResolverTypeWrapper<SwapTransactions_Variance_Fields>;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>;
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  tournamentInput: TournamentInput;
  tournaments: ResolverTypeWrapper<Tournaments>;
  tournaments_aggregate: ResolverTypeWrapper<Tournaments_Aggregate>;
  tournaments_aggregate_fields: ResolverTypeWrapper<Tournaments_Aggregate_Fields>;
  tournaments_avg_fields: ResolverTypeWrapper<Tournaments_Avg_Fields>;
  tournaments_bool_exp: Tournaments_Bool_Exp;
  tournaments_constraint: Tournaments_Constraint;
  tournaments_inc_input: Tournaments_Inc_Input;
  tournaments_insert_input: Tournaments_Insert_Input;
  tournaments_max_fields: ResolverTypeWrapper<Tournaments_Max_Fields>;
  tournaments_min_fields: ResolverTypeWrapper<Tournaments_Min_Fields>;
  tournaments_mutation_response: ResolverTypeWrapper<Tournaments_Mutation_Response>;
  tournaments_on_conflict: Tournaments_On_Conflict;
  tournaments_order_by: Tournaments_Order_By;
  tournaments_pk_columns_input: Tournaments_Pk_Columns_Input;
  tournaments_select_column: Tournaments_Select_Column;
  tournaments_set_input: Tournaments_Set_Input;
  tournaments_stddev_fields: ResolverTypeWrapper<Tournaments_Stddev_Fields>;
  tournaments_stddev_pop_fields: ResolverTypeWrapper<Tournaments_Stddev_Pop_Fields>;
  tournaments_stddev_samp_fields: ResolverTypeWrapper<Tournaments_Stddev_Samp_Fields>;
  tournaments_stream_cursor_input: Tournaments_Stream_Cursor_Input;
  tournaments_stream_cursor_value_input: Tournaments_Stream_Cursor_Value_Input;
  tournaments_sum_fields: ResolverTypeWrapper<Tournaments_Sum_Fields>;
  tournaments_update_column: Tournaments_Update_Column;
  tournaments_updates: Tournaments_Updates;
  tournaments_var_pop_fields: ResolverTypeWrapper<Tournaments_Var_Pop_Fields>;
  tournaments_var_samp_fields: ResolverTypeWrapper<Tournaments_Var_Samp_Fields>;
  tournaments_variance_fields: ResolverTypeWrapper<Tournaments_Variance_Fields>;
  transactionStatistics: ResolverTypeWrapper<TransactionStatistics>;
  transactionStatistics_aggregate: ResolverTypeWrapper<TransactionStatistics_Aggregate>;
  transactionStatistics_aggregate_fields: ResolverTypeWrapper<TransactionStatistics_Aggregate_Fields>;
  transactionStatistics_avg_fields: ResolverTypeWrapper<TransactionStatistics_Avg_Fields>;
  transactionStatistics_bool_exp: TransactionStatistics_Bool_Exp;
  transactionStatistics_constraint: TransactionStatistics_Constraint;
  transactionStatistics_inc_input: TransactionStatistics_Inc_Input;
  transactionStatistics_insert_input: TransactionStatistics_Insert_Input;
  transactionStatistics_max_fields: ResolverTypeWrapper<TransactionStatistics_Max_Fields>;
  transactionStatistics_min_fields: ResolverTypeWrapper<TransactionStatistics_Min_Fields>;
  transactionStatistics_mutation_response: ResolverTypeWrapper<TransactionStatistics_Mutation_Response>;
  transactionStatistics_on_conflict: TransactionStatistics_On_Conflict;
  transactionStatistics_order_by: TransactionStatistics_Order_By;
  transactionStatistics_pk_columns_input: TransactionStatistics_Pk_Columns_Input;
  transactionStatistics_select_column: TransactionStatistics_Select_Column;
  transactionStatistics_set_input: TransactionStatistics_Set_Input;
  transactionStatistics_stddev_fields: ResolverTypeWrapper<TransactionStatistics_Stddev_Fields>;
  transactionStatistics_stddev_pop_fields: ResolverTypeWrapper<TransactionStatistics_Stddev_Pop_Fields>;
  transactionStatistics_stddev_samp_fields: ResolverTypeWrapper<TransactionStatistics_Stddev_Samp_Fields>;
  transactionStatistics_stream_cursor_input: TransactionStatistics_Stream_Cursor_Input;
  transactionStatistics_stream_cursor_value_input: TransactionStatistics_Stream_Cursor_Value_Input;
  transactionStatistics_sum_fields: ResolverTypeWrapper<TransactionStatistics_Sum_Fields>;
  transactionStatistics_update_column: TransactionStatistics_Update_Column;
  transactionStatistics_updates: TransactionStatistics_Updates;
  transactionStatistics_var_pop_fields: ResolverTypeWrapper<TransactionStatistics_Var_Pop_Fields>;
  transactionStatistics_var_samp_fields: ResolverTypeWrapper<TransactionStatistics_Var_Samp_Fields>;
  transactionStatistics_variance_fields: ResolverTypeWrapper<TransactionStatistics_Variance_Fields>;
  transactions: ResolverTypeWrapper<Transactions>;
  transactions_aggregate: ResolverTypeWrapper<Transactions_Aggregate>;
  transactions_aggregate_fields: ResolverTypeWrapper<Transactions_Aggregate_Fields>;
  transactions_append_input: Transactions_Append_Input;
  transactions_avg_fields: ResolverTypeWrapper<Transactions_Avg_Fields>;
  transactions_bool_exp: Transactions_Bool_Exp;
  transactions_constraint: Transactions_Constraint;
  transactions_delete_at_path_input: Transactions_Delete_At_Path_Input;
  transactions_delete_elem_input: Transactions_Delete_Elem_Input;
  transactions_delete_key_input: Transactions_Delete_Key_Input;
  transactions_inc_input: Transactions_Inc_Input;
  transactions_insert_input: Transactions_Insert_Input;
  transactions_max_fields: ResolverTypeWrapper<Transactions_Max_Fields>;
  transactions_min_fields: ResolverTypeWrapper<Transactions_Min_Fields>;
  transactions_mutation_response: ResolverTypeWrapper<Transactions_Mutation_Response>;
  transactions_on_conflict: Transactions_On_Conflict;
  transactions_order_by: Transactions_Order_By;
  transactions_pk_columns_input: Transactions_Pk_Columns_Input;
  transactions_prepend_input: Transactions_Prepend_Input;
  transactions_select_column: Transactions_Select_Column;
  transactions_set_input: Transactions_Set_Input;
  transactions_stddev_fields: ResolverTypeWrapper<Transactions_Stddev_Fields>;
  transactions_stddev_pop_fields: ResolverTypeWrapper<Transactions_Stddev_Pop_Fields>;
  transactions_stddev_samp_fields: ResolverTypeWrapper<Transactions_Stddev_Samp_Fields>;
  transactions_stream_cursor_input: Transactions_Stream_Cursor_Input;
  transactions_stream_cursor_value_input: Transactions_Stream_Cursor_Value_Input;
  transactions_sum_fields: ResolverTypeWrapper<Transactions_Sum_Fields>;
  transactions_update_column: Transactions_Update_Column;
  transactions_updates: Transactions_Updates;
  transactions_var_pop_fields: ResolverTypeWrapper<Transactions_Var_Pop_Fields>;
  transactions_var_samp_fields: ResolverTypeWrapper<Transactions_Var_Samp_Fields>;
  transactions_variance_fields: ResolverTypeWrapper<Transactions_Variance_Fields>;
  updateAvailableAvatarNFTs: ResolverTypeWrapper<UpdateAvailableAvatarNfTs>;
  userErc721: ResolverTypeWrapper<UserErc721>;
  userErc721_aggregate: ResolverTypeWrapper<UserErc721_Aggregate>;
  userErc721_aggregate_fields: ResolverTypeWrapper<UserErc721_Aggregate_Fields>;
  userErc721_avg_fields: ResolverTypeWrapper<UserErc721_Avg_Fields>;
  userErc721_bool_exp: UserErc721_Bool_Exp;
  userErc721_constraint: UserErc721_Constraint;
  userErc721_inc_input: UserErc721_Inc_Input;
  userErc721_insert_input: UserErc721_Insert_Input;
  userErc721_max_fields: ResolverTypeWrapper<UserErc721_Max_Fields>;
  userErc721_min_fields: ResolverTypeWrapper<UserErc721_Min_Fields>;
  userErc721_mutation_response: ResolverTypeWrapper<UserErc721_Mutation_Response>;
  userErc721_on_conflict: UserErc721_On_Conflict;
  userErc721_order_by: UserErc721_Order_By;
  userErc721_pk_columns_input: UserErc721_Pk_Columns_Input;
  userErc721_select_column: UserErc721_Select_Column;
  userErc721_set_input: UserErc721_Set_Input;
  userErc721_stddev_fields: ResolverTypeWrapper<UserErc721_Stddev_Fields>;
  userErc721_stddev_pop_fields: ResolverTypeWrapper<UserErc721_Stddev_Pop_Fields>;
  userErc721_stddev_samp_fields: ResolverTypeWrapper<UserErc721_Stddev_Samp_Fields>;
  userErc721_stream_cursor_input: UserErc721_Stream_Cursor_Input;
  userErc721_stream_cursor_value_input: UserErc721_Stream_Cursor_Value_Input;
  userErc721_sum_fields: ResolverTypeWrapper<UserErc721_Sum_Fields>;
  userErc721_update_column: UserErc721_Update_Column;
  userErc721_updates: UserErc721_Updates;
  userErc721_var_pop_fields: ResolverTypeWrapper<UserErc721_Var_Pop_Fields>;
  userErc721_var_samp_fields: ResolverTypeWrapper<UserErc721_Var_Samp_Fields>;
  userErc721_variance_fields: ResolverTypeWrapper<UserErc721_Variance_Fields>;
  userJobs: ResolverTypeWrapper<UserJobs>;
  userJobs_aggregate: ResolverTypeWrapper<UserJobs_Aggregate>;
  userJobs_aggregate_fields: ResolverTypeWrapper<UserJobs_Aggregate_Fields>;
  userJobs_avg_fields: ResolverTypeWrapper<UserJobs_Avg_Fields>;
  userJobs_bool_exp: UserJobs_Bool_Exp;
  userJobs_constraint: UserJobs_Constraint;
  userJobs_inc_input: UserJobs_Inc_Input;
  userJobs_insert_input: UserJobs_Insert_Input;
  userJobs_max_fields: ResolverTypeWrapper<UserJobs_Max_Fields>;
  userJobs_min_fields: ResolverTypeWrapper<UserJobs_Min_Fields>;
  userJobs_mutation_response: ResolverTypeWrapper<UserJobs_Mutation_Response>;
  userJobs_on_conflict: UserJobs_On_Conflict;
  userJobs_order_by: UserJobs_Order_By;
  userJobs_pk_columns_input: UserJobs_Pk_Columns_Input;
  userJobs_select_column: UserJobs_Select_Column;
  userJobs_set_input: UserJobs_Set_Input;
  userJobs_stddev_fields: ResolverTypeWrapper<UserJobs_Stddev_Fields>;
  userJobs_stddev_pop_fields: ResolverTypeWrapper<UserJobs_Stddev_Pop_Fields>;
  userJobs_stddev_samp_fields: ResolverTypeWrapper<UserJobs_Stddev_Samp_Fields>;
  userJobs_stream_cursor_input: UserJobs_Stream_Cursor_Input;
  userJobs_stream_cursor_value_input: UserJobs_Stream_Cursor_Value_Input;
  userJobs_sum_fields: ResolverTypeWrapper<UserJobs_Sum_Fields>;
  userJobs_update_column: UserJobs_Update_Column;
  userJobs_updates: UserJobs_Updates;
  userJobs_var_pop_fields: ResolverTypeWrapper<UserJobs_Var_Pop_Fields>;
  userJobs_var_samp_fields: ResolverTypeWrapper<UserJobs_Var_Samp_Fields>;
  userJobs_variance_fields: ResolverTypeWrapper<UserJobs_Variance_Fields>;
  user_erc721_block: ResolverTypeWrapper<User_Erc721_Block>;
  user_erc721_block_aggregate: ResolverTypeWrapper<User_Erc721_Block_Aggregate>;
  user_erc721_block_aggregate_fields: ResolverTypeWrapper<User_Erc721_Block_Aggregate_Fields>;
  user_erc721_block_avg_fields: ResolverTypeWrapper<User_Erc721_Block_Avg_Fields>;
  user_erc721_block_bool_exp: User_Erc721_Block_Bool_Exp;
  user_erc721_block_constraint: User_Erc721_Block_Constraint;
  user_erc721_block_inc_input: User_Erc721_Block_Inc_Input;
  user_erc721_block_insert_input: User_Erc721_Block_Insert_Input;
  user_erc721_block_max_fields: ResolverTypeWrapper<User_Erc721_Block_Max_Fields>;
  user_erc721_block_min_fields: ResolverTypeWrapper<User_Erc721_Block_Min_Fields>;
  user_erc721_block_mutation_response: ResolverTypeWrapper<User_Erc721_Block_Mutation_Response>;
  user_erc721_block_on_conflict: User_Erc721_Block_On_Conflict;
  user_erc721_block_order_by: User_Erc721_Block_Order_By;
  user_erc721_block_pk_columns_input: User_Erc721_Block_Pk_Columns_Input;
  user_erc721_block_select_column: User_Erc721_Block_Select_Column;
  user_erc721_block_set_input: User_Erc721_Block_Set_Input;
  user_erc721_block_stddev_fields: ResolverTypeWrapper<User_Erc721_Block_Stddev_Fields>;
  user_erc721_block_stddev_pop_fields: ResolverTypeWrapper<User_Erc721_Block_Stddev_Pop_Fields>;
  user_erc721_block_stddev_samp_fields: ResolverTypeWrapper<User_Erc721_Block_Stddev_Samp_Fields>;
  user_erc721_block_stream_cursor_input: User_Erc721_Block_Stream_Cursor_Input;
  user_erc721_block_stream_cursor_value_input: User_Erc721_Block_Stream_Cursor_Value_Input;
  user_erc721_block_sum_fields: ResolverTypeWrapper<User_Erc721_Block_Sum_Fields>;
  user_erc721_block_update_column: User_Erc721_Block_Update_Column;
  user_erc721_block_updates: User_Erc721_Block_Updates;
  user_erc721_block_var_pop_fields: ResolverTypeWrapper<User_Erc721_Block_Var_Pop_Fields>;
  user_erc721_block_var_samp_fields: ResolverTypeWrapper<User_Erc721_Block_Var_Samp_Fields>;
  user_erc721_block_variance_fields: ResolverTypeWrapper<User_Erc721_Block_Variance_Fields>;
  user_gpanel: ResolverTypeWrapper<User_Gpanel>;
  user_gpanel_aggregate: ResolverTypeWrapper<User_Gpanel_Aggregate>;
  user_gpanel_aggregate_bool_exp: User_Gpanel_Aggregate_Bool_Exp;
  user_gpanel_aggregate_bool_exp_count: User_Gpanel_Aggregate_Bool_Exp_Count;
  user_gpanel_aggregate_fields: ResolverTypeWrapper<User_Gpanel_Aggregate_Fields>;
  user_gpanel_aggregate_order_by: User_Gpanel_Aggregate_Order_By;
  user_gpanel_arr_rel_insert_input: User_Gpanel_Arr_Rel_Insert_Input;
  user_gpanel_avg_fields: ResolverTypeWrapper<User_Gpanel_Avg_Fields>;
  user_gpanel_avg_order_by: User_Gpanel_Avg_Order_By;
  user_gpanel_bool_exp: User_Gpanel_Bool_Exp;
  user_gpanel_constraint: User_Gpanel_Constraint;
  user_gpanel_inc_input: User_Gpanel_Inc_Input;
  user_gpanel_insert_input: User_Gpanel_Insert_Input;
  user_gpanel_max_fields: ResolverTypeWrapper<User_Gpanel_Max_Fields>;
  user_gpanel_max_order_by: User_Gpanel_Max_Order_By;
  user_gpanel_min_fields: ResolverTypeWrapper<User_Gpanel_Min_Fields>;
  user_gpanel_min_order_by: User_Gpanel_Min_Order_By;
  user_gpanel_mutation_response: ResolverTypeWrapper<User_Gpanel_Mutation_Response>;
  user_gpanel_on_conflict: User_Gpanel_On_Conflict;
  user_gpanel_order_by: User_Gpanel_Order_By;
  user_gpanel_pk_columns_input: User_Gpanel_Pk_Columns_Input;
  user_gpanel_select_column: User_Gpanel_Select_Column;
  user_gpanel_set_input: User_Gpanel_Set_Input;
  user_gpanel_stddev_fields: ResolverTypeWrapper<User_Gpanel_Stddev_Fields>;
  user_gpanel_stddev_order_by: User_Gpanel_Stddev_Order_By;
  user_gpanel_stddev_pop_fields: ResolverTypeWrapper<User_Gpanel_Stddev_Pop_Fields>;
  user_gpanel_stddev_pop_order_by: User_Gpanel_Stddev_Pop_Order_By;
  user_gpanel_stddev_samp_fields: ResolverTypeWrapper<User_Gpanel_Stddev_Samp_Fields>;
  user_gpanel_stddev_samp_order_by: User_Gpanel_Stddev_Samp_Order_By;
  user_gpanel_stream_cursor_input: User_Gpanel_Stream_Cursor_Input;
  user_gpanel_stream_cursor_value_input: User_Gpanel_Stream_Cursor_Value_Input;
  user_gpanel_sum_fields: ResolverTypeWrapper<User_Gpanel_Sum_Fields>;
  user_gpanel_sum_order_by: User_Gpanel_Sum_Order_By;
  user_gpanel_update_column: User_Gpanel_Update_Column;
  user_gpanel_updates: User_Gpanel_Updates;
  user_gpanel_var_pop_fields: ResolverTypeWrapper<User_Gpanel_Var_Pop_Fields>;
  user_gpanel_var_pop_order_by: User_Gpanel_Var_Pop_Order_By;
  user_gpanel_var_samp_fields: ResolverTypeWrapper<User_Gpanel_Var_Samp_Fields>;
  user_gpanel_var_samp_order_by: User_Gpanel_Var_Samp_Order_By;
  user_gpanel_variance_fields: ResolverTypeWrapper<User_Gpanel_Variance_Fields>;
  user_gpanel_variance_order_by: User_Gpanel_Variance_Order_By;
  user_lock: ResolverTypeWrapper<User_Lock>;
  user_lock_aggregate: ResolverTypeWrapper<User_Lock_Aggregate>;
  user_lock_aggregate_bool_exp: User_Lock_Aggregate_Bool_Exp;
  user_lock_aggregate_bool_exp_bool_and: User_Lock_Aggregate_Bool_Exp_Bool_And;
  user_lock_aggregate_bool_exp_bool_or: User_Lock_Aggregate_Bool_Exp_Bool_Or;
  user_lock_aggregate_bool_exp_count: User_Lock_Aggregate_Bool_Exp_Count;
  user_lock_aggregate_fields: ResolverTypeWrapper<User_Lock_Aggregate_Fields>;
  user_lock_aggregate_order_by: User_Lock_Aggregate_Order_By;
  user_lock_arr_rel_insert_input: User_Lock_Arr_Rel_Insert_Input;
  user_lock_avg_fields: ResolverTypeWrapper<User_Lock_Avg_Fields>;
  user_lock_avg_order_by: User_Lock_Avg_Order_By;
  user_lock_bool_exp: User_Lock_Bool_Exp;
  user_lock_constraint: User_Lock_Constraint;
  user_lock_inc_input: User_Lock_Inc_Input;
  user_lock_insert_input: User_Lock_Insert_Input;
  user_lock_max_fields: ResolverTypeWrapper<User_Lock_Max_Fields>;
  user_lock_max_order_by: User_Lock_Max_Order_By;
  user_lock_min_fields: ResolverTypeWrapper<User_Lock_Min_Fields>;
  user_lock_min_order_by: User_Lock_Min_Order_By;
  user_lock_mutation_response: ResolverTypeWrapper<User_Lock_Mutation_Response>;
  user_lock_on_conflict: User_Lock_On_Conflict;
  user_lock_order_by: User_Lock_Order_By;
  user_lock_pk_columns_input: User_Lock_Pk_Columns_Input;
  user_lock_select_column: User_Lock_Select_Column;
  user_lock_select_column_user_lock_aggregate_bool_exp_bool_and_arguments_columns: User_Lock_Select_Column_User_Lock_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  user_lock_select_column_user_lock_aggregate_bool_exp_bool_or_arguments_columns: User_Lock_Select_Column_User_Lock_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  user_lock_set_input: User_Lock_Set_Input;
  user_lock_stddev_fields: ResolverTypeWrapper<User_Lock_Stddev_Fields>;
  user_lock_stddev_order_by: User_Lock_Stddev_Order_By;
  user_lock_stddev_pop_fields: ResolverTypeWrapper<User_Lock_Stddev_Pop_Fields>;
  user_lock_stddev_pop_order_by: User_Lock_Stddev_Pop_Order_By;
  user_lock_stddev_samp_fields: ResolverTypeWrapper<User_Lock_Stddev_Samp_Fields>;
  user_lock_stddev_samp_order_by: User_Lock_Stddev_Samp_Order_By;
  user_lock_stream_cursor_input: User_Lock_Stream_Cursor_Input;
  user_lock_stream_cursor_value_input: User_Lock_Stream_Cursor_Value_Input;
  user_lock_sum_fields: ResolverTypeWrapper<User_Lock_Sum_Fields>;
  user_lock_sum_order_by: User_Lock_Sum_Order_By;
  user_lock_update_column: User_Lock_Update_Column;
  user_lock_updates: User_Lock_Updates;
  user_lock_var_pop_fields: ResolverTypeWrapper<User_Lock_Var_Pop_Fields>;
  user_lock_var_pop_order_by: User_Lock_Var_Pop_Order_By;
  user_lock_var_samp_fields: ResolverTypeWrapper<User_Lock_Var_Samp_Fields>;
  user_lock_var_samp_order_by: User_Lock_Var_Samp_Order_By;
  user_lock_variance_fields: ResolverTypeWrapper<User_Lock_Variance_Fields>;
  user_lock_variance_order_by: User_Lock_Variance_Order_By;
  user_staking: ResolverTypeWrapper<User_Staking>;
  user_staking_aggregate: ResolverTypeWrapper<User_Staking_Aggregate>;
  user_staking_aggregate_fields: ResolverTypeWrapper<User_Staking_Aggregate_Fields>;
  user_staking_avg_fields: ResolverTypeWrapper<User_Staking_Avg_Fields>;
  user_staking_bool_exp: User_Staking_Bool_Exp;
  user_staking_constraint: User_Staking_Constraint;
  user_staking_inc_input: User_Staking_Inc_Input;
  user_staking_insert_input: User_Staking_Insert_Input;
  user_staking_max_fields: ResolverTypeWrapper<User_Staking_Max_Fields>;
  user_staking_min_fields: ResolverTypeWrapper<User_Staking_Min_Fields>;
  user_staking_mutation_response: ResolverTypeWrapper<User_Staking_Mutation_Response>;
  user_staking_on_conflict: User_Staking_On_Conflict;
  user_staking_order_by: User_Staking_Order_By;
  user_staking_pk_columns_input: User_Staking_Pk_Columns_Input;
  user_staking_select_column: User_Staking_Select_Column;
  user_staking_set_input: User_Staking_Set_Input;
  user_staking_stddev_fields: ResolverTypeWrapper<User_Staking_Stddev_Fields>;
  user_staking_stddev_pop_fields: ResolverTypeWrapper<User_Staking_Stddev_Pop_Fields>;
  user_staking_stddev_samp_fields: ResolverTypeWrapper<User_Staking_Stddev_Samp_Fields>;
  user_staking_stream_cursor_input: User_Staking_Stream_Cursor_Input;
  user_staking_stream_cursor_value_input: User_Staking_Stream_Cursor_Value_Input;
  user_staking_sum_fields: ResolverTypeWrapper<User_Staking_Sum_Fields>;
  user_staking_transactions: ResolverTypeWrapper<User_Staking_Transactions>;
  user_staking_transactions_aggregate: ResolverTypeWrapper<User_Staking_Transactions_Aggregate>;
  user_staking_transactions_aggregate_fields: ResolverTypeWrapper<User_Staking_Transactions_Aggregate_Fields>;
  user_staking_transactions_avg_fields: ResolverTypeWrapper<User_Staking_Transactions_Avg_Fields>;
  user_staking_transactions_bool_exp: User_Staking_Transactions_Bool_Exp;
  user_staking_transactions_constraint: User_Staking_Transactions_Constraint;
  user_staking_transactions_inc_input: User_Staking_Transactions_Inc_Input;
  user_staking_transactions_insert_input: User_Staking_Transactions_Insert_Input;
  user_staking_transactions_max_fields: ResolverTypeWrapper<User_Staking_Transactions_Max_Fields>;
  user_staking_transactions_min_fields: ResolverTypeWrapper<User_Staking_Transactions_Min_Fields>;
  user_staking_transactions_mutation_response: ResolverTypeWrapper<User_Staking_Transactions_Mutation_Response>;
  user_staking_transactions_on_conflict: User_Staking_Transactions_On_Conflict;
  user_staking_transactions_order_by: User_Staking_Transactions_Order_By;
  user_staking_transactions_pk_columns_input: User_Staking_Transactions_Pk_Columns_Input;
  user_staking_transactions_select_column: User_Staking_Transactions_Select_Column;
  user_staking_transactions_set_input: User_Staking_Transactions_Set_Input;
  user_staking_transactions_stddev_fields: ResolverTypeWrapper<User_Staking_Transactions_Stddev_Fields>;
  user_staking_transactions_stddev_pop_fields: ResolverTypeWrapper<User_Staking_Transactions_Stddev_Pop_Fields>;
  user_staking_transactions_stddev_samp_fields: ResolverTypeWrapper<User_Staking_Transactions_Stddev_Samp_Fields>;
  user_staking_transactions_stream_cursor_input: User_Staking_Transactions_Stream_Cursor_Input;
  user_staking_transactions_stream_cursor_value_input: User_Staking_Transactions_Stream_Cursor_Value_Input;
  user_staking_transactions_sum_fields: ResolverTypeWrapper<User_Staking_Transactions_Sum_Fields>;
  user_staking_transactions_update_column: User_Staking_Transactions_Update_Column;
  user_staking_transactions_updates: User_Staking_Transactions_Updates;
  user_staking_transactions_var_pop_fields: ResolverTypeWrapper<User_Staking_Transactions_Var_Pop_Fields>;
  user_staking_transactions_var_samp_fields: ResolverTypeWrapper<User_Staking_Transactions_Var_Samp_Fields>;
  user_staking_transactions_variance_fields: ResolverTypeWrapper<User_Staking_Transactions_Variance_Fields>;
  user_staking_update_column: User_Staking_Update_Column;
  user_staking_updates: User_Staking_Updates;
  user_staking_var_pop_fields: ResolverTypeWrapper<User_Staking_Var_Pop_Fields>;
  user_staking_var_samp_fields: ResolverTypeWrapper<User_Staking_Var_Samp_Fields>;
  user_staking_variance_fields: ResolverTypeWrapper<User_Staking_Variance_Fields>;
  users: ResolverTypeWrapper<Users>;
  users_aggregate: ResolverTypeWrapper<Users_Aggregate>;
  users_aggregate_fields: ResolverTypeWrapper<Users_Aggregate_Fields>;
  users_avg_fields: ResolverTypeWrapper<Users_Avg_Fields>;
  users_bool_exp: Users_Bool_Exp;
  users_constraint: Users_Constraint;
  users_inc_input: Users_Inc_Input;
  users_insert_input: Users_Insert_Input;
  users_login: ResolverTypeWrapper<Users_Login>;
  users_login_aggregate: ResolverTypeWrapper<Users_Login_Aggregate>;
  users_login_aggregate_fields: ResolverTypeWrapper<Users_Login_Aggregate_Fields>;
  users_login_bool_exp: Users_Login_Bool_Exp;
  users_login_max_fields: ResolverTypeWrapper<Users_Login_Max_Fields>;
  users_login_min_fields: ResolverTypeWrapper<Users_Login_Min_Fields>;
  users_login_order_by: Users_Login_Order_By;
  users_login_select_column: Users_Login_Select_Column;
  users_login_stream_cursor_input: Users_Login_Stream_Cursor_Input;
  users_login_stream_cursor_value_input: Users_Login_Stream_Cursor_Value_Input;
  users_max_fields: ResolverTypeWrapper<Users_Max_Fields>;
  users_min_fields: ResolverTypeWrapper<Users_Min_Fields>;
  users_mutation_response: ResolverTypeWrapper<Users_Mutation_Response>;
  users_obj_rel_insert_input: Users_Obj_Rel_Insert_Input;
  users_old: ResolverTypeWrapper<Users_Old>;
  users_old_aggregate: ResolverTypeWrapper<Users_Old_Aggregate>;
  users_old_aggregate_fields: ResolverTypeWrapper<Users_Old_Aggregate_Fields>;
  users_old_avg_fields: ResolverTypeWrapper<Users_Old_Avg_Fields>;
  users_old_bool_exp: Users_Old_Bool_Exp;
  users_old_constraint: Users_Old_Constraint;
  users_old_inc_input: Users_Old_Inc_Input;
  users_old_insert_input: Users_Old_Insert_Input;
  users_old_max_fields: ResolverTypeWrapper<Users_Old_Max_Fields>;
  users_old_min_fields: ResolverTypeWrapper<Users_Old_Min_Fields>;
  users_old_mutation_response: ResolverTypeWrapper<Users_Old_Mutation_Response>;
  users_old_on_conflict: Users_Old_On_Conflict;
  users_old_order_by: Users_Old_Order_By;
  users_old_pk_columns_input: Users_Old_Pk_Columns_Input;
  users_old_select_column: Users_Old_Select_Column;
  users_old_set_input: Users_Old_Set_Input;
  users_old_stddev_fields: ResolverTypeWrapper<Users_Old_Stddev_Fields>;
  users_old_stddev_pop_fields: ResolverTypeWrapper<Users_Old_Stddev_Pop_Fields>;
  users_old_stddev_samp_fields: ResolverTypeWrapper<Users_Old_Stddev_Samp_Fields>;
  users_old_stream_cursor_input: Users_Old_Stream_Cursor_Input;
  users_old_stream_cursor_value_input: Users_Old_Stream_Cursor_Value_Input;
  users_old_sum_fields: ResolverTypeWrapper<Users_Old_Sum_Fields>;
  users_old_update_column: Users_Old_Update_Column;
  users_old_updates: Users_Old_Updates;
  users_old_var_pop_fields: ResolverTypeWrapper<Users_Old_Var_Pop_Fields>;
  users_old_var_samp_fields: ResolverTypeWrapper<Users_Old_Var_Samp_Fields>;
  users_old_variance_fields: ResolverTypeWrapper<Users_Old_Variance_Fields>;
  users_on_conflict: Users_On_Conflict;
  users_order_by: Users_Order_By;
  users_pk_columns_input: Users_Pk_Columns_Input;
  users_select_column: Users_Select_Column;
  users_set_input: Users_Set_Input;
  users_stddev_fields: ResolverTypeWrapper<Users_Stddev_Fields>;
  users_stddev_pop_fields: ResolverTypeWrapper<Users_Stddev_Pop_Fields>;
  users_stddev_samp_fields: ResolverTypeWrapper<Users_Stddev_Samp_Fields>;
  users_stream_cursor_input: Users_Stream_Cursor_Input;
  users_stream_cursor_value_input: Users_Stream_Cursor_Value_Input;
  users_sum_fields: ResolverTypeWrapper<Users_Sum_Fields>;
  users_update_column: Users_Update_Column;
  users_updates: Users_Updates;
  users_var_pop_fields: ResolverTypeWrapper<Users_Var_Pop_Fields>;
  users_var_samp_fields: ResolverTypeWrapper<Users_Var_Samp_Fields>;
  users_variance_fields: ResolverTypeWrapper<Users_Variance_Fields>;
  uuid: ResolverTypeWrapper<Scalars['uuid']>;
  uuid_comparison_exp: Uuid_Comparison_Exp;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean'];
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  DepositOutput: DepositOutput;
  Float: Scalars['Float'];
  GeneralMessageOutput: GeneralMessageOutput;
  Int: Scalars['Int'];
  Int_comparison_exp: Int_Comparison_Exp;
  KYCSubmitOutput: KycSubmitOutput;
  NFTConnectedDetail: NftConnectedDetail;
  NFTConnectedOutput: NftConnectedOutput;
  NFTTicketsClaimOptions: NftTicketsClaimOptions;
  NFTTicketsClaimOutput: NftTicketsClaimOutput;
  RateOutput: RateOutput;
  StatusOutput: StatusOutput;
  String: Scalars['String'];
  String_comparison_exp: String_Comparison_Exp;
  SwapOrderOutput: SwapOrderOutput;
  SwapOutput: SwapOutput;
  Test: Test;
  Test_aggregate: Test_Aggregate;
  Test_aggregate_fields: Test_Aggregate_Fields;
  Test_avg_fields: Test_Avg_Fields;
  Test_bool_exp: Test_Bool_Exp;
  Test_inc_input: Test_Inc_Input;
  Test_insert_input: Test_Insert_Input;
  Test_max_fields: Test_Max_Fields;
  Test_min_fields: Test_Min_Fields;
  Test_mutation_response: Test_Mutation_Response;
  Test_on_conflict: Test_On_Conflict;
  Test_order_by: Test_Order_By;
  Test_pk_columns_input: Test_Pk_Columns_Input;
  Test_set_input: Test_Set_Input;
  Test_stddev_fields: Test_Stddev_Fields;
  Test_stddev_pop_fields: Test_Stddev_Pop_Fields;
  Test_stddev_samp_fields: Test_Stddev_Samp_Fields;
  Test_stream_cursor_input: Test_Stream_Cursor_Input;
  Test_stream_cursor_value_input: Test_Stream_Cursor_Value_Input;
  Test_sum_fields: Test_Sum_Fields;
  Test_updates: Test_Updates;
  Test_var_pop_fields: Test_Var_Pop_Fields;
  Test_var_samp_fields: Test_Var_Samp_Fields;
  Test_variance_fields: Test_Variance_Fields;
  UserOutput: UserOutput;
  agentFindUserOutput: AgentFindUserOutput;
  agentGetUsersOutput: AgentGetUsersOutput;
  agentUserItemOutput: AgentUserItemOutput;
  agentUserOutput: AgentUserOutput;
  anno_gpanel: Anno_Gpanel;
  anno_gpanel_aggregate: Anno_Gpanel_Aggregate;
  anno_gpanel_aggregate_fields: Anno_Gpanel_Aggregate_Fields;
  anno_gpanel_avg_fields: Anno_Gpanel_Avg_Fields;
  anno_gpanel_bool_exp: Anno_Gpanel_Bool_Exp;
  anno_gpanel_inc_input: Anno_Gpanel_Inc_Input;
  anno_gpanel_insert_input: Anno_Gpanel_Insert_Input;
  anno_gpanel_max_fields: Anno_Gpanel_Max_Fields;
  anno_gpanel_min_fields: Anno_Gpanel_Min_Fields;
  anno_gpanel_mutation_response: Anno_Gpanel_Mutation_Response;
  anno_gpanel_on_conflict: Anno_Gpanel_On_Conflict;
  anno_gpanel_order_by: Anno_Gpanel_Order_By;
  anno_gpanel_pk_columns_input: Anno_Gpanel_Pk_Columns_Input;
  anno_gpanel_set_input: Anno_Gpanel_Set_Input;
  anno_gpanel_stddev_fields: Anno_Gpanel_Stddev_Fields;
  anno_gpanel_stddev_pop_fields: Anno_Gpanel_Stddev_Pop_Fields;
  anno_gpanel_stddev_samp_fields: Anno_Gpanel_Stddev_Samp_Fields;
  anno_gpanel_stream_cursor_input: Anno_Gpanel_Stream_Cursor_Input;
  anno_gpanel_stream_cursor_value_input: Anno_Gpanel_Stream_Cursor_Value_Input;
  anno_gpanel_sum_fields: Anno_Gpanel_Sum_Fields;
  anno_gpanel_updates: Anno_Gpanel_Updates;
  anno_gpanel_var_pop_fields: Anno_Gpanel_Var_Pop_Fields;
  anno_gpanel_var_samp_fields: Anno_Gpanel_Var_Samp_Fields;
  anno_gpanel_variance_fields: Anno_Gpanel_Variance_Fields;
  authLoginOutput: AuthLoginOutput;
  authSignatureOutput: AuthSignatureOutput;
  avatarOutput: AvatarOutput;
  bigint: Scalars['bigint'];
  bigint_comparison_exp: Bigint_Comparison_Exp;
  blockchain: Blockchain;
  blockchain_aggregate: Blockchain_Aggregate;
  blockchain_aggregate_fields: Blockchain_Aggregate_Fields;
  blockchain_append_input: Blockchain_Append_Input;
  blockchain_avg_fields: Blockchain_Avg_Fields;
  blockchain_bool_exp: Blockchain_Bool_Exp;
  blockchain_delete_at_path_input: Blockchain_Delete_At_Path_Input;
  blockchain_delete_elem_input: Blockchain_Delete_Elem_Input;
  blockchain_delete_key_input: Blockchain_Delete_Key_Input;
  blockchain_inc_input: Blockchain_Inc_Input;
  blockchain_insert_input: Blockchain_Insert_Input;
  blockchain_main_deposit: Blockchain_Main_Deposit;
  blockchain_main_deposit_aggregate: Blockchain_Main_Deposit_Aggregate;
  blockchain_main_deposit_aggregate_fields: Blockchain_Main_Deposit_Aggregate_Fields;
  blockchain_main_deposit_avg_fields: Blockchain_Main_Deposit_Avg_Fields;
  blockchain_main_deposit_bool_exp: Blockchain_Main_Deposit_Bool_Exp;
  blockchain_main_deposit_inc_input: Blockchain_Main_Deposit_Inc_Input;
  blockchain_main_deposit_insert_input: Blockchain_Main_Deposit_Insert_Input;
  blockchain_main_deposit_max_fields: Blockchain_Main_Deposit_Max_Fields;
  blockchain_main_deposit_min_fields: Blockchain_Main_Deposit_Min_Fields;
  blockchain_main_deposit_mutation_response: Blockchain_Main_Deposit_Mutation_Response;
  blockchain_main_deposit_on_conflict: Blockchain_Main_Deposit_On_Conflict;
  blockchain_main_deposit_order_by: Blockchain_Main_Deposit_Order_By;
  blockchain_main_deposit_pk_columns_input: Blockchain_Main_Deposit_Pk_Columns_Input;
  blockchain_main_deposit_set_input: Blockchain_Main_Deposit_Set_Input;
  blockchain_main_deposit_stddev_fields: Blockchain_Main_Deposit_Stddev_Fields;
  blockchain_main_deposit_stddev_pop_fields: Blockchain_Main_Deposit_Stddev_Pop_Fields;
  blockchain_main_deposit_stddev_samp_fields: Blockchain_Main_Deposit_Stddev_Samp_Fields;
  blockchain_main_deposit_stream_cursor_input: Blockchain_Main_Deposit_Stream_Cursor_Input;
  blockchain_main_deposit_stream_cursor_value_input: Blockchain_Main_Deposit_Stream_Cursor_Value_Input;
  blockchain_main_deposit_sum_fields: Blockchain_Main_Deposit_Sum_Fields;
  blockchain_main_deposit_updates: Blockchain_Main_Deposit_Updates;
  blockchain_main_deposit_var_pop_fields: Blockchain_Main_Deposit_Var_Pop_Fields;
  blockchain_main_deposit_var_samp_fields: Blockchain_Main_Deposit_Var_Samp_Fields;
  blockchain_main_deposit_variance_fields: Blockchain_Main_Deposit_Variance_Fields;
  blockchain_max_fields: Blockchain_Max_Fields;
  blockchain_min_fields: Blockchain_Min_Fields;
  blockchain_mutation_response: Blockchain_Mutation_Response;
  blockchain_obj_rel_insert_input: Blockchain_Obj_Rel_Insert_Input;
  blockchain_on_conflict: Blockchain_On_Conflict;
  blockchain_order_by: Blockchain_Order_By;
  blockchain_pk_columns_input: Blockchain_Pk_Columns_Input;
  blockchain_prepend_input: Blockchain_Prepend_Input;
  blockchain_set_input: Blockchain_Set_Input;
  blockchain_stddev_fields: Blockchain_Stddev_Fields;
  blockchain_stddev_pop_fields: Blockchain_Stddev_Pop_Fields;
  blockchain_stddev_samp_fields: Blockchain_Stddev_Samp_Fields;
  blockchain_stream_cursor_input: Blockchain_Stream_Cursor_Input;
  blockchain_stream_cursor_value_input: Blockchain_Stream_Cursor_Value_Input;
  blockchain_sum_fields: Blockchain_Sum_Fields;
  blockchain_updates: Blockchain_Updates;
  blockchain_var_pop_fields: Blockchain_Var_Pop_Fields;
  blockchain_var_samp_fields: Blockchain_Var_Samp_Fields;
  blockchain_variance_fields: Blockchain_Variance_Fields;
  burn_profit: Burn_Profit;
  burn_profit_aggregate: Burn_Profit_Aggregate;
  burn_profit_aggregate_fields: Burn_Profit_Aggregate_Fields;
  burn_profit_avg_fields: Burn_Profit_Avg_Fields;
  burn_profit_bool_exp: Burn_Profit_Bool_Exp;
  burn_profit_inc_input: Burn_Profit_Inc_Input;
  burn_profit_insert_input: Burn_Profit_Insert_Input;
  burn_profit_max_fields: Burn_Profit_Max_Fields;
  burn_profit_min_fields: Burn_Profit_Min_Fields;
  burn_profit_mutation_response: Burn_Profit_Mutation_Response;
  burn_profit_on_conflict: Burn_Profit_On_Conflict;
  burn_profit_order_by: Burn_Profit_Order_By;
  burn_profit_pk_columns_input: Burn_Profit_Pk_Columns_Input;
  burn_profit_set_input: Burn_Profit_Set_Input;
  burn_profit_stddev_fields: Burn_Profit_Stddev_Fields;
  burn_profit_stddev_pop_fields: Burn_Profit_Stddev_Pop_Fields;
  burn_profit_stddev_samp_fields: Burn_Profit_Stddev_Samp_Fields;
  burn_profit_stream_cursor_input: Burn_Profit_Stream_Cursor_Input;
  burn_profit_stream_cursor_value_input: Burn_Profit_Stream_Cursor_Value_Input;
  burn_profit_sum_fields: Burn_Profit_Sum_Fields;
  burn_profit_updates: Burn_Profit_Updates;
  burn_profit_var_pop_fields: Burn_Profit_Var_Pop_Fields;
  burn_profit_var_samp_fields: Burn_Profit_Var_Samp_Fields;
  burn_profit_variance_fields: Burn_Profit_Variance_Fields;
  currencies: Currencies;
  currencies_aggregate: Currencies_Aggregate;
  currencies_aggregate_fields: Currencies_Aggregate_Fields;
  currencies_append_input: Currencies_Append_Input;
  currencies_avg_fields: Currencies_Avg_Fields;
  currencies_bool_exp: Currencies_Bool_Exp;
  currencies_delete_at_path_input: Currencies_Delete_At_Path_Input;
  currencies_delete_elem_input: Currencies_Delete_Elem_Input;
  currencies_delete_key_input: Currencies_Delete_Key_Input;
  currencies_inc_input: Currencies_Inc_Input;
  currencies_insert_input: Currencies_Insert_Input;
  currencies_max_fields: Currencies_Max_Fields;
  currencies_min_fields: Currencies_Min_Fields;
  currencies_mutation_response: Currencies_Mutation_Response;
  currencies_on_conflict: Currencies_On_Conflict;
  currencies_order_by: Currencies_Order_By;
  currencies_pk_columns_input: Currencies_Pk_Columns_Input;
  currencies_prepend_input: Currencies_Prepend_Input;
  currencies_set_input: Currencies_Set_Input;
  currencies_stddev_fields: Currencies_Stddev_Fields;
  currencies_stddev_pop_fields: Currencies_Stddev_Pop_Fields;
  currencies_stddev_samp_fields: Currencies_Stddev_Samp_Fields;
  currencies_stream_cursor_input: Currencies_Stream_Cursor_Input;
  currencies_stream_cursor_value_input: Currencies_Stream_Cursor_Value_Input;
  currencies_sum_fields: Currencies_Sum_Fields;
  currencies_updates: Currencies_Updates;
  currencies_var_pop_fields: Currencies_Var_Pop_Fields;
  currencies_var_samp_fields: Currencies_Var_Samp_Fields;
  currencies_variance_fields: Currencies_Variance_Fields;
  currency_history: Currency_History;
  currency_history_aggregate: Currency_History_Aggregate;
  currency_history_aggregate_fields: Currency_History_Aggregate_Fields;
  currency_history_avg_fields: Currency_History_Avg_Fields;
  currency_history_bool_exp: Currency_History_Bool_Exp;
  currency_history_inc_input: Currency_History_Inc_Input;
  currency_history_insert_input: Currency_History_Insert_Input;
  currency_history_max_fields: Currency_History_Max_Fields;
  currency_history_min_fields: Currency_History_Min_Fields;
  currency_history_mutation_response: Currency_History_Mutation_Response;
  currency_history_on_conflict: Currency_History_On_Conflict;
  currency_history_order_by: Currency_History_Order_By;
  currency_history_pk_columns_input: Currency_History_Pk_Columns_Input;
  currency_history_set_input: Currency_History_Set_Input;
  currency_history_stddev_fields: Currency_History_Stddev_Fields;
  currency_history_stddev_pop_fields: Currency_History_Stddev_Pop_Fields;
  currency_history_stddev_samp_fields: Currency_History_Stddev_Samp_Fields;
  currency_history_stream_cursor_input: Currency_History_Stream_Cursor_Input;
  currency_history_stream_cursor_value_input: Currency_History_Stream_Cursor_Value_Input;
  currency_history_sum_fields: Currency_History_Sum_Fields;
  currency_history_updates: Currency_History_Updates;
  currency_history_var_pop_fields: Currency_History_Var_Pop_Fields;
  currency_history_var_samp_fields: Currency_History_Var_Samp_Fields;
  currency_history_variance_fields: Currency_History_Variance_Fields;
  depositAddressOutput: DepositAddressOutput;
  erc721_addresses: Erc721_Addresses;
  erc721_addresses_aggregate: Erc721_Addresses_Aggregate;
  erc721_addresses_aggregate_fields: Erc721_Addresses_Aggregate_Fields;
  erc721_addresses_avg_fields: Erc721_Addresses_Avg_Fields;
  erc721_addresses_bool_exp: Erc721_Addresses_Bool_Exp;
  erc721_addresses_inc_input: Erc721_Addresses_Inc_Input;
  erc721_addresses_insert_input: Erc721_Addresses_Insert_Input;
  erc721_addresses_max_fields: Erc721_Addresses_Max_Fields;
  erc721_addresses_min_fields: Erc721_Addresses_Min_Fields;
  erc721_addresses_mutation_response: Erc721_Addresses_Mutation_Response;
  erc721_addresses_on_conflict: Erc721_Addresses_On_Conflict;
  erc721_addresses_order_by: Erc721_Addresses_Order_By;
  erc721_addresses_pk_columns_input: Erc721_Addresses_Pk_Columns_Input;
  erc721_addresses_set_input: Erc721_Addresses_Set_Input;
  erc721_addresses_stddev_fields: Erc721_Addresses_Stddev_Fields;
  erc721_addresses_stddev_pop_fields: Erc721_Addresses_Stddev_Pop_Fields;
  erc721_addresses_stddev_samp_fields: Erc721_Addresses_Stddev_Samp_Fields;
  erc721_addresses_stream_cursor_input: Erc721_Addresses_Stream_Cursor_Input;
  erc721_addresses_stream_cursor_value_input: Erc721_Addresses_Stream_Cursor_Value_Input;
  erc721_addresses_sum_fields: Erc721_Addresses_Sum_Fields;
  erc721_addresses_updates: Erc721_Addresses_Updates;
  erc721_addresses_var_pop_fields: Erc721_Addresses_Var_Pop_Fields;
  erc721_addresses_var_samp_fields: Erc721_Addresses_Var_Samp_Fields;
  erc721_addresses_variance_fields: Erc721_Addresses_Variance_Fields;
  evenbetAffiliatePlayerBalances: EvenbetAffiliatePlayerBalances;
  evenbetAffiliatePlayerBalances_aggregate: EvenbetAffiliatePlayerBalances_Aggregate;
  evenbetAffiliatePlayerBalances_aggregate_fields: EvenbetAffiliatePlayerBalances_Aggregate_Fields;
  evenbetAffiliatePlayerBalances_avg_fields: EvenbetAffiliatePlayerBalances_Avg_Fields;
  evenbetAffiliatePlayerBalances_bool_exp: EvenbetAffiliatePlayerBalances_Bool_Exp;
  evenbetAffiliatePlayerBalances_max_fields: EvenbetAffiliatePlayerBalances_Max_Fields;
  evenbetAffiliatePlayerBalances_min_fields: EvenbetAffiliatePlayerBalances_Min_Fields;
  evenbetAffiliatePlayerBalances_order_by: EvenbetAffiliatePlayerBalances_Order_By;
  evenbetAffiliatePlayerBalances_stddev_fields: EvenbetAffiliatePlayerBalances_Stddev_Fields;
  evenbetAffiliatePlayerBalances_stddev_pop_fields: EvenbetAffiliatePlayerBalances_Stddev_Pop_Fields;
  evenbetAffiliatePlayerBalances_stddev_samp_fields: EvenbetAffiliatePlayerBalances_Stddev_Samp_Fields;
  evenbetAffiliatePlayerBalances_stream_cursor_input: EvenbetAffiliatePlayerBalances_Stream_Cursor_Input;
  evenbetAffiliatePlayerBalances_stream_cursor_value_input: EvenbetAffiliatePlayerBalances_Stream_Cursor_Value_Input;
  evenbetAffiliatePlayerBalances_sum_fields: EvenbetAffiliatePlayerBalances_Sum_Fields;
  evenbetAffiliatePlayerBalances_var_pop_fields: EvenbetAffiliatePlayerBalances_Var_Pop_Fields;
  evenbetAffiliatePlayerBalances_var_samp_fields: EvenbetAffiliatePlayerBalances_Var_Samp_Fields;
  evenbetAffiliatePlayerBalances_variance_fields: EvenbetAffiliatePlayerBalances_Variance_Fields;
  evenbet_ticket_requests: Evenbet_Ticket_Requests;
  evenbet_ticket_requests_aggregate: Evenbet_Ticket_Requests_Aggregate;
  evenbet_ticket_requests_aggregate_fields: Evenbet_Ticket_Requests_Aggregate_Fields;
  evenbet_ticket_requests_avg_fields: Evenbet_Ticket_Requests_Avg_Fields;
  evenbet_ticket_requests_bool_exp: Evenbet_Ticket_Requests_Bool_Exp;
  evenbet_ticket_requests_inc_input: Evenbet_Ticket_Requests_Inc_Input;
  evenbet_ticket_requests_insert_input: Evenbet_Ticket_Requests_Insert_Input;
  evenbet_ticket_requests_max_fields: Evenbet_Ticket_Requests_Max_Fields;
  evenbet_ticket_requests_min_fields: Evenbet_Ticket_Requests_Min_Fields;
  evenbet_ticket_requests_mutation_response: Evenbet_Ticket_Requests_Mutation_Response;
  evenbet_ticket_requests_on_conflict: Evenbet_Ticket_Requests_On_Conflict;
  evenbet_ticket_requests_order_by: Evenbet_Ticket_Requests_Order_By;
  evenbet_ticket_requests_pk_columns_input: Evenbet_Ticket_Requests_Pk_Columns_Input;
  evenbet_ticket_requests_set_input: Evenbet_Ticket_Requests_Set_Input;
  evenbet_ticket_requests_stddev_fields: Evenbet_Ticket_Requests_Stddev_Fields;
  evenbet_ticket_requests_stddev_pop_fields: Evenbet_Ticket_Requests_Stddev_Pop_Fields;
  evenbet_ticket_requests_stddev_samp_fields: Evenbet_Ticket_Requests_Stddev_Samp_Fields;
  evenbet_ticket_requests_stream_cursor_input: Evenbet_Ticket_Requests_Stream_Cursor_Input;
  evenbet_ticket_requests_stream_cursor_value_input: Evenbet_Ticket_Requests_Stream_Cursor_Value_Input;
  evenbet_ticket_requests_sum_fields: Evenbet_Ticket_Requests_Sum_Fields;
  evenbet_ticket_requests_updates: Evenbet_Ticket_Requests_Updates;
  evenbet_ticket_requests_var_pop_fields: Evenbet_Ticket_Requests_Var_Pop_Fields;
  evenbet_ticket_requests_var_samp_fields: Evenbet_Ticket_Requests_Var_Samp_Fields;
  evenbet_ticket_requests_variance_fields: Evenbet_Ticket_Requests_Variance_Fields;
  evenbet_tickets: Evenbet_Tickets;
  evenbet_tickets_aggregate: Evenbet_Tickets_Aggregate;
  evenbet_tickets_aggregate_fields: Evenbet_Tickets_Aggregate_Fields;
  evenbet_tickets_avg_fields: Evenbet_Tickets_Avg_Fields;
  evenbet_tickets_bool_exp: Evenbet_Tickets_Bool_Exp;
  evenbet_tickets_inc_input: Evenbet_Tickets_Inc_Input;
  evenbet_tickets_insert_input: Evenbet_Tickets_Insert_Input;
  evenbet_tickets_max_fields: Evenbet_Tickets_Max_Fields;
  evenbet_tickets_min_fields: Evenbet_Tickets_Min_Fields;
  evenbet_tickets_mutation_response: Evenbet_Tickets_Mutation_Response;
  evenbet_tickets_on_conflict: Evenbet_Tickets_On_Conflict;
  evenbet_tickets_order_by: Evenbet_Tickets_Order_By;
  evenbet_tickets_pk_columns_input: Evenbet_Tickets_Pk_Columns_Input;
  evenbet_tickets_set_input: Evenbet_Tickets_Set_Input;
  evenbet_tickets_stddev_fields: Evenbet_Tickets_Stddev_Fields;
  evenbet_tickets_stddev_pop_fields: Evenbet_Tickets_Stddev_Pop_Fields;
  evenbet_tickets_stddev_samp_fields: Evenbet_Tickets_Stddev_Samp_Fields;
  evenbet_tickets_stream_cursor_input: Evenbet_Tickets_Stream_Cursor_Input;
  evenbet_tickets_stream_cursor_value_input: Evenbet_Tickets_Stream_Cursor_Value_Input;
  evenbet_tickets_sum_fields: Evenbet_Tickets_Sum_Fields;
  evenbet_tickets_updates: Evenbet_Tickets_Updates;
  evenbet_tickets_var_pop_fields: Evenbet_Tickets_Var_Pop_Fields;
  evenbet_tickets_var_samp_fields: Evenbet_Tickets_Var_Samp_Fields;
  evenbet_tickets_variance_fields: Evenbet_Tickets_Variance_Fields;
  gPanelOutput: GPanelOutput;
  gpanelPayments: GpanelPayments;
  gpanelPayments_aggregate: GpanelPayments_Aggregate;
  gpanelPayments_aggregate_bool_exp: GpanelPayments_Aggregate_Bool_Exp;
  gpanelPayments_aggregate_bool_exp_count: GpanelPayments_Aggregate_Bool_Exp_Count;
  gpanelPayments_aggregate_fields: GpanelPayments_Aggregate_Fields;
  gpanelPayments_aggregate_order_by: GpanelPayments_Aggregate_Order_By;
  gpanelPayments_arr_rel_insert_input: GpanelPayments_Arr_Rel_Insert_Input;
  gpanelPayments_avg_fields: GpanelPayments_Avg_Fields;
  gpanelPayments_avg_order_by: GpanelPayments_Avg_Order_By;
  gpanelPayments_bool_exp: GpanelPayments_Bool_Exp;
  gpanelPayments_inc_input: GpanelPayments_Inc_Input;
  gpanelPayments_insert_input: GpanelPayments_Insert_Input;
  gpanelPayments_max_fields: GpanelPayments_Max_Fields;
  gpanelPayments_max_order_by: GpanelPayments_Max_Order_By;
  gpanelPayments_min_fields: GpanelPayments_Min_Fields;
  gpanelPayments_min_order_by: GpanelPayments_Min_Order_By;
  gpanelPayments_mutation_response: GpanelPayments_Mutation_Response;
  gpanelPayments_on_conflict: GpanelPayments_On_Conflict;
  gpanelPayments_order_by: GpanelPayments_Order_By;
  gpanelPayments_pk_columns_input: GpanelPayments_Pk_Columns_Input;
  gpanelPayments_set_input: GpanelPayments_Set_Input;
  gpanelPayments_stddev_fields: GpanelPayments_Stddev_Fields;
  gpanelPayments_stddev_order_by: GpanelPayments_Stddev_Order_By;
  gpanelPayments_stddev_pop_fields: GpanelPayments_Stddev_Pop_Fields;
  gpanelPayments_stddev_pop_order_by: GpanelPayments_Stddev_Pop_Order_By;
  gpanelPayments_stddev_samp_fields: GpanelPayments_Stddev_Samp_Fields;
  gpanelPayments_stddev_samp_order_by: GpanelPayments_Stddev_Samp_Order_By;
  gpanelPayments_stream_cursor_input: GpanelPayments_Stream_Cursor_Input;
  gpanelPayments_stream_cursor_value_input: GpanelPayments_Stream_Cursor_Value_Input;
  gpanelPayments_sum_fields: GpanelPayments_Sum_Fields;
  gpanelPayments_sum_order_by: GpanelPayments_Sum_Order_By;
  gpanelPayments_updates: GpanelPayments_Updates;
  gpanelPayments_var_pop_fields: GpanelPayments_Var_Pop_Fields;
  gpanelPayments_var_pop_order_by: GpanelPayments_Var_Pop_Order_By;
  gpanelPayments_var_samp_fields: GpanelPayments_Var_Samp_Fields;
  gpanelPayments_var_samp_order_by: GpanelPayments_Var_Samp_Order_By;
  gpanelPayments_variance_fields: GpanelPayments_Variance_Fields;
  gpanelPayments_variance_order_by: GpanelPayments_Variance_Order_By;
  gpanelUsers: GpanelUsers;
  gpanelUsers_aggregate: GpanelUsers_Aggregate;
  gpanelUsers_aggregate_bool_exp: GpanelUsers_Aggregate_Bool_Exp;
  gpanelUsers_aggregate_bool_exp_bool_and: GpanelUsers_Aggregate_Bool_Exp_Bool_And;
  gpanelUsers_aggregate_bool_exp_bool_or: GpanelUsers_Aggregate_Bool_Exp_Bool_Or;
  gpanelUsers_aggregate_bool_exp_count: GpanelUsers_Aggregate_Bool_Exp_Count;
  gpanelUsers_aggregate_fields: GpanelUsers_Aggregate_Fields;
  gpanelUsers_aggregate_order_by: GpanelUsers_Aggregate_Order_By;
  gpanelUsers_arr_rel_insert_input: GpanelUsers_Arr_Rel_Insert_Input;
  gpanelUsers_avg_fields: GpanelUsers_Avg_Fields;
  gpanelUsers_avg_order_by: GpanelUsers_Avg_Order_By;
  gpanelUsers_bool_exp: GpanelUsers_Bool_Exp;
  gpanelUsers_inc_input: GpanelUsers_Inc_Input;
  gpanelUsers_insert_input: GpanelUsers_Insert_Input;
  gpanelUsers_max_fields: GpanelUsers_Max_Fields;
  gpanelUsers_max_order_by: GpanelUsers_Max_Order_By;
  gpanelUsers_min_fields: GpanelUsers_Min_Fields;
  gpanelUsers_min_order_by: GpanelUsers_Min_Order_By;
  gpanelUsers_mutation_response: GpanelUsers_Mutation_Response;
  gpanelUsers_obj_rel_insert_input: GpanelUsers_Obj_Rel_Insert_Input;
  gpanelUsers_on_conflict: GpanelUsers_On_Conflict;
  gpanelUsers_order_by: GpanelUsers_Order_By;
  gpanelUsers_pk_columns_input: GpanelUsers_Pk_Columns_Input;
  gpanelUsers_set_input: GpanelUsers_Set_Input;
  gpanelUsers_stddev_fields: GpanelUsers_Stddev_Fields;
  gpanelUsers_stddev_order_by: GpanelUsers_Stddev_Order_By;
  gpanelUsers_stddev_pop_fields: GpanelUsers_Stddev_Pop_Fields;
  gpanelUsers_stddev_pop_order_by: GpanelUsers_Stddev_Pop_Order_By;
  gpanelUsers_stddev_samp_fields: GpanelUsers_Stddev_Samp_Fields;
  gpanelUsers_stddev_samp_order_by: GpanelUsers_Stddev_Samp_Order_By;
  gpanelUsers_stream_cursor_input: GpanelUsers_Stream_Cursor_Input;
  gpanelUsers_stream_cursor_value_input: GpanelUsers_Stream_Cursor_Value_Input;
  gpanelUsers_sum_fields: GpanelUsers_Sum_Fields;
  gpanelUsers_sum_order_by: GpanelUsers_Sum_Order_By;
  gpanelUsers_updates: GpanelUsers_Updates;
  gpanelUsers_var_pop_fields: GpanelUsers_Var_Pop_Fields;
  gpanelUsers_var_pop_order_by: GpanelUsers_Var_Pop_Order_By;
  gpanelUsers_var_samp_fields: GpanelUsers_Var_Samp_Fields;
  gpanelUsers_var_samp_order_by: GpanelUsers_Var_Samp_Order_By;
  gpanelUsers_variance_fields: GpanelUsers_Variance_Fields;
  gpanelUsers_variance_order_by: GpanelUsers_Variance_Order_By;
  gpanel_users_agent: Gpanel_Users_Agent;
  gpanel_users_agent_aggregate: Gpanel_Users_Agent_Aggregate;
  gpanel_users_agent_aggregate_fields: Gpanel_Users_Agent_Aggregate_Fields;
  gpanel_users_agent_avg_fields: Gpanel_Users_Agent_Avg_Fields;
  gpanel_users_agent_bool_exp: Gpanel_Users_Agent_Bool_Exp;
  gpanel_users_agent_inc_input: Gpanel_Users_Agent_Inc_Input;
  gpanel_users_agent_insert_input: Gpanel_Users_Agent_Insert_Input;
  gpanel_users_agent_max_fields: Gpanel_Users_Agent_Max_Fields;
  gpanel_users_agent_min_fields: Gpanel_Users_Agent_Min_Fields;
  gpanel_users_agent_mutation_response: Gpanel_Users_Agent_Mutation_Response;
  gpanel_users_agent_on_conflict: Gpanel_Users_Agent_On_Conflict;
  gpanel_users_agent_order_by: Gpanel_Users_Agent_Order_By;
  gpanel_users_agent_pk_columns_input: Gpanel_Users_Agent_Pk_Columns_Input;
  gpanel_users_agent_set_input: Gpanel_Users_Agent_Set_Input;
  gpanel_users_agent_stddev_fields: Gpanel_Users_Agent_Stddev_Fields;
  gpanel_users_agent_stddev_pop_fields: Gpanel_Users_Agent_Stddev_Pop_Fields;
  gpanel_users_agent_stddev_samp_fields: Gpanel_Users_Agent_Stddev_Samp_Fields;
  gpanel_users_agent_stream_cursor_input: Gpanel_Users_Agent_Stream_Cursor_Input;
  gpanel_users_agent_stream_cursor_value_input: Gpanel_Users_Agent_Stream_Cursor_Value_Input;
  gpanel_users_agent_sum_fields: Gpanel_Users_Agent_Sum_Fields;
  gpanel_users_agent_updates: Gpanel_Users_Agent_Updates;
  gpanel_users_agent_var_pop_fields: Gpanel_Users_Agent_Var_Pop_Fields;
  gpanel_users_agent_var_samp_fields: Gpanel_Users_Agent_Var_Samp_Fields;
  gpanel_users_agent_variance_fields: Gpanel_Users_Agent_Variance_Fields;
  gpanel_users_deposit_address: Gpanel_Users_Deposit_Address;
  gpanel_users_deposit_address_aggregate: Gpanel_Users_Deposit_Address_Aggregate;
  gpanel_users_deposit_address_aggregate_fields: Gpanel_Users_Deposit_Address_Aggregate_Fields;
  gpanel_users_deposit_address_avg_fields: Gpanel_Users_Deposit_Address_Avg_Fields;
  gpanel_users_deposit_address_bool_exp: Gpanel_Users_Deposit_Address_Bool_Exp;
  gpanel_users_deposit_address_inc_input: Gpanel_Users_Deposit_Address_Inc_Input;
  gpanel_users_deposit_address_insert_input: Gpanel_Users_Deposit_Address_Insert_Input;
  gpanel_users_deposit_address_max_fields: Gpanel_Users_Deposit_Address_Max_Fields;
  gpanel_users_deposit_address_min_fields: Gpanel_Users_Deposit_Address_Min_Fields;
  gpanel_users_deposit_address_mutation_response: Gpanel_Users_Deposit_Address_Mutation_Response;
  gpanel_users_deposit_address_on_conflict: Gpanel_Users_Deposit_Address_On_Conflict;
  gpanel_users_deposit_address_order_by: Gpanel_Users_Deposit_Address_Order_By;
  gpanel_users_deposit_address_pk_columns_input: Gpanel_Users_Deposit_Address_Pk_Columns_Input;
  gpanel_users_deposit_address_set_input: Gpanel_Users_Deposit_Address_Set_Input;
  gpanel_users_deposit_address_stddev_fields: Gpanel_Users_Deposit_Address_Stddev_Fields;
  gpanel_users_deposit_address_stddev_pop_fields: Gpanel_Users_Deposit_Address_Stddev_Pop_Fields;
  gpanel_users_deposit_address_stddev_samp_fields: Gpanel_Users_Deposit_Address_Stddev_Samp_Fields;
  gpanel_users_deposit_address_stream_cursor_input: Gpanel_Users_Deposit_Address_Stream_Cursor_Input;
  gpanel_users_deposit_address_stream_cursor_value_input: Gpanel_Users_Deposit_Address_Stream_Cursor_Value_Input;
  gpanel_users_deposit_address_sum_fields: Gpanel_Users_Deposit_Address_Sum_Fields;
  gpanel_users_deposit_address_updates: Gpanel_Users_Deposit_Address_Updates;
  gpanel_users_deposit_address_var_pop_fields: Gpanel_Users_Deposit_Address_Var_Pop_Fields;
  gpanel_users_deposit_address_var_samp_fields: Gpanel_Users_Deposit_Address_Var_Samp_Fields;
  gpanel_users_deposit_address_variance_fields: Gpanel_Users_Deposit_Address_Variance_Fields;
  gpanel_users_kyc: Gpanel_Users_Kyc;
  gpanel_users_kyc_aggregate: Gpanel_Users_Kyc_Aggregate;
  gpanel_users_kyc_aggregate_fields: Gpanel_Users_Kyc_Aggregate_Fields;
  gpanel_users_kyc_avg_fields: Gpanel_Users_Kyc_Avg_Fields;
  gpanel_users_kyc_bool_exp: Gpanel_Users_Kyc_Bool_Exp;
  gpanel_users_kyc_inc_input: Gpanel_Users_Kyc_Inc_Input;
  gpanel_users_kyc_insert_input: Gpanel_Users_Kyc_Insert_Input;
  gpanel_users_kyc_max_fields: Gpanel_Users_Kyc_Max_Fields;
  gpanel_users_kyc_min_fields: Gpanel_Users_Kyc_Min_Fields;
  gpanel_users_kyc_mutation_response: Gpanel_Users_Kyc_Mutation_Response;
  gpanel_users_kyc_on_conflict: Gpanel_Users_Kyc_On_Conflict;
  gpanel_users_kyc_order_by: Gpanel_Users_Kyc_Order_By;
  gpanel_users_kyc_pk_columns_input: Gpanel_Users_Kyc_Pk_Columns_Input;
  gpanel_users_kyc_set_input: Gpanel_Users_Kyc_Set_Input;
  gpanel_users_kyc_stddev_fields: Gpanel_Users_Kyc_Stddev_Fields;
  gpanel_users_kyc_stddev_pop_fields: Gpanel_Users_Kyc_Stddev_Pop_Fields;
  gpanel_users_kyc_stddev_samp_fields: Gpanel_Users_Kyc_Stddev_Samp_Fields;
  gpanel_users_kyc_stream_cursor_input: Gpanel_Users_Kyc_Stream_Cursor_Input;
  gpanel_users_kyc_stream_cursor_value_input: Gpanel_Users_Kyc_Stream_Cursor_Value_Input;
  gpanel_users_kyc_sum_fields: Gpanel_Users_Kyc_Sum_Fields;
  gpanel_users_kyc_updates: Gpanel_Users_Kyc_Updates;
  gpanel_users_kyc_var_pop_fields: Gpanel_Users_Kyc_Var_Pop_Fields;
  gpanel_users_kyc_var_samp_fields: Gpanel_Users_Kyc_Var_Samp_Fields;
  gpanel_users_kyc_variance_fields: Gpanel_Users_Kyc_Variance_Fields;
  json: Scalars['json'];
  json_comparison_exp: Json_Comparison_Exp;
  jsonb: Scalars['jsonb'];
  jsonb_cast_exp: Jsonb_Cast_Exp;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  kycOutput: KycOutput;
  kycs: Kycs;
  kycs_aggregate: Kycs_Aggregate;
  kycs_aggregate_fields: Kycs_Aggregate_Fields;
  kycs_avg_fields: Kycs_Avg_Fields;
  kycs_bool_exp: Kycs_Bool_Exp;
  kycs_inc_input: Kycs_Inc_Input;
  kycs_insert_input: Kycs_Insert_Input;
  kycs_max_fields: Kycs_Max_Fields;
  kycs_min_fields: Kycs_Min_Fields;
  kycs_mutation_response: Kycs_Mutation_Response;
  kycs_obj_rel_insert_input: Kycs_Obj_Rel_Insert_Input;
  kycs_on_conflict: Kycs_On_Conflict;
  kycs_order_by: Kycs_Order_By;
  kycs_pk_columns_input: Kycs_Pk_Columns_Input;
  kycs_set_input: Kycs_Set_Input;
  kycs_stddev_fields: Kycs_Stddev_Fields;
  kycs_stddev_pop_fields: Kycs_Stddev_Pop_Fields;
  kycs_stddev_samp_fields: Kycs_Stddev_Samp_Fields;
  kycs_stream_cursor_input: Kycs_Stream_Cursor_Input;
  kycs_stream_cursor_value_input: Kycs_Stream_Cursor_Value_Input;
  kycs_sum_fields: Kycs_Sum_Fields;
  kycs_updates: Kycs_Updates;
  kycs_var_pop_fields: Kycs_Var_Pop_Fields;
  kycs_var_samp_fields: Kycs_Var_Samp_Fields;
  kycs_variance_fields: Kycs_Variance_Fields;
  leaderboard: Leaderboard;
  leaderboard_aggregate: Leaderboard_Aggregate;
  leaderboard_aggregate_fields: Leaderboard_Aggregate_Fields;
  leaderboard_avg_fields: Leaderboard_Avg_Fields;
  leaderboard_bool_exp: Leaderboard_Bool_Exp;
  leaderboard_inc_input: Leaderboard_Inc_Input;
  leaderboard_insert_input: Leaderboard_Insert_Input;
  leaderboard_max_fields: Leaderboard_Max_Fields;
  leaderboard_min_fields: Leaderboard_Min_Fields;
  leaderboard_mutation_response: Leaderboard_Mutation_Response;
  leaderboard_on_conflict: Leaderboard_On_Conflict;
  leaderboard_order_by: Leaderboard_Order_By;
  leaderboard_pk_columns_input: Leaderboard_Pk_Columns_Input;
  leaderboard_ranking: Leaderboard_Ranking;
  leaderboard_ranking_aggregate: Leaderboard_Ranking_Aggregate;
  leaderboard_ranking_aggregate_fields: Leaderboard_Ranking_Aggregate_Fields;
  leaderboard_ranking_avg_fields: Leaderboard_Ranking_Avg_Fields;
  leaderboard_ranking_bool_exp: Leaderboard_Ranking_Bool_Exp;
  leaderboard_ranking_inc_input: Leaderboard_Ranking_Inc_Input;
  leaderboard_ranking_insert_input: Leaderboard_Ranking_Insert_Input;
  leaderboard_ranking_max_fields: Leaderboard_Ranking_Max_Fields;
  leaderboard_ranking_min_fields: Leaderboard_Ranking_Min_Fields;
  leaderboard_ranking_mutation_response: Leaderboard_Ranking_Mutation_Response;
  leaderboard_ranking_on_conflict: Leaderboard_Ranking_On_Conflict;
  leaderboard_ranking_order_by: Leaderboard_Ranking_Order_By;
  leaderboard_ranking_pk_columns_input: Leaderboard_Ranking_Pk_Columns_Input;
  leaderboard_ranking_set_input: Leaderboard_Ranking_Set_Input;
  leaderboard_ranking_stddev_fields: Leaderboard_Ranking_Stddev_Fields;
  leaderboard_ranking_stddev_pop_fields: Leaderboard_Ranking_Stddev_Pop_Fields;
  leaderboard_ranking_stddev_samp_fields: Leaderboard_Ranking_Stddev_Samp_Fields;
  leaderboard_ranking_stream_cursor_input: Leaderboard_Ranking_Stream_Cursor_Input;
  leaderboard_ranking_stream_cursor_value_input: Leaderboard_Ranking_Stream_Cursor_Value_Input;
  leaderboard_ranking_sum_fields: Leaderboard_Ranking_Sum_Fields;
  leaderboard_ranking_updates: Leaderboard_Ranking_Updates;
  leaderboard_ranking_var_pop_fields: Leaderboard_Ranking_Var_Pop_Fields;
  leaderboard_ranking_var_samp_fields: Leaderboard_Ranking_Var_Samp_Fields;
  leaderboard_ranking_variance_fields: Leaderboard_Ranking_Variance_Fields;
  leaderboard_set_input: Leaderboard_Set_Input;
  leaderboard_stddev_fields: Leaderboard_Stddev_Fields;
  leaderboard_stddev_pop_fields: Leaderboard_Stddev_Pop_Fields;
  leaderboard_stddev_samp_fields: Leaderboard_Stddev_Samp_Fields;
  leaderboard_stream_cursor_input: Leaderboard_Stream_Cursor_Input;
  leaderboard_stream_cursor_value_input: Leaderboard_Stream_Cursor_Value_Input;
  leaderboard_sum_fields: Leaderboard_Sum_Fields;
  leaderboard_updates: Leaderboard_Updates;
  leaderboard_var_pop_fields: Leaderboard_Var_Pop_Fields;
  leaderboard_var_samp_fields: Leaderboard_Var_Samp_Fields;
  leaderboard_variance_fields: Leaderboard_Variance_Fields;
  liquidity_pools: Liquidity_Pools;
  liquidity_pools_aggregate: Liquidity_Pools_Aggregate;
  liquidity_pools_aggregate_fields: Liquidity_Pools_Aggregate_Fields;
  liquidity_pools_avg_fields: Liquidity_Pools_Avg_Fields;
  liquidity_pools_bool_exp: Liquidity_Pools_Bool_Exp;
  liquidity_pools_inc_input: Liquidity_Pools_Inc_Input;
  liquidity_pools_insert_input: Liquidity_Pools_Insert_Input;
  liquidity_pools_max_fields: Liquidity_Pools_Max_Fields;
  liquidity_pools_min_fields: Liquidity_Pools_Min_Fields;
  liquidity_pools_mutation_response: Liquidity_Pools_Mutation_Response;
  liquidity_pools_on_conflict: Liquidity_Pools_On_Conflict;
  liquidity_pools_order_by: Liquidity_Pools_Order_By;
  liquidity_pools_pk_columns_input: Liquidity_Pools_Pk_Columns_Input;
  liquidity_pools_set_input: Liquidity_Pools_Set_Input;
  liquidity_pools_stddev_fields: Liquidity_Pools_Stddev_Fields;
  liquidity_pools_stddev_pop_fields: Liquidity_Pools_Stddev_Pop_Fields;
  liquidity_pools_stddev_samp_fields: Liquidity_Pools_Stddev_Samp_Fields;
  liquidity_pools_stream_cursor_input: Liquidity_Pools_Stream_Cursor_Input;
  liquidity_pools_stream_cursor_value_input: Liquidity_Pools_Stream_Cursor_Value_Input;
  liquidity_pools_sum_fields: Liquidity_Pools_Sum_Fields;
  liquidity_pools_updates: Liquidity_Pools_Updates;
  liquidity_pools_var_pop_fields: Liquidity_Pools_Var_Pop_Fields;
  liquidity_pools_var_samp_fields: Liquidity_Pools_Var_Samp_Fields;
  liquidity_pools_variance_fields: Liquidity_Pools_Variance_Fields;
  marketListings: MarketListings;
  marketListings_aggregate: MarketListings_Aggregate;
  marketListings_aggregate_fields: MarketListings_Aggregate_Fields;
  marketListings_avg_fields: MarketListings_Avg_Fields;
  marketListings_bool_exp: MarketListings_Bool_Exp;
  marketListings_inc_input: MarketListings_Inc_Input;
  marketListings_insert_input: MarketListings_Insert_Input;
  marketListings_max_fields: MarketListings_Max_Fields;
  marketListings_min_fields: MarketListings_Min_Fields;
  marketListings_mutation_response: MarketListings_Mutation_Response;
  marketListings_obj_rel_insert_input: MarketListings_Obj_Rel_Insert_Input;
  marketListings_on_conflict: MarketListings_On_Conflict;
  marketListings_order_by: MarketListings_Order_By;
  marketListings_pk_columns_input: MarketListings_Pk_Columns_Input;
  marketListings_set_input: MarketListings_Set_Input;
  marketListings_stddev_fields: MarketListings_Stddev_Fields;
  marketListings_stddev_pop_fields: MarketListings_Stddev_Pop_Fields;
  marketListings_stddev_samp_fields: MarketListings_Stddev_Samp_Fields;
  marketListings_stream_cursor_input: MarketListings_Stream_Cursor_Input;
  marketListings_stream_cursor_value_input: MarketListings_Stream_Cursor_Value_Input;
  marketListings_sum_fields: MarketListings_Sum_Fields;
  marketListings_updates: MarketListings_Updates;
  marketListings_var_pop_fields: MarketListings_Var_Pop_Fields;
  marketListings_var_samp_fields: MarketListings_Var_Samp_Fields;
  marketListings_variance_fields: MarketListings_Variance_Fields;
  marketplaceContracts: MarketplaceContracts;
  marketplaceContracts_aggregate: MarketplaceContracts_Aggregate;
  marketplaceContracts_aggregate_fields: MarketplaceContracts_Aggregate_Fields;
  marketplaceContracts_avg_fields: MarketplaceContracts_Avg_Fields;
  marketplaceContracts_bool_exp: MarketplaceContracts_Bool_Exp;
  marketplaceContracts_inc_input: MarketplaceContracts_Inc_Input;
  marketplaceContracts_insert_input: MarketplaceContracts_Insert_Input;
  marketplaceContracts_max_fields: MarketplaceContracts_Max_Fields;
  marketplaceContracts_min_fields: MarketplaceContracts_Min_Fields;
  marketplaceContracts_mutation_response: MarketplaceContracts_Mutation_Response;
  marketplaceContracts_obj_rel_insert_input: MarketplaceContracts_Obj_Rel_Insert_Input;
  marketplaceContracts_on_conflict: MarketplaceContracts_On_Conflict;
  marketplaceContracts_order_by: MarketplaceContracts_Order_By;
  marketplaceContracts_pk_columns_input: MarketplaceContracts_Pk_Columns_Input;
  marketplaceContracts_set_input: MarketplaceContracts_Set_Input;
  marketplaceContracts_stddev_fields: MarketplaceContracts_Stddev_Fields;
  marketplaceContracts_stddev_pop_fields: MarketplaceContracts_Stddev_Pop_Fields;
  marketplaceContracts_stddev_samp_fields: MarketplaceContracts_Stddev_Samp_Fields;
  marketplaceContracts_stream_cursor_input: MarketplaceContracts_Stream_Cursor_Input;
  marketplaceContracts_stream_cursor_value_input: MarketplaceContracts_Stream_Cursor_Value_Input;
  marketplaceContracts_sum_fields: MarketplaceContracts_Sum_Fields;
  marketplaceContracts_updates: MarketplaceContracts_Updates;
  marketplaceContracts_var_pop_fields: MarketplaceContracts_Var_Pop_Fields;
  marketplaceContracts_var_samp_fields: MarketplaceContracts_Var_Samp_Fields;
  marketplaceContracts_variance_fields: MarketplaceContracts_Variance_Fields;
  mutation_root: {};
  nftContracts: NftContracts;
  nftContracts_aggregate: NftContracts_Aggregate;
  nftContracts_aggregate_fields: NftContracts_Aggregate_Fields;
  nftContracts_avg_fields: NftContracts_Avg_Fields;
  nftContracts_bool_exp: NftContracts_Bool_Exp;
  nftContracts_inc_input: NftContracts_Inc_Input;
  nftContracts_insert_input: NftContracts_Insert_Input;
  nftContracts_max_fields: NftContracts_Max_Fields;
  nftContracts_min_fields: NftContracts_Min_Fields;
  nftContracts_mutation_response: NftContracts_Mutation_Response;
  nftContracts_obj_rel_insert_input: NftContracts_Obj_Rel_Insert_Input;
  nftContracts_on_conflict: NftContracts_On_Conflict;
  nftContracts_order_by: NftContracts_Order_By;
  nftContracts_pk_columns_input: NftContracts_Pk_Columns_Input;
  nftContracts_set_input: NftContracts_Set_Input;
  nftContracts_stddev_fields: NftContracts_Stddev_Fields;
  nftContracts_stddev_pop_fields: NftContracts_Stddev_Pop_Fields;
  nftContracts_stddev_samp_fields: NftContracts_Stddev_Samp_Fields;
  nftContracts_stream_cursor_input: NftContracts_Stream_Cursor_Input;
  nftContracts_stream_cursor_value_input: NftContracts_Stream_Cursor_Value_Input;
  nftContracts_sum_fields: NftContracts_Sum_Fields;
  nftContracts_updates: NftContracts_Updates;
  nftContracts_var_pop_fields: NftContracts_Var_Pop_Fields;
  nftContracts_var_samp_fields: NftContracts_Var_Samp_Fields;
  nftContracts_variance_fields: NftContracts_Variance_Fields;
  nftMetadatas: NftMetadatas;
  nftMetadatas_aggregate: NftMetadatas_Aggregate;
  nftMetadatas_aggregate_fields: NftMetadatas_Aggregate_Fields;
  nftMetadatas_avg_fields: NftMetadatas_Avg_Fields;
  nftMetadatas_bool_exp: NftMetadatas_Bool_Exp;
  nftMetadatas_inc_input: NftMetadatas_Inc_Input;
  nftMetadatas_insert_input: NftMetadatas_Insert_Input;
  nftMetadatas_max_fields: NftMetadatas_Max_Fields;
  nftMetadatas_min_fields: NftMetadatas_Min_Fields;
  nftMetadatas_mutation_response: NftMetadatas_Mutation_Response;
  nftMetadatas_obj_rel_insert_input: NftMetadatas_Obj_Rel_Insert_Input;
  nftMetadatas_on_conflict: NftMetadatas_On_Conflict;
  nftMetadatas_order_by: NftMetadatas_Order_By;
  nftMetadatas_pk_columns_input: NftMetadatas_Pk_Columns_Input;
  nftMetadatas_set_input: NftMetadatas_Set_Input;
  nftMetadatas_stddev_fields: NftMetadatas_Stddev_Fields;
  nftMetadatas_stddev_pop_fields: NftMetadatas_Stddev_Pop_Fields;
  nftMetadatas_stddev_samp_fields: NftMetadatas_Stddev_Samp_Fields;
  nftMetadatas_stream_cursor_input: NftMetadatas_Stream_Cursor_Input;
  nftMetadatas_stream_cursor_value_input: NftMetadatas_Stream_Cursor_Value_Input;
  nftMetadatas_sum_fields: NftMetadatas_Sum_Fields;
  nftMetadatas_updates: NftMetadatas_Updates;
  nftMetadatas_var_pop_fields: NftMetadatas_Var_Pop_Fields;
  nftMetadatas_var_samp_fields: NftMetadatas_Var_Samp_Fields;
  nftMetadatas_variance_fields: NftMetadatas_Variance_Fields;
  nftOwners: NftOwners;
  nftOwners_aggregate: NftOwners_Aggregate;
  nftOwners_aggregate_bool_exp: NftOwners_Aggregate_Bool_Exp;
  nftOwners_aggregate_bool_exp_bool_and: NftOwners_Aggregate_Bool_Exp_Bool_And;
  nftOwners_aggregate_bool_exp_bool_or: NftOwners_Aggregate_Bool_Exp_Bool_Or;
  nftOwners_aggregate_bool_exp_count: NftOwners_Aggregate_Bool_Exp_Count;
  nftOwners_aggregate_fields: NftOwners_Aggregate_Fields;
  nftOwners_aggregate_order_by: NftOwners_Aggregate_Order_By;
  nftOwners_arr_rel_insert_input: NftOwners_Arr_Rel_Insert_Input;
  nftOwners_avg_fields: NftOwners_Avg_Fields;
  nftOwners_avg_order_by: NftOwners_Avg_Order_By;
  nftOwners_bool_exp: NftOwners_Bool_Exp;
  nftOwners_inc_input: NftOwners_Inc_Input;
  nftOwners_insert_input: NftOwners_Insert_Input;
  nftOwners_max_fields: NftOwners_Max_Fields;
  nftOwners_max_order_by: NftOwners_Max_Order_By;
  nftOwners_min_fields: NftOwners_Min_Fields;
  nftOwners_min_order_by: NftOwners_Min_Order_By;
  nftOwners_mutation_response: NftOwners_Mutation_Response;
  nftOwners_obj_rel_insert_input: NftOwners_Obj_Rel_Insert_Input;
  nftOwners_on_conflict: NftOwners_On_Conflict;
  nftOwners_order_by: NftOwners_Order_By;
  nftOwners_pk_columns_input: NftOwners_Pk_Columns_Input;
  nftOwners_set_input: NftOwners_Set_Input;
  nftOwners_stddev_fields: NftOwners_Stddev_Fields;
  nftOwners_stddev_order_by: NftOwners_Stddev_Order_By;
  nftOwners_stddev_pop_fields: NftOwners_Stddev_Pop_Fields;
  nftOwners_stddev_pop_order_by: NftOwners_Stddev_Pop_Order_By;
  nftOwners_stddev_samp_fields: NftOwners_Stddev_Samp_Fields;
  nftOwners_stddev_samp_order_by: NftOwners_Stddev_Samp_Order_By;
  nftOwners_stream_cursor_input: NftOwners_Stream_Cursor_Input;
  nftOwners_stream_cursor_value_input: NftOwners_Stream_Cursor_Value_Input;
  nftOwners_sum_fields: NftOwners_Sum_Fields;
  nftOwners_sum_order_by: NftOwners_Sum_Order_By;
  nftOwners_updates: NftOwners_Updates;
  nftOwners_var_pop_fields: NftOwners_Var_Pop_Fields;
  nftOwners_var_pop_order_by: NftOwners_Var_Pop_Order_By;
  nftOwners_var_samp_fields: NftOwners_Var_Samp_Fields;
  nftOwners_var_samp_order_by: NftOwners_Var_Samp_Order_By;
  nftOwners_variance_fields: NftOwners_Variance_Fields;
  nftOwners_variance_order_by: NftOwners_Variance_Order_By;
  nft_gpanel_users: Nft_Gpanel_Users;
  nft_gpanel_users_aggregate: Nft_Gpanel_Users_Aggregate;
  nft_gpanel_users_aggregate_fields: Nft_Gpanel_Users_Aggregate_Fields;
  nft_gpanel_users_avg_fields: Nft_Gpanel_Users_Avg_Fields;
  nft_gpanel_users_bool_exp: Nft_Gpanel_Users_Bool_Exp;
  nft_gpanel_users_inc_input: Nft_Gpanel_Users_Inc_Input;
  nft_gpanel_users_insert_input: Nft_Gpanel_Users_Insert_Input;
  nft_gpanel_users_max_fields: Nft_Gpanel_Users_Max_Fields;
  nft_gpanel_users_min_fields: Nft_Gpanel_Users_Min_Fields;
  nft_gpanel_users_mutation_response: Nft_Gpanel_Users_Mutation_Response;
  nft_gpanel_users_obj_rel_insert_input: Nft_Gpanel_Users_Obj_Rel_Insert_Input;
  nft_gpanel_users_on_conflict: Nft_Gpanel_Users_On_Conflict;
  nft_gpanel_users_order_by: Nft_Gpanel_Users_Order_By;
  nft_gpanel_users_pk_columns_input: Nft_Gpanel_Users_Pk_Columns_Input;
  nft_gpanel_users_set_input: Nft_Gpanel_Users_Set_Input;
  nft_gpanel_users_stddev_fields: Nft_Gpanel_Users_Stddev_Fields;
  nft_gpanel_users_stddev_pop_fields: Nft_Gpanel_Users_Stddev_Pop_Fields;
  nft_gpanel_users_stddev_samp_fields: Nft_Gpanel_Users_Stddev_Samp_Fields;
  nft_gpanel_users_stream_cursor_input: Nft_Gpanel_Users_Stream_Cursor_Input;
  nft_gpanel_users_stream_cursor_value_input: Nft_Gpanel_Users_Stream_Cursor_Value_Input;
  nft_gpanel_users_sum_fields: Nft_Gpanel_Users_Sum_Fields;
  nft_gpanel_users_updates: Nft_Gpanel_Users_Updates;
  nft_gpanel_users_var_pop_fields: Nft_Gpanel_Users_Var_Pop_Fields;
  nft_gpanel_users_var_samp_fields: Nft_Gpanel_Users_Var_Samp_Fields;
  nft_gpanel_users_variance_fields: Nft_Gpanel_Users_Variance_Fields;
  nft_listings: Nft_Listings;
  nft_listings_aggregate: Nft_Listings_Aggregate;
  nft_listings_aggregate_fields: Nft_Listings_Aggregate_Fields;
  nft_listings_avg_fields: Nft_Listings_Avg_Fields;
  nft_listings_bool_exp: Nft_Listings_Bool_Exp;
  nft_listings_inc_input: Nft_Listings_Inc_Input;
  nft_listings_insert_input: Nft_Listings_Insert_Input;
  nft_listings_max_fields: Nft_Listings_Max_Fields;
  nft_listings_min_fields: Nft_Listings_Min_Fields;
  nft_listings_mutation_response: Nft_Listings_Mutation_Response;
  nft_listings_on_conflict: Nft_Listings_On_Conflict;
  nft_listings_order_by: Nft_Listings_Order_By;
  nft_listings_pk_columns_input: Nft_Listings_Pk_Columns_Input;
  nft_listings_set_input: Nft_Listings_Set_Input;
  nft_listings_stddev_fields: Nft_Listings_Stddev_Fields;
  nft_listings_stddev_pop_fields: Nft_Listings_Stddev_Pop_Fields;
  nft_listings_stddev_samp_fields: Nft_Listings_Stddev_Samp_Fields;
  nft_listings_stream_cursor_input: Nft_Listings_Stream_Cursor_Input;
  nft_listings_stream_cursor_value_input: Nft_Listings_Stream_Cursor_Value_Input;
  nft_listings_sum_fields: Nft_Listings_Sum_Fields;
  nft_listings_updates: Nft_Listings_Updates;
  nft_listings_var_pop_fields: Nft_Listings_Var_Pop_Fields;
  nft_listings_var_samp_fields: Nft_Listings_Var_Samp_Fields;
  nft_listings_variance_fields: Nft_Listings_Variance_Fields;
  nft_transactions: Nft_Transactions;
  nft_transactions_aggregate: Nft_Transactions_Aggregate;
  nft_transactions_aggregate_fields: Nft_Transactions_Aggregate_Fields;
  nft_transactions_avg_fields: Nft_Transactions_Avg_Fields;
  nft_transactions_bool_exp: Nft_Transactions_Bool_Exp;
  nft_transactions_inc_input: Nft_Transactions_Inc_Input;
  nft_transactions_insert_input: Nft_Transactions_Insert_Input;
  nft_transactions_max_fields: Nft_Transactions_Max_Fields;
  nft_transactions_min_fields: Nft_Transactions_Min_Fields;
  nft_transactions_mutation_response: Nft_Transactions_Mutation_Response;
  nft_transactions_on_conflict: Nft_Transactions_On_Conflict;
  nft_transactions_order_by: Nft_Transactions_Order_By;
  nft_transactions_pk_columns_input: Nft_Transactions_Pk_Columns_Input;
  nft_transactions_set_input: Nft_Transactions_Set_Input;
  nft_transactions_stddev_fields: Nft_Transactions_Stddev_Fields;
  nft_transactions_stddev_pop_fields: Nft_Transactions_Stddev_Pop_Fields;
  nft_transactions_stddev_samp_fields: Nft_Transactions_Stddev_Samp_Fields;
  nft_transactions_stream_cursor_input: Nft_Transactions_Stream_Cursor_Input;
  nft_transactions_stream_cursor_value_input: Nft_Transactions_Stream_Cursor_Value_Input;
  nft_transactions_sum_fields: Nft_Transactions_Sum_Fields;
  nft_transactions_updates: Nft_Transactions_Updates;
  nft_transactions_var_pop_fields: Nft_Transactions_Var_Pop_Fields;
  nft_transactions_var_samp_fields: Nft_Transactions_Var_Samp_Fields;
  nft_transactions_variance_fields: Nft_Transactions_Variance_Fields;
  nfts: Nfts;
  nfts_aggregate: Nfts_Aggregate;
  nfts_aggregate_fields: Nfts_Aggregate_Fields;
  nfts_avg_fields: Nfts_Avg_Fields;
  nfts_bool_exp: Nfts_Bool_Exp;
  nfts_inc_input: Nfts_Inc_Input;
  nfts_insert_input: Nfts_Insert_Input;
  nfts_max_fields: Nfts_Max_Fields;
  nfts_min_fields: Nfts_Min_Fields;
  nfts_mutation_response: Nfts_Mutation_Response;
  nfts_obj_rel_insert_input: Nfts_Obj_Rel_Insert_Input;
  nfts_on_conflict: Nfts_On_Conflict;
  nfts_order_by: Nfts_Order_By;
  nfts_pk_columns_input: Nfts_Pk_Columns_Input;
  nfts_set_input: Nfts_Set_Input;
  nfts_stddev_fields: Nfts_Stddev_Fields;
  nfts_stddev_pop_fields: Nfts_Stddev_Pop_Fields;
  nfts_stddev_samp_fields: Nfts_Stddev_Samp_Fields;
  nfts_stream_cursor_input: Nfts_Stream_Cursor_Input;
  nfts_stream_cursor_value_input: Nfts_Stream_Cursor_Value_Input;
  nfts_sum_fields: Nfts_Sum_Fields;
  nfts_updates: Nfts_Updates;
  nfts_var_pop_fields: Nfts_Var_Pop_Fields;
  nfts_var_samp_fields: Nfts_Var_Samp_Fields;
  nfts_variance_fields: Nfts_Variance_Fields;
  numeric: Scalars['numeric'];
  numeric_comparison_exp: Numeric_Comparison_Exp;
  pendingTransactions: PendingTransactions;
  pendingTransactions_aggregate: PendingTransactions_Aggregate;
  pendingTransactions_aggregate_bool_exp: PendingTransactions_Aggregate_Bool_Exp;
  pendingTransactions_aggregate_bool_exp_bool_and: PendingTransactions_Aggregate_Bool_Exp_Bool_And;
  pendingTransactions_aggregate_bool_exp_bool_or: PendingTransactions_Aggregate_Bool_Exp_Bool_Or;
  pendingTransactions_aggregate_bool_exp_count: PendingTransactions_Aggregate_Bool_Exp_Count;
  pendingTransactions_aggregate_fields: PendingTransactions_Aggregate_Fields;
  pendingTransactions_aggregate_order_by: PendingTransactions_Aggregate_Order_By;
  pendingTransactions_arr_rel_insert_input: PendingTransactions_Arr_Rel_Insert_Input;
  pendingTransactions_avg_fields: PendingTransactions_Avg_Fields;
  pendingTransactions_avg_order_by: PendingTransactions_Avg_Order_By;
  pendingTransactions_bool_exp: PendingTransactions_Bool_Exp;
  pendingTransactions_inc_input: PendingTransactions_Inc_Input;
  pendingTransactions_insert_input: PendingTransactions_Insert_Input;
  pendingTransactions_max_fields: PendingTransactions_Max_Fields;
  pendingTransactions_max_order_by: PendingTransactions_Max_Order_By;
  pendingTransactions_min_fields: PendingTransactions_Min_Fields;
  pendingTransactions_min_order_by: PendingTransactions_Min_Order_By;
  pendingTransactions_mutation_response: PendingTransactions_Mutation_Response;
  pendingTransactions_on_conflict: PendingTransactions_On_Conflict;
  pendingTransactions_order_by: PendingTransactions_Order_By;
  pendingTransactions_pk_columns_input: PendingTransactions_Pk_Columns_Input;
  pendingTransactions_set_input: PendingTransactions_Set_Input;
  pendingTransactions_stddev_fields: PendingTransactions_Stddev_Fields;
  pendingTransactions_stddev_order_by: PendingTransactions_Stddev_Order_By;
  pendingTransactions_stddev_pop_fields: PendingTransactions_Stddev_Pop_Fields;
  pendingTransactions_stddev_pop_order_by: PendingTransactions_Stddev_Pop_Order_By;
  pendingTransactions_stddev_samp_fields: PendingTransactions_Stddev_Samp_Fields;
  pendingTransactions_stddev_samp_order_by: PendingTransactions_Stddev_Samp_Order_By;
  pendingTransactions_stream_cursor_input: PendingTransactions_Stream_Cursor_Input;
  pendingTransactions_stream_cursor_value_input: PendingTransactions_Stream_Cursor_Value_Input;
  pendingTransactions_sum_fields: PendingTransactions_Sum_Fields;
  pendingTransactions_sum_order_by: PendingTransactions_Sum_Order_By;
  pendingTransactions_updates: PendingTransactions_Updates;
  pendingTransactions_var_pop_fields: PendingTransactions_Var_Pop_Fields;
  pendingTransactions_var_pop_order_by: PendingTransactions_Var_Pop_Order_By;
  pendingTransactions_var_samp_fields: PendingTransactions_Var_Samp_Fields;
  pendingTransactions_var_samp_order_by: PendingTransactions_Var_Samp_Order_By;
  pendingTransactions_variance_fields: PendingTransactions_Variance_Fields;
  pendingTransactions_variance_order_by: PendingTransactions_Variance_Order_By;
  pending_tx: Pending_Tx;
  pending_tx_aggregate: Pending_Tx_Aggregate;
  pending_tx_aggregate_bool_exp: Pending_Tx_Aggregate_Bool_Exp;
  pending_tx_aggregate_bool_exp_count: Pending_Tx_Aggregate_Bool_Exp_Count;
  pending_tx_aggregate_fields: Pending_Tx_Aggregate_Fields;
  pending_tx_aggregate_order_by: Pending_Tx_Aggregate_Order_By;
  pending_tx_arr_rel_insert_input: Pending_Tx_Arr_Rel_Insert_Input;
  pending_tx_avg_fields: Pending_Tx_Avg_Fields;
  pending_tx_avg_order_by: Pending_Tx_Avg_Order_By;
  pending_tx_bool_exp: Pending_Tx_Bool_Exp;
  pending_tx_inc_input: Pending_Tx_Inc_Input;
  pending_tx_insert_input: Pending_Tx_Insert_Input;
  pending_tx_max_fields: Pending_Tx_Max_Fields;
  pending_tx_max_order_by: Pending_Tx_Max_Order_By;
  pending_tx_min_fields: Pending_Tx_Min_Fields;
  pending_tx_min_order_by: Pending_Tx_Min_Order_By;
  pending_tx_mutation_response: Pending_Tx_Mutation_Response;
  pending_tx_on_conflict: Pending_Tx_On_Conflict;
  pending_tx_order_by: Pending_Tx_Order_By;
  pending_tx_pk_columns_input: Pending_Tx_Pk_Columns_Input;
  pending_tx_set_input: Pending_Tx_Set_Input;
  pending_tx_stddev_fields: Pending_Tx_Stddev_Fields;
  pending_tx_stddev_order_by: Pending_Tx_Stddev_Order_By;
  pending_tx_stddev_pop_fields: Pending_Tx_Stddev_Pop_Fields;
  pending_tx_stddev_pop_order_by: Pending_Tx_Stddev_Pop_Order_By;
  pending_tx_stddev_samp_fields: Pending_Tx_Stddev_Samp_Fields;
  pending_tx_stddev_samp_order_by: Pending_Tx_Stddev_Samp_Order_By;
  pending_tx_stream_cursor_input: Pending_Tx_Stream_Cursor_Input;
  pending_tx_stream_cursor_value_input: Pending_Tx_Stream_Cursor_Value_Input;
  pending_tx_sum_fields: Pending_Tx_Sum_Fields;
  pending_tx_sum_order_by: Pending_Tx_Sum_Order_By;
  pending_tx_updates: Pending_Tx_Updates;
  pending_tx_var_pop_fields: Pending_Tx_Var_Pop_Fields;
  pending_tx_var_pop_order_by: Pending_Tx_Var_Pop_Order_By;
  pending_tx_var_samp_fields: Pending_Tx_Var_Samp_Fields;
  pending_tx_var_samp_order_by: Pending_Tx_Var_Samp_Order_By;
  pending_tx_variance_fields: Pending_Tx_Variance_Fields;
  pending_tx_variance_order_by: Pending_Tx_Variance_Order_By;
  query_root: {};
  rakebackLocks: RakebackLocks;
  rakebackLocks_aggregate: RakebackLocks_Aggregate;
  rakebackLocks_aggregate_fields: RakebackLocks_Aggregate_Fields;
  rakebackLocks_avg_fields: RakebackLocks_Avg_Fields;
  rakebackLocks_bool_exp: RakebackLocks_Bool_Exp;
  rakebackLocks_inc_input: RakebackLocks_Inc_Input;
  rakebackLocks_insert_input: RakebackLocks_Insert_Input;
  rakebackLocks_max_fields: RakebackLocks_Max_Fields;
  rakebackLocks_min_fields: RakebackLocks_Min_Fields;
  rakebackLocks_mutation_response: RakebackLocks_Mutation_Response;
  rakebackLocks_on_conflict: RakebackLocks_On_Conflict;
  rakebackLocks_order_by: RakebackLocks_Order_By;
  rakebackLocks_pk_columns_input: RakebackLocks_Pk_Columns_Input;
  rakebackLocks_set_input: RakebackLocks_Set_Input;
  rakebackLocks_stddev_fields: RakebackLocks_Stddev_Fields;
  rakebackLocks_stddev_pop_fields: RakebackLocks_Stddev_Pop_Fields;
  rakebackLocks_stddev_samp_fields: RakebackLocks_Stddev_Samp_Fields;
  rakebackLocks_stream_cursor_input: RakebackLocks_Stream_Cursor_Input;
  rakebackLocks_stream_cursor_value_input: RakebackLocks_Stream_Cursor_Value_Input;
  rakebackLocks_sum_fields: RakebackLocks_Sum_Fields;
  rakebackLocks_updates: RakebackLocks_Updates;
  rakebackLocks_var_pop_fields: RakebackLocks_Var_Pop_Fields;
  rakebackLocks_var_samp_fields: RakebackLocks_Var_Samp_Fields;
  rakebackLocks_variance_fields: RakebackLocks_Variance_Fields;
  smallint: Scalars['smallint'];
  smallint_comparison_exp: Smallint_Comparison_Exp;
  stablecoins: Stablecoins;
  stablecoins_aggregate: Stablecoins_Aggregate;
  stablecoins_aggregate_bool_exp: Stablecoins_Aggregate_Bool_Exp;
  stablecoins_aggregate_bool_exp_bool_and: Stablecoins_Aggregate_Bool_Exp_Bool_And;
  stablecoins_aggregate_bool_exp_bool_or: Stablecoins_Aggregate_Bool_Exp_Bool_Or;
  stablecoins_aggregate_bool_exp_count: Stablecoins_Aggregate_Bool_Exp_Count;
  stablecoins_aggregate_fields: Stablecoins_Aggregate_Fields;
  stablecoins_aggregate_order_by: Stablecoins_Aggregate_Order_By;
  stablecoins_arr_rel_insert_input: Stablecoins_Arr_Rel_Insert_Input;
  stablecoins_avg_fields: Stablecoins_Avg_Fields;
  stablecoins_avg_order_by: Stablecoins_Avg_Order_By;
  stablecoins_bool_exp: Stablecoins_Bool_Exp;
  stablecoins_inc_input: Stablecoins_Inc_Input;
  stablecoins_insert_input: Stablecoins_Insert_Input;
  stablecoins_max_fields: Stablecoins_Max_Fields;
  stablecoins_max_order_by: Stablecoins_Max_Order_By;
  stablecoins_min_fields: Stablecoins_Min_Fields;
  stablecoins_min_order_by: Stablecoins_Min_Order_By;
  stablecoins_mutation_response: Stablecoins_Mutation_Response;
  stablecoins_obj_rel_insert_input: Stablecoins_Obj_Rel_Insert_Input;
  stablecoins_on_conflict: Stablecoins_On_Conflict;
  stablecoins_order_by: Stablecoins_Order_By;
  stablecoins_pk_columns_input: Stablecoins_Pk_Columns_Input;
  stablecoins_set_input: Stablecoins_Set_Input;
  stablecoins_stddev_fields: Stablecoins_Stddev_Fields;
  stablecoins_stddev_order_by: Stablecoins_Stddev_Order_By;
  stablecoins_stddev_pop_fields: Stablecoins_Stddev_Pop_Fields;
  stablecoins_stddev_pop_order_by: Stablecoins_Stddev_Pop_Order_By;
  stablecoins_stddev_samp_fields: Stablecoins_Stddev_Samp_Fields;
  stablecoins_stddev_samp_order_by: Stablecoins_Stddev_Samp_Order_By;
  stablecoins_stream_cursor_input: Stablecoins_Stream_Cursor_Input;
  stablecoins_stream_cursor_value_input: Stablecoins_Stream_Cursor_Value_Input;
  stablecoins_sum_fields: Stablecoins_Sum_Fields;
  stablecoins_sum_order_by: Stablecoins_Sum_Order_By;
  stablecoins_updates: Stablecoins_Updates;
  stablecoins_var_pop_fields: Stablecoins_Var_Pop_Fields;
  stablecoins_var_pop_order_by: Stablecoins_Var_Pop_Order_By;
  stablecoins_var_samp_fields: Stablecoins_Var_Samp_Fields;
  stablecoins_var_samp_order_by: Stablecoins_Var_Samp_Order_By;
  stablecoins_variance_fields: Stablecoins_Variance_Fields;
  stablecoins_variance_order_by: Stablecoins_Variance_Order_By;
  stakingOutput: StakingOutput;
  staking_refill: Staking_Refill;
  staking_refill_aggregate: Staking_Refill_Aggregate;
  staking_refill_aggregate_fields: Staking_Refill_Aggregate_Fields;
  staking_refill_avg_fields: Staking_Refill_Avg_Fields;
  staking_refill_bool_exp: Staking_Refill_Bool_Exp;
  staking_refill_inc_input: Staking_Refill_Inc_Input;
  staking_refill_insert_input: Staking_Refill_Insert_Input;
  staking_refill_max_fields: Staking_Refill_Max_Fields;
  staking_refill_min_fields: Staking_Refill_Min_Fields;
  staking_refill_mutation_response: Staking_Refill_Mutation_Response;
  staking_refill_on_conflict: Staking_Refill_On_Conflict;
  staking_refill_order_by: Staking_Refill_Order_By;
  staking_refill_pk_columns_input: Staking_Refill_Pk_Columns_Input;
  staking_refill_set_input: Staking_Refill_Set_Input;
  staking_refill_stddev_fields: Staking_Refill_Stddev_Fields;
  staking_refill_stddev_pop_fields: Staking_Refill_Stddev_Pop_Fields;
  staking_refill_stddev_samp_fields: Staking_Refill_Stddev_Samp_Fields;
  staking_refill_stream_cursor_input: Staking_Refill_Stream_Cursor_Input;
  staking_refill_stream_cursor_value_input: Staking_Refill_Stream_Cursor_Value_Input;
  staking_refill_sum_fields: Staking_Refill_Sum_Fields;
  staking_refill_updates: Staking_Refill_Updates;
  staking_refill_var_pop_fields: Staking_Refill_Var_Pop_Fields;
  staking_refill_var_samp_fields: Staking_Refill_Var_Samp_Fields;
  staking_refill_variance_fields: Staking_Refill_Variance_Fields;
  subscription_root: {};
  sumsubOutput: SumsubOutput;
  swapTransactions: SwapTransactions;
  swapTransactions_aggregate: SwapTransactions_Aggregate;
  swapTransactions_aggregate_fields: SwapTransactions_Aggregate_Fields;
  swapTransactions_avg_fields: SwapTransactions_Avg_Fields;
  swapTransactions_bool_exp: SwapTransactions_Bool_Exp;
  swapTransactions_inc_input: SwapTransactions_Inc_Input;
  swapTransactions_insert_input: SwapTransactions_Insert_Input;
  swapTransactions_max_fields: SwapTransactions_Max_Fields;
  swapTransactions_min_fields: SwapTransactions_Min_Fields;
  swapTransactions_mutation_response: SwapTransactions_Mutation_Response;
  swapTransactions_order_by: SwapTransactions_Order_By;
  swapTransactions_set_input: SwapTransactions_Set_Input;
  swapTransactions_stddev_fields: SwapTransactions_Stddev_Fields;
  swapTransactions_stddev_pop_fields: SwapTransactions_Stddev_Pop_Fields;
  swapTransactions_stddev_samp_fields: SwapTransactions_Stddev_Samp_Fields;
  swapTransactions_stream_cursor_input: SwapTransactions_Stream_Cursor_Input;
  swapTransactions_stream_cursor_value_input: SwapTransactions_Stream_Cursor_Value_Input;
  swapTransactions_sum_fields: SwapTransactions_Sum_Fields;
  swapTransactions_updates: SwapTransactions_Updates;
  swapTransactions_var_pop_fields: SwapTransactions_Var_Pop_Fields;
  swapTransactions_var_samp_fields: SwapTransactions_Var_Samp_Fields;
  swapTransactions_variance_fields: SwapTransactions_Variance_Fields;
  timestamptz: Scalars['timestamptz'];
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  tournamentInput: TournamentInput;
  tournaments: Tournaments;
  tournaments_aggregate: Tournaments_Aggregate;
  tournaments_aggregate_fields: Tournaments_Aggregate_Fields;
  tournaments_avg_fields: Tournaments_Avg_Fields;
  tournaments_bool_exp: Tournaments_Bool_Exp;
  tournaments_inc_input: Tournaments_Inc_Input;
  tournaments_insert_input: Tournaments_Insert_Input;
  tournaments_max_fields: Tournaments_Max_Fields;
  tournaments_min_fields: Tournaments_Min_Fields;
  tournaments_mutation_response: Tournaments_Mutation_Response;
  tournaments_on_conflict: Tournaments_On_Conflict;
  tournaments_order_by: Tournaments_Order_By;
  tournaments_pk_columns_input: Tournaments_Pk_Columns_Input;
  tournaments_set_input: Tournaments_Set_Input;
  tournaments_stddev_fields: Tournaments_Stddev_Fields;
  tournaments_stddev_pop_fields: Tournaments_Stddev_Pop_Fields;
  tournaments_stddev_samp_fields: Tournaments_Stddev_Samp_Fields;
  tournaments_stream_cursor_input: Tournaments_Stream_Cursor_Input;
  tournaments_stream_cursor_value_input: Tournaments_Stream_Cursor_Value_Input;
  tournaments_sum_fields: Tournaments_Sum_Fields;
  tournaments_updates: Tournaments_Updates;
  tournaments_var_pop_fields: Tournaments_Var_Pop_Fields;
  tournaments_var_samp_fields: Tournaments_Var_Samp_Fields;
  tournaments_variance_fields: Tournaments_Variance_Fields;
  transactionStatistics: TransactionStatistics;
  transactionStatistics_aggregate: TransactionStatistics_Aggregate;
  transactionStatistics_aggregate_fields: TransactionStatistics_Aggregate_Fields;
  transactionStatistics_avg_fields: TransactionStatistics_Avg_Fields;
  transactionStatistics_bool_exp: TransactionStatistics_Bool_Exp;
  transactionStatistics_inc_input: TransactionStatistics_Inc_Input;
  transactionStatistics_insert_input: TransactionStatistics_Insert_Input;
  transactionStatistics_max_fields: TransactionStatistics_Max_Fields;
  transactionStatistics_min_fields: TransactionStatistics_Min_Fields;
  transactionStatistics_mutation_response: TransactionStatistics_Mutation_Response;
  transactionStatistics_on_conflict: TransactionStatistics_On_Conflict;
  transactionStatistics_order_by: TransactionStatistics_Order_By;
  transactionStatistics_pk_columns_input: TransactionStatistics_Pk_Columns_Input;
  transactionStatistics_set_input: TransactionStatistics_Set_Input;
  transactionStatistics_stddev_fields: TransactionStatistics_Stddev_Fields;
  transactionStatistics_stddev_pop_fields: TransactionStatistics_Stddev_Pop_Fields;
  transactionStatistics_stddev_samp_fields: TransactionStatistics_Stddev_Samp_Fields;
  transactionStatistics_stream_cursor_input: TransactionStatistics_Stream_Cursor_Input;
  transactionStatistics_stream_cursor_value_input: TransactionStatistics_Stream_Cursor_Value_Input;
  transactionStatistics_sum_fields: TransactionStatistics_Sum_Fields;
  transactionStatistics_updates: TransactionStatistics_Updates;
  transactionStatistics_var_pop_fields: TransactionStatistics_Var_Pop_Fields;
  transactionStatistics_var_samp_fields: TransactionStatistics_Var_Samp_Fields;
  transactionStatistics_variance_fields: TransactionStatistics_Variance_Fields;
  transactions: Transactions;
  transactions_aggregate: Transactions_Aggregate;
  transactions_aggregate_fields: Transactions_Aggregate_Fields;
  transactions_append_input: Transactions_Append_Input;
  transactions_avg_fields: Transactions_Avg_Fields;
  transactions_bool_exp: Transactions_Bool_Exp;
  transactions_delete_at_path_input: Transactions_Delete_At_Path_Input;
  transactions_delete_elem_input: Transactions_Delete_Elem_Input;
  transactions_delete_key_input: Transactions_Delete_Key_Input;
  transactions_inc_input: Transactions_Inc_Input;
  transactions_insert_input: Transactions_Insert_Input;
  transactions_max_fields: Transactions_Max_Fields;
  transactions_min_fields: Transactions_Min_Fields;
  transactions_mutation_response: Transactions_Mutation_Response;
  transactions_on_conflict: Transactions_On_Conflict;
  transactions_order_by: Transactions_Order_By;
  transactions_pk_columns_input: Transactions_Pk_Columns_Input;
  transactions_prepend_input: Transactions_Prepend_Input;
  transactions_set_input: Transactions_Set_Input;
  transactions_stddev_fields: Transactions_Stddev_Fields;
  transactions_stddev_pop_fields: Transactions_Stddev_Pop_Fields;
  transactions_stddev_samp_fields: Transactions_Stddev_Samp_Fields;
  transactions_stream_cursor_input: Transactions_Stream_Cursor_Input;
  transactions_stream_cursor_value_input: Transactions_Stream_Cursor_Value_Input;
  transactions_sum_fields: Transactions_Sum_Fields;
  transactions_updates: Transactions_Updates;
  transactions_var_pop_fields: Transactions_Var_Pop_Fields;
  transactions_var_samp_fields: Transactions_Var_Samp_Fields;
  transactions_variance_fields: Transactions_Variance_Fields;
  updateAvailableAvatarNFTs: UpdateAvailableAvatarNfTs;
  userErc721: UserErc721;
  userErc721_aggregate: UserErc721_Aggregate;
  userErc721_aggregate_fields: UserErc721_Aggregate_Fields;
  userErc721_avg_fields: UserErc721_Avg_Fields;
  userErc721_bool_exp: UserErc721_Bool_Exp;
  userErc721_inc_input: UserErc721_Inc_Input;
  userErc721_insert_input: UserErc721_Insert_Input;
  userErc721_max_fields: UserErc721_Max_Fields;
  userErc721_min_fields: UserErc721_Min_Fields;
  userErc721_mutation_response: UserErc721_Mutation_Response;
  userErc721_on_conflict: UserErc721_On_Conflict;
  userErc721_order_by: UserErc721_Order_By;
  userErc721_pk_columns_input: UserErc721_Pk_Columns_Input;
  userErc721_set_input: UserErc721_Set_Input;
  userErc721_stddev_fields: UserErc721_Stddev_Fields;
  userErc721_stddev_pop_fields: UserErc721_Stddev_Pop_Fields;
  userErc721_stddev_samp_fields: UserErc721_Stddev_Samp_Fields;
  userErc721_stream_cursor_input: UserErc721_Stream_Cursor_Input;
  userErc721_stream_cursor_value_input: UserErc721_Stream_Cursor_Value_Input;
  userErc721_sum_fields: UserErc721_Sum_Fields;
  userErc721_updates: UserErc721_Updates;
  userErc721_var_pop_fields: UserErc721_Var_Pop_Fields;
  userErc721_var_samp_fields: UserErc721_Var_Samp_Fields;
  userErc721_variance_fields: UserErc721_Variance_Fields;
  userJobs: UserJobs;
  userJobs_aggregate: UserJobs_Aggregate;
  userJobs_aggregate_fields: UserJobs_Aggregate_Fields;
  userJobs_avg_fields: UserJobs_Avg_Fields;
  userJobs_bool_exp: UserJobs_Bool_Exp;
  userJobs_inc_input: UserJobs_Inc_Input;
  userJobs_insert_input: UserJobs_Insert_Input;
  userJobs_max_fields: UserJobs_Max_Fields;
  userJobs_min_fields: UserJobs_Min_Fields;
  userJobs_mutation_response: UserJobs_Mutation_Response;
  userJobs_on_conflict: UserJobs_On_Conflict;
  userJobs_order_by: UserJobs_Order_By;
  userJobs_pk_columns_input: UserJobs_Pk_Columns_Input;
  userJobs_set_input: UserJobs_Set_Input;
  userJobs_stddev_fields: UserJobs_Stddev_Fields;
  userJobs_stddev_pop_fields: UserJobs_Stddev_Pop_Fields;
  userJobs_stddev_samp_fields: UserJobs_Stddev_Samp_Fields;
  userJobs_stream_cursor_input: UserJobs_Stream_Cursor_Input;
  userJobs_stream_cursor_value_input: UserJobs_Stream_Cursor_Value_Input;
  userJobs_sum_fields: UserJobs_Sum_Fields;
  userJobs_updates: UserJobs_Updates;
  userJobs_var_pop_fields: UserJobs_Var_Pop_Fields;
  userJobs_var_samp_fields: UserJobs_Var_Samp_Fields;
  userJobs_variance_fields: UserJobs_Variance_Fields;
  user_erc721_block: User_Erc721_Block;
  user_erc721_block_aggregate: User_Erc721_Block_Aggregate;
  user_erc721_block_aggregate_fields: User_Erc721_Block_Aggregate_Fields;
  user_erc721_block_avg_fields: User_Erc721_Block_Avg_Fields;
  user_erc721_block_bool_exp: User_Erc721_Block_Bool_Exp;
  user_erc721_block_inc_input: User_Erc721_Block_Inc_Input;
  user_erc721_block_insert_input: User_Erc721_Block_Insert_Input;
  user_erc721_block_max_fields: User_Erc721_Block_Max_Fields;
  user_erc721_block_min_fields: User_Erc721_Block_Min_Fields;
  user_erc721_block_mutation_response: User_Erc721_Block_Mutation_Response;
  user_erc721_block_on_conflict: User_Erc721_Block_On_Conflict;
  user_erc721_block_order_by: User_Erc721_Block_Order_By;
  user_erc721_block_pk_columns_input: User_Erc721_Block_Pk_Columns_Input;
  user_erc721_block_set_input: User_Erc721_Block_Set_Input;
  user_erc721_block_stddev_fields: User_Erc721_Block_Stddev_Fields;
  user_erc721_block_stddev_pop_fields: User_Erc721_Block_Stddev_Pop_Fields;
  user_erc721_block_stddev_samp_fields: User_Erc721_Block_Stddev_Samp_Fields;
  user_erc721_block_stream_cursor_input: User_Erc721_Block_Stream_Cursor_Input;
  user_erc721_block_stream_cursor_value_input: User_Erc721_Block_Stream_Cursor_Value_Input;
  user_erc721_block_sum_fields: User_Erc721_Block_Sum_Fields;
  user_erc721_block_updates: User_Erc721_Block_Updates;
  user_erc721_block_var_pop_fields: User_Erc721_Block_Var_Pop_Fields;
  user_erc721_block_var_samp_fields: User_Erc721_Block_Var_Samp_Fields;
  user_erc721_block_variance_fields: User_Erc721_Block_Variance_Fields;
  user_gpanel: User_Gpanel;
  user_gpanel_aggregate: User_Gpanel_Aggregate;
  user_gpanel_aggregate_bool_exp: User_Gpanel_Aggregate_Bool_Exp;
  user_gpanel_aggregate_bool_exp_count: User_Gpanel_Aggregate_Bool_Exp_Count;
  user_gpanel_aggregate_fields: User_Gpanel_Aggregate_Fields;
  user_gpanel_aggregate_order_by: User_Gpanel_Aggregate_Order_By;
  user_gpanel_arr_rel_insert_input: User_Gpanel_Arr_Rel_Insert_Input;
  user_gpanel_avg_fields: User_Gpanel_Avg_Fields;
  user_gpanel_avg_order_by: User_Gpanel_Avg_Order_By;
  user_gpanel_bool_exp: User_Gpanel_Bool_Exp;
  user_gpanel_inc_input: User_Gpanel_Inc_Input;
  user_gpanel_insert_input: User_Gpanel_Insert_Input;
  user_gpanel_max_fields: User_Gpanel_Max_Fields;
  user_gpanel_max_order_by: User_Gpanel_Max_Order_By;
  user_gpanel_min_fields: User_Gpanel_Min_Fields;
  user_gpanel_min_order_by: User_Gpanel_Min_Order_By;
  user_gpanel_mutation_response: User_Gpanel_Mutation_Response;
  user_gpanel_on_conflict: User_Gpanel_On_Conflict;
  user_gpanel_order_by: User_Gpanel_Order_By;
  user_gpanel_pk_columns_input: User_Gpanel_Pk_Columns_Input;
  user_gpanel_set_input: User_Gpanel_Set_Input;
  user_gpanel_stddev_fields: User_Gpanel_Stddev_Fields;
  user_gpanel_stddev_order_by: User_Gpanel_Stddev_Order_By;
  user_gpanel_stddev_pop_fields: User_Gpanel_Stddev_Pop_Fields;
  user_gpanel_stddev_pop_order_by: User_Gpanel_Stddev_Pop_Order_By;
  user_gpanel_stddev_samp_fields: User_Gpanel_Stddev_Samp_Fields;
  user_gpanel_stddev_samp_order_by: User_Gpanel_Stddev_Samp_Order_By;
  user_gpanel_stream_cursor_input: User_Gpanel_Stream_Cursor_Input;
  user_gpanel_stream_cursor_value_input: User_Gpanel_Stream_Cursor_Value_Input;
  user_gpanel_sum_fields: User_Gpanel_Sum_Fields;
  user_gpanel_sum_order_by: User_Gpanel_Sum_Order_By;
  user_gpanel_updates: User_Gpanel_Updates;
  user_gpanel_var_pop_fields: User_Gpanel_Var_Pop_Fields;
  user_gpanel_var_pop_order_by: User_Gpanel_Var_Pop_Order_By;
  user_gpanel_var_samp_fields: User_Gpanel_Var_Samp_Fields;
  user_gpanel_var_samp_order_by: User_Gpanel_Var_Samp_Order_By;
  user_gpanel_variance_fields: User_Gpanel_Variance_Fields;
  user_gpanel_variance_order_by: User_Gpanel_Variance_Order_By;
  user_lock: User_Lock;
  user_lock_aggregate: User_Lock_Aggregate;
  user_lock_aggregate_bool_exp: User_Lock_Aggregate_Bool_Exp;
  user_lock_aggregate_bool_exp_bool_and: User_Lock_Aggregate_Bool_Exp_Bool_And;
  user_lock_aggregate_bool_exp_bool_or: User_Lock_Aggregate_Bool_Exp_Bool_Or;
  user_lock_aggregate_bool_exp_count: User_Lock_Aggregate_Bool_Exp_Count;
  user_lock_aggregate_fields: User_Lock_Aggregate_Fields;
  user_lock_aggregate_order_by: User_Lock_Aggregate_Order_By;
  user_lock_arr_rel_insert_input: User_Lock_Arr_Rel_Insert_Input;
  user_lock_avg_fields: User_Lock_Avg_Fields;
  user_lock_avg_order_by: User_Lock_Avg_Order_By;
  user_lock_bool_exp: User_Lock_Bool_Exp;
  user_lock_inc_input: User_Lock_Inc_Input;
  user_lock_insert_input: User_Lock_Insert_Input;
  user_lock_max_fields: User_Lock_Max_Fields;
  user_lock_max_order_by: User_Lock_Max_Order_By;
  user_lock_min_fields: User_Lock_Min_Fields;
  user_lock_min_order_by: User_Lock_Min_Order_By;
  user_lock_mutation_response: User_Lock_Mutation_Response;
  user_lock_on_conflict: User_Lock_On_Conflict;
  user_lock_order_by: User_Lock_Order_By;
  user_lock_pk_columns_input: User_Lock_Pk_Columns_Input;
  user_lock_set_input: User_Lock_Set_Input;
  user_lock_stddev_fields: User_Lock_Stddev_Fields;
  user_lock_stddev_order_by: User_Lock_Stddev_Order_By;
  user_lock_stddev_pop_fields: User_Lock_Stddev_Pop_Fields;
  user_lock_stddev_pop_order_by: User_Lock_Stddev_Pop_Order_By;
  user_lock_stddev_samp_fields: User_Lock_Stddev_Samp_Fields;
  user_lock_stddev_samp_order_by: User_Lock_Stddev_Samp_Order_By;
  user_lock_stream_cursor_input: User_Lock_Stream_Cursor_Input;
  user_lock_stream_cursor_value_input: User_Lock_Stream_Cursor_Value_Input;
  user_lock_sum_fields: User_Lock_Sum_Fields;
  user_lock_sum_order_by: User_Lock_Sum_Order_By;
  user_lock_updates: User_Lock_Updates;
  user_lock_var_pop_fields: User_Lock_Var_Pop_Fields;
  user_lock_var_pop_order_by: User_Lock_Var_Pop_Order_By;
  user_lock_var_samp_fields: User_Lock_Var_Samp_Fields;
  user_lock_var_samp_order_by: User_Lock_Var_Samp_Order_By;
  user_lock_variance_fields: User_Lock_Variance_Fields;
  user_lock_variance_order_by: User_Lock_Variance_Order_By;
  user_staking: User_Staking;
  user_staking_aggregate: User_Staking_Aggregate;
  user_staking_aggregate_fields: User_Staking_Aggregate_Fields;
  user_staking_avg_fields: User_Staking_Avg_Fields;
  user_staking_bool_exp: User_Staking_Bool_Exp;
  user_staking_inc_input: User_Staking_Inc_Input;
  user_staking_insert_input: User_Staking_Insert_Input;
  user_staking_max_fields: User_Staking_Max_Fields;
  user_staking_min_fields: User_Staking_Min_Fields;
  user_staking_mutation_response: User_Staking_Mutation_Response;
  user_staking_on_conflict: User_Staking_On_Conflict;
  user_staking_order_by: User_Staking_Order_By;
  user_staking_pk_columns_input: User_Staking_Pk_Columns_Input;
  user_staking_set_input: User_Staking_Set_Input;
  user_staking_stddev_fields: User_Staking_Stddev_Fields;
  user_staking_stddev_pop_fields: User_Staking_Stddev_Pop_Fields;
  user_staking_stddev_samp_fields: User_Staking_Stddev_Samp_Fields;
  user_staking_stream_cursor_input: User_Staking_Stream_Cursor_Input;
  user_staking_stream_cursor_value_input: User_Staking_Stream_Cursor_Value_Input;
  user_staking_sum_fields: User_Staking_Sum_Fields;
  user_staking_transactions: User_Staking_Transactions;
  user_staking_transactions_aggregate: User_Staking_Transactions_Aggregate;
  user_staking_transactions_aggregate_fields: User_Staking_Transactions_Aggregate_Fields;
  user_staking_transactions_avg_fields: User_Staking_Transactions_Avg_Fields;
  user_staking_transactions_bool_exp: User_Staking_Transactions_Bool_Exp;
  user_staking_transactions_inc_input: User_Staking_Transactions_Inc_Input;
  user_staking_transactions_insert_input: User_Staking_Transactions_Insert_Input;
  user_staking_transactions_max_fields: User_Staking_Transactions_Max_Fields;
  user_staking_transactions_min_fields: User_Staking_Transactions_Min_Fields;
  user_staking_transactions_mutation_response: User_Staking_Transactions_Mutation_Response;
  user_staking_transactions_on_conflict: User_Staking_Transactions_On_Conflict;
  user_staking_transactions_order_by: User_Staking_Transactions_Order_By;
  user_staking_transactions_pk_columns_input: User_Staking_Transactions_Pk_Columns_Input;
  user_staking_transactions_set_input: User_Staking_Transactions_Set_Input;
  user_staking_transactions_stddev_fields: User_Staking_Transactions_Stddev_Fields;
  user_staking_transactions_stddev_pop_fields: User_Staking_Transactions_Stddev_Pop_Fields;
  user_staking_transactions_stddev_samp_fields: User_Staking_Transactions_Stddev_Samp_Fields;
  user_staking_transactions_stream_cursor_input: User_Staking_Transactions_Stream_Cursor_Input;
  user_staking_transactions_stream_cursor_value_input: User_Staking_Transactions_Stream_Cursor_Value_Input;
  user_staking_transactions_sum_fields: User_Staking_Transactions_Sum_Fields;
  user_staking_transactions_updates: User_Staking_Transactions_Updates;
  user_staking_transactions_var_pop_fields: User_Staking_Transactions_Var_Pop_Fields;
  user_staking_transactions_var_samp_fields: User_Staking_Transactions_Var_Samp_Fields;
  user_staking_transactions_variance_fields: User_Staking_Transactions_Variance_Fields;
  user_staking_updates: User_Staking_Updates;
  user_staking_var_pop_fields: User_Staking_Var_Pop_Fields;
  user_staking_var_samp_fields: User_Staking_Var_Samp_Fields;
  user_staking_variance_fields: User_Staking_Variance_Fields;
  users: Users;
  users_aggregate: Users_Aggregate;
  users_aggregate_fields: Users_Aggregate_Fields;
  users_avg_fields: Users_Avg_Fields;
  users_bool_exp: Users_Bool_Exp;
  users_inc_input: Users_Inc_Input;
  users_insert_input: Users_Insert_Input;
  users_login: Users_Login;
  users_login_aggregate: Users_Login_Aggregate;
  users_login_aggregate_fields: Users_Login_Aggregate_Fields;
  users_login_bool_exp: Users_Login_Bool_Exp;
  users_login_max_fields: Users_Login_Max_Fields;
  users_login_min_fields: Users_Login_Min_Fields;
  users_login_order_by: Users_Login_Order_By;
  users_login_stream_cursor_input: Users_Login_Stream_Cursor_Input;
  users_login_stream_cursor_value_input: Users_Login_Stream_Cursor_Value_Input;
  users_max_fields: Users_Max_Fields;
  users_min_fields: Users_Min_Fields;
  users_mutation_response: Users_Mutation_Response;
  users_obj_rel_insert_input: Users_Obj_Rel_Insert_Input;
  users_old: Users_Old;
  users_old_aggregate: Users_Old_Aggregate;
  users_old_aggregate_fields: Users_Old_Aggregate_Fields;
  users_old_avg_fields: Users_Old_Avg_Fields;
  users_old_bool_exp: Users_Old_Bool_Exp;
  users_old_inc_input: Users_Old_Inc_Input;
  users_old_insert_input: Users_Old_Insert_Input;
  users_old_max_fields: Users_Old_Max_Fields;
  users_old_min_fields: Users_Old_Min_Fields;
  users_old_mutation_response: Users_Old_Mutation_Response;
  users_old_on_conflict: Users_Old_On_Conflict;
  users_old_order_by: Users_Old_Order_By;
  users_old_pk_columns_input: Users_Old_Pk_Columns_Input;
  users_old_set_input: Users_Old_Set_Input;
  users_old_stddev_fields: Users_Old_Stddev_Fields;
  users_old_stddev_pop_fields: Users_Old_Stddev_Pop_Fields;
  users_old_stddev_samp_fields: Users_Old_Stddev_Samp_Fields;
  users_old_stream_cursor_input: Users_Old_Stream_Cursor_Input;
  users_old_stream_cursor_value_input: Users_Old_Stream_Cursor_Value_Input;
  users_old_sum_fields: Users_Old_Sum_Fields;
  users_old_updates: Users_Old_Updates;
  users_old_var_pop_fields: Users_Old_Var_Pop_Fields;
  users_old_var_samp_fields: Users_Old_Var_Samp_Fields;
  users_old_variance_fields: Users_Old_Variance_Fields;
  users_on_conflict: Users_On_Conflict;
  users_order_by: Users_Order_By;
  users_pk_columns_input: Users_Pk_Columns_Input;
  users_set_input: Users_Set_Input;
  users_stddev_fields: Users_Stddev_Fields;
  users_stddev_pop_fields: Users_Stddev_Pop_Fields;
  users_stddev_samp_fields: Users_Stddev_Samp_Fields;
  users_stream_cursor_input: Users_Stream_Cursor_Input;
  users_stream_cursor_value_input: Users_Stream_Cursor_Value_Input;
  users_sum_fields: Users_Sum_Fields;
  users_updates: Users_Updates;
  users_var_pop_fields: Users_Var_Pop_Fields;
  users_var_samp_fields: Users_Var_Samp_Fields;
  users_variance_fields: Users_Variance_Fields;
  uuid: Scalars['uuid'];
  uuid_comparison_exp: Uuid_Comparison_Exp;
};

export type CachedDirectiveArgs = {
  refresh?: Scalars['Boolean'];
  ttl?: Scalars['Int'];
};

export type CachedDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = CachedDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ClientDirectiveArgs = {
  always?: Maybe<Scalars['Boolean']>;
};

export type ClientDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ClientDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DepositOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositOutput'] = ResolversParentTypes['DepositOutput']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  depositAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralMessageOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeneralMessageOutput'] = ResolversParentTypes['GeneralMessageOutput']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KycSubmitOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KYCSubmitOutput'] = ResolversParentTypes['KYCSubmitOutput']
> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftConnectedDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NFTConnectedDetail'] = ResolversParentTypes['NFTConnectedDetail']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  collection?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpanelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nftId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftConnectedOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NFTConnectedOutput'] = ResolversParentTypes['NFTConnectedOutput']
> = {
  own?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['NFTConnectedDetail']>>>,
    ParentType,
    ContextType
  >;
  rented?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['NFTConnectedDetail']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftTicketsClaimOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NFTTicketsClaimOptions'] = ResolversParentTypes['NFTTicketsClaimOptions']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftTicketsClaimOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NFTTicketsClaimOutput'] = ResolversParentTypes['NFTTicketsClaimOutput']
> = {
  claimOptions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['NFTTicketsClaimOptions']>>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextReleaseTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalNFTs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RateOutput'] = ResolversParentTypes['RateOutput']
> = {
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fromAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fromCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  toAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  toCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  toMinimumAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusOutput'] = ResolversParentTypes['StatusOutput']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapOrderOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SwapOrderOutput'] = ResolversParentTypes['SwapOrderOutput']
> = {
  amountFrom?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  amountTo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountToMinimum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  blockchainId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currencyFrom?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyTo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gpanelUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SwapOutput'] = ResolversParentTypes['SwapOutput']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test'] = ResolversParentTypes['Test']
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_aggregate'] = ResolversParentTypes['Test_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['Test_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Test']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_aggregate_fields'] = ResolversParentTypes['Test_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['Test_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Test_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['Test_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['Test_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['Test_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['Test_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['Test_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['Test_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['Test_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['Test_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['Test_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_avg_fields'] = ResolversParentTypes['Test_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_max_fields'] = ResolversParentTypes['Test_max_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_min_fields'] = ResolversParentTypes['Test_min_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_mutation_response'] = ResolversParentTypes['Test_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Test']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_stddev_fields'] = ResolversParentTypes['Test_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_stddev_pop_fields'] = ResolversParentTypes['Test_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_stddev_samp_fields'] = ResolversParentTypes['Test_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_sum_fields'] = ResolversParentTypes['Test_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_var_pop_fields'] = ResolversParentTypes['Test_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_var_samp_fields'] = ResolversParentTypes['Test_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Test_variance_fields'] = ResolversParentTypes['Test_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserOutput'] = ResolversParentTypes['UserOutput']
> = {
  agent?: Resolver<
    Maybe<ResolversTypes['agentUserOutput']>,
    ParentType,
    ContextType
  >;
  avatar?: Resolver<
    Maybe<ResolversTypes['avatarOutput']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositAddress?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['depositAddressOutput']>>>,
    ParentType,
    ContextType
  >;
  ethAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gpanelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kyc?: Resolver<Maybe<ResolversTypes['kycOutput']>, ParentType, ContextType>;
  kycAllowed?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  kycPending?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  kycStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pokerBusd?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pokerInp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pokerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  staking?: Resolver<
    Maybe<ResolversTypes['stakingOutput']>,
    ParentType,
    ContextType
  >;
  telegramName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgentFindUserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['agentFindUserOutput'] = ResolversParentTypes['agentFindUserOutput']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gPanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgentGetUsersOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['agentGetUsersOutput'] = ResolversParentTypes['agentGetUsersOutput']
> = {
  agentFrom?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['agentUserItemOutput']>>>,
    ParentType,
    ContextType
  >;
  agentOf?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['agentUserItemOutput']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgentUserItemOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['agentUserItemOutput'] = ResolversParentTypes['agentUserItemOutput']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gPanelUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgentUserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['agentUserOutput'] = ResolversParentTypes['agentUserOutput']
> = {
  hasAgent?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isAgent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_GpanelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel'] = ResolversParentTypes['anno_gpanel']
> = {
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  code_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  refresh_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_aggregate'] = ResolversParentTypes['anno_gpanel_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['anno_gpanel']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_aggregate_fields'] = ResolversParentTypes['anno_gpanel_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Anno_Gpanel_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_avg_fields'] = ResolversParentTypes['anno_gpanel_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_max_fields'] = ResolversParentTypes['anno_gpanel_max_fields']
> = {
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  code_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  refresh_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  uuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_min_fields'] = ResolversParentTypes['anno_gpanel_min_fields']
> = {
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  code_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  refresh_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  uuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_mutation_response'] = ResolversParentTypes['anno_gpanel_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['anno_gpanel']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_stddev_fields'] = ResolversParentTypes['anno_gpanel_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_stddev_pop_fields'] = ResolversParentTypes['anno_gpanel_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_stddev_samp_fields'] = ResolversParentTypes['anno_gpanel_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_sum_fields'] = ResolversParentTypes['anno_gpanel_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_var_pop_fields'] = ResolversParentTypes['anno_gpanel_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_var_samp_fields'] = ResolversParentTypes['anno_gpanel_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Anno_Gpanel_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['anno_gpanel_variance_fields'] = ResolversParentTypes['anno_gpanel_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthLoginOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['authLoginOutput'] = ResolversParentTypes['authLoginOutput']
> = {
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eth_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  expires_in?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthSignatureOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['authSignatureOutput'] = ResolversParentTypes['authSignatureOutput']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nonce?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvatarOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['avatarOutput'] = ResolversParentTypes['avatarOutput']
> = {
  collection?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigintScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export type BlockchainResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain'] = ResolversParentTypes['blockchain']
> = {
  apiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chainId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  erc721Active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inpActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inpAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  inpFeeAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  inpVaultAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lp_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  marketplaceAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nativeCurrency?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    Partial<BlockchainNativeCurrencyArgs>
  >;
  nftCardAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pending_txes?: Resolver<
    Array<ResolversTypes['pending_tx']>,
    ParentType,
    ContextType,
    Partial<BlockchainPending_TxesArgs>
  >;
  pending_txes_aggregate?: Resolver<
    ResolversTypes['pending_tx_aggregate'],
    ParentType,
    ContextType,
    Partial<BlockchainPending_Txes_AggregateArgs>
  >;
  rpc?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    Partial<BlockchainRpcArgs>
  >;
  rpcPublic?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    Partial<BlockchainRpcPublicArgs>
  >;
  scanUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stablecoins?: Resolver<
    Array<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType,
    Partial<BlockchainStablecoinsArgs>
  >;
  stablecoins_aggregate?: Resolver<
    ResolversTypes['stablecoins_aggregate'],
    ParentType,
    ContextType,
    Partial<BlockchainStablecoins_AggregateArgs>
  >;
  swap_pair_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  webActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_aggregate'] = ResolversParentTypes['blockchain_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['blockchain_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['blockchain']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_aggregate_fields'] = ResolversParentTypes['blockchain_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['blockchain_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Blockchain_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['blockchain_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['blockchain_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['blockchain_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['blockchain_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['blockchain_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['blockchain_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['blockchain_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['blockchain_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['blockchain_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_avg_fields'] = ResolversParentTypes['blockchain_avg_fields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_DepositResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit'] = ResolversParentTypes['blockchain_main_deposit']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blockchain_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  forwarderfactory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_aggregate'] = ResolversParentTypes['blockchain_main_deposit_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['blockchain_main_deposit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_aggregate_fields'] = ResolversParentTypes['blockchain_main_deposit_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Blockchain_Main_Deposit_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_avg_fields'] = ResolversParentTypes['blockchain_main_deposit_avg_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_max_fields'] = ResolversParentTypes['blockchain_main_deposit_max_fields']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  forwarderfactory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_min_fields'] = ResolversParentTypes['blockchain_main_deposit_min_fields']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  forwarderfactory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_mutation_response'] = ResolversParentTypes['blockchain_main_deposit_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['blockchain_main_deposit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_stddev_fields'] = ResolversParentTypes['blockchain_main_deposit_stddev_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_stddev_pop_fields'] = ResolversParentTypes['blockchain_main_deposit_stddev_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_stddev_samp_fields'] = ResolversParentTypes['blockchain_main_deposit_stddev_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_sum_fields'] = ResolversParentTypes['blockchain_main_deposit_sum_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_var_pop_fields'] = ResolversParentTypes['blockchain_main_deposit_var_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_var_samp_fields'] = ResolversParentTypes['blockchain_main_deposit_var_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Main_Deposit_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_main_deposit_variance_fields'] = ResolversParentTypes['blockchain_main_deposit_variance_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_max_fields'] = ResolversParentTypes['blockchain_max_fields']
> = {
  apiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chainId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  inpAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  inpFeeAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  inpVaultAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lp_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  marketplaceAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nftCardAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  scanUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swap_pair_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_min_fields'] = ResolversParentTypes['blockchain_min_fields']
> = {
  apiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chainId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  inpAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  inpFeeAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  inpVaultAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lp_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  marketplaceAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nftCardAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  scanUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swap_pair_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_mutation_response'] = ResolversParentTypes['blockchain_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['blockchain']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_stddev_fields'] = ResolversParentTypes['blockchain_stddev_fields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_stddev_pop_fields'] = ResolversParentTypes['blockchain_stddev_pop_fields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_stddev_samp_fields'] = ResolversParentTypes['blockchain_stddev_samp_fields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_sum_fields'] = ResolversParentTypes['blockchain_sum_fields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_var_pop_fields'] = ResolversParentTypes['blockchain_var_pop_fields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_var_samp_fields'] = ResolversParentTypes['blockchain_var_samp_fields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Blockchain_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['blockchain_variance_fields'] = ResolversParentTypes['blockchain_variance_fields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lp_stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_ProfitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit'] = ResolversParentTypes['burn_profit']
> = {
  amount_rake?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amount_tournament?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  burn_date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  burn_tx_log?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  ended?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  money_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  started?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total_burn?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total_burn_inp?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_aggregate'] = ResolversParentTypes['burn_profit_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['burn_profit_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['burn_profit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_aggregate_fields'] = ResolversParentTypes['burn_profit_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['burn_profit_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Burn_Profit_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['burn_profit_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['burn_profit_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['burn_profit_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['burn_profit_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['burn_profit_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['burn_profit_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['burn_profit_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['burn_profit_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['burn_profit_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_avg_fields'] = ResolversParentTypes['burn_profit_avg_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_max_fields'] = ResolversParentTypes['burn_profit_max_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  burn_date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  burn_tx_log?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  ended?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  money_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  started?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_min_fields'] = ResolversParentTypes['burn_profit_min_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  burn_date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  burn_tx_log?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  ended?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  money_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  started?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_mutation_response'] = ResolversParentTypes['burn_profit_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['burn_profit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_stddev_fields'] = ResolversParentTypes['burn_profit_stddev_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_stddev_pop_fields'] = ResolversParentTypes['burn_profit_stddev_pop_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_stddev_samp_fields'] = ResolversParentTypes['burn_profit_stddev_samp_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_sum_fields'] = ResolversParentTypes['burn_profit_sum_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_var_pop_fields'] = ResolversParentTypes['burn_profit_var_pop_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_var_samp_fields'] = ResolversParentTypes['burn_profit_var_samp_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Burn_Profit_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['burn_profit_variance_fields'] = ResolversParentTypes['burn_profit_variance_fields']
> = {
  amount_rake?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amount_tournament?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_burn?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  total_burn_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies'] = ResolversParentTypes['currencies']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  coingecko_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate_btc?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  venus_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  venus_json?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<CurrenciesVenus_JsonArgs>
  >;
  venus_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  venus_symbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_aggregate'] = ResolversParentTypes['currencies_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['currencies_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['currencies']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_aggregate_fields'] = ResolversParentTypes['currencies_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['currencies_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Currencies_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['currencies_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['currencies_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['currencies_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['currencies_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['currencies_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['currencies_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['currencies_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['currencies_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['currencies_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_avg_fields'] = ResolversParentTypes['currencies_avg_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_btc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_max_fields'] = ResolversParentTypes['currencies_max_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  coingecko_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate_btc?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  venus_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  venus_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  venus_symbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_min_fields'] = ResolversParentTypes['currencies_min_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  coingecko_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate_btc?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  venus_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  venus_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  venus_symbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_mutation_response'] = ResolversParentTypes['currencies_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['currencies']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_stddev_fields'] = ResolversParentTypes['currencies_stddev_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_btc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_stddev_pop_fields'] = ResolversParentTypes['currencies_stddev_pop_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_btc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_stddev_samp_fields'] = ResolversParentTypes['currencies_stddev_samp_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_btc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_sum_fields'] = ResolversParentTypes['currencies_sum_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rate_btc?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_var_pop_fields'] = ResolversParentTypes['currencies_var_pop_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_btc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_var_samp_fields'] = ResolversParentTypes['currencies_var_samp_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_btc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currencies_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currencies_variance_fields'] = ResolversParentTypes['currencies_variance_fields']
> = {
  apy_distribution?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  apy_supply?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_btc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_last_update?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  venus_borrowApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  venus_supplyApy?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_HistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history'] = ResolversParentTypes['currency_history']
> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currency_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  rate_usd?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_aggregate'] = ResolversParentTypes['currency_history_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['currency_history_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['currency_history']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_aggregate_fields'] = ResolversParentTypes['currency_history_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['currency_history_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Currency_History_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['currency_history_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['currency_history_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['currency_history_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['currency_history_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['currency_history_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['currency_history_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['currency_history_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['currency_history_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['currency_history_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_avg_fields'] = ResolversParentTypes['currency_history_avg_fields']
> = {
  currency_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_max_fields'] = ResolversParentTypes['currency_history_max_fields']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rate_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_min_fields'] = ResolversParentTypes['currency_history_min_fields']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rate_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_mutation_response'] = ResolversParentTypes['currency_history_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['currency_history']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_stddev_fields'] = ResolversParentTypes['currency_history_stddev_fields']
> = {
  currency_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_stddev_pop_fields'] = ResolversParentTypes['currency_history_stddev_pop_fields']
> = {
  currency_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_stddev_samp_fields'] = ResolversParentTypes['currency_history_stddev_samp_fields']
> = {
  currency_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_sum_fields'] = ResolversParentTypes['currency_history_sum_fields']
> = {
  currency_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rate_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_var_pop_fields'] = ResolversParentTypes['currency_history_var_pop_fields']
> = {
  currency_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_var_samp_fields'] = ResolversParentTypes['currency_history_var_samp_fields']
> = {
  currency_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Currency_History_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['currency_history_variance_fields'] = ResolversParentTypes['currency_history_variance_fields']
> = {
  currency_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate_usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DepositAddressOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['depositAddressOutput'] = ResolversParentTypes['depositAddressOutput']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_AddressesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses'] = ResolversParentTypes['erc721_addresses']
> = {
  blockchain_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contract_address?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token_symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_aggregate'] = ResolversParentTypes['erc721_addresses_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['erc721_addresses']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_aggregate_fields'] = ResolversParentTypes['erc721_addresses_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Erc721_Addresses_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_avg_fields'] = ResolversParentTypes['erc721_addresses_avg_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_max_fields'] = ResolversParentTypes['erc721_addresses_max_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contract_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  token_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  token_symbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_min_fields'] = ResolversParentTypes['erc721_addresses_min_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contract_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  token_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  token_symbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_mutation_response'] = ResolversParentTypes['erc721_addresses_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['erc721_addresses']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_stddev_fields'] = ResolversParentTypes['erc721_addresses_stddev_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_stddev_pop_fields'] = ResolversParentTypes['erc721_addresses_stddev_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_stddev_samp_fields'] = ResolversParentTypes['erc721_addresses_stddev_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_sum_fields'] = ResolversParentTypes['erc721_addresses_sum_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_var_pop_fields'] = ResolversParentTypes['erc721_addresses_var_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_var_samp_fields'] = ResolversParentTypes['erc721_addresses_var_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Erc721_Addresses_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['erc721_addresses_variance_fields'] = ResolversParentTypes['erc721_addresses_variance_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalancesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances'] = ResolversParentTypes['evenbetAffiliatePlayerBalances']
> = {
  affiliateGpanelId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  affiliateUserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  affiliateUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  playerGpanelId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerUserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  playerUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_aggregate'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['evenbetAffiliatePlayerBalances']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_aggregate_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<EvenbetAffiliatePlayerBalances_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['evenbetAffiliatePlayerBalances_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_avg_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_avg_fields']
> = {
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_max_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_max_fields']
> = {
  affiliateGpanelId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  affiliateUserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  affiliateUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  playerGpanelId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerUserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  playerUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_min_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_min_fields']
> = {
  affiliateGpanelId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  affiliateUserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  affiliateUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  playerGpanelId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerUserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  playerUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_stddev_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_stddev_fields']
> = {
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_stddev_pop_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_stddev_pop_fields']
> = {
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_stddev_samp_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_stddev_samp_fields']
> = {
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_sum_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_sum_fields']
> = {
  cashBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_var_pop_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_var_pop_fields']
> = {
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_var_samp_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_var_samp_fields']
> = {
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EvenbetAffiliatePlayerBalances_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbetAffiliatePlayerBalances_variance_fields'] = ResolversParentTypes['evenbetAffiliatePlayerBalances_variance_fields']
> = {
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalanceUsd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_RequestsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests'] = ResolversParentTypes['evenbet_ticket_requests']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  evenbet_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grouped_request_id?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nft_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticket_template?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_aggregate'] = ResolversParentTypes['evenbet_ticket_requests_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['evenbet_ticket_requests']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_aggregate_fields'] = ResolversParentTypes['evenbet_ticket_requests_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Evenbet_Ticket_Requests_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_avg_fields'] = ResolversParentTypes['evenbet_ticket_requests_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_max_fields'] = ResolversParentTypes['evenbet_ticket_requests_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  grouped_request_id?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_min_fields'] = ResolversParentTypes['evenbet_ticket_requests_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  grouped_request_id?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_mutation_response'] = ResolversParentTypes['evenbet_ticket_requests_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['evenbet_ticket_requests']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_stddev_fields'] = ResolversParentTypes['evenbet_ticket_requests_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_stddev_pop_fields'] = ResolversParentTypes['evenbet_ticket_requests_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_stddev_samp_fields'] = ResolversParentTypes['evenbet_ticket_requests_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_sum_fields'] = ResolversParentTypes['evenbet_ticket_requests_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_var_pop_fields'] = ResolversParentTypes['evenbet_ticket_requests_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_var_samp_fields'] = ResolversParentTypes['evenbet_ticket_requests_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Ticket_Requests_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_ticket_requests_variance_fields'] = ResolversParentTypes['evenbet_ticket_requests_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticket_template?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_TicketsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets'] = ResolversParentTypes['evenbet_tickets']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  evenbet_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expiration_date?: Resolver<
    ResolversTypes['timestamptz'],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  money_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  used_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_aggregate'] = ResolversParentTypes['evenbet_tickets_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['evenbet_tickets']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_aggregate_fields'] = ResolversParentTypes['evenbet_tickets_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Evenbet_Tickets_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_avg_fields'] = ResolversParentTypes['evenbet_tickets_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_max_fields'] = ResolversParentTypes['evenbet_tickets_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expiration_date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  issued_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  money_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  used_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_min_fields'] = ResolversParentTypes['evenbet_tickets_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expiration_date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  issued_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  money_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  used_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_mutation_response'] = ResolversParentTypes['evenbet_tickets_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['evenbet_tickets']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_stddev_fields'] = ResolversParentTypes['evenbet_tickets_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_stddev_pop_fields'] = ResolversParentTypes['evenbet_tickets_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_stddev_samp_fields'] = ResolversParentTypes['evenbet_tickets_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_sum_fields'] = ResolversParentTypes['evenbet_tickets_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_var_pop_fields'] = ResolversParentTypes['evenbet_tickets_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_var_samp_fields'] = ResolversParentTypes['evenbet_tickets_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Evenbet_Tickets_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['evenbet_tickets_variance_fields'] = ResolversParentTypes['evenbet_tickets_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  spend_for?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GPanelOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gPanelOutput'] = ResolversParentTypes['gPanelOutput']
> = {
  accessToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPaymentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments'] = ResolversParentTypes['gpanelPayments']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  gpanelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gpanelUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gpanel_user?: Resolver<
    ResolversTypes['gpanelUsers'],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  identity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_aggregate'] = ResolversParentTypes['gpanelPayments_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['gpanelPayments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_aggregate_fields'] = ResolversParentTypes['gpanelPayments_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<GpanelPayments_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_avg_fields'] = ResolversParentTypes['gpanelPayments_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_max_fields'] = ResolversParentTypes['gpanelPayments_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_min_fields'] = ResolversParentTypes['gpanelPayments_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_mutation_response'] = ResolversParentTypes['gpanelPayments_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['gpanelPayments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_stddev_fields'] = ResolversParentTypes['gpanelPayments_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_stddev_pop_fields'] = ResolversParentTypes['gpanelPayments_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_stddev_samp_fields'] = ResolversParentTypes['gpanelPayments_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_sum_fields'] = ResolversParentTypes['gpanelPayments_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_var_pop_fields'] = ResolversParentTypes['gpanelPayments_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_var_samp_fields'] = ResolversParentTypes['gpanelPayments_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelPayments_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelPayments_variance_fields'] = ResolversParentTypes['gpanelPayments_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers'] = ResolversParentTypes['gpanelUsers']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bonusBalance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  cashBalance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  cash_balance_inp?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gpanelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gpanel_payments?: Resolver<
    Array<ResolversTypes['gpanelPayments']>,
    ParentType,
    ContextType,
    Partial<GpanelUsersGpanel_PaymentsArgs>
  >;
  gpanel_payments_aggregate?: Resolver<
    ResolversTypes['gpanelPayments_aggregate'],
    ParentType,
    ContextType,
    Partial<GpanelUsersGpanel_Payments_AggregateArgs>
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  kyc?: Resolver<ResolversTypes['kycs'], ParentType, ContextType>;
  kycPending?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  kycStatus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  registered?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state_internal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_aggregate'] = ResolversParentTypes['gpanelUsers_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_aggregate_fields'] = ResolversParentTypes['gpanelUsers_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<GpanelUsers_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_avg_fields'] = ResolversParentTypes['gpanelUsers_avg_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_max_fields'] = ResolversParentTypes['gpanelUsers_max_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gpanelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  registered?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state_internal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_min_fields'] = ResolversParentTypes['gpanelUsers_min_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gpanelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  registered?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state_internal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_mutation_response'] = ResolversParentTypes['gpanelUsers_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_stddev_fields'] = ResolversParentTypes['gpanelUsers_stddev_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_stddev_pop_fields'] = ResolversParentTypes['gpanelUsers_stddev_pop_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_stddev_samp_fields'] = ResolversParentTypes['gpanelUsers_stddev_samp_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_sum_fields'] = ResolversParentTypes['gpanelUsers_sum_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_var_pop_fields'] = ResolversParentTypes['gpanelUsers_var_pop_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_var_samp_fields'] = ResolversParentTypes['gpanelUsers_var_samp_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpanelUsers_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanelUsers_variance_fields'] = ResolversParentTypes['gpanelUsers_variance_fields']
> = {
  avatar_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bonusBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cashBalance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_inp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  cash_balance_usd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  evenbet_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  kycStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_AgentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent'] = ResolversParentTypes['gpanel_users_agent']
> = {
  agent_of_gpanel_user_id?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  gpanel_user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_aggregate'] = ResolversParentTypes['gpanel_users_agent_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['gpanel_users_agent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_aggregate_fields'] = ResolversParentTypes['gpanel_users_agent_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Gpanel_Users_Agent_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_avg_fields'] = ResolversParentTypes['gpanel_users_agent_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_max_fields'] = ResolversParentTypes['gpanel_users_agent_max_fields']
> = {
  agent_of_gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_min_fields'] = ResolversParentTypes['gpanel_users_agent_min_fields']
> = {
  agent_of_gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_mutation_response'] = ResolversParentTypes['gpanel_users_agent_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['gpanel_users_agent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_stddev_fields'] = ResolversParentTypes['gpanel_users_agent_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_stddev_pop_fields'] = ResolversParentTypes['gpanel_users_agent_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_stddev_samp_fields'] = ResolversParentTypes['gpanel_users_agent_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_sum_fields'] = ResolversParentTypes['gpanel_users_agent_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_var_pop_fields'] = ResolversParentTypes['gpanel_users_agent_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_var_samp_fields'] = ResolversParentTypes['gpanel_users_agent_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Agent_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_agent_variance_fields'] = ResolversParentTypes['gpanel_users_agent_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_AddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address'] = ResolversParentTypes['gpanel_users_deposit_address']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blockchain_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blockchain_main_deposit_id?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  tx?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_aggregate'] = ResolversParentTypes['gpanel_users_deposit_address_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['gpanel_users_deposit_address']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_aggregate_fields'] = ResolversParentTypes['gpanel_users_deposit_address_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Gpanel_Users_Deposit_Address_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_avg_fields'] = ResolversParentTypes['gpanel_users_deposit_address_avg_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_max_fields'] = ResolversParentTypes['gpanel_users_deposit_address_max_fields']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tx?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_min_fields'] = ResolversParentTypes['gpanel_users_deposit_address_min_fields']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tx?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_mutation_response'] = ResolversParentTypes['gpanel_users_deposit_address_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['gpanel_users_deposit_address']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_stddev_fields'] = ResolversParentTypes['gpanel_users_deposit_address_stddev_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_stddev_pop_fields'] = ResolversParentTypes['gpanel_users_deposit_address_stddev_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_stddev_samp_fields'] = ResolversParentTypes['gpanel_users_deposit_address_stddev_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_sum_fields'] = ResolversParentTypes['gpanel_users_deposit_address_sum_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_var_pop_fields'] = ResolversParentTypes['gpanel_users_deposit_address_var_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_var_samp_fields'] = ResolversParentTypes['gpanel_users_deposit_address_var_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Deposit_Address_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_deposit_address_variance_fields'] = ResolversParentTypes['gpanel_users_deposit_address_variance_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_main_deposit_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_KycResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc'] = ResolversParentTypes['gpanel_users_kyc']
> = {
  approvedDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  gpanel_user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inreviewDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  levelName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reviewResult?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  waitingDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_aggregate'] = ResolversParentTypes['gpanel_users_kyc_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['gpanel_users_kyc']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_aggregate_fields'] = ResolversParentTypes['gpanel_users_kyc_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Gpanel_Users_Kyc_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_avg_fields'] = ResolversParentTypes['gpanel_users_kyc_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_max_fields'] = ResolversParentTypes['gpanel_users_kyc_max_fields']
> = {
  approvedDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  inreviewDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  levelName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reviewResult?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  waitingDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_min_fields'] = ResolversParentTypes['gpanel_users_kyc_min_fields']
> = {
  approvedDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  inreviewDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  levelName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reviewResult?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  waitingDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_mutation_response'] = ResolversParentTypes['gpanel_users_kyc_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['gpanel_users_kyc']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_stddev_fields'] = ResolversParentTypes['gpanel_users_kyc_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_stddev_pop_fields'] = ResolversParentTypes['gpanel_users_kyc_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_stddev_samp_fields'] = ResolversParentTypes['gpanel_users_kyc_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_sum_fields'] = ResolversParentTypes['gpanel_users_kyc_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_var_pop_fields'] = ResolversParentTypes['gpanel_users_kyc_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_var_samp_fields'] = ResolversParentTypes['gpanel_users_kyc_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Gpanel_Users_Kyc_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gpanel_users_kyc_variance_fields'] = ResolversParentTypes['gpanel_users_kyc_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['json'], any> {
  name: 'json';
}

export interface JsonbScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type KycOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycOutput'] = ResolversParentTypes['kycOutput']
> = {
  feeDeposit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  feeDepositInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  feeWithdraw?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  feeWithdrawInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxDeposit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxDepositInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxWithdraw?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxWithdrawInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minDeposit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minDepositInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minWithdraw?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minWithdrawInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KycsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs'] = ResolversParentTypes['kycs']
> = {
  feeDeposit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  feeDepositInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  feeWithdraw?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  feeWithdrawInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxDeposit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxDepositInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxWithdraw?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxWithdrawInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minDeposit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minDepositInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minWithdraw?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minWithdrawInp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_aggregate'] = ResolversParentTypes['kycs_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['kycs_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['kycs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_aggregate_fields'] = ResolversParentTypes['kycs_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['kycs_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Kycs_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['kycs_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['kycs_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['kycs_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['kycs_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['kycs_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['kycs_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['kycs_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['kycs_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['kycs_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_avg_fields'] = ResolversParentTypes['kycs_avg_fields']
> = {
  feeDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_max_fields'] = ResolversParentTypes['kycs_max_fields']
> = {
  feeDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_min_fields'] = ResolversParentTypes['kycs_min_fields']
> = {
  feeDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_mutation_response'] = ResolversParentTypes['kycs_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['kycs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_stddev_fields'] = ResolversParentTypes['kycs_stddev_fields']
> = {
  feeDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_stddev_pop_fields'] = ResolversParentTypes['kycs_stddev_pop_fields']
> = {
  feeDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_stddev_samp_fields'] = ResolversParentTypes['kycs_stddev_samp_fields']
> = {
  feeDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_sum_fields'] = ResolversParentTypes['kycs_sum_fields']
> = {
  feeDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_var_pop_fields'] = ResolversParentTypes['kycs_var_pop_fields']
> = {
  feeDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_var_samp_fields'] = ResolversParentTypes['kycs_var_samp_fields']
> = {
  feeDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Kycs_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['kycs_variance_fields'] = ResolversParentTypes['kycs_variance_fields']
> = {
  feeDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  feeWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  maxWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDeposit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minDepositInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdraw?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  minWithdrawInp?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard'] = ResolversParentTypes['leaderboard']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  end?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  money_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reward?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  website_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_aggregate'] = ResolversParentTypes['leaderboard_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['leaderboard_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['leaderboard']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_aggregate_fields'] = ResolversParentTypes['leaderboard_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['leaderboard_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Leaderboard_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['leaderboard_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['leaderboard_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['leaderboard_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['leaderboard_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['leaderboard_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['leaderboard_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['leaderboard_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['leaderboard_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['leaderboard_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_avg_fields'] = ResolversParentTypes['leaderboard_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_max_fields'] = ResolversParentTypes['leaderboard_max_fields']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  end?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reward?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  website_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_min_fields'] = ResolversParentTypes['leaderboard_min_fields']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  end?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money_type?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reward?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  website_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_mutation_response'] = ResolversParentTypes['leaderboard_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['leaderboard']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_RankingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking'] = ResolversParentTypes['leaderboard_ranking']
> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  gpanel_user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  leaderboard_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_aggregate'] = ResolversParentTypes['leaderboard_ranking_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['leaderboard_ranking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_aggregate_fields'] = ResolversParentTypes['leaderboard_ranking_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Leaderboard_Ranking_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_avg_fields'] = ResolversParentTypes['leaderboard_ranking_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_max_fields'] = ResolversParentTypes['leaderboard_ranking_max_fields']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_min_fields'] = ResolversParentTypes['leaderboard_ranking_min_fields']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_mutation_response'] = ResolversParentTypes['leaderboard_ranking_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['leaderboard_ranking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_stddev_fields'] = ResolversParentTypes['leaderboard_ranking_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_stddev_pop_fields'] = ResolversParentTypes['leaderboard_ranking_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_stddev_samp_fields'] = ResolversParentTypes['leaderboard_ranking_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_sum_fields'] = ResolversParentTypes['leaderboard_ranking_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_var_pop_fields'] = ResolversParentTypes['leaderboard_ranking_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_var_samp_fields'] = ResolversParentTypes['leaderboard_ranking_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Ranking_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_ranking_variance_fields'] = ResolversParentTypes['leaderboard_ranking_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leaderboard_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_stddev_fields'] = ResolversParentTypes['leaderboard_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_stddev_pop_fields'] = ResolversParentTypes['leaderboard_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_stddev_samp_fields'] = ResolversParentTypes['leaderboard_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_sum_fields'] = ResolversParentTypes['leaderboard_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_var_pop_fields'] = ResolversParentTypes['leaderboard_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_var_samp_fields'] = ResolversParentTypes['leaderboard_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Leaderboard_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['leaderboard_variance_fields'] = ResolversParentTypes['leaderboard_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_PoolsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools'] = ResolversParentTypes['liquidity_pools']
> = {
  contract?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency_id1?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency_id2?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_aggregate'] = ResolversParentTypes['liquidity_pools_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['liquidity_pools']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_aggregate_fields'] = ResolversParentTypes['liquidity_pools_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Liquidity_Pools_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_avg_fields'] = ResolversParentTypes['liquidity_pools_avg_fields']
> = {
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_max_fields'] = ResolversParentTypes['liquidity_pools_max_fields']
> = {
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_min_fields'] = ResolversParentTypes['liquidity_pools_min_fields']
> = {
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_mutation_response'] = ResolversParentTypes['liquidity_pools_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['liquidity_pools']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_stddev_fields'] = ResolversParentTypes['liquidity_pools_stddev_fields']
> = {
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_stddev_pop_fields'] = ResolversParentTypes['liquidity_pools_stddev_pop_fields']
> = {
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_stddev_samp_fields'] = ResolversParentTypes['liquidity_pools_stddev_samp_fields']
> = {
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_sum_fields'] = ResolversParentTypes['liquidity_pools_sum_fields']
> = {
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_var_pop_fields'] = ResolversParentTypes['liquidity_pools_var_pop_fields']
> = {
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_var_samp_fields'] = ResolversParentTypes['liquidity_pools_var_samp_fields']
> = {
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Liquidity_Pools_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['liquidity_pools_variance_fields'] = ResolversParentTypes['liquidity_pools_variance_fields']
> = {
  currency_id1?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency_id2?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings'] = ResolversParentTypes['marketListings']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  buyer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  erc20Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isErc721?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastBidder?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  marketplaceContract?: Resolver<
    ResolversTypes['marketplaceContracts'],
    ParentType,
    ContextType
  >;
  marketplaceContractId?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  nft?: Resolver<ResolversTypes['nfts'], ParentType, ContextType>;
  nftId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  originalListingId?: Resolver<
    ResolversTypes['bigint'],
    ParentType,
    ContextType
  >;
  price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  seller?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stakeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_aggregate'] = ResolversParentTypes['marketListings_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['marketListings_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['marketListings']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_aggregate_fields'] = ResolversParentTypes['marketListings_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['marketListings_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<MarketListings_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['marketListings_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['marketListings_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['marketListings_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['marketListings_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['marketListings_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['marketListings_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['marketListings_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['marketListings_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['marketListings_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_avg_fields'] = ResolversParentTypes['marketListings_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_max_fields'] = ResolversParentTypes['marketListings_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  buyer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastBidder?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  seller?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stakeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_min_fields'] = ResolversParentTypes['marketListings_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  buyer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastBidder?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  seller?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stakeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_mutation_response'] = ResolversParentTypes['marketListings_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['marketListings']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_stddev_fields'] = ResolversParentTypes['marketListings_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_stddev_pop_fields'] = ResolversParentTypes['marketListings_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_stddev_samp_fields'] = ResolversParentTypes['marketListings_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_sum_fields'] = ResolversParentTypes['marketListings_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  stakeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_var_pop_fields'] = ResolversParentTypes['marketListings_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_var_samp_fields'] = ResolversParentTypes['marketListings_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListings_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketListings_variance_fields'] = ResolversParentTypes['marketListings_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketplaceContractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  nftId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContractsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts'] = ResolversParentTypes['marketplaceContracts']
> = {
  blockNumber?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  chain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  feeRecipient?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_aggregate'] = ResolversParentTypes['marketplaceContracts_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['marketplaceContracts']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_aggregate_fields'] = ResolversParentTypes['marketplaceContracts_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<MarketplaceContracts_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_avg_fields'] = ResolversParentTypes['marketplaceContracts_avg_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_max_fields'] = ResolversParentTypes['marketplaceContracts_max_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  chain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  feeRecipient?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_min_fields'] = ResolversParentTypes['marketplaceContracts_min_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  chain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  feeRecipient?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_mutation_response'] = ResolversParentTypes['marketplaceContracts_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['marketplaceContracts']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_stddev_fields'] = ResolversParentTypes['marketplaceContracts_stddev_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_stddev_pop_fields'] = ResolversParentTypes['marketplaceContracts_stddev_pop_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_stddev_samp_fields'] = ResolversParentTypes['marketplaceContracts_stddev_samp_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_sum_fields'] = ResolversParentTypes['marketplaceContracts_sum_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_var_pop_fields'] = ResolversParentTypes['marketplaceContracts_var_pop_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_var_samp_fields'] = ResolversParentTypes['marketplaceContracts_var_samp_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceContracts_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['marketplaceContracts_variance_fields'] = ResolversParentTypes['marketplaceContracts_variance_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mutation_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']
> = {
  NFTDelegationAccept?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootNftDelegationAcceptArgs, 'accept' | 'nftIds'>
  >;
  NFTReleaseTicket?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootNftReleaseTicketArgs, 'ticketId'>
  >;
  NFTRentOut?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootNftRentOutArgs,
      'blockchainId' | 'delegate' | 'nftIds'
    >
  >;
  agentPaymentSubmitDeposit?: Resolver<
    Maybe<ResolversTypes['DepositOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootAgentPaymentSubmitDepositArgs,
      'address' | 'amount' | 'gpanelUserId'
    >
  >;
  agentPaymentSubmitWithdraw?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootAgentPaymentSubmitWithdrawArgs,
      'agentGpanelUserId' | 'amount' | 'gpanelAccessToken' | 'gpanelUserId'
    >
  >;
  agentPaymentSubmittedDeposit?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootAgentPaymentSubmittedDepositArgs, 'id' | 'tx'>
  >;
  deleteBlockchain?: Resolver<
    Maybe<ResolversTypes['blockchain']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteBlockchainArgs, 'id'>
  >;
  deleteBlockchains?: Resolver<
    Maybe<ResolversTypes['blockchain_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteBlockchainsArgs, 'where'>
  >;
  deleteGpanelPayment?: Resolver<
    Maybe<ResolversTypes['gpanelPayments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteGpanelPaymentArgs, 'id'>
  >;
  deleteGpanelPayments?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteGpanelPaymentsArgs, 'where'>
  >;
  deleteGpanelUser?: Resolver<
    Maybe<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteGpanelUserArgs, 'id'>
  >;
  deleteGpanelUsers?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteGpanelUsersArgs, 'where'>
  >;
  deleteMarketListing?: Resolver<
    Maybe<ResolversTypes['marketListings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteMarketListingArgs, 'id'>
  >;
  deleteMarketListings?: Resolver<
    Maybe<ResolversTypes['marketListings_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteMarketListingsArgs, 'where'>
  >;
  deleteMarketplaceContract?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteMarketplaceContractArgs, 'id'>
  >;
  deleteMarketplaceContracts?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteMarketplaceContractsArgs, 'where'>
  >;
  deleteNft?: Resolver<
    Maybe<ResolversTypes['nfts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNftArgs, 'id'>
  >;
  deleteNftContract?: Resolver<
    Maybe<ResolversTypes['nftContracts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNftContractArgs, 'id'>
  >;
  deleteNftContracts?: Resolver<
    Maybe<ResolversTypes['nftContracts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNftContractsArgs, 'where'>
  >;
  deleteNftMetadata?: Resolver<
    Maybe<ResolversTypes['nftMetadatas']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNftMetadataArgs, 'id'>
  >;
  deleteNftMetadatas?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNftMetadatasArgs, 'where'>
  >;
  deleteNftOwner?: Resolver<
    Maybe<ResolversTypes['nftOwners']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNftOwnerArgs, 'id'>
  >;
  deleteNftOwners?: Resolver<
    Maybe<ResolversTypes['nftOwners_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNftOwnersArgs, 'where'>
  >;
  deleteNfts?: Resolver<
    Maybe<ResolversTypes['nfts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNftsArgs, 'where'>
  >;
  deletePendingTransaction?: Resolver<
    Maybe<ResolversTypes['pendingTransactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePendingTransactionArgs, 'id'>
  >;
  deletePendingTransactions?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePendingTransactionsArgs, 'where'>
  >;
  deleteStablecoin?: Resolver<
    Maybe<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStablecoinArgs, 'id'>
  >;
  deleteStablecoins?: Resolver<
    Maybe<ResolversTypes['stablecoins_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStablecoinsArgs, 'where'>
  >;
  deleteSwapTransactions?: Resolver<
    Maybe<ResolversTypes['swapTransactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteSwapTransactionsArgs, 'where'>
  >;
  deleteTransactionStatistic?: Resolver<
    Maybe<ResolversTypes['transactionStatistics']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTransactionStatisticArgs, 'id'>
  >;
  deleteTransactionStatistics?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTransactionStatisticsArgs, 'where'>
  >;
  deleteUser?: Resolver<
    Maybe<ResolversTypes['users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserArgs, 'id'>
  >;
  deleteUserErc721?: Resolver<
    Maybe<ResolversTypes['userErc721']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserErc721Args, 'id'>
  >;
  deleteUserErc721s?: Resolver<
    Maybe<ResolversTypes['userErc721_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserErc721sArgs, 'where'>
  >;
  deleteUserJob?: Resolver<
    Maybe<ResolversTypes['userJobs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserJobArgs, 'id'>
  >;
  deleteUserJobs?: Resolver<
    Maybe<ResolversTypes['userJobs_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserJobsArgs, 'where'>
  >;
  deleteUsers?: Resolver<
    Maybe<ResolversTypes['users_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersArgs, 'where'>
  >;
  delete_Test?: Resolver<
    Maybe<ResolversTypes['Test_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_TestArgs, 'where'>
  >;
  delete_Test_by_pk?: Resolver<
    Maybe<ResolversTypes['Test']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Test_By_PkArgs, 'id'>
  >;
  delete_anno_gpanel?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Anno_GpanelArgs, 'where'>
  >;
  delete_anno_gpanel_by_pk?: Resolver<
    Maybe<ResolversTypes['anno_gpanel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Anno_Gpanel_By_PkArgs, 'id'>
  >;
  delete_blockchain_main_deposit?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Blockchain_Main_DepositArgs, 'where'>
  >;
  delete_blockchain_main_deposit_by_pk?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Blockchain_Main_Deposit_By_PkArgs, 'id'>
  >;
  delete_burn_profit?: Resolver<
    Maybe<ResolversTypes['burn_profit_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Burn_ProfitArgs, 'where'>
  >;
  delete_burn_profit_by_pk?: Resolver<
    Maybe<ResolversTypes['burn_profit']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Burn_Profit_By_PkArgs, 'id'>
  >;
  delete_currencies?: Resolver<
    Maybe<ResolversTypes['currencies_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_CurrenciesArgs, 'where'>
  >;
  delete_currencies_by_pk?: Resolver<
    Maybe<ResolversTypes['currencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Currencies_By_PkArgs, 'id'>
  >;
  delete_currency_history?: Resolver<
    Maybe<ResolversTypes['currency_history_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Currency_HistoryArgs, 'where'>
  >;
  delete_currency_history_by_pk?: Resolver<
    Maybe<ResolversTypes['currency_history']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Currency_History_By_PkArgs, 'id'>
  >;
  delete_erc721_addresses?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Erc721_AddressesArgs, 'where'>
  >;
  delete_erc721_addresses_by_pk?: Resolver<
    Maybe<ResolversTypes['erc721_addresses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Erc721_Addresses_By_PkArgs, 'id'>
  >;
  delete_evenbet_ticket_requests?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Evenbet_Ticket_RequestsArgs, 'where'>
  >;
  delete_evenbet_ticket_requests_by_pk?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Evenbet_Ticket_Requests_By_PkArgs, 'id'>
  >;
  delete_evenbet_tickets?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Evenbet_TicketsArgs, 'where'>
  >;
  delete_evenbet_tickets_by_pk?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Evenbet_Tickets_By_PkArgs, 'id'>
  >;
  delete_gpanel_users_agent?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Gpanel_Users_AgentArgs, 'where'>
  >;
  delete_gpanel_users_agent_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Gpanel_Users_Agent_By_PkArgs, 'id'>
  >;
  delete_gpanel_users_deposit_address?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Gpanel_Users_Deposit_AddressArgs, 'where'>
  >;
  delete_gpanel_users_deposit_address_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootDelete_Gpanel_Users_Deposit_Address_By_PkArgs,
      'id'
    >
  >;
  delete_gpanel_users_kyc?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Gpanel_Users_KycArgs, 'where'>
  >;
  delete_gpanel_users_kyc_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Gpanel_Users_Kyc_By_PkArgs, 'id'>
  >;
  delete_kycs?: Resolver<
    Maybe<ResolversTypes['kycs_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_KycsArgs, 'where'>
  >;
  delete_kycs_by_pk?: Resolver<
    Maybe<ResolversTypes['kycs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Kycs_By_PkArgs, 'id'>
  >;
  delete_leaderboard?: Resolver<
    Maybe<ResolversTypes['leaderboard_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_LeaderboardArgs, 'where'>
  >;
  delete_leaderboard_by_pk?: Resolver<
    Maybe<ResolversTypes['leaderboard']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Leaderboard_By_PkArgs, 'id'>
  >;
  delete_leaderboard_ranking?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Leaderboard_RankingArgs, 'where'>
  >;
  delete_leaderboard_ranking_by_pk?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Leaderboard_Ranking_By_PkArgs, 'id'>
  >;
  delete_liquidity_pools?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Liquidity_PoolsArgs, 'where'>
  >;
  delete_liquidity_pools_by_pk?: Resolver<
    Maybe<ResolversTypes['liquidity_pools']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Liquidity_Pools_By_PkArgs, 'id'>
  >;
  delete_nft_gpanel_users?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Nft_Gpanel_UsersArgs, 'where'>
  >;
  delete_nft_gpanel_users_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Nft_Gpanel_Users_By_PkArgs, 'id'>
  >;
  delete_nft_listings?: Resolver<
    Maybe<ResolversTypes['nft_listings_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Nft_ListingsArgs, 'where'>
  >;
  delete_nft_listings_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_listings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Nft_Listings_By_PkArgs, 'id'>
  >;
  delete_nft_transactions?: Resolver<
    Maybe<ResolversTypes['nft_transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Nft_TransactionsArgs, 'where'>
  >;
  delete_nft_transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Nft_Transactions_By_PkArgs, 'id'>
  >;
  delete_pending_tx?: Resolver<
    Maybe<ResolversTypes['pending_tx_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Pending_TxArgs, 'where'>
  >;
  delete_pending_tx_by_pk?: Resolver<
    Maybe<ResolversTypes['pending_tx']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Pending_Tx_By_PkArgs, 'id'>
  >;
  delete_rakebackLocks?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_RakebackLocksArgs, 'where'>
  >;
  delete_rakebackLocks_by_pk?: Resolver<
    Maybe<ResolversTypes['rakebackLocks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_RakebackLocks_By_PkArgs, 'id'>
  >;
  delete_staking_refill?: Resolver<
    Maybe<ResolversTypes['staking_refill_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Staking_RefillArgs, 'where'>
  >;
  delete_staking_refill_by_pk?: Resolver<
    Maybe<ResolversTypes['staking_refill']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Staking_Refill_By_PkArgs, 'id'>
  >;
  delete_tournaments?: Resolver<
    Maybe<ResolversTypes['tournaments_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_TournamentsArgs, 'where'>
  >;
  delete_tournaments_by_pk?: Resolver<
    Maybe<ResolversTypes['tournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Tournaments_By_PkArgs, 'id'>
  >;
  delete_transactions?: Resolver<
    Maybe<ResolversTypes['transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_TransactionsArgs, 'where'>
  >;
  delete_transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Transactions_By_PkArgs, 'id'>
  >;
  delete_user_erc721_block?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_Erc721_BlockArgs, 'where'>
  >;
  delete_user_erc721_block_by_pk?: Resolver<
    Maybe<ResolversTypes['user_erc721_block']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_Erc721_Block_By_PkArgs, 'id'>
  >;
  delete_user_gpanel?: Resolver<
    Maybe<ResolversTypes['user_gpanel_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_GpanelArgs, 'where'>
  >;
  delete_user_gpanel_by_pk?: Resolver<
    Maybe<ResolversTypes['user_gpanel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_Gpanel_By_PkArgs, 'id'>
  >;
  delete_user_lock?: Resolver<
    Maybe<ResolversTypes['user_lock_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_LockArgs, 'where'>
  >;
  delete_user_lock_by_pk?: Resolver<
    Maybe<ResolversTypes['user_lock']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_Lock_By_PkArgs, 'id'>
  >;
  delete_user_staking?: Resolver<
    Maybe<ResolversTypes['user_staking_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_StakingArgs, 'where'>
  >;
  delete_user_staking_by_pk?: Resolver<
    Maybe<ResolversTypes['user_staking']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_Staking_By_PkArgs, 'id'>
  >;
  delete_user_staking_transactions?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_Staking_TransactionsArgs, 'where'>
  >;
  delete_user_staking_transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_User_Staking_Transactions_By_PkArgs, 'id'>
  >;
  delete_users_old?: Resolver<
    Maybe<ResolversTypes['users_old_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Users_OldArgs, 'where'>
  >;
  delete_users_old_by_pk?: Resolver<
    Maybe<ResolversTypes['users_old']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDelete_Users_Old_By_PkArgs, 'id'>
  >;
  insertBlockchain?: Resolver<
    Maybe<ResolversTypes['blockchain']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertBlockchainArgs, 'object'>
  >;
  insertBlockchains?: Resolver<
    Maybe<ResolversTypes['blockchain_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertBlockchainsArgs, 'objects'>
  >;
  insertGpanelPayment?: Resolver<
    Maybe<ResolversTypes['gpanelPayments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertGpanelPaymentArgs, 'object'>
  >;
  insertGpanelPayments?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertGpanelPaymentsArgs, 'objects'>
  >;
  insertGpanelUser?: Resolver<
    Maybe<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertGpanelUserArgs, 'object'>
  >;
  insertGpanelUsers?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertGpanelUsersArgs, 'objects'>
  >;
  insertMarketListing?: Resolver<
    Maybe<ResolversTypes['marketListings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertMarketListingArgs, 'object'>
  >;
  insertMarketListings?: Resolver<
    Maybe<ResolversTypes['marketListings_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertMarketListingsArgs, 'objects'>
  >;
  insertMarketplaceContract?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertMarketplaceContractArgs, 'object'>
  >;
  insertMarketplaceContracts?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertMarketplaceContractsArgs, 'objects'>
  >;
  insertNft?: Resolver<
    Maybe<ResolversTypes['nfts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNftArgs, 'object'>
  >;
  insertNftContract?: Resolver<
    Maybe<ResolversTypes['nftContracts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNftContractArgs, 'object'>
  >;
  insertNftContracts?: Resolver<
    Maybe<ResolversTypes['nftContracts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNftContractsArgs, 'objects'>
  >;
  insertNftMetadata?: Resolver<
    Maybe<ResolversTypes['nftMetadatas']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNftMetadataArgs, 'object'>
  >;
  insertNftMetadatas?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNftMetadatasArgs, 'objects'>
  >;
  insertNftOwner?: Resolver<
    Maybe<ResolversTypes['nftOwners']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNftOwnerArgs, 'object'>
  >;
  insertNftOwners?: Resolver<
    Maybe<ResolversTypes['nftOwners_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNftOwnersArgs, 'objects'>
  >;
  insertNfts?: Resolver<
    Maybe<ResolversTypes['nfts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNftsArgs, 'objects'>
  >;
  insertPendingTransaction?: Resolver<
    Maybe<ResolversTypes['pendingTransactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPendingTransactionArgs, 'object'>
  >;
  insertPendingTransactions?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPendingTransactionsArgs, 'objects'>
  >;
  insertStablecoin?: Resolver<
    Maybe<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStablecoinArgs, 'object'>
  >;
  insertStablecoins?: Resolver<
    Maybe<ResolversTypes['stablecoins_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStablecoinsArgs, 'objects'>
  >;
  insertSwapTransaction?: Resolver<
    Maybe<ResolversTypes['swapTransactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertSwapTransactionArgs, 'object'>
  >;
  insertSwapTransactions?: Resolver<
    Maybe<ResolversTypes['swapTransactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertSwapTransactionsArgs, 'objects'>
  >;
  insertTransactionStatistic?: Resolver<
    Maybe<ResolversTypes['transactionStatistics']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTransactionStatisticArgs, 'object'>
  >;
  insertTransactionStatistics?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTransactionStatisticsArgs, 'objects'>
  >;
  insertUser?: Resolver<
    Maybe<ResolversTypes['users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserArgs, 'object'>
  >;
  insertUserErc721?: Resolver<
    Maybe<ResolversTypes['userErc721']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserErc721Args, 'object'>
  >;
  insertUserErc721s?: Resolver<
    Maybe<ResolversTypes['userErc721_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserErc721sArgs, 'objects'>
  >;
  insertUserJob?: Resolver<
    Maybe<ResolversTypes['userJobs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserJobArgs, 'object'>
  >;
  insertUserJobs?: Resolver<
    Maybe<ResolversTypes['userJobs_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserJobsArgs, 'objects'>
  >;
  insertUsers?: Resolver<
    Maybe<ResolversTypes['users_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersArgs, 'objects'>
  >;
  insert_Test?: Resolver<
    Maybe<ResolversTypes['Test_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_TestArgs, 'objects'>
  >;
  insert_Test_one?: Resolver<
    Maybe<ResolversTypes['Test']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Test_OneArgs, 'object'>
  >;
  insert_anno_gpanel?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Anno_GpanelArgs, 'objects'>
  >;
  insert_anno_gpanel_one?: Resolver<
    Maybe<ResolversTypes['anno_gpanel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Anno_Gpanel_OneArgs, 'object'>
  >;
  insert_blockchain_main_deposit?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Blockchain_Main_DepositArgs, 'objects'>
  >;
  insert_blockchain_main_deposit_one?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Blockchain_Main_Deposit_OneArgs, 'object'>
  >;
  insert_burn_profit?: Resolver<
    Maybe<ResolversTypes['burn_profit_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Burn_ProfitArgs, 'objects'>
  >;
  insert_burn_profit_one?: Resolver<
    Maybe<ResolversTypes['burn_profit']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Burn_Profit_OneArgs, 'object'>
  >;
  insert_currencies?: Resolver<
    Maybe<ResolversTypes['currencies_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_CurrenciesArgs, 'objects'>
  >;
  insert_currencies_one?: Resolver<
    Maybe<ResolversTypes['currencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Currencies_OneArgs, 'object'>
  >;
  insert_currency_history?: Resolver<
    Maybe<ResolversTypes['currency_history_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Currency_HistoryArgs, 'objects'>
  >;
  insert_currency_history_one?: Resolver<
    Maybe<ResolversTypes['currency_history']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Currency_History_OneArgs, 'object'>
  >;
  insert_erc721_addresses?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Erc721_AddressesArgs, 'objects'>
  >;
  insert_erc721_addresses_one?: Resolver<
    Maybe<ResolversTypes['erc721_addresses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Erc721_Addresses_OneArgs, 'object'>
  >;
  insert_evenbet_ticket_requests?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Evenbet_Ticket_RequestsArgs, 'objects'>
  >;
  insert_evenbet_ticket_requests_one?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Evenbet_Ticket_Requests_OneArgs, 'object'>
  >;
  insert_evenbet_tickets?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Evenbet_TicketsArgs, 'objects'>
  >;
  insert_evenbet_tickets_one?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Evenbet_Tickets_OneArgs, 'object'>
  >;
  insert_gpanel_users_agent?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Gpanel_Users_AgentArgs, 'objects'>
  >;
  insert_gpanel_users_agent_one?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Gpanel_Users_Agent_OneArgs, 'object'>
  >;
  insert_gpanel_users_deposit_address?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootInsert_Gpanel_Users_Deposit_AddressArgs,
      'objects'
    >
  >;
  insert_gpanel_users_deposit_address_one?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootInsert_Gpanel_Users_Deposit_Address_OneArgs,
      'object'
    >
  >;
  insert_gpanel_users_kyc?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Gpanel_Users_KycArgs, 'objects'>
  >;
  insert_gpanel_users_kyc_one?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Gpanel_Users_Kyc_OneArgs, 'object'>
  >;
  insert_kycs?: Resolver<
    Maybe<ResolversTypes['kycs_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_KycsArgs, 'objects'>
  >;
  insert_kycs_one?: Resolver<
    Maybe<ResolversTypes['kycs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Kycs_OneArgs, 'object'>
  >;
  insert_leaderboard?: Resolver<
    Maybe<ResolversTypes['leaderboard_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_LeaderboardArgs, 'objects'>
  >;
  insert_leaderboard_one?: Resolver<
    Maybe<ResolversTypes['leaderboard']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Leaderboard_OneArgs, 'object'>
  >;
  insert_leaderboard_ranking?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Leaderboard_RankingArgs, 'objects'>
  >;
  insert_leaderboard_ranking_one?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Leaderboard_Ranking_OneArgs, 'object'>
  >;
  insert_liquidity_pools?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Liquidity_PoolsArgs, 'objects'>
  >;
  insert_liquidity_pools_one?: Resolver<
    Maybe<ResolversTypes['liquidity_pools']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Liquidity_Pools_OneArgs, 'object'>
  >;
  insert_nft_gpanel_users?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Nft_Gpanel_UsersArgs, 'objects'>
  >;
  insert_nft_gpanel_users_one?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Nft_Gpanel_Users_OneArgs, 'object'>
  >;
  insert_nft_listings?: Resolver<
    Maybe<ResolversTypes['nft_listings_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Nft_ListingsArgs, 'objects'>
  >;
  insert_nft_listings_one?: Resolver<
    Maybe<ResolversTypes['nft_listings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Nft_Listings_OneArgs, 'object'>
  >;
  insert_nft_transactions?: Resolver<
    Maybe<ResolversTypes['nft_transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Nft_TransactionsArgs, 'objects'>
  >;
  insert_nft_transactions_one?: Resolver<
    Maybe<ResolversTypes['nft_transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Nft_Transactions_OneArgs, 'object'>
  >;
  insert_pending_tx?: Resolver<
    Maybe<ResolversTypes['pending_tx_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Pending_TxArgs, 'objects'>
  >;
  insert_pending_tx_one?: Resolver<
    Maybe<ResolversTypes['pending_tx']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Pending_Tx_OneArgs, 'object'>
  >;
  insert_rakebackLocks?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_RakebackLocksArgs, 'objects'>
  >;
  insert_rakebackLocks_one?: Resolver<
    Maybe<ResolversTypes['rakebackLocks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_RakebackLocks_OneArgs, 'object'>
  >;
  insert_staking_refill?: Resolver<
    Maybe<ResolversTypes['staking_refill_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Staking_RefillArgs, 'objects'>
  >;
  insert_staking_refill_one?: Resolver<
    Maybe<ResolversTypes['staking_refill']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Staking_Refill_OneArgs, 'object'>
  >;
  insert_tournaments?: Resolver<
    Maybe<ResolversTypes['tournaments_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_TournamentsArgs, 'objects'>
  >;
  insert_tournaments_one?: Resolver<
    Maybe<ResolversTypes['tournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Tournaments_OneArgs, 'object'>
  >;
  insert_transactions?: Resolver<
    Maybe<ResolversTypes['transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_TransactionsArgs, 'objects'>
  >;
  insert_transactions_one?: Resolver<
    Maybe<ResolversTypes['transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Transactions_OneArgs, 'object'>
  >;
  insert_user_erc721_block?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_Erc721_BlockArgs, 'objects'>
  >;
  insert_user_erc721_block_one?: Resolver<
    Maybe<ResolversTypes['user_erc721_block']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_Erc721_Block_OneArgs, 'object'>
  >;
  insert_user_gpanel?: Resolver<
    Maybe<ResolversTypes['user_gpanel_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_GpanelArgs, 'objects'>
  >;
  insert_user_gpanel_one?: Resolver<
    Maybe<ResolversTypes['user_gpanel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_Gpanel_OneArgs, 'object'>
  >;
  insert_user_lock?: Resolver<
    Maybe<ResolversTypes['user_lock_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_LockArgs, 'objects'>
  >;
  insert_user_lock_one?: Resolver<
    Maybe<ResolversTypes['user_lock']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_Lock_OneArgs, 'object'>
  >;
  insert_user_staking?: Resolver<
    Maybe<ResolversTypes['user_staking_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_StakingArgs, 'objects'>
  >;
  insert_user_staking_one?: Resolver<
    Maybe<ResolversTypes['user_staking']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_Staking_OneArgs, 'object'>
  >;
  insert_user_staking_transactions?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_User_Staking_TransactionsArgs, 'objects'>
  >;
  insert_user_staking_transactions_one?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootInsert_User_Staking_Transactions_OneArgs,
      'object'
    >
  >;
  insert_users_old?: Resolver<
    Maybe<ResolversTypes['users_old_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Users_OldArgs, 'objects'>
  >;
  insert_users_old_one?: Resolver<
    Maybe<ResolversTypes['users_old']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsert_Users_Old_OneArgs, 'object'>
  >;
  paymentSubmitDeposit?: Resolver<
    Maybe<ResolversTypes['DepositOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootPaymentSubmitDepositArgs,
      'address' | 'amount' | 'gpanelUserId'
    >
  >;
  paymentSubmitKYC?: Resolver<
    Maybe<ResolversTypes['KYCSubmitOutput']>,
    ParentType,
    ContextType
  >;
  paymentSubmitWithdraw?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootPaymentSubmitWithdrawArgs,
      'address' | 'amount' | 'gpanelAccessToken' | 'gpanelUserId'
    >
  >;
  paymentSubmitWithdraw2FA1?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootPaymentSubmitWithdraw2Fa1Args,
      'address' | 'amount' | 'gpanelAccessToken' | 'gpanelUserId'
    >
  >;
  paymentSubmitWithdraw2FA2?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootPaymentSubmitWithdraw2Fa2Args, 'code' | 'id'>
  >;
  paymentSubmitWithdraw2FAresend?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootPaymentSubmitWithdraw2FAresendArgs, 'id'>
  >;
  paymentSubmittedDeposit?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootPaymentSubmittedDepositArgs, 'id' | 'tx'>
  >;
  refreshGpanel?: Resolver<
    Maybe<ResolversTypes['gPanelOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootRefreshGpanelArgs, 'refreshToken'>
  >;
  setGpanelUUID?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSetGpanelUuidArgs, 'uuid'>
  >;
  setNFTusername?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSetNfTusernameArgs, 'nftId'>
  >;
  swapTokens?: Resolver<
    Maybe<ResolversTypes['SwapOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootSwapTokensArgs,
      | 'fromAmount'
      | 'fromCurrency'
      | 'gpanelAccessToken'
      | 'gpanelUserId'
      | 'toCurrency'
      | 'toMinimumAmount'
    >
  >;
  tournamentCreate?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootTournamentCreateArgs, 'tournament'>
  >;
  updateAvailableAvatarNFTs?: Resolver<
    ResolversTypes['uuid'],
    ParentType,
    ContextType
  >;
  updateAvatar?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateAvatarArgs, 'avatarId'>
  >;
  updateBlockchain?: Resolver<
    Maybe<ResolversTypes['blockchain']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateBlockchainArgs, 'pk_columns'>
  >;
  updateBlockchains?: Resolver<
    Maybe<ResolversTypes['blockchain_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateBlockchainsArgs, 'where'>
  >;
  updateGpanelPayment?: Resolver<
    Maybe<ResolversTypes['gpanelPayments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateGpanelPaymentArgs, 'pk_columns'>
  >;
  updateGpanelPayments?: Resolver<
    Maybe<ResolversTypes['gpanelPayments_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateGpanelPaymentsArgs, 'where'>
  >;
  updateGpanelUser?: Resolver<
    Maybe<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateGpanelUserArgs, 'pk_columns'>
  >;
  updateGpanelUsers?: Resolver<
    Maybe<ResolversTypes['gpanelUsers_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateGpanelUsersArgs, 'where'>
  >;
  updateManySwapTransactions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['swapTransactions_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateManySwapTransactionsArgs, 'updates'>
  >;
  updateMarketListing?: Resolver<
    Maybe<ResolversTypes['marketListings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMarketListingArgs, 'pk_columns'>
  >;
  updateMarketListings?: Resolver<
    Maybe<ResolversTypes['marketListings_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMarketListingsArgs, 'where'>
  >;
  updateMarketplaceContract?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMarketplaceContractArgs, 'pk_columns'>
  >;
  updateMarketplaceContracts?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMarketplaceContractsArgs, 'where'>
  >;
  updateNFTListings?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType,
    Partial<Mutation_RootUpdateNftListingsArgs>
  >;
  updateNft?: Resolver<
    Maybe<ResolversTypes['nfts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNftArgs, 'pk_columns'>
  >;
  updateNftContract?: Resolver<
    Maybe<ResolversTypes['nftContracts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNftContractArgs, 'pk_columns'>
  >;
  updateNftContracts?: Resolver<
    Maybe<ResolversTypes['nftContracts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNftContractsArgs, 'where'>
  >;
  updateNftMetadata?: Resolver<
    Maybe<ResolversTypes['nftMetadatas']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNftMetadataArgs, 'pk_columns'>
  >;
  updateNftMetadatas?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNftMetadatasArgs, 'where'>
  >;
  updateNftOwner?: Resolver<
    Maybe<ResolversTypes['nftOwners']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNftOwnerArgs, 'pk_columns'>
  >;
  updateNftOwners?: Resolver<
    Maybe<ResolversTypes['nftOwners_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNftOwnersArgs, 'where'>
  >;
  updateNfts?: Resolver<
    Maybe<ResolversTypes['nfts_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNftsArgs, 'where'>
  >;
  updatePendingTransaction?: Resolver<
    Maybe<ResolversTypes['pendingTransactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePendingTransactionArgs, 'pk_columns'>
  >;
  updatePendingTransactions?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePendingTransactionsArgs, 'where'>
  >;
  updateStablecoin?: Resolver<
    Maybe<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStablecoinArgs, 'pk_columns'>
  >;
  updateStablecoins?: Resolver<
    Maybe<ResolversTypes['stablecoins_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStablecoinsArgs, 'where'>
  >;
  updateSwapTransactions?: Resolver<
    Maybe<ResolversTypes['swapTransactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSwapTransactionsArgs, 'where'>
  >;
  updateTransactionStatistic?: Resolver<
    Maybe<ResolversTypes['transactionStatistics']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTransactionStatisticArgs, 'pk_columns'>
  >;
  updateTransactionStatistics?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTransactionStatisticsArgs, 'where'>
  >;
  updateUser?: Resolver<
    Maybe<ResolversTypes['users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserArgs, 'pk_columns'>
  >;
  updateUserErc721?: Resolver<
    Maybe<ResolversTypes['userErc721']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserErc721Args, 'pk_columns'>
  >;
  updateUserErc721s?: Resolver<
    Maybe<ResolversTypes['userErc721_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserErc721sArgs, 'where'>
  >;
  updateUserJob?: Resolver<
    Maybe<ResolversTypes['userJobs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserJobArgs, 'pk_columns'>
  >;
  updateUserJobs?: Resolver<
    Maybe<ResolversTypes['userJobs_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserJobsArgs, 'where'>
  >;
  updateUsers?: Resolver<
    Maybe<ResolversTypes['users_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersArgs, 'where'>
  >;
  update_Test?: Resolver<
    Maybe<ResolversTypes['Test_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_TestArgs, 'where'>
  >;
  update_Test_by_pk?: Resolver<
    Maybe<ResolversTypes['Test']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Test_By_PkArgs, 'pk_columns'>
  >;
  update_Test_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Test_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Test_ManyArgs, 'updates'>
  >;
  update_anno_gpanel?: Resolver<
    Maybe<ResolversTypes['anno_gpanel_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Anno_GpanelArgs, 'where'>
  >;
  update_anno_gpanel_by_pk?: Resolver<
    Maybe<ResolversTypes['anno_gpanel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Anno_Gpanel_By_PkArgs, 'pk_columns'>
  >;
  update_anno_gpanel_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['anno_gpanel_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Anno_Gpanel_ManyArgs, 'updates'>
  >;
  update_blockchain_main_deposit?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Blockchain_Main_DepositArgs, 'where'>
  >;
  update_blockchain_main_deposit_by_pk?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_Blockchain_Main_Deposit_By_PkArgs,
      'pk_columns'
    >
  >;
  update_blockchain_main_deposit_many?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['blockchain_main_deposit_mutation_response']>>
    >,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_Blockchain_Main_Deposit_ManyArgs,
      'updates'
    >
  >;
  update_blockchain_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['blockchain_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Blockchain_ManyArgs, 'updates'>
  >;
  update_burn_profit?: Resolver<
    Maybe<ResolversTypes['burn_profit_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Burn_ProfitArgs, 'where'>
  >;
  update_burn_profit_by_pk?: Resolver<
    Maybe<ResolversTypes['burn_profit']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Burn_Profit_By_PkArgs, 'pk_columns'>
  >;
  update_burn_profit_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['burn_profit_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Burn_Profit_ManyArgs, 'updates'>
  >;
  update_currencies?: Resolver<
    Maybe<ResolversTypes['currencies_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_CurrenciesArgs, 'where'>
  >;
  update_currencies_by_pk?: Resolver<
    Maybe<ResolversTypes['currencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Currencies_By_PkArgs, 'pk_columns'>
  >;
  update_currencies_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['currencies_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Currencies_ManyArgs, 'updates'>
  >;
  update_currency_history?: Resolver<
    Maybe<ResolversTypes['currency_history_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Currency_HistoryArgs, 'where'>
  >;
  update_currency_history_by_pk?: Resolver<
    Maybe<ResolversTypes['currency_history']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Currency_History_By_PkArgs, 'pk_columns'>
  >;
  update_currency_history_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['currency_history_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Currency_History_ManyArgs, 'updates'>
  >;
  update_erc721_addresses?: Resolver<
    Maybe<ResolversTypes['erc721_addresses_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Erc721_AddressesArgs, 'where'>
  >;
  update_erc721_addresses_by_pk?: Resolver<
    Maybe<ResolversTypes['erc721_addresses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Erc721_Addresses_By_PkArgs, 'pk_columns'>
  >;
  update_erc721_addresses_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['erc721_addresses_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Erc721_Addresses_ManyArgs, 'updates'>
  >;
  update_evenbet_ticket_requests?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Evenbet_Ticket_RequestsArgs, 'where'>
  >;
  update_evenbet_ticket_requests_by_pk?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_Evenbet_Ticket_Requests_By_PkArgs,
      'pk_columns'
    >
  >;
  update_evenbet_ticket_requests_many?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['evenbet_ticket_requests_mutation_response']>>
    >,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_Evenbet_Ticket_Requests_ManyArgs,
      'updates'
    >
  >;
  update_evenbet_tickets?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Evenbet_TicketsArgs, 'where'>
  >;
  update_evenbet_tickets_by_pk?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Evenbet_Tickets_By_PkArgs, 'pk_columns'>
  >;
  update_evenbet_tickets_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['evenbet_tickets_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Evenbet_Tickets_ManyArgs, 'updates'>
  >;
  update_gpanelPayments_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['gpanelPayments_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_GpanelPayments_ManyArgs, 'updates'>
  >;
  update_gpanelUsers_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['gpanelUsers_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_GpanelUsers_ManyArgs, 'updates'>
  >;
  update_gpanel_users_agent?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Gpanel_Users_AgentArgs, 'where'>
  >;
  update_gpanel_users_agent_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_Gpanel_Users_Agent_By_PkArgs,
      'pk_columns'
    >
  >;
  update_gpanel_users_agent_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['gpanel_users_agent_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Gpanel_Users_Agent_ManyArgs, 'updates'>
  >;
  update_gpanel_users_deposit_address?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Gpanel_Users_Deposit_AddressArgs, 'where'>
  >;
  update_gpanel_users_deposit_address_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_Gpanel_Users_Deposit_Address_By_PkArgs,
      'pk_columns'
    >
  >;
  update_gpanel_users_deposit_address_many?: Resolver<
    Maybe<
      Array<
        Maybe<ResolversTypes['gpanel_users_deposit_address_mutation_response']>
      >
    >,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_Gpanel_Users_Deposit_Address_ManyArgs,
      'updates'
    >
  >;
  update_gpanel_users_kyc?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Gpanel_Users_KycArgs, 'where'>
  >;
  update_gpanel_users_kyc_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Gpanel_Users_Kyc_By_PkArgs, 'pk_columns'>
  >;
  update_gpanel_users_kyc_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['gpanel_users_kyc_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Gpanel_Users_Kyc_ManyArgs, 'updates'>
  >;
  update_kycs?: Resolver<
    Maybe<ResolversTypes['kycs_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_KycsArgs, 'where'>
  >;
  update_kycs_by_pk?: Resolver<
    Maybe<ResolversTypes['kycs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Kycs_By_PkArgs, 'pk_columns'>
  >;
  update_kycs_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['kycs_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Kycs_ManyArgs, 'updates'>
  >;
  update_leaderboard?: Resolver<
    Maybe<ResolversTypes['leaderboard_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_LeaderboardArgs, 'where'>
  >;
  update_leaderboard_by_pk?: Resolver<
    Maybe<ResolversTypes['leaderboard']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Leaderboard_By_PkArgs, 'pk_columns'>
  >;
  update_leaderboard_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['leaderboard_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Leaderboard_ManyArgs, 'updates'>
  >;
  update_leaderboard_ranking?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Leaderboard_RankingArgs, 'where'>
  >;
  update_leaderboard_ranking_by_pk?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_Leaderboard_Ranking_By_PkArgs,
      'pk_columns'
    >
  >;
  update_leaderboard_ranking_many?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['leaderboard_ranking_mutation_response']>>
    >,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Leaderboard_Ranking_ManyArgs, 'updates'>
  >;
  update_liquidity_pools?: Resolver<
    Maybe<ResolversTypes['liquidity_pools_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Liquidity_PoolsArgs, 'where'>
  >;
  update_liquidity_pools_by_pk?: Resolver<
    Maybe<ResolversTypes['liquidity_pools']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Liquidity_Pools_By_PkArgs, 'pk_columns'>
  >;
  update_liquidity_pools_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['liquidity_pools_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Liquidity_Pools_ManyArgs, 'updates'>
  >;
  update_marketListings_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['marketListings_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_MarketListings_ManyArgs, 'updates'>
  >;
  update_marketplaceContracts_many?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['marketplaceContracts_mutation_response']>>
    >,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_MarketplaceContracts_ManyArgs, 'updates'>
  >;
  update_nftContracts_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['nftContracts_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_NftContracts_ManyArgs, 'updates'>
  >;
  update_nftMetadatas_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['nftMetadatas_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_NftMetadatas_ManyArgs, 'updates'>
  >;
  update_nftOwners_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['nftOwners_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_NftOwners_ManyArgs, 'updates'>
  >;
  update_nft_gpanel_users?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_Gpanel_UsersArgs, 'where'>
  >;
  update_nft_gpanel_users_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_Gpanel_Users_By_PkArgs, 'pk_columns'>
  >;
  update_nft_gpanel_users_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['nft_gpanel_users_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_Gpanel_Users_ManyArgs, 'updates'>
  >;
  update_nft_listings?: Resolver<
    Maybe<ResolversTypes['nft_listings_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_ListingsArgs, 'where'>
  >;
  update_nft_listings_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_listings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_Listings_By_PkArgs, 'pk_columns'>
  >;
  update_nft_listings_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['nft_listings_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_Listings_ManyArgs, 'updates'>
  >;
  update_nft_transactions?: Resolver<
    Maybe<ResolversTypes['nft_transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_TransactionsArgs, 'where'>
  >;
  update_nft_transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_Transactions_By_PkArgs, 'pk_columns'>
  >;
  update_nft_transactions_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['nft_transactions_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nft_Transactions_ManyArgs, 'updates'>
  >;
  update_nfts_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['nfts_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Nfts_ManyArgs, 'updates'>
  >;
  update_pendingTransactions_many?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['pendingTransactions_mutation_response']>>
    >,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_PendingTransactions_ManyArgs, 'updates'>
  >;
  update_pending_tx?: Resolver<
    Maybe<ResolversTypes['pending_tx_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Pending_TxArgs, 'where'>
  >;
  update_pending_tx_by_pk?: Resolver<
    Maybe<ResolversTypes['pending_tx']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Pending_Tx_By_PkArgs, 'pk_columns'>
  >;
  update_pending_tx_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['pending_tx_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Pending_Tx_ManyArgs, 'updates'>
  >;
  update_rakebackLocks?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_RakebackLocksArgs, 'where'>
  >;
  update_rakebackLocks_by_pk?: Resolver<
    Maybe<ResolversTypes['rakebackLocks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_RakebackLocks_By_PkArgs, 'pk_columns'>
  >;
  update_rakebackLocks_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['rakebackLocks_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_RakebackLocks_ManyArgs, 'updates'>
  >;
  update_stablecoins_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['stablecoins_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Stablecoins_ManyArgs, 'updates'>
  >;
  update_staking_refill?: Resolver<
    Maybe<ResolversTypes['staking_refill_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Staking_RefillArgs, 'where'>
  >;
  update_staking_refill_by_pk?: Resolver<
    Maybe<ResolversTypes['staking_refill']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Staking_Refill_By_PkArgs, 'pk_columns'>
  >;
  update_staking_refill_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['staking_refill_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Staking_Refill_ManyArgs, 'updates'>
  >;
  update_tournaments?: Resolver<
    Maybe<ResolversTypes['tournaments_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_TournamentsArgs, 'where'>
  >;
  update_tournaments_by_pk?: Resolver<
    Maybe<ResolversTypes['tournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Tournaments_By_PkArgs, 'pk_columns'>
  >;
  update_tournaments_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['tournaments_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Tournaments_ManyArgs, 'updates'>
  >;
  update_transactionStatistics_many?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['transactionStatistics_mutation_response']>>
    >,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_TransactionStatistics_ManyArgs, 'updates'>
  >;
  update_transactions?: Resolver<
    Maybe<ResolversTypes['transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_TransactionsArgs, 'where'>
  >;
  update_transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Transactions_By_PkArgs, 'pk_columns'>
  >;
  update_transactions_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['transactions_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Transactions_ManyArgs, 'updates'>
  >;
  update_userErc721_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['userErc721_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_UserErc721_ManyArgs, 'updates'>
  >;
  update_userJobs_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['userJobs_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_UserJobs_ManyArgs, 'updates'>
  >;
  update_user_erc721_block?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Erc721_BlockArgs, 'where'>
  >;
  update_user_erc721_block_by_pk?: Resolver<
    Maybe<ResolversTypes['user_erc721_block']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Erc721_Block_By_PkArgs, 'pk_columns'>
  >;
  update_user_erc721_block_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['user_erc721_block_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Erc721_Block_ManyArgs, 'updates'>
  >;
  update_user_gpanel?: Resolver<
    Maybe<ResolversTypes['user_gpanel_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_GpanelArgs, 'where'>
  >;
  update_user_gpanel_by_pk?: Resolver<
    Maybe<ResolversTypes['user_gpanel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Gpanel_By_PkArgs, 'pk_columns'>
  >;
  update_user_gpanel_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['user_gpanel_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Gpanel_ManyArgs, 'updates'>
  >;
  update_user_lock?: Resolver<
    Maybe<ResolversTypes['user_lock_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_LockArgs, 'where'>
  >;
  update_user_lock_by_pk?: Resolver<
    Maybe<ResolversTypes['user_lock']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Lock_By_PkArgs, 'pk_columns'>
  >;
  update_user_lock_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['user_lock_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Lock_ManyArgs, 'updates'>
  >;
  update_user_staking?: Resolver<
    Maybe<ResolversTypes['user_staking_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_StakingArgs, 'where'>
  >;
  update_user_staking_by_pk?: Resolver<
    Maybe<ResolversTypes['user_staking']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Staking_By_PkArgs, 'pk_columns'>
  >;
  update_user_staking_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['user_staking_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Staking_ManyArgs, 'updates'>
  >;
  update_user_staking_transactions?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_User_Staking_TransactionsArgs, 'where'>
  >;
  update_user_staking_transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_User_Staking_Transactions_By_PkArgs,
      'pk_columns'
    >
  >;
  update_user_staking_transactions_many?: Resolver<
    Maybe<
      Array<
        Maybe<ResolversTypes['user_staking_transactions_mutation_response']>
      >
    >,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdate_User_Staking_Transactions_ManyArgs,
      'updates'
    >
  >;
  update_users_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['users_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Users_ManyArgs, 'updates'>
  >;
  update_users_old?: Resolver<
    Maybe<ResolversTypes['users_old_mutation_response']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Users_OldArgs, 'where'>
  >;
  update_users_old_by_pk?: Resolver<
    Maybe<ResolversTypes['users_old']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Users_Old_By_PkArgs, 'pk_columns'>
  >;
  update_users_old_many?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['users_old_mutation_response']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdate_Users_Old_ManyArgs, 'updates'>
  >;
};

export type NftContractsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts'] = ResolversParentTypes['nftContracts']
> = {
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  chain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  collectionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_aggregate'] = ResolversParentTypes['nftContracts_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['nftContracts_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['nftContracts']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_aggregate_fields'] = ResolversParentTypes['nftContracts_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['nftContracts_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<NftContracts_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['nftContracts_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['nftContracts_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['nftContracts_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['nftContracts_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['nftContracts_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['nftContracts_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['nftContracts_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['nftContracts_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['nftContracts_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_avg_fields'] = ResolversParentTypes['nftContracts_avg_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_max_fields'] = ResolversParentTypes['nftContracts_max_fields']
> = {
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  chain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collectionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contractAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_min_fields'] = ResolversParentTypes['nftContracts_min_fields']
> = {
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  chain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collectionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contractAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_mutation_response'] = ResolversParentTypes['nftContracts_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['nftContracts']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_stddev_fields'] = ResolversParentTypes['nftContracts_stddev_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_stddev_pop_fields'] = ResolversParentTypes['nftContracts_stddev_pop_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_stddev_samp_fields'] = ResolversParentTypes['nftContracts_stddev_samp_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_sum_fields'] = ResolversParentTypes['nftContracts_sum_fields']
> = {
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_var_pop_fields'] = ResolversParentTypes['nftContracts_var_pop_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_var_samp_fields'] = ResolversParentTypes['nftContracts_var_samp_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftContracts_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftContracts_variance_fields'] = ResolversParentTypes['nftContracts_variance_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatasResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas'] = ResolversParentTypes['nftMetadatas']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_aggregate'] = ResolversParentTypes['nftMetadatas_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['nftMetadatas']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_aggregate_fields'] = ResolversParentTypes['nftMetadatas_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<NftMetadatas_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['nftMetadatas_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_avg_fields'] = ResolversParentTypes['nftMetadatas_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_max_fields'] = ResolversParentTypes['nftMetadatas_max_fields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_min_fields'] = ResolversParentTypes['nftMetadatas_min_fields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_mutation_response'] = ResolversParentTypes['nftMetadatas_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['nftMetadatas']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_stddev_fields'] = ResolversParentTypes['nftMetadatas_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_stddev_pop_fields'] = ResolversParentTypes['nftMetadatas_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_stddev_samp_fields'] = ResolversParentTypes['nftMetadatas_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_sum_fields'] = ResolversParentTypes['nftMetadatas_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_var_pop_fields'] = ResolversParentTypes['nftMetadatas_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_var_samp_fields'] = ResolversParentTypes['nftMetadatas_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftMetadatas_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftMetadatas_variance_fields'] = ResolversParentTypes['nftMetadatas_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwnersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners'] = ResolversParentTypes['nftOwners']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  borrowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  delegated?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ownerAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pokerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenid?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_aggregate'] = ResolversParentTypes['nftOwners_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['nftOwners_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['nftOwners']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_aggregate_fields'] = ResolversParentTypes['nftOwners_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['nftOwners_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<NftOwners_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['nftOwners_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['nftOwners_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['nftOwners_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['nftOwners_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['nftOwners_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['nftOwners_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['nftOwners_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['nftOwners_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['nftOwners_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_avg_fields'] = ResolversParentTypes['nftOwners_avg_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_max_fields'] = ResolversParentTypes['nftOwners_max_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ownerAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pokerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_min_fields'] = ResolversParentTypes['nftOwners_min_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image_url?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ownerAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pokerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_mutation_response'] = ResolversParentTypes['nftOwners_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['nftOwners']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_stddev_fields'] = ResolversParentTypes['nftOwners_stddev_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_stddev_pop_fields'] = ResolversParentTypes['nftOwners_stddev_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_stddev_samp_fields'] = ResolversParentTypes['nftOwners_stddev_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_sum_fields'] = ResolversParentTypes['nftOwners_sum_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_var_pop_fields'] = ResolversParentTypes['nftOwners_var_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_var_samp_fields'] = ResolversParentTypes['nftOwners_var_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftOwners_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nftOwners_variance_fields'] = ResolversParentTypes['nftOwners_variance_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  generation?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_UsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users'] = ResolversParentTypes['nft_gpanel_users']
> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  delegate_accepted?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  delegate_stopped?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user?: Resolver<
    Maybe<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gpanel_username?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nft_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nft_owner?: Resolver<
    Maybe<ResolversTypes['nftOwners']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_aggregate'] = ResolversParentTypes['nft_gpanel_users_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['nft_gpanel_users']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_aggregate_fields'] = ResolversParentTypes['nft_gpanel_users_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Nft_Gpanel_Users_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_avg_fields'] = ResolversParentTypes['nft_gpanel_users_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_max_fields'] = ResolversParentTypes['nft_gpanel_users_max_fields']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  delegate_accepted?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  delegate_stopped?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpanel_username?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_min_fields'] = ResolversParentTypes['nft_gpanel_users_min_fields']
> = {
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  delegate_accepted?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  delegate_stopped?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpanel_username?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_mutation_response'] = ResolversParentTypes['nft_gpanel_users_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['nft_gpanel_users']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_stddev_fields'] = ResolversParentTypes['nft_gpanel_users_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_stddev_pop_fields'] = ResolversParentTypes['nft_gpanel_users_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_stddev_samp_fields'] = ResolversParentTypes['nft_gpanel_users_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_sum_fields'] = ResolversParentTypes['nft_gpanel_users_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_var_pop_fields'] = ResolversParentTypes['nft_gpanel_users_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_var_samp_fields'] = ResolversParentTypes['nft_gpanel_users_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Gpanel_Users_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_gpanel_users_variance_fields'] = ResolversParentTypes['nft_gpanel_users_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_ListingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings'] = ResolversParentTypes['nft_listings']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  buyer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  erc20Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isErc721?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastBidder?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  nft_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nftaddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originalListingId?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  seller?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stakeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_aggregate'] = ResolversParentTypes['nft_listings_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['nft_listings_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['nft_listings']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_aggregate_fields'] = ResolversParentTypes['nft_listings_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['nft_listings_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Nft_Listings_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['nft_listings_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['nft_listings_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['nft_listings_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['nft_listings_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['nft_listings_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['nft_listings_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['nft_listings_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['nft_listings_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['nft_listings_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_avg_fields'] = ResolversParentTypes['nft_listings_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_max_fields'] = ResolversParentTypes['nft_listings_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  buyer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastBidder?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nftaddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  seller?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stakeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_min_fields'] = ResolversParentTypes['nft_listings_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  buyer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastBidder?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nftaddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  seller?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stakeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_mutation_response'] = ResolversParentTypes['nft_listings_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['nft_listings']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_stddev_fields'] = ResolversParentTypes['nft_listings_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_stddev_pop_fields'] = ResolversParentTypes['nft_listings_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_stddev_samp_fields'] = ResolversParentTypes['nft_listings_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_sum_fields'] = ResolversParentTypes['nft_listings_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  stakeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_var_pop_fields'] = ResolversParentTypes['nft_listings_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_var_samp_fields'] = ResolversParentTypes['nft_listings_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Listings_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_listings_variance_fields'] = ResolversParentTypes['nft_listings_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  endedAt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nft_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakeCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_TransactionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions'] = ResolversParentTypes['nft_transactions']
> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  swap_log?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeStamp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenid?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_aggregate'] = ResolversParentTypes['nft_transactions_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['nft_transactions_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['nft_transactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_aggregate_fields'] = ResolversParentTypes['nft_transactions_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['nft_transactions_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Nft_Transactions_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['nft_transactions_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['nft_transactions_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['nft_transactions_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['nft_transactions_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['nft_transactions_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['nft_transactions_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['nft_transactions_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['nft_transactions_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['nft_transactions_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_avg_fields'] = ResolversParentTypes['nft_transactions_avg_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_max_fields'] = ResolversParentTypes['nft_transactions_max_fields']
> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  swap_log?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_min_fields'] = ResolversParentTypes['nft_transactions_min_fields']
> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  swap_log?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_mutation_response'] = ResolversParentTypes['nft_transactions_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['nft_transactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_stddev_fields'] = ResolversParentTypes['nft_transactions_stddev_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_stddev_pop_fields'] = ResolversParentTypes['nft_transactions_stddev_pop_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_stddev_samp_fields'] = ResolversParentTypes['nft_transactions_stddev_samp_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_sum_fields'] = ResolversParentTypes['nft_transactions_sum_fields']
> = {
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_var_pop_fields'] = ResolversParentTypes['nft_transactions_var_pop_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_var_samp_fields'] = ResolversParentTypes['nft_transactions_var_samp_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nft_Transactions_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nft_transactions_variance_fields'] = ResolversParentTypes['nft_transactions_variance_fields']
> = {
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NftsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts'] = ResolversParentTypes['nfts']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastTransferredAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  listing?: Resolver<
    Maybe<ResolversTypes['marketListings']>,
    ParentType,
    ContextType
  >;
  metadataId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nftContract?: Resolver<
    ResolversTypes['nftContracts'],
    ParentType,
    ContextType
  >;
  nftMetadatum?: Resolver<
    ResolversTypes['nftMetadatas'],
    ParentType,
    ContextType
  >;
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_aggregate'] = ResolversParentTypes['nfts_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['nfts_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['nfts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_aggregate_fields'] = ResolversParentTypes['nfts_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['nfts_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Nfts_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['nfts_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['nfts_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['nfts_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['nfts_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['nfts_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['nfts_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['nfts_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['nfts_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['nfts_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_avg_fields'] = ResolversParentTypes['nfts_avg_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metadataId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_max_fields'] = ResolversParentTypes['nfts_max_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastTransferredAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  metadataId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_min_fields'] = ResolversParentTypes['nfts_min_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastTransferredAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  metadataId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_mutation_response'] = ResolversParentTypes['nfts_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['nfts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_stddev_fields'] = ResolversParentTypes['nfts_stddev_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metadataId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_stddev_pop_fields'] = ResolversParentTypes['nfts_stddev_pop_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metadataId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_stddev_samp_fields'] = ResolversParentTypes['nfts_stddev_samp_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metadataId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_sum_fields'] = ResolversParentTypes['nfts_sum_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  metadataId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_var_pop_fields'] = ResolversParentTypes['nfts_var_pop_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metadataId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_var_samp_fields'] = ResolversParentTypes['nfts_var_samp_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metadataId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Nfts_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['nfts_variance_fields'] = ResolversParentTypes['nfts_variance_fields']
> = {
  activeListingId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  activeUsernameId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metadataId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface NumericScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['numeric'], any> {
  name: 'numeric';
}

export type PendingTransactionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions'] = ResolversParentTypes['pendingTransactions']
> = {
  agent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  blockchain?: Resolver<ResolversTypes['blockchain'], ParentType, ContextType>;
  blockchain_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  stablecoin?: Resolver<
    Maybe<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType
  >;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusInternal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twofa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  withdraw_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_aggregate'] = ResolversParentTypes['pendingTransactions_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['pendingTransactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_aggregate_fields'] = ResolversParentTypes['pendingTransactions_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PendingTransactions_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['pendingTransactions_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_avg_fields'] = ResolversParentTypes['pendingTransactions_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_max_fields'] = ResolversParentTypes['pendingTransactions_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  depositAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusInternal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  twofa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  withdraw_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_min_fields'] = ResolversParentTypes['pendingTransactions_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  depositAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusInternal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  twofa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  withdraw_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_mutation_response'] = ResolversParentTypes['pendingTransactions_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['pendingTransactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_stddev_fields'] = ResolversParentTypes['pendingTransactions_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_stddev_pop_fields'] = ResolversParentTypes['pendingTransactions_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_stddev_samp_fields'] = ResolversParentTypes['pendingTransactions_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_sum_fields'] = ResolversParentTypes['pendingTransactions_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_var_pop_fields'] = ResolversParentTypes['pendingTransactions_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_var_samp_fields'] = ResolversParentTypes['pendingTransactions_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTransactions_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pendingTransactions_variance_fields'] = ResolversParentTypes['pendingTransactions_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stablecoin_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_TxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx'] = ResolversParentTypes['pending_tx']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  function?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  timeStamp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenSymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  wallet?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_aggregate'] = ResolversParentTypes['pending_tx_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['pending_tx_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['pending_tx']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_aggregate_fields'] = ResolversParentTypes['pending_tx_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['pending_tx_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Pending_Tx_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['pending_tx_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['pending_tx_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['pending_tx_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['pending_tx_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['pending_tx_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['pending_tx_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['pending_tx_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['pending_tx_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['pending_tx_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_avg_fields'] = ResolversParentTypes['pending_tx_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_max_fields'] = ResolversParentTypes['pending_tx_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contractAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenSymbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  wallet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_min_fields'] = ResolversParentTypes['pending_tx_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contractAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenSymbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  wallet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_mutation_response'] = ResolversParentTypes['pending_tx_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['pending_tx']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_stddev_fields'] = ResolversParentTypes['pending_tx_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_stddev_pop_fields'] = ResolversParentTypes['pending_tx_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_stddev_samp_fields'] = ResolversParentTypes['pending_tx_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_sum_fields'] = ResolversParentTypes['pending_tx_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_var_pop_fields'] = ResolversParentTypes['pending_tx_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_var_samp_fields'] = ResolversParentTypes['pending_tx_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Pending_Tx_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['pending_tx_variance_fields'] = ResolversParentTypes['pending_tx_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Query_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']
> = {
  NFTAvailableTickets?: Resolver<
    Maybe<ResolversTypes['NFTTicketsClaimOutput']>,
    ParentType,
    ContextType
  >;
  NFTFindUser?: Resolver<
    Maybe<ResolversTypes['agentFindUserOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNftFindUserArgs, 'name'>
  >;
  NFTUserConnected?: Resolver<
    Maybe<ResolversTypes['NFTConnectedOutput']>,
    ParentType,
    ContextType
  >;
  Test?: Resolver<
    Array<ResolversTypes['Test']>,
    ParentType,
    ContextType,
    Partial<Query_RootTestArgs>
  >;
  Test_aggregate?: Resolver<
    ResolversTypes['Test_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTest_AggregateArgs>
  >;
  Test_by_pk?: Resolver<
    Maybe<ResolversTypes['Test']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTest_By_PkArgs, 'id'>
  >;
  agentFindUser?: Resolver<
    Maybe<ResolversTypes['agentFindUserOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootAgentFindUserArgs, 'name'>
  >;
  agentGetUsers?: Resolver<
    Maybe<ResolversTypes['agentGetUsersOutput']>,
    ParentType,
    ContextType
  >;
  anno_gpanel?: Resolver<
    Array<ResolversTypes['anno_gpanel']>,
    ParentType,
    ContextType,
    Partial<Query_RootAnno_GpanelArgs>
  >;
  anno_gpanel_aggregate?: Resolver<
    ResolversTypes['anno_gpanel_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootAnno_Gpanel_AggregateArgs>
  >;
  anno_gpanel_by_pk?: Resolver<
    Maybe<ResolversTypes['anno_gpanel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootAnno_Gpanel_By_PkArgs, 'id'>
  >;
  authLogin?: Resolver<
    Maybe<ResolversTypes['authLoginOutput']>,
    ParentType,
    ContextType,
    Partial<Query_RootAuthLoginArgs>
  >;
  authRefresh?: Resolver<
    Maybe<ResolversTypes['authLoginOutput']>,
    ParentType,
    ContextType,
    Partial<Query_RootAuthRefreshArgs>
  >;
  authSignature?: Resolver<
    Maybe<ResolversTypes['authSignatureOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootAuthSignatureArgs, 'address'>
  >;
  blockchain?: Resolver<
    Maybe<ResolversTypes['blockchain']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootBlockchainArgs, 'id'>
  >;
  blockchain_main_deposit?: Resolver<
    Array<ResolversTypes['blockchain_main_deposit']>,
    ParentType,
    ContextType,
    Partial<Query_RootBlockchain_Main_DepositArgs>
  >;
  blockchain_main_deposit_aggregate?: Resolver<
    ResolversTypes['blockchain_main_deposit_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootBlockchain_Main_Deposit_AggregateArgs>
  >;
  blockchain_main_deposit_by_pk?: Resolver<
    Maybe<ResolversTypes['blockchain_main_deposit']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootBlockchain_Main_Deposit_By_PkArgs, 'id'>
  >;
  blockchains?: Resolver<
    Array<ResolversTypes['blockchain']>,
    ParentType,
    ContextType,
    Partial<Query_RootBlockchainsArgs>
  >;
  blockchainsAggregate?: Resolver<
    ResolversTypes['blockchain_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootBlockchainsAggregateArgs>
  >;
  burn_profit?: Resolver<
    Array<ResolversTypes['burn_profit']>,
    ParentType,
    ContextType,
    Partial<Query_RootBurn_ProfitArgs>
  >;
  burn_profit_aggregate?: Resolver<
    ResolversTypes['burn_profit_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootBurn_Profit_AggregateArgs>
  >;
  burn_profit_by_pk?: Resolver<
    Maybe<ResolversTypes['burn_profit']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootBurn_Profit_By_PkArgs, 'id'>
  >;
  currencies?: Resolver<
    Array<ResolversTypes['currencies']>,
    ParentType,
    ContextType,
    Partial<Query_RootCurrenciesArgs>
  >;
  currencies_aggregate?: Resolver<
    ResolversTypes['currencies_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootCurrencies_AggregateArgs>
  >;
  currencies_by_pk?: Resolver<
    Maybe<ResolversTypes['currencies']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCurrencies_By_PkArgs, 'id'>
  >;
  currency_history?: Resolver<
    Array<ResolversTypes['currency_history']>,
    ParentType,
    ContextType,
    Partial<Query_RootCurrency_HistoryArgs>
  >;
  currency_history_aggregate?: Resolver<
    ResolversTypes['currency_history_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootCurrency_History_AggregateArgs>
  >;
  currency_history_by_pk?: Resolver<
    Maybe<ResolversTypes['currency_history']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCurrency_History_By_PkArgs, 'id'>
  >;
  erc721_addresses?: Resolver<
    Array<ResolversTypes['erc721_addresses']>,
    ParentType,
    ContextType,
    Partial<Query_RootErc721_AddressesArgs>
  >;
  erc721_addresses_aggregate?: Resolver<
    ResolversTypes['erc721_addresses_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootErc721_Addresses_AggregateArgs>
  >;
  erc721_addresses_by_pk?: Resolver<
    Maybe<ResolversTypes['erc721_addresses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootErc721_Addresses_By_PkArgs, 'id'>
  >;
  evenbetAffiliatePlayerBalances?: Resolver<
    Array<ResolversTypes['evenbetAffiliatePlayerBalances']>,
    ParentType,
    ContextType,
    Partial<Query_RootEvenbetAffiliatePlayerBalancesArgs>
  >;
  evenbetAffiliatePlayerBalancesAggregate?: Resolver<
    ResolversTypes['evenbetAffiliatePlayerBalances_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootEvenbetAffiliatePlayerBalancesAggregateArgs>
  >;
  evenbet_ticket_requests?: Resolver<
    Array<ResolversTypes['evenbet_ticket_requests']>,
    ParentType,
    ContextType,
    Partial<Query_RootEvenbet_Ticket_RequestsArgs>
  >;
  evenbet_ticket_requests_aggregate?: Resolver<
    ResolversTypes['evenbet_ticket_requests_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootEvenbet_Ticket_Requests_AggregateArgs>
  >;
  evenbet_ticket_requests_by_pk?: Resolver<
    Maybe<ResolversTypes['evenbet_ticket_requests']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEvenbet_Ticket_Requests_By_PkArgs, 'id'>
  >;
  evenbet_tickets?: Resolver<
    Array<ResolversTypes['evenbet_tickets']>,
    ParentType,
    ContextType,
    Partial<Query_RootEvenbet_TicketsArgs>
  >;
  evenbet_tickets_aggregate?: Resolver<
    ResolversTypes['evenbet_tickets_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootEvenbet_Tickets_AggregateArgs>
  >;
  evenbet_tickets_by_pk?: Resolver<
    Maybe<ResolversTypes['evenbet_tickets']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEvenbet_Tickets_By_PkArgs, 'id'>
  >;
  getGpanelUUID?: Resolver<
    Maybe<ResolversTypes['gPanelOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGetGpanelUuidArgs, 'uuid'>
  >;
  gpanelPayment?: Resolver<
    Maybe<ResolversTypes['gpanelPayments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGpanelPaymentArgs, 'id'>
  >;
  gpanelPayments?: Resolver<
    Array<ResolversTypes['gpanelPayments']>,
    ParentType,
    ContextType,
    Partial<Query_RootGpanelPaymentsArgs>
  >;
  gpanelPaymentsAggregate?: Resolver<
    ResolversTypes['gpanelPayments_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootGpanelPaymentsAggregateArgs>
  >;
  gpanelUser?: Resolver<
    Maybe<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGpanelUserArgs, 'id'>
  >;
  gpanelUsers?: Resolver<
    Array<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType,
    Partial<Query_RootGpanelUsersArgs>
  >;
  gpanelUsersAggregate?: Resolver<
    ResolversTypes['gpanelUsers_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootGpanelUsersAggregateArgs>
  >;
  gpanel_users_agent?: Resolver<
    Array<ResolversTypes['gpanel_users_agent']>,
    ParentType,
    ContextType,
    Partial<Query_RootGpanel_Users_AgentArgs>
  >;
  gpanel_users_agent_aggregate?: Resolver<
    ResolversTypes['gpanel_users_agent_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootGpanel_Users_Agent_AggregateArgs>
  >;
  gpanel_users_agent_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_agent']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGpanel_Users_Agent_By_PkArgs, 'id'>
  >;
  gpanel_users_deposit_address?: Resolver<
    Array<ResolversTypes['gpanel_users_deposit_address']>,
    ParentType,
    ContextType,
    Partial<Query_RootGpanel_Users_Deposit_AddressArgs>
  >;
  gpanel_users_deposit_address_aggregate?: Resolver<
    ResolversTypes['gpanel_users_deposit_address_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootGpanel_Users_Deposit_Address_AggregateArgs>
  >;
  gpanel_users_deposit_address_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGpanel_Users_Deposit_Address_By_PkArgs, 'id'>
  >;
  gpanel_users_kyc?: Resolver<
    Array<ResolversTypes['gpanel_users_kyc']>,
    ParentType,
    ContextType,
    Partial<Query_RootGpanel_Users_KycArgs>
  >;
  gpanel_users_kyc_aggregate?: Resolver<
    ResolversTypes['gpanel_users_kyc_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootGpanel_Users_Kyc_AggregateArgs>
  >;
  gpanel_users_kyc_by_pk?: Resolver<
    Maybe<ResolversTypes['gpanel_users_kyc']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGpanel_Users_Kyc_By_PkArgs, 'id'>
  >;
  kyc?: Resolver<
    Maybe<ResolversTypes['kycs']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootKycArgs, 'id'>
  >;
  kycs?: Resolver<
    Array<ResolversTypes['kycs']>,
    ParentType,
    ContextType,
    Partial<Query_RootKycsArgs>
  >;
  kycs_aggregate?: Resolver<
    ResolversTypes['kycs_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootKycs_AggregateArgs>
  >;
  leaderboard?: Resolver<
    Array<ResolversTypes['leaderboard']>,
    ParentType,
    ContextType,
    Partial<Query_RootLeaderboardArgs>
  >;
  leaderboard_aggregate?: Resolver<
    ResolversTypes['leaderboard_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootLeaderboard_AggregateArgs>
  >;
  leaderboard_by_pk?: Resolver<
    Maybe<ResolversTypes['leaderboard']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLeaderboard_By_PkArgs, 'id'>
  >;
  leaderboard_ranking?: Resolver<
    Array<ResolversTypes['leaderboard_ranking']>,
    ParentType,
    ContextType,
    Partial<Query_RootLeaderboard_RankingArgs>
  >;
  leaderboard_ranking_aggregate?: Resolver<
    ResolversTypes['leaderboard_ranking_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootLeaderboard_Ranking_AggregateArgs>
  >;
  leaderboard_ranking_by_pk?: Resolver<
    Maybe<ResolversTypes['leaderboard_ranking']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLeaderboard_Ranking_By_PkArgs, 'id'>
  >;
  liquidity_pools?: Resolver<
    Array<ResolversTypes['liquidity_pools']>,
    ParentType,
    ContextType,
    Partial<Query_RootLiquidity_PoolsArgs>
  >;
  liquidity_pools_aggregate?: Resolver<
    ResolversTypes['liquidity_pools_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootLiquidity_Pools_AggregateArgs>
  >;
  liquidity_pools_by_pk?: Resolver<
    Maybe<ResolversTypes['liquidity_pools']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLiquidity_Pools_By_PkArgs, 'id'>
  >;
  marketListing?: Resolver<
    Maybe<ResolversTypes['marketListings']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMarketListingArgs, 'id'>
  >;
  marketListings?: Resolver<
    Array<ResolversTypes['marketListings']>,
    ParentType,
    ContextType,
    Partial<Query_RootMarketListingsArgs>
  >;
  marketListingsAggregate?: Resolver<
    ResolversTypes['marketListings_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootMarketListingsAggregateArgs>
  >;
  marketplaceContract?: Resolver<
    Maybe<ResolversTypes['marketplaceContracts']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMarketplaceContractArgs, 'id'>
  >;
  marketplaceContracts?: Resolver<
    Array<ResolversTypes['marketplaceContracts']>,
    ParentType,
    ContextType,
    Partial<Query_RootMarketplaceContractsArgs>
  >;
  marketplaceContractsAggregate?: Resolver<
    ResolversTypes['marketplaceContracts_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootMarketplaceContractsAggregateArgs>
  >;
  me?: Resolver<Maybe<ResolversTypes['UserOutput']>, ParentType, ContextType>;
  nft?: Resolver<
    Maybe<ResolversTypes['nfts']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNftArgs, 'id'>
  >;
  nftContract?: Resolver<
    Maybe<ResolversTypes['nftContracts']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNftContractArgs, 'id'>
  >;
  nftContracts?: Resolver<
    Array<ResolversTypes['nftContracts']>,
    ParentType,
    ContextType,
    Partial<Query_RootNftContractsArgs>
  >;
  nftContractsAggregate?: Resolver<
    ResolversTypes['nftContracts_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootNftContractsAggregateArgs>
  >;
  nftMetadata?: Resolver<
    Maybe<ResolversTypes['nftMetadatas']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNftMetadataArgs, 'id'>
  >;
  nftMetadatas?: Resolver<
    Array<ResolversTypes['nftMetadatas']>,
    ParentType,
    ContextType,
    Partial<Query_RootNftMetadatasArgs>
  >;
  nftMetadatasAggregate?: Resolver<
    ResolversTypes['nftMetadatas_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootNftMetadatasAggregateArgs>
  >;
  nftOwner?: Resolver<
    Maybe<ResolversTypes['nftOwners']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNftOwnerArgs, 'id'>
  >;
  nftOwners?: Resolver<
    Array<ResolversTypes['nftOwners']>,
    ParentType,
    ContextType,
    Partial<Query_RootNftOwnersArgs>
  >;
  nftOwnersAggregate?: Resolver<
    ResolversTypes['nftOwners_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootNftOwnersAggregateArgs>
  >;
  nft_gpanel_users?: Resolver<
    Array<ResolversTypes['nft_gpanel_users']>,
    ParentType,
    ContextType,
    Partial<Query_RootNft_Gpanel_UsersArgs>
  >;
  nft_gpanel_users_aggregate?: Resolver<
    ResolversTypes['nft_gpanel_users_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootNft_Gpanel_Users_AggregateArgs>
  >;
  nft_gpanel_users_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_gpanel_users']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNft_Gpanel_Users_By_PkArgs, 'id'>
  >;
  nft_listings?: Resolver<
    Array<ResolversTypes['nft_listings']>,
    ParentType,
    ContextType,
    Partial<Query_RootNft_ListingsArgs>
  >;
  nft_listings_aggregate?: Resolver<
    ResolversTypes['nft_listings_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootNft_Listings_AggregateArgs>
  >;
  nft_listings_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_listings']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNft_Listings_By_PkArgs, 'id'>
  >;
  nft_transactions?: Resolver<
    Array<ResolversTypes['nft_transactions']>,
    ParentType,
    ContextType,
    Partial<Query_RootNft_TransactionsArgs>
  >;
  nft_transactions_aggregate?: Resolver<
    ResolversTypes['nft_transactions_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootNft_Transactions_AggregateArgs>
  >;
  nft_transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['nft_transactions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNft_Transactions_By_PkArgs, 'id'>
  >;
  nfts?: Resolver<
    Array<ResolversTypes['nfts']>,
    ParentType,
    ContextType,
    Partial<Query_RootNftsArgs>
  >;
  nftsAggregate?: Resolver<
    ResolversTypes['nfts_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootNftsAggregateArgs>
  >;
  paymentGetDepositAddress?: Resolver<
    Maybe<ResolversTypes['depositAddressOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPaymentGetDepositAddressArgs, 'blockchainId'>
  >;
  paymentSumsubToken?: Resolver<
    Maybe<ResolversTypes['sumsubOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPaymentSumsubTokenArgs, 'gpanelUserId'>
  >;
  pendingTransaction?: Resolver<
    Maybe<ResolversTypes['pendingTransactions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPendingTransactionArgs, 'id'>
  >;
  pendingTransactions?: Resolver<
    Array<ResolversTypes['pendingTransactions']>,
    ParentType,
    ContextType,
    Partial<Query_RootPendingTransactionsArgs>
  >;
  pendingTransactionsAggregate?: Resolver<
    ResolversTypes['pendingTransactions_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPendingTransactionsAggregateArgs>
  >;
  pending_tx?: Resolver<
    Array<ResolversTypes['pending_tx']>,
    ParentType,
    ContextType,
    Partial<Query_RootPending_TxArgs>
  >;
  pending_tx_aggregate?: Resolver<
    ResolversTypes['pending_tx_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPending_Tx_AggregateArgs>
  >;
  pending_tx_by_pk?: Resolver<
    Maybe<ResolversTypes['pending_tx']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPending_Tx_By_PkArgs, 'id'>
  >;
  rakebackLocks?: Resolver<
    Array<ResolversTypes['rakebackLocks']>,
    ParentType,
    ContextType,
    Partial<Query_RootRakebackLocksArgs>
  >;
  rakebackLocks_aggregate?: Resolver<
    ResolversTypes['rakebackLocks_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootRakebackLocks_AggregateArgs>
  >;
  rakebackLocks_by_pk?: Resolver<
    Maybe<ResolversTypes['rakebackLocks']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootRakebackLocks_By_PkArgs, 'id'>
  >;
  stablecoin?: Resolver<
    Maybe<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStablecoinArgs, 'id'>
  >;
  stablecoins?: Resolver<
    Array<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType,
    Partial<Query_RootStablecoinsArgs>
  >;
  stablecoinsAggregate?: Resolver<
    ResolversTypes['stablecoins_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStablecoinsAggregateArgs>
  >;
  staking_refill?: Resolver<
    Array<ResolversTypes['staking_refill']>,
    ParentType,
    ContextType,
    Partial<Query_RootStaking_RefillArgs>
  >;
  staking_refill_aggregate?: Resolver<
    ResolversTypes['staking_refill_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStaking_Refill_AggregateArgs>
  >;
  staking_refill_by_pk?: Resolver<
    Maybe<ResolversTypes['staking_refill']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStaking_Refill_By_PkArgs, 'id'>
  >;
  swapGetOrder?: Resolver<
    Maybe<ResolversTypes['SwapOrderOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSwapGetOrderArgs, 'gpanelUserId' | 'id'>
  >;
  swapRates?: Resolver<
    Maybe<ResolversTypes['RateOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSwapRatesArgs, 'fromCurrency' | 'toCurrency'>
  >;
  swapTransactions?: Resolver<
    Array<ResolversTypes['swapTransactions']>,
    ParentType,
    ContextType,
    Partial<Query_RootSwapTransactionsArgs>
  >;
  swapTransactionsAggregate?: Resolver<
    ResolversTypes['swapTransactions_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootSwapTransactionsAggregateArgs>
  >;
  tournaments?: Resolver<
    Array<ResolversTypes['tournaments']>,
    ParentType,
    ContextType,
    Partial<Query_RootTournamentsArgs>
  >;
  tournaments_aggregate?: Resolver<
    ResolversTypes['tournaments_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTournaments_AggregateArgs>
  >;
  tournaments_by_pk?: Resolver<
    Maybe<ResolversTypes['tournaments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTournaments_By_PkArgs, 'id'>
  >;
  transactionStatistic?: Resolver<
    Maybe<ResolversTypes['transactionStatistics']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTransactionStatisticArgs, 'id'>
  >;
  transactionStatistics?: Resolver<
    Array<ResolversTypes['transactionStatistics']>,
    ParentType,
    ContextType,
    Partial<Query_RootTransactionStatisticsArgs>
  >;
  transactionStatisticsAggregate?: Resolver<
    ResolversTypes['transactionStatistics_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTransactionStatisticsAggregateArgs>
  >;
  transactions?: Resolver<
    Array<ResolversTypes['transactions']>,
    ParentType,
    ContextType,
    Partial<Query_RootTransactionsArgs>
  >;
  transactionsAggregate?: Resolver<
    ResolversTypes['transactions_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTransactionsAggregateArgs>
  >;
  transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['transactions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTransactions_By_PkArgs, 'id'>
  >;
  updateAffiliatePlayerBalance?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType
  >;
  updateAvailableAvatarNFTs?: Resolver<
    Maybe<ResolversTypes['updateAvailableAvatarNFTs']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUpdateAvailableAvatarNfTsArgs, 'id'>
  >;
  user?: Resolver<
    Maybe<ResolversTypes['users']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserArgs, 'id'>
  >;
  userErc721?: Resolver<
    Maybe<ResolversTypes['userErc721']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserErc721Args, 'id'>
  >;
  userErc721s?: Resolver<
    Array<ResolversTypes['userErc721']>,
    ParentType,
    ContextType,
    Partial<Query_RootUserErc721sArgs>
  >;
  userErc721sAggregate?: Resolver<
    ResolversTypes['userErc721_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUserErc721sAggregateArgs>
  >;
  userJob?: Resolver<
    Maybe<ResolversTypes['userJobs']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserJobArgs, 'id'>
  >;
  userJobs?: Resolver<
    Array<ResolversTypes['userJobs']>,
    ParentType,
    ContextType,
    Partial<Query_RootUserJobsArgs>
  >;
  userJobsAggregage?: Resolver<
    ResolversTypes['userJobs_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUserJobsAggregageArgs>
  >;
  user_erc721_block?: Resolver<
    Array<ResolversTypes['user_erc721_block']>,
    ParentType,
    ContextType,
    Partial<Query_RootUser_Erc721_BlockArgs>
  >;
  user_erc721_block_aggregate?: Resolver<
    ResolversTypes['user_erc721_block_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUser_Erc721_Block_AggregateArgs>
  >;
  user_erc721_block_by_pk?: Resolver<
    Maybe<ResolversTypes['user_erc721_block']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUser_Erc721_Block_By_PkArgs, 'id'>
  >;
  user_gpanel?: Resolver<
    Array<ResolversTypes['user_gpanel']>,
    ParentType,
    ContextType,
    Partial<Query_RootUser_GpanelArgs>
  >;
  user_gpanel_aggregate?: Resolver<
    ResolversTypes['user_gpanel_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUser_Gpanel_AggregateArgs>
  >;
  user_gpanel_by_pk?: Resolver<
    Maybe<ResolversTypes['user_gpanel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUser_Gpanel_By_PkArgs, 'id'>
  >;
  user_lock?: Resolver<
    Array<ResolversTypes['user_lock']>,
    ParentType,
    ContextType,
    Partial<Query_RootUser_LockArgs>
  >;
  user_lock_aggregate?: Resolver<
    ResolversTypes['user_lock_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUser_Lock_AggregateArgs>
  >;
  user_lock_by_pk?: Resolver<
    Maybe<ResolversTypes['user_lock']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUser_Lock_By_PkArgs, 'id'>
  >;
  user_staking?: Resolver<
    Array<ResolversTypes['user_staking']>,
    ParentType,
    ContextType,
    Partial<Query_RootUser_StakingArgs>
  >;
  user_staking_aggregate?: Resolver<
    ResolversTypes['user_staking_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUser_Staking_AggregateArgs>
  >;
  user_staking_by_pk?: Resolver<
    Maybe<ResolversTypes['user_staking']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUser_Staking_By_PkArgs, 'id'>
  >;
  user_staking_transactions?: Resolver<
    Array<ResolversTypes['user_staking_transactions']>,
    ParentType,
    ContextType,
    Partial<Query_RootUser_Staking_TransactionsArgs>
  >;
  user_staking_transactions_aggregate?: Resolver<
    ResolversTypes['user_staking_transactions_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUser_Staking_Transactions_AggregateArgs>
  >;
  user_staking_transactions_by_pk?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUser_Staking_Transactions_By_PkArgs, 'id'>
  >;
  users?: Resolver<
    Array<ResolversTypes['users']>,
    ParentType,
    ContextType,
    Partial<Query_RootUsersArgs>
  >;
  usersAggregate?: Resolver<
    ResolversTypes['users_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUsersAggregateArgs>
  >;
  users_login?: Resolver<
    Array<ResolversTypes['users_login']>,
    ParentType,
    ContextType,
    Partial<Query_RootUsers_LoginArgs>
  >;
  users_login_aggregate?: Resolver<
    ResolversTypes['users_login_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUsers_Login_AggregateArgs>
  >;
  users_old?: Resolver<
    Array<ResolversTypes['users_old']>,
    ParentType,
    ContextType,
    Partial<Query_RootUsers_OldArgs>
  >;
  users_old_aggregate?: Resolver<
    ResolversTypes['users_old_aggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUsers_Old_AggregateArgs>
  >;
  users_old_by_pk?: Resolver<
    Maybe<ResolversTypes['users_old']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsers_Old_By_PkArgs, 'id'>
  >;
};

export type RakebackLocksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks'] = ResolversParentTypes['rakebackLocks']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_aggregate'] = ResolversParentTypes['rakebackLocks_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['rakebackLocks']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_aggregate_fields'] = ResolversParentTypes['rakebackLocks_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<RakebackLocks_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['rakebackLocks_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_avg_fields'] = ResolversParentTypes['rakebackLocks_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_max_fields'] = ResolversParentTypes['rakebackLocks_max_fields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_min_fields'] = ResolversParentTypes['rakebackLocks_min_fields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_mutation_response'] = ResolversParentTypes['rakebackLocks_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['rakebackLocks']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_stddev_fields'] = ResolversParentTypes['rakebackLocks_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_stddev_pop_fields'] = ResolversParentTypes['rakebackLocks_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_stddev_samp_fields'] = ResolversParentTypes['rakebackLocks_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_sum_fields'] = ResolversParentTypes['rakebackLocks_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_var_pop_fields'] = ResolversParentTypes['rakebackLocks_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_var_samp_fields'] = ResolversParentTypes['rakebackLocks_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RakebackLocks_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['rakebackLocks_variance_fields'] = ResolversParentTypes['rakebackLocks_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  percentageNft?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SmallintScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['smallint'], any> {
  name: 'smallint';
}

export type StablecoinsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins'] = ResolversParentTypes['stablecoins']
> = {
  blockchain?: Resolver<ResolversTypes['blockchain'], ParentType, ContextType>;
  blockchainId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  depositActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  depositAddressActive?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  withdrawActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_aggregate'] = ResolversParentTypes['stablecoins_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['stablecoins_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_aggregate_fields'] = ResolversParentTypes['stablecoins_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['stablecoins_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Stablecoins_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['stablecoins_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['stablecoins_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['stablecoins_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['stablecoins_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['stablecoins_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['stablecoins_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['stablecoins_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['stablecoins_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['stablecoins_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_avg_fields'] = ResolversParentTypes['stablecoins_avg_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_max_fields'] = ResolversParentTypes['stablecoins_max_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  units?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_min_fields'] = ResolversParentTypes['stablecoins_min_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  units?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_mutation_response'] = ResolversParentTypes['stablecoins_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['stablecoins']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_stddev_fields'] = ResolversParentTypes['stablecoins_stddev_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_stddev_pop_fields'] = ResolversParentTypes['stablecoins_stddev_pop_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_stddev_samp_fields'] = ResolversParentTypes['stablecoins_stddev_samp_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_sum_fields'] = ResolversParentTypes['stablecoins_sum_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_var_pop_fields'] = ResolversParentTypes['stablecoins_var_pop_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_var_samp_fields'] = ResolversParentTypes['stablecoins_var_samp_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Stablecoins_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stablecoins_variance_fields'] = ResolversParentTypes['stablecoins_variance_fields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StakingOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['stakingOutput'] = ResolversParentTypes['stakingOutput']
> = {
  busd?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  inp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nft?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rackbackId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_RefillResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill'] = ResolversParentTypes['staking_refill']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tx?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  week?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_aggregate'] = ResolversParentTypes['staking_refill_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['staking_refill_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['staking_refill']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_aggregate_fields'] = ResolversParentTypes['staking_refill_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['staking_refill_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Staking_Refill_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['staking_refill_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['staking_refill_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['staking_refill_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['staking_refill_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['staking_refill_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['staking_refill_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['staking_refill_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['staking_refill_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['staking_refill_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_avg_fields'] = ResolversParentTypes['staking_refill_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_max_fields'] = ResolversParentTypes['staking_refill_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tx?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_min_fields'] = ResolversParentTypes['staking_refill_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tx?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_mutation_response'] = ResolversParentTypes['staking_refill_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['staking_refill']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_stddev_fields'] = ResolversParentTypes['staking_refill_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_stddev_pop_fields'] = ResolversParentTypes['staking_refill_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_stddev_samp_fields'] = ResolversParentTypes['staking_refill_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_sum_fields'] = ResolversParentTypes['staking_refill_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_var_pop_fields'] = ResolversParentTypes['staking_refill_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_var_samp_fields'] = ResolversParentTypes['staking_refill_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Staking_Refill_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['staking_refill_variance_fields'] = ResolversParentTypes['staking_refill_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Subscription_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']
> = {
  Test?: SubscriptionResolver<
    Array<ResolversTypes['Test']>,
    'Test',
    ParentType,
    ContextType,
    Partial<Subscription_RootTestArgs>
  >;
  Test_aggregate?: SubscriptionResolver<
    ResolversTypes['Test_aggregate'],
    'Test_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTest_AggregateArgs>
  >;
  Test_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['Test']>,
    'Test_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTest_By_PkArgs, 'id'>
  >;
  Test_stream?: SubscriptionResolver<
    Array<ResolversTypes['Test']>,
    'Test_stream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTest_StreamArgs, 'batch_size' | 'cursor'>
  >;
  anno_gpanel?: SubscriptionResolver<
    Array<ResolversTypes['anno_gpanel']>,
    'anno_gpanel',
    ParentType,
    ContextType,
    Partial<Subscription_RootAnno_GpanelArgs>
  >;
  anno_gpanel_aggregate?: SubscriptionResolver<
    ResolversTypes['anno_gpanel_aggregate'],
    'anno_gpanel_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootAnno_Gpanel_AggregateArgs>
  >;
  anno_gpanel_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['anno_gpanel']>,
    'anno_gpanel_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootAnno_Gpanel_By_PkArgs, 'id'>
  >;
  anno_gpanel_stream?: SubscriptionResolver<
    Array<ResolversTypes['anno_gpanel']>,
    'anno_gpanel_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootAnno_Gpanel_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  blockchain?: SubscriptionResolver<
    Maybe<ResolversTypes['blockchain']>,
    'blockchain',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootBlockchainArgs, 'id'>
  >;
  blockchain_main_deposit?: SubscriptionResolver<
    Array<ResolversTypes['blockchain_main_deposit']>,
    'blockchain_main_deposit',
    ParentType,
    ContextType,
    Partial<Subscription_RootBlockchain_Main_DepositArgs>
  >;
  blockchain_main_deposit_aggregate?: SubscriptionResolver<
    ResolversTypes['blockchain_main_deposit_aggregate'],
    'blockchain_main_deposit_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootBlockchain_Main_Deposit_AggregateArgs>
  >;
  blockchain_main_deposit_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['blockchain_main_deposit']>,
    'blockchain_main_deposit_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootBlockchain_Main_Deposit_By_PkArgs, 'id'>
  >;
  blockchain_main_deposit_stream?: SubscriptionResolver<
    Array<ResolversTypes['blockchain_main_deposit']>,
    'blockchain_main_deposit_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootBlockchain_Main_Deposit_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  blockchain_stream?: SubscriptionResolver<
    Array<ResolversTypes['blockchain']>,
    'blockchain_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootBlockchain_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  blockchains?: SubscriptionResolver<
    Array<ResolversTypes['blockchain']>,
    'blockchains',
    ParentType,
    ContextType,
    Partial<Subscription_RootBlockchainsArgs>
  >;
  blockchainsAggregate?: SubscriptionResolver<
    ResolversTypes['blockchain_aggregate'],
    'blockchainsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootBlockchainsAggregateArgs>
  >;
  burn_profit?: SubscriptionResolver<
    Array<ResolversTypes['burn_profit']>,
    'burn_profit',
    ParentType,
    ContextType,
    Partial<Subscription_RootBurn_ProfitArgs>
  >;
  burn_profit_aggregate?: SubscriptionResolver<
    ResolversTypes['burn_profit_aggregate'],
    'burn_profit_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootBurn_Profit_AggregateArgs>
  >;
  burn_profit_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['burn_profit']>,
    'burn_profit_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootBurn_Profit_By_PkArgs, 'id'>
  >;
  burn_profit_stream?: SubscriptionResolver<
    Array<ResolversTypes['burn_profit']>,
    'burn_profit_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootBurn_Profit_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  currencies?: SubscriptionResolver<
    Array<ResolversTypes['currencies']>,
    'currencies',
    ParentType,
    ContextType,
    Partial<Subscription_RootCurrenciesArgs>
  >;
  currencies_aggregate?: SubscriptionResolver<
    ResolversTypes['currencies_aggregate'],
    'currencies_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootCurrencies_AggregateArgs>
  >;
  currencies_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['currencies']>,
    'currencies_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCurrencies_By_PkArgs, 'id'>
  >;
  currencies_stream?: SubscriptionResolver<
    Array<ResolversTypes['currencies']>,
    'currencies_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootCurrencies_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  currency_history?: SubscriptionResolver<
    Array<ResolversTypes['currency_history']>,
    'currency_history',
    ParentType,
    ContextType,
    Partial<Subscription_RootCurrency_HistoryArgs>
  >;
  currency_history_aggregate?: SubscriptionResolver<
    ResolversTypes['currency_history_aggregate'],
    'currency_history_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootCurrency_History_AggregateArgs>
  >;
  currency_history_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['currency_history']>,
    'currency_history_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCurrency_History_By_PkArgs, 'id'>
  >;
  currency_history_stream?: SubscriptionResolver<
    Array<ResolversTypes['currency_history']>,
    'currency_history_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootCurrency_History_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  erc721_addresses?: SubscriptionResolver<
    Array<ResolversTypes['erc721_addresses']>,
    'erc721_addresses',
    ParentType,
    ContextType,
    Partial<Subscription_RootErc721_AddressesArgs>
  >;
  erc721_addresses_aggregate?: SubscriptionResolver<
    ResolversTypes['erc721_addresses_aggregate'],
    'erc721_addresses_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootErc721_Addresses_AggregateArgs>
  >;
  erc721_addresses_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['erc721_addresses']>,
    'erc721_addresses_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootErc721_Addresses_By_PkArgs, 'id'>
  >;
  erc721_addresses_stream?: SubscriptionResolver<
    Array<ResolversTypes['erc721_addresses']>,
    'erc721_addresses_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootErc721_Addresses_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  evenbetAffiliatePlayerBalances?: SubscriptionResolver<
    Array<ResolversTypes['evenbetAffiliatePlayerBalances']>,
    'evenbetAffiliatePlayerBalances',
    ParentType,
    ContextType,
    Partial<Subscription_RootEvenbetAffiliatePlayerBalancesArgs>
  >;
  evenbetAffiliatePlayerBalancesAggregate?: SubscriptionResolver<
    ResolversTypes['evenbetAffiliatePlayerBalances_aggregate'],
    'evenbetAffiliatePlayerBalancesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootEvenbetAffiliatePlayerBalancesAggregateArgs>
  >;
  evenbetAffiliatePlayerBalancesStream?: SubscriptionResolver<
    Array<ResolversTypes['evenbetAffiliatePlayerBalances']>,
    'evenbetAffiliatePlayerBalancesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootEvenbetAffiliatePlayerBalancesStreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  evenbet_ticket_requests?: SubscriptionResolver<
    Array<ResolversTypes['evenbet_ticket_requests']>,
    'evenbet_ticket_requests',
    ParentType,
    ContextType,
    Partial<Subscription_RootEvenbet_Ticket_RequestsArgs>
  >;
  evenbet_ticket_requests_aggregate?: SubscriptionResolver<
    ResolversTypes['evenbet_ticket_requests_aggregate'],
    'evenbet_ticket_requests_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootEvenbet_Ticket_Requests_AggregateArgs>
  >;
  evenbet_ticket_requests_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['evenbet_ticket_requests']>,
    'evenbet_ticket_requests_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEvenbet_Ticket_Requests_By_PkArgs, 'id'>
  >;
  evenbet_ticket_requests_stream?: SubscriptionResolver<
    Array<ResolversTypes['evenbet_ticket_requests']>,
    'evenbet_ticket_requests_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootEvenbet_Ticket_Requests_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  evenbet_tickets?: SubscriptionResolver<
    Array<ResolversTypes['evenbet_tickets']>,
    'evenbet_tickets',
    ParentType,
    ContextType,
    Partial<Subscription_RootEvenbet_TicketsArgs>
  >;
  evenbet_tickets_aggregate?: SubscriptionResolver<
    ResolversTypes['evenbet_tickets_aggregate'],
    'evenbet_tickets_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootEvenbet_Tickets_AggregateArgs>
  >;
  evenbet_tickets_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['evenbet_tickets']>,
    'evenbet_tickets_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEvenbet_Tickets_By_PkArgs, 'id'>
  >;
  evenbet_tickets_stream?: SubscriptionResolver<
    Array<ResolversTypes['evenbet_tickets']>,
    'evenbet_tickets_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootEvenbet_Tickets_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  gpanelPayment?: SubscriptionResolver<
    Maybe<ResolversTypes['gpanelPayments']>,
    'gpanelPayment',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGpanelPaymentArgs, 'id'>
  >;
  gpanelPayments?: SubscriptionResolver<
    Array<ResolversTypes['gpanelPayments']>,
    'gpanelPayments',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanelPaymentsArgs>
  >;
  gpanelPaymentsAggregate?: SubscriptionResolver<
    ResolversTypes['gpanelPayments_aggregate'],
    'gpanelPaymentsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanelPaymentsAggregateArgs>
  >;
  gpanelPayments_stream?: SubscriptionResolver<
    Array<ResolversTypes['gpanelPayments']>,
    'gpanelPayments_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootGpanelPayments_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  gpanelUser?: SubscriptionResolver<
    Maybe<ResolversTypes['gpanelUsers']>,
    'gpanelUser',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGpanelUserArgs, 'id'>
  >;
  gpanelUsers?: SubscriptionResolver<
    Array<ResolversTypes['gpanelUsers']>,
    'gpanelUsers',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanelUsersArgs>
  >;
  gpanelUsersAggregate?: SubscriptionResolver<
    ResolversTypes['gpanelUsers_aggregate'],
    'gpanelUsersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanelUsersAggregateArgs>
  >;
  gpanelUsers_stream?: SubscriptionResolver<
    Array<ResolversTypes['gpanelUsers']>,
    'gpanelUsers_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootGpanelUsers_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  gpanel_users_agent?: SubscriptionResolver<
    Array<ResolversTypes['gpanel_users_agent']>,
    'gpanel_users_agent',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanel_Users_AgentArgs>
  >;
  gpanel_users_agent_aggregate?: SubscriptionResolver<
    ResolversTypes['gpanel_users_agent_aggregate'],
    'gpanel_users_agent_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanel_Users_Agent_AggregateArgs>
  >;
  gpanel_users_agent_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['gpanel_users_agent']>,
    'gpanel_users_agent_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGpanel_Users_Agent_By_PkArgs, 'id'>
  >;
  gpanel_users_agent_stream?: SubscriptionResolver<
    Array<ResolversTypes['gpanel_users_agent']>,
    'gpanel_users_agent_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootGpanel_Users_Agent_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  gpanel_users_deposit_address?: SubscriptionResolver<
    Array<ResolversTypes['gpanel_users_deposit_address']>,
    'gpanel_users_deposit_address',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanel_Users_Deposit_AddressArgs>
  >;
  gpanel_users_deposit_address_aggregate?: SubscriptionResolver<
    ResolversTypes['gpanel_users_deposit_address_aggregate'],
    'gpanel_users_deposit_address_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanel_Users_Deposit_Address_AggregateArgs>
  >;
  gpanel_users_deposit_address_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['gpanel_users_deposit_address']>,
    'gpanel_users_deposit_address_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGpanel_Users_Deposit_Address_By_PkArgs, 'id'>
  >;
  gpanel_users_deposit_address_stream?: SubscriptionResolver<
    Array<ResolversTypes['gpanel_users_deposit_address']>,
    'gpanel_users_deposit_address_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootGpanel_Users_Deposit_Address_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  gpanel_users_kyc?: SubscriptionResolver<
    Array<ResolversTypes['gpanel_users_kyc']>,
    'gpanel_users_kyc',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanel_Users_KycArgs>
  >;
  gpanel_users_kyc_aggregate?: SubscriptionResolver<
    ResolversTypes['gpanel_users_kyc_aggregate'],
    'gpanel_users_kyc_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootGpanel_Users_Kyc_AggregateArgs>
  >;
  gpanel_users_kyc_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['gpanel_users_kyc']>,
    'gpanel_users_kyc_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGpanel_Users_Kyc_By_PkArgs, 'id'>
  >;
  gpanel_users_kyc_stream?: SubscriptionResolver<
    Array<ResolversTypes['gpanel_users_kyc']>,
    'gpanel_users_kyc_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootGpanel_Users_Kyc_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  kyc?: SubscriptionResolver<
    Maybe<ResolversTypes['kycs']>,
    'kyc',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootKycArgs, 'id'>
  >;
  kycs?: SubscriptionResolver<
    Array<ResolversTypes['kycs']>,
    'kycs',
    ParentType,
    ContextType,
    Partial<Subscription_RootKycsArgs>
  >;
  kycs_aggregate?: SubscriptionResolver<
    ResolversTypes['kycs_aggregate'],
    'kycs_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootKycs_AggregateArgs>
  >;
  kycs_stream?: SubscriptionResolver<
    Array<ResolversTypes['kycs']>,
    'kycs_stream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootKycs_StreamArgs, 'batch_size' | 'cursor'>
  >;
  leaderboard?: SubscriptionResolver<
    Array<ResolversTypes['leaderboard']>,
    'leaderboard',
    ParentType,
    ContextType,
    Partial<Subscription_RootLeaderboardArgs>
  >;
  leaderboard_aggregate?: SubscriptionResolver<
    ResolversTypes['leaderboard_aggregate'],
    'leaderboard_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootLeaderboard_AggregateArgs>
  >;
  leaderboard_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['leaderboard']>,
    'leaderboard_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLeaderboard_By_PkArgs, 'id'>
  >;
  leaderboard_ranking?: SubscriptionResolver<
    Array<ResolversTypes['leaderboard_ranking']>,
    'leaderboard_ranking',
    ParentType,
    ContextType,
    Partial<Subscription_RootLeaderboard_RankingArgs>
  >;
  leaderboard_ranking_aggregate?: SubscriptionResolver<
    ResolversTypes['leaderboard_ranking_aggregate'],
    'leaderboard_ranking_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootLeaderboard_Ranking_AggregateArgs>
  >;
  leaderboard_ranking_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['leaderboard_ranking']>,
    'leaderboard_ranking_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLeaderboard_Ranking_By_PkArgs, 'id'>
  >;
  leaderboard_ranking_stream?: SubscriptionResolver<
    Array<ResolversTypes['leaderboard_ranking']>,
    'leaderboard_ranking_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootLeaderboard_Ranking_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  leaderboard_stream?: SubscriptionResolver<
    Array<ResolversTypes['leaderboard']>,
    'leaderboard_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootLeaderboard_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  liquidity_pools?: SubscriptionResolver<
    Array<ResolversTypes['liquidity_pools']>,
    'liquidity_pools',
    ParentType,
    ContextType,
    Partial<Subscription_RootLiquidity_PoolsArgs>
  >;
  liquidity_pools_aggregate?: SubscriptionResolver<
    ResolversTypes['liquidity_pools_aggregate'],
    'liquidity_pools_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootLiquidity_Pools_AggregateArgs>
  >;
  liquidity_pools_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['liquidity_pools']>,
    'liquidity_pools_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLiquidity_Pools_By_PkArgs, 'id'>
  >;
  liquidity_pools_stream?: SubscriptionResolver<
    Array<ResolversTypes['liquidity_pools']>,
    'liquidity_pools_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootLiquidity_Pools_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  marketListing?: SubscriptionResolver<
    Maybe<ResolversTypes['marketListings']>,
    'marketListing',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMarketListingArgs, 'id'>
  >;
  marketListings?: SubscriptionResolver<
    Array<ResolversTypes['marketListings']>,
    'marketListings',
    ParentType,
    ContextType,
    Partial<Subscription_RootMarketListingsArgs>
  >;
  marketListingsAggregate?: SubscriptionResolver<
    ResolversTypes['marketListings_aggregate'],
    'marketListingsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootMarketListingsAggregateArgs>
  >;
  marketListings_stream?: SubscriptionResolver<
    Array<ResolversTypes['marketListings']>,
    'marketListings_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootMarketListings_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  marketplaceContract?: SubscriptionResolver<
    Maybe<ResolversTypes['marketplaceContracts']>,
    'marketplaceContract',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMarketplaceContractArgs, 'id'>
  >;
  marketplaceContracts?: SubscriptionResolver<
    Array<ResolversTypes['marketplaceContracts']>,
    'marketplaceContracts',
    ParentType,
    ContextType,
    Partial<Subscription_RootMarketplaceContractsArgs>
  >;
  marketplaceContractsAggregate?: SubscriptionResolver<
    ResolversTypes['marketplaceContracts_aggregate'],
    'marketplaceContractsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootMarketplaceContractsAggregateArgs>
  >;
  marketplaceContracts_stream?: SubscriptionResolver<
    Array<ResolversTypes['marketplaceContracts']>,
    'marketplaceContracts_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootMarketplaceContracts_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  nft?: SubscriptionResolver<
    Maybe<ResolversTypes['nfts']>,
    'nft',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNftArgs, 'id'>
  >;
  nftContract?: SubscriptionResolver<
    Maybe<ResolversTypes['nftContracts']>,
    'nftContract',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNftContractArgs, 'id'>
  >;
  nftContracts?: SubscriptionResolver<
    Array<ResolversTypes['nftContracts']>,
    'nftContracts',
    ParentType,
    ContextType,
    Partial<Subscription_RootNftContractsArgs>
  >;
  nftContractsAggregate?: SubscriptionResolver<
    ResolversTypes['nftContracts_aggregate'],
    'nftContractsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootNftContractsAggregateArgs>
  >;
  nftContracts_stream?: SubscriptionResolver<
    Array<ResolversTypes['nftContracts']>,
    'nftContracts_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootNftContracts_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  nftMetadata?: SubscriptionResolver<
    Maybe<ResolversTypes['nftMetadatas']>,
    'nftMetadata',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNftMetadataArgs, 'id'>
  >;
  nftMetadatas?: SubscriptionResolver<
    Array<ResolversTypes['nftMetadatas']>,
    'nftMetadatas',
    ParentType,
    ContextType,
    Partial<Subscription_RootNftMetadatasArgs>
  >;
  nftMetadatasAggregate?: SubscriptionResolver<
    ResolversTypes['nftMetadatas_aggregate'],
    'nftMetadatasAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootNftMetadatasAggregateArgs>
  >;
  nftMetadatas_stream?: SubscriptionResolver<
    Array<ResolversTypes['nftMetadatas']>,
    'nftMetadatas_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootNftMetadatas_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  nftOwner?: SubscriptionResolver<
    Maybe<ResolversTypes['nftOwners']>,
    'nftOwner',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNftOwnerArgs, 'id'>
  >;
  nftOwners?: SubscriptionResolver<
    Array<ResolversTypes['nftOwners']>,
    'nftOwners',
    ParentType,
    ContextType,
    Partial<Subscription_RootNftOwnersArgs>
  >;
  nftOwnersAggregate?: SubscriptionResolver<
    ResolversTypes['nftOwners_aggregate'],
    'nftOwnersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootNftOwnersAggregateArgs>
  >;
  nftOwners_stream?: SubscriptionResolver<
    Array<ResolversTypes['nftOwners']>,
    'nftOwners_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootNftOwners_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  nft_gpanel_users?: SubscriptionResolver<
    Array<ResolversTypes['nft_gpanel_users']>,
    'nft_gpanel_users',
    ParentType,
    ContextType,
    Partial<Subscription_RootNft_Gpanel_UsersArgs>
  >;
  nft_gpanel_users_aggregate?: SubscriptionResolver<
    ResolversTypes['nft_gpanel_users_aggregate'],
    'nft_gpanel_users_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootNft_Gpanel_Users_AggregateArgs>
  >;
  nft_gpanel_users_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['nft_gpanel_users']>,
    'nft_gpanel_users_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNft_Gpanel_Users_By_PkArgs, 'id'>
  >;
  nft_gpanel_users_stream?: SubscriptionResolver<
    Array<ResolversTypes['nft_gpanel_users']>,
    'nft_gpanel_users_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootNft_Gpanel_Users_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  nft_listings?: SubscriptionResolver<
    Array<ResolversTypes['nft_listings']>,
    'nft_listings',
    ParentType,
    ContextType,
    Partial<Subscription_RootNft_ListingsArgs>
  >;
  nft_listings_aggregate?: SubscriptionResolver<
    ResolversTypes['nft_listings_aggregate'],
    'nft_listings_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootNft_Listings_AggregateArgs>
  >;
  nft_listings_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['nft_listings']>,
    'nft_listings_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNft_Listings_By_PkArgs, 'id'>
  >;
  nft_listings_stream?: SubscriptionResolver<
    Array<ResolversTypes['nft_listings']>,
    'nft_listings_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootNft_Listings_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  nft_transactions?: SubscriptionResolver<
    Array<ResolversTypes['nft_transactions']>,
    'nft_transactions',
    ParentType,
    ContextType,
    Partial<Subscription_RootNft_TransactionsArgs>
  >;
  nft_transactions_aggregate?: SubscriptionResolver<
    ResolversTypes['nft_transactions_aggregate'],
    'nft_transactions_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootNft_Transactions_AggregateArgs>
  >;
  nft_transactions_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['nft_transactions']>,
    'nft_transactions_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNft_Transactions_By_PkArgs, 'id'>
  >;
  nft_transactions_stream?: SubscriptionResolver<
    Array<ResolversTypes['nft_transactions']>,
    'nft_transactions_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootNft_Transactions_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  nfts?: SubscriptionResolver<
    Array<ResolversTypes['nfts']>,
    'nfts',
    ParentType,
    ContextType,
    Partial<Subscription_RootNftsArgs>
  >;
  nftsAggregate?: SubscriptionResolver<
    ResolversTypes['nfts_aggregate'],
    'nftsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootNftsAggregateArgs>
  >;
  nfts_stream?: SubscriptionResolver<
    Array<ResolversTypes['nfts']>,
    'nfts_stream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNfts_StreamArgs, 'batch_size' | 'cursor'>
  >;
  pendingTransaction?: SubscriptionResolver<
    Maybe<ResolversTypes['pendingTransactions']>,
    'pendingTransaction',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPendingTransactionArgs, 'id'>
  >;
  pendingTransactions?: SubscriptionResolver<
    Array<ResolversTypes['pendingTransactions']>,
    'pendingTransactions',
    ParentType,
    ContextType,
    Partial<Subscription_RootPendingTransactionsArgs>
  >;
  pendingTransactionsAggregate?: SubscriptionResolver<
    ResolversTypes['pendingTransactions_aggregate'],
    'pendingTransactionsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPendingTransactionsAggregateArgs>
  >;
  pendingTransactions_stream?: SubscriptionResolver<
    Array<ResolversTypes['pendingTransactions']>,
    'pendingTransactions_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootPendingTransactions_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  pending_tx?: SubscriptionResolver<
    Array<ResolversTypes['pending_tx']>,
    'pending_tx',
    ParentType,
    ContextType,
    Partial<Subscription_RootPending_TxArgs>
  >;
  pending_tx_aggregate?: SubscriptionResolver<
    ResolversTypes['pending_tx_aggregate'],
    'pending_tx_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPending_Tx_AggregateArgs>
  >;
  pending_tx_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['pending_tx']>,
    'pending_tx_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPending_Tx_By_PkArgs, 'id'>
  >;
  pending_tx_stream?: SubscriptionResolver<
    Array<ResolversTypes['pending_tx']>,
    'pending_tx_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootPending_Tx_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  rakebackLocks?: SubscriptionResolver<
    Array<ResolversTypes['rakebackLocks']>,
    'rakebackLocks',
    ParentType,
    ContextType,
    Partial<Subscription_RootRakebackLocksArgs>
  >;
  rakebackLocks_aggregate?: SubscriptionResolver<
    ResolversTypes['rakebackLocks_aggregate'],
    'rakebackLocks_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootRakebackLocks_AggregateArgs>
  >;
  rakebackLocks_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['rakebackLocks']>,
    'rakebackLocks_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRakebackLocks_By_PkArgs, 'id'>
  >;
  rakebackLocks_stream?: SubscriptionResolver<
    Array<ResolversTypes['rakebackLocks']>,
    'rakebackLocks_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootRakebackLocks_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  stablecoin?: SubscriptionResolver<
    Maybe<ResolversTypes['stablecoins']>,
    'stablecoin',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStablecoinArgs, 'id'>
  >;
  stablecoins?: SubscriptionResolver<
    Array<ResolversTypes['stablecoins']>,
    'stablecoins',
    ParentType,
    ContextType,
    Partial<Subscription_RootStablecoinsArgs>
  >;
  stablecoinsAggregate?: SubscriptionResolver<
    ResolversTypes['stablecoins_aggregate'],
    'stablecoinsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStablecoinsAggregateArgs>
  >;
  stablecoins_stream?: SubscriptionResolver<
    Array<ResolversTypes['stablecoins']>,
    'stablecoins_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootStablecoins_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  staking_refill?: SubscriptionResolver<
    Array<ResolversTypes['staking_refill']>,
    'staking_refill',
    ParentType,
    ContextType,
    Partial<Subscription_RootStaking_RefillArgs>
  >;
  staking_refill_aggregate?: SubscriptionResolver<
    ResolversTypes['staking_refill_aggregate'],
    'staking_refill_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStaking_Refill_AggregateArgs>
  >;
  staking_refill_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['staking_refill']>,
    'staking_refill_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStaking_Refill_By_PkArgs, 'id'>
  >;
  staking_refill_stream?: SubscriptionResolver<
    Array<ResolversTypes['staking_refill']>,
    'staking_refill_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootStaking_Refill_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  swapTransactions?: SubscriptionResolver<
    Array<ResolversTypes['swapTransactions']>,
    'swapTransactions',
    ParentType,
    ContextType,
    Partial<Subscription_RootSwapTransactionsArgs>
  >;
  swapTransactionsAggregate?: SubscriptionResolver<
    ResolversTypes['swapTransactions_aggregate'],
    'swapTransactionsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootSwapTransactionsAggregateArgs>
  >;
  swapTransactionsStream?: SubscriptionResolver<
    Array<ResolversTypes['swapTransactions']>,
    'swapTransactionsStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootSwapTransactionsStreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  tournaments?: SubscriptionResolver<
    Array<ResolversTypes['tournaments']>,
    'tournaments',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentsArgs>
  >;
  tournaments_aggregate?: SubscriptionResolver<
    ResolversTypes['tournaments_aggregate'],
    'tournaments_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournaments_AggregateArgs>
  >;
  tournaments_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['tournaments']>,
    'tournaments_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTournaments_By_PkArgs, 'id'>
  >;
  tournaments_stream?: SubscriptionResolver<
    Array<ResolversTypes['tournaments']>,
    'tournaments_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTournaments_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  transactionStatistic?: SubscriptionResolver<
    Maybe<ResolversTypes['transactionStatistics']>,
    'transactionStatistic',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTransactionStatisticArgs, 'id'>
  >;
  transactionStatistics?: SubscriptionResolver<
    Array<ResolversTypes['transactionStatistics']>,
    'transactionStatistics',
    ParentType,
    ContextType,
    Partial<Subscription_RootTransactionStatisticsArgs>
  >;
  transactionStatisticsAggregate?: SubscriptionResolver<
    ResolversTypes['transactionStatistics_aggregate'],
    'transactionStatisticsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTransactionStatisticsAggregateArgs>
  >;
  transactionStatistics_stream?: SubscriptionResolver<
    Array<ResolversTypes['transactionStatistics']>,
    'transactionStatistics_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTransactionStatistics_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  transactions?: SubscriptionResolver<
    Array<ResolversTypes['transactions']>,
    'transactions',
    ParentType,
    ContextType,
    Partial<Subscription_RootTransactionsArgs>
  >;
  transactionsAggregate?: SubscriptionResolver<
    ResolversTypes['transactions_aggregate'],
    'transactionsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTransactionsAggregateArgs>
  >;
  transactions_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['transactions']>,
    'transactions_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTransactions_By_PkArgs, 'id'>
  >;
  transactions_stream?: SubscriptionResolver<
    Array<ResolversTypes['transactions']>,
    'transactions_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTransactions_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  updateAvailableAvatarNFTs?: SubscriptionResolver<
    Maybe<ResolversTypes['updateAvailableAvatarNFTs']>,
    'updateAvailableAvatarNFTs',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUpdateAvailableAvatarNfTsArgs, 'id'>
  >;
  user?: SubscriptionResolver<
    Maybe<ResolversTypes['users']>,
    'user',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserArgs, 'id'>
  >;
  userErc721?: SubscriptionResolver<
    Maybe<ResolversTypes['userErc721']>,
    'userErc721',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserErc721Args, 'id'>
  >;
  userErc721_stream?: SubscriptionResolver<
    Array<ResolversTypes['userErc721']>,
    'userErc721_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUserErc721_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  userErc721s?: SubscriptionResolver<
    Array<ResolversTypes['userErc721']>,
    'userErc721s',
    ParentType,
    ContextType,
    Partial<Subscription_RootUserErc721sArgs>
  >;
  userErc721sAggregate?: SubscriptionResolver<
    ResolversTypes['userErc721_aggregate'],
    'userErc721sAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUserErc721sAggregateArgs>
  >;
  userJob?: SubscriptionResolver<
    Maybe<ResolversTypes['userJobs']>,
    'userJob',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserJobArgs, 'id'>
  >;
  userJobs?: SubscriptionResolver<
    Array<ResolversTypes['userJobs']>,
    'userJobs',
    ParentType,
    ContextType,
    Partial<Subscription_RootUserJobsArgs>
  >;
  userJobsAggregage?: SubscriptionResolver<
    ResolversTypes['userJobs_aggregate'],
    'userJobsAggregage',
    ParentType,
    ContextType,
    Partial<Subscription_RootUserJobsAggregageArgs>
  >;
  userJobsStream?: SubscriptionResolver<
    Array<ResolversTypes['userJobs']>,
    'userJobsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserJobsStreamArgs, 'batch_size' | 'cursor'>
  >;
  user_erc721_block?: SubscriptionResolver<
    Array<ResolversTypes['user_erc721_block']>,
    'user_erc721_block',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_Erc721_BlockArgs>
  >;
  user_erc721_block_aggregate?: SubscriptionResolver<
    ResolversTypes['user_erc721_block_aggregate'],
    'user_erc721_block_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_Erc721_Block_AggregateArgs>
  >;
  user_erc721_block_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['user_erc721_block']>,
    'user_erc721_block_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUser_Erc721_Block_By_PkArgs, 'id'>
  >;
  user_erc721_block_stream?: SubscriptionResolver<
    Array<ResolversTypes['user_erc721_block']>,
    'user_erc721_block_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUser_Erc721_Block_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  user_gpanel?: SubscriptionResolver<
    Array<ResolversTypes['user_gpanel']>,
    'user_gpanel',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_GpanelArgs>
  >;
  user_gpanel_aggregate?: SubscriptionResolver<
    ResolversTypes['user_gpanel_aggregate'],
    'user_gpanel_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_Gpanel_AggregateArgs>
  >;
  user_gpanel_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['user_gpanel']>,
    'user_gpanel_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUser_Gpanel_By_PkArgs, 'id'>
  >;
  user_gpanel_stream?: SubscriptionResolver<
    Array<ResolversTypes['user_gpanel']>,
    'user_gpanel_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUser_Gpanel_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  user_lock?: SubscriptionResolver<
    Array<ResolversTypes['user_lock']>,
    'user_lock',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_LockArgs>
  >;
  user_lock_aggregate?: SubscriptionResolver<
    ResolversTypes['user_lock_aggregate'],
    'user_lock_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_Lock_AggregateArgs>
  >;
  user_lock_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['user_lock']>,
    'user_lock_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUser_Lock_By_PkArgs, 'id'>
  >;
  user_lock_stream?: SubscriptionResolver<
    Array<ResolversTypes['user_lock']>,
    'user_lock_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUser_Lock_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  user_staking?: SubscriptionResolver<
    Array<ResolversTypes['user_staking']>,
    'user_staking',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_StakingArgs>
  >;
  user_staking_aggregate?: SubscriptionResolver<
    ResolversTypes['user_staking_aggregate'],
    'user_staking_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_Staking_AggregateArgs>
  >;
  user_staking_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['user_staking']>,
    'user_staking_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUser_Staking_By_PkArgs, 'id'>
  >;
  user_staking_stream?: SubscriptionResolver<
    Array<ResolversTypes['user_staking']>,
    'user_staking_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUser_Staking_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  user_staking_transactions?: SubscriptionResolver<
    Array<ResolversTypes['user_staking_transactions']>,
    'user_staking_transactions',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_Staking_TransactionsArgs>
  >;
  user_staking_transactions_aggregate?: SubscriptionResolver<
    ResolversTypes['user_staking_transactions_aggregate'],
    'user_staking_transactions_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUser_Staking_Transactions_AggregateArgs>
  >;
  user_staking_transactions_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['user_staking_transactions']>,
    'user_staking_transactions_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUser_Staking_Transactions_By_PkArgs, 'id'>
  >;
  user_staking_transactions_stream?: SubscriptionResolver<
    Array<ResolversTypes['user_staking_transactions']>,
    'user_staking_transactions_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUser_Staking_Transactions_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  users?: SubscriptionResolver<
    Array<ResolversTypes['users']>,
    'users',
    ParentType,
    ContextType,
    Partial<Subscription_RootUsersArgs>
  >;
  usersAggregate?: SubscriptionResolver<
    ResolversTypes['users_aggregate'],
    'usersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUsersAggregateArgs>
  >;
  users_login?: SubscriptionResolver<
    Array<ResolversTypes['users_login']>,
    'users_login',
    ParentType,
    ContextType,
    Partial<Subscription_RootUsers_LoginArgs>
  >;
  users_login_aggregate?: SubscriptionResolver<
    ResolversTypes['users_login_aggregate'],
    'users_login_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUsers_Login_AggregateArgs>
  >;
  users_login_stream?: SubscriptionResolver<
    Array<ResolversTypes['users_login']>,
    'users_login_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUsers_Login_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  users_old?: SubscriptionResolver<
    Array<ResolversTypes['users_old']>,
    'users_old',
    ParentType,
    ContextType,
    Partial<Subscription_RootUsers_OldArgs>
  >;
  users_old_aggregate?: SubscriptionResolver<
    ResolversTypes['users_old_aggregate'],
    'users_old_aggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUsers_Old_AggregateArgs>
  >;
  users_old_by_pk?: SubscriptionResolver<
    Maybe<ResolversTypes['users_old']>,
    'users_old_by_pk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsers_Old_By_PkArgs, 'id'>
  >;
  users_old_stream?: SubscriptionResolver<
    Array<ResolversTypes['users_old']>,
    'users_old_stream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUsers_Old_StreamArgs,
      'batch_size' | 'cursor'
    >
  >;
  users_stream?: SubscriptionResolver<
    Array<ResolversTypes['users']>,
    'users_stream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsers_StreamArgs, 'batch_size' | 'cursor'>
  >;
};

export type SumsubOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['sumsubOutput'] = ResolversParentTypes['sumsubOutput']
> = {
  accessToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions'] = ResolversParentTypes['swapTransactions']
> = {
  amountFrom?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amountTo?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amountToMinimum?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currencyFrom?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyTo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositTransaction?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusInternal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  withdrawTransaction?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_aggregate'] = ResolversParentTypes['swapTransactions_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['swapTransactions_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['swapTransactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_aggregate_fields'] = ResolversParentTypes['swapTransactions_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['swapTransactions_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<SwapTransactions_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['swapTransactions_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['swapTransactions_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['swapTransactions_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['swapTransactions_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['swapTransactions_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['swapTransactions_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['swapTransactions_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['swapTransactions_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['swapTransactions_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_avg_fields'] = ResolversParentTypes['swapTransactions_avg_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_max_fields'] = ResolversParentTypes['swapTransactions_max_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyFrom?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currencyTo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  depositTransaction?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusInternal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  withdrawTransaction?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_min_fields'] = ResolversParentTypes['swapTransactions_min_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyFrom?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currencyTo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  depositTransaction?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusInternal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  withdrawTransaction?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_mutation_response'] = ResolversParentTypes['swapTransactions_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['swapTransactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_stddev_fields'] = ResolversParentTypes['swapTransactions_stddev_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_stddev_pop_fields'] = ResolversParentTypes['swapTransactions_stddev_pop_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_stddev_samp_fields'] = ResolversParentTypes['swapTransactions_stddev_samp_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_sum_fields'] = ResolversParentTypes['swapTransactions_sum_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_var_pop_fields'] = ResolversParentTypes['swapTransactions_var_pop_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_var_samp_fields'] = ResolversParentTypes['swapTransactions_var_samp_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwapTransactions_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['swapTransactions_variance_fields'] = ResolversParentTypes['swapTransactions_variance_fields']
> = {
  amountFrom?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  amountTo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountToMinimum?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimestamptzScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export type TournamentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments'] = ResolversParentTypes['tournaments']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  announcement?: Resolver<
    ResolversTypes['timestamptz'],
    ParentType,
    ContextType
  >;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reentry?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_aggregate'] = ResolversParentTypes['tournaments_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['tournaments_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['tournaments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_aggregate_fields'] = ResolversParentTypes['tournaments_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['tournaments_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Tournaments_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['tournaments_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['tournaments_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['tournaments_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['tournaments_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['tournaments_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['tournaments_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['tournaments_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['tournaments_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['tournaments_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_avg_fields'] = ResolversParentTypes['tournaments_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_max_fields'] = ResolversParentTypes['tournaments_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  announcement?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_min_fields'] = ResolversParentTypes['tournaments_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  announcement?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  gpanel_user_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_mutation_response'] = ResolversParentTypes['tournaments_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['tournaments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_stddev_fields'] = ResolversParentTypes['tournaments_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_stddev_pop_fields'] = ResolversParentTypes['tournaments_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_stddev_samp_fields'] = ResolversParentTypes['tournaments_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_sum_fields'] = ResolversParentTypes['tournaments_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_var_pop_fields'] = ResolversParentTypes['tournaments_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_var_samp_fields'] = ResolversParentTypes['tournaments_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tournaments_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['tournaments_variance_fields'] = ResolversParentTypes['tournaments_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  eventbet_tournament_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_profit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatisticsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics'] = ResolversParentTypes['transactionStatistics']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCoins?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalTransactions?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_aggregate'] = ResolversParentTypes['transactionStatistics_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['transactionStatistics']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_aggregate_fields'] = ResolversParentTypes['transactionStatistics_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TransactionStatistics_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['transactionStatistics_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_avg_fields'] = ResolversParentTypes['transactionStatistics_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_max_fields'] = ResolversParentTypes['transactionStatistics_max_fields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalCoins?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalTransactions?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_min_fields'] = ResolversParentTypes['transactionStatistics_min_fields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalCoins?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalTransactions?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_mutation_response'] = ResolversParentTypes['transactionStatistics_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['transactionStatistics']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_stddev_fields'] = ResolversParentTypes['transactionStatistics_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_stddev_pop_fields'] = ResolversParentTypes['transactionStatistics_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_stddev_samp_fields'] = ResolversParentTypes['transactionStatistics_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_sum_fields'] = ResolversParentTypes['transactionStatistics_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_var_pop_fields'] = ResolversParentTypes['transactionStatistics_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_var_samp_fields'] = ResolversParentTypes['transactionStatistics_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionStatistics_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactionStatistics_variance_fields'] = ResolversParentTypes['transactionStatistics_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions'] = ResolversParentTypes['transactions']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payload?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    Partial<TransactionsPayloadArgs>
  >;
  payloadBinary?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<TransactionsPayloadBinaryArgs>
  >;
  price?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  txId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_aggregate'] = ResolversParentTypes['transactions_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['transactions_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['transactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_aggregate_fields'] = ResolversParentTypes['transactions_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['transactions_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Transactions_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['transactions_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['transactions_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['transactions_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['transactions_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['transactions_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['transactions_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['transactions_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['transactions_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['transactions_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_avg_fields'] = ResolversParentTypes['transactions_avg_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_max_fields'] = ResolversParentTypes['transactions_max_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  txId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_min_fields'] = ResolversParentTypes['transactions_min_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  txId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_mutation_response'] = ResolversParentTypes['transactions_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['transactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_stddev_fields'] = ResolversParentTypes['transactions_stddev_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_stddev_pop_fields'] = ResolversParentTypes['transactions_stddev_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_stddev_samp_fields'] = ResolversParentTypes['transactions_stddev_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_sum_fields'] = ResolversParentTypes['transactions_sum_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['smallint']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_var_pop_fields'] = ResolversParentTypes['transactions_var_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_var_samp_fields'] = ResolversParentTypes['transactions_var_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Transactions_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['transactions_variance_fields'] = ResolversParentTypes['transactions_variance_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAvailableAvatarNfTsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['updateAvailableAvatarNFTs'] = ResolversParentTypes['updateAvailableAvatarNFTs']
> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  errors?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  output?: Resolver<
    Maybe<ResolversTypes['GeneralMessageOutput']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721'] = ResolversParentTypes['userErc721']
> = {
  animationUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  collection?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  erc721AddressId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  imgUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_aggregate'] = ResolversParentTypes['userErc721_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['userErc721_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['userErc721']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_aggregate_fields'] = ResolversParentTypes['userErc721_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['userErc721_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<UserErc721_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['userErc721_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['userErc721_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['userErc721_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['userErc721_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['userErc721_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['userErc721_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['userErc721_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['userErc721_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['userErc721_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_avg_fields'] = ResolversParentTypes['userErc721_avg_fields']
> = {
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_max_fields'] = ResolversParentTypes['userErc721_max_fields']
> = {
  animationUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  collection?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  imgUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_min_fields'] = ResolversParentTypes['userErc721_min_fields']
> = {
  animationUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  collection?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  imgUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_mutation_response'] = ResolversParentTypes['userErc721_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['userErc721']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_stddev_fields'] = ResolversParentTypes['userErc721_stddev_fields']
> = {
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_stddev_pop_fields'] = ResolversParentTypes['userErc721_stddev_pop_fields']
> = {
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_stddev_samp_fields'] = ResolversParentTypes['userErc721_stddev_samp_fields']
> = {
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_sum_fields'] = ResolversParentTypes['userErc721_sum_fields']
> = {
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_var_pop_fields'] = ResolversParentTypes['userErc721_var_pop_fields']
> = {
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_var_samp_fields'] = ResolversParentTypes['userErc721_var_samp_fields']
> = {
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErc721_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userErc721_variance_fields'] = ResolversParentTypes['userErc721_variance_fields']
> = {
  erc721AddressId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs'] = ResolversParentTypes['userJobs']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_aggregate'] = ResolversParentTypes['userJobs_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['userJobs_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['userJobs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_aggregate_fields'] = ResolversParentTypes['userJobs_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['userJobs_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<UserJobs_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['userJobs_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['userJobs_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['userJobs_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['userJobs_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['userJobs_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['userJobs_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['userJobs_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['userJobs_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['userJobs_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_avg_fields'] = ResolversParentTypes['userJobs_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_max_fields'] = ResolversParentTypes['userJobs_max_fields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_min_fields'] = ResolversParentTypes['userJobs_min_fields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_mutation_response'] = ResolversParentTypes['userJobs_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['userJobs']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_stddev_fields'] = ResolversParentTypes['userJobs_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_stddev_pop_fields'] = ResolversParentTypes['userJobs_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_stddev_samp_fields'] = ResolversParentTypes['userJobs_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_sum_fields'] = ResolversParentTypes['userJobs_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_var_pop_fields'] = ResolversParentTypes['userJobs_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_var_samp_fields'] = ResolversParentTypes['userJobs_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJobs_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userJobs_variance_fields'] = ResolversParentTypes['userJobs_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_BlockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block'] = ResolversParentTypes['user_erc721_block']
> = {
  blockchain_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blocknr?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_aggregate'] = ResolversParentTypes['user_erc721_block_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['user_erc721_block']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_aggregate_fields'] = ResolversParentTypes['user_erc721_block_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<User_Erc721_Block_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['user_erc721_block_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_avg_fields'] = ResolversParentTypes['user_erc721_block_avg_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_max_fields'] = ResolversParentTypes['user_erc721_block_max_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_min_fields'] = ResolversParentTypes['user_erc721_block_min_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_mutation_response'] = ResolversParentTypes['user_erc721_block_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['user_erc721_block']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_stddev_fields'] = ResolversParentTypes['user_erc721_block_stddev_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_stddev_pop_fields'] = ResolversParentTypes['user_erc721_block_stddev_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_stddev_samp_fields'] = ResolversParentTypes['user_erc721_block_stddev_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_sum_fields'] = ResolversParentTypes['user_erc721_block_sum_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_var_pop_fields'] = ResolversParentTypes['user_erc721_block_var_pop_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_var_samp_fields'] = ResolversParentTypes['user_erc721_block_var_samp_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Erc721_Block_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_erc721_block_variance_fields'] = ResolversParentTypes['user_erc721_block_variance_fields']
> = {
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blocknr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_GpanelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel'] = ResolversParentTypes['user_gpanel']
> = {
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  code_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  refresh_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_aggregate'] = ResolversParentTypes['user_gpanel_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['user_gpanel_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['user_gpanel']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_aggregate_fields'] = ResolversParentTypes['user_gpanel_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['user_gpanel_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<User_Gpanel_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['user_gpanel_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['user_gpanel_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['user_gpanel_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['user_gpanel_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['user_gpanel_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['user_gpanel_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['user_gpanel_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['user_gpanel_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['user_gpanel_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_avg_fields'] = ResolversParentTypes['user_gpanel_avg_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_max_fields'] = ResolversParentTypes['user_gpanel_max_fields']
> = {
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  code_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  refresh_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_min_fields'] = ResolversParentTypes['user_gpanel_min_fields']
> = {
  access_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  code_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  refresh_token?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_mutation_response'] = ResolversParentTypes['user_gpanel_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['user_gpanel']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_stddev_fields'] = ResolversParentTypes['user_gpanel_stddev_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_stddev_pop_fields'] = ResolversParentTypes['user_gpanel_stddev_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_stddev_samp_fields'] = ResolversParentTypes['user_gpanel_stddev_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_sum_fields'] = ResolversParentTypes['user_gpanel_sum_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_var_pop_fields'] = ResolversParentTypes['user_gpanel_var_pop_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_var_samp_fields'] = ResolversParentTypes['user_gpanel_var_samp_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Gpanel_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_gpanel_variance_fields'] = ResolversParentTypes['user_gpanel_variance_fields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_LockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock'] = ResolversParentTypes['user_lock']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  confirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  lock_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  removed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_aggregate'] = ResolversParentTypes['user_lock_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['user_lock_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['user_lock']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_aggregate_fields'] = ResolversParentTypes['user_lock_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['user_lock_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<User_Lock_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['user_lock_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['user_lock_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['user_lock_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['user_lock_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['user_lock_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['user_lock_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['user_lock_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['user_lock_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['user_lock_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_avg_fields'] = ResolversParentTypes['user_lock_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_max_fields'] = ResolversParentTypes['user_lock_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_min_fields'] = ResolversParentTypes['user_lock_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_mutation_response'] = ResolversParentTypes['user_lock_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['user_lock']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_stddev_fields'] = ResolversParentTypes['user_lock_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_stddev_pop_fields'] = ResolversParentTypes['user_lock_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_stddev_samp_fields'] = ResolversParentTypes['user_lock_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_sum_fields'] = ResolversParentTypes['user_lock_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_var_pop_fields'] = ResolversParentTypes['user_lock_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_var_samp_fields'] = ResolversParentTypes['user_lock_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Lock_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_lock_variance_fields'] = ResolversParentTypes['user_lock_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lock_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_StakingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking'] = ResolversParentTypes['user_staking']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rackback_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unstaked_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  usd_value?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_aggregate'] = ResolversParentTypes['user_staking_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['user_staking_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['user_staking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_aggregate_fields'] = ResolversParentTypes['user_staking_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['user_staking_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<User_Staking_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['user_staking_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['user_staking_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['user_staking_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['user_staking_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['user_staking_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['user_staking_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['user_staking_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['user_staking_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['user_staking_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_avg_fields'] = ResolversParentTypes['user_staking_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rackback_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_max_fields'] = ResolversParentTypes['user_staking_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rackback_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unstaked_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_min_fields'] = ResolversParentTypes['user_staking_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rackback_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unstaked_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_mutation_response'] = ResolversParentTypes['user_staking_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['user_staking']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_stddev_fields'] = ResolversParentTypes['user_staking_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rackback_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_stddev_pop_fields'] = ResolversParentTypes['user_staking_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rackback_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_stddev_samp_fields'] = ResolversParentTypes['user_staking_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rackback_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_sum_fields'] = ResolversParentTypes['user_staking_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rackback_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  usd_value?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_TransactionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions'] = ResolversParentTypes['user_staking_transactions']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeStamp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_aggregate'] = ResolversParentTypes['user_staking_transactions_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['user_staking_transactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_aggregate_fields'] = ResolversParentTypes['user_staking_transactions_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<User_Staking_Transactions_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['user_staking_transactions_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_avg_fields'] = ResolversParentTypes['user_staking_transactions_avg_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_max_fields'] = ResolversParentTypes['user_staking_transactions_max_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_min_fields'] = ResolversParentTypes['user_staking_transactions_min_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_mutation_response'] = ResolversParentTypes['user_staking_transactions_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['user_staking_transactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_stddev_fields'] = ResolversParentTypes['user_staking_transactions_stddev_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_stddev_pop_fields'] = ResolversParentTypes['user_staking_transactions_stddev_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_stddev_samp_fields'] = ResolversParentTypes['user_staking_transactions_stddev_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_sum_fields'] = ResolversParentTypes['user_staking_transactions_sum_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  usd_value?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_var_pop_fields'] = ResolversParentTypes['user_staking_transactions_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_var_samp_fields'] = ResolversParentTypes['user_staking_transactions_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Transactions_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_transactions_variance_fields'] = ResolversParentTypes['user_staking_transactions_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchain_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_var_pop_fields'] = ResolversParentTypes['user_staking_var_pop_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rackback_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_var_samp_fields'] = ResolversParentTypes['user_staking_var_samp_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rackback_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Staking_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['user_staking_variance_fields'] = ResolversParentTypes['user_staking_variance_fields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rackback_id?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  usd_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users'] = ResolversParentTypes['users']
> = {
  chainBusd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  chainInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ethAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  gpanel_users?: Resolver<
    Array<ResolversTypes['gpanelUsers']>,
    ParentType,
    ContextType,
    Partial<UsersGpanel_UsersArgs>
  >;
  gpanel_users_aggregate?: Resolver<
    ResolversTypes['gpanelUsers_aggregate'],
    ParentType,
    ContextType,
    Partial<UsersGpanel_Users_AggregateArgs>
  >;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  idnr?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastSeen?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  lastSignin?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  nft_owners?: Resolver<
    Array<ResolversTypes['nftOwners']>,
    ParentType,
    ContextType,
    Partial<UsersNft_OwnersArgs>
  >;
  nft_owners_aggregate?: Resolver<
    ResolversTypes['nftOwners_aggregate'],
    ParentType,
    ContextType,
    Partial<UsersNft_Owners_AggregateArgs>
  >;
  pending_transactions?: Resolver<
    Array<ResolversTypes['pendingTransactions']>,
    ParentType,
    ContextType,
    Partial<UsersPending_TransactionsArgs>
  >;
  pending_transactions_aggregate?: Resolver<
    ResolversTypes['pendingTransactions_aggregate'],
    ParentType,
    ContextType,
    Partial<UsersPending_Transactions_AggregateArgs>
  >;
  pokerBusd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  pokerInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  pokerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signature?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  telegramName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_gpanels?: Resolver<
    Array<ResolversTypes['user_gpanel']>,
    ParentType,
    ContextType,
    Partial<UsersUser_GpanelsArgs>
  >;
  user_gpanels_aggregate?: Resolver<
    ResolversTypes['user_gpanel_aggregate'],
    ParentType,
    ContextType,
    Partial<UsersUser_Gpanels_AggregateArgs>
  >;
  user_locks?: Resolver<
    Array<ResolversTypes['user_lock']>,
    ParentType,
    ContextType,
    Partial<UsersUser_LocksArgs>
  >;
  user_locks_aggregate?: Resolver<
    ResolversTypes['user_lock_aggregate'],
    ParentType,
    ContextType,
    Partial<UsersUser_Locks_AggregateArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_aggregate'] = ResolversParentTypes['users_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['users_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_aggregate_fields'] = ResolversParentTypes['users_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['users_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Users_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['users_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['users_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['users_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['users_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['users_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['users_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['users_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['users_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['users_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_avg_fields'] = ResolversParentTypes['users_avg_fields']
> = {
  chainBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chainInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  idnr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_LoginResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_login'] = ResolversParentTypes['users_login']
> = {
  eth_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpanel_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Login_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_login_aggregate'] = ResolversParentTypes['users_login_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['users_login_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['users_login']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Login_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_login_aggregate_fields'] = ResolversParentTypes['users_login_aggregate_fields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Users_Login_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['users_login_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['users_login_min_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Login_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_login_max_fields'] = ResolversParentTypes['users_login_max_fields']
> = {
  eth_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpanel_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Login_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_login_min_fields'] = ResolversParentTypes['users_login_min_fields']
> = {
  eth_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpanel_id?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_max_fields'] = ResolversParentTypes['users_max_fields']
> = {
  chainBusd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  chainInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  ethAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  idnr?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastSeen?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  lastSignin?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  pokerBusd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  pokerInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  pokerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signature?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  telegramName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_min_fields'] = ResolversParentTypes['users_min_fields']
> = {
  chainBusd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  chainInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  ethAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  idnr?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastSeen?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  lastSignin?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  pokerBusd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  pokerInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  pokerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  signature?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  telegramName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_mutation_response'] = ResolversParentTypes['users_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_OldResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old'] = ResolversParentTypes['users_old']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  eth_address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  poker_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  telegram_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_aggregate'] = ResolversParentTypes['users_old_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['users_old_aggregate_fields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['users_old']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Aggregate_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_aggregate_fields'] = ResolversParentTypes['users_old_aggregate_fields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['users_old_avg_fields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<Users_Old_Aggregate_FieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['users_old_max_fields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['users_old_min_fields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['users_old_stddev_fields']>,
    ParentType,
    ContextType
  >;
  stddev_pop?: Resolver<
    Maybe<ResolversTypes['users_old_stddev_pop_fields']>,
    ParentType,
    ContextType
  >;
  stddev_samp?: Resolver<
    Maybe<ResolversTypes['users_old_stddev_samp_fields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['users_old_sum_fields']>,
    ParentType,
    ContextType
  >;
  var_pop?: Resolver<
    Maybe<ResolversTypes['users_old_var_pop_fields']>,
    ParentType,
    ContextType
  >;
  var_samp?: Resolver<
    Maybe<ResolversTypes['users_old_var_samp_fields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['users_old_variance_fields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Avg_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_avg_fields'] = ResolversParentTypes['users_old_avg_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Max_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_max_fields'] = ResolversParentTypes['users_old_max_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  eth_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  poker_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  telegram_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Min_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_min_fields'] = ResolversParentTypes['users_old_min_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  created_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  eth_address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  poker_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  telegram_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Mutation_ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_mutation_response'] = ResolversParentTypes['users_old_mutation_response']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['users_old']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_stddev_fields'] = ResolversParentTypes['users_old_stddev_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_stddev_pop_fields'] = ResolversParentTypes['users_old_stddev_pop_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_stddev_samp_fields'] = ResolversParentTypes['users_old_stddev_samp_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_sum_fields'] = ResolversParentTypes['users_old_sum_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_var_pop_fields'] = ResolversParentTypes['users_old_var_pop_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_var_samp_fields'] = ResolversParentTypes['users_old_var_samp_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Old_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_old_variance_fields'] = ResolversParentTypes['users_old_variance_fields']
> = {
  chain_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  chain_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poker_busd?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  poker_inp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Stddev_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_stddev_fields'] = ResolversParentTypes['users_stddev_fields']
> = {
  chainBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chainInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  idnr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Stddev_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_stddev_pop_fields'] = ResolversParentTypes['users_stddev_pop_fields']
> = {
  chainBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chainInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  idnr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Stddev_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_stddev_samp_fields'] = ResolversParentTypes['users_stddev_samp_fields']
> = {
  chainBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chainInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  idnr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Sum_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_sum_fields'] = ResolversParentTypes['users_sum_fields']
> = {
  chainBusd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  chainInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  idnr?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  pokerBusd?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  pokerInp?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Var_Pop_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_var_pop_fields'] = ResolversParentTypes['users_var_pop_fields']
> = {
  chainBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chainInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  idnr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Var_Samp_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_var_samp_fields'] = ResolversParentTypes['users_var_samp_fields']
> = {
  chainBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chainInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  idnr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Users_Variance_FieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['users_variance_fields'] = ResolversParentTypes['users_variance_fields']
> = {
  chainBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chainInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gpanelUserId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  idnr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerBusd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pokerInp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type Resolvers<ContextType = any> = {
  DepositOutput?: DepositOutputResolvers<ContextType>;
  GeneralMessageOutput?: GeneralMessageOutputResolvers<ContextType>;
  KYCSubmitOutput?: KycSubmitOutputResolvers<ContextType>;
  NFTConnectedDetail?: NftConnectedDetailResolvers<ContextType>;
  NFTConnectedOutput?: NftConnectedOutputResolvers<ContextType>;
  NFTTicketsClaimOptions?: NftTicketsClaimOptionsResolvers<ContextType>;
  NFTTicketsClaimOutput?: NftTicketsClaimOutputResolvers<ContextType>;
  RateOutput?: RateOutputResolvers<ContextType>;
  StatusOutput?: StatusOutputResolvers<ContextType>;
  SwapOrderOutput?: SwapOrderOutputResolvers<ContextType>;
  SwapOutput?: SwapOutputResolvers<ContextType>;
  Test?: TestResolvers<ContextType>;
  Test_aggregate?: Test_AggregateResolvers<ContextType>;
  Test_aggregate_fields?: Test_Aggregate_FieldsResolvers<ContextType>;
  Test_avg_fields?: Test_Avg_FieldsResolvers<ContextType>;
  Test_max_fields?: Test_Max_FieldsResolvers<ContextType>;
  Test_min_fields?: Test_Min_FieldsResolvers<ContextType>;
  Test_mutation_response?: Test_Mutation_ResponseResolvers<ContextType>;
  Test_stddev_fields?: Test_Stddev_FieldsResolvers<ContextType>;
  Test_stddev_pop_fields?: Test_Stddev_Pop_FieldsResolvers<ContextType>;
  Test_stddev_samp_fields?: Test_Stddev_Samp_FieldsResolvers<ContextType>;
  Test_sum_fields?: Test_Sum_FieldsResolvers<ContextType>;
  Test_var_pop_fields?: Test_Var_Pop_FieldsResolvers<ContextType>;
  Test_var_samp_fields?: Test_Var_Samp_FieldsResolvers<ContextType>;
  Test_variance_fields?: Test_Variance_FieldsResolvers<ContextType>;
  UserOutput?: UserOutputResolvers<ContextType>;
  agentFindUserOutput?: AgentFindUserOutputResolvers<ContextType>;
  agentGetUsersOutput?: AgentGetUsersOutputResolvers<ContextType>;
  agentUserItemOutput?: AgentUserItemOutputResolvers<ContextType>;
  agentUserOutput?: AgentUserOutputResolvers<ContextType>;
  anno_gpanel?: Anno_GpanelResolvers<ContextType>;
  anno_gpanel_aggregate?: Anno_Gpanel_AggregateResolvers<ContextType>;
  anno_gpanel_aggregate_fields?: Anno_Gpanel_Aggregate_FieldsResolvers<ContextType>;
  anno_gpanel_avg_fields?: Anno_Gpanel_Avg_FieldsResolvers<ContextType>;
  anno_gpanel_max_fields?: Anno_Gpanel_Max_FieldsResolvers<ContextType>;
  anno_gpanel_min_fields?: Anno_Gpanel_Min_FieldsResolvers<ContextType>;
  anno_gpanel_mutation_response?: Anno_Gpanel_Mutation_ResponseResolvers<ContextType>;
  anno_gpanel_stddev_fields?: Anno_Gpanel_Stddev_FieldsResolvers<ContextType>;
  anno_gpanel_stddev_pop_fields?: Anno_Gpanel_Stddev_Pop_FieldsResolvers<ContextType>;
  anno_gpanel_stddev_samp_fields?: Anno_Gpanel_Stddev_Samp_FieldsResolvers<ContextType>;
  anno_gpanel_sum_fields?: Anno_Gpanel_Sum_FieldsResolvers<ContextType>;
  anno_gpanel_var_pop_fields?: Anno_Gpanel_Var_Pop_FieldsResolvers<ContextType>;
  anno_gpanel_var_samp_fields?: Anno_Gpanel_Var_Samp_FieldsResolvers<ContextType>;
  anno_gpanel_variance_fields?: Anno_Gpanel_Variance_FieldsResolvers<ContextType>;
  authLoginOutput?: AuthLoginOutputResolvers<ContextType>;
  authSignatureOutput?: AuthSignatureOutputResolvers<ContextType>;
  avatarOutput?: AvatarOutputResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  blockchain?: BlockchainResolvers<ContextType>;
  blockchain_aggregate?: Blockchain_AggregateResolvers<ContextType>;
  blockchain_aggregate_fields?: Blockchain_Aggregate_FieldsResolvers<ContextType>;
  blockchain_avg_fields?: Blockchain_Avg_FieldsResolvers<ContextType>;
  blockchain_main_deposit?: Blockchain_Main_DepositResolvers<ContextType>;
  blockchain_main_deposit_aggregate?: Blockchain_Main_Deposit_AggregateResolvers<ContextType>;
  blockchain_main_deposit_aggregate_fields?: Blockchain_Main_Deposit_Aggregate_FieldsResolvers<ContextType>;
  blockchain_main_deposit_avg_fields?: Blockchain_Main_Deposit_Avg_FieldsResolvers<ContextType>;
  blockchain_main_deposit_max_fields?: Blockchain_Main_Deposit_Max_FieldsResolvers<ContextType>;
  blockchain_main_deposit_min_fields?: Blockchain_Main_Deposit_Min_FieldsResolvers<ContextType>;
  blockchain_main_deposit_mutation_response?: Blockchain_Main_Deposit_Mutation_ResponseResolvers<ContextType>;
  blockchain_main_deposit_stddev_fields?: Blockchain_Main_Deposit_Stddev_FieldsResolvers<ContextType>;
  blockchain_main_deposit_stddev_pop_fields?: Blockchain_Main_Deposit_Stddev_Pop_FieldsResolvers<ContextType>;
  blockchain_main_deposit_stddev_samp_fields?: Blockchain_Main_Deposit_Stddev_Samp_FieldsResolvers<ContextType>;
  blockchain_main_deposit_sum_fields?: Blockchain_Main_Deposit_Sum_FieldsResolvers<ContextType>;
  blockchain_main_deposit_var_pop_fields?: Blockchain_Main_Deposit_Var_Pop_FieldsResolvers<ContextType>;
  blockchain_main_deposit_var_samp_fields?: Blockchain_Main_Deposit_Var_Samp_FieldsResolvers<ContextType>;
  blockchain_main_deposit_variance_fields?: Blockchain_Main_Deposit_Variance_FieldsResolvers<ContextType>;
  blockchain_max_fields?: Blockchain_Max_FieldsResolvers<ContextType>;
  blockchain_min_fields?: Blockchain_Min_FieldsResolvers<ContextType>;
  blockchain_mutation_response?: Blockchain_Mutation_ResponseResolvers<ContextType>;
  blockchain_stddev_fields?: Blockchain_Stddev_FieldsResolvers<ContextType>;
  blockchain_stddev_pop_fields?: Blockchain_Stddev_Pop_FieldsResolvers<ContextType>;
  blockchain_stddev_samp_fields?: Blockchain_Stddev_Samp_FieldsResolvers<ContextType>;
  blockchain_sum_fields?: Blockchain_Sum_FieldsResolvers<ContextType>;
  blockchain_var_pop_fields?: Blockchain_Var_Pop_FieldsResolvers<ContextType>;
  blockchain_var_samp_fields?: Blockchain_Var_Samp_FieldsResolvers<ContextType>;
  blockchain_variance_fields?: Blockchain_Variance_FieldsResolvers<ContextType>;
  burn_profit?: Burn_ProfitResolvers<ContextType>;
  burn_profit_aggregate?: Burn_Profit_AggregateResolvers<ContextType>;
  burn_profit_aggregate_fields?: Burn_Profit_Aggregate_FieldsResolvers<ContextType>;
  burn_profit_avg_fields?: Burn_Profit_Avg_FieldsResolvers<ContextType>;
  burn_profit_max_fields?: Burn_Profit_Max_FieldsResolvers<ContextType>;
  burn_profit_min_fields?: Burn_Profit_Min_FieldsResolvers<ContextType>;
  burn_profit_mutation_response?: Burn_Profit_Mutation_ResponseResolvers<ContextType>;
  burn_profit_stddev_fields?: Burn_Profit_Stddev_FieldsResolvers<ContextType>;
  burn_profit_stddev_pop_fields?: Burn_Profit_Stddev_Pop_FieldsResolvers<ContextType>;
  burn_profit_stddev_samp_fields?: Burn_Profit_Stddev_Samp_FieldsResolvers<ContextType>;
  burn_profit_sum_fields?: Burn_Profit_Sum_FieldsResolvers<ContextType>;
  burn_profit_var_pop_fields?: Burn_Profit_Var_Pop_FieldsResolvers<ContextType>;
  burn_profit_var_samp_fields?: Burn_Profit_Var_Samp_FieldsResolvers<ContextType>;
  burn_profit_variance_fields?: Burn_Profit_Variance_FieldsResolvers<ContextType>;
  currencies?: CurrenciesResolvers<ContextType>;
  currencies_aggregate?: Currencies_AggregateResolvers<ContextType>;
  currencies_aggregate_fields?: Currencies_Aggregate_FieldsResolvers<ContextType>;
  currencies_avg_fields?: Currencies_Avg_FieldsResolvers<ContextType>;
  currencies_max_fields?: Currencies_Max_FieldsResolvers<ContextType>;
  currencies_min_fields?: Currencies_Min_FieldsResolvers<ContextType>;
  currencies_mutation_response?: Currencies_Mutation_ResponseResolvers<ContextType>;
  currencies_stddev_fields?: Currencies_Stddev_FieldsResolvers<ContextType>;
  currencies_stddev_pop_fields?: Currencies_Stddev_Pop_FieldsResolvers<ContextType>;
  currencies_stddev_samp_fields?: Currencies_Stddev_Samp_FieldsResolvers<ContextType>;
  currencies_sum_fields?: Currencies_Sum_FieldsResolvers<ContextType>;
  currencies_var_pop_fields?: Currencies_Var_Pop_FieldsResolvers<ContextType>;
  currencies_var_samp_fields?: Currencies_Var_Samp_FieldsResolvers<ContextType>;
  currencies_variance_fields?: Currencies_Variance_FieldsResolvers<ContextType>;
  currency_history?: Currency_HistoryResolvers<ContextType>;
  currency_history_aggregate?: Currency_History_AggregateResolvers<ContextType>;
  currency_history_aggregate_fields?: Currency_History_Aggregate_FieldsResolvers<ContextType>;
  currency_history_avg_fields?: Currency_History_Avg_FieldsResolvers<ContextType>;
  currency_history_max_fields?: Currency_History_Max_FieldsResolvers<ContextType>;
  currency_history_min_fields?: Currency_History_Min_FieldsResolvers<ContextType>;
  currency_history_mutation_response?: Currency_History_Mutation_ResponseResolvers<ContextType>;
  currency_history_stddev_fields?: Currency_History_Stddev_FieldsResolvers<ContextType>;
  currency_history_stddev_pop_fields?: Currency_History_Stddev_Pop_FieldsResolvers<ContextType>;
  currency_history_stddev_samp_fields?: Currency_History_Stddev_Samp_FieldsResolvers<ContextType>;
  currency_history_sum_fields?: Currency_History_Sum_FieldsResolvers<ContextType>;
  currency_history_var_pop_fields?: Currency_History_Var_Pop_FieldsResolvers<ContextType>;
  currency_history_var_samp_fields?: Currency_History_Var_Samp_FieldsResolvers<ContextType>;
  currency_history_variance_fields?: Currency_History_Variance_FieldsResolvers<ContextType>;
  depositAddressOutput?: DepositAddressOutputResolvers<ContextType>;
  erc721_addresses?: Erc721_AddressesResolvers<ContextType>;
  erc721_addresses_aggregate?: Erc721_Addresses_AggregateResolvers<ContextType>;
  erc721_addresses_aggregate_fields?: Erc721_Addresses_Aggregate_FieldsResolvers<ContextType>;
  erc721_addresses_avg_fields?: Erc721_Addresses_Avg_FieldsResolvers<ContextType>;
  erc721_addresses_max_fields?: Erc721_Addresses_Max_FieldsResolvers<ContextType>;
  erc721_addresses_min_fields?: Erc721_Addresses_Min_FieldsResolvers<ContextType>;
  erc721_addresses_mutation_response?: Erc721_Addresses_Mutation_ResponseResolvers<ContextType>;
  erc721_addresses_stddev_fields?: Erc721_Addresses_Stddev_FieldsResolvers<ContextType>;
  erc721_addresses_stddev_pop_fields?: Erc721_Addresses_Stddev_Pop_FieldsResolvers<ContextType>;
  erc721_addresses_stddev_samp_fields?: Erc721_Addresses_Stddev_Samp_FieldsResolvers<ContextType>;
  erc721_addresses_sum_fields?: Erc721_Addresses_Sum_FieldsResolvers<ContextType>;
  erc721_addresses_var_pop_fields?: Erc721_Addresses_Var_Pop_FieldsResolvers<ContextType>;
  erc721_addresses_var_samp_fields?: Erc721_Addresses_Var_Samp_FieldsResolvers<ContextType>;
  erc721_addresses_variance_fields?: Erc721_Addresses_Variance_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances?: EvenbetAffiliatePlayerBalancesResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_aggregate?: EvenbetAffiliatePlayerBalances_AggregateResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_aggregate_fields?: EvenbetAffiliatePlayerBalances_Aggregate_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_avg_fields?: EvenbetAffiliatePlayerBalances_Avg_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_max_fields?: EvenbetAffiliatePlayerBalances_Max_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_min_fields?: EvenbetAffiliatePlayerBalances_Min_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_stddev_fields?: EvenbetAffiliatePlayerBalances_Stddev_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_stddev_pop_fields?: EvenbetAffiliatePlayerBalances_Stddev_Pop_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_stddev_samp_fields?: EvenbetAffiliatePlayerBalances_Stddev_Samp_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_sum_fields?: EvenbetAffiliatePlayerBalances_Sum_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_var_pop_fields?: EvenbetAffiliatePlayerBalances_Var_Pop_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_var_samp_fields?: EvenbetAffiliatePlayerBalances_Var_Samp_FieldsResolvers<ContextType>;
  evenbetAffiliatePlayerBalances_variance_fields?: EvenbetAffiliatePlayerBalances_Variance_FieldsResolvers<ContextType>;
  evenbet_ticket_requests?: Evenbet_Ticket_RequestsResolvers<ContextType>;
  evenbet_ticket_requests_aggregate?: Evenbet_Ticket_Requests_AggregateResolvers<ContextType>;
  evenbet_ticket_requests_aggregate_fields?: Evenbet_Ticket_Requests_Aggregate_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_avg_fields?: Evenbet_Ticket_Requests_Avg_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_max_fields?: Evenbet_Ticket_Requests_Max_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_min_fields?: Evenbet_Ticket_Requests_Min_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_mutation_response?: Evenbet_Ticket_Requests_Mutation_ResponseResolvers<ContextType>;
  evenbet_ticket_requests_stddev_fields?: Evenbet_Ticket_Requests_Stddev_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_stddev_pop_fields?: Evenbet_Ticket_Requests_Stddev_Pop_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_stddev_samp_fields?: Evenbet_Ticket_Requests_Stddev_Samp_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_sum_fields?: Evenbet_Ticket_Requests_Sum_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_var_pop_fields?: Evenbet_Ticket_Requests_Var_Pop_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_var_samp_fields?: Evenbet_Ticket_Requests_Var_Samp_FieldsResolvers<ContextType>;
  evenbet_ticket_requests_variance_fields?: Evenbet_Ticket_Requests_Variance_FieldsResolvers<ContextType>;
  evenbet_tickets?: Evenbet_TicketsResolvers<ContextType>;
  evenbet_tickets_aggregate?: Evenbet_Tickets_AggregateResolvers<ContextType>;
  evenbet_tickets_aggregate_fields?: Evenbet_Tickets_Aggregate_FieldsResolvers<ContextType>;
  evenbet_tickets_avg_fields?: Evenbet_Tickets_Avg_FieldsResolvers<ContextType>;
  evenbet_tickets_max_fields?: Evenbet_Tickets_Max_FieldsResolvers<ContextType>;
  evenbet_tickets_min_fields?: Evenbet_Tickets_Min_FieldsResolvers<ContextType>;
  evenbet_tickets_mutation_response?: Evenbet_Tickets_Mutation_ResponseResolvers<ContextType>;
  evenbet_tickets_stddev_fields?: Evenbet_Tickets_Stddev_FieldsResolvers<ContextType>;
  evenbet_tickets_stddev_pop_fields?: Evenbet_Tickets_Stddev_Pop_FieldsResolvers<ContextType>;
  evenbet_tickets_stddev_samp_fields?: Evenbet_Tickets_Stddev_Samp_FieldsResolvers<ContextType>;
  evenbet_tickets_sum_fields?: Evenbet_Tickets_Sum_FieldsResolvers<ContextType>;
  evenbet_tickets_var_pop_fields?: Evenbet_Tickets_Var_Pop_FieldsResolvers<ContextType>;
  evenbet_tickets_var_samp_fields?: Evenbet_Tickets_Var_Samp_FieldsResolvers<ContextType>;
  evenbet_tickets_variance_fields?: Evenbet_Tickets_Variance_FieldsResolvers<ContextType>;
  gPanelOutput?: GPanelOutputResolvers<ContextType>;
  gpanelPayments?: GpanelPaymentsResolvers<ContextType>;
  gpanelPayments_aggregate?: GpanelPayments_AggregateResolvers<ContextType>;
  gpanelPayments_aggregate_fields?: GpanelPayments_Aggregate_FieldsResolvers<ContextType>;
  gpanelPayments_avg_fields?: GpanelPayments_Avg_FieldsResolvers<ContextType>;
  gpanelPayments_max_fields?: GpanelPayments_Max_FieldsResolvers<ContextType>;
  gpanelPayments_min_fields?: GpanelPayments_Min_FieldsResolvers<ContextType>;
  gpanelPayments_mutation_response?: GpanelPayments_Mutation_ResponseResolvers<ContextType>;
  gpanelPayments_stddev_fields?: GpanelPayments_Stddev_FieldsResolvers<ContextType>;
  gpanelPayments_stddev_pop_fields?: GpanelPayments_Stddev_Pop_FieldsResolvers<ContextType>;
  gpanelPayments_stddev_samp_fields?: GpanelPayments_Stddev_Samp_FieldsResolvers<ContextType>;
  gpanelPayments_sum_fields?: GpanelPayments_Sum_FieldsResolvers<ContextType>;
  gpanelPayments_var_pop_fields?: GpanelPayments_Var_Pop_FieldsResolvers<ContextType>;
  gpanelPayments_var_samp_fields?: GpanelPayments_Var_Samp_FieldsResolvers<ContextType>;
  gpanelPayments_variance_fields?: GpanelPayments_Variance_FieldsResolvers<ContextType>;
  gpanelUsers?: GpanelUsersResolvers<ContextType>;
  gpanelUsers_aggregate?: GpanelUsers_AggregateResolvers<ContextType>;
  gpanelUsers_aggregate_fields?: GpanelUsers_Aggregate_FieldsResolvers<ContextType>;
  gpanelUsers_avg_fields?: GpanelUsers_Avg_FieldsResolvers<ContextType>;
  gpanelUsers_max_fields?: GpanelUsers_Max_FieldsResolvers<ContextType>;
  gpanelUsers_min_fields?: GpanelUsers_Min_FieldsResolvers<ContextType>;
  gpanelUsers_mutation_response?: GpanelUsers_Mutation_ResponseResolvers<ContextType>;
  gpanelUsers_stddev_fields?: GpanelUsers_Stddev_FieldsResolvers<ContextType>;
  gpanelUsers_stddev_pop_fields?: GpanelUsers_Stddev_Pop_FieldsResolvers<ContextType>;
  gpanelUsers_stddev_samp_fields?: GpanelUsers_Stddev_Samp_FieldsResolvers<ContextType>;
  gpanelUsers_sum_fields?: GpanelUsers_Sum_FieldsResolvers<ContextType>;
  gpanelUsers_var_pop_fields?: GpanelUsers_Var_Pop_FieldsResolvers<ContextType>;
  gpanelUsers_var_samp_fields?: GpanelUsers_Var_Samp_FieldsResolvers<ContextType>;
  gpanelUsers_variance_fields?: GpanelUsers_Variance_FieldsResolvers<ContextType>;
  gpanel_users_agent?: Gpanel_Users_AgentResolvers<ContextType>;
  gpanel_users_agent_aggregate?: Gpanel_Users_Agent_AggregateResolvers<ContextType>;
  gpanel_users_agent_aggregate_fields?: Gpanel_Users_Agent_Aggregate_FieldsResolvers<ContextType>;
  gpanel_users_agent_avg_fields?: Gpanel_Users_Agent_Avg_FieldsResolvers<ContextType>;
  gpanel_users_agent_max_fields?: Gpanel_Users_Agent_Max_FieldsResolvers<ContextType>;
  gpanel_users_agent_min_fields?: Gpanel_Users_Agent_Min_FieldsResolvers<ContextType>;
  gpanel_users_agent_mutation_response?: Gpanel_Users_Agent_Mutation_ResponseResolvers<ContextType>;
  gpanel_users_agent_stddev_fields?: Gpanel_Users_Agent_Stddev_FieldsResolvers<ContextType>;
  gpanel_users_agent_stddev_pop_fields?: Gpanel_Users_Agent_Stddev_Pop_FieldsResolvers<ContextType>;
  gpanel_users_agent_stddev_samp_fields?: Gpanel_Users_Agent_Stddev_Samp_FieldsResolvers<ContextType>;
  gpanel_users_agent_sum_fields?: Gpanel_Users_Agent_Sum_FieldsResolvers<ContextType>;
  gpanel_users_agent_var_pop_fields?: Gpanel_Users_Agent_Var_Pop_FieldsResolvers<ContextType>;
  gpanel_users_agent_var_samp_fields?: Gpanel_Users_Agent_Var_Samp_FieldsResolvers<ContextType>;
  gpanel_users_agent_variance_fields?: Gpanel_Users_Agent_Variance_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address?: Gpanel_Users_Deposit_AddressResolvers<ContextType>;
  gpanel_users_deposit_address_aggregate?: Gpanel_Users_Deposit_Address_AggregateResolvers<ContextType>;
  gpanel_users_deposit_address_aggregate_fields?: Gpanel_Users_Deposit_Address_Aggregate_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_avg_fields?: Gpanel_Users_Deposit_Address_Avg_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_max_fields?: Gpanel_Users_Deposit_Address_Max_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_min_fields?: Gpanel_Users_Deposit_Address_Min_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_mutation_response?: Gpanel_Users_Deposit_Address_Mutation_ResponseResolvers<ContextType>;
  gpanel_users_deposit_address_stddev_fields?: Gpanel_Users_Deposit_Address_Stddev_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_stddev_pop_fields?: Gpanel_Users_Deposit_Address_Stddev_Pop_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_stddev_samp_fields?: Gpanel_Users_Deposit_Address_Stddev_Samp_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_sum_fields?: Gpanel_Users_Deposit_Address_Sum_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_var_pop_fields?: Gpanel_Users_Deposit_Address_Var_Pop_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_var_samp_fields?: Gpanel_Users_Deposit_Address_Var_Samp_FieldsResolvers<ContextType>;
  gpanel_users_deposit_address_variance_fields?: Gpanel_Users_Deposit_Address_Variance_FieldsResolvers<ContextType>;
  gpanel_users_kyc?: Gpanel_Users_KycResolvers<ContextType>;
  gpanel_users_kyc_aggregate?: Gpanel_Users_Kyc_AggregateResolvers<ContextType>;
  gpanel_users_kyc_aggregate_fields?: Gpanel_Users_Kyc_Aggregate_FieldsResolvers<ContextType>;
  gpanel_users_kyc_avg_fields?: Gpanel_Users_Kyc_Avg_FieldsResolvers<ContextType>;
  gpanel_users_kyc_max_fields?: Gpanel_Users_Kyc_Max_FieldsResolvers<ContextType>;
  gpanel_users_kyc_min_fields?: Gpanel_Users_Kyc_Min_FieldsResolvers<ContextType>;
  gpanel_users_kyc_mutation_response?: Gpanel_Users_Kyc_Mutation_ResponseResolvers<ContextType>;
  gpanel_users_kyc_stddev_fields?: Gpanel_Users_Kyc_Stddev_FieldsResolvers<ContextType>;
  gpanel_users_kyc_stddev_pop_fields?: Gpanel_Users_Kyc_Stddev_Pop_FieldsResolvers<ContextType>;
  gpanel_users_kyc_stddev_samp_fields?: Gpanel_Users_Kyc_Stddev_Samp_FieldsResolvers<ContextType>;
  gpanel_users_kyc_sum_fields?: Gpanel_Users_Kyc_Sum_FieldsResolvers<ContextType>;
  gpanel_users_kyc_var_pop_fields?: Gpanel_Users_Kyc_Var_Pop_FieldsResolvers<ContextType>;
  gpanel_users_kyc_var_samp_fields?: Gpanel_Users_Kyc_Var_Samp_FieldsResolvers<ContextType>;
  gpanel_users_kyc_variance_fields?: Gpanel_Users_Kyc_Variance_FieldsResolvers<ContextType>;
  json?: GraphQLScalarType;
  jsonb?: GraphQLScalarType;
  kycOutput?: KycOutputResolvers<ContextType>;
  kycs?: KycsResolvers<ContextType>;
  kycs_aggregate?: Kycs_AggregateResolvers<ContextType>;
  kycs_aggregate_fields?: Kycs_Aggregate_FieldsResolvers<ContextType>;
  kycs_avg_fields?: Kycs_Avg_FieldsResolvers<ContextType>;
  kycs_max_fields?: Kycs_Max_FieldsResolvers<ContextType>;
  kycs_min_fields?: Kycs_Min_FieldsResolvers<ContextType>;
  kycs_mutation_response?: Kycs_Mutation_ResponseResolvers<ContextType>;
  kycs_stddev_fields?: Kycs_Stddev_FieldsResolvers<ContextType>;
  kycs_stddev_pop_fields?: Kycs_Stddev_Pop_FieldsResolvers<ContextType>;
  kycs_stddev_samp_fields?: Kycs_Stddev_Samp_FieldsResolvers<ContextType>;
  kycs_sum_fields?: Kycs_Sum_FieldsResolvers<ContextType>;
  kycs_var_pop_fields?: Kycs_Var_Pop_FieldsResolvers<ContextType>;
  kycs_var_samp_fields?: Kycs_Var_Samp_FieldsResolvers<ContextType>;
  kycs_variance_fields?: Kycs_Variance_FieldsResolvers<ContextType>;
  leaderboard?: LeaderboardResolvers<ContextType>;
  leaderboard_aggregate?: Leaderboard_AggregateResolvers<ContextType>;
  leaderboard_aggregate_fields?: Leaderboard_Aggregate_FieldsResolvers<ContextType>;
  leaderboard_avg_fields?: Leaderboard_Avg_FieldsResolvers<ContextType>;
  leaderboard_max_fields?: Leaderboard_Max_FieldsResolvers<ContextType>;
  leaderboard_min_fields?: Leaderboard_Min_FieldsResolvers<ContextType>;
  leaderboard_mutation_response?: Leaderboard_Mutation_ResponseResolvers<ContextType>;
  leaderboard_ranking?: Leaderboard_RankingResolvers<ContextType>;
  leaderboard_ranking_aggregate?: Leaderboard_Ranking_AggregateResolvers<ContextType>;
  leaderboard_ranking_aggregate_fields?: Leaderboard_Ranking_Aggregate_FieldsResolvers<ContextType>;
  leaderboard_ranking_avg_fields?: Leaderboard_Ranking_Avg_FieldsResolvers<ContextType>;
  leaderboard_ranking_max_fields?: Leaderboard_Ranking_Max_FieldsResolvers<ContextType>;
  leaderboard_ranking_min_fields?: Leaderboard_Ranking_Min_FieldsResolvers<ContextType>;
  leaderboard_ranking_mutation_response?: Leaderboard_Ranking_Mutation_ResponseResolvers<ContextType>;
  leaderboard_ranking_stddev_fields?: Leaderboard_Ranking_Stddev_FieldsResolvers<ContextType>;
  leaderboard_ranking_stddev_pop_fields?: Leaderboard_Ranking_Stddev_Pop_FieldsResolvers<ContextType>;
  leaderboard_ranking_stddev_samp_fields?: Leaderboard_Ranking_Stddev_Samp_FieldsResolvers<ContextType>;
  leaderboard_ranking_sum_fields?: Leaderboard_Ranking_Sum_FieldsResolvers<ContextType>;
  leaderboard_ranking_var_pop_fields?: Leaderboard_Ranking_Var_Pop_FieldsResolvers<ContextType>;
  leaderboard_ranking_var_samp_fields?: Leaderboard_Ranking_Var_Samp_FieldsResolvers<ContextType>;
  leaderboard_ranking_variance_fields?: Leaderboard_Ranking_Variance_FieldsResolvers<ContextType>;
  leaderboard_stddev_fields?: Leaderboard_Stddev_FieldsResolvers<ContextType>;
  leaderboard_stddev_pop_fields?: Leaderboard_Stddev_Pop_FieldsResolvers<ContextType>;
  leaderboard_stddev_samp_fields?: Leaderboard_Stddev_Samp_FieldsResolvers<ContextType>;
  leaderboard_sum_fields?: Leaderboard_Sum_FieldsResolvers<ContextType>;
  leaderboard_var_pop_fields?: Leaderboard_Var_Pop_FieldsResolvers<ContextType>;
  leaderboard_var_samp_fields?: Leaderboard_Var_Samp_FieldsResolvers<ContextType>;
  leaderboard_variance_fields?: Leaderboard_Variance_FieldsResolvers<ContextType>;
  liquidity_pools?: Liquidity_PoolsResolvers<ContextType>;
  liquidity_pools_aggregate?: Liquidity_Pools_AggregateResolvers<ContextType>;
  liquidity_pools_aggregate_fields?: Liquidity_Pools_Aggregate_FieldsResolvers<ContextType>;
  liquidity_pools_avg_fields?: Liquidity_Pools_Avg_FieldsResolvers<ContextType>;
  liquidity_pools_max_fields?: Liquidity_Pools_Max_FieldsResolvers<ContextType>;
  liquidity_pools_min_fields?: Liquidity_Pools_Min_FieldsResolvers<ContextType>;
  liquidity_pools_mutation_response?: Liquidity_Pools_Mutation_ResponseResolvers<ContextType>;
  liquidity_pools_stddev_fields?: Liquidity_Pools_Stddev_FieldsResolvers<ContextType>;
  liquidity_pools_stddev_pop_fields?: Liquidity_Pools_Stddev_Pop_FieldsResolvers<ContextType>;
  liquidity_pools_stddev_samp_fields?: Liquidity_Pools_Stddev_Samp_FieldsResolvers<ContextType>;
  liquidity_pools_sum_fields?: Liquidity_Pools_Sum_FieldsResolvers<ContextType>;
  liquidity_pools_var_pop_fields?: Liquidity_Pools_Var_Pop_FieldsResolvers<ContextType>;
  liquidity_pools_var_samp_fields?: Liquidity_Pools_Var_Samp_FieldsResolvers<ContextType>;
  liquidity_pools_variance_fields?: Liquidity_Pools_Variance_FieldsResolvers<ContextType>;
  marketListings?: MarketListingsResolvers<ContextType>;
  marketListings_aggregate?: MarketListings_AggregateResolvers<ContextType>;
  marketListings_aggregate_fields?: MarketListings_Aggregate_FieldsResolvers<ContextType>;
  marketListings_avg_fields?: MarketListings_Avg_FieldsResolvers<ContextType>;
  marketListings_max_fields?: MarketListings_Max_FieldsResolvers<ContextType>;
  marketListings_min_fields?: MarketListings_Min_FieldsResolvers<ContextType>;
  marketListings_mutation_response?: MarketListings_Mutation_ResponseResolvers<ContextType>;
  marketListings_stddev_fields?: MarketListings_Stddev_FieldsResolvers<ContextType>;
  marketListings_stddev_pop_fields?: MarketListings_Stddev_Pop_FieldsResolvers<ContextType>;
  marketListings_stddev_samp_fields?: MarketListings_Stddev_Samp_FieldsResolvers<ContextType>;
  marketListings_sum_fields?: MarketListings_Sum_FieldsResolvers<ContextType>;
  marketListings_var_pop_fields?: MarketListings_Var_Pop_FieldsResolvers<ContextType>;
  marketListings_var_samp_fields?: MarketListings_Var_Samp_FieldsResolvers<ContextType>;
  marketListings_variance_fields?: MarketListings_Variance_FieldsResolvers<ContextType>;
  marketplaceContracts?: MarketplaceContractsResolvers<ContextType>;
  marketplaceContracts_aggregate?: MarketplaceContracts_AggregateResolvers<ContextType>;
  marketplaceContracts_aggregate_fields?: MarketplaceContracts_Aggregate_FieldsResolvers<ContextType>;
  marketplaceContracts_avg_fields?: MarketplaceContracts_Avg_FieldsResolvers<ContextType>;
  marketplaceContracts_max_fields?: MarketplaceContracts_Max_FieldsResolvers<ContextType>;
  marketplaceContracts_min_fields?: MarketplaceContracts_Min_FieldsResolvers<ContextType>;
  marketplaceContracts_mutation_response?: MarketplaceContracts_Mutation_ResponseResolvers<ContextType>;
  marketplaceContracts_stddev_fields?: MarketplaceContracts_Stddev_FieldsResolvers<ContextType>;
  marketplaceContracts_stddev_pop_fields?: MarketplaceContracts_Stddev_Pop_FieldsResolvers<ContextType>;
  marketplaceContracts_stddev_samp_fields?: MarketplaceContracts_Stddev_Samp_FieldsResolvers<ContextType>;
  marketplaceContracts_sum_fields?: MarketplaceContracts_Sum_FieldsResolvers<ContextType>;
  marketplaceContracts_var_pop_fields?: MarketplaceContracts_Var_Pop_FieldsResolvers<ContextType>;
  marketplaceContracts_var_samp_fields?: MarketplaceContracts_Var_Samp_FieldsResolvers<ContextType>;
  marketplaceContracts_variance_fields?: MarketplaceContracts_Variance_FieldsResolvers<ContextType>;
  mutation_root?: Mutation_RootResolvers<ContextType>;
  nftContracts?: NftContractsResolvers<ContextType>;
  nftContracts_aggregate?: NftContracts_AggregateResolvers<ContextType>;
  nftContracts_aggregate_fields?: NftContracts_Aggregate_FieldsResolvers<ContextType>;
  nftContracts_avg_fields?: NftContracts_Avg_FieldsResolvers<ContextType>;
  nftContracts_max_fields?: NftContracts_Max_FieldsResolvers<ContextType>;
  nftContracts_min_fields?: NftContracts_Min_FieldsResolvers<ContextType>;
  nftContracts_mutation_response?: NftContracts_Mutation_ResponseResolvers<ContextType>;
  nftContracts_stddev_fields?: NftContracts_Stddev_FieldsResolvers<ContextType>;
  nftContracts_stddev_pop_fields?: NftContracts_Stddev_Pop_FieldsResolvers<ContextType>;
  nftContracts_stddev_samp_fields?: NftContracts_Stddev_Samp_FieldsResolvers<ContextType>;
  nftContracts_sum_fields?: NftContracts_Sum_FieldsResolvers<ContextType>;
  nftContracts_var_pop_fields?: NftContracts_Var_Pop_FieldsResolvers<ContextType>;
  nftContracts_var_samp_fields?: NftContracts_Var_Samp_FieldsResolvers<ContextType>;
  nftContracts_variance_fields?: NftContracts_Variance_FieldsResolvers<ContextType>;
  nftMetadatas?: NftMetadatasResolvers<ContextType>;
  nftMetadatas_aggregate?: NftMetadatas_AggregateResolvers<ContextType>;
  nftMetadatas_aggregate_fields?: NftMetadatas_Aggregate_FieldsResolvers<ContextType>;
  nftMetadatas_avg_fields?: NftMetadatas_Avg_FieldsResolvers<ContextType>;
  nftMetadatas_max_fields?: NftMetadatas_Max_FieldsResolvers<ContextType>;
  nftMetadatas_min_fields?: NftMetadatas_Min_FieldsResolvers<ContextType>;
  nftMetadatas_mutation_response?: NftMetadatas_Mutation_ResponseResolvers<ContextType>;
  nftMetadatas_stddev_fields?: NftMetadatas_Stddev_FieldsResolvers<ContextType>;
  nftMetadatas_stddev_pop_fields?: NftMetadatas_Stddev_Pop_FieldsResolvers<ContextType>;
  nftMetadatas_stddev_samp_fields?: NftMetadatas_Stddev_Samp_FieldsResolvers<ContextType>;
  nftMetadatas_sum_fields?: NftMetadatas_Sum_FieldsResolvers<ContextType>;
  nftMetadatas_var_pop_fields?: NftMetadatas_Var_Pop_FieldsResolvers<ContextType>;
  nftMetadatas_var_samp_fields?: NftMetadatas_Var_Samp_FieldsResolvers<ContextType>;
  nftMetadatas_variance_fields?: NftMetadatas_Variance_FieldsResolvers<ContextType>;
  nftOwners?: NftOwnersResolvers<ContextType>;
  nftOwners_aggregate?: NftOwners_AggregateResolvers<ContextType>;
  nftOwners_aggregate_fields?: NftOwners_Aggregate_FieldsResolvers<ContextType>;
  nftOwners_avg_fields?: NftOwners_Avg_FieldsResolvers<ContextType>;
  nftOwners_max_fields?: NftOwners_Max_FieldsResolvers<ContextType>;
  nftOwners_min_fields?: NftOwners_Min_FieldsResolvers<ContextType>;
  nftOwners_mutation_response?: NftOwners_Mutation_ResponseResolvers<ContextType>;
  nftOwners_stddev_fields?: NftOwners_Stddev_FieldsResolvers<ContextType>;
  nftOwners_stddev_pop_fields?: NftOwners_Stddev_Pop_FieldsResolvers<ContextType>;
  nftOwners_stddev_samp_fields?: NftOwners_Stddev_Samp_FieldsResolvers<ContextType>;
  nftOwners_sum_fields?: NftOwners_Sum_FieldsResolvers<ContextType>;
  nftOwners_var_pop_fields?: NftOwners_Var_Pop_FieldsResolvers<ContextType>;
  nftOwners_var_samp_fields?: NftOwners_Var_Samp_FieldsResolvers<ContextType>;
  nftOwners_variance_fields?: NftOwners_Variance_FieldsResolvers<ContextType>;
  nft_gpanel_users?: Nft_Gpanel_UsersResolvers<ContextType>;
  nft_gpanel_users_aggregate?: Nft_Gpanel_Users_AggregateResolvers<ContextType>;
  nft_gpanel_users_aggregate_fields?: Nft_Gpanel_Users_Aggregate_FieldsResolvers<ContextType>;
  nft_gpanel_users_avg_fields?: Nft_Gpanel_Users_Avg_FieldsResolvers<ContextType>;
  nft_gpanel_users_max_fields?: Nft_Gpanel_Users_Max_FieldsResolvers<ContextType>;
  nft_gpanel_users_min_fields?: Nft_Gpanel_Users_Min_FieldsResolvers<ContextType>;
  nft_gpanel_users_mutation_response?: Nft_Gpanel_Users_Mutation_ResponseResolvers<ContextType>;
  nft_gpanel_users_stddev_fields?: Nft_Gpanel_Users_Stddev_FieldsResolvers<ContextType>;
  nft_gpanel_users_stddev_pop_fields?: Nft_Gpanel_Users_Stddev_Pop_FieldsResolvers<ContextType>;
  nft_gpanel_users_stddev_samp_fields?: Nft_Gpanel_Users_Stddev_Samp_FieldsResolvers<ContextType>;
  nft_gpanel_users_sum_fields?: Nft_Gpanel_Users_Sum_FieldsResolvers<ContextType>;
  nft_gpanel_users_var_pop_fields?: Nft_Gpanel_Users_Var_Pop_FieldsResolvers<ContextType>;
  nft_gpanel_users_var_samp_fields?: Nft_Gpanel_Users_Var_Samp_FieldsResolvers<ContextType>;
  nft_gpanel_users_variance_fields?: Nft_Gpanel_Users_Variance_FieldsResolvers<ContextType>;
  nft_listings?: Nft_ListingsResolvers<ContextType>;
  nft_listings_aggregate?: Nft_Listings_AggregateResolvers<ContextType>;
  nft_listings_aggregate_fields?: Nft_Listings_Aggregate_FieldsResolvers<ContextType>;
  nft_listings_avg_fields?: Nft_Listings_Avg_FieldsResolvers<ContextType>;
  nft_listings_max_fields?: Nft_Listings_Max_FieldsResolvers<ContextType>;
  nft_listings_min_fields?: Nft_Listings_Min_FieldsResolvers<ContextType>;
  nft_listings_mutation_response?: Nft_Listings_Mutation_ResponseResolvers<ContextType>;
  nft_listings_stddev_fields?: Nft_Listings_Stddev_FieldsResolvers<ContextType>;
  nft_listings_stddev_pop_fields?: Nft_Listings_Stddev_Pop_FieldsResolvers<ContextType>;
  nft_listings_stddev_samp_fields?: Nft_Listings_Stddev_Samp_FieldsResolvers<ContextType>;
  nft_listings_sum_fields?: Nft_Listings_Sum_FieldsResolvers<ContextType>;
  nft_listings_var_pop_fields?: Nft_Listings_Var_Pop_FieldsResolvers<ContextType>;
  nft_listings_var_samp_fields?: Nft_Listings_Var_Samp_FieldsResolvers<ContextType>;
  nft_listings_variance_fields?: Nft_Listings_Variance_FieldsResolvers<ContextType>;
  nft_transactions?: Nft_TransactionsResolvers<ContextType>;
  nft_transactions_aggregate?: Nft_Transactions_AggregateResolvers<ContextType>;
  nft_transactions_aggregate_fields?: Nft_Transactions_Aggregate_FieldsResolvers<ContextType>;
  nft_transactions_avg_fields?: Nft_Transactions_Avg_FieldsResolvers<ContextType>;
  nft_transactions_max_fields?: Nft_Transactions_Max_FieldsResolvers<ContextType>;
  nft_transactions_min_fields?: Nft_Transactions_Min_FieldsResolvers<ContextType>;
  nft_transactions_mutation_response?: Nft_Transactions_Mutation_ResponseResolvers<ContextType>;
  nft_transactions_stddev_fields?: Nft_Transactions_Stddev_FieldsResolvers<ContextType>;
  nft_transactions_stddev_pop_fields?: Nft_Transactions_Stddev_Pop_FieldsResolvers<ContextType>;
  nft_transactions_stddev_samp_fields?: Nft_Transactions_Stddev_Samp_FieldsResolvers<ContextType>;
  nft_transactions_sum_fields?: Nft_Transactions_Sum_FieldsResolvers<ContextType>;
  nft_transactions_var_pop_fields?: Nft_Transactions_Var_Pop_FieldsResolvers<ContextType>;
  nft_transactions_var_samp_fields?: Nft_Transactions_Var_Samp_FieldsResolvers<ContextType>;
  nft_transactions_variance_fields?: Nft_Transactions_Variance_FieldsResolvers<ContextType>;
  nfts?: NftsResolvers<ContextType>;
  nfts_aggregate?: Nfts_AggregateResolvers<ContextType>;
  nfts_aggregate_fields?: Nfts_Aggregate_FieldsResolvers<ContextType>;
  nfts_avg_fields?: Nfts_Avg_FieldsResolvers<ContextType>;
  nfts_max_fields?: Nfts_Max_FieldsResolvers<ContextType>;
  nfts_min_fields?: Nfts_Min_FieldsResolvers<ContextType>;
  nfts_mutation_response?: Nfts_Mutation_ResponseResolvers<ContextType>;
  nfts_stddev_fields?: Nfts_Stddev_FieldsResolvers<ContextType>;
  nfts_stddev_pop_fields?: Nfts_Stddev_Pop_FieldsResolvers<ContextType>;
  nfts_stddev_samp_fields?: Nfts_Stddev_Samp_FieldsResolvers<ContextType>;
  nfts_sum_fields?: Nfts_Sum_FieldsResolvers<ContextType>;
  nfts_var_pop_fields?: Nfts_Var_Pop_FieldsResolvers<ContextType>;
  nfts_var_samp_fields?: Nfts_Var_Samp_FieldsResolvers<ContextType>;
  nfts_variance_fields?: Nfts_Variance_FieldsResolvers<ContextType>;
  numeric?: GraphQLScalarType;
  pendingTransactions?: PendingTransactionsResolvers<ContextType>;
  pendingTransactions_aggregate?: PendingTransactions_AggregateResolvers<ContextType>;
  pendingTransactions_aggregate_fields?: PendingTransactions_Aggregate_FieldsResolvers<ContextType>;
  pendingTransactions_avg_fields?: PendingTransactions_Avg_FieldsResolvers<ContextType>;
  pendingTransactions_max_fields?: PendingTransactions_Max_FieldsResolvers<ContextType>;
  pendingTransactions_min_fields?: PendingTransactions_Min_FieldsResolvers<ContextType>;
  pendingTransactions_mutation_response?: PendingTransactions_Mutation_ResponseResolvers<ContextType>;
  pendingTransactions_stddev_fields?: PendingTransactions_Stddev_FieldsResolvers<ContextType>;
  pendingTransactions_stddev_pop_fields?: PendingTransactions_Stddev_Pop_FieldsResolvers<ContextType>;
  pendingTransactions_stddev_samp_fields?: PendingTransactions_Stddev_Samp_FieldsResolvers<ContextType>;
  pendingTransactions_sum_fields?: PendingTransactions_Sum_FieldsResolvers<ContextType>;
  pendingTransactions_var_pop_fields?: PendingTransactions_Var_Pop_FieldsResolvers<ContextType>;
  pendingTransactions_var_samp_fields?: PendingTransactions_Var_Samp_FieldsResolvers<ContextType>;
  pendingTransactions_variance_fields?: PendingTransactions_Variance_FieldsResolvers<ContextType>;
  pending_tx?: Pending_TxResolvers<ContextType>;
  pending_tx_aggregate?: Pending_Tx_AggregateResolvers<ContextType>;
  pending_tx_aggregate_fields?: Pending_Tx_Aggregate_FieldsResolvers<ContextType>;
  pending_tx_avg_fields?: Pending_Tx_Avg_FieldsResolvers<ContextType>;
  pending_tx_max_fields?: Pending_Tx_Max_FieldsResolvers<ContextType>;
  pending_tx_min_fields?: Pending_Tx_Min_FieldsResolvers<ContextType>;
  pending_tx_mutation_response?: Pending_Tx_Mutation_ResponseResolvers<ContextType>;
  pending_tx_stddev_fields?: Pending_Tx_Stddev_FieldsResolvers<ContextType>;
  pending_tx_stddev_pop_fields?: Pending_Tx_Stddev_Pop_FieldsResolvers<ContextType>;
  pending_tx_stddev_samp_fields?: Pending_Tx_Stddev_Samp_FieldsResolvers<ContextType>;
  pending_tx_sum_fields?: Pending_Tx_Sum_FieldsResolvers<ContextType>;
  pending_tx_var_pop_fields?: Pending_Tx_Var_Pop_FieldsResolvers<ContextType>;
  pending_tx_var_samp_fields?: Pending_Tx_Var_Samp_FieldsResolvers<ContextType>;
  pending_tx_variance_fields?: Pending_Tx_Variance_FieldsResolvers<ContextType>;
  query_root?: Query_RootResolvers<ContextType>;
  rakebackLocks?: RakebackLocksResolvers<ContextType>;
  rakebackLocks_aggregate?: RakebackLocks_AggregateResolvers<ContextType>;
  rakebackLocks_aggregate_fields?: RakebackLocks_Aggregate_FieldsResolvers<ContextType>;
  rakebackLocks_avg_fields?: RakebackLocks_Avg_FieldsResolvers<ContextType>;
  rakebackLocks_max_fields?: RakebackLocks_Max_FieldsResolvers<ContextType>;
  rakebackLocks_min_fields?: RakebackLocks_Min_FieldsResolvers<ContextType>;
  rakebackLocks_mutation_response?: RakebackLocks_Mutation_ResponseResolvers<ContextType>;
  rakebackLocks_stddev_fields?: RakebackLocks_Stddev_FieldsResolvers<ContextType>;
  rakebackLocks_stddev_pop_fields?: RakebackLocks_Stddev_Pop_FieldsResolvers<ContextType>;
  rakebackLocks_stddev_samp_fields?: RakebackLocks_Stddev_Samp_FieldsResolvers<ContextType>;
  rakebackLocks_sum_fields?: RakebackLocks_Sum_FieldsResolvers<ContextType>;
  rakebackLocks_var_pop_fields?: RakebackLocks_Var_Pop_FieldsResolvers<ContextType>;
  rakebackLocks_var_samp_fields?: RakebackLocks_Var_Samp_FieldsResolvers<ContextType>;
  rakebackLocks_variance_fields?: RakebackLocks_Variance_FieldsResolvers<ContextType>;
  smallint?: GraphQLScalarType;
  stablecoins?: StablecoinsResolvers<ContextType>;
  stablecoins_aggregate?: Stablecoins_AggregateResolvers<ContextType>;
  stablecoins_aggregate_fields?: Stablecoins_Aggregate_FieldsResolvers<ContextType>;
  stablecoins_avg_fields?: Stablecoins_Avg_FieldsResolvers<ContextType>;
  stablecoins_max_fields?: Stablecoins_Max_FieldsResolvers<ContextType>;
  stablecoins_min_fields?: Stablecoins_Min_FieldsResolvers<ContextType>;
  stablecoins_mutation_response?: Stablecoins_Mutation_ResponseResolvers<ContextType>;
  stablecoins_stddev_fields?: Stablecoins_Stddev_FieldsResolvers<ContextType>;
  stablecoins_stddev_pop_fields?: Stablecoins_Stddev_Pop_FieldsResolvers<ContextType>;
  stablecoins_stddev_samp_fields?: Stablecoins_Stddev_Samp_FieldsResolvers<ContextType>;
  stablecoins_sum_fields?: Stablecoins_Sum_FieldsResolvers<ContextType>;
  stablecoins_var_pop_fields?: Stablecoins_Var_Pop_FieldsResolvers<ContextType>;
  stablecoins_var_samp_fields?: Stablecoins_Var_Samp_FieldsResolvers<ContextType>;
  stablecoins_variance_fields?: Stablecoins_Variance_FieldsResolvers<ContextType>;
  stakingOutput?: StakingOutputResolvers<ContextType>;
  staking_refill?: Staking_RefillResolvers<ContextType>;
  staking_refill_aggregate?: Staking_Refill_AggregateResolvers<ContextType>;
  staking_refill_aggregate_fields?: Staking_Refill_Aggregate_FieldsResolvers<ContextType>;
  staking_refill_avg_fields?: Staking_Refill_Avg_FieldsResolvers<ContextType>;
  staking_refill_max_fields?: Staking_Refill_Max_FieldsResolvers<ContextType>;
  staking_refill_min_fields?: Staking_Refill_Min_FieldsResolvers<ContextType>;
  staking_refill_mutation_response?: Staking_Refill_Mutation_ResponseResolvers<ContextType>;
  staking_refill_stddev_fields?: Staking_Refill_Stddev_FieldsResolvers<ContextType>;
  staking_refill_stddev_pop_fields?: Staking_Refill_Stddev_Pop_FieldsResolvers<ContextType>;
  staking_refill_stddev_samp_fields?: Staking_Refill_Stddev_Samp_FieldsResolvers<ContextType>;
  staking_refill_sum_fields?: Staking_Refill_Sum_FieldsResolvers<ContextType>;
  staking_refill_var_pop_fields?: Staking_Refill_Var_Pop_FieldsResolvers<ContextType>;
  staking_refill_var_samp_fields?: Staking_Refill_Var_Samp_FieldsResolvers<ContextType>;
  staking_refill_variance_fields?: Staking_Refill_Variance_FieldsResolvers<ContextType>;
  subscription_root?: Subscription_RootResolvers<ContextType>;
  sumsubOutput?: SumsubOutputResolvers<ContextType>;
  swapTransactions?: SwapTransactionsResolvers<ContextType>;
  swapTransactions_aggregate?: SwapTransactions_AggregateResolvers<ContextType>;
  swapTransactions_aggregate_fields?: SwapTransactions_Aggregate_FieldsResolvers<ContextType>;
  swapTransactions_avg_fields?: SwapTransactions_Avg_FieldsResolvers<ContextType>;
  swapTransactions_max_fields?: SwapTransactions_Max_FieldsResolvers<ContextType>;
  swapTransactions_min_fields?: SwapTransactions_Min_FieldsResolvers<ContextType>;
  swapTransactions_mutation_response?: SwapTransactions_Mutation_ResponseResolvers<ContextType>;
  swapTransactions_stddev_fields?: SwapTransactions_Stddev_FieldsResolvers<ContextType>;
  swapTransactions_stddev_pop_fields?: SwapTransactions_Stddev_Pop_FieldsResolvers<ContextType>;
  swapTransactions_stddev_samp_fields?: SwapTransactions_Stddev_Samp_FieldsResolvers<ContextType>;
  swapTransactions_sum_fields?: SwapTransactions_Sum_FieldsResolvers<ContextType>;
  swapTransactions_var_pop_fields?: SwapTransactions_Var_Pop_FieldsResolvers<ContextType>;
  swapTransactions_var_samp_fields?: SwapTransactions_Var_Samp_FieldsResolvers<ContextType>;
  swapTransactions_variance_fields?: SwapTransactions_Variance_FieldsResolvers<ContextType>;
  timestamptz?: GraphQLScalarType;
  tournaments?: TournamentsResolvers<ContextType>;
  tournaments_aggregate?: Tournaments_AggregateResolvers<ContextType>;
  tournaments_aggregate_fields?: Tournaments_Aggregate_FieldsResolvers<ContextType>;
  tournaments_avg_fields?: Tournaments_Avg_FieldsResolvers<ContextType>;
  tournaments_max_fields?: Tournaments_Max_FieldsResolvers<ContextType>;
  tournaments_min_fields?: Tournaments_Min_FieldsResolvers<ContextType>;
  tournaments_mutation_response?: Tournaments_Mutation_ResponseResolvers<ContextType>;
  tournaments_stddev_fields?: Tournaments_Stddev_FieldsResolvers<ContextType>;
  tournaments_stddev_pop_fields?: Tournaments_Stddev_Pop_FieldsResolvers<ContextType>;
  tournaments_stddev_samp_fields?: Tournaments_Stddev_Samp_FieldsResolvers<ContextType>;
  tournaments_sum_fields?: Tournaments_Sum_FieldsResolvers<ContextType>;
  tournaments_var_pop_fields?: Tournaments_Var_Pop_FieldsResolvers<ContextType>;
  tournaments_var_samp_fields?: Tournaments_Var_Samp_FieldsResolvers<ContextType>;
  tournaments_variance_fields?: Tournaments_Variance_FieldsResolvers<ContextType>;
  transactionStatistics?: TransactionStatisticsResolvers<ContextType>;
  transactionStatistics_aggregate?: TransactionStatistics_AggregateResolvers<ContextType>;
  transactionStatistics_aggregate_fields?: TransactionStatistics_Aggregate_FieldsResolvers<ContextType>;
  transactionStatistics_avg_fields?: TransactionStatistics_Avg_FieldsResolvers<ContextType>;
  transactionStatistics_max_fields?: TransactionStatistics_Max_FieldsResolvers<ContextType>;
  transactionStatistics_min_fields?: TransactionStatistics_Min_FieldsResolvers<ContextType>;
  transactionStatistics_mutation_response?: TransactionStatistics_Mutation_ResponseResolvers<ContextType>;
  transactionStatistics_stddev_fields?: TransactionStatistics_Stddev_FieldsResolvers<ContextType>;
  transactionStatistics_stddev_pop_fields?: TransactionStatistics_Stddev_Pop_FieldsResolvers<ContextType>;
  transactionStatistics_stddev_samp_fields?: TransactionStatistics_Stddev_Samp_FieldsResolvers<ContextType>;
  transactionStatistics_sum_fields?: TransactionStatistics_Sum_FieldsResolvers<ContextType>;
  transactionStatistics_var_pop_fields?: TransactionStatistics_Var_Pop_FieldsResolvers<ContextType>;
  transactionStatistics_var_samp_fields?: TransactionStatistics_Var_Samp_FieldsResolvers<ContextType>;
  transactionStatistics_variance_fields?: TransactionStatistics_Variance_FieldsResolvers<ContextType>;
  transactions?: TransactionsResolvers<ContextType>;
  transactions_aggregate?: Transactions_AggregateResolvers<ContextType>;
  transactions_aggregate_fields?: Transactions_Aggregate_FieldsResolvers<ContextType>;
  transactions_avg_fields?: Transactions_Avg_FieldsResolvers<ContextType>;
  transactions_max_fields?: Transactions_Max_FieldsResolvers<ContextType>;
  transactions_min_fields?: Transactions_Min_FieldsResolvers<ContextType>;
  transactions_mutation_response?: Transactions_Mutation_ResponseResolvers<ContextType>;
  transactions_stddev_fields?: Transactions_Stddev_FieldsResolvers<ContextType>;
  transactions_stddev_pop_fields?: Transactions_Stddev_Pop_FieldsResolvers<ContextType>;
  transactions_stddev_samp_fields?: Transactions_Stddev_Samp_FieldsResolvers<ContextType>;
  transactions_sum_fields?: Transactions_Sum_FieldsResolvers<ContextType>;
  transactions_var_pop_fields?: Transactions_Var_Pop_FieldsResolvers<ContextType>;
  transactions_var_samp_fields?: Transactions_Var_Samp_FieldsResolvers<ContextType>;
  transactions_variance_fields?: Transactions_Variance_FieldsResolvers<ContextType>;
  updateAvailableAvatarNFTs?: UpdateAvailableAvatarNfTsResolvers<ContextType>;
  userErc721?: UserErc721Resolvers<ContextType>;
  userErc721_aggregate?: UserErc721_AggregateResolvers<ContextType>;
  userErc721_aggregate_fields?: UserErc721_Aggregate_FieldsResolvers<ContextType>;
  userErc721_avg_fields?: UserErc721_Avg_FieldsResolvers<ContextType>;
  userErc721_max_fields?: UserErc721_Max_FieldsResolvers<ContextType>;
  userErc721_min_fields?: UserErc721_Min_FieldsResolvers<ContextType>;
  userErc721_mutation_response?: UserErc721_Mutation_ResponseResolvers<ContextType>;
  userErc721_stddev_fields?: UserErc721_Stddev_FieldsResolvers<ContextType>;
  userErc721_stddev_pop_fields?: UserErc721_Stddev_Pop_FieldsResolvers<ContextType>;
  userErc721_stddev_samp_fields?: UserErc721_Stddev_Samp_FieldsResolvers<ContextType>;
  userErc721_sum_fields?: UserErc721_Sum_FieldsResolvers<ContextType>;
  userErc721_var_pop_fields?: UserErc721_Var_Pop_FieldsResolvers<ContextType>;
  userErc721_var_samp_fields?: UserErc721_Var_Samp_FieldsResolvers<ContextType>;
  userErc721_variance_fields?: UserErc721_Variance_FieldsResolvers<ContextType>;
  userJobs?: UserJobsResolvers<ContextType>;
  userJobs_aggregate?: UserJobs_AggregateResolvers<ContextType>;
  userJobs_aggregate_fields?: UserJobs_Aggregate_FieldsResolvers<ContextType>;
  userJobs_avg_fields?: UserJobs_Avg_FieldsResolvers<ContextType>;
  userJobs_max_fields?: UserJobs_Max_FieldsResolvers<ContextType>;
  userJobs_min_fields?: UserJobs_Min_FieldsResolvers<ContextType>;
  userJobs_mutation_response?: UserJobs_Mutation_ResponseResolvers<ContextType>;
  userJobs_stddev_fields?: UserJobs_Stddev_FieldsResolvers<ContextType>;
  userJobs_stddev_pop_fields?: UserJobs_Stddev_Pop_FieldsResolvers<ContextType>;
  userJobs_stddev_samp_fields?: UserJobs_Stddev_Samp_FieldsResolvers<ContextType>;
  userJobs_sum_fields?: UserJobs_Sum_FieldsResolvers<ContextType>;
  userJobs_var_pop_fields?: UserJobs_Var_Pop_FieldsResolvers<ContextType>;
  userJobs_var_samp_fields?: UserJobs_Var_Samp_FieldsResolvers<ContextType>;
  userJobs_variance_fields?: UserJobs_Variance_FieldsResolvers<ContextType>;
  user_erc721_block?: User_Erc721_BlockResolvers<ContextType>;
  user_erc721_block_aggregate?: User_Erc721_Block_AggregateResolvers<ContextType>;
  user_erc721_block_aggregate_fields?: User_Erc721_Block_Aggregate_FieldsResolvers<ContextType>;
  user_erc721_block_avg_fields?: User_Erc721_Block_Avg_FieldsResolvers<ContextType>;
  user_erc721_block_max_fields?: User_Erc721_Block_Max_FieldsResolvers<ContextType>;
  user_erc721_block_min_fields?: User_Erc721_Block_Min_FieldsResolvers<ContextType>;
  user_erc721_block_mutation_response?: User_Erc721_Block_Mutation_ResponseResolvers<ContextType>;
  user_erc721_block_stddev_fields?: User_Erc721_Block_Stddev_FieldsResolvers<ContextType>;
  user_erc721_block_stddev_pop_fields?: User_Erc721_Block_Stddev_Pop_FieldsResolvers<ContextType>;
  user_erc721_block_stddev_samp_fields?: User_Erc721_Block_Stddev_Samp_FieldsResolvers<ContextType>;
  user_erc721_block_sum_fields?: User_Erc721_Block_Sum_FieldsResolvers<ContextType>;
  user_erc721_block_var_pop_fields?: User_Erc721_Block_Var_Pop_FieldsResolvers<ContextType>;
  user_erc721_block_var_samp_fields?: User_Erc721_Block_Var_Samp_FieldsResolvers<ContextType>;
  user_erc721_block_variance_fields?: User_Erc721_Block_Variance_FieldsResolvers<ContextType>;
  user_gpanel?: User_GpanelResolvers<ContextType>;
  user_gpanel_aggregate?: User_Gpanel_AggregateResolvers<ContextType>;
  user_gpanel_aggregate_fields?: User_Gpanel_Aggregate_FieldsResolvers<ContextType>;
  user_gpanel_avg_fields?: User_Gpanel_Avg_FieldsResolvers<ContextType>;
  user_gpanel_max_fields?: User_Gpanel_Max_FieldsResolvers<ContextType>;
  user_gpanel_min_fields?: User_Gpanel_Min_FieldsResolvers<ContextType>;
  user_gpanel_mutation_response?: User_Gpanel_Mutation_ResponseResolvers<ContextType>;
  user_gpanel_stddev_fields?: User_Gpanel_Stddev_FieldsResolvers<ContextType>;
  user_gpanel_stddev_pop_fields?: User_Gpanel_Stddev_Pop_FieldsResolvers<ContextType>;
  user_gpanel_stddev_samp_fields?: User_Gpanel_Stddev_Samp_FieldsResolvers<ContextType>;
  user_gpanel_sum_fields?: User_Gpanel_Sum_FieldsResolvers<ContextType>;
  user_gpanel_var_pop_fields?: User_Gpanel_Var_Pop_FieldsResolvers<ContextType>;
  user_gpanel_var_samp_fields?: User_Gpanel_Var_Samp_FieldsResolvers<ContextType>;
  user_gpanel_variance_fields?: User_Gpanel_Variance_FieldsResolvers<ContextType>;
  user_lock?: User_LockResolvers<ContextType>;
  user_lock_aggregate?: User_Lock_AggregateResolvers<ContextType>;
  user_lock_aggregate_fields?: User_Lock_Aggregate_FieldsResolvers<ContextType>;
  user_lock_avg_fields?: User_Lock_Avg_FieldsResolvers<ContextType>;
  user_lock_max_fields?: User_Lock_Max_FieldsResolvers<ContextType>;
  user_lock_min_fields?: User_Lock_Min_FieldsResolvers<ContextType>;
  user_lock_mutation_response?: User_Lock_Mutation_ResponseResolvers<ContextType>;
  user_lock_stddev_fields?: User_Lock_Stddev_FieldsResolvers<ContextType>;
  user_lock_stddev_pop_fields?: User_Lock_Stddev_Pop_FieldsResolvers<ContextType>;
  user_lock_stddev_samp_fields?: User_Lock_Stddev_Samp_FieldsResolvers<ContextType>;
  user_lock_sum_fields?: User_Lock_Sum_FieldsResolvers<ContextType>;
  user_lock_var_pop_fields?: User_Lock_Var_Pop_FieldsResolvers<ContextType>;
  user_lock_var_samp_fields?: User_Lock_Var_Samp_FieldsResolvers<ContextType>;
  user_lock_variance_fields?: User_Lock_Variance_FieldsResolvers<ContextType>;
  user_staking?: User_StakingResolvers<ContextType>;
  user_staking_aggregate?: User_Staking_AggregateResolvers<ContextType>;
  user_staking_aggregate_fields?: User_Staking_Aggregate_FieldsResolvers<ContextType>;
  user_staking_avg_fields?: User_Staking_Avg_FieldsResolvers<ContextType>;
  user_staking_max_fields?: User_Staking_Max_FieldsResolvers<ContextType>;
  user_staking_min_fields?: User_Staking_Min_FieldsResolvers<ContextType>;
  user_staking_mutation_response?: User_Staking_Mutation_ResponseResolvers<ContextType>;
  user_staking_stddev_fields?: User_Staking_Stddev_FieldsResolvers<ContextType>;
  user_staking_stddev_pop_fields?: User_Staking_Stddev_Pop_FieldsResolvers<ContextType>;
  user_staking_stddev_samp_fields?: User_Staking_Stddev_Samp_FieldsResolvers<ContextType>;
  user_staking_sum_fields?: User_Staking_Sum_FieldsResolvers<ContextType>;
  user_staking_transactions?: User_Staking_TransactionsResolvers<ContextType>;
  user_staking_transactions_aggregate?: User_Staking_Transactions_AggregateResolvers<ContextType>;
  user_staking_transactions_aggregate_fields?: User_Staking_Transactions_Aggregate_FieldsResolvers<ContextType>;
  user_staking_transactions_avg_fields?: User_Staking_Transactions_Avg_FieldsResolvers<ContextType>;
  user_staking_transactions_max_fields?: User_Staking_Transactions_Max_FieldsResolvers<ContextType>;
  user_staking_transactions_min_fields?: User_Staking_Transactions_Min_FieldsResolvers<ContextType>;
  user_staking_transactions_mutation_response?: User_Staking_Transactions_Mutation_ResponseResolvers<ContextType>;
  user_staking_transactions_stddev_fields?: User_Staking_Transactions_Stddev_FieldsResolvers<ContextType>;
  user_staking_transactions_stddev_pop_fields?: User_Staking_Transactions_Stddev_Pop_FieldsResolvers<ContextType>;
  user_staking_transactions_stddev_samp_fields?: User_Staking_Transactions_Stddev_Samp_FieldsResolvers<ContextType>;
  user_staking_transactions_sum_fields?: User_Staking_Transactions_Sum_FieldsResolvers<ContextType>;
  user_staking_transactions_var_pop_fields?: User_Staking_Transactions_Var_Pop_FieldsResolvers<ContextType>;
  user_staking_transactions_var_samp_fields?: User_Staking_Transactions_Var_Samp_FieldsResolvers<ContextType>;
  user_staking_transactions_variance_fields?: User_Staking_Transactions_Variance_FieldsResolvers<ContextType>;
  user_staking_var_pop_fields?: User_Staking_Var_Pop_FieldsResolvers<ContextType>;
  user_staking_var_samp_fields?: User_Staking_Var_Samp_FieldsResolvers<ContextType>;
  user_staking_variance_fields?: User_Staking_Variance_FieldsResolvers<ContextType>;
  users?: UsersResolvers<ContextType>;
  users_aggregate?: Users_AggregateResolvers<ContextType>;
  users_aggregate_fields?: Users_Aggregate_FieldsResolvers<ContextType>;
  users_avg_fields?: Users_Avg_FieldsResolvers<ContextType>;
  users_login?: Users_LoginResolvers<ContextType>;
  users_login_aggregate?: Users_Login_AggregateResolvers<ContextType>;
  users_login_aggregate_fields?: Users_Login_Aggregate_FieldsResolvers<ContextType>;
  users_login_max_fields?: Users_Login_Max_FieldsResolvers<ContextType>;
  users_login_min_fields?: Users_Login_Min_FieldsResolvers<ContextType>;
  users_max_fields?: Users_Max_FieldsResolvers<ContextType>;
  users_min_fields?: Users_Min_FieldsResolvers<ContextType>;
  users_mutation_response?: Users_Mutation_ResponseResolvers<ContextType>;
  users_old?: Users_OldResolvers<ContextType>;
  users_old_aggregate?: Users_Old_AggregateResolvers<ContextType>;
  users_old_aggregate_fields?: Users_Old_Aggregate_FieldsResolvers<ContextType>;
  users_old_avg_fields?: Users_Old_Avg_FieldsResolvers<ContextType>;
  users_old_max_fields?: Users_Old_Max_FieldsResolvers<ContextType>;
  users_old_min_fields?: Users_Old_Min_FieldsResolvers<ContextType>;
  users_old_mutation_response?: Users_Old_Mutation_ResponseResolvers<ContextType>;
  users_old_stddev_fields?: Users_Old_Stddev_FieldsResolvers<ContextType>;
  users_old_stddev_pop_fields?: Users_Old_Stddev_Pop_FieldsResolvers<ContextType>;
  users_old_stddev_samp_fields?: Users_Old_Stddev_Samp_FieldsResolvers<ContextType>;
  users_old_sum_fields?: Users_Old_Sum_FieldsResolvers<ContextType>;
  users_old_var_pop_fields?: Users_Old_Var_Pop_FieldsResolvers<ContextType>;
  users_old_var_samp_fields?: Users_Old_Var_Samp_FieldsResolvers<ContextType>;
  users_old_variance_fields?: Users_Old_Variance_FieldsResolvers<ContextType>;
  users_stddev_fields?: Users_Stddev_FieldsResolvers<ContextType>;
  users_stddev_pop_fields?: Users_Stddev_Pop_FieldsResolvers<ContextType>;
  users_stddev_samp_fields?: Users_Stddev_Samp_FieldsResolvers<ContextType>;
  users_sum_fields?: Users_Sum_FieldsResolvers<ContextType>;
  users_var_pop_fields?: Users_Var_Pop_FieldsResolvers<ContextType>;
  users_var_samp_fields?: Users_Var_Samp_FieldsResolvers<ContextType>;
  users_variance_fields?: Users_Variance_FieldsResolvers<ContextType>;
  uuid?: GraphQLScalarType;
};

export type DirectiveResolvers<ContextType = any> = {
  cached?: CachedDirectiveResolver<any, any, ContextType>;
  client?: ClientDirectiveResolver<any, any, ContextType>;
};
