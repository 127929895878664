import { initReactI18next } from 'react-i18next';
import en from 'assets/i18n/en.json';
import es from 'assets/i18n/es.json';
import ja from 'assets/i18n/ja.json';
import kr from 'assets/i18n/kr.json';
import ru from 'assets/i18n/ru.json';
import vi from 'assets/i18n/vi.json';
import dayjs from 'dayjs';
import i18n from 'i18next';
import {
  getLocalStorageValue,
  LocalStorageKeys,
  setLocalStorageValue,
} from './helpers';

export const resources = {
  English: {
    translation: en,
  },
  Korean: {
    translation: kr,
  },
  Spanish: {
    translation: es,
  },
  Vietnamese: {
    translation: vi,
  },
  Japanese: {
    translation: ja,
  },
  Russian: {
    translation: ru,
  },
} as const;

export type TranslationKeys =
  keyof (typeof resources)['English']['translation'];

export enum ShortLangNames {
  // noinspection JSUnusedGlobalSymbols
  en = 'English',
  ko = 'Korean',
  sp = 'Spanish',
  vi = 'Vietnamese',
  ja = 'Japanese',
  ru = 'Russian',
}

i18n.use(initReactI18next).init({
  resources,
  lng: getLocalStorageValue(LocalStorageKeys.LANGUAGE) ?? undefined,
  fallbackLng: 'English',
  interpolation: {
    escapeValue: false,
  },
});

i18n.on('languageChanged', function (lngLocale) {
  dayjs.locale(lngLocale);
  setLocalStorageValue(LocalStorageKeys.LANGUAGE, lngLocale);
});

// noinspection JSUnusedGlobalSymbols
export default i18n;
