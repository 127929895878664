import { useMemo, useState, useEffect, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isString, isEmpty, remove } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  BalancesSection as DefBalancesSection,
  BalancesSectionLabel,
  BalancesItem,
  BalancesItemIcon,
  BalancesItemValue,
} from 'components/styled/balances';
import {
  currenciesData,
  Currencies,
  isInpTokenSymbol,
} from '../../utils/consts/currencies';
import { getFormattedNumber } from '../../utils/helpers/strings';
import { useGpanelBalances } from '../../hooks';
import { useBlockchainBalances } from '../../providers';
import { BackgroundedWrapper } from '../styled';
import { Spinner } from '../spinners/Spinner';

const Balances = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { balances: gpanelBalances } = useGpanelBalances();
  const [loading, setLoading] = useState(true);
  const {
    loading: loadingBlockchainBalances,
    error,
    value: blockchainBalances,
  } = useBlockchainBalances();

  const [blockchainInpCoin, blockchainStablecoins] = useMemo(() => {
    if (!blockchainBalances) return [undefined, []];
    const items = [...blockchainBalances];
    const [inpCoin] = remove(items, ({ symbol }) => isInpTokenSymbol(symbol));
    return [inpCoin, items];
  }, [blockchainBalances]);

  useEffect(() => {
    if (!loading) return;
    !loadingBlockchainBalances && setLoading(false);
  }, [loadingBlockchainBalances, loading]);

  return (
    <Wrapper {...props}>
      {(isString(gpanelBalances?.usd) || isString(gpanelBalances?.inp)) && (
        <BalancesSection $grid>
          <BalancesSectionLabel>
            {t('CASHIER_GENERAL_INFO__inpokerBalance')}
          </BalancesSectionLabel>
          {!!gpanelBalances!.usd && (
            <BalancesItem>
              <BalancesItemIcon
                src={currenciesData[Currencies.USD].icon}
                alt={Currencies.USD}
              />
              <BalancesItemValue>
                {getAmount(gpanelBalances!.usd, 4)} {Currencies.USD}
              </BalancesItemValue>
            </BalancesItem>
          )}
          {!!gpanelBalances!.inp && (
            <BalancesItem $inp>
              <BalancesItemIcon
                src={currenciesData[Currencies.INP].icon}
                alt={Currencies.INP}
              />
              <BalancesItemValue>
                {getAmount(gpanelBalances!.inp, 3)} {Currencies.INP}
              </BalancesItemValue>
            </BalancesItem>
          )}
        </BalancesSection>
      )}
      {!isEmpty(blockchainBalances) && (
        <BalancesSection $grid>
          <BalancesSectionLabel>
            {t('CASHIER_GENERAL_INFO__walletBalance')}
          </BalancesSectionLabel>
          {(() => {
            if (loading) return <Spinner />;

            if (error) return <>Couldn't load</>;

            return (
              <>
                {blockchainStablecoins.map(({ id, symbol, iconUrl, amount }) => (
                  <BalancesItem key={id}>
                    {!!iconUrl && <BalancesItemIcon src={iconUrl} alt={symbol} />}
                    <BalancesItemValue>
                      {getAmount(amount, 4)} {symbol.toUpperCase()}
                    </BalancesItemValue>
                  </BalancesItem>
                ))}
                {!!blockchainInpCoin && (
                  <BalancesItem key={blockchainInpCoin.id} $inp>
                    <BalancesItemIcon
                      src={blockchainInpCoin.iconUrl}
                      alt={blockchainInpCoin.symbol}
                    />
                    <BalancesItemValue>
                      {getAmount(blockchainInpCoin.amount, 4)}{' '}
                      {blockchainInpCoin.symbol.toUpperCase()}
                    </BalancesItemValue>
                  </BalancesItem>
                )}
              </>
            );
          })()}
        </BalancesSection>
      )}
    </Wrapper>
  );
};

const getAmount = (amount: StringOrNumber, fraction: number) =>
  getFormattedNumber(amount, {
    maximumFractionDigits: fraction,
  });

const Wrapper = styled(BackgroundedWrapper)`
  padding: 0.15rem 0 0.8rem;
`;

const BalancesSection = styled(DefBalancesSection)`
  & + & {
    padding-top: 0.3rem;
  }
`;

export { Balances };
