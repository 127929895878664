import { useMemo, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as DefUser } from 'assets/svgs/icons/avatar-placeholder.svg';
import { useUserAvatar } from '../hooks';
import { useServerAuth } from '../providers';
import { Spinner } from './spinners/Spinner';

export type AvatarProps = {
  size?: Size;
} & HTMLAttributes<HTMLDivElement>;

const Avatar = ({ size = 'normal', ...props }: AvatarProps) => {
  const { authorized } = useServerAuth();
  const { loading, avatar } = useUserAvatar();
  const url = useMemo(() => avatar?.url, [avatar]);

  if (!authorized) return null;

  return (
    <Wrapper $size={size} {...props}>
      {(() => {
        if (!url && loading) {
          return <Spinner />;
        }

        if (!url) {
          return <UserIcon />;
        }

        return <AvatarImage src={url} alt={'Avatar'} />;
      })()}
    </Wrapper>
  );
};

type Size = 'normal' | 'big';

const sizes: {
  [key in Size]: ReturnType<typeof css>;
} = {
  normal: css`
    width: 45px;
    height: 45px;

    ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
      width: 25px;
      height: 25px;
    }

    ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
      width: 20px;
      height: 20px;
    }
  `,
  big: css`
    width: 127px;
    height: 127px;

    ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
      width: 80px;
      height: 80px;
    }

    ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
      width: 60px;
      height: 60px;
    }
  `,
};

const Wrapper = styled.div<{
  $size: Size;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $size }) => sizes[$size]};
`;

const UserIcon = styled(DefUser)`
  width: 100%;
  height: 100%;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export { Avatar };
