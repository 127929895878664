import {
  useReducer,
  useCallback,
  useMemo,
  PropsWithChildren,
  Context,
} from 'react';
import styled, { css } from 'styled-components';
import { isObject } from 'lodash-es';
import ReactModal from 'react-modal';
import { useUpdateEffect, usePrevious } from 'react-use';
import colorFn from 'color';
import { useAccount } from 'wagmi';
import {
  initialModalState,
  getModalsReducer,
  ModalState,
  ModalsContext,
  Modals,
} from '../utils/modals';
import { resetButtonStyles } from '../styles/helpers';

export type ModalsSkeletonProps<M extends string> = PropsWithChildren<{
  modals: Modals<M>;
  ContextProvider: Context<ModalsContext<M>>['Provider'];
}>;

const ModalsSkeleton = <M extends string>({
  modals,
  ContextProvider,
  children,
}: ModalsSkeletonProps<M>) => {
  const { address } = useAccount();
  const prevAddress = usePrevious(address);
  const [state, dispatch] = useReducer(
    getModalsReducer(modals),
    initialModalState
  );

  const { modalOpened, Content, data } = state;

  const closeModal = useCallback(() => dispatch(['closeModal']), [dispatch]);

  useUpdateEffect(() => {
    if (!address || (prevAddress && prevAddress !== address)) {
      closeModal();
    }
  }, [address, prevAddress]);

  const contextValue = useMemo(
    () => ({ ...state, closeModal, dispatch }),
    [state, closeModal]
  );

  const { withCloseButton = true } = state.wrapperProps;

  return (
    <ContextProvider value={contextValue}>
      {children}
      <ReactModal
        className={'center-modal'}
        overlayClassName={'center-modal-overlay'}
        isOpen={modalOpened}
        onRequestClose={() => {
          if (!state.wrapperProps.closingDisabled) {
            // @ts-ignore
            data?.onClose?.();
            closeModal();
          }
        }}
      >
        <ContentWrapper {...state.wrapperProps}>
          {withCloseButton && (
            <CloseButton
              disabled={state.wrapperProps.closingDisabled}
              onClick={closeModal}
            >
              X
            </CloseButton>
          )}
          <Content {...(isObject(data) ? data : {})} />
        </ContentWrapper>
      </ReactModal>
    </ContextProvider>
  );
};

ReactModal.setAppElement('body');

const commonPaddings = css`
  padding: 50px 40px 40px;
  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    padding: 50px 30px 30px;
  }
`;

const getStyles: () => {
  [key in NonNullable<ModalState['wrapperProps']['size']>]: ReturnType<
    typeof css
  >;
} = () => ({
  normal: css`
    width: 850px;
    ${commonPaddings};
  `,
  narrow: css`
    width: 440px;
    ${commonPaddings};
  `,
  card: css`
    width: 460px;
    padding: 40px 24px 17px 24px;
    ${({ theme }) => theme.getDownMedia('xs')} {
      padding: 40px 16px 17px 16px;
    }
  `,
});

const ContentWrapper = styled.div<ModalState['wrapperProps']>`
  display: flex;
  flex-direction: column;
  ${({ size = 'normal' }) => getStyles()[size]};
  max-width: calc(100% - 20px);
  border: 1px solid ${({ theme }) => theme.getColor('mineShaft')};
  border-radius: 5px;
  ${({ background }) =>
    background === 'normal'
      ? css`
          background: ${({ theme }) =>
            theme.getColor('gradientModalBackground')}; ;
        `
      : css`
          background: ${({ theme }) => theme.getColor('shark')}; ;
        `};
  outline: none;
  position: relative;
`;

const CloseButton = styled.button`
  ${resetButtonStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.getColor('brightGray')};
  position: absolute;
  top: 23px;
  right: 17px;
  transition: background-color 0.15s ease-in;
  color: #fff;

  &:disabled {
    background-color: ${({ theme }) =>
      colorFn(theme.getColor('brightGray')).lighten(0.2).hex()};
  }

  &:hover:not([disabled]) {
    background-color: ${({ theme }) =>
      colorFn(theme.getColor('brightGray')).darken(0.2).hex()};
  }
`;

export { ModalsSkeleton };
