import { DefaultTheme } from 'styled-components';
import { isNumber } from 'lodash-es';
import { simpleColors, gradientColors, rgbaColors } from './colors';
import { desktopDefaultSize } from './consts';

const colors = {
  ...simpleColors,
  ...gradientColors,
  ...rgbaColors,
};

export const breakpoints = {
  xs: '600px',
  sm: '960px',
  md: '1280px',
  xl: '1920px',
};

export const themeMain: DefaultTheme = {
  colors,
  breakpoints,
  pxToRem: (px: number) => {
    const rem = px / desktopDefaultSize;
    return `${rem.toFixed(4)}rem`;
  },
  getColor: (colorName) => colors[colorName],
  getDownMedia: function (breakpoint) {
    return `@media screen and (max-width: ${
      isNumber(breakpoint)
        ? `${breakpoint - 1}px`
        : `${parseInt(themeMain.breakpoints[breakpoint]) - 1}px`
    })`;
  },
  getUpMedia: function (breakpoint) {
    return `@media screen and (min-width: ${
      isNumber(breakpoint)
        ? `${breakpoint}px`
        : themeMain.breakpoints[breakpoint]
    })`;
  },
};
