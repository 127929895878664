import { useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isEmpty, uniq } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import OnramperWidget from '@onramper/widget';
import { getEnvVariable } from '../utils/helpers/env-helpers';
import { useOnramper } from '../hooks';
import { Text } from './Text';
import { Spinner } from './spinners/Spinner';

export type OnramperProps = HTMLAttributes<HTMLDivElement>;

const Onramper = (props: OnramperProps) => {
  const { t } = useTranslation();
  const { loading, value } = useOnramper();

  const address = value?.addresses[0].address;

  const { defaultAddrs, onlyCryptos } = useMemo(() => {
    const stablecoins = value?.stablecoins;

    if (loading || isEmpty(stablecoins) || !address) {
      return {
        defaultAddrs: [],
        onlyCryptos: [],
      };
    }

    const cryptos = uniq(
      stablecoins!.map(({ symbol }) => symbol.toUpperCase())
    ).reduce<string[]>((acc, crypto) => {
      if (crypto === 'BUSD') {
        return [...acc, `${crypto}_BEP20`];
      }
      return [...acc, crypto, `${crypto}_Polygon`];
    }, []);

    return {
      defaultAddrs: cryptos.reduce(
        (acc, symbol) => ({
          ...acc,
          [symbol]: { address },
        }),
        {}
      ),
      onlyCryptos: cryptos,
    };
  }, [loading, value, address]);

  if (loading) {
    return <Spinner />;
  }

  if (isEmpty(defaultAddrs) || isEmpty(onlyCryptos)) {
    return <Error>{t('CASHIER_STAKE_TABS__buyCryptoLoadError')}</Error>;
  }

  return (
    <Wrapper {...props}>
      <NoticeText>{t('CASHIER_STAKE_TABS__withdrawNotice1')}</NoticeText>
      <NoticeText>
        {t('CASHIER_STAKE_TABS__withdrawNotice2')}:
        <br />
        {address}
      </NoticeText>
      <WidgetWrapper>
        <OnramperWidget
          API_KEY={apiKey}
          defaultAddrs={defaultAddrs}
          defaultCrypto={'USDT'}
          filters={{
            onlyCryptos,
          }}
          supportSell
          isAddressEditable={false}
        />
      </WidgetWrapper>
    </Wrapper>
  );
};

const apiKey = getEnvVariable('ONRAMPER_KEY');

const Error = styled(Text)`
  text-align: center;
`;

const Wrapper = styled.div`
  max-width: 478px;
  margin: auto;
`;

const NoticeText = styled(Text)`
  & + & {
    margin-top: 10px;
  }
  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const WidgetWrapper = styled.div`
  width: 100%;
  height: 600px;
  padding: 8px 0;
`;

export { Onramper };
