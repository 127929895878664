import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { useServerAuth } from '../providers';
import { useGpanel } from '../providers/GpanelProvider';
import { resetListStyles } from '../styles/helpers';
import { Text } from './Text';
import { ETHAccountButton } from './buttons';
import { Button as DefButton } from './buttons';
import { Spinner } from './spinners/Spinner';

export type PermissionsBlockProps = {
  small?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const PermissionsBlock = ({
  small = false,
  ...props
}: PermissionsBlockProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { fullAccess, authOnServer } = useServerAuth();
  const { loading, user: inpokerUser, authInGpanel } = useGpanel();

  if (loading) {
    return (
      <PermissionsWrapper $small={small} {...props}>
        <Spinner />
      </PermissionsWrapper>
    );
  }

  return (
    <PermissionsWrapper $small={small} {...props}>
      <Title component={'h2'}>{t('PERMISSIONS_BLOCK__authTitle')}</Title>
      <ButtonsList>
        {!address && (
          <ButtonsListItem>
            <ETHAccountButton icon={undefined}>
              {t('PERMISSIONS_BLOCK__connectWalletButton')}
            </ETHAccountButton>
          </ButtonsListItem>
        )}
        {!fullAccess && (
          <ButtonsListItem>
            <Button disabled={!address} onClick={authOnServer}>
              {t('PERMISSIONS_BLOCK__signWalletButton')}
            </Button>
          </ButtonsListItem>
        )}
        {!loading && !inpokerUser && (
          <ButtonsListItem>
            <Button color={'secondary'} onClick={authInGpanel}>
              {t('PERMISSIONS_BLOCK__connectInpokerButton')}
            </Button>
          </ButtonsListItem>
        )}
      </ButtonsList>
    </PermissionsWrapper>
  );
};

const Title = styled(Text)`
  margin-bottom: 20px;
  text-align: center;
`;

const PermissionsWrapper = styled.div<{
  $small: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ $small }) =>
    $small
      ? css`
          ${Title} {
            font-size: 1.3rem;
          }
        `
      : css`
          margin-top: 101px;
        `}
`;

const ButtonsList = styled.ul`
  ${resetListStyles};
  display: flex;
`;

const ButtonsListItem = styled.li`
  margin: 0 10px;
`;

const Button = styled(DefButton)``;

export { PermissionsBlock };
