import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Text as DefText, TextProps } from '../Text';

export type CardCollectionProps = PropsWithChildren<TextProps>;

const CardCollection = (props: CardCollectionProps) => (
  // @ts-ignore
  <Text variant={'small'} color={'osloGray'} {...props} />
);

const Text = styled(DefText)`
  text-align: center;
`;

export { CardCollection };
