import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SiteLinks } from '../../utils/consts/site-data';
import { useInnerModals } from '../../providers';
import { textGradient } from '../../styles/helpers';
import { Text as DefText } from '../Text';
import { Button as DefButton } from '../buttons';

export type UnstakeModalProps = {
  onConfirmClick: () => Promise<void>;
  onClose: () => void;
};

const UnstakeModal = ({ onConfirmClick, onClose }: UnstakeModalProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { dispatch } = useInnerModals();

  return (
    <>
      <Text>{t('UNSTAKE_MODAL__text')}</Text>
      <Buttons>
        <Button
          size={'small'}
          loading={loading}
          onClick={() => {
            setLoading(true);

            onConfirmClick()
              .then(() => {
                dispatch(['closeModal']);
              })
              .catch((e) => {
                setLoading(false);
                throw e;
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          {t('UNSTAKE_MODAL__confirmButton')}
        </Button>
        <Button
          size={'small'}
          color={'secondary'}
          onClick={() => {
            onClose();
            dispatch(['closeModal']);
            window.open(SiteLinks.LEARN_MORE, '_blank');
          }}
        >
          {t('UNSTAKE_MODAL__learnMoreButton')}
        </Button>
      </Buttons>
    </>
  );
};

const Text = styled(DefText)`
  font-size: 36px;
  line-height: ${46 / 36};
  font-weight: 700;
  text-transform: uppercase;
  ${textGradient('99.46deg, #FF2AB7 1.36%, #D7FF37 94.58%')};

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    font-size: 30px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    font-size: 20px;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  align-self: center;

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    width: 100%;
    flex-direction: column;
  }
`;

const Button = styled(DefButton)`
  margin: 0 10px;
  padding: 18px 10px;
  width: 150px;

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    width: 100%;
    margin: 0;

    & + & {
      margin-top: 10px;
    }
  }
`;

export { UnstakeModal };
