import { createContext, useContext, PropsWithChildren } from 'react';
import { compact, omit, toString } from 'lodash-es';
import {
  useAsyncRetry,
  useInterval,
  usePrevious,
  useUpdateEffect,
} from 'react-use';
import { useAccount } from 'wagmi';
import { Currencies, currenciesData } from '../utils/consts/currencies';
import { useGetTokenBalance } from '../hooks';
import { useBlockchain } from './BlockchainProvider';

const BlockchainBalancesProvider = (props: PropsWithChildren) => {
  const blockchain = useBlockchain();
  const { address } = useAccount();
  const prevAddress = usePrevious(address);

  const getTokenBalance = useGetTokenBalance();

  const state = useAsyncRetry<Balances>(() => {
    if (!blockchain) return Promise.resolve(null);

    return Promise.all(
      compact([
        !!blockchain.inpAddress &&
          getTokenBalance({ tokenAddress: blockchain.inpAddress }).then(
            (amount) => ({
              id: blockchain.inpAddress!,
              contract: blockchain.inpAddress!,
              symbol: Currencies.INP.toLowerCase(),
              iconUrl: currenciesData[Currencies.INP].icon,
              depositActive: true,
              withdrawActive: true,
              amount,
              units: 'ether',
            })
          ),
        ...blockchain.stablecoins.map(
          ({ contract, id, iconUrl, symbol, units, ...rest }) =>
            getTokenBalance({ tokenAddress: contract, units }).then(
              (amount) => ({
                ...omit(rest, ['__typename']),
                id: toString(id),
                contract,
                symbol: symbol.toLowerCase(),
                iconUrl: iconUrl ?? '',
                amount,
                units,
              })
            )
        ),
      ])
    );
  }, [blockchain]);

  useUpdateEffect(() => {
    if (address && prevAddress !== address) {
      state.retry();
    }
  }, [address, prevAddress, state]);

  useInterval(() => {
    if (address) {
      state.retry();
    }
  }, 5000);

  return (
    <BlockchainBalancesContext.Provider
      value={address ? state : defaultState}
      {...props}
    />
  );
};

type Balances = ReadonlyArray<{
  id: string;
  contract: string;
  symbol: string;
  iconUrl: string;
  depositActive: boolean;
  withdrawActive: boolean;
  amount: string;
  units: string;
}> | null;

const helperWrapperAsync = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useAsyncRetry<Balances>(() => Promise.resolve([]));

const defaultState = {
  loading: false,
  error: undefined,
  value: undefined,
  retry: () => undefined,
};

const BlockchainBalancesContext =
  createContext<ReturnType<typeof helperWrapperAsync>>(defaultState);

export const useBlockchainBalances = () =>
  useContext(BlockchainBalancesContext);

export { BlockchainBalancesProvider };
