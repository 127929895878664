import { HTMLAttributes, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { isObject, debounce, isArray } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { TranslationKeys } from '../../utils/i18n';
import { useServerAuth } from '../../providers';
import {
  AgentFindUserDocument,
  AgentFindUserQuery,
  AgentFindUserQueryVariables,
} from '../../apollo/operations';
import { Text as DefText } from '../Text';
import { Select, SelectProps } from '../form-elements';

export type AgentBlockProps = {
  type: Type;
  name: string;
  selectProps: SelectProps;
} & HTMLAttributes<HTMLDivElement>;

const AgentBlock = ({
  type,
  name,
  selectProps: { options, ...selectProps },
  ...props
}: AgentBlockProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const { fullAccess } = useServerAuth();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [extraUsers, setExtraUsers] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { error }, { setValue: setFormikValue }] = useField(name);

  const allOptions = useMemo(
    () => [...(isArray(options) ? options : []), ...extraUsers],
    [options, extraUsers]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onType = useCallback(
    debounce(async (value: string | null) => {
      if (!value || allOptions.find(({ label }) => label === value)) return;

      try {
        setLoadingUsers(true);

        const result = await client.query<
          AgentFindUserQuery,
          AgentFindUserQueryVariables
        >({
          query: AgentFindUserDocument,
          fetchPolicy: 'no-cache',
          variables: {
            name: value,
          },
        });

        const user = result.data.agentFindUser;

        console.log('user', user);
        if (!(user?.gPanelUserId && user?.username)) {
          enqueueSnackbar(t('DEPOSIT_FORM__agentQueryNoUser'), {
            variant: 'warning',
          });
          return;
        }
        setExtraUsers((prevUsers) => [
          { label: user.username!, value: user.gPanelUserId! },
          ...prevUsers,
        ]);
        enqueueSnackbar(t('DEPOSIT_FORM__agentQuerySetUser'), {
          variant: 'success',
        });
      } catch (e) {
        const msg = t('DEPOSIT_FORM__agentQueryError');
        enqueueSnackbar(msg, {
          variant: 'error',
        });
      } finally {
        setLoadingUsers(false);
      }
    }, 500),
    [allOptions]
  );

  const onValueChange = useCallback(
    async (value: string | null) => {
      setFormikValue(value || null);
    },
    [setFormikValue]
  );

  return (
    <Wrapper {...props}>
      <Title>{t(translations[type].title)}</Title>
      <Select
        {...selectProps}
        options={allOptions}
        isLoading={loadingUsers}
        error={!!error}
        whiteBackground
        onInputChange={(newValue) => {
          type === 'agent' && onType(newValue);
        }}
        onChange={(data) => {
          if (isObject(data) && 'value' in data) {
            onValueChange(!!data.value ? data.value : null);
          }
        }}
      />
      <Desc>{t(translations[type][fullAccess ? 'desc' : 'desc2'])}</Desc>
    </Wrapper>
  );
};

type Type = 'agent' | 'user';

const Wrapper = styled.div``;

const Text = styled(DefText)`
  font-size: 14px;
  line-height: 1;
`;

const Title = styled(Text)`
  margin-bottom: 6px;
`;

const Desc = styled(Text)`
  margin-top: 6px;
  color: ${({ theme }) => theme.getColor('osloGray')};
`;

const translations: {
  [key in Type]: {
    [key in 'title' | 'desc' | 'desc2']: TranslationKeys;
  };
} = {
  agent: {
    title: 'DEPOSIT_FORM__agentTitle',
    desc: 'DEPOSIT_FORM__agentDesc',
    desc2: 'DEPOSIT_FORM__agentDesc',
  },
  user: {
    title: 'WITHDRAW_FORM__agentTitle',
    desc: 'WITHDRAW_FORM__agentDesc',
    desc2: 'WITHDRAW_FORM__agentOnlyDesc',
  },
};

export { AgentBlock };
