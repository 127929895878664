import { useMemo, ComponentProps } from 'react';
import styled from 'styled-components';
import { isObject, isString, toNumber } from 'lodash-es';
import { Currencies, currenciesData } from '../../utils/consts/currencies';
import { Text } from '../Text';

export type BalanceInfoBlockProps = {
  title: string;
  currency:
    | Currencies
    | {
        icon?: string;
        symbol: string;
      };
  amount: StringOrNumber;
} & ComponentProps<typeof Wrapper>;

const BalanceInfoBlock = ({
  title,
  currency,
  amount: rawAmount,
  ...props
}: BalanceInfoBlockProps) => {
  const { icon, symbol } = useMemo(() => {
    if (!isObject(currency)) {
      return {
        icon: currenciesData[currency].icon,
        symbol: currency,
      };
    }

    const icon =
      currency.icon ||
      Object.entries(currenciesData).find(
        ([key]) => key.toLowerCase() === currency.symbol.toLowerCase()
      )?.[1].icon;

    return {
      icon,
      symbol: currency.symbol,
    };
  }, [currency]);

  const amount = useMemo(
    () =>
      isString(rawAmount)
        ? rawAmount
        : parseFloat(toNumber(rawAmount).toFixed(10)),
    [rawAmount]
  );

  return (
    <Wrapper {...props}>
      <Label
        component={'h3'}
        variant={'normal'}
        weight={'medium'}
        letterSpacing={'small'}
      >
        {title}
      </Label>
      <Value>
        <ValueIcon src={icon} alt={symbol} />
        <ValueAmount variant={'h3'} letterSpacing={'small'}>
          {amount} {symbol}
        </ValueAmount>
      </Value>
    </Wrapper>
  );
};

const Wrapper = styled.li``;

const Label = styled(Text)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.getColor('osloGray')};
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;
const ValueIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;
const ValueAmount = styled(Text)``;

export { BalanceInfoBlock };
