import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useInnerModals } from '../../providers';
import { resetListStyles } from '../../styles/helpers';
import { Text } from '../Text';
import { Button } from '../buttons';

const ConfirmRevokeNFTsModal = () => {
  const { t } = useTranslation();
  const { data, dispatch } = useInnerModals();

  useLayoutEffect(() => {
    dispatch([
      'setWrapperProps',
      {
        size: 'narrow',
      },
    ]);
  }, [dispatch]);

  return (
    <>
      <Title>{t('CONFIRM_REVOKE_NFTS_MODAL__title')}</Title>
      <ButtonsList>
        <ButtonsListItem>
          <Button
            onClick={() => {
              dispatch(['closeModal']);
            }}
          >
            {t('CONFIRM_REVOKE_NFTS_MODAL__no')}
          </Button>
        </ButtonsListItem>
        <ButtonsListItem>
          <Button
            onClick={async () => {
              // @ts-ignore
              data?.onConfirm();
            }}
          >
            {t('CONFIRM_REVOKE_NFTS_MODAL__yes')}
          </Button>
        </ButtonsListItem>
      </ButtonsList>
    </>
  );
};

const Title = styled((props) => <Text component={'h3'} {...props} />)`
  margin-bottom: 26px;
  text-align: center;
`;

const ButtonsList = styled.ul`
  ${resetListStyles};
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  justify-content: center;
`;

const ButtonsListItem = styled.li``;

export { ConfirmRevokeNFTsModal };
