import styled from 'styled-components';
import { ColorName } from '../../../styles/colors';

export const Divider = styled.div<{
  $color?: ColorName;
}>`
  width: 100%;
  height: 2px;
  background-color: ${({ theme, $color = 'rgbaDividerColor' }) =>
    theme.getColor($color)};
`;
