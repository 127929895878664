import { useState, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  TableWrapper,
  TableLoader,
  Table as DefTable,
  TableRow,
  TableCell,
} from 'components/styled/table';
import dayjs from 'dayjs';
import { NetworkStatus } from '@apollo/client';
import { getFormattedNumber } from '../../utils/helpers/strings';
import { TranslationKeys } from '../../utils/i18n';
import {
  useGpanelPaymentsQuery,
  GpanelPaymentsQuery,
} from '../../apollo/operations';
import { TablePagination } from '../TablePagination';

const DoneTransactionsTable = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const {
    networkStatus,
    error,
    data: { gpanelPaymentsAggregate, gpanelPayments } = {},
  } = useGpanelPaymentsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    pollInterval: 30 * 1000,
    variables: {
      limit,
      offset,
    },
  });

  if (error || !gpanelPayments) return null;

  const total = gpanelPaymentsAggregate?.aggregate?.totalCount ?? 0;

  return (
    <TableWrapper {...props}>
      {networkStatus === NetworkStatus.setVariables && <TableLoader />}
      <Table role="table">
        <TableRow role="rowgroup">
          {Array.from(columns).map(([key, label]) => (
            <TableCell key={key} role="columnheader" $header>
              {t(label)}
            </TableCell>
          ))}
        </TableRow>
        {gpanelPayments.map((data) => (
          <TableRow key={data.id} role="rowgroup">
            {Array.from(columns).map(([key]) => {
              const dataValue = data[key];
              let cellValue;

              switch (key) {
                case 'date':
                  cellValue = dayjs(dataValue).format('DD/MM/YYYY HH:mm:ss');
                  break;
                case 'amount':
                  cellValue = `${getFormattedNumber(dataValue)} ${
                    data.currency ?? ''
                  }`;
                  break;
                default:
                  cellValue = dataValue;
              }

              return (
                <TableCell key={key} role="cell">
                  {cellValue}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </Table>
      <TablePagination
        offset={offset}
        limit={limit}
        totalAmount={total}
        onLimitChange={setLimit}
        onOffsetChange={setOffset}
      />
    </TableWrapper>
  );
};

const Table = styled(DefTable)`
  grid-template-columns: repeat(2, minmax(180px, 1.5fr)) repeat(
      4,
      minmax(110px, 1.5fr)
    );
`;

type Payment = GpanelPaymentsQuery['gpanelPayments'][number];
type ColumnName = keyof Omit<Payment, '__typename' | 'id' | 'currency'>;

const columns: ReadonlyMap<ColumnName, TranslationKeys> = new Map([
  ['gpanelId', 'CASHIER_GPAMEL_PAYMENTS_TABLE__idColumn'],
  ['date', 'CASHIER_GPAMEL_PAYMENTS_TABLE__dateColumn'],
  ['method', 'CASHIER_GPAMEL_PAYMENTS_TABLE__methodColumn'],
  ['status', 'CASHIER_GPAMEL_PAYMENTS_TABLE__statusColumn'],
  ['identity', 'CASHIER_GPAMEL_PAYMENTS_TABLE__identityColumn'],
  ['amount', 'CASHIER_GPAMEL_PAYMENTS_TABLE__amountColumn'],
]);

export { DoneTransactionsTable };
