import styled from 'styled-components';
import {
  CircularProgress as DefCircularProgress,
  CircularProgressProps,
} from '@material-ui/core';
import { ColorName } from '../../styles/colors';

type SpinnerProps = {
  color?: ColorName;
  size?: StringOrNumber | boolean;
} & Omit<CircularProgressProps, 'color'>;

const Spinner = ({ color, size = 20, ...props }: SpinnerProps) => (
  <CircularProgress $color={color} size={size} {...props} />
);

const CircularProgress = styled(DefCircularProgress)<{
  $color?: ColorName;
}>`
  & .MuiCircularProgress-svg {
    color: ${({ theme, $color = 'silverTree' }) => theme.getColor($color)};
  }
`;

export { Spinner };
