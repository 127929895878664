import { createContext, ComponentType, Dispatch, Reducer } from 'react';
import { isString } from 'lodash-es';

export type ModalState = {
  modalOpened: boolean;
  Content: ComponentType<$TSFixMe>;
  data: unknown;
  wrapperProps: {
    closingDisabled?: boolean;
    withCloseButton?: boolean;
    background?: 'normal' | 'black';
    size?: 'normal' | 'narrow' | 'card';
  };
};

export const initialModalState: ModalState = {
  modalOpened: false,
  Content: () => null,
  data: undefined,
  wrapperProps: {
    closingDisabled: false,
    withCloseButton: true,
    background: 'normal',
    size: 'normal',
  },
};

type Action<T extends string> =
  | ['closeModal']
  | ['setModalContent', T, unknown?]
  | ['setWrapperProps', ModalState['wrapperProps']];

export type ModalsContext<T extends string> = ModalState & {
  closeModal: () => void;
  dispatch: Dispatch<Action<T>>;
};

export const getModalsContext = <T extends string>() =>
  createContext<ModalsContext<T>>({
    ...initialModalState,
    closeModal: () => undefined,
    dispatch: () => undefined,
  });

export type Modals<M extends string> = Readonly<{
  [key in M]: ComponentType<$TSFixMe>;
}>;

export const getModalsReducer: <M extends string>(
  modals: Modals<M>
) => Reducer<ModalState, Action<M>> =
  (modals) =>
  (prevState, [actionType, modalNameOrWrapperProps, data]) => {
    switch (actionType) {
      case 'closeModal':
        return initialModalState;
      case 'setModalContent':
        const Content =
          isString(modalNameOrWrapperProps) && modals[modalNameOrWrapperProps];

        return !Content
          ? initialModalState
          : {
              modalOpened: true,
              Content,
              data,
              wrapperProps: initialModalState.wrapperProps,
            };
      case 'setWrapperProps':
        return {
          ...prevState,
          wrapperProps: {
            ...prevState.wrapperProps,
            ...modalNameOrWrapperProps,
          },
        };
      default:
        return prevState;
    }
  };
