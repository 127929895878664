import styled from 'styled-components';

export const Table = styled.div.attrs({
  role: 'table',
})`
  display: grid;
  align-items: stretch;
  row-gap: 5px;
  overflow: auto;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.getColor('shark')};
`;
