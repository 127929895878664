import { useCallback } from 'react';
import { useContractWrite } from 'wagmi';
import { BigNumberish } from '@ethersproject/bignumber';
import {
  getAbi,
  getToBlockChainAmount,
  CryptoAddress,
} from '../utils/helpers/contract-helpers';

type HookConfig = {
  tokenAddress: CryptoAddress;
  transferAddress?: CryptoAddress;
  customAbiContractName?: string;
};

export const useDepositToken = ({
  tokenAddress,
  transferAddress: initialTransferAddress,
  customAbiContractName,
}: HookConfig) => {
  const { writeAsync, ...restData } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: tokenAddress,
    // TODO: check why it complains
    // @ts-ignore
    abi: getAbi(customAbiContractName),
    // @ts-ignore
    functionName: 'transfer',
  });

  const depositToken = useCallback(
    ({
      amount: rawAmount,
      transferAddress,
      units,
    }: {
      amount: StringOrNumber;
      transferAddress?: CryptoAddress;
      units?: BigNumberish;
    }) => {
      const address = transferAddress ?? initialTransferAddress;
      const amount = getToBlockChainAmount(rawAmount, units);

      if (!(address && amount)) {
        return Promise.reject(
          new Error('No transfer address or amount in deposit function ')
        );
      }

      return writeAsync?.({
        recklesslySetUnpreparedArgs: [address, amount],
      });
    },
    [writeAsync, initialTransferAddress]
  );

  return {
    ...restData,
    depositToken,
  };
};
