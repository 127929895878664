import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { externalLinkProps } from '../utils/consts/common';
import { SiteLinks } from '../utils/consts/site-data';
import hourglass from '../assets/img/hourglass.svg';
import { Text as DefText } from './Text';
import { Button as DefButton } from './buttons/Button';

const ClaimGap = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Icon src={hourglass} alt={'Sand watch'} />
      <Text>{t('CLAIM_GAP__text1')}</Text>
      <Text>{t('CLAIM_GAP__text2')}</Text>
      <Button
        component={'a'}
        href={SiteLinks.CLAIM_LEARN_MORE}
        {...externalLinkProps}
      >
        {t('CLAIM_GAP__button')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img``;

const Text = styled(DefText)`
  max-width: 540px;
  margin-top: 50px;
  text-align: center;
  & + & {
    margin-top: 24px;
  }
`;

const Button = styled(DefButton)`
  margin-top: 50px;
`;

export { ClaimGap };
