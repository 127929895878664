import { HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import { toString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { CardWrapper, CardImage } from 'components/styled/cards';
import { useSnackbar } from 'notistack';
import {
  isNftDelegationStatus,
  NftDelegationStatuses,
} from '../../utils/consts/nft-delegation-statuses';
import {
  useAcceptOrCancelDelegatedNftMutation,
  DelegatedNfTsDocument,
  DelegatedNftDataFragment,
} from '../../apollo/operations';
import { resetListStyles } from '../../styles/helpers';
import { Button } from '../buttons';
import { CardName } from '../cards-elements';
import { CardDelegationInfo as DefCardDelegationInfo } from '../cards-elements';

export type DelegatedCardItemProps = {
  card: DelegatedNftDataFragment;
} & HTMLAttributes<HTMLLIElement>;

const DelegatedCardItem = ({
  card: {
    collection,
    tokenId,
    imageUrl,
    status: rawStatus,
    owner,
    username,
    nftId,
  },
  ...props
}: DelegatedCardItemProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const status = useMemo(
    () => (isNftDelegationStatus(rawStatus) ? rawStatus : null),
    [rawStatus]
  );

  const [acceptOrCancel, { loading }] = useAcceptOrCancelDelegatedNftMutation({
    fetchPolicy: 'no-cache',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: DelegatedNfTsDocument,
      },
    ],
    onCompleted: (data, clientOptions) => {
      if (!clientOptions?.variables) {
        console.warn(
          `No variables in on completed fn of acceptOrCancel nft mutation`
        );
        return;
      }

      if (data.NFTDelegationAccept?.message === 'OK') {
        enqueueSnackbar(
          t(
            clientOptions.variables.accept
              ? 'DELEGATE_NFT_CARDS_acceptingSuccess'
              : 'DELEGATE_NFT_CARDS_cancellingSuccess'
          )
        );
      } else {
        enqueueSnackbar(
          t(
            clientOptions.variables.accept
              ? 'DELEGATE_NFT_CARDS_acceptingError'
              : 'DELEGATE_NFT_CARDS_cancellingError'
          )
        );
      }
    },
    onError: (e, clientOptions) => {
      console.error(`Error on accept or cancel delegated nft mutation`, e);
      if (!clientOptions?.variables) return;
      enqueueSnackbar(
        t(
          clientOptions.variables.accept
            ? 'DELEGATE_NFT_CARDS_acceptingError'
            : 'DELEGATE_NFT_CARDS_cancellingError'
        )
      );
    },
  });

  return (
    <CardWrapper {...props}>
      <CardImage src={imageUrl} />
      {!!collection && (
        <CardName collectionName={collection} cardId={toString(tokenId)} />
      )}
      {!!(status && owner && username) && (
        <DelegationInfo
          status={status}
          owner={owner}
          username={username}
          type={'rented'}
        />
      )}
      <ButtonsList>
        {status === NftDelegationStatuses.PENDING && (
          <li>
            <Button
              //@ts-ignore
              size={'small'}
              disabled={loading}
              onClick={() => {
                acceptOrCancel({
                  variables: {
                    nftId: nftId,
                    accept: true,
                  },
                });
              }}
            >
              {t('DELEGATE_NFT_CARDS_acceptButton')}
            </Button>
          </li>
        )}
        <li>
          <Button
            //@ts-ignore
            size={'small'}
            disabled={loading}
            onClick={() => {
              acceptOrCancel({
                variables: {
                  nftId: nftId,
                  accept: false,
                },
              });
            }}
          >
            {t('DELEGATE_NFT_CARDS_cancelButton')}
          </Button>
        </li>
      </ButtonsList>
    </CardWrapper>
  );
};

const DelegationInfo = styled(DefCardDelegationInfo)`
  margin-top: 10px;
`;

const ButtonsList = styled.ul`
  ${resetListStyles};
  margin-top: 15px;
  li + li {
    margin-top: 10px;
  }
`;

export { DelegatedCardItem };
