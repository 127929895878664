import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  NftDelegationStatuses,
  isNftDelegationStatus,
} from '../../utils/consts/nft-delegation-statuses';
import { TranslationKeys } from '../../utils/i18n';
import { Text, TextProps } from '../Text';

export type CardDelegationInfoProps = {
  status: string;
  owner: string;
  username: string;
  type: string;
} & TextProps;

const CardDelegationInfo = ({
  status,
  owner,
  username,
  type,
  ...props
}: CardDelegationInfoProps) => {
  const { t } = useTranslation();

  if (!isNftDelegationStatus(status)) return null;

  return (
    // @ts-ignore
    <Wrapper variant={'small'} {...props}>
      <Status component={'span'} variant={'small'}>
        {type === 'own'
          ? t(translationsOwn[status])
          : t(translationsRented[status])}
      </Status>
      : {type === 'own' ? username : owner}
    </Wrapper>
  );
};

const Wrapper = styled(Text)`
  text-align: center;
`;

const Status = styled(Text)`
  color: ${({ theme }) => theme.getColor('emerald')};
`;

const translationsOwn: {
  [key in NftDelegationStatuses]: TranslationKeys;
} = {
  [NftDelegationStatuses.ACCEPTED]: 'DELEGATE_NFT_CARDS_own_acceptedStatus',
  [NftDelegationStatuses.PENDING]: 'DELEGATE_NFT_CARDS_own_pendingStatus',
};
const translationsRented: {
  [key in NftDelegationStatuses]: TranslationKeys;
} = {
  [NftDelegationStatuses.ACCEPTED]: 'DELEGATE_NFT_CARDS_rented_acceptedStatus',
  [NftDelegationStatuses.PENDING]: 'DELEGATE_NFT_CARDS_rented_pendingStatus',
};

export { CardDelegationInfo };
