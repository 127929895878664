import { useMemo, useState, useEffect } from 'react';
import { BigNumber } from 'ethers';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractRead,
} from 'wagmi';
import {
  getAbi,
  CryptoAddress,
  getToBlockChainAmount,
} from '../utils/helpers/contract-helpers';
import { getEnvVariable } from '../utils/helpers/env-helpers';

type Data = { cardId?: string } & { enabled?: boolean } & {
  [key in 'onTransactionSuccess' | 'onConfirmSuccess']?: () => void;
} & {
  [key in 'onPrepareError' | 'onTransactionError' | 'onConfirmError']?: (
    e?: Error
  ) => void;
};

export const useSwapNFT = (data: Data) => {
  const [swapCost, setSwapCost] = useState<BigNumber | undefined>(undefined);
  const enabled = !!data.cardId && !!data.enabled;

  const { isLoading: swapCostLoading, data: swapCostResponse } =
    useContractRead({
      ...commonConfig,
      functionName: 'swapCost',
      enabled,
    });

  useEffect(() => {
    setSwapCost(
      BigNumber.isBigNumber(swapCostResponse) ? swapCostResponse : undefined
    );
  }, [swapCostResponse]);

  const {
    config,
    isLoading: prepareLoading,
    error: prepareError,
    isFetchedAfterMount,
  } = usePrepareContractWrite({
    ...commonConfig,
    functionName: 'swapERC721Token',
    staleTime: 0,
    args: [getToBlockChainAmount(data.cardId!, 0)],
    overrides: {
      value: swapCost,
    },
    enabled: enabled && BigNumber.isBigNumber(swapCost),
    onError: (e) => {
      console.error(`Error on SwapNFT prepare: `, e);
      data.onPrepareError?.();
    },
  });

  const {
    isLoading: writing,
    data: writeData,
    write,
    writeAsync,
  } = useContractWrite({
    ...config,
    onSuccess: data?.onTransactionSuccess,
    onError: (e) => {
      console.error(`Error on ApproveAllNFT contract write: `, e);
      data.onTransactionError?.();
    },
  });

  const { isLoading: confirmationLoading } = useWaitForTransaction({
    hash: writeData?.hash,
    confirmations: 3,
    onSuccess: (response) => {
      if (response?.status === 1) {
        data.onConfirmSuccess?.();
      } else {
        console.error(
          `Error on ApproveAllNFT confirm check: status not success`
        );
        data.onConfirmError?.();
      }
    },
    onError: (e) => {
      console.error(`Error on ApproveAllNFT confirm check: `, e);
      data.onConfirmError?.();
    },
  });

  return useMemo(
    () => ({
      loading:
        swapCostLoading || prepareLoading || writing || confirmationLoading,
      prepareError: isFetchedAfterMount ? prepareError : null,
      write,
      writeAsync,
    }),
    [
      swapCostLoading,
      prepareLoading,
      writing,
      confirmationLoading,
      isFetchedAfterMount,
      prepareError,
      write,
      writeAsync,
    ]
  );
};

const commonConfig = {
  address: getEnvVariable('NFT_SWAP_ADDRESS') as CryptoAddress,
  abi: getAbi('nftSwapAddress'),
};
