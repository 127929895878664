import { useCallback } from 'react';
import { ethers } from 'ethers';
import { useProvider, useAccount } from 'wagmi';
import { BigNumberish } from '@ethersproject/bignumber';
import {
  getAbi,
  getFromBlockChainAmount,
  getBNValue,
} from '../utils/helpers/contract-helpers';

export const useGetTokenBalance = () => {
  const { address } = useAccount();
  const provider = useProvider();

  return useCallback(
    async ({
      tokenAddress,
      customAbiContractName,
      units,
    }: {
      tokenAddress: string;
      customAbiContractName?: string;
      units?: BigNumberish;
    }) => {
      try {
        if (!address) {
          throw new Error(
            'Wallet is not connected in getTokenBalance function'
          );
        }
        const contract = new ethers.Contract(
          tokenAddress,
          getAbi(customAbiContractName),
          provider
        );
        const balance = getBNValue(await contract.balanceOf(address), 'string');
        return getFromBlockChainAmount(balance, units);
      } catch (e) {
        console.log(`Couldn't get ${tokenAddress} user balance:`, e);
        throw e;
      }
    },
    [address, provider]
  );
};
