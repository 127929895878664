/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMemo, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { components, OptionProps } from 'react-select';

export type SelectOptionWithIconProps = OptionProps<{
  label: string;
  value: string;
  icon?: string;
  iconSize?: IconSize;
  iconPosition?: IconPosition;
}>;

const SelectOptionWithIcon = (props: SelectOptionWithIconProps) => {
  const {
    data: { label, icon, iconSize = IconSize.NORMAL, iconPosition },
  } = props;

  const startPosition = iconPosition === IconPosition.START;

  const Icon = useMemo(
    () => (props: ComponentProps<typeof StyledIcon>) =>
      (
        <StyledIcon
          className={'react-select__label-icon'}
          src={icon}
          alt={label}
          $iconSize={iconSize}
          {...props}
        />
      ),
    [icon, label, iconSize]
  );

  if (!icon) {
    return <Option {...props}>{label}</Option>;
  }

  return (
    // @ts-ignore
    <StyledOption {...props}>
      {startPosition && <Icon $start />}
      {label}
      {!startPosition && <Icon />}
    </StyledOption>
  );
};

const Option = components.Option;

// noinspection JSUnusedGlobalSymbols
export enum IconPosition {
  START,
  END,
}

export enum IconSize {
  // noinspection JSUnusedGlobalSymbols
  NORMAL,
  BIG,
}

const StyledOption = styled(Option)`
  display: flex !important;
  align-items: center;
`;

const StyledIcon = styled.img<{
  $start?: boolean;
  $iconSize: IconSize;
}>`
  ${({ $iconSize }) =>
    $iconSize === IconSize.NORMAL
      ? css`
          width: 14px;
          height: 14px;
        `
      : css`
          width: 20px;
          height: 20px;
        `}

  ${({ $start }) =>
    $start
      ? css`
          margin-right: 8px;
        `
      : css`
          margin-left: 8px;
        `}
`;

export { SelectOptionWithIcon };
