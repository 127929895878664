/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useLayoutEffect, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/spinners/Spinner';
import { KYCStatuses } from '../../utils/enums';
import { getFormattedNumber } from '../../utils/helpers/strings';
import { useCashierGeneralInfo } from '../../hooks';
// noinspection ES6PreferShortImport
import { Text, TextProps } from '../../components/Text';
import { resetListStyles } from '../../styles/helpers';
import { Sumsub as DefSumsub } from '../Sumsub';
import { Button as DefButton } from '../buttons';
import { BalanceInfoBlock as DefBalanceInfoBlock } from './BalanceInfoBlock';

const GeneralInfo = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { loading, error, data } = useCashierGeneralInfo();
  const [withKycButton, setWithKycButton] = useState(false);
  const [sumsubEnabled, setSumsubEnabled] = useState(false);

  useLayoutEffect(() => {
    if (!data || data.kycStatus !== KYCStatuses.NO_KYC) return;
    setWithKycButton(true);
  }, [loading, error, data]);

  return (
    <Wrapper {...props}>
      {(() => {
        if (loading) return <Spinner />;
        if (error || !data) return 'No data';

        const { lastDeposit, lastWithdraw } = data;

        return (
          <>
            <Row>
              <RowLabel>{t('CASHIER_GENERAL_INFO__infoKYCLabel')}:</RowLabel>
              {(() => {
                let text;
                let green;

                switch (data.kycStatus) {
                  case KYCStatuses.NOT_ALLOWED:
                    text = t(kycStatusesTranslations.NOT_ALLOWED);
                    break;
                  case KYCStatuses.PENDING:
                    text = t(kycStatusesTranslations.PENDING);
                    break;
                  case KYCStatuses.NO_KYC:
                    text = (
                      <>
                        {t(kycStatusesTranslations.NOT_APPROVED)}
                        {withKycButton && (
                          <Button
                            color={'secondary'}
                            onClick={() => {
                              setSumsubEnabled(true);
                              setWithKycButton(false);
                            }}
                          >
                            {t('CASHIER_GENERAL_INFO__infoKYCButton')}
                          </Button>
                        )}
                      </>
                    );
                    break;
                  default:
                    text = t(kycStatusesTranslations.APPROVED);
                    green = true;
                }

                return <RowValue $greenColor={green}>{text}</RowValue>;
              })()}
            </Row>
            {(!!lastDeposit || !!lastWithdraw) && (
              <TransactionsList>
                {!!lastDeposit && (
                  <TransactionsListItem
                    title={t('CASHIER_GENERAL_INFO__infoLastTransactionLabel')}
                    currency={{
                      symbol: lastDeposit.currency.toUpperCase(),
                    }}
                    amount={getFormattedNumber(lastDeposit.amount)}
                  />
                )}
                {!!lastWithdraw && (
                  <TransactionsListItem
                    title={t('CASHIER_GENERAL_INFO__infoLastWithdrawalLabel')}
                    currency={{
                      symbol: lastWithdraw.currency.toUpperCase(),
                    }}
                    amount={getFormattedNumber(lastWithdraw.amount)}
                  />
                )}
              </TransactionsList>
            )}
          </>
        );
      })()}
      {sumsubEnabled && <Sumsub />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 22px 14px;
`;

const Row = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-child {
    margin-bottom: 12px;
  }
  & + & {
    margin-top: 2px;
  }
`;

const RowLabel = styled((props: TextProps) => (
  <Text variant={'h3'} {...props} />
))`
  flex: 0 0 140px;
  letter-spacing: -0.05px;
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    flex-basis: 100px;
  }
`;

const Button = styled(DefButton)``;

const RowValue = styled(Text)<{
  $greenColor?: boolean;
}>`
  display: flex;
  align-items: center;
  text-align: right;
  ${({ $greenColor }) =>
    $greenColor &&
    css`
      color: #4d9131;
    `}
  ${Button} {
    margin-left: 10px;
  }
`;

const TransactionsList = styled.ul`
  ${resetListStyles};
  display: flex;
  justify-content: space-between;
`;

const TransactionsListItem = styled(DefBalanceInfoBlock)``;

const Sumsub = styled(DefSumsub)`
  margin-top: 20px;
`;

// none, pending, level 1, level 2
const kycStatusesTranslations = {
  NOT_ALLOWED: 'CASHIER_GENERAL_INFO__infoKYCNotAllowedStatus',
  NOT_APPROVED: 'CASHIER_GENERAL_INFO__infoKYCDenyStatus',
  PENDING: 'CASHIER_GENERAL_INFO__infoKYCPendingStatus',
  APPROVED: 'CASHIER_GENERAL_INFO__infoKYCApproveStatus',
} as const;

export { GeneralInfo };
