import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval, useEffectOnce } from 'react-use';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { LocalStorageKeys, removeLocalStorageValue } from '../utils/helpers';
import { useLocalStorageValue } from './useLocalStorageValue';

export const useWithdraw2FAData = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const withdraw2faData = useLocalStorageValue(
    LocalStorageKeys.WITHDRAW_2_FA_DATA
  );

  const checkWithdraw2FA = useCallback(() => {
    if (!withdraw2faData) return;
    const currentTime = dayjs.utc();
    const withdraw2FATime = dayjs.utc(withdraw2faData.time);
    if (currentTime.isAfter(withdraw2FATime.add(1, 'hour'))) {
      enqueueSnackbar(t('WITHDRAW_2FA_CODE_FORM__codeExpired'), {
        variant: 'warning',
      });
      removeLocalStorageValue(LocalStorageKeys.WITHDRAW_2_FA_DATA);
    }
  }, [withdraw2faData, enqueueSnackbar, t]);

  useEffectOnce(checkWithdraw2FA);

  useInterval(checkWithdraw2FA, 5000);

  return withdraw2faData;
};
