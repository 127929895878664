import { useMemo, createContext, useContext, PropsWithChildren } from 'react';
import { Chain } from 'wagmi';
import {
  useBlockchainsQuery,
  BlockchainsQueryResult,
} from '../apollo/operations';

const BlockchainsProvider = (props: PropsWithChildren) => {
  const { data: { blockchains: backendBlockchains = [] } = {} } =
    useBlockchainsQuery();

  const blockchains = useMemo(
    () => convertBackendBlockchainsToWagmiChains(backendBlockchains),
    [backendBlockchains]
  );

  return <BlockchainsContext.Provider value={blockchains} {...props} />;
};

export type Blockchains = NonNullable<
  BlockchainsQueryResult['data']
>['blockchains'];

type ExtendedChain = Chain & {
  iconUrl: Maybe<string>;
};

const convertBackendBlockchainsToWagmiChains = (
  blockchains: Blockchains
): ExtendedChain[] =>
  blockchains.map(
    ({ chainId, name, rpcPublic, nativeCurrency, scanUrl, iconUrl }) => ({
      id: chainId,
      name,
      network: name,
      nativeCurrency,
      rpcUrls: {
        public: { http: rpcPublic },
        default: { http: rpcPublic },
      },
      blockExplorers: {
        default: {
          name: `${name} Scan`,
          url: scanUrl,
        },
      },
      iconUrl,
    })
  );

const BlockchainsContext = createContext<ExtendedChain[]>([]);

export const useBlockchains = () => useContext(BlockchainsContext);

export { BlockchainsProvider };
