import { useState, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Spinner } from 'components/spinners/Spinner';

export type ImageProps = {
  src: Maybe<string>;
  alt?: string;
} & HTMLAttributes<HTMLDivElement>;

const Image = ({ src, alt, ...props }: ImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (!src) return null;

  return (
    <Wrapper {...props}>
      <Img
        style={{ display: isImageLoaded ? 'block' : 'none' }}
        src={src}
        alt={alt ? alt : ''}
        onLoad={() => setIsImageLoaded(true)}
      />
      {!isImageLoaded && <Spinner size={50} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
`;

const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: inherit;
`;

export { Image };
