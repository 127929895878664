import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAccount } from 'wagmi';
import { externalLinkProps } from '../../utils/consts/common';
import { SiteLinks } from '../../utils/consts/site-data';
import { useCancelMarketplaceListing, useIsBSCChain } from '../../hooks';
import { useMyNft } from '../../providers';
import { Button } from './styled';
import { useUpdateNftListingsMutation } from '../../apollo/operations';
import { Text } from '../Text';

export type ListTabProps = {
  closeModal: () => void;
};

const ListTab = ({ closeModal }: ListTabProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const cancelMarketplaceListing = useCancelMarketplaceListing();
  const { address } = useAccount();
  const { card, reloadCards, methods } = useMyNft();

  const BSCBlockchain = useIsBSCChain();

  const [updateListings] = useUpdateNftListingsMutation({
    refetchQueries: [],
    awaitRefetchQueries: true,
  });

  const handleCancelListingCard = async () => {
    if (!address) {
      enqueueSnackbar(t('ACCOUNNT_PROVIDER__connectToMetamaskMessage'), {
        variant: 'warning',
      });
      return;
    }

    if (
      !card?.listing ||
      card.listing.marketplaceContract.type !== 'MARKETPLACE'
    ) {
      enqueueSnackbar('Delisting error', {
        variant: 'error',
      });
      return;
    }

    try {
      methods.setCardIsProcessing(true);

      await cancelMarketplaceListing(card.listing.originalListingId);
      await updateListings();
      await reloadCards();
      closeModal();
    } catch (e) {
      console.error(`Error on delisting:`, e);
      enqueueSnackbar(`Error on delisting`, {
        variant: 'error',
      });
    } finally {
      methods.setCardIsProcessing(false);
    }
  };

  if (!card) {
    return <Text>Something went wrong</Text>;
  }

  if (BSCBlockchain) {
    return (
      <>
        <Text
          component="span"
          style={{ marginTop: '15px', marginBottom: '15px' }}
        >
          {t('MY_CARDS_CARD_OVERVIEW_MODAL__listTabSwapText')}
        </Text>
        {!!card.listing && (
          <Button onClick={handleCancelListingCard}>
            {t('MY_CARDS_CARD_OVERVIEW_MODAL__delistButtonText')}
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      <Text style={{ marginTop: '5px', marginBottom: '15px' }}>
        {t('MY_CARDS_CARD_OVERVIEW_MODAL__listTabOpenSeaText')}
      </Text>
      <Button
        component={'a'}
        href={`${SiteLinks.OPEN_SEA_LISTING}/${card.nftContract.contractAddress}/${card.tokenId}`}
        {...externalLinkProps}
      >
        {t('MY_CARDS_CARD_OVERVIEW_MODAL__listTabOpenSeaButton')}
      </Button>
    </>
  );
};

export { ListTab };
