import { useState, useEffect, PropsWithChildren } from 'react';
import { ApolloProvider as Provider, ApolloClient } from '@apollo/client';
import { getClient } from '../utils/apollo-client';

const ApolloProvider = ({ children }: PropsWithChildren) => {
  const [client, setClient] = useState<ApolloClient<
    Record<string, unknown>
  > | null>(null);

  useEffect(() => {
    (async () => {
      const client = await getClient();
      setClient(client);
    })();
  }, []);

  return client ? <Provider client={client}>{children}</Provider> : null;
};
export { ApolloProvider };
