import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { wallets } from '../../utils/consts/connectors';
import { setLocalStorageValue, LocalStorageKeys } from '../../utils/helpers';
import { useWalletAuth } from '../../hooks';
import { useInnerModals } from '../../providers';
import { resetListStyles, resetButtonStyles } from '../../styles/helpers';
import { Text } from '../Text';

const WalletsModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useInnerModals();
  const { login } = useWalletAuth();

  return (
    <>
      <Title>{t('WALLETS_MODAL__title')}</Title>
      <WalletsList>
        {wallets.map(({ title, connectorId, icon }, index) => (
          <WalletsListItem key={index}>
            <WalletButton
              onClick={() => {
                setLocalStorageValue(LocalStorageKeys.CONNECTOR, connectorId);
                login(connectorId);
                dispatch(['closeModal']);
              }}
            >
              <WalletIcon src={icon} alt={title} />
              <WalletTitle>{title}</WalletTitle>
            </WalletButton>
          </WalletsListItem>
        ))}
      </WalletsList>
    </>
  );
};

const Title = styled((props) => <Text component={'h3'} {...props} />)`
  margin-bottom: 16px;
  text-align: center;
`;

const WalletsList = styled.ul`
  ${resetListStyles};
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  justify-content: space-between;
`;

const WalletsListItem = styled.li``;

const WalletButton = styled.button`
  ${resetButtonStyles};
  display: flex;
  align-items: center;
  padding: 6px 10px;
`;

const WalletIcon = styled.img`
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;

const WalletTitle = styled(Text)``;

export { WalletsModal };
