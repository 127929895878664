import {
  GpanelAuthPage,
  CashierPage,
  MyNftCardsPage,
  NFTAvatarPage,
  VaultPage,
  ClaimPage,
  DepositAndWithdrawalPage,
} from '../../pages';

export const pages = {
  cashier: {
    path: `/cashier`,
    Component: CashierPage,
  },
  myNftCards: {
    path: `/my-nft-cards`,
    Component: MyNftCardsPage,
  },
  nftAvatar: {
    path: '/nft-avatar',
    Component: NFTAvatarPage,
  },
  vault: {
    path: `/vault`,
    Component: VaultPage,
  },
  claim: {
    path: `/claim`,
    Component: ClaimPage,
  },
  depositAndWithdrawal: {
    path: `/cashierapp`,
    Component: DepositAndWithdrawalPage,
  },
  gpanelAuth: {
    path: `/auth/gpanel`,
    Component: GpanelAuthPage,
  },
};

export type PageName = keyof typeof pages;
export const getPath = (pageName: PageName) => pages[pageName].path ?? '/';
