import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import { BlockchainBalancesProvider } from '../providers';
import { GpanelLoginButton } from '../components/buttons';
import {
  DepositAndWithdrawalTabs as DefDepositAndWithdrawalTabs,
  DoneTransactionsTable as DefTransactionsTable,
} from '../components/cashier';
import { Spinner } from '../components/spinners/Spinner';
import { useGpanel } from '../providers/GpanelProvider';

const DepositAndWithdrawalPage = () => {
  const { t } = useTranslation();
  const { loading: loadingUser, user } = useGpanel();
  const [tableVisible, setTableVisible] = useState(false);

  if (loadingUser) {
    return <Spinner />;
  }

  if (!user) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 50,
        }}
      >
        <GpanelLoginButton withAutoClick>
          {t('DEPOSIT_AND_WITHDRAWAL_PAGE__loginButton')}
        </GpanelLoginButton>
      </div>
    );
  }

  return (
    <BlockchainBalancesProvider>
      <Wrapper>
        <DepositAndWithdrawalTabs
          onTransactionHistoryClick={() =>
            setTableVisible((prevState) => !prevState)
          }
        />
        {tableVisible && <TransactionsTable />}
      </Wrapper>
    </BlockchainBalancesProvider>
  );
};

const Wrapper = styled.div`
  max-width: 980px;
  padding: 40px 20px;
  margin: 0 auto;
`;

const DepositAndWithdrawalTabs = styled(DefDepositAndWithdrawalTabs)``;

const TransactionsTable = styled(DefTransactionsTable)`
  margin-top: 34px;
`;

export { DepositAndWithdrawalPage };
