import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { toString, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { resetButtonStyles } from '../styles/helpers';
import { DownArrow as DefDownArrow } from './DownArrow';
import { Text } from './Text';
import { Select as DefSelect } from './form-elements/Select';

export type TablePaginationProps = {
  offset: number;
  limit: number;
  totalAmount: number;
  onLimitChange: (limit: number) => void;
  onOffsetChange: (offset: number) => void;
} & HTMLAttributes<HTMLDivElement>;

const TablePagination = ({
  offset,
  limit,
  totalAmount,
  onLimitChange,
  onOffsetChange,
  ...props
}: TablePaginationProps) => {
  const { t } = useTranslation();
  const currentFirstItem = offset + 1;
  const currentLastItem =
    offset + limit <= totalAmount ? offset + limit : totalAmount;
  const currentPage = offset / limit + 1;
  const prevButtonDisabled = offset === 0;
  const totalPages = Math.ceil(totalAmount / limit);
  const nextButtonDisabled = currentPage === totalPages;

  return (
    <Wrapper {...props}>
      <LimitBlock>
        <span>{t('TABLE_PAGINATION__show')}</span>
        <Select
          value={{
            label: toString(limit),
            value: toString(limit),
          }}
          isSearchable={false}
          options={[
            {
              label: '10',
              value: '10',
            },
            {
              label: '20',
              value: '20',
            },
            {
              label: '50',
              value: '50',
            },
          ]}
          onChange={(data) => {
            // @ts-ignore
            onLimitChange(toNumber(data.value));
            onOffsetChange(0);
          }}
        />
        <span>{t('TABLE_PAGINATION__entries')}</span>
      </LimitBlock>
      <InfoBlock weight={'bold'}>
        {`${t('TABLE_PAGINATION__showing')} ${currentFirstItem} ${t(
          'TABLE_PAGINATION__to'
        )} ${currentLastItem} ${t('TABLE_PAGINATION__of')} ${totalAmount}  ${t(
          'TABLE_PAGINATION__entries'
        )}`}
      </InfoBlock>
      <OffsetBlock>
        <PrevOffsetButton
          aria-label={'Prev page'}
          disabled={prevButtonDisabled}
          onClick={() => {
            if (!prevButtonDisabled) {
              const nextOffset = offset - limit;
              onOffsetChange(0 <= nextOffset ? nextOffset : 0);
            }
          }}
        >
          <DownArrow />
        </PrevOffsetButton>
        <OffsetBlockText>{t('TABLE_PAGINATION__page')}</OffsetBlockText>
        <CurrentOffset>{currentPage}</CurrentOffset>
        <OffsetBlockText>
          {t('TABLE_PAGINATION__to')} {totalPages}
        </OffsetBlockText>
        <NextOffsetButton
          aria-label={'Next page'}
          disabled={nextButtonDisabled}
          onClick={() => {
            if (!nextButtonDisabled) {
              onOffsetChange(offset + limit);
            }
          }}
        >
          <DownArrow />
        </NextOffsetButton>
      </OffsetBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 20px 14px;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    grid-template-columns: 1fr;
  }
`;

const LimitBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: ${17 / 12};

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    justify-self: center;
    margin-bottom: 20px;
  }
`;

const Select = styled(DefSelect)`
  margin: 0 4px;
  min-width: 50px;
  min-height: 35px;

  .react-select__control {
    padding: 0 0;
  }

  .react-select__value-container {
    padding-right: 4px;
  }

  .react-select__single-value {
    font-size: 14px;
  }

  .react-select__dropdown-indicator {
    padding: 2px 4px;
  }

  .react-select__option {
    &--is-selected {
      background: linear-gradient(15.2deg, #540106 -16.09%, #bb4545 93.67%);
      color: #fff;
    }
  }
`;

const InfoBlock = styled(Text)`
  justify-self: center;
  font-size: 12px;
  line-height: ${17 / 12};
  color: ${({ theme }) => theme.getColor('osloGray')};
`;

const OffsetBlock = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  padding: 2px;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    margin-top: 20px;
    justify-self: center;
  }
`;

const OffsetBlockText = styled(Text)``;

const Button = styled.button`
  ${resetButtonStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.getColor('brightGray')};
  color: #fff;
  transition: background-color 0.3s ease-in;

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: rgba(255, 255, 255, 0.5);
          cursor: auto;
        `
      : css`
          &:hover {
            background-color: ${({ theme }) => theme.getColor('scarpaFlow')};
          }
        `};
`;

const DownArrow = styled(DefDownArrow)``;

const offset = 7;
const PrevOffsetButton = styled(Button)`
  margin-right: ${offset}px;
  ${DownArrow} {
    transform: rotate(90deg);
  }
`;

const CurrentOffset = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 4px;
  margin: 0 ${offset}px;
  border: 1px solid ${({ theme }) => theme.getColor('brightGray')};
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  line-height: ${14 / 12};
`;

const NextOffsetButton = styled(Button)`
  margin-left: ${offset}px;
  ${DownArrow} {
    transform: rotate(-90deg);
  }
`;

export { TablePagination };
