import { useField } from 'formik';
import { Input, InputProps } from '../form-elements';

export type FormikInputProps = InputProps & {
  name: string;
};

const FormikInput = (props: FormikInputProps) => {
  const [field, { touched, error }] = useField(props.name);

  return (
    <Input
      {...field}
      {...props}
      {...(touched && !!error
        ? {
            error: true,
            helperText: error,
          }
        : {})}
    />
  );
};

export { FormikInput };
