import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from '../../utils/i18n';
import { useShortenedAddress } from '../../hooks';
import { asideTabPanelStyles } from '../../styles/tabs';
import { Text } from '../Text';

export type HolderInformationProps = {
  text: TranslationKeys;
  owner?: string;
} & HTMLAttributes<HTMLDivElement>;

const HolderInformation = ({
  text,
  owner,
  ...props
}: HolderInformationProps) => {
  const { t } = useTranslation();
  const token = useShortenedAddress(owner);

  if (!owner) return null;

  return (
    <Wrapper {...props}>
      <Text component="span">{t(text)}</Text>
      <Text component="span">{token}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  ${asideTabPanelStyles};
`;

export { HolderInformation };
