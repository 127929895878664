import { toString } from 'lodash-es';
import { BigNumber } from 'ethers';
import bigDecimal from 'js-big-decimal';
import { erc20ABI } from 'wagmi';
import { readContract, prepareWriteContract, writeContract } from '@wagmi/core';
import { vaultStakingAbi } from '../abi/vault-staking';
import { wait } from './common';
import {
  getFromBlockChainAmount,
  getToBlockChainAmount,
  CryptoAddress,
} from './contract-helpers';

export enum VaultDataFields {
  ALLOWED_STATE,
  BALANCE,
  STACKED_AMOUNT,
  EARNED,
  TOTAL_STAKED,
  INTEREST,
}

export class Vault {
  static initialData = {
    [VaultDataFields.ALLOWED_STATE]: false,
    [VaultDataFields.BALANCE]: '',
    [VaultDataFields.STACKED_AMOUNT]: '',
    [VaultDataFields.EARNED]: '',
    [VaultDataFields.TOTAL_STAKED]: '',
    [VaultDataFields.INTEREST]: '',
  };
  private readonly userAddress: CryptoAddress;
  private readonly contractAddress: CryptoAddress;
  private tokenAddress: Nullish<CryptoAddress> = null;
  public initiated = false;
  public data = Vault.initialData;

  constructor(
    userAddress: CryptoAddress,
    stakingContractAddress: CryptoAddress
  ) {
    this.userAddress = userAddress;
    this.contractAddress = stakingContractAddress;
  }

  private async init() {
    this.tokenAddress = await this.getTokenAddress();
    this.initiated = true;
  }

  private async getTokenAddress() {
    try {
      return (await readContract({
        address: this.contractAddress,
        abi: vaultStakingAbi,
        functionName: 'stakingToken',
      })) as CryptoAddress;
    } catch (e) {
      console.log(`Couldn't get staking token contract`, e);
      throw e;
    }
  }

  private async checkAllowState() {
    try {
      if (!this.tokenAddress) {
        throw new Error('No token address in checkAllowState');
      }

      const allowedBN = (await readContract({
        address: this.tokenAddress,
        abi: erc20ABI,
        functionName: 'allowance',
        args: [this.userAddress, this.contractAddress],
      })) as unknown;
      const allowed = BigNumber.isBigNumber(allowedBN)
        ? allowedBN.toString()
        : 0;
      this.data[VaultDataFields.ALLOWED_STATE] = 0 < allowed;
    } catch (e) {
      console.log(`Couldn't check allow state`, e);
      throw e;
    }
  }

  private async getTokenBalance() {
    try {
      if (!this.tokenAddress) {
        throw new Error('No token address in getTokenBalance');
      }
      const balanceBN = (await readContract({
        address: this.tokenAddress,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [this.userAddress],
      })) as unknown;
      const balance = BigNumber.isBigNumber(balanceBN)
        ? balanceBN.toString()
        : 0;
      this.data[VaultDataFields.BALANCE] = getFromBlockChainAmount(balance);
    } catch (e) {
      console.log(`Couldn't get vault token balance`);
      throw e;
    }
  }

  private async getStakedBalance() {
    try {
      const balanceBN = (await readContract({
        address: this.contractAddress,
        abi: vaultStakingAbi,
        functionName: 'balanceOf',
        args: [this.userAddress],
      })) as unknown;
      const balance = BigNumber.isBigNumber(balanceBN)
        ? balanceBN.toString()
        : 0;
      this.data[VaultDataFields.STACKED_AMOUNT] =
        getFromBlockChainAmount(balance);
    } catch (e) {
      console.log(`Couldn't get vault stake balance`);
      throw e;
    }
  }

  private async getEarnedBalance() {
    try {
      const balanceBN = (await readContract({
        address: this.contractAddress,
        abi: vaultStakingAbi,
        functionName: 'earned',
        args: [this.userAddress],
      })) as unknown;
      const balance = BigNumber.isBigNumber(balanceBN)
        ? balanceBN.toString()
        : 0;
      this.data[VaultDataFields.EARNED] = getFromBlockChainAmount(balance);
    } catch (e) {
      console.log(`Couldn't get vault earn balance`);
      throw e;
    }
  }

  private async getTotalStaked() {
    try {
      const balanceBN = (await readContract({
        address: this.contractAddress,
        abi: vaultStakingAbi,
        functionName: 'totalSupply',
      })) as unknown;
      const balance = BigNumber.isBigNumber(balanceBN)
        ? balanceBN.toString()
        : 0;
      this.data[VaultDataFields.TOTAL_STAKED] =
        getFromBlockChainAmount(balance);
    } catch (e) {
      console.log(`Couldn't get vault total balance`);
      throw e;
    }
  }

  private async getAPY() {
    try {
      const periodFinishBN = (await readContract({
        address: this.contractAddress,
        abi: vaultStakingAbi,
        functionName: 'periodFinish',
      })) as unknown;

      const periodFinish = BigNumber.isBigNumber(periodFinishBN)
        ? periodFinishBN.toNumber()
        : 0;

      const timestamp = Date.now();

      if (periodFinish * 1000 >= timestamp) {
        // validate if there is still staking or if it's finished
        const rewardRateBN = (await readContract({
          address: this.contractAddress,
          abi: vaultStakingAbi,
          functionName: 'rewardRate',
        })) as BigNumber;

        const totalSupplyBN = (await readContract({
          address: this.contractAddress,
          abi: vaultStakingAbi,
          functionName: 'totalSupply',
        })) as BigNumber;

        const rewardRate = new bigDecimal(rewardRateBN.toString());
        const totalSupply = new bigDecimal(totalSupplyBN.toString());

        if (rewardRateBN.gt(0)) {
          // If there are rewards.
          const totalPerYear = rewardRate.multiply(new bigDecimal(31556926)); // = # tokens per
          // year

          const percentage = totalSupplyBN.gt(0)
            ? totalPerYear
                .divide(totalSupply, 18)
                .multiply(new bigDecimal(100))
                .getValue()
            : '1000';

          this.data[VaultDataFields.INTEREST] = toString(percentage);
          return;
        }
      }

      this.data[VaultDataFields.INTEREST] = '0';
    } catch (e) {
      console.log(`Couldn't get vault APY`);
      throw e;
    }
  }

  public async loadData() {
    const wasInitiated = this.initiated;

    if (!wasInitiated) {
      await this.init();
    } else {
      await wait(5000);
    }

    if (!this.tokenAddress) {
      throw new Error(
        'No variables to query data. Was VaultData inited properly?'
      );
    }

    await Promise.all([
      this.checkAllowState(),
      this.getTokenBalance(),
      this.getStakedBalance(),
      this.getEarnedBalance(),
      this.getTotalStaked(),
      this.getAPY(),
    ]);

    return this;
  }

  public async approve() {
    try {
      if (!this.tokenAddress) {
        throw new Error('No token address in approveVault');
      }

      const config = await prepareWriteContract({
        address: this.tokenAddress,
        abi: erc20ABI,
        functionName: 'approve',
        args: [this.contractAddress, getToBlockChainAmount('100000000')],
      });

      await writeContract(config);
    } catch (e) {
      console.log(`Couldn't approve staking in vault`, e);
      throw e;
    }
  }

  public async stake(amount: StringOrNumber) {
    try {
      const config = await prepareWriteContract({
        address: this.contractAddress,
        abi: vaultStakingAbi,
        functionName: 'stake',
        args: [getToBlockChainAmount(amount)],
      });

      await writeContract(config);
    } catch (e) {
      console.log(`Couldn't stake in vault`, e);
      throw e;
    }
  }

  public async unstake(amount: StringOrNumber) {
    try {
      const config = await prepareWriteContract({
        address: this.contractAddress,
        abi: vaultStakingAbi,
        functionName: 'withdraw',
        args: [getToBlockChainAmount(amount)],
      });

      await writeContract(config);
    } catch (e) {
      console.log(`Couldn't unstake in vault`, e);
      throw e;
    }
  }

  public async getReward() {
    try {
      const config = await prepareWriteContract({
        address: this.contractAddress,
        abi: vaultStakingAbi,
        functionName: 'getReward',
      });

      await writeContract(config);
    } catch (e) {
      console.log(`Couldn't get reward in vault`, e);
      throw e;
    }
  }
}
