import { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import '@fontsource/work-sans/400.css';
import '@fontsource/work-sans/500.css';
import '@fontsource/work-sans/600.css';
import '@fontsource/work-sans/700.css';
import '@fontsource/work-sans/variable.css';
import { GlobalStyles } from '../styles/GlobalStyles';
import { themeMain } from '../styles/theme';

const StylesProvider = ({ children }: PropsWithChildren) => (
  <ThemeProvider theme={themeMain}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);

export { StylesProvider };
