import { useMemo } from 'react';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { getAbi, CryptoAddress } from '../utils/helpers/contract-helpers';
import { getEnvVariable } from '../utils/helpers/env-helpers';
import { useBlockchain } from '../providers';

type Data = { enabled: boolean } & {
  [key in 'onTransactionSuccess' | 'onConfirmSuccess']?: () => void;
} & {
  [key in 'onPrepareError' | 'onTransactionError' | 'onConfirmError']?: (
    e?: Error
  ) => void;
};
export const useApproveAllNFT = (data: Data = { enabled: false }) => {
  const blockchain = useBlockchain();

  const nftCardAddress = (blockchain?.nftCardAddress as CryptoAddress) ?? '';

  const {
    config,
    isLoading: prepareLoading,
    error: prepareError,
  } = usePrepareContractWrite({
    address: nftCardAddress,
    // @ts-ignore
    abi: nftCardAbi,
    // @ts-ignore
    functionName: 'setApprovalForAll',
    args: [nftSwapAddress, true],
    onError: (e) => {
      console.error(`Error on ApproveAllNFT prepare: `, e);
      data.onPrepareError?.();
    },
    enabled: data.enabled && !!nftCardAddress,
  });

  const {
    isLoading: writing,
    data: writeData,
    write,
    writeAsync,
  } = useContractWrite({
    ...config,
    onSuccess: data?.onTransactionSuccess,
    onError: (e) => {
      console.error(`Error on ApproveAllNFT contract write: `, e);
      data.onTransactionError?.();
    },
  });

  const { isLoading: confirmationLoading } = useWaitForTransaction({
    hash: writeData?.hash,
    confirmations: 3,
    onSuccess: (response) => {
      if (response?.status === 1) {
        data.onConfirmSuccess?.();
      } else {
        console.error(
          `Error on ApproveAllNFT confirm check: status not success`
        );
        data.onConfirmError?.();
      }
    },
    onError: (e) => {
      console.error(`Error on ApproveAllNFT confirm check: `, e);
      data.onConfirmError?.();
    },
  });

  return useMemo(
    () => ({
      loading: prepareLoading || writing || confirmationLoading,
      prepareError,
      write,
      writeAsync,
    }),
    [
      prepareLoading,
      writing,
      confirmationLoading,
      prepareError,
      write,
      writeAsync,
    ]
  );
};

const nftCardAbi = getAbi('nftCardAddress');
const nftSwapAddress = getEnvVariable('NFT_SWAP_ADDRESS') as CryptoAddress;
