import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Icon } from 'components/Icon';
import { IconType } from '../../utils/consts';

export type IconButtonProps = {
  icon: IconType;
  disabled?: boolean;
  size?: number | string;
} & HTMLAttributes<HTMLButtonElement>;

const IconButton = ({
  children,
  size = 24,
  icon,
  disabled = false,
  ...props
}: IconButtonProps) => {
  return (
    <StyledButton disabled={disabled} {...props}>
      <Icon icon={icon} size={size} />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  background: none;
`;

export { IconButton };
