import { useMemo } from 'react';
import { pickBy } from 'lodash-es';
import { isOneOfGpanelCurrencies } from '../utils/helpers/gpanel-helpers';
import { useBlockchain } from '../providers';
import { useGpanel } from '../providers/GpanelProvider';

export const useGpanelBalances = () => {
  const blockchain = useBlockchain();
  const { loading, user } = useGpanel();

  const balances = useMemo(() => {
    if (!user?.balance) return null;

    const balances = Object.entries(user.balance).reduce<
      Record<string, string>
    >(
      (acc, [key, value]) => ({
        ...acc,
        [key.toLowerCase()]: value,
      }),
      {}
    );

    if (!blockchain) return balances;

    return pickBy(
      balances,
      (_, key) =>
        isOneOfGpanelCurrencies(key) ||
        blockchain.stablecoins.some(
          ({ symbol }) => symbol.toLowerCase() === key
        )
    );
  }, [blockchain, user]);

  return useMemo(
    () => ({
      loading,
      balances,
    }),
    [loading, balances]
  );
};
