import { css } from 'styled-components';

export const asideTabPanelStyles = css`
  padding-left: 24px;
  padding-right: 24px;

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
