import { useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { setLocalStorageValue, LocalStorageKeys } from '../../utils/helpers';
import { useGpanel } from '../../providers/GpanelProvider';
import { Button, ButtonProps } from './Button';

export type GpanelLoginButtonProps = {
  withAutoClick?: boolean;
} & ButtonProps;

const GpanelLoginButton = ({
  children,
  withAutoClick,
  ...props
}: GpanelLoginButtonProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { loading, authInGpanel } = useGpanel();

  const onClick = useCallback(() => {
    setLocalStorageValue(LocalStorageKeys.GPANEL_PREV_PAGE, pathname);
    authInGpanel();
  }, [pathname, authInGpanel]);

  useLayoutEffect(() => {
    if (withAutoClick) {
      onClick();
    }
  }, [withAutoClick, onClick]);

  return (
    <Button loading={loading} size={'small'} onClick={onClick} {...props}>
      {children || t('DASHBOARD__gpanelLoginButton')}
    </Button>
  );
};

export { GpanelLoginButton };
