import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Spinner } from 'components/spinners/Spinner';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import {
  LocalStorageKeys,
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../utils/helpers';
import { prettifyResponse } from '../utils/helpers/common';
import { useLocalStorageValue, useSilentServerAuth } from '../hooks';
import {
  GetGpanelTokensDocument,
  GetGpanelTokensQuery,
  GetGpanelTokensQueryVariables,
} from '../apollo/operations';

const GpanelAuthPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const { search } = useLocation();
  const serverAuth = useLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
  const silentServerAuth = useSilentServerAuth();
  const [loading, setLoading] = useState(true);

  const nextTo = useRef(
    getLocalStorageValue(LocalStorageKeys.GPANEL_PREV_PAGE) || '/'
  );

  useEffect(() => {
    removeLocalStorageValue(LocalStorageKeys.GPANEL_PREV_PAGE);
  }, []);

  useAsync(async () => {
    try {
      const searchParams = new URLSearchParams(search);
      const savedUUID = getLocalStorageValue(LocalStorageKeys.GPANEL_TEMP_UUID);

      if (searchParams.get('message') === 'failed') {
        throw new Error('Something went wrong on backend side');
      }

      if (!savedUUID || searchParams.get('uuid') !== savedUUID) {
        throw new Error('UUID param is invalid');
      }

      const response = await client.query<
        GetGpanelTokensQuery,
        GetGpanelTokensQueryVariables
      >({
        query: GetGpanelTokensDocument,
        fetchPolicy: 'no-cache',
        variables: {
          uuid: savedUUID,
        },
      });

      const accessToken = response.data?.getGpanelUUID?.accessToken;
      const refreshToken = response.data?.getGpanelUUID?.refreshToken;

      if (response.errors || !(accessToken && refreshToken)) {
        throw new Error(
          `Couldn't get gpanel tokens: ${
            response.errors ? `: ${prettifyResponse(response.errors)}` : ''
          }`
        );
      }

      setLocalStorageValue(LocalStorageKeys.GPANEL_AUTH, {
        accessToken,
        refreshToken,
      });

      if (!serverAuth) {
        await silentServerAuth();
      }
    } catch (e) {
      console.log(`Couldn't get gpanel tokens:`, e);
      enqueueSnackbar(t('GPANEL_AUTH_PAGE__error'), {
        variant: 'error',
      });
    } finally {
      removeLocalStorageValue(LocalStorageKeys.GPANEL_TEMP_UUID);
      requestAnimationFrame(() => {
        setLoading(false);
      });
    }
  });

  if (loading) return <Spinner />;

  return <Navigate to={nextTo.current} replace />;
};

export { GpanelAuthPage };
