import { useContext, PropsWithChildren } from 'react';
import { getModalsContext } from '../utils/modals';
import { ModalsSkeleton } from '../components';
import { AgentUsersModal } from '../components/modals/AgentUsersModal';
import { CardOverviewModal } from '../components/modals/CardOverviewModal';
import { ClaimModal } from '../components/modals/ClaimModal';
import { ConfirmDelegateNFTsModal } from '../components/modals/ConfirmDelegateNFTsModal';
import { ConfirmRevokeNFTsModal } from '../components/modals/ConfirmRevokeNFTsModal';
import { UnstakeModal } from '../components/modals/UnstakeModal';
import { WalletsModal } from '../components/modals/WalletsModal';

const InnerModals = ({ children }: PropsWithChildren<$TSFixMe>) => (
  <ModalsSkeleton modals={modals} ContextProvider={InnerModalsContext.Provider}>
    {children}
  </ModalsSkeleton>
);

const modals = {
  unstake: UnstakeModal,
  wallets: WalletsModal,
  agentUsers: AgentUsersModal,
  confirmDelegateNFTs: ConfirmDelegateNFTsModal,
  confirmRevokeNFTs: ConfirmRevokeNFTsModal,
  claim: ClaimModal,
  cardOverview: CardOverviewModal,
} as const;
export const InnerModalsContext = getModalsContext<keyof typeof modals>();

export const useInnerModals = () => useContext(InnerModalsContext);

export { InnerModals };
