import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Text } from '../Text';

export type CardTagProps = {
  label: string;
} & HTMLAttributes<HTMLDivElement>;

const CardTag = ({ label, ...props }: CardTagProps) => (
  <Wrapper {...props}>
    <Label>{label}</Label>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 4px;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.getColor('emerald')};
  position: absolute;
  top: 4px;
  left: 0;

  ${({ theme }) => theme.getDownMedia('xs')} {
    width: 100%;
  }
`;

const Label = styled(Text).attrs({
  component: 'span',
  variant: 'small',
})`
  color: #000;
`;

export { CardTag };
