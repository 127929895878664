import { useMemo, HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { omit } from 'lodash-es';
import colorFn from 'color';
import { Spinner } from 'components/spinners/Spinner';
import { externalLinkProps } from '../../utils/consts/common';
import { SimpleColorName } from '../../styles/colors';
import { resetButtonStyles } from '../../styles/helpers';
import { Icon as DefIcon, IconProps } from '../Icon';

export type ButtonProps<T extends ButtonOrLink = 'button'> = Omit<
  HTMLProps<HTMLElementTagNameMap[T]>,
  'size'
> &
  DefButtonProps & {
    component?: T;
  };

const Button = <T extends ButtonOrLink>({
  loading = false,
  size = 'normal',
  color = 'primary',
  icon,
  withExternalLinkProps = false,
  component,
  children,
  ...rawProps
}: ButtonProps<T>) => {
  const props = useMemo(() => {
    const disabled = !!(loading || rawProps?.disabled);
    return {
      ...(disabled ? omit(rawProps, ['onClick']) : rawProps),
      disabled,
    };
  }, [loading, rawProps]);

  return (
    // @ts-ignore
    <Elem
      $size={size}
      $color={color}
      {...(component ? { as: component } : {})}
      {...(component === 'a' && withExternalLinkProps ? externalLinkProps : {})}
      {...props}
    >
      {icon && <Icon style={{ marginRight: '5px' }} icon={icon} size={26} />}
      {children}
      {loading && (
        <Loader>
          <Spinner size={30} />
        </Loader>
      )}
    </Elem>
  );
};

type ButtonOrLink = keyof Pick<HTMLElementTagNameMap, 'a' | 'button'>;
type Size = 'normal' | 'small' | 'tiny';
type Color = 'primary' | 'secondary' | SimpleColorName;
type DefButtonProps = {
  loading?: boolean;
  disabled?: boolean;
  size?: Size;
  color?: Color;
  icon?: IconProps['icon'];
  withExternalLinkProps?: boolean;
};

const getColorStyles: (
  disabled: boolean,
  color: Color
) => ReturnType<typeof css> = (disabled, color) => {
  switch (color) {
    case 'primary':
      return css`
        background: ${({ theme }) => theme.getColor('gradientPrimaryColor')};

        ${!disabled &&
        css`
          &:hover {
            background: ${({ theme }) =>
              theme.getColor('gradientPrimaryInvertedColor')};
          }
        `};
      `;
    case 'secondary':
      return css`
        background: ${({ theme }) => theme.getColor('gradientSecondaryColor')};

        ${!disabled &&
        css`
          &:hover {
            background: ${({ theme }) =>
              theme.getColor('gradientSecondaryInvertedColor')};
          }
        `};
      `;
    default:
      return css`
        background-color: ${({ theme }) => theme.getColor(color)};

        ${!disabled &&
        css`
          &:hover {
            background-color: ${({ theme }) =>
              colorFn(theme.getColor(color)).lighten(0.5).hex()};
          }
        `};
      `;
  }
};

const sizeStyles: {
  [key in Size]: ReturnType<typeof css>;
} = {
  tiny: css`
    padding: 4px 5px;
    font-size: ${({ theme }) => theme.pxToRem(12)};
    letter-spacing: -0.5px;
    font-weight: 500;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 500;
    }
  `,
  small: css`
    padding: 11px 9px;
    font-size: ${({ theme }) => theme.pxToRem(12)};
    letter-spacing: -0.5px;
  `,
  normal: css`
    padding: 10px 9px;
    font-size: ${({ theme }) => theme.pxToRem(14)};
  `,
};

const Elem = styled.button<{
  $size: Size;
  $color: Color;
}>`
  ${resetButtonStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
  min-width: 108px;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  line-height: ${16.4 / 14};
  text-decoration: none;
  text-transform: capitalize;
  text-align: center;
  ${({ $size }) => sizeStyles[$size]};
  ${({ $color, disabled }) => getColorStyles(!!disabled, $color)};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: auto;
      opacity: ${disabled ? 0.5 : 1};
    `};

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    width: 100%;
  }
`;

const Icon = styled(DefIcon)`
  z-index: 2;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.sm}) {
      width: 19px;
      height: 19px;
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      position: relative;
      top: -2px;
      width: 15px;
      height: 15px;
    }
  `}
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;

export { Button };
