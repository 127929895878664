import { useCallback } from 'react';
import { ethers } from 'ethers';
import { useAccount, useSigner } from 'wagmi';
import { getAbi } from '../utils/helpers/contract-helpers';
import { useBlockchain } from '../providers';

export const useCancelMarketplaceListing = () => {
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const blockchain = useBlockchain();
  const marketplaceAddress = blockchain?.marketplaceAddress;

  return useCallback(
    (listingId: number) => {
      if (!(address && marketplaceAddress && signer)) {
        throw new Error(
          'No user address or marketplace address in useCancelMarketplaceListing'
        );
      }

      const marketplaceContract = new ethers.Contract(
        marketplaceAddress,
        getAbi('marketplaceAddress'),
        signer
      );

      return marketplaceContract.cancelListing(listingId);
    },
    [address, marketplaceAddress, signer]
  );
};
