import { useState, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { pick, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { currenciesData, Currencies } from '../../utils/consts/currencies';
import { getPercent, getFormattedNumber } from '../../utils/helpers/strings';
import { VaultDataFields } from '../../utils/helpers/vault-staking';
import { TranslationKeys } from '../../utils/i18n';
import { useVault } from '../../providers';
import { BackgroundedWrapper } from '../styled';
import percentImg from '../../assets/svgs/icons/colorful/percent.svg';
import { Text } from '../Text';
import { Button as DefButton } from '../buttons';
import { Spinner as DefSpinner } from '../spinners/Spinner';
import {
  BalancesSection as DefBalancesSection,
  BalancesSectionLabel,
  BalancesItem,
  BalancesItemIcon,
  BalancesItemValue,
} from '../styled/balances';

const VaultInfo = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { loading, error, value: vaultData, retry: reloadVault } = useVault();
  const [allowing, setAllowing] = useState(false);

  const vault = vaultData?.vault;

  return (
    <Wrapper {...props}>
      {(() => {
        if (!address) {
          return <Text component={'h2'}>{t('VAULT_COMMON__noAccount')}</Text>;
        }

        if (error) {
          return <Text component={'h2'}>{t('VAULT_COMMON__loadError')}</Text>;
        }

        if (loading) {
          return (
            <Loader>
              <DefSpinner />
            </Loader>
          );
        }

        if (!vault) {
          return (
            <Text component={'h2'}>{t('VAULT_COMMON__blockchainError')}</Text>
          );
        }

        return (
          <>
            <Wrapper>
              <Balances>
                {topSectionLabels.map(([field, label]) => (
                  <BalancesSection
                    key={field}
                    $offsetToRight={toNumber(field) === VaultDataFields.EARNED}
                  >
                    <BalancesSectionLabel>{t(label)}</BalancesSectionLabel>
                    <BalancesItem>
                      <InpBalancesItemIcon />
                      <BalancesItemValue>
                        {getNumber(vault.data[field])}
                      </BalancesItemValue>
                    </BalancesItem>
                  </BalancesSection>
                ))}
                <BalancesDivider aria-hidden />
                <BalancesSection>
                  <BalancesSectionLabel>
                    {t('VAULT_COMMON__totalStaked')}
                  </BalancesSectionLabel>
                  <BalancesItem>
                    <InpBalancesItemIcon />
                    <BalancesItemValue>
                      {getNumber(vault.data[VaultDataFields.TOTAL_STAKED])}
                    </BalancesItemValue>
                  </BalancesItem>
                </BalancesSection>
                <BalancesSection>
                  <BalancesSectionLabel>
                    {t('VAULT_COMMON__interest')}
                  </BalancesSectionLabel>
                  <BalancesItem>
                    <BalancesItemIcon src={percentImg} alt={'APY'} />
                    <BalancesItemValue>
                      {getPercent(vault.data[VaultDataFields.INTEREST])}
                    </BalancesItemValue>
                  </BalancesItem>
                </BalancesSection>
              </Balances>
            </Wrapper>
            <ApproveBlock>
              <ApproveText variant={'small'} color={'osloGray'}>
                {t('VAULT_COMMON__approveText')}
              </ApproveText>
              <ApproveButton
                loading={allowing}
                disabled={vault.data[VaultDataFields.ALLOWED_STATE]}
                onClick={async () => {
                  try {
                    setAllowing(true);
                    await vault.approve();
                    reloadVault();
                  } catch (e) {
                    console.log(`Couldn't approve:`, e);
                  } finally {
                    setAllowing(false);
                  }
                }}
              >
                {t('VAULT_COMMON__approveButton')}
              </ApproveButton>
            </ApproveBlock>
          </>
        );
      })()}
    </Wrapper>
  );
};

const getNumber = (value: unknown) =>
  getFormattedNumber(value, {
    maximumFractionDigits: 4,
  }) + ` ${Currencies.INP}`;

const Wrapper = styled.div`
  position: relative;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.3);
`;

const Balances = styled(BackgroundedWrapper)`
  padding: 0.85rem 5% 1rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(155px, 1fr));
  grid-gap: 10px;

  ${({ theme }) => theme.getDownMedia('xs')} {
    grid-template-columns: 1fr;
  }
`;

const BalancesDivider = styled.div`
  margin: 7px 0 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  grid-column: 1 / -1;
`;

const InpBalancesItemIcon = styled(BalancesItemIcon).attrs({
  src: currenciesData[Currencies.INP].icon,
  alt: Currencies.INP,
})``;

const BalancesSection = styled(DefBalancesSection)<{
  $offsetToRight?: boolean;
}>`
  padding: 0;

  ${({ theme }) => theme.getUpMedia('xs')} {
    ${({ $offsetToRight = false }) =>
      $offsetToRight &&
      css`
        grid-column: 2;
      `};
  }
`;

const ApproveBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 0 5px;
  margin-top: 30px;
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    flex-direction: column;
    margin-top: 12px;
    gap: 10px;
  }
`;

const ApproveText = styled(Text)``;

const ApproveButton = styled(DefButton)``;

type DataLabels = {
  [key in Exclude<VaultDataFields, VaultDataFields.ALLOWED_STATE>]: string;
};

const dataLabels: DataLabels = {
  [VaultDataFields.BALANCE]: 'VAULT_COMMON__balance',
  [VaultDataFields.STACKED_AMOUNT]: 'VAULT_COMMON__stakedAmount',
  [VaultDataFields.EARNED]: 'VAULT_COMMON__earned',
  [VaultDataFields.TOTAL_STAKED]: 'VAULT_COMMON__totalStaked',
  [VaultDataFields.INTEREST]: 'VAULT_COMMON__interest',
};

const topSectionDataKeys = [
  VaultDataFields.BALANCE,
  VaultDataFields.STACKED_AMOUNT,
  VaultDataFields.EARNED,
];

const topSectionLabels = Object.entries(
  pick(dataLabels, topSectionDataKeys)
) as unknown as Array<[VaultDataFields, TranslationKeys]>;

export { VaultInfo };
