import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UserNftAvatarsQuery } from '../../apollo/operations';
import { Button as DefButton } from '../buttons';
import {
  CardName as DefCardName,
  CardCollection as DefCardCollection,
} from '../cards-elements';
import { CardWrapper, CardImage, CardTextContent } from '../styled/cards';

export type AvatarCardProps = {
  card: UserNftAvatarsQuery['userErc721s'][number];
  settingAvatar: boolean;
  setUserAvatar: () => void;
} & HTMLAttributes<HTMLLIElement>;

const AvatarCard = ({
  card,
  settingAvatar = false,
  setUserAvatar,
  ...props
}: AvatarCardProps) => {
  const { t } = useTranslation();
  return (
    <CardWrapper {...props}>
      <CardImage src={card.imgUrl} alt={card.name} />
      <CardTextContent>
        <CardName name={card.name} />
        {!!card.collection && (
          <CardCollection>{card.collection}</CardCollection>
        )}
        <Button
          size={'tiny'}
          loading={settingAvatar}
          onClick={() => setUserAvatar()}
        >
          {t('AVATAR_NFT_CARD_ITEM__button')}
        </Button>
      </CardTextContent>
    </CardWrapper>
  );
};

const CardName = styled(DefCardName)`
  margin-bottom: 8px;
`;

const CardCollection = styled(DefCardCollection)`
  margin-bottom: 8px;
`;

const Button = styled(DefButton)`
  margin-top: auto;
  align-self: stretch;
`;

export { AvatarCard };
