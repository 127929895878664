import { Routes as LibRoutes, Route, Navigate } from 'react-router-dom';
import { pages, getPath } from '../utils/consts';
import { LayoutWithAsideMenu } from '../layouts';

const Routes = () => (
  <LibRoutes>
    <Route path={pages.claim.path} element={<pages.claim.Component />} />
    <Route
      path={pages.depositAndWithdrawal.path}
      element={<pages.depositAndWithdrawal.Component />}
    />
    <Route path={'/'} element={<Navigate to={getPath('cashier')} replace />} />
    <Route element={<LayoutWithAsideMenu />}>
      {Object.values(layoutPages).map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
    </Route>
    <Route
      path={'*'}
      element={
        <div
          style={{
            marginTop: 100,
          }}
        >
          404
        </div>
      }
    />
  </LibRoutes>
);

const layoutPages = Object.values(pages).filter(({ path }) =>
  [pages.claim.path, pages.depositAndWithdrawal.path].every(
    (excludedPath) => excludedPath !== path
  )
);

export { Routes };
