import styled, { css } from 'styled-components';
import DefSelect, { Props as DefSelectProps } from 'react-select';
import { ColorName } from '../../styles/colors';
import { SelectDropdownIconIndicator } from './SelectDropdownIconIndicator';
import {
  SelectOptionWithIcon,
  IconPosition,
  IconSize,
} from './SelectOptionWithIcon';
import { SingleValue } from './SingleValue';

export type SelectProps<T = SelectOptionProp> = DefSelectProps<T> & {
  whiteBackground?: boolean;
  bgColor?: ColorName;
  withBorder?: boolean;
  error?: boolean;
  dropDownTop?: boolean;
  collapsedLabelToIconBreakPoint?: number;
};

// noinspection JSUnusedGlobalSymbols
const Select = <T extends SelectOptionProp>({
  options,
  whiteBackground = false,
  bgColor,
  withBorder = true,
  error = false,
  dropDownTop = false,
  ...props
}: SelectProps<T>) => (
  // @ts-ignore
  <StyledSelect
    classNamePrefix="react-select"
    // @ts-ignore ignoring themes conflict
    defaultOptions={options?.[0]}
    options={options}
    whiteBackground={whiteBackground}
    $dropDownTop={dropDownTop}
    $bgColor={bgColor}
    $whiteBackground={whiteBackground}
    $error={error}
    $withBorder={withBorder}
    // @ts-ignore
    components={{
      // ValueContainer: SelectValueContainer,
      SingleValue: SingleValue,
      Option: SelectOptionWithIcon,
      IndicatorSeparator: null,
      DropdownIndicator: (props) => (
        <SelectDropdownIconIndicator white={!whiteBackground} {...props} />
      ),
    }}
    {...props}
  />
);

export type SelectOptionProp = {
  label: string;
  value?: string;
  icon?: string;
  iconSize?: IconSize;
  iconPosition?: IconPosition;
  options?: SelectOptionProp[];
};

const StyledSelect = styled(({ ...props }: DefSelectProps) => (
  <DefSelect {...props} />
))<{
  $bgColor?: ColorName;
  $whiteBackground: boolean;
  $error: boolean;
  $withBorder: boolean;
  $dropDownTop: boolean;
  collapsedLabelToIconBreakPoint?: number;
}>`
  ${({ collapsedLabelToIconBreakPoint }) =>
    !collapsedLabelToIconBreakPoint &&
    css`
      min-width: 180px;
    `};

  .react-select__control {
    cursor: pointer;
    min-height: 34px;
    padding: 2px 0;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, $error, $whiteBackground, $withBorder }) => {
      let color: ColorName | 'transparent';

      if ($error) {
        color = 'red';
      } else if (!$whiteBackground) {
        color = $withBorder ? 'brightGray' : 'transparent';
      } else {
        color = $withBorder ? 'mineShaft' : 'brightGray';
      }

      return color === 'transparent' ? 'transparent' : theme.getColor(color);
    }};
    border-radius: 5px;
    background: ${({ theme, $bgColor, $whiteBackground }) =>
      $bgColor || $whiteBackground
        ? theme.getColor($bgColor || 'alto')
        : 'transparent'};
    //theme.getColor($bgColor || ($whiteBackground ? 'alto' : 'transparent'))};
    position: relative;
    z-index: 2;
    font-size: 1rem;

    &:hover,
    &--is-focused {
      border-color: #aaa9a9;
    }
  }

  .react-select__placeholder {
    margin-left: 15px;
    color: ${({ $whiteBackground, $error }) =>
      $error ? '#ff0303' : $whiteBackground ? '#000' : '#fff'};

    ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
      font-size: ${({ theme }) => theme.pxToRem(14)};
      margin-left: 7px;
    }
  }

  .react-select__value-container {
    padding: 2px 0 2px 8px;
  }

  .react-select__single-value {
    color: ${({ $whiteBackground }) => ($whiteBackground ? '#000' : '#fff')};
  }

  .react-select__indicator {
    padding: 0 4px;
  }

  .react-select__menu {
    margin-top: -2px;
    padding-top: 1px;
    background-color: #000;
    border-width: 1px;
    border-style: solid;
    border-color: #c9c9c9;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    position: absolute;
    top: ${({ $dropDownTop }) => ($dropDownTop ? '-180px' : '')};
    z-index: 3;
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__option {
    border-bottom: 1px solid #aaa9a9;
    background: linear-gradient(
      165.32deg,
      rgba(145, 147, 151, 0.25) -33.3%,
      rgba(54, 54, 52, 0.25) -33.27%,
      rgba(252, 252, 252, 0.25) 226.43%
    );
    cursor: pointer;
    color: white;

    &--is-focused {
    }

    &--is-selected {
      background: linear-gradient(94.1deg, #8a42bf 14.31%, #c153e8 99.58%);
      color: #fff;
    }
  }

  .react-select__option:last-child {
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export { Select, IconPosition, IconSize };
