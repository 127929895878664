import styled, { css } from 'styled-components';

export const TableCell = styled.div.attrs<{
  $header?: boolean;
}>((props) => ({
  role: props.$header ? 'columnheader' : 'cell',
}))<{
  $header?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${({ $header }) => ($header ? '16px 21px 12px' : '11px 21px')};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${16 / 14};
  border-bottom: 1px solid ${({ theme }) => theme.getColor('shark2')};
  word-break: break-all;

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding: ${({ $header }) => ($header ? '6px 10px' : '6px 10px')};
  }

  ${({ $header, theme }) =>
    $header
      ? css`
          background-color: ${theme.getColor('charade')};
        `
      : css``}

  a {
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }
`;
