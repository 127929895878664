import { useState, HTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useWithdraw2FAData } from '../../hooks';
import { useServerAuth } from '../../providers';
import { BackgroundedWrapper } from '../styled';
import { DirectDeposit } from '../DirectDeposit';
import { Onramper } from '../Onramper';
import { PermissionsBlock } from '../PermissionsBlock';
import { Withdraw2FACodeForm, GpanelOnlyAccessWithdrawForm } from '../forms';
import { Tabs, TabList, Tab, TabPanel } from '../styled/tabs';

type DepositAndWithdrawalTabsProps = {
  onTransactionHistoryClick: () => void;
} & HTMLAttributes<HTMLDivElement>;
const DepositAndWithdrawalTabs = ({
  onTransactionHistoryClick,
  ...props
}: DepositAndWithdrawalTabsProps) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const withdraw2faData = useWithdraw2FAData();

  return (
    <Wrapper as={'section'} {...props}>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>{t('DEPOSIT_AND_WITHDRAWAL_TABS__depositTab')}</Tab>
          <Tab>{t('DEPOSIT_AND_WITHDRAWAL_TABS__buyCryptoTab')}</Tab>
          <Tab>{t('DEPOSIT_AND_WITHDRAWAL_TABS__withdrawTab')}</Tab>
        </TabList>
        <TabPanel>
          <TabPanelComponent>
            <DirectDeposit
              extendedVersion
              onTransactionHistoryClick={onTransactionHistoryClick}
            />
          </TabPanelComponent>
        </TabPanel>
        <TabPanel>
          <TabPanelComponent>
            <Onramper />
          </TabPanelComponent>
        </TabPanel>
        <TabPanel>
          <TabPanelComponent>
            {withdraw2faData ? (
              <Withdraw2FACodeForm id={withdraw2faData.id} />
            ) : (
              <GpanelOnlyAccessWithdrawForm
                onTransactionHistoryClick={onTransactionHistoryClick}
              />
            )}
          </TabPanelComponent>
        </TabPanel>
      </Tabs>
    </Wrapper>
  );
};

const TabPanelComponent = ({ children }: PropsWithChildren<$TSFixMe>) => {
  const { authorized } = useServerAuth();
  return authorized ? <>{children}</> : <PermissionsBlock small />;
};

const Wrapper = styled(BackgroundedWrapper)``;

export { DepositAndWithdrawalTabs };
