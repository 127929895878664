import { useCallback, useEffect, useMemo } from 'react';
import { useMethods } from 'react-use';
import { useAccount } from 'wagmi';
import { useApolloClient } from '@apollo/client';
import { useBlockchain } from '../providers';
import {
  UserNftsDocument,
  UserNftsQuery,
  UserNftsQueryVariables,
} from '../apollo/operations';
import { useGetUserCards } from './useGetUserCards';

export const useMyNftCards = (skip: boolean) => {
  const { address } = useAccount();
  const client = useApolloClient();
  const blockchain = useBlockchain();
  const getUserCards = useGetUserCards();
  const [state, methods] = useMethods(createMethods, initialState);

  const loadServerUserCards = useCallback(async () => {
    try {
      if (!address) {
        throw new Error(`No account to get server user cards`);
      }
      if (!blockchain) {
        throw new Error('No blockchain loaded');
      }

      return await client.query<UserNftsQuery, UserNftsQueryVariables>({
        query: UserNftsDocument,
        fetchPolicy: 'network-only',
        variables: {
          account: address.toLowerCase(),
          blockchainId: blockchain.id,
        },
      });
    } catch (e) {
      console.log('Error on server user cards load', e);
      throw e;
    }
  }, [address, client, blockchain]);

  const loadBlockchainUserCards = useCallback(async () => {
    try {
      return await getUserCards();
    } catch (e) {
      console.log('Error on blockchain user cards load', e);
      throw e;
    }
  }, [getUserCards]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadData = useCallback(async () => {
    if (!address || skip) return;

    try {
      methods.setLoading(true);

      const [serverCardsResponse, blockchainCards] = await Promise.all([
        loadServerUserCards(),
        loadBlockchainUserCards(),
      ]);

      const serverCards = serverCardsResponse.data?.nfts ?? [];

      if (serverCards.length === blockchainCards.length) {
        methods.setCards(serverCards);
        methods.setProcessingCards(0);
      } else if (serverCards.length < blockchainCards.length) {
        methods.setCards(serverCards);
        methods.setProcessingCards(blockchainCards.length - serverCards.length);
      } else {
        let processingCards = 0;

        const filteredCards = serverCards.filter(
          ({ tokenId: serverCardtokenId }) => {
            const existsInChains = blockchainCards.some(
              (chainsCardsTokenId) => chainsCardsTokenId === serverCardtokenId
            );

            if (!existsInChains) {
              processingCards++;
            }

            return existsInChains;
          }
        );

        methods.setCards(filteredCards);

        methods.setProcessingCards(processingCards);
      }
    } catch (e) {
      console.log('Error on cards load', e);
      methods.reset();
    } finally {
      methods.setLoading(false);
    }
  }, [address, skip, methods, loadServerUserCards, loadBlockchainUserCards]);

  useEffect(() => {
    address && !skip ? loadData() : methods.reset();
  }, [address, skip, loadData, methods]);

  return useMemo(
    () => ({
      ...state,
      reloadCards: loadData,
    }),
    [state, loadData]
  );
};

type State = {
  loading: boolean;
  cards: ServerUserCards;
  processingCards: number;
};

type ServerUserCards = UserNftsQuery['nfts'];

const initialState: State = {
  loading: false,
  cards: [],
  processingCards: 0,
};

// noinspection JSUnusedGlobalSymbols
const createMethods = (state: State) => ({
  setLoading: (newVal?: boolean) => ({
    ...state,
    loading: newVal ?? !state.loading,
  }),
  setCards: (cards: ServerUserCards) => ({
    ...state,
    cards,
  }),
  setProcessingCards: (processingCards: number) => ({
    ...state,
    processingCards,
  }),
  reset: () => initialState,
});
