import { useMemo, PropsWithChildren } from 'react';
import { WagmiConfig } from 'wagmi';
import { getClient } from '../utils/wagmi-client';
import { useBlockchains } from './BlockchainsProvider';

const WagmiProvider = (props: PropsWithChildren) => {
  const blockchains = useBlockchains();
  const client = useMemo(() => getClient(blockchains), [blockchains]);
  return <WagmiConfig client={client} {...props} />;
};

export { WagmiProvider };
