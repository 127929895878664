import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAccount, ConnectorData } from 'wagmi';
import { removeLocalStorageValue, LocalStorageKeys } from '../utils/helpers';
import { useSilentServerAuth } from './useSilentServerAuth';

export const useWalletEventListeners = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { connector } = useAccount();
  const silentServerAuth = useSilentServerAuth();

  useEffect(() => {
    if (connector) {
      const handleUpdateEvent = (event: ConnectorData) => {
        if (event.chain?.unsupported) {
          enqueueSnackbar(t('WALLET_AUTH__unsupportedNetworkError'), {
            variant: 'warning',
          });
        }
      };

      const handleDeactivateEvent = () => {
        removeLocalStorageValue(LocalStorageKeys.CONNECTOR);
        silentServerAuth(true);
      };

      connector.addListener('change', handleUpdateEvent);
      connector.addListener('disconnect', handleDeactivateEvent);

      return () => {
        connector.removeListener('change', handleUpdateEvent);
        connector.removeListener('disconnect', handleDeactivateEvent);
      };
    }
  }, [t, connector, silentServerAuth, enqueueSnackbar]);
};
