import styled from 'styled-components';

export const CardTextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 24px;
`;
