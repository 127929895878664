import { createContext, useContext, PropsWithChildren, useMemo } from 'react';
import { useAsyncRetry } from 'react-use';
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';
import { useAccount } from 'wagmi';
import { CryptoAddress } from '../utils/helpers/contract-helpers';
import { Vault, VaultDataFields } from '../utils/helpers/vault-staking';
import { useBlockchain } from './BlockchainProvider';

const VaultProvider = (props: PropsWithChildren) => {
  const { address: userAddress } = useAccount();
  const blockchain = useBlockchain();

  const vault = useMemo(
    () =>
      userAddress && blockchain?.inpVaultAddress
        ? new Vault(userAddress, blockchain.inpVaultAddress as CryptoAddress)
        : null,
    [userAddress, blockchain]
  );

  const data = useAsyncRetry(async () => {
    if (!vault) return null;
    return {
      vault: await vault.loadData(),
    };
  }, [vault]);

  return <VaultContext.Provider value={data} {...props} />;
};

const VaultContext = createContext<
  AsyncStateRetry<{
    vault: Vault;
  } | null>
>({
  loading: false,
  error: undefined,
  value: undefined,
  retry: () => undefined,
});

export const useVault = () => useContext(VaultContext);

export { VaultDataFields };

export { VaultProvider };
