import { sample, isEmpty, toNumber } from 'lodash-es';
import { configureChains, createClient, Connector, Chain } from 'wagmi';
import { bsc, bscTestnet } from 'wagmi/chains';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { infuraProvider } from 'wagmi/providers/infura';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import { WalletConnectLegacyConnector } from '@wagmi/core/connectors/walletConnectLegacy';
import { getEnvVariable } from './helpers/env-helpers';

const getProvidersAndConnectors = (initChains: Chain[]) => {
  if (isEmpty(initChains)) {
    initChains = [
      [bsc, bscTestnet].find(
        ({ id }) => id === toNumber(getEnvVariable('BSC_CHAIN_ID'))
      )!,
    ];
  }

  const { provider, webSocketProvider, chains } = configureChains(initChains, [
    infuraProvider({
      apiKey: getEnvVariable('INFURA_ID'),
    }),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: sample(chain.rpcUrls.default.http)!,
      }),
    }),
    publicProvider(),
  ]);

  const options = {
    shimDisconnect: false,
  };

  const injectedConnector = new InjectedConnector({
    chains,
    options,
  });

  const metaMaskConnector = new MetaMaskConnector({
    chains,
    options: {
      ...options,
      UNSTABLE_shimOnConnectSelectAccount: true,
    },
  });

  /*const walletConnectConnector = new WalletConnectConnector({
    chains,
    options: {
      projectId: getEnvVariable('WALLET_CONNECT_PROJECT_ID'),
      showQrModal: true,
    },
  });*/

  const walletConnectConnector = new WalletConnectLegacyConnector({
    options: {
      // infuraId: getEnvVariable('INFURA_ID'),
      qrcode: true,
    },
  });

  return [
    provider,
    webSocketProvider,
    [
      injectedConnector,
      metaMaskConnector,
      walletConnectConnector,
    ] as Connector[],
  ] as const;
};

export const getClient = (chains: Chain[]) => {
  const [provider, webSocketProvider, connectors] =
    getProvidersAndConnectors(chains);

  return createClient({
    provider,
    webSocketProvider,
    connectors,
    autoConnect: true,
  });
};

/*export const isChainSupported = memoize((chainId: number) =>
  chains.map(({ id }) => id).includes(chainId)
);*/

/*export const isChainTestnet = memoize(
  (chainId: number) => chains.find(({ id }) => id === chainId)?.testnet
);*/
