import {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
  HTMLAttributes,
} from 'react';
import styled, { css } from 'styled-components';
import {
  isEmpty,
  toString,
  toNumber,
  isArray,
  compact,
  isFunction,
} from 'lodash-es';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';
import {
  useClaimDataQuery,
  useClaimMutation,
  ClaimDataDocument,
} from '../apollo/operations';
import hourglass from '../assets/img/hourglass.svg';
import pokerCards from '../assets/img/poker-cards.svg';
import { resetListStyles } from '../styles/helpers';
import { Text as DefText, TextProps } from './Text';
import { Button } from './buttons';
import { Select } from './form-elements';
import { Spinner } from './spinners/Spinner';

export type ClaimProps = {
  inModal?: boolean;
  onNFTsPress?: () => void;
  onSuccess?: () => void;
} & HTMLAttributes<HTMLDivElement>;

const Claim = ({
  inModal = false,
  onNFTsPress,
  onSuccess,
  ...props
}: ClaimProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const currentChosenOptionIdRef = useRef<Nullish<number>>(null);

  const [leftTime, setLeftTime] = useState('');

  const {
    loading,
    error,
    data: claimResponse,
  } = useClaimDataQuery({
    fetchPolicy: 'cache-and-network',
  });

  const total = claimResponse?.NFTAvailableTickets?.totalNFTs ?? 0;
  const releaseTime = claimResponse?.NFTAvailableTickets?.nextReleaseTime;
  const rawOptions = claimResponse?.NFTAvailableTickets?.claimOptions;

  const options = useMemo(
    () =>
      !isEmpty(rawOptions)
        ? rawOptions!.map((option) => ({
            label: `${option!.amount} x ${option!.label}`,
            value: toString(option!.id),
          }))
        : [],
    [rawOptions]
  );

  useEffect(() => {
    const value = options[0]?.value;
    if (value) {
      currentChosenOptionIdRef.current = toNumber(value);
    }
  }, [options]);

  const calculateLeftTime = useCallback(() => {
    if (!releaseTime || dayjs.utc(releaseTime).isBefore(dayjs.utc()))
      return '0';

    const differenceMS = dayjs.utc(releaseTime).diff(dayjs.utc());

    const duration = dayjs.duration(differenceMS);

    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let template = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

    if (days) {
      template = `${days} days ${template}`;
    }

    if (months) {
      template = `${months} months ${template}`;
    }

    return template;
  }, [releaseTime]);

  useEffect(() => {
    setLeftTime(calculateLeftTime());

    const t = setTimeout(() => {
      setLeftTime(calculateLeftTime());
    }, 60 * 1000);

    return () => {
      clearInterval(t);
    };
  }, [calculateLeftTime]);

  const displayMutationError = useCallback(
    (error: Error | ApolloError) => {
      console.error(`Claim mutation error`, error);
      enqueueSnackbar(t('CLAIM_BLOCK__mutationError'));
    },
    [enqueueSnackbar, t]
  );

  const [claim, { loading: claiming }] = useClaimMutation({
    fetchPolicy: 'no-cache',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ClaimDataDocument,
      },
    ],
    onCompleted: (data) => {
      const message = data.NFTReleaseTicket?.message;
      if (message === 'OK') {
        enqueueSnackbar(t('CLAIM_BLOCK__mutationSuccess'));
        onSuccess?.();
      } else {
        displayMutationError(new Error('Status no ok'));
      }
    },
    onError: displayMutationError,
  });

  return (
    <Wrapper $inModal={inModal} {...props}>
      {(() => {
        if (loading) {
          return (
            <Spinner
              style={{
                margin: 'auto',
              }}
            />
          );
        }

        if (error) {
          return <InfoText>{t('CLAIM_BLOCK__loadError')}</InfoText>;
        }

        return (
          <>
            <StatsList>
              <StatsListItem onClick={onNFTsPress}>
                <StatIcon src={pokerCards} alt={'Stats icon'} />
                <StatDesc>{t('CLAIM_BLOCK__totalNFTs')}</StatDesc>
                <StatValue>{total}</StatValue>
              </StatsListItem>
              <StatsListItem>
                <StatIcon src={hourglass} alt={'Stats icon'} />
                <StatDesc>{t('CLAIM_BLOCK__timeRemaining')}:</StatDesc>
                <StatValue>{leftTime}</StatValue>
              </StatsListItem>
            </StatsList>
            {!isEmpty(options) ? (
              <>
                <ClaimOptions>
                  <ClaimOptionsLabel>
                    {t('CLAIM_BLOCK__optionsLabel')}:
                  </ClaimOptionsLabel>
                  <ClaimOptionsSelect
                    defaultValue={options[0]}
                    options={options}
                    onChange={(newValue) => {
                      const value =
                        newValue && 'value' in newValue && newValue?.value;

                      if (value) {
                        currentChosenOptionIdRef.current = toNumber(value);
                      }
                    }}
                    isSearchable={false}
                  />
                </ClaimOptions>
                <ClaimButton
                  loading={claiming}
                  onClick={() => {
                    const option =
                      isArray(rawOptions) &&
                      compact(rawOptions).find(
                        ({ id }) => id === currentChosenOptionIdRef.current
                      );

                    const { id: ticketId, amount } = option || {};

                    if (!(ticketId && amount)) {
                      enqueueSnackbar('Error', { variant: 'error' });
                      return;
                    }

                    claim({
                      variables: {
                        ticketId,
                        amount,
                      },
                    });
                  }}
                >
                  {t('CLAIM_BLOCK__button')}
                </ClaimButton>
                <DescText>{t('CLAIM_BLOCK__desc')}</DescText>
              </>
            ) : (
              <InfoText
                style={{
                  marginTop: 100,
                }}
              >
                {t('CLAIM_BLOCK__noClaimOptions')}
              </InfoText>
            )}
          </>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  $inModal: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 30px 40px;

  ${({ $inModal }) =>
    $inModal &&
    css`
      ${({ theme }) => theme.getDownMedia('xs')} {
        padding: 30px 0;
      }
    `}
`;

const InfoText = styled(DefText)`
  text-align: center;
`;

const StatsList = styled.ul`
  ${resetListStyles};
  display: flex;
  justify-content: space-between;
`;

const StatsListItem = styled.li<{
  onClick?: () => void;
}>`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 12px;
  align-content: space-between;
  grid-template-areas:
    'a b'
    'a c';
  ${({ onClick }) =>
    isFunction(onClick) &&
    css`
      cursor: pointer;
    `}
`;

const StatIcon = styled.img`
  width: 40px;
  height: 40px;
  grid-area: a;
`;

const Text = styled((props: TextProps) => (
  <DefText weight={'light'} {...props} />
))`
  font-size: ${({ theme }) => theme.pxToRem(12)};
  line-height: ${14 / 12};
`;

const StatDesc = styled(Text)`
  grid-area: b;
`;

const StatValue = styled(Text)`
  grid-area: c;
  font-weight: 700;
  font-size: 18px;
`;

const ClaimOptions = styled.div`
  margin-top: 60px;
`;

const ClaimOptionsLabel = styled(Text)`
  font-size: ${({ theme }) => theme.pxToRem(12)};
  line-height: ${14 / 12};
`;

const ClaimOptionsSelect = styled(Select)`
  margin-top: 10px;
`;

const ClaimButton = styled(Button)`
  align-self: center;
  margin-top: 60px;
  min-width: 220px;
`;

const DescText = styled(Text)`
  margin-top: 38px;
`;

export { Claim };
