import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikFormProps } from 'formik';
import * as Yup from 'yup';
import { TranslationKeys } from '../../utils/i18n';
import { useMyNft } from '../../providers';
import { Text } from '../Text';
import { Button as DefButton } from '../buttons';
import { FormikInput, FormikInputProps } from '../formik-elements';

export type CardNameFormProps = {
  saveCardName: (name: string) => void;
} & FormikFormProps;

const CardNameForm = ({ saveCardName, ...props }: CardNameFormProps) => {
  const { t } = useTranslation();
  const { card, cardIsProcessing } = useMyNft();

  return (
    <Formik<FormValues>
      initialValues={{
        cardName: '',
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async ({ cardName: name }) => {
        saveCardName(name);
      }}
    >
      {() => (
        <Form {...props}>
          <Text
            style={{
              marginBottom: 10,
            }}
          >
            {t('CARDNAME_FORM__inputDesc')}
          </Text>
          {Object.entries(fieldsData).map(
            ([name, { placeholder, ...props }]) => (
              <Input
                key={name}
                name={name}
                {...(placeholder
                  ? {
                      placeholder: t(
                        placeholder as TranslationKeys
                      ).toUpperCase(),
                    }
                  : {})}
                {...props}
              />
            )
          )}
          <Button
            // @ts-ignore
            type={'submit'}
            loading={cardIsProcessing}
            disabled={!card?.tokenId}
          >
            {t('CARDNAME_FORM__button')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikInput)``;

const Button = styled(DefButton)`
  margin-top: 10px;
`;

type FormValues = {
  cardName: string;
};

const schema = Yup.object().shape({
  cardName: Yup.string().required('Field is required'),
});

const fieldsData: {
  [key in keyof FormValues]: Omit<FormikInputProps, 'name'>;
} = {
  cardName: {
    placeholder: 'CARDNAME_FORM__inputPlaceholder',
  },
};

export { CardNameForm };
