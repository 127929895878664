import { useState, useMemo, AnchorHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { isObject } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Link as DefLink, LinkProps, useLocation } from 'react-router-dom';
import { Text as DefText, TextProps } from 'components/Text';
import { TranslationKeys } from '../utils/i18n';
import arrowIcon from '../assets/svgs/sub-menu-arrow.svg';
import { desktopDefaultSize } from '../styles/consts';
import { resetButtonStyles, resetListStyles } from '../styles/helpers';

export type SidebarMenuItemProps = MenuItemData;

const SidebarMenuItem = ({ title, icon, ...props }: SidebarMenuItemProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [subMenuOpened, setSubMenuOpened] = useState(false);

  /*const handleMenuItemClick = () => {
    if (link.otherPlatform) {
      const anchor = document.createElement('a');
      anchor.href = link.url;
      anchor.target = '_blank';
      anchor.click();
    } else {
      navigate(link.url);
    }
  };*/

  const activeLink = useMemo(() => {
    if (!('linkProps' in props && props.linkProps.to)) {
      return false;
    }

    const { to } = props.linkProps;

    return isObject(to) && 'pathname' in to
      ? to.pathname === pathname
      : to === pathname;
  }, [pathname, props]);

  if ('linkProps' in props) {
    return (
      <Link $active={activeLink} {...props.linkProps}>
        <Icon src={icon} alt={t(title)} />
        <Label>{t(title)}</Label>
      </Link>
    );
  }

  return (
    <BlockWithSubmenu $active={subMenuOpened}>
      <Button
        $active={subMenuOpened}
        onClick={() => setSubMenuOpened((prevState) => !prevState)}
      >
        <Icon src={icon} alt={t(title)} />
        <Label>{t(title)}</Label>
        <DropdownIcon $rotated={subMenuOpened} aria-hidden />
      </Button>
      <SubmenuList $closed={!subMenuOpened}>
        {props.items.map((itemProps, index) => (
          <SubmenuListItem key={index}>
            <SidebarMenuItem {...itemProps} />
          </SubmenuListItem>
        ))}
      </SubmenuList>
    </BlockWithSubmenu>
  );
};

type MenuItemData = {
  icon: string;
  title: TranslationKeys;
} & (
  | ({
      linkProps: LinkProps;
    } & AnchorHTMLAttributes<HTMLAnchorElement>)
  | {
      items: MenuItemData[];
    }
);

const Label = styled((props: TextProps) => (
  <DefText component={'span'} weight={'semi-bold'} {...props} />
))`
  margin-left: 23px;
  transition: color 0.15s ease-in-out;
  font-size: ${16 / desktopDefaultSize}rem;
  line-height: ${19 / 16};

  color: ${({ theme }) => theme.getColor('manatee')};

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-left: 15px;
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      margin-left: 12px;
    }
  `};
`;

type CommonStylesProps = { $active: boolean };

const leftPadding = 33;
const commonStyles = css<CommonStylesProps>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px 20px ${leftPadding}px;
  cursor: pointer;
  text-decoration: none;

  ${({ theme }) => theme.getDownMedia('sm')} {
    padding: 28px;
  }

  ${({ theme }) => theme.getDownMedia('xs')} {
    padding: 22px 28px;
  }

  ${({ $active }) =>
    $active
      ? css`
          ${Label} {
            color: #fff;
          }
        `
      : css`
          &:hover {
            ${Label} {
              color: #fff;
            }
          }
        `}
`;

const Button = styled.button<CommonStylesProps>`
  ${resetButtonStyles};
  ${commonStyles};
`;

const Link = styled(DefLink)<CommonStylesProps>`
  ${commonStyles};
  cursor: pointer;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;

  ${({ theme }) => theme.getDownMedia('sm')} {
    width: 25px;
    height: 25px;
  }
  ${({ theme }) => theme.getDownMedia('xs')} {
    width: 20px;
    height: 20px;
  }
`;

const DropdownIcon = styled.div<{
  $rotated: boolean;
}>`
  width: 10px;
  height: 8px;
  margin-left: 4px;
  background-image: url(${arrowIcon});
  background-size: contain;
  background-repeat: no-repeat;
  ${({ $rotated }) =>
    $rotated &&
    css`
      transform: rotate(180deg);
    `};
`;

const BlockWithSubmenu = styled.div<CommonStylesProps>`
  ${({ theme, $active }) =>
    $active &&
    css`
      background-color: ${theme.getColor('charade')};
    `}
`;

const SubmenuList = styled.ul<{
  $closed: boolean;
}>`
  ${resetListStyles};
  padding: ${({ $closed }) => ($closed ? 0 : '10px 0')};
  height: ${({ $closed }) => ($closed ? 0 : 'auto')};
  overflow: hidden;
  margin-left: ${leftPadding}px;
  position: relative;
  &:before {
    content: '';
    width: 1px;
    height: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 15px;
    left: 0;
  }

  ${Link}, ${Button} {
    padding: 6px 10px;

    ${({ theme }) => theme.getDownMedia('sm')} {
      padding: 28px 28px 28px 10px;
    }

    ${({ theme }) => theme.getDownMedia('xs')} {
      padding: 22px 28px 22px 10px;
    }
  }

  ${Label} {
    margin-left: 18px;
  }
`;

const SubmenuListItem = styled.li`
  & + & {
    margin-top: 27px;
  }
`;

export { SidebarMenuItem };
