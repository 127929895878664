import { useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { compact } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useDelegatedNfTsQuery } from '../apollo/operations';
import { resetListStyles } from '../styles/helpers';
import { Button as DefButton } from './buttons/Button';
import { DelegatedCardItem } from './cards';

type ClaimCardsProps = {
  onBackClick: () => void;
} & HTMLAttributes<HTMLDivElement>;

const ClaimCards = ({ onBackClick, ...props }: ClaimCardsProps) => {
  const { t } = useTranslation();
  const { loading: loadingNFTs, data: nftsResponse } = useDelegatedNfTsQuery();

  const cards = useMemo(() => {
    if (loadingNFTs) return [];
    return compact(nftsResponse?.NFTUserConnected?.rented);
  }, [loadingNFTs, nftsResponse]);

  return (
    <Wrapper {...props}>
      <CardsList>
        {cards.map((card) => (
          <DelegatedCardItem card={card} />
        ))}
      </CardsList>
      <Button onClick={onBackClick}>{t('CLAIM_CARDS__button')}</Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const CardsList = styled.ul`
  ${resetListStyles};
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 15px;
  justify-content: center;
`;

const Button = styled(DefButton)`
  margin-top: 40px;
`;

export { ClaimCards };
