import { isString } from 'lodash-es';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import {
  RefreshGpanelAccessTokenDocument,
  RefreshGpanelAccessTokenMutation,
  RefreshGpanelAccessTokenMutationVariables,
} from '../../apollo/operations';
import { getClient } from '../apollo-client';
import { isInpTokenSymbol } from '../consts/currencies';
import { prettifyResponse } from './common';
import { getEnvVariable } from './env-helpers';
import {
  getLocalStorageValue,
  LocalStorageKeys,
  removeLocalStorageValue,
  setLocalStorageValue,
} from './localStorage';

export enum GpanelAPICallTypes {
  USER_INFO,
  USER_WALLET,
}

type ApiResponses = {
  [GpanelAPICallTypes.USER_INFO]: {
    uuid: string;
    username: string;
    email: string;
    'websocket-token': string;
    roles: Array<'player'>;
  };
  [GpanelAPICallTypes.USER_WALLET]: {
    balances: {
      [key in 'BUSD' | 'FUN' | 'USD']: {
        [key2 in 'formatted' | 'row']: string;
      };
    };
  };
};

const defApiPointParams: {
  [key in GpanelAPICallTypes]: AxiosRequestConfig;
} = {
  [GpanelAPICallTypes.USER_INFO]: {
    url: '/user/v1alpha1',
  },
  [GpanelAPICallTypes.USER_WALLET]: {
    url: '/wallet/v1alpha1',
  },
};

const getAPIUrl = () => {
  const domain = getEnvVariable('INPOKER_OAUTH2_URL');

  if (!domain) {
    throw new Error('No inpoker oauth domain in env file');
  }

  return `${domain}/api`;
};

const api = axios.create({
  baseURL: getAPIUrl(),
});

const getTokens = () => {
  const authTokens = getLocalStorageValue(LocalStorageKeys.GPANEL_AUTH);
  if (!authTokens) {
    throw new Error('No auth tokens for gpanelApi call');
  }
  return authTokens;
};

let requeryTokenPromise: Maybe<Promise<void>>;

const handle401Error = async () => {
  if (requeryTokenPromise) return requeryTokenPromise;

  const requeryCall = (async () => {
    const { refreshToken } = getTokens();
    const client = await getClient();
    const response = await client.mutate<
      RefreshGpanelAccessTokenMutation,
      RefreshGpanelAccessTokenMutationVariables
    >({
      mutation: RefreshGpanelAccessTokenDocument,
      fetchPolicy: 'no-cache',
      variables: {
        refreshToken,
      },
    });

    const newAccessToken = response.data?.refreshGpanel?.accessToken;
    const newRefreshToken = response.data?.refreshGpanel?.refreshToken;

    if (response.errors || !(newAccessToken && newRefreshToken)) {
      throw new Error(
        `Couldn't update tokens in gpanel api call: ${
          response.errors ? `: ${prettifyResponse(response.errors)}` : ''
        }`
      );
    }

    setLocalStorageValue(LocalStorageKeys.GPANEL_AUTH, {
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    });
  })();

  requeryTokenPromise = requeryCall;

  requeryCall.finally(() => {
    requeryTokenPromise = undefined;
  });

  return requeryCall;
};

export const gpanelApi = async <T extends GpanelAPICallTypes>(
  type: T,
  params?: AxiosRequestConfig
) =>
  new Promise<AxiosResponse<ApiResponses[T]>>((resolve, reject) => {
    let checked404 = false;

    const run = async () => {
      try {
        const authTokens = getTokens();

        api
          .request<ApiResponses[T]>({
            headers: {
              Authorization: `Bearer ${authTokens.accessToken}`,
            },
            method: 'GET',
            ...defApiPointParams[type],
            ...params,
          })
          .then(resolve)
          .catch((e: AxiosError) => {
            if (!e.response || e.response.status !== 401) {
              throw e;
            }

            if (checked404) {
              removeLocalStorageValue(LocalStorageKeys.GPANEL_AUTH);
              throw e;
            }

            checked404 = true;

            handle401Error()
              .catch((e) => {
                removeLocalStorageValue(LocalStorageKeys.GPANEL_AUTH);
                throw e;
              })
              .then(run);
          });
      } catch (e) {
        console.log(`Error on gpanel api call`, e);
        reject(e);
      }
    };

    run();
  });

const isUSDSymbol = (symbol: Maybe<string>) =>
  isString(symbol) && symbol.toLowerCase() === 'usd';

export const isOneOfGpanelCurrencies = (symbol: Maybe<string>) =>
  [isUSDSymbol, isInpTokenSymbol].some((check) => check(symbol));
