import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Text } from 'components/Text';
import { IconButton } from 'components/buttons/IconButton';
import { useAccount } from 'wagmi';
import { useShortenedAddress } from '../hooks';
import { AccountAvatarAndName } from './AccountAvatarAndName';
import { AppbarGpanel as DefAppbarGpanel } from './AppbarGpanel';
import { BlockchainsSelect as DefBlockchainsSelect } from './BlockchainsSelect';
import { ETHAccountButton as DefETHAccountButton } from './buttons';

export type AppbarProps = {
  openMobileMenu: () => void;
} & HTMLAttributes<HTMLDivElement>;

const Appbar = ({ openMobileMenu, ...props }: AppbarProps) => {
  const { address } = useAccount();
  const shortenedAddress = useShortenedAddress(address);

  return (
    <Wrapper {...props}>
      <HamburgerMenuButton onClick={openMobileMenu} />
      <AccountAvatarAndName />
      <WalletInformation>
        {address ? (
          <>
            <UserAddress onClick={() => navigator.clipboard.writeText(address)}>
              {shortenedAddress}
            </UserAddress>
            <BlockchainsSelect />
          </>
        ) : (
          <ETHAccountButton />
        )}
      </WalletInformation>
      <AppbarGpanel />
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.getColor('woodsmoke')};

  ${({ theme }) => theme.getDownMedia('sm')} {
    height: 60px;
    padding: 10px 20px 10px 20px;
  }
`;

const HamburgerMenuButton = styled((props) => (
  <IconButton icon="navbarIcon" {...props} />
))`
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.sm}) {
      display: none;
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      padding: 0;
    }
  `}
`;

const Block = styled.div`
  display: flex;
  align-items: center;
`;

const WalletInformation = styled(Block)``;

const UserAddress = styled(Text)`
  cursor: copy;
  transition: color 5ms linear;

  &:active {
    color: ${({ theme }) => theme.getColor('silverChalice')};
  }

  ${({ theme }) => theme.getDownMedia(568)} {
    display: none;
  }
`;

const BlockchainsSelect = styled(DefBlockchainsSelect)`
  margin-left: 22px;
`;

const AppbarGpanel = styled(DefAppbarGpanel)`
  margin-left: auto;
`;

const ETHAccountButton = styled(DefETHAccountButton)`
  margin-left: 22px;
`;

export { Appbar };
