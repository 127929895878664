export enum SiteLinks {
  TERMS_AND_CONDITIONS = 'https://inpoker.gitbook.io/inpoker/terms-of-use/terms-and-conditions',
  SUPPORT_EMAIL = 'mailto:support@inpoker.app',
  LEARN_MORE = 'https://inpoker.gitbook.io/inpoker/user-guides/staking',
  IOS_APP = 'https://signapps.info/i_34_1656663343',
  IOS_APP_MORE = 'https://inpoker.gitbook.io/inpoker/user-guides/apps-download',
  PANCAKESWAP = 'https://pancakeswap.finance/swap?outputCurrency=0xf0f88d49d3fea2ee07002b9565f47666ba7439ea&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  INSWAP = 'https://inpswap.com',
  CLAIM_LEARN_MORE = 'https://inpoker.app',
  OPEN_SEA_LISTING = 'https://opensea.io/assets/ethereum',
}
