import styled, { css } from 'styled-components';
import { isNil } from 'lodash-es';
import { ColorName } from '../styles/colors';
import {
  headerFontFamilyStyles,
  normalFontFamilyStyles,
} from '../styles/helpers';

export type TextProps<T extends TextTagName = 'p'> = {
  component?: T;
  variant?: Variant;
  weight?: Weight;
  letterSpacing?: LetterSpacing;
  headerFontFamily?: boolean;
  color?: ColorName;
} & JSX.IntrinsicElements[T];

const Text = <T extends TextTagName>({
  component,
  variant,
  weight,
  letterSpacing,
  headerFontFamily,
  color,
  ...props
}: TextProps<T>) => {
  return (
    // @ts-ignore
    <StyledText
      {...(component ? { as: component } : {})}
      $variant={variant || tagVariantMap[component || 'p']}
      $weight={weight}
      $letterSpacing={letterSpacing}
      $headerFontFamily={headerFontFamily}
      $color={color}
      {...props}
    />
  );
};

type TextTagName = keyof Pick<
  HTMLElementTagNameMap,
  | 'a'
  | 'label'
  | 'strong'
  | 'span'
  | 'p'
  | 'h6'
  | 'h5'
  | 'h4'
  | 'h3'
  | 'h2'
  | 'h1'
>;
type LetterSpacing = 'normal' | 'small';
type Variant = 'small' | 'normal' | 'h4' | 'h3' | 'h2' | 'h1';
type Weight = 'light' | 'normal' | 'medium' | 'semi-bold' | 'bold';

const tagVariantMap: {
  [key in TextTagName]: Variant;
} = {
  a: 'normal',
  label: 'normal',
  span: 'normal',
  p: 'normal',
  strong: 'normal',
  h6: 'h3',
  h5: 'h3',
  h4: 'h3',
  h3: 'h3',
  h2: 'h2',
  h1: 'h1',
};

const letterSpacingStyles: {
  [key in LetterSpacing]: ReturnType<typeof css>;
} = {
  small: css`
    letter-spacing: -0.9px;
  `,
  normal: css`
    letter-spacing: normal;
  `,
};

const weightStyles: {
  [key in Weight]: ReturnType<typeof css>;
} = {
  light: css`
    font-weight: 300;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 300;
    }
  `,
  normal: css`
    font-weight: 400;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 400;
    }
  `,
  medium: css`
    font-weight: 500;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 500;
    }
  `,
  'semi-bold': css`
    font-weight: 600;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 600;
    }
  `,
  bold: css`
    font-weight: 700;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 700;
    }
  `,
};

const variantStyles: {
  [key in Variant]: ReturnType<typeof css>;
} = {
  small: css`
    ${weightStyles['medium']};
    ${letterSpacingStyles['small']};
    font-size: ${({ theme }) => theme.pxToRem(12)};
    line-height: ${17 / 12};
    ${({ theme }) => theme.getDownMedia('xs')} {
      font-size: ${({ theme }) => theme.pxToRem(10)};
    }
  `,
  normal: css`
    font-size: 1rem;
  `,
  h4: css`
    font-size: 1rem;
    ${weightStyles['semi-bold']};
  `,
  h3: css`
    font-size: ${({ theme }) => theme.pxToRem(16)};

    ${({ theme }) => theme.getDownMedia('sm')} {
      font-size: ${({ theme }) => theme.pxToRem(16)};
    }

    ${({ theme }) => theme.getDownMedia('xs')} {
      font-size: ${({ theme }) => theme.pxToRem(16)};
    }
  `,
  h2: css`
    ${weightStyles['semi-bold']};
    font-size: ${({ theme }) => theme.pxToRem(19)};
    line-height: ${22.3 / 19};

    ${({ theme }) => theme.getDownMedia('sm')} {
      font-size: ${({ theme }) => theme.pxToRem(19)};
    }

    ${({ theme }) => theme.getDownMedia('xs')} {
      font-size: ${({ theme }) => theme.pxToRem(19)};
    }
  `,
  h1: css`
    ${weightStyles['bold']};
    font-size: ${({ theme }) => theme.pxToRem(20)};
    line-height: ${23.5 / 20};

    ${({ theme }) => theme.getDownMedia('sm')} {
      font-size: ${({ theme }) => theme.pxToRem(20)};
    }

    ${({ theme }) => theme.getDownMedia('xs')} {
      font-size: ${({ theme }) => theme.pxToRem(20)};
    }
  `,
};

const StyledText = styled.p<{
  $variant?: Variant;
  $weight?: Weight;
  $letterSpacing?: LetterSpacing;
  $color?: ColorName;
  $headerFontFamily?: boolean;
}>`
  ${({ $variant = 'normal' }) => variantStyles[$variant]};
  ${({ $weight }) => $weight && weightStyles[$weight]};
  ${({ $letterSpacing }) =>
    $letterSpacing && letterSpacingStyles[$letterSpacing]};
  ${({ $headerFontFamily }) =>
    !isNil($headerFontFamily) &&
    ($headerFontFamily ? headerFontFamilyStyles : normalFontFamilyStyles)};
  color: ${({ theme, $color }) => ($color ? theme.getColor($color) : '#fff')};
`;

export { Text };
