import { PropsWithChildren } from 'react';
import { IconButton } from 'components/buttons/IconButton';
import {
  SnackbarProvider as DefSnackbarProvider,
  useSnackbar,
  SnackbarKey,
} from 'notistack';

type CloseSnackbarProps = {
  snackbarKey: SnackbarKey;
};

const CloseButton = ({
  snackbarKey,
}: PropsWithChildren<CloseSnackbarProps>) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      aria-label={'Close Snackbar'}
      icon={'closeIcon'}
      onClick={() => closeSnackbar(snackbarKey)}
      size={20}
    />
  );
};

const SnackbarProvider = ({ children }: PropsWithChildren) => {
  return (
    <DefSnackbarProvider
      action={(snackbarKey) => <CloseButton snackbarKey={snackbarKey} />}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      maxSnack={5}
    >
      {children}
    </DefSnackbarProvider>
  );
};

export { SnackbarProvider };
