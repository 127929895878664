import { HTMLAttributes } from 'react';
import styled from 'styled-components';

export type DownArrowProps = HTMLAttributes<HTMLDivElement>;

const DownArrow = (props: DownArrowProps) => (
  <Wrapper {...props}>
    <svg
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.707 4.293a1 1 0 0 1-1.414 0L.707 1.707C.077 1.077.523 0 1.414 0h5.172c.89 0 1.337 1.077.707 1.707L4.707 4.293Z"
        fill="currentColor"
      />
    </svg>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 10px;
  height: 5px;
  box-sizing: content-box;
  color: #fff;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export { DownArrow };
