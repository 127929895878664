import { useCallback } from 'react';
import { compact } from 'lodash-es';
import { ethers, BigNumber } from 'ethers';
import { useProvider, useAccount } from 'wagmi';
import { getAbi } from '../utils/helpers/contract-helpers';
import { useBlockchain } from '../providers';

export const useGetUserCards = () => {
  const provider = useProvider();
  const blockchain = useBlockchain();
  const { address } = useAccount();

  return useCallback(async () => {
    if (!blockchain) {
      throw new Error('No blockchain in GetUserCards');
    }

    const { nftCardAddress } = blockchain;

    if (!(address && nftCardAddress)) {
      throw new Error('No user address or inpAddress in GetUserCards');
    }

    const contract = new ethers.Contract(
      nftCardAddress,
      getAbi('nftCardAddress'),
      provider
    );

    const data = (await contract.tokensOfOwner(address)) as BigNumber[];

    return compact(
      (data ?? []).map((tokenBN) =>
        BigNumber.isBigNumber(tokenBN) ? tokenBN.toString() : null
      )
    );
  }, [address, provider, blockchain]);
};
