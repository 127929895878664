import { css } from 'styled-components';
import { isUndefined } from 'lodash-es';

export const withDividerStyles = ({
  desktopOffset,
  mobileOffset,
}: {
  desktopOffset: string | number;
  mobileOffset?: string | number;
}) => css`
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: -${desktopOffset}px;
    left: 0;
    background: rgba(255, 255, 255, 0.1);

    ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
      ${!isUndefined(mobileOffset)
        ? css`
            top: -${mobileOffset}px;
          `
        : css`
            display: none;
          `}
    }
  }
`;
