import styled from 'styled-components';
import { DropdownIndicatorProps } from 'react-select';
import { DownArrow as DefDownArrow } from '../DownArrow';

export type SelectDropdownIconIndicatorProps = {
  white?: boolean;
} & DropdownIndicatorProps;

const SelectDropdownIconIndicator = ({
  white = true,
  ...innerProps
}: SelectDropdownIconIndicatorProps) => (
  // @ts-ignore
  <DownArrow $white={white} {...innerProps} />
);

const DownArrow = styled(DefDownArrow)<{
  $white: boolean;
}>`
  padding: 8px 10px;
  color: ${({ $white }) => ($white ? '#fff' : '#000')};
`;

export { SelectDropdownIconIndicator };
