import styled from 'styled-components';
import { Tab as DefTab } from 'react-tabs';

export const Tab = styled(DefTab)`
  padding: 16px 10px 9px;
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: #fff;
  text-align: center;
  cursor: pointer;
  outline: none;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    padding: 6px 6px 7px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    font-size: ${({ theme }) => theme.pxToRem(10)};
  }

  &.react-tabs__tab--selected {
    border-bottom-color: ${({ theme }) => theme.getColor('punch')};
    font-weight: 700;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 700;
    }
  }
`;
