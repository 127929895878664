import styled, { css } from 'styled-components';
import { toNumber } from 'lodash-es';
import { isIOS } from 'react-device-detect';
import 'react-tabs/style/react-tabs.css';
import { useDownMedia, useCustomLayoutContentStyles } from '../hooks';
import { useServerAuth, BlockchainBalancesProvider } from '../providers';
import { PermissionsBlock } from '../components/PermissionsBlock';
import {
  IOSAppButton as DefIOSAppButton,
  AgentUsersButton as DefAgentUsersButton,
} from '../components/buttons';
import {
  Balances,
  GeneralInfo,
  StakeTabs as DefStakeTabs,
  PendingTransactionsTable as DefPendingTransactionsTable,
  DoneTransactionsTable as DefTransactionsTable,
} from '../components/cashier';
import { useGpanel } from '../providers/GpanelProvider';
import { cashierAndVaultPagesStyles } from '../styles/cashier-and-vault-pages';
import { withDividerStyles } from '../styles/divider';

const CashierPage = () => {
  const { authorized } = useServerAuth();
  const mobile = useDownMedia('sm');
  const { loading, user: inpokerUser } = useGpanel();

  const contentVisible = !(loading || !(authorized && inpokerUser));

  useCustomLayoutContentStyles(contentVisible && cashierAndVaultPagesStyles);

  if (!contentVisible) {
    return <PermissionsBlock />;
  }

  const withIOSButton =
    mobile && isIOS && toNumber(inpokerUser.balance.USD) >= 20;

  return (
    <BlockchainBalancesProvider>
      <Info>
        <Balances />
        <GeneralInfo />
        <AgentUsersButton />
      </Info>
      <StakeTabs />
      {withIOSButton && <IOSAppButton />}
      <PendingTransactionsTable $withIOSButton={withIOSButton} />
      <TransactionsTable />
    </BlockchainBalancesProvider>
  );
};

const Info = styled.section`
  display: flex;
  flex-direction: column;
`;

const AgentUsersButton = styled(DefAgentUsersButton)`
  margin-top: auto;
`;

const StakeTabs = styled(DefStakeTabs)`
  > * {
    height: 100%;
  }

  ${({ theme }) => theme.getDownMedia('sm')} {
    margin-top: 20px;
  }
`;

const desktopOffset = 16;
const mobileOffset = 15;

const IOSAppButton = styled(DefIOSAppButton)`
  grid-column: 1 / -1;
  justify-self: center;
  margin: ${mobileOffset}px 0 0;
`;

const commonTablesStyles = (withMobileTopLine = true) => css`
  grid-column: 1 / -1;
  position: relative;
  max-width: 100%;
  min-width: 0;

  margin-top: ${desktopOffset + desktopOffset}px;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    margin-top: ${withMobileTopLine
      ? mobileOffset + mobileOffset
      : mobileOffset}px;
  }

  ${withDividerStyles({
    desktopOffset,
    mobileOffset: withMobileTopLine ? mobileOffset : undefined,
  })};
`;

const PendingTransactionsTable = styled(DefPendingTransactionsTable)<{
  $withIOSButton: boolean;
}>`
  ${({ $withIOSButton = false }) => commonTablesStyles(!$withIOSButton)};
`;

const TransactionsTable = styled(DefTransactionsTable)`
  ${commonTablesStyles()};
`;

export { CashierPage };
