import { useCallback } from 'react';
import { useAccount } from 'wagmi';
import { LocalStorageKeys, getLocalStorageValue } from '../utils/helpers';
import { AccessTokenQueryVariables } from '../apollo/operations';
import { useGetAccessAuthData } from './useGetAccessAuthData';
import { useWalletSignature } from './useWalletSignature';

export const useGetServerAuthData = () => {
  const { address } = useAccount();
  const getSignature = useWalletSignature();
  const getAccessAuthData = useGetAccessAuthData();

  return useCallback(
    async (skipWallet = false) => {
      try {
        let variables: AccessTokenQueryVariables = {};
        const gpanelAuthData = getLocalStorageValue(
          LocalStorageKeys.GPANEL_AUTH
        );

        if (!skipWallet && address) {
          const signature = await getSignature();
          variables = {
            address,
            signature,
            gpanelAccessToken: gpanelAuthData?.accessToken,
          };
        } else if (gpanelAuthData?.accessToken) {
          variables = {
            gpanelAccessToken: gpanelAuthData.accessToken,
          };
        } else {
          throw new Error('No data to query server access token');
        }

        return await getAccessAuthData(variables);
      } catch (e) {
        console.log(`Couldn't get auth data in server auth hook: `, e);
        throw e;
      }
    },
    [address, getAccessAuthData, getSignature]
  );
};
