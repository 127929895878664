import { PropsWithChildren } from 'react';
import { useInitialData } from '../hooks';

const InitialDataProvider = ({ children }: PropsWithChildren) => {
  const { loading } = useInitialData();

  if (loading) return null;

  return <>{children}</>;
};

export { InitialDataProvider };
