import { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useApolloClient } from '@apollo/client';
import { ShortLangNames } from '../utils/i18n';
import { useServerAuth } from '../providers';
import {
  UpdateUserLanguageDocument,
  UserLanguageDocument,
  UpdateUserLanguageMutation,
  UpdateUserLanguageMutationVariables,
  UserLanguageQuery,
  UserLanguageQueryVariables,
} from '../apollo/operations';

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const client = useApolloClient();
  const { fullAccess } = useServerAuth();
  const [urlParams, setParams] = useSearchParams();

  const shortLanguage = useMemo(
    () => i18n.language.slice(0, 2).toLowerCase(),
    [i18n.language]
  );

  useEffect(() => {
    const languageToSet = urlParams.get('locale');
    if (languageToSet && Object.keys(ShortLangNames).includes(languageToSet)) {
      // @ts-ignore
      i18n.changeLanguage(ShortLangNames[languageToSet]);
      urlParams.delete('locale');
      setParams(urlParams);
    }
  }, [i18n, urlParams, setParams]);

  const updateLanguage = useCallback(
    async (lang: string) => {
      try {
        await client.mutate<
          UpdateUserLanguageMutation,
          UpdateUserLanguageMutationVariables
        >({
          mutation: UpdateUserLanguageDocument,
          variables: {
            lang,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    [client]
  );

  useAsync(async () => {
    if (!fullAccess) return;

    try {
      const { data } = await client.query<
        UserLanguageQuery,
        UserLanguageQueryVariables
      >({
        query: UserLanguageDocument,
        fetchPolicy: 'no-cache',
      });
      const savedServerLanguage = data.me?.language;
      if (savedServerLanguage !== shortLanguage) {
        await updateLanguage(shortLanguage);
      }
    } catch (error) {
      console.log(error);
    }
  }, [fullAccess, shortLanguage]);
};
