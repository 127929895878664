import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isEmpty, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikFormProps } from 'formik';
import { useSnackbar } from 'notistack';
import { useAccount } from 'wagmi';
import * as Yup from 'yup';
import { Currencies, isInpTokenSymbol } from '../../utils/consts/currencies';
import { prettifyResponse } from '../../utils/helpers/common';
import { CryptoAddress } from '../../utils/helpers/contract-helpers';
import { getFormattedNumber } from '../../utils/helpers/strings';
import { useDepositToken } from '../../hooks';
import { useBlockchainBalances, useBlockchain } from '../../providers';
import {
  LastDepositTransactionDocument,
  GpanelPendingTransactionsDocument,
  useUserDepositFormDataQuery,
  usePaymentSubmitDepositMutation,
  usePaymentSubmittedDepositMutation,
  useAgentPaymentSubmitDepositMutation,
  useAgentPaymentSubmittedDepositMutation,
} from '../../apollo/operations';
import { useGpanel } from '../../providers/GpanelProvider';
import { resetListStyles } from '../../styles/helpers';
import { Button as DefButton } from '../buttons';
import { BalanceInfoBlock } from '../cashier/BalanceInfoBlock';
import { AgentBlock as DefAgentBlock } from '../form-blocks';
import { Select as DefSelect, IconPosition } from '../form-elements/Select';
import { FormikInput } from '../formik-elements';
import { Label } from '../styled/cashier';

const DepositForm = (props: FormikFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useAccount();
  const blockchain = useBlockchain();
  const { value: balances, retry: reloadBalances } = useBlockchainBalances();
  const { user: gpanelUser } = useGpanel();
  const [currency, setCurrency] = useState(Currencies.INP);

  const chosenCurrency = useMemo(
    () => balances?.find(({ symbol }) => symbol.toUpperCase() === currency),
    [balances, currency]
  );

  const { data: { me: user, agentGetUsers } = {} } =
    useUserDepositFormDataQuery({
      fetchPolicy: 'cache-and-network',
    });

  const kycData = useMemo(() => {
    const kyc = user?.kyc;

    if (!(kyc && chosenCurrency)) return null;

    return isInpTokenSymbol(chosenCurrency.symbol)
      ? {
          minDeposit: kyc.minDepositInp,
          maxDeposit: kyc.maxDepositInp,
          feeDeposit: kyc.feeDepositInp,
        }
      : {
          minDeposit: kyc.minDeposit,
          maxDeposit: kyc.maxDeposit,
          feeDeposit: kyc.feeDeposit,
        };
  }, [user, chosenCurrency]);

  const agent = !!user?.agent?.isAgent;

  const agentOptions = useMemo(() => {
    const users = agentGetUsers?.agentOf;
    if (!(agent && !isEmpty(users))) return [];
    return users!.map((user) => ({
      label: user!.username,
      value: user!.gPanelUserId,
    }));
  }, [agent, agentGetUsers]);

  const schema = useMemo(() => {
    if (!kycData) return undefined;
    const min = kycData.minDeposit;
    const maxDeposit = kycData.maxDeposit;
    const availableBalance = toNumber(chosenCurrency?.amount ?? '0');
    const max = Math.min(maxDeposit, availableBalance);
    return getSchema({
      min,
      max,
    });
  }, [kycData, chosenCurrency]);

  const selectOptions = useMemo(() => {
    if (!balances) return [];

    return balances
      .filter(({ depositActive }) => depositActive)
      .map(({ symbol, iconUrl }) => ({
        label: symbol.toUpperCase(),
        value: symbol.toUpperCase(),
        icon: iconUrl,
        iconPosition: IconPosition.START,
      }));
  }, [balances]);

  const [userPaymentDeposit] = usePaymentSubmitDepositMutation({
    fetchPolicy: 'no-cache',
  });

  const [userPaymentDeposited] = usePaymentSubmittedDepositMutation({
    fetchPolicy: 'no-cache',
  });

  const [agentPaymentDeposit] = useAgentPaymentSubmitDepositMutation({
    fetchPolicy: 'no-cache',
  });

  const [agentPaymentDeposited] = useAgentPaymentSubmittedDepositMutation({
    fetchPolicy: 'no-cache',
  });

  const { depositToken } = useDepositToken({
    tokenAddress: (chosenCurrency?.contract as CryptoAddress) ?? '',
  });

  const deposit = useCallback(
    async (amount: number, userToDeposit: string | null) => {
      try {
        if (!(address && gpanelUser && blockchain && chosenCurrency)) {
          throw new Error(`User's wallet should be connected`);
        }

        const agentCall = agent && !!userToDeposit;

        const depositResult = await (async () => {
          const blockchainId = blockchain.id;
          const stablecoinId = !isInpTokenSymbol(chosenCurrency.symbol)
            ? toNumber(chosenCurrency.id)
            : undefined;
          const currency = chosenCurrency.symbol;

          const commonVariables = {
            blockchainId,
            account: address,
            stablecoinId,
            currency,
            amount,
          };

          if (agentCall) {
            const depositResult = await agentPaymentDeposit({
              variables: {
                ...commonVariables,
                gpanelUserId: userToDeposit,
              },
            });

            return depositResult.data?.agentPaymentSubmitDeposit;
          }

          const depositResult = await userPaymentDeposit({
            variables: {
              ...commonVariables,
              gpanelUserId: gpanelUser.id,
            },
          });

          return depositResult.data?.paymentSubmitDeposit;
        })();

        const { id, status, depositAddress } = depositResult ?? {};

        const tokenAddress = chosenCurrency.contract;

        if (!(id && status === 'Created' && tokenAddress && depositAddress)) {
          throw new Error(`No data in payment deposit mutation`);
        }

        const transactionResult = await depositToken({
          transferAddress: depositAddress as CryptoAddress,
          amount,
          units: chosenCurrency.units,
        });

        if (!transactionResult?.hash) {
          throw new Error('Transaction unsuccessful');
        }

        const { errors } = await (() => {
          const mutationConfig = {
            variables: {
              id,
              tx: transactionResult.hash,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              { query: GpanelPendingTransactionsDocument },
              { query: LastDepositTransactionDocument },
            ],
          };

          return (agentCall ? agentPaymentDeposited : userPaymentDeposited)(
            mutationConfig
          );
        })();

        if (!isEmpty(errors)) {
          throw new Error(
            `Errors on paymentDeposited: ${prettifyResponse(errors!)}`
          );
        }

        await reloadBalances();
      } catch (e) {
        console.log(`Error in deposit function:`, e);
        throw e;
      }
    },
    [
      address,
      gpanelUser,
      blockchain,
      chosenCurrency,
      agent,
      depositToken,
      reloadBalances,
      userPaymentDeposit,
      agentPaymentDeposit,
      agentPaymentDeposited,
      userPaymentDeposited,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        amount: 0,
        user: null,
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async ({ amount, user }, { setFieldValue }) => {
        try {
          await deposit(amount, user);
          setFieldValue('amount', 0, false);
          enqueueSnackbar(t('DEPOSIT_FORM__submitSuccess'), {
            variant: 'success',
          });
        } catch (e) {
          console.log(e);
          enqueueSnackbar(t('DEPOSIT_FORM__submitError'), {
            variant: 'error',
          });
        }
      }}
    >
      {({ values: { amount }, isSubmitting }) => {
        const minDeposit = getFormattedNumber(kycData?.minDeposit);
        const maxDeposit = getFormattedNumber(kycData?.maxDeposit);
        const feeDepositNum = kycData?.feeDeposit ?? 0;
        const feeDeposit = toNumber(feeDepositNum);
        const resultAmount = getFormattedNumber(
          amount - feeDepositNum <= 0 ? 0 : amount - feeDepositNum
        );
        const currentValue = selectOptions.find(
          ({ value }) => value === currency
        );

        return (
          <Form {...props}>
            <Label as={'label'} htmlFor="deposit-amount-input">
              {t('DEPOSIT_FORM__label')}:
            </Label>
            <InputWithButton>
              <Input
                id={'deposit-amount-input'}
                name={'amount'}
                type={'number'}
                disabled={!kycData}
                InputProps={
                  !isEmpty(selectOptions)
                    ? {
                        endAdornment: (
                          <Select
                            defaultValue={currentValue}
                            options={selectOptions}
                            isSearchable={false}
                            withBorder={false}
                            bgColor={'shark2'}
                            onChange={(newValue) => {
                              const newCurrencyValue =
                                newValue &&
                                'value' in newValue &&
                                newValue.value;

                              const newCurrency =
                                newCurrencyValue &&
                                (Object.keys(Currencies).find(
                                  (currency) => currency === newCurrencyValue
                                ) as Currencies | undefined);

                              if (newCurrency && newCurrency !== currency) {
                                setCurrency(newCurrency);
                              }
                            }}
                          />
                        ),
                      }
                    : undefined
                }
              />
              <SubmitButton
                loading={isSubmitting}
                // @ts-ignore
                type={'submit'}
                disabled={!kycData}
              >
                {t('DEPOSIT_FORM__button')}
              </SubmitButton>
            </InputWithButton>
            {!!kycData && (
              <TransactionDataList>
                <BalanceInfoBlock
                  title={t('DEPOSIT_FORM__resultAmount')}
                  currency={currency}
                  amount={resultAmount}
                />
                <BalanceInfoBlock
                  title={t('DEPOSIT_FORM__feeAmount')}
                  currency={currency}
                  amount={feeDeposit}
                />
              </TransactionDataList>
            )}
            {!!kycData && (
              <KYCDataList>
                <BalanceInfoBlock
                  title={t('DEPOSIT_FORM__minDepositInfo')}
                  currency={currency}
                  amount={minDeposit}
                />
                <BalanceInfoBlock
                  title={t('DEPOSIT_FORM__maxDepositInfo')}
                  currency={currency}
                  amount={maxDeposit}
                />
                <BalanceInfoBlock
                  title={t('DEPOSIT_FORM__feeDepositInfo')}
                  currency={currency}
                  amount={feeDeposit}
                />
              </KYCDataList>
            )}
            {agent && (
              <AgentBlock
                type={'agent'}
                name={'user'}
                selectProps={{
                  options: agentOptions,
                  isClearable: true,
                  whiteBackground: true,
                  // greyBackground: true,
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

type FormValues = {
  amount: number;
  user: string | null;
};

const getSchema = ({
  min,
  max,
}: {
  min: number;
  max: number;
}): Yup.SchemaOf<FormValues> =>
  Yup.object({
    amount: Yup.number()
      .transform((value) =>
        !isNaN(toNumber(value)) ? toNumber(value) : undefined
      )
      .positive('Should be more than zero')
      .min(min, 'Should be more than min amount')
      .max(max, 'Should be less than available amount')
      .required('Field is required'),
    user: Yup.string().defined().nullable(),
  }).defined();

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${({ theme }) => theme.getDownMedia('sm')} {
    gap: 10px;
  }
`;

const Input = styled(FormikInput)`
  flex: 1 1 auto;
`;

const switchBreakpoint = 1140;

const SubmitButton = styled(DefButton)`
  min-width: 142px;
  margin-left: 14px;

  ${({ theme }) => theme.getDownMedia(switchBreakpoint)} {
    margin-top: 8px;
    margin-left: 0;
  }
`;

const InputWithButton = styled.div`
  display: flex;
  ${({ theme }) => theme.getDownMedia(switchBreakpoint)} {
    flex-direction: column;
  }
`;

const Select = styled(DefSelect)`
  min-width: 104px;
  align-self: stretch;
  .react-select__control {
    border-radius: 4px;
    height: 100%;
  }

  .react-select__dropdown-indicator {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0;
  }
`;

const DataList = styled.ul`
  ${resetListStyles};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;

  ${({ theme }) => theme.getDownMedia(switchBreakpoint)} {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
`;

const TransactionDataList = styled(DataList)`
  padding-bottom: 17px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const KYCDataList = styled(DataList)``;

const AgentBlock = styled(DefAgentBlock)`
  margin-bottom: 2px;
`;

export { DepositForm };
