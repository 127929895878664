import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  TableWrapper,
  Table as DefTable,
  TableRow,
  TableCell,
} from 'components/styled/table';
import dayjs from 'dayjs';
import { externalLinkProps } from '../../utils/consts/common';
import { getFormattedNumber } from '../../utils/helpers/strings';
import { TranslationKeys } from '../../utils/i18n';
import {
  useGpanelPendingTransactionsQuery,
  GpanelPendingTransactionsQuery,
} from '../../apollo/operations';

const PendingTransactionsTable = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  const {
    loading,
    error,
    data: { pendingTransactions } = {},
  } = useGpanelPendingTransactionsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  if (loading || error || isEmpty(pendingTransactions)) {
    return null;
  }

  const getScanUrl = (scanUrl: string, txId: string) => {
    const domain = process.env.REACT_APP_BSCSCAN_URL;
    if (!domain) {
      throw new Error(`Scan net domain is not set in env`);
    }

    return `${scanUrl}tx/${txId}`;
  };

  return (
    <TableWrapper {...props}>
      <Table role="table">
        <TableRow role="rowgroup">
          {Array.from(columns).map(([key, label]) => (
            <TableCell key={key} $header role="columnheader">
              {t(label)}
            </TableCell>
          ))}
        </TableRow>
        {pendingTransactions!.map((data) => (
          <TableRow key={data.id} role="rowgroup">
            {Array.from(columns).map(([key]) => {
              const dataValue = data[key];
              let cellValue;

              switch (key) {
                case 'createdAt':
                  cellValue = dayjs(dataValue).format('DD/MM/YYYY HH:mm:ss');
                  break;
                case 'amount':
                  cellValue = `${getFormattedNumber(dataValue)} ${
                    data.currency ?? ''
                  }`;
                  break;
                case 'txid':
                  const scanUrl = data.blockchain?.scanUrl;
                  cellValue = scanUrl ? (
                    <a
                      href={getScanUrl(scanUrl, dataValue)}
                      {...externalLinkProps}
                    >
                      {dataValue}
                    </a>
                  ) : null;
                  break;
                default:
                  cellValue = dataValue ?? '';
              }

              return (
                <TableCell key={key} role="cell">
                  {cellValue}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </Table>
    </TableWrapper>
  );
};

const Table = styled(DefTable)`
  grid-template-columns:
    minmax(160px, 1.3fr) minmax(120px, 1.2fr) repeat(3, minmax(100px, 1fr))
    minmax(160px, 1.5fr);
`;

type Payment = GpanelPendingTransactionsQuery['pendingTransactions'][number];
type ColumnName = keyof Omit<Payment, '__typename' | 'id' | 'currency'>;

const columns: ReadonlyMap<ColumnName, TranslationKeys> = new Map([
  ['createdAt', 'CASHIER_GPAMEL_PENDING_PAYMENTS_TABLE__dateColumn'],
  ['username', 'CASHIER_GPAMEL_PENDING_PAYMENTS_TABLE__usernameColumn'],
  ['type', 'CASHIER_GPAMEL_PENDING_PAYMENTS_TABLE__typeColumn'],
  ['amount', 'CASHIER_GPAMEL_PENDING_PAYMENTS_TABLE__amountColumn'],
  ['status', 'CASHIER_GPAMEL_PENDING_PAYMENTS_TABLE__statusColumn'],
  ['txid', 'CASHIER_GPAMEL_PENDING_PAYMENTS_TABLE__txIdColumn'],
]);

export { PendingTransactionsTable };
