import { useState, useEffect, useCallback } from 'react';
import {
  LocalStorageKeys,
  getLocalStorageValue,
  addLocalStorageObserver,
  removeLocalStorageObserver,
} from '../utils/helpers';

export const useLocalStorageValue = <T extends LocalStorageKeys>(key: T) => {
  const [value, setValue] = useState(getLocalStorageValue(key));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeHandler = useCallback((newValue: any) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    addLocalStorageObserver(key, changeHandler);
    return () => {
      removeLocalStorageObserver(key, changeHandler);
    };
  }, [key, changeHandler]);

  return value;
};
