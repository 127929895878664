import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  AccessTokenDocument,
  AccessTokenQuery,
  AccessTokenQueryVariables,
} from '../apollo/operations';

export const useGetAccessAuthData = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: AccessTokenQueryVariables) => {
      const result = await client.query<
        AccessTokenQuery,
        AccessTokenQueryVariables
      >({
        query: AccessTokenDocument,
        fetchPolicy: 'no-cache',
        variables,
      });

      const userId = result.data.authLogin?.user_id;
      const role = result.data.authLogin?.role;
      const accessToken = result.data.authLogin?.access_token;

      if (!(userId && role && accessToken)) {
        throw new Error(`No authLogin data in response`);
      }

      return { userId, role, accessToken };
    },
    [client]
  );
};
