import { useMemo, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { compact, isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { NftDelegationStatuses } from '../utils/consts/nft-delegation-statuses';
import { getEnvVariable } from '../utils/helpers/env-helpers';
import { useDelegatedNfTsQuery } from '../apollo/operations';
import { Claim } from '../components/Claim';
import { ClaimCards } from '../components/ClaimCards';
import { ClaimGap } from '../components/ClaimGap';
import { GpanelLoginButton } from '../components/buttons';
import { Spinner } from '../components/spinners/Spinner';
import { useGpanel } from '../providers/GpanelProvider';
import { resetButtonStyles } from '../styles/helpers';

const ClaimPage = () => {
  const { t } = useTranslation();
  const { loading: loadingUser, user } = useGpanel();
  const { loading: loadingNFTs, data: nftsResponse } = useDelegatedNfTsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [claimView, setClaimView] = useState(true);

  const { cardsPresent, unAcceptedNFTsPresent } = useMemo(() => {
    const cards = compact(nftsResponse?.NFTUserConnected?.rented);
    return {
      cardsPresent: !isEmpty(cards),
      unAcceptedNFTsPresent: cards.some(
        ({ status }) => status === NftDelegationStatuses.PENDING
      ),
    };
  }, [nftsResponse]);

  const switchOffClaimView = useCallback(() => {
    if (cardsPresent) {
      setClaimView(false);
    }
  }, [cardsPresent]);

  const claimProps = useMemo(
    () =>
      cardsPresent && {
        onNFTsPress: switchOffClaimView,
      },
    [cardsPresent, switchOffClaimView]
  );

  useEffect(() => {
    if (!cardsPresent) {
      setClaimView(true);
    }
  }, [cardsPresent]);

  const claimPageEnabled = ['1', 'true'].some(
    (val) => val === getEnvVariable('CLAIM_PAGE_ENABLED')
  );

  return (
    <Wrapper>
      {(() => {
        if (!claimPageEnabled) {
          return <ClaimGap />;
        }

        if (loadingUser || loadingNFTs) {
          return (
            <Spinner
              style={{
                margin: 'auto',
              }}
            />
          );
        }

        if (!user) {
          return (
            <GpanelLoginButton withAutoClick>
              {t('CLAIM_PAGE__loginButton')}
            </GpanelLoginButton>
          );
        }

        return claimView ? (
          <>
            {unAcceptedNFTsPresent && (
              <DelegatedItemsButton onClick={switchOffClaimView}>
                {t('CLAIM_PAGE__newDelegatedNFTsButton')}
              </DelegatedItemsButton>
            )}
            <Claim {...claimProps} />
          </>
        ) : (
          <ClaimCards onBackClick={() => setClaimView(true)} />
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  max-width: 549px;
  margin: 0 auto;
`;

const DelegatedItemsButton = styled.button`
  ${resetButtonStyles};
  padding: 13px 9%;
  background-color: ${({ theme }) => theme.getColor('mineShaft')};
  color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export { ClaimPage };
