import { compact, isEmpty } from 'lodash-es';
import { useAsync } from 'react-use';
import { useApolloClient } from '@apollo/client';
import {
  BuyCryptoDataDocument,
  DepositAddressDocument,
  UserDepositAddressesDocument,
  BuyCryptoDataQuery,
  BuyCryptoDataQueryVariables,
  DepositAddressQuery,
  DepositAddressQueryVariables,
  UserDepositAddressesQuery,
  UserDepositAddressesQueryVariables,
} from '../apollo/operations';

export const useOnramper = () => {
  const client = useApolloClient();
  return useAsync(async () => {
    try {
      const { data: buyCryptoDataResponse } = await client.query<
        BuyCryptoDataQuery,
        BuyCryptoDataQueryVariables
      >({
        query: BuyCryptoDataDocument,
        fetchPolicy: 'network-only',
      });

      const stablecoins = compact(buyCryptoDataResponse.stablecoins);
      let addresses = compact(buyCryptoDataResponse.me?.depositAddress);

      if (isEmpty(addresses)) {
        await client.query<DepositAddressQuery, DepositAddressQueryVariables>({
          query: DepositAddressDocument,
          fetchPolicy: 'network-only',
          variables: {
            blockchainId: 0,
          },
        });
        const addressesResponse = await client.query<
          UserDepositAddressesQuery,
          UserDepositAddressesQueryVariables
        >({
          query: UserDepositAddressesDocument,
          fetchPolicy: 'network-only',
        });
        addresses = compact(addressesResponse.data.me?.depositAddress);
      }
      return {
        stablecoins,
        addresses,
      };
    } catch (e) {
      console.error(`Couldn't proceed with useOnramper hooks`, e);
      throw e;
    }
  }, [client]);
};
