import { useMemo } from 'react';
import styled from 'styled-components';
import { Text as DefText, TextProps } from '../Text';

export type CardNameProps = (
  | { name: string }
  | {
      collectionName: string;
      cardId: string;
    }
) &
  TextProps;

export const CardName = (props: CardNameProps) => {
  const { name, collectionName, cardId } = useMemo(() => {
    if ('name' in props) {
      return {
        name: props.name,
      };
    } else {
      return {
        collectionName: props.collectionName,
        cardId: props.cardId,
      };
    }
  }, [props]);

  return (
    // @ts-ignore
    <Text component={'h3'} variant={'small'} weight={'semi-bold'} {...props}>
      {name || `${collectionName} #${cardId}`}
    </Text>
  );
};

const Text = styled(DefText)`
  text-align: center;
`;
