import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { pages } from '../utils/consts';
import { useDownMedia } from '../hooks';
import { useGpanel } from '../providers/GpanelProvider';
import { Text } from './Text';
import { Button } from './buttons';
import { Spinner } from './spinners/Spinner';

export type GpanelInfoProps = HTMLAttributes<HTMLDivElement>;
const GpanelInfo = (props: GpanelInfoProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading, user } = useGpanel();
  const mobile = useDownMedia(breakPoint);

  if (loading) return <Spinner />;

  const usdbalance = user?.balance.USD;
  const inpbalance = user?.balance.INP;

  if (!(usdbalance || inpbalance)) return null;

  return (
    <Content {...props}>
      {isString(usdbalance) && (
        <Balance>
          {isString(usdbalance) && (
            <>
              {t('GPANEL__usdBalance')} {usdbalance}{' '}
            </>
          )}
        </Balance>
      )}
      {isString(usdbalance) && !mobile && <> | </>}
      {isString(inpbalance) && (
        <Balance>
          {isString(inpbalance) && (
            <>
              {t('GPANEL__inpBalance')} {inpbalance}
            </>
          )}
        </Balance>
      )}
      {!mobile && (
        <DepositButton
          onClick={() => {
            navigate(pages.cashier.path);
          }}
        >
          {t('GPANEL__depositButton')}
        </DepositButton>
      )}
    </Content>
  );
};

const breakPoint = 1060;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-radius: 6px;

  ${({ theme }) => theme.getUpMedia(breakPoint)} {
    background-color: ${({ theme }) => theme.getColor('shark')};
  }

  ${({ theme: { getDownMedia } }) => getDownMedia(breakPoint)} {
    flex-direction: column;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    padding: 2px 3px 2px 6px;
  }
`;

const Balance = styled(Text).attrs({
  component: 'span',
})`
  font-size: ${({ theme }) => theme.pxToRem(14)};

  ${({ theme }) => theme.getUpMedia(breakPoint)} {
    padding: 0 2px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    font-size: ${({ theme }) => theme.pxToRem(12)};
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    display: block;
  }
`;

const DepositButton = styled(Button)`
  margin-left: 5px;
  border-radius: 6px;
  padding: 8px 22px;
  text-transform: none;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    width: 70px;
    padding: 8px 8px;
    margin-left: 3px;
  }
`;

export { GpanelInfo };
