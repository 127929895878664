import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useGpanel } from '../../providers/GpanelProvider';
import { Text as DefText } from '../Text';
import { GpanelLoginButton } from '../buttons';
import { Spinner } from '../spinners/Spinner';

export type RegistrationTabContentProps = HTMLAttributes<HTMLDivElement>;

const RegistrationTabContent = (props: RegistrationTabContentProps) => {
  const { t } = useTranslation();
  const { loading: loadingUser, user } = useGpanel();

  if (loadingUser) {
  }

  return (
    <Wrapper {...props}>
      {(() => {
        if (loadingUser) {
          return <Spinner />;
        }

        if (user) {
          return <Text>{t('VAULT_TABS__registrationTabLoggedIn')}</Text>;
        }

        return <GpanelLoginButton />;
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const Text = styled(DefText)``;

export { RegistrationTabContent };
