/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMemo, useLayoutEffect, useEffect, ComponentType } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMethods } from 'react-use';
import { Image } from 'components/Image';
import { TranslationKeys } from '../../utils/i18n';
import { useShortenedAddress } from '../../hooks';
import {
  createMyNftCardMethods,
  initialMyNftState,
  MyNftCardProvider,
  useServerAuth,
  useInnerModals,
} from '../../providers';
import { Divider } from '../cards-tabs/styled';
import { UserNftsQuery } from '../../apollo/operations';
import { Button } from '../buttons';
import { CardName as DefCardName } from '../cards-elements';
import { OverviewTab, ListTab, HolderInformation } from '../cards-tabs';
import { Tabs as DefTabs, TabList, Tab, TabPanel } from '../styled/tabs';

type CardOverviewModalProps = {
  card: UserNftsQuery['nfts'][number];
  reloadCards: () => Promise<void>;
};

const CardOverviewModal = ({ card, reloadCards }: CardOverviewModalProps) => {
  const { t } = useTranslation();
  const { dispatch } = useInnerModals();
  const { fullAccess, authOnServer } = useServerAuth();

  const [state, methods] = useMethods(
    createMyNftCardMethods,
    initialMyNftState
  );

  const cardOwnerAddress = useShortenedAddress(card.owner);

  useLayoutEffect(() => {
    methods.setCard(card);
    methods.setReloadCards(reloadCards);
    dispatch([
      'setWrapperProps',
      {
        size: 'card',
      },
    ]);
  }, [card, methods, reloadCards, dispatch]);

  useEffect(() => {
    dispatch(['setWrapperProps', { closingDisabled: state.cardIsProcessing }]);
  }, [state.cardIsProcessing, dispatch]);

  const { imageUrl } = card.nftMetadatum;
  const { collectionName } = card.nftContract;

  const providerValue = useMemo(
    () => ({
      ...state,
      methods,
    }),
    [state, methods]
  );

  return (
    <MyNftCardProvider value={providerValue}>
      <CardImage src={card.imageUrl ?? imageUrl ?? ''} />
      {!!collectionName && (
        <CardTag collectionName={collectionName} cardId={card.tokenId!} />
      )}
      {fullAccess ? (
        <Tabs>
          <TabList>
            {Array.from(tabsData).map(([key, { tabLabel }]) => (
              <Tab key={key}>{t(tabLabel)}</Tab>
            ))}
          </TabList>
          <HolderInformation
            text={'MY_CARDS_CARD_OVERVIEW_MODAL__holderText'}
            owner={cardOwnerAddress}
          />
          <Divider />
          {Array.from(tabsData).map(([key, { Component }]) => (
            <TabPanel key={key}>
              <Component />
            </TabPanel>
          ))}
        </Tabs>
      ) : (
        <SignWalletButton onClick={authOnServer}>
          {t('MY_CARDS_CARD_OVERVIEW_MODAL__signWalletButton')}
        </SignWalletButton>
      )}
    </MyNftCardProvider>
  );
};

enum MyCardsCardOverviewTabs {
  OVERVIEW,
  LIST,
}

const tabsData: Map<
  MyCardsCardOverviewTabs,
  {
    tabLabel: TranslationKeys;
    Component: ComponentType<$TSFixMe>;
  }
> = new Map([
  [
    MyCardsCardOverviewTabs.OVERVIEW,
    {
      tabLabel: 'MY_CARDS_CARD_OVERVIEW_MODAL__overviewTab',
      Component: OverviewTab,
    },
  ],
  [
    MyCardsCardOverviewTabs.LIST,
    {
      tabLabel: 'MY_CARDS_CARD_OVERVIEW_MODAL__listTab',
      Component: ListTab,
    },
  ],
]);

const CardImage = styled(Image)`
  min-height: 314px;
  margin-bottom: 19px;

  & img {
    height: 100%;
    max-width: 203px;

    ${({ theme }) => theme.getDownMedia('xs')} {
      max-width: 180px;
    }
  }
`;

const CardTag = styled(DefCardName)`
  margin-bottom: 21px;
`;

const Tabs = styled(DefTabs)`
  background-color: ${({ theme }) => theme.getColor('shark')};
`;

const SignWalletButton = styled(Button)`
  margin: 20px 0 30px;
`;

export { CardOverviewModal };
