import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash-es';
import { UserNftsQuery } from '../../apollo/operations';
import {
  CardName,
  CardDelegationInfo as DefDDelegationInfo,
} from '../cards-elements';
import { Checkbox as DefCheckbox } from '../form-elements';
import { CardWrapper, CardImage, CardTextContent } from '../styled/cards';

export type MyCardItemProps = {
  card: UserNftsQuery['nfts'][number];
  delegated?: {
    status: string;
    owner: string;
    username: string;
  };
  cardIsChecked?: boolean;
  onCardCheckChange?: (id: number, state: boolean) => void;
} & HTMLAttributes<HTMLLIElement>;

const MyCardItem = ({
  card,
  delegated,
  cardIsChecked,
  onCardCheckChange,
  ...props
}: MyCardItemProps) => {
  const { imageUrl } = card.nftMetadatum;
  const { collectionName } = card.nftContract;

  return (
    <CardWrapper $cardIsChecked={cardIsChecked} {...props}>
      {isFunction(onCardCheckChange) && (
        <Checkbox
          inputProps={{
            checked: !!cardIsChecked,
            onChange: (event) => {
              onCardCheckChange(card.id, event.target.checked);
            },
          }}
        />
      )}
      <CardImage src={card.imageUrl ?? imageUrl ?? ''} />
      <CardTextContent>
        {!!collectionName && (
          <CardName collectionName={collectionName} cardId={card.tokenId} />
        )}
        {!!delegated && (
          <DelegationInfo
            status={delegated.status}
            owner={delegated.owner}
            username={delegated.username}
            type={'own'}
          />
        )}
      </CardTextContent>
    </CardWrapper>
  );
};

const Checkbox = styled(DefCheckbox)`
  position: absolute;
  top: 3px;
  right: 9px;
`;

const DelegationInfo = styled(DefDDelegationInfo)`
  margin-top: 6px;
`;

export { MyCardItem };
