import { isString, isNumber, toNumber } from 'lodash-es';

const isStringOrNumber = (value: unknown): value is StringOrNumber =>
  [isString, isNumber].some((fn) => fn(value));

export const getFormattedNumber = (
  rawAmount: unknown,
  config?: Intl.NumberFormatOptions
) => {
  if (!isStringOrNumber(rawAmount)) return '';
  const amount = !isNumber(rawAmount) ? toNumber(rawAmount) : rawAmount;
  return amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    ...config,
  });
};

export const getPercent = (rawAmount: StringOrNumber) => {
  const amount = (!isNumber(rawAmount) ? toNumber(rawAmount) : rawAmount) / 100;
  return amount.toLocaleString(undefined, {
    style: 'percent',
    maximumFractionDigits: 2,
  });
};
