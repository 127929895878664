import { isArray, compact, uniq, isEmpty } from 'lodash-es';
import axios from 'axios';
import { createClient } from 'graphql-ws';
import {
  ApolloClient,
  ApolloLink,
  split,
  NormalizedCacheObject,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { getCache, removeCacheFromLocalStorage } from './apollo-cache';
import {
  LocalStorageKeys,
  getLocalStorageValue,
  setLocalStorageValue,
  removeLocalStorageValue,
} from './helpers';
import { getEnvVariable } from './helpers/env-helpers';
import { resolvers } from './resolvers';
import { typeDefs } from './type-defs';

export type ApolloClientType = ApolloClient<NormalizedCacheObject>;

let apolloClient: ApolloClientType;

const getAuthHeader = () => {
  const auth = getLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
  return auth?.accessToken
    ? {
        Authorization: `Bearer ${auth.accessToken}`,
      }
    : {};
};

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
    });
  }
});

const authLink = setContext((_, { headers: oldHeaders }) => {
  const authHeader = getAuthHeader();
  return {
    headers: {
      ...oldHeaders,
      ...authHeader,
    },
  };
});

const refreshToken = () => {
  const serverAuth = getLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
  const gpanelAuth = getLocalStorageValue(LocalStorageKeys.GPANEL_AUTH);

  if (!serverAuth?.accessToken) {
    return Promise.resolve(null);
  }

  return axios.post<{
    errors?: ReadonlyArray<{
      extensions: {
        code: string;
        path: string;
      };
      message: string;
    }>;
    data?: {
      authRefresh: {
        user_id: string;
        role: string;
        access_token: string;
      };
    };
  }>(
    getEnvVariable('HTTP_API_URL'),
    {
      operationName: 'refreshAccessToken',
      query: `
        query refreshAccessToken($serverAccessToken: String, $gpanelAccessToken: String) {
          authRefresh(
            accessToken: $serverAccessToken,
            gpanelAccessToken: $gpanelAccessToken
          ){
            user_id
            role
            access_token
          }
        }
      `,
      variables: {
        serverAccessToken: serverAuth?.accessToken,
        gpanelAccessToken: gpanelAuth?.accessToken,
      },
    },
    {
      headers: {
        'content-type': 'application/json',
        ...getAuthHeader(),
      },
    }
  );
};

const refreshTokenLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const { accessToken } =
      getLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH) ?? {};

    if (!accessToken) return;

    const graphqlErrorCodes = (graphQLErrors?.map(
      ({ extensions }) => extensions?.code
    ) ?? []) as string[];

    const networkErrorCodes =
      networkError && 'result' in networkError && isArray(networkError.result)
        ? networkError.result.reduce((acc, { errors }) => {
            const codes = compact(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              errors.map((error: any) => error?.extensions?.code)
            );
            return [...acc, ...codes];
          }, [])
        : [];

    const networkStatusCode =
      networkError && 'statusCode' in networkError
        ? networkError.statusCode
        : null;

    if (
      ['invalid-jwt', 'validation-failed'].some((authErrorCode) =>
        uniq([...graphqlErrorCodes, ...networkErrorCodes]).some(
          (code) => authErrorCode === code
        )
      ) ||
      networkStatusCode === 401
    ) {
      return new Observable((observer) => {
        refreshToken()
          .then((response) => {
            const userId = response?.data.data?.authRefresh.user_id;
            const role = response?.data.data?.authRefresh.role;
            const accessToken = response?.data.data?.authRefresh.access_token;

            if (
              !isEmpty(response?.data.errors) ||
              !(userId && role && accessToken)
            ) {
              throw new Error('There is no token on refresh');
            }

            setLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH, {
              userId,
              role,
              accessToken,
            });

            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error: Error) => {
            console.log('clearing');
            removeLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
            observer.error(error);
          });
      });
    }
  }
);

const httpLink = new BatchHttpLink({ uri: process.env.REACT_APP_HTTP_API_URL });

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_API_URL as string,
    connectionParams: {
      headers: {
        ...getAuthHeader(),
      },
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

// noinspection JSUnusedGlobalSymbols
const apolloConfig = {
  link: ApolloLink.from([errorLink, authLink, refreshTokenLink, splitLink]),
  typeDefs,
  resolvers,
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(lastFetchPolicy: WatchQueryFetchPolicy) {
        if (
          lastFetchPolicy === 'cache-and-network' ||
          lastFetchPolicy === 'network-only'
        ) {
          return 'cache-first';
        }

        return lastFetchPolicy;
      },
    },
  },
};

let creatingPromise: Promise<ApolloClientType>;

export const getClient = async () => {
  if (apolloClient) return apolloClient;
  if (creatingPromise) return creatingPromise;

  creatingPromise = getCache().then((cache) => {
    // @ts-ignore
    const client = new ApolloClient({
      cache,
      ...apolloConfig,
    });

    client.onClearStore(removeCacheFromLocalStorage);
    client.onResetStore(removeCacheFromLocalStorage);

    apolloClient = client;

    return apolloClient;
  });

  return creatingPromise;
};
