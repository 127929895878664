import {
  useCallback,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import styled from 'styled-components';
import {
  isEmpty,
  isNil,
  isNaN,
  isObject,
  isFunction,
  toNumber,
  toString,
  uniqBy,
  omit,
} from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import dayjs from 'dayjs';
import { Formik, Form as DefForm, FormikFormProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import {
  Currencies,
  currenciesData,
  isInpTokenSymbol,
} from '../../utils/consts/currencies';
import { LocalStorageKeys, setLocalStorageValue } from '../../utils/helpers';
import { isCryptoAddress } from '../../utils/helpers/contract-helpers';
import { getEnvVariable } from '../../utils/helpers/env-helpers';
import { getFormattedNumber } from '../../utils/helpers/strings';
import { useLocalStorageValue, useGpanelBalances } from '../../hooks';
import { useServerAuth } from '../../providers';
import {
  useWithdrawPageFormDataQuery,
  useWithdrawPageWithdrawRequestMutation,
} from '../../apollo/operations';
import { useGpanel } from '../../providers/GpanelProvider';
import { resetListStyles } from '../../styles/helpers';
import { Text as DefText } from '../Text';
import { Button as DefButton, Button } from '../buttons';
import { BalanceInfoBlock } from '../cashier/BalanceInfoBlock';
import { Select as DefSelect, IconPosition } from '../form-elements/Select';
// Required to fix building issue
// noinspection ES6PreferShortImport
import { FormikInput } from '../formik-elements';
import { Spinner } from '../spinners/Spinner';
import { BalancesInfoList, Label as DefLabel } from '../styled/cashier';

// onTransactionHistoryClick

type GpanelOnlyAccessWithdrawFormProps = {
  onTransactionHistoryClick?: () => void;
} & FormikFormProps;
const GpanelOnlyAccessWithdrawForm = ({
  onTransactionHistoryClick,
  ...props
}: GpanelOnlyAccessWithdrawFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { authorized } = useServerAuth();
  const { user: gpanelUser } = useGpanel();
  const gpanelAuth = useLocalStorageValue(LocalStorageKeys.GPANEL_AUTH);

  const [withdrawCurrency, setWithdrawCurrency] = useState<Currencies>(
    Currencies.INP
  );

  const [receiveCurrency, setReceiveCurrency] = useState<Nullish<string>>(
    withdrawCurrency === Currencies.INP ? withdrawCurrency : null
  );

  const [blockchainId, setBlockchainId] = useState<Nullish<number>>(null);

  const { loading: loadingGpanelBalances, balances: gpanelBalances } =
    useGpanelBalances();

  const { loading: loadingFormData, data } = useWithdrawPageFormDataQuery({
    fetchPolicy: 'network-only',
    skip: !authorized,
  });

  const user = data?.me;
  const stablecoins = data?.stablecoins;
  const blockchains = data?.blockchains;

  const receiveCurrenciesOptions = useMemo(() => {
    if (!(stablecoins && gpanelBalances)) return [];

    if (withdrawCurrency === Currencies.INP) {
      return [inpReceiveOption];
    }

    return uniqBy(stablecoins, 'symbol')
      .map(({ symbol, iconUrl }) => ({
        label: symbol.toUpperCase(),
        value: symbol.toUpperCase(),
        icon: iconUrl ?? '',
        iconPosition: IconPosition.START,
      }))
      .filter(({ value }) =>
        Object.keys(gpanelBalances).some((key) => key.toUpperCase() === value)
      );
  }, [stablecoins, gpanelBalances, withdrawCurrency]);

  const blockchainsOptions = useMemo(() => {
    if (!(receiveCurrency && !isEmpty(blockchains))) {
      return [];
    }

    if (receiveCurrency === Currencies.INP) {
      const bscBlockchain = blockchains!.find(
        ({ chainId }) => chainId === toNumber(getEnvVariable('BSC_CHAIN_ID'))
      );

      return [
        bscBlockchain
          ? {
              label: bscBlockchain.name,
              value: toString(bscBlockchain.id),
              icon: bscBlockchain.iconUrl ?? '',
              iconPosition: IconPosition.START,
            }
          : {
              label: 'BSC',
              value: undefined,
            },
      ];
    }

    return blockchains!
      .filter(({ stablecoins }) =>
        stablecoins?.some(
          ({ symbol }) => symbol.toUpperCase() === receiveCurrency
        )
      )
      .map(({ id, iconUrl, name }) => ({
        label: name,
        value: toString(id),
        icon: iconUrl ?? '',
        iconPosition: IconPosition.START,
      }));
  }, [receiveCurrency, blockchains]);

  const kycData = useMemo(() => {
    const kyc = user?.kyc;

    if (!(kyc && receiveCurrency)) return null;

    return isInpTokenSymbol(receiveCurrency)
      ? {
          minWithdraw: kyc.minWithdrawInp,
          maxWithdraw: kyc.maxWithdrawInp,
          feeWithdraw: kyc.feeWithdrawInp,
        }
      : {
          minWithdraw: kyc.minWithdraw,
          maxWithdraw: kyc.maxWithdraw,
          feeWithdraw: kyc.feeWithdraw,
        };
  }, [user, receiveCurrency]);

  const schema = useMemo(() => {
    if (!kycData) return undefined;
    const min = kycData.minWithdraw;
    const maxWithdraw = kycData.maxWithdraw;
    const availableBalance = withdrawCurrency
      ? toNumber(
          gpanelBalances?.[
            withdrawCurrency === Currencies.INP ? 'inp' : 'usd'
          ] ?? '0'
        )
      : 0;
    const max = Math.min(maxWithdraw, availableBalance);
    return getSchema({
      min,
      max,
    });
  }, [kycData, gpanelBalances, withdrawCurrency]);

  const [requestWithdraw] = useWithdrawPageWithdrawRequestMutation({
    fetchPolicy: 'no-cache',
  });

  useUpdateEffect(() => {
    setReceiveCurrency(Currencies.INP);
  }, [withdrawCurrency]);

  useUpdateEffect(() => {
    if (withdrawCurrency === Currencies.INP) {
      const newBlockchainId = blockchainsOptions[0]?.value;
      setBlockchainId(newBlockchainId ? toNumber(newBlockchainId) : null);
    } else {
      setBlockchainId(null);
    }
  }, [withdrawCurrency, blockchainsOptions]);

  const setSelectValue = useCallback(
    <T extends string | number>(
        setValue: Dispatch<SetStateAction<T>>,
        convertToNumber = false
      ) =>
      (selectValue: unknown) => {
        const newValue =
          // @ts-ignore
          isObject(selectValue) && 'value' in selectValue && selectValue.value;
        // @ts-ignore
        setValue(convertToNumber ? toNumber(newValue) : newValue);
      },
    []
  );

  const withdraw = useCallback(
    async ({
      currency,
      blockchainId,
      address,
      amount,
    }: NonNullableValues<FormValues>) => {
      try {
        const currencyData = (() => {
          if (currency === Currencies.INP) {
            return {
              id: undefined,
              symbol: Currencies.INP,
            };
          }

          const blockchain = blockchains?.find(({ id }) => id === blockchainId);

          if (!blockchain) {
            throw new Error('No proper blockchain data');
          }

          return blockchain.stablecoins.find(
            ({ symbol }) => symbol === currency
          );
        })();

        if (!(gpanelUser && gpanelAuth && currencyData)) {
          throw new Error(`No data to proceed with gpanel only`);
        }

        const commonVariables = {
          gpanelUserId: gpanelUser.id,
          gpanelAccessToken: gpanelAuth.accessToken,
          currency: currencyData.symbol.toLowerCase(),
          stablecoinId: currencyData.id,
          blockchainId,
          address,
          amount,
        };

        const { data: withdrawResult } = await requestWithdraw({
          variables: {
            ...commonVariables,
          },
        });

        const { id, status } = withdrawResult?.paymentSubmitWithdraw2FA1 ?? {};

        if (!(id && status === 'waiting_2fa')) {
          throw new Error(
            `Wrong id or status, or both, in requestWithdraw mutation`
          );
        }

        setLocalStorageValue(LocalStorageKeys.WITHDRAW_2_FA_DATA, {
          id,
          time: dayjs.utc().format(),
        });
      } catch (e) {
        console.log(`Error in requestWithdraw function:`, e);
        throw e;
      }
    },
    [blockchains, gpanelUser, gpanelAuth, requestWithdraw]
  );

  if (!authorized) {
    return <>{t('WITHDRAW_PAGE_FORM__authorizeError')}</>;
  }

  if (loadingGpanelBalances || loadingFormData) {
    return <Spinner />;
  }

  return (
    <Formik<FormValues>
      initialValues={{
        currency: receiveCurrency,
        blockchainId,
        address: null,
        amount: null,
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        try {
          await withdraw({
            currency: values.currency!,
            blockchainId: values.blockchainId!,
            address: values.address!,
            amount: values.amount!,
          });

          setWithdrawCurrency(Currencies.INP);
          setBlockchainId(null);
          resetForm();
          enqueueSnackbar(t('WITHDRAW_PAGE_FORM__submitSuccess'), {
            variant: 'success',
          });
        } catch (e) {
          console.log(e);
          enqueueSnackbar(t('WITHDRAW_PAGE_FORM__submitError'), {
            variant: 'error',
          });
        }
      }}
    >
      {({ values: { amount: rawAmount }, errors, isSubmitting }) => {
        const amount = rawAmount ?? 0;
        const receiveCurrencyData = (() => {
          const currencyOption = receiveCurrenciesOptions.find(
            ({ value }) => value === receiveCurrency
          );
          if (!currencyOption) {
            return null;
          }
          return {
            ...omit(currencyOption, 'value'),
            symbol: currencyOption.value,
          };
        })();
        const minWithdraw = getFormattedNumber(kycData?.minWithdraw);
        const maxWithdraw = getFormattedNumber(kycData?.maxWithdraw);
        const feeWithdrawNum = isInpTokenSymbol(receiveCurrencyData?.symbol)
          ? amount * 0.03
          : kycData?.feeWithdraw ?? 0;
        const feeWithdraw = getFormattedNumber(feeWithdrawNum);
        const resultAmount = getFormattedNumber(
          amount - feeWithdrawNum > 0 ? amount - feeWithdrawNum : 0
        );

        const label = receiveCurrencyData?.label ?? '';

        return (
          <Form {...props}>
            <Inputs>
              <InputLabel>
                {t('WITHDRAW_PAGE_FORM__withdrawCurrencyLabel')}:
              </InputLabel>
              <Select
                name={'currency'}
                defaultValue={withdrawCurrencies.find(
                  ({ value }) => value === withdrawCurrency
                )}
                placeholder={t(
                  'WITHDRAW_PAGE_FORM__receiveCurrencyPlaceholder'
                )}
                options={withdrawCurrencies}
                isSearchable={false}
                whiteBackground
                // @ts-ignore
                onChange={setSelectValue(setWithdrawCurrency)}
              />
              <InputLabel>
                {t('WITHDRAW_PAGE_FORM__receiveCurrencyLabel')}:
              </InputLabel>
              <Select
                name={'currency'}
                placeholder={t(
                  'WITHDRAW_PAGE_FORM__withdrawCurrencySelectPlaceholder'
                )}
                value={
                  receiveCurrenciesOptions.find(
                    ({ value }) => value === receiveCurrency
                  ) ?? null
                }
                options={receiveCurrenciesOptions}
                error={!!errors['currency']}
                isSearchable={false}
                whiteBackground
                // @ts-ignore
                onChange={setSelectValue(setReceiveCurrency)}
              />
              <Select
                name={'blockchainId'}
                placeholder={t(
                  'WITHDRAW_PAGE_FORM__blockchainSelectPlaceholder'
                )}
                value={
                  blockchainsOptions.find(
                    ({ value }) => toNumber(value) === blockchainId
                  ) ?? null
                }
                options={blockchainsOptions}
                noOptionsMessage={() =>
                  t('WITHDRAW_PAGE_FORM__noBlockchainsOptions')
                }
                error={!!errors['blockchainId']}
                isSearchable={false}
                whiteBackground
                // @ts-ignore
                onChange={setSelectValue(setBlockchainId, true)}
              />
              <InputLabel>{t('WITHDRAW_PAGE_FORM__addressLabel')}:</InputLabel>
              <Input name={'address'} />
              <InputLabel>{t('WITHDRAW_PAGE_FORM__amountLabel')}:</InputLabel>
              <Input name={'amount'} type={'number'} disabled={!kycData} />
              <SubmitButton
                type={'submit'}
                loading={isSubmitting}
                disabled={!kycData}
              >
                {t('WITHDRAW_PAGE_FORM__button')}
              </SubmitButton>
            </Inputs>
            {!!kycData && !!receiveCurrencyData && (
              <>
                <TransactionDataList
                  $switchBreakpoint={switchBreakpoint}
                  $withBottomLine
                >
                  <BalanceInfoBlock
                    title={t('WITHDRAW_PAGE_FORM__resultAmount')}
                    currency={receiveCurrencyData}
                    amount={resultAmount}
                  />
                  <BalanceInfoBlock
                    title={t('WITHDRAW_PAGE_FORM__feeAmount')}
                    currency={receiveCurrencyData}
                    amount={feeWithdraw}
                  />
                </TransactionDataList>
              </>
            )}
            {!!kycData && !!receiveCurrencyData && (
              <>
                <KYCDataList $switchBreakpoint={switchBreakpoint}>
                  <BalanceInfoBlock
                    title={t('WITHDRAW_PAGE_FORM__minDepositInfo')}
                    currency={receiveCurrencyData}
                    amount={minWithdraw}
                  />
                  <BalanceInfoBlock
                    title={t('WITHDRAW_PAGE_FORM__maxDepositInfo')}
                    currency={receiveCurrencyData}
                    amount={maxWithdraw}
                  />
                  <BalanceInfoBlock
                    title={t('WITHDRAW_PAGE_FORM__feeDepositInfo')}
                    currency={receiveCurrencyData}
                    amount={feeWithdraw}
                  />
                </KYCDataList>
                {label === 'INP' && (
                  <ResultFeeInfo>
                    {t('WITHDRAW_PAGE_FORM__INPFeeRemark')}
                  </ResultFeeInfo>
                )}
              </>
            )}
            <ButtonsList>
              {isFunction(onTransactionHistoryClick) && (
                <li>
                  <Button
                    type={'button'}
                    color={'secondary'}
                    onClick={onTransactionHistoryClick}
                  >
                    {t('DIRECT_DEPOSIT__transactionHistoryButton')}
                  </Button>
                </li>
              )}
              {/*{isFunction(onWalletDepositClick) && (
                <li>
                  <Button onClick={onWalletDepositClick}>
                    {t('DIRECT_DEPOSIT__walletDepositButton')}
                  </Button>
                </li>
              )}*/}
            </ButtonsList>
          </Form>
        );
      }}
    </Formik>
  );
};

type FormValues = {
  currency: Nullish<string>;
  blockchainId: Nullish<number>;
  address: Nullish<string>;
  amount: Nullish<number>;
};

const withdrawCurrencies: ReadonlyArray<{
  label: Currencies;
  value: Currencies;
  icon: string;
  iconPosition: IconPosition;
}> = [
  {
    label: Currencies.INP,
    value: Currencies.INP,
    icon: currenciesData[Currencies.INP].icon,
    iconPosition: IconPosition.START,
  },
  {
    label: Currencies.USD,
    value: Currencies.USD,
    icon: currenciesData[Currencies.USD].icon,
    iconPosition: IconPosition.START,
  },
];

const inpReceiveOption = {
  id: undefined,
  label: Currencies.INP,
  value: Currencies.INP,
  icon: currenciesData[Currencies.INP].icon,
  iconPosition: IconPosition.START,
} as const;

const getSchema = ({
  min,
  max,
}: {
  min: number;
  max: number;
}): Yup.SchemaOf<FormValues> =>
  Yup.object({
    currency: Yup.string().required('Field is required'),
    blockchainId: Yup.number()
      .transform((value) =>
        [isNil, isNaN].some((fn) => fn(value)) ? undefined : toNumber(value)
      )
      .positive('Should be more than zero')
      .required('Field is required'),
    address: Yup.string()
      .nullable()
      .required('Field is required')
      .test('is-crypto-address', 'Address is not crypto', (value) =>
        isCryptoAddress(value)
      ),
    amount: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : toNumber(value)))
      .positive('Should be more than zero')
      .min(min, 'Should be more than min amount')
      .max(max, 'Should be less than available amount')
      .required('Field is required'),
  });

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
  max-width: 685px;
  margin: 0 auto;
  gap: 15px;

  ${({ theme }) => theme.getDownMedia('sm')} {
    gap: 10px;
  }
`;

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 22px;
  align-items: start;
  grid-row-gap: 15px;

  ${({ theme }) => theme.getDownMedia('sm')} {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const InputLabel = styled(DefLabel)`
  grid-column: 1 / -1;
`;

const switchBreakpoint = 1140;

const Select = styled(DefSelect)`
  width: 100%;
`;

const SubmitButton = styled(DefButton)`
  justify-self: end;
  grid-column: 2;
`;

const Input = styled(FormikInput)`
  grid-column: 1 / -1;
  width: 100%;
`;

const TransactionDataList = styled(BalancesInfoList)``;

const KYCDataList = styled(BalancesInfoList)``;

const SmallGrayText = styled(DefText)`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: ${14 / 12};
  color: ${({ theme }) => theme.getColor('osloGray')};
`;

const ResultFeeInfo = styled(SmallGrayText)``;

const ButtonsList = styled.ul`
  ${resetListStyles};
`;

export { GpanelOnlyAccessWithdrawForm };
