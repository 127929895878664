import { useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { toString, toNumber } from 'lodash-es';
import { useChainId } from '../hooks';
import { useBlockchains } from '../providers';
import { useChangeNetwork } from '../hooks/useChangeNetwork';
import { IconSize } from './form-elements';
// noinspection ES6PreferShortImport
import { Select, IconPosition } from './form-elements/Select';

export type BlockchainsSelectProps = HTMLAttributes<HTMLDivElement>;

const BlockchainsSelect = (props: BlockchainsSelectProps) => {
  const currentChainId = useChainId();
  const blockchains = useBlockchains();
  const {
    isLoading: loading,
    canSwitch,
    switchNetworkAsync,
  } = useChangeNetwork();

  const options = useMemo(
    () =>
      blockchains.map(({ id, name, iconUrl }) => ({
        label: name,
        value: toString(id),
        icon: iconUrl ?? undefined,
        iconSize: IconSize.BIG,
        iconPosition: IconPosition.START,
      })),
    [blockchains]
  );

  const value = useMemo(
    () =>
      currentChainId
        ? options.find(({ value }) => value === toString(currentChainId))
        : undefined,
    [currentChainId, options]
  );

  if (!(canSwitch && switchNetworkAsync && blockchains.length > 1)) return null;

  return (
    <Wrapper {...props}>
      <Select
        placeholder={'Switch blockchain'}
        value={value}
        options={options}
        isLoading={loading}
        isSearchable={false}
        collapsedLabelToIconBreakPoint={breakPoint}
        onChange={(data) => {
          const newValue = !!data && 'value' in data && data.value;
          if (newValue && newValue !== toString(currentChainId)) {
            switchNetworkAsync(toNumber(newValue));
          }
        }}
      />
    </Wrapper>
  );
};

const breakPoint = 600;
const Wrapper = styled.div`
  width: 153px;

  ${({ theme }) => theme.getDownMedia(breakPoint)} {
    width: auto;
  }

  .react-select__menu {
    min-width: 140px;
  }
`;

export { BlockchainsSelect };
