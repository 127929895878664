import { useState, useLayoutEffect, useContext, createContext } from 'react';
import styled, { css } from 'styled-components';
import { useLocation, Outlet } from 'react-router-dom';
import { pages } from '../utils/consts';
import { Appbar as DefAppbar } from '../components/Appbar';
import { Sidebar } from '../components/Sidebar';

const LayoutWithAsideMenu = () => {
  const { pathname } = useLocation();
  const [customLayoutContentStyles, setCustomLayoutContentStyles] = useState<
    CustomContentStyles | undefined
  >(undefined);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  useLayoutEffect(() => {
    if (!pathsWithCustomContentStyles.includes(pathname)) {
      setCustomLayoutContentStyles(undefined);
    }
  }, [pathname]);

  return (
    <CustomLayoutContentStylesContext.Provider
      value={{
        styles: customLayoutContentStyles,
        setStyles: setCustomLayoutContentStyles,
      }}
    >
      <Wrapper>
        <Sidebar
          isMobileMenuOpened={isMobileMenuOpened}
          closeMobileMenu={() => setIsMobileMenuOpened(false)}
        />
        <PageContent>
          <Appbar openMobileMenu={() => setIsMobileMenuOpened(true)} />
          <Main $customStyles={customLayoutContentStyles}>
            <Outlet />
          </Main>
        </PageContent>
      </Wrapper>
    </CustomLayoutContentStylesContext.Provider>
  );
};

type CustomContentStyles = ReturnType<typeof css>;

const pathsWithCustomContentStyles = [
  pages.cashier.path,
  pages.vault.path,
  pages.nftAvatar.path,
];

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
`;

const Appbar = styled(DefAppbar)`
  ${({ theme }) => theme.getDownMedia('sm')} {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

const PageContent = styled.div`
  width: 100%;
  padding: 36px 29px 30px 58px;
  overflow-y: auto;
  position: relative;

  ${({ theme }) => theme.getDownMedia('sm')} {
    padding: 0 30px 30px 30px;
  }

  ${({ theme }) => theme.getDownMedia('xs')} {
    padding: 0 20px 20px 20px;
  }
`;

const Main = styled.main<{
  $customStyles?: CustomContentStyles;
}>`
  margin-top: 48px;

  ${({ theme }) => theme.getDownMedia('md')} {
    margin-top: 80px;
  }

  ${({ $customStyles }) => $customStyles};
`;

const CustomLayoutContentStylesContext = createContext<{
  styles: CustomContentStyles | undefined;
  setStyles: (styles: CustomContentStyles | undefined) => void;
}>({
  styles: undefined,
  setStyles: () => undefined,
});

export const useCustomLayoutContentStyles = () =>
  useContext(CustomLayoutContentStylesContext);

export { LayoutWithAsideMenu };
