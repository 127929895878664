import { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useServerAuth } from '../../providers';
import { BackgroundedWrapper } from '../styled';
import { useUserAgentDataQuery } from '../../apollo/operations';
import { DirectDeposit } from '../DirectDeposit';
import { Onramper } from '../Onramper';
import { PermissionsBlock } from '../PermissionsBlock';
import {
  DepositForm,
  FullAccessWithdrawForm,
  GpanelOnlyAccessWithdrawForm,
} from '../forms';
import { Tabs, TabList, Tab, TabPanel } from '../styled/tabs';
import { SwapTabContent } from './SwapTabContent';

const StakeTabs = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { authorized, fullAccess } = useServerAuth();
  const { data: { me: user } = {} } = useUserAgentDataQuery();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Wrapper as={'section'} {...props}>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>{t('CASHIER_STAKE_TABS__depositTab')}</Tab>
          <Tab>{t('CASHIER_STAKE_TABS__directDepositTab')}</Tab>
          <Tab>{t('CASHIER_STAKE_TABS__buyCryptoTab')}</Tab>
          <Tab>{t('CASHIER_STAKE_TABS__withdrawTab')}</Tab>
          <Tab>{t('CASHIER_STAKE_TABS__swapTab')}</Tab>
        </TabList>
        <TabPanel>
          {fullAccess ? <DepositForm /> : <PermissionsBlock small />}
        </TabPanel>
        <TabPanel>
          {authorized ? (
            <DirectDeposit onWalletDepositClick={() => setTabIndex(0)} />
          ) : (
            <PermissionsBlock small />
          )}
        </TabPanel>
        <TabPanel>
          {authorized ? <Onramper /> : <PermissionsBlock small />}
        </TabPanel>
        <TabPanel>
          {(() => {
            if (fullAccess || user?.agent?.hasAgent) {
              return <FullAccessWithdrawForm />;
            }
            if (authorized) {
              return <GpanelOnlyAccessWithdrawForm />;
            }
            return <PermissionsBlock small />;
          })()}
        </TabPanel>
        <TabPanel>
          <SwapTabContent />
        </TabPanel>
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled(BackgroundedWrapper)``;

export { StakeTabs };
