// noinspection JSUnusedGlobalSymbols
import { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { toNumber, get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { useSnackbar } from 'notistack';
import { getBlockchainErrorTranslationByCode } from '../../utils/blockchain-errors';
import {
  useCheckAllowanceNFT,
  useApproveAllNFT,
  useSwapNFT,
  useIsBSCChain,
} from '../../hooks';
import {
  useServerAuth,
  useMyNft,
  useOuterModals,
  useInnerModals,
} from '../../providers';
import { Button } from './styled';
import {
  useNftCardNameQuery,
  useSaveNftCardNameMutation,
  NftCardNameDocument,
} from '../../apollo/operations';
import { useGpanel } from '../../providers/GpanelProvider';
import { resetListStyles } from '../../styles/helpers';
import { Text } from '../Text';
import { Button as DefButton } from '../buttons';
import { CardNameForm } from '../forms';
import { Spinner as DefSpinner } from '../spinners/Spinner';

const OverviewTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { closeModal } = useInnerModals();
  const { dispatch } = useOuterModals();
  const { authorized, authOnServer } = useServerAuth();
  const { user } = useGpanel();
  const { card, cardIsProcessing, reloadCards, methods, swapApproved } =
    useMyNft();
  const checkAllowanceNFT = useCheckAllowanceNFT();

  const BSCBlockchain = useIsBSCChain();

  const { loading: checkingNFTAllowance } = useAsync(async () => {
    try {
      if (!(card && BSCBlockchain)) return;

      const swapAllowed = await checkAllowanceNFT(card.tokenId);

      if (swapAllowed) {
        methods.setSwapApproved(true);
      }
    } catch (e) {
      console.error('Error on checking swap allowing', e);
    }
  }, [card, BSCBlockchain]);

  const {
    loading: approvingNFTSwap,
    prepareError: approvingNFTSwapPrepareError,
    writeAsync: approveNFTSwap,
  } = useApproveAllNFT({
    enabled: [
      !!card,
      !card?.listing,
      BSCBlockchain,
      !checkingNFTAllowance,
      !swapApproved,
    ].every(Boolean),
    onTransactionSuccess: () => {
      enqueueSnackbar(
        t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabAllowSwapSuccess'),
        {
          variant: 'success',
        }
      );
    },
    onTransactionError: () => {
      enqueueSnackbar(
        t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabAllowSwapError'),
        {
          variant: 'error',
        }
      );
    },
    onConfirmSuccess: () => {
      methods.setSwapApproved(true);
      enqueueSnackbar(
        t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabAllowSwapConfirmSuccess'),
        {
          variant: 'success',
        }
      );
    },
    onConfirmError: () => {
      enqueueSnackbar(
        t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabAllowSwapConfirmError'),
        {
          variant: 'error',
        }
      );
    },
  });

  const prepareApproveNFTErrorText = useMemo(() => {
    if (!approvingNFTSwapPrepareError) {
      return null;
    }
    return t(
      getBlockchainErrorTranslationByCode(
        get(approvingNFTSwapPrepareError, 'data.code')
      )
    );
  }, [approvingNFTSwapPrepareError, t]);

  const {
    loading: swappingNFT,
    prepareError: swapNFTPrepareError,
    writeAsync: swapNFT,
  } = useSwapNFT({
    cardId: card?.tokenId,
    enabled: [
      !!card,
      !card?.listing,
      BSCBlockchain,
      !checkingNFTAllowance,
      swapApproved,
    ].every(Boolean),
    onTransactionSuccess: () => {
      enqueueSnackbar(
        t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabSwapSuccess'),
        {
          variant: 'success',
        }
      );
    },
    onTransactionError: () => {
      enqueueSnackbar(t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabSwapError'), {
        variant: 'error',
      });
    },
    onConfirmSuccess: async () => {
      enqueueSnackbar(
        t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabSwapConfirmSuccess'),
        {
          variant: 'success',
        }
      );
      closeModal();
      await reloadCards();
    },
    onConfirmError: () => {
      enqueueSnackbar(t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabSwapError'), {
        variant: 'error',
      });
    },
  });

  const swapNFTPrepareErrorText = useMemo(() => {
    if (!swapNFTPrepareError) {
      return null;
    }
    return t(
      getBlockchainErrorTranslationByCode(get(swapNFTPrepareError, 'data.code'))
    );
  }, [swapNFTPrepareError, t]);

  const [customNameFormActive, setCustomNameFormActive] = useState(!user);

  const { loading, data } = useNftCardNameQuery({
    skip: !card?.tokenId,
    fetchPolicy: 'cache-and-network',
    variables: {
      tokenId: toNumber(card?.tokenId),
    },
  });

  const cardName = useMemo(() => data?.nftOwners[0]?.pokerName, [data]);

  const [saveNftName] = useSaveNftCardNameMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: NftCardNameDocument,
        variables: {
          tokenId: toNumber(card?.tokenId),
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSavingCardName = useCallback(
    (name: string) => {
      // noinspection JSUnusedGlobalSymbols
      dispatch([
        'setModalContent',
        'confirmationModal',
        {
          onConfirm: async () => {
            try {
              if (!card?.tokenId) {
                throw new Error(`No tokenId`);
              }

              methods.setCardIsProcessing(true);

              const result = await saveNftName({
                variables: {
                  tokenId: toNumber(card.tokenId),
                  name,
                },
              });

              const message = result.data?.setNFTusername?.message;

              if (!message || message.startsWith('Error')) {
                throw new Error(message);
              }

              enqueueSnackbar(t('CARDNAME_FORM__success'), {
                variant: 'success',
              });
            } catch (e) {
              console.log('e', e);
              enqueueSnackbar(
                t('CARDNAME_FORM__error') +
                  ' - ' +
                  ('' + e).replace('Error:', ''),
                {
                  variant: 'error',
                }
              );
            } finally {
              methods.setCardIsProcessing(false);
            }
          },
          onCancel: () => {
            methods.setCardIsProcessing(false);
          },
        },
      ]);
    },
    [card, enqueueSnackbar, methods, dispatch, saveNftName, t]
  );

  if (!card) return null;

  if (!data && loading) {
    return <Spinner size={60} />;
  }

  if (!authorized) {
    return (
      <>
        <Text
          component="span"
          style={{ marginTop: '15px', marginBottom: '15px' }}
        >
          {t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabReloginTitle')}
        </Text>
        <Button onClick={authOnServer}>
          {t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabSignButtonText')}
        </Button>
      </>
    );
  }

  if (BSCBlockchain && card.listing) {
    return (
      <Text
        style={{
          marginBottom: 10,
        }}
      >
        {t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabDelistTitle')}
      </Text>
    );
  }

  if (BSCBlockchain && !card.listing) {
    return (
      <>
        <Text
          style={{
            marginBottom: 10,
          }}
        >
          {t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabSwapTitle')}
        </Text>
        {!checkingNFTAllowance && !swapApproved && (
          <Button
            loading={approvingNFTSwap}
            disabled={!!approvingNFTSwapPrepareError || !approveNFTSwap}
            style={{
              marginBottom: 10,
            }}
            onClick={() => approveNFTSwap?.()}
          >
            {t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabAllowSwapButton')}
          </Button>
        )}
        {!!prepareApproveNFTErrorText && (
          <ErrorText>{prepareApproveNFTErrorText}</ErrorText>
        )}
        <Button
          loading={checkingNFTAllowance || swappingNFT}
          disabled={!!swapNFTPrepareError || !swapApproved || !swapNFT}
          onClick={() => swapNFT?.()}
        >
          {t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabSwapButton')}
        </Button>
        {!!swapNFTPrepareErrorText && (
          <ErrorText>{swapNFTPrepareErrorText}</ErrorText>
        )}
      </>
    );
  }

  if (!cardName) {
    return customNameFormActive ? (
      <CardNameForm saveCardName={handleSavingCardName} />
    ) : (
      <ButtonsList>
        <ButtonsListItem>
          <ActionButton
            loading={cardIsProcessing}
            onClick={() => handleSavingCardName(user?.name ?? '')}
          >
            {t(
              'MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabRegisterInpokerNameButtonText'
            )}
          </ActionButton>
        </ButtonsListItem>
        <ButtonsListItem>
          <ActionButton onClick={() => setCustomNameFormActive(true)}>
            {t(
              'MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabRegisterCustomNameButtonText'
            )}
          </ActionButton>
        </ButtonsListItem>
      </ButtonsList>
    );
  }

  return (
    <>
      <Text component="span" color="corn" style={{ marginTop: '18px' }}>
        {t('MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabRegisteredTitle')}
      </Text>
      <Button disabled>
        {t(
          'MY_CARDS_CARD_OVERVIEW_MODAL__overviewTabRegisterInpokerNameButtonText'
        )}
      </Button>
    </>
  );
};

const Spinner = styled(DefSpinner)`
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
`;

const ButtonsList = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-direction: column;
`;

const ButtonsListItem = styled.li`
  & + & {
    margin-top: 10px;
  }
`;

const ActionButton = styled(DefButton)`
  width: 100%;
`;

const ErrorText = styled(Text).attrs({
  variant: 'small',
})`
  margin-top: 2px;
  margin-bottom: 5px;
  color: #d2042d;
  text-align: center;
`;

export { OverviewTab };
