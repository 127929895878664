import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SiteLinks } from '../../utils/consts/site-data';
import { Text } from '../Text';
import { Button as DefButton } from '../buttons';

const SwapTabContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title>{t('SWAP_TAB_CONTENT__title')}</Title>
      <Button
        onClick={() => {
          window
            .open(SiteLinks.INSWAP ?? '', '_blank', 'noopener,noreferrer')
            ?.focus();
        }}
      >
        {t('SWAP_TAB_CONTENT__inswapButton')}
      </Button>
      <Button
        onClick={() => {
          window
            .open(SiteLinks.PANCAKESWAP ?? '', '_blank', 'noopener,noreferrer')
            ?.focus();
        }}
      >
        {t('SWAP_TAB_CONTENT__pancakeSwapButton')}
      </Button>
    </>
  );
};

const Title = styled(Text)`
  margin-top: 30px;
`;

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 25px;
  text-transform: none;
`;

export { SwapTabContent };
