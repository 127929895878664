import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikFormProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { removeLocalStorageValue, LocalStorageKeys } from '../../utils/helpers';
import {
  useWithdrawPageWithdrawCodeConfirmMutation,
  LastDepositTransactionDocument,
} from '../../apollo/operations';
import { Text } from '../Text';
import { Button as DefButton } from '../buttons';
import { FormikInput } from '../formik-elements';

export type Withdraw2FACodeFormProps = { id: string } & FormikFormProps;

const Withdraw2FACodeForm = ({ id, ...props }: Withdraw2FACodeFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [withdrawConfirm, { loading: confirmingWithdraw }] =
    useWithdrawPageWithdrawCodeConfirmMutation({
      fetchPolicy: 'no-cache',
      awaitRefetchQueries: true,
      refetchQueries: [{ query: LastDepositTransactionDocument }],
    });

  const clearLocalStorageValue = () => {
    removeLocalStorageValue(LocalStorageKeys.WITHDRAW_2_FA_DATA);
  };

  return (
    <Formik<FormValues>
      initialValues={{
        code: '',
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async ({ code }) => {
        try {
          const confirmResponse = await withdrawConfirm({
            variables: {
              id,
              code,
            },
          });

          const responseStatus =
            confirmResponse.data?.paymentSubmitWithdraw2FA2?.status.toLowerCase();

          if (responseStatus !== 'created') {
            throw new Error(`Confirm status is wrong`);
          }

          enqueueSnackbar(t('WITHDRAW_2FA_CODE_FORM__submitSuccess'), {
            variant: 'success',
          });

          clearLocalStorageValue();
        } catch (e) {
          enqueueSnackbar(t('WITHDRAW_2FA_CODE_FORM__submitError'), {
            variant: 'error',
          });
        } finally {
          removeLocalStorageValue(LocalStorageKeys.WITHDRAW_2_FA_DATA);
        }
      }}
    >
      {() => (
        <Form {...props}>
          <Text
            component={'label'}
            htmlFor="staking-input"
            style={{
              marginBottom: 10,
            }}
          >
            {t('WITHDRAW_2FA_CODE_FORM__inputLabel')}:
          </Text>
          <Input
            id={'staking-input'}
            name={'code'}
            placeholder={t('WITHDRAW_2FA_CODE_FORM__inputPlaceholder')}
          />
          <Button
            type={'submit'}
            color={'secondary'}
            loading={confirmingWithdraw}
          >
            {t('WITHDRAW_2FA_CODE_FORM__submitButton')}
          </Button>
          <Button
            type={'button'}
            onClick={clearLocalStorageValue}
            loading={confirmingWithdraw}
          >
            {t('WITHDRAW_2FA_CODE_FORM__resetButton')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = {
  code: string;
};

const schema: Yup.SchemaOf<FormValues> = Yup.object({
  code: Yup.string()
    .min(3, 'Code should be longer')
    .required(`Field is required`),
});

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`;

const Input = styled(FormikInput)``;

const Button = styled(DefButton)`
  margin-top: 10px;
`;

export { Withdraw2FACodeForm };
