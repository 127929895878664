import { useAsync } from 'react-use';
import { useApolloClient } from '@apollo/client';
import { UserRoles } from '../utils/auth-consts';
import {
  getLocalStorageValue,
  LocalStorageKeys,
  setLocalStorageValue,
} from '../utils/helpers';
import {
  CommonInitialDataDocument,
  InitialUserDataDocument,
  CommonInitialDataQuery,
  CommonInitialDataQueryVariables,
  InitialUserDataQuery,
  InitialUserDataQueryVariables,
} from '../apollo/operations';
import { useGetAccessAuthData } from './useGetAccessAuthData';

export const useInitialData = () => {
  const client = useApolloClient();
  const getAccessAuthData = useGetAccessAuthData();

  return useAsync(async () => {
    let serverAuthData = getLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
    const gpanelAuthData = getLocalStorageValue(LocalStorageKeys.GPANEL_AUTH);

    const queries: Array<Promise<unknown>> = [];

    queries.push(
      client.query<CommonInitialDataQuery, CommonInitialDataQueryVariables>({
        query: CommonInitialDataDocument,
        fetchPolicy: 'network-only',
      })
    );

    if (!serverAuthData && gpanelAuthData?.accessToken) {
      try {
        const authData = await getAccessAuthData({
          gpanelAccessToken: gpanelAuthData.accessToken,
        });
        setLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH, authData);
        serverAuthData = authData;
      } catch (e) {}
    }

    if (serverAuthData?.accessToken) {
      queries.push(
        client.query<InitialUserDataQuery, InitialUserDataQueryVariables>({
          query: InitialUserDataDocument,
          fetchPolicy: 'network-only',
          variables: {
            limited: serverAuthData.role !== UserRoles.user,
          },
        })
      );
    }

    await Promise.all(queries);
  }, [client]);
};
