import { useCallback } from 'react';
import { setLocalStorageValue, LocalStorageKeys } from '../utils/helpers';
import { useGetServerAuthData } from './useGetServerAuthData';

export const useSilentServerAuth = () => {
  const getServerAuthData = useGetServerAuthData();
  return useCallback(
    async (skipWallet = false) => {
      try {
        const serverAuth = await getServerAuthData(skipWallet);
        setLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH, serverAuth);
      } catch (e) {
        console.log('SilentServerAuth error:', e);
      }
    },
    [getServerAuthData]
  );
};
