import { useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useIsPortraitTablet } from '../hooks';
import { useServerAuth } from '../providers';
import { useGpanel } from '../providers/GpanelProvider';
import { Avatar as DefAvatar } from './Avatar';
import { Text } from './Text';

export type AccountAvatarAndNameProps = HTMLAttributes<HTMLDivElement>;

const AccountAvatarAndName = (props: AccountAvatarAndNameProps) => {
  const { authorized } = useServerAuth();
  const { user } = useGpanel();
  const portraitTablet = useIsPortraitTablet();

  const userName = useMemo(() => {
    const name = user?.name;
    if (!name) return;
    return portraitTablet && name.length > 6
      ? `${name.substring(0, 6)}..`
      : name;
  }, [user?.name, portraitTablet]);

  if (!authorized) return null;

  return (
    <Wrapper {...props}>
      <Avatar />
      {!!userName && <Name>{userName}</Name>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled(DefAvatar)`
  margin-right: 16px;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    margin-right: 8px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    margin-right: 6px;
  }
`;

const Name = styled(Text)`
  margin-right: 10px;

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    margin-right: 6px;
  }
`;

export { AccountAvatarAndName };
