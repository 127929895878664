import React from 'react';
import styled, { css } from 'styled-components';
import { Pagination as DefPagination, PaginationProps } from '@material-ui/lab';

type Props = PaginationProps;

const Paginator: React.FC<Props> = ({ className, ...props }) => (
  <Wrapper className={className}>
    <StyledPagination {...props} />
  </Wrapper>
);

const Wrapper = styled.div``;

const StyledPagination = styled(DefPagination)`
  & .Mui-selected,
  & .MuiPaginationItem-icon,
  & .MuiPaginationItem-root {
    color: #fff;
  }

  & .MuiPaginationItem-page.Mui-selected {
    background: ${({ theme }) => theme.getColor('gradientPrimaryColor')};
  }

  ${({ theme }) => css`
    @media (max-width: 1370px) {
      & .MuiPaginationItem-root {
        min-width: auto;
        padding: 7px;
        width: 25px;
        height: 25px;
      }
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      & .MuiPaginationItem-root {
        min-width: auto;
        padding: 5px;
        width: 20px;
        height: 20px;
      }
    }

    @media (max-width: 360px) {
      & .MuiPaginationItem-root {
        min-width: auto;
        padding: 5px;
        width: 15px;
        height: 15px;
      }
    }
  `}
`;

export { Paginator };
