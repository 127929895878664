import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getPath } from 'utils/consts';
import { useAccount } from 'wagmi';
import { Drawer, DrawerProps } from '@material-ui/core';
import { SiteLinks } from '../utils/consts/site-data';
import { TranslationKeys } from '../utils/i18n';
import { languagesData, Languages } from '../utils/languages';
import { useIsPortraitTablet, useShortenedAddress } from '../hooks';
import logoImg from '../assets/img/logo.svg';
import cashier from '../assets/menu/cashier_3d.svg';
// import dao from '../assets/menu/dao_3d.svg';
// import dekings from '../assets/menu/dekings_3d.svg';
import home from '../assets/menu/home_3d.svg';
// import leaderboard from '../assets/menu/leaderboard_3d.svg';
import mycollection from '../assets/menu/mycollection_3d.svg';
import nftavatar from '../assets/menu/nftavatar_3d.svg';
import vault from '../assets/menu/vault_3d.svg';
import { resetListStyles } from '../styles/helpers';
import { AccountAvatarAndName } from './AccountAvatarAndName';
import { AppbarGpanel } from './AppbarGpanel';
import { SidebarMenuItem, SidebarMenuItemProps } from './SidebarMenuItem';
import { Text } from './Text';
import {
  Select as DefSelect,
  IconPosition,
  IconSize,
} from './form-elements/Select';

export type SidebarProps = {
  isMobileMenuOpened: boolean;
  closeMobileMenu: () => void;
};

const Sidebar = ({ isMobileMenuOpened, closeMobileMenu }: SidebarProps) => {
  const { address } = useAccount();
  const { t, i18n } = useTranslation();
  const portraitTable = useIsPortraitTablet();

  const shortenedAddress = useShortenedAddress(address, {
    mobile: {
      start: 11,
      end: 7,
    },
  });

  const wrapperProps = useMemo<DrawerProps>(
    () =>
      portraitTable
        ? {
            variant: 'temporary',
            anchor: 'left',
            open: isMobileMenuOpened,
            onClose: closeMobileMenu,
          }
        : {
            variant: 'permanent',
            open: true,
            PaperProps: { square: false },
          },
    [closeMobileMenu, isMobileMenuOpened, portraitTable]
  );

  const changeLanguage = async (lng?: string) => {
    await i18n.changeLanguage(lng);
  };

  return (
    <Wrapper {...wrapperProps}>
      <LogoWrapper>
        <img src={logoImg} alt={'Logo'} />
      </LogoWrapper>
      <MobileUserInfo>
        <AccountAvatarAndName />
        <Gpanel />
        {!!shortenedAddress && (
          <MobileUserInfoAddress>{shortenedAddress}</MobileUserInfoAddress>
        )}
      </MobileUserInfo>
      <Nav>
        <ul onClick={() => closeMobileMenu()}>
          {sidebarRouteLinks.map((props, index) => (
            <li key={index}>
              <SidebarMenuItem {...props} />
            </li>
          ))}
        </ul>
      </Nav>
      <ServiceItems>
        <Select
          defaultValue={{
            label: i18n.language ?? '',
            value: i18n.language,
            icon: languagesData.get(i18n.language as Languages)?.icon ?? '',
            iconPosition: IconPosition.START,
            iconSize: IconSize.BIG,
          }}
          dropDownTop={true}
          options={selectOptions}
          isSearchable={false}
          onChange={(e) => {
            if (e && 'value' in e) {
              changeLanguage(e.value);
            }
          }}
        />
        <ServiceList>
          {serviceLinks.map(({ link, name }) => (
            <ServiceListItem key={name}>
              <ServiceListItemLink
                component={'a'}
                href={link}
                variant={'small'}
              >
                {t(name)}
              </ServiceListItemLink>
            </ServiceListItem>
          ))}
        </ServiceList>
      </ServiceItems>
    </Wrapper>
  );
};

const selectOptions = Array.from(languagesData).reduce(
  (acc, [language, { label, icon }]) => {
    const newRecord = {
      label,
      value: language,
      icon,
      iconPosition: IconPosition.START,
    };
    return [...acc, newRecord];
  },
  [] as ReadonlyArray<{
    label: string;
    value: Languages;
    icon: string;
    iconPosition: IconPosition;
  }>
);

const Wrapper = styled(Drawer)`
  width: 250px;
  background: transparent;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  .MuiDrawer-paper {
    width: inherit;
    padding-top: 38px;
    padding-bottom: 35px;
    border-right: 1px solid ${({ theme }) => theme.getColor('scarpaFlow')};
    background: ${({ theme }) => theme.getColor('shark')};
  }

  .MuiPaper-root {
    color: #fff;
  }
`;

const LogoWrapper = styled.div`
  width: 157px;
  margin-bottom: 40px;
  align-self: center;

  ${({ theme }) => theme.getDownMedia('sm')} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.getDownMedia('xs')} {
    margin-bottom: 20px;
  }
`;

const MobileUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  margin-left: 28px;
  margin-right: 28px;
  border-bottom: 1px solid ${({ theme }) => theme.getColor('boulder')};
  ${({ theme }) => theme.getUpMedia('sm')} {
    display: none;
  }
`;

const Gpanel = styled(AppbarGpanel)`
  padding: 10px 0;
  > div {
    ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const MobileUserInfoAddress = styled(Text)`
  font-size: ${({ theme }) => theme.pxToRem(10)};
  line-height: ${12 / 10};
  color: ${({ theme }) => theme.getColor('dustyGray')};
`;

const Nav = styled.nav`
  > ul {
    ${resetListStyles};
  }
`;

const ServiceItems = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: auto 34px 20px;
`;

const ServiceList = styled.ul`
  display: flex;
  justify-content: space-between;
`;
const ServiceListItem = styled.li`
  & + & {
    margin-left: 10px;
  }
`;

const ServiceListItemLink = styled(Text)`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Select = styled(DefSelect)`
  margin-bottom: 21px;
`;

const sidebarRouteLinks: SidebarMenuItemProps[] = [
  {
    icon: home,
    title: 'SIDEBAR_MENU_ITEM__home',
    linkProps: {
      to: '',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open('https://play.inpoker.app', '_blank');
      },
    },
  },
  {
    icon: cashier,
    title: 'SIDEBAR_MENU_ITEM__cashier',
    linkProps: {
      to: getPath('cashier'),
    },
  },
  /*{
    icon: dekings,
    title: 'SIDEBAR_MENU_ITEM__deKingsNFTs',
    items: [
      {
        icon: mycollection,
        title: 'SIDEBAR_MENU_ITEM__deKingsNFTsMyNFTs',
        linkProps: {
          to: getPath('myNftCards'),
        },
      },
      {
        icon: dao,
        title: 'SIDEBAR_MENU_ITEM__deKingsNFTsDAO',
        linkProps: {
          to: getPath('myNftCards'),
        },
      },
      {
        icon: leaderboard,
        title: 'SIDEBAR_MENU_ITEM__deKingsNFTsPOS',
        linkProps: {
          to: getPath('myNftCards'),
        },
      },
    ],
  },*/
  {
    icon: mycollection,
    title: 'SIDEBAR_MENU_ITEM__myNftCards',
    linkProps: {
      to: getPath('myNftCards'),
    },
  },
  {
    icon: nftavatar,
    title: 'SIDEBAR_MENU_ITEM__nftAvatar',
    linkProps: {
      to: getPath('nftAvatar'),
    },
  },
  {
    icon: vault,
    title: 'SIDEBAR_MENU_ITEM__vault',
    linkProps: {
      to: getPath('vault'),
    },
  },
];

const serviceLinks: ReadonlyArray<{
  name: TranslationKeys;
  link: SiteLinks;
}> = [
  {
    name: 'SIDEBAR__terms',
    link: SiteLinks.TERMS_AND_CONDITIONS,
  },
  {
    name: 'SIDEBAR__support',
    link: SiteLinks.SUPPORT_EMAIL,
  },
];

export { Sidebar };
