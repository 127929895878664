import styled from 'styled-components';
import { TextField, TextFieldProps } from '@material-ui/core';

export type InputProps = TextFieldProps;

const Input = (props: InputProps) => <StyledInput type={'text'} {...props} />;

const StyledInput = styled(TextField)`
  .MuiInputBase-root {
    border: 1px solid ${({ theme }) => theme.getColor('brightGray')};
    border-radius: 5px;
    background: ${({ theme }) => theme.getColor('alto')};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: ${16 / 14};
    color: ${({ theme }) => theme.getColor('mineShaft')};

    &.Mui-disabled {
      color: ${({ theme }) => theme.getColor('boulder')};
    }

    &.Mui-error {
      border-color: ${({ theme }) => theme.getColor('punch')};
    }

    &::placeholder {
      color: ${({ theme }) => theme.getColor('boulder')};
    }

    &:before,
    &:after {
      display: none;
    }
  }

  .MuiInputBase-input {
    padding: 9px 7px 10px 7px;
  }

  .MuiFormHelperText-root,
  .MuiFormHelperText-root.Mui-disabled {
    font-size: 0.8rem;
    color: #fff;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export { Input };
