import { useState } from 'react';
import styled from 'styled-components';
import { isEmpty, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { NetworkStatus } from '@apollo/client';
import { TranslationKeys } from '../../utils/i18n';
import {
  useAgentUsersBalancesQuery,
  AgentUsersBalancesQuery,
} from '../../apollo/operations';
import { TablePagination } from '../TablePagination';
import {
  TableLoader,
  TableRow,
  TableCell,
  TableWrapper,
  Table as DefTable,
} from '../styled/table';

const AgentUsersBalancesTable = () => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const {
    networkStatus,
    error,
    data: {
      evenbetAffiliatePlayerBalances,
      evenbetAffiliatePlayerBalancesAggregate,
    } = {},
  } = useAgentUsersBalancesQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    pollInterval: 30 * 1000,
    variables: {
      limit,
      offset,
    },
  });

  if (error) {
    return <>{t('AGENT_USERS_TALBE__loadError')}</>;
  }

  const total =
    evenbetAffiliatePlayerBalancesAggregate?.aggregate?.totalCount ?? 0;

  return (
    <TableWrapper>
      {networkStatus === NetworkStatus.setVariables && <TableLoader />}
      {(() => {
        if (isEmpty(evenbetAffiliatePlayerBalances)) {
          return t('AGENT_USERS_TALBE__noBalances');
        }

        return (
          <>
            <Table role="table">
              <TableRow role="rowgroup">
                {Array.from(columns).map(([key, label]) => (
                  <TableCell key={key} role="columnheader" $header>
                    {t(label)}
                  </TableCell>
                ))}
              </TableRow>
              {evenbetAffiliatePlayerBalances!.map((data) => (
                <TableRow key={data.playerUsername} role="rowgroup">
                  {Array.from(columns).map(([key]) => {
                    const dataValue = data[key];
                    let cellValue;

                    switch (key) {
                      case 'cashBalanceUsd':
                        cellValue = `${toNumber(
                          dataValue
                        ).toLocaleString()} USD`;
                        break;
                      case 'cashBalanceInp':
                        cellValue = `${toNumber(
                          dataValue
                        ).toLocaleString()} INP`;
                        break;
                      default:
                        cellValue = dataValue;
                    }

                    return (
                      <TableCell key={key} role="cell">
                        {cellValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </Table>
            {limit <= total && (
              <TablePagination
                offset={offset}
                limit={limit}
                totalAmount={total}
                onLimitChange={setLimit}
                onOffsetChange={setOffset}
              />
            )}
          </>
        );
      })()}
    </TableWrapper>
  );
};

const Table = styled(DefTable)`
  grid-template-columns: repeat(3, 1fr);
`;

type UserBalance =
  AgentUsersBalancesQuery['evenbetAffiliatePlayerBalances'][number];
type ColumnName = keyof Omit<UserBalance, '__typename'>;

const columns: ReadonlyMap<ColumnName, TranslationKeys> = new Map([
  ['playerUsername', 'AGENT_USERS_TALBE__playerUsernameColumn'],
  ['cashBalanceUsd', 'AGENT_USERS_TALBE__playerUsdCashBalanceColumn'],
  ['cashBalanceInp', 'AGENT_USERS_TALBE__playerInpCashBalanceColumn'],
]);

export { AgentUsersBalancesTable };
