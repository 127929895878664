import { createContext, useContext } from 'react';
import { pick } from 'lodash-es';
import { UserNftsQuery } from '../apollo/operations';

type WrappedMethods<M> = {
  [P in keyof M]: (...payload: $TSFixMe) => void;
};

type Card = UserNftsQuery['nfts'][number];

export type MyNftCardContextState = {
  loading: boolean;
  cardIsProcessing: boolean;
  card: Maybe<Card>;
  swapApproved: boolean;
  reloadCards: () => Promise<void>;
};

export const initialMyNftState: MyNftCardContextState = {
  loading: false,
  cardIsProcessing: false,
  card: null,
  swapApproved: false,
  reloadCards: () => Promise.resolve(),
};

// noinspection JSUnusedGlobalSymbols
export const createMyNftCardMethods = (state: MyNftCardContextState) => ({
  toggleLoading: (val: boolean) => ({
    ...state,
    loading: val ?? !state.loading,
  }),
  setCardIsProcessing: (val: boolean) => ({
    ...state,
    cardIsProcessing: val ?? !state.loading,
  }),
  setCard: (card: Card) => ({
    ...state,
    card,
  }),
  setReloadCards: (reloadCards: () => Promise<void>) => ({
    ...state,
    reloadCards,
  }),
  setSwapApproved: (swapApproved: boolean) => ({
    ...state,
    swapApproved,
  }),
  resetContractsData: () => ({
    ...state,
    ...pick(initialMyNftState, ['swapApproved']),
  }),
  reset: () => initialMyNftState,
});

const MyNftCardContext = createContext<
  MyNftCardContextState & {
    methods: WrappedMethods<ReturnType<typeof createMyNftCardMethods>>;
  }
>({
  ...initialMyNftState,
  methods: createMyNftCardMethods(initialMyNftState),
});

export const useMyNft = () => useContext(MyNftCardContext);

const MyNftCardProvider = MyNftCardContext.Provider;

export { MyNftCardProvider };
