import { useMemo, useCallback } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { useSnackbar } from 'notistack';
import {
  useUpdateAvatarNftsMutation,
  LoadingAvatarProcessesDocument,
  UserNftAvatarsDocument,
  LoadingAvatarProcessesQuery,
  LoadingAvatarProcessesQueryVariables,
} from '../apollo/operations';

export const useUpdateAvatars = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const showError = useCallback(() => {
    enqueueSnackbar(t('NFT_AVATAR_PAGE__updateSectionError'), {
      variant: 'error',
    });
  }, [enqueueSnackbar, t]);

  const [launchAvatarsUpdate, { client }] = useUpdateAvatarNftsMutation({
    fetchPolicy: 'no-cache',
    onError: showError,
  });

  const [
    { loading: updatingAvatars, error: errorOnAvatarsUpdate },
    updateAvatars,
  ] = useAsyncFn(async () => {
    let runCheckTry = 0;

    await launchAvatarsUpdate();

    return new Promise<void>((resolve, reject) => {
      const runCheck = async () => {
        runCheckTry++;

        const activeJobsResponse = await client.query<
          LoadingAvatarProcessesQuery,
          LoadingAvatarProcessesQueryVariables
        >({
          query: LoadingAvatarProcessesDocument,
        });

        const jobs = activeJobsResponse.data.userJobs;

        if (isEmpty(jobs)) {
          enqueueSnackbar(t('NFT_AVATAR_PAGE__updateSectionSuccess'), {
            variant: 'success',
          });
          client
            .refetchQueries({
              include: [UserNftAvatarsDocument],
            })
            .then(() => resolve())
            .catch((e) => {
              showError();
              reject(e);
            });
        } else if (runCheckTry <= 10) {
          setTimeout(runCheck, 4000);
        } else {
          showError();
          reject(new Error('Reached max tries and jobs still in progress'));
        }
      };

      setTimeout(runCheck, 4000);
    });
  }, [launchAvatarsUpdate, client, enqueueSnackbar, t]);

  return useMemo(
    () =>
      [
        updateAvatars,
        {
          updatingAvatars,
          errorOnAvatarsUpdate,
        },
      ] as const,
    [updateAvatars, updatingAvatars, errorOnAvatarsUpdate]
  );
};
