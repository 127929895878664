// import EnIcon from '../assets/svgs/en.svg';
// import SpIcon from '../assets/svgs/es.svg';
// import jaIcon from '../assets/svgs/japan.svg';
// import KrIcon from '../assets/svgs/kr.svg';
// import VnIcon from '../assets/svgs/vn.svg';
// import RuIcon from '../assets/svgs/ru.svg';

export type Languages =
  | 'English'
  | 'Korean'
  | 'Spanish'
  | 'Vietnamese'
  | 'Japanese'
  | 'Russian';

export const languagesData: ReadonlyMap<
  Languages,
  {
    label: string;
    icon: string;
  }
> = new Map([
  [
    'English',
    {
      label: 'English',
      icon: '', //EnIcon,
    },
  ],
  [
    'Korean',
    {
      label: 'Korean',
      icon: '', //icon: KrIcon,
    },
  ],
  [
    'Japanese',
    {
      label: 'Japanese',
      icon: '', //icon: jaIcon,
    },
  ],
  [
    'Spanish',
    {
      label: 'Spanish',
      icon: '', //icon: SpIcon,
    },
  ],
  [
    'Vietnamese',
    {
      label: 'Vietnamese',
      icon: '', //icon: VnIcon,
    },
  ],
  [
    'Russian',
    {
      label: 'Russian',
      icon: '', //icon: RuIcon,
    },
  ],
]);
