import styled, { css } from 'styled-components';
import { CardImage } from './CardImage';

export const CardWrapper = styled.li<{
  $cardIsChecked?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 175px;
  border: 1px solid
    ${({ theme, $cardIsChecked }) =>
      theme.getColor($cardIsChecked ? 'punch' : 'mineShaft')};
  border-radius: 50px 50px 5px 5px;
  position: relative;
  background-color: ${({ theme }) => theme.getColor('shark')};
  cursor: pointer;

  ${({ theme, $cardIsChecked }) =>
    $cardIsChecked &&
    css`
      ${CardImage} {
        border-color: ${theme.getColor('punch')};
      }
    `}
`;
