import { isString } from 'lodash-es';
import busd from '../../assets/svgs/icons/colorful/currencies/busd.svg';
import inp from '../../assets/svgs/icons/colorful/currencies/inp.svg';
import usd from '../../assets/svgs/icons/colorful/currencies/usd.svg';
import usdc from '../../assets/svgs/icons/colorful/currencies/usdc.svg';
import usdt from '../../assets/svgs/icons/colorful/currencies/usdt.svg';

export enum Currencies {
  USD = 'USD',
  INP = 'INP',
  BUSD = 'BUSD',
  USDT = 'USDT',
  USDC = 'USDC',
}

export const currenciesData: {
  [key in Currencies]: {
    icon: string;
  };
} = {
  [Currencies.USD]: {
    icon: usd,
  },
  [Currencies.INP]: {
    icon: inp,
  },
  [Currencies.BUSD]: {
    icon: busd,
  },
  [Currencies.USDT]: {
    icon: usdt,
  },
  [Currencies.USDC]: {
    icon: usdc,
  },
};
export const isInpTokenSymbol = (symbol: Maybe<string>) =>
  isString(symbol) && symbol.toLowerCase() === Currencies.INP.toLowerCase();
