import { useCallback, useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import SumsubWebSdk from '@sumsub/websdk-react';
import {
  SumsubAccessTokenDocument,
  SumsubAccessTokenQuery,
  SumsubAccessTokenQueryVariables,
} from '../apollo/operations';
import { useGpanel } from '../providers/GpanelProvider';

export type SumsubProps = {
  className?: string;
};

const Sumsub = (props: SumsubProps) => {
  const client = useApolloClient();
  const { user } = useGpanel();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const getAccessToken = useCallback(async () => {
    try {
      if (!user) {
        throw new Error(`No gpanel user`);
      }

      const { data } = await client.query<
        SumsubAccessTokenQuery,
        SumsubAccessTokenQueryVariables
      >({
        query: SumsubAccessTokenDocument,
        fetchPolicy: 'no-cache',
        variables: {
          gpanelUserId: user.id,
        },
      });

      const accessToken = data.paymentSumsubToken?.accessToken;

      if (!accessToken) {
        throw new Error(`No access token in response`);
      }

      return accessToken;
    } catch (e) {
      console.log(`Error getting access token:`, e);
      return '';
    }
  }, [user, client]);

  useEffect(() => {
    let mounted = true;

    getAccessToken().then((accessToken) => {
      accessToken && mounted && setAccessToken(accessToken);
    });

    return () => {
      mounted = false;
    };
  }, [getAccessToken]);

  if (!accessToken) return null;

  return (
    <SumsubWebSdk
      accessToken={accessToken}
      expirationHandler={getAccessToken}
      config={{
        lang: 'en',
        email: user?.email,
        uiConf: {
          customCssStr: `
            :root {
              --primary-color: #757575;
            }
            .content {
              border-radius: 5px;
              background: #141414;
            }
            .sumsub-logo{
              color: #fff;
            }
          `,
        },
      }}
      // options={options}
      onMessage={(message: unknown) => {
        console.log(`Sumsub message:`, message);
      }}
      onError={(e: unknown) => {
        console.log(`Sumsub error:`, e);
      }}
      {...props}
    />
  );
};

export { Sumsub };
