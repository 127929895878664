import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import {
  tabletDefaultSize,
  mobileDefaultSize,
  desktopDefaultSize,
} from './consts';
import { normalFontFamilyStyles } from './helpers';

const GlobalStyles = createGlobalStyle`
  ${normalize};

  html, body {
    margin: 0;
    max-width: 100%;
    overflow-x: hidden;
    background: ${({ theme }) => theme.getColor('woodsmoke')};
    font-style: normal;
    font-weight: 400;
    font-size: ${desktopDefaultSize}px;
    line-height: ${16.4 / desktopDefaultSize};
    color: #fff;
    box-sizing: border-box;

    ${normalFontFamilyStyles};

    ${({ theme }) => theme.getDownMedia('sm')} {
      font-size: ${tabletDefaultSize}px;
    }

    ${({ theme }) => theme.getDownMedia('xs')} {
      font-size: ${mobileDefaultSize}px;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  // TODO: remove on applying new design
  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
  }

  ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .ReactModal__Content {
    max-width: 100%;
    display: flex;
    justify-content: center;
    outline: none !important;
  }

  .center-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .center-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
    background-color: rgba(70, 70, 70, 0.5);
  }
`;

export { GlobalStyles };
