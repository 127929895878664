import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { useInnerModals } from '../../providers';
import { Button as DefButton, ButtonProps } from './Button';

const ETHAccountButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { dispatch } = useInnerModals();

  if (address) return null;

  // TODO: replace with download apps options
  /*if (error instanceof NoEthereumProviderError) {
    return (
      <DownloadMetaMaskButton
        {...props}
        onClick={() => {
          const link = document.createElement('a');
          link.href = 'https://metamask.io/download.html';
          link.target = '_blank';
          link.click();
        }}
      >
        {t('DASHBOARD__downloadMetamaskButtonText')}
      </DownloadMetaMaskButton>
    );
  }*/

  return (
    // @ts-ignore
    <ConnectWalletButton
      icon="loginIcon"
      onClick={() => {
        dispatch(['setModalContent', 'wallets']);
      }}
      {...props}
    >
      {children || t('DASHBOARD__connectWalletButtonText')}
    </ConnectWalletButton>
  );
};

/*const DownloadMetaMaskButton = styled(DefButton)`
  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    visibility: hidden;
  }
`;*/

const ConnectWalletButton = styled(DefButton)``;

export { ETHAccountButton };
