import { isString } from 'lodash-es';

export enum NftDelegationStatuses {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
}

export const isNftDelegationStatus = (
  status: unknown
): status is NftDelegationStatuses => {
  if (!isString(status)) return false;
  return Object.values(NftDelegationStatuses).some((s) => s === status);
};
