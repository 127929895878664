import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  useConnect,
  useDisconnect,
  ConnectorNotFoundError,
  UserRejectedRequestError,
  SwitchChainError,
  SwitchChainNotSupportedError,
} from 'wagmi';
import { ConnectorNames } from '../utils/consts/connectors';
import { removeLocalStorageValue, LocalStorageKeys } from '../utils/helpers';
import { useChainId } from './useChainId';

export const useWalletAuth = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { connectors, connectAsync } = useConnect();
  const chainId = useChainId();
  const { disconnectAsync } = useDisconnect();

  const login = useCallback(
    async (connectorID: ConnectorNames) => {
      const findConnector = connectors.find((c) => c.id === connectorID);

      try {
        await connectAsync({
          connector: findConnector,
          chainId,
        });
        /*if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory('chainId', connected.chain.id);
          setSessionChainId(connected.chain.id);
        }*/
      } catch (error) {
        console.error(error);
        removeLocalStorageValue(LocalStorageKeys.CONNECTOR);
        if (error instanceof UserRejectedRequestError) {
          // Do nothing
          return;
        }
        if (error instanceof ConnectorNotFoundError) {
          enqueueSnackbar(t('WALLET_AUTH__unsupportedNetworkError'), {
            variant: 'warning',
          });
        }
        if (
          error instanceof SwitchChainNotSupportedError ||
          error instanceof SwitchChainError
        ) {
          enqueueSnackbar(t('WALLET_AUTH__unableToSwitchNetworkError'), {
            variant: 'error',
          });
        }
        if (error instanceof Error) {
          enqueueSnackbar(t('WALLET_AUTH__authError'), {
            variant: 'error',
          });
        }
      }
    },
    [t, connectors, connectAsync, chainId, enqueueSnackbar]
  );

  const logout = useCallback(async () => {
    try {
      await disconnectAsync();
    } catch (error) {
      console.error(error);
    } finally {
      removeLocalStorageValue(LocalStorageKeys.CONNECTOR);
    }
  }, [disconnectAsync]);

  return { login, logout };
};
