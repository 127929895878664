import { useMemo } from 'react';
import { useServerAuth } from '../providers';
import { useUserAvatarQuery } from '../apollo/operations';

export const useUserAvatar = () => {
  const { authorized } = useServerAuth();
  const { loading, data } = useUserAvatarQuery({
    skip: !authorized,
  });
  return useMemo(
    () => ({
      loading,
      avatar: data?.me?.avatar,
    }),
    [loading, data?.me?.avatar]
  );
};
