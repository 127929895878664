import styled from 'styled-components';
import { TabPanel as DefTabPanel } from 'react-tabs';
import { asideTabPanelStyles } from '../../../styles/tabs';

export const TabPanel = styled(DefTabPanel)`
  &.react-tabs__tab-panel--selected {
    padding-top: 16px;
    padding-bottom: 16px;
    ${asideTabPanelStyles};
  }
`;
