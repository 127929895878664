import { isNil } from 'lodash-es';

const envVariables = {
  HTTP_API_URL: process.env.REACT_APP_HTTP_API_URL,
  INFURA_ID: process.env.REACT_APP_INFURA_ID,
  WALLET_CONNECT_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  BSC_CHAIN_ID: process.env.REACT_APP_BSC_CHAIN_ID,
  CHAIN_ID_WITH_MY_NFTS: process.env.REACT_APP_CHAIN_ID_WITH_MY_NFTS,
  INPOKER_OAUTH2_URL: process.env.REACT_APP_INPOKER_OAUTH2_URL,
  NFT_SWAP_ADDRESS: process.env.REACT_APP_NFT_SWAP_ADDRESS,
  CLAIM_PAGE_ENABLED: process.env.REACT_APP_CLAIM_PAGE_ENABLED,
  ONRAMPER_KEY: process.env.REACT_APP_ONRAMPER_KEY,
};

type EnvVariableName = keyof typeof envVariables;

export const getEnvVariable = (name: EnvVariableName, required = true) => {
  const value = envVariables[name];
  if (isNil(value) && required) {
    throw new Error(`${name} variable should be set in .env file`);
  }
  return value!;
};
