export const simpleColors = {
  completelyBlack: '#000',
  woodsmoke: '#0D0E12',
  gondola: '#1F121D',
  gondola2: '#1F111D',
  shark: '#1C1F25',
  shark2: '#282C34',
  mineShaft: '#2F2F2F',
  charade: '#2A2E37',
  brightGray: '#3C424D',
  scarpaFlow: '#514F5F',
  boulder: '#757575',
  hurricane: '#8D8686',
  manatee: '#898C96',
  friarGray: '#7E7E7D',
  friarGray2: '#7a7b75',
  osloGray: '#919397',
  dustyGray: '#999999',
  silverChalice: '#ADA9A9',
  alto: '#DDDDDD',
  corn: '#EAAF08',
  mandy: '#E04C4C',
  punch: '#D82929',
  tallPoppy: '#BE2E2E',
  emerald: '#44C658',
  silverTree: '#5DBE95',
  oceanGreen: '#3A936D',
  horizon: '#5E7DAA',
  eastBay: '#40587C',
  red: '#FF0303',
} as const;

export type SimpleColorName = keyof typeof simpleColors;
export type SimpleColorValue = (typeof simpleColors)[SimpleColorName];

export const gradientColors = {
  gradientPrimaryColor: `linear-gradient(94.1deg, ${simpleColors.mandy} 0%, ${simpleColors.punch} 99.58%);`,
  gradientPrimaryInvertedColor: `linear-gradient(94.1deg, ${simpleColors.punch} 0%, ${simpleColors.mandy} 99.58%);`,
  gradientSecondaryColor: `linear-gradient(94.1deg, ${simpleColors.silverTree} 0%, ${simpleColors.oceanGreen} 99.58%), linear-gradient(94.1deg, ${simpleColors.horizon} 0%, ${simpleColors.eastBay} 99.58%);`,
  gradientSecondaryInvertedColor: `linear-gradient(94.1deg, ${simpleColors.oceanGreen} 0%, ${simpleColors.silverTree} 99.58%), linear-gradient(94.1deg, ${simpleColors.eastBay} 0%, ${simpleColors.horizon} 99.58%)`,
  gradientModalBackground: `linear-gradient(134.78deg, ${simpleColors.shark} -8.57%, ${simpleColors.gondola} 101.36%, ${simpleColors.gondola2} 104.3%)`,
  gradientButtonBackground: `linear-gradient(350.98deg, ${simpleColors.friarGray} -87.56%, ${simpleColors.friarGray2} 277.62%)`,
} as const;

export type GradientColorName = keyof typeof gradientColors;
export type GradientColorValue = (typeof gradientColors)[GradientColorName];

export const rgbaColors = {
  rgbaBorderModalColor: 'rgba(51,51,51, 0.5)',
  rgbaDividerColor: 'rgba(255, 255, 255, 0.1)',
};

export type RgbaColorName = keyof typeof rgbaColors;
export type RgbaColorValue = (typeof rgbaColors)[RgbaColorName];

export type ColorName = SimpleColorName | GradientColorName | RgbaColorName;
export type ColorValue = SimpleColorValue | GradientColorValue | RgbaColorValue;
