import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isString, toString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import colorFn from 'color';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { resetButtonStyles } from '../../styles/helpers';

export type CopyButtonProps = {
  valueToCopy: unknown;
} & HTMLAttributes<HTMLButtonElement>;

const CopyButton = ({ valueToCopy, ...props }: CopyButtonProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Button
      aria-label={'Copy'}
      onClick={() => {
        const copied = copy(
          isString(valueToCopy) ? valueToCopy : toString(valueToCopy)
        );
        enqueueSnackbar(
          t(copied ? 'COPY_BUTTON__copied' : 'COPY_BUTTON__notCopied'),
          { variant: copied ? 'success' : 'warning' }
        );
      }}
      {...props}
    >
      <svg
        width={10}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.222.72H3.74c-.651 0-1.181.54-1.181 1.204v.148h-.78c-.652 0-1.181.54-1.181 1.204v7.12c0 .664.53 1.204 1.18 1.204h4.482c.65 0 1.18-.54 1.18-1.204v-.148h.78c.652 0 1.181-.54 1.181-1.204v-7.12c0-.664-.53-1.204-1.18-1.204ZM6.68 10.396a.424.424 0 0 1-.42.427H1.78a.424.424 0 0 1-.42-.427v-7.12c0-.236.188-.427.42-.427h4.48c.232 0 .42.191.42.427v7.12Zm1.96-1.352a.424.424 0 0 1-.418.427h-.78V3.276c0-.664-.53-1.204-1.181-1.204H3.32v-.148c0-.236.189-.427.42-.427h4.48c.232 0 .42.191.42.427v7.12Z"
          fill="currentColor"
        />
      </svg>
    </Button>
  );
};

const Button = styled.button`
  ${resetButtonStyles};
  display: block;
  width: 16px;
  height: 17px;
  border-radius: 5px;
  background: ${({ theme }) => theme.getColor('gradientButtonBackground')};
  color: #fff;

  &:hover {
    background: ${({ theme }) =>
      colorFn(theme.getColor('friarGray')).darken(0.3).hex()};
  }
`;

export { CopyButton };
