import { isEmpty, isArray } from 'lodash-es';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

const cache = new InMemoryCache({
  typePolicies: {
    query_root: {
      fields: {
        nfts: {
          keyArgs: false,
          merge: (_, incoming) => incoming,
        },
        gpanelPayments: {
          ...offsetLimitPagination(),
          read: (existing, { args }) => {
            if (!existing) return null;
            const limit = args?.limit ?? 10;
            const offset = args?.offset ?? 0;
            return existing && existing.slice(offset, offset + limit);
          },
        },
        gpanelPaymentsAggregate: {
          keyArgs: false,
          merge: (_, incoming) => incoming,
        },
        pendingTransactions: {
          merge: (_, incoming) => incoming,
        },
        nftOwners: {
          keyArgs: false,
          read: (existing, { args }) => {
            if (!isArray(existing) || isEmpty(existing)) return false;
            if (args?.tokenId) {
              return existing.filter((item) => item.tokenid === args.tokenId);
            } else {
              return existing;
            }
          },
          merge: (_, incoming) => incoming,
        },
        evenbetAffiliatePlayerBalances: {
          merge: (_, incoming) => incoming,
        },
        blockchains: {
          keyArgs: false,
        },
        agentGetUsers: {
          merge: (_, incoming) => incoming,
        },
      },
      queryType: true,
    },
    UserOutput: {
      fields: {
        agent: {
          keyArgs: false,
          merge: true,
        },
        depositAddress: {
          keyArgs: false,
          merge: (_, incoming) => incoming,
        },
      },
    },
    gpanelPayments: {
      keyFields: false,
    },
    pendingTransactions: {
      keyFields: false,
      merge: (_, incoming) => incoming,
    },
    gpanelUsers: {
      fields: {
        kyc: {
          keyArgs: false,
        },
      },
    },
    stablecoins: {
      keyFields: false,
    },
    mutation_root: {
      mutationType: true,
    },
  },
});

let persistor: CachePersistor<NormalizedCacheObject>;

let persisted = false;

const removeCacheFromLocalStorage = async () => {
  if (persistor) {
    return persistor.purge();
  }
};

const getCache = async () => {
  if (persisted) return cache;

  persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    debug: false,
    trigger: 'write',
  });

  await persistor.restore();
  persisted = true;
  return cache;
};

export { getCache, removeCacheFromLocalStorage };
