import { useLayoutEffect } from 'react';
import { useInnerModals } from '../../providers';
import { AgentUsersBalancesTable } from '../cashier';

const AgentUsersModal = () => {
  const { dispatch } = useInnerModals();
  useLayoutEffect(() => {
    dispatch([
      'setWrapperProps',
      {
        background: 'black',
      },
    ]);
  }, [dispatch]);

  return <AgentUsersBalancesTable />;
};

export { AgentUsersModal };
