/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMemo, ComponentProps } from 'react';
import styled, { css, useTheme, DefaultTheme } from 'styled-components';
import {
  components,
  SingleValueProps as DefSingleValueProps,
} from 'react-select';
import { Text } from '../Text';

export type SingleValueProps = DefSingleValueProps<{
  label: string;
  value: string;
  icon?: string;
  iconSize?: IconSize;
  iconPosition?: IconPosition;
}>;

const SingleValue = (props: SingleValueProps) => {
  const theme = useTheme();
  const {
    label,
    icon,
    iconSize = IconSize.NORMAL,
    iconPosition,
  } = props.getValue()?.[0] ?? {};

  const startPosition = iconPosition === IconPosition.START;

  const Icon = useMemo(
    () => (props: ComponentProps<typeof StyledIcon>) =>
      (
        <StyledIcon
          className={'react-select__label-icon'}
          src={icon}
          alt={label}
          $iconSize={iconSize}
          {...props}
        />
      ),
    [icon, iconSize, label]
  );

  if (!props.hasValue || !icon) {
    return <DefSingleValue {...props}>{props.children}</DefSingleValue>;
  }

  return (
    // @ts-ignore
    <StyledSingleValue
      // @ts-ignore
      $hideBreakPoint={props.selectProps.collapsedLabelToIconBreakPoint}
      $getDownMedia={theme.getDownMedia}
      {...props}
    >
      {startPosition && <Icon $start />}
      <Label
        // @ts-ignore
        $whiteBackground={props.selectProps.whiteBackground ?? false}
      >
        {label}
      </Label>
      {!startPosition && <Icon />}
    </StyledSingleValue>
  );
};

const DefSingleValue = components.SingleValue;

// noinspection JSUnusedGlobalSymbols
export enum IconPosition {
  START,
  END,
}

export enum IconSize {
  // noinspection JSUnusedGlobalSymbols
  NORMAL,
  BIG,
}

const StyledIcon = styled.img<{
  $start?: boolean;
  $iconSize: IconSize;
}>`
  ${({ $iconSize }) =>
    $iconSize === IconSize.NORMAL
      ? css`
          width: 14px;
          height: 14px;
        `
      : css`
          width: 23px;
          height: 23px;
        `}

  ${({ $start }) =>
    $start
      ? css`
          margin-right: 8px;
        `
      : css`
          margin-left: 8px;
        `}
`;

const Label = styled(Text).attrs({
  component: 'span',
})<{
  $whiteBackground: boolean;
}>`
  color: ${({ $whiteBackground }) => ($whiteBackground ? '#000' : '#fff')};
`;

const StyledSingleValue = styled(DefSingleValue)<{
  $hideBreakPoint?: number;
  $getDownMedia: DefaultTheme['getDownMedia'];
}>`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;

  ${({ $getDownMedia, $hideBreakPoint }) => {
    if (!$hideBreakPoint) return;
    const breakPointStr = $getDownMedia($hideBreakPoint);
    return css`
      ${Label} {
        ${breakPointStr} {
          display: none;
        }
      }

      ${StyledIcon} {
        ${breakPointStr} {
          margin-left: 0;
          margin-right: 0;
        }
      }
    `;
  }}}
`;

export { SingleValue };
