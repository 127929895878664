import { useCustomLayoutContentStyles } from '../hooks';
import { VaultProvider } from '../providers';
import {
  VaultInfo,
  VaultTabs,
  /*, VaultTable*/
} from '../components/vault';
import { cashierAndVaultPagesStyles } from '../styles/cashier-and-vault-pages';

const VaultPage = () => {
  useCustomLayoutContentStyles(cashierAndVaultPagesStyles);

  return (
    <VaultProvider>
      <VaultInfo />
      <VaultTabs />
      {/*<VaultTable />*/}
    </VaultProvider>
  );
};

export { VaultPage };
