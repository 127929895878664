/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, HTMLAttributes, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMethods } from 'react-use';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { useBlockchain } from '../../providers';
import { useInnerModals } from '../../providers';
import {
  useDelegateOrRevokeNftMutation,
  UserDataToDelegateNftDocument,
  UserDataToDelegateNftQuery,
  UserDataToDelegateNftQueryVariables,
  DelegatedNfTsDocument,
} from '../../apollo/operations';
import { resetListStyles } from '../../styles/helpers';
import { Text } from '../Text';
import { Button as DefButton } from '../buttons/Button';
import { Input } from '../form-elements';

export type DelegateNFTCardsFormProps = {
  cardIds: number[];
} & HTMLAttributes<HTMLFormElement>;

const DelegateNFTCardsForm = ({
  cardIds,
  ...props
}: DelegateNFTCardsFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const blockchain = useBlockchain();
  const { dispatch } = useInnerModals();
  const [state, methods] = useMethods(createMethods, initialState);
  const [fillingInName, setFillingInName] = useState(true);

  const [delegateOrRevokeNFT] = useDelegateOrRevokeNftMutation({
    fetchPolicy: 'no-cache',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: DelegatedNfTsDocument,
      },
    ],
  });

  useEffect(() => {
    if (!state.username) return;

    let mounted = true;

    setFillingInName(true);

    const t = setTimeout(() => {
      client
        .query<UserDataToDelegateNftQuery, UserDataToDelegateNftQueryVariables>(
          {
            query: UserDataToDelegateNftDocument,
            variables: {
              name: state.username,
            },
          }
        )
        .then((response) => {
          if (!mounted) return;
          const customGpanelUserId = response.data.NFTFindUser?.gPanelUserId;
          if (customGpanelUserId) {
            methods.setCustomGpanelUserId(customGpanelUserId);
          } else {
            methods.setUsernameError(true);
          }
        })
        .catch((e) => {
          if (!mounted) return;
          methods.setUsernameError(true);
          console.log('response error', e);
        })
        .finally(() => {
          setFillingInName(false);
        });
    }, 800);

    return () => {
      mounted = false;
      clearTimeout(t);
    };
  }, [client, methods, state.username]);

  const getOnButtonClickHandler =
    (delegate: boolean) => (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (!blockchain?.id) {
        enqueueSnackbar(t('DELEGATE_NFT_CARDS_FORM__noBlockchainError'), {
          variant: 'warning',
        });
        return;
      }

      if (isEmpty(cardIds)) {
        enqueueSnackbar(t('DELEGATE_NFT_CARDS_FORM__noItemsError'), {
          variant: 'warning',
        });
        return;
      }

      if (delegate && (state.usernameError || !state.username)) {
        enqueueSnackbar(t('DELEGATE_NFT_CARDS_FORM__wrongOtherUserError'), {
          variant: 'warning',
        });
        return;
      }

      dispatch([
        'setModalContent',
        delegate ? 'confirmDelegateNFTs' : 'confirmRevokeNFTs',
        {
          onConfirm: async () => {
            try {
              const gPanelUserId = state.customGpanelUserId;

              const result = await delegateOrRevokeNFT({
                variables: {
                  blockchainId: blockchain.id,
                  nftIds: cardIds,
                  gPanelUserId,
                  delegate,
                },
              });

              const message = result.data?.NFTRentOut?.message;

              if (result.data?.NFTRentOut?.message !== 'OK') {
                throw new Error(`delegateOrRevokeNFT error: ${message}`);
              }

              enqueueSnackbar(
                delegate
                  ? t('DELEGATE_NFT_CARDS_FORM__delegateMutationSuccess')
                  : t('DELEGATE_NFT_CARDS_FORM__revokeMutationSuccess'),
                { variant: 'success' }
              );

              dispatch(['closeModal']);
            } catch (e) {
              enqueueSnackbar(
                delegate
                  ? t('DELEGATE_NFT_CARDS_FORM__delegateMutationError')
                  : t('DELEGATE_NFT_CARDS_FORM__revokeMutationError'),
                { variant: 'error' }
              );
            }
          },
        },
      ]);
    };

  return (
    <Form {...props}>
      <FormTitle>{t('DELEGATE_NFT_CARDS_FORM__title')}:</FormTitle>
      <Username>
        <UsernameLabel>
          {t('DELEGATE_NFT_CARDS_FORM__usernameLabel')}:
        </UsernameLabel>
        <UsernameInput
          error={state.usernameError}
          value={state.username}
          onChange={(e) => {
            methods.setUsername(e.target.value);
          }}
        />
      </Username>
      <ButtonsList>
        <ButtonsListItem>
          <Button
            disabled={isEmpty(cardIds) || fillingInName}
            onClick={getOnButtonClickHandler(true)}
          >
            {t('DELEGATE_NFT_CARDS_FORM__delegateButton')}
          </Button>
        </ButtonsListItem>
        <ButtonsListItem>
          <Button
            disabled={isEmpty(cardIds)}
            onClick={getOnButtonClickHandler(false)}
          >
            {t('DELEGATE_NFT_CARDS_FORM__revokeButton')}
          </Button>
        </ButtonsListItem>
        <ClaimButtonsListItem>
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(['setModalContent', 'claim']);
            }}
          >
            {t('DELEGATE_NFT_CARDS_FORM__claimButton')}
          </Button>
        </ClaimButtonsListItem>
      </ButtonsList>
    </Form>
  );
};

type State = {
  username: string;
  customGpanelUserId: Nullish<string>;
  usernameError: boolean;
};

const initialState: State = {
  username: '',
  customGpanelUserId: null,
  usernameError: false,
};

// noinspection JSUnusedGlobalSymbols
const createMethods = (prevState: State) => ({
  setUsername(username: string) {
    return username ? { ...prevState, username } : initialState;
  },
  setUsernameError(error: boolean) {
    return {
      ...prevState,
      ...(error
        ? {
            customGpanelUserId: null,
          }
        : {}),
      usernameError: error,
    };
  },
  setCustomGpanelUserId(id: string) {
    return {
      ...prevState,
      customGpanelUserId: id,
      usernameError: false,
    };
  },
});

const Form = styled.form``;

const FormTitle = styled(Text).attrs({
  component: 'h2',
  variant: 'normal',
  weight: 'medium',
})`
  text-transform: uppercase;
`;

const Username = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
`;

const UsernameLabel = styled(Text)`
  font-size: 1rem;
  margin-right: 10px;
`;

const UsernameInput = styled(Input)``;

const ButtonsList = styled.ul`
  ${resetListStyles};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  margin-top: 20px;
  ${({ theme }) => theme.getDownMedia('xs')} {
    grid-template-columns: auto;
    grid-row-gap: 10px;
  }
`;

const ButtonsListItem = styled.li``;

const ClaimButtonsListItem = styled.li`
  grid-column: 1 / -1;
`;

const Button = styled(DefButton)``;

export { DelegateNFTCardsForm };
