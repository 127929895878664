import { HTMLAttributes, FC } from 'react';
import { useTranslation } from 'react-i18next';
import defaultCard from '../../assets/cards/default.svg';
import { CardTag } from '../cards-elements';
import { CardWrapper, CardImage } from '../styled/cards';

export type ProcessingCardItemProps = {
  tokenId: string;
} & HTMLAttributes<HTMLLIElement>;

const ProcessingCardItem: FC<ProcessingCardItemProps> = ({
  tokenId,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper {...props}>
      <CardTag label={t('PROCESSING_CARD_ITEM__processingTag')} />
      <CardImage src={defaultCard} />
    </CardWrapper>
  );
};

export { ProcessingCardItem };
