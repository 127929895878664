import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { icons, IconType } from 'utils/consts/icons';

export type IconProps = {
  size?: number | string;
  icon: IconType;
} & HTMLAttributes<HTMLImageElement>;

const Icon = ({ size = 20, icon, ...props }: IconProps) => (
  <Img src={icons[icon]} $size={size} alt={`${icon} icon`} {...props} />
);

const Img = styled.img<{ $size: number | string }>`
  max-width: 100%;
  width: 20px;

  ${({ $size }) => css`
    width: ${isNaN(parseFloat(`${$size}`)) ? `${$size}` : `${$size}px`};
  `}
`;

export { Icon };
