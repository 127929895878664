import { isArray, isObject } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

export const isArrayOrObject = (
  data: unknown
): data is Array<unknown> | Record<string, unknown> =>
  [isArray, isObject].some((fn) => fn(data));

export const getUniqueId = () => uuidv4();

type OptionalReadOnlyObject =
  | Record<string, unknown>
  | Readonly<Record<string, unknown>>;
type OptionalReadOnlyArray = Array<unknown> | ReadonlyArray<unknown>;

export const prettifyResponse = (
  data: OptionalReadOnlyObject | OptionalReadOnlyArray
) => JSON.stringify(data, undefined, 2);

export const wait = (delay = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
