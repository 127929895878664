import { useEffect } from 'react';
import { useMethods } from 'react-use';
import dayjs from 'dayjs';
import { KYCStatuses } from '../utils/enums';
import {
  useLastDepositTransactionQuery,
  useLastWithdrawTransactionQuery,
  useUserKycDataQuery,
} from '../apollo/operations';

export const useCashierGeneralInfo = () => {
  const [state, methods] = useMethods(createMethods, initialState);

  const kycRequest = useUserKycDataQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  const lastDepositRequest = useLastDepositTransactionQuery({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  const lastWithdrawRequest = useLastWithdrawTransactionQuery({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  // Handle loading
  useEffect(() => {
    methods.toggleLoading(
      kycRequest.loading ||
        lastDepositRequest.loading ||
        lastWithdrawRequest.loading
    );
  }, [kycRequest, lastDepositRequest, lastWithdrawRequest, methods]);

  // Handle error
  useEffect(() => {
    if (
      kycRequest.error ||
      lastDepositRequest.error ||
      lastWithdrawRequest.error
    ) {
      methods.setError(
        new Error(
          `Error getting general info; depositError: ${lastDepositRequest.error?.message}; withdrawError: ${lastWithdrawRequest.error?.message}`
        )
      );
    }
  }, [kycRequest, lastDepositRequest, lastWithdrawRequest, methods]);

  // Handle kyc
  useEffect(() => {
    const status = kycRequest.data?.me?.kycStatus;
    if (status) {
      methods.setKYCStatus(status);
    }
  }, [kycRequest, methods]);

  // Handle deposit value
  useEffect(() => {
    const donePayment = lastDepositRequest.data?.gpanelPayments[0];
    const pendingPayment = lastDepositRequest.data?.pendingTransactions[0];

    if (donePayment && pendingPayment) {
      const sortedPayments = [donePayment, pendingPayment].sort(
        (first, second) => {
          const firstDate = 'date' in first ? first.date : first.createdAt;
          const secondDate = 'date' in second ? second.date : second.createdAt;
          return dayjs(firstDate).isAfter(secondDate) ? -1 : 1;
        }
      );
      const payment = sortedPayments[0];
      const currency = payment.currency;
      const amount = payment.amount;
      if (currency && amount) {
        methods.setDeposit({
          currency,
          amount,
        });
      }
      return;
    }

    const currency = donePayment?.currency || pendingPayment?.currency;
    const amount = donePayment?.amount || pendingPayment?.amount;

    if (currency && amount) {
      methods.setDeposit({
        currency,
        amount,
      });
    }
  }, [lastDepositRequest, methods]);

  // Handle withdraw value
  useEffect(() => {
    const doneWithdrawal = lastWithdrawRequest.data?.gpanelPayments[0];
    const pendingWithdrawal = lastWithdrawRequest.data?.pendingTransactions[0];

    if (doneWithdrawal && pendingWithdrawal) {
      const sortedPayments = [doneWithdrawal, pendingWithdrawal].sort(
        (first, second) => {
          const firstDate = 'date' in first ? first.date : first.createdAt;
          const secondDate = 'date' in second ? second.date : second.createdAt;
          return dayjs(firstDate).isAfter(secondDate) ? -1 : 1;
        }
      );
      const payment = sortedPayments[0];
      const currency = payment.currency;
      const amount = payment.amount;
      if (currency && amount) {
        methods.setWithdraw({
          currency,
          amount,
        });
      }
      return;
    }

    const currency = doneWithdrawal?.currency || pendingWithdrawal?.currency;
    const amount = doneWithdrawal?.amount || pendingWithdrawal?.amount;

    if (currency && amount) {
      methods.setWithdraw({
        currency,
        amount,
      });
    }
  }, [lastWithdrawRequest, methods]);

  return state;
};

type TransactionData = {
  currency: string;
  amount: number;
};

type State = {
  loading: boolean;
  error: Maybe<Error>;
  data: {
    kycStatus: Maybe<KYCStatuses>;
    lastDeposit: Maybe<TransactionData>;
    lastWithdraw: Maybe<TransactionData>;
  };
};

const initialState: State = {
  loading: true,
  error: null,
  data: {
    kycStatus: null,
    lastDeposit: null,
    lastWithdraw: null,
  },
};

// noinspection JSUnusedGlobalSymbols
const createMethods = (state: State) => ({
  toggleLoading: (val: boolean) => ({
    ...state,
    loading: val ?? !state.loading,
  }),
  setError: (error: Error) => ({
    ...state,
    error,
  }),
  setKYCStatus: (kycStatus: KYCStatuses) => ({
    ...state,
    data: {
      ...state.data,
      kycStatus,
    },
  }),
  setDeposit: (lastDeposit: TransactionData) => ({
    ...state,
    data: {
      ...state.data,
      lastDeposit,
    },
  }),
  setWithdraw: (lastWithdraw: TransactionData) => ({
    ...state,
    data: {
      ...state.data,
      lastWithdraw,
    },
  }),
  reset: () => initialState,
});
