import { PropsWithChildren } from 'react';
import { ApolloProvider } from './ApolloProvider';
import { BlockchainProvider } from './BlockchainProvider';
import { BlockchainsProvider } from './BlockchainsProvider';
import { GpanelProvider } from './GpanelProvider';
import { InitialDataProvider } from './InitialDataProvider';
import { InnerModals } from './InnerModals';
import { OuterModals } from './OuterModals';
import { RouterProvider } from './RouterProvider';
import { ServerAuthProvider } from './ServerAuthProvider';
import { SnackbarProvider } from './SnackbarProvider';
import { StylesProvider } from './StylesProvider';
import { WagmiProvider } from './WagmiProvider';

const Providers = (props: PropsWithChildren) => (
  <StylesProvider>
    <RouterProvider>
      <SnackbarProvider>
        <ApolloProvider>
          <InitialDataProvider>
            <BlockchainsProvider>
              <WagmiProvider>
                <BlockchainProvider>
                  <GpanelProvider>
                    <ServerAuthProvider>
                      <OuterModals>
                        <InnerModals {...props} />
                      </OuterModals>
                    </ServerAuthProvider>
                  </GpanelProvider>
                </BlockchainProvider>
              </WagmiProvider>
            </BlockchainsProvider>
          </InitialDataProvider>
        </ApolloProvider>
      </SnackbarProvider>
    </RouterProvider>
  </StylesProvider>
);

export { Providers };
