import { useContext, PropsWithChildren } from 'react';
import { getModalsContext } from '../utils/modals';
import { ModalsSkeleton } from '../components';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';

const OuterModals = ({ children }: PropsWithChildren<$TSFixMe>) => (
  <ModalsSkeleton modals={modals} ContextProvider={OuterModalsContext.Provider}>
    {children}
  </ModalsSkeleton>
);

const modals = {
  confirmationModal: ConfirmationModal,
} as const;

export const OuterModalsContext = getModalsContext<keyof typeof modals>();

export const useOuterModals = () => useContext(OuterModalsContext);

export { OuterModals };
