import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isNil, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  Table as DefTable,
  TableRow,
  TableCell as DefTableCell,
} from 'components/styled/table';
import DOMPurify from 'dompurify';
import { SiteLinks } from '../../utils/consts/site-data';
import { getFormattedNumber, getPercent } from '../../utils/helpers/strings';
import { TranslationKeys } from '../../utils/i18n';
import { useVaultTable } from '../../hooks';
import { useServerAuth } from '../../providers';
import { useUserStakingDataQuery } from '../../apollo/operations';
import { resetListStyles } from '../../styles/helpers';
import { Text } from '../Text';
import { Button } from '../buttons';
import { Spinner } from '../spinners/Spinner';

export type VaultTableProps = HTMLAttributes<HTMLDivElement>;

const VaultTable = (props: VaultTableProps) => {
  const { t } = useTranslation();
  const { loading, error, data } = useVaultTable();
  const { authorized, fullAccess, authOnServer } = useServerAuth();
  const { data: stakingData } = useUserStakingDataQuery({
    fetchPolicy: 'cache-and-network',
    skip: !authorized,
  });

  const stakingNft = stakingData?.me?.staking?.nft || false;
  const stakingId = toNumber(stakingData?.me?.staking?.rackbackId) || 0;

  return (
    <Wrapper {...props}>
      {(() => {
        if (loading && !data) {
          return (
            <Spinner
              style={{
                margin: 'auto',
              }}
            />
          );
        }

        if (error) {
          return t('VAULT_TABLE__loadError');
        }

        if (!data) return null;

        return (
          <>
            <Header>
              <Title>{t('VAULT_TABLE__tableTitle')}</Title>
              <Link>
                <a
                  href={SiteLinks.LEARN_MORE}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {t('VAULT_TABLE__learnMoreLink')}
                </a>
              </Link>
            </Header>
            <Table>
              <TableRow>
                {columns.map(([key, label]) => (
                  <TableCell key={key} $header>
                    {t(label)}
                  </TableCell>
                ))}
              </TableRow>
              {data.map((data) => (
                <TableRow key={data.id}>
                  {columns.map(([key]) => {
                    const rawValue = data[key];

                    if (isNil(rawValue)) {
                      return <TableCell />;
                    }

                    let value;

                    switch (key) {
                      case 'percentage':
                        value = getPercent(rawValue);
                        if (data.id === stakingId && !stakingNft)
                          value = (
                            <b>
                              <u>{value}</u>
                            </b>
                          );
                        break;
                      case 'percentageNft':
                        value = getPercent(rawValue);
                        if (data.id === stakingId && stakingNft)
                          value = (
                            <b>
                              <u>{value}</u>
                            </b>
                          );
                        break;
                      case 'usd':
                        value = getFormattedNumber(rawValue);
                        break;
                      default:
                        value = rawValue;
                    }

                    return (
                      <TableCell key={key}>
                        <p>{value}</p>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </Table>
          </>
        );
      })()}
      {!fullAccess && (
        <SignButton onClick={authOnServer}>
          {t('VAULT_TABLE__signWalletButton')}
        </SignButton>
      )}
      <TableInfoBlock>
        <ul
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t('VAULT_TABLE__infoList')),
          }}
        ></ul>
        <p>{t('VAULT_TABLE__infoFirstNote')}</p>
        <p>{t('VAULT_TABLE__infoSecondNote')}</p>
      </TableInfoBlock>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  grid-column: 1 / -1;
  padding-top: 18px;
  border-top: 1px solid ${({ theme }) => theme.getColor('charade')};
  max-width: 100%;
  min-width: 0;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
`;

const Title = styled(Text).attrs({
  component: 'h2',
  variant: 'h4',
})`
  text-transform: uppercase;
`;

const Link = styled(Text)`
  margin-right: 26px;

  ${({ theme }) => theme.getDownMedia('xs')} {
    margin-right: 0;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.getColor('oceanGreen')};
  }
`;

const Table = styled(DefTable)`
  grid-template-columns: minmax(200px, 26%) minmax(200px, 27%) minmax(
      200px,
      1fr
    );
`;

const TableCell = styled(DefTableCell)`
  justify-content: center;
`;

const SignButton = styled(Button)`
  margin: 20px auto 10px;
`;

const TableInfoBlock = styled.div`
  margin-top: 28px;

  ul {
    ${resetListStyles};
    margin-bottom: 24px;
  }

  li + li {
    margin-top: 2px;
  }

  p {
    margin-top: 2px;
  }
`;

const columns: ReadonlyArray<
  [
    keyof NonNullable<ReturnType<typeof useVaultTable>['data']>[number],
    TranslationKeys
  ]
> = [
  ['percentage', 'VAULT_TABLE__regularUsersColumn'],
  ['percentageNft', 'VAULT_TABLE__eliteUsersColumn'],
  ['usd', 'VAULT_TABLE__toStakeColumn'],
];

export { VaultTable };
