import { css } from 'styled-components';

export const cashierAndVaultPagesStyles = css`
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-gap: 30px 25px;

  ${({ theme: { getDownMedia } }) => getDownMedia(1100)} {
    grid-template-columns: 1fr;
  }
`;
