import styled, { Breakpoint, css } from 'styled-components';
import { resetListStyles } from '../../../styles/helpers';

export const BalancesInfoList = styled.ul<{
  $switchBreakpoint: Breakpoint | number;
  $withBottomLine?: boolean;
}>`
  ${resetListStyles};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;

  ${({ theme, $switchBreakpoint }) => theme.getDownMedia($switchBreakpoint)} {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }

  ${({ theme, $withBottomLine }) =>
    $withBottomLine &&
    css`
      padding-bottom: 17px;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: ${theme.getColor('rgbaDividerColor')};
      }
    `}
`;
