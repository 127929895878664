import { useCallback } from 'react';
import { useAccount, useSignMessage } from 'wagmi';
import { useApolloClient } from '@apollo/client';
import {
  NonceDocument,
  NonceQuery,
  NonceQueryVariables,
} from '../apollo/operations';

export const useWalletSignature = () => {
  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const client = useApolloClient();

  const getNonce = useCallback(async () => {
    try {
      if (!address) {
        throw new Error('No account in getNonce fn');
      }

      const response = await client.query<NonceQuery, NonceQueryVariables>({
        query: NonceDocument,
        fetchPolicy: 'no-cache',
        variables: {
          address: address,
        },
      });

      const nonce = response.data.authSignature?.nonce;

      if (!nonce) {
        throw new Error('No nonce in query response');
      }

      return nonce;
    } catch (e) {
      console.log(`Couldn't get nonce:`, e);
      throw e;
    }
  }, [address, client]);

  return useCallback(async () => {
    try {
      if (!address) {
        throw new Error('No account in wallet signature');
      }

      const nonce = await getNonce();

      return signMessageAsync({
        message: `I am signing my one-time nonce: ${nonce}`,
      });
    } catch (e) {
      console.log(`Couldn't sign wallet:`, e);
      throw e;
    }
  }, [address, getNonce, signMessageAsync]);
};
