import styled from 'styled-components';
import { Image } from '../../Image';

export const CardImage = styled(Image)`
  width: calc(100% + 2px);
  aspect-ratio: 1 / 1;
  border-radius: 50px;
  margin: -1px -1px 23px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.getColor('shark2')};

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
