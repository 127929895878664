import { HTMLAttributes, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import icon from '../../assets/svgs/icons/white/checkmark_icon.svg';

export type CheckboxProps = HTMLAttributes<HTMLLabelElement> & {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
};

const Checkbox = ({ inputProps, ...props }: CheckboxProps) => (
  <Wrapper
    onClick={(event) => {
      event.stopPropagation();
    }}
    {...props}
  >
    <Box>
      <Input type={'checkbox'} {...inputProps} />
      <BoxShape $selected={!!inputProps?.checked} />
    </Box>
  </Wrapper>
);

const Wrapper = styled.label`
  cursor: pointer;
`;

const Box = styled.div`
  width: 20px;
  height: 20px;
`;

const BoxShape = styled.div<{
  $selected: boolean;
}>`
  width: 100%;
  height: 100%;
  ${({ theme: { getColor }, $selected }) =>
    $selected
      ? css`
          background: ${getColor('gradientPrimaryColor')};
        `
      : css`
          background: ${`linear-gradient(291.36deg, ${getColor(
            'hurricane'
          )} -13.73%, ${getColor('silverChalice')} 113.92%), ${getColor(
            'tallPoppy'
          )}`};
        `}

  border-radius: 4px;
`;

const Input = styled.input`
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;

  &:checked + ${BoxShape} {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 19px;
      height: 15px;
      position: absolute;
      top: 3px;
      left: 2px;
      background-image: url(${icon});
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
`;

export { Checkbox };
