import rhombusIconBlack from 'assets/svgs/icons/black/rhombus_icon.svg';
import average from 'assets/svgs/icons/colorful/average.svg';
import currencyBnb from 'assets/svgs/icons/colorful/currencies/bnb.svg';
import volume from 'assets/svgs/icons/colorful/volume.svg';
import closeIcon from 'assets/svgs/icons/white/close_icon.svg';
import loginIcon from 'assets/svgs/icons/white/login_icon.svg';
import cashier from 'assets/svgs/icons/white/menu_items/cashier.svg';
import marketplace from 'assets/svgs/icons/white/menu_items/marketplace.svg';
import myCards from 'assets/svgs/icons/white/menu_items/mycards.svg';
import nftAvatar from 'assets/svgs/icons/white/menu_items/nft-avatar.svg';
import play from 'assets/svgs/icons/white/menu_items/play.svg';
import transactions from 'assets/svgs/icons/white/menu_items/transactions.svg';
import vault from 'assets/svgs/icons/white/menu_items/vault.svg';
import navbarIcon from 'assets/svgs/icons/white/navbar_icon.svg';

export const icons = {
  marketplace,
  play,
  myCards,
  nftAvatar,
  transactions,
  cashier,
  vault,
  closeIcon,
  loginIcon,
  currencyBnb,
  volume,
  average,
  rhombusIconBlack,
  navbarIcon,
};

export type IconType = keyof typeof icons;
